import React, { useReducer, useState } from 'react'
// import { useLocation } from 'react-router-dom'

// import { makeStyles } from '@mui/styles'
import { Box, AppBar, Toolbar } from '@mui/material'
// import { Typography, useTheme } from '@mui/material'
// import Grid from '@mui/material/Grid'

import IDXLogo from '../dashboard/idx_hor_wht.png'
import Configurations from './Configurations'
import { configurable } from '../config'

// const navStyles = makeStyles(theme => ({
//   root: {
//     // zIndex: props => props.mobile ? 1301 : '',
//     // boxShadow: 'none',
//     // background:'red'
//   },
//   titleContainer: {
//     // width: '100%',
//     // padding: '13px 0',
//     // height: '41px'
//   },
//   idxLogo: {
//     // backgroundImage: `url(${IDXLogo})`,
//     // backgroundRepeat: 'no-repeat',
//     // height: '100%',
//     // width: '100%',
//     // backgroundSize: 'contain'

//   },
//   title: {
//     fontStyle: 'italic',
//     fontWeight: '100',
//     fontVariant: 'small-caps',
//     '&:hover': {
//       textDecoration: 'underline'
//     }
//   },
//   link: {
//     color: theme.palette.text.primary,
//     textDecoration: 'none',
//   },
//   signUp: {
//     marginRight: props => props.location.pathname !== '/login' ? 6 : 0,
//     textTransform: 'unset'
//   },
//   blog: {
//     marginRight: 6,
//     background: '#ff7800',
//     textTransform: 'unset'
//   },
//   logIn: {
//     background: theme.palette.grey[400],
//     textTransform: 'unset'
//   },
//   subtitle: {
//     fontWeight: '500',
//     fontSize: '0.88em'
//   }
// }));


let timeOut = null
const Nav = () => {

  // const location = useLocation()
  const [showConfigurations, setShowConfigurations] = useState(false)
  const [clickCount, setClickCount] = useReducer((s, o) => o.action === 'click' ? s + 1 : 0, 0)

  // const mobile = useTheme().breakpoints.down('xs')
  // const styles = navStyles({
  //   mobile,
  //   location
  // })

  const focus = () => {
    if (document.getElementById('cardRef')) {
      document.getElementById('cardRef').focus();
    }
  }

  const click = () => {
    if (showConfigurations) return
    clearTimeout(timeOut)
    setClickCount({ action: 'click' })
    timeOut = setTimeout(() => {
      setClickCount({})
      clearTimeout(timeOut)
    }, 3000)
  }

  if (clickCount >= 5) {
    clearTimeout(timeOut)
    setClickCount({})
    setShowConfigurations(true)
  }

  // const immutedConfig = window.localStorage.getItem('immutedConfig')

  return (
    // <AppBar className={styles.root} onClick={focus} color="default">
    <AppBar
      sx={{
        // border: 'green solid 1px',
        zIndex: props => props.mobile ? 1301 : '',
        boxShadow: 'none',
      }}
      onClick={focus} color="default">
      <Toolbar>
        <Box
          sx={{ height: '100%' }}>
          <Box
            sx={{
              // border: 'yellow 1px solid',
              backgroundSize: 'contain',
              height: '4.5vh',
              width: '15%',
              marginRight: '3vw',
              minWidth: '140px',
              background: `url(${IDXLogo}) no-repeat center left / contain`,
              cursor: 'pointer',
            }}
            onClick={() => click()}
          // style={{ filter: immutedConfig ? 'invert(1) drop-shadow(2px 4px 6px white)' : '' }}
          >
          </Box>
        </Box>
        {/* <Grid
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
          container
          spacing={4}>
        </Grid> */}
        {/* <Typography>Hello there</Typography>
        <Typography>Hello there</Typography>
        <Typography>Hello there</Typography> */}
      </Toolbar>
      {showConfigurations && configurable && <Configurations setShowConfigurations={setShowConfigurations} />}
    </AppBar>
  )
}
export default Nav