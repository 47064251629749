import React, { memo } from 'react'
import { toLower } from 'lodash'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

import R404 from '../404'
import DragDropTree from './devices/tree/DragDropTree'
import Tables from './tables/Tables'
import Templates from './templates/Templates'

export default memo(() => {
    const { pathname } = useLocation()
    const role = window.localStorage.getItem('role')
    const products = window.localStorage.getItem('products')
        ? window.localStorage
            .getItem('products')
            .split(',')
            .map((x) => toLower(x))
        : []

    if (role !== 'root' && !products.includes(pathname.split('/')[1])) {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            <Routes>
                <Route exact path='/' element={<Navigate to='/xtrend/home' replace />} />
                <Route exact path='/home' element={<DragDropTree route='Overview' />} />
                <Route
                    exact
                    path='/device/:index'
                    element={<DragDropTree route='Device' selected={{ type: 'Device' }} />}
                />
                <Route exact path='/casino' element={<DragDropTree route='Overview' selected={{ type: 'Casino' }} />} />
                <Route
                    exact
                    path='/zone/:index'
                    element={<DragDropTree route='Overview' selected={{ type: 'Zone' }} />}
                />
                <Route
                    exact
                    path='/pit/:index'
                    element={<DragDropTree route='Overview' selected={{ type: 'Pit' }} />}
                />
                <Route
                    exact
                    path='/table/:index'
                    element={<DragDropTree route='Overview' selected={{ type: 'Table' }} />}
                />
                <Route exact path='/tables' element={<Tables />} />
                <Route exact path='/templates' element={<Templates />} />
                <Route path='*' element={<R404 basePath='xtrend/' />} />
            </Routes>
        </>
    )
})
