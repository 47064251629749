import { useEffect, useReducer } from 'react'
import { Base64 } from 'js-base64'
import Katamari from 'katamari-client'
import ky from 'ky'
import { PrometheusDriver } from 'prometheus-query'
import * as tus from "tus-js-client"

import {
    authIP,
    cashIP,
    dealerIP,
    tableIP,
    historyIP,
    playerIP,
    pivotIP,
    tusdIP,
    prometheusTrend,
    ssl,
    xstadiumIP,
    patternsServerIP
} from './config'

const protocol = ssl ? 'https://' : 'http://'
const domain = pivotIP
const prefixUrl = protocol + domain

export const promTrendApi = prometheusTrend ? new PrometheusDriver({
    endpoint: prometheusTrend,
    baseURL: "/api/v1" // default value
}) : null

export const api = ky.extend({
    prefixUrl, retry: {
        limit: 0
    },
    timeout: 25000
})

export const cashApi = ky.extend({
    prefixUrl: protocol + cashIP, retry: {
        limit: 0
    },
    timeout: 35000
})

export const authApi = ky.extend({
    prefixUrl: protocol + authIP, retry: {
        limit: 0
    },
    timeout: 25000
})

export const dealerApi = ky.extend({
    prefixUrl: protocol + dealerIP, retry: {
        limit: 0
    },
    timeout: 50000
})

export const tableApi = ky.extend({
    prefixUrl: protocol + tableIP, retry: {
        limit: 0
    },
    timeout: 50000
})

export const historyApi = ky.extend({
    prefixUrl: protocol + historyIP, retry: {
        limit: 0
    },
    timeout: 50000
})

export const playerApi = ky.extend({
    prefixUrl: protocol + playerIP, retry: {
        limit: 0
    },
    timeout: 50000
})

export const xstadiumApi = ky.extend({
    prefixUrl: protocol + xstadiumIP, retry: {
        limit: 0
    },
    timeout: 50000
})

export const patternsApi = ky.extend({
    prefixUrl: protocol + patternsServerIP, retry: {
        limit: 0
    },
    timeout: 50000
})

const isForeingURL = (url) => (!!url && !isNaN(Number(url[0]))) ||
    (!!url && url.indexOf(window.location.hostname) === 0) ||
    (!!url && url.indexOf('http') === 0)

export const fetch = async (url) => {
    const notLocal = isForeingURL(url)
    const sender = notLocal ? ky.extend({
        retry: {
            limit: 0
        },
        timeout: 25000
    }) : api
    if (notLocal && url.indexOf('http') !== 0) {
        url = "http://" + url
    }
    try {
        const token = window.localStorage.getItem('token')
        return await sender.get(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).json()
    } catch (e) {
        console.warn(url, e)
        throw e
    }
}

export const fetchDecoded = async (url) => {
    const notLocal = isForeingURL(url)
    const sender = notLocal ? ky.extend({
        retry: {
            limit: 0
        },
        timeout: 25000
    }) : api
    if (notLocal && url.indexOf('http') !== 0) {
        url = "http://" + url
    }
    try {
        const token = window.localStorage.getItem('token')
        const response = await sender.get(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).json()
        response.data = Base64.decode(response.data)
        response.data = JSON.parse(response.data)
        return response
    } catch (e) {
        console.warn(url, e)
        throw e
    }
}

export const republish = async (url, data) => {
    const notLocal = isForeingURL(url)
    const sender = notLocal ? ky.extend({
        retry: {
            limit: 0
        },
        timeout: 25000
    }) : api
    if (notLocal && url.indexOf('http') !== 0) {
        url = "http://" + url
    }
    try {
        const token = window.localStorage.getItem('token')
        return await sender.put(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            json: data ? data : null
        })
    } catch (e) {
        console.warn(e)
        throw e
    }
}

export const unpublish = async (url) => {
    const notLocal = isForeingURL(url)
    const sender = notLocal ? ky.extend({
        retry: {
            limit: 0
        },
        timeout: 25000
    }) : api
    if (notLocal && url.indexOf('http') !== 0) {
        url = ssl ? "https://" + url : "http://" + url
    }
    try {
        const token = window.localStorage.getItem('token')
        return await sender.delete(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    } catch (e) {
        console.warn(e)
        throw e
    }
}

export const publish = async (url, data) => {
    const notLocal = isForeingURL(url)
    const sender = notLocal ? ky.extend({
        retry: {
            limit: 0
        },
        timeout: 25000
    }) : api
    if (notLocal && url.indexOf('http') !== 0) {
        url = ssl ? "https://" + url : "http://" + url
    }
    try {
        const token = window.localStorage.getItem('token')
        return sender.post(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            json: url.indexOf('user/') < 0 &&
                url.indexOf('register') < 0 &&
                url.indexOf('create') < 0 &&
                url.indexOf('recharge') < 0 &&
                url.indexOf('consume') < 0 &&
                url.indexOf('membership') < 0 &&
                url.indexOf('cashdrop') < 0 &&
                url.indexOf('chipfill') < 0 &&
                url.indexOf('chipcredit') < 0 ? {
                data: Base64.encode(JSON.stringify(data))
            } : data
        }).json()
    } catch (e) {
        console.warn(e)
        throw e
    }
}

export const usePublish = (url, authorize) => (data) => publish(url, data, authorize)

const subscribeReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {
                ...state,
                socket: action.data
            }
        case 'freeze':
            return {
                ...state,
                frozen: true
            }
        case 'resume':
            return {
                ...state,
                frozen: false
            }
        case 'close':
            return {
                ...state,
                socket: null
            }
        case 'data':
            return {
                ...state,
                data: action.data
            }
        default:
            throw new Error()
    }
}

export const subscribe = (url, socket, dispatch) =>
    () => {
        // https://github.com/facebook/react/issues/14326#issuecomment-472043812
        let unmounted = false
        const token = window.localStorage.getItem('token')
        // console.log('effect', url)
        if (!socket) {
            // console.log('mount', url)
            dispatch({
                type: 'open',
                data: Katamari(
                    isForeingURL(url) ? url : domain + (url ? '/' + url : ''),
                    ssl,
                    token ? ['bearer', token] : []
                )
            })
        } else {
            socket.onopen = () => {
                if (!unmounted) {
                    dispatch({ type: 'open', data: socket })
                }
            }
            socket.onerror = async (e) => {
                console.warn(url, e)
                // there's no propagation of the response
                // so close, refresh token and remount is done
                // to any error
                socket.close()
                if (!unmounted) {
                    if (!socket.frozen) {
                        if (!unmounted) {
                            dispatch({ type: 'close' })
                        }
                    }
                }
            }
            socket.onmessage = (data) => {
                if (!unmounted) {
                    dispatch({
                        type: 'data',
                        data
                    })
                }
            }
            socket.onfrozen = () => {
                if (!unmounted) {
                    dispatch({ type: 'freeze' })
                }
            }
            socket.onresume = () => {
                if (!unmounted) {
                    dispatch({ type: 'resume' })
                }
            }
        }

        return () => {
            unmounted = true
            if (socket) {
                // console.log('unmount', url)
                socket.close()
            }
        }
    }

export const useSubscribe = (url) => {
    const [state, dispatch] = useReducer(subscribeReducer, {
        socket: null,
        data: null
    })
    // if (url) {
    //   console.log("subscribe", url, state)
    // }
    if (state.socket) {
        const wsProtocol = ssl ? "wss://" : "ws://"
        const socketUrl = isForeingURL(url) ?
            state.socket.wsUrl.split(wsProtocol)[1] :
            state.socket.wsUrl.split(domain + '/')[1]
        if (socketUrl !== url && socketUrl !== undefined) {
            state.socket.close()
            // console.log("dispatch close", socketUrl)
            dispatch({ type: 'close' })
        }

        // this handles the effect not triggering when the socket state changes
        if (state.socket.readyState === WebSocket.CLOSED || state.socket.readyState === WebSocket.CLOSING) {
            // console.log("should dispatch close????", socketUrl)
            dispatch({ type: 'close' })
        }
    }
    // https://dmitripavlutin.com/react-hooks-stale-closures/
    // in this case I think that keeping the stale closure makes sense
    // the curried function ensures that we keep this "stale closure"
    // to completion by either closing the connection or handling reconnect
    // https://codesandbox.io/s/eager-pine-8wm3s?file=/src/App.js:0-803
    useEffect(subscribe(url, state.socket, dispatch), [state.socket]) // eslint-disable-line react-hooks/exhaustive-deps
    return [state.data, state.socket]
}




export const authorize = async (dispatch, context) => {
    const token = window.localStorage.getItem('token')
    const account = window.localStorage.getItem('account')
    const role = window.localStorage.getItem('role')
    const mock = new Blob(["unauthorized"])
    const fail = {
        response: new Response(mock, {
            status: 401
        })
    }

    if (!token || !account || !role) {
        dispatch({ type: "status", data: 'unauthorized' })
        throw fail
    }

    // try to get the profile
    try {
        if (context) {
            throw context
        }
        const profileRefresh = await authApi.get('profile',
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).json()
        window.localStorage.setItem('account', profileRefresh.account)
    } catch (e) {
        console.warn(e)
    }

    if (window.localStorage.getItem('account') === '' || !window.localStorage.getItem('account')) {
        dispatch({ type: "status", data: "unauthorized" })
        throw fail
    }
    dispatch({ type: "status", data: "authorized" })
}

export const checkAuthorize = async (callback) => {
    const token = window.localStorage.getItem('token')
    try {
        const profileRefresh = await authApi.get('profile',
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).json()
        window.localStorage.setItem('account', profileRefresh.account)
        callback.success()
    } catch (e) {
        console.warn(e)
        window.localStorage.removeItem('account')
        window.localStorage.removeItem('token')
        callback.failed()
    }
}

export const upload = async (file, name, type) =>
    new Promise((resolve, reject) => {
        const token = window.localStorage.getItem('token')
        console.log("TOKEN", token)
        const upload = new tus.Upload(file, {
            endpoint: tusdIP + "/files/",
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: name,
                filetype: type
            },
            headers: {
                'Authorization': 'Bearer ' + token
            },
            onError: function (error) {
                console.log("Failed because: " + error)
                reject(error)
            },
            // onProgress: function (bytesUploaded, bytesTotal) {
            //   var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
            //   console.log(bytesUploaded, bytesTotal, percentage + "%")
            // },
            onSuccess: function () {
                // console.log("Download %s from %s", upload.file.name, upload.url)
                resolve(upload.url)
            }
        })

        // Start the upload
        upload.start()
    })


export const useAuthorize = (dispatch) => (context) => authorize(dispatch, context)