import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import {
    validate,
    update,
    setDefaults,
    getFieldsValues,
    FieldsForm
} from '../../../forms'
import { defaultValues } from '../defaultValues'
import _ from 'lodash'

const rootStyles = makeStyles((theme) => ({
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        width: 980,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
}))

const Payouts_Insurance = ({
    publish,
    tables,
    table,
    settings,
    xstadium,
    changed,
    setChanged,
    template,
    changeCallback
}) => {
    const styles = rootStyles({ xstadium })

    const navigate = useNavigate()

    // field flags
    const [submitted, setSubmitted] = useState(false)

    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)

    // baccarat
    const [playerInsurance2CardsPlayer5, setPlayerInsurance2CardsPlayer5] = useState(2)
    const [playerInsurance2CardsPlayer6, setPlayerInsurance2CardsPlayer6] = useState(3)
    const [playerInsurance2CardsPlayer7, setPlayerInsurance2CardsPlayer7] = useState(4)
    const [playerInsurance3CardsBanker0to4, setPlayerInsurance3CardsBanker0to4] = useState(2)
    const [playerInsurance3CardsBanker0to5, setPlayerInsurance3CardsBanker0to5] = useState(3)
    const [playerInsurance3CardsBanker0to6AndPlayer7, setPlayerInsurance3CardsBanker0to6AndPlayer7] = useState(4)
    const [playerInsurance3CardsBanker0to6AndPlayer8, setPlayerInsurance3CardsBanker0to6AndPlayer8] = useState(8)
    const [playerInsurance3CardsBanker0to6AndPlayer9, setPlayerInsurance3CardsBanker0to6AndPlayer9] = useState(9)
    const [bankerInsurance2CardsBanker5, setBankerInsurance2CardsBanker5] = useState(2)
    const [bankerInsurance2CardsBanker6, setBankerInsurance2CardsBanker6] = useState(3)
    const [bankerInsurance2CardsBanker7, setBankerInsurance2CardsBanker7] = useState(4)
    const [bankerInsurance3CardsPlayer0, setBankerInsurance3CardsPlayer0] = useState(9)
    const [bankerInsurance3CardsPlayer1AndBanker2to6, setBankerInsurance3CardsPlayer1AndBanker2to6] = useState(8)
    const [bankerInsurance3CardsPlayer1AndBanker1, setBankerInsurance3CardsPlayer1AndBanker1] = useState(7)
    const [bankerInsurance3CardsPlayer2, setBankerInsurance3CardsPlayer2] = useState(4)
    const [bankerInsurance3CardsPlayer3, setBankerInsurance3CardsPlayer3] = useState(2)

    const gameType = table?.data?.gameType
    const gameVariant = table?.data?.gameVariant

    const fields = {
        betOdds: {
            baccarat: {
                standard: {
                    playerInsurance2CardsPlayer5: {
                        group: 'player',
                        label: '2-Card Player 5 Points',
                        error: () => playerInsurance2CardsPlayer5 < 1,
                        value: playerInsurance2CardsPlayer5,
                        set: setPlayerInsurance2CardsPlayer5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer6: {
                        group: 'player',
                        label: '2-Card Player 6 Points',
                        error: () => playerInsurance2CardsPlayer6 < 1,
                        value: playerInsurance2CardsPlayer6,
                        set: setPlayerInsurance2CardsPlayer6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer7: {
                        group: 'player',
                        label: '2-Card Player 7 Points',
                        error: () => playerInsurance2CardsPlayer7 < 1,
                        value: playerInsurance2CardsPlayer7,
                        set: setPlayerInsurance2CardsPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to4: {
                        group: 'player',
                        label: '3-Card Banker 0-4 Points',
                        error: () => playerInsurance3CardsBanker0to4 < 1,
                        value: playerInsurance3CardsBanker0to4,
                        set: setPlayerInsurance3CardsBanker0to4,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to5: {
                        group: 'player',
                        label: '3-Card Banker 0-5 Points',
                        error: () => playerInsurance3CardsBanker0to5 < 1,
                        value: playerInsurance3CardsBanker0to5,
                        set: setPlayerInsurance3CardsBanker0to5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer7: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 7 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer7 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer7,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer8: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 8 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer8 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer8,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer8,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer9: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 9 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer9 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer9,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer9,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker5: {
                        group: 'banker',
                        label: '2-Card Banker 5 Points',
                        error: () => bankerInsurance2CardsBanker5 < 1,
                        value: bankerInsurance2CardsBanker5,
                        set: setBankerInsurance2CardsBanker5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker6: {
                        group: 'banker',
                        label: '2-Card Banker 6 Points',
                        error: () => bankerInsurance2CardsBanker6 < 1,
                        value: bankerInsurance2CardsBanker6,
                        set: setBankerInsurance2CardsBanker6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker7: {
                        group: 'banker',
                        label: '2-Card Banker 7 Points',
                        error: () => bankerInsurance2CardsBanker7 < 1,
                        value: bankerInsurance2CardsBanker7,
                        set: setBankerInsurance2CardsBanker7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer0: {
                        group: 'banker',
                        label: '3-Card Player 0 Points',
                        error: () => bankerInsurance3CardsPlayer0 < 1,
                        value: bankerInsurance3CardsPlayer0,
                        set: setBankerInsurance3CardsPlayer0,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker2to6: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 2-6 Points',
                        error: () => bankerInsurance3CardsPlayer1AndBanker2to6 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker2to6,
                        set: setBankerInsurance3CardsPlayer1AndBanker2to6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker1: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 1 Point',
                        error: () => bankerInsurance3CardsPlayer1AndBanker1 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker1,
                        set: setBankerInsurance3CardsPlayer1AndBanker1,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer2: {
                        group: 'banker',
                        label: '3-Card Player 2 Points',
                        error: () => bankerInsurance3CardsPlayer2 < 1,
                        value: bankerInsurance3CardsPlayer2,
                        set: setBankerInsurance3CardsPlayer2,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer3: {
                        group: 'banker',
                        label: '3-Card Player 3 Points',
                        error: () => bankerInsurance3CardsPlayer3 < 1,
                        value: bankerInsurance3CardsPlayer3,
                        set: setBankerInsurance3CardsPlayer3,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                lucky6: {
                    playerInsurance2CardsPlayer5: {
                        group: 'player',
                        label: '2-Card Player 5 Points',
                        error: () => playerInsurance2CardsPlayer5 < 1,
                        value: playerInsurance2CardsPlayer5,
                        set: setPlayerInsurance2CardsPlayer5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer6: {
                        group: 'player',
                        label: '2-Card Player 6 Points',
                        error: () => playerInsurance2CardsPlayer6 < 1,
                        value: playerInsurance2CardsPlayer6,
                        set: setPlayerInsurance2CardsPlayer6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer7: {
                        group: 'player',
                        label: '2-Card Player 7 Points',
                        error: () => playerInsurance2CardsPlayer7 < 1,
                        value: playerInsurance2CardsPlayer7,
                        set: setPlayerInsurance2CardsPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to4: {
                        group: 'player',
                        label: '3-Card Banker 0-4 Points',
                        error: () => playerInsurance3CardsBanker0to4 < 1,
                        value: playerInsurance3CardsBanker0to4,
                        set: setPlayerInsurance3CardsBanker0to4,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to5: {
                        group: 'player',
                        label: '3-Card Banker 0-5 Points',
                        error: () => playerInsurance3CardsBanker0to5 < 1,
                        value: playerInsurance3CardsBanker0to5,
                        set: setPlayerInsurance3CardsBanker0to5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer7: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 7 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer7 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer7,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer8: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 8 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer8 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer8,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer8,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer9: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 9 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer9 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer9,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer9,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker5: {
                        group: 'banker',
                        label: '2-Card Banker 5 Points',
                        error: () => bankerInsurance2CardsBanker5 < 1,
                        value: bankerInsurance2CardsBanker5,
                        set: setBankerInsurance2CardsBanker5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker6: {
                        group: 'banker',
                        label: '2-Card Banker 6 Points',
                        error: () => bankerInsurance2CardsBanker6 < 1,
                        value: bankerInsurance2CardsBanker6,
                        set: setBankerInsurance2CardsBanker6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker7: {
                        group: 'banker',
                        label: '2-Card Banker 7 Points',
                        error: () => bankerInsurance2CardsBanker7 < 1,
                        value: bankerInsurance2CardsBanker7,
                        set: setBankerInsurance2CardsBanker7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer0: {
                        group: 'banker',
                        label: '3-Card Player 0 Points',
                        error: () => bankerInsurance3CardsPlayer0 < 1,
                        value: bankerInsurance3CardsPlayer0,
                        set: setBankerInsurance3CardsPlayer0,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker2to6: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 2-6 Points',
                        error: () => bankerInsurance3CardsPlayer1AndBanker2to6 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker2to6,
                        set: setBankerInsurance3CardsPlayer1AndBanker2to6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker1: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 1 Point',
                        error: () => bankerInsurance3CardsPlayer1AndBanker1 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker1,
                        set: setBankerInsurance3CardsPlayer1AndBanker1,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer2: {
                        group: 'banker',
                        label: '3-Card Player 2 Points',
                        error: () => bankerInsurance3CardsPlayer2 < 1,
                        value: bankerInsurance3CardsPlayer2,
                        set: setBankerInsurance3CardsPlayer2,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer3: {
                        group: 'banker',
                        label: '3-Card Player 3 Points',
                        error: () => bankerInsurance3CardsPlayer3 < 1,
                        value: bankerInsurance3CardsPlayer3,
                        set: setBankerInsurance3CardsPlayer3,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                lucky6Slot: {
                    playerInsurance2CardsPlayer5: {
                        group: 'player',
                        label: '2-Card Player 5 Points',
                        error: () => playerInsurance2CardsPlayer5 < 1,
                        value: playerInsurance2CardsPlayer5,
                        set: setPlayerInsurance2CardsPlayer5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer6: {
                        group: 'player',
                        label: '2-Card Player 6 Points',
                        error: () => playerInsurance2CardsPlayer6 < 1,
                        value: playerInsurance2CardsPlayer6,
                        set: setPlayerInsurance2CardsPlayer6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer7: {
                        group: 'player',
                        label: '2-Card Player 7 Points',
                        error: () => playerInsurance2CardsPlayer7 < 1,
                        value: playerInsurance2CardsPlayer7,
                        set: setPlayerInsurance2CardsPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to4: {
                        group: 'player',
                        label: '3-Card Banker 0-4 Points',
                        error: () => playerInsurance3CardsBanker0to4 < 1,
                        value: playerInsurance3CardsBanker0to4,
                        set: setPlayerInsurance3CardsBanker0to4,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to5: {
                        group: 'player',
                        label: '3-Card Banker 0-5 Points',
                        error: () => playerInsurance3CardsBanker0to5 < 1,
                        value: playerInsurance3CardsBanker0to5,
                        set: setPlayerInsurance3CardsBanker0to5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer7: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 7 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer7 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer7,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer8: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 8 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer8 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer8,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer8,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer9: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 9 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer9 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer9,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer9,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker5: {
                        group: 'banker',
                        label: '2-Card Banker 5 Points',
                        error: () => bankerInsurance2CardsBanker5 < 1,
                        value: bankerInsurance2CardsBanker5,
                        set: setBankerInsurance2CardsBanker5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker6: {
                        group: 'banker',
                        label: '2-Card Banker 6 Points',
                        error: () => bankerInsurance2CardsBanker6 < 1,
                        value: bankerInsurance2CardsBanker6,
                        set: setBankerInsurance2CardsBanker6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker7: {
                        group: 'banker',
                        label: '2-Card Banker 5 Points',
                        error: () => bankerInsurance2CardsBanker7 < 1,
                        value: bankerInsurance2CardsBanker7,
                        set: setBankerInsurance2CardsBanker7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer0: {
                        group: 'banker',
                        label: '3-Card Player 0 Points',
                        error: () => bankerInsurance3CardsPlayer0 < 1,
                        value: bankerInsurance3CardsPlayer0,
                        set: setBankerInsurance3CardsPlayer0,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker2to6: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 2-6 Points',
                        error: () => bankerInsurance3CardsPlayer1AndBanker2to6 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker2to6,
                        set: setBankerInsurance3CardsPlayer1AndBanker2to6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker1: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 1 Point',
                        error: () => bankerInsurance3CardsPlayer1AndBanker1 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker1,
                        set: setBankerInsurance3CardsPlayer1AndBanker1,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer2: {
                        group: 'banker',
                        label: '3-Card Player 2 Points',
                        error: () => bankerInsurance3CardsPlayer2 < 1,
                        value: bankerInsurance3CardsPlayer2,
                        set: setBankerInsurance3CardsPlayer2,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer3: {
                        group: 'banker',
                        label: '3-Card Player 3 Points',
                        error: () => bankerInsurance3CardsPlayer3 < 1,
                        value: bankerInsurance3CardsPlayer3,
                        set: setBankerInsurance3CardsPlayer3,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                mega6: {
                    playerInsurance2CardsPlayer5: {
                        group: 'player',
                        label: '2-Card Player 5 Points',
                        error: () => playerInsurance2CardsPlayer5 < 1,
                        value: playerInsurance2CardsPlayer5,
                        set: setPlayerInsurance2CardsPlayer5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer6: {
                        group: 'player',
                        label: '2-Card Player 6 Points',
                        error: () => playerInsurance2CardsPlayer6 < 1,
                        value: playerInsurance2CardsPlayer6,
                        set: setPlayerInsurance2CardsPlayer6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer7: {
                        group: 'player',
                        label: '2-Card Player 7 Points',
                        error: () => playerInsurance2CardsPlayer7 < 1,
                        value: playerInsurance2CardsPlayer7,
                        set: setPlayerInsurance2CardsPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to4: {
                        group: 'player',
                        label: '3-Card Banker 0-4 Points',
                        error: () => playerInsurance3CardsBanker0to4 < 1,
                        value: playerInsurance3CardsBanker0to4,
                        set: setPlayerInsurance3CardsBanker0to4,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to5: {
                        group: 'player',
                        label: '3-Card Banker 0-5 Points',
                        error: () => playerInsurance3CardsBanker0to5 < 1,
                        value: playerInsurance3CardsBanker0to5,
                        set: setPlayerInsurance3CardsBanker0to5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer7: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 7 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer7 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer7,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer8: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 8 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer8 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer8,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer8,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer9: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 9 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer9 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer9,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer9,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker5: {
                        group: 'banker',
                        label: '2-Card Banker 5 Points',
                        error: () => bankerInsurance2CardsBanker5 < 1,
                        value: bankerInsurance2CardsBanker5,
                        set: setBankerInsurance2CardsBanker5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker6: {
                        group: 'banker',
                        label: '2-Card Banker 6 Points',
                        error: () => bankerInsurance2CardsBanker6 < 1,
                        value: bankerInsurance2CardsBanker6,
                        set: setBankerInsurance2CardsBanker6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker7: {
                        group: 'banker',
                        label: '2-Card Banker 7 Points',
                        error: () => bankerInsurance2CardsBanker7 < 1,
                        value: bankerInsurance2CardsBanker7,
                        set: setBankerInsurance2CardsBanker7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer0: {
                        group: 'banker',
                        label: '3-Card Player 0 Points',
                        error: () => bankerInsurance3CardsPlayer0 < 1,
                        value: bankerInsurance3CardsPlayer0,
                        set: setBankerInsurance3CardsPlayer0,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker2to6: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 2-6 Points',
                        error: () => bankerInsurance3CardsPlayer1AndBanker2to6 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker2to6,
                        set: setBankerInsurance3CardsPlayer1AndBanker2to6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker1: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 1 Point',
                        error: () => bankerInsurance3CardsPlayer1AndBanker1 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker1,
                        set: setBankerInsurance3CardsPlayer1AndBanker1,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer2: {
                        group: 'banker',
                        label: '3-Card Player 2 Points',
                        error: () => bankerInsurance3CardsPlayer2 < 1,
                        value: bankerInsurance3CardsPlayer2,
                        set: setBankerInsurance3CardsPlayer2,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer3: {
                        group: 'banker',
                        label: '3-Card Player 3 Points',
                        error: () => bankerInsurance3CardsPlayer3 < 1,
                        value: bankerInsurance3CardsPlayer3,
                        set: setBankerInsurance3CardsPlayer3,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                tiger: {
                    playerInsurance2CardsPlayer5: {
                        group: 'player',
                        label: '2-Card Player 5 Points',
                        error: () => playerInsurance2CardsPlayer5 < 1,
                        value: playerInsurance2CardsPlayer5,
                        set: setPlayerInsurance2CardsPlayer5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer6: {
                        group: 'player',
                        label: '2-Card Player 6 Points',
                        error: () => playerInsurance2CardsPlayer6 < 1,
                        value: playerInsurance2CardsPlayer6,
                        set: setPlayerInsurance2CardsPlayer6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance2CardsPlayer7: {
                        group: 'player',
                        label: '2-Card Player 7 Points',
                        error: () => playerInsurance2CardsPlayer7 < 1,
                        value: playerInsurance2CardsPlayer7,
                        set: setPlayerInsurance2CardsPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to4: {
                        group: 'player',
                        label: '3-Card Banker 0-4 Points',
                        error: () => playerInsurance3CardsBanker0to4 < 1,
                        value: playerInsurance3CardsBanker0to4,
                        set: setPlayerInsurance3CardsBanker0to4,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to5: {
                        group: 'player',
                        label: '3-Card Banker 0-5 Points',
                        error: () => playerInsurance3CardsBanker0to5 < 1,
                        value: playerInsurance3CardsBanker0to5,
                        set: setPlayerInsurance3CardsBanker0to5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer7: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 7 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer7 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer7,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer8: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 8 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer8 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer8,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer8,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerInsurance3CardsBanker0to6AndPlayer9: {
                        group: 'player',
                        label: '3-Card Banker 0-6 & Player 9 Points',
                        error: () => playerInsurance3CardsBanker0to6AndPlayer9 < 1,
                        value: playerInsurance3CardsBanker0to6AndPlayer9,
                        set: setPlayerInsurance3CardsBanker0to6AndPlayer9,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker5: {
                        group: 'banker',
                        label: '2-Card Banker 5 Points',
                        error: () => bankerInsurance2CardsBanker5 < 1,
                        value: bankerInsurance2CardsBanker5,
                        set: setBankerInsurance2CardsBanker5,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker6: {
                        group: 'banker',
                        label: '2-Card Banker 6 Points',
                        error: () => bankerInsurance2CardsBanker6 < 1,
                        value: bankerInsurance2CardsBanker6,
                        set: setBankerInsurance2CardsBanker6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance2CardsBanker7: {
                        group: 'banker',
                        label: '2-Card Banker 7 Points',
                        error: () => bankerInsurance2CardsBanker7 < 1,
                        value: bankerInsurance2CardsBanker7,
                        set: setBankerInsurance2CardsBanker7,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer0: {
                        group: 'banker',
                        label: '3-Card Player 0 Points',
                        error: () => bankerInsurance3CardsPlayer0 < 1,
                        value: bankerInsurance3CardsPlayer0,
                        set: setBankerInsurance3CardsPlayer0,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker2to6: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 2-6 Points',
                        error: () => bankerInsurance3CardsPlayer1AndBanker2to6 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker2to6,
                        set: setBankerInsurance3CardsPlayer1AndBanker2to6,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer1AndBanker1: {
                        group: 'banker',
                        label: '3-Card Player 1 Points & Banker 1 Point',
                        error: () => bankerInsurance3CardsPlayer1AndBanker1 < 1,
                        value: bankerInsurance3CardsPlayer1AndBanker1,
                        set: setBankerInsurance3CardsPlayer1AndBanker1,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer2: {
                        group: 'banker',
                        label: '3-Card Player 2 Points',
                        error: () => bankerInsurance3CardsPlayer2 < 1,
                        value: bankerInsurance3CardsPlayer2,
                        set: setBankerInsurance3CardsPlayer2,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerInsurance3CardsPlayer3: {
                        group: 'banker',
                        label: '3-Card Player 3 Points',
                        error: () => bankerInsurance3CardsPlayer3 < 1,
                        value: bankerInsurance3CardsPlayer3,
                        set: setBankerInsurance3CardsPlayer3,
                        message: 'Insurance payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
            },
        }
    }

    const groups = {
        banker: {
            label: 'Banker',
        },
        player: {
            label: 'Player',
            dividerTop: true
        }
    }

    const validation = validate(fields)
    const fieldsOfVariant = fields.betOdds[gameType][gameVariant]
    const isolatedFields = {
        betOdds: table.data.betOdds
    }
    const _fields = {
        betOdds: {
            [gameType]: {
                [gameVariant]: fieldsOfVariant
            }
        }
    }

    if (table.updated !== updated) {
        update(_fields, [], table.data, defaultValues)
        setUpdated(table.updated)
    }

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            try {
                setLoading(true)
                // need to do this since the same object is modified by another form
                const newFields = _.merge(isolatedFields, getFieldsValues(_fields))
                await publish({
                    ...table.data,
                    ...newFields,
                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
                setFail('')
                setChanged(0)
                setLoading(false)
            } catch (e) {
                setFail(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
            } finally {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (changed > 0 && template) {
            const newFields = _.merge(isolatedFields, getFieldsValues(_fields))
            changeCallback(newFields)
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                key='tablePayoutForm'
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </Box>
                )}

                {submitted && validation.error && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    </Box>
                )}

                <FieldsForm fields={fieldsOfVariant}
                    groups={groups}
                    changed={changed}
                    setChanged={setChanged}
                    submitted={submitted}
                    loading={loading} />

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={loading}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                            setDefaults(fields, defaultValues)
                            setChanged(changed + 1)
                        }}
                    >
                        Default
                    </Button>

                    {changed > 0 && (
                        <Button
                            variant='contained'
                            type='button'
                            color='primary'
                            disabled={loading}
                            onClick={send}
                        >
                            Update
                        </Button>
                    )}
                </div>}

            </form>
        </>
    )
}

export default Payouts_Insurance
