import React, { useState, useRef, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react' // the AG Grid React Component
import _ from 'lodash'
import moment from 'moment'
import { Pie } from 'react-chartjs-2'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Card, CardHeader, CardTitle, CardBody, Container, Row, Col, Progress } from 'reactstrap'
import Grid from '@mui/material/Grid'

import { useSubscribe, playerApi } from '../../api'
import Tree from '../../tree/Tree'
import useWindowSize from '../../WindowSize'
import {
    formatCountString,
    formatCashString,
    formatPresicePercentString,
    TimeDisplay,
    PlayersVersusBreakdown,
} from '../Formats'

import '../../ag-grid/ag-grid-theme.css' // Core grid CSS, always needed
import '../../ag-grid/ag-grid.css' // Core grid CSS, always needed

const styles = {
    backToListButton: {
        marginTop: 20,
        marginBottom: 20,
        float: 'right',
    },
    pieContainer: {
        width: 200,
        height: 100,
    },
    barContainer: {
        width: '-webkit-fill-available',
        height: 150,
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em',
    },
    cardTitleText: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        // color: 'white'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5vw 0.7vw',
    },
    cardBodyText: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
    cardSubtitle: {
        margin: '0 15px',
        color: 'darkgrey',
    },
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red',
    }),
    profileDetailsText: {
        padding: '0.9em',
    },
    profilePicture: {
        marginLeft: 25,
    },
    profileText: {
        padding: '0.5em !important',
    },
    profileRow: {
        marginBottom: '1em !important',
    },
    profileContainer: {
        maxWidth: 3000,
    },
    tabPane: {
        backgroundColor: 'transparent',
    },
    playerPicture: {
        maxWidth: '220px',
        maxHeight: '145px',
        minHeight: '145px',
        padding: '0.2em',
    },
}

const Players = () => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    // const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        statusSocket.readyState === WebSocket.OPEN

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')

    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }

    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // fetched data
    const [playersReport, setPlayersReport] = useState(null)
    const [fetchedPlayersReport, setFetchedPlayersReport] = useState(false)
    const [fetchedMemberships, setFetchedMemberships] = useState(false)
    const [memberships, setMemberships] = useState(null)
    const [minLoadTime, setMinLoadTime] = useState(false)
    const [progressLoad, setProgressLoad] = useState(0)

    // built charts
    const [builtCharts, setBuiltCharts] = useState({})
    // const setBuiltChartProxy = (chartID, value) => {
    //     setBuiltCharts(prevBuilt => ({
    //         ...prevBuilt,
    //         [chartID]: value
    //     }))
    // }

    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter((v) => v !== 'push')
        const oldParams = urlParams.toString()
        // console.log(oldParams)
        propKeys.forEach((k) => {
            if (props[k] === null) {
                urlParams.delete(k)
                window.localStorage.removeItem('url:' + k)
                return
            }
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange =
            propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        if (urlChange || props.push) {
            navigate('/xtable/players?' + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                setFetchedPlayersReport(false)
            }
            setBuiltCharts({})
        }
    }

    // GRID
    const gridRef = useRef() // Optional - for accessing Grid's API

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => {
        return {
            // flex: 1,
            minWidth: 10,
            filter: true,
            sortable: true,
            // floatingFilter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }
    }, [])

    // Example of consuming Grid Event
    const rowSelectHandler = (event) => {
        if (event.node.isSelected()) {
            console.log('navigate', event.data.id)
            navigate('/xtable/player/' + event.data.id + '/overview')
        }
    }
    // GRID END

    // const isMoreThanOneDay = numberOfDays > 0
    const isLive = startDate === startOfToday && endDate === endOfToday ? true : false
    // const isMoreThanOneDayOrNotLive = isMoreThanOneDay || (numberOfDays === 0 && !isLive)

    const dateChange = (start, end) => {
        if (start === startDate && end === endDate) return
        setStartDate(start)
        setEndDate(end)
        clearFetch({ startDate: start, endDate: end })
    }

    const getPlayersReport = async () => {
        if (fetchedPlayersReport) {
            return
        }

        setFetchedPlayersReport(true)
        setPlayersReport(null)
        try {
            setTimeout(() => {
                setProgressLoad(10)
            }, 100)
            setTimeout(() => {
                setProgressLoad(20)
            }, 200)
            setTimeout(() => {
                setProgressLoad(25)
            }, 300)
            setTimeout(() => {
                setProgressLoad(35)
            }, 400)
            const response = await playerApi
                .get('report/overview/players/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            console.log('r', startDate, endDate, response)
            setTimeout(() => {
                setProgressLoad(40)
            }, 100)
            setTimeout(() => {
                setProgressLoad(60)
            }, 200)
            setTimeout(() => {
                setProgressLoad(84)
            }, 300)
            setTimeout(() => {
                setProgressLoad(95)
            }, 400)
            setTimeout(() => {
                setPlayersReport(response)
            }, 500)
        } catch (e) {
            console.warn(e)
            setPlayersReport([])
        }
    }

    const getMemberships = async () => {
        if (fetchedMemberships) {
            return
        }

        setFetchedMemberships(true)
        try {
            const response = await playerApi.get('memberships').json()
            console.log('memberships', response)
            setMemberships(response)
        } catch (e) {
            console.warn(e)
            setMemberships([])
        }
    }

    getMemberships()

    if (memberships && Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        if (!fetchedPlayersReport) {
            clearFetch({})
        }
        getPlayersReport()
    }

    if (resizing && builtCharts && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    const treeLoading = !active || !zones || !pits || !devices || !tables || !status || !minLoadTime

    const overviewPlayers = playersReport
        ? playersReport.map((player) => ({
              id: player.id,
              Name: !player.membership.id ? '-' : player.membership.name,
              PlayerID:
                  player.membership.sessions && player.membership.sessions.includes(player.id)
                      ? player.membership.id
                      : player.id,
              ActiveNow: player.activeNow ? 'Y' : 'N',
              CardedLV: !player.membership.id ? '-' : player.membership.level,
              LastVisit: player.lastVisit,
              VisitsThisPeriod: player.numberOfVisits,
              TotalWager: player.bets,
              PlayersWinLoss: player.profit,
              PlayerWinPercent: player.bets > 0 ? player.profit / player.bets : 0,
              HouseWinLoss: player.profit * -1,
              GamesPlayed: player.games,
              BetQTY: player.betCount,
              AvgBet: player.games > 0 ? (player.bets / player.games).toFixed(0) : 0,
          }))
        : []

    const columnDefs = [
        {
            field: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            sort: 'desc',
            pinned: 'left',
            maxWidth: 120,
        },
        {
            field: 'PlayerID',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            maxWidth: 140,
        },
        {
            field: 'ActiveNow',
            hide: !isLive,
            maxWidth: 110,
        },
        {
            field: 'CardedLV',
            maxWidth: 120,
        },
        {
            field: 'LastVisit',
            filter: 'agDateColumnFilter',
            suppressMenu: true,
            hide: !isLive,
            maxWidth: 120,
            valueFormatter: function (params) {
                return TimeDisplay(params.value)
            },
        },
        {
            field: 'VisitsThisPeriod',
            headerName: '# of visits',
            filter: 'agNumberColumnFilter',
            maxWidth: 100,
        },
        {
            field: 'TotalWager',
            filter: 'agNumberColumnFilter',
            valueFormatter: function (params) {
                return formatCashString(params.value)
            },
            maxWidth: 120,
        },
        {
            field: 'PlayersWinLoss',
            filter: 'agNumberColumnFilter',
            cellStyle: (params) => styles.cashValue(params.value),
            valueFormatter: function (params) {
                return formatCashString(params.value)
            },
            maxWidth: 120,
        },
        {
            field: 'PlayerWinPercent',
            filter: 'agNumberColumnFilter',
            maxWidth: 110,
            valueFormatter: function (params) {
                return formatPresicePercentString(params.value)
            },
        },
        {
            field: 'HouseWinLoss',
            filter: 'agNumberColumnFilter',
            cellStyle: (params) => styles.cashValue(params.value),
            valueFormatter: function (params) {
                return formatCashString(params.value)
            },
            maxWidth: 120,
        },
        {
            field: 'GamesPlayed',
            filter: 'agNumberColumnFilter',
            valueFormatter: function (params) {
                return formatCountString(params.value)
            },
            maxWidth: 100,
        },
        {
            field: 'BetQTY',
            filter: 'agNumberColumnFilter',
            valueFormatter: function (params) {
                return formatCountString(params.value)
            },
            maxWidth: 100,
        },
        {
            field: 'AvgBet',
            filter: 'agNumberColumnFilter',
            valueFormatter: function (params) {
                return formatCashString(params.value)
            },
            maxWidth: 100,
        },
    ]

    const genderChartColor = ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)']

    const ageChartColor = [
        'rgba(82, 115, 242, 1)',
        'rgba(129, 89, 181, 1)',
        'rgba(52, 186, 184, 1)',
        'rgba(31, 144, 161, 0.9)',
        'rgba(217, 192, 196, 0.8)',
        'rgba(186, 11, 132, 0.9)',
        'rgba(214, 236, 27, 0.6)',
        'rgba(7, 244, 170, 1)',
        'rgba(254, 11, 11, 0.9)',
    ]

    const gender = [
        {
            label: 'Male',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if ((cV.membership && cV.membership.gender === 'Male') || cV.gender === 'M') {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: 'Female',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if ((cV.membership && cV.membership.gender === 'Female') || cV.gender === 'F') {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
    ]

    const age = [
        {
            label: '18-26',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 18 && cV.membership.age <= 26) ||
                          (cV.age >= 18 && cV.age <= 26)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: '27-35',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 27 && cV.membership.age <= 35) ||
                          (cV.age >= 27 && cV.age <= 35)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: '36-44',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 36 && cV.membership.age <= 44) ||
                          (cV.age >= 36 && cV.age <= 44)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: '45-53',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 45 && cV.membership.age <= 53) ||
                          (cV.age >= 45 && cV.age <= 53)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: '54-62',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 54 && cV.membership.age <= 62) ||
                          (cV.age >= 54 && cV.age <= 62)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: '63-71',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 63 && cV.membership.age <= 71) ||
                          (cV.age >= 63 && cV.age <= 71)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: '72-80',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 72 && cV.membership.age <= 80) ||
                          (cV.age >= 72 && cV.age <= 80)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: '81-89',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 81 && cV.membership.age <= 89) ||
                          (cV.age >= 81 && cV.age <= 89)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
        {
            label: '90-98',
            value: playersReport
                ? playersReport.reduce((a, cV) => {
                      if (
                          (cV.membership && cV.membership.age >= 90 && cV.membership.age <= 98) ||
                          (cV.age >= 90 && cV.age <= 98)
                      ) {
                          return a + 1
                      }

                      return a
                  }, 0)
                : 0,
        },
    ]

    const getVesusObj = (data) =>
        data.map((v) => {
            return {
                label: v.label,
                value: v.value,
                percentage: formatPresicePercentString(v.value / data.reduce((acc, v) => acc + v.value, 0)),
            }
        })

    // display data
    const totalWinLoss =
        !playersReport || !playersReport.reduce ? 0 : playersReport.reduce((prev, next) => prev + next.profit, 0)
    const totalWager =
        !playersReport || !playersReport.reduce ? 0 : playersReport.reduce((prev, next) => prev + next.bets, 0)

    const fluid = width < 1900

    if (settings && !settings.data.xtable.sections.includes('players')) {
        return <Navigate to='/dashboard' />
    }

    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: 320 }}>
                <Tree
                    loading={treeLoading}
                    noAutoUpdate={true}
                    dateChange={dateChange}
                    startDate={startDate}
                    endDate={endDate}
                    fluid={fluid}
                    hideLocation
                />
            </Grid>
            <Grid item xs>
                {!playersReport && (
                    <Container fluid={fluid}>
                        <Row xs='1'>
                            <Col>
                                <h3>Fetching players report...</h3>
                                <Progress value={progressLoad} />
                            </Col>
                        </Row>
                    </Container>
                )}
                {playersReport && (
                    <Container fluid={fluid}>
                        <Row xs=''>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}># of Players</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span style={styles.cardBodyText}>{playersReport.length}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>Total Wager</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span style={styles.cardBodyText}>{formatCashString(totalWager)}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>House Total win/loss</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span
                                            style={{
                                                ...styles.cardBodyText,
                                                ...styles.cashValue(totalWinLoss * -1),
                                            }}
                                        >
                                            {formatCashString(totalWinLoss * -1)}
                                        </span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>Player Total win/loss</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span
                                            style={{
                                                ...styles.cardBodyText,
                                                ...styles.cashValue(totalWinLoss),
                                            }}
                                        >
                                            {formatCashString(totalWinLoss)}
                                        </span>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Players</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div
                                            className='ag-theme-balham-dark'
                                            style={{
                                                width: '-webkit-fill-available',
                                                height: 'calc(100vh - 460px)',
                                                colorScheme: 'dark',
                                            }}
                                        >
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={overviewPlayers}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                animateRows={true}
                                                rowSelection={'single'}
                                                onRowSelected={rowSelectHandler}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row xs='3'>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>Gender</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        {!_.isEmpty(playersReport) && (
                                            <div styles={styles.pieContainer}>
                                                <Pie
                                                    data={{
                                                        labels: ['Male', 'Female'],
                                                        datasets: [
                                                            {
                                                                label: 'Gender',
                                                                data: gender.map((v) => v.value),
                                                                backgroundColor: genderChartColor,
                                                                borderColor: genderChartColor,
                                                                borderWidth: 1,
                                                            },
                                                        ],
                                                    }}
                                                    options={{
                                                        plugins: {
                                                            legend: false,
                                                        },
                                                        responsive: true,
                                                        maintainAspectRatio: true,
                                                    }}
                                                />

                                                <PlayersVersusBreakdown
                                                    data={getVesusObj(gender)}
                                                    labelColors={genderChartColor}
                                                />
                                            </div>
                                        )}
                                        {!playersReport && (
                                            <div>
                                                <h4>No data</h4>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>Age</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        {!_.isEmpty(playersReport) && (
                                            <div styles={styles.pieContainer}>
                                                <Pie
                                                    data={{
                                                        labels: [
                                                            '18-26',
                                                            '27-35',
                                                            '36-44',
                                                            '45-53',
                                                            '54-62',
                                                            '63-71',
                                                            '72-80',
                                                            '81-89',
                                                            '90-98',
                                                        ],
                                                        datasets: [
                                                            {
                                                                label: 'Age',
                                                                data: age.map((v) => v.value),
                                                                backgroundColor: ageChartColor,
                                                                borderColor: ageChartColor,
                                                                borderWidth: 1,
                                                            },
                                                        ],
                                                    }}
                                                    options={{
                                                        plugins: {
                                                            legend: false,
                                                        },
                                                        responsive: true,
                                                        maintainAspectRatio: true,
                                                    }}
                                                />

                                                <PlayersVersusBreakdown
                                                    data={getVesusObj(age)}
                                                    labelColors={ageChartColor}
                                                />
                                            </div>
                                        )}
                                        {!playersReport && (
                                            <div>
                                                <h4>No data</h4>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            </Grid>
        </Grid>
    )
}

export default Players
