import React from 'react'
// import Lottie from 'react-lottie'
// import taco from './gopher.json'

const Loader = () => {
    // const defaultOptions = {
    //   loop: true,
    //   autoplay: true,

    //   animationData: taco,
    //   rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice'
    //   }
    // }

    return (
        <div>
            {/* <Lottie options={defaultOptions}
      height={400}
      width={400}
      isStopped={false}
      isPaused={false} /> */}
            Loading...
        </div>
    )
}
export default Loader