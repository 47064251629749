import React from 'react'
import { get } from 'lodash'

import { containerTypeColor } from '../util/ContainerTypeColor'
import useWindowSize from '../WindowSize'

const Breadcrums = ({ zones, pits, tables, devices, selected, levels = null, size = 17, xstadium }) => {
    const [width, ,] = useWindowSize()
    const key = {
        device: { o: devices, p: 'table', c: containerTypeColor.device },
        table: { o: tables, p: 'pit', c: containerTypeColor.table },
        pit: { o: pits, p: 'zone', c: containerTypeColor.pit },
        zone: { o: zones, p: 'casino', c: containerTypeColor.zone },
        casino: { o: [], p: null, c: containerTypeColor.casino },
    }

    const getBreadcrumbsArray = (type, index) => {
        const obj = key[type.toLowerCase()]
        // console.log("GBC", obj, type, index)
        const dataObj = obj ? obj.o.filter((v) => v.index === index) : {}
        const name = get(dataObj, ['0', 'data', 'name'], null)
        const containerIndex = get(dataObj, ['0', 'data', 'containerIndex'], null)
        const containerType = get(dataObj, ['0', 'data', 'containerType'], obj.p)
        return containerType
            ? [
                  getBreadcrumbsArray(containerType, containerIndex),
                  {
                      name: name,
                      type: type.toLowerCase(),
                      c: obj.c,
                  },
              ].flat()
            : [
                  {
                      name: 'Casino',
                      type: 'casino',
                      c: obj.c,
                  },
              ]
    }

    const breadCrumbs = levels
        ? getBreadcrumbsArray(selected.type, selected.index).slice(0 - levels)
        : getBreadcrumbsArray(selected.type, selected.index)

    const xstadiumBreadcrumbs = breadCrumbs.filter((breadcrumb) => breadcrumb.type === 'device')

    const getBreadcrumbs = () => {
        return xstadium ? xstadiumBreadcrumbs : breadCrumbs
    }

    // console.log("BC", breadCrumbs, selected)
    const maxWidth = width < 1160 ? 130 : 160
    return (
        <>
            {getBreadcrumbs() &&
                getBreadcrumbs().map((v, i) => {
                    if ((getBreadcrumbs().length > 1 && v.name === 'Casino') || !v.name) {
                        return null
                    }
                    return (
                        <span key={i}>
                            <h2 style={{ display: 'inline-block', fontSize: size, marginRight: 2 }}>
                                <div
                                    style={{
                                        height: 22,
                                        lineHeight: '22px',
                                        backgroundColor: v.c,
                                        color: 'white',
                                        margin: 3,
                                        marginRight: 0,
                                        marginTop: 0,
                                        marginBottom: 0,
                                        borderRadius: 10,
                                        position: 'relative',
                                        boxSizing: 'content-box',
                                        padding: '7px 15px',
                                        maxWidth: maxWidth,
                                        width: maxWidth,
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {v.name}
                                </div>
                            </h2>
                        </span>
                    )
                })}
        </>
    )
}

export default Breadcrums
