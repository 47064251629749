import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { withStyles, makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { useSubscribe, xstadiumApi } from '../../api'
import { xstadiumIP } from '../../config'

const CustomTab = withStyles({
    root: {
        backgroundColor: '#343434',
        borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
    },
    selected: {
        backgroundColor: 'rgb(0, 161, 255)',
    },
})(Tab)

const rootStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        padding: '2rem',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
    },
    warningIcon: {
        color: '#f0cf81',
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: '100',
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.2em',
    },
    toggleWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))

const Support = () => {
    const styles = rootStyles({})
    const [status, statusSocket] = useSubscribe(xstadiumIP + '/watchout/status')
    const [loading, setLoading] = useState(false)


    // dialog
    const [dialogClearToday, setDialogClearToday] = useState(false)
    const [sendingClear, setSendingClear] = useState(false)

    const active = statusSocket &&
        statusSocket.readyState === WebSocket.OPEN

    const sendPause = async () => {
        setLoading(true)
        try {
            await xstadiumApi.get("watchout/pause")
        } catch (e) {
            console.warn(e)
        }
        setLoading(false)
    }

    const sendResume = async () => {
        setLoading(true)
        try {
            await xstadiumApi.get("watchout/resume")
        } catch (e) {
            console.warn(e)
        }
        setLoading(false)
    }

    const openClearTodayDialog = () => {
        setDialogClearToday(true)
    }

    const closeClearTodayDialog = () => {
        setDialogClearToday(false)
    }

    const sendClearToday = async () => {
        setSendingClear(true)
        try {
            await xstadiumApi.get('schedule/clear')
        } catch (e) {
            console.warn('failed to send clear today', e)
        } finally {
            setSendingClear(false)
            closeClearTodayDialog()
        }
    }

    if (!active ||
        !status) {
        return <LinearProgress />
    }

    const canPause = !loading && status.data.online && !status.data.paused
    const canResume = !loading && !status.data.online && status.data.paused

    return (<>
        <Dialog open={dialogClearToday}>
            <DialogTitle>Stop Today's Scheduled Playlist</DialogTitle>
            <DialogContent sx={{ overflowY: 'visible' }}>
                This will stop and clear today's scheduled playlist. New playlist cannot be set for today. Only manual playing is available.
            </DialogContent>
            <DialogActions>
                <Button onClick={closeClearTodayDialog} >
                    Cancel
                </Button>
                <Button color='primary' onClick={sendClearToday} disabled={sendingClear}>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
            <Grid item xs>
                <form
                    className={styles.form}
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault()
                    }}
                    autoComplete='off'
                >
                    <Box display='flex' flexDirection='column' gap='1.25rem'>
                        <Box>
                            <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                                Settings
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                Select tab to configure the corresponding features of the application
                            </Typography>
                        </Box>

                        <Tabs
                            sx={{
                                background: '#2a2a2a',
                                borderBottom: '2px solid rgb(0, 161, 255)',
                            }}
                            value={1}
                            textColor='inherit'
                            indicatorColor='rgb(0, 161, 255)'
                        >
                            <CustomTab
                                label='Default Playlist'
                                component={Link}
                                to={'/xstadium/settings/defaultplaylist'}
                            />
                            <CustomTab label='Support' component={Link} to={'/xstadium/settings/support'} />
                            <CustomTab label='Volumes' component={Link} to={'/xstadium/settings/volumes'} />
                            <CustomTab label='Device Animations' component={Link} to={'/xstadium/settings/screener'} />
                        </Tabs>

                        <Box width='50%' display='flex' flexDirection='column' gap='1.5rem'>
                            <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                Root Controls
                            </Typography>
                            {canPause && <Box>
                                <Button color='error' style={{ width: '24em' }} onClick={openClearTodayDialog}>STOP TODAY'S SCHEDULED PLAYLIST</Button>
                            </Box>}
                            <Box>
                                <Box className={styles.toggleWrapper} component='label'>
                                    <Typography>XStadium Watchout Connection</Typography>
                                    {canPause && <Button onClick={sendPause}>Pause</Button>}
                                    {canResume && <Button onClick={sendResume}>Resume</Button>}
                                </Box>

                                <Typography variant='body1' sx={{ display: 'flex' }}>
                                    Connection Status&nbsp;
                                    {status.data.online ? (
                                        <Typography color='#00ff00'>Connected</Typography>
                                    ) : (
                                        <Typography color='#ff1111'>Disconnected</Typography>
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </form>
            </Grid>
        </Grid>
    </>)
}

export default Support
