import React, { useState, useContext, useRef } from "react";
import { find, get, sum, values } from "lodash";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import {
    terminalServerIP,
    ticketsIP,
    chaseTheTrendGameIPChipless,
    chaseTheTrendGameIPChipped,
    dMods,
} from "../../config";
import { formatCashString } from "../util/misc";
import BetTimer from "./BetTimer";
import { DUIContext } from "./Dealer";
import DealerConfirmation from "./DealerConfirmation";

const DealerSideMenu = ({ time }) => {
    const {
        authorize,
        bets,
        cancelEditCards,
        chasetrend,
        confirmEditPress,
        device,
        deviceData,
        deviceUpdated,
        editCardsPress,
        EODButtonPress,
        game,
        getBalance,
        getClassName,
        getMemberStatus,
        getSessionID,
        hasGameState,
        inEditMode,
        isVisible,
        menuXCloseBtn,
        noMoreBetsPress,
        removeCardPress,
        resetSeatPlayerSelection,
        restartBetTimerPress,
        s,
        selectedSeatID,
        setCTTGButtonTriggered,
        setShowCashTopUp,
        setShowChipTopUp,
        setShowTableFillCredit,
        setShowCrowdPlayerScan,
        setShowSeatPlayersMenu,
        setShowVoucherTopUp,
        showConfirmEdit,
        showCTTGButton,
        showMenu,
        showRemoveCard,
        showSeatPlayersMenu,
        stopBetTimerPress,
        t,
        turnOffDevicePress,
        voidGame,
        voidLastGame,
        isGameVoided,
        wipeTrendboardPress,
        dialogObj,
        setDialogObj,
        settings
    } = useContext(DUIContext)
    const [modalObj, setModalObj] = useState({ show: false, modalMsg: '', requirePitBoss: false, confirm: () => { } })
    const [moreOptions, setMoreOptions] = useState(false)
    const cashOutVBtnRef = useRef()
    const cashOutWBtnRef = useRef()
    const selectedSessionID = getSessionID(selectedSeatID)

    const LEDControls = async (action) => {
        let url = ''
        if (action === 'green') url = 'http://' + window.location.hostname + ':3099/led/green'
        if (action === 'red') url = 'http://' + window.location.hostname + ':3099/led/red'
        if (action === 'yellow') url = 'http://' + window.location.hostname + ':3099/led/on'
        if (action === 'off') url = 'http://' + window.location.hostname + ':3099/led/off'

        try {
            const response = await fetch(url)
            console.log('led control response', response.status, response)
        } catch (e) {
            console.log('failed to control led:', e)
        }
    }

    const endPlayerSession = async () => {
        try {
            const res = await fetch('http://' + terminalServerIP + '/session/end/' + device.index + '/' + selectedSeatID)
            if (res.status !== 200) throw new Error('Failed To End Session: bad response code:' + res.status)
            console.log('End Session Successs', res.status, res)
        } catch (e) {
            console.log('End Session Fail', e)
        }
    }

    const cashOutVoucher = async () => {
        const selectedPlayerCreditAmount = getBalance(selectedSeatID)
        setModalObj({
            show: true,
            modalMsg: 'Cashout Seat: ' + (Number(selectedSeatID) + 1) + '. Amount: $' + formatCashString(selectedPlayerCreditAmount),
            confirm: async () => {
                try {
                    if (cashOutVBtnRef.current) cashOutVBtnRef.current.classList.add('disabled')
                    const res1 = await fetch('http://' + terminalServerIP + '/credits/seat/' + device.index + '/' + selectedSeatID, {
                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                        headers: {},
                        body: JSON.stringify({
                            balance: 0 - selectedPlayerCreditAmount
                        })
                    })
                    if (res1.status !== 200) throw new Error('Failed To Deduct Credits: bad response code:' + res1.status)
                    console.log('deduct success', res1.status, res1)
                    endPlayerSession()
                    const res2 = await fetch('http://' + ticketsIP + '/ticket/print/' + device.index + '/' + selectedPlayerCreditAmount + '/' + selectedSeatID)
                    if (res2.status !== 200) throw new Error('Failed To Print Ticket: bad response code:' + res2.status)
                    console.log('cash out success', res2.status, res2)
                    if (cashOutVBtnRef.current) cashOutVBtnRef.current.classList.add('disabled')
                } catch (e) {
                    console.log('cash out fail:', e)
                    if (cashOutVBtnRef.current) cashOutVBtnRef.current.classList.add('disabled')
                }

            }
        })
    }

    const triggerPlayTutorial = async () => {
        const chaseTheTrendGameIP = dMods.has_chase_trend_game_chipless ? chaseTheTrendGameIPChipless : chaseTheTrendGameIPChipped

        try {
            const token = window.localStorage.getItem('token')
            const url1 = 'http://' + chaseTheTrendGameIP + '/tutorial/' + device.index
            await fetch(url1, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

        } catch (e) {
            if (e && e.response && (e.response.status === 401 || e.response.status === 403)) {
                try {
                    await authorize(e)
                    triggerPlayTutorial()
                } catch (e) {
                    console.log('failed to refresh token', e)
                }
            } else {
                console.log('failed to toggle tutorial', e)
            }
        }

    }

    const canCashoutVoucher = () => {
        if (getBalance(selectedSeatID) === 0) return false
        //if player has an ongoing bet in CTG dont allow cashout
        if (dMods.has_chase_trend_game || dMods.has_chase_trend_game_chipless) {
            const CTGBets = get(chasetrend, 'data.bets', []) || []
            if (find(CTGBets, { player: selectedSeatID, cashOutGame: 0 })) return false
        }
        //if the player has bets placed on the table (chipless)
        if (bets) {
            const found = find(bets, { data: { player: selectedSeatID } }) || null
            if (found && sum(values(found.data.bets)) > 0) {
                return false
            }
        }
        return true
    }

    const canCashoutWallet = () => {
        if (getBalance(selectedSeatID) === 0) return false
        if (!getMemberStatus(selectedSeatID)) return false
        //if player has an ongoing bet in CTG dont allow cashout 
        if (dMods.has_chase_trend_game || dMods.has_chase_trend_game_chipless) {
            const CTGBets = get(chasetrend, 'data.bets', []) || []
            if (find(CTGBets, { player: selectedSeatID, cashOutGame: 0 })) return false
        }
        //if the player has bets placed on the table (chipless)
        if (bets) {
            const found = find(bets, { data: { player: selectedSeatID } }) || null
            if (found && sum(values(found.data.bets)) > 0) {
                return false
            }
        }
        return true
    }

    const canWipeTrendBoard = () => {
        //if CTD and at least one bet uncashed then disallow
        if (dMods.has_chase_trend_game || dMods.has_chase_trend_game_chipless) {
            const CTGBets = get(chasetrend, 'data.bets', []) || []
            if (find(CTGBets, { cashOutGame: 0 })) return false
        }
        return true
    }
    const canCloseTable = canWipeTrendBoard


    const betTimerFeatures = {
        start: (deviceData.timerModeChipless && !deviceData.timerModeDisabled) ? true : false,
        restart: (deviceData.timerModeChipped && !deviceData.timerModeDisabled) ? true : false,
        stop: (deviceData.timerModeChipped && !deviceData.timerModeDisabled) ? true : false,
        noMoreBets: (deviceData.timerModeChipped || !deviceData.timerModeDisabled) ? true : false,
        countdown: (!deviceData.timerModeDisabled) ? true : false,
    }

    function BetTimerButton() {
        // betTimer state obj will render every second on a countdown, so this btn requires own component            
        const { betTimer, game, restartBetTimerPress } = useContext(DUIContext)
        return <li className={(hasGameState && game.data.betsoff) || ['started', 'stopped', 'counting'].includes(betTimer.status) ? "disabled" : ""} onClick={() => {
            restartBetTimerPress()
        }}> {"Start Bet Timer"} </li>
    }

    const showMainMenu = !inEditMode && !showSeatPlayersMenu && !moreOptions
    const hasOnGoingGame = hasGameState && game.data.status === "ongoing"


    return (
        <div className={getClassName(s.border, isVisible(showMenu))} style={{ width: 'min(20%,250px)', minWidth: 'min(20%,250px)' }}>
            {showMainMenu && <ul className={s.menu}>
                <li onClick={() => menuXCloseBtn()}>{"Hide Menu"}</li>
                <li onClick={() => setMoreOptions(true)}>{"More Options"}</li>
                {deviceData.allowEditCards && hasOnGoingGame && <li className={(inEditMode) ? s.menuBtnSelected : ''} onClick={() => editCardsPress()}>{t('dealer.editCards')}</li>}
                {betTimerFeatures.start && !hasOnGoingGame && <BetTimerButton />}
                {betTimerFeatures.restart && !hasOnGoingGame && <li className={(hasGameState && game.data.betsoff) ? "disabled" : ""} onClick={() => restartBetTimerPress()}>{t('dealer.restartBetTimer')}</li>}
                {betTimerFeatures.stop && !hasOnGoingGame && <li className={(hasGameState && game.data.betsoff) ? "disabled" : ""} onClick={() => stopBetTimerPress()}>{t('dealer.stopBetTimer')}</li>}
                {!hasOnGoingGame && <li className={(hasGameState && game.data.betsoff) || !betTimerFeatures.noMoreBets ? "disabled" : ""} onClick={betTimerFeatures.noMoreBets ? () => noMoreBetsPress() : () => { }}>
                    {"NO MORE BETS"}
                    {betTimerFeatures.countdown && <BetTimer time={time} noMoreBetsPress={noMoreBetsPress} device={device} deviceData={deviceData} deviceUpdated={deviceUpdated} game={game} />}
                </li>}
                {settings && !settings.data.xview.gameOnlyMode && <li onClick={() => setShowSeatPlayersMenu(true)}>{"Seat Players"}</li>}
                {dMods.show_top_up_crowd_btn && <li onClick={() => setShowCrowdPlayerScan(true)}>{"Crowd Players"}</li>}
                {(dMods.has_chase_trend_game || dMods.has_chase_trend_game_chipless) && hasGameState && game.data.status === 'done' && showCTTGButton && <li onClick={() => setCTTGButtonTriggered(true)}>
                    {"Chase The Trend"}
                    <br />{"Game"}
                </li>}
                {(dMods.has_chase_trend_game || dMods.has_chase_trend_game_chipless) && <li onClick={() => triggerPlayTutorial()}>
                    <div>
                        <PlayArrowIcon />
                        <PauseIcon />
                        {" Video Tutorial"}
                    </div>
                </li>}
            </ul>}

            {inEditMode && <ul className={s.menu}>
                <li onClick={() => cancelEditCards()}>Back</li>
                {showRemoveCard && <li onClick={() => removeCardPress()}>{"Remove Card"}</li>}
                {showConfirmEdit && <li onClick={() => confirmEditPress()}>{"Confirm Edit"}</li>}
            </ul>}

            {showSeatPlayersMenu && <ul className={s.menu}>
                <li onClick={() => { resetSeatPlayerSelection(); setShowSeatPlayersMenu(false) }}>{"Back"}</li>
                {(dMods.show_top_up_seat_btns && selectedSessionID) && <li onClick={() => setShowCashTopUp(true)}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontSize: '2.3vh' }}>{"Top-Up"}</div>
                        <div style={{ fontSize: '3.6vh' }}>{"Cash"}</div>
                    </div>
                </li>}
                {(dMods.show_top_up_seat_btns && selectedSessionID) && <li onClick={() => setShowVoucherTopUp(true)}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontSize: '2.3vh' }}>{"Top-Up"}</div>
                        <div style={{ fontSize: '3.6vh' }}>{"Voucher"}</div>
                    </div>
                </li>}
                {(dMods.show_top_up_seat_btns && selectedSessionID) && <li onClick={() => setShowChipTopUp(true)}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontSize: '2.3vh' }}>{"Top-Up"}</div>
                        <div style={{ fontSize: '3.6vh' }}>{"Chips"}</div>
                    </div>
                </li>}
                {(dMods.show_cash_out_voucher_btn && selectedSessionID) && <li className={canCashoutVoucher() ? "" : "disabled"} ref={cashOutVBtnRef} onClick={() => cashOutVoucher()}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>{"Cashout Voucher"}</div>
                </li>}
                {(dMods.show_cash_out_wallet_btn && selectedSessionID) && <li className={canCashoutWallet() ? "" : "disabled"} ref={cashOutWBtnRef} onClick={() => { }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>{"Cashout Wallet"}</div>
                </li>}
                {(dMods.show_end_session_btn && selectedSessionID) && <li onClick={() => endPlayerSession()}>{"End Session"}</li>}
            </ul>}

            {moreOptions && <ul className={s.menu}>
                <li onClick={() => setMoreOptions(false)}>{"Back"}</li>
                {!isGameVoided && game.data.status !== "done" && <li onClick={() => voidGame()}>{t('dealer.voidGame')}</li>}
                {!isGameVoided && game.data.status === "done" && <li onClick={() => voidLastGame()}>{t('dealer.voidLastGame')}</li>}
                {dMods.show_support_led_btns && <li onClick={() => LEDControls('green')} style={{ color: 'limegreen' }}>{"Customer Service"}</li>}
                {dMods.show_support_led_btns && <li onClick={() => LEDControls('red')} style={{ color: 'red' }}>{"Manager Support"}</li>}
                {dMods.show_support_led_btns && <li onClick={() => LEDControls('yellow')} style={{ color: 'yellow' }}>{"Pitboss / Supervisor"}</li>}
                {dMods.show_support_led_btns && <li onClick={() => LEDControls('off')}>{"LED OFF"}</li>}
                {!hasOnGoingGame && <li onClick={() => { turnOffDevicePress() }} className={canCloseTable() ? "" : "disabled"}> {t('dealer.closeTable')}</li>}
                {!hasOnGoingGame && <li onClick={() => wipeTrendboardPress()} className={canWipeTrendBoard() ? "" : "disabled"} >{t('dealer.wipeTrendboard')}</li>}
                {settings && !settings.data.xview.gameOnlyMode && !hasOnGoingGame && <li onClick={() => { setShowTableFillCredit(true); setDialogObj({ ...dialogObj, mode: "" }) }}>{t("dealer.tableFillCredit")}</li>}
                {dMods.show_eod_summary_btn && <li onClick={() => EODButtonPress()}>{'EOD Summary'}</li>}
            </ul>}

            {modalObj.show && <DealerConfirmation modalObj={modalObj} setModalObj={setModalObj} />}
        </div>
    )
}

export default DealerSideMenu