const i18n = {
  resources: {
    en: {
      common: {
        global: {
          cancel: "CANCEL",
          confirm: "CONFIRM",
          language: "LANGUAGE",
          languageLowercase: "Language 语言",
          en: "ENGLISH / 英语",
          zh_HK: "TRADITIONAL CHINESE / 简体中文",
          zh_CN: "SIMPLIFIED CHINESE / 繁体中文",
          english: "English / 英语",
          simplifiedChinese: "Simplified Chinese / 简体中文",
          traditionalChinese: "Traditional Chinese / 繁体中文",
        },
        dealer: {
          close: "CLOSE",
          showMenu: "SHOW MENU",
          hideMenu: "HIDE MENU",
          burnCards: "BURN CARDS",
          overdrewCards: "OVERDREW CARDS",
          editCards: "EDIT CARDS",
          editResults: "EDIT RESULTS",
          voidGame: "VOID GAME",
          voidLastGame: "VOID LAST GAME",
          wipeTrendboard: "DECK CHANGE",
          restartBetTimer: "RESTART BET TIMER",
          stopBetTimer: "STOP BET TIMER",
          betTimerStopped: "BET TIMER STOPPED!",
          logOut: "LOG OUT",
          wipeTrendboardConfirmation: "DECK CHANGE CONFIRMATION",
          wipeTrendboardPrompt: "ARE YOU SURE YOU WISH TO DECK CHANGE?",
          wipe: "WIPE",
          wipeDoubleConfirmation:
            "WIPE TRENDBOARD REQUIRES PIT BOSS CONFIRMATION.",
          pleaseSwipe:
            "PLEASE SWIPE PIT BOSS STAFF CARD OR ENTER ACCOUNT/PASSWORD",
          burnCardsConfirmation: "BURN CARDS CONFIRMATION",
          autoBurnPrompt:
            "PLEASE DRAW A CARD TO DETERMINE NUMBER OF CARDS TO BURN",
          burnPrompt: "NUMBER OF CARDS LEFT TO BURN:",
          done: "DONE",
          overdrewCardsConfirmation: "OVERDREW CARDS CONFIRMATION",
          overdrewCardsPrompt: "NUMBER OF CARDS OVERDRAWN",
          voidGameConfirmation: "VOID GAME CONFIRMATION",
          voidGamePrompt: "ARE YOU SURE YOU WISH TO VOID THIS GAME?",
          voidGameDoubleConfirmation:
            "VOID GAME REQUIRES PIT BOSS CONFIRMATION.",
          voidLastGameConfirmation: "VOID LAST GAME CONFIRMATION",
          voidLastGamePrompt: "ARE YOU SURE YOU WISH TO VOID LAST GAME?",
          voidLastGameDoubleConfirmation:
            "VOID LAST GAME REQUIRES PIT BOSS CONFIRMATION.",
          editCardsConfirmation: "EDIT CARDS CONFIRMATION",
          editCardsPrompt: "ARE YOU SURE YOU WISH TO EDIT CARDS?",
          editCardsInvalid:
            "The game cannot be ended with these cards! Please adjust cards.",
          illegalPlayerThird:
            "Your Player's third card is illegal, please remove!",
          illegalBankerThird:
            "Your Banker's third card is illegal, please remove!",
          editCardsDoubleConfirmation:
            "EDIT CARDS REQUIRES PIT BOSS CONFIRMATION",
          closeTable: "CLOSE TABLE",
          closeTablePrompt: "ARE YOU SURE YOU WISH TO CLOSE YOUR TABLE?",
          openTable: "OPEN TABLE",
          openTablePrompt: "OPEN TABLE TO CONTINUE",
          open: "OPEN",
          logOutPrompt: "ARE YOU SURE YOU WISH TO LOG OUT?",
          tableDeleted: "TABLE DOES NOT EXIST!",
          tableDeletedPrompt:
            "THIS TABLE WAS DELETED, PLEASE REINITIALISE TO ANOTHER TABLE",
          reinitialise: "REINITIALISE",
          banker: "BANKER",
          points: "points",
          player: "PLAYER",
          finalizeEditedCards: "FINALIZE EDITED CARDS",
          bankerWinPredict: "RESULT WILL BE BANKER WIN",
          tiePredict: "RESULT WILL BE TIE",
          playerWinPredict: "RESULT WILL BE PLAYER WIN",
          confirmEditCard: "CONFIRM EDIT CARD",
          removeCard: "REMOVE CARD",
          invalidGame: "INVALID GAME!",
          bankerWin: "BANKER WIN",
          tie: "TIE",
          playerWin: "PLAYER WIN",
          forceBankerWin: "FORCE BANKER WIN",
          forceTie: "FORCE TIE",
          forcePlayerWin: "FORCE PLAYER WIN",
          pair: "PAIR",
          lucky6: "LUCKY 6",
          natural8: "NATURAL 8",
          natural9: "NATURAL 9",
          editResultConfirmation: "EDIT RESULT CONFIRMATION",
          editResultDoubleConfirmation:
            "EDIT RESULT REQUIRES PIT BOSS CONFIRMATION.",
          editResultTo: "EDIT RESULT TO:",
          end: "END",
          game: "GAME",
          reveal: "REVEAL",
          playerId: "Player ID",
          started: "Started Time",
          memberId: "Member ID",
          name: "Name",
          memberLevel: "Member Level",
          gender: "Gender",
          unbind: "Clear VIP",
          cashdrop: "Cash Drop",
          tableFillCredit: "Table Chip Fill/Credit",
        },
        manager: {
          tableConfig: "Device Config",
          devices: "Devices",
          game: "GAME",
          tableIs: "TABLE IS",
          deviceStatus: "DEVICE STATUS",
          online: "ONLINE",
          offline: "OFFLINE",
          open: "OPEN",
          close: "CLOSE",
          trendboardStatus: "TRENDBOARD STATUS",
          minBet: "MIN BET",
          maxBet: "MAX BET",
          back: "BACK",
          rebootDevice: "REBOOT DEVICE CONFIRMATION",
          shutdownDevice: "SHUTDOWN DEVICE CONFIRMATION",
          restartTrendboard: "RESTART TRENDBOARD CONFIRMATION",
          restartServer: "REBOOT SERVER CONFIRMATION",
          rebootDevicePrompt: "ARE YOU SURE YOU WISH TO REBOOT THE DEVICE?",
          shutdownDevicePrompt: "ARE YOU SURE YOU WISH TO SHUT DOWN THE DEVICE?",
          restartTrendboardPrompt:
            "ARE YOU SURE YOU WISH TO RESTART THE TRENDBOARD?",
          restartServerPrompt: "ARE YOU SURE YOU WISH TO REBOOT THE SERVER?",
          tableId: "TABLE ID:",
          deviceId: "DEVICE ID",
          ipAddress: "IP ADDRESS",
          status: "STATUS",
          manage: "MANAGE",
          healthy: "HEALTHY",
          unhealthy: "UNHEALTHY",
          restart: "REBOOT",
          device: "DEVICE",
          trendboard: "TRENDBOARD",
          server: "SERVER",
          gameServer: "GAME SERVER",
          minBetError: "Min bet cannot be empty, or more than max bet!",
          maxBetError: "Max bet cannot be empty, or less than min bet!",
          tieMinBetError: "Tie min bet cannot be more than tie max bet!",
          tieMaxBetError: "Tie max bet cannot be less than tie min bet!",
          pairMinBetError: "Pair min bet cannot be more than pair max bet!",
          pairMaxBetError: "Pair max bet cannot be less than pair min bet!",
          natMinBetError:
            "Natural min bet cannot be more than natural max bet!",
          natMaxBetError:
            "Natural max bet cannot be less than natural min bet!",
          lucky6MinBetError:
            "Lucky 6 min bet cannot be more than lucky 6 max bet!",
          lucky6MaxBetError:
            "Lucky 6 max bet cannot be less than lucky 6 min bet!",
          unsavedChanges:
            "You have unsaved changes, are you sure you wish to leave?",
          tableName: "TABLE NAME",
          tableNumber: "TABLE NUMBER",
          on: "ON",
          off: "OFF",
          gameType: "GAME TYPE",
          stream: "STREAM",
          tieMinBet: "TIE MIN BET",
          tieMaxBet: "TIE MAX BET",
          natural89MinBet: "NATURAL 8/9 MIN BET",
          natural89MaxBet: "NATURAL 8/9 MAX BET",
          lucky6MinBet: "LUCKY 6 MIN BET",
          lucky6MaxBet: "LUCKY 6 MAX BET",
          pairMinBet: "PAIR MIN BET",
          pairMaxBet: "PAIR MAX BET",
          confirmChanges: "CONFIRM CHANGES",
          confirmChangesPrompt:
            "ARE YOU SURE YOU WISH TO MAKE CHANGES TO THIS TABLE?",
        },
      },
    },
    zh_CN: {
      common: {
        global: {
          cancel: "取消",
          confirm: "确认",
          language: "语言",
          languageLowercase: "语言 Language",
          en: "ENGLISH / 英语",
          zh_HK: "TRADITIONAL CHINESE / 简体中文",
          zh_CN: "SIMPLIFIED CHINESE / 繁体中文",
          english: "English / 英语",
          simplifiedChinese: "Simplified Chinese / 简体中文",
          traditionalChinese: "Traditional Chinese / 繁体中文",
        },
        dealer: {
          close: "关",
          showMenu: "打开列表",
          hideMenu: "关闭列表",
          burnCards: "销牌",
          overdrewCards: "超派了牌",
          editCards: "更改派牌",
          editResults: "更改当局结果",
          voidGame: "当局游戏作废",
          voidLastGame: "上局游戏作废",
          wipeTrendboard: "重设路單",
          restartBetTimer: "重设投注计时器",
          stopBetTimer: "停止投注计时器",
          betTimerStopped: "投注计时器已停了!",
          logOut: "登出",
          wipeTrendboardConfirmation: "确认重设路單",
          wipeTrendboardPrompt: "您确定要重设路單吗？",
          wipe: "重设",
          wipeDoubleConfirmation: "重设路單需要監場确认",
          pleaseSwipe: "请刷監場员工卡。",
          burnCardsConfirmation: "销牌确认",
          autoBurnPrompt: "请抽取一张牌以确定要烧牌的数量",
          burnPrompt: "剩下要烧的牌数：",
          done: "完成",
          overdrewCardsConfirmation: "确认超派了牌",
          overdrewCardsPrompt: "超派了牌数量",
          voidGameConfirmation: "确认当局游戏作废",
          voidGamePrompt: "您确定要作废当局游戏吗？",
          voidGameDoubleConfirmation: "作废当局游戏需要監場确认。",
          voidLastGameConfirmation: "确认上局游戏作废",
          voidLastGamePrompt: "您确定要作废上局游戏吗？",
          voidLastGameDoubleConfirmation: "作废上局游戏需要監場确认。",
          editCardsConfirmation: "确认更改发牌",
          editCardsPrompt: "您要确认更改发牌吗?",
          editCardsInvalid: "这一局结果不符合发牌规则,请调整发牌.",
          illegalPlayerThird: "闲家第三张卡不符合发牌规则, 请删除.",
          illegalBankerThird: "庄家第三张卡不符合发牌规则, 请删除.",
          editCardsDoubleConfirmation: "更改发牌,需要监场确认.",
          closeTable: "关掉赌桌",
          closeTablePrompt: "您确定要关掉赌桌吗？",
          openTable: "打开赌桌",
          openTablePrompt: "确认打开赌桌",
          open: "打开",
          logOutPrompt: "您确定要登出吗？",
          tableDeleted: "赌桌不存在！",
          tableDeletedPrompt: "该赌桌已删除，请重新初始化为另一个赌桌",
          reinitialise: "重新初始化",
          banker: "莊家",
          points: "分",
          player: "閒家",
          finalizeEditedCards: "最终确认更改发牌",
          bankerWinPredict: "结果是庄赢",
          tiePredict: "结果是和局",
          playerWinPredict: "结果是闲赢",
          confirmEditCard: "确认更改派牌",
          removeCard: "删除发牌",
          invalidGame: "本局无效",
          bankerWin: "莊家赢",
          tie: "和局",
          playerWin: "閒家赢",
          forceBankerWin: "编辑为莊家赢",
          forceTie: "编辑为和局",
          forcePlayerWin: "编辑为閒家赢",
          pair: "对子",
          lucky6: "幸运6",
          natural8: "自然8",
          natural9: "自然9",
          editResultConfirmation: "确认编辑结果",
          editResultDoubleConfirmation: "编辑结果需要監場确认",
          editResultTo: "将结果编辑到:",
          end: "结束",
          game: "游戏",
          reveal: "显示发牌",
          playerId: "Player ID",
          started: "Started Time",
          memberId: "Member ID",
          name: "Name",
          memberLevel: "Member Level",
          gender: "Gender",
          unbind: "Clear VIP",
          cashdrop: "Cash Drop",
          tableFillCredit: "Table Chip Fill/Credit",
        },
        manager: {
          tableConfig: "赌台设定",
          devices: "设备",
          game: "游戏",
          tableIs: "赌桌已",
          deviceStatus: "设备状态",
          online: "线上",
          offline: "离线",
          open: "打开",
          close: "关闭",
          trendboardStatus: "路單状态",
          minBet: "最低投注",
          maxBet: "最高投注",
          back: "背部",
          rebootDevice: "确认重新启动设备",
          restartTrendboard: "确认重新启动路單",
          restartServer: "确认重新启动服务器",
          rebootDevicePrompt: "您确定要重新启动设备吗？",
          restartTrendboardPrompt: "您确定要重新启动路單吗？",
          restartServerPrompt: "您确定要重新启动服务器吗？",
          tableId: "赌桌ID:",
          deviceId: "“设备ID",
          ipAddress: "IP地址",
          status: "状态",
          manage: "管理",
          healthy: "状况良好",
          unhealthy: "状况不良好",
          restart: "重新启动",
          device: "设备",
          trendboard: "路單",
          server: "服务器",
          gameServer: "游戏服务器",
          minBetError: "最低投注不能为空，或大于最高投注！",
          maxBetError: "最高投注不能为空，或小于最低投注！",
          tieMinBetError: "和局最低投注不能超过和局最高投注！",
          tieMaxBetError: "和局最高投注不能小于和局最低投注！",
          pairMinBetError: "对子最低投注不能大于对子最高投注！",
          pairMaxBetError: "对子最高投注不能小于对子最低投注！",
          natMinBetError: "自然8/9最低投注不能超过自然8/9最高投注！",
          natMaxBetError: "自然8/9最高投注不能超过自然8/9最低投注！",
          lucky6MinBetError: "幸运六最小投注不能大于最高投注!",
          lucky6MaxBetError: "幸运六最高投注不能小于最小投注!",
          unsavedChanges: "您有未保存的更改，确定要离开吗？",
          tableName: "赌桌名称:",
          tableNumber: "赌桌编号",
          on: "打开",
          off: "关闭",
          gameType: "游戏类型",
          stream: "视频流",
          tieMinBet: "和局最低投注",
          tieMaxBet: "和局最高投注",
          natural89MinBet: "自然8/9最低投注",
          natural89MaxBet: "自然8/9最高投注",
          lucky6MinBet: "幸运六最小投注",
          lucky6MaxBet: "幸运六最高投注",
          pairMinBet: "对子最低投注",
          pairMaxBet: "对子最高投注",
          confirmChanges: "确认变更",
          confirmChangesPrompt: "您确定要更改此赌桌吗？",
        },
      },
    },
    zh_HK: {
      common: {
        global: {
          cancel: "取消",
          confirm: "確認",
          language: "語言",
          languageLowercase: "語言 Language",
          en: "ENGLISH /英語",
          zh_HK: "TRADITIONAL CHINESE /簡體中文",
          zh_CN: "SIMPLIFIED CHINESE / 繁體中文",
          english: "English /英語",
          simplifiedChinese: "Simplified Chinese / 簡體中文",
          traditionalChinese: "Traditional Chinese /繁體中文",
        },
        dealer: {
          close: "關",
          showMenu: "打開列表",
          hideMenu: "關閉列表",
          burnCards: "銷牌",
          overdrewCards: "超派了牌",
          editCards: "更改派牌",
          editResults: "更改當局結果",
          voidGame: "當局遊戲作廢",
          voidLastGame: "上局遊戲作廢",
          wipeTrendboard: "重設路單",
          restartBetTimer: "重設投注計時器",
          stopBetTimer: "停止投注計時器",
          betTimerStopped: "投注計時器已停了!",
          logOut: "登出",
          wipeTrendboardConfirmation: "確認重設路單",
          wipeTrendboardPrompt: "您確定要重設路單嗎？",
          wipe: "重設",
          wipeDoubleConfirmation: "重設路單需要監場確認",
          pleaseSwipe: "請刷監場員工卡。",
          burnCardsConfirmation: "銷牌確認",
          autoBurnPrompt: "請抽取一張牌以確定要燒牌的數量",
          burnPrompt: "剩下要燒的牌數：",
          done: "完成",
          overdrewCardsConfirmation: "確認超派了牌",
          overdrewCardsPrompt: "超派了牌數量",
          voidGameConfirmation: "確認當局遊戲作廢",
          voidGamePrompt: "您確定要作廢當局遊戲嗎？",
          voidGameDoubleConfirmation: "作廢當局遊戲需要監場確認。",
          voidLastGameConfirmation: "確認上局遊戲作廢",
          voidLastGamePrompt: "您確定要作廢上局遊戲嗎？",
          voidLastGameDoubleConfirmation: "作廢上局遊戲需要監場確認。",
          editCardsConfirmation: "確認更改發牌",
          editCardsPrompt: "您要確認更改發牌嗎?",
          editCardsInvalid: "這一局結果不符合發牌規則,請調整發牌.",
          illegalPlayerThird: "閒家第三張卡不符合發牌規則, 請刪除.",
          illegalBankerThird: "莊家第三張卡不符合發牌規則, 請刪除.",
          editCardsDoubleConfirmation: "更改發牌,需要監場確認.",
          closeTable: "關掉賭桌",
          closeTablePrompt: "您確定要關掉賭桌嗎？",
          openTable: "打開賭桌",
          openTablePrompt: "確認打開賭桌",
          open: "打開",
          logOutPrompt: "您確定要登出嗎？",
          tableDeleted: "賭桌不存在！",
          tableDeletedPrompt: "該賭桌已刪除，請重新初始化為另一個賭桌",
          reinitialise: "重新初始化",
          banker: "莊家",
          points: "分",
          player: "閒家",
          finalizeEditedCards: "最終確認更改發牌",
          bankerWinPredict: "结果是庄赢",
          tiePredict: "结果是和局",
          playerWinPredict: "结果是閒赢",
          confirmEditCard: "確認更改派牌",
          removeCard: "刪除發牌",
          invalidGame: "本局無效",
          bankerWin: "莊家贏",
          tie: "和局",
          playerWin: "閒家贏",
          forceBankerWin: "編輯為莊家贏",
          forceTie: "編輯為和局",
          forcePlayerWin: "編輯為閒家贏",
          pair: "對子",
          lucky6: "幸運6",
          natural8: "自然8",
          natural9: "自然9",
          editResultConfirmation: "確認編輯結果",
          editResultDoubleConfirmation: "編輯結果需要監場確認",
          editResultTo: "將結果編輯到:",
          end: "結束",
          game: "遊戲",
          reveal: "顯示發牌",
          playerId: "Player ID",
          started: "Started Time",
          memberId: "Member ID",
          name: "Name",
          memberLevel: "Member Level",
          gender: "Gender",
          unbind: "Clear VIP",
          cashdrop: "Cash Drop",
          tableFillCredit: "Table Chip Fill/Credit",
        },
        manager: {
          tableConfig: "賭臺設定",
          devices: "設備",
          game: "遊戲",
          tableIs: "賭桌已",
          deviceStatus: "設備狀態",
          online: "線上",
          offline: "離線",
          open: "打開",
          close: "關閉",
          trendboardStatus: "路單狀態",
          minBet: "最低投注",
          maxBet: "最高投注",
          back: "背部",
          rebootDevice: "確認重新啟動設備",
          restartTrendboard: "確認重新啟動路單",
          restartServer: "確認重新啟動服務器",
          rebootDevicePrompt: "您確定要重新啟動設備嗎？",
          restartTrendboardPrompt: "您確定要重新啟動路單嗎？",
          restartServerPrompt: "您確定要重新啟動服務器嗎？",
          tableId: "賭桌ID:",
          deviceId: "“設備ID",
          ipAddress: "IP地址",
          status: "狀態",
          manage: "管理",
          healthy: "狀況良好",
          unhealthy: "狀況不良好",
          restart: "重新啟動",
          device: "設備",
          trendboard: "路單",
          server: "服務器",
          gameServer: "遊戲服務器",
          minBetError: "最低投注不能為空，或大於最高投注！",
          maxBetError: "最高投注不能為空，或小於最低投注！",
          tieMinBetError: "和局最低投注不能超過和局最高投注！",
          tieMaxBetError: "和局最高投注不能小於和局最低投注！",
          pairMinBetError: "對子最低投注不能大於對子最高投注！",
          pairMaxBetError: "對子最高投注不能小於對子最低投注！",
          natMinBetError: "自然8/9最低投注不能超過自然8/9最高投注！",
          natMaxBetError: "自然8/9最高投注不能超過自然8/9最低投注！",
          lucky6MinBetError: "幸運六最小投注不能大於最高投注!",
          lucky6MaxBetError: "幸運六最高投注不能小於最小投注!",
          unsavedChanges: "您有未保存的更改，確定要離開嗎？",
          tableName: "賭桌名稱:",
          tableNumber: "賭桌編號",
          on: "打開",
          off: "關閉",
          gameType: "遊戲類型",
          stream: "視頻流",
          tieMinBet: "和局最低投注",
          tieMaxBet: "和局最高投注",
          natural89MinBet: "自然8/9最低投注",
          natural89MaxBet: "自然8/9最高投注",
          lucky6MinBet: "幸運六最小投注",
          lucky6MaxBet: "幸運六最高投注",
          pairMinBet: "對子最低投注",
          pairMaxBet: "對子最高投注",
          confirmChanges: "確認變更",
          confirmChangesPrompt: "您確定要更改此賭桌嗎？",
        },
      },
    },
  },
  fallbackLng: "en",
  debug: false,
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
};

export default i18n;
