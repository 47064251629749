import React, { memo, forwardRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/styles'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const CustomTooltipWrapper = ({ condition, wrapper, children }) => (condition ? children : wrapper(children))

export default memo(({ open, setOpen }) => {
    const activeStyle = {
        background: 'rgb(88, 88, 88)',
    }

    const NavLinkRef = forwardRef((props, ref) => <NavLink {...props} ref={ref} />)

    // handle propagation
    // from the click event on the hamburger triggering the click on the chevron
    // not good but idk wtf
    const [openDoneStarted, setOpenDoneStarted] = useState(false)
    const [openDone, setOpenDone] = useState(false)

    if (open && !openDone && !openDoneStarted) {
        setOpenDoneStarted(true)
        setTimeout(() => {
            if (open) {
                setOpenDone(true)
            }
        }, 400)
    }

    if (!open && openDone) {
        setOpenDoneStarted(false)
        setOpenDone(false)
    }

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (openDone) {
                    setOpen(false)
                }
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box>
                    <DrawerHeader>
                        <Typography sx={{ ml: 2, fontWeight: 'bold', textTransform: 'uppercase' }}>X Meter</Typography>
                        <IconButton
                            onClick={() => {
                                if (openDone) {
                                    setOpen(false)
                                }
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>

                    <Divider />

                    <List style={{ padding: 0 }}>
                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Home' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xmeter/home',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Home'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Settings' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xmeter/settings',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Settings'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />
                    </List>
                </Box>

                <Box>
                    <CustomTooltipWrapper
                        condition={open}
                        wrapper={(children) => (
                            <Tooltip title='Exit App' placement='right'>
                                {children}
                            </Tooltip>
                        )}
                    >
                        <ListItemButton
                            component={NavLinkRef}
                            onClick={() => setOpen(false)}
                            {...{
                                disableTouchRipple: true,
                                to: '/dashboard',
                            }}
                        >
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Exit App'} />
                        </ListItemButton>
                    </CustomTooltipWrapper>
                </Box>
            </Box>
        </ClickAwayListener>
    )
})
