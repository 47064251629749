import React, { memo } from 'react';
import TreeMenu from './TreeMenu'

const TM = memo(function TM({ zones, pits, tables, devices, setSelectedBranch }) {
    return <TreeMenu zones={zones} pits={pits} tables={tables} devices={devices} setSelectedBranch={setSelectedBranch} />
})

const LSP = ({ zones, pits, tables, devices, setSelectedBranch }) => {
    return <TM zones={zones} pits={pits} tables={tables} devices={devices} setSelectedBranch={setSelectedBranch} />
}

export default LSP