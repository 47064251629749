import React, { useState } from 'react'
import { useReducer } from 'react'
import { zipObject, flatten } from 'lodash'
import LastPageIcon from '@mui/icons-material/LastPage'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import ViewComfyIcon from '@mui/icons-material/ViewComfy'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'

import { publish } from '../../../api'
import Icons from '../../../tree/icons/Icons'
import { containerTypeColor } from '../../../util/ContainerTypeColor'
import AlertDialog from '../../components/AlertDialog'

export const reservedFields = ['index', 'type', 'containerIndex', 'deviceCount', 'displayName', 'searchMatch', 'deviceType', 'isGameDeviceExist'].map(v => v.toLowerCase())

const rootStyles = makeStyles({
    list: {
        listStyle: 'none',
        borderLeft: props => props.level === 0 ? '' : '1px solid ' + props.parentBranchColor,
        paddingLeft: props => props.level === 0 ? 5 : 12,
        paddingRight: 10,
        cursor: 'pointer',
        margin: 0,
        marginLeft: props => props.level === 0 ? 0 : 1,
        '& li': {
            color: props => props.branchColor,
        },
        '& li.branch': {
            height: 30, display: 'flex', alignItems: 'center',
        },

        '& li.device': {
            color: props => props.deviceColor
        },
        '& li:hover': {
            color: '#fff'
        },
        '& a:link': { color: 'inherit' },
        '& a:active': { color: 'inherit' },
        '& a:visited': { color: 'inherit' },
        '& a:hover': { color: 'inherit' },
    },
    dragOver: {
        '& > li.branch ': {
            backgroundColor: '#525252'
        }
    },
    icon: {
        verticalAlign: 'middle',
        marginLeft: '-19px',

    },
    branchToggle: {
        color: props => props.branchColor,
    },
    textOverflow: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: 0,
        color: '#f0f0f0',
        fontSize: 14,
        fontWeight: 100,
        marginLeft: 5
    }
})

const $primary = containerTypeColor.casino,
    $success = containerTypeColor.zone,
    $danger = containerTypeColor.pit,
    $warning = containerTypeColor.table,
    $info = containerTypeColor.device

const getBranchColor = (level) => {
    return level === 0 ? $primary : level === 1 ? $success : level === 2 ? $danger : level === 3 ? $warning : level === 4 ? $info : '#fff'
}

// builds and returns the navtree as a nested `object` corresponding to the branch hierarchy 
const buildTreeNavData = (zones, pits, tables, devices, showDevices) => {
    const baseData = { index: 'root', type: 'Casino' }
    const indexes = { 'root': baseData }

    const insertData = (v, type) => {
        const index = (v.index && v.index.length > 0) ? v.index : null
        const containerIndex = (v.data && v.data.containerIndex && v.data.containerIndex.length > 0) ? v.data.containerIndex : null
        const deviceType = (v.data && v.data.type && v.data.type.length > 0)? v.data.type : null
        const devicesInTable = devices.filter(v => v.data.containerIndex === index)
        const isGameDeviceExist = devicesInTable.filter(v => v.data.type === 'game').length > 0 ? true : false
        const name = (v.data && v.data.name && v.data.name.length > 0) ? v.data.name : null
        if (name && index && containerIndex && typeof indexes[containerIndex] !== 'undefined') {
            indexes[containerIndex][name] = {}
            indexes[containerIndex][name]['index'] = index
            indexes[containerIndex][name]['type'] = type
            if (type === 'Table') {
                indexes[containerIndex][name]['isGameDeviceExist'] = isGameDeviceExist
            }
            if (type === 'Device') {
                indexes[containerIndex][name]['deviceType'] = deviceType
            }
            indexes[index] = indexes[containerIndex][name]
        }
    }

    zones.forEach(v => { insertData(v, 'Zone') })
    pits.forEach(v => { insertData(v, 'Pit') })
    tables.forEach(v => { insertData(v, 'Table') })
    if (showDevices) {
        devices.forEach(v => { insertData(v, 'Device') })
    }
    return baseData
}

// Constructs and returns the actual <Branch/> component. Varies based on the props (i.e. level, name, etc.)
const Branch = ({
    data,
    parentIndex,
    name,
    level,
    setSaveExpanded,
    treeMenuExpandedIndex,
    branchSelect,
    setAlertDialogState,
    moveThingToNewContainer,
    draggedObj,
    setDraggedObj,
    collapseAllBranches = () => { },
    expandAllBranches = () => { },
    isAllBranchesCollapsed = null,
    parentBranchColor = '#2980B9'
}) => {
    const index = data['index']
    const containerType = data['type']
    const isDevice = (level === 4 || containerType === 'Device') ? true : false
    const deviceType = data['deviceType']
    const isGameDeviceExist = data['isGameDeviceExist']
    const [branchExpanded, setBranchExpanded] = useState(level === 0 ? true : treeMenuExpandedIndex && typeof treeMenuExpandedIndex[index] !== 'undefined' ? treeMenuExpandedIndex[index] : false)
    const hasChildren = (typeof (data) === 'object' && Object.keys(data).filter(x => { return !reservedFields.includes(x.toLowerCase()) }).length > 0) ? true : false
    const branchColor = (containerType === 'Zone' && name === 'Unassigned') ? '#000' : getBranchColor(level)
    const deviceColor = getBranchColor(4)
    const branches = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()))
    const s = rootStyles({ level: level, parentBranchColor: parentBranchColor, branchColor: branchColor, deviceColor: deviceColor })

    const icons = {
        arrow_drop_down: <Icons onClick={(e) => { branchCollapse(); e.stopPropagation() }} style={{ fontSize: 37, marginLeft: -18 }}>arrow_drop_down</Icons>,
        arrow_right: <Icons onClick={(e) => { branchExpand(); e.stopPropagation() }} style={{ fontSize: 37, marginLeft: -18 }}>arrow_right</Icons>,
        fiber_manual_record: <Icons style={{ fontSize: 10, margin: 16, marginLeft: -5, color: branchColor }}>fiber_manual_record</Icons>,
        more_vert: <MoreVertIcon style={{ fontSize: 15, margin: 16, marginLeft: 0, color: branchColor }} />,
        power: <Icons style={{ padding: '0 2px 0 7px', marginLeft: -10, color: deviceColor }}>power</Icons>,
        customIcon: <Icons onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} icon={containerType.toLowerCase()} size="18px" />,
        casino: <ViewComfyIcon onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} style={{ fontSize: 25, }} />,
        zone: <ViewModuleIcon onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} style={{ fontSize: 25, }} />,
        pit: <ViewColumnIcon onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} style={{ fontSize: 25, }} />,
        table: <ViewCarouselIcon onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} style={{ fontSize: 25, }} />,
    }

    const branchClick = () => {
        branchSelect(containerType, index)
    }

    const branchExpand = () => {
        setBranchExpanded(true)
        setSaveExpanded(index, true)
    }

    const branchCollapse = () => {
        setBranchExpanded(false)
        setSaveExpanded(index, false)
    }

    const isValidDragDrop = () => {
        if (((containerType === 'Casino' && draggedObj.containerType === 'Zone') ||
            (containerType === 'Zone' && draggedObj.containerType === 'Pit') ||
            (containerType === 'Pit' && draggedObj.containerType === 'Table') ||
            (containerType !== 'Device' && draggedObj.containerType === 'Device'))
            && data['index'] !== draggedObj.parentIndex) {
            return true
        }
        return false
    }

    const dragProps = {
        onDrag: (e) => {
            if (draggedObj.index !== index || draggedObj.parentIndex !== parentIndex) {
                setDraggedObj({
                    containerType: containerType,
                    index: index,
                    parentIndex: parentIndex,
                    name: name,
                    deviceType: deviceType
                })
            }
            e.stopPropagation()
        },
        onDragStart: (e) => {
            if (parentIndex === 'root' && containerType !== 'Device') {
                e.preventDefault();
                return
            }
            e.stopPropagation()
        },
        onDrop: (e) => {
            e.stopPropagation()
            e.preventDefault()
            if (!isValidDragDrop()) {
                return false
            }
            if (draggedObj.deviceType === 'game' && isGameDeviceExist) {
                setAlertDialogState({
                    show: 'true',
                    type: 'error',
                    message: 'Only 1 game device per table is allowed.',
                    error: 'true',
                })
                return false
            }
            if (draggedObj.containerType === 'Device' && draggedObj.deviceType === 'game' && containerType !== 'Table') {
                setAlertDialogState({
                    show: 'true',
                    type: 'error',
                    message: 'Game device can be added to Table only.',
                    error: 'true',
                })
                return false
            }
            e.currentTarget.className = e.currentTarget.className.replace(s.dragOver, '')
            setAlertDialogState({
                show: true,
                type: 'confirmation',
                message: (
                    <span>
                        {'Are you sure you want to move '}
                        <b>{draggedObj.name}</b>
                        {' (' + draggedObj.containerType + ') into '}
                        <b> {name}</b> {' (' + containerType + ')'}
                    </span>
                ),
                error: false,
                confirm: () =>
                    moveThingToNewContainer({
                        thingType: draggedObj.containerType,
                        thingIndex: draggedObj.index,
                        containerType: containerType,
                        containerIndex: index,
                        loading: () => {
                            setAlertDialogState((prev) => ({
                                ...prev,
                                loading: true,
                            }))
                        },
                        success: () => {
                            setAlertDialogState((prev) => ({
                                ...prev,
                                show: false,
                                type: '',
                                message: '',
                                error: false,
                                loading: false,
                            }))
                        },
                        fail: () => {
                            setAlertDialogState((prev) => ({
                                ...prev,
                                show: true,
                                type: 'error',
                                message: 'Failed to move thing into new container.',
                                error: true,
                                loading: false,
                            }))
                        },
                    }),
                loading: false,
            })
        },
        onDragOver: (e) => {
            e.preventDefault()
            e.stopPropagation()
            let className = e.currentTarget.className
            if (isValidDragDrop() && className.indexOf(s.dragOver) === -1) {
                e.currentTarget.className = e.currentTarget.className + ' ' + s.dragOver
            }
            // if (isValidDragDrop() && className.indexOf(sxStyles.dragOver) === -1) {
            //     e.currentTarget.className = e.currentTarget.className + ' ' + sxStyles.dragOver
            // }
        },
        onDragLeave: (e) => {
            let className = e.currentTarget.className
            e.currentTarget.className = className.replace(s.dragOver, '')
            // e.currentTarget.className = className.replace(sxStyles.dragOver, '')
            e.stopPropagation()
            e.preventDefault()
        },
        draggable: "true"
    }

    return <ul key={name} className={s.list} {...dragProps} >
        <li className={isDevice ? "branch device" : "branch"}
            onClick={() => { branchClick() }}
            style={{ position: 'relative' }} >

            {/* The the collapse/expand Icon */}
            {index === 'root' && <div style={{ position: 'absolute', top: 2, right: 0 }}>
                <LastPageIcon sx={{ transform: isAllBranchesCollapsed ? 'rotate(90deg)' : 'rotate(270deg)', height: '25px', width: '25px' }} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    isAllBranchesCollapsed ? expandAllBranches() : collapseAllBranches()
                }} />
            </div>}
            {/* ellipsis to indicate there are nested branches */}
            <div style={{ position: 'absolute', left: 6, bottom: -27 }}>
                {!isDevice && hasChildren && !branchExpanded && icons.more_vert}
            </div>

            {/* Icon representing the current branch followed by branch name (casino, zone, etc.) - styles specified in src/icons/Icons */}
            {isDevice ? icons.power : icons[containerType.toLowerCase()]}
            <Tooltip title={`${name} - ${containerType}`} placement="right" enterDelay={1000} leaveTouchDelay={0} arrow>
                <p className={s.textOverflow}>{name}</p>
            </Tooltip>
        </li>

        {/* Below code corresponds to nested branches (if any) resulting in a recursion */}
        {branchExpanded && branches &&
            branches.map(v =>
                <Branch
                    data={data[v]}
                    key={v}
                    moveThingToNewContainer={moveThingToNewContainer}
                    setAlertDialogState={setAlertDialogState}
                    parentIndex={data['index']}
                    name={v}
                    level={level + 1}
                    setSaveExpanded={setSaveExpanded}
                    treeMenuExpandedIndex={treeMenuExpandedIndex}
                    draggedObj={draggedObj}
                    setDraggedObj={setDraggedObj}
                    branchSelect={branchSelect}
                    parentBranchColor={branchColor}
                />)}

    </ul >
}

const TreeNav = ({ fontSize = 23, showDevices = true, selectedIndex = null, zones, pits, tables, devices, branchSelect }) => {
    const treeNavData = buildTreeNavData(zones, pits, tables, devices, showDevices)
    // console.log('baseData > treeNavData:', treeNavData);
    const [treeMenuExpandedIndex, setTreeMenuExpandedIndex] = useState(window.localStorage.getItem('treeMenuExpandedIndex') ? JSON.parse(window.localStorage.getItem('treeMenuExpandedIndex')) : {})
    const [lastCollapsedContainer, setLastCollapsedContainer] = useState('')
    const [draggedObj, setDraggedObj] = useReducer((state, obj) => {
        return obj
    }, { containerType: '', index: '', parentIndex: '', name: '' })
    
    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => {},
    })

    const setSaveExpanded = (index, bool) => {
        treeMenuExpandedIndex[index] = bool
        window.localStorage.setItem('treeMenuExpandedIndex', JSON.stringify(treeMenuExpandedIndex))
        setTreeMenuExpandedIndex(treeMenuExpandedIndex)
    }

    const expandAllBranches = () => {
        const getAllIndexes = (obj) => {
            let indexes = []
            Object.keys(obj).forEach(k => {
                if (k === 'index') indexes.push(obj[k])
                if (typeof obj[k] === 'object') indexes.push(flatten(getAllIndexes(obj[k])))
            })
            return flatten(indexes)
        }

        const indexes = getAllIndexes(treeNavData)
        const obj = zipObject(indexes, Array(indexes.length).fill(true))
        setTreeMenuExpandedIndex(obj)
        window.localStorage.setItem('treeMenuExpandedIndex', JSON.stringify(obj))

    }

    const collapseAllBranches = () => {
        setTreeMenuExpandedIndex({})
        window.localStorage.setItem('treeMenuExpandedIndex', JSON.stringify({}))
        setLastCollapsedContainer(selectedIndex)
    }

    const clickedBranch = (index) => {
        //clicked on same branch again, and branch not marked as most recent collapsed
        //then we collapse it
        if (index === selectedIndex && selectedIndex !== lastCollapsedContainer) {
            setLastCollapsedContainer(selectedIndex)
            setSaveExpanded(selectedIndex, false)
        } else {
            setLastCollapsedContainer('')
        }
    }

    const moveThingToNewContainer = async ({
        thingType,
        thingIndex,
        containerType,
        containerIndex,
        loading,
        success,
        fail,
    }) => {
        try {
            loading()
            switch (thingType) {
                case 'Zone':
                    await publish('zones/' + thingIndex, {
                        ...zones.filter((v) => v.index === thingIndex)[0].data,
                        containerIndex: containerIndex,
                    })
                    break
                case 'Pit':
                    await publish('pits/' + thingIndex, {
                        ...pits.filter((v) => v.index === thingIndex)[0].data,
                        containerIndex: containerIndex,
                    })
                    break
                case 'Table':
                    await publish('tables/' + thingIndex, {
                        ...tables.filter((v) => v.index === thingIndex)[0].data,
                        containerIndex: containerIndex,
                    })
                    break
                case 'Device':
                    await publish('devices/' + thingIndex, {
                        ...devices.filter((v) => v.index === thingIndex)[0].data,
                        containerIndex: containerIndex,
                        containerType: containerType,
                    })
                    break
                default:
                    console.log('do nothing')
            }
            success()
        } catch (e) {
            console.log(e)
            fail()
        }
    }

    if (selectedIndex && selectedIndex !== lastCollapsedContainer && treeMenuExpandedIndex[selectedIndex] !== true) {
        setSaveExpanded(selectedIndex, true)
    }

    const isAllBranchesCollapsed = Object.keys(treeMenuExpandedIndex).length === 0 ? true : false;


    // returns the top-most branch (Casino) - notice the prop `key` is absent
    const Branches = () =>
        <Branch data={treeNavData}
            name="Casino"
            level={0}
            parentIndex="root"
            moveThingToNewContainer={moveThingToNewContainer}
            setAlertDialogState={setAlertDialogState}
            setSaveExpanded={setSaveExpanded}
            treeMenuExpandedIndex={treeMenuExpandedIndex}
            draggedObj={draggedObj}
            setDraggedObj={setDraggedObj}
            branchSelect={(...args) => {
                clickedBranch(args[1]); branchSelect(...args)
            }}
            expandAllBranches={expandAllBranches}
            collapseAllBranches={collapseAllBranches}
            isAllBranchesCollapsed={isAllBranchesCollapsed}
        />

    return <div style={{ maxWidth: 300 }}>
        {/* Contains all the branches in a single component */}
        <Branches />

        <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
    </div>
}

export default TreeNav
