/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useContext } from "react";
import { css, jsx } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { isUndefined } from "lodash";

import { DUIContext } from "./Dealer";

const darkTheme = createTheme({ palette: { mode: "dark" } });

const AlertDialog = () => {
  const {
    t,
    dialogObj,
    setDialogObj,
    showCashDrop,
    setShowCashDrop,
    showTableFillCredit,
    setShowTableFillCredit,
  } = useContext(DUIContext);

  const handleClose = () => {
    if (
      (dialogObj.type === "success" || dialogObj.type === "error") &&
      showCashDrop
    ) {
      setShowCashDrop(!showCashDrop);
    }

    if (
      (dialogObj.type === "success" || dialogObj.type === "error") &&
      showTableFillCredit
    ) {
      setShowTableFillCredit(!showTableFillCredit);
    }

    setDialogObj({
      ...dialogObj,
      show: false,
      type: "",
      message: "",
      error: false,
      confirm: () => {},
    });
  };

  const handleConfirm = () => {
    if (
      !isUndefined(dialogObj.confirm) &&
      typeof dialogObj.confirm === "function"
    )
      dialogObj.confirm();
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog
        open={dialogObj.show}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          css={css`
            font-size: 1.4rem;
          `}
        >
          {dialogObj.type === "confirmation" && <div>Confirmation</div>}
          {dialogObj.type === "success" && <div>Note</div>}
          {dialogObj.type === "reminder" && <div>Reminder</div>}
          {dialogObj.type === "error" && <div>Error</div>}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            css={css`
              font-size: 1.2rem;
            `}
          >
            {dialogObj.message}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {dialogObj.type === "confirmation" && (
            <React.Fragment>
              <Button
                onClick={handleClose}
                css={css`
                  font-size: 1.1rem;
                `}
                size="large"
              >
                {t("global.cancel")}
              </Button>
              <Button
                onClick={handleConfirm}
                css={css`
                  font-size: 1.1rem;
                `}
                size="large"
              >
                {t("global.confirm")}
              </Button>
            </React.Fragment>
          )}

          {(dialogObj.type === "success" ||
            dialogObj.type === "reminder" ||
            dialogObj.type === "error") && (
            <Button
              onClick={handleClose}
              css={css`
                font-size: 1.1rem;
              `}
              size="large"
            >
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AlertDialog;
