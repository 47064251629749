import React from 'react'
import './index.css'
import PreApp from './PreApp'
// import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom'

// const container = document.getElementById('root')
// const root = createRoot(container)
// root.render(<PreApp  />)
ReactDOM.render(<PreApp />, document.getElementById('root'));



// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <PreApp />
//   </React.StrictMode>
// );