const LOCAL_CONF = JSON.parse(window.localStorage.getItem('config') ? window.localStorage.getItem('config') : 'null')

export const env = process.env.NODE_ENV

// xtrend
export const authIP = LOCAL_CONF ? (LOCAL_CONF.authIP === '127.0.0.1:3111' || LOCAL_CONF.authIP === 'localhost:3111' ? window.location.hostname + ':3111' : LOCAL_CONF.authIP) : ''
export const pivotIP = LOCAL_CONF ? (LOCAL_CONF.pivotIP === '127.0.0.1:3099' || LOCAL_CONF.pivotIP === 'localhost:3099' ? window.location.hostname + ':3099' : LOCAL_CONF.pivotIP) : ''
export const patternsServerIP = LOCAL_CONF ? (LOCAL_CONF.patternsServerIP === '127.0.0.1:3101' || LOCAL_CONF.patternsServerIP === 'localhost:3101' ? window.location.hostname + ':3101' : LOCAL_CONF.patternsServerIP) : ''
export const ticketsIP = LOCAL_CONF ? (LOCAL_CONF.ticketsIP === '127.0.0.1:3039' || LOCAL_CONF.ticketsIP === 'localhost:3039' ? window.location.hostname + ':3039' : LOCAL_CONF.ticketsIP) : ''
export const cashIP = LOCAL_CONF ? (LOCAL_CONF.cashIP === '127.0.0.1:3043' || LOCAL_CONF.cashIP === 'localhost:3043' ? window.location.hostname + ':3043' : LOCAL_CONF.cashIP) : ''
export const resultsIP = LOCAL_CONF ? (LOCAL_CONF.resultsIP === '127.0.0.1:3042' || LOCAL_CONF.resultsIP === 'localhost:3042' ? window.location.hostname + ':3042' : LOCAL_CONF.resultsIP) : ''
export const historyIP = LOCAL_CONF ? (LOCAL_CONF.historyIP === '127.0.0.1:3049' || LOCAL_CONF.historyIP === 'localhost:3049' ? window.location.hostname + ':3049' : LOCAL_CONF.historyIP) : ''
export const prometheusTrend = LOCAL_CONF ? (LOCAL_CONF.prometheusTrend === 'http://127.0.0.1:9090' || LOCAL_CONF.prometheusTrend === 'http://localhost:9090' ? "http://" + window.location.hostname + ':9090' : LOCAL_CONF.prometheusTrend) : ''

// xtable
export const dealerIP = LOCAL_CONF ? (LOCAL_CONF.dealerIP === '127.0.0.1:3040' || LOCAL_CONF.dealerIP === 'localhost:3040' ? window.location.hostname + ':3040' : LOCAL_CONF.dealerIP) : ''
export const playerIP = LOCAL_CONF ? (LOCAL_CONF.playerIP === '127.0.0.1:3036' || LOCAL_CONF.playerIP === 'localhost:3036' ? window.location.hostname + ':3036' : LOCAL_CONF.playerIP) : ''
// duplicated
export const playerServerIP = LOCAL_CONF ? (LOCAL_CONF.playerServerIP === '127.0.0.1:3036' || LOCAL_CONF.playerServerIP === 'localhost:3036' ? window.location.hostname + ':3036' : LOCAL_CONF.playerServerIP) : ''
export const tableIP = LOCAL_CONF ? (LOCAL_CONF.tableIP === '127.0.0.1:3041' || LOCAL_CONF.tableIP === 'localhost:3041' ? window.location.hostname + ':3041' : LOCAL_CONF.tableIP) : ''
export const prometheusTable = LOCAL_CONF ? (LOCAL_CONF.prometheusTable === 'http://127.0.0.1:9095' || LOCAL_CONF.prometheusTable === 'http://localhost:9095' ? "http://" + window.location.hostname + ':9095' : LOCAL_CONF.prometheusTable) : ''
export const prometheusDealer = LOCAL_CONF ? (LOCAL_CONF.prometheusDealer === 'http://127.0.0.1:9093' || LOCAL_CONF.prometheusDealer === 'http://localhost:9093' ? "http://" + window.location.hostname + ':9093' : LOCAL_CONF.prometheusDealer) : ''
export const prometheusPlayer = LOCAL_CONF ? (LOCAL_CONF.prometheusPlayer === 'http://127.0.0.1:9091' || LOCAL_CONF.prometheusPlayer === 'http://localhost:9091' ? "http://" + window.location.hostname + ':9091' : LOCAL_CONF.prometheusPlayer) : ''

// xstadium
export const xstadiumIP = LOCAL_CONF ? (LOCAL_CONF.xstadiumIP === '127.0.0.1:3050' || LOCAL_CONF.xstadiumIP === 'localhost:3050' ? window.location.hostname + ':3050' : LOCAL_CONF.xstadiumIP) : ''
export const screenerIP = LOCAL_CONF ? (LOCAL_CONF.screenerIP === '127.0.0.1:3104' || LOCAL_CONF.screenerIP === 'localhost:3104' ? window.location.hostname + ':3104' : LOCAL_CONF.screenerIP) : ''

// xsignage
export const tusdIP = LOCAL_CONF ? (LOCAL_CONF.tusdIP === 'http://127.0.0.1:9898' || LOCAL_CONF.tusdIP === 'http://localhost:9898' ? "http://" + window.location.hostname + ':9898' : LOCAL_CONF.tusdIP) : ''

// posui (please remove this)
export const visionIP = LOCAL_CONF ? LOCAL_CONF.visionIP : ''
export const chaseTheTrendGameIPChipped = LOCAL_CONF ? LOCAL_CONF.chaseTheTrendGameIPChipped : ''
export const chaseTheTrendGameIPChipless = LOCAL_CONF ? LOCAL_CONF.chaseTheTrendGameIPChipless : ''
export const dBug = LOCAL_CONF ? LOCAL_CONF.dBug : {}
export const dMods = LOCAL_CONF ? LOCAL_CONF.dMods : {}
export const terminalServerIP = LOCAL_CONF ? LOCAL_CONF.terminalServerIP : ''
export const vMods = LOCAL_CONF ? LOCAL_CONF.vMods : {}
export const configurable = LOCAL_CONF ? LOCAL_CONF.configurable : ''

// configs
export const ssl = LOCAL_CONF ? LOCAL_CONF.ssl : false
export const modules = LOCAL_CONF ? LOCAL_CONF.modules : {}