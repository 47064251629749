export const getTrendsTranslation = (key) => {
    let result = ''
    switch (key) {
        case 'dragonTriple':
            result = 'Triple The Dragon'
            break
        case 'dragonDouble':
            result = 'Double The Dragon'
            break
        case 'dragonPlayer':
            result = 'Player Dragon Tail'
            break
        case 'dragonBanker':
            result = 'Banker Dragon Tail'
            break
        case 'clapClapConnect':
            result = 'Clap Clap Connect'
            break
        case 'tieTriple':
            result = 'Triple Tie (3 Consecutive Tie)'
            break
        case 'pingPongStreak':
            result = 'Ping Pong Streak / Triple Ping Pong'
            break
        case 'pingPongDouble':
            result = 'Double Ping Pong'
            break
        case 'pairStreakPlayer':
            result = 'Player Pair Streak'
            break
        case 'pairStreakBanker':
            result = 'Banker Pair Streak'
            break
        case 'tieDouble':
            result = 'Double Tie (2 Consecutive Tie)'
            break
        case 'oneLoungeTwoBedrooms':
            result = '1 Lounge 2 Bedrooms'
            break
        case 'streakPlayer':
            result = 'Player Streak'
            break
        case 'streakBanker':
            result = 'Banker Streak'
            break
        case 'doublePair':
            result = 'Double Pair'
            break
        case 'pingPong':
            result = 'Ping Pong'
            break
        case 'tie':
            result = 'Tie'
            break
        case 'playerPair':
            result = 'Player Pair'
            break
        case 'bankerPair':
            result = 'Banker Pair'
            break
        case 'playerWin':
            result = 'Player Win'
            break
        case 'bankerWin':
            result = 'Banker Win'
            break
        case 'lucky6':
            result = 'Lucky 6'
            break
        case 'mega6':
            result = 'Mega 6'
            break
        case 'tigerPairTwin':
            result = 'Tiger Pair Twin'
            break
        case 'tigerBig':
            result = 'Tiger Big'
            break
        case 'tigerSmall':
            result = 'Tiger Small'
            break
        case 'tigerTieTriple':
            result = 'Triple Tiger Tie'
            break
        case 'tigerPairStreak':
            result = 'Tiger Pair Streak'
            break
        case 'tigerTieDouble':
            result = 'Double Tiger Tie (2 Consecutive Tie)'
            break
        case 'tigerPairDouble':
            result = 'Double Tiger Pair'
            break
        case 'tigerTie':
            result = 'Tiger Tie'
            break
        case 'tigerPair':
            result = 'Tiger Pair'
            break
        case 'superHotPlayerPair':
            result = 'Super Hot Player Pair'
            break
        case 'superHotBankerPair':
            result = 'Super Hot Banker Pair'
            break
        case 'hotPlayerPair':
            result = 'Hot Player Pair'
            break
        case 'hotBankerPair':
            result = 'Hot Banker Pair'
            break
        case 'superHotTie':
            result = 'Super Hot Tie'
            break
        case 'hotTie':
            result = 'Hot Tie'
            break
        case 'superHotLuckySix':
            result = 'Super Hot Lucky 6'
            break
        case 'hotLuckySix':
            result = 'Hot Lucky 6'
            break
        case 'superHotMegaSix':
            result = 'Super Hot Mega 6'
            break
        case 'hotMegaSix':
            result = 'Hot Mega 6'
            break
        case 'superHotTigerBig':
            result = 'Super Hot Tiger Big'
            break
        case 'hotTigerBig':
            result = 'Hot Tiger Big'
            break
        case 'superHotTigerTie':
            result = 'Super Hot Tiger Tie'
            break
        case 'hotTigerTie':
            result = 'Hot Tiger Tie'
            break
        case 'superHotTiger':
            result = 'Super Hot Tiger'
            break
        case 'superHotTigerSmall':
            result = 'Super Hot Tiger Small'
            break
        case 'hotTiger':
            result = 'Hot Tiger'
            break
        case 'hotTigerSmall':
            result = 'Hot Tiger Small'
            break
        case 'superHotTigerPair':
            result = 'Super Hot Tiger Pair'
            break
        case 'hotTigerPair':
            result = 'Hot Tiger Pair'
            break
        default:
    }
    return result
}
