import React, { useState } from 'react'
import { useSubscribe } from '../../api'
import LinearProgress from '@mui/material/LinearProgress'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import Jackpot from './Jackpot'
import JackpotList from './JackpotList'

const madeStyles = makeStyles((theme) => ({
    root: {

    },
    createButton: {
        display: 'flex',
        margin: 5,
    },
    tabRoot: {
        display: 'flex',
        flex: '1 1'
    },
    tabIndicator: {
        top: 0,
        height: 5,
        transition: 'none',
    },
    selectedTab: {
        backgroundColor: '#000'
    },
    title: {
        padding: 26,
        fontSize: '3em',
        color: 'aliceblue',
    }
}))

const Jackpots = ({ authorize }) => {
    const styles = madeStyles()
    const [jackpots, jackpotsSocket] = useSubscribe('jackpots/*', authorize)
    const active = jackpotsSocket && jackpotsSocket.readyState === WebSocket.OPEN
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = useState(null)

    const handleClickOpen = () => {
        setSelected(null)
        setOpen(true)
    }

    const handleSelect = (jackpot) => {
        setSelected(jackpot)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    if (!jackpots ||
        !active) {
        return <LinearProgress />
    }

    return <div className={styles.root}>
        <AppBar position="sticky">
            <Tabs classes={{ indicator: styles.tabIndicator }}
                value={0}
                indicatorColor="primary"
                textColor="primary"
                variant={'standard'}>
                <Tab disableTouchRipple
                    label="Jackpots"
                    classes={{ selected: styles.selectedTab }}
                    component={Link}
                    to="/dashboard" />
            </Tabs>
        </AppBar>
        {jackpots.length < 40 && <Button className={styles.createButton}
            color="primary"
            variant="contained"
            component="label"
            onClick={handleClickOpen}>
            Create jackpot
        </Button>}
        <Jackpot open={open}
            editing={selected}
            handleClose={handleClose} />
        <JackpotList jackpots={jackpots}
            editDialog={handleSelect} />
    </div>
}

export default Jackpots