import React, { useState, useRef } from "react"
import { isUndefined } from "lodash"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@mui/styles"
import { TextField, Modal, Fade } from "@mui/material"
import KeyboardIcon from "@mui/icons-material/Keyboard"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import { api } from "../../api"

const rootStyles = makeStyles((theme) => ({
    modalContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modal: {
        position: "relative",
        backgroundColor: "#1B1B1F",
        color: "#fff",
        textAlign: "center",
        borderRadius: 25,
        padding: 50,
        outline: 0,
    },
    modalHeader: {
        backgroundColor: "#111314",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        borderRadius: "25px 25px 0 0",
    },
    modalPrompt: {
        marginTop: "3em",
        paddingTop: "1em",
        fontSize: 13,
    },
    modalButtonRow: {
        display: "flex",
        justifyContent: "space-evenly",
        marginTop: "5%",
        "& button": {
            //width: '33%',
            height: "4.2rem",
            backgroundColor: "#303238",
            color: "#fff",
            boxShadow: "0px 1px 1px #161D251A",
            border: "1px solid #5F606A",
            fontSize: "1.5rem",
        },
    },
    modalInput: {
        width: "90%",
        height: "4.2rem",
        backgroundColor: "#303238",
        boxShadow: "0px 1px 2px #00000014",
        border: "1px solid #5F606A",
        borderRadius: 4,
        fontSize: "2rem",
        "& .MuiOutlinedInput-root": { height: "4.2rem" },
    },
}));

const PitBossConfirmation = ({ setModalObj, modalObj }) => {
    const s = rootStyles();
    const { t } = useTranslation("common");

    const [swipeCard, setSwipeCard] = useState("");
    //Rare case whereby on some certain card-readers, a half swipe will return a single 'F' char.
    //We should ignore this by reseting the value
    if (swipeCard === "F") {
        setModalObj({
            ...modalObj,
            modalMsg: (
                <span style={{ color: "red" }}>
                    Card swipe failed, please try again
                </span>
            ),
        });
        setSwipeCard("");
    }
    console.log("swipeCard", swipeCard);

    const [useSwipeAuth, setUseSwipeAuth] = useState(true);
    const [pitbossAccount, setPitbossAccount] = useState("");
    const [pitbossPassword, setPitbossPassword] = useState("");
    const cardField = useRef(null);

    const reFocusCardField = () => {
        if (useSwipeAuth && cardField.current) cardField.current.focus();
    };

    const modalConfirmPress = async () => {
        try {
            const response = useSwipeAuth
                ? await api
                    .post("authorize/card", {
                        json: {
                            card: swipeCard.slice(1, swipeCard.length - 1),
                        },
                    })
                    .json()
                : await api
                    .post("authorize", {
                        json: {
                            account: pitbossAccount,
                            password: pitbossPassword,
                        },
                    })
                    .json();
            if (response.roles.indexOf('pitboss') >= 0) {
                if (
                    !isUndefined(modalObj.confirm) &&
                    typeof modalObj.confirm === "function"
                )
                    modalObj.confirm();
            } else {
                console.log("staff member not authorized to double confirm");
                setModalObj({
                    ...modalObj,
                    modalMsg: (
                        <span style={{ color: "red" }}>
                            You do not have the right permissions to proceed, please try again
                        </span>
                    ),
                });
                setSwipeCard("");
                setPitbossAccount("");
                setPitbossPassword("");
                reFocusCardField();
                return;
            }
            setSwipeCard("");
            setPitbossAccount("");
            setPitbossPassword("");
            setModalObj({ ...modalObj, show: false });
        } catch (e) {
            console.warn("unabled to authorize card", e);
            setModalObj({
                ...modalObj,
                modalMsg: (
                    <span style={{ color: "red" }}>
                        Failed to authorize, please try again
                    </span>
                ),
            });
            setSwipeCard("");
            setPitbossAccount("");
            setPitbossPassword("");
        }
        reFocusCardField();
    };

    const modalCancelPress = async () => {
        if (!isUndefined(modalObj.cancel) && typeof modalObj.cancel === "function")
            modalObj.cancel();
        setModalObj({ ...modalObj, show: false });
    };

    return (
        <Modal
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                reFocusCardField();
            }}
            className={s.modalContainer}
            open={modalObj.show}
            style={{ backgroundColor: "#000", top: "-25vh" }}
        >
            <Fade in={modalObj.show}>
                <div className={s.modal}>
                    <div className={s.modalHeader}>
                        <h1> PIT BOSS CONFIRMATION </h1>
                    </div>
                    <div className={s.modalPrompt}>
                        <h2 style={{ fontWeight: 200, fontSize: "3.5vh" }}>
                            {modalObj.modalMsg}
                            <br />
                            {t("dealer.pleaseSwipe")}
                        </h2>
                        <div>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    modalConfirmPress();
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {useSwipeAuth && (
                                        <>
                                            <TextField
                                                className={s.modalInput}
                                                required
                                                autoFocus
                                                InputLabelProps={{ shrink: true }}
                                                margin="dense"
                                                id="card"
                                                label="Card"
                                                type="password"
                                                fullWidth
                                                variant="outlined"
                                                onChange={(e) => setSwipeCard(e.target.value)}
                                                value={swipeCard}
                                                error={swipeCard === ""}
                                                InputProps={{
                                                    inputProps: { ref: cardField },
                                                }}
                                            />
                                            <KeyboardIcon
                                                onClick={() => setUseSwipeAuth(false)}
                                                style={{ fontSize: "50px", marginLeft: 10 }}
                                            />
                                        </>
                                    )}
                                    {!useSwipeAuth && (
                                        <>
                                            <div style={{ margin: 0, padding: 0 }}>
                                                <TextField
                                                    className={s.modalInput}
                                                    required
                                                    autoFocus
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="dense"
                                                    id="user"
                                                    label="Account"
                                                    type="text"
                                                    fullWidth
                                                    onChange={(e) => setPitbossAccount(e.target.value)}
                                                    value={pitbossAccount}
                                                    error={pitbossAccount === ""}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    className={s.modalInput}
                                                    required
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="dense"
                                                    id="pass"
                                                    label="Password"
                                                    type="password"
                                                    onChange={(e) => setPitbossPassword(e.target.value)}
                                                    value={pitbossPassword}
                                                    error={pitbossPassword === ""}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </div>
                                            <CreditCardIcon
                                                onClick={() => setUseSwipeAuth(true)}
                                                style={{ fontSize: "50px", marginLeft: 10 }}
                                            />
                                        </>
                                    )}
                                </div>
                            </form>
                            <div className={s.modalButtonRow}>
                                <button
                                    style={{ borderRadius: 10 }}
                                    onClick={(e) => {
                                        modalCancelPress();
                                    }}
                                >
                                    {" "}
                                    {t("global.cancel")}{" "}
                                </button>
                                <button
                                    style={{ borderRadius: 10 }}
                                    onClick={(e) => {
                                        modalConfirmPress();
                                    }}
                                >
                                    {" "}
                                    {t("global.confirm")}{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default PitBossConfirmation;