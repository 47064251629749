import { createContext, useContext, useState } from 'react'

const Context = createContext()

export const useAppContext = () => useContext(Context)

export const AppContext = ({ children }) => {
    const [gameVariantFilter, setGameVariantFilter] = useState('blackjackAnyPairSlot')

    return (
        <Context.Provider
            value={{
                gameVariantFilter: gameVariantFilter,
                setGameVariantFilter: setGameVariantFilter,
            }}
        >
            {children}
        </Context.Provider>
    )
}
