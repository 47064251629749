import React, { useState } from 'react'
import moment from 'moment'
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/dark.css"
import "./CustomFlatpickr.css"
import useInterval from '../useInterval'
import Switch from 'rc-switch'
import './CustomSwitch.css'
import { Pause, Play } from 'react-feather'

const DateSelectorInline = ({
    dateChange,
    noAutoUpdate = false,
    rangeStart = false,
    rangeEnd = false,
    options = false,
    externalAutoUpdateEnabled = false,
    toggleAutoUpdate = null
}) => {
    const [picker, setPicker] = useState(null)
    const [range, setRange] = useState(rangeStart && rangeEnd ? [rangeStart, rangeEnd] : null)
    const [lastUpdated, setLastUpdated] = useState(null)
    const [autoUpdateEnabled, setAutoUpdateEnabled] = useState(true)
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [hoverLive, setHoverLive] = useState(false)
    const isLive = range && endOfToday === range[1]

    if (!lastUpdated) {
        setLastUpdated(moment.now())
    }

    useInterval(() => {
        // console.log("live", isLive, endOfToday, range[1])
        if (range && isLive && autoUpdateEnabled && externalAutoUpdateEnabled && !noAutoUpdate) {
            const start = range[0]
            const end = moment().endOf('day').unix()
            dateChange(start, end, true)
            setLastUpdated(moment.now())
            // console.log("OP->>>>>>>>>>>>>>>>>>", lastUpdated)
        }
    }, 10000)

    if (range && (range[0] !== rangeStart || range[1] !== rangeEnd)) {
        setRange(rangeStart && rangeEnd ? [rangeStart, rangeEnd] : null)
        return <></>
    }

    const todayToToday = [
        moment().startOf('day').unix() * 1000,
        moment().endOf('day').unix() * 1000
    ]
    const rangeToRange = !range ?
        [] :
        [
            moment.unix(range[0]).startOf('day').unix() * 1000,
            moment.unix(range[1]).endOf('day').unix() * 1000
        ]

    const defaultDate = range ? rangeToRange : todayToToday
    if (picker) {
        picker.flatpickr.setDate(defaultDate)
    }

    return <div style={{
        display: 'flex',
        maxWidth: '300px',
        flexDirection: 'column'
    }}> <div className="historyLiveIndicators" style={{ display: 'flex', marginBottom: 15 }}>
            <div
                onMouseEnter={() => {
                    setHoverLive(true)
                }}
                onMouseLeave={() => {
                    setHoverLive(false)
                }}
                onClick={() => {
                    dateChange(moment().startOf('day').unix(), moment().endOf('day').unix())
                }}
                style={{
                    backgroundColor: isLive || hoverLive ? ((autoUpdateEnabled && externalAutoUpdateEnabled) || noAutoUpdate) ? 'green' : 'red' : 'rgb(98, 98, 98)',
                    color: isLive || hoverLive ? '#fff' : 'rgb(56 56 56)',
                    width: '50%',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '17px',
                    padding: 5,
                    borderRadius: '5px 0px 0 5px',
                    cursor: 'pointer',
                }}>
                {hoverLive && !isLive ? 'GO LIVE' : 'LIVE'}
                {((!autoUpdateEnabled || !externalAutoUpdateEnabled) && isLive && !noAutoUpdate) && <span style={{
                    fontSize: 9,
                    position: 'absolute',
                    top: 35
                }}>(paused)</span>}
            </div>
            <div style={{
                backgroundColor: !isLive && !hoverLive ? 'rgb(0 161 255)' : 'rgb(98, 98, 98)',
                color: !isLive && !hoverLive ? '#fff' : 'rgb(56 56 56)',
                width: '50%',
                margin: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '17px',
                padding: 5,
                borderRadius: '0px 5px 5px 0px'
            }}>
                HISTORY
            </div>
        </div>
        {(isLive && lastUpdated && !noAutoUpdate) && <div style={{ marginBottom: 10 }}>
            <Switch checked={autoUpdateEnabled && externalAutoUpdateEnabled} onChange={(v) => {
                setAutoUpdateEnabled(v)
                if (toggleAutoUpdate) {
                    toggleAutoUpdate(v)
                }
            }}
                checkedChildren={<Pause style={{ color: 'red' }} />}
                unCheckedChildren={<Play style={{ color: 'green' }} />} />
            {(autoUpdateEnabled && externalAutoUpdateEnabled) && <span style={{ marginLeft: 5 }}>auto update every 10 seconds</span>}
            {(!autoUpdateEnabled || !externalAutoUpdateEnabled) && <span style={{ marginLeft: 5 }}>auto update paused</span>}
        </div>}
        <Flatpickr
            className="form-control"
            options={{
                minDate: moment().startOf('day').subtract(18, 'month').unix() * 1000,
                maxDate: moment().endOf('day').unix() * 1000,
                mode: "range",
                inline: true,
                defaultDate: defaultDate,
                formatDate: (date) => moment().endOf('day').isSame(date, 'day') ? 'Today' : moment(date).format('YYYY-MM-D'),
                ...options && { ...options }
            }}
            onClose={(selectedDates) => {
                const start = moment(selectedDates[0]).unix() <= moment(selectedDates[1]).unix() ?
                    moment(selectedDates[0]).startOf('day').unix() :
                    moment(selectedDates[1]).startOf('day').unix()
                let end = moment(selectedDates[0]).unix() <= moment(selectedDates[1]).unix() ?
                    moment(selectedDates[1]).endOf('day').unix() :
                    moment(selectedDates[0]).endOf('day').unix()

                if (end === endOfToday && start !== startOfToday) {
                    end = moment().endOf('day').subtract(24, 'hours').unix()
                    if (picker) {
                        picker.flatpickr.setDate([start * 1000, end * 1000])
                    }
                }
                console.log("date change")

                dateChange(start, end)
                // console.log('startUnix', moment.unix(start).format('YYYY-MM-DD HH:mm'))
                // console.log('endUnix', moment.unix(end).format('YYYY-MM-DD HH:mm'))
            }}
            ref={reactFlatpickr => {
                setPicker(reactFlatpickr)
            }}
        /></div>
}

export default DateSelectorInline