import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { usePublish, useSubscribe } from '../../api'
import ChaseTheTrend from './form/ChaseTheTrend'
import DealerUI from './form/DealerUI'
import Bet from './form/Bet'
import NDISettings from './form/NDISettings'
import Payouts from './form/Payouts'
import Slots from './form/Slots'
import TrendAnimations from './form/TrendAnimations'
import Trendboard from './form/Trendboard'
import TrendPriority from './form/TrendPriority'
import DeviceNotFound from './DeviceNotFound'
import NDI from './stadium/ndi'
import Tables from './stadium/tables'
import Devices from './stadium/devices'
import RotatorTimers from './stadium/rotatorTimers'
import Main from './Main'
import { pivotIP } from '../../config'

const Device = ({ loading, zones, pits, tables, devices, xstadium, template }) => {
    const { index } = useParams()

    const [settings, settingsSocket] = useSubscribe('settings')
    const [device, deviceSocket] = useSubscribe((!template ? 'devices/' : 'templates/') + index)
    const publish = usePublish((!template ? 'devices/' : 'templates/') + index)
    const active =
        settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN &&
        deviceSocket &&
        deviceSocket.readyState === WebSocket.OPEN

    // tabs
    const [tabIndex, setTabIndex] = useState(0)
    const handleChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex)
    }

    useEffect(() => {
        if (index) {
            setTabIndex(0)
        }
    }, [index])


    const getLayouts = async (setValue) => {
        if (loadingLayouts) {
            return
        }
        setLoadingLayouts(true)
        try {
            const controller = new AbortController()
            const signal = controller.signal
            const timeoutID = setTimeout(function () {
                setValue([])
            }, 20000)
            // const deviceIP = device.data.ip === '127.0.0.1' ? window.location.hostname : device.data.ip
            const content = await (
                await fetch('http://' + pivotIP + '/remote/trendboard/layouts/' + device.index, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    signal,
                })
            ).json()
            clearTimeout(timeoutID)
            setValue(content.layouts)
        } catch (e) {
            console.warn('error getting layouts list', e)
        } finally {
            setLoadingLayouts(false)
        }
    }

    const getNDISources = async (setValue) => {
        if (loadingStreams) {
            return
        }
        setLoadingStreams(true)
        try {
            const controller = new AbortController()
            const signal = controller.signal
            const timeoutID = setTimeout(function () {
                // throw new Error("timeout")
                controller.abort()
                setValue([])
            }, 20000)
            // const deviceIP = device.data.ip === '127.0.0.1' ? window.location.hostname : device.data.ip
            const content = await (
                await fetch('http://' + pivotIP + '/remote/ndi/sources/' + device.index, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    signal,
                })
            ).json()
            clearTimeout(timeoutID)
            setValue(content)
        } catch (e) {
            console.warn('error getting ndi sources list', e)
        } finally {
            setLoadingStreams(false)
        }
    }

    // GET SET Streams
    const [streams, setStreams] = useState(null)
    const [loadingStreams, setLoadingStreams] = useState(null)
    if (
        streams === null &&
        device &&
        device.data &&
        device.data.ip &&
        (device.data.type === 'game' || device.data.type === 'xstadiumContent')
    ) {
        getNDISources(setStreams)
    }

    //GET SET Layouts
    const [layouts, setLayouts] = useState(null)
    const [loadingLayouts, setLoadingLayouts] = useState(null)
    if (layouts === null && device && device.data && device.data.ip && device.data.type === 'game') {
        getLayouts(setLayouts)
    }

    const invalidState =
        !index ||
        (!loading && devices && devices.filter((v) => v.index === index).length === 0) ||
        (device && device.created === 0)

    if (loading || !settings || !device || !active) {
        return <LinearProgress />
    }

    return (
        <Paper
            sx={{
                borderRadius: 0,
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1',
                backgroundColor: 'transparent',
                height: '100%',
            }}
            elevation={0}
        >
            {invalidState ? (
                <Box
                    sx={{
                        position: 'fixed',
                        width: 'fill-available',
                        zIndex: 10,
                        height: '100%',
                    }}
                >
                    <DeviceNotFound xstadium={xstadium} />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            position: 'fixed',
                            width: 'fill-available',
                            zIndex: 10,
                        }}
                    >
                        <Tabs
                            value={tabIndex}
                            onChange={handleChange}
                            variant='scrollable'
                            scrollButtons='auto'
                            sx={{ backgroundColor: '#424242' }}
                        >
                            <Tab label='Main' />
                            {device.data.type === 'game' &&
                                !isEmpty(device.data.gameType) && [
                                    <Tab key='minMaxBet' label='Min Max Bet' />,
                                    <Tab key='trendboard' label='Trendboard' />,
                                    device.data.gameType === 'baccarat' && [<Tab label='Trend Priority' />],
                                    (device.data.gameType === 'baccarat' || device.data.gameType === 'roulette') && [
                                        <Tab label='Trend Animation' />,
                                    ],
                                    device.data.gameType === 'baccarat' && [<Tab label='Chase The Trend' />],
                                    [<Tab key='payouts' label='Payouts' />],
                                    !settings.data.xstadium.etgMode &&
                                    device.data.gameType === 'baccarat' && [<Tab key='dealer' label='Dealer UI' />],
                                    !settings.data.xstadium.etgMode && <Tab key='ndi' label='NDI Settings' />,
                                    ((device.data.gameType === 'baccarat' &&
                                        device.data.gameVariant === 'lucky6Slot') ||
                                        (device.data.gameType === 'blackjack' &&
                                            device.data.gameVariant === 'anyPairSlot')) && [<Tab label='Slots' />],
                                ]}
                            {device.data.type === 'xstadiumContent' && [
                                <Tab key='tables' label='Tables' />,
                                <Tab key='ndiCapture' label='NDI Capture' />,
                                <Tab key='ndi' label='NDI' />,
                                <Tab key='devices' label='Devices' />,
                            ]}
                            {device.data.type === 'xstadiumRotator' && [
                                <Tab key='timers' label='Timers' />,
                                <Tab key='ndi' label='NDI' />,
                            ]}
                            {device.data.type === 'xstadiumOthers' && [<Tab key='ndi' label='NDI' />]}
                        </Tabs>
                    </Box>

                    {tabIndex === 0 && (
                        <Main
                            publish={publish}
                            device={device}
                            devices={devices}
                            tables={tables}
                            zones={zones}
                            pits={pits}
                            settings={settings}
                            xstadium={xstadium}
                        />
                    )}

                    {device.data.type === 'game' && (
                        <>
                            {tabIndex === 1 && (
                                <>
                                    {device && (
                                        <Bet
                                            publish={publish}
                                            table={device}
                                            settings={settings}
                                            xstadium={xstadium}
                                        />
                                    )}
                                </>
                            )}

                            {tabIndex === 2 && (
                                <>
                                    {device && (
                                        <Trendboard
                                            publish={publish}
                                            table={device}
                                            layouts={layouts}
                                            streams={streams}
                                            xstadium={xstadium}
                                        />
                                    )}
                                </>
                            )}

                            {device.data.gameType === 'baccarat' && tabIndex === 3 && (
                                <>
                                    {device && (
                                        <TrendPriority
                                            publish={publish}
                                            tables={devices}
                                            table={device}
                                            settings={settings}
                                            xstadium={xstadium}
                                        />
                                    )}
                                </>
                            )}

                            {((device.data.gameType === 'baccarat' && tabIndex === 4) ||
                                (device.data.gameType === 'roulette' && tabIndex === 3)) && (
                                    <>
                                        {device && (
                                            <TrendAnimations
                                                publish={publish}
                                                tables={devices}
                                                table={device}
                                                settings={settings}
                                                xstadium={xstadium}
                                            />
                                        )}
                                    </>
                                )}

                            {device.data.gameType === 'baccarat' && tabIndex === 5 && (
                                <>
                                    {device && (
                                        <ChaseTheTrend
                                            publish={publish}
                                            tables={devices}
                                            table={device}
                                            settings={settings}
                                            xstadium={xstadium}
                                        />
                                    )}
                                </>
                            )}

                            {((device.data.gameType === 'baccarat' && tabIndex === 6) ||
                                (device.data.gameType === 'roulette' && tabIndex === 4) ||
                                ((device.data.gameType === 'blackjack' ||
                                    device.data.gameType === 'sicbo' ||
                                    device.data.gameType === 'war') &&
                                    tabIndex === 3)) && (
                                    <>
                                        {device && (
                                            <Payouts
                                                publish={publish}
                                                tables={devices}
                                                table={device}
                                                layouts={layouts}
                                                settings={settings}
                                                xstadium={xstadium}
                                            />
                                        )}
                                    </>
                                )}

                            {device.data.gameType === 'baccarat' &&
                                !settings.data.xstadium.etgMode &&
                                tabIndex === 7 && (
                                    <>{device && <DealerUI publish={publish}
                                        settings={settings}
                                        table={device}
                                        xstadium={xstadium} />}</>
                                )}

                            {((device.data.gameType === 'baccarat' && tabIndex === 8) ||
                                (((device.data.gameType === 'blackjack' && !settings.data.xstadium.etgMode) ||
                                    device.data.gameType === 'sicbo') &&
                                    tabIndex === 4) ||
                                (device.data.gameType === 'roulette' && tabIndex === 5) ||
                                (device.data.gameType === 'war' && tabIndex === 4)) && (
                                    <>{device && <NDISettings publish={publish} table={device} xstadium={xstadium} />}</>
                                )}

                            {((device.data.gameType === 'baccarat' &&
                                device.data.gameVariant === 'lucky6Slot' &&
                                ((!settings.data.xstadium.etgMode && tabIndex === 9) ||
                                    (settings.data.xstadium.etgMode && tabIndex === 7))) ||
                                (device.data.gameType === 'blackjack' &&
                                    device.data.gameVariant === 'anyPairSlot' &&
                                    ((!settings.data.xstadium.etgMode && tabIndex === 5) ||
                                        (settings.data.xstadium.etgMode && tabIndex === 4)))) && (
                                    <>{device && <Slots publish={publish} device={device} xstadium={xstadium} />}</>
                                )}
                        </>
                    )}

                    {device.data.type === 'xstadiumOthers' && (
                        <>
                            {tabIndex === 1 && (
                                <>{device && <NDISettings publish={publish} table={device} xstadium={xstadium} />}</>
                            )}
                        </>
                    )}

                    {device.data.type === 'xstadiumContent' && (
                        <>
                            {tabIndex === 1 && (
                                <>{device && <Tables publish={publish} device={device} xstadium={xstadium} />}</>
                            )}
                            {tabIndex === 2 && (
                                <>
                                    {device && (
                                        <NDI publish={publish} device={device} streams={streams} xstadium={xstadium} />
                                    )}
                                </>
                            )}
                            {tabIndex === 3 && (
                                <>{device && <NDISettings publish={publish} table={device} xstadium={xstadium} />}</>
                            )}
                            {tabIndex === 4 && (
                                <>{device && <Devices publish={publish} device={device} xstadium={xstadium} />}</>
                            )}
                        </>
                    )}
                    {device.data.type === 'xstadiumRotator' && (
                        <>
                            {tabIndex === 1 && (
                                <>{device && <RotatorTimers publish={publish} device={device} xstadium={xstadium} />}</>
                            )}
                            {tabIndex === 2 && (
                                <>{device && <NDISettings publish={publish} table={device} xstadium={xstadium} />}</>
                            )}
                        </>
                    )}
                </>
            )}
        </Paper>
    )
}

export default Device
