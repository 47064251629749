import React, { useContext } from "react";
import moment from "moment";
import { DUIContext } from "./Dealer";

const EOD = () => {
  const { setEODViewOpen } = useContext(DUIContext);
  return (
    <div
      style={{
        backgroundColor: "rgba(7, 6, 6, 0.5)",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1001,
      }}
      onClick={() => {
        setEODViewOpen(false);
      }}
    >
      <div
        style={{
          height: "calc(100% - 50px)",
          width: "calc(100% - 50px)",
          borderRadius: 20,
          objectFit: "contain",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#666",
            height: "40vh",
            width: "60vh",
            color: "#ccc",
            position: "relative",
            borderRadius: "30px",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              fontSize: "28px",
              fontWeight: 700,
              marginTop: "20px",
            }}
          >
            End of Day Summary
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              fontSize: "20px",
              color: "#000",
              marginTop: "10px",
            }}
          >
            As of {moment().format("YYYY-MM-DD HH:mm")}
          </div>
          <div
            style={{
              height: "22vh",
              fontSize: "18px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
                gap: 10,
                marginTop: 30,
              }}
            >
              <div></div>
              <div>Cash:</div>
              <div>Vouchers:</div>
              <div>Chips:</div>
              <div>Credit:</div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
                gap: 10,
                marginTop: 30,
              }}
            >
              <div>INCOMING</div>
              <div>$76,000</div>
              <div>$350,000</div>
              <div>$703,000</div>
              <div>$120,000</div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
                gap: 10,
                marginTop: 30,
              }}
            >
              <div>OUTGOING</div>
              <div>--</div>
              <div>$240,000</div>
              <div>$560,000</div>
              <div>$83,000</div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
                gap: 10,
                marginTop: 30,
              }}
            >
              <div>NET</div>
              <div>$76,000</div>
              <div>$110,000</div>
              <div>$143,000</div>
              <div>$37,100</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EOD;