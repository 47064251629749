import React, { memo } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import R404 from '../404'
import Chips from './Chips'
import SelectDevice from './SelectDevice'

export default memo(() => {
    const role = window.localStorage.getItem('role')

    if (role !== 'root' && role !== 'masterAdmin') {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            <Routes>
                <Route exact path='/' element={<Navigate to='/xview/selectdevice' replace />} />
                <Route exact path='/selectdevice' element={<SelectDevice />} />
                <Route exact path='/chipresult' element={<Chips />} />
                <Route path='*' element={<R404 basePath='xview/' />} />
            </Routes>
        </>
    )
})
