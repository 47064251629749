import React, { useState } from 'react'
import { zipObject } from 'lodash'
import { useLocation } from 'react-router-dom'
import LastPageIcon from '@mui/icons-material/LastPage'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import ViewComfyIcon from '@mui/icons-material/ViewComfy'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import { styled } from '@mui/material/styles'

import { containerTypeColor } from '../util/ContainerTypeColor'
import Icons from './icons/Icons'

export const reservedFields = ['index', 'type', 'containerIndex', 'displayName', 'gameType', 'gameVariant'].map(v => v.toLowerCase())

let $primary = containerTypeColor.casino,
    $success = containerTypeColor.zone,
    $danger = containerTypeColor.pit,
    $warning = containerTypeColor.table,
    $info = containerTypeColor.device
const PREFIX = 'tree';
const classes = {
    list: `${PREFIX}-list`,
    icon: `${PREFIX}-icon`,
    branchToggle: `${PREFIX}-branchToggle`,
    textOverflow: `${PREFIX}-textOverflow`,
}
const Root = styled('ul', {
    shouldForwardProp: (prop) => true
})(({ theme, props }) => ({
    [`&.${classes.list}`]: {
        listStyle: 'none',
        borderLeft: props.level === 0 ? '' : '1px solid ' + props.parentBranchColor,

        paddingLeft: props.level === 0 ? 5 : 12,
        cursor: 'pointer',
        marginLeft: props.level === 0 ? 0 : 12,
        margin: 0,
        '& li': {
            color: props.branchColor,
        },
        '& li p': {
            margin: 0,
            marginLeft: 7,
            color: '#f0f0f0',
            fontSize: 14,
            fontWeight: 100,
        },
        '& li.branch': {
            height: 35, display: 'flex', alignItems: 'flex-end',
        },

        '& li.device': {
            color: props.deviceColor,
            display: 'none'
        },
        '& p:hover': {
            color: '#fff'
        },
        '& a:link': { color: 'inherit' },
        '& a:active': { color: 'inherit' },
        '& a:visited': { color: 'inherit' },
        '& a:hover': { color: 'inherit' },
    },
    [`& .${classes.icon}`]: {
        verticalAlign: 'middle', marginLeft: '-19px',
    },
    [`& .${classes.branchToggle}`]: {
        color: props.branchColor,
    },
    [`& .${classes.textOverflow}`]: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: '0 !important',
        marginLeft: '10px !important'
    },
}))

const TreeView = ({ Data, Label, setSelectedBranch, selectionMustBeDevice = false }) => {
    const [treeMenuExpandedIndex, setTreeMenuExpandedIndex] = useState(window.localStorage.getItem('treeMenuExpandedIndex') ? JSON.parse(window.localStorage.getItem('treeMenuExpandedIndex')) : {})

    const saveExpanded = () => {
        window.localStorage.setItem('treeMenuExpandedIndex', JSON.stringify(treeMenuExpandedIndex))
        setTreeMenuExpandedIndex(treeMenuExpandedIndex)
    }

    const expandAllBranches = () => {
        const getAllIndexes = (obj) => {
            let indexes = []
            Object.keys(obj).forEach(k => {
                if (k === 'index') indexes.push(obj[k])
                if (typeof obj[k] === 'object') indexes.push(getAllIndexes(obj[k]).flat())
            })
            return indexes.flat()
        }

        const indexes = getAllIndexes(Data)
        const obj = zipObject(indexes, Array(indexes.length).fill(true))
        setTreeMenuExpandedIndex(obj)
        window.localStorage.setItem('treeMenuExpandedIndex', JSON.stringify(obj))

    }

    const collapseAllBranches = () => {
        setTreeMenuExpandedIndex({})
        window.localStorage.setItem('treeMenuExpandedIndex', JSON.stringify({}))
    }

    const isAllBranchesCollapsed = Object.keys(treeMenuExpandedIndex).length === 0 ? true : false
    const Branch = ({ data, name, level, saveExpanded, selectionMustBeDevice }) => {
        const { pathname } = useLocation()
        const isOnOverviewPage = pathname.split('/')[2] === 'overview'
        const viewDetails = pathname.split('/')[3]
        const branches = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()))
        const getBranchColor = (level) => {
            return level === 0 ? $primary : level === 1 ? $success : level === 2 ? $danger : level === 3 ? $warning : level === 4 ? $info : '#fff'
        }
        const branchColor = getBranchColor(level)
        const parentBranchColor = getBranchColor(level - 1)
        const deviceColor = getBranchColor(4)
        const styleProps = {
            level: level,
            parentBranchColor: parentBranchColor,
            branchColor: branchColor,
            deviceColor: deviceColor
        }
        const containerType = data['type']
        const displayName = data['displayName'] ? data['displayName'] : name
        const [index] = useState(containerType === 'Casino' ? 'root' : data['index'])
        const [branchExpanded, setBranchExpanded] = useState(level === 0 ? true : typeof treeMenuExpandedIndex[index] !== 'undefined' ? treeMenuExpandedIndex[index] : false)
        const isDevice = (level === 4 || containerType === 'Device') ? true : false
        const hasChildren = (typeof (data) === 'object' && Object.keys(data).filter(x => { return !reservedFields.includes(x.toLowerCase()) }).length > 0) ? true : false

        const branchClick = () => {
            if (viewDetails && containerType === 'Table') {
                return
            }

            const getDescendantDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device').map(v => getDescendantDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantBacDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'baccarat').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'baccarat').map(v => getDescendantBacDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantBJDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'blackjack').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'blackjack').map(v => getDescendantBJDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantRouDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'roulette').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'roulette').map(v => getDescendantBJDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantSicboDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'sicbo').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'sicbo').map(v => getDescendantBJDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantWarDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'war').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'war').map(v => getDescendantBJDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantBacStandardDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'baccarat' && data[v].gameVariant === 'standard').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'baccarat' && data[v].gameVariant !== 'standard').map(v => getDescendantBacStandardDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantBacLucky6DevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'baccarat' && data[v].gameVariant === 'lucky6').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'baccarat' && data[v].gameVariant !== 'lucky6').map(v => getDescendantBacLucky6DevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantBacLucky6SlotDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'baccarat' && data[v].gameVariant === 'lucky6Slot').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'baccarat' && data[v].gameVariant !== 'lucky6Slot').map(v => getDescendantBacLucky6DevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            } 

            const getDescendantBacMega6DevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'baccarat' && data[v].gameVariant === 'mega6').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'baccarat' && data[v].gameVariant !== 'mega6').map(v => getDescendantBacMega6DevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantBacTigerDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'baccarat' && data[v].gameVariant === 'tiger').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'baccarat' && data[v].gameVariant !== 'tiger').map(v => getDescendantBacTigerDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantBJStandardDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'blackjack' && data[v].gameVariant === 'standard').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'blackjack' && data[v].gameVariant !== 'standard').map(v => getDescendantBJStandardDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantBJAnyPairSlotDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'blackjack' && data[v].gameVariant === 'anyPairSlot').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'blackjack' && data[v].gameVariant !== 'anyPairSlot').map(v => getDescendantBJStandardDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantRouStandardDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'roulette' && data[v].gameVariant === 'standard').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'roulette' && data[v].gameVariant !== 'standard').map(v => getDescendantBJStandardDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantSicboStandardDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'sicbo' && data[v].gameVariant === 'standard').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'sicbo' && data[v].gameVariant !== 'standard').map(v => getDescendantBJStandardDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getDescendantWarEtgDevicesFromData = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType === 'war' && data[v].gameVariant === 'etg').map(v => data[v].index)
                const descendantDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type !== 'Device' && data[v].gameType !== 'war' && data[v].gameVariant !== 'etg').map(v => getDescendantBJStandardDevicesFromData(data[v]))
                return [...immediateDevices, ...descendantDevices].flat()
            }

            const getGameType = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType && data[v].gameVariant).map(v => data[v])
                return immediateDevices && immediateDevices.length > 0 ? immediateDevices[0].gameType : ''
            }

            const getGameVariant = (data) => {
                const immediateDevices = Object.keys(data).filter(v => !reservedFields.includes(v.toLowerCase()) && data[v].type === 'Device' && data[v].gameType && data[v].gameVariant).map(v => data[v])
                return immediateDevices && immediateDevices.length > 0 ? immediateDevices[0].gameVariant : ''
            }

            setSelectedBranch({
                branchIndex: data.index,
                branchType: data.type,
                branchName: data.displayName,
                branchColor: branchColor,
                gameType: containerType === 'Table' ? getGameType(data) : '',
                gameVariant: containerType === 'Table' ? getGameVariant(data) : '',
                descendantDevices:
                    containerType === 'Device' ? [data.index] : selectionMustBeDevice ? [] : getDescendantDevicesFromData(data),
                descendantDevicesByGameType:
                    containerType === 'Device'
                        ? [data.index]
                        : selectionMustBeDevice
                        ? []
                        : {
                              baccarat: getDescendantBacDevicesFromData(data),
                              blackjack: getDescendantBJDevicesFromData(data),
                              roulette: getDescendantRouDevicesFromData(data),
                              sicbo: getDescendantSicboDevicesFromData(data),
                              war: getDescendantWarDevicesFromData(data),
                          },
                descendantDevicesByGameVariant:
                    containerType === 'Device'
                        ? [data.index]
                        : selectionMustBeDevice
                        ? []
                        : {
                              baccarat: {
                                  standard: getDescendantBacStandardDevicesFromData(data),
                                  lucky6: getDescendantBacLucky6DevicesFromData(data),
                                  lucky6Slot: getDescendantBacLucky6SlotDevicesFromData(data),
                                  mega6: getDescendantBacMega6DevicesFromData(data),
                                  tiger: getDescendantBacTigerDevicesFromData(data),
                              },
                              blackjack: {
                                  standard: getDescendantBJStandardDevicesFromData(data),
                                  anyPairSlot: getDescendantBJAnyPairSlotDevicesFromData(data),
                              },
                              roulette: {
                                  standard: getDescendantRouStandardDevicesFromData(data),
                              },
                              sicbo: {
                                  standard: getDescendantSicboStandardDevicesFromData(data),
                              },
                              war: {
                                  etg: getDescendantWarEtgDevicesFromData(data),
                              },
                          },
            })
        }
        const branchExpand = () => {
            setBranchExpanded(true)
            treeMenuExpandedIndex[index] = true
            saveExpanded()
        }

        const branchCollapse = () => {
            setBranchExpanded(false)
            treeMenuExpandedIndex[index] = false
            saveExpanded()
        }

        const icons = {
            arrow_drop_down: <Icons className={classes.icon + ' ' + classes.branchToggle} onClick={(e) => { branchCollapse(); e.stopPropagation() }} style={{ fontSize: 37, marginLeft: -18 }}>arrow_drop_down</Icons>,
            arrow_right: <Icons className={classes.icon + ' ' + classes.branchToggle} onClick={(e) => { branchExpand(); e.stopPropagation() }} style={{ fontSize: 37, marginLeft: -18 }}>arrow_right</Icons>,
            fiber_manual_record: <Icons className={classes.icon} style={{ fontSize: 10, margin: 16, marginLeft: -5, color: branchColor }}>fiber_manual_record</Icons>,
            more_vert: <MoreVertIcon className={classes.icon} style={{ fontSize: 15, margin: 16, marginLeft: 0, color: branchColor }} />,
            power: <Icons className={classes.icon} style={{ padding: '0 2px 0 7px', marginLeft: -10, color: deviceColor }}>power</Icons>,
            customIcon: <Icons onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} icon={containerType.toLowerCase()} size="18px" />,
            casino: <ViewComfyIcon onClick={isOnOverviewPage ? null : (e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} style={{ fontSize: 25, }} />,
            zone: <ViewModuleIcon onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} style={{ fontSize: 25, }} />,
            pit: <ViewColumnIcon onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} style={{ fontSize: 25, }} />,
            table: <ViewCarouselIcon onClick={(e) => { branchExpanded ? branchCollapse() : branchExpand(); e.stopPropagation() }} style={{ fontSize: 25, opacity: viewDetails ? '20%' : '100%' }} />,
        }

        return <Root className={classes.list} props={styleProps}>
            <li className={isDevice ? "branch device" : "branch"}
                style={{ position: 'relative' }}
            >
                {containerType === 'Casino' && !isOnOverviewPage && <div style={{ position: 'absolute', top: 8, right: 4 }}>
                    <LastPageIcon style={{
                        transform: isAllBranchesCollapsed ? 'rotate(90deg)' : 'rotate(270deg)',
                        height: 25,
                        width: 25
                    }}
                        onClick={() => isAllBranchesCollapsed ? expandAllBranches() : collapseAllBranches()} />
                </div>}
                {!isOnOverviewPage &&
                    <div style={{ position: 'absolute', left: 6, bottom: -27, pointerEvents: 'none' }}>
                    {!isDevice && hasChildren && !branchExpanded && containerType !== 'Table' && icons.more_vert}
                    </div>
                }

                {isDevice ? icons.power : icons[containerType.toLowerCase()]}

                <p onClick={() => { branchClick() }} >{displayName}</p>

            </li>
            {!isOnOverviewPage && branchExpanded && branches && branches.map(v =>
                <Branch key={v}
                    data={data[v]}
                    name={v}
                    level={level + 1}
                    saveExpanded={saveExpanded}
                    setSelectedBranch={setSelectedBranch}
                    selectionMustBeDevice={selectionMustBeDevice} />)}
        </Root >
    }


    return <Branch key="brah"
        data={Data}
        name={Label}
        level={0}
        saveExpanded={saveExpanded}
        setSelectedBranch={setSelectedBranch}
        selectionMustBeDevice={selectionMustBeDevice} />
}

export default TreeView