import React, { useState } from 'react'
import { isEmpty, startCase } from 'lodash'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useNavigate, useParams } from 'react-router-dom'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import CreateIcon from '@mui/icons-material/Create'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import SnackbarContent from '@mui/material/SnackbarContent'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'

import { useSubscribe, unpublish } from '../../api'
import { publish, fetchDecoded } from '../../api'
import { xstadiumIP } from '../../config'
import AlertDialog from '../../xtrend/components/AlertDialog'

var randomColor = require('randomcolor')

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            colorScheme: 'dark',
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const rootStyles = makeStyles((theme) => ({
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
    },
    warningIcon: {
        color: '#f0cf81',
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: '100',
    },
    formSubmit: {
        marginTop: 20,
        marginLeft: 10,
    },
    formCancel: {
        marginTop: 20,
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

const nanoToMomentTime = (time) => moment.unix(time / 1000000000)

function sameDate(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
}

function spliceNoMutate(myArray, indexToRemove) {
    return myArray.slice(0, indexToRemove).concat(myArray.slice(indexToRemove + 1))
}

function displayTimeUnit(unit) {
    const str = String(unit)
    if (str.length < 2) {
        return '0' + str
    }

    return str
}

function dateOfTrack(track) {
    return moment('2022-04-17T' + displayTimeUnit(track.start.hour) + ':' + displayTimeUnit(track.start.minutes))
}

function compareSortTracksFunc(a, b) {
    const dateA = dateOfTrack(a)
    const dateB = dateOfTrack(b)
    if (dateA.isBefore(dateB)) {
        return -1
    }
    if (dateA.isAfter(dateB)) {
        return 1
    }
    return 0
}

const Track = ({
    loading,
    setTracksDialog,
    setSelectedTrackType,
    trackType,
    list,
    editTrack,
    removeTrack,
    isPlaylistPlaying,
}) => {
    return (
        <Box
            sx={{
                width: '100%',
                padding: '.75rem 1.25rem',
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #646464',
                borderRadius: '5px',
                gap: '1rem',
            }}
        >
            <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography>{startCase(trackType)}</Typography>
                <IconButton
                    color='primary'
                    size='large'
                    onClick={() => {
                        setTracksDialog(true)
                        setSelectedTrackType(trackType)
                    }}
                    disabled={loading || isPlaylistPlaying}
                >
                    <AddCircleIcon />
                </IconButton>
            </Box>

            {!isEmpty(list) && (
                <PerfectScrollbar>
                    <Box display='flex' flexDirection='column' gap='1rem'>
                        {list.sort(compareSortTracksFunc).map((item, itemIndex) => {
                            return (
                                <Card key={itemIndex} sx={{ padding: '16px' }}>
                                    <CardHeader
                                        sx={{ padding: '0', overflowWrap: 'anywhere', gap: '1rem' }}
                                        subheader={item.name}
                                        action={
                                            <IconButton
                                                sx={{
                                                    color: 'red',
                                                }}
                                                onClick={() => removeTrack(itemIndex, trackType)}
                                                disabled={loading || isPlaylistPlaying}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        }
                                    />
                                    <CardContent
                                        sx={{
                                            padding: '0 !important',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            {displayTimeUnit(item.start.hour)}:{displayTimeUnit(item.start.minutes)}
                                            &nbsp;
                                        </Typography>

                                        <IconButton
                                            sx={{ color: 'white' }}
                                            onClick={() => editTrack(itemIndex, trackType)}
                                            disabled={loading || isPlaylistPlaying}
                                        >
                                            <CreateIcon />
                                        </IconButton>
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </Box>
                </PerfectScrollbar>
            )}
        </Box>
    )
}

const Playlist = ({ afterCreate, playlistID }) => {
    const params = useParams()
    const navigate = useNavigate()

    const styles = rootStyles()

    const [status, statusSocket] = useSubscribe(xstadiumIP + '/watchout/status')
    const [playlists, playlistsSocket] = useSubscribe(xstadiumIP + '/playlists/*')
    const [schedules, schedulesSocket] = useSubscribe(xstadiumIP + '/schedules/*')

    const active =
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        playlistsSocket &&
        playlistsSocket.readyState === WebSocket.OPEN &&
        schedulesSocket &&
        schedulesSocket.readyState === WebSocket.OPEN

    const ID = !afterCreate ? (playlistID ? playlistID : params.id ? params.id : '') : ''

    // load flags
    const [loading, setLoading] = useState(true)
    const [fetched, setFetched] = useState(null)
    const [initialized, setInitialzed] = useState(false)

    // fields
    const [name, setName] = useState('')
    const [layout, setLayout] = useState([])
    const [background, setBackground] = useState([])
    const [music, setMusic] = useState([])
    const [animation, setAnimation] = useState([])
    const [announcement, setAnnouncement] = useState([])
    const [color, setColor] = useState('')

    // dialog
    const [tracksDialog, setTracksDialog] = useState(false)
    const [selectedTrackType, setSelectedTrackType] = useState('')
    const [trackTime, setTrackTime] = useState(moment('2022-04-17T00:00').toDate())
    const [trackName, setTrackName] = useState('')
    const [trackFail, setTrackFail] = useState('')
    const [trackEditingIndex, setTrackEditingIndex] = useState(-1)
    const [repeat, setRepeat] = useState(false)
    const [repeatInterval, setRepeatInterval] = useState('')
    const [repeatEndTime, setRepeatEndTime] = useState(moment('2022-04-17T01:00').toDate())

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
    })

    const getPlaylist = async () => {
        if (!fetched) {
            setFetched(true)
            try {
                const playlist = await fetchDecoded(xstadiumIP + '/playlists/' + ID)
                console.log('fetch', playlist)
                setTimeout(async () => {
                    setName(playlist.data.name)
                    setLayout(playlist.data.layout)
                    setBackground(playlist.data.background)
                    setMusic(playlist.data.music)
                    setAnimation(playlist.data.animation)
                    setAnnouncement(playlist.data.announcement)
                    setColor(playlist.data.color)
                    setLoading(false)
                }, 400)
            } catch (e) {
                console.error(e)
            }
        }
    }

    const isPlaylistNameUsed = (name) => {
        const newMatch = playlists && playlists.find((playlist) => playlist.data.name === name)
        const existingMatch =
            playlists && playlists.find((playlist) => playlist.index !== ID && playlist.data.name === name)

        return ID === '' ? (newMatch ? true : false) : existingMatch ? true : false
    }

    const isPlaylistScheduled = (playlistID) => {
        const match = schedules && schedules.find((schedule) => schedule.data.playlistID === playlistID)

        return match ? true : false
    }

    const isPlaylistPlaying = (playlistID) => {
        const now = new Date()
        const match = schedules.find((schedule) => {
            return (
                schedule.data.playlistID === playlistID && sameDate(now, nanoToMomentTime(schedule.data.start).toDate())
            )
        })

        return match ? true : false
    }

    const create = () => {
        if (isPlaylistNameUsed(name)) {
            setAlertDialogState({
                show: true,
                type: 'reminder',
                message: `This name already exists. Please enter a different name.`,
                error: false,
            })
            return
        }

        createApi()
    }

    const createApi = async () => {
        setLoading(true)
        try {
            await publish(xstadiumIP + '/playlists/*', {
                name,
                layout,
                background,
                music,
                animation,
                announcement,
                color: randomColor(0.3, 0.99),
                updatedBy: window.localStorage.getItem('account') ?? '-',
            })
            afterCreate()
        } catch (e) {
            console.warn('failed to submit form create: ', e)
            setLoading(false)
        }
    }

    const remove = () => {
        if (isPlaylistScheduled(ID)) {
            setAlertDialogState({
                show: true,
                type: 'reminder',
                message: `The playlist has already been scheduled. Cannot be removed.`,
                error: false,
            })
            return
        }

        setAlertDialogState({
            show: true,
            type: 'confirmation',
            message: `Are you sure you want to delete ${name}?`,
            error: false,
            confirm: () =>
                removeApi({
                    success: () => {
                        setAlertDialogState({
                            ...alertDialogState,
                            show: false,
                        })
                    },
                    fail: () => {
                        setAlertDialogState({
                            ...alertDialogState,
                            show: true,
                            type: 'error',
                            message: 'Remove failed.',
                            error: true,
                        })
                    },
                }),
        })
    }

    const removeApi = async ({ success, fail }) => {
        setLoading(true)
        try {
            await unpublish(xstadiumIP + '/playlists/' + ID)
            navigate('/xstadium/playlists')
            success()
        } catch (e) {
            console.warn('failed to delete: ', e)
            setLoading(false)
            fail()
        }
    }

    const update = () => {
        if (isPlaylistNameUsed(name)) {
            setAlertDialogState({
                show: true,
                type: 'reminder',
                message: `This name already exists. Please enter a different name.`,
                error: false,
            })
            return
        }

        updateApi()
    }

    const updateApi = async () => {
        setLoading(true)
        try {
            await publish(xstadiumIP + '/playlists/' + ID, {
                name,
                layout,
                background,
                music,
                animation,
                announcement,
                color: !color ? randomColor(0.3, 0.99) : color,
                updatedBy: window.localStorage.getItem('account') ?? '-',
            })
            navigate('/xstadium/playlists')
        } catch (e) {
            console.warn('failed to submit form update: ', e)
            setLoading(false)
        }
    }

    const clearFields = () => {
        setMusic([])
        setBackground([])
        setLayout([])
        setAnimation([])
        setAnnouncement([])
    }

    const clearTrack = () => {
        setTracksDialog(false)
        setTrackEditingIndex(-1)
        setTrackFail('')
        setTrackName('')
        setTrackTime(moment('2022-04-17T00:00').toDate())
        setSelectedTrackType('')
        setRepeat(false)
        setRepeatInterval('')
        setRepeatEndTime(moment('2022-04-17T01:00').toDate())
    }

    const getRepeatTime = (times) => {
        const startTime = Array(1).fill(trackTime)
        const repeatTime = Array(times).fill(moment(trackTime))

        const result = repeatTime.reduce(function (pV, cV) {
            pV.push(cV.add(repeatInterval, 'minutes').toDate())
            return pV
        }, [])

        return trackEditingIndex < 0 ? startTime.concat(result) : result
    }

    const getRepeatFrequency = () => {
        return Math.floor(Math.floor(Math.abs(repeatEndTime - trackTime) / 1000 / 60) / repeatInterval)
    }

    const addTrack = (list, setList, canOverlap) => {
        const hour = trackTime.getHours()
        const minutes = trackTime.getMinutes()
        if (!canOverlap) {
            const timeTaken = list.find((item) => item.start.hour === hour && item.start.minutes === minutes)
            if (timeTaken) {
                setTrackFail('This time slot is already taken by another item')
                return
            }
        }
        if (repeat) {
            const newList = getRepeatTime(getRepeatFrequency()).map((item) => {
                return {
                    name: trackName,
                    start: {
                        hour: item.getHours(),
                        minutes: item.getMinutes(),
                    },
                }
            })
            setList(list.concat(newList))
        } else {
            setList(
                list.concat({
                    name: trackName,
                    start: {
                        hour,
                        minutes,
                    },
                })
            )
        }

        clearTrack()
    }

    const _addTrack = () => {
        switch (selectedTrackType) {
            case 'layout':
                addTrack(layout, setLayout, false)
                break
            case 'background':
                addTrack(background, setBackground, false)
                break
            case 'music':
                addTrack(music, setMusic, false)
                break
            case 'animation':
                addTrack(animation, setAnimation, true)
                break
            case 'announcement':
                addTrack(announcement, setAnnouncement, true)
                break
            default:
        }
    }

    const updateTrack = (list, setList) => {
        const hour = trackTime.getHours()
        const minutes = trackTime.getMinutes()
        const timeTaken = list.find(
            (item, index) => index !== trackEditingIndex && item.start.hour === hour && item.start.minutes === minutes
        )
        if (timeTaken) {
            setTrackFail('This time slot is already taken by another item')
            return
        }
        let cloned = [...list]
        cloned[trackEditingIndex].name = trackName
        cloned[trackEditingIndex].start.hour = hour
        cloned[trackEditingIndex].start.minutes = minutes
        if (repeat) {
            const newList = getRepeatTime(getRepeatFrequency()).map((item) => {
                return {
                    name: trackName,
                    start: {
                        hour: item.getHours(),
                        minutes: item.getMinutes(),
                    },
                }
            })
            const match = newList.filter((item) => {
                return cloned.find(
                    (clonedItem, index) =>
                        index !== trackEditingIndex &&
                        clonedItem.start.hour === item.start.hour &&
                        clonedItem.start.minutes === item.start.minutes
                )
            })
            if (!isEmpty(match)) {
                setTrackFail('Repeat time slot is already taken')
                return
            } else {
                setList(cloned.concat(newList))
            }
        } else {
            setList(cloned)
        }

        clearTrack()
    }

    const _updateTrack = () => {
        if (trackEditingIndex < 0) {
            console.warn("can't update track, wrong index")
        }

        switch (selectedTrackType) {
            case 'layout':
                updateTrack(layout, setLayout)
                break
            case 'background':
                updateTrack(background, setBackground)
                break
            case 'music':
                updateTrack(music, setMusic)
                break
            case 'animation':
                updateTrack(animation, setAnimation)
                break
            case 'announcement':
                updateTrack(announcement, setAnnouncement)
                break
            default:
        }
    }

    const proceed = () => {
        if (repeat && Number(repeatInterval) === 0) {
            setTrackFail('Repeat interval cannot be zero')
            return
        }

        if (repeat && repeatEndTime - trackTime < 0) {
            setTrackFail('End time cannot be earlier than start time')
            return
        }

        if (repeat && getRepeatFrequency() <= 0) {
            setTrackFail('Duration must be longer than repeat frequency time')
            return
        }

        if (trackEditingIndex < 0) {
            _addTrack()
        } else {
            _updateTrack()
        }
    }

    const removeTrack = (trackIndex, trackType) => {
        switch (trackType) {
            case 'layout':
                setLayout(spliceNoMutate(layout, trackIndex))
                break
            case 'background':
                setBackground(spliceNoMutate(background, trackIndex))
                break
            case 'music':
                setMusic(spliceNoMutate(music, trackIndex))
                break
            case 'animation':
                setAnimation(spliceNoMutate(animation, trackIndex))
                break
            case 'announcement':
                setAnnouncement(spliceNoMutate(announcement, trackIndex))
                break
            default:
        }
    }

    const editTrack = (trackIndex, trackType) => {
        switch (trackType) {
            case 'layout':
                setTrackName(layout[trackIndex].name)
                setTrackTime(dateOfTrack(layout[trackIndex]).toDate())
                setSelectedTrackType('layout')
                break
            case 'background':
                setTrackName(background[trackIndex].name)
                setTrackTime(dateOfTrack(background[trackIndex]).toDate())
                setSelectedTrackType('background')
                break
            case 'music':
                setTrackName(music[trackIndex].name)
                setTrackTime(dateOfTrack(music[trackIndex]).toDate())
                setSelectedTrackType('music')
                break
            case 'animation':
                setTrackName(animation[trackIndex].name)
                setTrackTime(dateOfTrack(animation[trackIndex]).toDate())
                setSelectedTrackType('animation')
                break
            case 'announcement':
                setTrackName(announcement[trackIndex].name)
                setTrackTime(dateOfTrack(announcement[trackIndex]).toDate())
                setSelectedTrackType('announcement')
                break
            default:
        }

        setTrackEditingIndex(trackIndex)
        setTracksDialog(true)
    }

    if (!afterCreate) {
        getPlaylist()
    }

    if (afterCreate && !initialized) {
        setInitialzed(true)
        clearFields()
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }

    if (!status || !playlists || !schedules || !active) {
        return <LinearProgress />
    }

    return (
        <>
            <Dialog
                key='dialogTracks'
                open={tracksDialog}
                onClose={clearTrack}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                PaperProps={{
                    sx: {
                        width: '25rem',
                    },
                }}
            >
                <DialogTitle id='alert-dialog-title'>
                    {trackEditingIndex < 0 ? 'Add' : 'Edit'} {selectedTrackType} track
                </DialogTitle>
                <DialogContent sx={{ overflowY: 'visible' }}>
                    <Box display='flex' flexDirection='column' gap='1rem'>
                        {trackFail && (
                            <DialogContentText>
                                <SnackbarContent
                                    className={styles.fail}
                                    message={
                                        <Typography component='p' className={styles.warningText}>
                                            <WarningIcon className={styles.warningIcon} /> {trackFail}
                                        </Typography>
                                    }
                                />
                            </DialogContentText>
                        )}
                        <Select
                            id='track'
                            inputProps={{
                                name: 'track',
                                id: 'track',
                            }}
                            required
                            fullWidth
                            onChange={(e) => {
                                setTrackName(e.target.value)
                            }}
                            displayEmpty
                            renderValue={(value) => (value !== '' ? value : '...Select ' + selectedTrackType)}
                            value={trackName}
                            disabled={false}
                            MenuProps={MenuProps}
                        >
                            {selectedTrackType === 'layout' &&
                                status.data.layouts.map((item, index) => {
                                    return (
                                        <MenuItem key={'select-layout' + index} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                            {selectedTrackType === 'background' &&
                                status.data.backgrounds.map((item, index) => {
                                    return (
                                        <MenuItem key={'select-background' + index} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                            {selectedTrackType === 'music' &&
                                status.data.music.map((item, index) => {
                                    return (
                                        <MenuItem key={'select-music' + index} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                            {selectedTrackType === 'animation' &&
                                status.data.animations.map((item, index) => {
                                    return (
                                        <MenuItem key={'select-animation' + index} value={item.Name}>
                                            {item.Name}
                                        </MenuItem>
                                    )
                                })}
                            {selectedTrackType === 'announcement' &&
                                status.data.announcements.map((item, index) => {
                                    return (
                                        <MenuItem key={'select-announcement' + index} value={item.Name}>
                                            {item.Name}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                        {trackName !== '' && (
                            <Box display='flex' flexDirection='column' gap='1rem'>
                                <Box>
                                    <Typography>Start Time</Typography>
                                    <DesktopTimePicker
                                        sx={{ width: '100%' }}
                                        value={trackTime}
                                        onChange={(value) => setTrackTime(moment(value).toDate())}
                                        timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                                    />
                                </Box>
                                {(selectedTrackType === 'animation' || selectedTrackType === 'announcement') && (
                                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                                        <Typography>Repeat</Typography>
                                        <FormControlLabel
                                            sx={{ m: '0' }}
                                            checked={repeat}
                                            control={<Switch />}
                                            onChange={(e) => setRepeat(e.target.checked)}
                                        />
                                    </Box>
                                )}
                                {(selectedTrackType === 'animation' || selectedTrackType === 'announcement') &&
                                    repeat && [
                                        <Box display='flex' alignItems='center' gap='1rem'>
                                            <Typography>Repeat every</Typography>
                                            <TextField
                                                sx={{ width: '5rem' }}
                                                variant='standard'
                                                InputProps={{
                                                    inputProps: {
                                                        style: { textAlign: 'right' },
                                                    },
                                                }}
                                                value={repeatInterval}
                                                onChange={(e) => setRepeatInterval(e.target.value.replace(/\D/g, ''))}
                                            />
                                            <Typography>Minute</Typography>
                                        </Box>,
                                        <Box>
                                            <Typography>End Time</Typography>
                                            <DesktopTimePicker
                                                sx={{ width: '100%' }}
                                                value={repeatEndTime}
                                                onChange={(value) => setRepeatEndTime(moment(value).toDate())}
                                                timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                                            />
                                        </Box>,
                                    ]}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearTrack}>Cancel</Button>
                    <Button
                        color='primary'
                        onClick={proceed}
                        disabled={trackName === '' || (repeat && repeatInterval === '')}
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>

            <Box height='100%' padding='2rem' display='flex' flexDirection='column' gap='1.25rem'>
                <Box>
                    <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                        Playlists
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                        Create new and modify existing 24 hour playlists
                    </Typography>
                </Box>

                <TextField
                    key='playlist-name'
                    required
                    margin='dense'
                    id='playlist-name'
                    label='Name'
                    type='text'
                    fullWidth
                    variant='outlined'
                    inputProps={{
                        autoComplete: 'off',
                    }}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    value={name}
                    disabled={loading || isPlaylistPlaying(ID)}
                    error={false}
                />

                <Box height='70%' display='flex' gap='1rem'>
                    <Track
                        loading={loading}
                        setTracksDialog={setTracksDialog}
                        setSelectedTrackType={setSelectedTrackType}
                        trackType={'layout'}
                        list={layout}
                        editTrack={editTrack}
                        removeTrack={removeTrack}
                        isPlaylistPlaying={isPlaylistPlaying(ID)}
                    />
                    <Track
                        loading={loading}
                        setTracksDialog={setTracksDialog}
                        setSelectedTrackType={setSelectedTrackType}
                        trackType={'background'}
                        list={background}
                        editTrack={editTrack}
                        removeTrack={removeTrack}
                        isPlaylistPlaying={isPlaylistPlaying(ID)}
                    />
                    <Track
                        loading={loading}
                        setTracksDialog={setTracksDialog}
                        setSelectedTrackType={setSelectedTrackType}
                        trackType={'music'}
                        list={music}
                        editTrack={editTrack}
                        removeTrack={removeTrack}
                        isPlaylistPlaying={isPlaylistPlaying(ID)}
                    />
                    <Track
                        loading={loading}
                        setTracksDialog={setTracksDialog}
                        setSelectedTrackType={setSelectedTrackType}
                        trackType={'animation'}
                        list={animation}
                        editTrack={editTrack}
                        removeTrack={removeTrack}
                        isPlaylistPlaying={isPlaylistPlaying(ID)}
                    />
                    <Track
                        loading={loading}
                        setTracksDialog={setTracksDialog}
                        setSelectedTrackType={setSelectedTrackType}
                        trackType={'announcement'}
                        list={announcement}
                        editTrack={editTrack}
                        removeTrack={removeTrack}
                        isPlaylistPlaying={isPlaylistPlaying(ID)}
                    />
                </Box>

                <Box key='playlist-form-box'>
                    <form
                        key='playlist-form'
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}
                        autoComplete='off'
                    >
                        <div key='buttonwrapper' className={styles.formButtonWrapper}>
                            <Button
                                className={styles.formCancel}
                                disabled={loading}
                                onClick={() => navigate('/xstadium/playlists')}
                            >
                                Cancel
                            </Button>
                            {afterCreate && (
                                <Button
                                    className={styles.formSubmit}
                                    variant='contained'
                                    type='submit'
                                    color='primary'
                                    disabled={loading || name === ''}
                                    onClick={create}
                                >
                                    Create New Playlist
                                </Button>
                            )}
                            {!afterCreate && (
                                <Button
                                    className={styles.formSubmit}
                                    variant='contained'
                                    type='submit'
                                    color='error'
                                    disabled={loading || name === '' || isPlaylistPlaying(ID)}
                                    onClick={remove}
                                >
                                    Delete
                                </Button>
                            )}
                            {!afterCreate && (
                                <Button
                                    className={styles.formSubmit}
                                    variant='contained'
                                    type='submit'
                                    color='primary'
                                    disabled={loading || name === '' || isPlaylistPlaying(ID)}
                                    onClick={update}
                                >
                                    Update
                                </Button>
                            )}
                        </div>
                    </form>
                </Box>
            </Box>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default Playlist
