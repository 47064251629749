import React, { useState } from 'react'
import { cloneDeep, isNil } from 'lodash'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import SnackbarContent from '@mui/material/SnackbarContent'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles, withStyles } from '@mui/styles'

import { defaultValues } from '../defaultValues'
import nine from './images/slots/9_80x50.png'
import notdiamond from './images/slots/any_80x50.png'
import club from './images/slots/club_80x50.png'
import diamond from './images/slots/diamond_80x50.png'
import heart from './images/slots/heart_80x50.png'
import logo from './images/slots/logo_80x50.png'
import spade from './images/slots/spade_80x50.png'

const XSwitch = withStyles((theme) => ({
    root: {
        width: 48,
        height: 26,
        padding: 5,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        paddingLeft: 4,
    },
    thumb: {
        width: 24,
        height: 24,
    },
}))(Switch)

const InvalidNumber = (n) => {
    return isNaN(n) || n === ''
}

const rootStyles = makeStyles((theme) => ({
    form: {
        width: 900,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
    title: {
        padding: '30px',
    },
    tiggersSwitches: {
        padding: '30px',
    },
    stats: {
        padding: '30px',
    },
    slotResult: {
        padding: 30,
        paddingTop: 0,
    },
}))

const Slots = ({ device, publish, xstadium }) => {
    const styles = rootStyles({ xstadium })

    const navigate = useNavigate()

    // form changed detect hook
    const [changed, setChanged] = useState(false)

    const [loading, setLoading] = useState(true)
    const [fetched, setFetched] = useState(false)
    const [fail, setFail] = useState(null)

    const isLucky6Slot = device.data.gameType === 'baccarat' && device.data.gameVariant === 'lucky6Slot'
    const isAnyPairSlot = device.data.gameType === 'blackjack' && device.data.gameVariant === 'anyPairSlot'

    // baccarat lucky6Slot triggers
    const [bankerOrPlayer, setBankerOrPlayer] = useState(
        isLucky6Slot ? device.data.slots.baccarat.lucky6Slot.triggers.bankerOrPlayer : true
    )
    const [tie, setTie] = useState(isLucky6Slot ? device.data.slots.baccarat.lucky6Slot.triggers.tie : true)
    const [bankerPair, setBankerPair] = useState(
        isLucky6Slot ? device.data.slots.baccarat.lucky6Slot.triggers.bankerPair : true
    )
    const [playerPair, setPlayerPair] = useState(
        isLucky6Slot ? device.data.slots.baccarat.lucky6Slot.triggers.playerPair : true
    )
    const [lucky6, setLucky6] = useState(isLucky6Slot ? device.data.slots.baccarat.lucky6Slot.triggers.lucky6 : true)
    // blackjack anyPairSlot triggers
    const [dealerWinBlackjack, setDealerWinBlackjack] = useState(
        isAnyPairSlot ? device.data.slots.blackjack.anyPairSlot.triggers.dealerWinBlackjack : true
    )
    const [dealerWin21, setDealerWin21] = useState(
        isAnyPairSlot ? device.data.slots.blackjack.anyPairSlot.triggers.dealerWin21 : true
    )
    const [anyPlayerBust, setAnyPlayerBust] = useState(
        isAnyPairSlot ? device.data.slots.blackjack.anyPairSlot.triggers.anyPlayerBust : true
    )
    const [anyPlayerPair, setAnyPlayerPair] = useState(
        isAnyPairSlot ? device.data.slots.blackjack.anyPairSlot.triggers.anyPlayerPair : true
    )
    // payouts
    const [fullA, setFullA] = useState(
        isLucky6Slot
            ? device.data.slots.baccarat.lucky6Slot.payouts.fullA
            : isAnyPairSlot
                ? device.data.slots.blackjack.anyPairSlot.payouts.fullA
                : 50
    )
    const [fullB, setFullB] = useState(
        isLucky6Slot
            ? device.data.slots.baccarat.lucky6Slot.payouts.fullB
            : isAnyPairSlot
                ? device.data.slots.blackjack.anyPairSlot.payouts.fullB
                : 20
    )
    const [fullC, setFullC] = useState(
        isLucky6Slot
            ? device.data.slots.baccarat.lucky6Slot.payouts.fullC
            : isAnyPairSlot
                ? device.data.slots.blackjack.anyPairSlot.payouts.fullC
                : 15
    )
    const [fullD, setFullD] = useState(
        isLucky6Slot
            ? device.data.slots.baccarat.lucky6Slot.payouts.fullD
            : isAnyPairSlot
                ? device.data.slots.blackjack.anyPairSlot.payouts.fullD
                : 10
    )
    const [mixE2F1, setMixE2F1] = useState(
        isLucky6Slot
            ? device.data.slots.baccarat.lucky6Slot.payouts.mixE2F1
            : isAnyPairSlot
                ? device.data.slots.blackjack.anyPairSlot.payouts.mixE2F1
                : 5
    )
    const [mixE2notF, setMixE2notF] = useState(
        isLucky6Slot
            ? device.data.slots.baccarat.lucky6Slot.payouts.mixE2notF
            : isAnyPairSlot
                ? device.data.slots.blackjack.anyPairSlot.payouts.mixE2notF
                : 2
    )

    if (!fetched) {
        setFetched(true)
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }

    const validateData = (data, gameType, gameVariant) => {
        Object.keys(data[gameType][gameVariant]).forEach((key) => {
            if (key === 'triggers') {
                if (gameType === 'baccarat' && gameVariant === 'lucky6Slot') {
                    if (!isNil(data[gameType][gameVariant][key].bankerOrPlayer)) {
                        data[gameType][gameVariant][key].bankerOrPlayer = !!bankerOrPlayer
                    }
                    if (!isNil(data[gameType][gameVariant][key].tie)) {
                        data[gameType][gameVariant][key].tie = !!tie
                    }
                    if (!isNil(data[gameType][gameVariant][key].bankerPair)) {
                        data[gameType][gameVariant][key].bankerPair = !!bankerPair
                    }
                    if (!isNil(data[gameType][gameVariant][key].playerPair)) {
                        data[gameType][gameVariant][key].playerPair = !!playerPair
                    }
                    if (!isNil(data[gameType][gameVariant][key].lucky6)) {
                        data[gameType][gameVariant][key].lucky6 = !!lucky6
                    }
                }

                if (gameType === 'blackjack' && gameVariant === 'anyPairSlot') {
                    if (!isNil(data[gameType][gameVariant][key].dealerWinBlackjack)) {
                        data[gameType][gameVariant][key].dealerWinBlackjack = !!dealerWinBlackjack
                    }
                    if (!isNil(data[gameType][gameVariant][key].dealerWin21)) {
                        data[gameType][gameVariant][key].dealerWin21 = !!dealerWin21
                    }
                    if (!isNil(data[gameType][gameVariant][key].anyPlayerBust)) {
                        data[gameType][gameVariant][key].anyPlayerBust = !!anyPlayerBust
                    }
                    if (!isNil(data[gameType][gameVariant][key].anyPlayerPair)) {
                        data[gameType][gameVariant][key].anyPlayerPair = !!anyPlayerPair
                    }
                }
            }

            if (key === 'payouts') {
                if (!isNil(data[gameType][gameVariant][key].fullA)) {
                    data[gameType][gameVariant][key].fullA = Number(fullA)
                }
                if (!isNil(data[gameType][gameVariant][key].fullB)) {
                    data[gameType][gameVariant][key].fullB = Number(fullB)
                }
                if (!isNil(data[gameType][gameVariant][key].fullC)) {
                    data[gameType][gameVariant][key].fullC = Number(fullC)
                }
                if (!isNil(data[gameType][gameVariant][key].fullD)) {
                    data[gameType][gameVariant][key].fullD = Number(fullD)
                }
                if (!isNil(data[gameType][gameVariant][key].mixE2F1)) {
                    data[gameType][gameVariant][key].mixE2F1 = Number(mixE2F1)
                }
                if (!isNil(data[gameType][gameVariant][key].mixE2notF)) {
                    data[gameType][gameVariant][key].mixE2notF = Number(mixE2notF)
                }
            }
        })

        console.log(data)
        return data
    }

    const send = async () => {
        setLoading(true)
        if (
            InvalidNumber(fullA) ||
            InvalidNumber(fullB) ||
            InvalidNumber(fullC) ||
            InvalidNumber(fullD) ||
            InvalidNumber(mixE2F1) ||
            InvalidNumber(mixE2notF)
        ) {
            setFail(`Some payout values are not numbers, please check and try again`)
            return
        }
        try {
            await publish({
                ...device.data,
                slots: validateData(cloneDeep(device.data.slots), device.data.gameType, device.data.gameVariant),
                updatedBy: window.localStorage.getItem('account') ?? '-',
            })
            setFail('')
            setLoading(false)
        } catch (e) {
            setFail(
                `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            setLoading(false)
        }
        setChanged(false)
    }

    const setDefaults = () => {
        setFullA(
            isLucky6Slot
                ? defaultValues.slots.baccarat.lucky6Slot.payouts.fullA
                : isAnyPairSlot
                    ? defaultValues.slots.blackjack.anyPairSlot.payouts.fullA
                    : 50
        )
        setFullB(
            isLucky6Slot
                ? defaultValues.slots.baccarat.lucky6Slot.payouts.fullB
                : isAnyPairSlot
                    ? defaultValues.slots.blackjack.anyPairSlot.payouts.fullB
                    : 20
        )
        setFullC(
            isLucky6Slot
                ? defaultValues.slots.baccarat.lucky6Slot.payouts.fullC
                : isAnyPairSlot
                    ? defaultValues.slots.blackjack.anyPairSlot.payouts.fullC
                    : 15
        )
        setFullD(
            isLucky6Slot
                ? defaultValues.slots.baccarat.lucky6Slot.payouts.fullD
                : isAnyPairSlot
                    ? defaultValues.slots.blackjack.anyPairSlot.payouts.fullD
                    : 10
        )
        setMixE2F1(
            isLucky6Slot
                ? defaultValues.slots.baccarat.lucky6Slot.payouts.mixE2F1
                : isAnyPairSlot
                    ? defaultValues.slots.blackjack.anyPairSlot.payouts.mixE2F1
                    : 5
        )
        setMixE2notF(
            isLucky6Slot
                ? defaultValues.slots.baccarat.lucky6Slot.payouts.mixE2notF
                : isAnyPairSlot
                    ? defaultValues.slots.blackjack.anyPairSlot.payouts.mixE2notF
                    : 3
        )
        setBankerOrPlayer(isLucky6Slot ? defaultValues.slots.baccarat.lucky6Slot.triggers.bankerOrPlayer : true)
        setTie(isLucky6Slot ? defaultValues.slots.baccarat.lucky6Slot.triggers.tie : true)
        setBankerPair(isLucky6Slot ? defaultValues.slots.baccarat.lucky6Slot.triggers.bankerPair : true)
        setPlayerPair(isLucky6Slot ? defaultValues.slots.baccarat.lucky6Slot.triggers.playerPair : true)
        setLucky6(isLucky6Slot ? defaultValues.slots.baccarat.lucky6Slot.triggers.lucky6 : true)
        setDealerWinBlackjack(
            isAnyPairSlot ? defaultValues.slots.blackjack.anyPairSlot.triggers.dealerWinBlackjack : true
        )
        setDealerWin21(isAnyPairSlot ? defaultValues.slots.blackjack.anyPairSlot.triggers.dealerWin21 : true)
        setAnyPlayerBust(isAnyPairSlot ? defaultValues.slots.blackjack.anyPairSlot.triggers.anyPlayerBust : true)
        setAnyPlayerPair(isAnyPairSlot ? defaultValues.slots.blackjack.anyPairSlot.triggers.anyPlayerPair : true)
    }

    if (loading) {
        return <LinearProgress />
    }

    const RTP =
        (2 * Number(fullA) +
            Number(fullB) +
            3 * Number(fullC) +
            8 * Number(fullD) +
            50 * Number(mixE2F1) +
            200 * Number(mixE2notF)) /
        10

    const inputColumnStyle = { width: 120, marginRight: 20 }

    return (
        <>
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </Box>
                )}

                <Grid container spacing={3}>
                    <Grid item style={{ width: 310 }}>
                        <Paper variant='outlined'>
                            <h3 className={styles.title}>Trigger streaks</h3>
                            <div className={styles.tiggersSwitches}>
                                {device.data.gameType === 'baccarat' && device.data.gameVariant === 'lucky6Slot' && (
                                    <>
                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Banker or Player (3x)</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={bankerOrPlayer}
                                                        onChange={(e) => {
                                                            setBankerOrPlayer(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '.5rem 0' }} />

                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Tie (1x)</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={tie}
                                                        onChange={(e) => {
                                                            setTie(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '.5rem 0' }} />

                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Banker pair (1x)</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={bankerPair}
                                                        onChange={(e) => {
                                                            setBankerPair(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '.5rem 0' }} />

                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Player pair (1x)</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={playerPair}
                                                        onChange={(e) => {
                                                            setPlayerPair(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '.5rem 0' }} />

                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Lucky6 (1x)</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={lucky6}
                                                        onChange={(e) => {
                                                            setLucky6(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>
                                    </>
                                )}
                                {device.data.gameType === 'blackjack' && device.data.gameVariant === 'anyPairSlot' && (
                                    <>
                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Dealer Wins Blackjack</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={dealerWinBlackjack}
                                                        onChange={(e) => {
                                                            setDealerWinBlackjack(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '.5rem 0' }} />

                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Dealer Wins 21</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={dealerWin21}
                                                        onChange={(e) => {
                                                            setDealerWin21(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '.5rem 0' }} />

                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Player Hand Bust</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={anyPlayerBust}
                                                        onChange={(e) => {
                                                            setAnyPlayerBust(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '.5rem 0' }} />

                                        <Box
                                            component='label'
                                            width='100%'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Typography>Player Hand Any Pair</Typography>

                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        onMouseDown={(e) => {
                                                            e.preventDefault()
                                                        }}
                                                        checked={anyPlayerPair}
                                                        onChange={(e) => {
                                                            setAnyPlayerPair(e.target.checked)
                                                            setChanged(true)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>
                                    </>
                                )}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper variant='outlined'>
                            <h3 className={styles.title}>Pay table</h3>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <img alt='slot-logo-result' className={styles.slotResult} src={logo}></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img alt='slot-logo-result' className={styles.slotResult} src={logo}></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img alt='slot-logo-result' className={styles.slotResult} src={logo}></img>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={inputColumnStyle}>
                                    <TextField
                                        value={fullA}
                                        onChange={(e) => {
                                            setFullA(e.target.value)
                                            setChanged(true)
                                        }}
                                        id='fulla=payout'
                                        error={InvalidNumber(fullA)}
                                        label='Payout'
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <img alt='slot-nine-result' className={styles.slotResult} src={nine}></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img alt='slot-nine-result' className={styles.slotResult} src={nine}></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img alt='slot-nine-result' className={styles.slotResult} src={nine}></img>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={inputColumnStyle}>
                                    <TextField
                                        value={fullB}
                                        onChange={(e) => {
                                            setFullB(e.target.value)
                                            setChanged(true)
                                        }}
                                        id='fullb-payout'
                                        error={InvalidNumber(fullB)}
                                        label='Payout'
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <img
                                                alt='slot-spade-result'
                                                className={styles.slotResult}
                                                src={spade}
                                            ></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img
                                                alt='slot-spade-result'
                                                className={styles.slotResult}
                                                src={spade}
                                            ></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img
                                                alt='slot-spade-result'
                                                className={styles.slotResult}
                                                src={spade}
                                            ></img>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={inputColumnStyle}>
                                    <TextField
                                        value={fullC}
                                        onChange={(e) => {
                                            setFullC(e.target.value)
                                            setChanged(true)
                                        }}
                                        id='fullc-payout'
                                        error={InvalidNumber(fullC)}
                                        label='Payout'
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <img
                                                alt='slot-heart-result'
                                                className={styles.slotResult}
                                                src={heart}
                                            ></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img
                                                alt='slot-heart-result'
                                                className={styles.slotResult}
                                                src={heart}
                                            ></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img
                                                alt='slot-heart-result'
                                                className={styles.slotResult}
                                                src={heart}
                                            ></img>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={inputColumnStyle}>
                                    <TextField
                                        value={fullD}
                                        onChange={(e) => {
                                            setFullD(e.target.value)
                                            setChanged(true)
                                        }}
                                        id='fulld-payout'
                                        error={InvalidNumber(fullD)}
                                        label='Payout'
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <img alt='slot-club-result' className={styles.slotResult} src={club}></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img alt='slot-club-result' className={styles.slotResult} src={club}></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img
                                                alt='slot-diamond-result'
                                                className={styles.slotResult}
                                                src={diamond}
                                            ></img>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={inputColumnStyle}>
                                    <TextField
                                        value={mixE2F1}
                                        onChange={(e) => {
                                            setMixE2F1(e.target.value)
                                            setChanged(true)
                                        }}
                                        id='mixe2f1-payout'
                                        label='Payout'
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <img alt='slot-club-result' className={styles.slotResult} src={club}></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img alt='slot-club-result' className={styles.slotResult} src={club}></img>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <img
                                                alt='slot-not-diamond-result'
                                                className={styles.slotResult}
                                                src={notdiamond}
                                            ></img>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={inputColumnStyle}>
                                    <TextField
                                        value={mixE2notF}
                                        onChange={(e) => {
                                            setMixE2notF(e.target.value)
                                            setChanged(true)
                                        }}
                                        id='mixe2notf-payout'
                                        label='Payout'
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <div className={styles.stats}>
                                        <p>
                                            Hit frequency: <b>26.4%</b>
                                        </p>
                                        <p>
                                            Average number of spins to win: <b>4</b>
                                        </p>
                                        <p>
                                            RTP: <b>{RTP}%</b>
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs></Grid>
                    <Grid item style={{ width: 300 }}>
                        <div className={styles.formButtonWrapper}>
                            <Button
                                color='inherit'
                                disabled={loading}
                                disableRipple
                                onClick={() => {
                                    navigate(
                                        !xstadium
                                            ? device.data.containerType === 'casino'
                                                ? `/xtrend/${device.data.containerType}`
                                                : `/xtrend/${device.data.containerType}/${device.data.containerIndex}`
                                            : device.data.type === 'game'
                                                ? '/xstadium/tables'
                                                : '/xstadium/servers'
                                    )
                                }}
                            >
                                Back
                            </Button>

                            <Button
                                variant='contained'
                                disabled={loading}
                                onClick={() => {
                                    setDefaults()
                                    setChanged(true)
                                }}
                            >
                                Default
                            </Button>

                            {changed && (
                                <Button variant='contained' color='primary' disabled={loading} onClick={() => send()}>
                                    Update
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>

                {loading && (
                    <CircularProgress
                        size={80}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                )}
            </form>
        </>
    )
}

export default Slots
