import React, { useState, useEffect, useReducer } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import { Accordion } from '@mui/material'
import { AccordionSummary } from '@mui/material'
import { AccordionDetails } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles, useTheme } from '@mui/styles'

import { DateTimeDisplay } from '../../xtable/Formats'
import Card from '../dealer/Card'

const rootStyles = makeStyles((theme) => ({
  tableHead: {
    background: props => props.lights ? '#e2e2e2' : '#000',
    textTransform: 'uppercase',
  },
  tableHeadRoot: {
    //position: 'sticky',
    zIndex: 10,
    top: (props) => props.top,
    //tableLayout:'fixed'
  },
  tableRoot: {
    background: (props) => props.lights ? '#fffffff0' : '#1f1f1fd6',
  },
  tableRow: {
    textDecoration: 'none',
    '&:hover': {
      background: (props) => props.link ? 'pointer' : '',
      cursor: (props) => props.link ? 'pointer' : ''
    }
  },
  tableCell: {
    paddingTop: 20,
    paddingBottom: 20
  },
  tableCellRoot: {
    //    wordBreak: 'break-all',
    [theme.breakpoints.down('xs')]: {
      padding: '14px 20px 14px 9px'
    },
    textAlign: 'center',
    width: (props) => props.columnWidth ? props.columnWidth + '%' : 'auto'
  },
  tablePagination: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    borderTop: '1px solid #b5b5b57a',
    background: (props) => props.lights ? '#fffffff0' : 'rgba(31, 31, 31, 1)'
  },
  paginationActions: {
    margin: 0
  },
  paginationInput: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 7
    }
  },
  fakeTableHead: {
    visibility: 'collapse'
  },
  fakeTableRow: {
    visibility: 'collapse'
  },
  sortLable: {
    right: -16,
    textAlign: 'center'
  },
  border: {
    border: '0px solid #ccc'
  },
  card: {
    margin: 5,
    '& ::selection': {
      color: 'none',
      background: 'none'
    }
  },
}))

// function desc(a, b, orderBy) {
//   const extractText = (s) => {
//     var span = document.createElement('span');
//     span.innerHTML = s;
//     return span.textContent || span.innerText;
//   }


//   if (typeof a[orderBy] === 'object' && typeof b[orderBy] === 'object') {
//     const s1 = { [orderBy]: extractText(ReactDOMServer.renderToString(a[orderBy])) }
//     const s2 = { [orderBy]: extractText(ReactDOMServer.renderToString(b[orderBy])) }
//     return desc(s1, s2, orderBy)
//   }


//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function stableSort(array, cmp) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// function getSorting(order, orderBy) {
//   return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }

const ExpandableTable = ({ deviceGames, colWidths = [], top = 0, pagination = false, hiddenFields = [], hiddenMobileFields = [], link = null, sortDefault = '', selectedDevice = null, canEditResult, setPageNav, expandedURL, setExpandedURL, setEditGameID }) => {
  const lights = window.localStorage.getItem('lights') === 'on'
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const tablet = useMediaQuery(theme.breakpoints.between('s', 'sm'))

  // table
  const responsiveTableFields = (field) => hiddenFields.indexOf(field) === -1 &&
    ((!mobile && !tablet) ||
      ((mobile || tablet) && hiddenMobileFields.indexOf(field) === -1))
  
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  function handleChangePage(_event, newPage) {
    setPage(newPage)
    setExpandedRows(false)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const [selectedDeviceState, setSelectedDeviceState] = useState(null)

  const getGameRowDateAndEndTime = (v) => {
      return (
          <div>
              {'Date: ' + moment(v / 1000000).format('YYYY-MM-DD')}
              <div style={{ width: 100, display: 'inline - block' }} />
              {'End Time: ' + moment(v / 1000000).format('hh:mm:ss A')}
          </div>
      )
  }

  const filteredRows = deviceGames.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(v => { return { sessions: getGameRowDateAndEndTime(v.ended), url: v } })

  console.log('filteredRows', filteredRows)

  const expandedDomRow = filteredRows.reduce((c, v, i) => {
    c = v.url === expandedURL ? i : c
    return c
  }, null)

  const [expandedRows, setExpandedRows] = useReducer((state, obj) => {
    if (obj === false) return []
    return [obj]
  }, expandedDomRow !== null ? [expandedDomRow] : [])

  if (selectedDevice !== selectedDeviceState) {
    setSelectedDeviceState(selectedDevice)
    setExpandedRows(false)
  }

  const filterKeys = typeof filteredRows[0] !== 'undefined' ? Object.keys(filteredRows[0]).filter(v => responsiveTableFields(v)) : []

  const columnWidth = 100 / filterKeys.length
  const styles = rootStyles({ lights, top, link, columnWidth })


  ///////////////////////////////////////////////////////////////
  ///////////////// display results code ////////////////////////
  ///////////////////////////////////////////////////////////////


  const getClassName = (...k) => {
    return k.join(' ')
  }

  const getCardsVisual = (cards) => {
    const bankerCards = cards.filter(v => v.owner === 'B')
    const playerCards = cards.filter(v => v.owner === 'P')


    return <div style={{ display: 'flex' }}>
      <div style={{ background: 'transparent linear-gradient(180deg, #340404 0%, #FF4F4F 100%)', border: '3px solid #FF4F4F', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10 }}>
        <div style={{ margin: 5, marginTop: 0, color: '#999' }}>Banker Cards</div>
        <div style={{ display: 'flex' }}>
          {bankerCards.map((v, i) => {
            return <div key={i} className={getClassName(styles.border, styles.card)}>
              <div style={{ width: '45px', height: '90px', backgroundColor: '#fff', borderRadius: '10px', border: '8px solid transparent', margin: 'auto' }}>
                <Card id={'mini'} suit={v.suit} value={v.value} reveal={true} revealTimer={0} apiRevealPerCard={false} />
              </div>
            </div>
          })}
        </div>
      </div>
      <div style={{ background: 'transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)', border: '3px solid #008FFA', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10, marginLeft: 10 }}>
        <div style={{ margin: 5, marginTop: 0, color: '#999' }}>Player Cards</div>
        <div style={{ display: 'flex' }}>
          {playerCards.map((v, i) => {
            return <div key={i} className={getClassName(styles.border, styles.card)}>
              <div style={{ width: '45px', height: '90px', backgroundColor: '#fff', borderRadius: '10px', border: '8px solid transparent', margin: 'auto' }}>
                <Card id={'mini'} suit={v.suit} value={v.value} reveal={true} revealTimer={0} apiRevealPerCard={false} />
              </div>
            </div>
          })}
        </div>
      </div>


    </div>

  }

  const DisplayResults = ({ game }) => {
      const gameID = game.id

      const keyMap = {
          playerPair: 'Player Pair',
          bankerPair: 'Banker Pair',
          natural8: 'Natural 8',
          natural9: 'Natural 9',
          lucky6_2: '2 Card Lucky 6',
          lucky6_3: '3 Card Lucky 6',
          mega6_2: '2 Card Mega 6',
          mega6_3: '3 Card Mega 6',
      }

      return (
          <>
              <div
                  style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr auto 1fr',
                      gridGap: 30,
                      width: '100%',
                      justifyContent: 'space-evenly',
                      margin: '10px 0',
                      position: 'relative',
                  }}
              >
                  {canEditResult && (
                      <EditIcon
                          onClick={() => {
                              setPageNav('editResults')
                              setEditGameID(gameID)
                          }}
                          style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              fontSize: 29,
                              color: '#ccc',
                              cursor: 'pointer',
                          }}
                      />
                  )}

                  <div
                      style={{
                          gridColumn: '1 / span 1',
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right',
                          justifyContent: 'center',
                      }}
                  >
                      <div style={{ color: '#999' }}>{'Dealer'}</div>
                      <div style={{ marginBottom: 5 }}>
                          <b>{game.dealer ? game.dealer : 'Unknown Dealer'}</b>
                      </div>

                      <div style={{ color: '#999' }}>{'Start Time'}</div>
                      <div style={{ marginBottom: 5 }}>
                          <b>{game.started ? DateTimeDisplay(game.started) : '-'}</b>
                      </div>

                      <div style={{ color: '#999' }}>{'End Time'}</div>
                      <div style={{ marginBottom: 5 }}>
                          <b>{game.ended ? DateTimeDisplay(game.ended) : '-'}</b>
                      </div>

                      {game.editor && (
                          <>
                              <div style={{ color: '#999' }}>{'Edited Time'}</div>
                              <div style={{ marginBottom: 5 }}>
                                  <b>{game.edited ? DateTimeDisplay(game.edited) : '-'}</b>
                              </div>
                          </>
                      )}
                  </div>

                  <div style={{ gridColumn: '2 / span 1', display: 'flex', justifyContent: 'center' }}>
                      {!game.fakeCards && !game.voider && !isEmpty(game.cards) && getCardsVisual(game.cards)}
                  </div>

                  <div
                      style={{
                          gridColumn: '3 / span 1',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                      }}
                  >
                      <div style={{ color: '#999' }}>{'Winner'}</div>
                      <div style={{ marginBottom: 5, fontSize: 20 }}>
                          <b>
                              {game.result.status === 'P' ? (
                                  <span style={{ color: '#008FFA' }}>Player</span>
                              ) : game.result.status === 'B' ? (
                                  <span style={{ color: '#FF4F4F' }}>Banker</span>
                              ) : game.result.status === 'T' ? (
                                  <span style={{ color: 'lime' }}>Tie</span>
                              ) : game.result.status === 'V' ? (
                                  <span style={{ color: 'hotpink' }}>Voided</span>
                              ) : (
                                  'Unknown'
                              )}
                          </b>
                      </div>

                      {Object.keys(game.result)
                          .filter((v) => v !== 'status' && game.result[v])
                          .map((v, i) => (
                              <div key={i}>
                                  {keyMap[v]} <CheckIcon style={{ verticalAlign: 'bottom', color: 'lime' }} />
                              </div>
                          ))}

                      {game.voider && (
                          <>
                              <div style={{ color: '#999' }}>{'Game is voided by'}</div>
                              <div style={{ marginBottom: 5 }}>
                                  <b>{game.voider}</b>
                              </div>
                          </>
                      )}

                      {game.editor && (
                          <>
                              <div style={{ color: '#999' }}>{'Game is edited by'}</div>
                              <div style={{ marginBottom: 5 }}>
                                  <b>{game.editor}</b>
                              </div>
                          </>
                      )}
                  </div>
              </div>
          </>
      )
  }

  useEffect(() => {
    if (expandedRows.length === 0) {
      const index = 0
      setExpandedRows(index)
    }
  }, [expandedRows, filteredRows])

  return [<Table key="tableHead" className={styles.tableHeadRoot} >
    <TableHead className={styles.tableHead}>
      <TableRow>
        {filterKeys.map((v, i) =>
          <TableCell classes={{ root: styles.tableCellRoot }}
            key={v}
            align='center'
            style={(colWidths[i] ? { width: colWidths[i] } : {})}
          >
            {v}
          </TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody className={styles.tableRoot}>
      {filteredRows.map((row, index) =>
        <TableRow key={index} className={`${styles.fakeTableRow} ${styles.tableRow}`}>
          <TableCell style={{ padding: 0 }} colSpan="100%">
            <Accordion style={{ backgroundColor: '#1f1f1f', padding: '8px 0' }}>
              <AccordionSummary style={{ padding: 0 }}>
                {filterKeys.map((v, i) =>
                  <div className={styles.tableCellRoot} style={(colWidths[i] ? { width: colWidths[i] } : {})}
                    key={v}
                    align='center'>
                    {typeof row[v] !== 'function' ? row[v] : row[v]()}
                  </div>)}
              </AccordionSummary>
              <AccordionDetails>
                <></>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>)}
    </TableBody>
  </Table>,

  <Table key="table" className={styles.table}>
    <TableHead className={`${styles.fakeTableHead} ${styles.tableHead}`}>
      <TableRow>
        {filterKeys.map((v, i) =>
          <TableCell classes={{ root: styles.tableCellRoot }}
            key={v}
            align='center'
            style={(colWidths[i] ? { width: colWidths[i] } : {})}
          >
            {v}
          </TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody className={styles.tableRoot}>
      {filteredRows.map((row, index) =>
        <TableRow key={index} className={styles.tableRow}>
          <TableCell style={{ padding: 0 }} colSpan="100%">

            <Accordion
              style={{ backgroundColor: '#1f1f1f', padding: '8px 0' }}
              expanded={expandedRows.includes(index) ? true : false}
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedRows(index)
                  setExpandedURL(row.url)
                }
              }}>
              <AccordionSummary style={{ padding: 0 }}>
                {filterKeys.map((v, i) =>
                  <div className={styles.tableCellRoot} style={(colWidths[i] ? { width: colWidths[i] } : {})}
                    key={v}
                    align='center'>
                    {typeof row[v] !== 'function' ? row[v] : row[v]()}
                  </div>)}
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#333' }}
              >

                <div color="textSecondary" style={{ margin: 'auto', width: '100%' }}>
                  <DisplayResults game={row.url} />
                </div>

              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>)}
      <TableRow className={styles.tableRow} style={{ height: 50 }}>
        <TableCell style={{ padding: 0, border: 'none', cursor: 'auto' }} colSpan="100%"></TableCell>
      </TableRow>

    </TableBody>
  </Table>,
  pagination && <TablePagination key="tablePagination" className={styles.tablePagination}
    classes={{
      actions: styles.paginationActions,
      input: styles.paginationInput
    }}
    rowsPerPageOptions={[10, 40, 100]}
    component="div"
    count={deviceGames.length}
    rowsPerPage={rowsPerPage}
    page={page}
    backIconButtonProps={{
      'aria-label': 'previous page',
    }}
    nextIconButtonProps={{
      'aria-label': 'next page',
    }}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
  ]
}

export default ExpandableTable
