import React from "react";
import { isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import { Transition } from "react-transition-group";
import { makeStyles } from "@mui/styles";

const duration = 200;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

const rootStyles = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    modal: {
        position: 'relative',
        backgroundColor: '#1B1B1F',
        color: '#fff',
        textAlign: 'center',
        borderRadius: 25,
        padding: 50,
        outline: 0,

    },
    modalHeader: {
        backgroundColor: '#111314',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        borderRadius: '25px 25px 0 0'
    },
    modalPrompt: {
        marginTop: '3em',
        paddingTop: '1em',
        fontSize: 13
    },
    modalButtonRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '5%',
        '& button': {
            //width: '33%',
            height: '4.2rem',
            backgroundColor: '#303238',
            color: '#fff',
            boxShadow: '0px 1px 1px #161D251A',
            border: '1px solid #5F606A',
            fontSize: '1.5rem',

        }
    },
    modalInput: {
        width: '90%',
        height: '4.2rem',
        backgroundColor: '#303238',
        boxShadow: '0px 1px 2px #00000014',
        border: '1px solid #5F606A',
        borderRadius: 4,
        fontSize: '2rem',
        '& .MuiOutlinedInput-root': { height: '4.2rem' }

    },
}))



const DealerConfirmation = ({ setModalObj, modalObj }) => {
    const s = rootStyles()
    const { t } = useTranslation('common')




    const modalConfirmPress = async () => {
        if (!isUndefined(modalObj.confirm) && typeof modalObj.confirm === 'function') modalObj.confirm()
        setModalObj({ ...modalObj, show: false })
    }

    const modalCancelPress = async () => {
        if (!isUndefined(modalObj.cancel) && typeof modalObj.cancel === 'function') modalObj.cancel()
        setModalObj({ ...modalObj, show: false })
    }



    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10000, backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <Transition in={true} timeout={duration} appear={true}>
                {state => (
                    <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}>
                        <div className={s.modal}>
                            <div className={s.modalHeader}><h1> DEALER CONFIRMATION</h1></div>
                            <div className={s.modalPrompt}>
                                <h2 style={{ fontWeight: 200, fontSize: '3.5vh' }}>
                                    {modalObj.modalMsg}
                                    <br /> Please confirm or cancel the following actions
                                </h2>
                                <div className={s.modalButtonRow}>
                                    <button style={{ borderRadius: 10 }} onClick={(e) => { modalCancelPress() }}> {t('global.cancel')} </button>
                                    <button style={{ borderRadius: 10 }} onClick={(e) => { modalConfirmPress() }}> {t('global.confirm')} </button>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </Transition>
        </div>
    )
}

export default DealerConfirmation
