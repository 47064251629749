export const getMinBetColor = (minbet, rainbow) => {
    return (Object.keys(rainbow).reduce((c, k, i) => {
        if (minbet && rainbow && rainbow['level' + i].value && (Number(minbet) >= Number(rainbow['level' + i].value))) {
            c = rainbow['level' + i].color
        }
        return c
    }, '#000'))
}

export const calculateMinBetColor = (fields) => {
    if (('minbet' in fields) && ('colorThresholds' in fields)) {
        const rainbow = fields.colorThresholds
        const minbet = fields.minbet
        fields.minBetColor = getMinBetColor(minbet, rainbow)
        return fields
    }
    return fields
}

