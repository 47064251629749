import React, { useState } from 'react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles } from '@mui/styles'

import { usePublish, fetch } from '../api'

const componentStyles = makeStyles((theme) => ({
    root: {
        flex: '1 1',
        display: 'flex',
        background: '#545454',
    },
    container: {
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        paddingRight: 0,
    },
    gameOutput: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
        maxHeight: '-webkit-fill-available',
    },
    output: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
    },
    outputGames: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
        overflowY: 'auto',
    },
    cardSelectContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

const WarGame = ({
    time,
    device,
    deviceID,
    gameVariant,
    name,
    ip,
    games,
    game,
    trend,
    statistics,
    etgSummary,
}) => {
    const styles = componentStyles()
    const cardPublish = usePublish(ip + '/war/card')
    // const [from, setFrom] = useState(0)
    // const [to, setTo] = useState(0)
    const [cardSuit, setCardSuit] = useState(1)
    const [cardValue, setCardValue] = useState(11)
    const sendCard = async (owner) => {
        try {
            await cardPublish({
                suit: cardSuit,
                value: cardValue,
            })
        } catch (e) {
            console.warn(e)
        }
    }

    const sendBetsOff = async () => {
        try {
            await window.fetch('http://' + ip + '/war/betsoff')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendEnd = async () => {
        try {
            await window.fetch('http://' + ip + '/war/end')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendWar = async () => {
        try {
            await window.fetch('http://' + ip + '/war/war')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendVoid = async () => {
        try {
            await fetch(ip + '/war/void')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendDeck = async () => {
        try {
            await window.fetch('http://' + ip + '/war/deck')
        } catch (e) {
            console.warn(e)
        }
    }


    const getSummary = (gameVariant) => {
        switch (gameVariant) {
            case 'etg':
                return etgSummary
            default: { }
        }
    }

    const timeInSeconds = Math.round(time / 1000000000)

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div>Name: {name}</div>
                <div>IP: {ip}</div>
                <div>TIME: {timeInSeconds}</div>
                <div># of games: {games.length}</div>
                <div className={styles.cardSelectContainer}>
                    <Button onClick={() => sendCard()}>Card</Button>
                    <Select
                        labelId='card-suit-select'
                        id='card-suit-select'
                        value={cardSuit}
                        label='Suit'
                        onChange={(e) => {
                            setCardSuit(e.target.value)
                        }}
                    >
                        <MenuItem value={1}>
                            <span role='img' aria-label='spade'>
                                ♠️
                            </span>
                        </MenuItem>
                        <MenuItem value={2}>
                            <span role='img' aria-label='heart'>
                                ♥️
                            </span>
                        </MenuItem>
                        <MenuItem value={3}>
                            <span role='img' aria-label='club'>
                                ♣️
                            </span>
                        </MenuItem>
                        <MenuItem value={4}>
                            <span role='img' aria-label='diamond'>
                                ♦️
                            </span>
                        </MenuItem>
                    </Select>
                    <Select
                        labelId='player-card-value-select'
                        id='player-card-value-select'
                        value={cardValue}
                        label='Value'
                        onChange={(e) => {
                            setCardValue(e.target.value)
                        }}
                    >
                        <MenuItem value={14}>A</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>J</MenuItem>
                        <MenuItem value={12}>Q</MenuItem>
                        <MenuItem value={13}>K</MenuItem>
                    </Select>
                </div>
                <Button onClick={() => sendBetsOff()}>Bets Off</Button>
                <Button onClick={() => sendWar()}>Start War</Button>
                <Button onClick={() => sendEnd()}>End Game</Button>
                <Button onClick={() => sendVoid()}>Void Game</Button>
                <Button disabled={games.length === 0} onClick={() => sendDeck()}>
                    Deck Change
                </Button>
                <h1>Statistics</h1>
                <pre className={styles.output}>{JSON.stringify(statistics, undefined, 2)}</pre>
                <h1>Trend</h1>
                <pre className={styles.output}>{JSON.stringify(trend, undefined, 2)}</pre>
                <h1>Summary</h1>
                <pre className={styles.output}>{JSON.stringify(getSummary(gameVariant), undefined, 2)}</pre>
            </div>
            <div className={styles.container}>
                <h1>Game [{game.data.status}]</h1>
                <pre className={styles.gameOutput}>{JSON.stringify(game.data, undefined, 2)}</pre>
            </div>
            <div className={styles.container}>
                <h1>Games [{games.length}]</h1>
                <pre className={styles.outputGames}>
                    {games.length > 0 ? JSON.stringify(games[games.length - 1], undefined, 2) : 'no games yet'}
                </pre>
            </div>
        </div>
    )
}

export default WarGame