import React, { } from 'react'
import casino from './casino.png'
import zone from './zone.png'
import pit from './pit.png'
import table from './table.png'
import xstadium from './xstadium.png'
// import Icon from '@material-ui/core/Icon'


const Icons = ({ onClick = () => { }, icon, size, style }) => {
    const icons = {
        casino: casino,
        zone: zone,
        pit: pit,
        table: table,
        xstadium: xstadium,
    }
    const filters = {
        casino: 'invert(38%) sepia(94%) saturate(428%) hue-rotate(160deg) brightness(95%) contrast(87%)',
        zone: 'invert(40%) sepia(83%) saturate(2273%) hue-rotate(337deg) brightness(93%) contrast(95%)',
        pit: 'invert(83%) sepia(27%) saturate(228%) hue-rotate(147deg) brightness(87%) contrast(90%)',
        table: 'invert(56%) sepia(24%) saturate(3320%) hue-rotate(178deg) brightness(92%) contrast(85%)',
        xstadium: 'none',
    }


    return <div onClick={onClick} style={{
        height: size,
        width: size,
        marginRight: 5,
        display: 'flex',
        alignItems: 'center',
        ...style
    }} >
        {Object.keys(icons).includes(icon) && <img src={icons[icon]} alt='' height={size} width={size} style={{ filter: filters[icon] }}></img>}
        {/* {!Object.keys(icons).includes(icon) && <Icon style={{ padding: '0 2px 0 7px', marginLeft: -10, color: '#E6A655' }}>power</Icon>} */}
    </div>
}

export default Icons