import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { usePublish, fetch, unpublish } from '../api'
import { Cards } from '../xtable/cards/Card'
import Timer from './Timer'

const componentStyles = makeStyles((theme) => ({
    root: {
        flex: '1 1',
        display: 'flex',
        background: '#545454',
    },
    container: {
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        paddingRight: 0,
    },
    gameOutput: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
        maxHeight: '-webkit-fill-available',
    },
    output: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
    },
    outputGames: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
        overflowY: 'auto',
    },
    cardSelectContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

const BJGame = ({
    time,
    device,
    deviceID,
    gameVariant,
    name,
    ip,
    session,
    sessionStart,
    games,
    game,
    trend,
    statistics,
    standardSummary,
    anyPairSlotSummary,
}) => {
    const styles = componentStyles()
    const card = usePublish(ip + '/blackjack/card')
    const [to, setTo] = useState(0)
    const [cardSuit, setCardSuit] = useState(1)
    const [cardValue, setCardValue] = useState(11)
    const [dialogHandView, setDialogHandView] = useState(false)

    const sendCard = async () => {
        console.table({
            suit: cardSuit,
            value: cardValue,
        })

        try {
            await card({
                suit: cardSuit,
                value: cardValue,
            })
        } catch (e) {
            console.warn(e)
        }
    }

    const sendEndHand = async () => {
        try {
            await window.fetch('http://' + ip + '/blackjack/endhand')
            console.log('/blackjack/endhand')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendSplitHand = async () => {
        try {
            await window.fetch('http://' + ip + '/blackjack/splithand')
            console.log('/blackjack/splithand')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendVoid = async () => {
        try {
            await fetch(ip + '/blackjack/void')
            console.log('blackjack/void')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendDeck = async () => {
        try {
            await window.fetch('http://' + ip + '/blackjack/deck')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendFirstRoundEnd = async () => {
        try {
            await window.fetch('http://' + ip + '/blackjack/firstroundend')
            console.log("/blackjack/firstroundend')")
        } catch (e) {
            console.warn(e)
        }
    }

    const sendFirstRoundEndOCR = async () => {
        try {
            await window.fetch('http://' + ip + '/blackjack/firstroundendocr')
            console.log("/blackjack/firstroundendocr')")
        } catch (e) {
            console.warn(e)
        }
    }

    const startSession = async (account) => {
        try {
            await sessionStart({
                account,
            })
        } catch (e) {
            console.warn(e)
        }
    }

    const endSession = async () => {
        try {
            await unpublish(ip + '/sessions/' + deviceID)
        } catch (e) {
            console.warn(e)
        }
    }

    const openHandView = () => {
        setDialogHandView(true)
    }

    const getHand = (owner) => {
        if (!game.data || !game.data.hands) {
            return null
        }

        const hands = game.data.hands.filter((hand) => hand.owner === owner)
        if (!hands || hands.length === 0) {
            return null
        }

        return hands
    }

    const timeInSeconds = Math.round(time / 1000000000)
    const timeInMiliSeconds = Math.round(time / 1000000)

    if (to === 0 || timeInMiliSeconds - to > 120000) {
        // setFrom(timeInMiliSeconds - 120000)
        setTo(timeInMiliSeconds)
    }

    const dealerHands = getHand(0)
    const player1Hands = getHand(1)
    const player2Hands = getHand(2)
    const player3Hands = getHand(3)
    const player4Hands = getHand(4)
    const player5Hands = getHand(5)

    const getSummary = (gameVariant) => {
        switch (gameVariant) {
            case 'standard':
                return standardSummary
            case 'anyPairSlot':
                return anyPairSlotSummary
            default: {
            }
        }
    }

    return (
        <>
            <Dialog
                key='dialogChange'
                open={dialogHandView}
                onClose={() => {}}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullScreen={true}
            >
                <DialogTitle id='alert-dialog-title'>Game [{game.data.status}]</DialogTitle>
                <DialogContent sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                    <Box>
                        <Typography variant='h6' gutterBottom>
                            Dealer
                        </Typography>
                        {dealerHands && (
                            <>
                                {dealerHands.map((hand) => (
                                    <Cards gameType='blackjack' cards={hand.cards} notDone={hand.ended} />
                                ))}
                            </>
                        )}
                    </Box>

                    <Box width='100%' height='100%' display='flex'>
                        <Box width='100%' height='100%'>
                            <Typography variant='h6' gutterBottom>
                                Player 1
                            </Typography>
                            <PerfectScrollbar sx={{ height: '100%', overflow: 'auto' }}>
                                {player1Hands && (
                                    <>
                                        {player1Hands.map((hand, index) => (
                                            <>
                                                <Typography variant='subtitle1'>Player 1 - hand {index + 1}</Typography>
                                                <Cards gameType='blackjack' cards={hand.cards} notDone={hand.ended} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </PerfectScrollbar>
                        </Box>

                        <Box width='100%' height='100%'>
                            <Typography variant='h6' gutterBottom>
                                Player 2
                            </Typography>
                            <PerfectScrollbar sx={{ height: '100%', overflow: 'auto' }}>
                                {player2Hands && (
                                    <>
                                        {player2Hands.map((hand, index) => (
                                            <>
                                                <Typography variant='subtitle1'>Player 2 - hand {index + 1}</Typography>
                                                <Cards gameType='blackjack' cards={hand.cards} notDone={hand.ended} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </PerfectScrollbar>
                        </Box>

                        <Box width='100%' height='100%'>
                            <Typography variant='h6' gutterBottom>
                                Player 3
                            </Typography>
                            <PerfectScrollbar sx={{ height: '100%', overflow: 'auto' }}>
                                {player3Hands && (
                                    <>
                                        {player3Hands.map((hand, index) => (
                                            <>
                                                <Typography variant='subtitle1'>Player 2 - hand {index + 1}</Typography>
                                                <Cards gameType='blackjack' cards={hand.cards} notDone={hand.ended} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </PerfectScrollbar>
                        </Box>

                        <Box width='100%' height='100%'>
                            <Typography variant='h6' gutterBottom>
                                Player 4
                            </Typography>
                            <PerfectScrollbar sx={{ height: '100%', overflow: 'auto' }}>
                                {player4Hands && (
                                    <>
                                        {player4Hands.map((hand, index) => (
                                            <>
                                                <Typography variant='subtitle1'>Player 2 - hand {index + 1}</Typography>
                                                <Cards gameType='blackjack' cards={hand.cards} notDone={hand.ended} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </PerfectScrollbar>
                        </Box>

                        <Box width='100%' height='100%'>
                            <Typography variant='h6' gutterBottom>
                                Player 5
                            </Typography>
                            <PerfectScrollbar sx={{ height: '100%', overflow: 'auto' }}>
                                {player5Hands && (
                                    <>
                                        {player5Hands.map((hand, index) => (
                                            <>
                                                <Typography variant='subtitle1'>Player 2 - hand {index + 1}</Typography>
                                                <Cards gameType='blackjack' cards={hand.cards} notDone={hand.ended} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </PerfectScrollbar>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        color='warning'
                        onClick={() => {
                            setDialogHandView(false)
                        }}
                    >
                        Exit
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={styles.root}>
                <div className={styles.container}>
                    <div>Name: {name}</div>
                    <div>IP: {ip}</div>
                    <div>TIME: {timeInSeconds}</div>
                    <div>SESSION: {session}</div>
                    <Timer time={time} device={device} games={games} game={game} />
                    <div># of games: {games.length}</div>
                    <Button onClick={() => openHandView()}>Open Hand View</Button>
                    <div className={styles.cardSelectContainer}>
                        <Button onClick={() => sendCard()}>Card</Button>
                        <Select
                            labelId='card-suit-select'
                            id='card-suit-select'
                            value={cardSuit}
                            label='Suit'
                            onChange={(e) => {
                                setCardSuit(e.target.value)
                            }}
                        >
                            <MenuItem value={1}>
                                <span role='img' aria-label='spade'>
                                    ♠️
                                </span>
                            </MenuItem>
                            <MenuItem value={2}>
                                <span role='img' aria-label='heart'>
                                    ♥️
                                </span>
                            </MenuItem>
                            <MenuItem value={3}>
                                <span role='img' aria-label='club'>
                                    ♣️
                                </span>
                            </MenuItem>
                            <MenuItem value={4}>
                                <span role='img' aria-label='diamond'>
                                    ♦️
                                </span>
                            </MenuItem>
                        </Select>
                        <Select
                            labelId='card-value-select'
                            id='card-value-select'
                            value={cardValue}
                            label='Value'
                            onChange={(e) => {
                                setCardValue(e.target.value)
                            }}
                        >
                            <MenuItem value={1}>A</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>J</MenuItem>
                            <MenuItem value={12}>Q</MenuItem>
                            <MenuItem value={13}>K</MenuItem>
                        </Select>
                    </div>

                    <Button onClick={() => sendFirstRoundEnd()}>First Round End</Button>
                    <Button disabled onClick={() => sendFirstRoundEndOCR()}>
                        First Round End OCR
                    </Button>
                    <Button onClick={() => sendEndHand()}>End Hand</Button>
                    <Button onClick={() => sendSplitHand()}>Split Hand</Button>

                    <Button onClick={() => sendVoid()}>Void Game</Button>
                    <Button disabled={games.length === 0} onClick={() => sendDeck()}>
                        Deck Change
                    </Button>
                    <Button onClick={() => startSession('dealer')}>Start Session</Button>
                    <Button onClick={() => endSession()}>End Session</Button>
                    <h1>Statistics</h1>
                    <pre className={styles.output}>{JSON.stringify(statistics, undefined, 2)}</pre>
                    <h1>Trend</h1>
                    <pre className={styles.output}>{JSON.stringify(trend, undefined, 2)}</pre>
                    <h1>Summary</h1>
                    <pre className={styles.output}>{JSON.stringify(getSummary(gameVariant), undefined, 2)}</pre>
                </div>
                <div className={styles.container}>
                    <h1>Game [{game.data.status}]</h1>
                    <pre className={styles.gameOutput}>{JSON.stringify(game.data, undefined, 2)}</pre>
                </div>
                <div className={styles.container}>
                    <h1>Games [{games.length}]</h1>
                    <pre className={styles.outputGames}>
                        {games.length > 0 ? JSON.stringify(games[games.length - 1], undefined, 2) : 'no games yet'}
                    </pre>
                </div>
            </div>
        </>
    )
}

export default BJGame
