import React, { useState, useEffect } from 'react'
import chartjs from 'chart.js'
import ChartDatasourcePrometheusPlugin from 'chartjs-plugin-datasource-prometheus'
import _ from 'lodash'
import moment from 'moment'

import {
    chartBackgroundColors,
    allChartBackgroundColors
} from './Formats.js'


const getStepResolution = (startDate, endDate) => {
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))
    if (numberOfDays === 0) {
        return '3600'
    }

    if (numberOfDays > 0 && numberOfDays <= 64) {
        return '86400'
    }

    if (numberOfDays > 64 && numberOfDays <= 128) {
        return '604800'
    }

    return '2419200'
}

const getTimeUnit = (startDate, endDate) => {
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))
    if (numberOfDays === 0) {
        return 'hour'
    }

    if (numberOfDays > 0 && numberOfDays <= 40) {
        return 'day'
    }

    if (numberOfDays > 40 && numberOfDays <= 80) {
        return 'week'
    }

    return 'month'
}


const TableHistoryChart = ({ builtChart, setBuiltChart, query, findInLabelMap, startDate, endDate, prometheus, nonTable, hideLegend }) => {

    const [chartElement, setChartElement] = useState(null)
    const [chartInstance, setChartInstance] = useState(null)
    const step = getStepResolution(startDate, endDate)
    const timeUnit = getTimeUnit(startDate, endDate)
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))
    // const startOfToday = moment().startOf('day')
    // const endOfToday = moment().endOf('day').unix()
    // const isLive = endDate === endOfToday
    // const includesToday = numberOfDays > 1 && endDate === endOfToday

    const buildChart = () => {
        const ctx = chartElement.getContext('2d')


        setBuiltChart(true)
        let metricIndex = 0
        const chartOptions = {
            type: 'line',
            plugins: [ChartDatasourcePrometheusPlugin],
            options: {
                animation: {
                    duration: 800
                },
                lineTension: 0,
                spanGaps: false,
                showLines: true,
                cubicInterpolationMode: 'monotone',
                pointStyle: 'rect',
                pointBorderWidth: 5,
                backgroundColor: nonTable ? allChartBackgroundColors : chartBackgroundColors,
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: timeUnit,
                            minUnit: timeUnit,
                            round: timeUnit
                        },
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: hideLegend ? false : true,
                },
                plugins: {
                    'datasource-prometheus': {
                        prometheus: {
                            endpoint: prometheus,
                            baseURL: "/api/v1",   // default value
                            timeout: 55000,
                        },
                        query: query,
                        fillGaps: false,
                        backgroundColor: nonTable ? allChartBackgroundColors : chartBackgroundColors,
                        borderColor: nonTable ? allChartBackgroundColors : chartBackgroundColors,
                        cubicInterpolationMode: 'monotone', //'monotone',
                        borderWidth: 5,
                        tension: 0,
                        stepped: false, // "after",
                        fill: false,
                        timeRange: {
                            type: 'absolute',
                            start: new Date((Number(startDate) + ((numberOfDays === 0) ? 10 : 86300)) * 1000),
                            end: new Date((Number(endDate) - (numberOfDays > 0 ? 10 : 0)) * 1000),
                            step: step,
                            // msUpdateInterval: isLive ? 10000 : undefined
                        },
                        findInLabelMap: (_metric) => {
                            metricIndex++
                            // weird
                            // console.log(metric, metricIndex)
                            return findInLabelMap(metricIndex)
                        }
                    },
                },
            },
        }
        // console.log("build chart", startDate, endDate)
        if (chartInstance) {
            // console.log("should clear chart", chartInstance)
            // chartInstance.options = chartOptions
            // chartInstance.clear()
            // chartInstance.clear()
            // console.log("LIO", isLive, _.isEqual(chartInstance.options.plugins['datasource-prometheus'].query, query))
            const sameQuery = _.isEqual(chartInstance.options.plugins['datasource-prometheus'].query, query)
            chartInstance.options.animation.duration = sameQuery ? 0 : 800
            chartInstance.options.plugins['datasource-prometheus'].query = query
            chartInstance.options.plugins['datasource-prometheus'].timeRange = chartOptions.options.plugins['datasource-prometheus'].timeRange
            chartInstance.options.plugins['datasource-prometheus'].findInLabelMap = chartOptions.options.plugins['datasource-prometheus'].findInLabelMap
            // chartInstance.draw()
            chartInstance.update()
            // chartInstance.destroy()
        } else {
            const myChart = new chartjs(ctx, chartOptions)
            setChartInstance(myChart)
        }
    }

    useEffect(() => {
        if (!chartElement) {
            return undefined
        }

        buildChart()
        return undefined
        // eslint-disable-next-line
    }, [chartElement, builtChart])

    return <div><canvas ref={setChartElement}
        style={{
            minHeight: 200,
            width: '-webkit-fill-available'
        }}
        height="200"></canvas></div>
}

export default TableHistoryChart