import React, { useState } from 'react'
import moment from 'moment'
import { startCase } from 'lodash'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { formatCashString } from '../../xtable/Formats'
import CircleIcon from '@mui/icons-material/Circle'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'
import EditIcon from '@mui/icons-material/Edit'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Template from './Template'
import Apply from './Apply'
import AlertDialog, {
    startLoading,
    defaultAlertDialogState, handleClose
} from '../components/AlertDialog'
import { unpublish, useSubscribe } from '../../api'

const Templates = () => {
    const [settings, settingsSocket] = useSubscribe('settings')
    const [templates, templatesSocket] = useSubscribe('templates/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')

    const [editingTemplate, setEditingTemplate] = useState(null)
    const [templateDialog, setTemplateDialog] = useState(false)

    const [searchTerm, setSearchTerm] = useState('')

    const [applyDialog, setApplyDialog] = useState(false)
    const [applyTemplate, setApplyTemplate] = useState(null)

    const [alertDialogState, setAlertDialogState] = useState(defaultAlertDialogState)

    const active = templatesSocket &&
        templatesSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN

    const handleCloseTemplateDialog = (event, reason) => {
        if (reason && reason === "backdropClick")
            return
        setTemplateDialog(false)
        setEditingTemplate(null)
    }

    const handleCloseApplyDialog = (event, reason) => {
        if (reason && reason === "backdropClick")
            return
        setApplyDialog(false)
    }

    const handleOpenTemplateDialog = () => {
        setTemplateDialog(true)
    }

    const handleOpenApplyDialog = () => {
        setApplyDialog(true)
    }

    const selectApplyTemplate = (params) => {
        const found = templates.filter(t => t.index === params.row.id)
        if (found.lenght === 0) {
            console.warn("failed to find template", params.row.id)
            return
        }
        console.log("template", found[0])

        setApplyTemplate(found[0])
        handleOpenApplyDialog()
    }

    const selectEditTemplate = (params) => {
        const found = templates.filter(t => t.index === params.row.id)
        if (found.lenght === 0) {
            console.warn("failed to find template", params.row.id)
            return
        }
        setEditingTemplate(found[0])
        handleOpenTemplateDialog()
    }

    const deleteTemplate = async (params) => {
        startLoading(alertDialogState, setAlertDialogState)
        try {
            await unpublish('templates/' + params.row.id)
        } catch (e) {
            console.warn('failed to delete template', params.row.id, e)
        } finally {
            handleClose(alertDialogState, setAlertDialogState)
        }
    }

    const confirmDeleteTemplate = (params) => {
        setAlertDialogState({
            show: true,
            type: 'confirmation',
            title: 'Confirm Delete',
            message: `Would you like to delete the template: ${params.row.templateName} (${params.row.gameType}-${params.row.gameVariant})?`,
            confirm: () => deleteTemplate(params),
            error: false,
            loading: false
        })
    }

    const dateDisplay = (time) => moment.unix(time / 1000000000).format('YYYY-MM-DD')

    if (!active ||
        !devices ||
        !templates ||
        !settings) {
        return <LinearProgress />
    }

    const rows = templates
        ? templates
            .filter((template) => template.data.type === 'game')
            .filter((template) => JSON.stringify(template).indexOf(searchTerm) >= 0)
            .map((template) => {
                return {
                    id: template.index ? template.index : '-',
                    templateName: template.data.templateName ? template.data.templateName : '-',
                    gameType: template.data.gameType ? template.data.gameType : '-',
                    gameVariant: template.data.gameVariant ? template.data.gameVariant : '-',
                    minbet: template.data.bets
                        ? template.data.bets[template.data.gameType][template.data.gameVariant].minBet
                        : '-',
                    minBetColor: template.data.minBetColor ? template.data.minBetColor : '-',
                    maxbet: template.data.bets
                        ? template.data.bets[template.data.gameType][template.data.gameVariant].maxBet
                        : '-',
                    trendboardLayout: template.data.layout ? template.data.layout : '-',
                    lastUpdatedOn: template.updated ? template.updated : template.created,
                    lastUpdatedBy: template.data.updatedBy ? template.data.updatedBy : '-',
                }
            })
        : []

    const columns = [
        {
            field: 'templateName',
            headerName: 'Name',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{params.value}</>}</>
            },
            width: 115,
        },
        {
            field: 'gameType',
            headerName: 'Game Type',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{startCase(params.value)}</>}</>
            },
            width: 115,
        },
        {
            field: 'gameVariant',
            headerName: 'Game Variant',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{startCase(params.value)}</>}</>
            },
            width: 115,
        },
        {
            field: 'minbet',
            headerName: 'Min Bet',
            renderCell: (params) => {
                return (
                    <Box display='flex' alignItems='center' gap='.5rem'>
                        {params.row.minBetColor && <CircleIcon sx={{ color: params.row.minBetColor }} />}
                        <>{formatCashString(params.value)}</>
                    </Box>
                )
            },
            width: 180,
        },
        {
            field: 'maxbet',
            headerName: 'Max Bet',
            renderCell: (params) => {
                return <>{formatCashString(params.value)}</>
            },
            width: 180,
        },
        {
            field: 'trendboardLayout',
            headerName: 'Trendboard Layout',
            width: 150,
        },
        {
            field: 'lastUpdatedOn',
            headerName: 'Last Updated On',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{dateDisplay(params.value)}</>}</>
            },
            width: 145,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 145,
        },
        {
            field: 'actions',
            headerName: 'actions',
            sortable: false,
            filterable: false,
            width: 145,
            renderCell: (params) => {
                return (<>
                    <IconButton onClick={() => selectApplyTemplate(params)}>
                        <ShareIcon />
                    </IconButton>
                    <IconButton onClick={() => selectEditTemplate(params)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => confirmDeleteTemplate(params)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </>)
            },
        }
    ]

    return (
        <>
            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
            <Template open={templateDialog}
                handleClose={handleCloseTemplateDialog}
                devices={devices}
                status={status}
                settings={settings}
                editing={editingTemplate} />
            <Apply open={applyDialog}
                template={applyTemplate}
                handleClose={handleCloseApplyDialog}
                devices={devices} />
            <Box style={{ height: '-webkit-fill-available' }} padding='2rem' display='flex' flexDirection='column' gap='3rem'>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <TextField
                        variant='outlined'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box display='flex' flexDirection='column' gap='1rem'>
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <Button variant='contained' color='primary' onClick={handleOpenTemplateDialog}>Add template</Button>
                        </Box>
                    </Box>
                </Box>
                <DataGrid
                    sx={{
                        colorScheme: 'dark',
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                            outline: 'none',
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        },
                        // pinnedColumns: { left: ['templateName'], right: ['actions'] }
                    }}
                    pageSizeOptions={[5, 10, 15, 20]}
                    gridRowId={(row) => row.index}
                    onRowDoubleClick={(params) => selectApplyTemplate(params)}
                />
            </Box>
        </>
    )
}

export default Templates