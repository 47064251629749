import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import Select from '@mui/material/Select'
import SnackbarContent from '@mui/material/SnackbarContent'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { validate, update, getFieldsValues, setDefaults } from '../../../forms'
import { defaultValues } from '../defaultValues'

import FormGroup from '@mui/material/FormGroup'

const rootStyles = makeStyles((theme) => ({
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        width: 700,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
    fieldRowWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5em',
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.2em',
    },
    togglesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.2em',
    },
    toggleWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))

const DealerUI = ({ publish, table, settings, xstadium, template, changeCallback }) => {
    const styles = rootStyles({ xstadium })

    const navigate = useNavigate()

    // form changed detect hook
    const [changed, setChanged] = useState(0)

    // field flags
    const [editing, setEditing] = useState([])
    const [submitted, setSubmitted] = useState(false)

    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)

    // formerly global fields - dealer
    const [skipSeatFour, setSkipSeatFour] = useState(false)
    const [cardsToBurn, setCardsToBurn] = useState(6)
    const [cardReveal, setCardReveal] = useState('immediate')
    const [allowEditResults, setAllowEditResults] = useState(false)
    const [allowEditCards, setAllowEditCards] = useState(false)
    const [showBurnOverdraw, setShowBurnOverdraw] = useState(false)
    const [doubleConfirmation, setDoubleConfirmation] = useState(false)
    const [revealTimer, setRevealTimer] = useState(3)
    const [buyInConfirmation, setbuyInConfirmation] = useState(0)
    const [buyInThreshold, setbuyInThreshold] = useState(0)
    const [cashOutConfirmation, setcashOutConfirmation] = useState(0)
    const [cashOutThreshold, setcashOutThreshold] = useState(0)
    const [shoe, setShoe] = useState('automatic')

    const [betTimer, setBetTimer] = useState(90)
    const [timerModeChipped, setTimerModeChipped] = useState(false)
    const [timerModeChipless, setTimerModeChipless] = useState(false)
    const [timerModeFixed, setTimerModeFixed] = useState(false)
    const [timerModeDisabled, setTimerModeDisabled] = useState(false)

    const gameType = table.data.gameType

    const fields = {
        skipSeatFour: {
            error: () => false,
            value: skipSeatFour,
            set: setSkipSeatFour,
            message: '',
        },
        shoe: {
            error: () => false,
            value: shoe,
            set: setShoe,
            message: '',
        },
        cardReveal: {
            error: () => false,
            value: cardReveal,
            set: setCardReveal,
            message: '',
        },
        revealTimer: {
            error: () =>
                Number(revealTimer) < 1 || !revealTimer || (revealTimer.trim && revealTimer.trim().length === 0),
            value: revealTimer,
            set: setRevealTimer,
            message: 'Reveal Timer cannot be empty or less than 1',
        },
        allowEditCards: {
            error: () => false,
            value: allowEditCards,
            set: setAllowEditCards,
            message: '',
        },
        doubleConfirmation: {
            error: () => false,
            value: doubleConfirmation,
            set: setDoubleConfirmation,
            message: '',
        },
        timerModeChipped: {
            error: () => false,
            value: timerModeChipped,
            set: setTimerModeChipped,
            message: '',
        },
        timerModeChipless: {
            error: () => false,
            value: timerModeChipless,
            set: setTimerModeChipless,
            message: '',
        },
        timerModeFixed: {
            error: () => false,
            value: timerModeFixed,
            set: setTimerModeFixed,
            message: '',
        },
        timerModeDisabled: {
            error: () => false,
            value: timerModeDisabled,
            set: setTimerModeDisabled,
            message: '',
        },
        betTimer: {
            error: () => Number(betTimer) < 1 || !betTimer || (betTimer.trim && betTimer.trim().length === 0),
            value: betTimer,
            set: setBetTimer,
            message: 'Bet Timer cannot be empty or less than 1',
        },
        cardsToBurn: {
            error: () => false,
            value: cardsToBurn,
            set: setCardsToBurn,
            message: '',
        },
        buyInConfirmation: {
            error: () => false,
            value: buyInConfirmation,
            set: setbuyInConfirmation,
            message: '',
        },
        buyInThreshold: {
            error: () => false,
            value: buyInThreshold,
            set: setbuyInThreshold,
            message: '',
        },
        cashOutConfirmation: {
            error: () => false,
            value: cashOutConfirmation,
            set: setcashOutConfirmation,
            message: '',
        },
        cashOutThreshold: {
            error: () => false,
            value: cashOutThreshold,
            set: setcashOutThreshold,
            message: '',
        },
        allowEditResults: {
            error: () => false,
            value: allowEditResults,
            set: setAllowEditResults,
            message: '',
        },
        showBurnOverdraw: {
            error: () => false,
            value: showBurnOverdraw,
            set: setShowBurnOverdraw,
            message: '',
        },
    }

    const validation = validate(fields)

    if (table.updated !== updated) {
        update(fields, editing, table.data, defaultValues)
        setUpdated(table.updated)
    }

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            try {
                setLoading(true)
                await publish({
                    ...table.data,
                    skipSeatFour: skipSeatFour,
                    shoe: String(shoe),
                    cardsToBurn: Number(cardsToBurn),
                    cardReveal: table.data.cardReveal,
                    revealTimer: Number(revealTimer),
                    buyInConfirmation: Number(buyInConfirmation),
                    buyInThreshold: Number(buyInThreshold),
                    cashOutConfirmation: Number(cashOutConfirmation),
                    cashOutThreshold: Number(cashOutThreshold),
                    allowEditResults: !!allowEditResults,
                    allowEditCards: !!allowEditCards,
                    showBurnOverdraw: !!showBurnOverdraw,
                    doubleConfirmation: !!doubleConfirmation,
                    betTimer: Number(betTimer),
                    timerModeChipped: !!timerModeChipped,
                    timerModeChipless: !!timerModeChipless,
                    timerModeFixed: !!timerModeFixed,
                    timerModeDisabled: !!timerModeDisabled,
                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
                setEditing([])
                setFail('')
                setChanged(0)
            } catch (e) {
                setFail(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
            } finally {
                setLoading(false)
            }
        }

    }

    const onFocus = (key) => setEditing([...editing, key])

    const onBlur = (e, key) =>
        e.target.value === table.data[key] ? setEditing(editing.filter((field) => field !== key)) : null

    useEffect(() => {
        if (changed > 0 && template) {
            changeCallback(getFieldsValues(fields))
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </Box>
                )}

                {submitted && validation.error && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    </Box>
                )}

                <Box>
                    <Box className={styles.fieldRowWrapper}>
                        {!settings.data.xstadium.etgMode && (gameType === 'baccarat' || gameType === 'blackjack') && (
                            <FormGroup>
                                <Box className={styles.toggleWrapper} component='label'>
                                    <Box className={styles.tooltipWrapper}>
                                        <Typography>Skip seat number 4</Typography>
                                        <Tooltip
                                            title={
                                                <span style={{ fontSize: '15px' }}>
                                                    Changes will take effect across Dealer UI {'>'} Seat Players view,
                                                    and XTable {'>'} Table reports view
                                                </span>
                                            }
                                            placement='right'
                                        >
                                            <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                        </Tooltip>
                                    </Box>
                                    <FormControlLabel
                                        value='skipSeatFour'
                                        control={
                                            <Switch
                                                checked={skipSeatFour}
                                                disabled={loading}
                                                onChange={(e) => {
                                                    setSkipSeatFour(e.target.checked)
                                                    setChanged(changed + 1)
                                                }}
                                                inputProps={{ 'aria-label': 'Skip seat number 4 checkbox' }}
                                            />
                                        }
                                    />
                                </Box>
                            </FormGroup>
                        )}
                        <FormControl variant='outlined'>
                            <Box className={styles.tooltipWrapper}>
                                <Typography>Shoe</Typography>
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '15px' }}>
                                            Choose Automatic for card shoes with game logic, and Manual for shoes
                                            without
                                        </span>
                                    }
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>

                            <Select
                                id='shoe'
                                fullWidth
                                onChange={(e) => {
                                    setShoe(e.target.value)
                                    setChanged(changed + 1)
                                }}
                                inputProps={{
                                    name: 'shoe',
                                    id: 'shoe',
                                }}
                                value={shoe}
                                disabled={
                                    loading ||
                                    (table.data.gameType === 'baccarat' && table.data.gameVariant === 'tiger')
                                }
                                size='small'
                            >
                                <MenuItem value='automatic'>Automatic</MenuItem>
                                <MenuItem value='manual'>Manual</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant='outlined'>
                            <Box className={styles.tooltipWrapper}>
                                <Typography>Card Reveal</Typography>
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '15px' }}>
                                            This can be changed in the Trendboard {'>'} Card Reveal setting
                                        </span>
                                    }
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>
                            <Typography>{cardReveal.charAt(0).toUpperCase() + cardReveal.slice(1)}</Typography>
                        </FormControl>

                        <FormControl variant='outlined'>
                            <Box className={styles.tooltipWrapper}>
                                <Typography>Timer countdown to card reveal (seconds)</Typography>
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '15px' }}>
                                            Available when Card Reveal is set to Timed. Cannot be zero
                                        </span>
                                    }
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>
                            <TextField
                                id='revealTimer'
                                name='revealTimer'
                                type='text'
                                size='small'
                                fullWidth
                                variant='outlined'
                                onChange={(e) => {
                                    setRevealTimer(e.target.value.replace(/\D/g, ''))
                                    setChanged(changed + 1)
                                }}
                                value={revealTimer}
                                disabled={loading || cardReveal === 'immediate' || cardReveal === 'trigger'}
                                error={submitted && fields.revealTimer.error()}
                                required
                            />
                        </FormControl>

                        <Box className={styles.togglesWrapper}>
                            <Box className={styles.toggleWrapper}>
                                <Typography>Allow dealer to edit cards</Typography>
                                <FormControlLabel
                                    value='allowEditCards'
                                    control={
                                        <Switch
                                            checked={allowEditCards}
                                            disabled={loading}
                                            onChange={(e) => {
                                                setAllowEditCards(e.target.checked)
                                                setChanged(changed + 1)
                                            }}
                                            inputProps={{ 'aria-label': 'edit cards checkbox' }}
                                        />
                                    }
                                />
                            </Box>

                            <Box className={styles.toggleWrapper}>
                                <Typography>Void game and edit cards requires Pit Boss confirmation</Typography>
                                <FormControlLabel
                                    value='doubleConfirmation'
                                    control={
                                        <Switch
                                            checked={doubleConfirmation}
                                            disabled={loading}
                                            onChange={(e) => {
                                                setDoubleConfirmation(e.target.checked)
                                                setChanged(changed + 1)
                                            }}
                                            inputProps={{
                                                'aria-label': 'pit boss double confirmation required for dealer',
                                            }}
                                        />
                                    }
                                />
                            </Box>
                        </Box>

                        <Box>
                            <Box className={styles.tooltipWrapper} sx={{ mb: '0.5rem' }}>
                                <Typography>No More Bets Timer Type</Typography>
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '15px' }}>
                                            Chipped: auto countdown after end game, can stop and restart.
                                            <br />
                                            Chipless: manual trigger countdown, cannot stop.
                                            <br />
                                            Fixed: auto countdown after end game, cannot stop
                                        </span>
                                    }
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>

                            <Box display='flex' alignItems='center' mb='0.5rem'>
                                <Radio
                                    checked={timerModeChipped}
                                    onChange={() => {
                                        setTimerModeChipped(true)
                                        setTimerModeChipless(false)
                                        setTimerModeFixed(false)
                                        setTimerModeDisabled(false)
                                        setChanged(changed + 1)
                                    }}
                                    disabled={loading}
                                />
                                Chipped Timer Mode
                            </Box>

                            <Box display='flex' alignItems='center' mb='0.5rem'>
                                <Radio
                                    checked={timerModeChipless}
                                    onChange={() => {
                                        setTimerModeChipped(false)
                                        setTimerModeChipless(true)
                                        setTimerModeFixed(false)
                                        setTimerModeDisabled(false)
                                        setChanged(changed + 1)
                                    }}
                                    disabled={loading}
                                />
                                Chipless Timer Mode
                            </Box>

                            <Box display='flex' alignItems='center' mb='0.5rem'>
                                <Radio
                                    checked={timerModeFixed}
                                    onChange={() => {
                                        setTimerModeChipped(false)
                                        setTimerModeChipless(false)
                                        setTimerModeFixed(true)
                                        setTimerModeDisabled(false)
                                        setChanged(changed + 1)
                                    }}
                                    disabled={loading}
                                />
                                Fixed Timer Mode
                            </Box>

                            <Box display='flex' alignItems='center' mb='0.5rem'>
                                <Radio
                                    checked={timerModeDisabled}
                                    onChange={() => {
                                        setTimerModeChipped(false)
                                        setTimerModeChipless(false)
                                        setTimerModeFixed(false)
                                        setTimerModeDisabled(true)
                                        setChanged(changed + 1)
                                    }}
                                    disabled={loading}
                                />
                                Disable Timer
                            </Box>
                        </Box>

                        <Box>
                            <Typography>Bet Timer Amount (seconds)</Typography>
                            <TextField
                                id='betTimer'
                                name='betTimer'
                                type='text'
                                size='small'
                                fullWidth
                                variant='outlined'
                                onFocus={() => onFocus('betTimer')}
                                onBlur={(e) => onBlur(e, 'betTimer')}
                                onChange={(e) => {
                                    setBetTimer(e.target.value.replace(/\D/g, ''))
                                    setChanged(changed + 1)
                                }}
                                value={betTimer}
                                disabled={loading || timerModeDisabled}
                                error={submitted && fields.betTimer.error()}
                            />
                        </Box>
                    </Box>
                </Box>

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={loading}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                            setDefaults(fields, defaultValues)
                            setChanged(changed + 1)
                        }}
                    >
                        Default
                    </Button>

                    <Button variant='contained' type='button' color='primary' disabled={loading || changed === 0} onClick={send}>
                        Update
                    </Button>
                </div>}
            </form>
        </>
    )
}

export default DealerUI
