import React, { useState } from 'react'
import { isEmpty, startCase } from 'lodash'
import PasswordChecklist from 'react-password-checklist'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import WarningIcon from '@mui/icons-material/Warning'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import LinearProgress from '@mui/material/LinearProgress'
import SnackbarContent from '@mui/material/SnackbarContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { fetch, useSubscribe, usePublish, unpublish, republish, authApi } from '../../api'
import { authIP } from '../../config'
import { validate, clear, update } from '../../forms'
import useAsyncState from '../../useAsyncState'
import { allRoles, allRolesRoot, posuiUser, nonPosuiUser, getLevelTranslation, getRoleTranslation } from '../../util/Roles'
import View from '../../View'

const rootStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        marginTop: '4em',
        marginLeft: '3em',
        background: (props) => (props.lights ? 'rgb(249 249 249)' : '#1f1f1fd6'),
    },
    formContainer: {
        margin: '0 auto',
        maxWidth: 728,
        display: 'flex',
        flexDirection: 'column',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        padding: '4em',
        maxWidth: 700,
        margin: '0 auto',
    },
    selectControl: {
        marginBottom: 15,
    },
    selectInput: {
        height: 7,
        padding: 14,
        paddingBottom: 22,
    },
    formSubmit: {
        marginTop: 20,
        marginLeft: 10,
    },
    formDelete: {
        marginTop: 20,
        marginLeft: 10,
        background: theme.palette.error.main,
        '&:hover': {
            background: '#bf3328',
        },
    },
    formCancel: {
        marginTop: 20,
    },
    formGenerate: {
        marginTop: 20,
        marginLeft: 10,
        background: '#b9b9b9',
    },
    formChange: {
        marginTop: 20,
        marginLeft: 10,
        background: '#b9b9b9',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formProgress: {
        position: 'absolute',
        top: '67%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    text: {
        overflowWrap: 'break-word',
        padding: '2em',
    },
    newUserTitle: {
        marginTop: '1em',
        marginLeft: '1em',
    },
}))

const userAccountRegex = /^[a-z0-9]{1,10}$/

// isCreate can be determined by checking the afterCreate callback
// afterCreate is the same reset tab to 0 baby function used in Tables
const User = ({ afterCreate, boxID, goBack, menuOpen }) => {
    const loggedInAccount = window.localStorage.getItem('account')
    const loggedInRole = window.localStorage.getItem('role')
    const isAdmin = loggedInRole === 'root' || loggedInRole === 'itSupport' || loggedInRole === 'masterAdmin'
    const params = useParams()
    const navigate = useNavigate()
    const theme = useTheme()
    const [fetched, setFetched] = useState(null)
    const ID = !afterCreate ? (boxID ? boxID : params.id ? params.id : '') : ''
    const [settings, settingsSocket] = useSubscribe('settings')
    const active = settingsSocket && settingsSocket.readyState === WebSocket.OPEN
    const publish = usePublish(authIP + '/user/' + ID)
    const publishCreate = usePublish(authIP + '/create')
    const showAllRoles = isAdmin && (loggedInRole === 'masterAdmin' || loggedInRole === 'root')
    // policies
    const [policies, setPolicies] = useAsyncState(null)
    const [passwordChecklist, setPasswordChecklist] = useAsyncState([])
    const [fetchedPolicies, setFetchedPolicies] = useAsyncState(false)
    const [passwordMeetsPolicies, setPasswordMeetsPolicies] = useState(false)

    // user data
    const [userData, setUserData] = useAsyncState(null)

    // form fields
    const [roles, setRoles] = useState([])
    const [products, setProducts] = useState([])
    const [account, setAccount] = useState('')
    const [password, setPassword] = useState('') // password field for creating new user
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [card, setCard] = useState('')

    // modals
    const [changePassword, setChangePassword] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [confirmGenerate, setConfirmGenerate] = useState(false)
    const [handleWrite, setHandleWrite] = useState(false)

    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(true)
    const [initialized, setInitialzed] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const lights = window.localStorage.getItem('lights') === 'on'
    const styles = rootStyles({ lights })

    const fetchPolicies = async () => {
        if (fetchedPolicies) {
            return
        }

        setFetchedPolicies(true)
        try {
            const token = window.localStorage.getItem('token')
            const response = await authApi
                .get('policies', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                .json()
            let newPasswordChecklist = ['match', 'minLength']
            if (response.password.upperAndLowerCase) {
                newPasswordChecklist.push('capital')
                newPasswordChecklist.push('lowercase')
            }
            if (response.password.numericCharacters) {
                newPasswordChecklist.push('number')
            }
            if (response.password.nonAlphanumericCharacters) {
                newPasswordChecklist.push('specialChar')
            }
            await setPasswordChecklist(newPasswordChecklist)
            await setPolicies(response)
        } catch (e) {
            console.warn(e)
        }
    }

    fetchPolicies()

    const selectedRoles = roles.map((el) => el.role)

    const isPosuiUserChosen = posuiUser.some((el) => selectedRoles.includes(el))

    const isNonPosuiUserChosen = nonPosuiUser.some((el) => selectedRoles.includes(el))

    const isPosuiUserChosenOnly = isPosuiUserChosen && !isNonPosuiUserChosen

    const fields = {
        roles: {
            error: () => isEmpty(roles),
            message: 'please select at lease one role',
            value: roles,
            set: () => {},
        },
        products: {
            error: () => isNonPosuiUserChosen && isEmpty(products),
            message: 'please select at lease one product',
            value: products,
            set: () => {},
        },
        account: {
            error: () => account.trim().length === 0,
            message: 'username cannot be empty',
            value: account,
            set: setAccount,
        },
        firstName: {
            error: () => firstName.trim().length === 0,
            message: 'first name cannot be empty',
            value: firstName,
            set: setFirstName,
        },
        lastName: {
            error: () => lastName.trim().length === 0,
            message: 'last name cannot be empty',
            value: lastName,
            set: setLastName,
        },
        password: {
            error: () => (password.trim().length === 0 || !passwordMeetsPolicies) && !!afterCreate,
            message: 'password must meet valid policies',
            value: password,
            set: setPassword,
        },
        card: {
            error: () => false,
            message: '',
            value: card,
            set: setCard,
        },
    }

    const validation = validate(fields)

    const getUser = async () => {
        if (!fetched) {
            setFetched(true)
            try {
                const data = await fetch(authIP + '/user/' + ID)
                setTimeout(async () => {
                    update(fields, [], {
                        data: {
                            ...data,
                            password: '',
                        },
                    })
                    if (data.roles) {
                        const matchedRoles = getMatched()

                       function getMatched() {
                           if (loggedInRole === 'masterAdmin' || loggedInRole === 'root') {
                               var tempmatchedRoles = allRolesRoot.filter((el) => data.roles.includes(el.role))
                               return tempmatchedRoles
                           } else {
                               tempmatchedRoles = allRoles.filter((el) => data.roles.includes(el.role))
                               return tempmatchedRoles
                           }
                        }
                        setRoles(matchedRoles)
                    }
                    if (data.products) {
                        setProducts(data.products)
                    }
                    await setUserData(data)
                    setLoading(false)
                }, 400)
            } catch (e) {
                console.error(e)
                goBack(true)
            }
        }
    }

    const send = async () => {
        if (!validation.error && userData) {
            try {
                setLoading(true)
                console.log({
                    ...userData,
                    roles: roles.map((el) => el.role),
                    products: products,
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                })
                await publish({
                    ...userData,
                    roles: roles.map((el) => el.role),
                    products: products,
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                })
                clear(fields)
                setFetched(false)
                // go back to prev page after successful update
                goBack()
            } catch (e) {
                setFail(
                    `Something went wrong (${
                        e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setLoading(false)
            }
        } else {
            console.log(validation.errors)
        }
    }

    const remove = async () => {
        if (account === 'root') {
            return
        }
        try {
            setLoading(true)
            await unpublish(authIP + '/user/' + ID)
            goBack()
        } catch (e) {
            setFail(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            setConfirmDelete(false)
            setLoading(false)
        }
    }

    const generate = async () => {
        try {
            setLoading(true)
            await republish(authIP + '/card/' + account, null)
            setConfirmGenerate(false)
            setLoading(false)
            setFetched(false)
        } catch (e) {
            setFail(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            setConfirmGenerate(false)
            setLoading(false)
        }
    }

    const change = async () => {
        try {
            setLoading(true)
            await republish(authIP + '/password/' + account, { password: newPassword })
            setConfirmPassword('')
            setNewPassword('')
            goBack()
        } catch (e) {
            setFail(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            setConfirmGenerate(false)
            setLoading(false)
            setConfirmPassword('')
            setNewPassword('')
            setChangePassword(false)
        }
    }

    if (!goBack) {
        goBack = () => {
            navigate('/xuser/users')
        }
    }

    const create = async () => {
        setSubmitted(true)
        if (!validation.error && confirmPassword === password) {
            setLoading(true)
            try {
                await publishCreate({
                    roles: roles.map((el) => el.role),
                    products: products,
                    account: account.trim(),
                    password: password.trim(),
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                })
                setLoading(false)
                afterCreate()
                setTimeout(() => {
                    setSubmitted(false)
                    clear(fields)
                }, 200)
            } catch (e) {
                console.warn('error creating new user', e)
                setFail(
                    `Something went wrong (${
                        e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setLoading(false)
            }
        }
    }

    const write = async () => {
        setHandleWrite(true)

        let data = { cardid: card }
        try {
            const response = await window.fetch('http://' + window.location.hostname + ':8989/writecardid', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })

            await response.json()
        } catch (e) {
            console.warn(e)
        }
    }

    const clearFields = () => {
        setRoles([])
        setProducts([])
        setAccount('')
        setPassword('')
        setFirstName('')
        setLastName('')
        setCard('')
    }

    if (!afterCreate && policies) {
        getUser()
    }

    if (afterCreate && !initialized && policies) {
        setInitialzed(true)
        clearFields()
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }

    if (!settings || !active) {
        return <LinearProgress />
    }

    return [
        <Dialog
            key='dialogDelete'
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                Do you want to delete the user <b>{firstName}</b>?
            </DialogTitle>
            <DialogActions>
                <Button onClick={() => setConfirmDelete(false)} color='primary'>
                    No
                </Button>
                <Button onClick={remove}>Yes</Button>
            </DialogActions>
        </Dialog>,
        <Dialog
            key='dialogGenerate'
            open={confirmGenerate}
            onClose={() => setConfirmGenerate(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                Do you want to generate a new card ID for <b>{firstName}</b>?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This action will not invalidate {firstName} password access and can't be undone
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmGenerate(false)}>Cancel</Button>
                <Button onClick={generate} color='primary'>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>,
        <Dialog
            key='dialogChange'
            open={changePassword}
            onClose={() => setChangePassword(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>Change Password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This action will invalidate card access for: {firstName}
                    <br />
                    Please type and confirm your new password in order to proceed.
                    <br />
                    This action cannot be undone.
                </DialogContentText>
                <TextField
                    required
                    margin='dense'
                    id='newPassword'
                    label='New Password'
                    type='password'
                    fullWidth
                    variant='outlined'
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    disabled={loading}
                    error={newPassword === ''}
                />
                <TextField
                    required
                    margin='dense'
                    id='confirmPassword'
                    label='Confirm New Password'
                    type='password'
                    fullWidth
                    variant='outlined'
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    disabled={loading}
                    error={newPassword !== confirmPassword || confirmPassword === ''}
                />
                {policies && (
                    <PasswordChecklist
                        style={{ marginTop: 30 }}
                        rules={passwordChecklist}
                        minLength={policies.password.minimumLength}
                        value={newPassword}
                        valueAgain={confirmPassword}
                        onChange={(isValid) => {}}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setConfirmPassword('')
                        setNewPassword('')
                        setChangePassword(false)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={newPassword === '' || newPassword !== confirmPassword}
                    onClick={change}
                    color='primary'
                >
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>,
        <Dialog
            key='dialogWrite'
            open={handleWrite}
            onClose={() => setHandleWrite(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>Write Card ID</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Card ID has been sent to card writer, please swipe the card you wish to write to.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setHandleWrite(false)}>OK</Button>
            </DialogActions>
        </Dialog>,
        <Box key='main' component='main' sx={View(menuOpen, theme)}>
            {!account && !afterCreate && <LinearProgress />}
            <div className={styles.formContainer}>
                {afterCreate && (
                    <Typography variant='h4' className={styles.newUserTitle}>
                        New user
                    </Typography>
                )}
                {fail && !loading && (
                    <SnackbarContent
                        className={styles.fail}
                        message={
                            <Typography component='p' className={styles.warningText}>
                                <WarningIcon className={styles.warningIcon} /> {fail}
                            </Typography>
                        }
                    />
                )}
                {validation.error && submitted && (
                    <SnackbarContent
                        className={styles.warning}
                        message={Object.keys(validation.errors).map((error, key) =>
                            validation.errors[error] !== '' ? (
                                <Typography key={key} component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                </Typography>
                            ) : null
                        )}
                    />
                )}
                <form
                    key='core-user-form'
                    className={styles.form}
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault()
                    }}
                    autoComplete='off'
                >
                    {(afterCreate || account !== 'root') && (
                        <FormControl className={styles.selectControl} fullWidth>
                            <Autocomplete
                                fullWidth
                                multiple
                                id='levels-roles-filter'
                                key='levels-roles-filter'
                                value={roles}
                                onChange={(e, value) => setRoles(value)}
                                disableCloseOnSelect={false}
                                disabled={loading || !isAdmin}
                                options={showAllRoles ? allRolesRoot : allRoles}
                                getOptionLabel={(option) => getRoleTranslation(option.role)}
                                isOptionEqualToValue={(option, value) => option.role === value.role}
                                groupBy={(option) => getLevelTranslation(option.level)}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label={'Roles'}
                                        placeholder='Roles'
                                        error={submitted && !loading && fields.roles.error()}
                                    />
                                )}
                            />
                        </FormControl>
                    )}
                    {(afterCreate || account !== 'root') && !isEmpty(roles) && (
                        <FormControl className={styles.selectControl} fullWidth>
                            <Autocomplete
                                fullWidth
                                multiple
                                id='products-filter'
                                key='products-filter'
                                value={products}
                                onChange={(e, value) => setProducts(value)}
                                disableCloseOnSelect={false}
                                disabled={loading || !isAdmin || isPosuiUserChosenOnly}
                                options={settings?.data?.products || []}
                                getOptionLabel={(option) => startCase(option)}
                                isOptionEqualToValue={(option, value) => option === value}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label={'Products'}
                                        placeholder='Products'
                                        error={submitted && !loading && fields.products.error()}
                                    />
                                )}
                            />
                        </FormControl>
                    )}
                    {afterCreate &&
                        policies && [
                            <TextField
                                key='account'
                                required
                                margin='dense'
                                id='account'
                                label='Username'
                                type='text'
                                fullWidth
                                variant='outlined'
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                onChange={(e) => {
                                    if (userAccountRegex.test(e.target.value)) {
                                        setAccount(e.target.value)
                                    }
                                    if (e.target.value === '') {
                                        setAccount('')
                                    }
                                }}
                                value={account}
                                disabled={loading}
                                error={(!afterCreate || submitted) && !loading && fields.account.error()}
                            />,
                            <TextField
                                key='password'
                                required
                                margin='dense'
                                id='passwordX'
                                name='passwordX'
                                label='Password'
                                type='password'
                                fullWidth
                                variant='outlined'
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                disabled={loading}
                                error={submitted && !loading && fields.password.error()}
                            />,
                            <TextField
                                key='confirmPassword'
                                required
                                margin='dense'
                                id='confirmPassword'
                                label='Confirm New Password'
                                type='password'
                                fullWidth
                                variant='outlined'
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                                disabled={loading}
                                error={(password !== confirmPassword || confirmPassword === '') && submitted}
                            />,
                            <PasswordChecklist
                                key='passwordChecklist'
                                style={{ marginTop: 10, marginBottom: 10 }}
                                rules={passwordChecklist}
                                minLength={policies.password.minimumLength}
                                value={password}
                                valueAgain={confirmPassword}
                                onChange={(isValid) => {
                                    setPasswordMeetsPolicies(isValid)
                                }}
                            />,
                        ]}
                    {(afterCreate || account !== 'root') && (
                        <TextField
                            required
                            margin='dense'
                            id='firstname'
                            label='First name'
                            type='text'
                            fullWidth
                            variant='outlined'
                            inputProps={{
                                autoComplete: 'off',
                            }}
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            disabled={loading || !isAdmin || account === 'root'}
                            error={(!afterCreate || submitted) && !loading && fields.firstName.error()}
                        />
                    )}
                    {(afterCreate || account !== 'root') && (
                        <TextField
                            required
                            margin='dense'
                            id='lastname'
                            label='Last name'
                            type='text'
                            fullWidth
                            variant='outlined'
                            inputProps={{
                                autoComplete: 'off',
                            }}
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            disabled={loading || !isAdmin || account === 'root'}
                            error={(!afterCreate || submitted) && !loading && fields.lastName.error()}
                        />
                    )}
                    {!afterCreate && (
                        <TextField
                            required
                            margin='dense'
                            id='card'
                            label='Card'
                            type='text'
                            fullWidth
                            variant='outlined'
                            onChange={(e) => setCard(e.target.value)}
                            value={card}
                            disabled={true}
                        />
                    )}
                    <div key='buttonwrapper' className={styles.formButtonWrapper}>
                        {!afterCreate && (
                            <>
                                <Button
                                    className={styles.formCancel}
                                    key='cancel'
                                    variant='contained'
                                    type='submit'
                                    disabled={loading}
                                    onClick={() => {
                                        setConfirmPassword('')
                                        setPassword('')
                                        goBack()
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={styles.formChange}
                                    key='change'
                                    variant='contained'
                                    type='submit'
                                    disabled={loading}
                                    onClick={() => setChangePassword(true)}
                                >
                                    Change password
                                </Button>
                                {isAdmin && (
                                    <>
                                        <Button
                                            className={styles.formGenerate}
                                            key='generate'
                                            variant='contained'
                                            type='submit'
                                            disabled={loading}
                                            onClick={() => setConfirmGenerate(true)}
                                        >
                                            Generate card ID
                                        </Button>
                                        <Button
                                            className={styles.formGenerate}
                                            key='write'
                                            variant='contained'
                                            type='submit'
                                            disabled={loading}
                                            onClick={() => write()}
                                        >
                                            Write card ID
                                        </Button>
                                    </>
                                )}
                                {isAdmin && (
                                    <Button
                                        className={styles.formDelete}
                                        key='delete'
                                        variant='contained'
                                        type='submit'
                                        disabled={loading || account === 'root' || account === loggedInAccount}
                                        onClick={() => setConfirmDelete(true)}
                                    >
                                        Delete
                                    </Button>
                                )}
                                {isAdmin && (
                                    <Button
                                        className={styles.formSubmit}
                                        key='update'
                                        variant='contained'
                                        type='submit'
                                        color='primary'
                                        disabled={loading || account === 'root'}
                                        onClick={send}
                                    >
                                        Update
                                    </Button>
                                )}
                            </>
                        )}
                        {afterCreate && (
                            <Button
                                className={styles.formCancel}
                                variant='contained'
                                disabled={loading}
                                onClick={() => afterCreate()}
                            >
                                Cancel
                            </Button>
                        )}
                        {afterCreate && (
                            <Button
                                className={styles.formSubmit}
                                variant='contained'
                                type='submit'
                                color='primary'
                                disabled={loading}
                                onClick={create}
                            >
                                Create New User
                            </Button>
                        )}
                        {loading && <CircularProgress size={24} className={styles.formProgress} />}
                    </div>
                </form>
            </div>
        </Box>,
    ]
}

export default User
