import React, { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { usePublish, publish } from '../../api'
import PlaylistVideos from './PlaylistVideos'
import VideoList from './VideoList'

const madeStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '45vw',
        maxWidth: '45vw',
    },
}))

function getSteps() {
    return ['Playlist information', 'Select videos', 'Sort timeline']
}

const Playlist = ({ authorize, open, handleClose, videos, editing, selectedVideo, setSelectedVideo }) => {
    const styles = madeStyles()

    const create = usePublish('playlists/*', authorize)

    const [selected, setSelected] = useState(null)
    const [activeStep, setActiveStep] = useState(0)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [sortedVideos, setSortedVideos] = useState([])

    const [loading, setLoading] = useState(false)

    const steps = getSteps()

    if (editing && selected !== editing.index) {
        setActiveStep(0)
        setName(editing.data.name)
        setDescription(editing.data.description)
        setSelected(editing.index)
        const selectedVideosIDs = editing.data.videos.map((v) => v.id)
        const selectedVideoObjs = selectedVideosIDs.map((v) => videos.filter((video) => video.data.id === v)).flat(1)
        setSelectedVideo(selectedVideoObjs)
    }

    if (!editing && selected) {
        setActiveStep(0)
        setName('')
        setDescription('')
        setSelected(null)
        setSelectedVideo([])
    }

    const handleNext = async () => {
        if (activeStep === 2) {
            setLoading(true)
            try {
                if (!selected) {
                    await create({
                        name,
                        description,
                        videos: sortedVideos.map((v) => ({
                            id: v.data.id,
                            index: v.index,
                        })),
                        updatedBy: window.localStorage.getItem('account') ?? '-',
                    })
                } else {
                    await publish(
                        'playlists/' + selected,
                        {
                            name,
                            description,
                            videos: sortedVideos.map((v) => ({
                                id: v.data.id,
                                index: v.index,
                            })),
                            updatedBy: window.localStorage.getItem('account') ?? '-',
                        },
                        authorize
                    )
                }
            } catch (e) {
                console.warn('failed to store playlist', e)
            } finally {
                setLoading(false)
            }

            closeDialog()
            return
        }
        setSortedVideos(makeEditingPlaylistVideos(videos, selectedVideo))
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const closeDialog = () => {
        setActiveStep(0)
        setName('')
        setDescription('')
        setSelected(null)
        setSelectedVideo([])
        handleClose()
    }

    const makeEditingPlaylistVideos = (allVideos, currentVideos) => {
        const result = []
        for (const vc of currentVideos) {
            for (const va of allVideos) {
                if (vc.index === va.index) {
                    result.push(va)
                }
            }
        }

        return result
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <TextField
                            id='name'
                            label='Name'
                            type='text'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            autoFocus
                            margin='dense'
                            fullWidth
                        />
                        <TextField
                            id='description'
                            label='Description'
                            type='text'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            margin='dense'
                            fullWidth
                        />
                    </Box>
                )
            case 1:
                return (
                    <VideoList
                        videos={videos}
                        searchTerm={searchTerm}
                        selectedVideo={selectedVideo}
                        setSelectedVideo={setSelectedVideo}
                    />
                )
            case 2:
                return (
                    <DndProvider backend={HTML5Backend}>
                        <PlaylistVideos videos={sortedVideos} setVideos={setSortedVideos} />
                    </DndProvider>
                )
            default:
                return 'Unknown stepIndex'
        }
    }

    return (
        <Paper>
            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby='form-dialog-title'
                classes={{ paper: styles.dialogPaper }}
            >
                <DialogTitle
                    id='form-dialog-title'
                    sx={{ pb: 0, display: 'flex', flexDirection: 'column', gap: '2rem' }}
                >
                    New playlist
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Typography variant='body2'>
                        Create a playlist of videos following these steps, please notice that by canceling the proccess
                        your changes will be lost.
                    </Typography>
                    {activeStep === 1 && (
                        <Box
                            sx={{
                                p: '2px 4px',
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                border: '2px solid #616161',
                                borderRadius: '10px',
                            }}
                        >
                            <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder='Search'
                                inputProps={{ 'aria-label': 'search google maps' }}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Box>
                    )}
                </DialogTitle>

                <IconButton
                    aria-label='close'
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                    <PerfectScrollbar>
                        <Box>
                            {!loading && getStepContent(activeStep)}
                            {loading && <LinearProgress />}
                        </Box>
                    </PerfectScrollbar>
                </DialogContent>

                <DialogActions>
                    <Button color='inherit' disabled={activeStep === 0} onClick={handleBack}>
                        Back
                    </Button>

                    <Button
                        color='primary'
                        disabled={
                            (activeStep === 0 && (name === '' || description === '')) ||
                            (activeStep === 1 && selectedVideo.length === 0)
                        }
                        onClick={handleNext}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default Playlist
