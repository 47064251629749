import React from 'react'
import { useNavigate } from 'react-router-dom'
import DevicesIcon from '@mui/icons-material/Devices'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const DeviceNotFound = ({ xstadium }) => {
    const navigate = useNavigate()

    return (
        <Box height='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center' gap='2rem'>
            <DevicesIcon sx={{ fontSize: '5rem' }} />

            <Box>
                <Typography variant='h4' gutterBottom textAlign='center'>
                    Device Not Found
                </Typography>
                <Typography textAlign='center'>The device you are looking for might have been removed.</Typography>
            </Box>

            <Box>
                <Button variant='contained' onClick={() => navigate(xstadium ? '/xstadium/tables' : '/xtrend/home')}>
                    Go back to {xstadium ? 'XStadium' : 'XTrend'}
                </Button>
            </Box>
        </Box>
    )
}

export default DeviceNotFound
