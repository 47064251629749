import { useState, useMemo, useCallback } from 'react'
import moment from 'moment'
import prettyBytes from 'pretty-bytes'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SearchIcon from '@mui/icons-material/Search'
import UploadIcon from '@mui/icons-material/Upload'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import { styled, alpha } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'

import { useSubscribe, usePublish, unpublish, upload } from '../../api'
import AlertDialog, { handleClose } from '../../xtrend/components/AlertDialog'

const Row = (props) => {
    const { row, setSelected, setAnchorEl, setFileName } = props
    const [open, setOpen] = useState(false)

    const dateDisplay = (time) => moment.unix(time / 1000000000).format('YYYY-MM-DD')

    return (
        <>
            <TableRow
                sx={{ '& > *': { borderBottom: '0 !important', cursor: 'pointer' } }}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                        {open ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.fileName}</TableCell>
                <TableCell>{row.fileSize}</TableCell>
                <TableCell>{dateDisplay(row.uploadedOn)}</TableCell>
                <TableCell>{row.uploadedBy}</TableCell>
                <TableCell>
                    <IconButton
                        size='small'
                        onClick={(e) => {
                            e.stopPropagation()
                            setAnchorEl(e.currentTarget)
                            setSelected(row.id)
                            setFileName(row.fileName)
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box sx={{ margin: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <video
                                type='video/webm'
                                src={row.fileUrl}
                                style={{ padding: 10, maxWidth: '50vh' }}
                                controls
                                preload='auto'
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}))

const Videos = ({ authorize }) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [videos, videosSocket] = useSubscribe('content/files/videos/*', authorize)
    const [playlists, playlistsSocket] = useSubscribe('playlists/*', authorize)

    const active =
        videosSocket &&
        videosSocket.readyState === WebSocket.OPEN &&
        playlistsSocket &&
        playlistsSocket.readyState === WebSocket.OPEN

    // add video dialog
    const [addDialogOpen, setAddDialogOpen] = useState(false)
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleAddDialogClickOpen = () => {
        setAddDialogOpen(true)
    }

    const handleAddDialogClose = () => {
        setAddDialogOpen(false)
        setFile(null)
        setLoading(false)
    }

    // search bar
    const [searchTerm, setSearchTerm] = useState('')

    // row
    const [selected, setSelected] = useState('')

    // menu
    const [anchorEl, setAnchorEl] = useState(null)
    const menuOpen = Boolean(anchorEl)

    // edit video dialog
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [fileName, setFileName] = useState('')

    const handleEditDialogClickOpen = () => {
        setEditDialogOpen(true)
    }

    const handleEditDialogClose = () => {
        setEditDialogOpen(false)
        setFile(null)
        setLoading(false)
    }

    const uploadPublish = usePublish('content/files/videos/*', authorize)
    const editPublish = usePublish('content/files/videos/' + selected)

    const [order, setOrder] = useState('')
    const [orderBy, setOrderBy] = useState('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        loading: false,
        error: false,
        confirm: () => {},
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }

    const stableSort = useCallback((array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) {
                return order
            }
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }, [])

    const matchedVideos = useMemo(
        () =>
            videos
                ? videos
                      .filter((video) => JSON.stringify(video).indexOf(searchTerm) >= 0)
                      .map((video) => {
                          return {
                              id: video.index ? video.index : null,
                              fileUrl: video.data.url ? video.data.url : null,
                              fileName: video.data.name ? video.data.name : '-',
                              fileSize: video.data.size ? video.data.size : '-',
                              uploadedOn: video.updated ? video.updated : video.created,
                              uploadedBy: video.data.uploadedBy ? video.data.uploadedBy : '-',
                          }
                      })
                : [],
        [videos, searchTerm]
    )

    const filteredVideos = useMemo(() => {
        function getComparator(order, orderBy) {
            return order === 'desc'
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy)
        }

        return stableSort(matchedVideos, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )
    }, [order, orderBy, page, rowsPerPage, stableSort, matchedVideos])

    if (!videos || !playlists || !active) {
        return <LinearProgress />
    }

    const onFileChange = async (e) => {
        console.log('file changed', e.target.files[0])

        const newFile = e.target.files[0]
        const isFileAlreadyExist = videos.find((video) => video.data.name === newFile.name)

        if (!e.target || e.target.files.length < 1) {
            setAlertDialogState({
                show: true,
                type: 'error',
                message: 'There was an error processing your video, please try again.',
                loading: false,
                error: true,
                confirm: () => {},
            })
            return
        }

        if (newFile.type !== 'video/webm') {
            setAlertDialogState({
                show: true,
                type: 'reminder',
                message: 'Only .webm format video file is allowed.',
                loading: false,
                error: false,
                confirm: () => {},
            })
            return
        }

        if (isFileAlreadyExist) {
            setAlertDialogState({
                show: true,
                type: 'reminder',
                message: 'Video file already exists.',
                loading: false,
                error: false,
                confirm: () => {},
            })
            return
        }

        setFile(newFile)
    }

    const addVideo = async () => {
        setLoading(true)
        try {
            const video = await upload(file, file.name, file.type)
            await uploadPublish({
                name: file.name,
                type: file.type,
                size: prettyBytes(file.size),
                url: video,
                id: video.split('files/')[1],
                uploadedBy: window.localStorage.getItem('account') ?? '-',
            })
            setAlertDialogState({
                show: true,
                type: 'success',
                message: 'Video uploaded successfully.',
                loading: false,
                error: false,
                confirm: () => {},
            })
        } catch (e) {
            console.warn('we had an issue uploading the file', e)
            setAlertDialogState({
                show: true,
                type: 'error',
                message: 'Failed to upload video, please try again.',
                loading: false,
                error: true,
                confirm: () => {},
            })
        } finally {
            handleAddDialogClose()
        }
    }

    const editVideo = async (fileName) => {
        const fileNameInUse = videos
            .map((video) => {
                return video.data.name
            })
            .flat(1)

        const isFileNameInUse = fileNameInUse.find((video) => video === fileName)

        if (isFileNameInUse) {
            setAlertDialogState({
                show: true,
                type: 'reminder',
                message: 'File name is being used with another video. Please use a different file name.',
                loading: false,
                error: false,
                confirm: () => {},
            })

            return
        }

        if (!isFileNameInUse) {
            setLoading(true)
            try {
                const video = videos.find((video) => (video.index = selected))
                await editPublish({
                    ...video.data,
                    name: fileName,
                    uploadedBy: window.localStorage.getItem('account') ?? '-',
                })
            } catch (e) {
                console.warn('we had an issue editing the file', e)
                setAlertDialogState({
                    show: true,
                    type: 'error',
                    message: 'Failed to edit video, please try again.',
                    loading: false,
                    error: true,
                    confirm: () => {},
                })
            } finally {
                handleEditDialogClose()
            }
        }
    }

    const handleRemove = async (index) => {
        const videosInUse = playlists
            .map((playlist) => {
                return playlist.data.videos
            })
            .flat(1)

        const isVideoInUse = videosInUse.find((video) => video.index === index)

        if (isVideoInUse) {
            setAlertDialogState({
                show: true,
                type: 'reminder',
                message: 'Video is being used with a playlist. Cannot be removed.',
                loading: false,
                error: false,
                confirm: () => {},
            })

            return
        }

        if (!isVideoInUse) {
            setAlertDialogState((prev) => ({
                ...prev,
                loading: true,
            }))
            try {
                await unpublish('content/files/videos/' + index)
                handleClose(alertDialogState, setAlertDialogState)
            } catch (e) {
                console.warn('error', e)
                setAlertDialogState({
                    show: true,
                    type: 'error',
                    message: 'Unable to remove video. Please try again.',
                    loading: false,
                    error: true,
                    confirm: () => {},
                })
            }
        }
    }

    const columns = [
        { id: 'preview', label: '', width: '2.5%' },
        { id: 'fileName', label: 'File Name', width: 200 },
        { id: 'fileSize', label: 'File Size', width: 25 },
        {
            id: 'uploadedOn',
            label: 'Uploaded On',
            width: 25,
        },
        {
            id: 'uploadedBy',
            label: 'Uploaded By',
            width: 25,
        },
        { id: 'actions', label: 'Actions', width: '5%' },
    ]

    const isTableEmpty = videos.length === 0 || (videos.length > 0 && filteredVideos.length === 0)

    return (
        <>
            <Dialog open={addDialogOpen} onClose={handleAddDialogClose} fullWidth>
                {loading ? <LinearProgress /> : null}
                <DialogTitle>Add Video</DialogTitle>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='1.25rem'>
                        <Button sx={{ width: mobile ? '100%' : 'auto' }} variant='contained' component='label'>
                            <UploadIcon />
                            &nbsp; Choose file
                            <input onChange={onFileChange} type='file' hidden />
                        </Button>

                        {file && (
                            <Box display='flex' flexDirection='column' gap='.5rem'>
                                <Typography>File</Typography>

                                <Box display='flex' alignItems='center' gap='1rem'>
                                    <UploadFileIcon color='primary' fontSize='large' />
                                    <Box>
                                        <Typography>{file.name}</Typography>
                                        <Typography>{prettyBytes(file.size)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddDialogClose}>Cancel</Button>
                    <Button onClick={() => addVideo()} disabled={!file}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} fullWidth>
                {loading ? <LinearProgress /> : null}
                <DialogTitle>Edit Video</DialogTitle>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='.5rem'>
                        <Typography>File Name</Typography>

                        <TextField value={fileName} onChange={(e) => setFileName(e.target.value)} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>Cancel</Button>
                    <Button onClick={() => editVideo(fileName)} disabled={fileName === ''}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <StyledMenu
                id='actions-menu'
                MenuListProps={{
                    'aria-labelledby': 'actions-menu-button',
                }}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => {
                    setAnchorEl(null)
                    setSelected('')
                    setFileName('')
                }}
            >
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null)
                        handleEditDialogClickOpen()
                    }}
                    disableRipple
                >
                    <EditIcon />
                    Edit
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null)
                        setAlertDialogState({
                            show: true,
                            type: 'confirmation',
                            message: 'Are you sure you want to delete this video?',
                            loading: false,
                            error: false,
                            confirm: () => {
                                handleRemove(selected)
                                setSelected('')
                                setFileName('')
                            },
                        })
                    }}
                    disableRipple
                >
                    <DeleteIcon />
                    Remove
                </MenuItem>
            </StyledMenu>

            <Box padding='2rem' display='flex' flexDirection='column' gap='1rem'>
                <Box
                    display='flex'
                    flexDirection={mobile ? 'column' : 'row'}
                    alignItems='center'
                    justifyContent='space-between'
                    gap='1rem'
                >
                    <Tabs
                        sx={{ width: mobile ? '100%' : 'auto' }}
                        value={0}
                        indicatorColor='primary'
                        textColor='primary'
                        variant={mobile ? 'fullWidth' : 'standard'}
                    >
                        <Tab disableTouchRipple label='Videos' component={Link} to='/xsignage/videos' />
                        <Tab disableTouchRipple label='Playlists' component={Link} to='/xsignage/playlists' />
                    </Tabs>

                    <Box
                        width={mobile ? '100%' : 'auto'}
                        display='flex'
                        flexDirection={mobile ? 'column' : 'row'}
                        alignItems='center'
                        gap='1rem'
                    >
                        <Box width={mobile ? '100%' : 'auto'}>
                            <Button
                                sx={{ width: mobile ? '100%' : 'auto' }}
                                variant='contained'
                                onClick={handleAddDialogClickOpen}
                            >
                                <AddIcon />
                                &nbsp; Add Video
                            </Button>
                        </Box>

                        <TextField
                            sx={{ width: mobile ? '100%' : 'auto' }}
                            variant='outlined'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>

                <Paper>
                    <TableContainer sx={{ height: '72.5vh', overflow: 'auto' }}>
                        <PerfectScrollbar>
                            <Table
                                stickyHeader
                                aria-label='collapsible table'
                                sx={{ height: isTableEmpty ? '100%' : 'auto' }}
                            >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => {
                                            return (
                                                <TableCell key={column.id} width={column.width}>
                                                    {column.label ? (
                                                        <TableSortLabel
                                                            active={orderBy === column.id}
                                                            direction={orderBy === column.id ? order : 'asc'}
                                                            onClick={createSortHandler(column.id)}
                                                        >
                                                            {column.label}
                                                        </TableSortLabel>
                                                    ) : (
                                                        <>{column.label}</>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {isTableEmpty && (
                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Typography>
                                                        {videos.length === 0 && 'No video(s) found.'}
                                                        {videos.length > 0 &&
                                                            filteredVideos.length === 0 &&
                                                            'No match video(s) found.'}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {filteredVideos.map((row) => (
                                        <Row
                                            key={row.id}
                                            row={row}
                                            setSelected={setSelected}
                                            setAnchorEl={setAnchorEl}
                                            setFileName={setFileName}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </PerfectScrollbar>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={matchedVideos.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default Videos
