import React, { useState } from 'react'
import { Button, FormControlLabel, LinearProgress, Switch } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import { authApi } from '../api'
import useAsyncState from '../useAsyncState'
import useWindowSize from '../WindowSize'

const styles = {
    header: (w) => ({
        position: 'fixed',
        top: 60,
        width: w > 1150 ? 1039 : '-webkit-fill-available',
        background: '#2a2a2a',
        zIndex: 999,
        borderBottom: '3px solid'
    }),
    form: {
        maxWidth: 900,
        marginRight: 88,
        marginBottom: 180,
        marginTop: 120,
    },
    switch: {
        marginTop: 15,
        marginLeft: 20,
        display: 'block'
    },
    numberInput: {
        margin: '10px 0',
        marginLeft: 28
    },
    footer: (w) => ({
        paddingBottom: 50,
        marginTop: 10,
        position: 'fixed',
        bottom: 0,
        width: w > 1150 ? 1039 : '-webkit-fill-available',
        background: '#2a2a2a',
        zIndex: 999,
        borderTop: '3px solid'
    })
}

const Policies = () => {
    const [width, ,] = useWindowSize()
    const loggedInRole = window.localStorage.getItem('role')
    const isAdmin = loggedInRole === 'root' || loggedInRole === 'itSupport' || loggedInRole === 'masterAdmin'
    const isReadOnly = !isAdmin
    // const MAX_PASSWORD_LENGTH = 20
    const MAX_LOCKOUT_AFTER_FAILURE_COUNT = 10
    const MIN_LOCKOUT_AFTER_FAILURE_COUNT = 3
    const MAX_PASSWORD_MIN_LENGTH = 20
    const MIN_PASSWORD_MIN_LENGTH = 8
    const MAX_PASSWORD_EXPIRY_AGE_DAYS = 365
    const MIN_PASSWORD_EXPIRY_AGE_DAYS = 1
    const MAX_PASSWORD_REPEATED_HISTORY_REJECT = 20
    const MIN_PASSWORD_REPEATED_HISTORY_REJECT = 10
    const MAX_PASSWORD_WARNING_DAYS_BEFORE_EXPIRY = 10
    const MIN_PASSWORD_WARNING_DAYS_BEFORE_EXPIRY = 5
    const MAX_PASSWORD_MIN_AGE_BEFORE_CHANGE = 14
    const MIN_PASSWORD_MIN_AGE_BEFORE_CHANGE = 1
    const MAX_IDLE_LOGOUT_MINUTES = 180
    const MIN_IDLE_LOGOUT_MINUTES = 15

    // confirm dialog
    const [confirmDelete, setConfirmDelete] = useState(false)

    const [policies, setPolicies] = useAsyncState(null)
    const [fetchedPolicies, setFetchedPolicies] = useAsyncState(false)
    const [loading, setLoading] = useAsyncState(true)
    const [autoLogoutOnNewSession, setAutoLogoutOnNewSession] = useAsyncState(true)
    const [canLockAfterFailures, setCanLockAfterFailures] = useAsyncState(true)
    const [lockAfterFailureCount, setLockAfterFailureCount] = useAsyncState('')
    const [idleLogoutMinutes, setIdleLogoutMinutes] = useAsyncState('')
    const [passwordMinimumLength, setPasswordMinimumLength] = useAsyncState(8)
    const [passwordUpperAndLowerCase, setPasswordUpperAndLowerCase] = useAsyncState(false)
    const [passwordNumericCharacters, setPasswordNumericCharacters] = useAsyncState(false)
    const [passwordNonAlphanumericCharacters, setPasswordNonAlphanumericCharacters] = useAsyncState(false)
    const [passwordCanExpire, setPasswordCanExpire] = useAsyncState(false)
    const [passwordExpiryAgeDays, setPasswordExpiryAgeDays] = useAsyncState('')
    const [passwordWillThrowWarningsBeforeExpiry, setPasswordWillThrowWarningsBeforeExpiry] = useAsyncState(false)
    const [passwordWarningDaysBeforeExpiry, setPasswordWarningDaysBeforeExpiry] = useAsyncState('')
    const [passwordCanRejectChangeByMinimumAge, setPasswordCanRejectChangeByMinimumAge] = useAsyncState(false)
    const [passwordMinimumAgeBeforeChange, setPasswordMinimumAgeBeforeChange] = useAsyncState('')
    const [passwordCanRejectChangeIfRepeated, setPasswordCanRejectChangeIfRepeated] = useAsyncState(false)
    const [passwordRepeatedHistoryRejectLookback, setPasswordRepeatedHistoryRejectLookback] = useAsyncState('')

    const fetchPolicies = async () => {
        if (fetchedPolicies) {
            return
        }

        setFetchedPolicies(true)
        try {
            const token = window.localStorage.getItem('token')
            const response = await authApi.get('policies', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).json()
            await Promise.all([
                setAutoLogoutOnNewSession(response.autoLogoutOnNewSession),
                setCanLockAfterFailures(response.canLockAfterFailures),
                setLockAfterFailureCount(response.lockAfterFailureCount),
                setIdleLogoutMinutes(response.idleLogoutMinutes),
                setPasswordMinimumLength(response.password.minimumLength),
                setPasswordUpperAndLowerCase(response.password.upperAndLowerCase),
                setPasswordNumericCharacters(response.password.numericCharacters),
                setPasswordNonAlphanumericCharacters(response.password.nonAlphanumericCharacters),
                setPasswordCanExpire(response.password.canExpire),
                setPasswordExpiryAgeDays(response.password.expiryAgeDays),
                setPasswordWillThrowWarningsBeforeExpiry(response.password.willThrowWarningsBeforeExpiry),
                setPasswordWarningDaysBeforeExpiry(response.password.warningDaysBeforeExpiry),
                setPasswordCanRejectChangeByMinimumAge(response.password.canRejectChangeByMinimumAge),
                setPasswordMinimumAgeBeforeChange(response.password.minimumAgeBeforeChange),
                setPasswordCanRejectChangeIfRepeated(response.password.canRejectChangeIfRepeated),
                setPasswordRepeatedHistoryRejectLookback(response.password.repeatedHistoryRejectLookback),
                setPolicies(response)
            ])
            setTimeout(() => {
                setLoading(false)
            }, 300)
        } catch (e) {
            console.warn(e)
        }
    }

    fetchPolicies()

    const validData = () => {
        return !Number.isNaN(lockAfterFailureCount) && lockAfterFailureCount <= MAX_LOCKOUT_AFTER_FAILURE_COUNT && lockAfterFailureCount >= MIN_LOCKOUT_AFTER_FAILURE_COUNT &&
            !Number.isNaN(idleLogoutMinutes) && idleLogoutMinutes <= MAX_IDLE_LOGOUT_MINUTES && idleLogoutMinutes >= MIN_IDLE_LOGOUT_MINUTES &&
            !Number.isNaN(passwordMinimumLength) && passwordMinimumLength <= MAX_PASSWORD_MIN_LENGTH && passwordMinimumLength >= MIN_PASSWORD_MIN_LENGTH &&
            !Number.isNaN(passwordExpiryAgeDays) && passwordExpiryAgeDays <= MAX_PASSWORD_EXPIRY_AGE_DAYS && passwordExpiryAgeDays >= MIN_PASSWORD_EXPIRY_AGE_DAYS &&
            !Number.isNaN(passwordWarningDaysBeforeExpiry) && passwordWarningDaysBeforeExpiry <= MAX_PASSWORD_WARNING_DAYS_BEFORE_EXPIRY && passwordWarningDaysBeforeExpiry >= MIN_PASSWORD_WARNING_DAYS_BEFORE_EXPIRY &&
            !Number.isNaN(passwordMinimumAgeBeforeChange) && passwordMinimumAgeBeforeChange <= MAX_PASSWORD_MIN_AGE_BEFORE_CHANGE && passwordMinimumAgeBeforeChange >= MIN_PASSWORD_MIN_AGE_BEFORE_CHANGE &&
            !Number.isNaN(passwordRepeatedHistoryRejectLookback) && passwordRepeatedHistoryRejectLookback <= MAX_PASSWORD_REPEATED_HISTORY_REJECT && passwordRepeatedHistoryRejectLookback >= MIN_PASSWORD_REPEATED_HISTORY_REJECT
    }

    const handleConfirmDeleteClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return
        }

        setConfirmDelete(false)
    }

    const clearPolicies = async (e) => {
        await setLoading(true)
        try {
            const token = window.localStorage.getItem('token')
            await authApi.delete('policies', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            await setFetchedPolicies(false)
            setConfirmDelete(false)
        } catch (e) {
            console.warn('failed to clear', e)
            setLoading(false)
            setConfirmDelete(false)
        }
    }

    const submitForm = async (e) => {
        await setLoading(true)
        try {
            console.log("processing...", lockAfterFailureCount)
            if (!validData()) {
                throw new Error('invalid data provided')
            }
            const token = window.localStorage.getItem('token')
            await authApi.post('policies', {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                json: {
                    autoLogoutOnNewSession: autoLogoutOnNewSession,
                    canLockAfterFailures: canLockAfterFailures,
                    lockAfterFailureCount: lockAfterFailureCount,
                    idleLogoutMinutes: idleLogoutMinutes,
                    password: {
                        minimumLength: passwordMinimumLength,
                        upperAndLowerCase: passwordUpperAndLowerCase,
                        numericCharacters: passwordNumericCharacters,
                        nonAlphanumericCharacters: passwordNonAlphanumericCharacters,
                        canExpire: passwordCanExpire,
                        expiryAgeDays: passwordExpiryAgeDays,
                        willThrowWarningsBeforeExpiry: passwordWillThrowWarningsBeforeExpiry,
                        warningDaysBeforeExpiry: passwordWarningDaysBeforeExpiry,
                        canRejectChangeByMinimumAge: passwordCanRejectChangeByMinimumAge,
                        minimumAgeBeforeChange: passwordMinimumAgeBeforeChange,
                        canRejectChangeIfRepeated: passwordCanRejectChangeIfRepeated,
                        repeatedHistoryRejectLookback: passwordRepeatedHistoryRejectLookback
                    }
                }
            })
            await setFetchedPolicies(false)
        } catch (e) {
            console.warn('failed to submit', e)
            setLoading(false)
        }
    }

    const fluid = width > 1480

    return <Grid container spacing={2}>
        <Grid item style={{ width: fluid ? 320 : 60 }}>
            {/* <code>{JSON.stringify(policies)}</code> */}
        </Grid>
        <Grid item xs>
            <Dialog
                key='dialogDelete'
                open={confirmDelete}
                onClose={handleConfirmDeleteClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>This action will clear all your current policies, please confirm</DialogTitle>
                <DialogActions>
                    <Button disabled={loading}
                        onClick={() => setConfirmDelete(false)}
                        variant='contained'
                        color='error'>
                        Cancel
                    </Button>
                    <Button disabled={loading}
                        onClick={clearPolicies}
                        variant='contained'
                        color='primary'>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            {policies && <form style={styles.form}
                noValidate
                onSubmit={(e) => { e.preventDefault() }}
                autoComplete="off">
                <Grid container spacing={2} style={styles.header(width)}>
                    <Grid item xs><h1>Policies</h1></Grid>
                    <Grid item style={{ width: 180 }}></Grid>
                </Grid>
                {loading && <LinearProgress></LinearProgress>}
                <h3>Login and Session</h3>
                <FormControlLabel
                    style={styles.switch}
                    value="autoLogoutOnNewSession"
                    control={<Switch
                        checked={autoLogoutOnNewSession}
                        disabled={loading}
                        onChange={!isReadOnly ? (e) => { setAutoLogoutOnNewSession(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'auto log out of old session when a new one is created' }}
                    />}
                    label={"Single active session login allowed only. " + (autoLogoutOnNewSession ? "If multiple log ins are detected, auto log out from all inactive sessions" : "Block login until user manually logs out from all other inactive sessions")}
                />
                <FormControlLabel
                    style={styles.switch}
                    value="canLockAfterFailures"
                    control={<Switch
                        checked={canLockAfterFailures}
                        disabled={loading}
                        onChange={!isReadOnly ? (e) => { setCanLockAfterFailures(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'can lock after failures' }}
                    />}
                    label={"Can lock user login after failures: " + (canLockAfterFailures ? 'ON' : 'OFF')}
                />
                <TextField
                    required
                    size="small"
                    id="lockAfterFailureCount"
                    name="lockAfterFailureCount"
                    label="Lock After Failure Count"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: isReadOnly,
                        inputProps: {
                            max: MAX_LOCKOUT_AFTER_FAILURE_COUNT,
                            min: MIN_LOCKOUT_AFTER_FAILURE_COUNT
                        }
                    }}
                    onChange={(e) => {
                        const max = MAX_LOCKOUT_AFTER_FAILURE_COUNT
                        let value = parseInt(e.target.value, 10)
                        if (value > max) {
                            value = max
                        }
                        if (e.target.value === '') {
                            value = ''
                        }

                        setLockAfterFailureCount(value)
                    }}
                    onBlur={(e) => {
                        const min = MIN_LOCKOUT_AFTER_FAILURE_COUNT
                        if (e.target.value === '') {
                            setLockAfterFailureCount(min)
                            return
                        }
                        let value = parseInt(e.target.value, 10)
                        if (value < min) {
                            setLockAfterFailureCount(min)
                        }
                    }}
                    onFocus={() => { }}
                    value={lockAfterFailureCount}
                    disabled={loading || !canLockAfterFailures}
                    error={lockAfterFailureCount === ''}
                    style={styles.numberInput}
                    autoComplete="off"
                />
                <TextField
                    required
                    size="small"
                    id="idleLogoutMinutes"
                    name="idleLogoutMinutes"
                    label="Idle logout time (minutes)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: isReadOnly,
                        inputProps: {
                            max: MAX_IDLE_LOGOUT_MINUTES,
                            min: MIN_IDLE_LOGOUT_MINUTES
                        }
                    }}
                    onChange={(e) => {
                        const max = MAX_IDLE_LOGOUT_MINUTES
                        let value = parseInt(e.target.value, 10)
                        if (value > max) {
                            value = max
                        }
                        if (e.target.value === '') {
                            value = ''
                        }

                        setIdleLogoutMinutes(value)
                    }}
                    onBlur={(e) => {
                        const min = MIN_IDLE_LOGOUT_MINUTES
                        if (e.target.value === '') {
                            setIdleLogoutMinutes(min)
                        }
                        let value = parseInt(e.target.value, 10)
                        if (value < min) {
                            setIdleLogoutMinutes(min)
                        }
                    }}
                    onFocus={() => { }}
                    value={idleLogoutMinutes}
                    disabled={loading}
                    error={idleLogoutMinutes === ''}
                    style={styles.numberInput}
                    autoComplete="off"
                />
                <h3>Password requirements</h3>
                <TextField
                    required
                    size="small"
                    id="passwordMinimumLength"
                    name="passwordMinimumLength"
                    label="Minimum length"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: isReadOnly,
                        inputProps: {
                            max: MAX_PASSWORD_MIN_LENGTH,
                            min: MIN_PASSWORD_MIN_LENGTH
                        }
                    }}
                    onChange={(e) => {
                        const max = MAX_PASSWORD_MIN_LENGTH
                        let value = parseInt(e.target.value, 10)
                        if (value > max) {
                            value = max
                        }
                        if (e.target.value === '') {
                            value = ''
                        }

                        setPasswordMinimumLength(value)
                    }}
                    onBlur={(e) => {
                        const min = MIN_PASSWORD_MIN_LENGTH
                        if (e.target.value === '') {
                            setPasswordMinimumLength(min)
                            return
                        }
                        let value = parseInt(e.target.value, 10)
                        if (value < min) {
                            setPasswordMinimumLength(min)
                        }
                    }}
                    onFocus={() => { }}
                    value={passwordMinimumLength}
                    disabled={loading}
                    error={passwordMinimumLength === ''}
                    style={styles.numberInput}
                    autoComplete="off"
                />
                <FormControlLabel
                    style={styles.switch}
                    value="passwordUpperAndLowerCase"
                    control={<Switch
                        checked={passwordUpperAndLowerCase}
                        disabled={loading}
                        onChange={!isReadOnly ? (e) => { setPasswordUpperAndLowerCase(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'password must use uppercase and lowercase' }}
                    />}
                    label={"Must use uppercase and lowercase: " + (passwordUpperAndLowerCase ? 'ON' : 'OFF')}
                />
                <FormControlLabel
                    style={styles.switch}
                    value="passwordNumericCharacters"
                    control={<Switch
                        checked={passwordNumericCharacters}
                        disabled={loading}
                        onChange={!isReadOnly ? (e) => { setPasswordNumericCharacters(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'password must use numbers' }}
                    />}
                    label={"Must use numbers: " + (passwordNumericCharacters ? 'ON' : 'OFF')}
                />
                <FormControlLabel
                    style={styles.switch}
                    value="passwordNonAlphanumericCharacters"
                    control={<Switch
                        checked={passwordNonAlphanumericCharacters}
                        disabled={loading}
                        onChange={!isReadOnly ? (e) => { setPasswordNonAlphanumericCharacters(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'password must use special characters' }}
                    />}
                    label={"Must use special characters: " + (passwordNonAlphanumericCharacters ? 'ON' : 'OFF')}
                />
                <h3>Password expiry</h3>
                <FormControlLabel
                    style={styles.switch}
                    value="passwordCanExpire"
                    control={<Switch
                        checked={passwordCanExpire}
                        disabled={loading}
                        onChange={!isReadOnly ? (e) => { setPasswordCanExpire(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'password can expire' }}
                    />}
                    label={"Can expire: " + (passwordCanExpire ? 'ON' : 'OFF')}
                />
                <TextField
                    required
                    size="small"
                    id="passwordExpiryAgeDays"
                    name="passwordExpiryAgeDays"
                    label="Expiry age (days)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: isReadOnly,
                        inputProps: {
                            max: MAX_PASSWORD_EXPIRY_AGE_DAYS,
                            min: MIN_PASSWORD_EXPIRY_AGE_DAYS
                        }
                    }}
                    onChange={(e) => {
                        const max = MAX_PASSWORD_EXPIRY_AGE_DAYS
                        let value = parseInt(e.target.value, 10)
                        if (value > max) {
                            value = max
                        }
                        if (e.target.value === '') {
                            value = ''
                        }

                        setPasswordExpiryAgeDays(value)
                    }}
                    onBlur={(e) => {
                        const min = MIN_PASSWORD_EXPIRY_AGE_DAYS
                        if (e.target.value === '') {
                            setPasswordExpiryAgeDays(min)
                            return
                        }
                        let value = parseInt(e.target.value, 10)
                        if (value < min) {
                            setPasswordExpiryAgeDays(min)
                        }
                    }}
                    onFocus={() => { }}
                    value={passwordExpiryAgeDays}
                    disabled={loading || !passwordCanExpire}
                    error={passwordExpiryAgeDays === ''}
                    style={styles.numberInput}
                    autoComplete="off"
                />
                <FormControlLabel
                    style={styles.switch}
                    value="passwordWillThrowWarningsBeforeExpiry"
                    control={<Switch
                        checked={passwordWillThrowWarningsBeforeExpiry}
                        disabled={loading || !passwordCanExpire}
                        onChange={!isReadOnly ? (e) => { setPasswordWillThrowWarningsBeforeExpiry(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'Show warnings before expiry' }}
                    />}
                    label={"Show warnings before expiry: " + (passwordWillThrowWarningsBeforeExpiry ? 'ON' : 'OFF')}
                />
                <TextField
                    required
                    size="small"
                    id="passwordWarningDaysBeforeExpiry"
                    name="passwordWarningDaysBeforeExpiry"
                    label="Warning before expiry time (days)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: isReadOnly,
                        inputProps: {
                            max: MAX_PASSWORD_WARNING_DAYS_BEFORE_EXPIRY,
                            min: MIN_PASSWORD_WARNING_DAYS_BEFORE_EXPIRY
                        }
                    }}
                    onChange={(e) => {
                        const max = MAX_PASSWORD_WARNING_DAYS_BEFORE_EXPIRY
                        let value = parseInt(e.target.value, 10)
                        if (value > max) {
                            value = max
                        }
                        if (e.target.value === '') {
                            value = ''
                        }

                        setPasswordWarningDaysBeforeExpiry(value)
                    }}
                    onBlur={(e) => {
                        const min = MIN_PASSWORD_WARNING_DAYS_BEFORE_EXPIRY
                        if (e.target.value === '') {
                            setPasswordWarningDaysBeforeExpiry(min)
                            return
                        }
                        let value = parseInt(e.target.value, 10)
                        if (value < min) {
                            setPasswordWarningDaysBeforeExpiry(min)
                        }
                    }}
                    onFocus={() => { }}
                    value={passwordWarningDaysBeforeExpiry}
                    disabled={loading || !passwordCanExpire || !passwordWillThrowWarningsBeforeExpiry}
                    error={passwordWarningDaysBeforeExpiry === ''}
                    style={styles.numberInput}
                    autoComplete="off"
                />
                <h3>Password change</h3>
                <FormControlLabel
                    style={styles.switch}
                    value="passwordCanRejectChangeByMinimumAge"
                    control={<Switch
                        checked={passwordCanRejectChangeByMinimumAge}
                        disabled={loading}
                        onChange={!isReadOnly ? (e) => { setPasswordCanRejectChangeByMinimumAge(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'can reject password change by minimum age' }}
                    />}
                    label={"Can reject change by minimum age: " + (passwordCanRejectChangeByMinimumAge ? 'ON' : 'OFF')}
                />
                <TextField
                    required
                    size="small"
                    id="passwordMinimumAgeBeforeChange"
                    name="passwordMinimumAgeBeforeChange"
                    label="Minimum age to allow change (days)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: isReadOnly,
                        inputProps: {
                            max: MAX_PASSWORD_MIN_AGE_BEFORE_CHANGE,
                            min: MIN_PASSWORD_MIN_AGE_BEFORE_CHANGE
                        }
                    }}
                    onChange={(e) => {
                        const max = MAX_PASSWORD_MIN_AGE_BEFORE_CHANGE
                        let value = parseInt(e.target.value, 10)
                        if (value > max) {
                            value = max
                        }
                        if (e.target.value === '') {
                            value = ''
                        }

                        setPasswordMinimumAgeBeforeChange(value)
                    }}
                    onBlur={(e) => {
                        const min = MIN_PASSWORD_MIN_AGE_BEFORE_CHANGE
                        if (e.target.value === '') {
                            setPasswordMinimumAgeBeforeChange(min)
                            return
                        }
                        let value = parseInt(e.target.value, 10)
                        if (value < min) {
                            setPasswordMinimumAgeBeforeChange(min)
                        }
                    }}
                    onFocus={() => { }}
                    value={passwordMinimumAgeBeforeChange}
                    disabled={loading || !passwordCanRejectChangeByMinimumAge}
                    error={passwordMinimumAgeBeforeChange === ''}
                    style={styles.numberInput}
                    autoComplete="off"
                />
                <FormControlLabel
                    style={styles.switch}
                    value="passwordCanRejectChangeIfRepeated"
                    control={<Switch
                        checked={passwordCanRejectChangeIfRepeated}
                        disabled={loading}
                        onChange={!isReadOnly ? (e) => { setPasswordCanRejectChangeIfRepeated(e.target.checked) } : undefined}
                        inputProps={{ 'aria-label': 'can reject password change if repeated' }}
                    />}
                    label={"Can reject change if repeated: " + (passwordCanRejectChangeIfRepeated ? 'ON' : 'OFF')}
                />
                <TextField
                    required
                    size="small"
                    id="passwordRepeatedHistoryRejectLookback"
                    name="passwordRepeatedHistoryRejectLookback"
                    label="Repeated history lookback count"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: isReadOnly,
                        inputProps: {
                            max: MAX_PASSWORD_REPEATED_HISTORY_REJECT,
                            min: MIN_PASSWORD_REPEATED_HISTORY_REJECT
                        }
                    }}
                    onChange={(e) => {
                        const max = MAX_PASSWORD_REPEATED_HISTORY_REJECT
                        let value = parseInt(e.target.value, 10)
                        if (value > max) {
                            value = max
                        }
                        if (e.target.value === '') {
                            value = ''
                        }

                        setPasswordRepeatedHistoryRejectLookback(value)
                    }}
                    onBlur={(e) => {
                        const min = MIN_PASSWORD_REPEATED_HISTORY_REJECT
                        if (e.target.value === '') {
                            setPasswordRepeatedHistoryRejectLookback(min)
                            return
                        }
                        let value = parseInt(e.target.value, 10)
                        if (value < min) {
                            setPasswordRepeatedHistoryRejectLookback(min)
                        }
                    }}
                    onFocus={() => { }}
                    value={passwordRepeatedHistoryRejectLookback}
                    disabled={loading || !passwordCanRejectChangeIfRepeated}
                    error={passwordRepeatedHistoryRejectLookback === ''}
                    style={styles.numberInput}
                    autoComplete="off"
                />
                {!isReadOnly && <Grid container spacing={2} style={styles.footer(width)}>
                    <Grid item xs></Grid>
                    <Grid item style={{ width: 280 }}>
                        <Button type='button'
                            style={{ marginRight: 10 }}
                            variant='contained'
                            color='secondary'
                            onClick={() => setConfirmDelete(true)}>Defaults</Button>
                        <Button type='submit'
                            variant='contained'
                            color='primary'
                            onClick={submitForm}>Save changes</Button>
                    </Grid>
                </Grid>}
            </form>}
        </Grid>
    </Grid>
}

export default Policies