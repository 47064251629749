import React, { useState } from 'react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles } from '@mui/styles'

import { usePublish, fetch, unpublish } from '../api'
import Timer from './Timer'

const componentStyles = makeStyles((theme) => ({
    root: {
        flex: '1 1',
        display: 'flex',
        background: '#545454',
    },
    container: {
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        paddingRight: 0,
    },
    gameOutput: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
        maxHeight: '-webkit-fill-available',
    },
    output: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
    },
    outputGames: {
        display: 'flex',
        flex: '1 1',
        color: 'white',
        padding: '1em',
        overflowY: 'auto',
    },
    cardSelectContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

const BacGame = ({
    time,
    device,
    deviceID,
    gameVariant,
    name,
    ip,
    session,
    sessionStart,
    games,
    game,
    trend,
    statistics,
    standardSummary,
    lucky6Summary,
    mega6Summary,
    tigerSummary,
}) => {
    const styles = componentStyles()
    const card = usePublish(ip + '/baccarat/card')
    // const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [bankerCardSuit, setBankerCardSuit] = useState(1)
    const [bankerCardValue, setBankerCardValue] = useState(11)
    const [playerCardSuit, setPlayerCardSuit] = useState(1)
    const [playerCardValue, setPlayerCardValue] = useState(11)
    const sendCard = async (owner) => {
        try {
            if (owner === 'P') {
                await card({
                    suit: playerCardSuit,
                    value: playerCardValue,
                    owner,
                })
            }

            if (owner === 'B') {
                await card({
                    suit: bankerCardSuit,
                    value: bankerCardValue,
                    owner,
                })
            }
        } catch (e) {
            console.warn(e)
        }
    }

    const sendBetsOff = async () => {
        try {
            await window.fetch('http://' + ip + '/baccarat/betsoff')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendEnd = async () => {
        try {
            await window.fetch('http://' + ip + '/baccarat/end')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendVoid = async () => {
        try {
            await fetch(ip + '/baccarat/void')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendDeck = async () => {
        try {
            await window.fetch('http://' + ip + '/baccarat/deck')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendStop = async () => {
        try {
            await window.fetch('http://' + ip + '/baccarat/timer/stop')
        } catch (e) {
            console.warn(e)
        }
    }

    const sendRestart = async () => {
        try {
            await window.fetch('http://' + ip + '/baccarat/timer/restart')
        } catch (e) {
            console.warn(e)
        }
    }

    const startSession = async (account) => {
        try {
            await sessionStart({
                account,
            })
        } catch (e) {
            console.warn(e)
        }
    }

    const endSession = async () => {
        try {
            await unpublish(ip + '/sessions/' + deviceID)
        } catch (e) {
            console.warn(e)
        }
    }

    const timeInSeconds = Math.round(time / 1000000000)
    const timeInMiliSeconds = Math.round(time / 1000000)

    if (to === 0 || timeInMiliSeconds - to > 120000) {
        // setFrom(timeInMiliSeconds - 120000)
        setTo(timeInMiliSeconds)
    }

    const getSummary = (gameVariant) => {
        switch (gameVariant) {
            case 'standard':
                return standardSummary
            case 'lucky6':
                return lucky6Summary
            case 'mega6':
                return mega6Summary
            case 'tiger':
                return tigerSummary
            default: {}
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div>Name: {name}</div>
                <div>IP: {ip}</div>
                <div>TIME: {timeInSeconds}</div>
                <div>SESSION: {session}</div>
                <Timer time={time} device={device} games={games} game={game} />
                <div># of games: {games.length}</div>
                <div className={styles.cardSelectContainer}>
                    <Button onClick={() => sendCard('P')}>Player Card</Button>
                    <Select
                        labelId='player-card-suit-select'
                        id='player-card-suit-select'
                        value={playerCardSuit}
                        label='Suit'
                        onChange={(e) => {
                            setPlayerCardSuit(e.target.value)
                        }}
                    >
                        <MenuItem value={1}>
                            <span role='img' aria-label='spade'>
                                ♠️
                            </span>
                        </MenuItem>
                        <MenuItem value={2}>
                            <span role='img' aria-label='heart'>
                                ♥️
                            </span>
                        </MenuItem>
                        <MenuItem value={3}>
                            <span role='img' aria-label='club'>
                                ♣️
                            </span>
                        </MenuItem>
                        <MenuItem value={4}>
                            <span role='img' aria-label='diamond'>
                                ♦️
                            </span>
                        </MenuItem>
                    </Select>
                    <Select
                        labelId='player-card-value-select'
                        id='player-card-value-select'
                        value={playerCardValue}
                        label='Value'
                        onChange={(e) => {
                            setPlayerCardValue(e.target.value)
                        }}
                    >
                        <MenuItem value={1}>A</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>J</MenuItem>
                        <MenuItem value={12}>Q</MenuItem>
                        <MenuItem value={13}>K</MenuItem>
                    </Select>
                </div>
                <div className={styles.cardSelectContainer}>
                    <Button onClick={() => sendCard('B')}>Banker Card</Button>
                    <Select
                        labelId='baker-card-suit-select'
                        id='banker-card-suit-select'
                        value={bankerCardSuit}
                        label='Suit'
                        onChange={(e) => {
                            setBankerCardSuit(e.target.value)
                        }}
                    >
                        <MenuItem value={1}>
                            <span role='img' aria-label='spade'>
                                ♠️
                            </span>
                        </MenuItem>
                        <MenuItem value={2}>
                            <span role='img' aria-label='heart'>
                                ♥️
                            </span>
                        </MenuItem>
                        <MenuItem value={3}>
                            <span role='img' aria-label='club'>
                                ♣️
                            </span>
                        </MenuItem>
                        <MenuItem value={4}>
                            <span role='img' aria-label='diamond'>
                                ♦️
                            </span>
                        </MenuItem>
                    </Select>
                    <Select
                        labelId='banker-card-value-select'
                        id='banker-card-value-select'
                        value={bankerCardValue}
                        label='Value'
                        onChange={(e) => {
                            setBankerCardValue(e.target.value)
                        }}
                    >
                        <MenuItem value={1}>A</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>J</MenuItem>
                        <MenuItem value={12}>Q</MenuItem>
                        <MenuItem value={13}>K</MenuItem>
                    </Select>
                </div>
                <Button onClick={() => sendBetsOff()}>Bets Off</Button>
                <Button onClick={() => sendEnd()}>End Game</Button>
                <Button onClick={() => sendVoid()}>Void Game</Button>
                <Button disabled={games.length === 0} onClick={() => sendDeck()}>
                    Deck Change
                </Button>
                <Button onClick={() => sendStop()}>Stop Timer</Button>
                <Button onClick={() => sendRestart()}>Restart Timer</Button>
                <Button onClick={() => startSession('dealer')}>Start Session</Button>
                <Button onClick={() => endSession()}>End Session</Button>
                <h1>Statistics</h1>
                <pre className={styles.output}>{JSON.stringify(statistics, undefined, 2)}</pre>
                <h1>Trend</h1>
                <pre className={styles.output}>{JSON.stringify(trend, undefined, 2)}</pre>
                <h1>Summary</h1>
                <pre className={styles.output}>{JSON.stringify(getSummary(gameVariant), undefined, 2)}</pre>
            </div>
            <div className={styles.container}>
                <h1>Game [{game.data.status}]</h1>
                <pre className={styles.gameOutput}>{JSON.stringify(game.data, undefined, 2)}</pre>
            </div>
            <div className={styles.container}>
                <h1>Games [{games.length}]</h1>
                <pre className={styles.outputGames}>
                    {games.length > 0 ? JSON.stringify(games[games.length - 1], undefined, 2) : 'no games yet'}
                </pre>
            </div>
        </div>
    )
}

export default BacGame
