import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiDrawer from '@mui/material/Drawer'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { usePublish } from '../../api'
import AlertDialog from '../../xtrend/components/AlertDialog'
import HotTables from './HotTables'
import Lookup from './Lookup'

const rootStyles = makeStyles((theme) => ({
    root: {
        marginTop: '4em',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        background: 'transparent',
        overflowY: 'hidden',
    },
}))

const MoreInfoDrawer = ({ displayMoreInfo, setDisplayMoreInfo }) => {
    const lights = window.localStorage.getItem('lights') === 'on'
    const styles = rootStyles({ lights })

    return (
        <MuiDrawer
            anchor={'right'}
            variant={'temporary'}
            open={displayMoreInfo}
            onClose={() => setDisplayMoreInfo(false)}
        >
            <Paper key='form' className={styles.root} elevation={0}>
                <Box
                    sx={{
                        width: '500px',
                        height: '100%',
                        padding: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography>Please contact IDX Support for any questions or comments.</Typography>

                    <Box display='flex' justifyContent='center'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                setDisplayMoreInfo(false)
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </MuiDrawer>
    )
}

export const getGameVariantTranslation = (variant) => {
    let result = ''
    switch (variant) {
        case 'standard':
            result = 'Standard'
            break
        case 'lucky6':
            result = 'Lucky 6'
            break
        case 'lucky6Slot':
            result = 'Lucky 6 with Slot'
            break
        case 'mega6':
            result = 'Mega 6'
            break
        case 'tiger':
            result = 'Tiger'
            break
        default:
    }
    return result
}

const Settings = ({ devices }) => {
    const [displayMoreInfo, setDisplayMoreInfo] = useState(false)

    // tabs
    const [value, setValue] = useState(1)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [nestedValue, setNestedValue] = useState(0)
    const handleNestedChange = (event, newValue) => {
        setNestedValue(newValue)
    }

    const [gamesLimit, setGamesLimit] = useState('')
    const [hotTablesDisplay, setHotTablesDisplay] = useState('')

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => {},
        loading: false,
    })

    const [submitted, setSubmitted] = useState(false)

    const validateData = () => {
        const gamesLimitValidation = gamesLimit < 100 ? false : gamesLimit > 500 ? false : true

        return gamesLimitValidation
    }

    const batchApplyPrompt = () => {
        setSubmitted(true)
        if (validateData()) {
            setAlertDialogState({
                show: true,
                type: 'confirmation',
                message: <>These settings will apply to all ETG tables across all game types. Are you sure?</>,
                loading: false,
                error: false,
                confirm: async () => {
                    const deviceIndexesToApply = devices.map((v) => v.index)

                    setAlertDialogState((prev) => ({
                        ...prev,
                        loading: true,
                    }))

                    const promises = deviceIndexesToApply.map((deviceIndex) => Apply(deviceIndex))

                    Promise.allSettled(promises).then(() => {
                        setAlertDialogState({
                            show: 'true',
                            type: 'success',
                            message: 'Update successfully.',
                            loading: false,
                            error: 'false',
                        })
                        setSubmitted(false)
                    })
                },
            })
        }
    }

    const Apply = async (deviceIndex) => {
        const publish = usePublish('devices/' + deviceIndex)
        const device = devices.filter((device) => device.index === deviceIndex)[0]

        try {
            return publish({
                ...device.data,
                gamesLimit: gamesLimit,
                hotTablesDisplay: hotTablesDisplay,
                updatedBy: window.localStorage.getItem('account') ?? '-',
            })
        } catch (error) {
            console.log('error:', error)
        }
    }

    return (
        <Box
            sx={{
                height: '100%',
                padding: '2rem',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <Box display='flex' justifyContent='space-between' gap='2rem'>
                <Box>
                    <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                        Chase The Trend ETG
                    </Typography>
                </Box>

                <Button
                    variant='outlined'
                    onClick={() => {
                        setDisplayMoreInfo(true)
                    }}
                >
                    More Information
                </Button>
            </Box>

            <Tabs
                sx={{ backgroundColor: '#424242', mt: 2 }}
                value={value}
                onChange={handleChange}
                indicatorColor='secondary'
                textColor='secondary'
                aria-label='full width tabs example'
            >
                <Tab label='Trends' component={Link} to={'/xstadium/hottrends/trends'} />
                <Tab label='Settings' component={Link} to={'/xstadium/hottrends/settings'} />
            </Tabs>

            <Box sx={{ height: '100%', colorScheme: 'dark', overflow: 'auto' }}>
                <PerfectScrollbar>
                    <Tabs
                        sx={{ backgroundColor: '#424242' }}
                        value={nestedValue}
                        onChange={handleNestedChange}
                        indicatorColor='secondary'
                        textColor='secondary'
                        aria-label='full width tabs example'
                    >
                        <Tab label='Lookup' />
                        <Tab
                            label='
                    Hot Tables'
                        />
                    </Tabs>

                    {nestedValue === 0 && (
                        <Lookup
                            type='setting'
                            submitted={submitted}
                            gamesLimit={gamesLimit}
                            setGamesLimit={setGamesLimit}
                        />
                    )}
                    {nestedValue === 1 && (
                        <HotTables hotTablesDisplay={hotTablesDisplay} setHotTablesDisplay={setHotTablesDisplay} />
                    )}
                </PerfectScrollbar>
            </Box>

            <Box sx={{ mt: 2 }} display='flex' justifyContent='flex-end' gap='.5rem'>
                <Button variant='contained' color='primary' onClick={() => batchApplyPrompt()}>
                    Save
                </Button>
            </Box>

            <MoreInfoDrawer displayMoreInfo={displayMoreInfo} setDisplayMoreInfo={setDisplayMoreInfo} />

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </Box>
    )
}

export default Settings
