import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { useAppContext } from '../contexts/AppContext'
import Breadcrumbs from './Breadcrumbs'

const Location = ({
    loading,
    zones,
    pits,
    tables,
    devices,
    location,
    fluid,
    filter,
    back,
    locationID,
    locationType,
    startDate,
    endDate,
    xstadium,
}) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const { gameVariantFilter, setGameVariantFilter } = useAppContext()

    const handleChange = (e) => {
        setGameVariantFilter(e.target.value)
    }

    const isTable = location && location[0].branchType === 'Table'

    const path = xstadium ? 'xstadium' : 'xtable'

    const isXstadiumGamesOrTrends =
        pathname.split('/')[1] === 'xstadium' &&
        (pathname.split('/')[2] === 'games' || pathname.split('/')[2] === 'trends')

    return (
        <>
            <div
                style={{
                    position: 'sticky',
                    top: 0,
                    marginLeft: fluid ? 0 : 138,
                    alignSelf: 'start',
                    zIndex: 10,
                    background: '#2a2a2a',
                    paddingLeft: 6,
                }}
            >
                {loading && <LinearProgress />}

                {!loading && location && (
                    <Box
                        width='100%'
                        display='flex'
                        alignItems='center'
                        justifyContent={
                            back ? 'space-between' : filter ? (xstadium ? 'end' : 'space-between') : 'start'
                        }
                    >
                        {!isXstadiumGamesOrTrends && (
                            <Box>
                                <Breadcrumbs
                                    zones={zones}
                                    pits={pits}
                                    tables={tables}
                                    devices={devices}
                                    selected={{ index: location[0].branchIndex, type: location[0].branchType }}
                                    xstadium={xstadium}
                                />
                            </Box>
                        )}

                        {back && !isTable && (
                            <Button
                                variant='contained'
                                sx={{ m: 2 }}
                                onClick={() =>
                                    navigate(
                                        `/${path}/statistics?locationID=${locationID}&locationType=${locationType}&startDate=${startDate}&endDate=${endDate}`
                                    )
                                }
                            >
                                Back
                            </Button>
                        )}

                        {filter && !isTable && (
                            <FormControl sx={{ m: 1, minWidth: 250 }} size='small'>
                                <InputLabel>Game Type & Variant</InputLabel>
                                <Select label='Game Type & Variant' value={gameVariantFilter} onChange={handleChange}>
                                    <MenuItem value='baccaratStandard'>Baccarat - Standard</MenuItem>
                                    <MenuItem value='baccaratLucky6'>Baccarat - Lucky 6</MenuItem>
                                    <MenuItem value='baccaratLucky6Slot'>Baccarat - Lucky 6 with Slots</MenuItem>
                                    <MenuItem value='baccaratMega6'>Baccarat - Mega 6</MenuItem>
                                    <MenuItem value='baccaratTiger'>Baccarat - Tiger</MenuItem>
                                    <MenuItem value='blackjackStandard'>Blackjack - Standard</MenuItem>
                                    <MenuItem value='blackjackAnyPairSlot'>Blackjack - Any Pair with Slot</MenuItem>
                                    <MenuItem value='rouletteStandard'>Roulette - Standard</MenuItem>
                                    <MenuItem value='warEtg'>War - ETG</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                )}

                {!loading && !location && (
                    <Breadcrumbs
                        zones={zones}
                        pits={pits}
                        tables={tables}
                        devices={devices}
                        selected={{ index: 'root', type: 'Casino' }}
                    />
                )}
            </div>
        </>
    )
}

export default Location
