import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import CircleIcon from '@mui/icons-material/Circle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'

import { useSubscribe, publish, unpublish } from '../../api'
import { xstadiumIP } from '../../config'
import AlertDialog from '../../xtrend/components/AlertDialog'

var randomColor = require('randomcolor')

const Playlists = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const navigate = useNavigate()

    const [playlists, playlistsSocket] = useSubscribe(xstadiumIP + '/playlists/*')
    const [schedules, schedulesSocket] = useSubscribe(xstadiumIP + '/schedules/*')

    const active =
        playlistsSocket &&
        playlistsSocket.readyState === WebSocket.OPEN &&
        schedulesSocket &&
        schedulesSocket.readyState === WebSocket.OPEN

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedPlaylist, setSelectedPlaylist] = useState({})
    const [saveAsDialog, setSaveAsDialog] = useState(false)
    const [newPlaylistName, setNewPlaylistName] = useState('')
    const [submitted, setSubmitted] = useState(false)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => {},
    })

    if (!playlists || !schedules || !active) {
        return <LinearProgress />
    }

    const nanoToMomentTime = (time) => moment.unix(time / 1000000000)

    const dateDisplay = (time) => nanoToMomentTime(time).format('YYYY-MM-DD')

    const sameDate = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
    }

    const isPlaylistNameUsed = (name) => {
        const match = playlists && playlists.find((playlist) => playlist.data.name === name)

        return match ? true : false
    }

    const isPlaylistScheduled = (playlistID) => {
        const match = schedules && schedules.find((schedule) => schedule.data.playlistID === playlistID)

        return match ? true : false
    }

    const isPlaylistPlaying = (playlistID) => {
        const now = new Date()
        const match =
            schedules &&
            schedules.find((schedule) => {
                return (
                    schedule.data.playlistID === playlistID &&
                    sameDate(now, nanoToMomentTime(schedule.data.start).toDate())
                )
            })

        return match ? true : false
    }

    const columns = [
        {
            field: 'color',
            renderHeader: () => <div></div>,
            renderCell: (params) => {
                return <>{params.row.color !== '-' && <CircleIcon sx={{ color: params.row.color }} />}</>
            },
            sortable: false,
            filterable: false,
            width: 75,
        },
        { field: 'name', headerName: 'Name', width: 740 },
        {
            field: 'lastUpdatedOn',
            headerName: 'Last Updated On',
            width: 375,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 375,
        },
        {
            field: 'edit',
            renderHeader: () => <div></div>,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            if (isPlaylistPlaying(params.row.id)) {
                                setAlertDialogState({
                                    show: true,
                                    type: 'custom',
                                    title: 'Reminder',
                                    message: `The selected playlist (${params.row.name}) is playing now. It cannot be edited.`,
                                    action: (
                                        <Button
                                            onClick={() => navigate(`/xstadium/playlist/${params.row.id}`)}
                                            size='large'
                                        >
                                            <Typography sx={{ fontSize: '1.1rem' }}>
                                                Proceed to view only mode
                                            </Typography>
                                        </Button>
                                    ),
                                    error: false,
                                })
                            } else {
                                navigate(`/xstadium/playlist/${params.row.id}`)
                            }
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                )
            },
            sortable: false,
            filterable: false,
            width: 75,
        },
        {
            field: 'saveAs',
            renderHeader: () => <div></div>,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            setSelectedPlaylist(params.row)
                            setSaveAsDialog(true)
                        }}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                )
            },
            sortable: false,
            filterable: false,
            width: 75,
        },
        {
            field: 'remove',
            renderHeader: () => <div></div>,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            if (isPlaylistScheduled(params.row.id)) {
                                setAlertDialogState({
                                    show: true,
                                    type: 'reminder',
                                    message: `The selected playlist (${params.row.name}) has already been scheduled, cannot be removed.`,
                                    error: false,
                                })
                            }

                            if (!isPlaylistScheduled(params.row.id)) {
                                setAlertDialogState({
                                    show: true,
                                    type: 'confirmation',
                                    message: `Are you sure you want to remove ${params.row.name}?`,
                                    error: false,
                                    confirm: () =>
                                        remove({
                                            id: params.row.id,
                                            success: () => {
                                                setAlertDialogState({
                                                    ...alertDialogState,
                                                    show: false,
                                                })
                                            },
                                            fail: () => {
                                                setAlertDialogState({
                                                    ...alertDialogState,
                                                    show: true,
                                                    type: 'error',
                                                    message: 'Remove failed.',
                                                    error: true,
                                                })
                                            },
                                        }),
                                })
                            }
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                )
            },
            sortable: false,
            filterable: false,
            width: 75,
        },
    ]

    const rows = playlists
        ? playlists
              .filter((playlist) => JSON.stringify(playlist).indexOf(searchTerm) >= 0)
              .map((playlist) => {
                  return {
                      id: playlist.index ? playlist.index : '-',
                      color: playlist.data.color ? playlist.data.color : '-',
                      name: playlist.data.name ? playlist.data.name : '-',
                      layout: playlist.data.layout ? playlist.data.layout : [],
                      background: playlist.data.background ? playlist.data.background : [],
                      music: playlist.data.music ? playlist.data.music : [],
                      animation: playlist.data.animation ? playlist.data.animation : [],
                      announcement: playlist.data.announcement ? playlist.data.announcement : [],
                      lastUpdatedOn: playlist.updated
                          ? dateDisplay(playlist.updated)
                          : playlist.created
                          ? dateDisplay(playlist.created)
                          : '-',
                      lastUpdatedBy: playlist.data.updatedBy ? playlist.data.updatedBy : '-',
                  }
              })
        : []

    const saveAs = () => {
        setSubmitted(true)
        if (!isPlaylistNameUsed(newPlaylistName)) {
            saveAsApi(selectedPlaylist)
        }
    }

    const saveAsApi = async (payload) => {
        setSubmitted(false)
        try {
            await publish(xstadiumIP + '/playlists/*', {
                name: newPlaylistName,
                layout: payload.layout,
                background: payload.background,
                music: payload.music,
                animation: payload.animation,
                announcement: payload.announcement,
                color: randomColor(0.3, 0.99),
                updatedBy: window.localStorage.getItem('account') ?? '-',
            })
        } catch (e) {
            console.warn('failed to submit form copy: ', e)
        }
        setSelectedPlaylist({})
        setSaveAsDialog(false)
        setNewPlaylistName('')
    }

    const remove = async ({ id, success, fail }) => {
        try {
            await unpublish(xstadiumIP + '/playlists/' + id)
            success()
        } catch (e) {
            console.warn('failed to delete: ', e)
            fail()
        }
    }

    return (
        <Box padding='2rem' display='flex' flexDirection='column' gap='1.25rem'>
            <Box
                display='flex'
                flexDirection={mobile ? 'column' : 'row'}
                alignItems='center'
                justifyContent='space-between'
                gap='1rem'
            >
                <Box width={mobile ? '100%' : 'auto'}>
                    <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                        Playlists
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                        Create new and modify existing 24 hour playlists
                    </Typography>
                </Box>

                <Box
                    width={mobile ? '100%' : 'auto'}
                    display='flex'
                    flexDirection={mobile ? 'column' : 'row'}
                    alignItems='center'
                    gap='1rem'
                >
                    <Box width={mobile ? '100%' : 'auto'}>
                        <Button
                            sx={{ width: mobile ? '100%' : 'auto' }}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                navigate('/xstadium/playlists/new')
                            }}
                        >
                            <AddIcon /> &nbsp; Add Playlist
                        </Button>
                    </Box>

                    <TextField
                        sx={{ width: mobile ? '100%' : 'auto' }}
                        variant='outlined'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>

            <Box>
                <DataGrid
                    sx={{
                        colorScheme: 'dark',
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                                outline: 'none',
                            },
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    onRowDoubleClick={(params) => navigate(`/xstadium/playlist/${params.row.id}`)}
                    autoHeight
                />
            </Box>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />

            <Dialog
                key='saveAs'
                open={saveAsDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                PaperProps={{
                    sx: {
                        width: '25rem',
                    },
                }}
            >
                <DialogTitle id='alert-dialog-title'>Save as new playlist</DialogTitle>
                <DialogContent sx={{ overflowY: 'visible' }}>
                    <TextField
                        label='Enter playlist name'
                        value={newPlaylistName}
                        onChange={(e) => setNewPlaylistName(e.target.value)}
                        error={submitted && isPlaylistNameUsed(newPlaylistName)}
                        helperText={
                            submitted &&
                            isPlaylistNameUsed(newPlaylistName) &&
                            'This name already exists. Please enter a different name.'
                        }
                        autoFocus
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setSelectedPlaylist({})
                            setSaveAsDialog(false)
                            setNewPlaylistName('')
                            setSubmitted(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' onClick={saveAs} disabled={isEmpty(newPlaylistName)}>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Playlists
