import React, { useState } from 'react'
import { cloneDeep, get } from 'lodash'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import WarningIcon from '@mui/icons-material/Warning'
import { LinearProgress, Modal, Fade, Button, SnackbarContent, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'

import { usePublish } from '../../api'
import { getGameTypeTranslation, getGameVariantTranslation } from '../../util/Translation'
import { defaultValues } from '../../xtrend/devices/defaultValues'
import { calculateMinBetColor } from '../minBetColor/color'
import { getMinBetColor } from '../minBetColor/color'
import { validate, validateWithTypeAndVariant, updateWithTypeAndVariant } from '../util/forms'
import { formatCashString } from '../util/misc'

const rootStyles = makeStyles((theme) => ({
    noScrollBar: {
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '1px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
        },
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        position: 'relative',
        backgroundColor: '#1B1B1F',
        color: '#fff',
        textAlign: 'center',
        borderRadius: 25,
        padding: 50,
        outline: 0,
    },
    modalHeader: {
        backgroundColor: '#111314',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        borderRadius: '25px 25px 0 0',
    },
    modalPrompt: {
        marginTop: '3em',
        paddingTop: '1em',
        fontSize: 13,
    },
    modalButtonRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '5%',
        '& button': {
            height: '4.2rem',
            boxShadow: '0px 1px 1px #161D251A',
            border: '1px solid #5F606A',
            fontSize: '19px',
        },
    },
    modalInput: {
        width: '90%',
        height: '4.2rem',
        backgroundColor: '#303238',
        boxShadow: '0px 1px 2px #00000014',
        border: '1px solid #5F606A',
        borderRadius: 4,
        fontSize: '2rem',
        '& .MuiOutlinedInput-root': { height: '4.2rem' },
    },
    tablelink: {
        height: '350px',
        margin: 10,
        textDecoration: 'none',
        width: '350px',
        display: 'inline-flex',
        backgroundColor: '#1F1B1B',
        padding: '20px',
        textTransform: 'none',
    },
    table: {
        width: '100%',
        height: '100%',
        color: '#ffffff',
        fontWeight: '100',
        textAlign: 'left',
    },
    title: {
        color: '#5F606A',
        fontSize: '1rem',
        marginRight: '5%',
    },
    offline: {
        color: '#ff1111',
    },
    online: {
        color: '#00ff00',
    },
    switch: {
        '& .MuiSwitch-root': {
            transform: 'scale(1.5)',
        },
        '& .MuiFormControlLabel-label': {
            fontSize: 20,
        },
    },
    tableDetailsForm: {
        backgroundColor: '',
        fontSize: 20,
        '& label': {
            color: '#777',
            marginLeft: 3,
        },
        '& input': {
            border: '1px solid #ccc',
            backgroundColor: '#333',
            fontSize: 20,
            color: '#ccc',
            padding: 5,
            margin: '5px 2px',
            borderRadius: 5,
            width: '90%',
        },
        '& .disabled': {
            opacity: 0.5,
        },
    },
    onoff: {
        color: '#fff',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
        gap: '0.5rem',
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
}))

const Configurations = ({ device }) => {
    const s = rootStyles()
    const publish = usePublish('devices/' + device.index)
    const { t } = useTranslation('common')

    //change detection hook
    const [changed, setChanged] = useState(false)

    //field hooks
    const [tableOn, setTableOn] = useState(null)
    const [name, setName] = useState('')
    const [tableNum, setTableNum] = useState('')
    const [gameType, setGameType] = useState('')
    const [gameVariant, setGameVariant] = useState('')
    const [ip, setIp] = useState('')
    const [chosenStream, setChosenStream] = useState('')
    const [minBet, setMinBet] = useState('')
    const [maxBet, setMaxBet] = useState('')
    const [tieMinBet, setTieMinBet] = useState('')
    const [tieMaxBet, setTieMaxBet] = useState('')
    const [pairMinBet, setPairMinBet] = useState('')
    const [pairMaxBet, setPairMaxBet] = useState('')
    const [naturalMinBet, setNaturalMinBet] = useState('')
    const [naturalMaxBet, setNaturalMaxBet] = useState('')
    const [lucky6MinBet, setLucky6MinBet] = useState('')
    const [lucky6MaxBet, setLucky6MaxBet] = useState('')
    const [mega6MinBet, setMega6MinBet] = useState('')
    const [mega6MaxBet, setMega6MaxBet] = useState('')
    const [tigerMinBet, setTigerMinBet] = useState('')
    const [tigerMaxBet, setTigerMaxBet] = useState('')
    const [tigerTieMinBet, setTigerTieMinBet] = useState('')
    const [tigerTieMaxBet, setTigerTieMaxBet] = useState('')
    const [tigerPairMinBet, setTigerPairMinBet] = useState('')
    const [tigerPairMaxBet, setTigerPairMaxBet] = useState('')
    const [tigerBigMinBet, setTigerBigMinBet] = useState('')
    const [tigerBigMaxBet, setTigerBigMaxBet] = useState('')
    const [tigerSmallMinBet, setTigerSmallMinBet] = useState('')
    const [tigerSmallMaxBet, setTigerSmallMaxBet] = useState('')
    const [anyPairMinBet, setAnyPairMinBet] = useState('')
    const [anyPairMaxBet, setAnyPairMaxBet] = useState('')
    const [anyPairAnyPlayerMinBet, setAnyPairAnyPlayerMinBet] = useState('')
    const [anyPairAnyPlayerMaxBet, setAnyPairAnyPlayerMaxBet] = useState('')

    //UI state hooks
    const [loadingChange, setLoadingChange] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    // form utilities
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)
    const [created, setCreated] = useState(-1)
    const [devIndex, setDevIndex] = useState(-1)

    // field rules
    const fields = {
        open: {
            error: () => false,
            value: tableOn,
            set: setTableOn,
            message: '',
        },
        name: {
            error: () => false,
            value: name,
            set: setName,
            message: '',
        },
        tableNum: {
            error: () => false,
            value: tableNum,
            set: setTableNum,
            message: '',
        },
        gameType: {
            error: () => false,
            value: gameType,
            set: setGameType,
            message: '',
        },
        gameVariant: {
            error: () => false,
            value: gameVariant,
            set: setGameVariant,
            message: '',
        },
        ip: {
            error: () => false,
            value: ip,
            set: setIp,
            message: '',
        },
        stream: {
            error: () => false,
            value: chosenStream,
            set: setChosenStream,
            message: '',
        },
    }

    const betsFields = {
        baccarat: {
            standard: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: t('manager.minBetError'),
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: t('manager.maxBetError'),
                },
                tieMinBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMinBet,
                    set: setTieMinBet,
                    message: t('manager.tieMinBetError'),
                },
                tieMaxBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMaxBet,
                    set: setTieMaxBet,
                    message: t('manager.tieMaxBetError'),
                },
                pairMinBet: {
                    error: () => Number(pairMinBet) > Number(pairMaxBet),
                    value: pairMinBet,
                    set: setPairMinBet,
                    message: t('manager.pairMinBetError'),
                },
                pairMaxBet: {
                    error: () => Number(pairMinBet) > Number(pairMaxBet),
                    value: pairMaxBet,
                    set: setPairMaxBet,
                    message: t('manager.pairMaxBetError'),
                },
                naturalMinBet: {
                    error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                    value: naturalMinBet,
                    set: setNaturalMinBet,
                    message: t('manager.natMinBetError'),
                },
                naturalMaxBet: {
                    error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                    value: naturalMaxBet,
                    set: setNaturalMaxBet,
                    message: t('manager.natMaxBetError'),
                },
            },
            lucky6: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: t('manager.minBetError'),
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: t('manager.maxBetError'),
                },
                tieMinBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMinBet,
                    set: setTieMinBet,
                    message: t('manager.tieMinBetError'),
                },
                tieMaxBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMaxBet,
                    set: setTieMaxBet,
                    message: t('manager.tieMaxBetError'),
                },
                pairMinBet: {
                    error: () => Number(pairMinBet) > Number(pairMaxBet),
                    value: pairMinBet,
                    set: setPairMinBet,
                    message: t('manager.pairMinBetError'),
                },
                pairMaxBet: {
                    error: () => Number(pairMinBet) > Number(pairMaxBet),
                    value: pairMaxBet,
                    set: setPairMaxBet,
                    message: t('manager.pairMaxBetError'),
                },
                naturalMinBet: {
                    error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                    value: naturalMinBet,
                    set: setNaturalMinBet,
                    message: t('manager.natMinBetError'),
                },
                naturalMaxBet: {
                    error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                    value: naturalMaxBet,
                    set: setNaturalMaxBet,
                    message: t('manager.natMaxBetError'),
                },
                lucky6MinBet: {
                    error: () => Number(lucky6MinBet) > Number(lucky6MaxBet),
                    value: lucky6MinBet,
                    set: setLucky6MinBet,
                    message: t('manager.lucky6MinBetError'),
                },
                lucky6MaxBet: {
                    error: () => Number(lucky6MinBet) > Number(lucky6MaxBet),
                    value: lucky6MaxBet,
                    set: setLucky6MaxBet,
                    message: t('manager.lucky6MaxBetError'),
                },
            },
            lucky6Slot: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: t('manager.minBetError'),
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: t('manager.maxBetError'),
                },
                tieMinBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMinBet,
                    set: setTieMinBet,
                    message: t('manager.tieMinBetError'),
                },
                tieMaxBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMaxBet,
                    set: setTieMaxBet,
                    message: t('manager.tieMaxBetError'),
                },
                pairMinBet: {
                    error: () => Number(pairMinBet) > Number(pairMaxBet),
                    value: pairMinBet,
                    set: setPairMinBet,
                    message: t('manager.pairMinBetError'),
                },
                pairMaxBet: {
                    error: () => Number(pairMinBet) > Number(pairMaxBet),
                    value: pairMaxBet,
                    set: setPairMaxBet,
                    message: t('manager.pairMaxBetError'),
                },
                naturalMinBet: {
                    error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                    value: naturalMinBet,
                    set: setNaturalMinBet,
                    message: t('manager.natMinBetError'),
                },
                naturalMaxBet: {
                    error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                    value: naturalMaxBet,
                    set: setNaturalMaxBet,
                    message: t('manager.natMaxBetError'),
                },
                lucky6MinBet: {
                    error: () => Number(lucky6MinBet) > Number(lucky6MaxBet),
                    value: lucky6MinBet,
                    set: setLucky6MinBet,
                    message: t('manager.lucky6MinBetError'),
                },
                lucky6MaxBet: {
                    error: () => Number(lucky6MinBet) > Number(lucky6MaxBet),
                    value: lucky6MaxBet,
                    set: setLucky6MaxBet,
                    message: t('manager.lucky6MaxBetError'),
                },
            },
            mega6: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: t('manager.minBetError'),
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: t('manager.maxBetError'),
                },
                tieMinBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMinBet,
                    set: setTieMinBet,
                    message: t('manager.tieMinBetError'),
                },
                tieMaxBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMaxBet,
                    set: setTieMaxBet,
                    message: t('manager.tieMaxBetError'),
                },
                pairMinBet: {
                    error: () => Number(pairMinBet) > Number(pairMaxBet),
                    value: pairMinBet,
                    set: setPairMinBet,
                    message: t('manager.pairMinBetError'),
                },
                pairMaxBet: {
                    error: () => Number(pairMinBet) > Number(pairMaxBet),
                    value: pairMaxBet,
                    set: setPairMaxBet,
                    message: t('manager.pairMaxBetError'),
                },
                naturalMinBet: {
                    error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                    value: naturalMinBet,
                    set: setNaturalMinBet,
                    message: t('manager.natMinBetError'),
                },
                naturalMaxBet: {
                    error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                    value: naturalMaxBet,
                    set: setNaturalMaxBet,
                    message: t('manager.natMaxBetError'),
                },
                mega6MinBet: {
                    error: () => Number(mega6MinBet) > Number(mega6MaxBet),
                    value: mega6MinBet,
                    set: setMega6MinBet,
                    message: 'Mega 6 min bet cannot be more than mega 6 max bet!',
                },
                mega6MaxBet: {
                    error: () => Number(mega6MinBet) > Number(mega6MaxBet),
                    value: mega6MaxBet,
                    set: setMega6MaxBet,
                    message: 'Mega 6 max bet cannot be less than mega 6 min bet!',
                },
            },
            tiger: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: t('manager.minBetError'),
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: t('manager.maxBetError'),
                },
                tieMinBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMinBet,
                    set: setTieMinBet,
                    message: t('manager.tieMinBetError'),
                },
                tieMaxBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMaxBet,
                    set: setTieMaxBet,
                    message: t('manager.tieMaxBetError'),
                },
                tigerMinBet: {
                    error: () => Number(tigerMinBet) > Number(tigerMaxBet),
                    value: tigerMinBet,
                    set: setTigerMinBet,
                    message: 'Tiger min bet cannot be more than tiger max bet!',
                },
                tigerMaxBet: {
                    error: () => Number(tigerMinBet) > Number(tigerMaxBet),
                    value: tigerMaxBet,
                    set: setTigerMaxBet,
                    message: 'Tiger max bet cannot be less than tiger min bet!',
                },
                tigerTieMinBet: {
                    error: () => Number(tigerTieMinBet) > Number(tigerTieMaxBet),
                    value: tigerTieMinBet,
                    set: setTigerTieMinBet,
                    message: 'Tiger tie min bet cannot be more than tiger tie max bet!',
                },
                tigerTieMaxBet: {
                    error: () => Number(tigerTieMinBet) > Number(tigerTieMaxBet),
                    value: tigerTieMaxBet,
                    set: setTigerTieMaxBet,
                    message: 'Tiger tie max bet cannot be less than tiger tie min bet!',
                },
                tigerPairMinBet: {
                    error: () => Number(tigerPairMinBet) > Number(tigerPairMaxBet),
                    value: tigerPairMinBet,
                    set: setTigerPairMinBet,
                    message: 'Tiger pair min bet cannot be more than tiger pair max bet!',
                },
                tigerPairMaxBet: {
                    error: () => Number(tigerPairMinBet) > Number(tigerPairMaxBet),
                    value: tigerPairMaxBet,
                    set: setTigerPairMaxBet,
                    message: 'Tiger pair max bet cannot be less than tiger pair min bet!',
                },
                tigerBigMinBet: {
                    error: () => Number(tigerBigMinBet) > Number(tigerBigMaxBet),
                    value: tigerBigMinBet,
                    set: setTigerBigMinBet,
                    message: 'Tiger big min bet cannot be more than tiger big max bet!',
                },
                tigerBigMaxBet: {
                    error: () => Number(tigerBigMinBet) > Number(tigerBigMaxBet),
                    value: tigerBigMaxBet,
                    set: setTigerBigMaxBet,
                    message: 'Tiger big max bet cannot be less than tiger big min bet!',
                },
                tigerSmallMinBet: {
                    error: () => Number(tigerSmallMinBet) > Number(tigerSmallMaxBet),
                    value: tigerSmallMinBet,
                    set: setTigerSmallMinBet,
                    message: 'Tiger small min bet cannot be more than tiger small max bet!',
                },
                tigerSmallMaxBet: {
                    error: () => Number(tigerSmallMinBet) > Number(tigerSmallMaxBet),
                    value: tigerSmallMaxBet,
                    set: setTigerSmallMaxBet,
                    message: 'Tiger small max bet cannot be less than tiger small min bet!',
                },
            },
        },
        blackjack: {
            standard: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: t('manager.minBetError'),
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: t('manager.maxBetError'),
                },
            },
            anyPairSlot: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: 'Base min bet cannot be more than base max bet!',
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: 'Base max bet cannot be less than base min bet!',
                },
                anyPairMinBet: {
                    error: () =>
                        Number(anyPairMinBet) > Number(anyPairMaxBet) ||
                        !anyPairMinBet ||
                        (anyPairMinBet.trim && anyPairMinBet.trim().length === 0),
                    value: anyPairMinBet,
                    set: setAnyPairMinBet,
                    message: 'Any pair min bet cannot be more than any pair max bet!',
                },
                anyPairMaxBet: {
                    error: () =>
                        Number(anyPairMinBet) > Number(anyPairMaxBet) ||
                        !anyPairMaxBet ||
                        (anyPairMaxBet.trim && anyPairMaxBet.trim().length === 0),
                    value: anyPairMaxBet,
                    set: setAnyPairMaxBet,
                    message: 'Any pair max bet cannot be less than any pair min bet!',
                },
                anyPairAnyPlayerMinBet: {
                    error: () =>
                        Number(anyPairAnyPlayerMinBet) > Number(anyPairAnyPlayerMaxBet) ||
                        !anyPairAnyPlayerMinBet ||
                        (anyPairAnyPlayerMinBet.trim && anyPairAnyPlayerMinBet.trim().length === 0),
                    value: anyPairAnyPlayerMinBet,
                    set: setAnyPairAnyPlayerMinBet,
                    message: 'Any pair any player min bet cannot be more than any pair any player max bet!',
                },
                anyPairAnyPlayerMaxBet: {
                    error: () =>
                        Number(anyPairAnyPlayerMinBet) > Number(anyPairAnyPlayerMaxBet) ||
                        !anyPairAnyPlayerMaxBet ||
                        (anyPairAnyPlayerMaxBet.trim && anyPairAnyPlayerMaxBet.trim().length === 0),
                    value: anyPairAnyPlayerMaxBet,
                    set: setAnyPairAnyPlayerMaxBet,
                    message: 'Any pair any player max bet cannot be less than any pair any player min bet!',
                },
            },
        },
        roulette: {
            standard: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: t('manager.minBetError'),
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: t('manager.maxBetError'),
                },
            },
        },
        sicbo: {
            standard: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: t('manager.minBetError'),
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: t('manager.maxBetError'),
                },
            },
        },
        war: {
            etg: {
                minBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !minBet || (minBet.trim && minBet.trim().length === 0),
                    value: minBet,
                    set: setMinBet,
                    message: 'Ante min bet cannot be more than ante max bet!',
                },
                maxBet: {
                    error: () =>
                        Number(minBet) > Number(maxBet) || !maxBet || (maxBet.trim && maxBet.trim().length === 0),
                    value: maxBet,
                    set: setMaxBet,
                    message: 'Ante max bet cannot be less than ante min bet!',
                },
                tieMinBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMinBet,
                    set: setTieMinBet,
                    message: t('manager.tieMinBetError'),
                },
                tieMaxBet: {
                    error: () => Number(tieMinBet) > Number(tieMaxBet),
                    value: tieMaxBet,
                    set: setTieMaxBet,
                    message: t('manager.tieMaxBetError'),
                },
            },
        },
    }

    const update2 = (fields, editing, newData) => {
        const availableFields =
            editing.length > 0 ? Object.keys(fields).filter((key) => editing.indexOf(key) === -1) : Object.keys(fields)
        availableFields.forEach((key) =>
            fields[key].value !== newData.data[key] && typeof newData.data[key] !== 'undefined'
                ? fields[key].set(newData.data[key])
                : fields[key].value === newData.data[key]
                ? null
                : fields[key].set('')
        )
    }

    const validation = validate(fields)
    const betsValidation = validateWithTypeAndVariant(betsFields, device.data.gameType, device.data.gameVariant)
    validation.error = betsValidation.error ? true : validation.error
    validation.errors = { ...validation.errors, ...betsValidation.errors }

    if (device.updated !== updated || device.created !== created || device.index !== devIndex) {
        update2(fields, [], device)
        updateWithTypeAndVariant(betsFields, [], device, device.data.gameType, device.data.gameVariant, 'bets')
        setUpdated(device.updated)
        setCreated(device.created)
        setDevIndex(device.index)
        setLoading(false)
        closeModal()
    }

    const ApiDeckChange = async (address, cb) => {
        const response = await fetch('http://' + address + '/baccarat/deck')
        if (response.status === 200) {
            cb()
        } else {
            console.log('Something went wrong with forced deck change:', response)
        }
    }

    const send = async () => {
        if (!validation.error) {
            setLoadingChange(true)
            setLoading(true)

            const getNewBetsObj = (gameType, gameVariant) => {
                const betsObj = device.data.bets ? cloneDeep(device).data.bets : defaultValues.bets
                const betsObjByVariant = betsObj[gameType][gameVariant]

                betsObjByVariant.minBet = Number(minBet)
                betsObjByVariant.maxBet = Number(maxBet)

                if (gameType === 'baccarat' || gameType === 'war') {
                    betsObjByVariant.tieMinBet = Number(tieMinBet)
                    betsObjByVariant.tieMaxBet = Number(tieMaxBet)
                }

                if (gameType === 'baccarat' && gameVariant !== 'tiger') {
                    betsObjByVariant.pairMinBet = Number(pairMinBet)
                    betsObjByVariant.pairMaxBet = Number(pairMaxBet)
                    betsObjByVariant.naturalMinBet = Number(naturalMinBet)
                    betsObjByVariant.naturalMaxBet = Number(naturalMaxBet)
                }

                if (gameType === 'baccarat' && (gameVariant === 'lucky6' || gameVariant === 'lucky6Slot')) {
                    betsObjByVariant.lucky6MinBet = Number(lucky6MinBet)
                    betsObjByVariant.lucky6MaxBet = Number(lucky6MaxBet)
                }

                if (gameType === 'baccarat' && gameVariant === 'mega6') {
                    betsObjByVariant.mega6MinBet = Number(mega6MinBet)
                    betsObjByVariant.mega6MaxBet = Number(mega6MaxBet)
                }

                if (gameType === 'baccarat' && gameVariant === 'tiger') {
                    betsObjByVariant.tigerMinBet = Number(tigerMinBet)
                    betsObjByVariant.tigerMaxBet = Number(tigerMaxBet)
                    betsObjByVariant.tigerTieMinBet = Number(tigerTieMinBet)
                    betsObjByVariant.tigerTieMaxBet = Number(tigerTieMaxBet)
                    betsObjByVariant.tigerPairMinBet = Number(tigerPairMinBet)
                    betsObjByVariant.tigerPairMaxBet = Number(tigerPairMaxBet)
                    betsObjByVariant.tigerBigMinBet = Number(tigerBigMinBet)
                    betsObjByVariant.tigerBigMaxBet = Number(tigerBigMaxBet)
                    betsObjByVariant.tigerSmallMinBet = Number(tigerSmallMinBet)
                    betsObjByVariant.tigerSmallMaxBet = Number(tigerSmallMaxBet)
                }

                if (gameType === 'blackjack' && gameVariant === 'anyPairSlot') {
                    betsObjByVariant.anyPairMinBet = Number(anyPairMinBet)
                    betsObjByVariant.anyPairMaxBet = Number(anyPairMaxBet)
                    betsObjByVariant.anyPairAnyPlayerMinBet = Number(anyPairAnyPlayerMinBet)
                    betsObjByVariant.anyPairAnyPlayerMaxBet = Number(anyPairAnyPlayerMaxBet)
                }

                return betsObj
            }

            try {
                const dataToSend = calculateMinBetColor({
                    ...device.data,
                    open: tableOn,
                    bets: getNewBetsObj(device.data.gameType, device.data.gameVariant),
                    minBetColor: getMinBetColor(
                        minBet,
                        device.data.colorThresholds ? device.data.colorThresholds : defaultValues.colorThresholds
                    ),
                })
                await publish(dataToSend)
                if (!device.data.open && dataToSend.open) {
                    //when turning table on from off state, force a trendboard wipe with deck change signal
                    ApiDeckChange(device.data.ip + ':' + get(device, 'data.port', '3099'), () =>
                        console.log('---Forced deck change succcesful---')
                    )
                }

                console.log(dataToSend)
            } catch (e) {
                console.log('failed to send data: ', e)
                // TODO: show the error
                setLoading(false)
            }
            setChanged(false)
        }
    }

    if (!device) {
        return <LinearProgress />
    }

    function closeModal() {
        setModalOpen(false)
        setLoadingChange(false)
    }

    return (
        <PerfectScrollbar>
            <div
                className={s.tableDetailsForm}
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box width='80%' padding='2.5rem 0' display='flex' flexDirection='column' gap='1.25rem'>
                    {changed && validation.error && (
                        <div style={{ width: '100%' }}>
                            <SnackbarContent
                                className={s.warning}
                                message={Object.keys(validation.errors).map((error, key) =>
                                    validation.errors[error] !== '' ? (
                                        <Typography key={key} component='p' className={s.warningText}>
                                            <WarningIcon className={s.warningIcon} /> {validation.errors[error]}
                                        </Typography>
                                    ) : null
                                )}
                            />
                        </div>
                    )}

                    <Box display='flex'>
                        <Typography variant='h6' sx={{ color: 'rgb(204, 204, 204)', textTransform: 'uppercase' }}>
                            Table is:&nbsp;
                        </Typography>
                        <Typography variant='h6' sx={{ color: 'white', fontWeight: 'bold' }}>
                            {tableOn ? 'OPEN' : 'CLOSED'}
                        </Typography>
                    </Box>

                    <Box display='flex'>
                        <Typography variant='h6' sx={{ color: 'rgb(204, 204, 204)', textTransform: 'uppercase' }}>
                            Table (Device) Name:&nbsp;
                        </Typography>
                        <Typography variant='h6' sx={{ color: 'white', fontWeight: 'bold' }}>
                            {name ? name : '-'}
                        </Typography>
                    </Box>

                    <Box display='flex'>
                        <Typography variant='h6' sx={{ color: 'rgb(204, 204, 204)', textTransform: 'uppercase' }}>
                            Display Table Number:&nbsp;
                        </Typography>
                        <Typography variant='h6' sx={{ color: 'white', fontWeight: 'bold' }}>
                            {tableNum ? tableNum : '-'}
                        </Typography>
                    </Box>

                    <Box display='flex'>
                        <Typography variant='h6' sx={{ color: 'rgb(204, 204, 204)', textTransform: 'uppercase' }}>
                            Game Type:&nbsp;
                        </Typography>
                        <Typography variant='h6' sx={{ color: 'white', fontWeight: 'bold' }}>
                            {gameType ? getGameTypeTranslation(gameType) : '-'}
                        </Typography>
                    </Box>

                    <Box display='flex'>
                        <Typography variant='h6' sx={{ color: 'rgb(204, 204, 204)', textTransform: 'uppercase' }}>
                            Game Variant:&nbsp;
                        </Typography>
                        <Typography variant='h6' sx={{ color: 'white', fontWeight: 'bold' }}>
                            {gameVariant ? getGameVariantTranslation(gameVariant) : '-'}
                        </Typography>
                    </Box>

                    <Box display='flex'>
                        <Typography variant='h6' sx={{ color: 'rgb(204, 204, 204)', textTransform: 'uppercase' }}>
                            IP Address:&nbsp;
                        </Typography>
                        <Typography variant='h6' sx={{ color: 'white', fontWeight: 'bold' }}>
                            {ip ? ip : '-'}
                        </Typography>
                    </Box>

                    <Box display='flex'>
                        <Typography variant='h6' sx={{ color: 'rgb(204, 204, 204)', textTransform: 'uppercase' }}>
                            Closed Table Feed:&nbsp;
                        </Typography>
                        <Typography variant='h6' sx={{ color: 'white', fontWeight: 'bold' }}>
                            {chosenStream ? chosenStream : '-'}
                        </Typography>
                    </Box>

                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <label style={{ color: '#ccc' }}>
                                {((device.data.gameType !== 'blackjack' && device.data.gameType !== 'war') ||
                                    (device.data.gamType === 'blackjack' &&
                                        device.data.gameVariant === 'standard')) && <>{t('manager.minBet')}</>}
                                {device.data.gameType === 'blackjack' && device.data.gameVariant === 'anyPairSlot' && (
                                    <>BASE MIN BET</>
                                )}
                                {device.data.gameType === 'war' && device.data.gameVariant === 'etg' && (
                                    <>ANTE MIN BET</>
                                )}
                            </label>
                            <input
                                onChange={(e) => {
                                    setMinBet(e.target.value.replace(/\D/g, ''))
                                    setChanged(true)
                                }}
                                disabled={loading}
                                value={formatCashString(minBet)}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <label style={{ color: '#ccc' }}>
                                {((device.data.gameType !== 'blackjack' && device.data.gameType !== 'war') ||
                                    (device.data.gamType === 'blackjack' &&
                                        device.data.gameVariant === 'standard')) && <>{t('manager.maxBet')}</>}
                                {device.data.gameType === 'blackjack' && device.data.gameVariant === 'anyPairSlot' && (
                                    <>BASE MAX BET</>
                                )}
                                {device.data.gameType === 'war' && device.data.gameVariant === 'etg' && (
                                    <>ANTE MAX BET</>
                                )}
                            </label>
                            <input
                                onChange={(e) => {
                                    setMaxBet(e.target.value.replace(/\D/g, ''))
                                    setChanged(true)
                                }}
                                disabled={loading}
                                value={formatCashString(maxBet)}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        ></div>
                    </div>

                    {(device.data.gameType === 'baccarat' || device.data.gameType === 'war') &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canTieBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>{t('manager.tieMinBet')}</label>
                                    <input
                                        onChange={(e) => {
                                            setTieMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tieMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>{t('manager.tieMaxBet')}</label>
                                    <input
                                        onChange={(e) => {
                                            setTieMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tieMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        device.data.gameVariant !== 'tiger' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canNatBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>{t('manager.natural89MinBet')}</label>
                                    <input
                                        onChange={(e) => {
                                            setNaturalMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(naturalMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>{t('manager.natural89MaxBet')}</label>
                                    <input
                                        onChange={(e) => {
                                            setNaturalMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(naturalMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        device.data.gameVariant !== 'tiger' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canPairBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>{t('manager.pairMinBet')}</label>
                                    <input
                                        onChange={(e) => {
                                            setPairMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(pairMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>{t('manager.pairMaxBet')}</label>
                                    <input
                                        onChange={(e) => {
                                            setPairMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(pairMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        (device.data.gameVariant === 'lucky6' || device.data.gameVariant === 'lucky6Slot') &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canLucky6 && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>{t('manager.lucky6MinBet')}</label>
                                    <input
                                        onChange={(e) => {
                                            setLucky6MinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(lucky6MinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>{t('manager.lucky6MaxBet')}</label>
                                    <input
                                        onChange={(e) => {
                                            setLucky6MaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(lucky6MaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        device.data.gameVariant === 'mega6' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canMega6 && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>MEGA 6 MIN BET</label>
                                    <input
                                        onChange={(e) => {
                                            setMega6MinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(mega6MinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>MEGA 6 MAX BET</label>
                                    <input
                                        onChange={(e) => {
                                            setMega6MaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(mega6MaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        device.data.gameVariant === 'tiger' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canTigerBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER MIN BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER MAX BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        device.data.gameVariant === 'tiger' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canTigerTieBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER TIE MIN BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerTieMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerTieMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER TIE MAX BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerTieMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerTieMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        device.data.gameVariant === 'tiger' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canTigerPairBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER PAIR MIN BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerPairMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerPairMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER PAIR MAX BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerPairMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerPairMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        device.data.gameVariant === 'tiger' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canTigerBigBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER BIG MIN BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerBigMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerBigMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER BIG MAX BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerBigMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerBigMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'baccarat' &&
                        device.data.gameVariant === 'tiger' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canTigerSmallBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER SMALL MIN BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerSmallMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerSmallMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>TIGER SMALL MAX BET</label>
                                    <input
                                        onChange={(e) => {
                                            setTigerSmallMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(tigerSmallMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'blackjack' &&
                        device.data.gameVariant === 'anyPairSlot' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canAnyPairBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>ANY PAIR MIN BET</label>
                                    <input
                                        onChange={(e) => {
                                            setAnyPairMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(anyPairMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>ANY PAIR MAX BET</label>
                                    <input
                                        onChange={(e) => {
                                            setAnyPairMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(anyPairMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {device.data.gameType === 'blackjack' &&
                        device.data.gameVariant === 'anyPairSlot' &&
                        device.data.bets[device.data.gameType][device.data.gameVariant].canAnyPairAnyPlayerBet && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>ANY PAIR ANY PLAYER MIN BET</label>
                                    <input
                                        onChange={(e) => {
                                            setAnyPairAnyPlayerMinBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(anyPairAnyPlayerMinBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label style={{ color: '#ccc' }}>ANY PAIR ANY PLAYER MAX BET</label>
                                    <input
                                        onChange={(e) => {
                                            setAnyPairAnyPlayerMaxBet(e.target.value.replace(/\D/g, ''))
                                            setChanged(true)
                                        }}
                                        disabled={loading}
                                        value={formatCashString(anyPairAnyPlayerMaxBet)}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                ></div>
                            </div>
                        )}

                    {changed && !validation.error && (
                        <div style={{ position: 'fixed', bottom: 10, right: 20 }}>
                            <Button
                                variant='contained'
                                color='primary'
                                style={{ color: '#fff', fontSize: 12 }}
                                onClick={() => setModalOpen(true)}
                            >
                                <h1>{t('manager.confirmChanges')}</h1>
                            </Button>
                        </div>
                    )}
                </Box>

                <Modal
                    className={s.modalContainer}
                    open={modalOpen}
                    onClose={(e) => {
                        setModalOpen(false)
                    }}
                >
                    <Fade in={modalOpen}>
                        <div className={s.modal}>
                            <div className={s.modalHeader}>
                                <h1>{t('manager.confirmChanges')}</h1>
                                {loadingChange && <LinearProgress />}
                            </div>

                            {!loadingChange && (
                                <div className={s.modalPrompt}>
                                    <h2>{t('manager.confirmChangesPrompt')}</h2>
                                    <div className={s.modalButtonRow}>
                                        <Button variant='contained' onClick={() => closeModal()}>
                                            {t('global.cancel')}
                                        </Button>
                                        <Button variant='contained' color='primary' onClick={() => send()}>
                                            {t('global.confirm')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Fade>
                </Modal>
            </div>
        </PerfectScrollbar>
    )
}
export default Configurations
