import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { withStyles, makeStyles } from '@mui/styles'
import { usePublish, useSubscribe } from '../../api'
import { screenerIP } from '../../config'
import { DeviceSelect } from '../../forms'

const CustomTab = withStyles({
    root: {
        backgroundColor: '#343434',
        borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
    },
    selected: {
        backgroundColor: 'rgb(0, 161, 255)',
    },
})(Tab)

const rootStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        padding: '2rem',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
    },
    warningIcon: {
        color: '#f0cf81',
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: '100',
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.2em',
    },
    toggleWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))

const Screener = () => {
    const styles = rootStyles({})
    const [settings, settingsSocket] = useSubscribe(screenerIP + '/settings')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const setSettings = usePublish(screenerIP + '/settings')
    const [deviceA, setDeviceA] = useState('')
    const [deviceB, setDeviceB] = useState('')
    const [deviceC, setDeviceC] = useState('')
    const [publishing, setPublishing] = useState(false)

    const active = settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN


    useEffect(() => {
        if (settings?.data?.deviceA !== undefined) {
            setDeviceA(settings.data.deviceA)
        }
        if (settings?.data?.deviceB !== undefined) {
            setDeviceB(settings.data.deviceB)
        }
        if (settings?.data?.deviceC !== undefined) {
            setDeviceC(settings.data.deviceC)
        }
    }, [settings])

    const devicesFiltered = devices ? devices.filter((e) => e.data.type === 'game') : []


    const submitForm = () => {
        setPublishing(true)

        try {
            setSettings({
                deviceA,
                deviceB,
                deviceC,
            })
        } catch (e) {
            console.warn('failed to submit form: ', e)
        } finally {
            setPublishing(false)
        }
    }

    if (!active ||
        !settings ||
        !devices) {
        return <LinearProgress />
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <form
                    className={styles.form}
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault()
                    }}
                    autoComplete='off'
                >
                    <Box display='flex' flexDirection='column' gap='1.25rem'>
                        <Box>
                            <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                                Settings
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                Select tab to configure the corresponding features of the application
                            </Typography>
                        </Box>
                        <Tabs
                            sx={{
                                background: '#2a2a2a',
                                borderBottom: '2px solid rgb(0, 161, 255)',
                            }}
                            value={3}
                            textColor='inherit'
                            indicatorColor='rgb(0, 161, 255)'
                        >
                            <CustomTab
                                label='Default Playlist'
                                component={Link}
                                to={'/xstadium/settings/defaultplaylist'}
                            />
                            <CustomTab label='Support' component={Link} to={'/xstadium/settings/support'} />
                            <CustomTab label='Volumes' component={Link} to={'/xstadium/settings/volumes'} />
                            <CustomTab label='Device Animations' component={Link} to={'/xstadium/settings/screener'} />
                        </Tabs>
                        <Box width='50%' display='flex' flexDirection='column' gap='1.5rem'>
                            <Box>
                                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                    Device A
                                </Typography>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <DeviceSelect devices={devicesFiltered}
                                        name={'deviceA'}
                                        value={deviceA}
                                        setValue={setDeviceA}
                                        publishing={publishing} />
                                </Stack>
                            </Box>
                            <Box>
                                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                    Device B
                                </Typography>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <DeviceSelect devices={devicesFiltered}
                                        name={'deviceB'}
                                        value={deviceB}
                                        setValue={setDeviceB}
                                        publishing={publishing} />
                                </Stack>
                            </Box>
                            <Box>
                                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                    Device C
                                </Typography>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <DeviceSelect devices={devicesFiltered}
                                        name={'deviceC'}
                                        value={deviceC}
                                        setValue={setDeviceC}
                                        publishing={publishing} />
                                </Stack>
                            </Box>
                        </Box>


                        <Box>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                onClick={submitForm}
                                disabled={publishing}
                            >
                                Save changes
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Grid>
        </Grid>
    )
}

export default Screener