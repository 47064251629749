import React from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const SpinnerStyles = makeStyles({
  root: {
    position: "relative",
    height: 24,
  },
  top: {
    color: "#eef3fd",
  },
  bottom: {
    color: "#6798e5",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
});

const Spinner = (props) => {
  const classes = SpinnerStyles();
  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={24}
        thickness={4}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={24}
        thickness={4}
        {...props}
      />
    </div>
  );
};

export default Spinner;

export const CustomSpinner = (props) => {
  const classes = SpinnerStyles();
  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        {...props.top}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        {...props.bottom}
      />
    </div>
  );
};
