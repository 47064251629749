import React, { useState } from 'react'
import { cloneDeep, keys, startCase } from 'lodash'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'

import { useSubscribe, usePublish } from '../../api'
import { validate } from '../../forms'
import { formatCashString, formatCountString } from '../../xtable/Formats'
import AlertDialog from '../components/AlertDialog'
import { defaultValues } from '../devices/defaultValues'
import { getMinBetColor } from '../devices/minBetColor/color'

const FieldRow = ({ id, title, state, onChange, disabled, error, helperText }) => {
    return (
        <Box width='13rem'>
            <Typography>{title}</Typography>
            <TextField
                id={id}
                name={id}
                type='text'
                size='small'
                fullWidth
                variant='outlined'
                value={state !== '' ? formatCountString(state) : state}
                onChange={onChange}
                disabled={disabled}
                error={error}
                helperText={helperText}
                autoComplete='off'
            />
        </Box>
    )
}

const initialBatch = {
    minBet: {
        value: '',
        changed: false,
    },
    maxBet: {
        value: '',
        changed: false,
    },
    tieMinBet: {
        value: '',
        changed: false,
    },
    tieMaxBet: {
        value: '',
        changed: false,
    },
    pairMinBet: {
        value: '',
        changed: false,
    },
    pairMaxBet: {
        value: '',
        changed: false,
    },
    naturalMinBet: {
        value: '',
        changed: false,
    },
    naturalMaxBet: {
        value: '',
        changed: false,
    },
    lucky6MinBet: {
        value: '',
        changed: false,
    },
    lucky6MaxBet: {
        value: '',
        changed: false,
    },
    mega6MinBet: {
        value: '',
        changed: false,
    },
    mega6MaxBet: {
        value: '',
        changed: false,
    },
    tigerMinBet: {
        value: '',
        changed: false,
    },
    tigerMaxBet: {
        value: '',
        changed: false,
    },
    tigerTieMinBet: {
        value: '',
        changed: false,
    },
    tigerTieMaxBet: {
        value: '',
        changed: false,
    },
    tigerPairMinBet: {
        value: '',
        changed: false,
    },
    tigerPairMaxBet: {
        value: '',
        changed: false,
    },
    tigerBigMinBet: {
        value: '',
        changed: false,
    },
    tigerBigMaxBet: {
        value: '',
        changed: false,
    },
    tigerSmallMinBet: {
        value: '',
        changed: false,
    },
    tigerSmallMaxBet: {
        value: '',
        changed: false,
    },
    anyPairMinBet: {
        value: '',
        changed: false,
    },
    anyPairMaxBet: {
        value: '',
        changed: false,
    },
    anyPairAnyPlayerMinBet: {
        value: '',
        changed: false,
    },
    anyPairAnyPlayerMaxBet: {
        value: '',
        changed: false,
    },
    tripleDiceLimit: {
        value: '',
        changed: false,
    },
    doubleDiceLimit: {
        value: '',
        changed: false,
    },
}

const Tables = () => {
    const navigate = useNavigate()

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [currentTab, setCurrentTab] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [deviceIndexes, setDeviceIndexes] = useState([])
    const [batch, setBatch] = useState(initialBatch)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
    })

    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')

    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN

    if (!active || !zones || !pits || !tables || !devices) return <LinearProgress />

    const username = window.localStorage.getItem('account') ?? '-'

    const getTable = (deviceIndex) => {
        const match = tables && tables.filter((table) => table.index === deviceIndex)
        return match ? match[0] : {}
    }

    const getPit = (deviceIndex) => {
        const match = pits && pits.filter((table) => table.index === getTable(deviceIndex).data.containerIndex)
        return match ? match[0] : {}
    }

    const getZone = (deviceIndex) => {
        const match = zones && zones.filter((table) => table.index === getPit(deviceIndex).data.containerIndex)
        return match ? match[0] : {}
    }

    const dateDisplay = (time) => moment.unix(time / 1000000000).format('YYYY-MM-DD')

    const rows = devices
        ? devices
            .filter((device) => device.data.type === 'game')
            .filter((device) => JSON.stringify(device).indexOf(searchTerm) >= 0)
            .map((device) => {
                return {
                    id: device.index ? device.index : '-',
                    containerIndex: device.data.containerIndex ? device.data.containerIndex : '-',
                    status: device.data.open,
                    tableName: device.data.tableNum ? device.data.tableNum : '-',
                    gameType: device.data.gameType ? device.data.gameType : '-',
                    gameVariant: device.data.gameVariant ? device.data.gameVariant : '-',
                    minbet: device.data.bets
                        ? device.data.bets[device.data.gameType][device.data.gameVariant].minBet
                        : '-',
                    minBetColor: device.data.minBetColor ? device.data.minBetColor : '-',
                    maxbet: device.data.bets
                        ? device.data.bets[device.data.gameType][device.data.gameVariant].maxBet
                        : '-',
                    trendboardLayout: device.data.layout ? device.data.layout : '-',
                    zone: device.data.containerIndex ? getZone(device.data.containerIndex).data.name : '-',
                    pit: device.data.containerIndex ? getPit(device.data.containerIndex).data.name : '-',
                    deviceName: device.data.name ? device.data.name : '-',
                    lastUpdatedOn: device.updated ? device.updated : device.created,
                    lastUpdatedBy: device.data.updatedBy ? device.data.updatedBy : '-',
                }
            })
        : []

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            color: params.value === true ? '#00ff00' : '#ff1111',
                        }}
                    >
                        {params.value === true ? 'Open' : 'Closed'}
                    </Box>
                )
            },
        },
        { field: 'tableName', headerName: 'Table Name', width: 115 },
        {
            field: 'gameType',
            headerName: 'Game Type',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{startCase(params.value)}</>}</>
            },
            width: 115,
        },
        {
            field: 'gameVariant',
            headerName: 'Game Variant',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{startCase(params.value)}</>}</>
            },
            width: 115,
        },
        {
            field: 'minbet',
            headerName: 'Min Bet',
            renderCell: (params) => {
                return (
                    <Box display='flex' alignItems='center' gap='.5rem'>
                        {params.row.minBetColor && <CircleIcon sx={{ color: params.row.minBetColor }} />}
                        <>{formatCashString(params.value)}</>
                    </Box>
                )
            },
            width: 180,
        },
        {
            field: 'maxbet',
            headerName: 'Max Bet',
            renderCell: (params) => {
                return <>{formatCashString(params.value)}</>
            },
            width: 180,
        },
        {
            field: 'trendboardLayout',
            headerName: 'Trendboard Layout',
            width: 150,
        },
        {
            field: 'zone',
            headerName: 'Zone',
            width: 125,
        },
        {
            field: 'pit',
            headerName: 'Pit',
            width: 125,
        },
        {
            field: 'deviceName',
            headerName: 'Device Name',
            width: 150,
        },
        {
            field: 'lastUpdatedOn',
            headerName: 'Last Updated On',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{dateDisplay(params.value)}</>}</>
            },
            width: 145,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 145,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => navigate(`/xtrend/device/${params.row.id}`)}>
                        <EditIcon />
                    </IconButton>
                )
            },
        },
    ]

    const fields = {
        minBet: {
            error: () =>
                batch.minBet.changed && batch.maxBet.changed && Number(batch.minBet.value) > Number(batch.maxBet.value),
            message: 'Min bet cannot be more than max bet',
        },
        maxBet: {
            error: () =>
                batch.minBet.changed && batch.maxBet.changed && Number(batch.minBet.value) > Number(batch.maxBet.value),
            message: 'Max bet cannot be less than min bet',
        },
        tieMinBet: {
            error: () =>
                batch.tieMinBet.changed &&
                batch.tieMaxBet.changed &&
                Number(batch.tieMinBet.value) > Number(batch.tieMaxBet.value),
            message: 'Tie min bet cannot be more than tie max bet',
        },
        tieMaxBet: {
            error: () =>
                batch.tieMinBet.changed &&
                batch.tieMaxBet.changed &&
                Number(batch.tieMinBet.value) > Number(batch.tieMaxBet.value),
            message: 'Tie max bet cannot be less than tie min bet',
        },
        pairMinBet: {
            error: () =>
                batch.pairMinBet.changed &&
                batch.pairMaxBet.changed &&
                Number(batch.pairMinBet.value) > Number(batch.pairMaxBet.value),
            message: 'Pair min bet cannot be more than tie max bet',
        },
        pairMaxBet: {
            error: () =>
                batch.pairMinBet.changed &&
                batch.pairMaxBet.changed &&
                Number(batch.pairMinBet.value) > Number(batch.pairMaxBet.value),
            message: 'Pair max bet cannot be less than tie min bet',
        },
        naturalMinBet: {
            error: () =>
                batch.naturalMinBet.changed &&
                batch.naturalMaxBet.changed &&
                Number(batch.naturalMinBet.value) > Number(batch.naturalMaxBet.value),
            message: 'Natural min bet cannot be more than tie max bet',
        },
        naturalMaxBet: {
            error: () =>
                batch.naturalMinBet.changed &&
                batch.naturalMaxBet.changed &&
                Number(batch.naturalMinBet.value) > Number(batch.naturalMaxBet.value),
            message: 'Natural max bet cannot be less than tie min bet',
        },
        lucky6MinBet: {
            error: () =>
                batch.lucky6MinBet.changed &&
                batch.lucky6MaxBet.changed &&
                Number(batch.lucky6MinBet.value) > Number(batch.lucky6MaxBet.value),
            message: 'Lucky 6 min bet cannot be more than Lucky 6 max bet',
        },
        lucky6MaxBet: {
            error: () =>
                batch.lucky6MinBet.changed &&
                batch.lucky6MaxBet.changed &&
                Number(batch.lucky6MinBet.value) > Number(batch.lucky6MaxBet.value),
            message: 'Lucky 6 max bet cannot be less than Lucky 6 min bet',
        },
        mega6MinBet: {
            error: () =>
                batch.mega6MinBet.changed &&
                batch.mega6MaxBet.changed &&
                Number(batch.mega6MinBet.value) > Number(batch.mega6MaxBet.value),
            message: 'Mega 6 min bet cannot be more than Mega 6 max bet',
        },
        mega6MaxBet: {
            error: () =>
                batch.mega6MinBet.changed &&
                batch.mega6MaxBet.changed &&
                Number(batch.mega6MinBet.value) > Number(batch.mega6MaxBet.value),
            message: 'Mega 6 max bet cannot be less than Mega 6 min bet',
        },
        tigerMinBet: {
            error: () =>
                batch.tigerMinBet.changed &&
                batch.tigerMaxBet.changed &&
                Number(batch.tigerMinBet.value) > Number(batch.tigerMaxBet.value),
            message: 'Tiger min bet cannot be more than Tiger max bet',
        },
        tigerMaxBet: {
            error: () =>
                batch.tigerMinBet.changed &&
                batch.tigerMaxBet.changed &&
                Number(batch.tigerMinBet.value) > Number(batch.tigerMaxBet.value),
            message: 'Tiger max bet cannot be less than Tiger min bet',
        },
        tigerTieMinBet: {
            error: () =>
                batch.tigerTieMinBet.changed &&
                batch.tigerTieMaxBet.changed &&
                Number(batch.tigerTieMinBet.value) > Number(batch.tigerTieMaxBet.value),
            message: 'Tiger tie min bet cannot be more than Tiger tie max bet',
        },
        tigerTieMaxBet: {
            error: () =>
                batch.tigerTieMinBet.changed &&
                batch.tigerTieMaxBet.changed &&
                Number(batch.tigerTieMinBet.value) > Number(batch.tigerTieMaxBet.value),
            message: 'Tiger tie max bet cannot be less than Tiger tie min bet',
        },
        tigerPairMinBet: {
            error: () =>
                batch.tigerPairMinBet.changed &&
                batch.tigerPairMaxBet.changed &&
                Number(batch.tigerPairMinBet.value) > Number(batch.tigerPairMaxBet.value),
            message: 'Tiger pair min bet cannot be more than Tiger pair max bet',
        },
        tigerPairMaxBet: {
            error: () =>
                batch.tigerPairMinBet.changed &&
                batch.tigerPairMaxBet.changed &&
                Number(batch.tigerPairMinBet.value) > Number(batch.tigerPairMaxBet.value),
            message: 'Tiger pair max bet cannot be less than Tiger pair min bet',
        },
        tigerBigMinBet: {
            error: () =>
                batch.tigerBigMinBet.changed &&
                batch.tigerBigMaxBet.changed &&
                Number(batch.tigerBigMinBet.value) > Number(batch.tigerBigMaxBet.value),
            message: 'Tiger big min bet cannot be more than Tiger big max bet',
        },
        tigerBigMaxBet: {
            error: () =>
                batch.tigerBigMinBet.changed &&
                batch.tigerBigMaxBet.changed &&
                Number(batch.tigerBigMinBet.value) > Number(batch.tigerBigMaxBet.value),
            message: 'Tiger big max bet cannot be less than Tiger big min bet',
        },
        tigerSmallMinBet: {
            error: () =>
                batch.tigerSmallMinBet.changed &&
                batch.tigerSmallMaxBet.changed &&
                Number(batch.tigerSmallMinBet.value) > Number(batch.tigerSmallMaxBet.value),
            message: 'Tiger small min bet cannot be more than Tiger small max bet',
        },
        tigerSmallMaxBet: {
            error: () =>
                batch.tigerSmallMinBet.changed &&
                batch.tigerSmallMaxBet.changed &&
                Number(batch.tigerSmallMinBet.value) > Number(batch.tigerSmallMaxBet.value),
            message: 'Tiger small max bet cannot be less than Tiger small min bet',
        },
        anyPairMinBet: {
            error: () =>
                batch.anyPairMinBet.changed &&
                batch.anyPairMaxBet.changed &&
                Number(batch.anyPairMinBet.value) > Number(batch.anyPairMaxBet.value),
            message: 'Any pair min bet cannot be more than Any pair max bet',
        },
        anyPairMaxBet: {
            error: () =>
                batch.anyPairMinBet.changed &&
                batch.anyPairMaxBet.changed &&
                Number(batch.anyPairMinBet.value) > Number(batch.anyPairMaxBet.value),
            message: 'Any pair max bet cannot be less than Any pair min bet',
        },
        anyPairAnyPlayerMinBet: {
            error: () =>
                batch.anyPairAnyPlayerMinBet.changed &&
                batch.anyPairAnyPlayerMaxBet.changed &&
                Number(batch.anyPairAnyPlayerMinBet.value) > Number(batch.anyPairAnyPlayerMaxBet.value),
            message: 'Any pair any player min bet cannot be more than Any pair any player max bet',
        },
        anyPairAnyPlayerMaxBet: {
            error: () =>
                batch.anyPairAnyPlayerMinBet.changed &&
                batch.anyPairAnyPlayerMaxBet.changed &&
                Number(batch.anyPairAnyPlayerMinBet.value) > Number(batch.anyPairAnyPlayerMaxBet.value),
            message: 'Any pair any player max bet cannot be less than Any pair any player min bet',
        },
        tripleDiceLimit: {
            error: () => batch.tripleDiceLimit.changed && Number(batch.tripleDiceLimit.value) < 1,
            message: 'Triple dice limit cannot be less than 1',
        },
        doubleDiceLimit: {
            error: () => batch.doubleDiceLimit.changed && Number(batch.doubleDiceLimit.value) < 1,
            message: 'Double dice limit cannot be less than 1',
        },
    }

    const validation = validate(fields)

    const isSelectionIncludeDeviceWithGameType = (gameType) => {
        const match = deviceIndexes.map((di) => devices.filter((device) => device.index === di)).flat()
        return match.some((match) => match.data.gameType === gameType) ? true : false
    }

    const isSelectionIncludeDeviceWithGameVariant = (gameVariant) => {
        const match = deviceIndexes.map((di) => devices.filter((device) => device.index === di)).flat()
        return match.some((match) => match.data.gameVariant === gameVariant) ? true : false
    }

    const isSelectionNoSideBet = () => {
        const match = deviceIndexes.map((di) => devices.filter((device) => device.index === di)).flat()
        return match.every((match) => match.data.gameType === 'roulette' && match.data.gameVariant === 'standard') ||
            match.every((match) => match.data.gameType === 'blackjack' && match.data.gameVariant === 'standard') ||
            match.every(
                (match) =>
                    (match.data.gameType === 'blackjack' && match.data.gameVariant === 'standard') ||
                    (match.data.gameType === 'roulette' && match.data.gameVariant === 'standard')
            )
            ? true
            : false
    }

    const isMinMaxRuleMet = () => {
        let errorMessage = []

        deviceIndexes.map((deviceIndex) => {
            const match = devices.find((device) => device.index === deviceIndex)
            const changedFieldsKeys = Object.keys(batch).filter((key) => batch[key].changed)

            if (
                match &&
                changedFieldsKeys.includes('minBet') &&
                !changedFieldsKeys.includes('maxBet') &&
                batch.minBet.value > match.data.bets[match.data.gameType][match.data.gameVariant].maxBet
            ) {
                errorMessage.push(`${match.data.name}: Min bet cannot be greater than current max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('maxBet') &&
                !changedFieldsKeys.includes('minBet') &&
                batch.maxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].minBet
            ) {
                errorMessage.push(`${match.data.name}: Max bet cannot be less than current min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tieMinBet') &&
                !changedFieldsKeys.includes('tieMaxBet') &&
                batch.tieMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tieMaxBet
            ) {
                errorMessage.push(`${match.data.name}: Tie min bet cannot be greater than current tie max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tieMaxBet') &&
                !changedFieldsKeys.includes('tieMinBet') &&
                batch.tieMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tieMinBet
            ) {
                errorMessage.push(`${match.data.name}: Tie max bet cannot be less than current tie min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('pairMinBet') &&
                !changedFieldsKeys.includes('pairMaxBet') &&
                batch.pairMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].pairMaxBet
            ) {
                errorMessage.push(`${match.data.name}: Pair min bet cannot be greater than current pair max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('pairMaxBet') &&
                !changedFieldsKeys.includes('pairMinBet') &&
                batch.pairMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].pairMinBet
            ) {
                errorMessage.push(`${match.data.name}: Pair max bet cannot be less than current pair min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('naturalMinBet') &&
                !changedFieldsKeys.includes('naturalMaxBet') &&
                batch.naturalMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].naturalMaxBet
            ) {
                errorMessage.push(`${match.data.name}: Natural min bet cannot be greater than current natural max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('naturalMaxBet') &&
                !changedFieldsKeys.includes('naturalMinBet') &&
                batch.naturalMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].naturalMinBet
            ) {
                errorMessage.push(`${match.data.name}: Natural max bet cannot be less than current natural min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('lucky6MinBet') &&
                !changedFieldsKeys.includes('lucky6MaxBet') &&
                batch.lucky6MinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].lucky6MaxBet
            ) {
                errorMessage.push(`${match.data.name}: Lucky6 min bet cannot be greater than current lucky6 max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('lucky6MaxBet') &&
                !changedFieldsKeys.includes('lucky6MinBet') &&
                batch.lucky6MaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].lucky6MinBet
            ) {
                errorMessage.push(`${match.data.name}: Lucky6 max bet cannot be less than current lucky6 min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('mega6MinBet') &&
                !changedFieldsKeys.includes('mega6MaxBet') &&
                batch.mega6MinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].mega6MaxBet
            ) {
                errorMessage.push(`${match.data.name}: Mega6 min bet cannot be greater than current mega6 max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('mega6MaxBet') &&
                !changedFieldsKeys.includes('mega6MinBet') &&
                batch.mega6MaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].mega6MinBet
            ) {
                errorMessage.push(`${match.data.name}: Mega6 max bet cannot be less than current mega6 min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerMinBet') &&
                !changedFieldsKeys.includes('tigerMaxBet') &&
                batch.tigerMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerMaxBet
            ) {
                errorMessage.push(`${match.data.name}: Tiger min bet cannot be greater than current Tiger max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerMaxBet') &&
                !changedFieldsKeys.includes('tigerMinBet') &&
                batch.tigerMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerMinBet
            ) {
                errorMessage.push(`${match.data.name}: Tiger max bet cannot be less than current Tiger min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerTieMinBet') &&
                !changedFieldsKeys.includes('tigerTieMaxBet') &&
                batch.tigerTieMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerTieMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger tie min bet cannot be greater than current Tiger tie max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerTieMaxBet') &&
                !changedFieldsKeys.includes('tigerTieMinBet') &&
                batch.tigerTieMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerTieMinBet
            ) {
                errorMessage.push(`${match.data.name}: Tiger tie max bet cannot be less than current Tiger tie min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerPairMinBet') &&
                !changedFieldsKeys.includes('tigerPairMaxBet') &&
                batch.tigerPairMinBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].tigerPairMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger pair min bet cannot be greater than current Tiger pair max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerPairMaxBet') &&
                !changedFieldsKeys.includes('tigerPairMinBet') &&
                batch.tigerPairMaxBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].tigerPairMinBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger pair max bet cannot be less than current Tiger pair min bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerBigMinBet') &&
                !changedFieldsKeys.includes('tigerBigMaxBet') &&
                batch.tigerBigMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerBigMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger big min bet cannot be greater than current Tiger big max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerBigMaxBet') &&
                !changedFieldsKeys.includes('tigerBigMinBet') &&
                batch.tigerBigMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerBigMinBet
            ) {
                errorMessage.push(`${match.data.name}: Tiger big max bet cannot be less than current Tiger big min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerSmallMinBet') &&
                !changedFieldsKeys.includes('tigerSmallMaxBet') &&
                batch.tigerSmallMinBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].tigerSmallMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger small min bet cannot be greater than current Tiger small max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerSmallMaxBet') &&
                !changedFieldsKeys.includes('tigerSmallMinBet') &&
                batch.tigerSmallMaxBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].tigerSmallMinBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger small max bet cannot be less than current Tiger small min bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('anyPairMinBet') &&
                !changedFieldsKeys.includes('anyPairMaxBet') &&
                batch.anyPairMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].anyPairMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Any pair min bet cannot be greater than current Any pair max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('anyPairMaxBet') &&
                !changedFieldsKeys.includes('anyPairMinBet') &&
                batch.anyPairMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].anyPairMinBet
            ) {
                errorMessage.push(`${match.data.name}: Any pair max bet cannot be less than current Any pair min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('anyPairAnyPlayerMinBet') &&
                !changedFieldsKeys.includes('anyPairAnyPlayerMaxBet') &&
                batch.anyPairAnyPlayerMinBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].anyPairAnyPlayerMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Any pair any player min bet cannot be greater than current Any pair any player max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('anyPairAnyPlayerMaxBet') &&
                !changedFieldsKeys.includes('anyPairAnyPlayerMinBet') &&
                batch.anyPairAnyPlayerMaxBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].anyPairAnyPlayerMinBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Any pair any player max bet cannot be less than current Any pair any player min bet`
                )
            }

            return errorMessage
        })

        return errorMessage
    }

    const Apply = async (deviceIndex) => {
        const publish = usePublish('devices/' + deviceIndex)
        const device = devices.filter((device) => device.index === deviceIndex)[0]

        const getNewBetsObj = (gameType, gameVariant) => {
            const betsObj = device.data.bets ? cloneDeep(device).data.bets : defaultValues.bets
            const betsObjByVariant = betsObj[gameType][gameVariant]

            keys(betsObjByVariant).forEach((item) => {
                keys(batch).forEach((field) => {
                    if (batch[item] && batch[field].changed && item === field) {
                        console.log(`${field} has changed`)
                        betsObjByVariant[item] = Number(batch[field].value)
                    }
                })
            })

            return betsObj
        }

        try {
            return publish({
                ...device.data,
                ...(batch.minBet.changed && {
                    minBetColor: device.data.colorThresholds
                        ? getMinBetColor(Number(batch.minBet.value), device.data.colorThresholds)
                        : getMinBetColor(Number(batch.minBet.value), defaultValues.colorThresholds),
                }),
                bets: getNewBetsObj(device.data.gameType, device.data.gameVariant),
                updatedBy: username,
            })
        } catch (error) {
            console.log('error:', error)
        }
    }

    const getDeviceName = (deviceIndex) => {
        const match = devices.filter((device) => device.index === deviceIndex)
        return match ? match[0].data.name : ''
    }

    const applyBtnClick = () => {
        setSubmitted(true)

        if (!isEmpty(isMinMaxRuleMet())) {
            setAlertDialogState({
                show: 'true',
                type: 'reminder',
                message: isMinMaxRuleMet(),
                error: 'true',
            })
            setSubmitted(false)
            return
        }

        if (!validation.error) {
            setLoading(true)

            const promises = deviceIndexes.map((deviceIndex) => Apply(deviceIndex))

            Promise.allSettled(promises).then((result) => {
                setLoading(false)

                const successDevices = result.map((v) => (v.value.index ? v.value.index : '')).filter((v) => v !== '')
                const successDevicesNames = successDevices.map((v) => getDeviceName(v))
                const failedDevices = deviceIndexes.filter((v) => !successDevices.includes(v))
                const failedDevicesNames = failedDevices.map((v) => getDeviceName(v))

                setAlertDialogState({
                    show: 'true',
                    type: 'success',
                    message: `${!isEmpty(successDevices)
                        ? `Updated success for ${successDevices.length > 1 ? successDevicesNames.join(', ') : successDevicesNames[0]
                        }.`
                        : ''
                        } ${!isEmpty(failedDevices)
                            ? `Updated failed for ${failedDevices.length > 1 ? failedDevicesNames.join(', ') : failedDevicesNames[0]
                            }.`
                            : ''
                        }`,
                    error: 'false',
                })

                setBatch(initialBatch)
            })

            setSubmitted(false)
        }
    }

    return (
        <>
            <Box padding='2rem' display='flex' flexDirection='column' gap='3rem'>
                <Box display='flex' flexDirection='column' gap='1rem'>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Tabs
                            onChange={(_e, index) => {
                                setCurrentTab(index)
                            }}
                            value={currentTab}
                            indicatorColor='primary'
                            textColor='primary'
                            variant={mobile ? 'fullWidth' : 'standard'}
                        >
                            <Tab disableTouchRipple label='Table List' />
                        </Tabs>

                        <TextField
                            variant='outlined'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <Box height='29.5rem'>
                        <DataGrid
                            sx={{
                                colorScheme: 'dark',
                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                                {
                                    outline: 'none',
                                },
                            }}
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection
                            gridRowId={(row) => row.index}
                            onRowSelectionModelChange={(id) => {
                                setDeviceIndexes(id)
                            }}
                        />
                    </Box>
                </Box>
                <Box display='flex' justifyContent='space-between' gap='2rem'>
                    <Box display='flex' gap='2rem'>
                        <Box display='flex' flexDirection='column' gap='1rem'>
                            <FieldRow
                                id='minBet'
                                title='Min Bet'
                                state={batch.minBet.value}
                                onChange={(e) => {
                                    setBatch((prev) => ({
                                        ...prev,
                                        minBet: { value: e.target.value.replace(/\D/g, ''), changed: true },
                                    }))
                                }}
                                disabled={loading}
                                error={submitted && fields.minBet.error()}
                                helperText={submitted && fields.minBet.error() ? fields.minBet.message : null}
                            />

                            <FieldRow
                                id='maxBet'
                                title='Max Bet'
                                state={batch.maxBet.value}
                                onChange={(e) => {
                                    setBatch((prev) => ({
                                        ...prev,
                                        maxBet: { value: e.target.value.replace(/\D/g, ''), changed: true },
                                    }))
                                }}
                                disabled={loading}
                                error={submitted && fields.maxBet.error()}
                                helperText={submitted && fields.maxBet.error() ? fields.maxBet.message : null}
                            />
                        </Box>

                        {!isEmpty(deviceIndexes) && !isSelectionNoSideBet() && (
                            <Box
                                padding='1rem'
                                display='flex'
                                flexDirection='column'
                                border='2px solid grey'
                                gap='1rem'
                            >
                                <Typography>Side Bets</Typography>

                                <Grid container display='flex' gap='1rem'>
                                    {(isSelectionIncludeDeviceWithGameType('baccarat') ||
                                        isSelectionIncludeDeviceWithGameType('war')) && (
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='tieMinBet'
                                                    title='TIE Min Bet'
                                                    state={batch.tieMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tieMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tieMinBet.error()}
                                                    helperText={
                                                        submitted && fields.tieMinBet.error()
                                                            ? fields.tieMinBet.message
                                                            : null
                                                    }
                                                />

                                                <FieldRow
                                                    id='tieMaxBet'
                                                    title='TIE Max Bet'
                                                    state={batch.tieMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tieMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tieMaxBet.error()}
                                                    helperText={
                                                        submitted && fields.tieMaxBet.error()
                                                            ? fields.tieMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('baccarat') &&
                                        (isSelectionIncludeDeviceWithGameVariant('standard') ||
                                            isSelectionIncludeDeviceWithGameVariant('lucky6') ||
                                            isSelectionIncludeDeviceWithGameVariant('lucky6Slot') ||
                                            isSelectionIncludeDeviceWithGameVariant('mega6')) && (
                                            <>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='pairMinBet'
                                                        title='PAIR Min Bet'
                                                        state={batch.pairMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                pairMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={loading}
                                                        error={submitted && fields.pairMinBet.error()}
                                                        helperText={
                                                            submitted && fields.pairMinBet.error()
                                                                ? fields.pairMinBet.message
                                                                : null
                                                        }
                                                    />

                                                    <FieldRow
                                                        id='pairMaxBet'
                                                        title='PAIR Max Bet'
                                                        state={batch.pairMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                pairMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={loading}
                                                        error={submitted && fields.pairMaxBet.Beterror()}
                                                        helperText={
                                                            submitted && fields.pairMaxBet.error()
                                                                ? fields.pairMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='naturalMinBet'
                                                        title='NATURAL Min Bet'
                                                        state={batch.naturalMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                naturalMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={loading}
                                                        error={submitted && fields.naturalMinBet.error()}
                                                        helperText={
                                                            submitted && fields.naturalMinBet.error()
                                                                ? fields.naturalMinBet.message
                                                                : null
                                                        }
                                                    />

                                                    <FieldRow
                                                        id='naturalMaxBet'
                                                        title='NATURAL Max Bet'
                                                        state={batch.naturalMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                naturalMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={loading}
                                                        error={submitted && fields.naturalMaxBet.error()}
                                                        helperText={
                                                            submitted && fields.naturalMaxBet.error()
                                                                ? fields.naturalMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('baccarat') &&
                                        (isSelectionIncludeDeviceWithGameVariant('lucky6') ||
                                            isSelectionIncludeDeviceWithGameVariant('lucky6Slot')) && (
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='lucky6MinBet'
                                                    title='LUCKY6 Min Bet'
                                                    state={batch.lucky6MinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            lucky6MinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.lucky6MinBet.error()}
                                                    helperText={
                                                        submitted && fields.lucky6MinBet.error()
                                                            ? fields.lucky6MinBet.message
                                                            : null
                                                    }
                                                />

                                                <FieldRow
                                                    id='lucky6MaxBet'
                                                    title='LUCKY6 Max Bet'
                                                    state={batch.lucky6MaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            lucky6MaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.lucky6MaxBet.error()}
                                                    helperText={
                                                        submitted && fields.lucky6MaxBet.error()
                                                            ? fields.lucky6MaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('baccarat') &&
                                        isSelectionIncludeDeviceWithGameVariant('mega6') && (
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='mega6MinBet'
                                                    title='MEGA6 Min Bet'
                                                    state={batch.mega6MinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            mega6MinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.mega6MinBet.error()}
                                                    helperText={
                                                        submitted && fields.mega6MinBet.error()
                                                            ? fields.mega6MinBet.message
                                                            : null
                                                    }
                                                />

                                                <FieldRow
                                                    id='mega6MaxBet'
                                                    title='MEGA6 Max Bet'
                                                    state={batch.mega6MaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            mega6MaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.mega6MaxBet.error()}
                                                    helperText={
                                                        submitted && fields.mega6MaxBet.error()
                                                            ? fields.mega6MaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        )}
                                </Grid>

                                {isSelectionIncludeDeviceWithGameType('baccarat') &&
                                    isSelectionIncludeDeviceWithGameVariant('tiger') && (
                                        <Grid container display='flex' gap='1rem'>
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='tigerMinBet'
                                                    title='TIGER Min Bet'
                                                    state={batch.tigerMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerMinBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerMinBet.error()
                                                            ? fields.tigerMinBet.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='tigerMaxBet'
                                                    title='TIGER Max Bet'
                                                    state={batch.tigerMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerMaxBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerMaxBet.error()
                                                            ? fields.tigerMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='tigerTieMinBet'
                                                    title='TIGER TIE Min Bet'
                                                    state={batch.tigerTieMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerTieMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerTieMinBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerTieMinBet.error()
                                                            ? fields.tigerTieMinBet.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='tigerTieMaxBet'
                                                    title='TIGER TIE Max Bet'
                                                    state={batch.tigerTieMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerTieMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerTieMaxBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerTieMaxBet.error()
                                                            ? fields.tigerTieMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='tigerPairMinBet'
                                                    title='TIGER PAIR Min Bet'
                                                    state={batch.tigerPairMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerPairMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerPairMinBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerPairMinBet.error()
                                                            ? fields.tigerPairMinBet.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='tigerPairMaxBet'
                                                    title='TIGER PAIR Max Bet'
                                                    state={batch.tigerPairMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerPairMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerPairMaxBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerPairMaxBet.error()
                                                            ? fields.tigerPairMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='tigerBigMinBet'
                                                    title='TIGER BIG Min Bet'
                                                    state={batch.tigerBigMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerBigMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerBigMinBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerBigMinBet.error()
                                                            ? fields.tigerBigMinBet.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='tigerBigMaxBet'
                                                    title='TIGER BIG Max Bet'
                                                    state={batch.tigerBigMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerBigMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerBigMaxBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerBigMaxBet.error()
                                                            ? fields.tigerBigMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='tigerSmallMinBet'
                                                    title='TIGER SMALL Min Bet'
                                                    state={batch.tigerSmallMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerSmallMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerSmallMinBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerSmallMinBet.error()
                                                            ? fields.tigerSmallMinBet.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='tigerSmallMaxBet'
                                                    title='TIGER SMALL Max Bet'
                                                    state={batch.tigerSmallMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tigerSmallMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tigerSmallMaxBet.error()}
                                                    helperText={
                                                        submitted && fields.tigerSmallMaxBet.error()
                                                            ? fields.tigerSmallMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                {isSelectionIncludeDeviceWithGameType('blackjack') &&
                                    isSelectionIncludeDeviceWithGameVariant('anyPairSlot') && (
                                        <Grid container display='flex' gap='1rem'>
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='anyPairMinBet'
                                                    title='ANY PAIR Min Bet'
                                                    state={batch.anyPairMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            anyPairMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.anyPairMinBet.error()}
                                                    helperText={
                                                        submitted && fields.anyPairMinBet.error()
                                                            ? fields.anyPairMinBet.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='anyPairMaxBet'
                                                    title='ANY PAIR Max Bet'
                                                    state={batch.anyPairMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            anyPairMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.anyPairMaxBet.error()}
                                                    helperText={
                                                        submitted && fields.anyPairMaxBet.error()
                                                            ? fields.anyPairMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='anyPairAnyPlayerMinBet'
                                                    title='ANY PAIR ANY PLAYER Min Bet'
                                                    state={batch.anyPairAnyPlayerMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            anyPairAnyPlayerMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.anyPairAnyPlayerMinBet.error()}
                                                    helperText={
                                                        submitted && fields.anyPairAnyPlayerMinBet.error()
                                                            ? fields.anyPairAnyPlayerMinBet.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='anyPairAnyPlayerMaxBet'
                                                    title='ANY PAIR ANY PLAYER Max Bet'
                                                    state={batch.anyPairAnyPlayerMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            anyPairAnyPlayerMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.anyPairAnyPlayerMaxBet.error()}
                                                    helperText={
                                                        submitted && fields.anyPairAnyPlayerMaxBet.error()
                                                            ? fields.anyPairAnyPlayerMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                {isSelectionIncludeDeviceWithGameType('sicbo') &&
                                    isSelectionIncludeDeviceWithGameVariant('standard') && (
                                        <Grid container display='flex' gap='1rem'>
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='tripleDiceLimit'
                                                    title='Triple Dice Limit'
                                                    state={batch.tripleDiceLimit.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tripleDiceLimit: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.tripleDiceLimit.error()}
                                                    helperText={
                                                        submitted && fields.tripleDiceLimit.error()
                                                            ? fields.tripleDiceLimit.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='doubleDiceLimit'
                                                    title='Double Dice Limit'
                                                    state={batch.doubleDiceLimit.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            doubleDiceLimit: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={loading}
                                                    error={submitted && fields.doubleDiceLimit.error()}
                                                    helperText={
                                                        submitted && fields.doubleDiceLimit.error()
                                                            ? fields.doubleDiceLimit.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <LoadingButton
                            variant='contained'
                            onClick={() => applyBtnClick()}
                            loading={loading}
                            disabled={isEmpty(deviceIndexes)}
                        >
                            Apply
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default Tables
