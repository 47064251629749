import React, { useReducer } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Badge from '@mui/material/Badge'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles, withStyles } from '@mui/styles'

import { getTrendsTranslation } from './helper'

const XSwitch = withStyles((theme) => ({
    root: {
        width: 48,
        height: 26,
        padding: 5,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        paddingLeft: 4,
    },
    thumb: {
        width: 24,
        height: 24,
    },
}))(Switch)

const rootStyles = makeStyles((theme) => ({
    tableHeaderCells: {
        backgroundColor: '#333',
        borderRadius: 5,
    },
    switchCols: { textAlign: 'center' },
}))

const Trends = ({ type, variant, patterns }) => {
    const styles = rootStyles()

    const checkColumnStatus = (a, innerKey) => {
        const columnStatus = Object.keys(a).filter((key) => a[key][innerKey])

        if (columnStatus.length === 0) {
            return 'allOff'
        } else if (columnStatus.length === Object.keys(a).length) {
            return 'allOn'
        } else {
            return 'mixed'
        }
    }

    const [, rerender] = useReducer((x) => (x ? false : true), false)

    const [hasActiveTrends, setHasActiveTrends] = useReducer(
        (h, a) => checkColumnStatus(a, 'active'),
        checkColumnStatus(patterns[type], 'active')
    )

    const checkForChanges = (c, key) => {
        if (key === type) {
            setHasActiveTrends(c)
        }

        rerender()
    }

    const switchEvent = (name, key) => {
        patterns[type][key][name] = patterns[type][key][name] ? false : true
        checkForChanges(patterns[type], type)
    }

    const toggleAllOn = (innerKey) => {
        Object.keys(patterns[type]).forEach((key) => (patterns[type][key][innerKey] = true))
        checkForChanges(patterns[type], type)
    }

    const toggleAllOff = (innerKey) => {
        Object.keys(patterns[type]).forEach((key) => (patterns[type][key][innerKey] = false))
        checkForChanges(patterns[type], type)
    }

    const sortFunc = (a, b) => {
        if (a.priority < b.priority) {
            return -1
        }
        if (a.priority > b.priority) {
            return 1
        }
        return 0
    }

    const trendsSortedByPriority = Object.keys(patterns[type])
        .map((v) => ({ key: v, priority: patterns[type][v]['priority'] }))
        .sort(sortFunc)

    return (
        <div style={{ backgroundColor: '#444', padding: 20 }}>
            <Paper className='hot-trends' style={{ backgroundColor: '#333' }}>
                <Table className='hot-trends-table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.tableHeaderCells} style={{ textAlign: 'right', width: 50 }}>
                                <h3>
                                    <Badge
                                        badgeContent={
                                            <Tooltip
                                                title={
                                                    <span style={{ fontSize: '15px' }}>
                                                        Priority in descending order (1 has most priority)
                                                    </span>
                                                }
                                            >
                                                <InfoIcon style={{ fontSize: 17, color: '#77aeff' }} />
                                            </Tooltip>
                                        }
                                    >
                                        Priority
                                    </Badge>
                                </h3>
                            </TableCell>
                            <TableCell className={styles.tableHeaderCells}>
                                <h3>Trend Name</h3>
                            </TableCell>
                            <TableCell
                                className={styles.tableHeaderCells}
                                style={{ textAlign: 'center', width: 150 }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell colSpan='1'></TableCell>
                            <TableCell colSpan='1'>Toggle all ON/OFF</TableCell>
                            <TableCell className={styles.switchCols}>
                                <FormControl variant='standard' sx={{ m: 1, minWidth: 80 }}>
                                    <Select id='trend-toggle' value={hasActiveTrends}>
                                        <MenuItem value='allOn' onClick={() => toggleAllOn('active')}>
                                            All On
                                        </MenuItem>

                                        <MenuItem value='allOff' onClick={() => toggleAllOff('active')}>
                                            All Off
                                        </MenuItem>

                                        <MenuItem value='mixed' sx={{ display: 'none' }}>
                                            Mixed
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        {trendsSortedByPriority.map((v, index) => {
                            const key = v.key
                            const item = patterns[type][key]
                            const trendName = getTrendsTranslation(key)
                            const isLastRow = index === trendsSortedByPriority.length - 1 ? true : false

                            return (
                                <TableRow key={index}>
                                    <TableCell
                                        style={{
                                            textAlign: 'center',
                                            width: 50,
                                            color: '#bbb',
                                            ...(isLastRow && { borderBottom: 'none' }),
                                        }}
                                    >
                                        {item.priority}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            textTransform: 'capitalize',
                                            ...(isLastRow && { borderBottom: 'none' }),
                                        }}
                                    >
                                        {trendName}
                                    </TableCell>
                                    <TableCell
                                        className={styles.switchCols}
                                        style={{ ...(isLastRow && { borderBottom: 'none' }) }}
                                    >
                                        <div className='activeToggle'>
                                            <FormControlLabel
                                                sx={{ margin: 0 }}
                                                control={
                                                    <XSwitch
                                                        checked={item.active ? true : false}
                                                        onChange={(e) => {
                                                            switchEvent('active', key)
                                                        }}
                                                        inputProps={{
                                                            'aria-label': 'active on/off',
                                                            'data-testid': key,
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}

export default Trends
