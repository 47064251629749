/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext, useState, useRef, useEffect } from 'react'
import { keys, isEmpty, toString } from 'lodash'
import moment from 'moment'
import { css, jsx } from '@emotion/react'
import Button from '@mui/material/Button'

import { fetch } from '../../api'
import { playerServerIP } from '../../config'
import { DefaultProfileImg, profileImg } from '../icons/DefaultProfileImg'
import { DUIContext } from './Dealer'

const SeatPlayersNewVer = () => {
    const {
        t,
        members,
        verifyMembership,
        seats,
        selectedSeat,
        setSelectedSeat,
        bindSeat,
        unbindSeat,
        setShowCashDrop,
        dialogObj,
        setDialogObj,
        device,
    } = useContext(DUIContext)

    const [capturedCardNo, setCapturedCardNo] = useState('')
    const [currentPlayerID, setCurrentPlayerID] = useState('')
    const buttonRef = useRef(null)

    const handleCashDrop = (seatIndex, playerId) => {
        setDialogObj({
            ...dialogObj,
            playerID: playerId,
            seatNumber: toString(seatIndex),
        })
        setShowCashDrop(true)
    }

    const handleSeatSelection = (seatIndex) => {
        setSelectedSeat(seatIndex)

        // Unselect seat if it's selected
        if (selectedSeat === seatIndex) {
            setSelectedSeat(null)
        }
    }

    const linkSession = async (membershipID, awsID) => {
        try {
            await fetch(playerServerIP + '/membership/link/' + membershipID + '/' + awsID)
            console.log('link success')
        } catch (e) {
            console.warn(e)
        }
    }

    console.log('currentPlayerID', currentPlayerID)
    console.log('seats', seats)

    const handleSubmit = async (e) => {
        e.preventDefault()

        let filteredCardNo = capturedCardNo.slice(capturedCardNo.indexOf(';') + 1, capturedCardNo.indexOf('?'))

        // Get the member ID with captured card number
        const res = await verifyMembership(filteredCardNo)

        // Get the member info by member ID
        const matchedMemberInfo = members?.find((member) => member.index === res.id)

        // Check if member already binded to a seat
        let isMemberBinded = false

        keys(seats?.data).forEach((key) => {
            if (seats?.data[key]?.player === matchedMemberInfo?.data?.id) {
                isMemberBinded = true
            }
        })

        // Bind the captured member ID to selected seat
        if (matchedMemberInfo && !isMemberBinded) {
            const awsID = currentPlayerID

            if (awsID !== '') {
                linkSession(matchedMemberInfo.index, awsID)
            }

            bindSeat(selectedSeat, res.id)
                .then(() => {
                    setCapturedCardNo('')
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }

    const autoFormSubmission = () => {
        if (buttonRef.current) {
            buttonRef.current.click()
        }
    }

    useEffect(() => {
        // Check if the full card number has been captured
        if (capturedCardNo.slice(-1) === '?') {
            autoFormSubmission()
        }
    }, [capturedCardNo])

    const formatTime = (timeInNanoSeconds) => {
        const NanoToSeconds = (time) => Math.floor(time / 1000000000)
        const NanoToDate = (time) => moment.unix(NanoToSeconds(time)).format('YYYY-MM-DD h:mm A')

        return NanoToDate(timeInNanoSeconds)
    }

    const fieldLabel = ({ index }) => css`
        font-size: 1.1rem;
        color: ${selectedSeat === index + 1 ? '#4f4f4f' : '#b5b5b5'};
    `

    const fieldValue = ({ index }) => css`
        font-size: 1.2rem;
        color: ${selectedSeat === index + 1 ? '#000' : ''};
    `

    return (
        <React.Fragment>
            <div
                css={css`
                    width: 100%;
                    height: 100%;
                    background-color: #222;
                `}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <table
                    css={css`
                        position: relative;
                        width: 100%;
                        height: 100%;
                        padding: 0.5vh;
                        box-sizing: border-box;
                        table-layout: fixed;
                        gap: 0.5vh;
                    `}
                >
                    <tbody>
                        <tr>
                            {[...Array(5)].map((el, index) => {
                                const key = 'seat' + (index + 1)
                                const matchedMemberInfo = !isEmpty(seats.data)
                                    ? members?.find((member) => member.index === seats.data[key].player)
                                    : null

                                const isPlayerHasSession =
                                    members &&
                                    members.find(
                                        (member) =>
                                            member.data.sessions &&
                                            member.data.sessions.includes(seats.data[key].player)
                                    )

                                const sessionMemberPicture = isPlayerHasSession
                                    ? members.find((member) => member.data.sessions.includes(seats.data[key].player))
                                          .picture
                                    : '-'

                                const sessionMemberName = isPlayerHasSession
                                    ? members.find((member) => member.data.sessions.includes(seats.data[key].player))
                                          .name
                                    : '-'

                                const sessionMemberLevel = isPlayerHasSession
                                    ? members.find((member) => member.data.sessions.includes(seats.data[key].player))
                                          .level
                                    : '-'

                                const sessionMemberGender = isPlayerHasSession
                                    ? members.find((member) => member.data.sessions.includes(seats.data[key].player))
                                          .gender
                                    : '-'

                                const sessionMemberAge = isPlayerHasSession
                                    ? members.find((member) => member.data.sessions.includes(seats.data[key].player))
                                          .age
                                    : '-'

                                const sessionMemberLocation = isPlayerHasSession
                                    ? members.find((member) => member.data.sessions.includes(seats.data[key].player))
                                          .location
                                    : '-'

                                console.log('matchedMemberInfo', matchedMemberInfo)
                                console.log('isPlayerHasSession', isPlayerHasSession)

                                return (
                                    <td
                                        key={index}
                                        className='SeatColumn'
                                        css={css`
                                            width: 100%;
                                            border: ${selectedSeat === index + 1 ? '1px solid #ccc' : 'none'};
                                            overflow-wrap: break-word;
                                            gap: 0.5vh;
                                            cursor: pointer;
                                        `}
                                        onClick={() => {
                                            handleSeatSelection(index + 1)
                                            setCurrentPlayerID(seats.data[key].player)
                                        }}
                                    >
                                        <div
                                            className='ProfileImage'
                                            css={css`
                                                position: relative;
                                                height: 20%;
                                                min-height: 20%;
                                                max-height: 20%;
                                                background-color: #9e9e9e;
                                            `}
                                        >
                                            {!isEmpty(seats.data) ? (
                                                <>
                                                    {matchedMemberInfo ? (
                                                        !isEmpty(matchedMemberInfo.data.picture) ? (
                                                            <img
                                                                src={matchedMemberInfo.data.picture}
                                                                alt='ProfileImage'
                                                                css={profileImg}
                                                            />
                                                        ) : (
                                                            <DefaultProfileImg css={profileImg} />
                                                        )
                                                    ) : isPlayerHasSession ? (
                                                        <img
                                                            src={sessionMemberPicture}
                                                            alt='ProfileImage'
                                                            css={profileImg}
                                                        />
                                                    ) : !isEmpty(seats.data[key].crop) ? (
                                                        <img
                                                            src={
                                                                !seats.data[key].crop.includes('data:image')
                                                                    ? `data:image/png;base64,${seats.data[key].crop}`
                                                                    : seats.data[key].crop
                                                            }
                                                            alt='ProfileImage'
                                                            css={profileImg}
                                                        />
                                                    ) : (
                                                        <DefaultProfileImg css={profileImg} />
                                                    )}
                                                </>
                                            ) : (
                                                <DefaultProfileImg css={profileImg} />
                                            )}

                                            <div
                                                className='SeatNumber'
                                                css={css`
                                                    position: absolute;
                                                    top: -0.5vh;
                                                    left: 0.5vw;
                                                    height: 4vh;
                                                    font-size: 4vh;
                                                    font-weight: 700;
                                                    text-align: center;
                                                    color: #000;
                                                    -webkit-text-stroke: 1px #ccc;
                                                `}
                                            >
                                                {(device.data.gameType === 'baccarat' ||
                                                    device.data.gameType === 'blackjack') &&
                                                device.data?.skipSeatFour
                                                    ? index + 1 > 3
                                                        ? index + 2
                                                        : index + 1
                                                    : index + 1}
                                            </div>
                                        </div>

                                        <div
                                            className='SeatInfo'
                                            css={css`
                                                height: 80%;
                                                padding: 2vh;
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: space-between;
                                                background-color: ${selectedSeat === index + 1 ? '#ffc526' : '#124e6f'};
                                                box-sizing: border-box;
                                                border-radius: 5;
                                                gap: 1rem;
                                                overflow: auto;
                                                ::-webkit-scrollbar {
                                                    width: 0px;
                                                }
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    display: flex;
                                                    flex-direction: column;
                                                    jusitfy-content: center;
                                                    color: #fff;
                                                    gap: 1rem;
                                                    overflow: auto;
                                                    ::-webkit-scrollbar {
                                                        width: 0px;
                                                    }
                                                `}
                                            >
                                                {!matchedMemberInfo && !isPlayerHasSession && (
                                                    <>
                                                        <div>
                                                            <div css={fieldLabel({ index })}>
                                                                {t('dealer.playerId')}
                                                            </div>
                                                            <div css={fieldValue({ index })}>
                                                                {!isEmpty(seats.data)
                                                                    ? !isEmpty(seats.data[key].player)
                                                                        ? seats.data[key].player
                                                                        : '-'
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div css={fieldLabel({ index })}>{t('dealer.started')}</div>
                                                            <div css={fieldValue({ index })}>
                                                                {seats.data[key].started !== 0
                                                                    ? formatTime(seats.data[key].started)
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {(matchedMemberInfo || isPlayerHasSession) && (
                                                    <>
                                                        <div>
                                                            <div css={fieldLabel({ index })}>
                                                                {t('dealer.memberId')}
                                                            </div>
                                                            <div css={fieldValue({ index })}>
                                                                {!isEmpty(seats.data[key].player)
                                                                    ? seats.data[key].player
                                                                    : '-'}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div css={fieldLabel({ index })}>{t('dealer.name')}</div>
                                                            <div css={fieldValue({ index })}>
                                                                {matchedMemberInfo
                                                                    ? !isEmpty(matchedMemberInfo?.data?.name)
                                                                        ? matchedMemberInfo?.data?.name
                                                                        : '-'
                                                                    : isPlayerHasSession
                                                                    ? sessionMemberName
                                                                    : '-'}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div css={fieldLabel({ index })}>
                                                                {t('dealer.memberLevel')}
                                                            </div>
                                                            <div css={fieldValue({ index })}>
                                                                {matchedMemberInfo
                                                                    ? !isEmpty(matchedMemberInfo?.data?.level)
                                                                        ? matchedMemberInfo?.data?.level
                                                                        : '-'
                                                                    : isPlayerHasSession
                                                                    ? sessionMemberLevel
                                                                    : '-'}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div css={fieldLabel({ index })}>{t('dealer.gender')}</div>
                                                            <div css={fieldValue({ index })}>
                                                                {matchedMemberInfo
                                                                    ? !isEmpty(matchedMemberInfo?.data?.gender)
                                                                        ? matchedMemberInfo?.data?.gender
                                                                        : '-'
                                                                    : isPlayerHasSession
                                                                    ? sessionMemberGender
                                                                    : '-'}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div css={fieldLabel({ index })}>Age</div>
                                                            <div css={fieldValue({ index })}>
                                                                {matchedMemberInfo
                                                                    ? matchedMemberInfo.data.age
                                                                    : isPlayerHasSession
                                                                    ? sessionMemberAge
                                                                    : '-'}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div css={fieldLabel({ index })}>Location</div>
                                                            <div css={fieldValue({ index })}>
                                                                {matchedMemberInfo
                                                                    ? !isEmpty(matchedMemberInfo?.data?.location)
                                                                        ? matchedMemberInfo?.data?.location
                                                                        : '-'
                                                                    : isPlayerHasSession
                                                                    ? sessionMemberLocation
                                                                    : '-'}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div css={fieldLabel({ index })}>{t('dealer.started')}</div>
                                                            <div css={fieldValue({ index })}>
                                                                {seats.data[key].started !== 0
                                                                    ? formatTime(seats.data[key].started)
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {/* Action Button */}
                                            {selectedSeat === index + 1 && (
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        color: #fff;
                                                    `}
                                                >
                                                    <form
                                                        autoComplete='off'
                                                        onSubmit={handleSubmit}
                                                        css={css`
                                                            width: 0;
                                                            overflow: hidden;
                                                            opacity: 0;
                                                        `}
                                                    >
                                                        <input
                                                            css={css`
                                                                width: 0;
                                                            `}
                                                            autoFocus
                                                            onChange={(e) => {
                                                                setCapturedCardNo(e.target.value)
                                                            }}
                                                        />
                                                        <button
                                                            type='submit'
                                                            ref={buttonRef}
                                                            css={css`
                                                                display: none;
                                                            `}
                                                        />
                                                    </form>

                                                    {!isEmpty(seats.data) && (
                                                        <div
                                                            css={css`
                                                                width: 100%;
                                                                display: flex;
                                                                flex-direction: column;
                                                                gap: 1rem;
                                                            `}
                                                        >
                                                            {!isEmpty(seats.data[key].player) && (
                                                                <Button
                                                                    variant='contained'
                                                                    fullWidth
                                                                    sx={{
                                                                        height: '3.5rem',
                                                                        backgroundColor: 'rgba(0, 125, 12, 0.85)',
                                                                        '&:hover': {
                                                                            backgroundColor: 'rgba(0, 125, 12, 1)',
                                                                        },
                                                                        fontSize: '1rem',
                                                                        color: '#fff',
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        handleCashDrop(
                                                                            index + 1,
                                                                            seats.data[key].player
                                                                        )
                                                                    }}
                                                                >
                                                                    {t('dealer.cashdrop')}
                                                                </Button>
                                                            )}

                                                            {seats.data[key].bound && (
                                                                <Button
                                                                    variant='contained'
                                                                    fullWidth
                                                                    sx={{
                                                                        height: '3.5rem',
                                                                        fontSize: '1rem',
                                                                        color: '#fff',
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        unbindSeat(index + 1, seats.data[key].player)
                                                                    }}
                                                                >
                                                                    {t('dealer.unbind')}
                                                                </Button>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default SeatPlayersNewVer
