import { makeStyles } from "@mui/styles";

import SuitC from "./assets/club.png";
import SuitD from "./assets/diamond.png";
import SuitH from "./assets/heart.png";
import SuitS from "./assets/spade.png";

export const rootStyles = makeStyles((theme) => ({
  suit1: {
    background: `url(${SuitS}) no-repeat center center / contain`,
  },
  suit2: {
    background: `url(${SuitH}) no-repeat center center / contain`,
  },
  suit3: {
    background: `url(${SuitC}) no-repeat center center / contain`,
  },
  suit4: {
    background: `url(${SuitD}) no-repeat center center / contain`,
  },
  border: {
    border: "0px solid #ccc",
  },
  card: {
    "& ::selection": {
      color: "none",
      background: "none",
    },
  },
  menu: {
    listStyle: "none",
    fontSize: "3.5vh",
    textTransform: "uppercase",
    padding: 0,
    textAlign: "center",
    margin: 0,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& li": {
      height: "100%",
      maxHeight: "30%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#1F2126",
      border: "1px solid #ccc",
      cursor: "pointer",
    },
    "& li.disabled": { pointerEvents: "none", color: "#555" },
  },
  cardsInput: {
    border: "2px solid #000",
    backgroundColor: "#fff",
    fontSize: "4vh",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hidden: {
    display: "none !important;",
  },
  cardSelected: {
    borderColor: "lime !important",
    boxShadow: "0px 0px 0px 6px lime",
  },
  cardsInputSelected: {
    backgroundColor: "lime",
  },
  menuBtnSelected: {
    backgroundColor: "#777 !important",
  },
  finalizeError: {
    color: "#fff",
    fontSize: 30,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: -40,
  },
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    position: "relative",
    backgroundColor: "#1B1B1F",
    color: "#fff",
    textAlign: "center",
    borderRadius: 25,
    padding: 50,
    outline: 0,
    width: "50%",
  },
  modalHeader: {
    backgroundColor: "#111314",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    borderRadius: "25px 25px 0 0",
  },
  modalPrompt: {
    marginTop: "3em",
    paddingTop: "1em",
    fontSize: 13,
  },
  modalButtonRow: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "5%",
    "& button": {
      height: "4.2rem",
      backgroundColor: "#303238",
      color: "#fff",
      boxShadow: "0px 1px 1px #161D251A",
      border: "1px solid #5F606A",
      fontSize: "1.5rem",
    },
  },
  modalInput: {
    width: "90%",
    height: "4.2rem",
    backgroundColor: "#303238",
    boxShadow: "0px 1px 2px #00000014",
    border: "1px solid #5F606A",
    borderRadius: 4,
    fontSize: "2rem",
    "& .MuiOutlinedInput-root": { height: "4.2rem" },
  },
}));
