import React, { useReducer, useState, useContext } from "react"
import { get } from "lodash"
import Button from "@mui/material/Button"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { terminalServerIP } from "../../config"
import { publish } from "../../api"
import { formatCashString } from "../util/misc"
import { DUIContext } from "./Dealer"
import PitBossConfirmation from "./PitBossConfirmation"
import DealerConfirmation from "./DealerConfirmation"

const CrowdPlayerScan = () => {
    const {
        device,
        setShowCrowdPlayerScan,
        callApiPublishChipfill,
    } = useContext(DUIContext)
    const [modalObj, setModalObj] = useState({ show: false, modalMsg: '', requirePitBoss: false, confirm: () => { } })
    const [crowdDeviceID, setCrowdDeviceID] = useState(null)
    //const [voucherOutput, setVoucherOuput] = useState(null)

    const [cashType,] = useState(null)


    const [inputState, setInputState] = useReducer((state, obj) => {
        if (obj.key === 'D') return state.substring(0, state.length - 1).length > 0 ? state.substring(0, state.length - 1) : '0'
        if (state === '0') return obj.key
        //if (obj.key === '.' && state.split('').includes('.')) return state
        return state + '' + obj.key
    }, '0')


    const seatID = crowdDeviceID

    const callAddCredit = async ({ amount, success, fail }) => {
        try {
            await publish(terminalServerIP + '/seat/credits/' + device.index + '/' + seatID, {
                balance: Number(amount),
            })
            success()
        } catch (e) {
            console.warn(e)
            fail()
        }
    }
    console.log('modalObj', modalObj)


    const keyPress = (key) => {
        if ((key === 'T' || key === 'F' || key === 'C') && Number(inputState) <= 0) {
            setShowCrowdPlayerScan(false)
            return
        }
        if (key === 'T') {
            setModalObj({
                show: true,
                modalMsg: 'Chip Take Away: $' + Number(inputState),
                requirePitBoss: true,
                confirm: () => callApiPublishChipfill({ data: { amount: Number(inputState) }, success: () => { setShowCrowdPlayerScan(false) }, fail: () => { console.log('Api post fail, unable to send chip fill') } })
            })
            return
        }
        if (key === 'F') {
            setModalObj({
                show: true,
                modalMsg: 'Chip Fill: $' + Number(inputState),
                requirePitBoss: true,
                confirm: () => callApiPublishChipfill({ data: { amount: Number(inputState) }, success: () => { setShowCrowdPlayerScan(false) }, fail: () => { console.log('Api post fail, unable to send chip fill') } })
            })
            return
        }
        if (key === 'C') {
            setModalObj({
                show: true,
                modalMsg: 'Seat: ' + (Number(seatID) + 1) + ', Add Credit: $' + Number(inputState) + ', Type: ' + cashType.charAt(0).toUpperCase() + cashType.slice(1),
                requirePitBoss: get(device, 'data.buyInConfirmation', false) && Number(inputState) >= get(device, 'data.buyInThreshold', 0) ? true : false,
                confirm: () => {
                    callAddCredit({ amount: Number(inputState), success: () => { setShowCrowdPlayerScan(false) }, fail: () => { console.log('Api post fail, unable to add credits') } })
                }
            })
            return
        }
        setInputState({ key: key })
    }




    if (!crowdDeviceID) {
        return <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: '0.9', backgroundColor: '#000', }} onClick={(e) => { setShowCrowdPlayerScan(false); e.preventDefault(); e.stopPropagation(); }}>
            <div style={{ position: 'relative', height: '30vh', width: '60vh', background: '#333', color: '#ccc', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>

                <div onClick={() => setShowCrowdPlayerScan(false)} className="modalOffTigger" style={{ position: 'absolute', top: -50, right: -50, height: 80, width: 80, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                    <HighlightOffIcon style={{ fontSize: 50, color: 'white' }} />
                </div>

                <div style={{ justifyContent: 'center', display: 'flex', fontSize: '2.8vh', fontWeight: 700, marginTop: '2vh' }}>Add Crowd Player Credits</div>
                <div style={{ height: '10vh', display: 'flex', justifyContent: 'center', fontSize: '2.4vh' }}>
                    <div>Scan barcode on the tablet to add the new player</div>
                    <form onSubmit={(e) => {
                        setCrowdDeviceID(1);
                        //console.log(get(e.currentTarget['tagInput'], 'value', null)); 
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <input name="tagInput" type="text" autoFocus={true} autoComplete="off" style={{ position: 'absolute', inset: 0, height: '100%', width: '100%', opacity: '0' }} />
                    </form>
                </div>
            </div>
        </div>
    }


    return <div style={{ height: '100%', width: '100%' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
        <div style={{ position: 'relative', width: '60vh', height: '60vh', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#333', display: 'grid', gridTemplateRows: 'repeat(5, 1fr)', gridTemplateColumns: 'repeat(4, 1fr)' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <div onClick={() => setShowCrowdPlayerScan(false)} className="modalOffTigger" style={{ position: 'absolute', top: -50, right: -50, height: 80, width: 80, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                <HighlightOffIcon style={{ fontSize: 50, color: 'white' }} />
            </div>

            <div style={{ gridColumn: '1 / span 4', gridRow: '1 / span 1', backgroundColor: '#000' }}>
                <input style={{ height: '100%', width: '100%', padding: '10px', textAlign: 'right', fontSize: '3vw', boxSizing: 'border-box', backgroundColor: '#000', color: '#fff', border: 'none' }} value={'$' + formatCashString(inputState)} />
            </div>
            <div style={{ gridRow: '2 /span 1', gridColumn: '1 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('7') }}>7</Button></div>
            <div style={{ gridRow: '3 /span 1', gridColumn: '1 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('4') }}>4</Button></div>
            <div style={{ gridRow: '4 /span 1', gridColumn: '1 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('1') }}>1</Button></div>
            <div style={{ gridRow: '5 /span 1', gridColumn: '1 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('0') }}>0</Button></div>

            <div style={{ gridRow: '2 /span 1', gridColumn: '2 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('8') }}>8</Button></div>
            <div style={{ gridRow: '3 /span 1', gridColumn: '2 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('5') }}>5</Button></div>
            <div style={{ gridRow: '4 /span 1', gridColumn: '2 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('2') }}>2</Button></div>
            <div style={{ gridRow: '5 /span 1', gridColumn: '2 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('D') }}>Del</Button></div>

            <div style={{ gridRow: '2 /span 1', gridColumn: '3 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('9') }}>9</Button></div>
            <div style={{ gridRow: '3 /span 1', gridColumn: '3 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('6') }}>6</Button></div>
            <div style={{ gridRow: '4 /span 1', gridColumn: '3 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('3') }}>3</Button></div>
            {/* <div style={{ gridRow: '5 /span 1', gridColumn: '3 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('.') }}>.</Button></div> */}

            <div style={{ gridRow: '2 /span 4', gridColumn: '4 / span 1' }}>
                <Button style={{ height: '100%', width: '100%', fontSize: '2vw', backgroundColor: '#ffc300' }} onClick={() => { keyPress('C') }}>Dealer Confirm</Button>
            </div>


        </div>

        {modalObj.show && modalObj.requirePitBoss && <PitBossConfirmation modalObj={modalObj} setModalObj={setModalObj} />}
        {modalObj.show && !modalObj.requirePitBoss && <DealerConfirmation modalObj={modalObj} setModalObj={setModalObj} />}


    </div>
}

export default CrowdPlayerScan