export const getGameTypeTranslation = (value) => {
    let translation
    switch (value) {
        case 'baccarat':
            translation = 'Baccarat'
            break
        case 'blackjack':
            translation = 'Blackjack'
            break
        case 'roulette':
            translation = 'Roulette'
            break
        case 'sicbo':
            translation = 'Sicbo'
            break
        case 'war':
            translation = 'Casino War'
            break
        default:
            translation = ''
    }
    return translation
}

export const getGameVariantTranslation = (value) => {
    let translation
    switch (value) {
        case 'standard':
            translation = 'Standard'
            break
        case 'lucky6':
            translation = 'Lucky6'
            break
        case 'lucky6Slot':
            translation = 'Lucky6 with Slot'
            break
        case 'mega6':
            translation = 'Mega6'
            break
        case 'tiger':
            translation = 'Tiger'
            break
        case 'anyPairSlot':
            translation = 'Any Pair with Slot'
            break
        case 'etg':
            translation = 'ETG'
            break
        default:
            translation = ''
    }
    return translation
}
