import React, { useState, useReducer } from 'react'
import { Base64 } from 'js-base64'
import { findIndex, set } from 'lodash'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import { fetch, unpublish, publish } from '../api'
import { republish } from '../api'
import { playerServerIP } from '../config'

const Players = () => {
    // GET 	    /membership/link/{membershipID}/{sessionID} 	add a player session to the sessions array of a membership
    // DEL 	    /membership/delete/{membership}     	    delete a membership entry
    // GET 	    /membership/verify/{card} 	                return a membership related to a card

    // PUT 	    /membership/update/{membership} 	        update a membership entry
    // POST 	/membership/create                          create a membership entry
    // GET 	    /membership/read/{membership}       	    read a membership entry
    // GET 	    /memberships 	                            read memberships entries list

    const getMemberships = async () => {
        try {
            const res = await fetch(playerServerIP + '/memberships')
            console.log('getMemberships results', res)
            setMemberships(res)
        } catch (e) {
            console.log('Failed', e)
        }
    }

    const deleteMembership = async (id) => {
        setFeedback(null)
        try {
            const res = await unpublish(playerServerIP + '/membership/delete/' + id)
            console.log('deleteMembership results', res)
            setFeedback('deleted : ' + JSON.stringify(res))
            await getMemberships()
        } catch (e) {
            console.log('Failed', e)
        }
    }

    const readMembership = async (id) => {
        setFeedback(null)
        try {
            const res = await fetch(playerServerIP + 'membership/read/' + id)
            console.log('readMembership results', res)
            setFeedback('read : ' + JSON.stringify(res))
        } catch (e) {
            console.log('Failed', e)
        }
    }

    const updateMembership = async (id) => {
        setFeedback(null)
        try {
            console.log('trying update ' + id)
            const res = await republish(playerServerIP + '/membership/update/' + id, {
                data: Base64.encode(JSON.stringify(memberships.filter((v) => v.id === id)[0])),
            })
            console.log('updateMembership results', res)
            setFeedback('updated : ' + JSON.stringify(res))
        } catch (e) {
            console.log('Failed', e)
        }
    }

    const createMemberships = async () => {
        setFeedback(null)
        try {
            const res = await publish(playerServerIP + '/membership/create', {
                data: Base64.encode(JSON.stringify(newEntry)),
            })
            console.log('createMemberships results', res)
            setFeedback('created : ' + JSON.stringify(res))
            await getMemberships()
            return res
        } catch (e) {
            console.log('Failed', e)
        }
    }

    // const verifyMemberships = async (card) => {
    //     setFeedback(null)
    //     try {
    //         const res = await fetch('membership/verify/' + card)
    //         console.log('verifyMemberships results', res)
    //         setFeedback('verified : ' + JSON.stringify(res))
    //     } catch (e) {
    //         console.log('Failed', e)
    //     }
    // }

    //const [memberships, setMemberships] = useState(null)
    const [memberships, setMemberships] = useReducer((state, obj) => {
        if (Object.keys(obj).includes('selector')) {
            console.log('update')
            const id = obj.id
            const selector = obj.selector
            const value = obj.value
            console.log('change', set(state, [findIndex(state, ['id', id]), selector], value))
            //_.findIndex(users, ['active', false]);
            //_.set(object, ['x', '0', 'y', 'z'], 5);
            //return state
            return set(state, [findIndex(state, ['id', id]), selector], value)
        }
        return obj
    }, null)

    const [newEntry, setNewEntry] = useReducer(
        (state, obj) => {
            return { ...state, ...obj }
        },
        {
            name: '',
            card: '',
            level: '',
            location: '',
            gender: '',
            sessions: [],
            picture: '',
        }
    )

    const [feedback, setFeedback] = useState(null)

    if (!memberships) {
        getMemberships()
    }
    //createMemberships()
    const deletePress = (id) => {
        deleteMembership(id)
    }
    const updatePress = (id) => {
        updateMembership(id)
    }

    const readPress = (id) => {
        readMembership(id)
    }
    const makeChange = (selector, id, value) => {
        console.log('do change', selector, id, value)
        setMemberships({ id: id, selector: selector, value: value })
    }

    const makeNew = (selector, value) => {
        setNewEntry({ [selector]: value })
    }

    const [linkSessionID, setLinkSessionID] = useState(null)
    const linkPress = async (id) => {
        if (!linkSessionID) return
        try {
            //const sessionID = '00000000'
            const res = await fetch(playerServerIP + '/membership/link/' + id + '/' + linkSessionID)
            console.log('successfully linked member to session ID', res)
        } catch (e) {
            console.log('Failed to link', e)
        }
    }

    return (
        <div style={{ display: 'grid', gridAutoRows: 'auto', marginLeft: 54 }}>
            {feedback && <div style={{ textAlign: 'center', margin: 10, color: 'white' }}>{feedback}</div>}

            {memberships && (
                <div
                    style={{
                        display: 'grid',
                        gridAutoColumns: 'auto',
                        gridTemplateRows: '1',
                        textAlign: 'center',
                        padding: 10,
                        backgroundColor: '#333',
                    }}
                >
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        {' '}
                        #{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        id{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        picture{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        name{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        card{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        gender{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        level{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        location{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        session{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        delete{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        update{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        link{' '}
                    </div>
                    <div style={{ gridRow: '1 / span 1', fontSize: '20px', color: 'white', fontWeight: 'bold' }}>
                        read{' '}
                    </div>

                    {/* 
            <div style={{ gridRow: '2 / span 1', gridColumn: '4 / span ', color: '#ccc', padding: 10 }}>
                <TextField variant="outlined" defaultValue={'card number'} size="small" color="primary" style={{ width: '100%' }} onChange={(e) => { setCardNumber(e.target.value) }} />
            </div>

            <div style={{ gridRow: '2 / span 1', gridColumn: '10 / span 4', color: '#ccc', display: 'flex', margin: 5 }}>
                <Button variant="contained" size="small" style={{ margin: 5, width: '100%' }} onClick={() => { verifyPress() }}>verify</Button>
            </div> */}

                    <div style={{ gridRow: '3 / span 1', color: '#ccc' }}> - </div>
                    <div style={{ gridRow: '3 / span 1', color: '#ccc' }}> - </div>
                    <div style={{ gridRow: '3 / span 1', color: '#ccc' }}>
                        {' '}
                        <TextField
                            variant='outlined'
                            size='small'
                            color='primary'
                            onChange={(e) => {
                                makeNew('picture', e.target.value)
                            }}
                        />{' '}
                    </div>
                    <div style={{ gridRow: '3 / span 1', color: '#ccc' }}>
                        {' '}
                        <TextField
                            variant='outlined'
                            size='small'
                            color='primary'
                            onChange={(e) => {
                                makeNew('name', e.target.value)
                            }}
                        />{' '}
                    </div>
                    <div style={{ gridRow: '3 / span 1', color: '#ccc' }}>
                        {' '}
                        <TextField
                            variant='outlined'
                            size='small'
                            color='primary'
                            onChange={(e) => {
                                makeNew('card', e.target.value)
                            }}
                        />
                    </div>
                    <div style={{ gridRow: '3 / span 1', color: '#ccc' }}>
                        {' '}
                        <TextField
                            variant='outlined'
                            size='small'
                            color='primary'
                            onChange={(e) => {
                                makeNew('gender', e.target.value)
                            }}
                        />
                    </div>
                    <div style={{ gridRow: '3 / span 1', color: '#ccc' }}>
                        {' '}
                        <TextField
                            variant='outlined'
                            size='small'
                            color='primary'
                            onChange={(e) => {
                                makeNew('level', e.target.value)
                            }}
                        />
                    </div>
                    <div style={{ gridRow: '3 / span 1', color: '#ccc' }}>
                        {' '}
                        <TextField
                            variant='outlined'
                            size='small'
                            color='primary'
                            onChange={(e) => {
                                makeNew('location', e.target.value)
                            }}
                        />
                    </div>
                    {/* <div style={{ gridRow: '3 / span 1', color: '#ccc' }}> <TextField variant="outlined" size="small" color="primary" onChange={(e) => { makeNew('sessions', e.target.value.split(',').map(v => v.trim())) }} /></div> */}

                    <div
                        style={{
                            gridRow: '3 / span 1',
                            gridColumn: '10 / span 4',
                            color: '#ccc',
                            display: 'flex',
                            margin: 5,
                        }}
                    >
                        <Button
                            variant='contained'
                            size='small'
                            style={{ margin: 5, width: '100%' }}
                            onClick={() => {
                                createMemberships()
                            }}
                        >
                            create
                        </Button>
                    </div>

                    <div
                        style={{
                            gridRow: '4 / span 1',
                            gridColumn: '10 / span 4',
                            color: '#ccc',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        LINK SESSION ID :{' '}
                        <TextField
                            variant='outlined'
                            size='small'
                            color='primary'
                            onChange={(e) => setLinkSessionID(e.target.value)}
                        />
                    </div>

                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            {i}{' '}
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            {v.id}{' '}
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <TextField
                                variant='outlined'
                                size='small'
                                defaultValue={v.picture}
                                onChange={(e) => {
                                    makeChange('picture', v.id, e.target.value)
                                }}
                            />{' '}
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <TextField
                                variant='outlined'
                                size='small'
                                defaultValue={v.name}
                                onChange={(e) => {
                                    makeChange('name', v.id, e.target.value)
                                }}
                            />{' '}
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <TextField
                                variant='outlined'
                                size='small'
                                defaultValue={v.card}
                                onChange={(e) => {
                                    makeChange('card', v.id, e.target.value)
                                }}
                            />
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <TextField
                                variant='outlined'
                                size='small'
                                defaultValue={v.gender}
                                onChange={(e) => {
                                    makeChange('gender', v.id, e.target.value)
                                }}
                            />
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <TextField
                                variant='outlined'
                                size='small'
                                defaultValue={v.level}
                                onChange={(e) => {
                                    makeChange('level', v.id, e.target.value)
                                }}
                            />
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <TextField
                                variant='outlined'
                                size='small'
                                defaultValue={v.location}
                                onChange={(e) => {
                                    makeChange('location', v.id, e.target.value)
                                }}
                            />
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <TextField
                                variant='outlined'
                                size='small'
                                defaultValue={v.sessions?.join(', ')}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <Button
                                variant='contained'
                                size='small'
                                style={{ margin: 5 }}
                                onClick={() => {
                                    deletePress(v.id)
                                }}
                            >
                                delete
                            </Button>{' '}
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <Button
                                variant='contained'
                                size='small'
                                style={{ margin: 5 }}
                                onClick={() => {
                                    updatePress(v.id)
                                }}
                            >
                                update
                            </Button>{' '}
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <Button
                                variant='contained'
                                size='small'
                                style={{ margin: 5 }}
                                onClick={() => {
                                    linkPress(v.id)
                                }}
                            >
                                link
                            </Button>{' '}
                        </div>
                    ))}
                    {memberships.map((v, i) => (
                        <div key={v.id + ':' + i} style={{ gridRow: i + 5 + ' / span 1', color: '#ccc' }}>
                            {' '}
                            <Button
                                variant='contained'
                                size='small'
                                style={{ margin: 5 }}
                                onClick={() => {
                                    readPress(v.id)
                                }}
                            >
                                read
                            </Button>{' '}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Players
