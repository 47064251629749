import React, { useState, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import SnackbarContent from '@mui/material/SnackbarContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { validate, update } from '../../../forms'

const rootStyles = makeStyles((theme) => ({
    form: {
        width: 700,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
}))

const FieldRow = memo(({ gameType, name, label, value, handleChange, loading, error }) => {
    return (
        <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box width='100%'>
                <Typography>{label}:</Typography>
            </Box>
            <TextField
                sx={{
                    colorScheme: 'dark',
                }}
                width='20%'
                margin='dense'
                size='small'
                id={name}
                name={name}
                type={gameType === 'blackjack' ? 'number' : 'text'}
                inputProps={{
                    step: '0.5',
                }}
                variant='outlined'
                onChange={handleChange}
                value={value}
                disabled={loading}
                error={error}
            />
        </Box>
    )
})

const Tables = ({ publish, device, xstadium }) => {
    const styles = rootStyles({ xstadium })

    const navigate = useNavigate()

    // field flags
    const [submitted, setSubmitted] = useState(false)
    // form changed detect hook
    const [changed, setChanged] = useState(false)
    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)

    const [displayA, setDisplayA] = useState('')
    const [displayB, setDisplayB] = useState('')
    const [displayC, setDisplayC] = useState('')
    const [displayD, setDisplayD] = useState('')
    const [displayE, setDisplayE] = useState('')
    const [displayF, setDisplayF] = useState('')
    const [displayG, setDisplayG] = useState('')
    const [displayH, setDisplayH] = useState('')

    const fields = {
        displayA: {
            error: () => displayA === '',
            value: displayA,
            set: setDisplayA,
            message: 'Display numbers cannot be empty',
        },
        displayB: {
            error: () => displayB === '',
            value: displayB,
            set: setDisplayB,
            message: 'Display numbers cannot be empty',
        },
        displayC: {
            error: () => displayC === '',
            value: displayC,
            set: setDisplayC,
            message: 'Display numbers cannot be empty',
        },
        displayD: {
            error: () => displayD === '',
            value: displayD,
            set: setDisplayD,
            message: 'Display numbers cannot be empty',
        },
        displayE: {
            error: () => displayE === '',
            value: displayE,
            set: setDisplayE,
            message: 'Display numbers cannot be empty',
        },
        displayF: {
            error: () => displayF === '',
            value: displayF,
            set: setDisplayF,
            message: 'Display numbers cannot be empty',
        },
        displayG: {
            error: () => displayG === '',
            value: displayG,
            set: setDisplayG,
            message: 'Display numbers cannot be empty',
        },
        displayH: {
            error: () => displayH === '',
            value: displayH,
            set: setDisplayH,
            message: 'Display numbers cannot be empty',
        },
    }

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            try {
                setLoading(true)
                await publish({
                    ...device.data,
                    displayA: displayA,
                    displayB: displayB,
                    displayC: displayC,
                    displayD: displayD,
                    displayE: displayE,
                    displayF: displayF,
                    displayG: displayG,
                    displayH: displayH,
                })
                setFail('')
                setLoading(false)
            } catch (e) {
                setFail(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setLoading(false)
            }
        }
        setChanged(false)
    }

    const handleChange = (e, setState) => {
        setState(Number(e.target.value.replace(/\D/g, '')))
        setChanged(true)
    }

    const validation = validate(fields)

    if (device.updated !== updated) {
        update(fields, [], device)
        setUpdated(device.updated)
    }

    return (
        <>
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </Box>
                )}
                <FieldRow
                    label='Display number A'
                    name='displayNumberA'
                    value={displayA}
                    handleChange={(e) => handleChange(e, setDisplayA)}
                    loading={loading}
                    error={submitted && fields.displayA.error()}
                />
                <FieldRow
                    label='Display number B'
                    name='displayNumberB'
                    value={displayB}
                    handleChange={(e) => handleChange(e, setDisplayB)}
                    loading={loading}
                    error={submitted && fields.displayB.error()}
                />
                <FieldRow
                    label='Display number C'
                    name='displayNumberC'
                    value={displayC}
                    handleChange={(e) => handleChange(e, setDisplayC)}
                    loading={loading}
                    error={submitted && fields.displayC.error()}
                />
                <FieldRow
                    label='Display number D'
                    name='displayNumberD'
                    value={displayD}
                    handleChange={(e) => handleChange(e, setDisplayD)}
                    loading={loading}
                    error={submitted && fields.displayD.error()}
                />
                <FieldRow
                    label='Display number E'
                    name='displayNumberE'
                    value={displayE}
                    handleChange={(e) => handleChange(e, setDisplayE)}
                    loading={loading}
                    error={submitted && fields.displayE.error()}
                />
                <FieldRow
                    label='Display number F'
                    name='displayNumberF'
                    value={displayF}
                    handleChange={(e) => handleChange(e, setDisplayF)}
                    loading={loading}
                    error={submitted && fields.displayF.error()}
                />
                <FieldRow
                    label='Display number G'
                    name='displayNumberG'
                    value={displayG}
                    handleChange={(e) => handleChange(e, setDisplayG)}
                    loading={loading}
                    error={submitted && fields.displayG.error()}
                />
                <FieldRow
                    label='Display number H'
                    name='displayNumberH'
                    value={displayH}
                    handleChange={(e) => handleChange(e, setDisplayH)}
                    loading={loading}
                    error={submitted && fields.displayH.error()}
                />

                <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={loading}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? device.data.containerType === 'casino'
                                        ? `/xtrend/${device.data.containerType}`
                                        : `/xtrend/${device.data.containerType}/${device.data.containerIndex}`
                                    : device.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    {changed && (
                        <Button variant='contained' type='button' color='primary' disabled={loading} onClick={send}>
                            Update
                        </Button>
                    )}
                </div>

                {loading && (
                    <CircularProgress
                        size={80}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                )}
            </form>
        </>
    )
}

export default Tables
