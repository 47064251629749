export const allRoles = [
    {
        level: 'operatorDashboardUI',
        role: 'operator',
    },
    {
        level: 'operatorDashboardUI',
        role: 'generalManager',
    },
    {
        level: 'operatorDashboardUI',
        role: 'itSupport',
    },
    {
        level: 'dealerUI',
        role: 'dealer',
    },
    {
        level: 'pitbossUI',
        role: 'pitboss',
    },
    {
        level: 'xstadiumPitbossUI',
        role: 'pitbossETG',
    },
]

export const allRolesRoot = [
    {
        level: 'masterUI',
        role: 'masterAdmin',
    },
    {
        level: 'operatorDashboardUI',
        role: 'operator',
    },
    {
        level: 'operatorDashboardUI',
        role: 'generalManager',
    },
    {
        level: 'operatorDashboardUI',
        role: 'itSupport',
    },
    {
        level: 'dealerUI',
        role: 'dealer',
    },
    {
        level: 'pitbossUI',
        role: 'pitboss',
    },
    {
        level: 'xstadiumPitbossUI',
        role: 'pitbossETG',
    },
]

export const posuiUser = ['dealer', 'pitboss']

export const nonPosuiUser = ['masterAdmin', 'operator', 'generalManager', 'itSupport', 'pitbossETG']

export const getLevelTranslation = (level) => {
    switch (level) {
        case 'masterUI':
            return 'Master UI'
        case 'operatorDashboardUI':
            return 'Operator Dashboard UI'
        case 'dealerUI':
            return 'Dealer UI'
        case 'pitbossUI':
            return 'Pitboss UI'
        case 'xstadiumPitbossUI':
            return 'XStadium Pitboss UI '
        default:
            return ''
    }
}

export const getRoleTranslation = (role) => {
    switch (role) {
        case 'masterAdmin':
            return 'Master Admin'
        case 'operator':
            return 'Operator'
        case 'generalManager':
            return 'General Manager'
        case 'itSupport':
            return 'IT Admin'
        case 'dealer':
            return 'Dealer'
        case 'pitboss':
            return 'Pitboss'
        case 'pitbossETG':
            return 'Pitboss ETG'
        default:
            return ''
    }
}
