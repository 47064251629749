import React, { useState, Fragment } from 'react'
import { startCase } from 'lodash'
import LetteredAvatar from 'react-lettered-avatar'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles, useTheme } from '@mui/styles'

import { fetch, authApi } from '../../api'
import { authIP } from '../../config'
import Table from '../../table'
import useAsyncState from '../../useAsyncState'
import { allRoles, allRolesRoot,getLevelTranslation, getRoleTranslation } from '../../util/Roles'
import View from '../../View'
import UserView from './UserView'

const TabContainer = ({ children, dir }) => (
    <Typography component='div' dir={dir}>
        {children}
    </Typography>
)

const rootStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        background: 'transparent',
        overflowY: 'auto',
    },
    disablePointer: {
        pointerEvents: 'none',
    },
    tabRoot: {
        display: 'flex',
        flex: '1 1',
    },
    tabsContainer: {
        paddingTop: 40,
    },
    searchInput: {
        width: '22em',
        position: 'absolute',
        top: '1em',
        right: '0.8em',
    },
    closeDrawerButton: {
        width: 40,
        position: 'absolute',
        right: 2,
        zIndex: 9999999,
        top: '2.8em',
    },
    buttonNewUser: {
        marginBottom: 20,
        position: 'absolute',
        top: '2.4em',
        right: '28em',
    },
    empty: {
        padding: '2em',
        fontSize: '0.83em',
        color: '#959595',
    },
}))

const Users = ({ menuOpen, setMenuOpen }) => {
    const loggedInAccount = window.localStorage.getItem('account')
    const loggedInRole = window.localStorage.getItem('role')
    const isAdmin = loggedInRole === 'root' || loggedInRole === 'itSupport' || loggedInRole === 'masterAdmin'
    const products = window.localStorage.getItem('products') ? window.localStorage.getItem('products').split(',') : []
    const navigate = useNavigate()
    const [users, setUsers] = useState(null)
    const [fetched, setFetched] = useState(null)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const lights = window.localStorage.getItem('lights') === 'on'
    const styles = rootStyles({ lights })
    const showAllRoles = isAdmin && (loggedInRole === 'masterAdmin' || loggedInRole === 'root')

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    // tabs
    const [currentTab, setCurrentTab] = useState(0)

    //search results
    const [searchTerm, setSearchTerm] = useState('')

    const getUsers = async () => {
        if (!fetched) {
            setFetched(true)
            try {
                const response = await fetch(authIP + '/users')
                setTimeout(() => {
                    if (isAdmin && loggedInRole !== 'itSupport') {
                       setUsers(response)
                    } else if (isAdmin && loggedInRole === 'itSupport')  {
                        setUsers(response.filter((user) => user.roles[0] !== 'masterAdmin'))
                    } else {
                        setUsers(response.filter((user) => user.account === loggedInAccount))
                    }
                }, 400)
            } catch (e) {
                console.error(e)
            }
        }
    }

    getUsers()

    // policies
    const [fetchedPolicies, setFetchedPolicies] = useAsyncState(false)
    const [lockAfterFailureCount, setLockAfterFailureCount] = useAsyncState('')

    const fetchPolicies = async () => {
        if (fetchedPolicies) {
            return
        }

        setFetchedPolicies(true)
        try {
            const token = window.localStorage.getItem('token')
            const response = await authApi
                .get('policies', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                .json()
            await setLockAfterFailureCount(response.lockAfterFailureCount)
        } catch (e) {
            console.warn(e)
        }
    }

    fetchPolicies()

    const currentProduct = currentTab === 0 ? 'all' : products[currentTab - 1]
    const filteredUsers = users
        ? users.filter(
              (user) =>
                  (currentProduct === 'all' || (user.products && user.products.indexOf(currentProduct) >= 0)) &&
                  (searchTerm === '' || JSON.stringify(user).indexOf(searchTerm) >= 0)
          )
        : []
        
    const typeRoles = showAllRoles ? allRolesRoot : allRoles
    const mappedUsers = filteredUsers.map((user) => ({
        account: user.account,
        '': <LetteredAvatar size={50} name={user.firstName} />,
        'FIRST NAME': user.firstName,
        'LAST NAME': user.lastName,
        USERNAME: user.account,
        'LEVEL(S)': typeRoles
            .filter((el) => user.roles.includes(el.role))
            .map((el) => getLevelTranslation(el.level))
            .join(', '),
        'PRODUCT(S)': user.products.map((el) => startCase(el)).join(', '),
        'ROLE(S)': user.roles.map((el) => getRoleTranslation(el)).join(', '),
    }))

    const findUser = (account) => {
        for (let user of users) {
            if (user.account === account) {
                return user
            }
        }

        return {}
    }

    return (
        <Box component='main' sx={View(menuOpen, theme)}>
            <Tabs
                className={styles.tabsContainer}
                onChange={(_e, index) => {
                    setCurrentTab(index)
                }}
                value={currentTab}
                classes={{ indicator: styles.tabIndicator }}
                indicatorColor='primary'
                textColor='primary'
                variant={mobile ? 'fullWidth' : 'standard'}
            >
                <Tab disableTouchRipple label='All' />
                {products.map((product) => (
                    <Tab key={product} label={product} disableTouchRipple />
                ))}
            </Tabs>
            {!users && <LinearProgress />}
            {isAdmin && (
                <Button
                    variant='contained'
                    className={styles.buttonNewUser}
                    onClick={() => {
                        navigate('/xuser/users/new')
                    }}
                    color='primary'
                >
                    <AddIcon />
                    Add user
                </Button>
            )}
            <TextField
                className={styles.searchInput}
                variant='outlined'
                id='search-users'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Drawer
                hideBackdrop
                anchor={'right'}
                variant={'persistent'}
                open={drawerOpen && !menuOpen}
                onClose={() => {
                    setSelectedIndex(null)
                    setSelectedUser(null)
                    setDrawerOpen(false)
                }}
            >
                <IconButton
                    color='default'
                    className={styles.closeDrawerButton}
                    onClick={() => {
                        setSelectedIndex(null)
                        setDrawerOpen(false)
                    }}
                    aria-label='close drawer'
                    component='label'
                >
                    <CloseIcon />
                </IconButton>
                {selectedUser !== null && (
                    <UserView
                        users={users}
                        user={selectedUser}
                        lockAfterFailureCount={lockAfterFailureCount}
                        goBack={() => setDrawerOpen(false)}
                    />
                )}
            </Drawer>
            <TabContainer dir={theme.direction}>
                {users && (
                    <Fragment>
                        {currentProduct !== 'all' &&
                            filteredUsers &&
                            filteredUsers.length === 0 &&
                            searchTerm === '' && (
                                <Typography className={styles.empty} component='p'>
                                    There are no users matching with {startCase(currentProduct)} product
                                </Typography>
                            )}
                        {currentProduct !== 'all' &&
                            filteredUsers &&
                            filteredUsers.length === 0 &&
                            searchTerm !== '' && (
                                <Typography className={styles.empty} component='p'>
                                    There are no users matching with {startCase(currentProduct)} product and search term{' '}
                                    {searchTerm}
                                </Typography>
                            )}
                        {currentProduct === 'all' &&
                            filteredUsers &&
                            filteredUsers.length === 0 &&
                            searchTerm !== '' && (
                                <Typography className={styles.empty} component='p'>
                                    There are no users matching with search term {searchTerm}
                                </Typography>
                            )}
                        {currentProduct === 'all' &&
                            filteredUsers &&
                            filteredUsers.length === 0 &&
                            searchTerm === '' && (
                                <Typography className={styles.empty} component='p'>
                                    There are no users registered yet
                                </Typography>
                            )}
                        {filteredUsers && filteredUsers.length >= 1 && (
                            <Table
                                style={{ tableLayout: 'fixed' }}
                                rows={mappedUsers}
                                hiddenFields={['account']}
                                selectedIndex={selectedIndex}
                                link={(row, index) => {
                                    setMenuOpen(false)
                                    setSelectedIndex(index)
                                    setSelectedUser(findUser(row['account']))
                                    setDrawerOpen(true)
                                }}
                            />
                        )}
                    </Fragment>
                )}
            </TabContainer>
        </Box>
    )
}

export default Users
