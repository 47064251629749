import React, { useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SearchIcon from '@mui/icons-material/Search'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled, alpha } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'

import { useSubscribe, usePublish } from '../../api'
import { xstadiumApi } from '../../api'
import { pivotIP } from '../../config'
import { validate } from '../../forms'
import AlertDialog from '../../xtrend/components/AlertDialog'
import { reservedFields } from '../../xtrend/devices/tree/TreeNav'

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}))

const Servers = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const navigate = useNavigate()

    const [status, statusSocket] = useSubscribe('status/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')

    const devicePublish = usePublish('devices/*')

    const [mainDisplayRebooting, setMainDisplayRebooting] = useState(false)
    const [allDisplaysRebooting, setAllDisplaysRebooting] = useState(false)
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [ip, setIP] = useState('')
    const [port, setPort] = useState('')
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [currentRow, setCurrentRow] = useState('')

    // dialog
    const [dialogOpen, setDialogOpen] = useState(false)

    const handleClickOpen = () => {
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false)
        setSubmitted(false)
        setType('')
        setName('')
        setIP('')
        setPort('')
    }

    // menu
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
    })

    const active =
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN

    if (!active || !status || !tables || !devices) {
        return <LinearProgress />
    }

    const MIN_WAIT_DELAY = 400

    const rebootMainDisplay = async () => {
        setMainDisplayRebooting(true)
        try {
            const response = await xstadiumApi.get('watchout/reboot/main')
            if (response.status !== 200) {
                throw Error('reboot failed')
            }
            setTimeout(() => {
                setMainDisplayRebooting(false)
            }, 1500)
        } catch (e) {
            console.warn(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to reboot main display. Please try again.',
                error: true,
            })
            setMainDisplayRebooting(false)
        }
    }

    const rebootAllDisplays = async () => {
        setAllDisplaysRebooting(true)
        try {
            const response = await xstadiumApi.get('watchout/reboot/cascade')
            if (response.status !== 200) {
                throw Error('reboot failed')
            }
            setTimeout(() => {
                setAllDisplaysRebooting(false)
            }, MIN_WAIT_DELAY)
        } catch (e) {
            console.warn(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to reboot all displays. Please try again.',
                error: true,
            })
            setAllDisplaysRebooting(false)
        }
    }

    const fields = {
        type: {
            error: () => type.length === 0,
            value: type,
            set: setType,
            message: 'Server type cannot be empty',
        },
        name: {
            error: () =>
                name.trim().length < 5 ||
                reservedFields.includes(name.trim().toLowerCase()) ||
                devices.filter((v) => v.data.name === name).length > 0,
            value: name,
            set: setName,
            message:
                (name.trim().length < 5 && 'Server name cannot be less than 5 characters long') ||
                (reservedFields.includes(name.trim().toLowerCase()) && 'Invalid server name, please choose another') ||
                (devices.filter((v) => v.data.name === name).length > 0 && 'Server name is already used'),
        },
        ip: {
            error: () =>
                ip.trim().length < 5 ||
                devices.filter((v) => v.data.ip + ':' + v.data.port === ip + ':' + port).length > 0,
            value: ip,
            set: setIP,
            message:
                ip.trim().length < 5 ? 'IP cannot be less than 5 characters' : 'IP + Port is used on another device',
        },
        port: {
            error: () =>
                port.trim().length < 2 ||
                devices.filter((v) => v.data.ip + ':' + v.data.port === ip + ':' + port).length > 0,
            value: port,
            set: setPort,
            message:
                port.trim().length < 2
                    ? 'Port cannot be less than 2 characters'
                    : 'IP + Port is used on another device',
        },
    }

    const validation = validate(fields)

    const addServer = async () => {
        try {
            let serverData = {
                name: name,
                containerIndex: 'root',
                containerType: 'casino',
                type: type,
                ip: ip,
                port: port,
                updatedBy: window.localStorage.getItem('account') ?? '-',
            }
            if (type === 'xstadiumRotator') {
                serverData.timerRotation = 20
            }
            await devicePublish(serverData)
        } catch (e) {
            console.warn(e)
        }
    }

    const proceed = async () => {
        setSubmitted(true)
        if (!validation.error) {
            setSubmitted(false)
            setLoading(true)
            try {
                await addServer()
                handleClose()
            } catch (e) {
                console.warn(e)
                setAlertDialogState({
                    ...alertDialogState,
                    show: true,
                    type: 'error',
                    message: 'Unable to add server. Please try again.',
                    error: true,
                })
            }
            setLoading(false)
        }
    }

    const dateDisplay = (time) => moment.unix(time / 1000000000).format('YYYY-MM-DD')

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            color: params.value === true ? '#00ff00' : '#ff1111',
                        }}
                    >
                        {params.value === true ? 'Online' : 'Offline'}
                    </Box>
                )
            },
            width: 120,
        },
        {
            field: 'serverType',
            headerName: 'Server Type',
            renderCell: (params) => {
                return (
                    <>
                        {params.value === '-' && <>-</>}
                        {params.value === 'xstadiumContent' && <>XStadium Content</>}
                        {params.value === 'xstadiumRotator' && <>XStadium Rotator</>}
                        {params.value === 'xstadiumOthers' && <>XStadium Others</>}
                    </>
                )
            },
            width: 235,
        },
        { field: 'serverName', headerName: 'Server Name', width: 235 },
        { field: 'serverIP', headerName: 'Server IP', width: 235 },
        { field: 'serverPort', headerName: 'Server Port', width: 235 },
        {
            field: 'lastUpdatedOn',
            headerName: 'Last Updated On',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{dateDisplay(params.value)}</>}</>
            },
            width: 310,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 310,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        <IconButton
                            onClick={(event) => {
                                setCurrentRow(params.row)
                                setAnchorEl(event.currentTarget)
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </>
                )
            },
        },
    ]

    const rows = devices
        ? devices
            .filter((device) => device.data.type === 'xstadiumContent' ||
                device.data.type === 'xstadiumRotator' ||
                device.data.type === 'xstadiumOthers')
            .filter((playlist) => JSON.stringify(playlist).indexOf(searchTerm) >= 0)
            .map((device) => {
                return {
                    id: device.index ? device.index : '-',
                    containerIndex: device.data.containerIndex ? device.data.containerIndex : '-',
                    status: status.find((status) => status.index === device.index)
                        ? status.find((status) => status.index === device.index).data.on
                        : '-',
                    serverType: device.data.type ? device.data.type : '-',
                    serverName: device.data.name ? device.data.name : '-',
                    serverIP: device.data.ip ? device.data.ip : '-',
                    serverPort: device.data.port ? device.data.port : '-',
                    lastUpdatedOn: device.updated ? device.updated : device.created,
                    lastUpdatedBy: device.data.updatedBy ? device.data.updatedBy : '-',
                }
            })
        : []

    const rebootDevice = async (deviceID) => {
        try {
            await fetch(`http://${pivotIP}/remote/reboot/${deviceID}`)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to reboot device. Please try again.',
                error: true,
            })
        }
    }

    const shutdownDevice = async (deviceID) => {
        try {
            await fetch(`http://${pivotIP}/remote/shutdown/${deviceID}`)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to shut down device. Please try again.',
                error: true,
            })
        }
    }

    return (
        <Box padding='2rem' display='flex' flexDirection='column' gap='1.25rem'>
            <Box
                display='flex'
                flexDirection={mobile ? 'column' : 'row'}
                alignItems='center'
                justifyContent='space-between'
                gap='1rem'
            >
                <Box sx={{ width: mobile ? '100%' : 'auto' }}>
                    <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                        Servers
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                        Control shutdown and reboot of X Stadium devices
                    </Typography>
                </Box>

                <Box
                    width={mobile ? '100%' : 'auto'}
                    display='flex'
                    flexDirection={mobile ? 'column' : 'row'}
                    alignItems='center'
                    gap='1rem'
                >
                    <LoadingButton
                        sx={{ width: mobile ? '100%' : 'auto' }}
                        variant='outlined'
                        color='primary'
                        onClick={rebootMainDisplay}
                        loading={mainDisplayRebooting}
                        disabled={allDisplaysRebooting}
                    >
                        Reboot Main Display
                    </LoadingButton>

                    <LoadingButton
                        sx={{ width: mobile ? '100%' : 'auto' }}
                        variant='outlined'
                        color='primary'
                        onClick={rebootAllDisplays}
                        loading={allDisplaysRebooting}
                        disabled={mainDisplayRebooting}
                    >
                        Reboot All Displays
                    </LoadingButton>

                    <Button
                        sx={{ width: mobile ? '100%' : 'auto' }}
                        variant='contained'
                        color='primary'
                        onClick={handleClickOpen}
                    >
                        <AddIcon />
                        &nbsp; Add Server
                    </Button>

                    <TextField
                        sx={{ width: mobile ? '100%' : 'auto' }}
                        variant='outlined'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>

            <Box>
                <DataGrid
                    sx={{
                        colorScheme: 'dark',
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                            outline: 'none',
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    onRowDoubleClick={(params) => navigate(`/xstadium/device/${params.row.id}`)}
                    autoHeight
                />

                <StyledMenu
                    id='actions-menu'
                    MenuListProps={{
                        'aria-labelledby': 'actions-menu-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        onClick={() => {
                            navigate(`/xstadium/device/${currentRow.id}`)
                            setAnchorEl(null)
                        }}
                        disableRipple
                    >
                        <EditIcon />
                        Edit
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                        onClick={() => {
                            rebootDevice(currentRow.id)
                            setAnchorEl(null)
                        }}
                        disableRipple
                    >
                        <RestartAltIcon />
                        Reboot Device
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            shutdownDevice(currentRow.id)
                            setAnchorEl(null)
                        }}
                        disableRipple
                    >
                        <PowerSettingsNewIcon />
                        Shutdown Device
                    </MenuItem>
                </StyledMenu>
            </Box>

            <Dialog
                key='addServerDialog'
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby='add-server-dialog-title'
                aria-describedby='add-server-dialog-description'
                PaperProps={{
                    sx: {
                        width: '25rem',
                    },
                }}
            >
                <DialogTitle id='add-server-dialog-title'>Add Server</DialogTitle>
                <DialogContent sx={{ overflowY: 'visible' }}>
                    <Box display='flex' flexDirection='column' gap='1rem'>
                        <FormControl
                            sx={{
                                display: 'flex',
                            }}
                            variant='outlined'
                            autoComplete='off'
                            error={submitted && fields.type.error()}
                        >
                            <Typography>Server Type</Typography>
                            <Select
                                id='server-type'
                                inputProps={{
                                    name: 'type',
                                    id: 'type',
                                }}
                                required
                                fullWidth
                                value={type}
                                onChange={(e) => {
                                    setType(e.target.value)
                                }}
                                size='small'
                            >
                                <MenuItem value='xstadiumContent'>XStadium Content</MenuItem>
                                <MenuItem value='xstadiumRotator'>XStadium Rotator</MenuItem>
                                <MenuItem value='xstadiumOthers'>XStadium Others</MenuItem>
                            </Select>
                            {submitted && fields.type.error() && <FormHelperText>{fields.type.message}</FormHelperText>}
                        </FormControl>

                        <Box>
                            <Typography>Server Name*</Typography>
                            <TextField
                                required
                                size='small'
                                id='name'
                                name='name'
                                type='text'
                                fullWidth
                                variant='outlined'
                                onChange={(e) => {
                                    setName(e.target.value.replace(/[\WA-Z ]/g, ''))
                                }}
                                value={name}
                                disabled={loading}
                                error={submitted && fields.name.error()}
                                helperText={submitted && fields.name.error() ? fields.name.message : null}
                                autoComplete='off'
                            />
                        </Box>

                        <Box>
                            <Typography>Server IP*</Typography>
                            <TextField
                                required
                                size='small'
                                id='ip'
                                name='ip'
                                type='text'
                                fullWidth
                                variant='outlined'
                                onChange={(e) => {
                                    setIP(e.target.value)
                                }}
                                value={ip}
                                disabled={loading}
                                error={submitted && fields.ip.error()}
                                helperText={submitted && fields.ip.error() ? fields.ip.message : null}
                                autoComplete='off'
                            />
                        </Box>

                        <Box>
                            <Typography>Server Port*</Typography>
                            <TextField
                                required
                                size='small'
                                id='port'
                                name='port'
                                type='text'
                                fullWidth
                                variant='outlined'
                                onChange={(e) => {
                                    setPort(e.target.value.replace(/\D/g, ''))
                                }}
                                value={port}
                                disabled={loading}
                                error={submitted && fields.port.error()}
                                helperText={submitted && fields.port.error() ? fields.port.message : null}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton color='primary' loading={loading} onClick={proceed}>
                        Proceed
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </Box>
    )
}

export default Servers
