import React, { useState } from 'react'

const Timer = ({ time, device, games, game }) => {
    const [timerEnd, setTimerEnd] = useState(0)
    const [timerStart, setTimerStart] = useState(0)
    const [numberOfGames, setNumberOfGames] = useState(0)
    const timerDuration = device.data.bet_timer

    const showTimer = (timeInSeconds) => {
        if (game.data.status === 'ongoing') {
            return ''
        }

        if (game.data.timerStop) {
            return 'stopped'
        }

        if (timerEnd - timeInSeconds > 0) {
            return timerEnd - timeInSeconds
        }

        return ''
    }

    if (numberOfGames !== games.length) {
        setNumberOfGames(games.length)
        // deck change
        if (games.length === 0) {
            setTimerEnd(0)
            setTimerStart(0)
        }
    }

    const timeInSeconds = Math.round(time / 1000000000)
    // deck change or first game
    if (games.length === 0 && timerEnd === 0) {
        setTimerEnd(timeInSeconds + timerDuration)
        setTimerStart(time)
    }

    // previous game ended time
    if (games.length > 0) {
        const lastGameEnded = games[games.length - 1].data.ended
        const lastGameTimeEndedInSeconds = Math.floor(games[games.length - 1].data.ended / 1000000000)
        const nextEnd = lastGameTimeEndedInSeconds + timerDuration
        if (nextEnd > timeInSeconds && timerStart < lastGameEnded) {
            setTimerEnd(lastGameTimeEndedInSeconds + timerDuration)
            setTimerStart(lastGameEnded)
        }
    }

    const timerEndInNanos = timerEnd * 1000000000
    if (
        (game.data.timerRestarted > timerStart && game.data.timerRestarted < timerEndInNanos) ||
        game.data.timerRestarted > timerEndInNanos
    ) {
        const restartTimeSeconds = Math.floor(game.data.timerRestarted / 1000000000)
        setTimerEnd(restartTimeSeconds + timerDuration)
        setTimerStart(game.data.timerRestarted)
    }

    return <div>{showTimer(timeInSeconds)}</div>
}

export default Timer
