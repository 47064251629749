import React, { useContext, useState, useReducer } from "react";
import Button from "@mui/material/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { formatCashString } from "../util/misc";
import { DUIContext } from "./Dealer";
import PitBossConfirmation from "./PitBossConfirmation";
import DealerConfirmation from "./DealerConfirmation";

const ChipsRefill = () => {
    const {
        setShowChipsRefill,
        callApiPublishChipfill
    } = useContext(DUIContext)

    const [modalObj, setModalObj] = useState({ show: false, modalMsg: '', requirePitBoss: false, confirm: () => { } })



    const [inputState, setInputState] = useReducer((state, obj) => {
        if (obj.key === 'D') return state.substring(0, state.length - 1).length > 0 ? state.substring(0, state.length - 1) : '0'
        if (state === '0') return obj.key
        return state + '' + obj.key
    }, '0')




    const keyPress = (key) => {
        if ((key === 'T' || key === 'F' || key === 'C') && Number(inputState) <= 0) {
            setShowChipsRefill(false)
            return
        }
        if (key === 'T') {
            setModalObj({
                show: true,
                modalMsg: 'Chip Take Away: $' + formatCashString(Number(inputState)),
                requirePitBoss: true,
                confirm: () => callApiPublishChipfill({ data: { amount: Number(inputState) }, success: () => { setShowChipsRefill(false) }, fail: () => { console.log('Api post fail, unable to send chip fill') } })
            })
            return
        }
        if (key === 'F') {
            setModalObj({
                show: true,
                modalMsg: 'Chip Fill: $' + formatCashString(Number(inputState)),
                requirePitBoss: true,
                confirm: () => callApiPublishChipfill({ data: { amount: Number(inputState) }, success: () => { setShowChipsRefill(false) }, fail: () => { console.log('Api post fail, unable to send chip fill') } })
            })
            return
        }

        setInputState({ key: key })
    }


    return <div style={{ height: '100%', width: '100%' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
        <div style={{ position: 'relative', width: '60vh', height: '60vh', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#333', display: 'grid', gridTemplateRows: 'repeat(5, 1fr)', gridTemplateColumns: 'repeat(4, 1fr)' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <div onClick={() => setShowChipsRefill(false)} className="modalOffTigger" style={{ position: 'absolute', top: -50, right: -50, height: 80, width: 80, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                <HighlightOffIcon style={{ fontSize: 50, color: 'white' }} />
            </div>

            <div style={{ gridColumn: '1 / span 4', gridRow: '1 / span 1', backgroundColor: '#000' }}>
                <input style={{ height: '100%', width: '100%', padding: '10px', textAlign: 'right', fontSize: '3vw', boxSizing: 'border-box', backgroundColor: '#000', color: '#fff', border: 'none' }} value={'$' + formatCashString(inputState)} readOnly />
            </div>
            <div style={{ gridRow: '2 /span 1', gridColumn: '1 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('7') }}>7</Button></div>
            <div style={{ gridRow: '3 /span 1', gridColumn: '1 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('4') }}>4</Button></div>
            <div style={{ gridRow: '4 /span 1', gridColumn: '1 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('1') }}>1</Button></div>
            <div style={{ gridRow: '5 /span 1', gridColumn: '1 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('0') }}>0</Button></div>

            <div style={{ gridRow: '2 /span 1', gridColumn: '2 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('8') }}>8</Button></div>
            <div style={{ gridRow: '3 /span 1', gridColumn: '2 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('5') }}>5</Button></div>
            <div style={{ gridRow: '4 /span 1', gridColumn: '2 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('2') }}>2</Button></div>
            <div style={{ gridRow: '5 /span 1', gridColumn: '2 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('D') }}>Del</Button></div>

            <div style={{ gridRow: '2 /span 1', gridColumn: '3 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('9') }}>9</Button></div>
            <div style={{ gridRow: '3 /span 1', gridColumn: '3 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('6') }}>6</Button></div>
            <div style={{ gridRow: '4 /span 1', gridColumn: '3 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('3') }}>3</Button></div>
            {/* <div style={{ gridRow: '5 /span 1', gridColumn: '3 / span 1' }}><Button style={{ height: '100%', width: '100%', fontSize: '2vw' }} onClick={() => { keyPress('.') }}>.</Button></div> */}

            <div style={{ gridRow: '2 /span 2', gridColumn: '4 / span 1' }}>
                <Button style={{ height: '100%', width: '100%', fontSize: '2vw', backgroundColor: 'green' }} onClick={() => { keyPress('T') }}>Take Away</Button>
            </div>
            <div style={{ gridRow: '4 /span 2', gridColumn: '4 / span 1' }}>
                <Button style={{ height: '100%', width: '100%', fontSize: '2vw', backgroundColor: '#ac0000' }} onClick={() => { keyPress('F') }}>Fill</Button>
            </div>

        </div>

        {modalObj.show && modalObj.requirePitBoss && <PitBossConfirmation modalObj={modalObj} setModalObj={setModalObj} />}
        {modalObj.show && !modalObj.requirePitBoss && <DealerConfirmation modalObj={modalObj} setModalObj={setModalObj} />}


    </div>
}

export default ChipsRefill