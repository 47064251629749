import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import SuitC from "../dealer/assets/club.png";
import SuitD from "../dealer/assets/diamond.png";
import SuitH from "../dealer/assets/heart.png";
import SuitS from "../dealer/assets/spade.png";

import D11 from "../dealer/assets/d_j.png";
import D12 from "../dealer/assets/d_q.png";
import D13 from "../dealer/assets/d_k.png";

import C11 from "../dealer/assets/c_j.png";
import C12 from "../dealer/assets/c_q.png";
import C13 from "../dealer/assets/c_k.png";

import S11 from "../dealer/assets/s_j.png";
import S12 from "../dealer/assets/s_q.png";
import S13 from "../dealer/assets/s_k.png";

import H11 from "../dealer/assets/h_j.png";
import H12 from "../dealer/assets/h_q.png";
import H13 from "../dealer/assets/h_k.png";

import cardBackBanker from "../dealer/assets/card_back_banker.png";
import cardBackPlayer from "../dealer/assets/card_back_player.png";

const rootStyles = makeStyles((theme) => ({
    outline: { border: '0px solid #000' },
    face: {
        color: 'blue'
    },

    suit1: {
        background: `url(${SuitS}) no-repeat center center / contain`
    },
    suit2: {
        background: `url(${SuitH}) no-repeat center center / contain`
    },
    suit3: {
        background: `url(${SuitC}) no-repeat center center / contain`
    },
    suit4: {
        background: `url(${SuitD}) no-repeat center center / contain`
    },


    s1_11: {
        background: `url(${S11}) no-repeat center center / contain`
    },
    s1_12: {
        background: `url(${S12}) no-repeat center center / contain`
    },
    s1_13: {
        background: `url(${S13}) no-repeat center center / contain`
    },

    s2_11: {
        background: `url(${H11}) no-repeat center center / contain`
    },
    s2_12: {
        background: `url(${H12}) no-repeat center center / contain`
    },
    s2_13: {
        background: `url(${H13}) no-repeat center center / contain`
    },

    s3_11: {
        background: `url(${C11}) no-repeat center center / contain`
    },
    s3_12: {
        background: `url(${C12}) no-repeat center center / contain`
    },
    s3_13: {
        background: `url(${C13}) no-repeat center center / contain`
    },

    s4_11: {
        background: `url(${D11}) no-repeat center center / contain`
    },
    s4_12: {
        background: `url(${D12}) no-repeat center center / contain`
    },
    s4_13: {
        background: `url(${D13}) no-repeat center center / contain`
    },

    suitRow: {
        gridColumn: '1 /span 1', display: 'flex', alignItems: 'center', justifyContent: 'center'
    },

    red: {
        color: '#cd0600'
    },
    black: {
        color: 'black'
    },
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    },
    hiddenCard: {
        fontSize: 30
    },
    readyCard: {
        fontSize: 16,
        letterSpacing: 1
    },
    countDownBankerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: `url(${cardBackBanker}) no-repeat center center / cover`,
        fontSize: '19px',
        color: '#370505',
        '& div': {
            backgroundColor: '#000',
            width: '9vh',
            height: '9vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.6',
            borderRadius: '50%',
            color: 'white',
        }

    },
    countDownBankerHorizontal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: `url(${cardBackBanker}) no-repeat center center / cover`,
        fontSize: '19px',
        color: '#370505',
        '& div': {
            transform: 'rotate(-90deg)',
            textAlign: 'center',
            backgroundColor: '#000',
            width: '9vh',
            height: '9vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.6',
            borderRadius: '50%',
            color: 'white',
        }

    },

    countDownPlayerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: `url(${cardBackPlayer}) no-repeat center center / cover`,
        fontSize: '19px',
        color: '#00243f',
        '& div': {
            backgroundColor: '#000',
            width: '9vh',
            height: '9vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.6',
            borderRadius: '50%',
            color: 'white',
        }
    },
    countDownPlayerHorizontal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: `url(${cardBackBanker}) no-repeat center center / cover`,
        fontSize: '19px',
        color: '#00243f',
        '& div': {
            transform: 'rotate(-90deg)',
            textAlign: 'center',
            backgroundColor: '#000',
            width: '9vh',
            height: '9vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.6',
            borderRadius: '50%',
            color: 'white',
        }
    },

    counterDiv: {
        backgroundColor: '#000',
        width: '9vh',
        height: '9vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: '0.6',
        borderRadius: '50%',
        color: 'white',
        fontSize: '29px'
    }

}))


const Card = ({ id, suit, value, reveal, revealTimer, apiRevealPerCard = null }) => {
    //reveal = 'hidden', 'ready', 'show'

    // 1s
    // 2h
    // 3c
    // 4d
    const s = rootStyles()

    const getCardCoverStyle = () => {
        if (id === 'b1' || id === 'b2') {
            return s.countDownBankerVertical
        }


        if (id === 'p1' || id === 'p2') {
            return s.countDownPlayerVertical
        }
        if (id === 'p3') {
            return s.countDownPlayerHorizontal
        }
        if (id === 'b3') {
            return s.countDownBankerHorizontal
        }
    }

    const getClassName = (...k) => {
        return k.join(' ')
    }

    const getFace = (suit, value) => {
        return value > 10 ? s['s' + suit + '_' + value] : ''
    }

    const suitColumn = (col, suit, value) => {
        const LRCol = [[0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [1, 0, 0, 0, 0, 0, 1], [1, 0, 0, 0, 0, 0, 1], [1, 0, 0, 1, 0, 0, 1], [1, 0, 0, 1, 0, 0, 1], [1, 0, 0, 1, 0, 0, 1], [1, 0, 1, 0, 1, 0, 1], [1, 0, 1, 0, 1, 0, 1]]
        const CCol = [[0, 0, 0, 1, 0, 0, 0], [1, 0, 0, 0, 0, 0, 1], [1, 0, 0, 1, 0, 0, 1], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 1, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 1, 0, 0, 0, 0, 0], [0, 1, 0, 0, 0, 1, 0], [0, 0, 0, 1, 0, 0, 0], [0, 1, 0, 0, 0, 1, 0]]
        const isVisible = (col, value, row) => {
            const arr = col === 'C' ? CCol : LRCol
            return !value ? s.hidden : value > 10 ? s.hidden : arr[value - 1][row - 1] ? s.visible : s.hidden
        }
        return (
            <div className={getClassName(s.outline)} style={{ display: 'grid', gridTemplateRows: '8% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 8%', gridTemplateColumns: 'auto' }}>
                <div className={getClassName(s.outline, s.suitRow)} style={{ gridRow: '1 / span 1' }}></div>
                <div className={getClassName(isVisible(col, value, 1), s.outline, s.suitRow, s['suit' + suit])} style={{ gridRow: '2 / span 2' }}></div>
                <div className={getClassName(isVisible(col, value, 2), s.outline, s.suitRow, s['suit' + suit])} style={{ gridRow: '3 / span 2' }}></div>
                <div className={getClassName(isVisible(col, value, 3), s.outline, s.suitRow, s['suit' + suit])} style={{ gridRow: '4 / span 2' }}></div>
                <div className={getClassName(isVisible(col, value, 4), s.outline, s.suitRow, s['suit' + suit])} style={{ gridRow: '5 / span 2' }}></div>
                <div className={getClassName(isVisible(col, value, 5), s.outline, s.suitRow, s['suit' + suit])} style={{ gridRow: '6 / span 2' }}></div>
                <div className={getClassName(isVisible(col, value, 6), s.outline, s.suitRow, s['suit' + suit])} style={{ gridRow: '7 / span 2' }}></div>
                <div className={getClassName(isVisible(col, value, 7), s.outline, s.suitRow, s['suit' + suit])} style={{ gridRow: '8 / span 2' }}></div>
                <div className={getClassName(s.outline, s.suitRow)} style={{ gridRow: '10 / span 1' }}></div>
            </div>
        )
    }

    const displayValue = (value) => {
        return value === 1 ? 'A' : value < 11 ? value : value === 11 ? 'J' : value === 12 ? 'Q' : value === 13 ? 'K' : ''
    }
    const getSuitColor = () => {
        return suit === 2 || suit === 4 ? s.red : s.black
    }

    const miniCard = <div className={getSuitColor()} style={{ textAlign: "center", fontSize: '3vh', fontWeight: 500 }}> {displayValue(value)} <div className={getClassName(s['suit' + suit])} style={{ height: '3vh' }}>&nbsp;</div></div>

    const fullCard = (<div className={getSuitColor()} style={{ display: 'grid', gridTemplateColumns: '15% auto 15%', height: '100%' }} >
        <div className={getClassName(s.outline)} style={{ textAlign: "center", padding: '10% 0', fontSize: '3vh', fontWeight: 500 }}>
            {displayValue(value)}
            <div className={getClassName(s['suit' + suit])}>&nbsp;</div>
        </div>
        <div className={getClassName(getFace(suit, value))} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
            {suitColumn('L', suit, value)}
            {suitColumn('C', suit, value)}
            {suitColumn('R', suit, value)}
        </div>
        <div className={getClassName(s.outline)} style={{ textAlign: "center", padding: '10% 0', fontSize: '3vh', fontWeight: 500, transform: 'rotate(180deg)' }}>
            {displayValue(value)}
            <div className={getClassName(s['suit' + suit])}>&nbsp;</div>
        </div>
    </div>)

    const hiddenCard = <div className={getClassName(s.hiddenCard, getCardCoverStyle())}>&nbsp;</div>
    const readyCard = <div className={getClassName(s.readyCard, getCardCoverStyle())}><div>REVEAL</div></div>
    const [counter, setCount] = useState(revealTimer)
    const countDownCard = <div className={getCardCoverStyle()}><div className={s.counterDiv}>{counter}</div></div>



    const countDown = () => {
        if (counter === 0) {
            return false
        } else {
            setTimeout(() => {
                setCount(counter - 1)
            }, 1000)
            return true
        }

    }


    if (reveal === "hidden" && counter !== revealTimer) {
        setCount(revealTimer)
    }

    if (reveal === 'countdown_flop' && (id === 'b1' || id === 'b2' || id === 'p1' || id === 'p2')) {
        if (countDown()) return countDownCard
        if (apiRevealPerCard) apiRevealPerCard(['b1', 'b2', 'p1', 'p2'])
    }

    if (reveal === 'countdown_plusB' && id === 'b3') {
        if (countDown()) return countDownCard
        if (apiRevealPerCard) apiRevealPerCard(id)
    }
    if (reveal === 'countdown_plusP' && id === 'p3') {
        if (countDown()) return countDownCard
        if (apiRevealPerCard) apiRevealPerCard(id)
    }

    //console.log(reveal, id, suit, value)
    //console.log(revealTimer, counter)
    return id.indexOf('mini') > -1 ? miniCard : (!suit || !value) ? '' : reveal === 'hidden' ? hiddenCard : reveal === 'ready' ? readyCard : fullCard
}

export default Card