export const defaultValues = {
    type: 'game',
    gameType: 'baccarat',
    gameVariant: 'standard',
    ip: '',
    port: '3099',
    skipSeatFour: true,
    open: true,
    updatedBy: '',
    // NDI
    NDIconfig: {
        networks: {
            discovery: '',
        },
        groups: {
            send: '',
            recv: '',
        },
        rudp: {
            send: {
                enable: true,
            },
            recv: {
                enable: true,
            },
        },
        multicast: {
            send: {
                ttl: 1,
                enable: false,
                netmask: '255.255.0.0',
                netprefix: '239.255.0.0',
            },
            recv: {
                enable: true,
            },
        },
        tcp: {
            send: {
                enable: false,
            },
            recv: {
                enable: false,
            },
        },
        unicast: {
            send: {
                enable: false,
            },
            recv: {
                enable: false,
            },
        },
    },
    streamOutputName: '',
    // Dealer UI
    shoe: 'automatic',
    cardsToBurn: 6,
    cardReveal: 'immediate',
    revealTimer: 5,
    buyInConfirmation: true,
    buyInThreshold: 1000,
    cashOutConfirmation: true,
    cashOutThreshold: 1000,
    allowEditResults: false,
    allowEditCards: true,
    showBurnOverdraw: false,
    doubleConfirmation: false,
    // Trendboard
    tableNum: 'tablename',
    layout: '',
    themes: 'default',
    colorScheme: 'bankerRed',
    showAnimations: true,
    showInsuranceBadge: true,
    insuranceReminderTimeout: 10,
    insuranceReminderDelayFourCards: 0,
    insuranceReminderDelayPlayerThirdCard: 0,
    betTimer: 90,
    showBetTimerAnimation: true,
    showBigRoadNumbers: false,
    timerModeChipped: true,
    timerModeChipless: false,
    timerModeFixed: false,
    timerModeDisabled: false,
    frameRule: 1,
    trendHighlight: true,
    revealAnimation: false,
    manualReveal: false,
    deckchangeTimer: 90,
    hotColdNumDisplay: 'both',
    timerPlayerShowHand: 5,
    timerBankerShowHand: 5,
    timerShowPlayerThirdCard: 7,
    timerShowBankerThirdCard: 7,
    timeOnly: true,
    disclaimerText: 'Disclaimer Text',
    disclaimerSpeed: 'medium',
    minBetColor: '#0a2d7e',
    stream: '',
    marketingStream: '',
    colorThresholds: {
        level0: { color: '#0a2d7e', value: 500 },
        level1: { color: '#b10000', value: 1000 },
        level2: { color: '#109a65', value: 3000 },
        level3: { color: '#d43a5e', value: 5000 },
        level4: { color: '#9c3aee', value: 10000 },
    },
    // roulette TODO: this need to be variant splitted
    showNeighbor: true,
    // sicbo  TODO: this need to be variant splitted
    tripleDiceLimit: 100,
    doubleDiceLimit: 100,
    bets: {
        baccarat: {
            standard: {
                maxBet: 1000,
                minBet: 100,
                tieMinBet: 100,
                tieMaxBet: 1000,
                pairMinBet: 100,
                pairMaxBet: 1000,
                naturalMinBet: 100,
                naturalMaxBet: 1000,
                maxTableDiffBet: 1000,
                useMaxDiffBet: true,
                canTieBet: false,
                canPairBet: false,
                canNatBet: false,
            },
            lucky6: {
                maxBet: 1000,
                minBet: 100,
                tieMinBet: 100,
                tieMaxBet: 1000,
                pairMinBet: 100,
                pairMaxBet: 1000,
                naturalMinBet: 100,
                naturalMaxBet: 1000,
                lucky6MinBet: 100,
                lucky6MaxBet: 1000,
                maxTableDiffBet: 1000,
                useMaxDiffBet: true,
                canTieBet: false,
                canPairBet: false,
                canNatBet: false,
                canLucky6: false,
            },
            lucky6Slot: {
                maxBet: 1000,
                minBet: 100,
                tieMinBet: 100,
                tieMaxBet: 1000,
                pairMinBet: 100,
                pairMaxBet: 1000,
                naturalMinBet: 100,
                naturalMaxBet: 1000,
                lucky6MinBet: 100,
                lucky6MaxBet: 1000,
                maxTableDiffBet: 1000,
                useMaxDiffBet: false,
                canTieBet: false,
                canPairBet: false,
                canNatBet: false,
                canLucky6: false,
            },
            mega6: {
                maxBet: 1000,
                minBet: 100,
                tieMinBet: 100,
                tieMaxBet: 1000,
                pairMinBet: 100,
                pairMaxBet: 1000,
                naturalMinBet: 100,
                naturalMaxBet: 1000,
                mega6MinBet: 100,
                mega6MaxBet: 1000,
                mega6AllSidesMinBet: 100,
                mega6AllSidesMaxBet: 1000,
                maxTableDiffBet: 1000,
                useMaxDiffBet: false,
                canTieBet: false,
                canPairBet: false,
                canNatBet: false,
                canMega6: false,
            },
            tiger: {
                maxBet: 1000,
                minBet: 100,
                tieMinBet: 100,
                tieMaxBet: 1000,
                tigerMinBet: 100,
                tigerMaxBet: 1000,
                tigerTieMinBet: 100,
                tigerTieMaxBet: 1000,
                tigerPairMinBet: 100,
                tigerPairMaxBet: 1000,
                tigerBigMinBet: 100,
                tigerBigMaxBet: 1000,
                tigerSmallMinBet: 100,
                tigerSmallMaxBet: 1000,
                maxTableDiffBet: 1000,
                useMaxDiffBet: true,
                canTieBet: true,
                canTigerBet: true,
                canTigerTieBet: true,
                canTigerPairBet: true,
                canTigerBigBet: true,
                canTigerSmallBet: true,
            },
        },
        blackjack: {
            standard: {
                maxBet: 1000,
                minBet: 100,
            },
            anyPairSlot: {
                maxBet: 1000,
                minBet: 100,
                anyPairMinBet: 100,
                anyPairMaxBet: 1000,
                anyPairAnyPlayerMinBet: 100,
                anyPairAnyPlayerMaxBet: 1000,
                canAnyPairBet: false,
                canAnyPairAnyPlayerBet: false,
            },
        },
        roulette: {
            standard: {
                maxBet: 1000,
                minBet: 100,
            },
        },
        sicbo: {
            standard: {
                maxBet: 1000,
                minBet: 100,
                tripleDiceLimit: 100,
                doubleDiceLimit: 100,
            },
        },
        war: {
            etg: {
                maxBet: 1000,
                minBet: 100,
                tieMinBet: 100,
                tieMaxBet: 1000,
                canTieBet: false,
            },
        },
    },
    betOdds: {
        baccarat: {
            standard: {
                vig: false,
                banker: 1,
                player: 1,
                tie: 8,
                bankerPair: 11,
                playerPair: 11,
                playerInsurance2CardsPlayer5: 2,
                playerInsurance2CardsPlayer6: 3,
                playerInsurance2CardsPlayer7: 4,
                playerInsurance3CardsBanker0to4: 2,
                playerInsurance3CardsBanker0to5: 3,
                playerInsurance3CardsBanker0to6AndPlayer7: 4,
                playerInsurance3CardsBanker0to6AndPlayer8: 8,
                playerInsurance3CardsBanker0to6AndPlayer9: 9,
                bankerInsurance2CardsBanker5: 2,
                bankerInsurance2CardsBanker6: 3,
                bankerInsurance2CardsBanker7: 4,
                bankerInsurance3CardsPlayer0: 9,
                bankerInsurance3CardsPlayer1AndBanker2to6: 8,
                bankerInsurance3CardsPlayer1AndBanker1: 7,
                bankerInsurance3CardsPlayer2: 4,
                bankerInsurance3CardsPlayer3: 2,
            },
            lucky6: {
                vig: false,
                banker: 1,
                player: 1,
                tie: 8,
                bankerPair: 11,
                playerPair: 11,
                lucky6_2: 12,
                lucky6_3: 20,
                playerInsurance2CardsPlayer5: 2,
                playerInsurance2CardsPlayer6: 3,
                playerInsurance2CardsPlayer7: 4,
                playerInsurance3CardsBanker0to4: 2,
                playerInsurance3CardsBanker0to5: 3,
                playerInsurance3CardsBanker0to6AndPlayer7: 4,
                playerInsurance3CardsBanker0to6AndPlayer8: 8,
                playerInsurance3CardsBanker0to6AndPlayer9: 9,
                bankerInsurance2CardsBanker5: 2,
                bankerInsurance2CardsBanker6: 3,
                bankerInsurance2CardsBanker7: 4,
                bankerInsurance3CardsPlayer0: 9,
                bankerInsurance3CardsPlayer1AndBanker2to6: 8,
                bankerInsurance3CardsPlayer1AndBanker1: 7,
                bankerInsurance3CardsPlayer2: 4,
                bankerInsurance3CardsPlayer3: 2,
            },
            lucky6Slot: {
                vig: false,
                banker: 1,
                player: 1,
                tie: 8,
                bankerPair: 11,
                playerPair: 11,
                lucky6_2: 12,
                lucky6_3: 20,
                playerInsurance2CardsPlayer5: 2,
                playerInsurance2CardsPlayer6: 3,
                playerInsurance2CardsPlayer7: 4,
                playerInsurance3CardsBanker0to4: 2,
                playerInsurance3CardsBanker0to5: 3,
                playerInsurance3CardsBanker0to6AndPlayer7: 4,
                playerInsurance3CardsBanker0to6AndPlayer8: 8,
                playerInsurance3CardsBanker0to6AndPlayer9: 9,
                bankerInsurance2CardsBanker5: 2,
                bankerInsurance2CardsBanker6: 3,
                bankerInsurance2CardsBanker7: 4,
                bankerInsurance3CardsPlayer0: 9,
                bankerInsurance3CardsPlayer1AndBanker2to6: 8,
                bankerInsurance3CardsPlayer1AndBanker1: 7,
                bankerInsurance3CardsPlayer2: 4,
                bankerInsurance3CardsPlayer3: 2,
            },
            mega6: {
                vig: false,
                banker: 1,
                player: 1,
                tie: 8,
                bankerPair: 11,
                playerPair: 11,
                mega6_2: 12,
                mega6_3: 20,
                playerInsurance2CardsPlayer5: 2,
                playerInsurance2CardsPlayer6: 3,
                playerInsurance2CardsPlayer7: 4,
                playerInsurance3CardsBanker0to4: 2,
                playerInsurance3CardsBanker0to5: 3,
                playerInsurance3CardsBanker0to6AndPlayer7: 4,
                playerInsurance3CardsBanker0to6AndPlayer8: 8,
                playerInsurance3CardsBanker0to6AndPlayer9: 9,
                bankerInsurance2CardsBanker5: 2,
                bankerInsurance2CardsBanker6: 3,
                bankerInsurance2CardsBanker7: 4,
                bankerInsurance3CardsPlayer0: 9,
                bankerInsurance3CardsPlayer1AndBanker2to6: 8,
                bankerInsurance3CardsPlayer1AndBanker1: 7,
                bankerInsurance3CardsPlayer2: 4,
                bankerInsurance3CardsPlayer3: 2,
            },
            tiger: {
                vig: false,
                bankerWithTiger: 0.5,
                banker: 1,
                player: 1,
                tie: 8,
                tigerTie: 35,
                tigerSmall: 22,
                tigerBig: 50,
                tigerWithTigerSmall: 12,
                tigerWithTigerBig: 20,
                tigerPairSingle: 4,
                tigerPairDouble: 20,
                tigerPairTwin: 100,
                playerInsurance2CardsPlayer5: 2,
                playerInsurance2CardsPlayer6: 3,
                playerInsurance2CardsPlayer7: 4,
                playerInsurance3CardsBanker0to4: 2,
                playerInsurance3CardsBanker0to5: 3,
                playerInsurance3CardsBanker0to6AndPlayer7: 4,
                playerInsurance3CardsBanker0to6AndPlayer8: 8,
                playerInsurance3CardsBanker0to6AndPlayer9: 9,
                bankerInsurance2CardsBanker5: 2,
                bankerInsurance2CardsBanker6: 3,
                bankerInsurance2CardsBanker7: 4,
                bankerInsurance3CardsPlayer0: 9,
                bankerInsurance3CardsPlayer1AndBanker2to6: 8,
                bankerInsurance3CardsPlayer1AndBanker1: 7,
                bankerInsurance3CardsPlayer2: 4,
                bankerInsurance3CardsPlayer3: 2,
            },
        },
        blackjack: {
            standard: {
                base: 1,
                blackjack21: 1.5,
                insurance: 2,
                perfectPair: 30,
                coloredPair: 10,
                anyPair: 5,
            },
            anyPairSlot: {
                base: 1,
                blackjack21: 1.5,
                insurance: 2,
                perfectPair: 30,
                coloredPair: 10,
                anyPair: 5,
            },
        },
        roulette: {
            standard: {
                red: 1,
                black: 1,
                odd: 1,
                even: 1,
                low: 1,
                high: 1,
                firstDozen: 2,
                secondDozen: 2,
                thirdDozen: 2,
                firstColumn: 2,
                secondColumn: 2,
                thirdColumn: 2,
                straightUp: 35,
                split: 17,
                basket: 11,
                street: 11,
                corner: 8,
                topLine: 6,
                sixLine: 5,
            },
        },
        sicbo: {
            standard: {
                small: 1,
                big: 1,
                odd: 1,
                even: 1,
            },
        },
        war: {
            etg: {
                ante: 1,
                tie: 10,
                war: 1,
                warTie: 1,
            },
        },
    },
    meters: {
        baccarat: {
            standard: {
                showSideBetMeterValue: true,
                sideBetMeterValueMode: 'number',
                tieMeterMax: 8,
                bankerPairMeterMax: 11,
                playerPairMeterMax: 11,
            },
            lucky6: {
                showSideBetMeterValue: true,
                sideBetMeterValueMode: 'number',
                lucky6MeterMax: 16,
                tieMeterMax: 8,
                bankerPairMeterMax: 11,
                playerPairMeterMax: 11,
            },
            lucky6Slot: {
                showSideBetMeterValue: true,
                sideBetMeterValueMode: 'number',
                lucky6MeterMax: 16,
                tieMeterMax: 8,
                bankerPairMeterMax: 11,
                playerPairMeterMax: 11,
            },
            mega6: {
                showSideBetMeterValue: true,
                sideBetMeterValueMode: 'number',
                mega6MeterMax: 16,
                tieMeterMax: 8,
                bankerPairMeterMax: 11,
                playerPairMeterMax: 11,
            },
            tiger: {
                showSideBetMeterValue: true,
                sideBetMeterValueMode: 'number',
                tieMeterMax: 8,
                tigerMeterMax: 12,
                tigerTieMeterMax: 35,
                tigerPairMeterMax: 4,
                tigerBigMeterMax: 50,
                tigerSmallMeterMax: 22,
            },
        },
        war: {
            etg: {
                showSideBetMeterValue: true,
                sideBetMeterValueMode: 'number',
                tieMeterMax: 8,
            },
        },
    },
    gamesLimit: 100,
    trends: {
        baccarat: {
            standard: {
                dragonTriple: {
                    priority: 1,
                    name: 'Triple The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonDouble: {
                    priority: 2,
                    name: 'Double The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonBanker: {
                    priority: 3,
                    name: 'Banker Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonPlayer: {
                    priority: 4,
                    name: 'Player Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                clapClapConnect: {
                    priority: 5,
                    name: 'Clap Clap Connect',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                oneLoungeTwoBedrooms: {
                    priority: 6,
                    name: '1 Lounge 2 Bedrooms',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongStreak: {
                    priority: 7,
                    name: 'Ping Pong Streak / Triple Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongDouble: {
                    priority: 8,
                    name: 'Double Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pairStreakBanker: {
                    priority: 9,
                    name: 'Banker Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pairStreakPlayer: {
                    priority: 10,
                    name: 'Player Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieTriple: {
                    priority: 11,
                    name: 'Triple Tie (3 Consecutive Tie)',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieDouble: {
                    priority: 12,
                    name: 'Double Tie (2 Consecutive Tie)',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakBanker: {
                    priority: 13,
                    name: 'Banker Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakPlayer: {
                    priority: 14,
                    name: 'Player Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPong: {
                    priority: 15,
                    name: 'Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                doublePair: {
                    priority: 16,
                    name: 'Double Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerPair: {
                    priority: 17,
                    name: 'Banker Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerPair: {
                    priority: 18,
                    name: 'Player Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerWin: {
                    priority: 19,
                    name: 'Banker Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerWin: {
                    priority: 20,
                    name: 'Player Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tie: {
                    priority: 21,
                    name: 'Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
            },
            lucky6: {
                dragonTriple: {
                    priority: 1,
                    name: 'Triple The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonDouble: {
                    priority: 2,
                    name: 'Double The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonBanker: {
                    priority: 3,
                    name: 'Banker Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonPlayer: {
                    priority: 4,
                    name: 'Player Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                clapClapConnect: {
                    priority: 5,
                    name: 'Clap Clap Connect',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                oneLoungeTwoBedrooms: {
                    priority: 6,
                    name: '1 Lounge 2 Bedrooms',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongStreak: {
                    priority: 7,
                    name: 'Ping Pong Streak / Triple Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongDouble: {
                    priority: 8,
                    name: 'Double Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pairStreakBanker: {
                    priority: 9,
                    name: 'Banker Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pairStreakPlayer: {
                    priority: 10,
                    name: 'Player Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieTriple: {
                    priority: 11,
                    name: 'Triple Tie (3 Consecutive Tie)',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieDouble: {
                    priority: 12,
                    name: 'Double Tie (2 Consecutive Tie)',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakBanker: {
                    priority: 13,
                    name: 'Banker Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakPlayer: {
                    priority: 14,
                    name: 'Player Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPong: {
                    priority: 15,
                    name: 'Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                doublePair: {
                    priority: 16,
                    name: 'Double Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                lucky6: {
                    priority: 17,
                    name: 'Lucky 6',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerPair: {
                    priority: 18,
                    name: 'Banker Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerPair: {
                    priority: 19,
                    name: 'Player Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerWin: {
                    priority: 20,
                    name: 'Banker Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerWin: {
                    priority: 21,
                    name: 'Player Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tie: {
                    priority: 22,
                    name: 'Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
            },
            lucky6Slot: {
                dragonTriple: {
                    priority: 1,
                    name: 'Triple The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonDouble: {
                    priority: 2,
                    name: 'Double The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonBanker: {
                    priority: 3,
                    name: 'Banker Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonPlayer: {
                    priority: 4,
                    name: 'Player Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                clapClapConnect: {
                    priority: 5,
                    name: 'Clap Clap Connect',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                oneLoungeTwoBedrooms: {
                    priority: 6,
                    name: '1 Lounge 2 Bedrooms',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongStreak: {
                    priority: 7,
                    name: 'Ping Pong Streak / Triple Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongDouble: {
                    priority: 8,
                    name: 'Double Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pairStreakBanker: {
                    priority: 9,
                    name: 'Banker Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pairStreakPlayer: {
                    priority: 10,
                    name: 'Player Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieTriple: {
                    priority: 11,
                    name: 'Triple Tie (3 Consecutive Tie)',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieDouble: {
                    priority: 12,
                    name: 'Double Tie (2 Consecutive Tie)',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakBanker: {
                    priority: 13,
                    name: 'Banker Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakPlayer: {
                    priority: 14,
                    name: 'Player Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPong: {
                    priority: 15,
                    name: 'Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                doublePair: {
                    priority: 16,
                    name: 'Double Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                lucky6: {
                    priority: 17,
                    name: 'Lucky 6',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerPair: {
                    priority: 18,
                    name: 'Banker Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerPair: {
                    priority: 19,
                    name: 'Player Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerWin: {
                    priority: 20,
                    name: 'Banker Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerWin: {
                    priority: 21,
                    name: 'Player Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tie: {
                    priority: 22,
                    name: 'Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
            },
            mega6: {
                dragonTriple: {
                    priority: 1,
                    name: 'Triple The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonDouble: {
                    priority: 2,
                    name: 'Double The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonBanker: {
                    priority: 3,
                    name: 'Banker Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonPlayer: {
                    priority: 4,
                    name: 'Player Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                clapClapConnect: {
                    priority: 5,
                    name: 'Clap Clap Connect',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                oneLoungeTwoBedrooms: {
                    priority: 6,
                    name: '1 Lounge 2 Bedrooms',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongStreak: {
                    priority: 7,
                    name: 'Ping Pong Streak / Triple Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongDouble: {
                    priority: 8,
                    name: 'Double Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pairStreakBanker: {
                    priority: 9,
                    name: 'Banker Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pairStreakPlayer: {
                    priority: 10,
                    name: 'Player Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieTriple: {
                    priority: 11,
                    name: 'Triple Tie (3 Consecutive Tie)',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieDouble: {
                    priority: 12,
                    name: 'Double Tie (2 Consecutive Tie)',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakBanker: {
                    priority: 13,
                    name: 'Banker Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakPlayer: {
                    priority: 14,
                    name: 'Player Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPong: {
                    priority: 15,
                    name: 'Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                doublePair: {
                    priority: 16,
                    name: 'Double Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                mega6: {
                    priority: 17,
                    name: 'Mega 6',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerPair: {
                    priority: 18,
                    name: 'Banker Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerPair: {
                    priority: 19,
                    name: 'Player Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerWin: {
                    priority: 20,
                    name: 'Banker Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerWin: {
                    priority: 21,
                    name: 'Player Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tie: {
                    priority: 22,
                    name: 'Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
            },
            tiger: {
                dragonTriple: {
                    priority: 1,
                    name: 'Triple The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonDouble: {
                    priority: 5,
                    name: 'Double The Dragon',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonBanker: {
                    priority: 12,
                    name: 'Banker Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                dragonPlayer: {
                    priority: 11,
                    name: 'Player Dragon Tail',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                clapClapConnect: {
                    priority: 18,
                    name: 'Clap Clap Connect',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                oneLoungeTwoBedrooms: {
                    priority: 20,
                    name: '1 Lounge 2 Bedrooms',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongStreak: {
                    priority: 15,
                    name: 'Ping Pong Streak / Triple Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPongDouble: {
                    priority: 9,
                    name: 'Double Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerPairStreak: {
                    priority: 10,
                    name: 'Tiger Pair Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerTieTriple: {
                    priority: 3,
                    name: 'Triple Tiger Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerTieDouble: {
                    priority: 6,
                    name: 'Double Tiger Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieTriple: {
                    priority: 4,
                    name: 'Triple Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tieDouble: {
                    priority: 8,
                    name: 'Double Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakPlayer: {
                    priority: 21,
                    name: 'Player Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                streakBanker: {
                    priority: 22,
                    name: 'Banker Streak',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                pingPong: {
                    priority: 19,
                    name: 'Ping Pong',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerPairTwin: {
                    priority: 2,
                    name: 'Tiger Pair Twin',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerPairDouble: {
                    priority: 7,
                    name: 'Double Tiger Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerBig: {
                    priority: 13,
                    name: 'Tiger Big',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerSmall: {
                    priority: 14,
                    name: 'Tiger Small',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerTie: {
                    priority: 16,
                    name: 'Tiger Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tigerPair: {
                    priority: 17,
                    name: 'Tiger Pair',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                bankerWin: {
                    priority: 25,
                    name: 'Banker Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                playerWin: {
                    priority: 24,
                    name: 'Player Win',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
                tie: {
                    priority: 23,
                    name: 'Tie',
                    showOnTrendboard: true,
                    showOnPit: false,
                    showOnZone: false,
                    showOnEvent: false,
                    showOnXstadium: true,
                },
            },
        },
        roulette: {
            standard: {
                bias4Seven: {
                    priority: 1,
                    name: 'Super Jeu Zero',
                    showOnTrendboard: true,
                },
                bias1Seven: {
                    priority: 2,
                    name: 'Super Voision du Zero',
                    showOnTrendboard: true,
                },
                bias2Seven: {
                    priority: 3,
                    name: 'Super Tier du Cylindre',
                    showOnTrendboard: true,
                },
                bias3Seven: {
                    priority: 4,
                    name: 'Super Orphelins',
                    showOnTrendboard: true,
                },
                lowSix: {
                    priority: 5,
                    name: 'Low Low Low',
                    showOnTrendboard: true,
                },
                highSix: {
                    priority: 6,
                    name: 'High High High',
                    showOnTrendboard: true,
                },
                dozenFive: {
                    priority: 7,
                    name: 'Magnificent Dozen',
                    showOnTrendboard: true,
                },
                redFour: {
                    priority: 8,
                    name: 'Red Hot',
                    showOnTrendboard: true,
                },
                blackFour: {
                    priority: 9,
                    name: 'Black Magic',
                    showOnTrendboard: true,
                },
                zeroTriple: {
                    priority: 10,
                    name: 'Zero Mania',
                    showOnTrendboard: true,
                },
                eightEightEight: {
                    priority: 11,
                    name: 'Eight Eight Eight',
                    showOnTrendboard: true,
                },
                bias4: {
                    priority: 12,
                    name: 'Bias Sectors: Jeu Zero',
                    showOnTrendboard: true,
                },
                bias1: {
                    priority: 13,
                    name: 'Bias Sector: Voisins du Zero',
                    showOnTrendboard: true,
                },
                bias2: {
                    priority: 14,
                    name: 'Bias Sectors: Tiers du Cylindre',
                    showOnTrendboard: true,
                },
                bias3: {
                    priority: 15,
                    name: 'Bias Sectors: Orphelins',
                    showOnTrendboard: true,
                },
                oddTriple: {
                    priority: 16,
                    name: 'Wizard of "Odd"',
                    showOnTrendboard: true,
                },
                evenTriple: {
                    priority: 17,
                    name: 'Super Even',
                    showOnTrendboard: true,
                },
                zero: {
                    priority: 18,
                    name: 'Zero',
                    showOnTrendboard: true,
                },
            },
        },
    },
    hotTrends: {
        baccarat: {
            standard: {
                superHotStart: 20,
                superHotPercentage: 70,
                luckyDealerPercentage: 50,
                hotDealerPercentage: 60,
                geniusDealerPercentage: 70,
                godDealerPercentage: 80,
                dealerModeMinCount: 10,
                hotTrendStart: 5,
                trends: {
                    superHotPlayerPair: {
                        priority: 1,
                        active: true,
                    },
                    superHotBankerPair: {
                        priority: 2,
                        active: true,
                    },
                    hotPlayerPair: {
                        priority: 3,
                        active: true,
                    },
                    hotBankerPair: {
                        priority: 4,
                        active: true,
                    },
                    superHotTie: {
                        priority: 5,
                        active: true,
                    },
                    hotTie: {
                        priority: 6,
                        active: true,
                    },
                },
            },
            lucky6: {
                superHotStart: 20,
                superHotPercentage: 70,
                luckyDealerPercentage: 50,
                hotDealerPercentage: 60,
                geniusDealerPercentage: 70,
                godDealerPercentage: 80,
                dealerModeMinCount: 10,
                hotTrendStart: 5,
                trends: {
                    superHotLuckySix: {
                        priority: 1,
                        active: true,
                    },
                    hotLuckySix: {
                        priority: 2,
                        active: true,
                    },
                    superHotPlayerPair: {
                        priority: 3,
                        active: true,
                    },
                    superHotBankerPair: {
                        priority: 4,
                        active: true,
                    },
                    hotPlayerPair: {
                        priority: 5,
                        active: true,
                    },
                    hotBankerPair: {
                        priority: 6,
                        active: true,
                    },
                    superHotTie: {
                        priority: 7,
                        active: true,
                    },
                    hotTie: {
                        priority: 8,
                        active: true,
                    },
                },
            },
            lucky6Slot: {
                superHotStart: 20,
                superHotPercentage: 70,
                luckyDealerPercentage: 50,
                hotDealerPercentage: 60,
                geniusDealerPercentage: 70,
                godDealerPercentage: 80,
                dealerModeMinCount: 10,
                hotTrendStart: 5,
                trends: {
                    superHotLuckySix: {
                        priority: 1,
                        active: true,
                    },
                    hotLuckySix: {
                        priority: 2,
                        active: true,
                    },
                    superHotPlayerPair: {
                        priority: 3,
                        active: true,
                    },
                    superHotBankerPair: {
                        priority: 4,
                        active: true,
                    },
                    hotPlayerPair: {
                        priority: 5,
                        active: true,
                    },
                    hotBankerPair: {
                        priority: 6,
                        active: true,
                    },
                    superHotTie: {
                        priority: 7,
                        active: true,
                    },
                    hotTie: {
                        priority: 8,
                        active: true,
                    },
                },
            },
            mega6: {
                superHotStart: 20,
                superHotPercentage: 70,
                luckyDealerPercentage: 50,
                hotDealerPercentage: 60,
                geniusDealerPercentage: 70,
                godDealerPercentage: 80,
                dealerModeMinCount: 10,
                hotTrendStart: 5,
                trends: {
                    superHotMegaSix: {
                        priority: 1,
                        active: true,
                    },
                    hotMegaSix: {
                        priority: 2,
                        active: true,
                    },
                    superHotPlayerPair: {
                        priority: 3,
                        active: true,
                    },
                    superHotBankerPair: {
                        priority: 4,
                        active: true,
                    },
                    hotPlayerPair: {
                        priority: 5,
                        active: true,
                    },
                    hotBankerPair: {
                        priority: 6,
                        active: true,
                    },
                    superHotTie: {
                        priority: 7,
                        active: true,
                    },
                    hotTie: {
                        priority: 8,
                        active: true,
                    },
                },
            },
            tiger: {
                superHotStart: 20,
                superHotPercentage: 70,
                luckyDealerPercentage: 50,
                hotDealerPercentage: 60,
                geniusDealerPercentage: 70,
                godDealerPercentage: 80,
                dealerModeMinCount: 10,
                hotTrendStart: 5,
                trends: {
                    superHotTigerBig: {
                        priority: 1,
                        active: true,
                    },
                    hotTigerBig: {
                        priority: 2,
                        active: true,
                    },
                    superHotTigerTie: {
                        priority: 3,
                        active: true,
                    },
                    hotTigerTie: {
                        priority: 4,
                        active: true,
                    },
                    superHotTigerSmall: {
                        priority: 5,
                        active: true,
                    },
                    hotTigerSmall: {
                        priority: 6,
                        active: true,
                    },
                    superHotTiger: {
                        priority: 7,
                        active: true,
                    },
                    hotTiger: {
                        priority: 8,
                        active: true,
                    },
                    superHotTie: {
                        priority: 9,
                        active: true,
                    },
                    hotTie: {
                        priority: 10,
                        active: true,
                    },
                    superHotTigerPair: {
                        priority: 11,
                        active: true,
                    },
                    hotTigerPair: {
                        priority: 12,
                        active: true,
                    },
                },
            },
        },
    },
    slots: {
        baccarat: {
            lucky6Slot: {
                payouts: {
                    fullA: 50,
                    fullB: 20,
                    fullC: 15,
                    fullD: 10,
                    mixE2F1: 5,
                    mixE2notF: 2,
                },
                triggers: {
                    bankerOrPlayer: true,
                    tie: true,
                    bankerPair: true,
                    playerPair: true,
                    lucky6: true,
                },
            },
        },
        blackjack: {
            anyPairSlot: {
                payouts: {
                    fullA: 50,
                    fullB: 20,
                    fullC: 15,
                    fullD: 10,
                    mixE2F1: 5,
                    mixE2notF: 2,
                },
                triggers: {
                    dealerWinBlackjack: true,
                    dealerWin21: true,
                    anyPlayerBust: true,
                    anyPlayerPair: true,
                },
            },
        },
    },
    accuracyMode: 0,
    accuracyAddOnPercentage: 20,
}
