import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import {
    update,
    validate,
    getFieldsValues,
    setDefaults,
    FieldsForm
} from '../../../forms'
import { defaultValues } from '../defaultValues'
import _ from 'lodash'

const rootStyles = makeStyles((theme) => ({
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        width: 900,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
}))

const Payouts_GameVariant = ({
    publish,
    tables,
    table,
    settings,
    xstadium,
    changed,
    setChanged,
    template,
    changeCallback,
}) => {
    const styles = rootStyles({ xstadium })

    const navigate = useNavigate()

    // field flags
    const [submitted, setSubmitted] = useState(false)

    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)

    // baccarat
    const [vig, setVig] = useState(false)
    const [bankerWithTiger, setBankerWithTiger] = useState(0.5)
    const [banker, setBanker] = useState(1)
    const [player, setPlayer] = useState(1)
    const [tieBac, setTieBac] = useState(8)
    const [bankerPair, setBankerPair] = useState(11)
    const [playerPair, setPlayerPair] = useState(11)
    const [lucky6_2, setLucky6_2] = useState(12)
    const [lucky6_3, setLucky6_3] = useState(20)
    const [mega6_2, setMega6_2] = useState(12)
    const [mega6_3, setMega6_3] = useState(20)
    const [tigerTie, setTigerTie] = useState(45)
    const [tigerSmall, setTigerSmall] = useState(22)
    const [tigerBig, setTigerBig] = useState(55)
    const [tigerWithTigerSmall, setTigerWithTigerSmall] = useState(12)
    const [tigerWithTigerBig, setTigerWithTigerBig] = useState(20)
    const [tigerPairSingle, setTigerPairSingle] = useState(4)
    const [tigerPairDouble, setTigerPairDouble] = useState(25)
    const [tigerPairTwin, setTigerPairTwin] = useState(100)

    // blackjack
    const [base, setBase] = useState(1)
    const [blackjack21, setBlackjack21] = useState(1.5)
    const [insurance, setInsurance] = useState(2)
    const [perfectPair, setPerfectPair] = useState(30)
    const [coloredPair, setColoredPair] = useState(10)
    const [anyPair, setAnyPair] = useState(5)

    // roulette
    const [red, setRed] = useState(1)
    const [black, setBlack] = useState(1)
    const [oddRoulette, setOddRoulette] = useState(1)
    const [evenRoulette, setEvenRoulette] = useState(1)
    const [low, setLow] = useState(1)
    const [high, setHigh] = useState(1)
    const [firstDozen, setFirstDozen] = useState(2)
    const [secondDozen, setSecondDozen] = useState(2)
    const [thirdDozen, setThirdDozen] = useState(2)
    const [firstColumn, setFirstColumn] = useState(2)
    const [secondColumn, setSecondColumn] = useState(2)
    const [thirdColumn, setThirdColumn] = useState(2)
    const [straightUp, setStratightUp] = useState(35)
    const [split, setSplit] = useState(17)
    const [basket, setBasket] = useState(11)
    const [street, setStreet] = useState(11)
    const [corner, setCorner] = useState(8)
    const [topLine, setTopLine] = useState(6)
    const [sixLine, setSixLine] = useState(5)

    // sicbo
    const [small, setSmall] = useState(1)
    const [big, setBig] = useState(1)
    const [oddSicbo, setOddSicbo] = useState(1)
    const [evenSicbo, setEvenSicbo] = useState(1)

    // casino war
    const [ante, setAnte] = useState(1)
    const [tieWar, setTieWar] = useState(10)
    const [war, setWar] = useState(1)
    const [warTie, setWarTie] = useState(1)

    const gameType = table?.data?.gameType
    const gameVariant = table?.data?.gameVariant

    const fields = {
        betOdds: {
            baccarat: {
                standard: {
                    vig: {
                        label: 'Banker Win 5% Commission',
                        error: () => false,
                        value: vig,
                        set: setVig,
                        message: '',
                        type: 'bool'
                    },
                    banker: {
                        label: 'Banker Payout',
                        error: () => banker < 1,
                        value: banker,
                        set: setBanker,
                        message: 'Banker Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    player: {
                        label: 'Player Payout',
                        error: () => player < 1,
                        value: player,
                        set: setPlayer,
                        message: 'Player Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tie: {
                        label: 'Tie Payout',
                        error: () => tieBac < 1,
                        value: tieBac,
                        set: setTieBac,
                        message: 'Tie Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerPair: {
                        label: 'Banker Pair Payout',
                        error: () => bankerPair < 1,
                        value: bankerPair,
                        set: setBankerPair,
                        message: 'Banker Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerPair: {
                        label: 'Player Pair Payout',
                        error: () => playerPair < 1,
                        value: playerPair,
                        set: setPlayerPair,
                        message: 'Player Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                lucky6: {
                    vig: {
                        label: 'Banker Win 5% Commission',
                        error: () => false,
                        value: vig,
                        set: setVig,
                        message: '',
                        type: 'bool'
                    },
                    banker: {
                        label: 'Banker Payout',
                        error: () => banker < 1,
                        value: banker,
                        set: setBanker,
                        message: 'Banker Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    player: {
                        label: 'Player Payout',
                        error: () => player < 1,
                        value: player,
                        set: setPlayer,
                        message: 'Player Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tie: {
                        label: 'Tie Payout',
                        error: () => tieBac < 1,
                        value: tieBac,
                        set: setTieBac,
                        message: 'Tie Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerPair: {
                        label: 'Banker Pair Payout',
                        error: () => bankerPair < 1,
                        value: bankerPair,
                        set: setBankerPair,
                        message: 'Banker Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerPair: {
                        label: 'Player Pair Payout',
                        error: () => playerPair < 1,
                        value: playerPair,
                        set: setPlayerPair,
                        message: 'Player Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    lucky6_2: {
                        label: '2-Card Lucky 6 Payout',
                        error: () => lucky6_2 < 1,
                        value: lucky6_2,
                        set: setLucky6_2,
                        message: '2 Card Lucky 6 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    lucky6_3: {
                        label: '3-Card Lucky 6 Payout',
                        error: () => lucky6_3 < 1,
                        value: lucky6_3,
                        set: setLucky6_3,
                        message: '3 Card Lucky 6 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                lucky6Slot: {
                    vig: {
                        label: 'Banker Win 5% Commission',
                        error: () => false,
                        value: vig,
                        set: setVig,
                        message: '',
                        type: 'bool'
                    },
                    banker: {
                        label: 'Banker Payout',
                        error: () => banker < 1,
                        value: banker,
                        set: setBanker,
                        message: 'Banker Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    player: {
                        label: 'Player Payout',
                        error: () => player < 1,
                        value: player,
                        set: setPlayer,
                        message: 'Player Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tie: {
                        label: 'Tie Payout',
                        error: () => tieBac < 1,
                        value: tieBac,
                        set: setTieBac,
                        message: 'Tie Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerPair: {
                        label: 'Banker Pair Payout',
                        error: () => bankerPair < 1,
                        value: bankerPair,
                        set: setBankerPair,
                        message: 'Banker Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerPair: {
                        label: 'Player Pair Payout',
                        error: () => playerPair < 1,
                        value: playerPair,
                        set: setPlayerPair,
                        message: 'Player Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    lucky6_2: {
                        label: '2-Card Lucky 6 Payout',
                        error: () => lucky6_2 < 1,
                        value: lucky6_2,
                        set: setLucky6_2,
                        message: '2 Card Lucky 6 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    lucky6_3: {
                        label: '3-Card Lucky 6 Payout',
                        error: () => lucky6_3 < 1,
                        value: lucky6_3,
                        set: setLucky6_3,
                        message: '3 Card Lucky 6 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                mega6: {
                    vig: {
                        label: 'Banker Win 5% Commission',
                        error: () => false,
                        value: vig,
                        set: setVig,
                        message: '',
                        type: 'bool'
                    },
                    banker: {
                        label: 'Banker Payout',
                        error: () => banker < 1,
                        value: banker,
                        set: setBanker,
                        message: 'Banker Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    player: {
                        label: 'Player Payout',
                        error: () => player < 1,
                        value: player,
                        set: setPlayer,
                        message: 'Player Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tie: {
                        label: 'Tie Payout',
                        error: () => tieBac < 1,
                        value: tieBac,
                        set: setTieBac,
                        message: 'Tie Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    bankerPair: {
                        label: 'Banker Pair Payout',
                        error: () => bankerPair < 1,
                        value: bankerPair,
                        set: setBankerPair,
                        message: 'Banker Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    playerPair: {
                        label: 'Player Pair Payout',
                        error: () => playerPair < 1,
                        value: playerPair,
                        set: setPlayerPair,
                        message: 'Player Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    mega6_2: {
                        label: '2-Card Mega 6 Payout',
                        error: () => mega6_2 < 1,
                        value: mega6_2,
                        set: setMega6_2,
                        message: '2 Card Mega 6 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    mega6_3: {
                        label: '3-Card Mega 6 Payout',
                        error: () => mega6_3 < 1,
                        value: mega6_3,
                        set: setMega6_3,
                        message: '3 Card Mega 6 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                tiger: {
                    vig: {
                        label: 'Banker Win 5% Commission',
                        error: () => false,
                        value: vig,
                        set: setVig,
                        message: '',
                        type: 'bool'
                    },
                    bankerWithTiger: {
                        label: 'Banker with Tiger Result Payout',
                        error: () => bankerWithTiger <= 0,
                        value: bankerWithTiger,
                        set: setBankerWithTiger,
                        message: 'Banker with Tiger Result Payout cannot be less than or equal to 0',
                        type: 'numberPayout'
                    },
                    banker: {
                        label: 'Banker Payout',
                        error: () => banker < 1,
                        value: banker,
                        set: setBanker,
                        message: 'Banker Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    player: {
                        label: 'Player Payout',
                        error: () => player < 1,
                        value: player,
                        set: setPlayer,
                        message: 'Player Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tie: {
                        label: 'Tie Payout',
                        error: () => tieBac < 1,
                        value: tieBac,
                        set: setTieBac,
                        message: 'Tie Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tigerTie: {
                        label: 'Tiger Tie Payout',
                        error: () => tigerTie < 1,
                        value: tigerTie,
                        set: setTigerTie,
                        message: 'Tiger Tie Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tigerSmall: {
                        label: 'Tiger Small Payout',
                        error: () => tigerSmall < 1,
                        value: tigerSmall,
                        set: setTigerSmall,
                        message: 'Tiger Small Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tigerBig: {
                        label: 'Tiger Big Payout',
                        error: () => tigerBig < 1,
                        value: tigerBig,
                        set: setTigerBig,
                        message: 'Tiger Big Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tigerWithTigerSmall: {
                        label: 'Tiger with Tiger Small Result Payout',
                        error: () => tigerWithTigerSmall < 1,
                        value: tigerWithTigerSmall,
                        set: setTigerWithTigerSmall,
                        message: 'Tiger with Tiger Small Result Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tigerWithTigerBig: {
                        label: 'Tiger with Tiger Big Result Payout',
                        error: () => tigerWithTigerBig < 1,
                        value: tigerWithTigerBig,
                        set: setTigerWithTigerBig,
                        message: 'Tiger with Tiger Big Result Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tigerPairSingle: {
                        label: 'Tiger Pair Single Payout',
                        error: () => tigerPairSingle < 1,
                        value: tigerPairSingle,
                        set: setTigerPairSingle,
                        message: 'Tiger Pair Single Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tigerPairDouble: {
                        label: 'Tiger Pair Double Payout',
                        error: () => tigerPairDouble < 1,
                        value: tigerPairDouble,
                        set: setTigerPairDouble,
                        message: 'Tiger Pair Double Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tigerPairTwin: {
                        label: 'Tiger Pair Twin Payout',
                        error: () => tigerPairTwin < 1,
                        value: tigerPairTwin,
                        set: setTigerPairTwin,
                        message: 'Tiger Pair Twin Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
            },
            blackjack: {
                standard: {
                    base: {
                        label: '21 Payout',
                        error: () => base < 1,
                        value: base,
                        set: setBase,
                        message: '21 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    blackjack21: {
                        label: 'Blackjack 21 Payout',
                        error: () => blackjack21 < 1,
                        value: blackjack21,
                        set: setBlackjack21,
                        message: 'Blackjack 21 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    insurance: {
                        label: 'Insurance Payout',
                        error: () => insurance < 1,
                        value: insurance,
                        set: setInsurance,
                        message: 'Insurance Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    perfectPair: {
                        label: 'Perfect Pair Payout',
                        error: () => perfectPair < 1,
                        value: perfectPair,
                        set: setPerfectPair,
                        message: 'Perfect Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    coloredPair: {
                        label: 'Colored Pair Payout',
                        error: () => coloredPair < 1,
                        value: coloredPair,
                        set: setColoredPair,
                        message: 'Colored Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    anyPair: {
                        label: 'Any Pair Payout',
                        error: () => anyPair < 1,
                        value: anyPair,
                        set: setAnyPair,
                        message: 'Any Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
                anyPairSlot: {
                    base: {
                        label: '21 Payout',
                        error: () => base < 1,
                        value: base,
                        set: setBase,
                        message: '21 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    blackjack21: {
                        label: 'Blackjack 21 Payout',
                        error: () => blackjack21 < 1,
                        value: blackjack21,
                        set: setBlackjack21,
                        message: 'Blackjack 21 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    insurance: {
                        label: 'Insurance Payout',
                        error: () => insurance < 1,
                        value: insurance,
                        set: setInsurance,
                        message: 'Insurance Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    perfectPair: {
                        label: 'Perfect Pair Payout',
                        error: () => perfectPair < 1,
                        value: perfectPair,
                        set: setPerfectPair,
                        message: 'Perfect Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    coloredPair: {
                        label: 'Colored Pair Payout',
                        error: () => coloredPair < 1,
                        value: coloredPair,
                        set: setColoredPair,
                        message: 'Colored Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    anyPair: {
                        label: 'Any Pair Payout',
                        error: () => anyPair < 1,
                        value: anyPair,
                        set: setAnyPair,
                        message: 'Any Pair Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
            },
            roulette: {
                standard: {
                    red: {
                        label: 'Red Payout',
                        error: () => red < 1,
                        value: red,
                        set: setRed,
                        message: 'Red Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    black: {
                        label: 'Black Payout',
                        error: () => black < 1,
                        value: black,
                        set: setBlack,
                        message: 'Black Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    odd: {
                        label: 'Odd Payout',
                        error: () => oddRoulette < 1,
                        value: oddRoulette,
                        set: setOddRoulette,
                        message: 'Odd Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    even: {
                        label: 'Even Payout',
                        error: () => evenRoulette < 1,
                        value: evenRoulette,
                        set: setEvenRoulette,
                        message: 'Even Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    low: {
                        label: '1 to 18 Payout',
                        error: () => low < 1,
                        value: low,
                        set: setLow,
                        message: '1 to 18 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    high: {
                        label: '19 to 36 Payout',
                        error: () => high < 1,
                        value: high,
                        set: setHigh,
                        message: '19 to 36 Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    firstDozen: {
                        label: '1st Dozen Payout',
                        error: () => firstDozen < 1,
                        value: firstDozen,
                        set: setFirstDozen,
                        message: '1st Dozen Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    secondDozen: {
                        label: '2nd Dozen Payout',
                        error: () => secondDozen < 1,
                        value: secondDozen,
                        set: setSecondDozen,
                        message: '2nd Dozen Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    thirdDozen: {
                        label: '3rd Column Payout',
                        error: () => thirdDozen < 1,
                        value: thirdDozen,
                        set: setThirdDozen,
                        message: '3rd Column Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    firstColumn: {
                        label: '1st Column Payout',
                        error: () => firstColumn < 1,
                        value: firstColumn,
                        set: setFirstColumn,
                        message: '1st Column Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    secondColumn: {
                        label: '2nd Column Payout',
                        error: () => secondColumn < 1,
                        value: secondColumn,
                        set: setSecondColumn,
                        message: '2nd Column Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    thirdColumn: {
                        label: '3rd Column Payout',
                        error: () => thirdColumn < 1,
                        value: thirdColumn,
                        set: setThirdColumn,
                        message: '3rd Column Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    straightUp: {
                        label: 'Straight Up Payout',
                        error: () => straightUp < 1,
                        value: straightUp,
                        set: setStratightUp,
                        message: 'Straight Up Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    split: {
                        label: 'Split Payout',
                        error: () => split < 1,
                        value: split,
                        set: setSplit,
                        message: 'Split Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    basket: {
                        label: 'Basket Payout',
                        error: () => basket < 1,
                        value: basket,
                        set: setBasket,
                        message: 'Basket Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    street: {
                        label: 'Street Payout',
                        error: () => street < 1,
                        value: street,
                        set: setStreet,
                        message: 'Street Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    corner: {
                        label: 'Corner Payout',
                        error: () => corner < 1,
                        value: corner,
                        set: setCorner,
                        message: 'Corner Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    topLine: {
                        label: 'Top Line Payout',
                        error: () => topLine < 1,
                        value: topLine,
                        set: setTopLine,
                        message: 'Top Line Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    sixLine: {
                        label: 'Six Line Payout',
                        error: () => sixLine < 1,
                        value: sixLine,
                        set: setSixLine,
                        message: 'Six Line Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
            },
            sicbo: {
                standard: {
                    small: {
                        label: 'Small Payout',
                        error: () => small < 1,
                        value: small,
                        set: setSmall,
                        message: 'Small Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    big: {
                        label: 'Big Payout',
                        error: () => big < 1,
                        value: big,
                        set: setBig,
                        message: 'Big Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    odd: {
                        label: 'Odd Payout',
                        error: () => oddSicbo < 1,
                        value: oddSicbo,
                        set: setOddSicbo,
                        message: 'Odd Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    even: {
                        label: 'Even Payout',
                        error: () => evenSicbo < 1,
                        value: evenSicbo,
                        set: setEvenSicbo,
                        message: 'Even Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                },
            },
            war: {
                etg: {
                    ante: {
                        label: 'Ante Payout',
                        error: () => ante < 1,
                        value: ante,
                        set: setAnte,
                        message: 'Ante Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    tie: {
                        label: 'Tie Payout',
                        error: () => tieWar < 1,
                        value: tieWar,
                        set: setTieWar,
                        message: 'Tie Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    war: {
                        label: 'War Payout',
                        error: () => war < 1,
                        value: war,
                        set: setWar,
                        message: 'War Payout cannot be less than 1',
                        type: 'numberPayout'
                    },
                    warTie: {
                        label: 'War Tie Payout',
                        error: () => warTie < 1,
                        value: warTie,
                        set: setWarTie,
                        message: 'War tie Payout cannot be less than 1',
                        type: 'numberPayout'
                    }
                }
            }
        }
    }

    const validation = validate(fields)
    const fieldsOfVariant = fields.betOdds[gameType][gameVariant]
    const isolatedFields = {
        betOdds: table.data.betOdds
    }
    const _fields = {
        betOdds: {
            [gameType]: {
                [gameVariant]: fieldsOfVariant
            }
        }
    }

    if (table.updated !== updated) {
        update(_fields, [], table.data, defaultValues)
        setUpdated(table.updated)
    }

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            // need to do this since the same object is modified by another form
            const newFields = _.merge(isolatedFields, getFieldsValues(_fields))
            try {
                setLoading(true)
                await publish({
                    ...table.data,
                    ...newFields,
                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
                setChanged(0)
                setFail('')
            } catch (e) {
                setFail(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
            } finally {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (changed > 0 && template) {
            const newFields = _.merge(isolatedFields, getFieldsValues(_fields))
            changeCallback(newFields)
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                key='tablePayoutForm'
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </Box>
                )}

                {submitted && validation.error && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    </Box>
                )}

                <FieldsForm fields={fieldsOfVariant}
                    changed={changed}
                    setChanged={setChanged}
                    submitted={submitted}
                    loading={loading} />

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={loading}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                            setDefaults(fields, defaultValues)
                            setChanged(changed + 1)
                        }}
                    >
                        Default
                    </Button>

                    {changed > 0 && (
                        <Button
                            variant='contained'
                            type='button'
                            color='primary'
                            disabled={loading}
                            onClick={send}
                        >
                            Update
                        </Button>
                    )}
                </div>}
            </form>
        </>
    )
}

export default Payouts_GameVariant
