import React from 'react'
import SnackbarContent from '@mui/material/SnackbarContent'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import CancelIcon from '@mui/icons-material/Cancel'

const R404 = ({ basePath = '' }) => {
    return (
        <Paper className="paper-container" style={{
            flex: 1,
            marginTop: 0
        }} elevation={1}>
            <SnackbarContent
                sx={{
                    backgroundColor: '#f1932c',
                    maxWidth: 'unset',
                    borderRadius: 0
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        {...{ to: '/' + basePath }}
                        component={Link}>
                        <CancelIcon />
                    </IconButton>
                ]}
                message={(
                    <Typography component="p" style={{ color: 'white' }}>
                        <WarningIcon
                            sx={{ verticalAlign: "bottom", color: "#f0cf81" }} />Could not find this path {window.location.pathname}.
                    </Typography>
                )}
            /></Paper>
    )
}

export default R404