import React, { Fragment, useState } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TablePagination from '@mui/material/TablePagination'

const rootStyles = makeStyles((theme) => ({
    table: {
        // display: 'block',
        // overflowX: 'auto',
    },
    tableHead: {
        background: props => props.lights ? '#eaf1ff' : 'rgb(117 122 133)'
    },
    tableCellHead: {
        position: 'sticky',
        top: 0,
        background: props => props.lights ? '#eaf1ff' : 'rgb(117 122 133)'
    },
    tableHeadRoot: {
        display: 'block',
        overflowX: 'auto',
    },
    tableRoot: {
        background: (props) => props.lights ? '#fffffff0' : '#1f1f1fd6',
    },
    tableRow: {
        textDecoration: 'none',
        '&:hover': {
            background: (props) => props.link ? 'pointer' : '',
            cursor: (props) => props.link ? 'pointer' : ''
        }
    },
    tableCell: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    tableCellRoot: {
        maxWidth: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',

        [theme.breakpoints.down('xl')]: {
            padding: '0.9em'
        },
        [theme.breakpoints.down('lg')]: {
            padding: '0.5em'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '16px'
        },
    },
    tablePagination: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '100%',
        borderTop: '1px solid #b5b5b57a',
        background: (props) => props.lights ? '#fffffff0' : '#1f1f1fd6'
    },
    paginationActions: {
        margin: 0
    },
    paginationInput: {
        [theme.breakpoints.down('xs')]: {
            marginRight: 7
        }
    },
    fakeTableHead: {
        visibility: 'collapse'
    },
    fakeTableRow: {
        visibility: 'collapse'
    },
}))

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const TableComponent = ({
    rows,
    top = 0,
    pagination = false,
    hiddenFields = [],
    hiddenMobileFields = [],
    selectedIndex = null,
    link = null,
    sortDefault = '' }) => {
    const lights = window.localStorage.getItem('lights') === 'on'
    const styles = rootStyles({ lights, top, link })
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))
    const tablet = useMediaQuery(theme.breakpoints.between('s', 'sm'))

    // table
    const responsiveTableFields = (field) => hiddenFields.indexOf(field) === -1 &&
        ((!mobile && !tablet) ||
            ((mobile || tablet) && hiddenMobileFields.indexOf(field) === -1))
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState(sortDefault)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    function handleRequestSort(_event, property) {
        const isDesc = orderBy === property && order === 'desc'
        setOrder(isDesc ? 'asc' : 'desc')
        setOrderBy(property)
    }
    const createSortHandler = property => event => {
        handleRequestSort(event, property)
    }
    function handleChangePage(_event, newPage) {
        setPage(newPage)
    }
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const filteredRows = stableSort(rows, getSorting(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const filterKeys = Object.keys(rows[0]).filter(v => responsiveTableFields(v))

    if (page !== 0 && rows.length < rowsPerPage * page) {
        setPage(0)
    }

    return <Fragment><Table key="table" className={styles.table}>
        <TableHead className={styles.tableHead}>
            <TableRow>
                {rows.length > 0 && filterKeys.map((v, i) => v !== '' ?
                    <TableCell key={v} align={'left'} className={styles.tableCellHead}>
                        <TableSortLabel active={orderBy === v}
                            onClick={createSortHandler(v)}
                            hideSortIcon
                        >{v}</TableSortLabel>
                    </TableCell> : <TableCell key={v} align={'left'} className={styles.tableCellHead}>{v}</TableCell>)}
            </TableRow>
        </TableHead>
        <TableBody className={styles.tableRoot}>
            {filteredRows.map((row, index) => <TableRow key={index}
                className={styles.tableRow}
                hover={link !== null}
                selected={index === selectedIndex}
                onClick={() => link ? link(row, index) : null}>
                {filterKeys.map((v, i) =>
                    <TableCell classes={{
                        root: styles.tableCellRoot
                    }}
                        key={v}
                        component={i === 0 ? 'th' : ''}
                        scope={i === 0 ? 'row' : ''}
                        align={'left'}>{row[v]}</TableCell>)}
            </TableRow>)}
        </TableBody>
    </Table>
        {pagination && <TablePagination key="tablePagination" className={styles.tablePagination}
            classes={{
                actions: styles.paginationActions,
                input: styles.paginationInput
            }}
            rowsPerPageOptions={[100, 400, 1000]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
                'aria-label': 'next page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />}
    </Fragment>
}

export default TableComponent