import { XCircle } from 'react-feather'
import styled from 'styled-components'

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`
const Container = styled(FlexCenter)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`
const Inner = styled(FlexCenter)`
    position: relative;
    width: 100%;
    height: 100%;
`
const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.8;
`
const Content = styled(FlexCenter)`
    color: white;
    z-index: 11;
    flex-direction: column;
    background: #4a4a4a;
    padding: 30px;
    border-radius: 10px;
    gap: 40px;
    width: 400px;
    position: relative;
`
const Heading = styled(FlexCenter)`
    font-size: 25px;
    font-weight: bold;
`
const Button = styled(FlexCenter)`
    border-radius: 20px;
    padding: 10px 20px;
    color: white;
    background-color: ${(props) => props.color};
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        background: #333;
    }
`
const CloseButton = styled(XCircle)`
    color: #ccc;
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    transform: translate(100%, -100%);
    cursor: pointer;
    &:hover {
        color: white;
    }
`
const Message = styled(FlexCenter)`
    text-align: center;
`
const DevicesNotAllOnline = ({ setShowPanel }) => {
    return (
        <Container>
            <Inner>
                <Content>
                    <CloseButton onClick={() => setShowPanel(null)} />
                    <Heading>Devices Not All Online </Heading>
                    <Message>Number of total devices must match the number of online devices.</Message>
                    <Button color={'#666'} onClick={() => setShowPanel(null)}>
                        OK
                    </Button>
                </Content>
                <Backdrop onClick={() => setShowPanel(null)} />
            </Inner>
        </Container>
    )
}

export default DevicesNotAllOnline
