import React, { useReducer, useState } from 'react'
import _, { values, startCase, isEmpty } from 'lodash'
import moment from 'moment'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Card, CardHeader, CardTitle, CardBody, Container, Row, Col, Table } from 'reactstrap'
import LinearProgress from '@mui/material/LinearProgress'

import { useSubscribe, tableApi, historyApi } from '../api'
import { useAppContext } from '../contexts/AppContext'
import Location from '../tree/Location'
import Tree from '../tree/Tree'
import { findDevicesInTables, getDescendantDevices } from '../tree/TreeUtils'
import useWindowSize from '../WindowSize'
import {
    formatCashString,
    formatCountString,
    getBetTypeColor,
    baccaratStandardBetTypes,
    baccaratLucky6BetTypes,
    baccaratLucky6SlotBetTypes,
    baccaratMega6BetTypes,
    baccaratTigerBetTypes,
    blackjackStandardBetTypes,
    blackjackAnyPairSlotBetTypes,
    baccaratStandardBetTypesRow,
    baccaratLucky6BetTypesRow,
    baccaratLucky6SlotBetTypesRow,
    baccaratMega6BetTypesRow,
    baccaratTigerBetTypesRow,
    blackjackStandardBetTypesRow,
    blackjackAnyPairSlotBetTypesRow,
} from './Formats'

const styles = {
    rootContainer: {
        display: 'grid',
        gridTemplateColumns: '305px auto 280px',
        gap: '10px',
    },
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red',
    }),
    betType: (v) => ({
        color: getBetTypeColor(v),
    }),
}

const View = () => {
    const navigate = useNavigate()
    const [width, ,] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')
    const URLlocationID = urlParams.get('locationID') || window.localStorage.getItem('url:locationID') || 'root'
    const URLlocationType = urlParams.get('locationType') || window.localStorage.getItem('url:locationType') || 'Casino'
    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }
    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        statusSocket.readyState === WebSocket.OPEN

    // fetched data
    const [allTablesReport, setAllTablesReport] = useState(null)
    const [baccaratStandardAllTablesReport, setBaccaratStandardAllTablesReport] = useState(null)
    const [baccaratLucky6AllTablesReport, setBaccaratLucky6AllTablesReport] = useState(null)
    const [baccaratLucky6SlotAllTablesReport, setBaccaratLucky6SlotAllTablesReport] = useState(null)
    const [baccaratMega6AllTablesReport, setBaccaratMega6AllTablesReport] = useState(null)
    const [baccaratTigerAllTablesReport, setBaccaratTigerAllTablesReport] = useState(null)
    const [blackjackStandardAllTablesReport, setBlackjackStandardAllTablesReport] = useState(null)
    const [blackjackAnyPairSlotAllTablesReport, setBlackjackAnyPairSlotAllTablesReport] = useState(null)
    const [fetchedTablesReport, setFetchedTablesReport] = useState(false)
    const [minLoadTime, setMinLoadTime] = useState(false)
    const [locationChange, setLocationChange] = useState(false)

    const { gameVariantFilter } = useAppContext()

    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter((v) => v !== 'push')
        const oldParams = urlParams.toString()
        propKeys.forEach((k) => {
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange =
            propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        if (urlChange || props.push) {
            navigate('/xtable/view?' + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                setFetchedTablesReport(false)
            }
        }
    }

    const [location, setLocation] = useReducer((_state, obj) => {
        clearFetch({ locationID: obj.branchIndex, locationType: obj.branchType })
        return [obj]
    }, null)

    if (settings && !settings.data.xtable.sections.includes('view')) {
        return <Navigate to='/dashboard' />
    }

    const isCasino = !location || (location && location[0].branchIndex === 'root')
    const isTable = _.get(location, ['0', 'branchType'], '') === 'Table' ? true : false
    const isPit = _.get(location, ['0', 'branchType'], '') === 'Pit' ? true : false
    const isZone = _.get(location, ['0', 'branchType'], '') === 'Zone' ? true : false
    const pitsSelected =
        !isZone || !pits ? [] : pits.filter((pit) => location[0].branchIndex === pit.data.containerIndex)
    const tablesSelected =
        !isPit || !tables ? [] : tables.filter((table) => location[0].branchIndex === table.data.containerIndex)
    const devicesSelected =
        !isTable || !devices ? [] : devices.filter((device) => location[0].branchIndex === device.data.containerIndex && device.data.type === 'game')
    const gameDevicesIndexes = !devices
        ? []
        : devices.filter((device) => device.data.type === 'game').map((v) => v.index)

    const dateChange = (start, end, push) => {
        if (start === startDate && end === endDate && !push) return
        if (!push && (start !== startDate || end !== endDate)) {
            setStartDate(start)
            setEndDate(end)
        }
        clearFetch({ startDate: start, endDate: end, push: !!push })
    }

    const getDevicesReport = async () => {
        if (fetchedTablesReport) {
            return
        }

        setFetchedTablesReport(true)

        const api = settings && settings.data.xtable.gameOnlyMode ? historyApi : tableApi

        try {
            const responseOverview = await api
                .get('report/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratStandard = await api
                .get('report/baccarat/standard/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratLucky6 = await api
                .get('report/baccarat/lucky6/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratLucky6Slot = await api
                .get('report/baccarat/lucky6Slot/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratMega6 = await api
                .get('report/baccarat/mega6/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratTiger = await api
                .get('report/baccarat/tiger/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBlackjackStandard = await api
                .get('report/blackjack/standard/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBlackjackAnyPairSlot = await api
                .get('report/blackjack/anyPairSlot/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            console.log('overview', responseOverview)
            console.log('baccarat/standard', responseBaccaratStandard)
            console.log('baccarat/lucky6', responseBaccaratLucky6)
            console.log('baccarat/lucky6Slot', responseBaccaratLucky6Slot)
            console.log('baccarat/mega6', responseBaccaratMega6)
            console.log('baccarat/tiger', responseBaccaratTiger)
            console.log('blackjack/standard', responseBlackjackStandard)
            console.log('blackjack/anyPairSlot', responseBlackjackAnyPairSlot)
            setAllTablesReport(responseOverview)
            setBaccaratStandardAllTablesReport(responseBaccaratStandard)
            setBaccaratLucky6AllTablesReport(responseBaccaratLucky6)
            setBaccaratLucky6SlotAllTablesReport(responseBaccaratLucky6Slot)
            setBaccaratMega6AllTablesReport(responseBaccaratMega6)
            setBaccaratTigerAllTablesReport(responseBaccaratTiger)
            setBlackjackStandardAllTablesReport(responseBlackjackStandard)
            setBlackjackAnyPairSlotAllTablesReport(responseBlackjackAnyPairSlot)
        } catch (e) {
            console.warn(e)
            setAllTablesReport([])
            setBaccaratStandardAllTablesReport([])
            setBaccaratLucky6AllTablesReport([])
            setBaccaratLucky6SlotAllTablesReport([])
            setBaccaratMega6AllTablesReport([])
            setBaccaratTigerAllTablesReport([])
            setBlackjackStandardAllTablesReport([])
            setBlackjackAnyPairSlotAllTablesReport([])
        }
    }

    if (Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        getDevicesReport()
    }

    const findPitsInZone = (zoneID) => pits.filter((dv) => dv.data.containerIndex === zoneID).map((dv) => dv.index)
    const findTablesInPit = (pitID) => tables.filter((dv) => dv.data.containerIndex === pitID).map((dv) => dv.index)
    const findTablesInPits = (subsetPits) =>
        tables.filter((dv) => subsetPits.indexOf(dv.data.containerIndex) >= 0).map((dv) => dv.index)

    const getBetTypes = (gameVariantFilter) => {
        let betTypes
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                betTypes = baccaratStandardBetTypes
                break
            case 'baccaratLucky6':
                betTypes = baccaratLucky6BetTypes
                break
            case 'baccaratLucky6Slot':
                betTypes = baccaratLucky6SlotBetTypes
                break
            case 'baccaratMega6':
                betTypes = baccaratMega6BetTypes
                break
            case 'baccaratTiger':
                betTypes = baccaratTigerBetTypes
                break
            case 'blackjackStandard':
                betTypes = blackjackStandardBetTypes
                break
            case 'blackjackAnyPairSlot':
                betTypes = blackjackAnyPairSlotBetTypes
                break
            default:
                betTypes = []
        }
        return betTypes
    }

    const getBetTypesRow = (gameVariantFilter) => {
        let betTypesRow
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                betTypesRow = baccaratStandardBetTypesRow
                break
            case 'baccaratLucky6':
                betTypesRow = baccaratLucky6BetTypesRow
                break
            case 'baccaratLucky6Slot':
                betTypesRow = baccaratLucky6SlotBetTypesRow
                break
            case 'baccaratMega6':
                betTypesRow = baccaratMega6BetTypesRow
                break
            case 'baccaratTiger':
                betTypesRow = baccaratTigerBetTypesRow
                break
            case 'blackjackStandard':
                betTypesRow = blackjackStandardBetTypesRow
                break
            case 'blackjackAnyPairSlot':
                betTypesRow = blackjackAnyPairSlotBetTypesRow
                break
            default:
                betTypesRow = []
        }
        return betTypesRow
    }

    // Wager by locations
    // Wager Casino
    const findZoneWagerByBetType = (zoneID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const pitsInZone = findPitsInZone(zoneID)
        const tablesInPits = findTablesInPits(pitsInZone)
        const devicesInPits = findDevicesInTables(devices, tablesInPits)
        return tablesReport.reduce(
            (prev, next) => (devicesInPits.indexOf(next.device) < 0 ? prev : prev + next.betsPerType[betType]),
            0
        )
    }

    const findZoneWagerTotal = (zoneID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const pitsInZone = findPitsInZone(zoneID)
        const tablesInPits = findTablesInPits(pitsInZone)
        const devicesInPits = findDevicesInTables(devices, tablesInPits)
        return tablesReport.reduce(
            (prev, next) => (devicesInPits.indexOf(next.device) < 0 ? prev : prev + next.bets),
            0
        )
    }

    // Wager Zone
    const findPitWagerByBetType = (pitID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const tablesInPit = findTablesInPit(pitID)
        const devicesInPit = findDevicesInTables(devices, tablesInPit)
        return tablesReport.reduce(
            (prev, next) => (devicesInPit.indexOf(next.device) < 0 ? prev : prev + next.betsPerType[betType]),
            0
        )
    }

    const findPitWagerTotal = (pitID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const tablesInPit = findTablesInPit(pitID)
        const devicesInPit = findDevicesInTables(devices, tablesInPit)
        return tablesReport.reduce((prev, next) => (devicesInPit.indexOf(next.device) < 0 ? prev : prev + next.bets), 0)
    }

    // Wager Pit
    const findDeviceWagerByBetType = (deviceID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const found = tablesReport.find((dv) => dv.device === deviceID)
        return found ? found.betsPerType[betType] : 0
    }

    const findDeviceWagerTotal = (deviceID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const found = tablesReport.find((dv) => dv.device === deviceID)
        return found ? found.bets : 0
    }

    // WinLoss by locations
    // WinLoss Casino
    const findZoneWinLossByBetType = (zoneID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const pitsInZone = findPitsInZone(zoneID)
        const tablesInPits = findTablesInPits(pitsInZone)
        const devicesInPits = findDevicesInTables(devices, tablesInPits)
        return tablesReport.reduce(
            (prev, next) => (devicesInPits.indexOf(next.device) < 0 ? prev : prev + next.profitPerType[betType]),
            0
        )
    }

    const findZoneWinLossTotal = (zoneID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const pitsInZone = findPitsInZone(zoneID)
        const tablesInPits = findTablesInPits(pitsInZone)
        const devicesInPits = findDevicesInTables(devices, tablesInPits)
        return tablesReport.reduce(
            (prev, next) => (devicesInPits.indexOf(next.device) < 0 ? prev : prev + next.profit),
            0
        )
    }

    // WinLoss Zone
    const findPitWinLossByBetType = (pitID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const tablesInPit = findTablesInPit(pitID)
        const devicesInPit = findDevicesInTables(devices, tablesInPit)
        return tablesReport.reduce(
            (prev, next) => (devicesInPit.indexOf(next.device) < 0 ? prev : prev + next.profitPerType[betType]),
            0
        )
    }

    const findPitWinLossTotal = (pitID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const tablesInPit = findTablesInPit(pitID)
        const devicesInPit = findDevicesInTables(devices, tablesInPit)
        return tablesReport.reduce(
            (prev, next) => (devicesInPit.indexOf(next.device) < 0 ? prev : prev + next.profit),
            0
        )
    }

    // WinLoss Pit
    const findDeviceWinLossByBetType = (deviceID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const found = tablesReport.find((dv) => dv.device === deviceID)
        return found ? found.profitPerType[betType] : 0
    }

    const findDeviceWinLossTotal = (deviceID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const found = tablesReport.find((dv) => dv.device === deviceID)
        return found ? found.profit : 0
    }

    // BetCount by locations
    // BetCount Casino
    const findZoneBetCountByBetType = (zoneID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const pitsInZone = findPitsInZone(zoneID)
        const tablesInPits = findTablesInPits(pitsInZone)
        const devicesInPits = findDevicesInTables(devices, tablesInPits)
        return tablesReport.reduce(
            (prev, next) => (devicesInPits.indexOf(next.device) < 0 ? prev : prev + next.betCountPerType[betType]),
            0
        )
    }

    const findZoneBetCountTotal = (zoneID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const pitsInZone = findPitsInZone(zoneID)
        const tablesInPits = findTablesInPits(pitsInZone)
        const devicesInPits = findDevicesInTables(devices, tablesInPits)
        return tablesReport.reduce(
            (prev, next) => (devicesInPits.indexOf(next.device) < 0 ? prev : prev + next.betCount),
            0
        )
    }

    // BetCount Zone
    const findPitBetCountByBetType = (pitID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const tablesInPit = findTablesInPit(pitID)
        const devicesInPit = findDevicesInTables(devices, tablesInPit)
        return tablesReport.reduce(
            (prev, next) => (devicesInPit.indexOf(next.device) < 0 ? prev : prev + next.betCountPerType[betType]),
            0
        )
    }

    const findPitBetCountTotal = (pitID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const tablesInPit = findTablesInPit(pitID)
        const devicesInPit = findDevicesInTables(devices, tablesInPit)
        return tablesReport.reduce(
            (prev, next) => (devicesInPit.indexOf(next.device) < 0 ? prev : prev + next.betCount),
            0
        )
    }

    // BetCount Pit
    const findDeviceBetCountByBetType = (deviceID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const found = tablesReport.find((dv) => dv.device === deviceID)
        return found ? found.betCountPerType[betType] : 0
    }

    const findDeviceBetCountTotal = (deviceID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const found = tablesReport.find((dv) => dv.device === deviceID)
        return found ? found.betCount : 0
    }

    // Payout by locations
    // Payout Casino
    const findZonePayoutByBetType = (zoneID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const pitsInZone = findPitsInZone(zoneID)
        const tablesInPits = findTablesInPits(pitsInZone)
        const devicesInPits = findDevicesInTables(devices, tablesInPits)
        return tablesReport.reduce(
            (prev, next) => (devicesInPits.indexOf(next.device) < 0 ? prev : prev + next.returnPerType[betType]),
            0
        )
    }

    const findZonePayoutTotal = (zoneID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const pitsInZone = findPitsInZone(zoneID)
        const tablesInPits = findTablesInPits(pitsInZone)
        const devicesInPits = findDevicesInTables(devices, tablesInPits)
        return tablesReport.reduce(
            (prev, next) => (devicesInPits.indexOf(next.device) < 0 ? prev : prev + next.return),
            0
        )
    }

    // Payout Zone
    const findPitPayoutByBetType = (pitID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const tablesInPit = findTablesInPit(pitID)
        const devicesInPit = findDevicesInTables(devices, tablesInPit)
        return tablesReport.reduce(
            (prev, next) => (devicesInPit.indexOf(next.device) < 0 ? prev : prev + next.returnPerType[betType]),
            0
        )
    }

    const findPitPayoutTotal = (pitID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const tablesInPit = findTablesInPit(pitID)
        const devicesInPit = findDevicesInTables(devices, tablesInPit)
        return tablesReport.reduce(
            (prev, next) => (devicesInPit.indexOf(next.device) < 0 ? prev : prev + next.return),
            0
        )
    }

    // Payout Pit
    const findDevicePayoutByBetType = (deviceID, betType) => {
        if (!tablesReport || isEmpty(tablesReport) || getBetTypes(gameVariantFilter).indexOf(betType) < 0) {
            return 0
        }

        const found = tablesReport.find((dv) => dv.device === deviceID)
        return found ? found.returnPerType[betType] : 0
    }

    const findDevicePayoutTotal = (deviceID) => {
        if (!tablesReport || isEmpty(tablesReport)) {
            return 0
        }

        const found = tablesReport.find((dv) => dv.device === deviceID)
        return found ? found.return : 0
    }

    const reportLoading = !allTablesReport || isEmpty(allTablesReport)
    const tablesReport = reportLoading
        ? []
        : isCasino
            ? gameVariantFilter === 'baccaratStandard'
                ? baccaratStandardAllTablesReport
                : gameVariantFilter === 'baccaratLucky6'
                    ? baccaratLucky6AllTablesReport
                    : gameVariantFilter === 'baccaratLucky6Slot'
                        ? baccaratLucky6SlotAllTablesReport
                        : gameVariantFilter === 'baccaratMega6'
                            ? baccaratMega6AllTablesReport
                            : gameVariantFilter === 'baccaratTiger'
                                ? baccaratTigerAllTablesReport
                                : gameVariantFilter === 'blackjackStandard'
                                    ? blackjackStandardAllTablesReport
                                    : gameVariantFilter === 'blackjackAnyPairSlot'
                                        ? blackjackAnyPairSlotAllTablesReport
                                        : []
            : ((isPit || isZone) && gameVariantFilter === 'baccaratStandard' && baccaratStandardAllTablesReport) ||
                (isTable &&
                    devicesSelected &&
                    !isEmpty(devicesSelected) &&
                    devicesSelected[0].data.gameType === 'baccarat' &&
                    devicesSelected[0].data.gameVariant === 'standard' &&
                    baccaratStandardAllTablesReport)
                ? baccaratStandardAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
                : ((isPit || isZone) && gameVariantFilter === 'baccaratLucky6' && baccaratLucky6AllTablesReport) ||
                    (isTable &&
                        devicesSelected &&
                        !isEmpty(devicesSelected) &&
                        devicesSelected[0].data.gameType === 'baccarat' &&
                        devicesSelected[0].data.gameVariant === 'lucky6' &&
                        baccaratLucky6AllTablesReport)
                    ? baccaratLucky6AllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
                    : ((isPit || isZone) && gameVariantFilter === 'baccaratLucky6Slot' && baccaratLucky6SlotAllTablesReport) ||
                        (isTable &&
                            devicesSelected &&
                            !isEmpty(devicesSelected) &&
                            devicesSelected[0].data.gameType === 'baccarat' &&
                            devicesSelected[0].data.gameVariant === 'lucky6Slot' &&
                            baccaratLucky6SlotAllTablesReport)
                        ? baccaratLucky6SlotAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
                        : ((isPit || isZone) && gameVariantFilter === 'baccaratMega6' && baccaratMega6AllTablesReport) ||
                            (isTable &&
                                devicesSelected &&
                                !isEmpty(devicesSelected) &&
                                devicesSelected[0].data.gameType === 'baccarat' &&
                                devicesSelected[0].data.gameVariant === 'mega6' &&
                                baccaratMega6AllTablesReport)
                            ? baccaratMega6AllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
                            : ((isPit || isZone) && gameVariantFilter === 'baccaratTiger' && baccaratTigerAllTablesReport) ||
                                (isTable &&
                                    devicesSelected &&
                                    !isEmpty(devicesSelected) &&
                                    devicesSelected[0].data.gameType === 'baccarat' &&
                                    devicesSelected[0].data.gameVariant === 'tiger' &&
                                    baccaratTigerAllTablesReport)
                                ? baccaratTigerAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
                                : ((isPit || isZone) && gameVariantFilter === 'blackjackStandard' && blackjackStandardAllTablesReport) ||
                                    (isTable &&
                                        devicesSelected &&
                                        !isEmpty(devicesSelected) &&
                                        devicesSelected[0].data.gameType === 'blackjack' &&
                                        devicesSelected[0].data.gameVariant === 'standard' &&
                                        blackjackStandardAllTablesReport)
                                    ? blackjackStandardAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
                                    : ((isPit || isZone) && gameVariantFilter === 'blackjackAnyPairSlot' && blackjackAnyPairSlotAllTablesReport) ||
                                        (isTable &&
                                            devicesSelected &&
                                            !isEmpty(devicesSelected) &&
                                            devicesSelected[0].data.gameType === 'blackjack' &&
                                            devicesSelected[0].data.gameVariant === 'anyPairSlot' &&
                                            blackjackAnyPairSlotAllTablesReport)
                                        ? blackjackAnyPairSlotAllTablesReport.filter(
                                            (entry) => location[0].descendantDevices.indexOf(entry.device) >= 0
                                        )
                                        : []

    // table wager
    const getSeatWager = (seat, betType) => {
        return !tablesReport || isEmpty(tablesReport)
            ? 0
            : tablesReport.reduce((prev, next) => prev + next.seatsBetsPerType[seat][betType], 0)
    }

    const getSeatTotalWager = (seat, betTypes) => {
        let arr = []
        betTypes.forEach((betType) => {
            arr.push(getSeatWager(seat, betType))
        })
        return !tablesReport || isEmpty(tablesReport) ? 0 : arr.reduce((prev, next) => prev + next, 0)
    }

    const getBetTypeTotalWager = (betType) => {
        return !tablesReport || isEmpty(tablesReport)
            ? 0
            : tablesReport.reduce((prev, next) => prev + next.betsPerType[betType], 0)
    }

    const totalWager =
        !tablesReport || isEmpty(tablesReport) ? 0 : tablesReport.reduce((prev, next) => prev + next.bets, 0)

    // table winloss
    const getSeatWinLoss = (seat, betType) => {
        return !tablesReport || isEmpty(tablesReport)
            ? 0
            : tablesReport.reduce((prev, next) => prev + next.seatsProfitPerType[seat][betType], 0)
    }

    const getSeatTotalWinLoss = (seat, betTypes) => {
        let arr = []
        betTypes.forEach((betType) => {
            arr.push(getSeatWinLoss(seat, betType))
        })
        return !tablesReport || isEmpty(tablesReport) ? 0 : arr.reduce((prev, next) => prev + next, 0)
    }

    const getBetTypeTotalWinLoss = (betType) => {
        return !tablesReport || isEmpty(tablesReport)
            ? 0
            : tablesReport.reduce((prev, next) => prev + next.profitPerType[betType], 0)
    }

    const totalWinLoss =
        !tablesReport || isEmpty(tablesReport) ? 0 : tablesReport.reduce((prev, next) => prev + next.profit, 0)

    // table bet count
    const getSeatBetCount = (seat, betType) => {
        return !tablesReport || isEmpty(tablesReport)
            ? 0
            : tablesReport.reduce((prev, next) => prev + next.seatsBetCountPerType[seat][betType], 0)
    }

    const getSeatTotalBetCount = (seat, betTypes) => {
        let arr = []
        betTypes.forEach((betType) => {
            arr.push(getSeatBetCount(seat, betType))
        })
        return !tablesReport || isEmpty(tablesReport) ? 0 : arr.reduce((prev, next) => prev + next, 0)
    }

    const getBetTypeTotalBetCount = (betType) => {
        return !tablesReport || isEmpty(tablesReport)
            ? 0
            : tablesReport.reduce((prev, next) => prev + next.betCountPerType[betType], 0)
    }

    const totalBetCount =
        !tablesReport || isEmpty(tablesReport) ? 0 : tablesReport.reduce((prev, next) => prev + next.betCount, 0)

    // table payout
    const getSeatPayout = (seat, betType) => {
        return !tablesReport || isEmpty(tablesReport)
            ? 0
            : tablesReport.reduce((prev, next) => prev + next.seatsReturnPerType[seat][betType], 0)
    }

    const getSeatTotalPayout = (seat, betTypes) => {
        let arr = []
        betTypes.forEach((betType) => {
            arr.push(getSeatPayout(seat, betType))
        })
        return !tablesReport || isEmpty(tablesReport) ? 0 : arr.reduce((prev, next) => prev + next, 0)
    }

    const getBetTypeTotalPayout = (betType) => {
        return !tablesReport || isEmpty(tablesReport)
            ? 0
            : tablesReport.reduce((prev, next) => prev + next.returnPerType[betType], 0)
    }

    const totalPayout =
        !tablesReport || isEmpty(tablesReport) ? 0 : tablesReport.reduce((prev, next) => prev + next.return, 0)

    // Wager
    const getZoneWager = (zone) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findZoneWagerByBetType(zone.index, 'banker'),
                    player: findZoneWagerByBetType(zone.index, 'player'),
                    tie: findZoneWagerByBetType(zone.index, 'tie'),
                    bankerPair: findZoneWagerByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneWagerByBetType(zone.index, 'playerPair'),
                    total: findZoneWagerTotal(zone.index),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findZoneWagerByBetType(zone.index, 'banker'),
                    player: findZoneWagerByBetType(zone.index, 'player'),
                    tie: findZoneWagerByBetType(zone.index, 'tie'),
                    bankerPair: findZoneWagerByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneWagerByBetType(zone.index, 'playerPair'),
                    lucky6: findZoneWagerByBetType(zone.index, 'lucky6'),
                    total: findZoneWagerTotal(zone.index),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findZoneWagerByBetType(zone.index, 'banker'),
                    player: findZoneWagerByBetType(zone.index, 'player'),
                    tie: findZoneWagerByBetType(zone.index, 'tie'),
                    bankerPair: findZoneWagerByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneWagerByBetType(zone.index, 'playerPair'),
                    lucky6: findZoneWagerByBetType(zone.index, 'lucky6'),
                    bonus: findZoneWagerByBetType(zone.index, 'bonus'),
                    total: findZoneWagerTotal(zone.index),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findZoneWagerByBetType(zone.index, 'banker'),
                    player: findZoneWagerByBetType(zone.index, 'player'),
                    tie: findZoneWagerByBetType(zone.index, 'tie'),
                    bankerPair: findZoneWagerByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneWagerByBetType(zone.index, 'playerPair'),
                    mega6: findZoneWagerByBetType(zone.index, 'mega6'),
                    total: findZoneWagerTotal(zone.index),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findZoneWagerByBetType(zone.index, 'banker'),
                    player: findZoneWagerByBetType(zone.index, 'player'),
                    tie: findZoneWagerByBetType(zone.index, 'tie'),
                    tigerTie: findZoneWagerByBetType(zone.index, 'tigerTie'),
                    tigerPair: findZoneWagerByBetType(zone.index, 'tigerPair'),
                    tigerBig: findZoneWagerByBetType(zone.index, 'tigerBig'),
                    tigerSmall: findZoneWagerByBetType(zone.index, 'tigerSmall'),
                    tiger: findZoneWagerByBetType(zone.index, 'tiger'),
                    total: findZoneWagerTotal(zone.index),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findZoneWagerByBetType(zone.index, 'base'),
                    doubleDown: findZoneWagerByBetType(zone.index, 'doubleDown'),
                    insurance: findZoneWagerByBetType(zone.index, 'insurance'),
                    pair: findZoneWagerByBetType(zone.index, 'pair'),
                    split: findZoneWagerByBetType(zone.index, 'split'),
                    total: findZoneWagerTotal(zone.index),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findZoneWagerByBetType(zone.index, 'base'),
                    pair: findZoneWagerByBetType(zone.index, 'pair'),
                    anyPair: findZoneWagerByBetType(zone.index, 'anyPair'),
                    split: findZoneWagerByBetType(zone.index, 'split'),
                    doubleDown: findZoneWagerByBetType(zone.index, 'doubleDown'),
                    insurance: findZoneWagerByBetType(zone.index, 'insurance'),
                    bonus: findZoneWagerByBetType(zone.index, 'bonus'),
                    total: findZoneWagerTotal(zone.index),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getPitWager = (pit) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findPitWagerByBetType(pit.index, 'banker'),
                    player: findPitWagerByBetType(pit.index, 'player'),
                    tie: findPitWagerByBetType(pit.index, 'tie'),
                    bankerPair: findPitWagerByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitWagerByBetType(pit.index, 'playerPair'),
                    total: findPitWagerTotal(pit.index),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findPitWagerByBetType(pit.index, 'banker'),
                    player: findPitWagerByBetType(pit.index, 'player'),
                    tie: findPitWagerByBetType(pit.index, 'tie'),
                    bankerPair: findPitWagerByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitWagerByBetType(pit.index, 'playerPair'),
                    lucky6: findPitWagerByBetType(pit.index, 'lucky6'),
                    total: findPitWagerTotal(pit.index),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findPitWagerByBetType(pit.index, 'banker'),
                    player: findPitWagerByBetType(pit.index, 'player'),
                    tie: findPitWagerByBetType(pit.index, 'tie'),
                    bankerPair: findPitWagerByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitWagerByBetType(pit.index, 'playerPair'),
                    lucky6: findPitWagerByBetType(pit.index, 'lucky6'),
                    bonus: findPitWagerByBetType(pit.index, 'bonus'),
                    total: findPitWagerTotal(pit.index),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findPitWagerByBetType(pit.index, 'banker'),
                    player: findPitWagerByBetType(pit.index, 'player'),
                    tie: findPitWagerByBetType(pit.index, 'tie'),
                    bankerPair: findPitWagerByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitWagerByBetType(pit.index, 'playerPair'),
                    mega6: findPitWagerByBetType(pit.index, 'mega6'),
                    total: findPitWagerTotal(pit.index),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findPitWagerByBetType(pit.index, 'banker'),
                    player: findPitWagerByBetType(pit.index, 'player'),
                    tie: findPitWagerByBetType(pit.index, 'tie'),
                    tigerTie: findPitWagerByBetType(pit.index, 'tigerTie'),
                    tigerPair: findPitWagerByBetType(pit.index, 'tigerPair'),
                    tigerBig: findPitWagerByBetType(pit.index, 'tigerBig'),
                    tigerSmall: findPitWagerByBetType(pit.index, 'tigerSmall'),
                    total: findPitWagerTotal(pit.index),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findPitWagerByBetType(pit.index, 'base'),
                    doubleDown: findPitWagerByBetType(pit.index, 'doubleDown'),
                    insurance: findPitWagerByBetType(pit.index, 'insurance'),
                    pair: findPitWagerByBetType(pit.index, 'pair'),
                    split: findPitWagerByBetType(pit.index, 'split'),
                    total: findPitWagerTotal(pit.index),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findPitWagerByBetType(pit.index, 'base'),
                    pair: findPitWagerByBetType(pit.index, 'pair'),
                    anyPair: findPitWagerByBetType(pit.index, 'anyPair'),
                    split: findPitWagerByBetType(pit.index, 'split'),
                    doubleDown: findPitWagerByBetType(pit.index, 'doubleDown'),
                    insurance: findPitWagerByBetType(pit.index, 'insurance'),
                    bonus: findPitWagerByBetType(pit.index, 'bonus'),
                    total: findPitWagerTotal(pit.index),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getDeviceWager = (deviceID) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findDeviceWagerByBetType(deviceID, 'banker'),
                    player: findDeviceWagerByBetType(deviceID, 'player'),
                    tie: findDeviceWagerByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceWagerByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceWagerByBetType(deviceID, 'playerPair'),
                    total: findDeviceWagerTotal(deviceID),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findDeviceWagerByBetType(deviceID, 'banker'),
                    player: findDeviceWagerByBetType(deviceID, 'player'),
                    tie: findDeviceWagerByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceWagerByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceWagerByBetType(deviceID, 'playerPair'),
                    lucky6: findDeviceWagerByBetType(deviceID, 'lucky6'),
                    total: findDeviceWagerTotal(deviceID),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findDeviceWagerByBetType(deviceID, 'banker'),
                    player: findDeviceWagerByBetType(deviceID, 'player'),
                    tie: findDeviceWagerByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceWagerByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceWagerByBetType(deviceID, 'playerPair'),
                    lucky6: findDeviceWagerByBetType(deviceID, 'lucky6'),
                    bonus: findDeviceWagerByBetType(deviceID, 'bonus'),
                    total: findDeviceWagerTotal(deviceID),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findDeviceWagerByBetType(deviceID, 'banker'),
                    player: findDeviceWagerByBetType(deviceID, 'player'),
                    tie: findDeviceWagerByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceWagerByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceWagerByBetType(deviceID, 'playerPair'),
                    mega6: findDeviceWagerByBetType(deviceID, 'mega6'),
                    total: findDeviceWagerTotal(deviceID),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findDeviceWagerByBetType(deviceID, 'banker'),
                    player: findDeviceWagerByBetType(deviceID, 'player'),
                    tie: findDeviceWagerByBetType(deviceID, 'tie'),
                    tigerTie: findDeviceWagerByBetType(deviceID, 'tigerTie'),
                    tigerPair: findDeviceWagerByBetType(deviceID, 'tigerPair'),
                    tigerBig: findDeviceWagerByBetType(deviceID, 'tigerBig'),
                    tigerSmall: findDeviceWagerByBetType(deviceID, 'tigerSmall'),
                    tiger: findDeviceWagerByBetType(deviceID, 'tiger'),
                    total: findDeviceWagerTotal(deviceID),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findDeviceWagerByBetType(deviceID, 'base'),
                    doubleDown: findDeviceWagerByBetType(deviceID, 'doubleDown'),
                    insurance: findDeviceWagerByBetType(deviceID, 'insurance'),
                    pair: findDeviceWagerByBetType(deviceID, 'pair'),
                    split: findDeviceWagerByBetType(deviceID, 'split'),
                    total: findDeviceWagerTotal(deviceID),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findDeviceWagerByBetType(deviceID, 'base'),
                    pair: findDeviceWagerByBetType(deviceID, 'pair'),
                    anyPair: findDeviceWagerByBetType(deviceID, 'anyPair'),
                    split: findDeviceWagerByBetType(deviceID, 'split'),
                    doubleDown: findDeviceWagerByBetType(deviceID, 'doubleDown'),
                    insurance: findDeviceWagerByBetType(deviceID, 'insurance'),
                    bonus: findDeviceWagerByBetType(deviceID, 'bonus'),
                    total: findDeviceWagerTotal(deviceID),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getSeatBetTypeWager = (gameType, gameVariant) => {
        let obj
        if (gameType === 'baccarat') {
            if (gameVariant === 'standard') {
                obj = {
                    banker: {
                        seat1: getSeatWager('seat1', 'banker'),
                        seat2: getSeatWager('seat2', 'banker'),
                        seat3: getSeatWager('seat3', 'banker'),
                        seat4: getSeatWager('seat4', 'banker'),
                        seat5: getSeatWager('seat5', 'banker'),
                        total: getBetTypeTotalWager('banker'),
                    },
                    player: {
                        seat1: getSeatWager('seat1', 'player'),
                        seat2: getSeatWager('seat2', 'player'),
                        seat3: getSeatWager('seat3', 'player'),
                        seat4: getSeatWager('seat4', 'player'),
                        seat5: getSeatWager('seat5', 'player'),
                        total: getBetTypeTotalWager('player'),
                    },
                    tie: {
                        seat1: getSeatWager('seat1', 'tie'),
                        seat2: getSeatWager('seat2', 'tie'),
                        seat3: getSeatWager('seat3', 'tie'),
                        seat4: getSeatWager('seat4', 'tie'),
                        seat5: getSeatWager('seat5', 'tie'),
                        total: getBetTypeTotalWager('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatWager('seat1', 'bankerPair'),
                        seat2: getSeatWager('seat2', 'bankerPair'),
                        seat3: getSeatWager('seat3', 'bankerPair'),
                        seat4: getSeatWager('seat4', 'bankerPair'),
                        seat5: getSeatWager('seat5', 'bankerPair'),
                        total: getBetTypeTotalWager('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatWager('seat1', 'playerPair'),
                        seat2: getSeatWager('seat2', 'playerPair'),
                        seat3: getSeatWager('seat3', 'playerPair'),
                        seat4: getSeatWager('seat4', 'playerPair'),
                        seat5: getSeatWager('seat5', 'playerPair'),
                        total: getBetTypeTotalWager('playerPair'),
                    },
                    total: {
                        seat1: getSeatTotalWager('seat1', baccaratStandardBetTypes),
                        seat2: getSeatTotalWager('seat2', baccaratStandardBetTypes),
                        seat3: getSeatTotalWager('seat3', baccaratStandardBetTypes),
                        seat4: getSeatTotalWager('seat4', baccaratStandardBetTypes),
                        seat5: getSeatTotalWager('seat5', baccaratStandardBetTypes),
                        total: totalWager,
                    },
                }
            }
            if (gameVariant === 'lucky6') {
                obj = {
                    banker: {
                        seat1: getSeatWager('seat1', 'banker'),
                        seat2: getSeatWager('seat2', 'banker'),
                        seat3: getSeatWager('seat3', 'banker'),
                        seat4: getSeatWager('seat4', 'banker'),
                        seat5: getSeatWager('seat5', 'banker'),
                        total: getBetTypeTotalWager('banker'),
                    },
                    player: {
                        seat1: getSeatWager('seat1', 'player'),
                        seat2: getSeatWager('seat2', 'player'),
                        seat3: getSeatWager('seat3', 'player'),
                        seat4: getSeatWager('seat4', 'player'),
                        seat5: getSeatWager('seat5', 'player'),
                        total: getBetTypeTotalWager('player'),
                    },
                    tie: {
                        seat1: getSeatWager('seat1', 'tie'),
                        seat2: getSeatWager('seat2', 'tie'),
                        seat3: getSeatWager('seat3', 'tie'),
                        seat4: getSeatWager('seat4', 'tie'),
                        seat5: getSeatWager('seat5', 'tie'),
                        total: getBetTypeTotalWager('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatWager('seat1', 'bankerPair'),
                        seat2: getSeatWager('seat2', 'bankerPair'),
                        seat3: getSeatWager('seat3', 'bankerPair'),
                        seat4: getSeatWager('seat4', 'bankerPair'),
                        seat5: getSeatWager('seat5', 'bankerPair'),
                        total: getBetTypeTotalWager('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatWager('seat1', 'playerPair'),
                        seat2: getSeatWager('seat2', 'playerPair'),
                        seat3: getSeatWager('seat3', 'playerPair'),
                        seat4: getSeatWager('seat4', 'playerPair'),
                        seat5: getSeatWager('seat5', 'playerPair'),
                        total: getBetTypeTotalWager('playerPair'),
                    },
                    lucky6: {
                        seat1: getSeatWager('seat1', 'lucky6'),
                        seat2: getSeatWager('seat2', 'lucky6'),
                        seat3: getSeatWager('seat3', 'lucky6'),
                        seat4: getSeatWager('seat4', 'lucky6'),
                        seat5: getSeatWager('seat5', 'lucky6'),
                        total: getBetTypeTotalWager('lucky6'),
                    },
                    total: {
                        seat1: getSeatTotalWager('seat1', baccaratLucky6BetTypes),
                        seat2: getSeatTotalWager('seat2', baccaratLucky6BetTypes),
                        seat3: getSeatTotalWager('seat3', baccaratLucky6BetTypes),
                        seat4: getSeatTotalWager('seat4', baccaratLucky6BetTypes),
                        seat5: getSeatTotalWager('seat5', baccaratLucky6BetTypes),
                        total: totalWager,
                    },
                }
            }
            if (gameVariant === 'lucky6Slot') {
                obj = {
                    banker: {
                        seat1: getSeatWager('seat1', 'banker'),
                        seat2: getSeatWager('seat2', 'banker'),
                        seat3: getSeatWager('seat3', 'banker'),
                        seat4: getSeatWager('seat4', 'banker'),
                        seat5: getSeatWager('seat5', 'banker'),
                        total: getBetTypeTotalWager('banker'),
                    },
                    player: {
                        seat1: getSeatWager('seat1', 'player'),
                        seat2: getSeatWager('seat2', 'player'),
                        seat3: getSeatWager('seat3', 'player'),
                        seat4: getSeatWager('seat4', 'player'),
                        seat5: getSeatWager('seat5', 'player'),
                        total: getBetTypeTotalWager('player'),
                    },
                    tie: {
                        seat1: getSeatWager('seat1', 'tie'),
                        seat2: getSeatWager('seat2', 'tie'),
                        seat3: getSeatWager('seat3', 'tie'),
                        seat4: getSeatWager('seat4', 'tie'),
                        seat5: getSeatWager('seat5', 'tie'),
                        total: getBetTypeTotalWager('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatWager('seat1', 'bankerPair'),
                        seat2: getSeatWager('seat2', 'bankerPair'),
                        seat3: getSeatWager('seat3', 'bankerPair'),
                        seat4: getSeatWager('seat4', 'bankerPair'),
                        seat5: getSeatWager('seat5', 'bankerPair'),
                        total: getBetTypeTotalWager('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatWager('seat1', 'playerPair'),
                        seat2: getSeatWager('seat2', 'playerPair'),
                        seat3: getSeatWager('seat3', 'playerPair'),
                        seat4: getSeatWager('seat4', 'playerPair'),
                        seat5: getSeatWager('seat5', 'playerPair'),
                        total: getBetTypeTotalWager('playerPair'),
                    },
                    lucky6: {
                        seat1: getSeatWager('seat1', 'lucky6'),
                        seat2: getSeatWager('seat2', 'lucky6'),
                        seat3: getSeatWager('seat3', 'lucky6'),
                        seat4: getSeatWager('seat4', 'lucky6'),
                        seat5: getSeatWager('seat5', 'lucky6'),
                        total: getBetTypeTotalWager('lucky6'),
                    },
                    bonus: {
                        seat1: getSeatWager('seat1', 'bonus'),
                        seat2: getSeatWager('seat2', 'bonus'),
                        seat3: getSeatWager('seat3', 'bonus'),
                        seat4: getSeatWager('seat4', 'bonus'),
                        seat5: getSeatWager('seat5', 'bonus'),
                        total: getBetTypeTotalWager('bonus'),
                    },
                    total: {
                        seat1: getSeatTotalWager('seat1', baccaratLucky6BetTypes),
                        seat2: getSeatTotalWager('seat2', baccaratLucky6BetTypes),
                        seat3: getSeatTotalWager('seat3', baccaratLucky6BetTypes),
                        seat4: getSeatTotalWager('seat4', baccaratLucky6BetTypes),
                        seat5: getSeatTotalWager('seat5', baccaratLucky6BetTypes),
                        total: totalWager,
                    },
                }
            }
            if (gameVariant === 'mega6') {
                obj = {
                    banker: {
                        seat1: getSeatWager('seat1', 'banker'),
                        seat2: getSeatWager('seat2', 'banker'),
                        seat3: getSeatWager('seat3', 'banker'),
                        seat4: getSeatWager('seat4', 'banker'),
                        seat5: getSeatWager('seat5', 'banker'),
                        total: getBetTypeTotalWager('banker'),
                    },
                    player: {
                        seat1: getSeatWager('seat1', 'player'),
                        seat2: getSeatWager('seat2', 'player'),
                        seat3: getSeatWager('seat3', 'player'),
                        seat4: getSeatWager('seat4', 'player'),
                        seat5: getSeatWager('seat5', 'player'),
                        total: getBetTypeTotalWager('player'),
                    },
                    tie: {
                        seat1: getSeatWager('seat1', 'tie'),
                        seat2: getSeatWager('seat2', 'tie'),
                        seat3: getSeatWager('seat3', 'tie'),
                        seat4: getSeatWager('seat4', 'tie'),
                        seat5: getSeatWager('seat5', 'tie'),
                        total: getBetTypeTotalWager('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatWager('seat1', 'bankerPair'),
                        seat2: getSeatWager('seat2', 'bankerPair'),
                        seat3: getSeatWager('seat3', 'bankerPair'),
                        seat4: getSeatWager('seat4', 'bankerPair'),
                        seat5: getSeatWager('seat5', 'bankerPair'),
                        total: getBetTypeTotalWager('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatWager('seat1', 'playerPair'),
                        seat2: getSeatWager('seat2', 'playerPair'),
                        seat3: getSeatWager('seat3', 'playerPair'),
                        seat4: getSeatWager('seat4', 'playerPair'),
                        seat5: getSeatWager('seat5', 'playerPair'),
                        total: getBetTypeTotalWager('playerPair'),
                    },
                    mega6: {
                        seat1: getSeatWager('seat1', 'mega6'),
                        seat2: getSeatWager('seat2', 'mega6'),
                        seat3: getSeatWager('seat3', 'mega6'),
                        seat4: getSeatWager('seat4', 'mega6'),
                        seat5: getSeatWager('seat5', 'mega6'),
                        total: getBetTypeTotalWager('mega6'),
                    },
                    total: {
                        seat1: getSeatTotalWager('seat1', baccaratMega6BetTypes),
                        seat2: getSeatTotalWager('seat2', baccaratMega6BetTypes),
                        seat3: getSeatTotalWager('seat3', baccaratMega6BetTypes),
                        seat4: getSeatTotalWager('seat4', baccaratMega6BetTypes),
                        seat5: getSeatTotalWager('seat5', baccaratMega6BetTypes),
                        total: totalWager,
                    },
                }
            }
            if (gameVariant === 'tiger') {
                obj = {
                    banker: {
                        seat1: getSeatWager('seat1', 'banker'),
                        seat2: getSeatWager('seat2', 'banker'),
                        seat3: getSeatWager('seat3', 'banker'),
                        seat4: getSeatWager('seat4', 'banker'),
                        seat5: getSeatWager('seat5', 'banker'),
                        total: getBetTypeTotalWager('banker'),
                    },
                    player: {
                        seat1: getSeatWager('seat1', 'player'),
                        seat2: getSeatWager('seat2', 'player'),
                        seat3: getSeatWager('seat3', 'player'),
                        seat4: getSeatWager('seat4', 'player'),
                        seat5: getSeatWager('seat5', 'player'),
                        total: getBetTypeTotalWager('player'),
                    },
                    tie: {
                        seat1: getSeatWager('seat1', 'tie'),
                        seat2: getSeatWager('seat2', 'tie'),
                        seat3: getSeatWager('seat3', 'tie'),
                        seat4: getSeatWager('seat4', 'tie'),
                        seat5: getSeatWager('seat5', 'tie'),
                        total: getBetTypeTotalWager('tie'),
                    },
                    tigerTie: {
                        seat1: getSeatWager('seat1', 'tigerTie'),
                        seat2: getSeatWager('seat2', 'tigerTie'),
                        seat3: getSeatWager('seat3', 'tigerTie'),
                        seat4: getSeatWager('seat4', 'tigerTie'),
                        seat5: getSeatWager('seat5', 'tigerTie'),
                        total: getBetTypeTotalWager('tigerTie'),
                    },
                    tigerPair: {
                        seat1: getSeatWager('seat1', 'tigerPair'),
                        seat2: getSeatWager('seat2', 'tigerPair'),
                        seat3: getSeatWager('seat3', 'tigerPair'),
                        seat4: getSeatWager('seat4', 'tigerPair'),
                        seat5: getSeatWager('seat5', 'tigerPair'),
                        total: getBetTypeTotalWager('tigerPair'),
                    },
                    tigerBig: {
                        seat1: getSeatWager('seat1', 'tigerBig'),
                        seat2: getSeatWager('seat2', 'tigerBig'),
                        seat3: getSeatWager('seat3', 'tigerBig'),
                        seat4: getSeatWager('seat4', 'tigerBig'),
                        seat5: getSeatWager('seat5', 'tigerBig'),
                        total: getBetTypeTotalWager('tigerBig'),
                    },
                    tigerSmall: {
                        seat1: getSeatWager('seat1', 'tigerSmall'),
                        seat2: getSeatWager('seat2', 'tigerSmall'),
                        seat3: getSeatWager('seat3', 'tigerSmall'),
                        seat4: getSeatWager('seat4', 'tigerSmall'),
                        seat5: getSeatWager('seat5', 'tigerSmall'),
                        total: getBetTypeTotalWager('tigerSmall'),
                    },
                    tiger: {
                        seat1: getSeatWager('seat1', 'tiger'),
                        seat2: getSeatWager('seat2', 'tiger'),
                        seat3: getSeatWager('seat3', 'tiger'),
                        seat4: getSeatWager('seat4', 'tiger'),
                        seat5: getSeatWager('seat5', 'tiger'),
                        total: getBetTypeTotalWager('tiger'),
                    },
                    total: {
                        seat1: getSeatTotalWager('seat1', baccaratTigerBetTypes),
                        seat2: getSeatTotalWager('seat2', baccaratTigerBetTypes),
                        seat3: getSeatTotalWager('seat3', baccaratTigerBetTypes),
                        seat4: getSeatTotalWager('seat4', baccaratTigerBetTypes),
                        seat5: getSeatTotalWager('seat5', baccaratTigerBetTypes),
                        total: totalWager,
                    },
                }
            }
        }
        if (gameType === 'blackjack') {
            if (gameVariant === 'standard') {
                obj = {
                    base: {
                        seat1: getSeatWager('seat1', 'base'),
                        seat2: getSeatWager('seat2', 'base'),
                        seat3: getSeatWager('seat3', 'base'),
                        seat4: getSeatWager('seat4', 'base'),
                        seat5: getSeatWager('seat5', 'base'),
                        total: getBetTypeTotalWager('base'),
                    },
                    doubleDown: {
                        seat1: getSeatWager('seat1', 'doubleDown'),
                        seat2: getSeatWager('seat2', 'doubleDown'),
                        seat3: getSeatWager('seat3', 'doubleDown'),
                        seat4: getSeatWager('seat4', 'doubleDown'),
                        seat5: getSeatWager('seat5', 'doubleDown'),
                        total: getBetTypeTotalWager('doubleDown'),
                    },
                    insurance: {
                        seat1: getSeatWager('seat1', 'insurance'),
                        seat2: getSeatWager('seat2', 'insurance'),
                        seat3: getSeatWager('seat3', 'insurance'),
                        seat4: getSeatWager('seat4', 'insurance'),
                        seat5: getSeatWager('seat5', 'insurance'),
                        total: getBetTypeTotalWager('insurance'),
                    },
                    pair: {
                        seat1: getSeatWager('seat1', 'pair'),
                        seat2: getSeatWager('seat2', 'pair'),
                        seat3: getSeatWager('seat3', 'pair'),
                        seat4: getSeatWager('seat4', 'pair'),
                        seat5: getSeatWager('seat5', 'pair'),
                        total: getBetTypeTotalWager('pair'),
                    },
                    split: {
                        seat1: getSeatWager('seat1', 'split'),
                        seat2: getSeatWager('seat2', 'split'),
                        seat3: getSeatWager('seat3', 'split'),
                        seat4: getSeatWager('seat4', 'split'),
                        seat5: getSeatWager('seat5', 'split'),
                        total: getBetTypeTotalWager('split'),
                    },
                    total: {
                        seat1: getSeatTotalWager('seat1', blackjackStandardBetTypes),
                        seat2: getSeatTotalWager('seat2', blackjackStandardBetTypes),
                        seat3: getSeatTotalWager('seat3', blackjackStandardBetTypes),
                        seat4: getSeatTotalWager('seat4', blackjackStandardBetTypes),
                        seat5: getSeatTotalWager('seat5', blackjackStandardBetTypes),
                        total: totalWager,
                    },
                }
            }
            if (gameVariant === 'anyPairSlot') {
                obj = {
                    base: {
                        seat1: getSeatWager('seat1', 'base'),
                        seat2: getSeatWager('seat2', 'base'),
                        seat3: getSeatWager('seat3', 'base'),
                        seat4: getSeatWager('seat4', 'base'),
                        seat5: getSeatWager('seat5', 'base'),
                        total: getBetTypeTotalWager('base'),
                    },
                    pair: {
                        seat1: getSeatWager('seat1', 'pair'),
                        seat2: getSeatWager('seat2', 'pair'),
                        seat3: getSeatWager('seat3', 'pair'),
                        seat4: getSeatWager('seat4', 'pair'),
                        seat5: getSeatWager('seat5', 'pair'),
                        total: getBetTypeTotalWager('pair'),
                    },
                    anyPair: {
                        seat1: getSeatWager('seat1', 'anyPair'),
                        seat2: getSeatWager('seat2', 'anyPair'),
                        seat3: getSeatWager('seat3', 'anyPair'),
                        seat4: getSeatWager('seat4', 'anyPair'),
                        seat5: getSeatWager('seat5', 'anyPair'),
                        total: getBetTypeTotalWager('anyPair'),
                    },
                    split: {
                        seat1: getSeatWager('seat1', 'split'),
                        seat2: getSeatWager('seat2', 'split'),
                        seat3: getSeatWager('seat3', 'split'),
                        seat4: getSeatWager('seat4', 'split'),
                        seat5: getSeatWager('seat5', 'split'),
                        total: getBetTypeTotalWager('split'),
                    },
                    doubleDown: {
                        seat1: getSeatWager('seat1', 'doubleDown'),
                        seat2: getSeatWager('seat2', 'doubleDown'),
                        seat3: getSeatWager('seat3', 'doubleDown'),
                        seat4: getSeatWager('seat4', 'doubleDown'),
                        seat5: getSeatWager('seat5', 'doubleDown'),
                        total: getBetTypeTotalWager('doubleDown'),
                    },
                    insurance: {
                        seat1: getSeatWager('seat1', 'insurance'),
                        seat2: getSeatWager('seat2', 'insurance'),
                        seat3: getSeatWager('seat3', 'insurance'),
                        seat4: getSeatWager('seat4', 'insurance'),
                        seat5: getSeatWager('seat5', 'insurance'),
                        total: getBetTypeTotalWager('insurance'),
                    },
                    bonus: {
                        seat1: getSeatWager('seat1', 'bonus'),
                        seat2: getSeatWager('seat2', 'bonus'),
                        seat3: getSeatWager('seat3', 'bonus'),
                        seat4: getSeatWager('seat4', 'bonus'),
                        seat5: getSeatWager('seat5', 'bonus'),
                        total: getBetTypeTotalWager('bonus'),
                    },
                    total: {
                        seat1: getSeatTotalWager('seat1', blackjackAnyPairSlotBetTypes),
                        seat2: getSeatTotalWager('seat2', blackjackAnyPairSlotBetTypes),
                        seat3: getSeatTotalWager('seat3', blackjackAnyPairSlotBetTypes),
                        seat4: getSeatTotalWager('seat4', blackjackAnyPairSlotBetTypes),
                        seat5: getSeatTotalWager('seat5', blackjackAnyPairSlotBetTypes),
                        total: totalWager,
                    },
                }
            }
        }

        return obj
    }

    const getTotalWager = () => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: getBetTypeTotalWager('banker'),
                    player: getBetTypeTotalWager('player'),
                    tie: getBetTypeTotalWager('tie'),
                    bankerPair: getBetTypeTotalWager('bankerPair'),
                    playerPair: getBetTypeTotalWager('playerPair'),
                    total: totalWager,
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: getBetTypeTotalWager('banker'),
                    player: getBetTypeTotalWager('player'),
                    tie: getBetTypeTotalWager('tie'),
                    bankerPair: getBetTypeTotalWager('bankerPair'),
                    playerPair: getBetTypeTotalWager('playerPair'),
                    lucky6: getBetTypeTotalWager('lucky6'),
                    total: totalWager,
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: getBetTypeTotalWager('banker'),
                    player: getBetTypeTotalWager('player'),
                    tie: getBetTypeTotalWager('tie'),
                    bankerPair: getBetTypeTotalWager('bankerPair'),
                    playerPair: getBetTypeTotalWager('playerPair'),
                    lucky6: getBetTypeTotalWager('lucky6'),
                    bonus: getBetTypeTotalWager('bonus'),
                    total: totalWager,
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: getBetTypeTotalWager('banker'),
                    player: getBetTypeTotalWager('player'),
                    tie: getBetTypeTotalWager('tie'),
                    bankerPair: getBetTypeTotalWager('bankerPair'),
                    playerPair: getBetTypeTotalWager('playerPair'),
                    mega6: getBetTypeTotalWager('mega6'),
                    total: totalWager,
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: getBetTypeTotalWager('banker'),
                    player: getBetTypeTotalWager('player'),
                    tie: getBetTypeTotalWager('tie'),
                    tigerTie: getBetTypeTotalWager('tigerTie'),
                    tigerPair: getBetTypeTotalWager('tigerPair'),
                    tigerBig: getBetTypeTotalWager('tigerBig'),
                    tigerSmall: getBetTypeTotalWager('tigerSmall'),
                    tiger: getBetTypeTotalWager('tiger'),
                    total: totalWager,
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: getBetTypeTotalWager('base'),
                    doubleDown: getBetTypeTotalWager('doubleDown'),
                    insurance: getBetTypeTotalWager('insurance'),
                    pair: getBetTypeTotalWager('pair'),
                    split: getBetTypeTotalWager('split'),
                    total: totalWager,
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: getBetTypeTotalWager('base'),
                    pair: getBetTypeTotalWager('pair'),
                    anyPair: getBetTypeTotalWager('anyPair'),
                    split: getBetTypeTotalWager('split'),
                    doubleDown: getBetTypeTotalWager('doubleDown'),
                    insurance: getBetTypeTotalWager('insurance'),
                    bonus: getBetTypeTotalWager('bonus'),
                    total: totalWager,
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    // WinLoss
    const getZoneWinLoss = (zone) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findZoneWinLossByBetType(zone.index, 'banker'),
                    player: findZoneWinLossByBetType(zone.index, 'player'),
                    tie: findZoneWinLossByBetType(zone.index, 'tie'),
                    bankerPair: findZoneWinLossByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneWinLossByBetType(zone.index, 'playerPair'),
                    total: findZoneWinLossTotal(zone.index),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findZoneWinLossByBetType(zone.index, 'banker'),
                    player: findZoneWinLossByBetType(zone.index, 'player'),
                    tie: findZoneWinLossByBetType(zone.index, 'tie'),
                    bankerPair: findZoneWinLossByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneWinLossByBetType(zone.index, 'playerPair'),
                    lucky6: findZoneWinLossByBetType(zone.index, 'lucky6'),
                    total: findZoneWinLossTotal(zone.index),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findZoneWinLossByBetType(zone.index, 'banker'),
                    player: findZoneWinLossByBetType(zone.index, 'player'),
                    tie: findZoneWinLossByBetType(zone.index, 'tie'),
                    bankerPair: findZoneWinLossByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneWinLossByBetType(zone.index, 'playerPair'),
                    lucky6: findZoneWinLossByBetType(zone.index, 'lucky6'),
                    bonus: findZoneWinLossByBetType(zone.index, 'bonus'),
                    total: findZoneWinLossTotal(zone.index),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findZoneWinLossByBetType(zone.index, 'banker'),
                    player: findZoneWinLossByBetType(zone.index, 'player'),
                    tie: findZoneWinLossByBetType(zone.index, 'tie'),
                    bankerPair: findZoneWinLossByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneWinLossByBetType(zone.index, 'playerPair'),
                    mega6: findZoneWinLossByBetType(zone.index, 'mega6'),
                    total: findZoneWinLossTotal(zone.index),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findZoneWinLossByBetType(zone.index, 'banker'),
                    player: findZoneWinLossByBetType(zone.index, 'player'),
                    tie: findZoneWinLossByBetType(zone.index, 'tie'),
                    tigerTie: findZoneWinLossByBetType(zone.index, 'tigerTie'),
                    tigerPair: findZoneWinLossByBetType(zone.index, 'tigerPair'),
                    tigerBig: findZoneWinLossByBetType(zone.index, 'tigerBig'),
                    tigerSmall: findZoneWinLossByBetType(zone.index, 'tigerSmall'),
                    tiger: findZoneWinLossByBetType(zone.index, 'tiger'),
                    total: findZoneWinLossTotal(zone.index),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findZoneWinLossByBetType(zone.index, 'base'),
                    doubleDown: findZoneWinLossByBetType(zone.index, 'doubleDown'),
                    insurance: findZoneWinLossByBetType(zone.index, 'insurance'),
                    pair: findZoneWinLossByBetType(zone.index, 'pair'),
                    split: findZoneWinLossByBetType(zone.index, 'split'),
                    total: findZoneWinLossTotal(zone.index),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findZoneWinLossByBetType(zone.index, 'base'),
                    pair: findZoneWinLossByBetType(zone.index, 'pair'),
                    anyPair: findZoneWinLossByBetType(zone.index, 'anyPair'),
                    split: findZoneWinLossByBetType(zone.index, 'split'),
                    doubleDown: findZoneWinLossByBetType(zone.index, 'doubleDown'),
                    insurance: findZoneWinLossByBetType(zone.index, 'insurance'),
                    bonus: findZoneWinLossByBetType(zone.index, 'bonus'),
                    total: findZoneWinLossTotal(zone.index),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getPitWinLoss = (pit) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findPitWinLossByBetType(pit.index, 'banker'),
                    player: findPitWinLossByBetType(pit.index, 'player'),
                    tie: findPitWinLossByBetType(pit.index, 'tie'),
                    bankerPair: findPitWinLossByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitWinLossByBetType(pit.index, 'playerPair'),
                    total: findPitWinLossTotal(pit.index),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findPitWinLossByBetType(pit.index, 'banker'),
                    player: findPitWinLossByBetType(pit.index, 'player'),
                    tie: findPitWinLossByBetType(pit.index, 'tie'),
                    bankerPair: findPitWinLossByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitWinLossByBetType(pit.index, 'playerPair'),
                    lucky6: findPitWinLossByBetType(pit.index, 'lucky6'),
                    total: findPitWinLossTotal(pit.index),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findPitWinLossByBetType(pit.index, 'banker'),
                    player: findPitWinLossByBetType(pit.index, 'player'),
                    tie: findPitWinLossByBetType(pit.index, 'tie'),
                    bankerPair: findPitWinLossByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitWinLossByBetType(pit.index, 'playerPair'),
                    lucky6: findPitWinLossByBetType(pit.index, 'lucky6'),
                    bonus: findPitWinLossByBetType(pit.index, 'bonus'),
                    total: findPitWinLossTotal(pit.index),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findPitWinLossByBetType(pit.index, 'banker'),
                    player: findPitWinLossByBetType(pit.index, 'player'),
                    tie: findPitWinLossByBetType(pit.index, 'tie'),
                    bankerPair: findPitWinLossByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitWinLossByBetType(pit.index, 'playerPair'),
                    mega6: findPitWinLossByBetType(pit.index, 'mega6'),
                    total: findPitWinLossTotal(pit.index),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findPitWinLossByBetType(pit.index, 'banker'),
                    player: findPitWinLossByBetType(pit.index, 'player'),
                    tie: findPitWinLossByBetType(pit.index, 'tie'),
                    tigerTie: findPitWinLossByBetType(pit.index, 'tigerTie'),
                    tigerPair: findPitWinLossByBetType(pit.index, 'tigerPair'),
                    tigerBig: findPitWinLossByBetType(pit.index, 'tigerBig'),
                    tigerSmall: findPitWinLossByBetType(pit.index, 'tigerSmall'),
                    tiger: findPitWinLossByBetType(pit.index, 'tiger'),
                    total: findPitWinLossTotal(pit.index),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findPitWinLossByBetType(pit.index, 'base'),
                    doubleDown: findPitWinLossByBetType(pit.index, 'doubleDown'),
                    insurance: findPitWinLossByBetType(pit.index, 'insurance'),
                    pair: findPitWinLossByBetType(pit.index, 'pair'),
                    split: findPitWinLossByBetType(pit.index, 'split'),
                    total: findPitWinLossTotal(pit.index),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findPitWinLossByBetType(pit.index, 'base'),
                    pair: findPitWinLossByBetType(pit.index, 'pair'),
                    anyPair: findPitWinLossByBetType(pit.index, 'anyPair'),
                    split: findPitWinLossByBetType(pit.index, 'split'),
                    doubleDown: findPitWinLossByBetType(pit.index, 'doubleDown'),
                    insurance: findPitWinLossByBetType(pit.index, 'insurance'),
                    bonus: findPitWinLossByBetType(pit.index, 'bonus'),
                    total: findPitWinLossTotal(pit.index),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getDeviceWinLoss = (deviceID) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findDeviceWinLossByBetType(deviceID, 'banker'),
                    player: findDeviceWinLossByBetType(deviceID, 'player'),
                    tie: findDeviceWinLossByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceWinLossByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceWinLossByBetType(deviceID, 'playerPair'),
                    total: findDeviceWinLossTotal(deviceID),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findDeviceWinLossByBetType(deviceID, 'banker'),
                    player: findDeviceWinLossByBetType(deviceID, 'player'),
                    tie: findDeviceWinLossByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceWinLossByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceWinLossByBetType(deviceID, 'playerPair'),
                    lucky6: findDeviceWinLossByBetType(deviceID, 'lucky6'),
                    total: findDeviceWinLossTotal(deviceID),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findDeviceWinLossByBetType(deviceID, 'banker'),
                    player: findDeviceWinLossByBetType(deviceID, 'player'),
                    tie: findDeviceWinLossByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceWinLossByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceWinLossByBetType(deviceID, 'playerPair'),
                    lucky6: findDeviceWinLossByBetType(deviceID, 'lucky6'),
                    bonus: findDeviceWinLossByBetType(deviceID, 'bonus'),
                    total: findDeviceWinLossTotal(deviceID),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findDeviceWinLossByBetType(deviceID, 'banker'),
                    player: findDeviceWinLossByBetType(deviceID, 'player'),
                    tie: findDeviceWinLossByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceWinLossByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceWinLossByBetType(deviceID, 'playerPair'),
                    mega6: findDeviceWinLossByBetType(deviceID, 'mega6'),
                    total: findDeviceWinLossTotal(deviceID),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findDeviceWinLossByBetType(deviceID, 'banker'),
                    player: findDeviceWinLossByBetType(deviceID, 'player'),
                    tie: findDeviceWinLossByBetType(deviceID, 'tie'),
                    tigerTie: findDeviceWinLossByBetType(deviceID, 'tigerTie'),
                    tigerPair: findDeviceWinLossByBetType(deviceID, 'tigerPair'),
                    tigerBig: findDeviceWinLossByBetType(deviceID, 'tigerBig'),
                    tigerSmall: findDeviceWinLossByBetType(deviceID, 'tigerSmall'),
                    tiger: findDeviceWinLossByBetType(deviceID, 'tiger'),
                    total: findDeviceWinLossTotal(deviceID),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findDeviceWinLossByBetType(deviceID, 'base'),
                    doubleDown: findDeviceWinLossByBetType(deviceID, 'doubleDown'),
                    insurance: findDeviceWinLossByBetType(deviceID, 'insurance'),
                    pair: findDeviceWinLossByBetType(deviceID, 'pair'),
                    split: findDeviceWinLossByBetType(deviceID, 'split'),
                    total: findDeviceWinLossTotal(deviceID),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findDeviceWinLossByBetType(deviceID, 'base'),
                    pair: findDeviceWinLossByBetType(deviceID, 'pair'),
                    anyPair: findDeviceWinLossByBetType(deviceID, 'anyPair'),
                    split: findDeviceWinLossByBetType(deviceID, 'split'),
                    doubleDown: findDeviceWinLossByBetType(deviceID, 'doubleDown'),
                    insurance: findDeviceWinLossByBetType(deviceID, 'insurance'),
                    bonus: findDeviceWinLossByBetType(deviceID, 'bonus'),
                    total: findDeviceWinLossTotal(deviceID),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getSeatBetTypeWinLoss = (gameType, gameVariant) => {
        let obj
        if (gameType === 'baccarat') {
            if (gameVariant === 'standard') {
                obj = {
                    banker: {
                        seat1: getSeatWinLoss('seat1', 'banker'),
                        seat2: getSeatWinLoss('seat2', 'banker'),
                        seat3: getSeatWinLoss('seat3', 'banker'),
                        seat4: getSeatWinLoss('seat4', 'banker'),
                        seat5: getSeatWinLoss('seat5', 'banker'),
                        total: getBetTypeTotalWinLoss('banker'),
                    },
                    player: {
                        seat1: getSeatWinLoss('seat1', 'player'),
                        seat2: getSeatWinLoss('seat2', 'player'),
                        seat3: getSeatWinLoss('seat3', 'player'),
                        seat4: getSeatWinLoss('seat4', 'player'),
                        seat5: getSeatWinLoss('seat5', 'player'),
                        total: getBetTypeTotalWinLoss('player'),
                    },
                    tie: {
                        seat1: getSeatWinLoss('seat1', 'tie'),
                        seat2: getSeatWinLoss('seat2', 'tie'),
                        seat3: getSeatWinLoss('seat3', 'tie'),
                        seat4: getSeatWinLoss('seat4', 'tie'),
                        seat5: getSeatWinLoss('seat5', 'tie'),
                        total: getBetTypeTotalWinLoss('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatWinLoss('seat1', 'bankerPair'),
                        seat2: getSeatWinLoss('seat2', 'bankerPair'),
                        seat3: getSeatWinLoss('seat3', 'bankerPair'),
                        seat4: getSeatWinLoss('seat4', 'bankerPair'),
                        seat5: getSeatWinLoss('seat5', 'bankerPair'),
                        total: getBetTypeTotalWinLoss('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatWinLoss('seat1', 'playerPair'),
                        seat2: getSeatWinLoss('seat2', 'playerPair'),
                        seat3: getSeatWinLoss('seat3', 'playerPair'),
                        seat4: getSeatWinLoss('seat4', 'playerPair'),
                        seat5: getSeatWinLoss('seat5', 'playerPair'),
                        total: getBetTypeTotalWinLoss('playerPair'),
                    },
                    total: {
                        seat1: getSeatTotalWinLoss('seat1', baccaratStandardBetTypes),
                        seat2: getSeatTotalWinLoss('seat2', baccaratStandardBetTypes),
                        seat3: getSeatTotalWinLoss('seat3', baccaratStandardBetTypes),
                        seat4: getSeatTotalWinLoss('seat4', baccaratStandardBetTypes),
                        seat5: getSeatTotalWinLoss('seat5', baccaratStandardBetTypes),
                        total: totalWinLoss,
                    },
                }
            }
            if (gameVariant === 'lucky6') {
                obj = {
                    banker: {
                        seat1: getSeatWinLoss('seat1', 'banker'),
                        seat2: getSeatWinLoss('seat2', 'banker'),
                        seat3: getSeatWinLoss('seat3', 'banker'),
                        seat4: getSeatWinLoss('seat4', 'banker'),
                        seat5: getSeatWinLoss('seat5', 'banker'),
                        total: getBetTypeTotalWinLoss('banker'),
                    },
                    player: {
                        seat1: getSeatWinLoss('seat1', 'player'),
                        seat2: getSeatWinLoss('seat2', 'player'),
                        seat3: getSeatWinLoss('seat3', 'player'),
                        seat4: getSeatWinLoss('seat4', 'player'),
                        seat5: getSeatWinLoss('seat5', 'player'),
                        total: getBetTypeTotalWinLoss('player'),
                    },
                    tie: {
                        seat1: getSeatWinLoss('seat1', 'tie'),
                        seat2: getSeatWinLoss('seat2', 'tie'),
                        seat3: getSeatWinLoss('seat3', 'tie'),
                        seat4: getSeatWinLoss('seat4', 'tie'),
                        seat5: getSeatWinLoss('seat5', 'tie'),
                        total: getBetTypeTotalWinLoss('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatWinLoss('seat1', 'bankerPair'),
                        seat2: getSeatWinLoss('seat2', 'bankerPair'),
                        seat3: getSeatWinLoss('seat3', 'bankerPair'),
                        seat4: getSeatWinLoss('seat4', 'bankerPair'),
                        seat5: getSeatWinLoss('seat5', 'bankerPair'),
                        total: getBetTypeTotalWinLoss('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatWinLoss('seat1', 'playerPair'),
                        seat2: getSeatWinLoss('seat2', 'playerPair'),
                        seat3: getSeatWinLoss('seat3', 'playerPair'),
                        seat4: getSeatWinLoss('seat4', 'playerPair'),
                        seat5: getSeatWinLoss('seat5', 'playerPair'),
                        total: getBetTypeTotalWinLoss('playerPair'),
                    },
                    lucky6: {
                        seat1: getSeatWinLoss('seat1', 'lucky6'),
                        seat2: getSeatWinLoss('seat2', 'lucky6'),
                        seat3: getSeatWinLoss('seat3', 'lucky6'),
                        seat4: getSeatWinLoss('seat4', 'lucky6'),
                        seat5: getSeatWinLoss('seat5', 'lucky6'),
                        total: getBetTypeTotalWinLoss('lucky6'),
                    },
                    total: {
                        seat1: getSeatTotalWinLoss('seat1', baccaratLucky6BetTypes),
                        seat2: getSeatTotalWinLoss('seat2', baccaratLucky6BetTypes),
                        seat3: getSeatTotalWinLoss('seat3', baccaratLucky6BetTypes),
                        seat4: getSeatTotalWinLoss('seat4', baccaratLucky6BetTypes),
                        seat5: getSeatTotalWinLoss('seat5', baccaratLucky6BetTypes),
                        total: totalWinLoss,
                    },
                }
            }
            if (gameVariant === 'lucky6Slot') {
                obj = {
                    banker: {
                        seat1: getSeatWinLoss('seat1', 'banker'),
                        seat2: getSeatWinLoss('seat2', 'banker'),
                        seat3: getSeatWinLoss('seat3', 'banker'),
                        seat4: getSeatWinLoss('seat4', 'banker'),
                        seat5: getSeatWinLoss('seat5', 'banker'),
                        total: getBetTypeTotalWinLoss('banker'),
                    },
                    player: {
                        seat1: getSeatWinLoss('seat1', 'player'),
                        seat2: getSeatWinLoss('seat2', 'player'),
                        seat3: getSeatWinLoss('seat3', 'player'),
                        seat4: getSeatWinLoss('seat4', 'player'),
                        seat5: getSeatWinLoss('seat5', 'player'),
                        total: getBetTypeTotalWinLoss('player'),
                    },
                    tie: {
                        seat1: getSeatWinLoss('seat1', 'tie'),
                        seat2: getSeatWinLoss('seat2', 'tie'),
                        seat3: getSeatWinLoss('seat3', 'tie'),
                        seat4: getSeatWinLoss('seat4', 'tie'),
                        seat5: getSeatWinLoss('seat5', 'tie'),
                        total: getBetTypeTotalWinLoss('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatWinLoss('seat1', 'bankerPair'),
                        seat2: getSeatWinLoss('seat2', 'bankerPair'),
                        seat3: getSeatWinLoss('seat3', 'bankerPair'),
                        seat4: getSeatWinLoss('seat4', 'bankerPair'),
                        seat5: getSeatWinLoss('seat5', 'bankerPair'),
                        total: getBetTypeTotalWinLoss('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatWinLoss('seat1', 'playerPair'),
                        seat2: getSeatWinLoss('seat2', 'playerPair'),
                        seat3: getSeatWinLoss('seat3', 'playerPair'),
                        seat4: getSeatWinLoss('seat4', 'playerPair'),
                        seat5: getSeatWinLoss('seat5', 'playerPair'),
                        total: getBetTypeTotalWinLoss('playerPair'),
                    },
                    lucky6: {
                        seat1: getSeatWinLoss('seat1', 'lucky6'),
                        seat2: getSeatWinLoss('seat2', 'lucky6'),
                        seat3: getSeatWinLoss('seat3', 'lucky6'),
                        seat4: getSeatWinLoss('seat4', 'lucky6'),
                        seat5: getSeatWinLoss('seat5', 'lucky6'),
                        total: getBetTypeTotalWinLoss('lucky6'),
                    },
                    bonus: {
                        seat1: getSeatWinLoss('seat1', 'bonus'),
                        seat2: getSeatWinLoss('seat2', 'bonus'),
                        seat3: getSeatWinLoss('seat3', 'bonus'),
                        seat4: getSeatWinLoss('seat4', 'bonus'),
                        seat5: getSeatWinLoss('seat5', 'bonus'),
                        total: getBetTypeTotalWinLoss('bonus'),
                    },
                    total: {
                        seat1: getSeatTotalWinLoss('seat1', baccaratLucky6BetTypes),
                        seat2: getSeatTotalWinLoss('seat2', baccaratLucky6BetTypes),
                        seat3: getSeatTotalWinLoss('seat3', baccaratLucky6BetTypes),
                        seat4: getSeatTotalWinLoss('seat4', baccaratLucky6BetTypes),
                        seat5: getSeatTotalWinLoss('seat5', baccaratLucky6BetTypes),
                        total: totalWinLoss,
                    },
                }
            }
            if (gameVariant === 'mega6') {
                obj = {
                    banker: {
                        seat1: getSeatWinLoss('seat1', 'banker'),
                        seat2: getSeatWinLoss('seat2', 'banker'),
                        seat3: getSeatWinLoss('seat3', 'banker'),
                        seat4: getSeatWinLoss('seat4', 'banker'),
                        seat5: getSeatWinLoss('seat5', 'banker'),
                        total: getBetTypeTotalWinLoss('banker'),
                    },
                    player: {
                        seat1: getSeatWinLoss('seat1', 'player'),
                        seat2: getSeatWinLoss('seat2', 'player'),
                        seat3: getSeatWinLoss('seat3', 'player'),
                        seat4: getSeatWinLoss('seat4', 'player'),
                        seat5: getSeatWinLoss('seat5', 'player'),
                        total: getBetTypeTotalWinLoss('player'),
                    },
                    tie: {
                        seat1: getSeatWinLoss('seat1', 'tie'),
                        seat2: getSeatWinLoss('seat2', 'tie'),
                        seat3: getSeatWinLoss('seat3', 'tie'),
                        seat4: getSeatWinLoss('seat4', 'tie'),
                        seat5: getSeatWinLoss('seat5', 'tie'),
                        total: getBetTypeTotalWinLoss('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatWinLoss('seat1', 'bankerPair'),
                        seat2: getSeatWinLoss('seat2', 'bankerPair'),
                        seat3: getSeatWinLoss('seat3', 'bankerPair'),
                        seat4: getSeatWinLoss('seat4', 'bankerPair'),
                        seat5: getSeatWinLoss('seat5', 'bankerPair'),
                        total: getBetTypeTotalWinLoss('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatWinLoss('seat1', 'playerPair'),
                        seat2: getSeatWinLoss('seat2', 'playerPair'),
                        seat3: getSeatWinLoss('seat3', 'playerPair'),
                        seat4: getSeatWinLoss('seat4', 'playerPair'),
                        seat5: getSeatWinLoss('seat5', 'playerPair'),
                        total: getBetTypeTotalWinLoss('playerPair'),
                    },
                    mega6: {
                        seat1: getSeatWinLoss('seat1', 'mega6'),
                        seat2: getSeatWinLoss('seat2', 'mega6'),
                        seat3: getSeatWinLoss('seat3', 'mega6'),
                        seat4: getSeatWinLoss('seat4', 'mega6'),
                        seat5: getSeatWinLoss('seat5', 'mega6'),
                        total: getBetTypeTotalWinLoss('mega6'),
                    },
                    total: {
                        seat1: getSeatTotalWinLoss('seat1', baccaratMega6BetTypes),
                        seat2: getSeatTotalWinLoss('seat2', baccaratMega6BetTypes),
                        seat3: getSeatTotalWinLoss('seat3', baccaratMega6BetTypes),
                        seat4: getSeatTotalWinLoss('seat4', baccaratMega6BetTypes),
                        seat5: getSeatTotalWinLoss('seat5', baccaratMega6BetTypes),
                        total: totalWinLoss,
                    },
                }
            }
            if (gameVariant === 'tiger') {
                obj = {
                    banker: {
                        seat1: getSeatWinLoss('seat1', 'banker'),
                        seat2: getSeatWinLoss('seat2', 'banker'),
                        seat3: getSeatWinLoss('seat3', 'banker'),
                        seat4: getSeatWinLoss('seat4', 'banker'),
                        seat5: getSeatWinLoss('seat5', 'banker'),
                        total: getBetTypeTotalWinLoss('banker'),
                    },
                    player: {
                        seat1: getSeatWinLoss('seat1', 'player'),
                        seat2: getSeatWinLoss('seat2', 'player'),
                        seat3: getSeatWinLoss('seat3', 'player'),
                        seat4: getSeatWinLoss('seat4', 'player'),
                        seat5: getSeatWinLoss('seat5', 'player'),
                        total: getBetTypeTotalWinLoss('player'),
                    },
                    tie: {
                        seat1: getSeatWinLoss('seat1', 'tie'),
                        seat2: getSeatWinLoss('seat2', 'tie'),
                        seat3: getSeatWinLoss('seat3', 'tie'),
                        seat4: getSeatWinLoss('seat4', 'tie'),
                        seat5: getSeatWinLoss('seat5', 'tie'),
                        total: getBetTypeTotalWinLoss('tie'),
                    },
                    tigerTie: {
                        seat1: getSeatWinLoss('seat1', 'tigerTie'),
                        seat2: getSeatWinLoss('seat2', 'tigerTie'),
                        seat3: getSeatWinLoss('seat3', 'tigerTie'),
                        seat4: getSeatWinLoss('seat4', 'tigerTie'),
                        seat5: getSeatWinLoss('seat5', 'tigerTie'),
                        total: getBetTypeTotalWinLoss('tigerTie'),
                    },
                    tigerPair: {
                        seat1: getSeatWinLoss('seat1', 'tigerPair'),
                        seat2: getSeatWinLoss('seat2', 'tigerPair'),
                        seat3: getSeatWinLoss('seat3', 'tigerPair'),
                        seat4: getSeatWinLoss('seat4', 'tigerPair'),
                        seat5: getSeatWinLoss('seat5', 'tigerPair'),
                        total: getBetTypeTotalWinLoss('tigerPair'),
                    },
                    tigerBig: {
                        seat1: getSeatWinLoss('seat1', 'tigerBig'),
                        seat2: getSeatWinLoss('seat2', 'tigerBig'),
                        seat3: getSeatWinLoss('seat3', 'tigerBig'),
                        seat4: getSeatWinLoss('seat4', 'tigerBig'),
                        seat5: getSeatWinLoss('seat5', 'tigerBig'),
                        total: getBetTypeTotalWinLoss('tigerBig'),
                    },
                    tigerSmall: {
                        seat1: getSeatWinLoss('seat1', 'tigerSmall'),
                        seat2: getSeatWinLoss('seat2', 'tigerSmall'),
                        seat3: getSeatWinLoss('seat3', 'tigerSmall'),
                        seat4: getSeatWinLoss('seat4', 'tigerSmall'),
                        seat5: getSeatWinLoss('seat5', 'tigerSmall'),
                        total: getBetTypeTotalWinLoss('tigerSmall'),
                    },
                    tiger: {
                        seat1: getSeatWinLoss('seat1', 'tiger'),
                        seat2: getSeatWinLoss('seat2', 'tiger'),
                        seat3: getSeatWinLoss('seat3', 'tiger'),
                        seat4: getSeatWinLoss('seat4', 'tiger'),
                        seat5: getSeatWinLoss('seat5', 'tiger'),
                        total: getBetTypeTotalWinLoss('tiger'),
                    },
                    total: {
                        seat1: getSeatTotalWinLoss('seat1', baccaratTigerBetTypes),
                        seat2: getSeatTotalWinLoss('seat2', baccaratTigerBetTypes),
                        seat3: getSeatTotalWinLoss('seat3', baccaratTigerBetTypes),
                        seat4: getSeatTotalWinLoss('seat4', baccaratTigerBetTypes),
                        seat5: getSeatTotalWinLoss('seat5', baccaratTigerBetTypes),
                        total: totalWinLoss,
                    },
                }
            }
        }
        if (gameType === 'blackjack') {
            if (gameVariant === 'standard') {
                obj = {
                    base: {
                        seat1: getSeatWinLoss('seat1', 'base'),
                        seat2: getSeatWinLoss('seat2', 'base'),
                        seat3: getSeatWinLoss('seat3', 'base'),
                        seat4: getSeatWinLoss('seat4', 'base'),
                        seat5: getSeatWinLoss('seat5', 'base'),
                        total: getBetTypeTotalWinLoss('base'),
                    },
                    doubleDown: {
                        seat1: getSeatWinLoss('seat1', 'doubleDown'),
                        seat2: getSeatWinLoss('seat2', 'doubleDown'),
                        seat3: getSeatWinLoss('seat3', 'doubleDown'),
                        seat4: getSeatWinLoss('seat4', 'doubleDown'),
                        seat5: getSeatWinLoss('seat5', 'doubleDown'),
                        total: getBetTypeTotalWinLoss('doubleDown'),
                    },
                    insurance: {
                        seat1: getSeatWinLoss('seat1', 'insurance'),
                        seat2: getSeatWinLoss('seat2', 'insurance'),
                        seat3: getSeatWinLoss('seat3', 'insurance'),
                        seat4: getSeatWinLoss('seat4', 'insurance'),
                        seat5: getSeatWinLoss('seat5', 'insurance'),
                        total: getBetTypeTotalWinLoss('insurance'),
                    },
                    pair: {
                        seat1: getSeatWinLoss('seat1', 'pair'),
                        seat2: getSeatWinLoss('seat2', 'pair'),
                        seat3: getSeatWinLoss('seat3', 'pair'),
                        seat4: getSeatWinLoss('seat4', 'pair'),
                        seat5: getSeatWinLoss('seat5', 'pair'),
                        total: getBetTypeTotalWinLoss('pair'),
                    },
                    split: {
                        seat1: getSeatWinLoss('seat1', 'split'),
                        seat2: getSeatWinLoss('seat2', 'split'),
                        seat3: getSeatWinLoss('seat3', 'split'),
                        seat4: getSeatWinLoss('seat4', 'split'),
                        seat5: getSeatWinLoss('seat5', 'split'),
                        total: getBetTypeTotalWinLoss('split'),
                    },
                    total: {
                        seat1: getSeatTotalWinLoss('seat1', blackjackStandardBetTypes),
                        seat2: getSeatTotalWinLoss('seat2', blackjackStandardBetTypes),
                        seat3: getSeatTotalWinLoss('seat3', blackjackStandardBetTypes),
                        seat4: getSeatTotalWinLoss('seat4', blackjackStandardBetTypes),
                        seat5: getSeatTotalWinLoss('seat5', blackjackStandardBetTypes),
                        total: totalWinLoss,
                    },
                }
            }
            if (gameVariant === 'anyPairSlot') {
                obj = {
                    base: {
                        seat1: getSeatWinLoss('seat1', 'base'),
                        seat2: getSeatWinLoss('seat2', 'base'),
                        seat3: getSeatWinLoss('seat3', 'base'),
                        seat4: getSeatWinLoss('seat4', 'base'),
                        seat5: getSeatWinLoss('seat5', 'base'),
                        total: getBetTypeTotalWinLoss('base'),
                    },
                    pair: {
                        seat1: getSeatWinLoss('seat1', 'pair'),
                        seat2: getSeatWinLoss('seat2', 'pair'),
                        seat3: getSeatWinLoss('seat3', 'pair'),
                        seat4: getSeatWinLoss('seat4', 'pair'),
                        seat5: getSeatWinLoss('seat5', 'pair'),
                        total: getBetTypeTotalWinLoss('pair'),
                    },
                    anyPair: {
                        seat1: getSeatWinLoss('seat1', 'anyPair'),
                        seat2: getSeatWinLoss('seat2', 'anyPair'),
                        seat3: getSeatWinLoss('seat3', 'anyPair'),
                        seat4: getSeatWinLoss('seat4', 'anyPair'),
                        seat5: getSeatWinLoss('seat5', 'anyPair'),
                        total: getBetTypeTotalWinLoss('anyPair'),
                    },
                    split: {
                        seat1: getSeatWinLoss('seat1', 'split'),
                        seat2: getSeatWinLoss('seat2', 'split'),
                        seat3: getSeatWinLoss('seat3', 'split'),
                        seat4: getSeatWinLoss('seat4', 'split'),
                        seat5: getSeatWinLoss('seat5', 'split'),
                        total: getBetTypeTotalWinLoss('split'),
                    },
                    doubleDown: {
                        seat1: getSeatWinLoss('seat1', 'doubleDown'),
                        seat2: getSeatWinLoss('seat2', 'doubleDown'),
                        seat3: getSeatWinLoss('seat3', 'doubleDown'),
                        seat4: getSeatWinLoss('seat4', 'doubleDown'),
                        seat5: getSeatWinLoss('seat5', 'doubleDown'),
                        total: getBetTypeTotalWinLoss('doubleDown'),
                    },
                    insurance: {
                        seat1: getSeatWinLoss('seat1', 'insurance'),
                        seat2: getSeatWinLoss('seat2', 'insurance'),
                        seat3: getSeatWinLoss('seat3', 'insurance'),
                        seat4: getSeatWinLoss('seat4', 'insurance'),
                        seat5: getSeatWinLoss('seat5', 'insurance'),
                        total: getBetTypeTotalWinLoss('insurance'),
                    },
                    bonus: {
                        seat1: getSeatWinLoss('seat1', 'bonus'),
                        seat2: getSeatWinLoss('seat2', 'bonus'),
                        seat3: getSeatWinLoss('seat3', 'bonus'),
                        seat4: getSeatWinLoss('seat4', 'bonus'),
                        seat5: getSeatWinLoss('seat5', 'bonus'),
                        total: getBetTypeTotalWinLoss('bonus'),
                    },
                    total: {
                        seat1: getSeatTotalWinLoss('seat1', blackjackAnyPairSlotBetTypes),
                        seat2: getSeatTotalWinLoss('seat2', blackjackAnyPairSlotBetTypes),
                        seat3: getSeatTotalWinLoss('seat3', blackjackAnyPairSlotBetTypes),
                        seat4: getSeatTotalWinLoss('seat4', blackjackAnyPairSlotBetTypes),
                        seat5: getSeatTotalWinLoss('seat5', blackjackAnyPairSlotBetTypes),
                        total: totalWinLoss,
                    },
                }
            }
        }

        return obj
    }

    const getTotalWinLoss = () => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: getBetTypeTotalWinLoss('banker'),
                    player: getBetTypeTotalWinLoss('player'),
                    tie: getBetTypeTotalWinLoss('tie'),
                    bankerPair: getBetTypeTotalWinLoss('bankerPair'),
                    playerPair: getBetTypeTotalWinLoss('playerPair'),
                    total: totalWinLoss,
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: getBetTypeTotalWinLoss('banker'),
                    player: getBetTypeTotalWinLoss('player'),
                    tie: getBetTypeTotalWinLoss('tie'),
                    bankerPair: getBetTypeTotalWinLoss('bankerPair'),
                    playerPair: getBetTypeTotalWinLoss('playerPair'),
                    lucky6: getBetTypeTotalWinLoss('lucky6'),
                    total: totalWinLoss,
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: getBetTypeTotalWinLoss('banker'),
                    player: getBetTypeTotalWinLoss('player'),
                    tie: getBetTypeTotalWinLoss('tie'),
                    bankerPair: getBetTypeTotalWinLoss('bankerPair'),
                    playerPair: getBetTypeTotalWinLoss('playerPair'),
                    lucky6: getBetTypeTotalWinLoss('lucky6'),
                    bonus: getBetTypeTotalWinLoss('bonus'),
                    total: totalWinLoss,
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: getBetTypeTotalWinLoss('banker'),
                    player: getBetTypeTotalWinLoss('player'),
                    tie: getBetTypeTotalWinLoss('tie'),
                    bankerPair: getBetTypeTotalWinLoss('bankerPair'),
                    playerPair: getBetTypeTotalWinLoss('playerPair'),
                    mega6: getBetTypeTotalWinLoss('mega6'),
                    total: totalWinLoss,
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: getBetTypeTotalWinLoss('banker'),
                    player: getBetTypeTotalWinLoss('player'),
                    tie: getBetTypeTotalWinLoss('tie'),
                    tigerTie: getBetTypeTotalWinLoss('tigerTie'),
                    tigerPair: getBetTypeTotalWinLoss('tigerPair'),
                    tigerBig: getBetTypeTotalWinLoss('tigerBig'),
                    tigerSmall: getBetTypeTotalWinLoss('tigerSmall'),
                    tiger: getBetTypeTotalWinLoss('tiger'),
                    total: totalWinLoss,
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: getBetTypeTotalWinLoss('base'),
                    doubleDown: getBetTypeTotalWinLoss('doubleDown'),
                    insurance: getBetTypeTotalWinLoss('insurance'),
                    pair: getBetTypeTotalWinLoss('pair'),
                    split: getBetTypeTotalWinLoss('split'),
                    total: totalWinLoss,
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: getBetTypeTotalWinLoss('base'),
                    pair: getBetTypeTotalWinLoss('pair'),
                    anyPair: getBetTypeTotalWinLoss('anyPair'),
                    split: getBetTypeTotalWinLoss('split'),
                    doubleDown: getBetTypeTotalWinLoss('doubleDown'),
                    insurance: getBetTypeTotalWinLoss('insurance'),
                    bonus: getBetTypeTotalWinLoss('bonus'),
                    total: totalWinLoss,
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    // Bet Count
    const getZoneBetCount = (zone) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findZoneBetCountByBetType(zone.index, 'banker'),
                    player: findZoneBetCountByBetType(zone.index, 'player'),
                    tie: findZoneBetCountByBetType(zone.index, 'tie'),
                    bankerPair: findZoneBetCountByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneBetCountByBetType(zone.index, 'playerPair'),
                    total: findZoneBetCountTotal(zone.index),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findZoneBetCountByBetType(zone.index, 'banker'),
                    player: findZoneBetCountByBetType(zone.index, 'player'),
                    tie: findZoneBetCountByBetType(zone.index, 'tie'),
                    bankerPair: findZoneBetCountByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneBetCountByBetType(zone.index, 'playerPair'),
                    lucky6: findZoneBetCountByBetType(zone.index, 'lucky6'),
                    total: findZoneBetCountTotal(zone.index),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findZoneBetCountByBetType(zone.index, 'banker'),
                    player: findZoneBetCountByBetType(zone.index, 'player'),
                    tie: findZoneBetCountByBetType(zone.index, 'tie'),
                    bankerPair: findZoneBetCountByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneBetCountByBetType(zone.index, 'playerPair'),
                    lucky6: findZoneBetCountByBetType(zone.index, 'lucky6'),
                    bonus: findZoneBetCountByBetType(zone.index, 'bonus'),
                    total: findZoneBetCountTotal(zone.index),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findZoneBetCountByBetType(zone.index, 'banker'),
                    player: findZoneBetCountByBetType(zone.index, 'player'),
                    tie: findZoneBetCountByBetType(zone.index, 'tie'),
                    bankerPair: findZoneBetCountByBetType(zone.index, 'bankerPair'),
                    playerPair: findZoneBetCountByBetType(zone.index, 'playerPair'),
                    mega6: findZoneBetCountByBetType(zone.index, 'mega6'),
                    total: findZoneBetCountTotal(zone.index),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findZoneBetCountByBetType(zone.index, 'banker'),
                    player: findZoneBetCountByBetType(zone.index, 'player'),
                    tie: findZoneBetCountByBetType(zone.index, 'tie'),
                    tigerTie: findZoneBetCountByBetType(zone.index, 'tigerTie'),
                    tigerPair: findZoneBetCountByBetType(zone.index, 'tigerPair'),
                    tigerBig: findZoneBetCountByBetType(zone.index, 'tigerBig'),
                    tigerSmall: findZoneBetCountByBetType(zone.index, 'tigerSmall'),
                    tiger: findZoneBetCountByBetType(zone.index, 'tiger'),
                    total: findZoneBetCountTotal(zone.index),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findZoneBetCountByBetType(zone.index, 'base'),
                    doubleDown: findZoneBetCountByBetType(zone.index, 'doubleDown'),
                    insurance: findZoneBetCountByBetType(zone.index, 'insurance'),
                    pair: findZoneBetCountByBetType(zone.index, 'pair'),
                    split: findZoneBetCountByBetType(zone.index, 'split'),
                    total: findZoneBetCountTotal(zone.index),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findZoneBetCountByBetType(zone.index, 'base'),
                    pair: findZoneBetCountByBetType(zone.index, 'pair'),
                    anyPair: findZoneBetCountByBetType(zone.index, 'anyPair'),
                    split: findZoneBetCountByBetType(zone.index, 'split'),
                    doubleDown: findZoneBetCountByBetType(zone.index, 'doubleDown'),
                    insurance: findZoneBetCountByBetType(zone.index, 'insurance'),
                    bonus: findZoneBetCountByBetType(zone.index, 'bonus'),
                    total: findZoneBetCountTotal(zone.index),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getPitBetCount = (pit) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findPitBetCountByBetType(pit.index, 'banker'),
                    player: findPitBetCountByBetType(pit.index, 'player'),
                    tie: findPitBetCountByBetType(pit.index, 'tie'),
                    bankerPair: findPitBetCountByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitBetCountByBetType(pit.index, 'playerPair'),
                    total: findPitBetCountTotal(pit.index),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findPitBetCountByBetType(pit.index, 'banker'),
                    player: findPitBetCountByBetType(pit.index, 'player'),
                    tie: findPitBetCountByBetType(pit.index, 'tie'),
                    bankerPair: findPitBetCountByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitBetCountByBetType(pit.index, 'playerPair'),
                    lucky6: findPitBetCountByBetType(pit.index, 'lucky6'),
                    total: findPitBetCountTotal(pit.index),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findPitBetCountByBetType(pit.index, 'banker'),
                    player: findPitBetCountByBetType(pit.index, 'player'),
                    tie: findPitBetCountByBetType(pit.index, 'tie'),
                    bankerPair: findPitBetCountByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitBetCountByBetType(pit.index, 'playerPair'),
                    lucky6: findPitBetCountByBetType(pit.index, 'lucky6'),
                    bonus: findPitBetCountByBetType(pit.index, 'bonus'),
                    total: findPitBetCountTotal(pit.index),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findPitBetCountByBetType(pit.index, 'banker'),
                    player: findPitBetCountByBetType(pit.index, 'player'),
                    tie: findPitBetCountByBetType(pit.index, 'tie'),
                    bankerPair: findPitBetCountByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitBetCountByBetType(pit.index, 'playerPair'),
                    mega6: findPitBetCountByBetType(pit.index, 'mega6'),
                    total: findPitBetCountTotal(pit.index),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findPitBetCountByBetType(pit.index, 'banker'),
                    player: findPitBetCountByBetType(pit.index, 'player'),
                    tie: findPitBetCountByBetType(pit.index, 'tie'),
                    tigerTie: findPitBetCountByBetType(pit.index, 'tigerTie'),
                    tigerPair: findPitBetCountByBetType(pit.index, 'tigerPair'),
                    tigerBig: findPitBetCountByBetType(pit.index, 'tigerBig'),
                    tigerSmall: findPitBetCountByBetType(pit.index, 'tigerSmall'),
                    tiger: findPitBetCountByBetType(pit.index, 'tiger'),
                    total: findPitBetCountTotal(pit.index),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findPitBetCountByBetType(pit.index, 'base'),
                    doubleDown: findPitBetCountByBetType(pit.index, 'doubleDown'),
                    insurance: findPitBetCountByBetType(pit.index, 'insurance'),
                    pair: findPitBetCountByBetType(pit.index, 'pair'),
                    split: findPitBetCountByBetType(pit.index, 'split'),
                    total: findPitBetCountTotal(pit.index),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findPitBetCountByBetType(pit.index, 'base'),
                    pair: findPitBetCountByBetType(pit.index, 'pair'),
                    anyPair: findPitBetCountByBetType(pit.index, 'anyPair'),
                    split: findPitBetCountByBetType(pit.index, 'split'),
                    doubleDown: findPitBetCountByBetType(pit.index, 'doubleDown'),
                    insurance: findPitBetCountByBetType(pit.index, 'insurance'),
                    bonus: findPitBetCountByBetType(pit.index, 'bonus'),
                    total: findPitBetCountTotal(pit.index),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getDeviceBetCount = (deviceID) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findDeviceBetCountByBetType(deviceID, 'banker'),
                    player: findDeviceBetCountByBetType(deviceID, 'player'),
                    tie: findDeviceBetCountByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceBetCountByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceBetCountByBetType(deviceID, 'playerPair'),
                    total: findDeviceBetCountTotal(deviceID),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findDeviceBetCountByBetType(deviceID, 'banker'),
                    player: findDeviceBetCountByBetType(deviceID, 'player'),
                    tie: findDeviceBetCountByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceBetCountByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceBetCountByBetType(deviceID, 'playerPair'),
                    lucky6: findDeviceBetCountByBetType(deviceID, 'lucky6'),
                    total: findDeviceBetCountTotal(deviceID),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findDeviceBetCountByBetType(deviceID, 'banker'),
                    player: findDeviceBetCountByBetType(deviceID, 'player'),
                    tie: findDeviceBetCountByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceBetCountByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceBetCountByBetType(deviceID, 'playerPair'),
                    lucky6: findDeviceBetCountByBetType(deviceID, 'lucky6'),
                    bonus: findDeviceBetCountByBetType(deviceID, 'bonus'),
                    total: findDeviceBetCountTotal(deviceID),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findDeviceBetCountByBetType(deviceID, 'banker'),
                    player: findDeviceBetCountByBetType(deviceID, 'player'),
                    tie: findDeviceBetCountByBetType(deviceID, 'tie'),
                    bankerPair: findDeviceBetCountByBetType(deviceID, 'bankerPair'),
                    playerPair: findDeviceBetCountByBetType(deviceID, 'playerPair'),
                    mega6: findDeviceBetCountByBetType(deviceID, 'mega6'),
                    total: findDeviceBetCountTotal(deviceID),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findDeviceBetCountByBetType(deviceID, 'banker'),
                    player: findDeviceBetCountByBetType(deviceID, 'player'),
                    tie: findDeviceBetCountByBetType(deviceID, 'tie'),
                    tigerTie: findDeviceBetCountByBetType(deviceID, 'tigerTie'),
                    tigerPair: findDeviceBetCountByBetType(deviceID, 'tigerPair'),
                    tigerBig: findDeviceBetCountByBetType(deviceID, 'tigerBig'),
                    tigerSmall: findDeviceBetCountByBetType(deviceID, 'tigerSmall'),
                    tiger: findDeviceBetCountByBetType(deviceID, 'tiger'),
                    total: findDeviceBetCountTotal(deviceID),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findDeviceBetCountByBetType(deviceID, 'base'),
                    doubleDown: findDeviceBetCountByBetType(deviceID, 'doubleDown'),
                    insurance: findDeviceBetCountByBetType(deviceID, 'insurance'),
                    pair: findDeviceBetCountByBetType(deviceID, 'pair'),
                    split: findDeviceBetCountByBetType(deviceID, 'split'),
                    total: findDeviceBetCountTotal(deviceID),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findDeviceBetCountByBetType(deviceID, 'base'),
                    pair: findDeviceBetCountByBetType(deviceID, 'pair'),
                    anyPair: findDeviceBetCountByBetType(deviceID, 'anyPair'),
                    split: findDeviceBetCountByBetType(deviceID, 'split'),
                    doubleDown: findDeviceBetCountByBetType(deviceID, 'doubleDown'),
                    insurance: findDeviceBetCountByBetType(deviceID, 'insurance'),
                    bonus: findDeviceBetCountByBetType(deviceID, 'bonus'),
                    total: findDeviceBetCountTotal(deviceID),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getSeatBetTypeBetCount = (gameType, gameVariant) => {
        let obj
        if (gameType === 'baccarat') {
            if (gameVariant === 'standard') {
                obj = {
                    banker: {
                        seat1: getSeatBetCount('seat1', 'banker'),
                        seat2: getSeatBetCount('seat2', 'banker'),
                        seat3: getSeatBetCount('seat3', 'banker'),
                        seat4: getSeatBetCount('seat4', 'banker'),
                        seat5: getSeatBetCount('seat5', 'banker'),
                        total: getBetTypeTotalBetCount('banker'),
                    },
                    player: {
                        seat1: getSeatBetCount('seat1', 'player'),
                        seat2: getSeatBetCount('seat2', 'player'),
                        seat3: getSeatBetCount('seat3', 'player'),
                        seat4: getSeatBetCount('seat4', 'player'),
                        seat5: getSeatBetCount('seat5', 'player'),
                        total: getBetTypeTotalBetCount('player'),
                    },
                    tie: {
                        seat1: getSeatBetCount('seat1', 'tie'),
                        seat2: getSeatBetCount('seat2', 'tie'),
                        seat3: getSeatBetCount('seat3', 'tie'),
                        seat4: getSeatBetCount('seat4', 'tie'),
                        seat5: getSeatBetCount('seat5', 'tie'),
                        total: getBetTypeTotalBetCount('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatBetCount('seat1', 'bankerPair'),
                        seat2: getSeatBetCount('seat2', 'bankerPair'),
                        seat3: getSeatBetCount('seat3', 'bankerPair'),
                        seat4: getSeatBetCount('seat4', 'bankerPair'),
                        seat5: getSeatBetCount('seat5', 'bankerPair'),
                        total: getBetTypeTotalBetCount('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatBetCount('seat1', 'playerPair'),
                        seat2: getSeatBetCount('seat2', 'playerPair'),
                        seat3: getSeatBetCount('seat3', 'playerPair'),
                        seat4: getSeatBetCount('seat4', 'playerPair'),
                        seat5: getSeatBetCount('seat5', 'playerPair'),
                        total: getBetTypeTotalBetCount('playerPair'),
                    },
                    total: {
                        seat1: getSeatTotalBetCount('seat1', baccaratStandardBetTypes),
                        seat2: getSeatTotalBetCount('seat2', baccaratStandardBetTypes),
                        seat3: getSeatTotalBetCount('seat3', baccaratStandardBetTypes),
                        seat4: getSeatTotalBetCount('seat4', baccaratStandardBetTypes),
                        seat5: getSeatTotalBetCount('seat5', baccaratStandardBetTypes),
                        total: totalBetCount,
                    },
                }
            }
            if (gameVariant === 'lucky6') {
                obj = {
                    banker: {
                        seat1: getSeatBetCount('seat1', 'banker'),
                        seat2: getSeatBetCount('seat2', 'banker'),
                        seat3: getSeatBetCount('seat3', 'banker'),
                        seat4: getSeatBetCount('seat4', 'banker'),
                        seat5: getSeatBetCount('seat5', 'banker'),
                        total: getBetTypeTotalBetCount('banker'),
                    },
                    player: {
                        seat1: getSeatBetCount('seat1', 'player'),
                        seat2: getSeatBetCount('seat2', 'player'),
                        seat3: getSeatBetCount('seat3', 'player'),
                        seat4: getSeatBetCount('seat4', 'player'),
                        seat5: getSeatBetCount('seat5', 'player'),
                        total: getBetTypeTotalBetCount('player'),
                    },
                    tie: {
                        seat1: getSeatBetCount('seat1', 'tie'),
                        seat2: getSeatBetCount('seat2', 'tie'),
                        seat3: getSeatBetCount('seat3', 'tie'),
                        seat4: getSeatBetCount('seat4', 'tie'),
                        seat5: getSeatBetCount('seat5', 'tie'),
                        total: getBetTypeTotalBetCount('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatBetCount('seat1', 'bankerPair'),
                        seat2: getSeatBetCount('seat2', 'bankerPair'),
                        seat3: getSeatBetCount('seat3', 'bankerPair'),
                        seat4: getSeatBetCount('seat4', 'bankerPair'),
                        seat5: getSeatBetCount('seat5', 'bankerPair'),
                        total: getBetTypeTotalBetCount('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatBetCount('seat1', 'playerPair'),
                        seat2: getSeatBetCount('seat2', 'playerPair'),
                        seat3: getSeatBetCount('seat3', 'playerPair'),
                        seat4: getSeatBetCount('seat4', 'playerPair'),
                        seat5: getSeatBetCount('seat5', 'playerPair'),
                        total: getBetTypeTotalBetCount('playerPair'),
                    },
                    lucky6: {
                        seat1: getSeatBetCount('seat1', 'lucky6'),
                        seat2: getSeatBetCount('seat2', 'lucky6'),
                        seat3: getSeatBetCount('seat3', 'lucky6'),
                        seat4: getSeatBetCount('seat4', 'lucky6'),
                        seat5: getSeatBetCount('seat5', 'lucky6'),
                        total: getBetTypeTotalBetCount('lucky6'),
                    },
                    total: {
                        seat1: getSeatTotalBetCount('seat1', baccaratLucky6BetTypes),
                        seat2: getSeatTotalBetCount('seat2', baccaratLucky6BetTypes),
                        seat3: getSeatTotalBetCount('seat3', baccaratLucky6BetTypes),
                        seat4: getSeatTotalBetCount('seat4', baccaratLucky6BetTypes),
                        seat5: getSeatTotalBetCount('seat5', baccaratLucky6BetTypes),
                        total: totalBetCount,
                    },
                }
            }
            if (gameVariant === 'lucky6Slot') {
                obj = {
                    banker: {
                        seat1: getSeatBetCount('seat1', 'banker'),
                        seat2: getSeatBetCount('seat2', 'banker'),
                        seat3: getSeatBetCount('seat3', 'banker'),
                        seat4: getSeatBetCount('seat4', 'banker'),
                        seat5: getSeatBetCount('seat5', 'banker'),
                        total: getBetTypeTotalBetCount('banker'),
                    },
                    player: {
                        seat1: getSeatBetCount('seat1', 'player'),
                        seat2: getSeatBetCount('seat2', 'player'),
                        seat3: getSeatBetCount('seat3', 'player'),
                        seat4: getSeatBetCount('seat4', 'player'),
                        seat5: getSeatBetCount('seat5', 'player'),
                        total: getBetTypeTotalBetCount('player'),
                    },
                    tie: {
                        seat1: getSeatBetCount('seat1', 'tie'),
                        seat2: getSeatBetCount('seat2', 'tie'),
                        seat3: getSeatBetCount('seat3', 'tie'),
                        seat4: getSeatBetCount('seat4', 'tie'),
                        seat5: getSeatBetCount('seat5', 'tie'),
                        total: getBetTypeTotalBetCount('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatBetCount('seat1', 'bankerPair'),
                        seat2: getSeatBetCount('seat2', 'bankerPair'),
                        seat3: getSeatBetCount('seat3', 'bankerPair'),
                        seat4: getSeatBetCount('seat4', 'bankerPair'),
                        seat5: getSeatBetCount('seat5', 'bankerPair'),
                        total: getBetTypeTotalBetCount('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatBetCount('seat1', 'playerPair'),
                        seat2: getSeatBetCount('seat2', 'playerPair'),
                        seat3: getSeatBetCount('seat3', 'playerPair'),
                        seat4: getSeatBetCount('seat4', 'playerPair'),
                        seat5: getSeatBetCount('seat5', 'playerPair'),
                        total: getBetTypeTotalBetCount('playerPair'),
                    },
                    lucky6: {
                        seat1: getSeatBetCount('seat1', 'lucky6'),
                        seat2: getSeatBetCount('seat2', 'lucky6'),
                        seat3: getSeatBetCount('seat3', 'lucky6'),
                        seat4: getSeatBetCount('seat4', 'lucky6'),
                        seat5: getSeatBetCount('seat5', 'lucky6'),
                        total: getBetTypeTotalBetCount('lucky6'),
                    },
                    bonus: {
                        seat1: getSeatBetCount('seat1', 'bonus'),
                        seat2: getSeatBetCount('seat2', 'bonus'),
                        seat3: getSeatBetCount('seat3', 'bonus'),
                        seat4: getSeatBetCount('seat4', 'bonus'),
                        seat5: getSeatBetCount('seat5', 'bonus'),
                        total: getBetTypeTotalBetCount('bonus'),
                    },
                    total: {
                        seat1: getSeatTotalBetCount('seat1', baccaratLucky6BetTypes),
                        seat2: getSeatTotalBetCount('seat2', baccaratLucky6BetTypes),
                        seat3: getSeatTotalBetCount('seat3', baccaratLucky6BetTypes),
                        seat4: getSeatTotalBetCount('seat4', baccaratLucky6BetTypes),
                        seat5: getSeatTotalBetCount('seat5', baccaratLucky6BetTypes),
                        total: totalBetCount,
                    },
                }
            }
            if (gameVariant === 'mega6') {
                obj = {
                    banker: {
                        seat1: getSeatBetCount('seat1', 'banker'),
                        seat2: getSeatBetCount('seat2', 'banker'),
                        seat3: getSeatBetCount('seat3', 'banker'),
                        seat4: getSeatBetCount('seat4', 'banker'),
                        seat5: getSeatBetCount('seat5', 'banker'),
                        total: getBetTypeTotalBetCount('banker'),
                    },
                    player: {
                        seat1: getSeatBetCount('seat1', 'player'),
                        seat2: getSeatBetCount('seat2', 'player'),
                        seat3: getSeatBetCount('seat3', 'player'),
                        seat4: getSeatBetCount('seat4', 'player'),
                        seat5: getSeatBetCount('seat5', 'player'),
                        total: getBetTypeTotalBetCount('player'),
                    },
                    tie: {
                        seat1: getSeatBetCount('seat1', 'tie'),
                        seat2: getSeatBetCount('seat2', 'tie'),
                        seat3: getSeatBetCount('seat3', 'tie'),
                        seat4: getSeatBetCount('seat4', 'tie'),
                        seat5: getSeatBetCount('seat5', 'tie'),
                        total: getBetTypeTotalBetCount('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatBetCount('seat1', 'bankerPair'),
                        seat2: getSeatBetCount('seat2', 'bankerPair'),
                        seat3: getSeatBetCount('seat3', 'bankerPair'),
                        seat4: getSeatBetCount('seat4', 'bankerPair'),
                        seat5: getSeatBetCount('seat5', 'bankerPair'),
                        total: getBetTypeTotalBetCount('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatBetCount('seat1', 'playerPair'),
                        seat2: getSeatBetCount('seat2', 'playerPair'),
                        seat3: getSeatBetCount('seat3', 'playerPair'),
                        seat4: getSeatBetCount('seat4', 'playerPair'),
                        seat5: getSeatBetCount('seat5', 'playerPair'),
                        total: getBetTypeTotalBetCount('playerPair'),
                    },
                    mega6: {
                        seat1: getSeatBetCount('seat1', 'mega6'),
                        seat2: getSeatBetCount('seat2', 'mega6'),
                        seat3: getSeatBetCount('seat3', 'mega6'),
                        seat4: getSeatBetCount('seat4', 'mega6'),
                        seat5: getSeatBetCount('seat5', 'mega6'),
                        total: getBetTypeTotalBetCount('mega6'),
                    },
                    total: {
                        seat1: getSeatTotalBetCount('seat1', baccaratMega6BetTypes),
                        seat2: getSeatTotalBetCount('seat2', baccaratMega6BetTypes),
                        seat3: getSeatTotalBetCount('seat3', baccaratMega6BetTypes),
                        seat4: getSeatTotalBetCount('seat4', baccaratMega6BetTypes),
                        seat5: getSeatTotalBetCount('seat5', baccaratMega6BetTypes),
                        total: totalBetCount,
                    },
                }
            }
            if (gameVariant === 'tiger') {
                obj = {
                    banker: {
                        seat1: getSeatBetCount('seat1', 'banker'),
                        seat2: getSeatBetCount('seat2', 'banker'),
                        seat3: getSeatBetCount('seat3', 'banker'),
                        seat4: getSeatBetCount('seat4', 'banker'),
                        seat5: getSeatBetCount('seat5', 'banker'),
                        total: getBetTypeTotalBetCount('banker'),
                    },
                    player: {
                        seat1: getSeatBetCount('seat1', 'player'),
                        seat2: getSeatBetCount('seat2', 'player'),
                        seat3: getSeatBetCount('seat3', 'player'),
                        seat4: getSeatBetCount('seat4', 'player'),
                        seat5: getSeatBetCount('seat5', 'player'),
                        total: getBetTypeTotalBetCount('player'),
                    },
                    tie: {
                        seat1: getSeatBetCount('seat1', 'tie'),
                        seat2: getSeatBetCount('seat2', 'tie'),
                        seat3: getSeatBetCount('seat3', 'tie'),
                        seat4: getSeatBetCount('seat4', 'tie'),
                        seat5: getSeatBetCount('seat5', 'tie'),
                        total: getBetTypeTotalBetCount('tie'),
                    },
                    tigerTie: {
                        seat1: getSeatBetCount('seat1', 'tigerTie'),
                        seat2: getSeatBetCount('seat2', 'tigerTie'),
                        seat3: getSeatBetCount('seat3', 'tigerTie'),
                        seat4: getSeatBetCount('seat4', 'tigerTie'),
                        seat5: getSeatBetCount('seat5', 'tigerTie'),
                        total: getBetTypeTotalBetCount('tigerTie'),
                    },
                    tigerPair: {
                        seat1: getSeatBetCount('seat1', 'tigerPair'),
                        seat2: getSeatBetCount('seat2', 'tigerPair'),
                        seat3: getSeatBetCount('seat3', 'tigerPair'),
                        seat4: getSeatBetCount('seat4', 'tigerPair'),
                        seat5: getSeatBetCount('seat5', 'tigerPair'),
                        total: getBetTypeTotalBetCount('tigerPair'),
                    },
                    tigerBig: {
                        seat1: getSeatBetCount('seat1', 'tigerBig'),
                        seat2: getSeatBetCount('seat2', 'tigerBig'),
                        seat3: getSeatBetCount('seat3', 'tigerBig'),
                        seat4: getSeatBetCount('seat4', 'tigerBig'),
                        seat5: getSeatBetCount('seat5', 'tigerBig'),
                        total: getBetTypeTotalBetCount('tigerBig'),
                    },
                    tigerSmall: {
                        seat1: getSeatBetCount('seat1', 'tigerSmall'),
                        seat2: getSeatBetCount('seat2', 'tigerSmall'),
                        seat3: getSeatBetCount('seat3', 'tigerSmall'),
                        seat4: getSeatBetCount('seat4', 'tigerSmall'),
                        seat5: getSeatBetCount('seat5', 'tigerSmall'),
                        total: getBetTypeTotalBetCount('tigerSmall'),
                    },
                    tiger: {
                        seat1: getSeatBetCount('seat1', 'tiger'),
                        seat2: getSeatBetCount('seat2', 'tiger'),
                        seat3: getSeatBetCount('seat3', 'tiger'),
                        seat4: getSeatBetCount('seat4', 'tiger'),
                        seat5: getSeatBetCount('seat5', 'tiger'),
                        total: getBetTypeTotalBetCount('tiger'),
                    },
                    total: {
                        seat1: getSeatTotalBetCount('seat1', baccaratTigerBetTypes),
                        seat2: getSeatTotalBetCount('seat2', baccaratTigerBetTypes),
                        seat3: getSeatTotalBetCount('seat3', baccaratTigerBetTypes),
                        seat4: getSeatTotalBetCount('seat4', baccaratTigerBetTypes),
                        seat5: getSeatTotalBetCount('seat5', baccaratTigerBetTypes),
                        total: totalBetCount,
                    },
                }
            }
        }
        if (gameType === 'blackjack') {
            if (gameVariant === 'standard') {
                obj = {
                    base: {
                        seat1: getSeatBetCount('seat1', 'base'),
                        seat2: getSeatBetCount('seat2', 'base'),
                        seat3: getSeatBetCount('seat3', 'base'),
                        seat4: getSeatBetCount('seat4', 'base'),
                        seat5: getSeatBetCount('seat5', 'base'),
                        total: getBetTypeTotalBetCount('base'),
                    },
                    doubleDown: {
                        seat1: getSeatBetCount('seat1', 'doubleDown'),
                        seat2: getSeatBetCount('seat2', 'doubleDown'),
                        seat3: getSeatBetCount('seat3', 'doubleDown'),
                        seat4: getSeatBetCount('seat4', 'doubleDown'),
                        seat5: getSeatBetCount('seat5', 'doubleDown'),
                        total: getBetTypeTotalBetCount('doubleDown'),
                    },
                    insurance: {
                        seat1: getSeatBetCount('seat1', 'insurance'),
                        seat2: getSeatBetCount('seat2', 'insurance'),
                        seat3: getSeatBetCount('seat3', 'insurance'),
                        seat4: getSeatBetCount('seat4', 'insurance'),
                        seat5: getSeatBetCount('seat5', 'insurance'),
                        total: getBetTypeTotalBetCount('insurance'),
                    },
                    pair: {
                        seat1: getSeatBetCount('seat1', 'pair'),
                        seat2: getSeatBetCount('seat2', 'pair'),
                        seat3: getSeatBetCount('seat3', 'pair'),
                        seat4: getSeatBetCount('seat4', 'pair'),
                        seat5: getSeatBetCount('seat5', 'pair'),
                        total: getBetTypeTotalBetCount('pair'),
                    },
                    split: {
                        seat1: getSeatBetCount('seat1', 'split'),
                        seat2: getSeatBetCount('seat2', 'split'),
                        seat3: getSeatBetCount('seat3', 'split'),
                        seat4: getSeatBetCount('seat4', 'split'),
                        seat5: getSeatBetCount('seat5', 'split'),
                        total: getBetTypeTotalBetCount('split'),
                    },
                    total: {
                        seat1: getSeatTotalBetCount('seat1', blackjackStandardBetTypes),
                        seat2: getSeatTotalBetCount('seat2', blackjackStandardBetTypes),
                        seat3: getSeatTotalBetCount('seat3', blackjackStandardBetTypes),
                        seat4: getSeatTotalBetCount('seat4', blackjackStandardBetTypes),
                        seat5: getSeatTotalBetCount('seat5', blackjackStandardBetTypes),
                        total: totalBetCount,
                    },
                }
            }
            if (gameVariant === 'anyPairSlot') {
                obj = {
                    base: {
                        seat1: getSeatBetCount('seat1', 'base'),
                        seat2: getSeatBetCount('seat2', 'base'),
                        seat3: getSeatBetCount('seat3', 'base'),
                        seat4: getSeatBetCount('seat4', 'base'),
                        seat5: getSeatBetCount('seat5', 'base'),
                        total: getBetTypeTotalBetCount('base'),
                    },
                    pair: {
                        seat1: getSeatBetCount('seat1', 'pair'),
                        seat2: getSeatBetCount('seat2', 'pair'),
                        seat3: getSeatBetCount('seat3', 'pair'),
                        seat4: getSeatBetCount('seat4', 'pair'),
                        seat5: getSeatBetCount('seat5', 'pair'),
                        total: getBetTypeTotalBetCount('pair'),
                    },
                    anyPair: {
                        seat1: getSeatBetCount('seat1', 'anyPair'),
                        seat2: getSeatBetCount('seat2', 'anyPair'),
                        seat3: getSeatBetCount('seat3', 'anyPair'),
                        seat4: getSeatBetCount('seat4', 'anyPair'),
                        seat5: getSeatBetCount('seat5', 'anyPair'),
                        total: getBetTypeTotalBetCount('anyPair'),
                    },
                    split: {
                        seat1: getSeatBetCount('seat1', 'split'),
                        seat2: getSeatBetCount('seat2', 'split'),
                        seat3: getSeatBetCount('seat3', 'split'),
                        seat4: getSeatBetCount('seat4', 'split'),
                        seat5: getSeatBetCount('seat5', 'split'),
                        total: getBetTypeTotalBetCount('split'),
                    },
                    doubleDown: {
                        seat1: getSeatBetCount('seat1', 'doubleDown'),
                        seat2: getSeatBetCount('seat2', 'doubleDown'),
                        seat3: getSeatBetCount('seat3', 'doubleDown'),
                        seat4: getSeatBetCount('seat4', 'doubleDown'),
                        seat5: getSeatBetCount('seat5', 'doubleDown'),
                        total: getBetTypeTotalBetCount('doubleDown'),
                    },
                    insurance: {
                        seat1: getSeatBetCount('seat1', 'insurance'),
                        seat2: getSeatBetCount('seat2', 'insurance'),
                        seat3: getSeatBetCount('seat3', 'insurance'),
                        seat4: getSeatBetCount('seat4', 'insurance'),
                        seat5: getSeatBetCount('seat5', 'insurance'),
                        total: getBetTypeTotalBetCount('insurance'),
                    },
                    bonus: {
                        seat1: getSeatBetCount('seat1', 'bonus'),
                        seat2: getSeatBetCount('seat2', 'bonus'),
                        seat3: getSeatBetCount('seat3', 'bonus'),
                        seat4: getSeatBetCount('seat4', 'bonus'),
                        seat5: getSeatBetCount('seat5', 'bonus'),
                        total: getBetTypeTotalBetCount('bonus'),
                    },
                    total: {
                        seat1: getSeatTotalBetCount('seat1', blackjackAnyPairSlotBetTypes),
                        seat2: getSeatTotalBetCount('seat2', blackjackAnyPairSlotBetTypes),
                        seat3: getSeatTotalBetCount('seat3', blackjackAnyPairSlotBetTypes),
                        seat4: getSeatTotalBetCount('seat4', blackjackAnyPairSlotBetTypes),
                        seat5: getSeatTotalBetCount('seat5', blackjackAnyPairSlotBetTypes),
                        total: totalBetCount,
                    },
                }
            }
        }

        return obj
    }

    const getTotalBetCount = () => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: getBetTypeTotalBetCount('banker'),
                    player: getBetTypeTotalBetCount('player'),
                    tie: getBetTypeTotalBetCount('tie'),
                    bankerPair: getBetTypeTotalBetCount('bankerPair'),
                    playerPair: getBetTypeTotalBetCount('playerPair'),
                    total: totalBetCount,
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: getBetTypeTotalBetCount('banker'),
                    player: getBetTypeTotalBetCount('player'),
                    tie: getBetTypeTotalBetCount('tie'),
                    bankerPair: getBetTypeTotalBetCount('bankerPair'),
                    playerPair: getBetTypeTotalBetCount('playerPair'),
                    lucky6: getBetTypeTotalBetCount('lucky6'),
                    total: totalBetCount,
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: getBetTypeTotalBetCount('banker'),
                    player: getBetTypeTotalBetCount('player'),
                    tie: getBetTypeTotalBetCount('tie'),
                    bankerPair: getBetTypeTotalBetCount('bankerPair'),
                    playerPair: getBetTypeTotalBetCount('playerPair'),
                    lucky6: getBetTypeTotalBetCount('lucky6'),
                    bonus: getBetTypeTotalBetCount('bonus'),
                    total: totalBetCount,
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: getBetTypeTotalBetCount('banker'),
                    player: getBetTypeTotalBetCount('player'),
                    tie: getBetTypeTotalBetCount('tie'),
                    bankerPair: getBetTypeTotalBetCount('bankerPair'),
                    playerPair: getBetTypeTotalBetCount('playerPair'),
                    mega6: getBetTypeTotalBetCount('mega6'),
                    total: totalBetCount,
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: getBetTypeTotalBetCount('banker'),
                    player: getBetTypeTotalBetCount('player'),
                    tie: getBetTypeTotalBetCount('tie'),
                    tigerTie: getBetTypeTotalBetCount('tigerTie'),
                    tigerPair: getBetTypeTotalBetCount('tigerPair'),
                    tigerBig: getBetTypeTotalBetCount('tigerBig'),
                    tigerSmall: getBetTypeTotalBetCount('tigerSmall'),
                    tiger: getBetTypeTotalBetCount('tiger'),
                    total: totalBetCount,
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: getBetTypeTotalBetCount('base'),
                    doubleDown: getBetTypeTotalBetCount('doubleDown'),
                    insurance: getBetTypeTotalBetCount('insurance'),
                    pair: getBetTypeTotalBetCount('pair'),
                    split: getBetTypeTotalBetCount('split'),
                    total: totalBetCount,
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: getBetTypeTotalBetCount('base'),
                    pair: getBetTypeTotalBetCount('pair'),
                    anyPair: getBetTypeTotalBetCount('anyPair'),
                    split: getBetTypeTotalBetCount('split'),
                    doubleDown: getBetTypeTotalBetCount('doubleDown'),
                    insurance: getBetTypeTotalBetCount('insurance'),
                    bonus: getBetTypeTotalBetCount('bonus'),
                    total: totalBetCount,
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    // Payout
    const getZonePayout = (zone) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findZonePayoutByBetType(zone.index, 'banker'),
                    player: findZonePayoutByBetType(zone.index, 'player'),
                    tie: findZonePayoutByBetType(zone.index, 'tie'),
                    bankerPair: findZonePayoutByBetType(zone.index, 'bankerPair'),
                    playerPair: findZonePayoutByBetType(zone.index, 'playerPair'),
                    total: findZonePayoutTotal(zone.index),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findZonePayoutByBetType(zone.index, 'banker'),
                    player: findZonePayoutByBetType(zone.index, 'player'),
                    tie: findZonePayoutByBetType(zone.index, 'tie'),
                    bankerPair: findZonePayoutByBetType(zone.index, 'bankerPair'),
                    playerPair: findZonePayoutByBetType(zone.index, 'playerPair'),
                    lucky6: findZonePayoutByBetType(zone.index, 'lucky6'),
                    total: findZonePayoutTotal(zone.index),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findZonePayoutByBetType(zone.index, 'banker'),
                    player: findZonePayoutByBetType(zone.index, 'player'),
                    tie: findZonePayoutByBetType(zone.index, 'tie'),
                    bankerPair: findZonePayoutByBetType(zone.index, 'bankerPair'),
                    playerPair: findZonePayoutByBetType(zone.index, 'playerPair'),
                    lucky6: findZonePayoutByBetType(zone.index, 'lucky6'),
                    bonus: findZonePayoutByBetType(zone.index, 'bonus'),
                    total: findZonePayoutTotal(zone.index),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findZonePayoutByBetType(zone.index, 'banker'),
                    player: findZonePayoutByBetType(zone.index, 'player'),
                    tie: findZonePayoutByBetType(zone.index, 'tie'),
                    bankerPair: findZonePayoutByBetType(zone.index, 'bankerPair'),
                    playerPair: findZonePayoutByBetType(zone.index, 'playerPair'),
                    mega6: findZonePayoutByBetType(zone.index, 'mega6'),
                    total: findZonePayoutTotal(zone.index),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findZonePayoutByBetType(zone.index, 'banker'),
                    player: findZonePayoutByBetType(zone.index, 'player'),
                    tie: findZonePayoutByBetType(zone.index, 'tie'),
                    tigerTie: findZonePayoutByBetType(zone.index, 'tigerTie'),
                    tigerPair: findZonePayoutByBetType(zone.index, 'tigerPair'),
                    tigerBig: findZonePayoutByBetType(zone.index, 'tigerBig'),
                    tigerSmall: findZonePayoutByBetType(zone.index, 'tigerSmall'),
                    tiger: findZonePayoutByBetType(zone.index, 'tiger'),
                    total: findZonePayoutTotal(zone.index),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findZonePayoutByBetType(zone.index, 'base'),
                    doubleDown: findZonePayoutByBetType(zone.index, 'doubleDown'),
                    insurance: findZonePayoutByBetType(zone.index, 'insurance'),
                    pair: findZonePayoutByBetType(zone.index, 'pair'),
                    split: findZonePayoutByBetType(zone.index, 'split'),
                    total: findZonePayoutTotal(zone.index),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findZonePayoutByBetType(zone.index, 'base'),
                    pair: findZonePayoutByBetType(zone.index, 'pair'),
                    anyPair: findZonePayoutByBetType(zone.index, 'anyPair'),
                    split: findZonePayoutByBetType(zone.index, 'split'),
                    doubleDown: findZonePayoutByBetType(zone.index, 'doubleDown'),
                    insurance: findZonePayoutByBetType(zone.index, 'insurance'),
                    bonus: findZonePayoutByBetType(zone.index, 'bonus'),
                    total: findZonePayoutTotal(zone.index),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getPitPayout = (pit) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findPitPayoutByBetType(pit.index, 'banker'),
                    player: findPitPayoutByBetType(pit.index, 'player'),
                    tie: findPitPayoutByBetType(pit.index, 'tie'),
                    bankerPair: findPitPayoutByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitPayoutByBetType(pit.index, 'playerPair'),
                    total: findPitPayoutTotal(pit.index),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findPitPayoutByBetType(pit.index, 'banker'),
                    player: findPitPayoutByBetType(pit.index, 'player'),
                    tie: findPitPayoutByBetType(pit.index, 'tie'),
                    bankerPair: findPitPayoutByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitPayoutByBetType(pit.index, 'playerPair'),
                    lucky6: findPitPayoutByBetType(pit.index, 'lucky6'),
                    total: findPitPayoutTotal(pit.index),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findPitPayoutByBetType(pit.index, 'banker'),
                    player: findPitPayoutByBetType(pit.index, 'player'),
                    tie: findPitPayoutByBetType(pit.index, 'tie'),
                    bankerPair: findPitPayoutByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitPayoutByBetType(pit.index, 'playerPair'),
                    lucky6: findPitPayoutByBetType(pit.index, 'lucky6'),
                    bonus: findPitPayoutByBetType(pit.index, 'bonus'),
                    total: findPitPayoutTotal(pit.index),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findPitPayoutByBetType(pit.index, 'banker'),
                    player: findPitPayoutByBetType(pit.index, 'player'),
                    tie: findPitPayoutByBetType(pit.index, 'tie'),
                    bankerPair: findPitPayoutByBetType(pit.index, 'bankerPair'),
                    playerPair: findPitPayoutByBetType(pit.index, 'playerPair'),
                    mega6: findPitPayoutByBetType(pit.index, 'mega6'),
                    total: findPitPayoutTotal(pit.index),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findPitPayoutByBetType(pit.index, 'banker'),
                    player: findPitPayoutByBetType(pit.index, 'player'),
                    tie: findPitPayoutByBetType(pit.index, 'tie'),
                    tigerTie: findPitPayoutByBetType(pit.index, 'tigerTie'),
                    tigerPair: findPitPayoutByBetType(pit.index, 'tigerPair'),
                    tigerBig: findPitPayoutByBetType(pit.index, 'tigerBig'),
                    tigerSmall: findPitPayoutByBetType(pit.index, 'tigerSmall'),
                    tiger: findPitPayoutByBetType(pit.index, 'tiger'),
                    total: findPitPayoutTotal(pit.index),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findPitPayoutByBetType(pit.index, 'base'),
                    doubleDown: findPitPayoutByBetType(pit.index, 'doubleDown'),
                    insurance: findPitPayoutByBetType(pit.index, 'insurance'),
                    pair: findPitPayoutByBetType(pit.index, 'pair'),
                    split: findPitPayoutByBetType(pit.index, 'split'),
                    total: findPitPayoutTotal(pit.index),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findPitPayoutByBetType(pit.index, 'base'),
                    pair: findPitPayoutByBetType(pit.index, 'pair'),
                    anyPair: findPitPayoutByBetType(pit.index, 'anyPair'),
                    split: findPitPayoutByBetType(pit.index, 'split'),
                    doubleDown: findPitPayoutByBetType(pit.index, 'doubleDown'),
                    insurance: findPitPayoutByBetType(pit.index, 'insurance'),
                    bonus: findPitPayoutByBetType(pit.index, 'bonus'),
                    total: findPitPayoutTotal(pit.index),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getDevicePayout = (deviceID) => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: findDevicePayoutByBetType(deviceID, 'banker'),
                    player: findDevicePayoutByBetType(deviceID, 'player'),
                    tie: findDevicePayoutByBetType(deviceID, 'tie'),
                    bankerPair: findDevicePayoutByBetType(deviceID, 'bankerPair'),
                    playerPair: findDevicePayoutByBetType(deviceID, 'playerPair'),
                    total: findDevicePayoutTotal(deviceID),
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: findDevicePayoutByBetType(deviceID, 'banker'),
                    player: findDevicePayoutByBetType(deviceID, 'player'),
                    tie: findDevicePayoutByBetType(deviceID, 'tie'),
                    bankerPair: findDevicePayoutByBetType(deviceID, 'bankerPair'),
                    playerPair: findDevicePayoutByBetType(deviceID, 'playerPair'),
                    lucky6: findDevicePayoutByBetType(deviceID, 'lucky6'),
                    total: findDevicePayoutTotal(deviceID),
                }
                break
            case 'baccaratLucky6Slot':
                obj = {
                    banker: findDevicePayoutByBetType(deviceID, 'banker'),
                    player: findDevicePayoutByBetType(deviceID, 'player'),
                    tie: findDevicePayoutByBetType(deviceID, 'tie'),
                    bankerPair: findDevicePayoutByBetType(deviceID, 'bankerPair'),
                    playerPair: findDevicePayoutByBetType(deviceID, 'playerPair'),
                    lucky6: findDevicePayoutByBetType(deviceID, 'lucky6'),
                    bonus: findDevicePayoutByBetType(deviceID, 'bonus'),
                    total: findDevicePayoutTotal(deviceID),
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: findDevicePayoutByBetType(deviceID, 'banker'),
                    player: findDevicePayoutByBetType(deviceID, 'player'),
                    tie: findDevicePayoutByBetType(deviceID, 'tie'),
                    bankerPair: findDevicePayoutByBetType(deviceID, 'bankerPair'),
                    playerPair: findDevicePayoutByBetType(deviceID, 'playerPair'),
                    mega6: findDevicePayoutByBetType(deviceID, 'mega6'),
                    total: findDevicePayoutTotal(deviceID),
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: findDevicePayoutByBetType(deviceID, 'banker'),
                    player: findDevicePayoutByBetType(deviceID, 'player'),
                    tie: findDevicePayoutByBetType(deviceID, 'tie'),
                    tigerTie: findDevicePayoutByBetType(deviceID, 'tigerTie'),
                    tigerPair: findDevicePayoutByBetType(deviceID, 'tigerPair'),
                    tigerBig: findDevicePayoutByBetType(deviceID, 'tigerBig'),
                    tigerSmall: findDevicePayoutByBetType(deviceID, 'tigerSmall'),
                    tiger: findDevicePayoutByBetType(deviceID, 'tiger'),
                    total: findDevicePayoutTotal(deviceID),
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: findDevicePayoutByBetType(deviceID, 'base'),
                    doubleDown: findDevicePayoutByBetType(deviceID, 'doubleDown'),
                    insurance: findDevicePayoutByBetType(deviceID, 'insurance'),
                    pair: findDevicePayoutByBetType(deviceID, 'pair'),
                    split: findDevicePayoutByBetType(deviceID, 'split'),
                    total: findDevicePayoutTotal(deviceID),
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: findDevicePayoutByBetType(deviceID, 'base'),
                    pair: findDevicePayoutByBetType(deviceID, 'pair'),
                    anyPair: findDevicePayoutByBetType(deviceID, 'anyPair'),
                    split: findDevicePayoutByBetType(deviceID, 'split'),
                    doubleDown: findDevicePayoutByBetType(deviceID, 'doubleDown'),
                    insurance: findDevicePayoutByBetType(deviceID, 'insurance'),
                    bonus: findDevicePayoutByBetType(deviceID, 'bonus'),
                    total: findDevicePayoutTotal(deviceID),
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const getSeatBetTypePayout = (gameType, gameVariant) => {
        let obj
        if (gameType === 'baccarat') {
            if (gameVariant === 'standard') {
                obj = {
                    banker: {
                        seat1: getSeatPayout('seat1', 'banker'),
                        seat2: getSeatPayout('seat2', 'banker'),
                        seat3: getSeatPayout('seat3', 'banker'),
                        seat4: getSeatPayout('seat4', 'banker'),
                        seat5: getSeatPayout('seat5', 'banker'),
                        total: getBetTypeTotalPayout('banker'),
                    },
                    player: {
                        seat1: getSeatPayout('seat1', 'player'),
                        seat2: getSeatPayout('seat2', 'player'),
                        seat3: getSeatPayout('seat3', 'player'),
                        seat4: getSeatPayout('seat4', 'player'),
                        seat5: getSeatPayout('seat5', 'player'),
                        total: getBetTypeTotalPayout('player'),
                    },
                    tie: {
                        seat1: getSeatPayout('seat1', 'tie'),
                        seat2: getSeatPayout('seat2', 'tie'),
                        seat3: getSeatPayout('seat3', 'tie'),
                        seat4: getSeatPayout('seat4', 'tie'),
                        seat5: getSeatPayout('seat5', 'tie'),
                        total: getBetTypeTotalPayout('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatPayout('seat1', 'bankerPair'),
                        seat2: getSeatPayout('seat2', 'bankerPair'),
                        seat3: getSeatPayout('seat3', 'bankerPair'),
                        seat4: getSeatPayout('seat4', 'bankerPair'),
                        seat5: getSeatPayout('seat5', 'bankerPair'),
                        total: getBetTypeTotalPayout('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatPayout('seat1', 'playerPair'),
                        seat2: getSeatPayout('seat2', 'playerPair'),
                        seat3: getSeatPayout('seat3', 'playerPair'),
                        seat4: getSeatPayout('seat4', 'playerPair'),
                        seat5: getSeatPayout('seat5', 'playerPair'),
                        total: getBetTypeTotalPayout('playerPair'),
                    },
                    total: {
                        seat1: getSeatTotalPayout('seat1', baccaratStandardBetTypes),
                        seat2: getSeatTotalPayout('seat2', baccaratStandardBetTypes),
                        seat3: getSeatTotalPayout('seat3', baccaratStandardBetTypes),
                        seat4: getSeatTotalPayout('seat4', baccaratStandardBetTypes),
                        seat5: getSeatTotalPayout('seat5', baccaratStandardBetTypes),
                        total: totalPayout,
                    },
                }
            }
            if (gameVariant === 'lucky6') {
                obj = {
                    banker: {
                        seat1: getSeatPayout('seat1', 'banker'),
                        seat2: getSeatPayout('seat2', 'banker'),
                        seat3: getSeatPayout('seat3', 'banker'),
                        seat4: getSeatPayout('seat4', 'banker'),
                        seat5: getSeatPayout('seat5', 'banker'),
                        total: getBetTypeTotalPayout('banker'),
                    },
                    player: {
                        seat1: getSeatPayout('seat1', 'player'),
                        seat2: getSeatPayout('seat2', 'player'),
                        seat3: getSeatPayout('seat3', 'player'),
                        seat4: getSeatPayout('seat4', 'player'),
                        seat5: getSeatPayout('seat5', 'player'),
                        total: getBetTypeTotalPayout('player'),
                    },
                    tie: {
                        seat1: getSeatPayout('seat1', 'tie'),
                        seat2: getSeatPayout('seat2', 'tie'),
                        seat3: getSeatPayout('seat3', 'tie'),
                        seat4: getSeatPayout('seat4', 'tie'),
                        seat5: getSeatPayout('seat5', 'tie'),
                        total: getBetTypeTotalPayout('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatPayout('seat1', 'bankerPair'),
                        seat2: getSeatPayout('seat2', 'bankerPair'),
                        seat3: getSeatPayout('seat3', 'bankerPair'),
                        seat4: getSeatPayout('seat4', 'bankerPair'),
                        seat5: getSeatPayout('seat5', 'bankerPair'),
                        total: getBetTypeTotalPayout('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatPayout('seat1', 'playerPair'),
                        seat2: getSeatPayout('seat2', 'playerPair'),
                        seat3: getSeatPayout('seat3', 'playerPair'),
                        seat4: getSeatPayout('seat4', 'playerPair'),
                        seat5: getSeatPayout('seat5', 'playerPair'),
                        total: getBetTypeTotalPayout('playerPair'),
                    },
                    lucky6: {
                        seat1: getSeatPayout('seat1', 'lucky6'),
                        seat2: getSeatPayout('seat2', 'lucky6'),
                        seat3: getSeatPayout('seat3', 'lucky6'),
                        seat4: getSeatPayout('seat4', 'lucky6'),
                        seat5: getSeatPayout('seat5', 'lucky6'),
                        total: getBetTypeTotalPayout('lucky6'),
                    },
                    total: {
                        seat1: getSeatTotalPayout('seat1', baccaratLucky6BetTypes),
                        seat2: getSeatTotalPayout('seat2', baccaratLucky6BetTypes),
                        seat3: getSeatTotalPayout('seat3', baccaratLucky6BetTypes),
                        seat4: getSeatTotalPayout('seat4', baccaratLucky6BetTypes),
                        seat5: getSeatTotalPayout('seat5', baccaratLucky6BetTypes),
                        total: totalPayout,
                    },
                }
            }
            if (gameVariant === 'lucky6Slot') {
                obj = {
                    banker: {
                        seat1: getSeatPayout('seat1', 'banker'),
                        seat2: getSeatPayout('seat2', 'banker'),
                        seat3: getSeatPayout('seat3', 'banker'),
                        seat4: getSeatPayout('seat4', 'banker'),
                        seat5: getSeatPayout('seat5', 'banker'),
                        total: getBetTypeTotalPayout('banker'),
                    },
                    player: {
                        seat1: getSeatPayout('seat1', 'player'),
                        seat2: getSeatPayout('seat2', 'player'),
                        seat3: getSeatPayout('seat3', 'player'),
                        seat4: getSeatPayout('seat4', 'player'),
                        seat5: getSeatPayout('seat5', 'player'),
                        total: getBetTypeTotalPayout('player'),
                    },
                    tie: {
                        seat1: getSeatPayout('seat1', 'tie'),
                        seat2: getSeatPayout('seat2', 'tie'),
                        seat3: getSeatPayout('seat3', 'tie'),
                        seat4: getSeatPayout('seat4', 'tie'),
                        seat5: getSeatPayout('seat5', 'tie'),
                        total: getBetTypeTotalPayout('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatPayout('seat1', 'bankerPair'),
                        seat2: getSeatPayout('seat2', 'bankerPair'),
                        seat3: getSeatPayout('seat3', 'bankerPair'),
                        seat4: getSeatPayout('seat4', 'bankerPair'),
                        seat5: getSeatPayout('seat5', 'bankerPair'),
                        total: getBetTypeTotalPayout('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatPayout('seat1', 'playerPair'),
                        seat2: getSeatPayout('seat2', 'playerPair'),
                        seat3: getSeatPayout('seat3', 'playerPair'),
                        seat4: getSeatPayout('seat4', 'playerPair'),
                        seat5: getSeatPayout('seat5', 'playerPair'),
                        total: getBetTypeTotalPayout('playerPair'),
                    },
                    lucky6: {
                        seat1: getSeatPayout('seat1', 'lucky6'),
                        seat2: getSeatPayout('seat2', 'lucky6'),
                        seat3: getSeatPayout('seat3', 'lucky6'),
                        seat4: getSeatPayout('seat4', 'lucky6'),
                        seat5: getSeatPayout('seat5', 'lucky6'),
                        total: getBetTypeTotalPayout('lucky6'),
                    },
                    bonus: {
                        seat1: getSeatPayout('seat1', 'bonus'),
                        seat2: getSeatPayout('seat2', 'bonus'),
                        seat3: getSeatPayout('seat3', 'bonus'),
                        seat4: getSeatPayout('seat4', 'bonus'),
                        seat5: getSeatPayout('seat5', 'bonus'),
                        total: getBetTypeTotalPayout('bonus'),
                    },
                    total: {
                        seat1: getSeatTotalPayout('seat1', baccaratLucky6BetTypes),
                        seat2: getSeatTotalPayout('seat2', baccaratLucky6BetTypes),
                        seat3: getSeatTotalPayout('seat3', baccaratLucky6BetTypes),
                        seat4: getSeatTotalPayout('seat4', baccaratLucky6BetTypes),
                        seat5: getSeatTotalPayout('seat5', baccaratLucky6BetTypes),
                        total: totalPayout,
                    },
                }
            }
            if (gameVariant === 'mega6') {
                obj = {
                    banker: {
                        seat1: getSeatPayout('seat1', 'banker'),
                        seat2: getSeatPayout('seat2', 'banker'),
                        seat3: getSeatPayout('seat3', 'banker'),
                        seat4: getSeatPayout('seat4', 'banker'),
                        seat5: getSeatPayout('seat5', 'banker'),
                        total: getBetTypeTotalPayout('banker'),
                    },
                    player: {
                        seat1: getSeatPayout('seat1', 'player'),
                        seat2: getSeatPayout('seat2', 'player'),
                        seat3: getSeatPayout('seat3', 'player'),
                        seat4: getSeatPayout('seat4', 'player'),
                        seat5: getSeatPayout('seat5', 'player'),
                        total: getBetTypeTotalPayout('player'),
                    },
                    tie: {
                        seat1: getSeatPayout('seat1', 'tie'),
                        seat2: getSeatPayout('seat2', 'tie'),
                        seat3: getSeatPayout('seat3', 'tie'),
                        seat4: getSeatPayout('seat4', 'tie'),
                        seat5: getSeatPayout('seat5', 'tie'),
                        total: getBetTypeTotalPayout('tie'),
                    },
                    bankerPair: {
                        seat1: getSeatPayout('seat1', 'bankerPair'),
                        seat2: getSeatPayout('seat2', 'bankerPair'),
                        seat3: getSeatPayout('seat3', 'bankerPair'),
                        seat4: getSeatPayout('seat4', 'bankerPair'),
                        seat5: getSeatPayout('seat5', 'bankerPair'),
                        total: getBetTypeTotalPayout('bankerPair'),
                    },
                    playerPair: {
                        seat1: getSeatPayout('seat1', 'playerPair'),
                        seat2: getSeatPayout('seat2', 'playerPair'),
                        seat3: getSeatPayout('seat3', 'playerPair'),
                        seat4: getSeatPayout('seat4', 'playerPair'),
                        seat5: getSeatPayout('seat5', 'playerPair'),
                        total: getBetTypeTotalPayout('playerPair'),
                    },
                    mega6: {
                        seat1: getSeatPayout('seat1', 'mega6'),
                        seat2: getSeatPayout('seat2', 'mega6'),
                        seat3: getSeatPayout('seat3', 'mega6'),
                        seat4: getSeatPayout('seat4', 'mega6'),
                        seat5: getSeatPayout('seat5', 'mega6'),
                        total: getBetTypeTotalPayout('mega6'),
                    },
                    total: {
                        seat1: getSeatTotalPayout('seat1', baccaratMega6BetTypes),
                        seat2: getSeatTotalPayout('seat2', baccaratMega6BetTypes),
                        seat3: getSeatTotalPayout('seat3', baccaratMega6BetTypes),
                        seat4: getSeatTotalPayout('seat4', baccaratMega6BetTypes),
                        seat5: getSeatTotalPayout('seat5', baccaratMega6BetTypes),
                        total: totalPayout,
                    },
                }
            }
            if (gameVariant === 'tiger') {
                obj = {
                    banker: {
                        seat1: getSeatPayout('seat1', 'banker'),
                        seat2: getSeatPayout('seat2', 'banker'),
                        seat3: getSeatPayout('seat3', 'banker'),
                        seat4: getSeatPayout('seat4', 'banker'),
                        seat5: getSeatPayout('seat5', 'banker'),
                        total: getBetTypeTotalPayout('banker'),
                    },
                    player: {
                        seat1: getSeatPayout('seat1', 'player'),
                        seat2: getSeatPayout('seat2', 'player'),
                        seat3: getSeatPayout('seat3', 'player'),
                        seat4: getSeatPayout('seat4', 'player'),
                        seat5: getSeatPayout('seat5', 'player'),
                        total: getBetTypeTotalPayout('player'),
                    },
                    tie: {
                        seat1: getSeatPayout('seat1', 'tie'),
                        seat2: getSeatPayout('seat2', 'tie'),
                        seat3: getSeatPayout('seat3', 'tie'),
                        seat4: getSeatPayout('seat4', 'tie'),
                        seat5: getSeatPayout('seat5', 'tie'),
                        total: getBetTypeTotalPayout('tie'),
                    },
                    tigerTie: {
                        seat1: getSeatPayout('seat1', 'tigerTie'),
                        seat2: getSeatPayout('seat2', 'tigerTie'),
                        seat3: getSeatPayout('seat3', 'tigerTie'),
                        seat4: getSeatPayout('seat4', 'tigerTie'),
                        seat5: getSeatPayout('seat5', 'tigerTie'),
                        total: getBetTypeTotalPayout('tigerTie'),
                    },
                    tigerPair: {
                        seat1: getSeatPayout('seat1', 'tigerPair'),
                        seat2: getSeatPayout('seat2', 'tigerPair'),
                        seat3: getSeatPayout('seat3', 'tigerPair'),
                        seat4: getSeatPayout('seat4', 'tigerPair'),
                        seat5: getSeatPayout('seat5', 'tigerPair'),
                        total: getBetTypeTotalPayout('tigerPair'),
                    },
                    tigerBig: {
                        seat1: getSeatPayout('seat1', 'tigerBig'),
                        seat2: getSeatPayout('seat2', 'tigerBig'),
                        seat3: getSeatPayout('seat3', 'tigerBig'),
                        seat4: getSeatPayout('seat4', 'tigerBig'),
                        seat5: getSeatPayout('seat5', 'tigerBig'),
                        total: getBetTypeTotalPayout('tigerBig'),
                    },
                    tigerSmall: {
                        seat1: getSeatPayout('seat1', 'tigerSmall'),
                        seat2: getSeatPayout('seat2', 'tigerSmall'),
                        seat3: getSeatPayout('seat3', 'tigerSmall'),
                        seat4: getSeatPayout('seat4', 'tigerSmall'),
                        seat5: getSeatPayout('seat5', 'tigerSmall'),
                        total: getBetTypeTotalPayout('tigerSmall'),
                    },
                    tiger: {
                        seat1: getSeatPayout('seat1', 'tiger'),
                        seat2: getSeatPayout('seat2', 'tiger'),
                        seat3: getSeatPayout('seat3', 'tiger'),
                        seat4: getSeatPayout('seat4', 'tiger'),
                        seat5: getSeatPayout('seat5', 'tiger'),
                        total: getBetTypeTotalPayout('tiger'),
                    },
                    total: {
                        seat1: getSeatTotalPayout('seat1', baccaratTigerBetTypes),
                        seat2: getSeatTotalPayout('seat2', baccaratTigerBetTypes),
                        seat3: getSeatTotalPayout('seat3', baccaratTigerBetTypes),
                        seat4: getSeatTotalPayout('seat4', baccaratTigerBetTypes),
                        seat5: getSeatTotalPayout('seat5', baccaratTigerBetTypes),
                        total: totalPayout,
                    },
                }
            }
        }
        if (gameType === 'blackjack') {
            if (gameVariant === 'standard') {
                obj = {
                    base: {
                        seat1: getSeatPayout('seat1', 'base'),
                        seat2: getSeatPayout('seat2', 'base'),
                        seat3: getSeatPayout('seat3', 'base'),
                        seat4: getSeatPayout('seat4', 'base'),
                        seat5: getSeatPayout('seat5', 'base'),
                        total: getBetTypeTotalPayout('base'),
                    },
                    doubleDown: {
                        seat1: getSeatPayout('seat1', 'doubleDown'),
                        seat2: getSeatPayout('seat2', 'doubleDown'),
                        seat3: getSeatPayout('seat3', 'doubleDown'),
                        seat4: getSeatPayout('seat4', 'doubleDown'),
                        seat5: getSeatPayout('seat5', 'doubleDown'),
                        total: getBetTypeTotalPayout('doubleDown'),
                    },
                    insurance: {
                        seat1: getSeatPayout('seat1', 'insurance'),
                        seat2: getSeatPayout('seat2', 'insurance'),
                        seat3: getSeatPayout('seat3', 'insurance'),
                        seat4: getSeatPayout('seat4', 'insurance'),
                        seat5: getSeatPayout('seat5', 'insurance'),
                        total: getBetTypeTotalPayout('insurance'),
                    },
                    pair: {
                        seat1: getSeatPayout('seat1', 'pair'),
                        seat2: getSeatPayout('seat2', 'pair'),
                        seat3: getSeatPayout('seat3', 'pair'),
                        seat4: getSeatPayout('seat4', 'pair'),
                        seat5: getSeatPayout('seat5', 'pair'),
                        total: getBetTypeTotalPayout('pair'),
                    },
                    split: {
                        seat1: getSeatPayout('seat1', 'split'),
                        seat2: getSeatPayout('seat2', 'split'),
                        seat3: getSeatPayout('seat3', 'split'),
                        seat4: getSeatPayout('seat4', 'split'),
                        seat5: getSeatPayout('seat5', 'split'),
                        total: getBetTypeTotalPayout('split'),
                    },
                    total: {
                        seat1: getSeatTotalPayout('seat1', blackjackStandardBetTypes),
                        seat2: getSeatTotalPayout('seat2', blackjackStandardBetTypes),
                        seat3: getSeatTotalPayout('seat3', blackjackStandardBetTypes),
                        seat4: getSeatTotalPayout('seat4', blackjackStandardBetTypes),
                        seat5: getSeatTotalPayout('seat5', blackjackStandardBetTypes),
                        total: totalPayout,
                    },
                }
            }
            if (gameVariant === 'anyPairSlot') {
                obj = {
                    base: {
                        seat1: getSeatPayout('seat1', 'base'),
                        seat2: getSeatPayout('seat2', 'base'),
                        seat3: getSeatPayout('seat3', 'base'),
                        seat4: getSeatPayout('seat4', 'base'),
                        seat5: getSeatPayout('seat5', 'base'),
                        total: getBetTypeTotalPayout('base'),
                    },
                    pair: {
                        seat1: getSeatPayout('seat1', 'pair'),
                        seat2: getSeatPayout('seat2', 'pair'),
                        seat3: getSeatPayout('seat3', 'pair'),
                        seat4: getSeatPayout('seat4', 'pair'),
                        seat5: getSeatPayout('seat5', 'pair'),
                        total: getBetTypeTotalPayout('pair'),
                    },
                    anyPair: {
                        seat1: getSeatPayout('seat1', 'anyPair'),
                        seat2: getSeatPayout('seat2', 'anyPair'),
                        seat3: getSeatPayout('seat3', 'anyPair'),
                        seat4: getSeatPayout('seat4', 'anyPair'),
                        seat5: getSeatPayout('seat5', 'anyPair'),
                        total: getBetTypeTotalPayout('anyPair'),
                    },
                    split: {
                        seat1: getSeatPayout('seat1', 'split'),
                        seat2: getSeatPayout('seat2', 'split'),
                        seat3: getSeatPayout('seat3', 'split'),
                        seat4: getSeatPayout('seat4', 'split'),
                        seat5: getSeatPayout('seat5', 'split'),
                        total: getBetTypeTotalPayout('split'),
                    },
                    doubleDown: {
                        seat1: getSeatPayout('seat1', 'doubleDown'),
                        seat2: getSeatPayout('seat2', 'doubleDown'),
                        seat3: getSeatPayout('seat3', 'doubleDown'),
                        seat4: getSeatPayout('seat4', 'doubleDown'),
                        seat5: getSeatPayout('seat5', 'doubleDown'),
                        total: getBetTypeTotalPayout('doubleDown'),
                    },
                    insurance: {
                        seat1: getSeatPayout('seat1', 'insurance'),
                        seat2: getSeatPayout('seat2', 'insurance'),
                        seat3: getSeatPayout('seat3', 'insurance'),
                        seat4: getSeatPayout('seat4', 'insurance'),
                        seat5: getSeatPayout('seat5', 'insurance'),
                        total: getBetTypeTotalPayout('insurance'),
                    },
                    bonus: {
                        seat1: getSeatPayout('seat1', 'bonus'),
                        seat2: getSeatPayout('seat2', 'bonus'),
                        seat3: getSeatPayout('seat3', 'bonus'),
                        seat4: getSeatPayout('seat4', 'bonus'),
                        seat5: getSeatPayout('seat5', 'bonus'),
                        total: getBetTypeTotalPayout('bonus'),
                    },
                    total: {
                        seat1: getSeatTotalPayout('seat1', blackjackAnyPairSlotBetTypes),
                        seat2: getSeatTotalPayout('seat2', blackjackAnyPairSlotBetTypes),
                        seat3: getSeatTotalPayout('seat3', blackjackAnyPairSlotBetTypes),
                        seat4: getSeatTotalPayout('seat4', blackjackAnyPairSlotBetTypes),
                        seat5: getSeatTotalPayout('seat5', blackjackAnyPairSlotBetTypes),
                        total: totalPayout,
                    },
                }
            }
        }

        return obj
    }

    const getTotalPayout = () => {
        let obj
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                obj = {
                    banker: getBetTypeTotalPayout('banker'),
                    player: getBetTypeTotalPayout('player'),
                    tie: getBetTypeTotalPayout('tie'),
                    bankerPair: getBetTypeTotalPayout('bankerPair'),
                    playerPair: getBetTypeTotalPayout('playerPair'),
                    total: totalPayout,
                }
                break
            case 'baccaratLucky6':
                obj = {
                    banker: getBetTypeTotalPayout('banker'),
                    player: getBetTypeTotalPayout('player'),
                    tie: getBetTypeTotalPayout('tie'),
                    bankerPair: getBetTypeTotalPayout('bankerPair'),
                    playerPair: getBetTypeTotalPayout('playerPair'),
                    lucky6: getBetTypeTotalPayout('lucky6'),
                    total: totalPayout,
                }
                break
            case 'baccaratMega6':
                obj = {
                    banker: getBetTypeTotalPayout('banker'),
                    player: getBetTypeTotalPayout('player'),
                    tie: getBetTypeTotalPayout('tie'),
                    bankerPair: getBetTypeTotalPayout('bankerPair'),
                    playerPair: getBetTypeTotalPayout('playerPair'),
                    mega6: getBetTypeTotalPayout('mega6'),
                    total: totalPayout,
                }
                break
            case 'baccaratTiger':
                obj = {
                    banker: getBetTypeTotalPayout('banker'),
                    player: getBetTypeTotalPayout('player'),
                    tie: getBetTypeTotalPayout('tie'),
                    tigerTie: getBetTypeTotalPayout('tigerTie'),
                    tigerPair: getBetTypeTotalPayout('tigerPair'),
                    tigerBig: getBetTypeTotalPayout('tigerBig'),
                    tigerSmall: getBetTypeTotalPayout('tigerSmall'),
                    tiger: getBetTypeTotalPayout('tiger'),
                    total: totalPayout,
                }
                break
            case 'blackjackStandard':
                obj = {
                    base: getBetTypeTotalPayout('base'),
                    doubleDown: getBetTypeTotalPayout('doubleDown'),
                    insurance: getBetTypeTotalPayout('insurance'),
                    pair: getBetTypeTotalPayout('pair'),
                    split: getBetTypeTotalPayout('split'),
                    total: totalPayout,
                }
                break
            case 'blackjackAnyPairSlot':
                obj = {
                    base: getBetTypeTotalPayout('base'),
                    pair: getBetTypeTotalPayout('pair'),
                    anyPair: getBetTypeTotalPayout('anyPair'),
                    split: getBetTypeTotalPayout('split'),
                    doubleDown: getBetTypeTotalPayout('doubleDown'),
                    insurance: getBetTypeTotalPayout('insurance'),
                    bonus: getBetTypeTotalPayout('bonus'),
                    total: totalPayout,
                }
                break
            default:
                obj = {}
        }
        return obj
    }

    const ZoneWagerTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Wager by zone and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {zones.map((zone) => {
                                            return <th key={zone.index}>{zone.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {zones.map((zone) => {
                                                    const obj = getZoneWager(zone)

                                                    return (
                                                        <td style={styles.cashValue(obj[betType])} key={zone.index}>
                                                            {formatCashString(obj[betType])}
                                                        </td>
                                                    )
                                                })}
                                                <th style={styles.cashValue(getTotalWager()[betType])}>
                                                    {formatCashString(getTotalWager()[betType])}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const ZoneWinLossTable = ({ type }) => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                {type === 'house' && <>House Win/Loss by zone and bet type</>}
                                {type === 'player' && <>Player Win/Loss by zone and bet type</>}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {zones.map((zone) => {
                                            return <th key={zone.index}>{zone.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {zones.map((zone) => {
                                                    const obj = getZoneWinLoss(zone)
                                                    return (
                                                        <td
                                                            style={styles.cashValue(
                                                                type === 'house' ? obj[betType] : obj[betType] * -1
                                                            )}
                                                            key={zone.index}
                                                        >
                                                            {formatCashString(
                                                                type === 'house' ? obj[betType] : obj[betType] * -1
                                                            )}
                                                        </td>
                                                    )
                                                })}
                                                <th
                                                    style={styles.cashValue(
                                                        type === 'house'
                                                            ? getTotalWinLoss()[betType]
                                                            : getTotalWinLoss()[betType] * -1
                                                    )}
                                                >
                                                    {formatCashString(
                                                        type === 'house'
                                                            ? getTotalWinLoss()[betType]
                                                            : getTotalWinLoss()[betType] * -1
                                                    )}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const ZoneBetCountTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Bet count by zone and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {zones.map((zone) => {
                                            return <th key={zone.index}>{zone.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {zones.map((zone) => {
                                                    const obj = getZoneBetCount(zone)
                                                    return <td key={zone.index}>{formatCountString(obj[betType])}</td>
                                                })}
                                                <th>{formatCountString(getTotalBetCount()[betType])}</th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const ZonePayoutTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Payout by zone and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {zones.map((zone) => {
                                            return <th key={zone.index}>{zone.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {zones.map((zone) => {
                                                    const obj = getZonePayout(zone)
                                                    return (
                                                        <td style={styles.cashValue(obj[betType])} key={zone.index}>
                                                            {formatCashString(obj[betType])}
                                                        </td>
                                                    )
                                                })}
                                                <th style={styles.cashValue(getTotalPayout()[betType])}>
                                                    {formatCashString(getTotalPayout()[betType])}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const PitWagerTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Wager by pit and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {pitsSelected.map((pit) => {
                                            return <th key={pit.index}>{pit.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {pitsSelected.map((pit) => {
                                                    const obj = getPitWager(pit)
                                                    return (
                                                        <td style={styles.cashValue(obj[betType])} key={pit.index}>
                                                            {formatCashString(obj[betType])}
                                                        </td>
                                                    )
                                                })}
                                                <th style={styles.cashValue(getTotalWager()[betType])}>
                                                    {formatCashString(getTotalWager()[betType])}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const PitWinLossTable = ({ type }) => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                {type === 'house' && <>House Win/Loss by pit and bet type</>}
                                {type === 'player' && <>Player Win/Loss by pit and bet type</>}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {pitsSelected.map((pit) => {
                                            return <th key={pit.index}>{pit.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {pitsSelected.map((pit) => {
                                                    const obj = getPitWinLoss(pit)
                                                    return (
                                                        <td
                                                            style={styles.cashValue(
                                                                type === 'house' ? obj[betType] : obj[betType] * -1
                                                            )}
                                                            key={pit.index}
                                                        >
                                                            {formatCashString(
                                                                type === 'house' ? obj[betType] : obj[betType] * -1
                                                            )}
                                                        </td>
                                                    )
                                                })}
                                                <th
                                                    style={styles.cashValue(
                                                        type === 'house'
                                                            ? getTotalWinLoss()[betType]
                                                            : getTotalWinLoss()[betType] * -1
                                                    )}
                                                >
                                                    {formatCashString(
                                                        type === 'house'
                                                            ? getTotalWinLoss()[betType]
                                                            : getTotalWinLoss()[betType] * -1
                                                    )}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const PitBetCountTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Bet count by pit and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {pitsSelected.map((pit) => {
                                            return <th key={pit.index}>{pit.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {pitsSelected.map((pit) => {
                                                    const obj = getPitBetCount(pit)
                                                    return <td key={pit.index}>{formatCountString(obj[betType])}</td>
                                                })}
                                                <th>{formatCountString(getTotalBetCount()[betType])}</th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const PitPayoutTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Payout by pit and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {pitsSelected.map((pit) => {
                                            return <th key={pit.index}>{pit.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {pitsSelected.map((pit) => {
                                                    const obj = getPitPayout(pit)
                                                    return (
                                                        <td style={styles.cashValue(obj[betType])} key={pit.index}>
                                                            {formatCashString(obj[betType])}
                                                        </td>
                                                    )
                                                })}
                                                <th style={styles.cashValue(getTotalPayout()[betType])}>
                                                    {formatCashString(getTotalPayout()[betType])}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const DeviceWagerTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Wager by table and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {tablesSelected.map((table) => {
                                            return <th key={table.index}>{table.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {location[0].descendantDevices
                                                    .filter((device) => gameDevicesIndexes.includes(device))
                                                    .map((deviceID) => {
                                                        const obj = getDeviceWager(deviceID)
                                                        return (
                                                            <td style={styles.cashValue(obj[betType])} key={deviceID}>
                                                                {formatCashString(obj[betType])}
                                                            </td>
                                                        )
                                                    })}
                                                <th style={styles.cashValue(getTotalWager()[betType])}>
                                                    {formatCashString(getTotalWager()[betType])}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const DeviceWinLossTable = ({ type }) => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                {type === 'house' && <>House Win/Loss by table and bet type</>}
                                {type === 'player' && <>Player Win/Loss by table and bet type</>}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {tablesSelected.map((table) => {
                                            return <th key={table.index}>{table.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {location[0].descendantDevices
                                                    .filter((device) => gameDevicesIndexes.includes(device))
                                                    .map((deviceID) => {
                                                        const obj = getDeviceWinLoss(deviceID)
                                                        return (
                                                            <td
                                                                style={styles.cashValue(
                                                                    type === 'house' ? obj[betType] : obj[betType] * -1
                                                                )}
                                                                key={deviceID}
                                                            >
                                                                {formatCashString(
                                                                    type === 'house' ? obj[betType] : obj[betType] * -1
                                                                )}
                                                            </td>
                                                        )
                                                    })}
                                                <th
                                                    style={styles.cashValue(
                                                        type === 'house'
                                                            ? getTotalWinLoss()[betType]
                                                            : getTotalWinLoss()[betType] * -1
                                                    )}
                                                >
                                                    {formatCashString(
                                                        type === 'house'
                                                            ? getTotalWinLoss()[betType]
                                                            : getTotalWinLoss()[betType] * -1
                                                    )}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const DeviceBetCountTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Bet count by table and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {tablesSelected.map((table) => {
                                            return <th key={table.index}>{table.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {location[0].descendantDevices
                                                    .filter((device) => gameDevicesIndexes.includes(device))
                                                    .map((deviceID) => {
                                                        const obj = getDeviceBetCount(deviceID)
                                                        return <td key={deviceID}>{formatCountString(obj[betType])}</td>
                                                    })}
                                                <th>{formatCountString(getTotalBetCount()[betType])}</th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const DevicePayoutTable = () => {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Payout by table and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {tablesSelected.map((table) => {
                                            return <th key={table.index}>{table.data.name}</th>
                                        })}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getBetTypesRow(gameVariantFilter).map((betType, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {location[0].descendantDevices
                                                    .filter((device) => gameDevicesIndexes.includes(device))
                                                    .map((deviceID) => {
                                                        const obj = getDevicePayout(deviceID)
                                                        return (
                                                            <td style={styles.cashValue(obj[betType])} key={deviceID}>
                                                                {formatCashString(obj[betType])}
                                                            </td>
                                                        )
                                                    })}
                                                <th style={styles.cashValue(getTotalPayout()[betType])}>
                                                    {formatCashString(getTotalPayout()[betType])}
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const SeatWagerTable = () => {
        let betTypesRow
        if (devicesSelected[0].data.gameType === 'baccarat') {
            if (devicesSelected[0].data.gameVariant === 'standard') {
                betTypesRow = baccaratStandardBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'lucky6') {
                betTypesRow = baccaratLucky6BetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'lucky6Slot') {
                betTypesRow = baccaratLucky6SlotBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'mega6') {
                betTypesRow = baccaratMega6BetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'tiger') {
                betTypesRow = baccaratTigerBetTypesRow
            }
        }
        if (devicesSelected[0].data.gameType === 'blackjack') {
            if (devicesSelected[0].data.gameVariant === 'standard') {
                betTypesRow = blackjackStandardBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'anyPairSlot') {
                betTypesRow = blackjackAnyPairSlotBetTypesRow
            }
        }

        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Wager by seat and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {[...Array(5)].map((e, i) => (
                                            <th>
                                                Seat{' '}
                                                {(devicesSelected[0].data.gameType === 'baccarat' ||
                                                    devicesSelected[0].data.gameType === 'blackjack') &&
                                                    devicesSelected[0].data?.skipSeatFour
                                                    ? i + 1 > 3
                                                        ? i + 2
                                                        : i + 1
                                                    : i + 1}
                                            </th>
                                        ))}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {betTypesRow.map((betType, index) => {
                                        const obj = devicesSelected
                                            ? getSeatBetTypeWager(
                                                devicesSelected[0].data.gameType,
                                                devicesSelected[0].data.gameVariant
                                            )
                                            : {}

                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {values(obj[betType]).map((seat, index) => {
                                                    return (
                                                        <td style={styles.cashValue(seat)} key={index}>
                                                            {formatCashString(seat)}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const SeatWinLossTable = ({ type }) => {
        let betTypesRow
        if (devicesSelected[0].data.gameType === 'baccarat') {
            if (devicesSelected[0].data.gameVariant === 'standard') {
                betTypesRow = baccaratStandardBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'lucky6') {
                betTypesRow = baccaratLucky6BetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'lucky6Slot') {
                betTypesRow = baccaratLucky6SlotBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'mega6') {
                betTypesRow = baccaratMega6BetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'tiger') {
                betTypesRow = baccaratTigerBetTypesRow
            }
        }
        if (devicesSelected[0].data.gameType === 'blackjack') {
            if (devicesSelected[0].data.gameVariant === 'standard') {
                betTypesRow = blackjackStandardBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'anyPairSlot') {
                betTypesRow = blackjackAnyPairSlotBetTypesRow
            }
        }

        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                {type === 'house' && <>House Win/Loss by seat and bet type</>}
                                {type === 'player' && <>Player Win/Loss by seat and bet type</>}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {[...Array(5)].map((e, i) => (
                                            <th>
                                                Seat{' '}
                                                {(devicesSelected[0].data.gameType === 'baccarat' ||
                                                    devicesSelected[0].data.gameType === 'blackjack') &&
                                                    devicesSelected[0].data?.skipSeatFour
                                                    ? i + 1 > 3
                                                        ? i + 2
                                                        : i + 1
                                                    : i + 1}
                                            </th>
                                        ))}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {betTypesRow.map((betType, index) => {
                                        const obj = devicesSelected
                                            ? getSeatBetTypeWinLoss(
                                                devicesSelected[0].data.gameType,
                                                devicesSelected[0].data.gameVariant
                                            )
                                            : {}

                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {values(obj[betType]).map((seat, index) => {
                                                    return (
                                                        <td
                                                            style={styles.cashValue(
                                                                type === 'house' ? seat : seat * -1
                                                            )}
                                                            key={index}
                                                        >
                                                            {formatCashString(type === 'house' ? seat : seat * -1)}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const SeatBetCountTable = () => {
        let betTypesRow
        if (devicesSelected[0].data.gameType === 'baccarat') {
            if (devicesSelected[0].data.gameVariant === 'standard') {
                betTypesRow = baccaratStandardBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'lucky6') {
                betTypesRow = baccaratLucky6BetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'lucky6Slot') {
                betTypesRow = baccaratLucky6SlotBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'mega6') {
                betTypesRow = baccaratMega6BetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'tiger') {
                betTypesRow = baccaratTigerBetTypesRow
            }
        }
        if (devicesSelected[0].data.gameType === 'blackjack') {
            if (devicesSelected[0].data.gameVariant === 'standard') {
                betTypesRow = blackjackStandardBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'anyPairSlot') {
                betTypesRow = blackjackAnyPairSlotBetTypesRow
            }
        }

        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Bet count by seat and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {[...Array(5)].map((e, i) => (
                                            <th>
                                                Seat{' '}
                                                {(devicesSelected[0].data.gameType === 'baccarat' ||
                                                    devicesSelected[0].data.gameType === 'blackjack') &&
                                                    devicesSelected[0].data?.skipSeatFour
                                                    ? i + 1 > 3
                                                        ? i + 2
                                                        : i + 1
                                                    : i + 1}
                                            </th>
                                        ))}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {betTypesRow.map((betType, index) => {
                                        const obj = devicesSelected
                                            ? getSeatBetTypeBetCount(
                                                devicesSelected[0].data.gameType,
                                                devicesSelected[0].data.gameVariant
                                            )
                                            : {}

                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {values(obj[betType]).map((seat, index) => {
                                                    return <td key={index}>{formatCountString(seat)}</td>
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const SeatPayoutTable = () => {
        let betTypesRow
        if (devicesSelected[0].data.gameType === 'baccarat') {
            if (devicesSelected[0].data.gameVariant === 'standard') {
                betTypesRow = baccaratStandardBetTypesRow
            }
            if (
                devicesSelected[0].data.gameVariant === 'lucky6' ||
                devicesSelected[0].data.gameVariant === 'lucky6Slot'
            ) {
                betTypesRow = baccaratLucky6BetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'mega6') {
                betTypesRow = baccaratMega6BetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'tiger') {
                betTypesRow = baccaratTigerBetTypesRow
            }
        }
        if (devicesSelected[0].data.gameType === 'blackjack') {
            if (devicesSelected[0].data.gameVariant === 'standard') {
                betTypesRow = blackjackStandardBetTypesRow
            }
            if (devicesSelected[0].data.gameVariant === 'anyPairSlot') {
                betTypesRow = blackjackAnyPairSlotBetTypesRow
            }
        }

        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>Payout by seat and bet type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {[...Array(5)].map((e, i) => (
                                            <th>
                                                Seat{' '}
                                                {(devicesSelected[0].data.gameType === 'baccarat' ||
                                                    devicesSelected[0].data.gameType === 'blackjack') &&
                                                    devicesSelected[0].data?.skipSeatFour
                                                    ? i + 1 > 3
                                                        ? i + 2
                                                        : i + 1
                                                    : i + 1}
                                            </th>
                                        ))}
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {betTypesRow.map((betType, index) => {
                                        const obj = devicesSelected
                                            ? getSeatBetTypePayout(
                                                devicesSelected[0].data.gameType,
                                                devicesSelected[0].data.gameVariant
                                            )
                                            : {}

                                        return (
                                            <tr key={index}>
                                                <th scope='row' style={styles.betType(startCase(betType))}>
                                                    {startCase(betType)}
                                                </th>
                                                {values(obj[betType]).map((seat, index) => {
                                                    return (
                                                        <td style={styles.cashValue(seat)} key={index}>
                                                            {formatCashString(seat)}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    const treeLoading = !active || !zones || !pits || !devices || !tables || !status || !minLoadTime

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    if (
        !treeLoading &&
        (!location || (URLlocationID !== location[0].branchIndex && URLlocationType !== location[0].branchType))
    ) {
        setLocation({
            branchIndex: URLlocationID,
            branchType: URLlocationType,
            descendantDevices: getDescendantDevices(pits, tables, devices, URLlocationID, URLlocationType),
        })
    }

    const fluid = width < 1900

    return (
        <div style={styles.rootContainer}>
            <Tree
                loading={treeLoading}
                autoUpdateEnabled={true}
                dateChange={dateChange}
                startDate={startDate}
                endDate={endDate}
                zones={zones}
                pits={pits}
                tables={tables}
                devices={devices}
                setLocation={(newLocation) => {
                    setLocationChange(true)
                    setLocation(newLocation)
                    setTimeout(() => {
                        setLocationChange(false)
                    }, 1500)
                }}
                location={location}
                fluid={fluid}
            />

            <div style={{ gridColumn: '2 / span 2' }}>
                <Location
                    loading={treeLoading}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    location={location}
                    fluid={fluid}
                    filter
                />
                {(!tablesReport || locationChange || !location) && !treeLoading && (
                    <Container fluid={fluid}>
                        <Row xs='1'>
                            <Col>
                                <h3>Fetching tables report...</h3>
                                <LinearProgress />
                            </Col>
                        </Row>
                    </Container>
                )}
                {!isTable && !gameVariantFilter && !treeLoading && tablesReport && !locationChange && location && (
                    <Container fluid={fluid}>
                        <Row xs='1'>
                            <Col>
                                <h3>Please choose a game type variant to view the data</h3>
                            </Col>
                        </Row>
                    </Container>
                )}
                {((!isTable && gameVariantFilter) || isTable) &&
                    !treeLoading &&
                    tablesReport &&
                    !locationChange &&
                    location && (
                        <Container fluid={fluid}>
                            {isCasino &&
                                gameVariantFilter !== 'rouletteStandard' &&
                                gameVariantFilter !== 'warEtg' &&
                                tablesReport && (
                                    <>
                                        <ZoneWagerTable />
                                        <ZoneWinLossTable type='house' />
                                        <ZoneBetCountTable />
                                        <ZonePayoutTable />
                                        <ZoneWinLossTable type='player' />
                                    </>
                                )}

                            {isZone &&
                                gameVariantFilter !== 'rouletteStandard' &&
                                gameVariantFilter !== 'warEtg' &&
                                tablesReport && (
                                    <>
                                        <PitWagerTable />
                                        <PitWinLossTable type='house' />
                                        <PitBetCountTable />
                                        <PitPayoutTable />
                                        <PitWinLossTable type='player' />
                                    </>
                                )}

                            {isPit &&
                                gameVariantFilter !== 'rouletteStandard' &&
                                gameVariantFilter !== 'warEtg' &&
                                devices &&
                                tablesReport && (
                                    <>
                                        <DeviceWagerTable />
                                        <DeviceWinLossTable type='house' />
                                        <DeviceBetCountTable />
                                        <DevicePayoutTable />
                                        <DeviceWinLossTable type='player' />
                                    </>
                                )}

                            {isTable &&
                                devicesSelected &&
                                !isEmpty(devicesSelected) &&
                                devicesSelected[0].data.gameType &&
                                devicesSelected[0].data.gameVariant &&
                                devicesSelected[0].data.gameType !== 'roulette' &&
                                devicesSelected[0].data.gameType !== 'war' && (
                                    <>
                                        <SeatWagerTable />
                                        <SeatWinLossTable type='house' />
                                        <SeatBetCountTable />
                                        <SeatPayoutTable />
                                        <SeatWinLossTable type='player' />
                                    </>
                                )}
                        </Container>
                    )}
            </div>
        </div>
    )
}

export default View
