import React, { createContext, useContext, useState, useRef, useReducer } from 'react'
import { get, isEqual, cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate, Navigate } from 'react-router-dom'
import { LinearProgress } from '@mui/material'

import { useSubscribe, usePublish, fetch as api_fetch, api, unpublish, playerApi } from '../../api'
import { playerServerIP, dMods, dBug } from '../../config'
import { useMenuContext } from '../contexts/MenuContext'
import Initialise from '../initialise/Initialise'
import LoadingMessage from '../LoadingMessage'
import CashDrop from './CashDrop'
import CashTopUp from './CashTopUp'
import ChaseTheTrendGame from './ChaseTheTrendGame'
import ChipsRefill from './ChipsRefill'
import ChipTopUp from './ChipTopUp'
import CrowdPlayerScan from './CrowdPlayerScan'
import DealerCards from './DealerCards'
import { rootStyles } from './DealerCSS'
import DealerEOD from './DealerEOD'
import DealerModal from './DealerModal'
import DealerSideMenu from './DealerSideMenu'
import Dialog from './Dialog'
import EditCardsPanel from './EditCardsPanel'
import ManualEndGameButton from './ManualEndGameButton'
import ManualGame from './ManualGame'
import PitBossDialog from './PitBossDialog'
import SeatPlayersNewVer from './SeatPlayers_NewVer'
import Simulator from './Simulator'
import TableFillCredit from './TableFillCredit'
import VoucherTopUp from './VoucherTopUp'

export const DUIContext = createContext()
const PreDUIContext = createContext()

const blankCardState = {
    b1: { suit: null, value: null, show: false },
    b2: { suit: null, value: null, show: false },
    b3: { suit: null, value: null, show: false },
    p1: { suit: null, value: null, show: false },
    p2: { suit: null, value: null, show: false },
    p3: { suit: null, value: null, show: false },
}

const PrePreDealer = ({
    isPitBoss = false,
    defaultDeviceIndex = false,
    defaultDeviceIp = false,
    fromPitbossUI,
    selectedDevice,
}) => {
    const { setSelectedTable } = useMenuContext()
    const deviceIp = defaultDeviceIp || window.localStorage.getItem('dealer-ip')

    const [game, gameSocket] = useSubscribe(deviceIp + '/game')
    const active = gameSocket && gameSocket.readyState === WebSocket.OPEN

    if (!isPitBoss) {
        if (!window.localStorage.getItem('dealer-initialisation') || !window.localStorage.getItem('dealer-ip')) {
            return <Navigate to='/posui/dashboard/initialise' />
        }
    }

    if (isPitBoss && fromPitbossUI && game && active) {
        setSelectedTable(selectedDevice.data.name)
    }

    if (!game || !active) {
        return <LoadingMessage message={'Connecting to Table Game Server'} />
    }

    return <PreDealerUI {...{ isPitBoss, defaultDeviceIndex, defaultDeviceIp, game }} />
}

const PreDealerUI = ({ isPitBoss, defaultDeviceIndex, defaultDeviceIp, game }) => {
    // const chaseTheTrendGameIP = dMods.has_chase_trend_game_chipless ? chaseTheTrendGameIPChipless : chaseTheTrendGameIPChipped
    //console.log('--------------------------------------')
    const deviceIndex = defaultDeviceIndex || window.localStorage.getItem('dealer-initialisation')
    const deviceIp = defaultDeviceIp || window.localStorage.getItem('dealer-ip')
    //console.log('defaultDeviceIndex:', defaultDeviceIndex, ' defaultDeviceIp:', defaultDeviceIp)
    //console.log('deviceIndex:', deviceIndex, ' deviceIp:', deviceIp)

    const sessionStart = usePublish(deviceIp + '/sessions/' + deviceIndex)
    const apiPublishDevice = usePublish(deviceIp + '/devices/' + deviceIndex)
    const apiPublishEdit = usePublish(deviceIp + '/baccarat/edit')
    const apiPublishRevealCard = usePublish(deviceIp + '/baccarat/flipoff')
    const apiPublishResult = usePublish(deviceIp + '/baccarat/result')
    const apiPublishCashdrop = usePublish(deviceIp + '/cashdrop')
    const apiPublishChipfill = usePublish(deviceIp + '/chipfill')
    const apiPublishChipcredit = usePublish(deviceIp + '/chipcredit')

    const [time, timeSocket] = useSubscribe(deviceIp)
    const [device, deviceSocket] = useSubscribe(deviceIp + '/devices/' + deviceIndex)
    // TODO: this should be optional to be able to support offline (device only connection functioniality)
    const [settings, settingsSocket] = useSubscribe('settings')
    const [members, membersSocket] = useSubscribe(playerServerIP + '/membership/*')
    const [seats, seatsSocket] = useSubscribe(playerServerIP + '/sessions/' + deviceIndex)
    const [savedMemberships, setSavedMemberships] = useState(null)
    const memberships = []
    const sessions = []
    const credits = []
    const bets = []
    const chasetrend = {}

    const chasetrendStatus = !dMods.has_chase_trend_game && !dMods.has_chase_trend_game_chipless ? false : chasetrend
    const creditsStatus = !dMods.has_seat_credits ? false : credits

    const [sessionStarted, setSessionStarted] = useState(false)
    const active =
        settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN &&
        timeSocket &&
        timeSocket.readyState === WebSocket.OPEN &&
        deviceSocket &&
        deviceSocket.readyState === WebSocket.OPEN &&
        settings &&
        !settings.data.xview.gameOnlyMode &&
        membersSocket &&
        membersSocket.readyState === WebSocket.OPEN &&
        settings &&
        !settings.data.xview.gameOnlyMode &&
        seatsSocket &&
        seatsSocket.readyState === WebSocket.OPEN

    if (
        !time ||
        !device ||
        !active ||
        !memberships ||
        !sessions ||
        !bets ||
        !(dMods.has_chase_trend_game ? chasetrendStatus : true) ||
        !(dMods.has_chase_trend_game_chipless ? chasetrendStatus : true) ||
        !(dMods.has_seat_credits ? creditsStatus : true) ||
        !settings ||
        (settings && !settings.data.xview.gameOnlyMode && !members) ||
        (settings && !settings.data.xview.gameOnlyMode && !seats)
    ) {
        // console.log('WS dependencies not ready:', {
        //   game: game,
        //   device: device,
        //   memberships: memberships,
        //   sessions: sessions,
        //   active: active,
        //   ...dMods.has_chase_trend_game && { chasetrendStatus: chasetrendStatus },
        //   ...dMods.has_seat_credits && { creditsStatus: creditsStatus }
        // })
        if (!device)
            return (
                <LoadingMessage
                    message={
                        'Connection to Device in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (!memberships)
            return (
                <LoadingMessage
                    message={
                        'Connection to Memberships in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (!sessions)
            return (
                <LoadingMessage
                    message={
                        'Connection to Sessions in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (!bets)
            return (
                <LoadingMessage
                    message={
                        'Connection to Bets in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (dMods.has_chase_trend_game && !chasetrendStatus)
            return (
                <LoadingMessage
                    message={
                        'Connection to ChaseTrends in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (dMods.has_chase_trend_game_chipless && !chasetrendStatus)
            return (
                <LoadingMessage
                    message={
                        'Connection to ChaseTrends in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (dMods.has_seat_credits && !creditsStatus)
            return (
                <LoadingMessage
                    message={
                        'Connection to Credits in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (!settings)
            return (
                <LoadingMessage
                    message={
                        'Connection to Settings in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (settings && !settings.data.xview.gameOnlyMode && !members)
            return (
                <LoadingMessage
                    message={
                        'Connection to Memberships in progress. If this progress persists, please contact support for help'
                    }
                />
            )
        if (settings && !settings.data.xview.gameOnlyMode && !seats)
            return (
                <LoadingMessage
                    message={
                        'Connection to Seats in progress. If this progress persists, please contact support for help'
                    }
                />
            )
    }

    if (!isEqual(memberships, savedMemberships)) {
        setSavedMemberships(memberships)
    }

    if (!deviceIndex || !device.created) {
        if (!device.created) {
            // the device that was in the local settings was deleted
            console.log('the device was deletd, reseting initialization')
            window.localStorage.removeItem('dealer-initialisation')
        }
        return <Initialise role='dealer' />
    }

    if (!sessionStarted && !isPitBoss) {
        setSessionStarted(true)
        ;(async () => {
            window.localStorage.setItem('sessionDevice', deviceIp + '/sessions/' + deviceIndex)
            const account = window.localStorage.getItem('account')
            try {
                await sessionStart({
                    account,
                })
            } catch (e) {
                console.warn(e)
            }
        })()
    }

    return (
        <PreDUIContext.Provider
            value={{
                bets,
                apiPublishRevealCard,
                apiPublishDevice,
                apiPublishEdit,
                apiPublishResult,
                apiPublishCashdrop,
                apiPublishChipfill,
                apiPublishChipcredit,
                device,
                game,
                memberships,
                sessions,
                deviceIp,
                chasetrend:
                    (dMods.has_chase_trend_game || dMods.has_chase_trend_game_chipless) && chasetrendStatus
                        ? chasetrend
                        : null,
                credits: dMods.has_seat_credits && creditsStatus ? credits : null,
                members,
                seats,
                settings,
            }}
        >
            <DealerUI time={time} />
        </PreDUIContext.Provider>
    )
}

const DealerUI = ({ time }) => {
    const {
        bets,
        device,
        game,
        memberships,
        deviceIp,
        apiPublishRevealCard,
        apiPublishEdit,
        apiPublishResult,
        apiPublishCashdrop,
        apiPublishChipfill,
        apiPublishChipcredit,
        chasetrend,
        credits,
        sessions,
        members,
        seats,
        settings,
    } = useContext(PreDUIContext)
    const hasGameState = game.data && !isEqual(game.data, {})

    const captureField = useRef(null)
    const [capturedString, setCapturedString] = useState(null)

    const s = rootStyles()
    const navigate = useNavigate()
    const { t } = useTranslation('common')
    const [, rerender] = useReducer((x) => (x ? false : true), false)
    const [showCTTGButton, setShowCTTGButton] = useState(true)
    const [CTTGButtonTriggered, setCTTGButtonTriggered] = useState(false)

    const [editState, setEditState] = useState(cloneDeep(blankCardState))
    const [cardState, setCardState] = useState(cloneDeep(blankCardState))
    const [cardStateUnedited, setCardStateUnedited] = useState({})
    const [showMenu, setShowMenu] = useState(false)
    const [showConfirmEdit, setShowCEbtn] = useState(false)
    const [showRemoveCard, setShowRCbtn] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)
    const [inEditMode, setInEditMode] = useState(false)

    const [selectedSeat, setSelectedSeat] = useState(null)

    const verifyMembership = async (capturedCardNo) => {
        try {
            const res = await playerApi.get(`membership/verify/${capturedCardNo}`).json()
            return res
        } catch (e) {
            console.log(e)
        }
    }

    const bindSeat = async (seatIndex, capturedMembershipID) => {
        try {
            await playerApi.get(`bind/membership/${device.index}/${seatIndex}/${capturedMembershipID}`)
        } catch (e) {
            console.log(e)
        }
    }
    const unbindSeat = async (seatIndex, bindedMembershipID) => {
        try {
            await playerApi.get(`unbind/membership/${device.index}/${seatIndex}/${bindedMembershipID}`)
        } catch (e) {
            console.log(e)
        }
    }

    const initialDialogObj = {
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => {},
    }

    const [dialogObj, setDialogObj] = useState(initialDialogObj)

    const NanoToSeconds = (time) => Math.floor(time / 1000000000)

    const timeInSeconds = Math.round(time / 1000000000)

    const [betTimer, setBetTimer] = useReducer(
        (s, o) => {
            if (o.action === 'restart') return { ...s, status: 'started' }
            if (o.action === 'start') return { ...s, status: 'started' }
            if (o.action === 'reset') return { ...s, countdown: 0, start: 0, status: 'new' }
            if (o.action === 'stop') return { ...s, countdown: 0, start: 0, status: 'stopped' }
            if (o.action === 'reduce1')
                return s.stop ? s : { ...s, countdown: Number(timeInSeconds), status: 'counting' }
            if (o.action === 'timerChange') return { ...s, bet_timer: Number(device.data.bet_timer) }
            if (o.action === 'setStart')
                return { ...s, start: NanoToSeconds(game.data.timerRestarted), status: 'started' }
        },
        { countdown: 0, start: 0, bet_timer: Number(device.data.bet_timer), status: 'new' }
    )

    const [revealStatus, setRevealStatus] = useReducer(
        (state, obj) => {
            if (obj.state) {
                return obj.state
            }

            const cardReveal = obj.cardReveal
            const gameStatus = obj.gameStatus
            const gameCards = obj.gameCards
            const cardsInPlay = Object.keys(gameCards).length
            const latestOwner = gameCards[cardsInPlay - 1] && gameCards[cardsInPlay - 1].owner
            const cardKeys = { p1: 0, b1: 1, p2: 2, b2: 3, p3: 4, b3: 5 }

            const newRevealStatus = Object.keys(cardKeys).reduce((c, card) => {
                if (cardReveal === 'trigger' && cardsInPlay === 5 && latestOwner === 'B' && c[card] === 'show') {
                    return { ...c, b3: 'show' }
                }

                if (c[card] === 'show') return c

                if (gameCards[cardKeys[card]] && gameCards[cardKeys[card]].show === true) {
                    return { ...c, [card]: 'show' }
                }

                if (gameStatus === 'done' || gameStatus === 'fail') {
                    console.log('game status done, set reveal show')
                    return { ...c, [card]: 'show' }
                }

                if (
                    ['trigger', 'timed'].includes(cardReveal) &&
                    cardsInPlay >= 5 &&
                    ['b1', 'b2', 'p1', 'p2'].includes(card)
                ) {
                    c = { ...c, [card]: 'show' }
                }

                if (c[card] === 'ready') return c

                if (cardReveal === 'trigger' && cardsInPlay === 4) {
                    c = { ...c, [card]: 'ready' }
                }

                if (cardReveal === 'immediate') {
                    c = { ...c, [card]: 'show' }
                }

                if (cardReveal === 'timed') {
                    if (cardsInPlay === 4 && c[card] === 'hidden' && gameStatus === 'ongoing') {
                        c = { ...c, [card]: 'countdown_flop' }
                    }
                    if (cardsInPlay > 4 && c[card] !== 'countdown_plus' + latestOwner && gameStatus === 'ongoing') {
                        c = { ...c, [card]: 'countdown_plus' + latestOwner }
                    }
                }
                return c
            }, state)
            return newRevealStatus
        },
        { b1: 'hidden', b2: 'hidden', b3: 'hidden', p1: 'hidden', p2: 'hidden', p3: 'hidden' }
    )

    const [finalizeError, setFinalizeError] = useState(null)
    const [bankerPoints, setBankerPoints] = useState(0)
    const [playerPoints, setPlayerPoints] = useState(0)

    const [gameCreated, setGameCreated] = useState(-1)
    const [gameUpdated, setGameUpdated] = useState(-1)
    const [deviceUpdated, setDeviceUpdated] = useState(-1)
    const [gameStarted, setGameStarted] = useState(-1)
    const [deviceData, setDeviceData] = useState({})
    const [loading, setLoading] = useState(false)

    const [EODViewOpen, setEODViewOpen] = useState(false)

    const [showCashTopUp, setShowCashTopUp] = useState(false)
    const [showChipTopUp, setShowChipTopUp] = useState(false)
    const [showVoucherTopUp, setShowVoucherTopUp] = useState(false)
    const [showCashDrop, setShowCashDrop] = useState(false)
    const [showTableFillCredit, setShowTableFillCredit] = useState(false)
    const [showChipsRefill, setShowChipsRefill] = useState(false)
    const [showCrowdPlayerScan, setShowCrowdPlayerScan] = useState(false)
    const [showSeatPlayersMenu, setShowSeatPlayersMenu] = useState(false)
    //const [showMemberSwipe, setShowMemberSwipe] = useState(false)
    const [selectedSeatID, setSelectedSeatID] = useState(null)

    const resetSeatPlayerSelection = () => {
        setSelectedSeatID(null)
        setCapturedString(null)
    }

    const [modalOpen, setModalOpen] = useState(false)
    const [modalHeader, setModalHeader] = useState('')
    const [modalDescript, setModalDescript] = useState('')
    const [modalConfirm, setModalConfirm] = useState('')
    const [swipeCard, setSwipeCard] = useState('')

    const [useSwipeAuth, setUseSwipeAuth] = useState(true)
    const [pitbossAccount, setPitbossAccount] = useState('')
    const [pitbossPassword, setPitbossPassword] = useState('')

    const [isGameVoided, setIsGameVoided] = useState(false)

    // beware this function is actually not being called
    const logoutDealer = async () => {
        try {
            const sessionDevice = window.localStorage.getItem('sessionDevice')
            if (sessionDevice) {
                window.localStorage.removeItem('sessionDevice')
                await unpublish(sessionDevice)
            }
            navigate('/logout')
        } catch (e) {
            console.log('logoutDealer err ', e)
        }
    }

    const displayTallyPoints = () => {
        //console.log(game)
        const cardReveal = deviceData.cardReveal
        const gameCards = get(game, 'data.cards', [])
        const cardsInPlay = Object.keys(gameCards).length

        if (cardReveal === 'immediate') {
            return true
        }
        if (cardReveal === 'trigger') {
            return true
        }
        if (cardReveal === 'timed') {
            if (cardsInPlay === 4 && !['p1', 'p2', 'b1', 'b2'].map((v) => revealStatus[v] === 'show').includes(false)) {
                return true
            }
            if (
                cardsInPlay === 5 &&
                !['p1', 'p2', 'b1', 'b2', 'p3'].map((v) => revealStatus[v] === 'show').includes(false)
            ) {
                return true
            }
            if (
                cardsInPlay === 6 &&
                !['p1', 'p2', 'b1', 'b2', 'p3', 'b3'].map((v) => revealStatus[v] === 'show').includes(false)
            ) {
                return true
            }
        }
        return false
    }

    if (loading) {
        return <LinearProgress />
    }

    const callApiPublishCashdrop = async ({ data, success, fail }) => {
        try {
            setLoading(true)
            await apiPublishCashdrop(data)
            success()
            setLoading(false)
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            fail()
            setLoading(false)
        }
    }

    const callApiPublishChipfill = async ({ data, success, fail }) => {
        try {
            setLoading(true)
            await apiPublishChipfill(data)
            success()
            setLoading(false)
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            fail()
            setLoading(false)
        }
    }

    const callApiPublishChipcredit = async ({ data, success, fail }) => {
        try {
            setLoading(true)
            await apiPublishChipcredit(data)
            success()
            setLoading(false)
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            fail()
            setLoading(false)
        }
    }

    const apiDeviceOpen = async (success, fail) => {
        try {
            await api_fetch(deviceIp + '/device/open')
            success()
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            fail()
        }
    }

    const apiDeviceClose = async (success, fail) => {
        try {
            await api_fetch(deviceIp + '/device/close')
            success()
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            fail()
        }
    }

    const apiVoidGame = async (success, fail) => {
        try {
            await api_fetch(deviceIp + '/baccarat/void')
            success()
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            fail()
        }
    }

    const apiVoidLastGame = async (success, fail) => {
        try {
            await api_fetch(deviceIp + '/baccarat/void/last')
            success()
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            fail()
        }
    }

    const apiDeckChange = async (cb) => {
        try {
            const response = await fetch('http://' + deviceIp + '/baccarat/deck')
            if (response.status === 200) {
                cb()
            } else {
                console.log('Something went wrong:', response)
            }
        } catch (e) {
            console.log('apiDeckChange err ', e)
        }
    }

    /////////////////////////////////////////////////////////
    //                  CSS HELPERS
    /////////////////////////////////////////////////////////
    const isVisible = (bool) => {
        return bool ? '' : s.hidden
    }
    const getClassName = (...k) => {
        return k.join(' ')
    }

    /////////////////////////////////////////////////////////
    //                      EDIT MODE CODE
    /////////////////////////////////////////////////////////

    const toggleEditMode = (bool) => {
        if (bool) {
            setInEditMode(true)
            setSelectedCard(null)
            if (isEqual(editState, blankCardState)) {
                setCardStateUnedited(cloneDeep(cardState))
                setShowCEbtn(false)
            } else {
                setShowCEbtn(true)
            }
        } else {
            setInEditMode(false)
            setShowCEbtn(false)
            setShowRCbtn(false)
            setSelectedCard(null)
        }
    }

    // Button Events

    const apiRevealPerCard = async (card) => {
        //card = string || []
        const getShowStatus = (k) => {
            if (typeof card === 'object') {
                return card.includes(k) ? true : cardState[k].show
            }
            if (typeof card === 'string') {
                const flop = ['b1', 'b2', 'p1', 'p2']
                //return k === card ? true : cardState[k].show
                return (flop.includes(card) && flop.includes(k)) || k === card ? true : cardState[k].show
            }
            return cardState[k].show
        }
        const formattedData = () => {
            const data = []
            const keys = ['p1', 'b1', 'p2', 'b2', 'p3', 'b3']
            keys.forEach((k) => {
                if (cardState[k].suit && cardState[k].value) {
                    data.push({
                        suit: cardState[k].suit,
                        value: cardState[k].value,
                        owner: k.substring(0, 1).toUpperCase(),
                        show: getShowStatus(k),
                    })
                }
            })
            return data
        }

        try {
            console.log('formattedData()', formattedData())
            await apiPublishRevealCard(formattedData())
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
        }
    }

    const isFullCard = (obj) => {
        return obj.suit && obj.value ? true : false
    }
    const cardPress = (card) => {
        //In Edit Mode or Reveal Mode
        if (inEditMode) {
            //has third card in state or edit state, allow option to remove
            if ((card === 'p3' || card === 'b3') && (isFullCard(editState[card]) || isFullCard(cardState[card]))) {
                setShowRCbtn(true)
            } else {
                setShowRCbtn(false)
            }
            setSelectedCard(card)
        } else {
            if (revealStatus[card] === 'ready') {
                apiRevealPerCard(card)
            }
        }
    }

    const panelPress = (btn, suit = false) => {
        if (!selectedCard) return
        const obj = editState[selectedCard]
        const key = suit ? 'suit' : 'value'
        obj[key] = btn
        obj.suit = obj.value && !obj.suit ? 1 : obj.suit
        if (isFullCard(obj)) {
            setShowCEbtn(true)
        }
        rerender()
    }

    const confirmEditPress = () => {
        if (hasGameState && game.data.status === 'done') {
            cancelEditCards()
            console.log('game already ended, cannot edit cards')
            return
        }

        if (deviceData.doubleConfirmation) {
            setModalHeader(t('dealer.editCardsConfirmation'))
            setModalConfirm('edit')
            setModalOpen(true)
            return
        }

        triggerPublishEditCards()
    }

    // const removeNullValues = (obj) => {
    //     return Object.keys(obj).reduce((c, v, i) => {
    //         if (obj[v].suit && obj[v].value) {
    //             c[v] = obj[v]
    //         }
    //         return c
    //     }, {})
    // }

    const triggerPublishEditCards = async () => {
        const mergedState = {
            ...cardState,
            ...(editState.b1.suit && {
                b1: { suit: editState.b1.suit, value: editState.b1.value, show: cardState.b1.show },
            }),
            ...(editState.b2.suit && {
                b2: { suit: editState.b2.suit, value: editState.b2.value, show: cardState.b2.show },
            }),
            ...(editState.b3.suit && {
                b3: { suit: editState.b3.suit, value: editState.b3.value, show: cardState.b3.show },
            }),
            ...(editState.p1.suit && {
                p1: { suit: editState.p1.suit, value: editState.p1.value, show: cardState.p1.show },
            }),
            ...(editState.p2.suit && {
                p2: { suit: editState.p2.suit, value: editState.p2.value, show: cardState.p2.show },
            }),
            ...(editState.p3.suit && {
                p3: { suit: editState.p3.suit, value: editState.p3.value, show: cardState.p3.show },
            }),
        }

        const formattedData = () => {
            const data = []
            const keys = ['p1', 'b1', 'p2', 'b2', 'p3', 'b3']

            keys.forEach((k) => {
                if (mergedState[k].suit && mergedState[k].value) {
                    data.push({
                        suit: mergedState[k].suit,
                        value: mergedState[k].value,
                        owner: k.substring(0, 1).toUpperCase(),
                        show: mergedState[k].show,
                    })
                }
            })
            return data
        }

        try {
            await apiPublishEdit(formattedData())
            setEditState(cloneDeep(blankCardState))
            setCardState(cloneDeep(mergedState))
            toggleEditMode(false)
        } catch (e) {
            console.log('Failed to publish edit', e)
            setFinalizeError(true)
        }
    }

    const cancelEditCards = () => {
        toggleEditMode(false)
        setEditState(cloneDeep(blankCardState))
        setCardState(cardStateUnedited)
        setSelectedCard(null)
    }

    const removeCardPress = () => {
        if (selectedCard === 'p3' || selectedCard === 'b3') {
            editState[selectedCard].suit = null
            editState[selectedCard].value = null
            editState[selectedCard].show = false
            cardState[selectedCard].suit = null
            cardState[selectedCard].value = null
            cardState[selectedCard].show = false
            setShowRCbtn(false)
            setShowCEbtn(true)
        }
    }

    const isCardSelected = (card) => {
        return card === selectedCard ? s.cardSelected : ''
    }

    const isPanelBtnSelected = (btn, suit = false) => {
        const key = suit ? 'suit' : 'value'
        return selectedCard && btn === editState[selectedCard][key] ? s.cardsInputSelected : ''
    }

    const isMiniCardVisible = (card) => {
        //return true
        return inEditMode && editState[card].value && editState[card].suit ? '' : s.hidden
    }

    /////////////////////////////////////////////////////////
    //                    MENU BUTTON EVENTS
    /////////////////////////////////////////////////////////
    const menuXCloseBtn = () => {
        if (!inEditMode) {
            setShowMenu(showMenu ? false : true)
            return
        }
        toggleEditMode(false)
        setEditState(cloneDeep(blankCardState))
        setCardState(cardStateUnedited)
        setSelectedCard(null)
    }
    const editCardsPress = () => {
        if (inEditMode || get(game, 'data.status', null) === 'done') return
        toggleEditMode(true)
    }

    const restartBetTimerPress = async () => {
        try {
            const response = await fetch('http://' + deviceIp + '/baccarat/timer/restart')
            if (response.status === 200) {
                console.log('bet timer restarted')
                setBetTimer({ action: 'restart' })
            } else {
                console.log('Something went wrong:', response)
            }
        } catch (e) {
            console.log('restartBetTimerPress err e', e)
        }
    }

    const stopBetTimerPress = async (cb) => {
        try {
            const response = await fetch('http://' + deviceIp + '/baccarat/timer/stop')
            if (response.status === 200) {
                console.log('stopped bet timer')
                setBetTimer({ action: 'stop' })
            } else {
                console.log('Something went wrong:', response)
            }
        } catch (e) {
            console.log('stopBetTimerPress err e', e)
        }
    }

    const noMoreBetsPress = async () => {
        try {
            const response = await api_fetch(deviceIp + '/baccarat/betsoff')
            if (response.status === 200) {
                console.log('bets off successfully triggered')
            } else {
                console.log('Something went wrong:', response)
                console.log('failed to trigger bets off')
            }
        } catch (e) {
            console.log('noMoreBetsPress err e', e)
        }
    }

    const wipeTrendboardPress = () => {
        setModalHeader(t('dealer.wipeTrendboardConfirmation'))
        setModalDescript(t('dealer.wipeTrendboardPrompt'))
        setModalOpen(true)
        setModalConfirm('wipe')
    }
    const EODButtonPress = () => setEODViewOpen(true)

    const logOutPress = () => {
        setModalHeader(t('dealer.logOut'))
        setModalDescript(t('dealer.logOutPrompt'))
        setModalOpen(true)
        setModalConfirm('logout')
    }

    const voidGame = () => {
        setModalHeader(t('dealer.voidGameConfirmation'))
        setModalDescript(t('dealer.voidGamePrompt'))
        setModalOpen(true)
        setModalConfirm('void')
    }

    const voidLastGame = () => {
        setModalHeader(t('dealer.voidLastGameConfirmation'))
        setModalDescript(t('dealer.voidLastGamePrompt'))
        setModalOpen(true)
        setModalConfirm('voidLast')
    }

    const turnOffDevicePress = () => {
        setModalHeader(t('dealer.closeTable'))
        setModalDescript(t('dealer.closeTablePrompt'))
        setModalOpen(true)
        setModalConfirm('deviceOff')
    }

    const deviceOnModal = () => {
        setModalHeader(t('dealer.openTable'))
        setModalDescript(t('dealer.openTablePrompt'))
        setModalOpen(true)
        setModalConfirm('deviceOn')
    }

    const burnModal = () => {
        setModalHeader(t('dealer.burnCardsConfirmation'))
        setModalDescript('Game will resume after burn mode')
        setModalOpen(true)
        setModalConfirm('burnMode')
    }
    const modalConfirmPress = async (thingToConfirm) => {
        if (deviceData.doubleConfirmation && (thingToConfirm === 'void' || thingToConfirm === 'edit')) {
            try {
                const response = useSwipeAuth
                    ? await api
                          .post('authorize/card', {
                              json: {
                                  card: swipeCard.slice(1, swipeCard.length - 1),
                              },
                          })
                          .json()
                    : await api
                          .post('authorize', {
                              json: {
                                  account: pitbossAccount,
                                  password: pitbossPassword,
                              },
                          })
                          .json()
                if (response.roles.indexOf('pitboss') >= 0) {
                    switch (thingToConfirm) {
                        case 'void':
                            apiVoidGame(
                                () => {
                                    console.log('---game is voided--')
                                    setIsGameVoided(true)
                                    setModalOpen(false)
                                },
                                () => {
                                    console.log('failed to void game')
                                    setModalOpen(false)
                                }
                            )
                            break //todo double confirmation
                        case 'voidLast':
                            apiVoidLastGame(
                                () => {
                                    console.log('---last game is voided--')
                                    setIsGameVoided(true)
                                    setModalOpen(false)
                                },
                                () => {
                                    console.log('failed to void last game')
                                    setModalOpen(false)
                                }
                            )
                            break //todo double confirmation
                        case 'edit':
                            triggerPublishEditCards()
                            setModalOpen(false)
                            break
                        default:
                            console.log('default confirm press, no option found')
                    }
                } else {
                    console.log('staff member not authorized to double confirm')
                }
                setSwipeCard('')
                setPitbossAccount('')
                setPitbossPassword('')
            } catch (e) {
                console.warn('unabled to authorize card', e)
                setSwipeCard('')
                setPitbossAccount('')
                setPitbossPassword('')
            }

            return
        }

        switch (thingToConfirm) {
            case 'void':
                apiVoidGame(
                    () => {
                        console.log('---game is voided--')
                        setIsGameVoided(true)
                        setModalOpen(false)
                    },
                    () => {
                        console.log('failed to void game')
                        setModalOpen(false)
                    }
                )
                break //todo double confirmation
            case 'voidLast':
                apiVoidLastGame(
                    () => {
                        console.log('---last game is voided--')
                        setIsGameVoided(true)
                        setModalOpen(false)
                    },
                    () => {
                        console.log('failed to void last game')
                        setModalOpen(false)
                    }
                )
                break //todo double confirmation
            case 'logout':
                logoutDealer()
                setModalOpen(false)
                break
            case 'wipe':
                apiDeckChange(() => {
                    console.log('----triggered a wipe (deck change)-----')
                })
                setModalOpen(false)
                break
            case 'deviceOff':
                apiDeviceClose(
                    () => {
                        console.log('device turned off')
                        setModalOpen(false)
                    },
                    () => {
                        console.log('failed to turn off')
                    }
                )
                break
            case 'deviceOn':
                apiDeviceOpen(
                    () => {
                        console.log('device turned on')
                        apiDeckChange(() => {
                            console.log('--deck change auto triggered after tableOff---')
                            setModalOpen(false)
                        })
                    },
                    () => {
                        console.log('failed to turn on device')
                    }
                )
                break
            case 'burnMode':
                setModalOpen(false)
                break

            default:
                console.log('default confirm press, no option found')
        }
    }

    /////////////////////////////////////////////////////////
    //        INCOMING API DATA CHANGE HANDLING
    /////////////////////////////////////////////////////////

    const startOfNewHand = () => {
        console.log('SNH blank:', blankCardState)
        setEditState(cloneDeep(blankCardState))
        setCardState(cloneDeep(blankCardState))
        setCardStateUnedited({})
        setShowCEbtn(false)
        setShowRCbtn(false)
        setSelectedCard(null)
        setInEditMode(false)
        setRevealStatus({
            state: { b1: 'hidden', b2: 'hidden', b3: 'hidden', p1: 'hidden', p2: 'hidden', p3: 'hidden' },
        })
        setFinalizeError(null)
        setBankerPoints(0)
        setPlayerPoints(0)
        setBetTimer({ action: 'reset' })
    }

    const updateGameState = (game) => {
        if (!hasGameState) return
        if (game.data.status !== 'ongoing' && game.data.status !== 'done') return
        if (game.data.status === 'done') {
            toggleEditMode(false)
        }
        const ownerCount = { P: 1, B: 1 }
        const newState = {}
        Object.keys(game.data.cards).forEach((k) => {
            const card = game.data.cards[k]
            newState[card.owner.toLowerCase() + ownerCount[card.owner]] = {
                suit: card.suit,
                value: card.value,
                show: card.show,
            }
            ownerCount[card.owner] += 1
            //console.log(card.owner, card.value, card.suit)
        })
        console.log('updateGameState merge newState:', newState)
        setCardState({ ...cloneDeep(blankCardState), ...newState })
    }

    if (device.updated !== deviceUpdated) {
        console.log('TABLE UPDATED')
        setDeviceData(device.data)
        setDeviceUpdated(device.updated)
        console.log(device.data)
        return <LinearProgress />
    }

    if (deviceData.gameType === 'baccarat' && hasGameState && game.data.started !== gameStarted) {
        console.log('START OF NEW HAND')
        startOfNewHand()
        setGameStarted(game.data.started)

        return <LinearProgress />
    }
    //console.log('===================================================', game.updated, gameUpdated)

    if (
        deviceData.gameType === 'baccarat' &&
        hasGameState &&
        (game.updated !== gameUpdated || game.created !== gameCreated)
    ) {
        console.log('GAME UPDATED ')
        updateGameState(game)
        setGameUpdated(game.updated)
        setGameCreated(game.created)
        if (game.data.tally) {
            setPlayerPoints(game.data.tally.player)
            setBankerPoints(game.data.tally.banker)
        }
        if (game.data.cards) {
            setRevealStatus({
                cardReveal: deviceData.cardReveal,
                gameStatus: game.data.status,
                gameCards: game.data.cards,
            })
        }

        return <LinearProgress />
    }

    if (!device.data.open && (!modalOpen || modalConfirm !== 'deviceOn') && !loading) {
        console.log('::1')
        deviceOnModal()
    }
    if (device.data.open && modalOpen && modalConfirm === 'deviceOn' && !loading) {
        console.log('::2')
        setModalOpen(false)
    }

    if (game.data.burn && device.data.open && (!modalOpen || modalConfirm !== 'burnMode') && !loading) {
        console.log('::3')
        burnModal()
    }

    if (!game.data.burn && device.data.open && modalOpen && modalConfirm === 'burnMode' && !loading) {
        console.log('::4')
        setModalOpen(false)
    }

    const getSessionID = (seatID) => {
        return seatID ? sessions.filter((v) => v.index === Number(seatID).toString()).map((v) => v.data)[0] : null
    }

    const getBalance = (seatID) => {
        if (!dMods.has_seat_credits || !credits) return 0
        return (
            credits
                .filter((v) => v.index === Number(seatID).toString())
                .map((v) => v.data.balance)
                .slice(-1)[0] ?? 0
        )
    }

    const getMemberStatus = (seatID) => {
        const matchMembership = (sessionID) => {
            const mship = !memberships ? [] : memberships.filter((v) => v.data.sessions.includes(sessionID))
            return mship.length === 0 ? false : true
        }
        return matchMembership(getSessionID(seatID))
    }

    const getMemberData = (seatID) => {
        const matchMembership = (sessionID) => {
            const mship = !memberships ? [] : memberships.filter((v) => v.data.sessions.includes(sessionID))
            return mship.length === 0 ? false : mship[0].data
        }
        return matchMembership(getSessionID(seatID))
    }

    const reFocusCaptureField = () => {
        if (captureField.current) captureField.current.focus()
    }

    let showInputCapture = false
    if (getSessionID(selectedSeatID) && showSeatPlayersMenu && !showVoucherTopUp && !showCrowdPlayerScan)
        showInputCapture = true
    if (getSessionID(selectedSeatID) && showSeatPlayersMenu && !showCrowdPlayerScan && showVoucherTopUp)
        showInputCapture = true
    if (getSessionID(selectedSeatID) && showSeatPlayersMenu && !showVoucherTopUp && showCrowdPlayerScan)
        showInputCapture = true
    if (showCashTopUp || showChipTopUp || showChipsRefill) showInputCapture = false

    const trySetCapturedString = (str) => {
        if (!showInputCapture) return
        if (getSessionID(selectedSeatID) && showSeatPlayersMenu && !showVoucherTopUp && !showCrowdPlayerScan)
            setCapturedString({ membershipCapture: str })
        if (getSessionID(selectedSeatID) && showSeatPlayersMenu && !showCrowdPlayerScan && showVoucherTopUp)
            setCapturedString({ voucherCapture: str })
        if (getSessionID(selectedSeatID) && showSeatPlayersMenu && !showVoucherTopUp && showCrowdPlayerScan)
            setCapturedString({ crowdPlayerCapture: str })
    }

    return (
        <DUIContext.Provider
            value={{
                apiRevealPerCard,
                bets,
                betTimer,
                setBetTimer,
                bankerPoints,
                capturedString,
                callApiPublishCashdrop,
                callApiPublishChipfill,
                callApiPublishChipcredit,
                cardPress,
                cardState,
                cancelEditCards,
                chasetrend,
                confirmEditPress,
                credits,
                CTTGButtonTriggered,
                device,
                deviceData,
                deviceIp,
                deviceUpdated,
                displayTallyPoints,
                editCardsPress,
                editState,
                EODButtonPress,
                finalizeError,
                game,
                hasGameState,
                getBalance,
                getClassName,
                getMemberData,
                getMemberStatus,
                getSessionID,
                inEditMode,
                isCardSelected,
                isMiniCardVisible,
                isPanelBtnSelected,
                isVisible,
                logoutDealer,
                logOutPress,
                memberships,
                menuXCloseBtn,
                modalConfirm,
                modalConfirmPress,
                modalDescript,
                modalHeader,
                modalOpen,
                noMoreBetsPress,
                panelPress,
                pitbossAccount,
                pitbossPassword,
                playerPoints,
                removeCardPress,
                resetSeatPlayerSelection,
                restartBetTimerPress,
                revealStatus,
                s,
                selectedSeatID,
                sessions,
                setCapturedString,
                setCTTGButtonTriggered,
                setEODViewOpen,
                setModalOpen,
                setPitbossAccount,
                setPitbossPassword,
                setSelectedSeatID,
                setShowCashTopUp,
                setShowCashDrop,
                setShowTableFillCredit,
                setShowChipsRefill,
                setShowChipTopUp,
                setShowCrowdPlayerScan,
                setShowCTTGButton,
                //setShowMemberSwipe,
                setShowSeatPlayersMenu,
                setShowVoucherTopUp,
                setSwipeCard,
                setUseSwipeAuth,
                showConfirmEdit,
                showCTTGButton,
                showMenu,
                showRemoveCard,
                showSeatPlayersMenu,
                showCashTopUp,
                showChipTopUp,
                showVoucherTopUp,
                showCashDrop,
                showTableFillCredit,
                showChipsRefill,
                showCrowdPlayerScan,
                stopBetTimerPress,
                swipeCard,
                t,
                turnOffDevicePress,
                useSwipeAuth,
                voidGame,
                voidLastGame,
                isGameVoided,
                setIsGameVoided,
                wipeTrendboardPress,
                members,
                verifyMembership,
                seats,
                selectedSeat,
                setSelectedSeat,
                bindSeat,
                unbindSeat,
                initialDialogObj,
                dialogObj,
                setDialogObj,
                settings,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#1F1B1B',
                    overflow: 'hidden',
                    gap: 2,
                }}
            >
                {showInputCapture && (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            trySetCapturedString(get(e.currentTarget['inputCapture'], 'value', null))
                            e.currentTarget['inputCapture'].value = ''
                        }}
                        noValidate
                        autoComplete='off'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 10000,
                            ...(!dBug.show_swipe_capture_field && { opacity: 0, pointerEvents: 'none' }),
                        }}
                    >
                        <input
                            name='inputCapture'
                            ref={captureField}
                            onBlur={() => reFocusCaptureField()}
                            type='text'
                            autoFocus={true}
                            autoComplete='off'
                            style={{
                                opacity: 1,
                                pointerEvents: 'none',
                                height: '5vh',
                                width: '30vw',
                                backgroundColor: 'transparent',
                                color: 'white',
                            }}
                        />
                    </form>
                )}

                {showMenu && <DealerSideMenu time={time} />}

                {device.data.gameType === 'baccarat' &&
                    deviceData.shoe === 'automatic' &&
                    !showSeatPlayersMenu &&
                    !dBug.force_manual_mode && (
                        <div style={{ width: '100%', display: 'flex', position: 'relative' }}>
                            <DealerCards />
                            {inEditMode && <EditCardsPanel />}
                        </div>
                    )}

                {/* Modal Results */}
                <DealerModal />

                {!inEditMode && !showMenu && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: 'min(20%,250px)',
                            maxWidth: 'min(20%,250px)',
                            display: 'flex',
                            fontSize: '2vh',
                        }}
                    >
                        <div
                            variant='contained'
                            onClick={() => {
                                menuXCloseBtn()
                            }}
                            style={{
                                width: '100%',
                                height: '10vh',
                                zIndex: 100,
                                background: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                whiteSpace: 'nowrap',
                                color: 'black',
                            }}
                        >
                            {t('dealer.showMenu')}
                        </div>
                    </div>
                )}

                {(deviceData.shoe === 'manual' || dBug.force_manual_mode) && !showSeatPlayersMenu && (
                    <div style={{ width: '100%' }}>
                        <ManualGame publishBacRes={apiPublishResult} showMenu={showMenu} device={device} />
                    </div>
                )}

                {dBug.split_view_mode && !showSeatPlayersMenu && (
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ height: '70%' }}>
                            <ManualGame publishBacRes={apiPublishResult} showMenu={showMenu} device={device} />
                        </div>
                        <div style={{ height: '30%' }}>
                            <Simulator />
                        </div>
                    </div>
                )}

                {((device.data.gameType === 'baccarat' && showSeatPlayersMenu) ||
                    device.data.gameType === 'blackjack') && (
                    <div style={{ width: '100%', zIndex: 110 }}>
                        {/* <SeatPlayers /> */}
                        <SeatPlayersNewVer />
                    </div>
                )}

                {/* 
      {showMemberSwipe && <div onClick={() => { setShowMemberSwipe(false) }} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1001 }} >
        <div style={{ height: 'calc(100% - 50px)', width: 'calc(100% - 50px)', borderRadius: 20, objectFit: 'contain', overflow: 'hidden' }}>
          <MemberSwipe />
        </div>
      </div>} */}

                {/* TopUp by Cash */}
                {showCashTopUp && (
                    <div
                        onClick={() => {
                            setShowCashTopUp(false)
                        }}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1001,
                        }}
                    >
                        <div
                            style={{
                                height: 'calc(100% - 50px)',
                                width: 'calc(100% - 50px)',
                                borderRadius: 20,
                                objectFit: 'contain',
                                overflow: 'hidden',
                            }}
                        >
                            <CashTopUp />
                        </div>
                    </div>
                )}

                {/* TopUp by Chips */}
                {showChipTopUp && (
                    <div
                        onClick={() => {
                            setShowChipTopUp(false)
                        }}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1001,
                        }}
                    >
                        <div
                            style={{
                                height: 'calc(100% - 50px)',
                                width: 'calc(100% - 50px)',
                                borderRadius: 20,
                                objectFit: 'contain',
                                overflow: 'hidden',
                            }}
                        >
                            <ChipTopUp />
                        </div>
                    </div>
                )}

                {/* TopUp by Voucher */}
                {showVoucherTopUp && (
                    <div
                        onClick={() => {
                            setShowVoucherTopUp(false)
                        }}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1001,
                        }}
                    >
                        <VoucherTopUp />
                    </div>
                )}

                {/* Chips Refill Modal */}
                {dMods.show_chips_refill_btn && showChipsRefill && (
                    <div
                        onClick={() => setShowChipsRefill(false)}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1001,
                        }}
                    >
                        <ChipsRefill />
                    </div>
                )}

                {/* Crowd Player Scan Modal */}
                {dMods.show_top_up_crowd_btn && showCrowdPlayerScan && (
                    <div
                        onClick={() => setShowChipsRefill(false)}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1001,
                        }}
                    >
                        <CrowdPlayerScan />
                    </div>
                )}

                {hasGameState && dMods.show_end_game_btn && !inEditMode && game.data.status === 'ongoing' && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            height: 'fit-content',
                            width: 'min(20%,250px)',
                            maxWidth: 'min(20%,250px)',
                        }}
                    >
                        <ManualEndGameButton deviceIp={deviceIp} />
                    </div>
                )}

                {hasGameState &&
                    (dMods.has_chase_trend_game || dMods.has_chase_trend_game_chipless) &&
                    chasetrend &&
                    game.data.status === 'done' && (
                        <>
                            <ChaseTheTrendGame />
                        </>
                    )}

                {EODViewOpen && <DealerEOD />}

                {showCashDrop && (
                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1001,
                        }}
                    >
                        <CashDrop />
                    </div>
                )}

                {showTableFillCredit && (
                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1001,
                        }}
                    >
                        <TableFillCredit />
                    </div>
                )}

                {dialogObj.show && !dialogObj.requirePitBoss && <Dialog />}

                {dialogObj.show && dialogObj.requirePitBoss && <PitBossDialog />}
            </div>
        </DUIContext.Provider>
    )
}

export default PrePreDealer
