import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

const LoadingMessage = ({ message }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "max-content", color: "white", lineHeight: "30px" }}>
        {message}
        <LinearProgress />
      </div>
    </div>
  );
};

export default LoadingMessage;
