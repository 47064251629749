import React, { useReducer } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

import { keys } from 'lodash'

const Configurations = ({ setShowConfigurations }) => {
    const [config, setConfig] = useReducer((s, o) => {
        return { ...s, ...o }
    }, JSON.parse(window.localStorage.getItem('config')))

    const saveConfig = () => {
        window.localStorage.setItem('config', JSON.stringify(config))
        window.localStorage.setItem('immutedConfig', true)
        window.location.reload()
    }

    const clear = () => {
        window.localStorage.clear()
        window.location.reload()
    }

    console.log(config)


    return <div onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} style={{ margin: '20px 50px', height: '100vh', width: '100vw', overflow: 'scroll' }}>

        <div style={{ display: 'flex', justifyContent: 'center', margin: 20, gap: 20 }}>
            <Button style={{ width: '100px' }} variant="contained" color="primary" onClick={() => saveConfig()}>Save</Button>
            <Button style={{ width: '100px' }} variant="contained" color="primary" onClick={() => clear()}>Clear</Button>
            <Button style={{ width: '100px' }} variant="contained" color="primary" onClick={() => setShowConfigurations(false)}>Close</Button>
        </div>

        <div style={{ display: 'flex', flex: '1', flexDirection: 'column', gap: 20, justifyContent: 'center', alignItems: 'center', paddingBottom: 100 }}>
            {keys(config).map((v, i) => <div key={i} style={{ width: 400 }}>
                {typeof config[v] === 'boolean' &&
                    <FormControlLabel
                        control={<Switch
                            checked={config[v]}
                            onChange={(e) => setConfig({ [v]: e.target.checked })}
                        />}
                        label={v + ": " + (config[v] ? "true" : "false")}
                    />
                }
                {typeof config[v] !== 'boolean' && typeof config[v] !== 'object' &&
                    <TextField
                        style={{ width: '100%' }}
                        margin="dense"
                        label={v}
                        type="text"
                        variant="outlined"
                        onChange={(e) => setConfig({ [v]: e.target.value })}
                        value={config[v]}
                    />
                }
                {typeof config[v] === 'object' && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 20 }}>
                    <div>{v}:</div>
                    {keys(config[v]).map((v1, i) => <div key={i} style={{ marginLeft: 50 }}>
                        {typeof config[v][v1] === 'boolean' &&
                            <FormControlLabel
                                value=""
                                control={<Switch
                                    checked={config[v][v1]}
                                    onChange={(e) => setConfig({ [v]: { ...config[v], [v1]: e.target.checked } })}
                                />}
                                label={v1 + ": " + (config[v][v1] ? "true" : "false")}
                            />
                        }
                        {typeof config[v][v1] !== 'boolean' &&
                            <TextField
                                style={{ width: '100%' }}
                                margin="dense"
                                label={v + '.' + v1}
                                type="text"
                                variant="outlined"
                                onChange={(e) => setConfig({ [v]: { ...config[v], [v1]: e.target.value } })}
                                value={config[v][v1]}
                            />
                        }
                    </div>)}
                </div>}



            </div>)}
        </div>

    </div>

}
export default Configurations