import React, { memo, forwardRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { Person, ShowChart, Home, Visibility, SentimentSatisfied, Casino, BarChart } from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/styles'

import { useSubscribe } from '../api'
import { ReactComponent as SlotIcon } from '../assets/images/slot.svg'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const CustomTooltipWrapper = ({ condition, wrapper, children }) => (condition ? children : wrapper(children))

export default memo(({ open, setOpen }) => {
    const [settings] = useSubscribe('settings')

    const activeStyle = {
        background: 'rgb(88, 88, 88)',
    }

    const NavLinkRef = forwardRef((props, ref) => <NavLink {...props} ref={ref} />)

    // handle propagation
    // from the click event on the hamburger triggering the click on the chevron
    // not good but idk wtf
    const [openDoneStarted, setOpenDoneStarted] = useState(false)
    const [openDone, setOpenDone] = useState(false)

    if (open && !openDone && !openDoneStarted) {
        setOpenDoneStarted(true)
        setTimeout(() => {
            if (open) {
                setOpenDone(true)
            }
        }, 400)
    }

    if (!open && openDone) {
        setOpenDoneStarted(false)
        setOpenDone(false)
    }

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (openDone) {
                    setOpen(false)
                }
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box>
                    <DrawerHeader>
                        <Typography sx={{ ml: 2, fontWeight: 'bold', textTransform: 'uppercase' }}>X Table</Typography>
                        <IconButton
                            onClick={() => {
                                if (openDone) {
                                    setOpen(false)
                                }
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>

                    <Divider />

                    <List sx={{ padding: 0 }}>
                        {settings && settings.data.xtable.sections.includes('overview') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Overview' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/overview',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Home />
                                        </ListItemIcon>
                                        <ListItemText primary={'Overview'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {settings && settings.data.xtable.sections.includes('statistics') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Statistics' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/statistics',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <BarChart />
                                        </ListItemIcon>
                                        <ListItemText primary={'Statistics'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {settings && settings.data.xtable.sections.includes('games') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Games' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/games',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Casino />
                                        </ListItemIcon>
                                        <ListItemText primary={'Games'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {settings && settings.data.xtable.sections.includes('trends') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Trends' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/trends',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <ShowChart />
                                        </ListItemIcon>
                                        <ListItemText primary={'Trends'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {settings && settings.data.xtable.sections.includes('view') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='View' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/view',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Visibility />
                                        </ListItemIcon>
                                        <ListItemText primary={'View'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {settings && settings.data.xtable.sections.includes('players') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Players' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/players',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <SentimentSatisfied />
                                        </ListItemIcon>
                                        <ListItemText primary={'Players'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {settings && settings.data.xtable.sections.includes('slots') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Slots' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/slots',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <SlotIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Slots'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {settings && settings.data.xtable.sections.includes('dealers') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Dealers' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/dealers',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText primary={'Dealers'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {settings && settings.data.xtable.sections.includes('overview') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Settings' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/settings',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Settings'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}
                    </List>
                </Box>

                <Box>
                    <CustomTooltipWrapper
                        condition={open}
                        wrapper={(children) => (
                            <Tooltip title='Exit App' placement='right'>
                                {children}
                            </Tooltip>
                        )}
                    >
                        <ListItemButton
                            component={NavLinkRef}
                            onClick={() => setOpen(false)}
                            {...{
                                disableTouchRipple: true,
                                to: '/dashboard',
                            }}
                        >
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Exit App'} />
                        </ListItemButton>
                    </CustomTooltipWrapper>
                </Box>
            </Box>
        </ClickAwayListener>
    )
})
