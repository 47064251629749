import React, { useState, useMemo } from 'react'
import _, { get, find } from 'lodash'
import DataTable from 'react-data-table-component'
import { ChevronDown } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { LinearProgress } from '@mui/material'

import { useSubscribe } from '../api'
import { defaultValues as defaultDeviceSettings } from '../xtrend/devices/defaultValues'
import DeployPanel from './DeployPanel'
import DevicesNotAllOnline from './DevicesNotAllOnline'
import GeneratePanel from './GeneratePanel'
import SelectVersionPanel from './SelectVersionPanel'
import UploadLicensePanel from './UploadLicensePanel'
import UploadSoftwarePanel from './UploadSoftwarePanel'

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`
const OuterContainer = styled.div`
    position: relative;
    background-color: #222;
    min-height: calc(100vh - 64px);
    margin-left: 54px;
`
const Container = styled.div`
    background-color: #222;
    ${tw`flex flex-row text-white justify-center items-center h-full w-full overflow-hidden p-5 py-8 box-border`}
`
const ListContainer = styled.div`
    background-color: #222;
    ${tw`flex flex-col text-white justify-center items-center h-full w-full overflow-hidden px-5 box-border`}
`
const TopBar = tw.div`flex text-white justify-start items-center h-[140px] w-full box-border gap-10`
const LicenseRenewalPanel = styled(FlexCenter)`
    border: 1px solid #666;
    border-radius: 5px;
    height: 100px;
    padding: 10px 20px;
    flex-direction: column;
    gap: 10px;
`
const Heading = styled(FlexCenter)`
    justify-content: flex-start;
    width: 100%;
    ${(props) =>
        props.color &&
        `
        color:` +
            props.color +
            `
    `}
`
const Buttons = styled(FlexCenter)`
    gap: 20px;
`
const Button = styled(FlexCenter)`
    border-radius: 5px;
    padding: 10px;
    color: black;
    background-color: ${(props) => props.color};
    white-space: nowrap;
    font-weight: bold;
    cursor: pointer;
    ${(props) =>
        !props.active &&
        `
        opacity:0.5;
        pointer-events:none;
    `}
    user-select:none;
`

const DataTableContainer = styled.div`
    user-select: none;
    ${tw`flex h-full w-full overflow-hidden border border-solid border-gray-500 rounded`}
    > :first-child {
        ${tw`h-full max-h-full`}
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #333;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #888;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10;
            background-color: #333;
        }

        > :first-child {
            ${tw`h-full`}
        }
    }
    .rdt_TableHeadRow {
        ${tw`text-lg`}
    }
    .rdt_TableHeadRow > .rdt_TableCol {
        ${tw`border-0 border-b border-solid border-gray-500`}
    }
    .rdt_Table,
    .rdt_TableRow,
    .rdt_TableHeadRow,
    .rdt_Table > :first-child {
        background-color: #333;
    }
    .rdt_TableRow {
        border-bottom: 1px solid #444;
        :hover {
            background: #444;
            cursor: pointer;
        }
    }

    .rdt_TableRow:nth-child(even) {
        background-color: #393939;
        :hover {
            background: #444;
            cursor: pointer;
        }
    }
`
const TCell = styled.div`
    display: flex;
    gap: 20px;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
`

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
}

/* const Checkbox = styled.input`
    height:20px;
    width:20px;
    border-radius:5px;
` */

const OnlineOffline = styled.div`
    color: ${(props) => (props['data-status'] === 'online' ? 'limegreen' : 'red')};
`

const GameDevicesOnline = styled(FlexCenter)`
    border: 1px solid #666;
    border-radius: 5px;
    height: 100px;
    padding: 10px 20px;
    flex-direction: column;
    gap: 10px;
`

const DeviceCountContainer = styled(FlexCenter)`
    font-size: 35px;
    height: 35px;
`
const TotalDevices = styled(FlexCenter)``
const OnlineCount = styled(FlexCenter)`
    color: ${(props) => (props.status ? 'white' : 'red')};
`

const Licenses = () => {
    console.time('Data Fetch')
    return <LoadWebSocketData />
}

const LoadWebSocketData = () => {
    const history = useNavigate()
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [templates, templatesSocket] = useSubscribe('templates/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [versions, versionsSocket] = useSubscribe('versions/*')

    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        templatesSocket &&
        templatesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        versionsSocket &&
        versionsSocket.readyState === WebSocket.OPEN

    if (!zones || !pits || !devices || !tables || !status || !versions || !templates || !active) {
        return <LinearProgress />
    }

    const reformatted = templates.map((v) => ({ ...v.data, _id: v.index, updated: v.updated, created: v.created }))

    const filteredDevices = devices.filter((v) => v.data.type === 'game')

    console.timeEnd('Data Fetch')
    return (
        <DevicesList
            {...{ zones, pits, tables, devices: filteredDevices, templates: reformatted, status, history, versions }}
        />
    )
}

export const DevicesList = ({ zones, pits, tables, devices, templates, status, history, versions }) => {
    console.log('status', status)

    const [searchInput] = useState('')
    console.time('Data Composition')

    const sortFunc = (a, b, type) => {
        if (type === 'zones' && a.data.name.toLowerCase() === 'unassigned') return 1
        var nameA = a.data.name.toUpperCase() // ignore upper and lowercase
        var nameB = b.data.name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        // names must be equal
        return 0
    }
    //sort containers for select menus
    zones.sort((a, b) => sortFunc(a, b, 'zones'))
    tables.sort((a, b) => sortFunc(a, b, 'tables'))
    pits.sort((a, b) => sortFunc(a, b, 'pits'))
    devices.sort((a, b) => sortFunc(a, b, 'devices'))

    //get data lists for select menus
    const gameTypes = useMemo(
        () =>
            _.chain(devices.map((v) => v.data.gameType || null))
                .compact()
                .uniq()
                .value()
                .sort((a, b) => a - b),
        [devices]
    )
    /* const minbets = useMemo(() => _.chain(devices.map(v => _.toNumber(v.data.minbet) || null)).compact().uniq().value().sort((a, b) => a - b), [devices]) */
    /* const maxbets = useMemo(() => _.chain(devices.map(v => _.toNumber(v.data.maxbet) || null)).compact().uniq().value().sort((a, b) => a - b), [devices]) */
    /* const layouts = useMemo(() => _.chain(devices.map(v => v.data.layout || null)).compact().uniq().value().sort(), [devices]) */
    /* const deviceTemplates = useMemo(() => _.chain(templates.map(v => v.type === 'device' ? v.name : null)).compact().concat(['Default Template']).uniq().value().sort(), [templates]) */
    const deviceTypes = useMemo(
        () =>
            _.chain(devices.map((v) => v.data.type || null))
                .compact()
                .uniq()
                .value()
                .sort(),
        [devices]
    )
    /* const healthStates = ['healthy', 'unhealthy'] */
    console.log('templates', templates)
    console.log('devices', devices)
    console.log('deviceTypes', deviceTypes)
    console.log('gameTypes', gameTypes)

    //state tracking for select filters
    const [zoneFilter] = useState('')
    const [pitFilter] = useState('')
    const [tableFilter] = useState('')
    const [gameTypeFilter] = useState('')
    const [healthFilter] = useState('')
    const [deviceTypeFilter] = useState('')

    const hasDeviceFilterApplied =
        zoneFilter !== '' ||
        pitFilter !== '' ||
        tableFilter !== '' ||
        gameTypeFilter !== '' ||
        healthFilter !== '' ||
        deviceTypeFilter !== ''

    // find which devices are using templates, ie match device settings with templates
    const matchDevicesToTemplates = (devices, settings) => {
        const tSettings = _.cloneDeep(settings)
        delete tSettings.ip
        delete tSettings.containerIndex
        delete tSettings.containerType
        delete tSettings.name
        delete tSettings.table_num
        delete tSettings.port
        delete tSettings.maxbet
        delete tSettings.minbet
        delete tSettings.tiemin
        delete tSettings.tiemax
        delete tSettings.pairmin
        delete tSettings.pairmax
        delete tSettings.naturalmin
        delete tSettings.naturalmax
        delete tSettings.lucky6min
        delete tSettings.lucky6max

        return devices
            .filter((v) => v.data.type === 'game')
            .filter((v) => {
                const vSettings = _.cloneDeep(v.data)
                delete vSettings.ip
                delete vSettings.containerIndex
                delete vSettings.containerType
                delete vSettings.name
                delete vSettings.table_num
                delete vSettings.port
                delete vSettings.maxbet
                delete vSettings.minbet
                delete vSettings.tiemin
                delete vSettings.tiemax
                delete vSettings.pairmin
                delete vSettings.pairmax
                delete vSettings.naturalmin
                delete vSettings.naturalmax
                delete vSettings.lucky6min
                delete vSettings.lucky6max
                return _.isEqual(vSettings, tSettings)
            })
            .map(
                (v) =>
                    //({ name: v.data.name, index: v.index, data: v.data })
                    v.index
            )
    }
    const matchedDeviceToTemplates = useMemo(() => {
        const defaultDeviceTemplate = {
            _id: 0,
            type: 'device',
            name: 'Default Template',
            created: 0,
            updated: 0,
            settings: defaultDeviceSettings,
            matchedDeviceIndexes: matchDevicesToTemplates(devices, defaultDeviceSettings),
        }
        return !templates
            ? []
            : [
                  defaultDeviceTemplate,
                  ...templates
                      .filter((v) => v.type === 'device')
                      .map((v) => ({ ...v, matchedDeviceIndexes: matchDevicesToTemplates(devices, v.settings) })),
              ]
    }, [templates, devices])
    console.log('matchedDeviceToTemplates', matchedDeviceToTemplates)

    //compose devices data obj for big data table
    const devicesData = devices
        .map((v) => {
            //extend device data obj with data from status/zones/pits etc
            const deviceContainer = get(v, 'data.containerIndex', null)
            const tableObj = get(
                tables.filter((v1) => v1.index === deviceContainer),
                '0',
                null
            )
            const tableContainer = get(tableObj, 'data.containerIndex', null)
            const pitObj = get(
                pits.filter((v1) => v1.index === deviceContainer || v1.index === tableContainer),
                '0',
                null
            )
            const pitContainer = get(pitObj, 'data.containerIndex', null)
            const zoneObj = get(
                zones.filter(
                    (v1) => v1.index === deviceContainer || v1.index === tableContainer || v1.index === pitContainer
                ),
                '0',
                null
            )
            const statusEntry = find(status, (v2) => v2.index === v.index)
            const newObj = {
                ...v,
                data: {
                    ...v.data,
                    licenseExpiry: statusEntry?.data.licenseExpiry,
                    gameVersion: statusEntry?.data.gameVersion,
                    online: statusEntry?.data.game && statusEntry?.data.trendboard ? 'online' : 'offline',
                },
                table: tableObj,
                pit: pitObj,
                zone: zoneObj,
            }

            return newObj
        })
        .filter((v) => {
            if (!hasDeviceFilterApplied && searchInput === '') return true

            //if text search, ignore filters
            if (searchInput !== '') {
                const deviceName = get(v, 'data.name', '').toLowerCase().trim()
                const IP = get(v, 'data.ip', '').toLowerCase().trim()
                const search = searchInput.toLowerCase().trim()
                return deviceName.slice(0, search.length) === search || IP.slice(0, search.length) === search
                    ? true
                    : false
            }

            let match = true
            if (match && zoneFilter !== '')
                match = get(v, 'zone.data.name', '').toLowerCase() === zoneFilter.toLowerCase() ? true : false
            if (match && pitFilter !== '')
                match = get(v, 'pit.data.name', '').toLowerCase() === pitFilter.toLowerCase() ? true : false
            if (match && tableFilter !== '')
                match = get(v, 'table.data.name', '').toLowerCase() === tableFilter.toLowerCase() ? true : false
            if (match && gameTypeFilter !== '')
                match = get(v, 'data.gameType', '').toLowerCase() === gameTypeFilter.toLowerCase() ? true : false
            if (match && healthFilter !== '') match = get(v, 'health', '') === healthFilter.toLowerCase() ? true : false
            if (match && deviceTypeFilter !== '')
                match = get(v, 'data.type', '').toLowerCase() === deviceTypeFilter.toLowerCase() ? true : false
            return match
        })
    console.log('devicesData', devicesData)

    console.timeEnd('Options Composition')

    const [selectedDevices, setSelectedDevices] = useState([])

    //remove multi device select
    /* const isAllDevicesSelected = _.isEqual(devicesData.map(v => v.index).sort(), selectedDevices.sort()) ? true : false */
    /* const toggleSelectAllDevices = () => {
        if (isAllDevicesSelected) {
            setSelectedDevices([])
        } else {
            setSelectedDevices(devicesData.map(v => v.index))
        }
    } */

    const toggleSelectDevice = (index) => {
        if (selectedDevices.includes(index)) {
            setSelectedDevices((v) => v.filter((i) => i !== index))
        } else {
            // remove multi device select
            /* setSelectedDevices(v => [...v, index]) */
            setSelectedDevices((v) => [index])
        }
    }
    const [showPanel, setShowPanel] = useState(null) // null, Deploy, SelectVersion, Generate, UploadLicense, UploadSoftware

    const [selectedVersion, setSelectedVersion] = useState('')
    versions.some((v) => {
        if (v.data.selected && selectedVersion?.id !== v.data.ID) {
            setSelectedVersion({ id: v.data.ID, tag: v.data.tag })
            return true
        }
        return null
    })

    //remove highlight for selected device since all is always selected?
    /* const conditionalRowStyles = [
        {
            when: row => selectedDevices.includes(row.index),
            style: {
                backgroundColor: 'teal',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }
    ] */

    console.log('selectedDevices', selectedDevices)

    let onlineDevicesCount = 0
    devicesData.forEach((v) => {
        if (v.data.online === 'online') onlineDevicesCount += 1
    })

    const devicesAreAllOnline = onlineDevicesCount === devicesData.length
    /* console.log('onlineDevicesCount', onlineDevicesCount) */
    /* console.log('devicesAreAllOnline', devicesAreAllOnline) */
    console.timeEnd('Data Composition')

    return (
        <OuterContainer>
            {console.time('Render')}
            {showPanel === 'SelectVersion' && (
                <SelectVersionPanel
                    setShowPanel={setShowPanel}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    versions={versions}
                />
            )}
            {showPanel === 'Deploy' && (
                <DeployPanel
                    setShowPanel={setShowPanel}
                    devicesData={devicesData}
                    selectedDevices={selectedDevices}
                    selectedVersion={selectedVersion}
                />
            )}
            {showPanel === 'Generate' && <GeneratePanel setShowPanel={setShowPanel} />}
            {showPanel === 'UploadLicense' && <UploadLicensePanel setShowPanel={setShowPanel} />}
            {showPanel === 'UploadSoftware' && <UploadSoftwarePanel setShowPanel={setShowPanel} />}
            {showPanel === 'DevicesNotAllOnline' && <DevicesNotAllOnline setShowPanel={setShowPanel} />}

            <Container>
                {/* <SelectionContainer>
                <SearchBar>
                    <SearchIcon />
                    <SearchInput data-testid="devices-search-input" value={searchInput} onChange={e => setSearchInput(e.target.value)} placeholder={"Device Name Or IP"} />
                </SearchBar>
                <SelectTitle>{"Zone"}</SelectTitle>
                <SelectMenu data-testid="devices-zone-select" onChange={e => setZoneFilter(e.target.value)} value={zoneFilter} optionsLength={zoneOptions.length}>
                    <MenuOption value={''}>{"All"}</MenuOption>
                    {zoneOptions.map((v, i) => <MenuOption key={i} value={v} >{v}</MenuOption>)}
                </SelectMenu>
                <SelectTitle>{"Pit"}</SelectTitle>
                <SelectMenu data-testid="devices-pit-select" onChange={e => setPitFilter(e.target.value)} value={pitFilter} optionsLength={pitOptions.length}>
                    <MenuOption value={''}>{"All"}</MenuOption>
                    {pitOptions.map((v, i) => <MenuOption key={i} value={v} >{v}</MenuOption>)}
                </SelectMenu>
                <SelectTitle>{"Table"}</SelectTitle>
                <SelectMenu data-testid="devices-table-select" onChange={e => setTableFilter(e.target.value)} value={tableFilter} optionsLength={tableOptions.length}>
                    <MenuOption value={''}>{"All"}</MenuOption>
                    {tableOptions.map((v, i) => <MenuOption key={i} value={v} >{v}</MenuOption>)}
                </SelectMenu>
                <SelectTitle>{"Game Type"}</SelectTitle>
                <SelectMenu data-testid="devices-game-select" onChange={e => setGameTypeFilter(e.target.value)} value={gameTypeFilter} optionsLength={gameTypeOptions.length}>
                    <MenuOption value={''}>{"All"}</MenuOption>
                    {gameTypeOptions.map((v, i) => <MenuOption key={i} value={v} >{v}</MenuOption>)}
                </SelectMenu>
                <SelectTitle>{"Device Type"}</SelectTitle>
                <SelectMenu data-testid="devices-type-select" onChange={e => setDeviceTypeFilter(e.target.value)} value={deviceTypeFilter} optionsLength={deviceTypeOptions.length}>
                    <MenuOption value={''}>{"All"}</MenuOption>
                    {deviceTypeOptions.map((v, i) => <MenuOption key={i} value={v} >{v}</MenuOption>)}
                </SelectMenu>
                <FlexCenter>
                    <ResetBtn data-testid="devices-reset" onClick={resetFilters}>Reset</ResetBtn>
                </FlexCenter>
            </SelectionContainer> */}

                <ListContainer>
                    <TopBar>
                        <GameDevicesOnline>
                            <Heading>Game Devices Online</Heading>
                            <DeviceCountContainer>
                                <OnlineCount status={devicesAreAllOnline}>{onlineDevicesCount}</OnlineCount>
                                <pre>/</pre>
                                <TotalDevices>{devicesData.length}</TotalDevices>
                            </DeviceCountContainer>
                        </GameDevicesOnline>
                        <LicenseRenewalPanel>
                            <Heading>License Renewal File</Heading>
                            <Buttons>
                                <Button
                                    color={'skyblue'}
                                    onClick={() =>
                                        devicesAreAllOnline
                                            ? setShowPanel('Generate')
                                            : setShowPanel('DevicesNotAllOnline')
                                    }
                                    active
                                >
                                    GENERATE
                                </Button>
                                <Button
                                    color={'skyblue'}
                                    onClick={() =>
                                        devicesAreAllOnline
                                            ? setShowPanel('UploadLicense')
                                            : setShowPanel('DevicesNotAllOnline')
                                    }
                                    active
                                >
                                    UPLOAD
                                </Button>
                            </Buttons>
                        </LicenseRenewalPanel>
                        {/* <SoftwareUpdatePanel>
                        <Half>
                            <Heading>Software Update File</Heading>
                            <Buttons>
                                <Button
                                    color={'lightgreen'}
                                    onClick={() => devicesAreAllOnline ? setShowPanel('UploadSoftware') : setShowPanel('DevicesNotAllOnline')}
                                    active>UPLOAD</Button>
                                <Button
                                    color={'lightgreen'}
                                    onClick={() => devicesAreAllOnline ? setShowPanel('SelectVersion') : setShowPanel('DevicesNotAllOnline')}
                                    active>SELECT VERSION</Button>
                            </Buttons>
                        </Half>
                        <Half>
                            <Heading color={'red'}><Warning />Version to Deploy</Heading>
                            <Buttons>
                                <V2Deploy>{selectedVersion.tag}</V2Deploy>
                                <Button
                                    color={'lightgreen'}
                                    onClick={() => devicesAreAllOnline ? setShowPanel('Deploy') : setShowPanel('DevicesNotAllOnline')}
                                    // active={selectedDevices.length > 0}
                                    active={true}
                                >DEPLOY</Button>
                            </Buttons>

                        </Half>
                    </SoftwareUpdatePanel> */}
                    </TopBar>
                    <DataTableContainer data-testid='data-table'>
                        <DataTable
                            data={devicesData}
                            columns={[
                                // {
                                //     // remove multi device select
                                //     // name: <CheckBox onChange={() => toggleSelectAllDevices()} checked={isAllDevicesSelected} />,
                                //     selector: v => get(v, 'health', ''),
                                //     sortable: false,
                                //     cell: v => <TCell>
                                //         <CheckBox onChange={() => toggleSelectDevice(v.index)} checked={selectedDevices.includes(v.index)} />
                                //     </TCell>,
                                //     width: '50px'
                                // },
                                {
                                    name: 'Device Name',
                                    selector: (v) => get(v, 'data.name', '-'),
                                    sortable: true,
                                    cell: (v) => (
                                        <TCell
                                            onClick={() => toggleSelectDevice(v.index)}
                                            title={get(v, 'data.name', '-')}
                                        >
                                            {get(v, 'data.name', '-')}
                                        </TCell>
                                    ),
                                },
                                {
                                    name: 'Device Type',
                                    selector: (v) => get(v, 'data.type', '-'),
                                    sortable: true,
                                    cell: (v) => (
                                        <TCell
                                            onClick={() => toggleSelectDevice(v.index)}
                                            title={get(v, 'data.type', '-')}
                                        >
                                            {get(v, 'data.type', '-')}
                                        </TCell>
                                    ),
                                },
                                {
                                    name: 'IP',
                                    selector: (v) => get(v, 'data.ip', '-'),
                                    sortable: true,
                                    cell: (v) => (
                                        <TCell
                                            onClick={() => toggleSelectDevice(v.index)}
                                            title={get(v, 'data.ip', '-')}
                                        >
                                            {get(v, 'data.ip', '') !== '' ? get(v, 'data.ip') : '-'}
                                        </TCell>
                                    ),
                                },
                                {
                                    name: 'Zone',
                                    selector: (v) => get(v, 'zone.data.name', '-'),
                                    sortable: true,
                                    cell: (v) => (
                                        <TCell
                                            onClick={() => toggleSelectDevice(v.index)}
                                            title={get(v, 'zone.data.name', '-')}
                                        >
                                            {get(v, 'zone.data.name', '-')}
                                        </TCell>
                                    ),
                                },
                                {
                                    name: 'Pit',
                                    selector: (v) => get(v, 'pit.data.name', '-'),
                                    sortable: true,
                                    cell: (v) => (
                                        <TCell
                                            onClick={() => toggleSelectDevice(v.index)}
                                            title={get(v, 'pit.data.name', '-')}
                                        >
                                            {get(v, 'pit.data.name', '-')}
                                        </TCell>
                                    ),
                                },
                                {
                                    name: 'Table',
                                    selector: (v) => get(v, 'table.data.name', '-'),
                                    sortable: true,
                                    cell: (v) => (
                                        <TCell
                                            onClick={() => toggleSelectDevice(v.index)}
                                            title={get(v, 'table.data.name', '-')}
                                        >
                                            {get(v, 'table.data.name', '-')}
                                        </TCell>
                                    ),
                                },
                                // {
                                //     name: "License Status",
                                //     selector: v => "",
                                //     sortable: true,
                                //     cell: v => <TCell onClick={() => toggleSelectDevice(v.index)} title={"active"}>{"active"}</TCell>
                                // },
                                {
                                    name: 'Device Status',
                                    selector: (v) => get(v, 'data.online', '-'),
                                    sortable: true,
                                    cell: (v) => (
                                        <TCell
                                            onClick={() => toggleSelectDevice(v.index)}
                                            title={get(v, 'data.online', '-')}
                                        >
                                            <OnlineOffline data-status={get(v, 'data.online', '-')}>
                                                {get(v, 'data.online', '-')}
                                            </OnlineOffline>
                                        </TCell>
                                    ),
                                },
                                {
                                    name: 'License Expiration',
                                    selector: (v) => get(v, 'data.licenseExpiry', '-'),
                                    sortable: true,
                                    cell: (v) => (
                                        <TCell
                                            onClick={() => toggleSelectDevice(v.index)}
                                            title={get(v, 'data.licenseExpiry', '-')}
                                        >
                                            {get(v, 'data.licenseExpiry', '-')}
                                        </TCell>
                                    ),
                                },
                                // {
                                //     name: "Current Version",
                                //     selector: v => get(v, 'data.gameVersion', '-'),
                                //     sortable: true,
                                //     cell: v => <TCell onClick={() => toggleSelectDevice(v.index)} title={get(v, 'data.gameVersion', '-')}>{get(v, 'data.gameVersion', '-')}</TCell>
                                // },
                                // {
                                //     name: "Previous Version",
                                //     selector: v => "",
                                //     sortable: true,
                                //     cell: v => <TCell onClick={() => toggleSelectDevice(v.index)} title={"1.1.1"}>{"1.1.1"}</TCell>
                                // }
                            ]}
                            onRowClicked={(v) => toggleSelectDevice(v.index)}
                            customStyles={customStyles}
                            // conditionalRowStyles={conditionalRowStyles}
                            theme='dark'
                            sortIcon={<ChevronDown />}
                            noHeader
                            fixedHeader
                            fixedHeaderScrollHeight='500px'
                        />
                    </DataTableContainer>
                </ListContainer>
            </Container>
            {console.timeEnd('Render')}
        </OuterContainer>
    )
}

export default Licenses
