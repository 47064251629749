import { useState } from 'react'
import ky from 'ky'
import { ChevronDown, XCircle, AlertCircle } from 'react-feather'
import styled from 'styled-components'

import { pivotIP } from '../config'

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`
const Container = styled(FlexCenter)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`
const Inner = styled(FlexCenter)`
    position: relative;
    width: 100%;
    height: 100%;
`
const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.8;
`
const Content = styled(FlexCenter)`
    color: white;
    z-index: 11;
    flex-direction: column;
    background: #4a4a4a;
    padding: 30px;
    border-radius: 10px;
    gap: 40px;
    width: 400px;
    position: relative;
`
const Heading = styled(FlexCenter)`
    font-size: 25px;
    font-weight: bold;
`
const Button = styled(FlexCenter)`
    border-radius: 20px;
    padding: 10px 20px;
    color: white;
    background-color: ${(props) => props.color};
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover {
        background: #333;
    }
`
const FakeDropDown = styled(FlexCenter)`
    background: #6c6c6c;
    padding: 10px 40px 10px 20px;
    border: 1px solid white;
    border-radius: 10px;
    position: relative;
    width: 100%;
    font-size: 20px;
    justify-content: flex-start;
    cursor: pointer;
`
const DownArrow = styled(ChevronDown)`
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
`
const Options = styled(FlexCenter)`
    flex-direction: column;
    background: #6c6c6c;
    border-radius: 20px;
    margin-top: -35px;
    width: 100%;
    max-height: 300px;
    justify-content: flex-start;
    overflow-y: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 20px;
        border: transparent;
    }
`
const Option = styled(FlexCenter)`
    justify-content: flex-start;
    min-height: 40px;
    font-size: 20px;
    width: 100%;
    padding: 0px 20px;
    cursor: pointer;
    &:hover {
        background: #333;
    }
`
const CloseButton = styled(XCircle)`
    color: #ccc;
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    transform: translate(100%, -100%);
    cursor: pointer;
    &:hover {
        color: white;
    }
`
const ErrorIcon = styled(AlertCircle)`
    height: 30px;
    width: 30px;
    color: orange;
`

const SelectVersionPanel = ({ setShowPanel, selectedVersion, setSelectedVersion, versions }) => {
    const [showOptions, setShowOptions] = useState(false)
    const [panelSelectedVersion, setPanelSelectedVersion] = useState(selectedVersion)
    const [error, setError] = useState(false)

    const toggleSelectNewVersion = () => {
        if (showOptions) {
            setShowOptions(false)
        } else {
            setShowOptions(true)
        }
    }
    const selectVersion = (v) => {
        setShowOptions(false)
        setPanelSelectedVersion(v)
    }
    const saveSelectedVersion = async () => {
        // setSelectedVersion(panelSelectedVersion)
        try {
            const url = 'http://' + pivotIP + '/version/select/' + panelSelectedVersion.id
            const res = await ky.get(url)

            if (res.status === 200) {
                setError(false)
                setShowPanel(false)
            }
        } catch (e) {
            console.log('error:', e)
            setError(true)
        }
    }

    const OptionsArr = []
    if (versions) {
        versions.forEach((v) => {
            OptionsArr.push({ id: v.data.ID, tag: v.data.tag })
        })
    }
    console.log('versions', versions)

    return (
        <Container>
            <Inner>
                <Content>
                    <CloseButton onClick={() => setShowPanel(null)} />
                    <Heading>Select Software Version</Heading>
                    <FakeDropDown onClick={() => toggleSelectNewVersion()}>
                        {panelSelectedVersion.tag}
                        <DownArrow />
                    </FakeDropDown>
                    {showOptions && (
                        <Options>
                            {OptionsArr.map((v) => (
                                <Option key={v.id} onClick={() => selectVersion(v)}>
                                    {v.tag}
                                </Option>
                            ))}
                        </Options>
                    )}
                    <Button color={'#666'} onClick={() => saveSelectedVersion()}>
                        {error && <ErrorIcon />}
                        Select
                    </Button>
                </Content>
                <Backdrop onClick={() => setShowPanel(null)} />
            </Inner>
        </Container>
    )
}

export default SelectVersionPanel
