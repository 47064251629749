import React, { useReducer, useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { makeStyles, withStyles } from '@mui/styles'

import AlertDialog from '../../components/AlertDialog'
import { defaultValues } from '../defaultValues'

const XSwitch = withStyles((theme) => ({
    root: {
        width: 48,
        height: 26,
        padding: 5,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        paddingLeft: 4,
    },
    thumb: {
        width: 24,
        height: 24,
    },
}))(Switch)

const rootStyles = makeStyles((theme) => ({
    form: {
        width: ({ template }) => template ? 900 : 900,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
    modal: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        margin: 50,
    },
    stickyTableHeaderCells: {
        position: 'sticky',
        top: ({ template }) => template ? 0 : 112,
        backgroundColor: '#333',
        zIndex: 1,
    },
    switchCols: { textAlign: 'center' },
    modalScroll: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        maxHeight: '100%',
        '&::-webkit-scrollbar': {
            width: 3,
            backgroundColor: '#333',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#888',
        },

        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: 10,
            backgroundColor: '#333',
        },
    },
}))

const TrendAnimation = ({ publish, tables, table, settings, xstadium, template, changeCallback }) => {
    const navigate = useNavigate()

    const [displayMoreInfo, setDisplayMoreInfo] = useState(false)

    //----------
    // Styles
    //-----------

    const styles = rootStyles({ xstadium, template })

    //----------
    // Define States
    //-----------

    const checkColumnStatus = (a, innerKey) => {
        const columnStatus = Object.keys(a).filter((key) => a[key][innerKey])

        if (columnStatus.length === 0) {
            return 'allOff'
        } else if (columnStatus.length === Object.keys(a).length) {
            return 'allOn'
        } else {
            return 'mixed'
        }
    }

    const [updated, setUpdated] = useState(-1)
    const [changed, setChanged] = useState(0)
    const [animations, setAnimations] = useState(
        table.data.trends ? cloneDeep(table.data.trends) : cloneDeep(defaultValues.trends)
    )

    const [hasActiveTrendboards, setHasActiveTrendboards] = useReducer(
        (h, a) => checkColumnStatus(a[table.data.gameType][table.data.gameVariant], 'showOnTrendboard'),
        checkColumnStatus(animations[table.data.gameType][table.data.gameVariant], 'showOnTrendboard')
    )
    const [hasActivePits, setHasActivePits] = useReducer(
        (h, a) => checkColumnStatus(a[table.data.gameType][table.data.gameVariant], 'showOnPit'),
        checkColumnStatus(animations[table.data.gameType][table.data.gameVariant], 'showOnPit')
    )
    const [hasActiveZones, setHasActiveZones] = useReducer(
        (h, a) => checkColumnStatus(a[table.data.gameType][table.data.gameVariant], 'showOnZone'),
        checkColumnStatus(animations[table.data.gameType][table.data.gameVariant], 'showOnZone')
    )
    const [hasActiveEvents, setHasActiveEvents] = useReducer(
        (h, a) => checkColumnStatus(a[table.data.gameType][table.data.gameVariant], 'showOnEvent'),
        checkColumnStatus(animations[table.data.gameType][table.data.gameVariant], 'showOnEvent')
    )

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
        loading: false,
    })


    const checkForChanges = (a) => {
        setHasActiveTrendboards(a)
        setHasActiveZones(a)
        setHasActivePits(a)
        setHasActiveEvents(a)
    }

    //----------
    // Live data Control
    //-----------

    if (table.updated !== updated && table.data.trends) {
        const newState = table.data.trends
        setAnimations(cloneDeep(newState))
        setUpdated(table.updated)
    }

    //----------
    // UI EVENTS
    //-----------

    const switchEvent = (name, key) => {
        animations[table.data.gameType][table.data.gameVariant][key][name] = animations[table.data.gameType][
            table.data.gameVariant
        ][key][name]
            ? false
            : true
        setChanged(changed + 1)
        checkForChanges(animations)
    }

    const getDefaultTrendAnimations = (gameType, gameVariant) => {
        const clonedDefaultAnimations = cloneDeep(defaultValues.trends)
        const clonedAnimations = cloneDeep(animations)
        Object.keys(clonedDefaultAnimations[gameType][gameVariant]).forEach((key) => {
            clonedDefaultAnimations[gameType][gameVariant][key].priority =
                clonedAnimations[gameType][gameVariant][key].priority
        })
        return clonedDefaultAnimations
    }

    const setDefaultTrendAnimations = () => {
        setAnimations(getDefaultTrendAnimations(table.data.gameType, table.data.gameVariant))
    }

    const getAnimations = (gameType, gameVariant) => {
        const result = Object.keys(animations[gameType][gameVariant]).reduce((obj, key) => {
            obj[key] = animations[gameType][gameVariant][key]
            return obj
        }, {})
        return result
    }

    const filteredAnimations = getAnimations(table.data.gameType, table.data.gameVariant)

    const toggleAllOn = (innerKey) => {
        Object.keys(filteredAnimations).forEach((key) => (filteredAnimations[key][innerKey] = true))
        setChanged(changed + 1)
        checkForChanges(animations)
    }

    const toggleAllOff = (innerKey) => {
        Object.keys(filteredAnimations).forEach((key) => (filteredAnimations[key][innerKey] = false))
        setChanged(changed + 1)
        checkForChanges(animations)
    }

    //----------
    // API EVENTS
    //-----------

    const validateData = (data, gameType, gameVariant) => {
        const number = (v) => {
            return parseInt(String(v).replace(/\D/g, ''), 10)
        }

        Object.keys(data[gameType][gameVariant]).forEach((key) => {
            data[gameType][gameVariant][key].name = String(data[gameType][gameVariant][key].name)
            data[gameType][gameVariant][key].priority = number(data[gameType][gameVariant][key].priority)
            data[gameType][gameVariant][key].showOnTrendboard = Boolean(
                data[gameType][gameVariant][key].showOnTrendboard
            )
            if (table.data.gameType === 'baccarat') {
                data[gameType][gameVariant][key].showOnPit = Boolean(data[gameType][gameVariant][key].showOnPit)
                data[gameType][gameVariant][key].showOnZone = Boolean(data[gameType][gameVariant][key].showOnZone)
                data[gameType][gameVariant][key].showOnEvent = Boolean(data[gameType][gameVariant][key].showOnEvent)
                data[gameType][gameVariant][key].showOnXstadium = Boolean(
                    data[gameType][gameVariant][key].showOnXstadium
                )
            }
        })

        console.log(data)
        return data
    }

    const [postStatus, setPostStatus] = useState(false)

    const send = async () => {
        try {
            setPostStatus(true)
            console.log(animations)
            await publish({
                ...table.data,
                trends: validateData(cloneDeep(animations), table.data.gameType, table.data.gameVariant),
                updatedBy: window.localStorage.getItem('account') ?? '-',
            })
            setChanged(0)
        } catch (e) {
            console.log(
                `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
        } finally {
            setPostStatus(false)
        }
    }

    const sortFunc = (a, b) => {
        if (a.priority < b.priority) {
            return -1
        }
        if (a.priority > b.priority) {
            return 1
        }
        return 0
    }

    const keysSortedByPriority = Object.keys(filteredAnimations)
        .map((v) => ({ key: v, priority: filteredAnimations[v].priority }))
        .sort(sortFunc)

    useEffect(() => {
        if (changed > 0 && template) {
            changeCallback({
                trends: validateData(cloneDeep(animations), table.data.gameType, table.data.gameVariant),
            })
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                <Box>
                    {/* More Information area - TREND ANIMATION*/}
                    <Paper
                        sx={{
                            textAlign: 'center',
                            padding: '10px',
                            background: (theme) => theme.palette.card.background,
                        }}
                    >
                        {!displayMoreInfo && (
                            <Button
                                variant='contained'
                                color='info'
                                onClick={() => {
                                    setDisplayMoreInfo(true)
                                }}
                            >
                                More Information
                            </Button>
                        )}

                        {displayMoreInfo && (
                            <>
                                <Button
                                    variant='contained'
                                    color='error'
                                    onClick={() => {
                                        setDisplayMoreInfo(false)
                                    }}
                                >
                                    Close
                                </Button>
                                <p style={{ textAlign: 'left' }}>
                                    <br />
                                    The animation of each trend can be toggled on or off for the Trendboard display. The
                                    priority of the trends are listed in descending order, with 1 being the highest
                                    priority. The priority can be adjusted under the “Trend Priority” tab.
                                    <br />
                                    {table.data.gameType === 'baccarat' && (
                                        <>
                                            <br />
                                            Please note that Pit, Zone and Event{' '}
                                            <strong>settings are only for signage use (if available)</strong>.
                                            <br />
                                        </>
                                    )}
                                </p>
                            </>
                        )}
                    </Paper>
                    <br />

                    {/* The table displaying the priority order */}
                    <Paper
                        className='triggers'
                        sx={{
                            backgroundColor: 'inherit',
                        }}
                    >
                        <Table>
                            <TableHead>
                                {table.data.gameType === 'baccarat' && (
                                    <TableRow>
                                        <TableCell
                                            style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}
                                            colSpan={3}
                                        ></TableCell>
                                        <TableCell style={{ textAlign: 'center' }} colSpan={3}>
                                            <h3>Signage Controls</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow className={styles.stickyTableHeaderCells}>
                                    <TableCell style={{ textAlign: 'right', width: 50 }}>
                                        <h3>Priority</h3>
                                    </TableCell>
                                    <TableCell>
                                        <h3>Animation</h3>
                                    </TableCell>
                                    <TableCell
                                        className={styles.switchCols}
                                        style={{
                                            borderRight:
                                                table.data.gameType === 'baccarat'
                                                    ? '1px solid rgba(81, 81, 81, 1)'
                                                    : 'none',
                                        }}
                                    >
                                        <h3>Trendboard Display</h3>
                                    </TableCell>
                                    {table.data.gameType === 'baccarat' && (
                                        <>
                                            <TableCell className={styles.switchCols}>
                                                <h3>Pit Display</h3>
                                            </TableCell>
                                            <TableCell className={styles.switchCols}>
                                                <h3>Zone Display</h3>
                                            </TableCell>
                                            <TableCell className={styles.switchCols}>
                                                <h3>Events Display</h3>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody className='triggers-table'>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan='1' style={{ width: 50 }}></TableCell>
                                    <TableCell colSpan='1'>Toggle all ON/OFF</TableCell>
                                    <TableCell
                                        className={styles.switchCols}
                                        style={{
                                            borderRight:
                                                table.data.gameType === 'baccarat'
                                                    ? '1px solid rgba(81, 81, 81, 1)'
                                                    : 'none',
                                        }}
                                    >
                                        <FormControl variant='standard' sx={{ m: 1, minWidth: 80 }}>
                                            <Select id='trendboard-display-toggle' value={hasActiveTrendboards}>
                                                <MenuItem value='allOn' onClick={() => toggleAllOn('showOnTrendboard')}>
                                                    All On
                                                </MenuItem>

                                                <MenuItem
                                                    value='allOff'
                                                    onClick={() => toggleAllOff('showOnTrendboard')}
                                                >
                                                    All Off
                                                </MenuItem>

                                                <MenuItem value='mixed' sx={{ display: 'none' }}>
                                                    Mixed
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    {table.data.gameType === 'baccarat' && (
                                        <>
                                            <TableCell className={styles.switchCols}>
                                                <FormControl variant='standard' sx={{ m: 1, minWidth: 80 }}>
                                                    <Select id='pit-display-toggle' value={hasActivePits}>
                                                        <MenuItem
                                                            value='allOn'
                                                            onClick={() => toggleAllOn('showOnPit')}
                                                        >
                                                            All On
                                                        </MenuItem>

                                                        <MenuItem
                                                            value='allOff'
                                                            onClick={() => toggleAllOff('showOnPit')}
                                                        >
                                                            All Off
                                                        </MenuItem>

                                                        <MenuItem value='mixed' sx={{ display: 'none' }}>
                                                            Mixed
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={styles.switchCols}>
                                                <FormControl variant='standard' sx={{ m: 1, minWidth: 80 }}>
                                                    <Select id='zone-display-toggle' value={hasActiveZones}>
                                                        <MenuItem
                                                            value='allOn'
                                                            onClick={() => toggleAllOn('showOnZone')}
                                                        >
                                                            All On
                                                        </MenuItem>

                                                        <MenuItem
                                                            value='allOff'
                                                            onClick={() => toggleAllOff('showOnZone')}
                                                        >
                                                            All Off
                                                        </MenuItem>

                                                        <MenuItem value='mixed' sx={{ display: 'none' }}>
                                                            Mixed
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={styles.switchCols}>
                                                <FormControl variant='standard' sx={{ m: 1, minWidth: 80 }}>
                                                    <Select id='events-display-toggle' value={hasActiveEvents}>
                                                        <MenuItem
                                                            value='allOn'
                                                            onClick={() => toggleAllOn('showOnEvent')}
                                                        >
                                                            All On
                                                        </MenuItem>

                                                        <MenuItem
                                                            value='allOff'
                                                            onClick={() => toggleAllOff('showOnEvent')}
                                                        >
                                                            All Off
                                                        </MenuItem>

                                                        <MenuItem value='mixed' sx={{ display: 'none' }}>
                                                            Mixed
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>

                                {keysSortedByPriority.map((v, index) => {
                                    const key = v.key
                                    const item = animations[table.data.gameType][table.data.gameVariant][key]
                                    return (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{ textAlign: 'center', width: 50, color: '#bbb' }}>
                                                {item.priority}
                                            </TableCell>
                                            <TableCell>{item.name}</TableCell>

                                            <TableCell
                                                className={styles.switchCols}
                                                style={{
                                                    borderRight:
                                                        table.data.gameType === 'baccarat'
                                                            ? '1px solid rgba(81, 81, 81, 1)'
                                                            : 'none',
                                                }}
                                            >
                                                <div className='activeToggle'>
                                                    <FormControlLabel
                                                        control={
                                                            <XSwitch
                                                                checked={item.showOnTrendboard ? true : false}
                                                                onChange={(e) => {
                                                                    switchEvent('showOnTrendboard', key)
                                                                }}
                                                                inputProps={{ 'aria-label': 'active on/off' }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </TableCell>

                                            {table.data.gameType === 'baccarat' && (
                                                <>
                                                    <TableCell className={styles.switchCols}>
                                                        <div className='activeToggle'>
                                                            <FormControlLabel
                                                                control={
                                                                    <XSwitch
                                                                        checked={item.showOnPit ? true : false}
                                                                        onMouseDown={(e) => {
                                                                            e.preventDefault()
                                                                        }}
                                                                        onChange={(e) => {
                                                                            switchEvent('showOnPit', key)
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'active on/off' }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </TableCell>

                                                    <TableCell className={styles.switchCols}>
                                                        <div className='activeToggle'>
                                                            <FormControlLabel
                                                                control={
                                                                    <XSwitch
                                                                        checked={item.showOnZone ? true : false}
                                                                        onChange={(e) => {
                                                                            switchEvent('showOnZone', key)
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'active on/off' }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </TableCell>

                                                    <TableCell className={styles.switchCols}>
                                                        <div className='activeToggle'>
                                                            <FormControlLabel
                                                                control={
                                                                    <XSwitch
                                                                        checked={item.showOnEvent ? true : false}
                                                                        onChange={(e) => {
                                                                            switchEvent('showOnEvent', key)
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'active on/off' }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={postStatus === true}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        onClick={() => {
                            setDefaultTrendAnimations()
                        }}
                    >
                        Default
                    </Button>

                    {changed > 0 && (
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={send}
                            disabled={postStatus}
                        >
                            Update
                        </Button>
                    )}
                </div>}
            </form>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default TrendAnimation
