import React, { useState, memo, useEffect } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import SnackbarContent from '@mui/material/SnackbarContent'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import AlertDialog from '../../components/AlertDialog'

const rootStyles = makeStyles((theme) => ({
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        width: 700,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
    sectionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.75em',
    },
    sectionHeader: {
        fontSize: '1.15em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    fieldRowWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5em',
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.2em',
    },
}))

const FieldRow = memo(({ name, label, value, handleChange, loading, error, type = 'text' }) => {
    return (
        <Box display='flex' flexDirection='column'>
            <Box>{label}</Box>
            <TextField
                size='small'
                id={name}
                name={name}
                type={type}
                fullWidth
                variant='outlined'
                onChange={handleChange}
                value={value}
                disabled={loading}
                error={error}
            />
        </Box>
    )
})

const NDISettings = ({ publish, table, xstadium, template, changeCallback }) => {
    const styles = rootStyles({ xstadium })

    const navigate = useNavigate()

    // form changed detect hook
    const [changed, setChanged] = useState(0)

    // form flags
    const [loading, setLoading] = useState(false)
    const [fail, setFail] = useState('')
    const [updated, setUpdated] = useState(-1)

    // fields
    const [receiveGroupName, setReceiveGroupName] = useState([])
    const [discoveryServerIP, setDiscoveryServerIP] = useState([])
    const [streamName, setStreamName] = useState('')
    const [sendGroupName, setSendGroupName] = useState([])
    const [multicastSendTtl, setMulticastSendTtl] = useState(1)
    const [multicastSendEnable, setMulticastSendEnable] = useState(false)
    const [multicastSendNetmask, setMulticastSendNetmask] = useState('255.255.0.0')
    const [multicastSendNetprefix, setMulticastSendNetprefix] = useState('239.255.0.0')
    const [multicastRecvEnable, setMulticastRecvEnable] = useState(true)
    const [tcpSendEnable, setTcpSendEnable] = useState(true)
    const [tcpRecvEnable, setTcpRecvEnable] = useState(true)
    const [unicastSendEnable, setUnicastSendEnable] = useState(true)
    const [unicastRecvEnable, setUnicastRecvEnable] = useState(true)
    const [rudpSendEnable, setRudpSendEnable] = useState(true)
    const [rudpRecvEnable, setRudpRecvEnable] = useState(true)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
        loading: false,
    })

    if (table.updated !== updated) {
        setUpdated(table.updated)
        if (!changed) {
            setReceiveGroupName(
                get(table, 'data.NDIconfig.groups.recv') ? get(table, 'data.NDIconfig.groups.recv').split(',') : []
            )
            setDiscoveryServerIP(
                get(table, 'data.NDIconfig.networks.discovery')
                    ? get(table, 'data.NDIconfig.networks.discovery').split(',')
                    : []
            )
            setStreamName(get(table, 'data.streamOutputName') ? get(table, 'data.streamOutputName') : '')
            setSendGroupName(
                get(table, 'data.NDIconfig.groups.send') ? get(table, 'data.NDIconfig.groups.send').split(',') : []
            )
            setMulticastSendTtl(
                get(table, 'data.NDIconfig.multicast.send.ttl') ? get(table, 'data.NDIconfig.multicast.send.ttl') : 1
            )
            setMulticastSendEnable(
                get(table, 'data.NDIconfig.multicast.send.enable')
                    ? get(table, 'data.NDIconfig.multicast.send.enable')
                    : false
            )
            setMulticastSendNetmask(
                get(table, 'data.NDIconfig.multicast.send.netmask')
                    ? get(table, 'data.NDIconfig.multicast.send.netmask')
                    : '255.255.0.0'
            )
            setMulticastSendNetmask(
                get(table, 'data.NDIconfig.multicast.send.netprefix')
                    ? get(table, 'data.NDIconfig.multicast.send.netprefix')
                    : '239.255.0.0'
            )
            setMulticastRecvEnable(
                get(table, 'data.NDIconfig.multicast.recv.enable')
                    ? get(table, 'data.NDIconfig.multicast.send.enable')
                    : true
            )
            setTcpSendEnable(
                get(table, 'data.NDIconfig.tcp.send.enable') ? get(table, 'data.NDIconfig.tcp.send.enable') : false
            )
            setTcpRecvEnable(
                get(table, 'data.NDIconfig.tcp.recv.enable') ? get(table, 'data.NDIconfig.tcp.send.enable') : false
            )
            setUnicastSendEnable(
                get(table, 'data.NDIconfig.unicast.send.enable')
                    ? get(table, 'data.NDIconfig.unicast.send.enable')
                    : false
            )
            setUnicastRecvEnable(
                get(table, 'data.NDIconfig.unicast.recv.enable')
                    ? get(table, 'data.NDIconfig.unicast.send.enable')
                    : false
            )
            setRudpSendEnable(
                get(table, 'data.NDIconfig.rudp.send.enable') ? get(table, 'data.NDIconfig.rudp.send.enable') : true
            )
            setRudpRecvEnable(
                get(table, 'data.NDIconfig.rudp.recv.enable') ? get(table, 'data.NDIconfig.rudp.send.enable') : true
            )
        }
    }

    const restartTrendboardPrompt = () => {
        setAlertDialogState({
            show: true,
            type: 'confirmation',
            message: 'The Trendboard application must restart before NDI settings can take effect. Restart now?',
            loading: false,
            error: false,
            confirm: () => {
                send()
            },
        })
    }

    const getFieldsValues = () => ({
        NDIconfig: {
            networks: {
                discovery: discoveryServerIP.join(','),
            },
            groups: {
                send: sendGroupName.join(','),
                recv: receiveGroupName.join(','),
            },
            rudp: {
                send: {
                    enable: rudpSendEnable,
                },
                recv: {
                    enable: rudpRecvEnable,
                },
            },
            tcp: {
                send: {
                    enable: tcpSendEnable,
                },
                recv: {
                    enable: tcpRecvEnable,
                },
            },
            unicast: {
                send: {
                    enable: unicastSendEnable,
                },
                recv: {
                    enable: unicastRecvEnable,
                },
            },
            multicast: {
                send: {
                    ttl: Number(multicastSendTtl),
                    enable: multicastSendEnable,
                    netmast: multicastSendNetmask.trim(),
                    netprefix: multicastSendNetprefix.trim(),
                },
                recv: {
                    enable: multicastRecvEnable,
                },
            },
        },
        streamOutputName: streamName,
    })

    const send = async () => {
        setAlertDialogState((prev) => ({
            ...prev,
            show: false,
        }))
        try {
            setLoading(true)
            await publish({
                ...table.data,
                ...getFieldsValues(),
                streamOutputName: streamName,
                updatedBy: window.localStorage.getItem('account') ?? '-',
            })
            setFail('')
            setChanged(0)
        } catch (e) {
            setFail(
                `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
        } finally {
            setLoading(false)
        }

    }


    const handleChange = (e, setState, bypassValidation) => {
        if (!bypassValidation) {
            setState(e.target.value.replace(/[\WA-Z ]/g, ''))
        } else {
            setState(e.target.value)
        }
        setChanged(changed + 1)
    }

    useEffect(() => {
        if (changed > 0 && template) {
            changeCallback(getFieldsValues())
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <div className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </div>
                )}

                <Box className={styles.sectionWrapper}>
                    <Typography className={styles.sectionHeader}>Trendboard Display Marketing Feeds</Typography>

                    <Box>
                        <Box className={styles.tooltipWrapper}>
                            <Typography>NDI Receive Group Name(s)</Typography>
                            <Tooltip
                                title={<span style={{ fontSize: '15px' }}>Press enter to add input(s)</span>}
                                placement='right'
                            >
                                <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                            </Tooltip>
                        </Box>

                        <Autocomplete
                            multiple
                            options={[]}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip label={option} size='small' {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => <TextField {...params} size='small' />}
                            onChange={(e, value) => {
                                setReceiveGroupName(value)
                                setChanged(changed + 1)
                            }}
                            value={receiveGroupName || []}
                            disabled={loading}
                        />
                    </Box>
                </Box>

                <Divider sx={{ margin: '2em 0' }} />

                <Box className={styles.sectionWrapper}>
                    <Typography className={styles.sectionHeader}>Trendboard Output Settings</Typography>

                    <Box className={styles.fieldRowWrapper}>
                        <Box>
                            <Box className={styles.tooltipWrapper}>
                                <Typography>NDI Discovery Server IP</Typography>
                                <Tooltip
                                    title={<span style={{ fontSize: '15px' }}>Press enter to add input(s)</span>}
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>

                            <Autocomplete
                                multiple
                                options={[]}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option} size='small' {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => <TextField {...params} size='small' />}
                                onChange={(e, value) => {
                                    setDiscoveryServerIP(value)
                                    setChanged(changed + 1)
                                }}
                                autoSelect
                                value={discoveryServerIP || []}
                                disabled={loading}
                            />
                        </Box>
                        {!template && <FieldRow
                            label='NDI Stream Name'
                            name='streamName'
                            value={streamName}
                            handleChange={(e) => handleChange(e, setStreamName)}
                            loading={loading}
                            error={false}
                        />}
                        <Box>
                            <Box className={styles.tooltipWrapper}>
                                <Typography>NDI Send Group Name</Typography>
                                <Tooltip
                                    title={<span style={{ fontSize: '15px' }}>Press enter to add input(s)</span>}
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>
                            <Autocomplete
                                multiple
                                options={[]}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option} size='small' {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => <TextField {...params} size='small' />}
                                onChange={(e, value) => {
                                    setSendGroupName(value)
                                    setChanged(changed + 1)
                                }}
                                autoSelect
                                value={sendGroupName || []}
                                disabled={loading}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box className={styles.sectionWrapper}>
                    <Typography className={styles.sectionHeader}>Multicast Settings</Typography>
                    <Box className={styles.fieldRowWrapper}>
                        <FieldRow
                            label='Send ttl'
                            name='multicastSendTtl'
                            value={multicastSendTtl}
                            handleChange={(e) => handleChange(e, setMulticastSendTtl)}
                            loading={loading}
                            error={false}
                            type='number'
                        />
                    </Box>
                    <Box className={styles.toggleWrapper}>
                        <Typography className={styles.fieldRowHeader}>Enable Send</Typography>
                        <FormControlLabel
                            value='multicastEnableSend'
                            control={
                                <Switch
                                    checked={multicastSendEnable}
                                    onChange={(e) => {
                                        setMulticastSendEnable(e.target.checked)
                                        setChanged(changed + 1)
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Box className={styles.fieldRowWrapper}>
                        <FieldRow
                            label='Send netmask'
                            name='multicastSendNetmask'
                            value={multicastSendNetmask}
                            handleChange={(e) => handleChange(e, setMulticastSendNetmask, true)}
                            loading={loading}
                            error={false}
                        />
                    </Box>
                    <Box className={styles.fieldRowWrapper}>
                        <FieldRow
                            label='Send netprefix'
                            name='multicastSendNetprefix'
                            value={multicastSendNetprefix}
                            handleChange={(e) => handleChange(e, setMulticastSendNetprefix, true)}
                            loading={loading}
                            error={false}
                        />
                    </Box>
                    <Box className={styles.toggleWrapper}>
                        <Typography className={styles.fieldRowHeader}>Enable Receive</Typography>
                        <FormControlLabel
                            value='multicastEnableReceive'
                            control={
                                <Switch
                                    checked={multicastRecvEnable}
                                    onChange={(e) => {
                                        setMulticastRecvEnable(e.target.checked)
                                        setChanged(changed + 1)
                                    }}
                                />
                            }
                        />
                    </Box>
                </Box>

                <Box className={styles.sectionWrapper}>
                    <Typography className={styles.sectionHeader}>Reliable UDP Settings</Typography>
                    <Box className={styles.toggleWrapper}>
                        <Typography className={styles.fieldRowHeader}>Enable Send</Typography>
                        <FormControlLabel
                            value='rudpEnableSend'
                            control={
                                <Switch
                                    checked={rudpSendEnable}
                                    onChange={(e) => {
                                        setRudpSendEnable(e.target.checked)
                                        setChanged(changed + 1)
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Box className={styles.toggleWrapper}>
                        <Typography className={styles.fieldRowHeader}>Enable Receive</Typography>
                        <FormControlLabel
                            value='rudpEnableRecv'
                            control={
                                <Switch
                                    checked={rudpRecvEnable}
                                    onChange={(e) => {
                                        setRudpRecvEnable(e.target.checked)
                                        setChanged(changed + 1)
                                    }}
                                />
                            }
                        />
                    </Box>
                </Box>

                <Box className={styles.sectionWrapper}>
                    <Typography className={styles.sectionHeader}>TCP Settings</Typography>
                    <Box className={styles.toggleWrapper}>
                        <Typography className={styles.fieldRowHeader}>Enable Send</Typography>
                        <FormControlLabel
                            value='tcpEnableSend'
                            control={
                                <Switch
                                    checked={tcpSendEnable}
                                    onChange={(e) => {
                                        setTcpSendEnable(e.target.checked)
                                        setChanged(changed + 1)
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Box className={styles.toggleWrapper}>
                        <Typography className={styles.fieldRowHeader}>Enable Receive</Typography>
                        <FormControlLabel
                            value='tcpEnableRecv'
                            control={
                                <Switch
                                    checked={tcpRecvEnable}
                                    onChange={(e) => {
                                        setTcpRecvEnable(e.target.checked)
                                        setChanged(changed + 1)
                                    }}
                                />
                            }
                        />
                    </Box>
                </Box>

                <Box className={styles.sectionWrapper}>
                    <Typography className={styles.sectionHeader}>Unicast Settings</Typography>
                    <Box className={styles.toggleWrapper}>
                        <Typography className={styles.fieldRowHeader}>Enable Send</Typography>
                        <FormControlLabel
                            value='unicastEnableSend'
                            control={
                                <Switch
                                    checked={unicastSendEnable}
                                    onChange={(e) => {
                                        setUnicastSendEnable(e.target.checked)
                                        setChanged(changed + 1)
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Box className={styles.toggleWrapper}>
                        <Typography className={styles.fieldRowHeader}>Enable Receive</Typography>
                        <FormControlLabel
                            value='unicastEnableRecv'
                            control={
                                <Switch
                                    checked={unicastRecvEnable}
                                    onChange={(e) => {
                                        setUnicastRecvEnable(e.target.checked)
                                        setChanged(changed + 1)
                                    }}
                                />
                            }
                        />
                    </Box>
                </Box>

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={loading}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    {changed > 0 && (
                        <Button
                            variant='contained'
                            type='button'
                            color='primary'
                            disabled={loading}
                            onClick={restartTrendboardPrompt}
                        >
                            Update
                        </Button>
                    )}
                </div>}


                <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
            </form>
        </>
    )
}

export default NDISettings
