import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Bar } from 'react-chartjs-2'
import LetteredAvatar from 'react-lettered-avatar'
import { useLocation, useNavigate, useParams, Navigate } from 'react-router-dom'
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap'
import { LinearProgress } from '@mui/material'
import Grid from '@mui/material/Grid'

import { useSubscribe, dealerApi, authApi } from '../../api'
import { prometheusDealer } from '../../config'
import Tree from '../../tree/Tree'
import useWindowSize from '../../WindowSize'
import {
    chartBackgroundColors,
    hoursLabels,
    NanoToHours,
} from '../Formats'
import TableHistoryChart from '../TableHistoryChart'

import '../../ag-grid/ag-grid-theme.css' // Core grid CSS, always needed
import '../../ag-grid/ag-grid.css' // Core grid CSS, always needed

const styles = {
    backToListButton: {
        marginTop: 20,
        marginBottom: 20,
        float: 'right'
    },
    pieContainer: {
        width: 200,
        height: 100
    },
    barContainer: {
        width: 'inherit',
        height: 150
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em'
    },
    cardTitleText: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        // color: 'white'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        // padding: '0.5vw 0.7vw',
    },
    cardBodyText: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
    cardSubtitle: {
        margin: '0 15px',
        color: 'darkgrey'
    },
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red'
    }),
    profileDetailsText: {
        padding: '0.9em',
    },
    profilePicture: {
        marginLeft: 25
    },
    profileText: {
        padding: '0.5em !important',
        fontWeight: 500
    },
    profileRow: {
        marginBottom: '1em !important'
    },
    profileContainer: {
        maxWidth: 3000
    },
    tabPane: {
        backgroundColor: 'transparent'
    },
}

const Dealer = () => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active = zonesSocket && zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket && tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket && pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket && devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket && statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket && statusSocket.readyState === WebSocket.OPEN

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')
    let { dealerID } = useParams()

    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }

    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // fetched data
    const [dealerReport, setDealerReport] = useState(null)
    const [fetchedDealerReport, setFetchedDealerReport] = useState(false)
    const [users, setUsers] = useState(null)
    const [fetchedUsers, setFetchedUsers] = useState(false)
    const [minLoadTime, setMinLoadTime] = useState(false)

    // built charts
    const [builtCharts, setBuiltCharts] = useState({})
    const setBuiltChartProxy = (chartID, value) => {
        setBuiltCharts(prevBuilt => ({
            ...prevBuilt,
            [chartID]: value
        }))
    }

    // tab control
    const [selectedTab, setSelectedTab] = useState("1")

    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter(v => v !== 'push')
        const oldParams = urlParams.toString()
        propKeys.forEach(k => {
            if (props[k] === null) {
                urlParams.delete(k)
                window.localStorage.removeItem('url:' + k)
                return
            }
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange = propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        if (urlChange || props.push) {
            navigate('/xtable/dealer/' + dealerID + '?' + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                setFetchedDealerReport(false)
            }
            setBuiltCharts({})
        }
    }

    // GRID
    // const gamesGridRef = useRef() // Optional - for accessing Grid's API

    // // DefaultColDef sets props common to all Columns
    // const defaultColDef = useMemo(() => {
    //     return {
    //         // flex: 1,
    //         minWidth: 10,
    //         filter: true,
    //         sortable: true,
    //         // floatingFilter: true,
    //         wrapHeaderText: true,
    //         autoHeaderHeight: true,
    //     }
    // }, [])
    // GRID END

    // const isMoreThanOneDay = numberOfDays > 0
    // const isLive = startDate === startOfToday && endDate === endOfToday ? true : false
    // const isMoreThanOneDayOrNotLive = isMoreThanOneDay || (numberOfDays === 0 && !isLive)

    const dateChange = (start, end) => {
        if (start === startDate && end === endDate) return
        setStartDate(start)
        setEndDate(end)
        clearFetch({ startDate: start, endDate: end })
    }

    const getHoursHandsData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'hands',
                data: hoursLabels.map((hr) => dealerReport.hourlyGames['h' + hr]),
                backgroundColor: chartBackgroundColors[6],
                borderColor: chartBackgroundColors[6],
            },
        ]
    })

    const getHoursTurnoverData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'turnover',
                data: hoursLabels.map((hr) => dealerReport.hourlyBets['h' + hr]),
                backgroundColor: chartBackgroundColors[4],
                borderColor: chartBackgroundColors[4],
            },
        ]
    })

    const getHoursOutcomeData = () => {
        const data = hoursLabels.map((hr) => dealerReport.hourlyProfit['h' + hr])
        return {
            labels: hoursLabels,
            datasets: [
                {
                    label: 'turnover',
                    data,
                    backgroundColor: data.map(v => v > 0 ? 'chartreuse' : 'red'),
                },
            ]
        }
    }

    const getDealerHPH = (dr) => {
        let result = 0
        let count = 0
        for (const hour in dr.hourlyGames) {
            if (dr.hourlyGames[hour] > 0) {
                count++
                result = result + dr.hourlyGames[hour]
            }
        }

        if (count === 0) {
            return 0
        }

        result = result / count

        return (result).toFixed(1)
    }

    const dealerTheoOffset = 45 * 1000000000
    const getDealerTheoHPH = (dr) => {
        const dur = NanoToHours(dr.active + (dr.games * dealerTheoOffset))
        if (Number.isNaN(dur) || dur === 0) {
            return '-'
        }

        return (dr.games / dur).toFixed(1)
    }

    const getDealerReport = async () => {
        if (fetchedDealerReport) {
            return
        }

        setFetchedDealerReport(true)
        try {
            const response = await dealerApi.get('report/overview/dealer/' + dealerID + '/' + (startDate) * 1000000000 + '/' + (endDate) * 1000000000).json()
            console.log("overview", response)
            setDealerReport(response)
        } catch (e) {
            console.warn(e)
            setDealerReport([])
        }
    }

    const getUsers = async () => {
        if (fetchedUsers) {
            return
        }

        setFetchedUsers(true)
        try {
            const response = await authApi.get('dealers').json()
            console.log("users", response)
            setUsers(response)
        } catch (e) {
            console.warn(e)
            setUsers([])
        }
    }

    getUsers()

    if (users && Number(URLstartDate) === startDate && (Number(URLendDate) === endDate)) {
        if (!fetchedDealerReport) {
            clearFetch({})
        }
        getDealerReport()
    }

    // if (selectedTab === "2" && !fetchedPlayerGames) {
    //     getPlayerGames()
    // }

    if (resizing && (builtCharts && Object.keys(builtCharts).length > 0)) {
        setBuiltCharts({})
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    const treeLoading = !active ||
        !zones ||
        !pits ||
        !devices ||
        !tables ||
        !status ||
        !minLoadTime

    // this detects the hotreload from react
    if (treeLoading && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }


    const dealerUserData = users ? _.get(users.filter(v => v.account === dealerID), '0', {}) : {}
    const dealerFullName = dealerUserData ? dealerUserData.firstName + ' ' + _.get(dealerUserData, 'lastName', '') : ''
    const fluid = width < 1900

    if (settings && !settings.data.xtable.sections.includes('dealers')) {
        return <Navigate to='/dashboard' />
    }

    return <Grid container spacing={2}>
        <Grid item style={{ width: 320 }}>
            <Tree loading={treeLoading}
                noAutoUpdate={true}
                dateChange={dateChange}
                startDate={startDate}
                endDate={endDate}
                fluid={fluid}
                hideLocation></Tree>
        </Grid>
        <Grid item xs>
            {(resizing) && <LinearProgress />}
            {(!resizing && !dealerReport) && <Container fluid={fluid}>
                <Row xs="1">
                    <Col>
                        <h3>Fetching dealer report...</h3>
                        <LinearProgress />
                    </Col>
                </Row>
            </Container>}
            {(!resizing && dealerReport) && <><Container fluid={fluid}>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader style={styles.cardHeader}>
                                <span style={styles.cardTitleText}>
                                    Dealer Profile
                                </span>
                            </CardHeader>
                            <CardBody style={styles.cardBody}>
                                <Row style={styles.profileDetailsText}>
                                    <Col sm={3}>
                                        <Label style={styles.profilePicture}>
                                            <LetteredAvatar size={150} name={dealerFullName} />
                                        </Label>
                                    </Col>
                                    <Col sm={9}>
                                        <Row style={styles.profileRow}>
                                            <Col sm={3}>Name</Col>
                                            <Col sm={9} style={styles.profileText}>
                                                <h3>{dealerFullName}</h3>
                                            </Col>
                                        </Row>
                                        <Row style={styles.profileRow}>
                                            <Col sm={3}>Employee ID</Col>
                                            <Col sm={9} style={styles.profileText}>
                                                {dealerUserData.employeeID ? dealerUserData.employeeID : '<N/A>'}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <Card>
                            <CardHeader style={styles.cardHeader}>
                                <span style={styles.cardTitleText}>
                                    # of games
                                </span>
                            </CardHeader>
                            <CardBody style={styles.cardBody}>
                                <span style={styles.cardBodyText}>
                                    {dealerReport.games}
                                </span>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader style={styles.cardHeader}>
                                <span style={styles.cardTitleText}>
                                    HPH
                                </span>
                            </CardHeader>
                            <CardBody style={styles.cardBody}>
                                <span style={styles.cardBodyText}>
                                    {numberOfDays === 0 ? getDealerHPH(dealerReport) : '-'}
                                </span>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader style={styles.cardHeader}>
                                <span style={styles.cardTitleText}>
                                    Theo HPH.
                                </span>
                            </CardHeader>
                            <CardBody style={styles.cardBody}>
                                <span style={styles.cardBodyText}>
                                    {getDealerTheoHPH(dealerReport)}
                                </span>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row xs="1">
                    <Col>
                        <Nav tabs style={{ margin: '15px 30px 0' }}>
                            <NavItem>
                                <NavLink
                                    className={selectedTab === "1" ? "active" : ""}
                                    onClick={() => setSelectedTab("1")}
                                >
                                    Profile
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink
                                    className={selectedTab === "2" ? "active" : ""}
                                    onClick={() => {
                                        setSelectedTab("2")
                                    }}
                                >
                                    Game History
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink
                                    className=""
                                    onClick={() => {
                                        navigate('/xtable/dealers?startDate=' + startDate + '&endDate=' + endDate)
                                    }}
                                >
                                    Back to dealers list
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={selectedTab}>
                            <TabPane tabId="1" style={styles.tabPane}>
                                <Container fluid={fluid}>
                                    {(numberOfDays === 0 && dealerReport) && <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Hourly # of games
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}><Bar options={{
                                                        plugins: {
                                                            legend: false,
                                                        },
                                                        responsive: true,
                                                        maintainAspectRatio: false,
                                                        scales: {
                                                            yAxes: [{
                                                                id: 'first-y-axis',
                                                                type: 'linear',
                                                                ticks: {
                                                                    min: 0,
                                                                }
                                                            }]
                                                        }
                                                    }}
                                                        data={getHoursHandsData()} /></div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>}
                                    {(numberOfDays === 0 && dealerReport) && <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Hourly turnover
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}><Bar options={{
                                                        plugins: {
                                                            legend: false,
                                                        },
                                                        responsive: true,
                                                        maintainAspectRatio: false,
                                                        scales: {
                                                            yAxes: [{
                                                                id: 'first-y-axis',
                                                                type: 'linear',
                                                                ticks: {
                                                                    min: 0,
                                                                }
                                                            }]
                                                        }
                                                    }}
                                                        data={getHoursTurnoverData()} /></div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>}
                                    {(numberOfDays === 0 && dealerReport) && <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Hourly house win/loss
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}><Bar options={{
                                                        plugins: {
                                                            legend: false,
                                                        },
                                                        responsive: true,
                                                        maintainAspectRatio: false,
                                                    }}
                                                        data={getHoursOutcomeData()} /></div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>}
                                    {(numberOfDays > 0 && dealerReport) && <Row>
                                        <Col>
                                            <Card style={styles.cardHeader}>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        # of hands history
                                                    </span>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row >
                                                        <Col lg="12" sm="12">
                                                            <TableHistoryChart setBuiltChart={(value) => setBuiltChartProxy('hands', value)}
                                                                builtChart={builtCharts['hands']}
                                                                findInLabelMap={() => 'Hands'}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                prometheus={prometheusDealer}
                                                                query={'{__name__=~"dealer_' + dealerID + '_games"}'}></TableHistoryChart>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>}
                                    {(numberOfDays > 0 && dealerReport) && <Row>
                                        <Col>
                                            <Card style={styles.cardHeader}>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Turnover
                                                    </span>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row >
                                                        <Col lg="12" sm="12">
                                                            <TableHistoryChart setBuiltChart={(value) => setBuiltChartProxy('turnover', value)}
                                                                builtChart={builtCharts['turnover']}
                                                                findInLabelMap={() => 'Turnover'}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                prometheus={prometheusDealer}
                                                                query={'{__name__=~"dealer_' + dealerID + '_bets"}'}></TableHistoryChart>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>}
                                    {(numberOfDays > 0 && dealerReport) && <Row>
                                        <Col>
                                            <Card style={styles.cardHeader}>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Win/Loss
                                                    </span>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row >
                                                        <Col lg="12" sm="12">
                                                            <TableHistoryChart setBuiltChart={(value) => setBuiltChartProxy('profit', value)}
                                                                builtChart={builtCharts['profit']}
                                                                findInLabelMap={() => 'Profit'}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                prometheus={prometheusDealer}
                                                                query={'{__name__=~"dealer_' + dealerID + '_profit"}'}></TableHistoryChart>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>}
                                </Container>
                            </TabPane>
                            <TabPane tabId="2">
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            TODO
                                            {/* {!playerGames && <>
                                                <h3>Fetching games...</h3>
                                                <LinearProgress />
                                            </>}
                                            {playerGamesMapped && <Card>
                                                <CardBody>
                                                    <div className="ag-theme-balham-dark" style={{ width: '-webkit-fill-available', height: 'calc(100vh - 520px)' }}>
                                                        <AgGridReact
                                                            ref={gamesGridRef}
                                                            rowData={playerGamesMapped}
                                                            columnDefs={columnDefsGames}
                                                            defaultColDef={defaultColDef}
                                                            animateRows={true}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>} */}
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                        </TabContent>
                    </Col >
                </Row >
            </Container ></>}
        </Grid>
    </Grid>
}

export default Dealer