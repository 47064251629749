import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { useSubscribe } from '../api'
import { pivotIP } from '../config'
import { validate } from '../forms'
import { findGameDeviceInTable } from '../tree/TreeUtils'

const rootStyles = makeStyles((theme) => ({
    formContainer: {
        margin: '0 auto',
        maxWidth: 728,
        display: 'flex',
        flexDirection: 'column',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em 0',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        padding: '4em 0',
        margin: '0 auto',
        width: '100%',
    },
    selectControl: {
        marginBottom: 15,
    },
    formSubmit: {
        marginTop: 20,
        marginLeft: 10,
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    titleText: {
        marginTop: '1em',
    },
}))

const SelectDevice = () => {
    const styles = rootStyles()
    const navigate = useNavigate()
    const [devices, devicesSocket] = useSubscribe(pivotIP + '/devices/*')
    const [selectedDevice, setSelectedDevice] = useState('')
    const [selectedSeats, setSelectedSeats] = useState('')

    // form flags
    const [submitted, setSubmitted] = useState(false)

    const active = devicesSocket && devicesSocket.readyState === WebSocket.OPEN

    if (!active || !devices) {
        return <LinearProgress />
    }

    const fields = {
        device: {
            error: () => !isGameDeviceExist(),
            message: 'Table has not setup game device with this vision device.',
            value: selectedDevice,
        },
        seats: {
            error: () => isEmpty(selectedSeats),
            message: 'Please select seats.',
            value: selectedSeats,
        },
    }

    const isGameDeviceExist = () => {
        const devicesInTable = devices.filter((v) => v.data.containerIndex === selectedDevice)
        return devicesInTable.filter((v) => v.data.type === 'game').length > 0 ? true : false
    }

    const validation = validate(fields)

    const send = () => {
        setSubmitted(true)
        if (!validation.error) {
            const visionIP = devices.filter(
                (v) => v.data.containerIndex === selectedDevice && v.data.type === 'visionServer'
            )[0].data.ip
            const deviceIP =
                findGameDeviceInTable(devices, selectedDevice)[0].data.ip +
                ':' +
                findGameDeviceInTable(devices, selectedDevice)[0].data.port
            window.localStorage.setItem('visionIP', visionIP)
            window.localStorage.setItem('deviceIP', deviceIP)
            window.localStorage.setItem('selectedSeats', selectedSeats)
            navigate('/xview/chipresult')
        } else {
            console.log(validation.errors)
        }
    }

    return (
        <>
            <Box key='main' component='main'>
                <div className={styles.formContainer}>
                    <Typography variant='h4' className={styles.titleText}>
                        Device Selection
                    </Typography>

                    {validation.error && submitted && (
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    )}

                    <form
                        key='device-selection-form'
                        className={styles.form}
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}
                        autoComplete='off'
                    >
                        <FormControl className={styles.selectControl} fullWidth>
                            <Select
                                id='vision-device-select'
                                fullWidth
                                value={selectedDevice}
                                onChange={(e) => {
                                    setSelectedDevice(e.target.value)
                                }}
                                inputProps={{
                                    name: 'visionDevice',
                                    id: 'visionDevice',
                                }}
                                error={submitted && fields.device.error()}
                                size='small'
                            >
                                {devices &&
                                    devices
                                        .filter((v) => v.data.type === 'visionServer')
                                        .map((device, index) => (
                                            <MenuItem key={index} value={device.data.containerIndex}>
                                                {device.data.name} {device.data.ip ? '(' + device.data.ip + ')' : null}
                                            </MenuItem>
                                        ))}
                                {(!devices || devices.filter((v) => v.data.type === 'visionServer').length === 0) && (
                                    <MenuItem value='' disabled={true}>
                                        <em>No available vision device(s)</em>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    </form>
                </div>
            </Box>

            <Box key='second' component='main'>
                <div className={styles.formContainer}>
                    <Typography variant='h4' className={styles.titleText}>
                        Seating Selection
                    </Typography>

                    {validation.error && submitted && (
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    )}

                    <form
                        key='seat-selection-form'
                        className={styles.form}
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}
                        autoComplete='off'
                    >
                        <FormControl className={styles.selectControl} fullWidth>
                            <Select
                                id='seat-number-select'
                                fullWidth
                                value={selectedSeats}
                                onChange={(e) => {
                                    setSelectedSeats(e.target.value)
                                }}
                                inputProps={{
                                    name: 'seatNumber',
                                    id: 'seatNumber',
                                }}
                                error={submitted && fields.seats.error()}
                                size='small'
                            >
                                <MenuItem value='5'> 
                                    5
                                </MenuItem>
                                <MenuItem value='6' > 
                                    6
                                </MenuItem>
                                <MenuItem value='7'> 
                                    7
                                </MenuItem>
                                <MenuItem value='8'> 
                                    8
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <div key='buttonwrapper' className={styles.formButtonWrapper}>
                            {!isEmpty(selectedDevice) && !isEmpty(selectedSeats) && (
                                <Button
                                    className={styles.formSubmit}
                                    variant='contained'
                                    type='submit'
                                    color='primary'
                                    onClick={send}
                                >
                                    Proceed
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            </Box>

       
        </>
    )
}

export default SelectDevice
