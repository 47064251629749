import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import prettyBytes from 'pretty-bytes'
import DoneIcon from '@mui/icons-material/Done'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import HourglassFullIcon from '@mui/icons-material/HourglassFull'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Fade from '@mui/material/Fade'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { useSubscribe, usePublish, upload } from '../../../api'
import { ClosedTableDefaultImage } from '../../../assets/images/ClosedTableDefaultImage'
import AlertDialog from '../../components/AlertDialog'
import { LinearProgress } from '@mui/material'

const rootStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        margin: 50,
    },
    modalScroll: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        maxHeight: '100%',
        '&::-webkit-scrollbar': {
            width: 3,
            backgroundColor: '#333',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#888',
        },

        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: 10,
            backgroundColor: '#333',
        },
    },
    fieldRowHeader: {
        fontWeight: 'normal',
    },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            colorScheme: 'dark',
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const PIPUploader = ({ authorize, device, streams }) => {
    const styles = rootStyles({})

    const deviceIP = device.data.ip === '127.0.0.1' ? window.location.hostname : device.data.ip
    const deviceURL = deviceIP + ':' + get(device, 'data.port', '3099')

    const [pip, pipSocket] = useSubscribe(deviceURL + '/content/pip')
    const [videos, videosSocket] = useSubscribe('content/files/videos/*', authorize)

    const staticAdPublish = usePublish(deviceURL + '/content/pip')
    const videosPublish = usePublish('content/files/videos/*', authorize)

    const active =
        pipSocket &&
        pipSocket.readyState === WebSocket.OPEN &&
        videosSocket &&
        videosSocket.readyState === WebSocket.OPEN

    // format
    const [format, setFormat] = useState('image')

    const handleFormatChange = (event) => {
        setFormat(event.target.value)
    }

    // select dialog
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
        if (format === 'video' && getFileType(pip.data.type) === 'Video') {
            setVideo(pip.data.id)
        }
        if (format === 'ndi' && getFileType(pip.data.type) === 'NDI') {
            setNDI(pip.data.name)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const [modalOpen, setModalOpen] = useState(false)
    const [previewImg, setPreviewImg] = useState(null)
    const [uploadState, setUploadState] = useState(null)

    // video
    const [video, setVideo] = useState('')

    const handleVideoChange = (event) => {
        setVideo(event.target.value)
    }

    // ndi
    const [ndi, setNDI] = useState('')

    const handleNDIChange = (event) => {
        setNDI(event.target.value)
    }

    const havePipImage = active && pip && pip.data && pip.data.base64Image
    const havePipVideo = active && pip && pip.data && pip.data.url

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        loading: false,
        error: false,
        confirm: () => { },
    })

    const applyPublish = async (data) => {
        if (getFileType(data.type) === 'Image') {
            setUploadState('uploading')
        }
        try {
            await staticAdPublish({
                name: data.name,
                type: data.type,
                ...(getFileType(data.type) === 'Image' && { base64Image: data.base64Image }),
                ...(getFileType(data.type) === 'Video' && { id: data.id, url: data.url }),
            })
            if (getFileType(data.type) === 'Image') {
                setTimeout(() => {
                    setUploadState('success')
                }, 2000)
                setTimeout(() => {
                    setUploadState(null)
                }, 4000)
            } else {
                handleClose()
            }
        } catch (e) {
            console.log('error up: ', e)
            if (getFileType(data.type) === 'Image') {
                setUploadState('error')
            }
        }
    }

    const handleUpload = async (e) => {
        console.log('file changed', e.target.files[0])
        const newFile = e.target.files[0]
        const isFileAlreadyExist = videos.find((video) => video.data.name === newFile.name)

        if (!e.target || e.target.files.length < 1) {
            setAlertDialogState({
                show: true,
                type: 'error',
                message: 'There was an error during processing, please try again.',
                loading: false,
                error: true,
                confirm: () => { },
            })
            return
        }

        if (getFileType(e.target.files[0].type) === 'Image') {
            setUploadState('processing')
            var reader = new FileReader()
            var canvas = document.createElement('canvas')
            var ctx = canvas.getContext('2d')
            reader.onload = function (event) {
                var img = new Image()
                img.onload = function () {
                    canvas.width = img.width
                    canvas.height = img.height
                    ctx.drawImage(img, 0, 0)
                    applyPublish({
                        name: e.target.files[0].name,
                        type: e.target.files[0].type,
                        base64Image: canvas.toDataURL('image/png'),
                    })
                    setPreviewImg(canvas.toDataURL('image/png'))
                }
                img.src = event.target.result
            }
            reader.readAsDataURL(e.target.files[0])
        }

        if (getFileType(e.target.files[0].type) === 'Video') {
            if (newFile.type !== 'video/webm') {
                setAlertDialogState({
                    show: true,
                    type: 'reminder',
                    message: 'Only .webm format video file is allowed.',
                    loading: false,
                    error: false,
                    confirm: () => { },
                })
                return
            }

            if (isFileAlreadyExist) {
                setAlertDialogState({
                    show: true,
                    type: 'reminder',
                    message: 'Video file already exists.',
                    loading: false,
                    error: false,
                    confirm: () => { },
                })
                return
            }

            setUploadState('processing')
            try {
                setUploadState('uploading')
                const video = await upload(e.target.files[0], e.target.files[0].name, e.target.files[0].type)
                await videosPublish({
                    name: e.target.files[0].name,
                    type: e.target.files[0].type,
                    size: prettyBytes(e.target.files[0].size),
                    url: video,
                    id: video.split('files/')[1],
                    uploadedBy: window.localStorage.getItem('account') ?? '-',
                })
                setTimeout(() => {
                    setUploadState('success')
                }, 2000)
                setTimeout(() => {
                    setUploadState(null)
                }, 4000)
            } catch (e) {
                console.log('error up: ', e)
                setUploadState('error')
            }
        }
    }

    const matchedVideo = videos && video ? videos.find((v) => v.data.id === video) : {}

    const apply = () => {
        if (format === 'video') {
            applyPublish({
                id: matchedVideo.data.id,
                name: matchedVideo.data.name,
                type: matchedVideo.data.type,
                url: matchedVideo.data.url,
            })
        }
        if (format === 'ndi') {
            applyPublish({
                name: ndi,
                type: 'ndi',
            })
        }
    }

    const getOptionsTranslation = (format) => {
        let result = ''
        switch (format) {
            case 'image':
                result = 'Image'
                break
            case 'video':
                result = 'Video'
                break
            case 'ndi':
                result = 'NDI Feed'
                break
            default:
        }
        return result
    }

    const getFileType = (type) => {
        let result = ''
        if (type.includes('image')) {
            result = 'Image'
        }
        if (type.includes('video')) {
            result = 'Video'
        }
        if (type.includes('ndi')) {
            result = 'NDI'
        }
        return result
    }

    useEffect(() => {
        if (active && pip && pip.data && pip.data.type) {
            setFormat(getFileType(pip.data.type).toLowerCase())
        }
    }, [active, pip])

    if (!active) {
        return <LinearProgress />
    }

    return (
        <>
            <Box display='flex' flexDirection='column' gap='.25rem'>
                <Box>
                    <Typography className={styles.fieldRowHeader}>Select Picture-in-Picture Display Format</Typography>

                    <Box display='flex' alignItems='center' justifyContent='space-between' gap='.75rem'>
                        <Select
                            labelId='format-select-label'
                            id='format-select'
                            value={format}
                            onChange={handleFormatChange}
                            size='small'
                            fullWidth
                        >
                            <MenuItem value='image'>Image</MenuItem>
                            <MenuItem value='video'>Video</MenuItem>
                            <MenuItem value='ndi'>NDI Feed</MenuItem>
                        </Select>

                        <Button
                            sx={{
                                width: '15rem',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                },
                                border: '1px solid white',
                                borderRadius: '5px',
                            }}
                            onClick={handleClickOpen}
                            disabled={(format === 'video' || format === 'ndi') && !pip}
                        >
                            Select {getOptionsTranslation(format)}
                        </Button>
                    </Box>
                </Box>

                {(havePipImage || havePipVideo) && pip.data.name && pip.data.type && (
                    <Typography
                        sx={{
                            fontSize: '.95rem',
                            fontStyle: 'italic',
                            cursor: 'default',
                        }}
                    >
                        Current selection: {getFileType(pip.data.type)}: {pip.data.name}
                    </Typography>
                )}
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth
            >
                <DialogTitle id='alert-dialog-title'>Select {getOptionsTranslation(format)}</DialogTitle>
                <DialogContent>
                    <Box display='flex' flexDirection='column' gap='1rem'>
                        {format === 'video' && (
                            <FormControl sx={{ mt: 1 }} fullWidth>
                                <InputLabel id='video-select-label'>Videos</InputLabel>
                                <Select
                                    labelId='video-select-label'
                                    id='video-select'
                                    value={video}
                                    label='Videos'
                                    onChange={handleVideoChange}
                                    MenuProps={MenuProps}
                                    fullWidth
                                >
                                    {videos &&
                                        videos.map((video, index) => {
                                            return (
                                                <MenuItem key={index} value={video.data.id}>
                                                    {video.data.name}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        )}

                        {format === 'ndi' && (
                            <FormControl sx={{ mt: 1 }} fullWidth>
                                <InputLabel id='ndi-select-label'>NDI Feeds</InputLabel>
                                <Select
                                    labelId='ndi-select-label'
                                    id='ndi-select'
                                    value={ndi}
                                    label='NDI Feeds'
                                    onChange={handleNDIChange}
                                    MenuProps={MenuProps}
                                    fullWidth
                                >
                                    {streams &&
                                        streams.sources &&
                                        streams.sources.map((stream, index) => (
                                            <MenuItem key={index} value={stream}>
                                                {stream}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}

                        <Box display='flex' alignItems='center' justifyContent='center' gap='1rem'>
                            {format === 'image' && (
                                <img
                                    alt='static ad preview'
                                    onClick={() => setModalOpen(true)}
                                    src={
                                        havePipImage
                                            ? pip.data.base64Image
                                            : previewImg
                                                ? previewImg
                                                : ClosedTableDefaultImage
                                    }
                                    style={{ width: '50%', border: '1px solid #ccc' }}
                                />
                            )}

                            {format === 'video' && video && (
                                <video
                                    type='video/webm'
                                    src={matchedVideo ? matchedVideo.data.url : ''}
                                    style={{ maxWidth: '50vh' }}
                                    controls
                                    preload='auto'
                                />
                            )}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: format !== 'video' ? 'flex-end' : 'space-between',
                        }}
                    >
                        {format !== 'ndi' && (
                            <Box sx={{ order: format === 'image' ? 2 : 1 }}>
                                <Button component='label' color='inherit'>
                                    {uploadState === null && <FileUploadIcon />}
                                    {uploadState === 'processing' && <HourglassEmptyIcon />}
                                    {uploadState === 'uploading' && <HourglassFullIcon />}
                                    {uploadState === 'success' && <DoneIcon style={{ color: 'lime' }} />}
                                    {uploadState === 'error' && <ErrorOutlineIcon style={{ color: 'red' }} />}
                                    &nbsp;Upload
                                    <input name='file' id='staticAdFile' type='file' onChange={handleUpload} hidden />
                                </Button>
                            </Box>
                        )}

                        <Box sx={{ order: format === 'image' ? 1 : 2 }}>
                            <Button onClick={handleClose}>Cancel</Button>
                            {format !== 'image' && (
                                <Button
                                    onClick={apply}
                                    disabled={(format === 'video' && !video) || (format === 'ndi' && !ndi)}
                                    autoFocus
                                >
                                    Apply
                                </Button>
                            )}
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>

            <Modal
                className={styles.modal}
                open={modalOpen}
                onClose={(e) => {
                    setModalOpen(false)
                }}
                closeAfterTransition
            >
                <Fade in={modalOpen}>
                    <div className={styles.modalScroll} style={{ overflowY: 'scroll', outline: 'none' }}>
                        <img
                            alt='static ad preview'
                            src={
                                havePipImage ? pip.data.base64Image : previewImg ? previewImg : ClosedTableDefaultImage
                            }
                        />
                    </div>
                </Fade>
            </Modal>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default PIPUploader
