import React, { useState } from 'react'
import { addDays } from 'date-fns'
import { get } from 'lodash'
import moment from 'moment'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Alert, AlertTitle } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'

import { historyApi, tableApi } from '../../api'
import ExpTable from '../gameHistory/expandableTable'
import { CustomSpinner } from '../util/spinner'
import Breadcrumbs from './breadcrumbs'

const rootStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        background: 'transparent',
        overflowY: 'auto'
    },
    selectedTab: {
        backgroundColor: '#000'
    },
    tabIndicator: {
        top: 0,
        height: 5,
        transition: 'none',
    },
    syncButton: {
        margin: 5,
        background: '#e64236',
    },
    selectButton: {
        margin: 5,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 50,
    },
    modalScroll: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        maxHeight: '100%',
        '&::-webkit-scrollbar': {
            width: 3,
            backgroundColor: '#333',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#888'
        },

        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: 10,
            backgroundColor: '#333',
        }
    },
    dateRangePicker: {
        borderRadius: 10,
        overflow: 'hidden',
        '& .rdrDefinedRangesWrapper, .rdrStaticRange, .rdrDateDisplayWrapper, .rdrMonthAndYearWrapper, .rdrMonth': {
            backgroundColor: '#555',
        },
        '& .rdrWeekDay': {
            color: '#fff',
            fontWeight: 'bold'
        },
        '& .rdrDayNumber span, .rdrStaticRangeLabel, .rdrDefinedRangesWrapper ': {
            color: '#fff'
        },
        '& .rdrDayPassive .rdrDayNumber span ': {
            color: '#aaa'
        },
        '& .rdrDateDisplayItem': {
            border: '0px',
            borderRadius: '5px',
            overflow: 'hidden'
        },
        '& .rdrDateDisplayItemActive': {
            border: '0px',
            borderRadius: '5px',
            overflow: 'hidden'
        },
        '& .rdrMonthAndYearPickers select, .rdrDateDisplayItem input, .rdrNextPrevButton': {
            color: 'white',
            backgroundColor: '#5690b4',
        },
        '& .rdrDateDisplayItemActive input': {
            backgroundColor: '#5690b4'
        },
        '& .rdrMonthName': {
            color: '#fff',
            fontWeight: 'bold'
        },
        '& .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge ': {
            backgroundColor: '#28536d'
        },
        '& .rdrInputRangeInput': {
            backgroundColor: '#5690b4',
            color: '#fff',
            borderColor: '#666'
        },
        '& .rdrDefinedRangesWrapper': {
            borderRight: '1px solid #666'
        },
        '& .rdrStaticRange': {
            borderBottom: '1px solid #666',
        },
        '& .rdrStaticRange:hover span': {
            backgroundColor: '#5690b4'
        },
        '& .rdrStaticRangeSelected span': {
            backgroundColor: '#5690b4'
        },
        '& .rdrDayDisabled': {
            backgroundColor: '#000'
        },
        '& .rdrDayDisabled .rdrDayNumber span': {
            color: '#555'
        }
    }


}))

const GameHistory = ({ selected, role, pits, zones, devices, tables, canEditResult = false, setPageNav = () => { }, expandedURL = null, setExpandedURL = () => { }, setEditGameID = () => { }, device, settings }) => {

    const styles = rootStyles()


    const [datePickerState, setDatePickerState] = useState([
        {
            startDate: new Date(moment().startOf('day').format('YYYY-MM-D HH:mm')),
            endDate: new Date(moment().endOf('day').format('YYYY-MM-D HH:mm')),
            key: 'selection',
        }
    ]);
    //const startingRange = dateRanges[0]
    const startingRange = datePickerState[0]


    //console.log(dateRanges)
    const [displayDateModal, setDisplayDateModal] = useState(false)
    const [startDate, setStartDate] = useState(moment(startingRange.startDate).format('YYYY-MM-D HH:mm'))
    const [endDate, setEndDate] = useState(moment(startingRange.endDate).format('YYYY-MM-D HH:mm'))
    const [fetchedDeviceGames, setFetchedDeviceGames] = useState(false)
    const [deviceGames, setDeviceGames] = useState(null)

    // const min = moment().endOf('month').subtract(1, 'months').format('YYYY-MM-D HH:mm')
    // const max = moment().format('YYYY-MM-D HH:mm')
    const dateRangeChange = (range) => {
        setDeviceGames(null)
        setStartDate(moment(range.startDate).startOf('day').format('YYYY-MM-D HH:mm'))
        setEndDate(moment(range.endDate).endOf('day').format('YYYY-MM-D HH:mm'))
    }

    const [selectedIndex, setSelectedIndex] = useState(selected.index)

    const dateToNano = (date) => {
        return moment(date).format('x') * 1000000
    }
    
    const deviceID = selected.index
    const gameType = device.data.type === 'game' ? device.data.gameType : null
    const gameVariant = device.data.type === 'game' ? device.data.gameVariant : null
    
    const getDeviceGames = async () => {
        if (fetchedDeviceGames) {
            return
        }
    
        const api = settings?.data.xtable.gameOnlyMode ? historyApi : tableApi
    
        setFetchedDeviceGames(true)
    
        try {
            const response = await api
                .get(
                    'lastgames/' +
                        gameType +
                        '/' +
                        gameVariant +
                        '/' +
                        deviceID +
                        '/' +
                        dateToNano(startDate) +
                        '/' +
                        dateToNano(endDate)
                )
                .json()
            setDeviceGames(response)
        } catch (e) {
            console.warn(e)
            setDeviceGames([])
        }
    }
    
    console.log('deviceGames', deviceGames)

    //////////////////////////////////////////////////////////////
    ///// reset component when new  table selection ///////////////
    //////////////////////////////////////////////////////////////

    if (selectedIndex !== selected.index) {
        setSelectedIndex(selected.index)
        dateRangeChange(startingRange)
    }

    ///////////////////////////////////////////////////////
    /////////////////////// make ui ///////////////////////
    ///////////////////////////////////////////////////////
    const getContainerObj = (type) => {
        const key = { table: tables, pit: pits, zone: zones, device: devices, casino: [{ index: 'root' }] }
        //console.log('type', type.toLowerCase())
        return key[type.toLowerCase()]
    }

    const devicesInAContainer = devices.filter(v => v.data && v.data.containerIndex && v.data.containerType && v.data.gameType &&
        typeof v.data.containerType === 'string' &&
        typeof v.data.containerIndex === 'string' &&
        typeof v.data.gameType === 'string')

    const selectedDevice = selected.type === 'device' ? devicesInAContainer.filter(v => v.index === selected.index) : devicesInAContainer.filter(v => v.data.containerIndex === selected.index)
    const showHistory = (selected.type === 'device' || selectedDevice.length > 0) ? true : false;
    const selectedObject = showHistory ? getContainerObj(selected.type).filter(v => v.index === selected.index) : null

    // console.log('selected', selected)
    // console.log('devicesInAContainer', devicesInAContainer)
    // console.log('selectedDevice', selectedDevice)
    // console.log('showHistory', showHistory)
    // console.log('selectedObject', selectedObject)

    if (showHistory && startDate && endDate) {
        getDeviceGames()
    }

    return <div style={{ backgroundColor: '#1f1f1fd6', height: '100%', width: '100%', position: 'relative' }} >

        {!showHistory && selected.type !== 'table' && <div style={{ display: 'flex', position: 'absolute', top: 0, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
            <Alert severity="info">
                <AlertTitle>Game History</AlertTitle>
                <span>Select a <b>Table</b> to view it's history</span>
            </Alert>
        </div>
        }

        {!showHistory && selectedDevice.length === 0 && get(selected, 'type', null) && <div style={{ display: 'flex', position: 'absolute', top: 0, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
            <Alert severity="info">
                <AlertTitle>Game History</AlertTitle>
                <span>No device found on this {selected.type}</span>
            </Alert>
        </div>
        }


        {showHistory && <>
            <div style={{ display: 'flex', justifyContent: 'left', height: '100px', alignItems: 'center', position: 'relative' }}>
                {!canEditResult && <Button onClick={() => { setDisplayDateModal(true) }} style={{ height: 50, backgroundColor: '#444', marginLeft: 20 }} variant="outlined" color="primary">Select Date Range</Button>}
                <div style={{ marginLeft: 20, paddingLeft: 20, borderLeft: '1px solid #ccc', fontSize: 22 }}>
                    <div>{startDate + ' - ' + endDate}</div>
                </div>
                {selectedObject && selectedObject.length > 0 && <div style={{ position: 'absolute', right: 20, textAlign: 'right' }}>
                    <Breadcrumbs selected={selected} levels={2} size={25} />
                </div>}
            </div>
            <Divider />
            {deviceGames && deviceGames.length > 0 && <ExpTable
                //rows={gameSessionUrls.map(v => { return { sessions: hexToTimeStamp(v), url:v, gameresult: Object.keys(loadedData).includes(v) ? loadedData[v] : {} } })}
                deviceGames={deviceGames}
                top={60}
                pagination
                hiddenFields={['index', 'gameresult', 'created', 'tableindex', 'pitindex', 'url']}
                link={(row) => '/posui/dashboard/gamehistory/games:' + row['gametype'] + ':' + row['index']}
                canEditResult={canEditResult}
                setPageNav={setPageNav}
                expandedURL={expandedURL}
                setExpandedURL={setExpandedURL}
                setEditGameID={setEditGameID}
            />}
            {deviceGames && deviceGames.length === 0 && <div style={{ display: 'flex', position: 'absolute', top: 0, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                <Alert severity="error">
                    <AlertTitle>Game History</AlertTitle>
                    <span>No data found for selected {selected.type} and date(s)</span>
                </Alert>
            </div>}
            {!deviceGames && <div style={{ display: 'flex', position: 'absolute', top: 0, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                <Alert icon={<CustomSpinner top={{ size: 25, thickness: 13, style: { height: 25, color: '#1e4620' } }} bottom={{ size: 25, thickness: 13, style: { height: 25, color: '#b7dfb9' } }} />} severity="success">
                    <AlertTitle>Game History</AlertTitle>
                    <span>Loading Data for this table</span>
                </Alert>
            </div>}
        </>}

        <Modal
            className={styles.modal}
            open={displayDateModal}
            onClose={(e) => { setDisplayDateModal(false) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }} >
            {/* <DateRangePicker
                open={true}
                onChange={range => dateRangeChange(range)}
                initialDateRange={startingRange}
                minDate={min}
                maxDate={max}
                definedRanges={dateRanges}
            /> */}
            <div style={{ outline: 'none' }}>
                <DateRange
                    onChange={item => {
                        //console.log('item.selection', item.selection)
                        setDatePickerState([item.selection]); dateRangeChange(item.selection);
                    }}
                    months={2}
                    shownDate={addDays(new Date(), -30)}
                    ranges={datePickerState}
                    direction="horizontal"
                    minDate={addDays(new Date(), -30)}
                    maxDate={new Date()}
                    className={styles.dateRangePicker}
                />
            </div>
        </Modal>
    </div >

}

export default GameHistory
