import React, { useState, useRef, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react' // the AG Grid React Component
import _ from 'lodash'
import moment from 'moment'
import { Pie, Bar, Line } from 'react-chartjs-2'
import { useLocation, useNavigate, useParams, Navigate } from 'react-router-dom'
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Table,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { useSubscribe, playerApi } from '../../api'
import { resultsIP, prometheusPlayer } from '../../config'
import { useAppContext } from '../../contexts/AppContext'
import Tree from '../../tree/Tree'
import useWindowSize from '../../WindowSize'
import {
    chartBackgroundColors,
    hoursLabels,
    baccaratStandardPerLabelFind,
    baccaratLucky6PerLabelFind,
    baccaratMega6PerLabelFind,
    baccaratTigerPerLabelFind,
    blackjackStandardPerLabelFind,
    blackjackAnyPairSlotPerLabelFind,
    baccaratStandardCharPerTypeLabels,
    baccaratLucky6CharPerTypeLabels,
    baccaratMega6CharPerTypeLabels,
    baccaratTigerCharPerTypeLabels,
    blackjackStandardCharPerTypeLabels,
    blackjackAnyPairSlotCharPerTypeLabels,
    DisplayBaccaratStandardBetTypeSimpleCashBreakdown,
    DisplayBaccaratLucky6BetTypeSimpleCashBreakdown,
    DisplayBaccaratMega6BetTypeSimpleCashBreakdown,
    DisplayBaccaratTigerBetTypeSimpleCashBreakdown,
    DisplayBlackjackStandardBetTypeSimpleCashBreakdown,
    DisplayBlackjackAnyPairSlotBetTypeSimpleCashBreakdown,
    DisplayBaccaratStandardBetTypeCashBreakdown,
    DisplayBaccaratLucky6BetTypeCashBreakdown,
    DisplayBaccaratMega6BetTypeCashBreakdown,
    DisplayBaccaratTigerBetTypeCashBreakdown,
    DisplayBlackjackStandardBetTypeCashBreakdown,
    DisplayBlackjackAnyPairSlotBetTypeCashBreakdown,
    DisplayBaccaratStandardBetTypeBreakdown,
    DisplayBaccaratLucky6BetTypeBreakdown,
    DisplayBaccaratMega6BetTypeBreakdown,
    DisplayBaccaratTigerBetTypeBreakdown,
    DisplayBlackjackStandardBetTypeBreakdown,
    DisplayBlackjackAnyPairSlotBetTypeBreakdown,
    DateTimeDisplay,
    formatNumber,
    formatCountString,
    formatCashString,
    formatPresicePercentString,
    formatDuration,
    findMembership,
    DEFAULT_EMPTY_USER,
} from '../Formats'
import GamesPie from '../GamesPie'
import TableHistoryChart from '../TableHistoryChart'

import '../../ag-grid/ag-grid-theme.css' // Core grid CSS, always needed
import '../../ag-grid/ag-grid.css' // Core grid CSS, always needed

const styles = {
    backToListButton: {
        marginTop: 20,
        marginBottom: 20,
        float: 'right',
    },
    pieContainer: {
        width: 200,
        height: 100,
    },
    barContainer: {
        width: 'inherit',
        height: 150,
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em',
    },
    cardTitleText: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        // color: 'white'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        // padding: '0.5vw 0.7vw',
    },
    cardBodyText: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
    cardSubtitle: {
        margin: '0 15px',
        color: 'darkgrey',
    },
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red',
    }),
    profileDetailsText: {
        padding: '0.9em',
    },
    profilePicture: {
        marginLeft: 25,
    },
    profileText: {
        padding: '0.5em !important',
        fontWeight: 500,
    },
    profileRow: {
        marginBottom: '1em !important',
    },
    profileContainer: {
        maxWidth: 3000,
    },
    tabPane: {
        backgroundColor: 'transparent',
    },
    playerPicture: {
        width: '-webkit-fill-available',
        padding: '0.2em',
    },
}

const Player = () => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        statusSocket.readyState === WebSocket.OPEN

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')
    let { playerID, gameType } = useParams()

    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }

    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // fetched data
    const [playerReport, setPlayerReport] = useState(null)
    const [fetchedPlayerReport, setFetchedPlayerReport] = useState(false)
    const [playerGames, setPlayerGames] = useState(null)
    const [fetchedPlayerGames, setFetchedPlayerGames] = useState(false)
    const [playerSummary, setPlayerSummary] = useState(null)
    const [fetchedPlayerSummary, setFetchedPlayerSummary] = useState(false)
    const [fetchedMemberships, setFetchedMemberships] = useState(false)
    const [memberships, setMemberships] = useState(null)
    const [minLoadTime, setMinLoadTime] = useState(false)

    // built charts
    const [builtCharts, setBuiltCharts] = useState({})
    const setBuiltChartProxy = (chartID, value) => {
        setBuiltCharts((prevBuilt) => ({
            ...prevBuilt,
            [chartID]: value,
        }))
    }

    // tab control
    const [selectedTab, setSelectedTab] = useState('1')

    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter((v) => v !== 'push')
        const oldParams = urlParams.toString()
        propKeys.forEach((k) => {
            if (props[k] === null) {
                urlParams.delete(k)
                window.localStorage.removeItem('url:' + k)
                return
            }
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange =
            propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        if (urlChange || props.push) {
            navigate('/xtable/player/' + playerID + '/' + gameType + '?' + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                setPlayerGames(null)
                setFetchedPlayerGames(false)
                setFetchedPlayerSummary(false)
                setFetchedPlayerReport(false)
            }
            setBuiltCharts({})
        }
    }

    const { gameVariantFilter, setGameVariantFilter } = useAppContext()

    const handleChange = (e) => {
        setGameVariantFilter(e.target.value)
    }

    // GRID
    const gamesGridRef = useRef() // Optional - for accessing Grid's API

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => {
        return {
            // flex: 1,
            minWidth: 10,
            filter: true,
            sortable: true,
            // floatingFilter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }
    }, [])
    // GRID END

    const isMoreThanOneDay = numberOfDays > 0
    const isLive = startDate === startOfToday && endDate === endOfToday ? true : false
    const isMoreThanOneDayOrNotLive = isMoreThanOneDay || (numberOfDays === 0 && !isLive)

    const dateChange = (start, end) => {
        if (start === startDate && end === endDate) return
        setStartDate(start)
        setEndDate(end)
        clearFetch({ startDate: start, endDate: end })
    }

    const getHoursWagerData = (playerReport) => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'wager',
                data: hoursLabels.map((hr) => playerReport.reduce((prev, next) => prev + next.hourlyBets['h' + hr], 0)),
                backgroundColor: chartBackgroundColors[4],
                borderColor: chartBackgroundColors[4],
            },
        ],
    })

    const getHoursWinLossData = (playerReport) => {
        const data = hoursLabels.map((hr) => playerReport.reduce((prev, next) => prev + next.hourlyProfit['h' + hr], 0))
        return {
            labels: hoursLabels,
            datasets: [
                {
                    label: 'win/loss',
                    data,
                    backgroundColor: data.map((v) => (v > 0 ? 'chartreuse' : 'red')),
                    borderColor: chartBackgroundColors[6],
                    order: 0,
                },
            ],
        }
    }

    const getHoursGamesData = (playerReport) => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'games',
                data: hoursLabels.map((hr) =>
                    playerReport.reduce((prev, next) => prev + next.hourlyGames['h' + hr], 0)
                ),
                // backgroundColor: chartBackgroundColors[6],
                borderColor: chartBackgroundColors[6],
            },
        ],
    })

    // overview
    const queryBetsHistory = (playerID) => 'sum({__name__=~"player_' + playerID + '_bets"})'
    const queryProfitHistory = (playerID) => 'sum({__name__=~"player_' + playerID + '_profit"})'
    // baccarat standard
    const baccaratStandardQueryBetsBase = (playerID) =>
        'sum({__name__=~"player_baccarat_standard_' + playerID + '_bets_'
    const baccaratStandardTotalBetQuery = (playerID) =>
        baccaratStandardQueryBetsBase(playerID) +
        'banker"}) + ' +
        baccaratStandardQueryBetsBase(playerID) +
        'player"}) + ' +
        baccaratStandardQueryBetsBase(playerID) +
        'tie"}) + ' +
        baccaratStandardQueryBetsBase(playerID) +
        'banker_pair"}) + ' +
        baccaratStandardQueryBetsBase(playerID) +
        'player_pair"}) + '
    const baccaratStandardQueryBetHistory = (playerID) => [
        baccaratStandardQueryBetsBase(playerID) + 'banker"})',
        baccaratStandardQueryBetsBase(playerID) + 'player"})',
        baccaratStandardQueryBetsBase(playerID) + 'tie"})',
        baccaratStandardQueryBetsBase(playerID) + 'banker_pair"})',
        baccaratStandardQueryBetsBase(playerID) + 'player_pair"})',
        baccaratStandardTotalBetQuery(playerID),
    ]
    // baccarat lucky6
    const baccaratLucky6QueryBetsBase = (playerID) => 'sum({__name__=~"player_baccarat_lucky6_' + playerID + '_bets_'
    const baccaratLucky6TotalBetQuery = (playerID) =>
        baccaratLucky6QueryBetsBase(playerID) +
        'banker"}) + ' +
        baccaratLucky6QueryBetsBase(playerID) +
        'player"}) + ' +
        baccaratLucky6QueryBetsBase(playerID) +
        'tie"}) + ' +
        baccaratLucky6QueryBetsBase(playerID) +
        'banker_pair"}) + ' +
        baccaratLucky6QueryBetsBase(playerID) +
        'player_pair"}) + ' +
        baccaratLucky6QueryBetsBase(playerID) +
        'lucky6"})'
    const baccaratLucky6QueryBetHistory = (playerID) => [
        baccaratLucky6QueryBetsBase(playerID) + 'banker"})',
        baccaratLucky6QueryBetsBase(playerID) + 'player"})',
        baccaratLucky6QueryBetsBase(playerID) + 'tie"})',
        baccaratLucky6QueryBetsBase(playerID) + 'banker_pair"})',
        baccaratLucky6QueryBetsBase(playerID) + 'player_pair"})',
        baccaratLucky6QueryBetsBase(playerID) + 'lucky6"})',
        baccaratLucky6TotalBetQuery(playerID),
    ]
    // baccarat lucky6Slot
    const baccaratLucky6SlotQueryBetsBase = (playerID) =>
        'sum({__name__=~"player_baccarat_lucky6Slot_' + playerID + '_bets_'
    const baccaratLucky6SlotTotalBetQuery = (playerID) =>
        baccaratLucky6SlotQueryBetsBase(playerID) +
        'banker"}) + ' +
        baccaratLucky6SlotQueryBetsBase(playerID) +
        'player"}) + ' +
        baccaratLucky6SlotQueryBetsBase(playerID) +
        'tie"}) + ' +
        baccaratLucky6SlotQueryBetsBase(playerID) +
        'banker_pair"}) + ' +
        baccaratLucky6SlotQueryBetsBase(playerID) +
        'player_pair"}) + ' +
        baccaratLucky6SlotQueryBetsBase(playerID) +
        'lucky6"})'
    const baccaratLucky6SlotQueryBetHistory = (playerID) => [
        baccaratLucky6SlotQueryBetsBase(playerID) + 'banker"})',
        baccaratLucky6SlotQueryBetsBase(playerID) + 'player"})',
        baccaratLucky6SlotQueryBetsBase(playerID) + 'tie"})',
        baccaratLucky6SlotQueryBetsBase(playerID) + 'banker_pair"})',
        baccaratLucky6SlotQueryBetsBase(playerID) + 'player_pair"})',
        baccaratLucky6SlotQueryBetsBase(playerID) + 'lucky6"})',
        baccaratLucky6SlotTotalBetQuery(playerID),
    ]
    // baccarat mega6
    const baccaratMega6QueryBetsBase = (playerID) => 'sum({__name__=~"player_baccarat_mega6_' + playerID + '_bets_'
    const baccaratMega6TotalBetQuery = (playerID) =>
        baccaratMega6QueryBetsBase(playerID) +
        'banker"}) + ' +
        baccaratMega6QueryBetsBase(playerID) +
        'player"}) + ' +
        baccaratMega6QueryBetsBase(playerID) +
        'tie"}) + ' +
        baccaratMega6QueryBetsBase(playerID) +
        'banker_pair"}) + ' +
        baccaratMega6QueryBetsBase(playerID) +
        'player_pair"}) + ' +
        baccaratMega6QueryBetsBase(playerID) +
        'mega6"})'
    const baccaratMega6QueryBetHistory = (playerID) => [
        baccaratMega6QueryBetsBase(playerID) + 'banker"})',
        baccaratMega6QueryBetsBase(playerID) + 'player"})',
        baccaratMega6QueryBetsBase(playerID) + 'tie"})',
        baccaratMega6QueryBetsBase(playerID) + 'banker_pair"})',
        baccaratMega6QueryBetsBase(playerID) + 'player_pair"})',
        baccaratMega6QueryBetsBase(playerID) + 'mega6"})',
        baccaratMega6TotalBetQuery(playerID),
    ]
    // baccarat tiger
    const baccaratTigerQueryBetsBase = (playerID) => 'sum({__name__=~"player_baccarat_tiger_' + playerID + '_bets_'
    const baccaratTigerTotalBetQuery = (playerID) =>
        baccaratTigerQueryBetsBase(playerID) +
        'banker"}) + ' +
        baccaratTigerQueryBetsBase(playerID) +
        'player"}) + ' +
        baccaratTigerQueryBetsBase(playerID) +
        'tie"}) + ' +
        baccaratTigerQueryBetsBase(playerID) +
        'tiger_tie"}) + ' +
        baccaratTigerQueryBetsBase(playerID) +
        'tiger_pair"}) + ' +
        baccaratTigerQueryBetsBase(playerID) +
        'tiger_big"}) + ' +
        baccaratTigerQueryBetsBase(playerID) +
        'tiger_small"}) + ' +
        baccaratTigerQueryBetsBase(playerID) +
        'tiger"})'
    const baccaratTigerQueryBetHistory = (playerID) => [
        baccaratTigerQueryBetsBase(playerID) + 'banker"})',
        baccaratTigerQueryBetsBase(playerID) + 'player"})',
        baccaratTigerQueryBetsBase(playerID) + 'tie"})',
        baccaratTigerQueryBetsBase(playerID) + 'tiger_tie"})',
        baccaratTigerQueryBetsBase(playerID) + 'tiger_pair"})',
        baccaratTigerQueryBetsBase(playerID) + 'tiger_big"})',
        baccaratTigerQueryBetsBase(playerID) + 'tiger_small"})',
        baccaratTigerQueryBetsBase(playerID) + 'tiger"})',
        baccaratTigerTotalBetQuery(playerID),
    ]
    // blackjack standard
    const blackjackStandardQueryBetsBase = (playerID) =>
        'sum({__name__=~"player_blackjack_standard_' + playerID + '_bets_'
    const blackjackStandardTotalBetQuery = (playerID) =>
        blackjackStandardQueryBetsBase(playerID) +
        'base"}) + ' +
        blackjackStandardQueryBetsBase(playerID) +
        'doubledown"}) + ' +
        blackjackStandardQueryBetsBase(playerID) +
        'insurance"}) + ' +
        blackjackStandardQueryBetsBase(playerID) +
        'pair"}) + ' +
        blackjackStandardQueryBetsBase(playerID) +
        'split"})'
    const blackjackStandardQueryBetHistory = (playerID) => [
        blackjackStandardQueryBetsBase(playerID) + 'base"})',
        blackjackStandardQueryBetsBase(playerID) + 'doubledown"})',
        blackjackStandardQueryBetsBase(playerID) + 'insurance"})',
        blackjackStandardQueryBetsBase(playerID) + 'pair"})',
        blackjackStandardQueryBetsBase(playerID) + 'split"})',
        blackjackStandardTotalBetQuery(playerID),
    ]
    // blackjack anyPairSlot
    const blackjackAnyPairSlotQueryBetsBase = (playerID) =>
        'sum({__name__=~"player_blackjack_anyPairSlot_' + playerID + '_bets_'
    const blackjackAnyPairSlotTotalBetQuery = (playerID) =>
        blackjackAnyPairSlotQueryBetsBase(playerID) +
        'base"}) + ' +
        blackjackAnyPairSlotQueryBetsBase(playerID) +
        'pair"}) + ' +
        blackjackAnyPairSlotQueryBetsBase(playerID) +
        'anyPair"}) + ' +
        blackjackAnyPairSlotQueryBetsBase(playerID) +
        'split"}) + ' +
        blackjackAnyPairSlotQueryBetsBase(playerID) +
        'doubledown"}) + ' +
        blackjackAnyPairSlotQueryBetsBase(playerID) +
        'insurance"}) + ' +
        blackjackAnyPairSlotQueryBetsBase(playerID) +
        'bonus"})'
    const blackjackAnyPairSlotQueryBetHistory = (playerID) => [
        blackjackAnyPairSlotQueryBetsBase(playerID) + 'base"})',
        blackjackAnyPairSlotQueryBetsBase(playerID) + 'pair"})',
        blackjackAnyPairSlotQueryBetsBase(playerID) + 'anyPair"})',
        blackjackAnyPairSlotQueryBetsBase(playerID) + 'split"})',
        blackjackAnyPairSlotQueryBetsBase(playerID) + 'doubledown"})',
        blackjackAnyPairSlotQueryBetsBase(playerID) + 'insurance"})',
        blackjackAnyPairSlotQueryBetsBase(playerID) + 'bonus"})',
        blackjackAnyPairSlotTotalBetQuery(playerID),
    ]

    const findPlayerSeat = (seats, playerID) => seats.filter((entry) => entry.player === playerID)

    const baccaratCalculatePlayerADT = (player) => {
        const playerObj =
            gameVariantFilter === 'baccaratStandard'
                ? player.baccaratStandard
                : gameVariantFilter === 'baccaratLucky6'
                    ? player.baccaratLucky6
                    : gameVariantFilter === 'baccaratLucky6Slot'
                        ? player.baccaratLucky6Slot
                        : gameVariantFilter === 'baccaratMega6'
                            ? player.baccaratMega6
                            : {}

        const _banker = (playerObj.betsPerType.banker * 1.06) / 100
        const _player = (playerObj.betsPerType.player * 1.24) / 100
        const _tie = (playerObj.betsPerType.tie * 14.36) / 100
        const _bankerPair = (playerObj.betsPerType.bankerPair * 10.36) / 100
        const _playerPair = (playerObj.betsPerType.playerPair * 10.36) / 100
        const _lucky6 = (player.baccaratLucky6.betsPerType.lucky6 * 16.0) / 100
        const _mega6 = (player.baccaratMega6.betsPerType.mega6 * 16.0) / 100

        return gameVariantFilter === 'baccaratStandard'
            ? (_banker + _player + _tie + _bankerPair + _playerPair).toFixed(0)
            : gameVariantFilter === 'baccaratLucky6' || gameVariantFilter === 'baccaratLucky6Slot'
                ? (_banker + _player + _tie + _bankerPair + _playerPair + _lucky6).toFixed(0)
                : gameVariantFilter === 'baccaratMega6'
                    ? (_banker + _player + _tie + _bankerPair + _playerPair + _mega6).toFixed(0)
                    : '-'
    }
    const blackjackCalculatePlayerADT = (player) => {
        const playerObj =
            gameVariantFilter === 'blackjackStandard'
                ? player.blackjackStandard
                : gameVariantFilter === 'blackjackAnyPairSlot'
                    ? player.blackjackAnyPairSlot
                    : {}

        const _base = (playerObj.betsPerType.base * 0.868) / 100
        const _doubleDown = (playerObj.betsPerType.doubleDown * 0.868) / 100
        const _insurance = (playerObj.betsPerType.insurance * 0.868) / 100
        const _pair = (playerObj.betsPerType.pair * 0.868) / 100
        const _split = (playerObj.betsPerType.split * 0.868) / 100

        const _anyPair = (player.blackjackAnyPairSlot.betsPerType.anyPair * 0.868) / 100
        const _bonus = (player.blackjackAnyPairSlot.betsPerType.bonus * 0.868) / 100

        return gameVariantFilter === 'blackjackStandard'
            ? (_base + _doubleDown + _insurance + _pair + _split).toFixed(0)
            : gameVariantFilter === 'blackjackAnyPairSlot'
                ? (_base + _pair + _anyPair + _split + _doubleDown + _insurance + _bonus).toFixed(0)
                : '-'
    }

    const getPlayerReport = async () => {
        if (fetchedPlayerReport) {
            return
        }

        setFetchedPlayerReport(true)
        setPlayerReport(null)
        try {
            const response = await playerApi
                .get('report/overview/player/' + playerID + '/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            console.log('overview', response)
            setPlayerReport(response)
        } catch (e) {
            console.warn(e)
            setPlayerReport([])
        }
    }

    const getPlayerGames = async () => {
        if (fetchedPlayerGames) {
            return
        }

        setFetchedPlayerGames(true)
        try {
            let gameTypeURL = 'overview'
            if (gameType === 'baccarat') {
                gameTypeURL = 'baccarat'
            }
            if (gameType === 'blackjack') {
                gameTypeURL = 'blackjack'
            }
            const response = await playerApi
                .get(
                    'report/' +
                    gameTypeURL +
                    '/player/games/' +
                    playerID +
                    '/' +
                    startDate * 1000000000 +
                    '/' +
                    endDate * 1000000000
                )
                .json()
            console.log('games', gameTypeURL, response)
            setTimeout(() => {
                setPlayerGames(response)
            }, 800)
        } catch (e) {
            console.warn(e)
            setPlayerGames([])
        }
    }

    const getMemberships = async () => {
        if (fetchedMemberships) {
            return
        }

        setFetchedMemberships(true)
        try {
            const response = await playerApi.get('memberships').json()
            setMemberships(response)
        } catch (e) {
            console.warn(e)
            setMemberships([])
        }
    }

    getMemberships()

    const getPlayerSummary = async () => {
        if (fetchedPlayerSummary) {
            return
        }

        setFetchedPlayerSummary(true)
        try {
            const response = await playerApi.get('summary/player/' + playerID).json()
            console.log('player summary', response)
            setPlayerSummary(response)
        } catch (e) {
            console.warn(e)
            setPlayerSummary({})
        }
    }

    if (memberships && Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        if (!fetchedPlayerReport) {
            clearFetch({})
        }
        getPlayerReport()
        getPlayerSummary()
    }

    if (selectedTab === '2' && !fetchedPlayerGames) {
        getPlayerGames()
    }

    if (resizing && builtCharts && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    const treeLoading = !active || !zones || !pits || !devices || !tables || !status || !minLoadTime

    const overviewColumnDefsGames = [
        {
            field: 'ID',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'gameType',
            headerName: 'Game Type',
            headerTooltip: 'Game Type',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'gameVariant',
            headerName: 'Game Variant',
            headerTooltip: 'Game Variant',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'ended',
            headerName: 'Time',
            headerTooltip: 'Time',
            filter: 'agDateColumnFilter',
            suppressMenu: true,
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
        },
        {
            field: 'dealer',
            headerName: 'Dealer',
            headerTooltip: 'Dealer',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'bet',
            headerName: 'Total Wager',
            headerTooltip: 'Total Wager',
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'winLoss',
            headerName: 'WinLoss',
            headerTooltip: 'WinLoss',
            filter: 'agNumberColumnFilter',
            cellStyle: (params) => styles.cashValue(params.value),
        },
    ]

    const columnDefsGames = overviewColumnDefsGames

    const player = playerReport

    const getGameType = (gameVariantFilter) => {
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                return 'baccarat'
            case 'baccaratLucky6':
                return 'baccarat'
            case 'baccaratLucky6Slot':
                return 'baccarat'
            case 'baccaratMega6':
                return 'baccarat'
            case 'baccaratTiger':
                return 'baccarat'
            case 'blackjackStandard':
                return 'blackjack'
            case 'blackjackAnyPairSlot':
                return 'blackjack'
            case 'rouletteStandard':
                return 'roulette'
            case 'warEtg':
                return 'war'
            default:
        }
    }

    const getGameVariant = (gameVariantFilter) => {
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                return 'standard'
            case 'baccaratLucky6':
                return 'lucky6'
            case 'baccaratLucky6Slot':
                return 'lucky6Slot'
            case 'baccaratMega6':
                return 'mega6'
            case 'baccaratTiger':
                return 'tiger'
            case 'blackjackStandard':
                return 'standard'
            case 'blackjackAnyPairSlot':
                return 'anyPairSlot'
            case 'rouletteStandard':
                return 'standard'
            case 'warEtg':
                return 'etg'
            default:
        }
    }

    const playerGamesMapped =
        playerGames && fetchedPlayerGames && player && gameVariantFilter
            ? playerGames
                .filter(
                    (game) =>
                        getGameType(gameVariantFilter) === game.gameType &&
                        getGameVariant(gameVariantFilter) === game.gameVariant
                )
                .map((game) => {
                    const seat = _.get(findPlayerSeat(game.seats, player.id), 0, null)
                    if (!seat) {
                        return null
                    }
                    return {
                        ID: game.id,
                        gameType: game.gameType,
                        gameVariant: game.gameVariant,
                        ended: game.ended,
                        dealer: game.dealer,
                        bet: seat.bet,
                        winLoss: seat.profit,
                    }
                })
            : null

    // this detects the hotreload from react
    if (treeLoading && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    const fluid = width < 1900

    if (settings && !settings.data.xtable.sections.includes('players')) {
        return <Navigate to='/dashboard' />
    }

    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: 320 }}>
                <Tree
                    loading={treeLoading}
                    noAutoUpdate={true}
                    dateChange={dateChange}
                    startDate={startDate}
                    endDate={endDate}
                    fluid={fluid}
                    hideLocation
                />
            </Grid>
            <Grid item xs>
                <Container fluid={fluid}>
                    <Box width='100%' display='flex' alignItems='center' justifyContent='end'>
                        <FormControl sx={{ m: 1, minWidth: 250 }} size='small'>
                            <InputLabel>Game Type & Variant</InputLabel>
                            <Select label='Game Type & Variant' value={gameVariantFilter} onChange={handleChange}>
                                <MenuItem value='baccaratStandard'>Baccarat - Standard</MenuItem>
                                <MenuItem value='baccaratLucky6'>Baccarat - Lucky 6</MenuItem>
                                <MenuItem value='baccaratLucky6Slot'>Baccarat - Lucky 6 Slot</MenuItem>
                                <MenuItem value='baccaratMega6'>Baccarat - Mega 6</MenuItem>
                                <MenuItem value='baccaratTiger'>Baccarat - Tiger</MenuItem>
                                <MenuItem value='blackjackStandard'>Blackjack - Standard</MenuItem>
                                <MenuItem value='blackjackAnyPairSlot'>Blackjack - Any Pair with Slot</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Container>

                {resizing && <LinearProgress />}
                {!resizing && !playerSummary && (
                    <Container fluid={fluid}>
                        <Row xs='1'>
                            <Col>
                                <h3>Fetching players report...</h3>
                                <LinearProgress />
                            </Col>
                        </Row>
                    </Container>
                )}
                {!resizing && playerSummary && player && (
                    <>
                        <Container fluid={fluid}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader style={styles.cardHeader}>
                                            <span style={styles.cardTitleText}>Player Profile</span>
                                        </CardHeader>
                                        <CardBody style={styles.cardBody}>
                                            {(() => {
                                                const cu = findMembership(memberships, playerID)
                                                // avoid image cache
                                                const timestamp = new Date().getTime()

                                                return (
                                                    <Row style={styles.profileDetailsText}>
                                                        <Col xs={3}>
                                                            {cu.id !== playerID && (
                                                                <img
                                                                    alt={'player'}
                                                                    style={styles.playerPicture}
                                                                    src={`http://${resultsIP}/players/${playerID}.png?t=${timestamp}`}
                                                                    onError={(e) => (e.target.src = DEFAULT_EMPTY_USER)}
                                                                />
                                                            )}
                                                            {playerID !== '' && cu.id === playerID && (
                                                                <img
                                                                    alt={'player'}
                                                                    style={styles.playerPicture}
                                                                    src={cu.picture}
                                                                />
                                                            )}
                                                            {playerID === '' && (
                                                                <img
                                                                    alt={'player'}
                                                                    style={styles.playerPicture}
                                                                    src={cu.picture}
                                                                />
                                                            )}
                                                        </Col>
                                                        <Col xs={5}>
                                                            <Row style={styles.profileRow}>
                                                                <Col xs={5}>Name</Col>
                                                                <Col xs={7} style={styles.profileText}>
                                                                    {cu
                                                                        ? cu.name !== 'unknown'
                                                                            ? cu.name
                                                                            : playerID
                                                                        : playerID}
                                                                </Col>
                                                            </Row>
                                                            <Row style={styles.profileRow}>
                                                                <Col xs={5}>Membership ID</Col>
                                                                <Col xs={7} style={styles.profileText}>
                                                                    {cu ? cu.id : '<N/A>'}
                                                                </Col>
                                                            </Row>
                                                            <Row style={styles.profileRow}>
                                                                <Col xs={5}>Gender</Col>
                                                                <Col xs={7} style={styles.profileText}>
                                                                    {cu.gender
                                                                        ? cu.gender
                                                                        : player
                                                                            ? player.gender
                                                                            : 'N/A'}
                                                                </Col>
                                                            </Row>
                                                            <Row style={styles.profileRow}>
                                                                <Col xs={5}>Age</Col>
                                                                <Col xs={7} style={styles.profileText}>
                                                                    {cu.age ? cu.age : player ? player.age : 'N/A'}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <GamesPie
                                                                gameVariantFilter={gameVariantFilter}
                                                                baccaratStandardGames={player.baccaratStandard.games}
                                                                baccaratLucky6Games={player.baccaratLucky6.games}
                                                                baccaratLucky6SlotGames={player.baccaratLucky6Slot.games}
                                                                baccaratMega6Games={player.baccaratMega6.games}
                                                                baccaratTigerGames={player.baccaratTiger.games}
                                                                blackjackStandardGames={player.blackjackStandard.games}
                                                                blackjackAnyPairSlotGames={
                                                                    player.blackjackAnyPairSlot.games
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )
                                            })()}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid={fluid}>
                            <Row xs='1'>
                                <Col>
                                    <Nav tabs style={{ margin: '15px 30px 0' }}>
                                        <NavItem>
                                            <NavLink
                                                className={selectedTab === '1' ? 'active' : ''}
                                                onClick={() => setSelectedTab('1')}
                                            >
                                                Profile
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={selectedTab === '2' ? 'active' : ''}
                                                onClick={() => {
                                                    setSelectedTab('2')
                                                }}
                                            >
                                                Game History
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className=''
                                                onClick={() => {
                                                    navigate(
                                                        '/xtable/players?startDate=' + startDate + '&endDate=' + endDate
                                                    )
                                                }}
                                            >
                                                Back to players list
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={selectedTab}>
                                        <TabPane tabId='1' style={styles.tabPane}>
                                            <Container fluid={fluid}>
                                                {gameVariantFilter === 'baccaratStandard' &&
                                                    isMoreThanOneDayOrNotLive &&
                                                    player &&
                                                    player.baccaratStandard.bets > 0 && (
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wager History
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <TableHistoryChart
                                                                            setBuiltChart={(value) =>
                                                                                setBuiltChartProxy(
                                                                                    player.id + 'wagerhistory',
                                                                                    value
                                                                                )
                                                                            }
                                                                            builtChart={
                                                                                builtCharts[player.id + 'wagerhistory']
                                                                            }
                                                                            findInLabelMap={
                                                                                baccaratStandardPerLabelFind
                                                                            }
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            prometheus={prometheusPlayer}
                                                                            query={baccaratStandardQueryBetHistory(
                                                                                player.id
                                                                            )}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratStandard' &&
                                                    player &&
                                                    player.baccaratStandard.bets > 0 && (
                                                        <Row xs='3'>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wagers
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratStandardCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Wagers',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .betsPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .betsPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .betsPerType.tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .betsPerType
                                                                                                        .bankerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .betsPerType
                                                                                                        .playerPair
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratStandardBetTypeSimpleCashBreakdown
                                                                                banker={
                                                                                    player.baccaratStandard.betsPerType
                                                                                        .banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratStandard.betsPerType
                                                                                        .player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratStandard.betsPerType
                                                                                        .tie
                                                                                }
                                                                                bankerPair={
                                                                                    player.baccaratStandard.betsPerType
                                                                                        .bankerPair
                                                                                }
                                                                                playerPair={
                                                                                    player.baccaratStandard.betsPerType
                                                                                        .playerPair
                                                                                }
                                                                                total={player.baccaratStandard.bets}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Win/Loss
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratStandardCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Win/Loss',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .profitPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .profitPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .profitPerType
                                                                                                        .tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .profitPerType
                                                                                                        .bankerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratStandard
                                                                                                        .profitPerType
                                                                                                        .playerPair
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratStandardBetTypeCashBreakdown
                                                                                banker={
                                                                                    player.baccaratStandard
                                                                                        .profitPerType.banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratStandard
                                                                                        .profitPerType.player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratStandard
                                                                                        .profitPerType.tie
                                                                                }
                                                                                bankerPair={
                                                                                    player.baccaratStandard
                                                                                        .profitPerType.bankerPair
                                                                                }
                                                                                playerPair={
                                                                                    player.baccaratStandard
                                                                                        .profitPerType.playerPair
                                                                                }
                                                                                total={player.baccaratStandard.profit}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Bets Counts
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        {player.bets > 0 && (
                                                                            <div styles={styles.pieContainer}>
                                                                                <Pie
                                                                                    data={{
                                                                                        labels: baccaratStandardCharPerTypeLabels,
                                                                                        datasets: [
                                                                                            {
                                                                                                label: 'Bets Counts',
                                                                                                data: [
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratStandard
                                                                                                            .betCountPerType
                                                                                                            .banker
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratStandard
                                                                                                            .betCountPerType
                                                                                                            .player
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratStandard
                                                                                                            .betCountPerType
                                                                                                            .tie
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratStandard
                                                                                                            .betCountPerType
                                                                                                            .bankerPair
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratStandard
                                                                                                            .betCountPerType
                                                                                                            .playerPair
                                                                                                    ),
                                                                                                ],
                                                                                                backgroundColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderWidth: 1,
                                                                                            },
                                                                                        ],
                                                                                    }}
                                                                                    options={{
                                                                                        plugins: {
                                                                                            legend: false,
                                                                                        },
                                                                                        responsive: true,
                                                                                        maintainAspectRatio: true,
                                                                                    }}
                                                                                />
                                                                                <DisplayBaccaratStandardBetTypeBreakdown
                                                                                    banker={formatCountString(
                                                                                        player.baccaratStandard
                                                                                            .betCountPerType.banker
                                                                                    )}
                                                                                    player={formatCountString(
                                                                                        player.baccaratStandard
                                                                                            .betCountPerType.player
                                                                                    )}
                                                                                    tie={formatCountString(
                                                                                        player.baccaratStandard
                                                                                            .betCountPerType.tie
                                                                                    )}
                                                                                    bankerPair={formatCountString(
                                                                                        player.baccaratStandard
                                                                                            .betCountPerType.bankerPair
                                                                                    )}
                                                                                    playerPair={formatCountString(
                                                                                        player.baccaratStandard
                                                                                            .betCountPerType.playerPair
                                                                                    )}
                                                                                    total={formatCountString(
                                                                                        player.baccaratStandarad
                                                                                            .betCount
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {player.bets === 0 && (
                                                                            <div>
                                                                                <h4>No data</h4>
                                                                            </div>
                                                                        )}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratLucky6' &&
                                                    isMoreThanOneDayOrNotLive &&
                                                    player &&
                                                    player.baccaratLucky6.bets > 0 && (
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wager History
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <TableHistoryChart
                                                                            setBuiltChart={(value) =>
                                                                                setBuiltChartProxy(
                                                                                    player.id + 'wagerhistory',
                                                                                    value
                                                                                )
                                                                            }
                                                                            builtChart={
                                                                                builtCharts[player.id + 'wagerhistory']
                                                                            }
                                                                            findInLabelMap={baccaratLucky6PerLabelFind}
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            prometheus={prometheusPlayer}
                                                                            query={baccaratLucky6QueryBetHistory(
                                                                                player.id
                                                                            )}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratLucky6' &&
                                                    player &&
                                                    player.baccaratLucky6.bets > 0 && (
                                                        <Row xs='3'>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wagers
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratLucky6CharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Wagers',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .betsPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .betsPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .betsPerType.tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .betsPerType
                                                                                                        .bankerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .betsPerType
                                                                                                        .playerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .betsPerType
                                                                                                        .lucky6
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratLucky6BetTypeSimpleCashBreakdown
                                                                                banker={
                                                                                    player.baccaratLucky6.betsPerType
                                                                                        .banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratLucky6.betsPerType
                                                                                        .player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratLucky6.betsPerType
                                                                                        .tie
                                                                                }
                                                                                bankerPair={
                                                                                    player.baccaratLucky6.betsPerType
                                                                                        .bankerPair
                                                                                }
                                                                                playerPair={
                                                                                    player.baccaratLucky6.betsPerType
                                                                                        .playerPair
                                                                                }
                                                                                lucky6={
                                                                                    player.baccaratLucky6.betsPerType
                                                                                        .lucky6
                                                                                }
                                                                                total={player.baccaratLucky6.bets}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Win/Loss
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratLucky6CharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Win/Loss',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .profitPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .profitPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .profitPerType
                                                                                                        .tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .profitPerType
                                                                                                        .bankerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .profitPerType
                                                                                                        .playerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6
                                                                                                        .profitPerType
                                                                                                        .lucky6
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratLucky6BetTypeCashBreakdown
                                                                                banker={
                                                                                    player.baccaratLucky6.profitPerType
                                                                                        .banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratLucky6.profitPerType
                                                                                        .player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratLucky6.profitPerType
                                                                                        .tie
                                                                                }
                                                                                bankerPair={
                                                                                    player.baccaratLucky6.profitPerType
                                                                                        .bankerPair
                                                                                }
                                                                                playerPair={
                                                                                    player.baccaratLucky6.profitPerType
                                                                                        .playerPair
                                                                                }
                                                                                lucky6={
                                                                                    player.baccaratLucky6.profitPerType
                                                                                        .lucky6
                                                                                }
                                                                                total={player.baccaratLucky6.profit}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Bets Counts
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        {player.bets > 0 && (
                                                                            <div styles={styles.pieContainer}>
                                                                                <Pie
                                                                                    data={{
                                                                                        labels: baccaratLucky6CharPerTypeLabels,
                                                                                        datasets: [
                                                                                            {
                                                                                                label: 'Bets Counts',
                                                                                                data: [
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6
                                                                                                            .betCountPerType
                                                                                                            .banker
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6
                                                                                                            .betCountPerType
                                                                                                            .player
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6
                                                                                                            .betCountPerType
                                                                                                            .tie
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6
                                                                                                            .betCountPerType
                                                                                                            .bankerPair
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6
                                                                                                            .betCountPerType
                                                                                                            .playerPair
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6
                                                                                                            .betCountPerType
                                                                                                            .lucky6
                                                                                                    ),
                                                                                                ],
                                                                                                backgroundColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderWidth: 1,
                                                                                            },
                                                                                        ],
                                                                                    }}
                                                                                    options={{
                                                                                        plugins: {
                                                                                            legend: false,
                                                                                        },
                                                                                        responsive: true,
                                                                                        maintainAspectRatio: true,
                                                                                    }}
                                                                                />
                                                                                <DisplayBaccaratLucky6BetTypeBreakdown
                                                                                    banker={formatCountString(
                                                                                        player.baccaratLucky6
                                                                                            .betCountPerType.banker
                                                                                    )}
                                                                                    player={formatCountString(
                                                                                        player.baccaratLucky6
                                                                                            .betCountPerType.player
                                                                                    )}
                                                                                    tie={formatCountString(
                                                                                        player.baccaratLucky6
                                                                                            .betCountPerType.tie
                                                                                    )}
                                                                                    bankerPair={formatCountString(
                                                                                        player.baccaratLucky6
                                                                                            .betCountPerType.bankerPair
                                                                                    )}
                                                                                    playerPair={formatCountString(
                                                                                        player.baccaratLucky6
                                                                                            .betCountPerType.playerPair
                                                                                    )}
                                                                                    lucky6={formatCountString(
                                                                                        player.baccaratLucky6
                                                                                            .betCountPerType.lucky6
                                                                                    )}
                                                                                    total={formatCountString(
                                                                                        player.baccaratLucky6.betCount
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {player.bets === 0 && (
                                                                            <div>
                                                                                <h4>No data</h4>
                                                                            </div>
                                                                        )}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratLucky6Slot' &&
                                                    isMoreThanOneDayOrNotLive &&
                                                    player &&
                                                    player.baccaratLucky6Slot.bets > 0 && (
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wager History
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <TableHistoryChart
                                                                            setBuiltChart={(value) =>
                                                                                setBuiltChartProxy(
                                                                                    player.id + 'wagerhistory',
                                                                                    value
                                                                                )
                                                                            }
                                                                            builtChart={
                                                                                builtCharts[player.id + 'wagerhistory']
                                                                            }
                                                                            findInLabelMap={baccaratLucky6PerLabelFind}
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            prometheus={prometheusPlayer}
                                                                            query={baccaratLucky6SlotQueryBetHistory(
                                                                                player.id
                                                                            )}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratLucky6Slot' &&
                                                    player &&
                                                    player.baccaratLucky6Slot.bets > 0 && (
                                                        <Row xs='3'>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wagers
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratLucky6CharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Wagers',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .betsPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .betsPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .betsPerType.tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .betsPerType
                                                                                                        .bankerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .betsPerType
                                                                                                        .playerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .betsPerType
                                                                                                        .lucky6
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratLucky6BetTypeSimpleCashBreakdown
                                                                                banker={
                                                                                    player.baccaratLucky6Slot
                                                                                        .betsPerType.banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratLucky6Slot
                                                                                        .betsPerType.player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratLucky6Slot
                                                                                        .betsPerType.tie
                                                                                }
                                                                                bankerPair={
                                                                                    player.baccaratLucky6Slot
                                                                                        .betsPerType.bankerPair
                                                                                }
                                                                                playerPair={
                                                                                    player.baccaratLucky6Slot
                                                                                        .betsPerType.playerPair
                                                                                }
                                                                                lucky6={
                                                                                    player.baccaratLucky6Slot
                                                                                        .betsPerType.lucky6
                                                                                }
                                                                                total={player.baccaratLucky6Slot.bets}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Win/Loss
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratLucky6CharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Win/Loss',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .profitPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .profitPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .profitPerType
                                                                                                        .tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .profitPerType
                                                                                                        .bankerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .profitPerType
                                                                                                        .playerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .baccaratLucky6Slot
                                                                                                        .profitPerType
                                                                                                        .lucky6
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratLucky6BetTypeCashBreakdown
                                                                                banker={
                                                                                    player.baccaratLucky6Slot
                                                                                        .profitPerType.banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratLucky6Slot
                                                                                        .profitPerType.player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratLucky6Slot
                                                                                        .profitPerType.tie
                                                                                }
                                                                                bankerPair={
                                                                                    player.baccaratLucky6Slot
                                                                                        .profitPerType.bankerPair
                                                                                }
                                                                                playerPair={
                                                                                    player.baccaratLucky6Slot
                                                                                        .profitPerType.playerPair
                                                                                }
                                                                                lucky6={
                                                                                    player.baccaratLucky6Slot
                                                                                        .profitPerType.lucky6
                                                                                }
                                                                                total={player.baccaratLucky6Slot.profit}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Bets Counts
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        {player.bets > 0 && (
                                                                            <div styles={styles.pieContainer}>
                                                                                <Pie
                                                                                    data={{
                                                                                        labels: baccaratLucky6CharPerTypeLabels,
                                                                                        datasets: [
                                                                                            {
                                                                                                label: 'Bets Counts',
                                                                                                data: [
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6Slot
                                                                                                            .betCountPerType
                                                                                                            .banker
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6Slot
                                                                                                            .betCountPerType
                                                                                                            .player
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6Slot
                                                                                                            .betCountPerType
                                                                                                            .tie
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6Slot
                                                                                                            .betCountPerType
                                                                                                            .bankerPair
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6Slot
                                                                                                            .betCountPerType
                                                                                                            .playerPair
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratLucky6Slot
                                                                                                            .betCountPerType
                                                                                                            .lucky6
                                                                                                    ),
                                                                                                ],
                                                                                                backgroundColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderWidth: 1,
                                                                                            },
                                                                                        ],
                                                                                    }}
                                                                                    options={{
                                                                                        plugins: {
                                                                                            legend: false,
                                                                                        },
                                                                                        responsive: true,
                                                                                        maintainAspectRatio: true,
                                                                                    }}
                                                                                />
                                                                                <DisplayBaccaratLucky6BetTypeBreakdown
                                                                                    banker={formatCountString(
                                                                                        player.baccaratLucky6Slot
                                                                                            .betCountPerType.banker
                                                                                    )}
                                                                                    player={formatCountString(
                                                                                        player.baccaratLucky6Slot
                                                                                            .betCountPerType.player
                                                                                    )}
                                                                                    tie={formatCountString(
                                                                                        player.baccaratLucky6Slot
                                                                                            .betCountPerType.tie
                                                                                    )}
                                                                                    bankerPair={formatCountString(
                                                                                        player.baccaratLucky6Slot
                                                                                            .betCountPerType.bankerPair
                                                                                    )}
                                                                                    playerPair={formatCountString(
                                                                                        player.baccaratLucky6Slot
                                                                                            .betCountPerType.playerPair
                                                                                    )}
                                                                                    lucky6={formatCountString(
                                                                                        player.baccaratLucky6Slot
                                                                                            .betCountPerType.lucky6
                                                                                    )}
                                                                                    total={formatCountString(
                                                                                        player.baccaratLucky6Slot
                                                                                            .betCount
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {player.bets === 0 && (
                                                                            <div>
                                                                                <h4>No data</h4>
                                                                            </div>
                                                                        )}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratMega6' &&
                                                    isMoreThanOneDayOrNotLive &&
                                                    player &&
                                                    player.baccaratMega6.bets > 0 && (
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wager History
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <TableHistoryChart
                                                                            setBuiltChart={(value) =>
                                                                                setBuiltChartProxy(
                                                                                    player.id + 'wagerhistory',
                                                                                    value
                                                                                )
                                                                            }
                                                                            builtChart={
                                                                                builtCharts[player.id + 'wagerhistory']
                                                                            }
                                                                            findInLabelMap={baccaratMega6PerLabelFind}
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            prometheus={prometheusPlayer}
                                                                            query={baccaratMega6QueryBetHistory(
                                                                                player.id
                                                                            )}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratMega6' &&
                                                    player &&
                                                    player.baccaratMega6.bets > 0 && (
                                                        <Row xs='3'>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wagers
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratMega6CharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Wagers',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .betsPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .betsPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .betsPerType.tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .betsPerType
                                                                                                        .bankerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .betsPerType
                                                                                                        .playerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .betsPerType
                                                                                                        .mega6
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratMega6BetTypeSimpleCashBreakdown
                                                                                banker={
                                                                                    player.baccaratMega6.betsPerType
                                                                                        .banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratMega6.betsPerType
                                                                                        .player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratMega6.betsPerType.tie
                                                                                }
                                                                                bankerPair={
                                                                                    player.baccaratMega6.betsPerType
                                                                                        .bankerPair
                                                                                }
                                                                                playerPair={
                                                                                    player.baccaratMega6.betsPerType
                                                                                        .playerPair
                                                                                }
                                                                                mega6={
                                                                                    player.baccaratMega6.betsPerType
                                                                                        .mega6
                                                                                }
                                                                                total={player.baccaratMega6.bets}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Win/Loss
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratMega6CharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Win/Loss',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .profitPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .profitPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .profitPerType
                                                                                                        .tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .profitPerType
                                                                                                        .bankerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .profitPerType
                                                                                                        .playerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratMega6
                                                                                                        .profitPerType
                                                                                                        .mega6
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratMega6BetTypeCashBreakdown
                                                                                banker={
                                                                                    player.baccaratMega6.profitPerType
                                                                                        .banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratMega6.profitPerType
                                                                                        .player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratMega6.profitPerType
                                                                                        .tie
                                                                                }
                                                                                bankerPair={
                                                                                    player.baccaratMega6.profitPerType
                                                                                        .bankerPair
                                                                                }
                                                                                playerPair={
                                                                                    player.baccaratMega6.profitPerType
                                                                                        .playerPair
                                                                                }
                                                                                mega6={
                                                                                    player.baccaratMega6.profitPerType
                                                                                        .mega6
                                                                                }
                                                                                total={player.baccaratMega6.profit}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Bets Counts
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        {player.bets > 0 && (
                                                                            <div styles={styles.pieContainer}>
                                                                                <Pie
                                                                                    data={{
                                                                                        labels: baccaratMega6CharPerTypeLabels,
                                                                                        datasets: [
                                                                                            {
                                                                                                label: 'Bets Counts',
                                                                                                data: [
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratMega6
                                                                                                            .betCountPerType
                                                                                                            .banker
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratMega6
                                                                                                            .betCountPerType
                                                                                                            .player
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratMega6
                                                                                                            .betCountPerType
                                                                                                            .tie
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratMega6
                                                                                                            .betCountPerType
                                                                                                            .bankerPair
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratMega6
                                                                                                            .betCountPerType
                                                                                                            .playerPair
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratMega6
                                                                                                            .betCountPerType
                                                                                                            .mega6
                                                                                                    ),
                                                                                                ],
                                                                                                backgroundColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderWidth: 1,
                                                                                            },
                                                                                        ],
                                                                                    }}
                                                                                    options={{
                                                                                        plugins: {
                                                                                            legend: false,
                                                                                        },
                                                                                        responsive: true,
                                                                                        maintainAspectRatio: true,
                                                                                    }}
                                                                                />
                                                                                <DisplayBaccaratMega6BetTypeBreakdown
                                                                                    banker={formatCountString(
                                                                                        player.baccaratMega6
                                                                                            .betCountPerType.banker
                                                                                    )}
                                                                                    player={formatCountString(
                                                                                        player.baccaratMega6
                                                                                            .betCountPerType.player
                                                                                    )}
                                                                                    tie={formatCountString(
                                                                                        player.baccaratMega6
                                                                                            .betCountPerType.tie
                                                                                    )}
                                                                                    bankerPair={formatCountString(
                                                                                        player.baccaratMega6
                                                                                            .betCountPerType.bankerPair
                                                                                    )}
                                                                                    playerPair={formatCountString(
                                                                                        player.baccaratMega6
                                                                                            .betCountPerType.playerPair
                                                                                    )}
                                                                                    mega6={formatCountString(
                                                                                        player.baccaratMega6
                                                                                            .betCountPerType.mega6
                                                                                    )}
                                                                                    total={formatCountString(
                                                                                        player.baccaratMega6.betCount
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {player.bets === 0 && (
                                                                            <div>
                                                                                <h4>No data</h4>
                                                                            </div>
                                                                        )}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratTiger' &&
                                                    isMoreThanOneDayOrNotLive &&
                                                    player &&
                                                    player.baccaratTiger.bets > 0 && (
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wager History
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <TableHistoryChart
                                                                            setBuiltChart={(value) =>
                                                                                setBuiltChartProxy(
                                                                                    player.id + 'wagerhistory',
                                                                                    value
                                                                                )
                                                                            }
                                                                            builtChart={
                                                                                builtCharts[player.id + 'wagerhistory']
                                                                            }
                                                                            findInLabelMap={baccaratTigerPerLabelFind}
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            prometheus={prometheusPlayer}
                                                                            query={baccaratTigerQueryBetHistory(
                                                                                player.id
                                                                            )}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'baccaratTiger' &&
                                                    player &&
                                                    player.baccaratTiger.bets > 0 && (
                                                        <Row xs='3'>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Wagers
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratTigerCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Wagers',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .betsPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .betsPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .betsPerType.tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .betsPerType
                                                                                                        .tigerTie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .betsPerType
                                                                                                        .tigerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .betsPerType
                                                                                                        .tigerBig
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .betsPerType
                                                                                                        .tigerSmall
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .betsPerType
                                                                                                        .tiger
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratTigerBetTypeSimpleCashBreakdown
                                                                                banker={
                                                                                    player.baccaratTiger.betsPerType
                                                                                        .banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratTiger.betsPerType
                                                                                        .player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratTiger.betsPerType.tie
                                                                                }
                                                                                tigerTie={
                                                                                    player.baccaratTiger.betsPerType
                                                                                        .tigerTie
                                                                                }
                                                                                tigerPair={
                                                                                    player.baccaratTiger.betsPerType
                                                                                        .tigerPair
                                                                                }
                                                                                tigerBig={
                                                                                    player.baccaratTiger.betsPerType
                                                                                        .tigerBig
                                                                                }
                                                                                tigerSmall={
                                                                                    player.baccaratTiger.betsPerType
                                                                                        .tigerSmall
                                                                                }
                                                                                tiger={
                                                                                    player.baccaratTiger.betsPerType
                                                                                        .tiger
                                                                                }
                                                                                total={player.baccaratTiger.bets}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Win/Loss
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: baccaratTigerCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Win/Loss',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .profitPerType
                                                                                                        .banker
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .profitPerType
                                                                                                        .player
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .profitPerType
                                                                                                        .tie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .profitPerType
                                                                                                        .tigerTie
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .profitPerType
                                                                                                        .tigerPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .profitPerType
                                                                                                        .tigerBig
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .profitPerType
                                                                                                        .tigerSmall
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player.baccaratTiger
                                                                                                        .profitPerType
                                                                                                        .tiger
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBaccaratTigerBetTypeCashBreakdown
                                                                                banker={
                                                                                    player.baccaratTiger.profitPerType
                                                                                        .banker
                                                                                }
                                                                                player={
                                                                                    player.baccaratTiger.profitPerType
                                                                                        .player
                                                                                }
                                                                                tie={
                                                                                    player.baccaratTiger.profitPerType
                                                                                        .tie
                                                                                }
                                                                                tigerTie={
                                                                                    player.baccaratTiger.profitPerType
                                                                                        .tigerTie
                                                                                }
                                                                                tigerPair={
                                                                                    player.baccaratTiger.profitPerType
                                                                                        .tigerPair
                                                                                }
                                                                                tigerBig={
                                                                                    player.baccaratTiger.profitPerType
                                                                                        .tigerBig
                                                                                }
                                                                                tigerSmall={
                                                                                    player.baccaratTiger.profitPerType
                                                                                        .tigerSmall
                                                                                }
                                                                                tiger={
                                                                                    player.baccaratTiger.profitPerType
                                                                                        .tiger
                                                                                }
                                                                                total={player.baccaratTiger.profit}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Baccarat Bets Counts
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        {player.bets > 0 && (
                                                                            <div styles={styles.pieContainer}>
                                                                                <Pie
                                                                                    data={{
                                                                                        labels: baccaratTigerCharPerTypeLabels,
                                                                                        datasets: [
                                                                                            {
                                                                                                label: 'Bets Counts',
                                                                                                data: [
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratTiger
                                                                                                            .betCountPerType
                                                                                                            .banker
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratTiger
                                                                                                            .betCountPerType
                                                                                                            .player
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratTiger
                                                                                                            .betCountPerType
                                                                                                            .tie
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratTiger
                                                                                                            .betCountPerType
                                                                                                            .tigerTie
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratTiger
                                                                                                            .betCountPerType
                                                                                                            .tigerPair
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratTiger
                                                                                                            .betCountPerType
                                                                                                            .tigerBig
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratTiger
                                                                                                            .betCountPerType
                                                                                                            .tigerSmall
                                                                                                    ),
                                                                                                    formatNumber(
                                                                                                        player
                                                                                                            .baccaratTiger
                                                                                                            .betCountPerType
                                                                                                            .tiger
                                                                                                    ),
                                                                                                ],
                                                                                                backgroundColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderColor:
                                                                                                    chartBackgroundColors,
                                                                                                borderWidth: 1,
                                                                                            },
                                                                                        ],
                                                                                    }}
                                                                                    options={{
                                                                                        plugins: {
                                                                                            legend: false,
                                                                                        },
                                                                                        responsive: true,
                                                                                        maintainAspectRatio: true,
                                                                                    }}
                                                                                />
                                                                                <DisplayBaccaratTigerBetTypeBreakdown
                                                                                    banker={formatCountString(
                                                                                        player.baccaratTiger
                                                                                            .betCountPerType.banker
                                                                                    )}
                                                                                    player={formatCountString(
                                                                                        player.baccaratTiger
                                                                                            .betCountPerType.player
                                                                                    )}
                                                                                    tie={formatCountString(
                                                                                        player.baccaratTiger
                                                                                            .betCountPerType.tie
                                                                                    )}
                                                                                    tigerTie={formatCountString(
                                                                                        player.baccaratTiger
                                                                                            .betCountPerType.tigerTie
                                                                                    )}
                                                                                    tigerPair={formatCountString(
                                                                                        player.baccaratTiger
                                                                                            .betCountPerType.tigerPair
                                                                                    )}
                                                                                    tigerBig={formatCountString(
                                                                                        player.baccaratTiger
                                                                                            .betCountPerType.tigerBig
                                                                                    )}
                                                                                    tigerSmall={formatCountString(
                                                                                        player.baccaratTiger
                                                                                            .betCountPerType.tigerSmall
                                                                                    )}
                                                                                    tiger={formatCountString(
                                                                                        player.baccaratTiger
                                                                                            .betCountPerType.tiger
                                                                                    )}
                                                                                    total={formatCountString(
                                                                                        player.baccaratTiger.betCount
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {player.bets === 0 && (
                                                                            <div>
                                                                                <h4>No data</h4>
                                                                            </div>
                                                                        )}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'blackjackStandard' &&
                                                    isMoreThanOneDayOrNotLive &&
                                                    player &&
                                                    player.blackjackStandard.bets > 0 && (
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Blackjack Wager History
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <TableHistoryChart
                                                                            setBuiltChart={(value) =>
                                                                                setBuiltChartProxy(
                                                                                    player.id + 'blackjackWagerhistory',
                                                                                    value
                                                                                )
                                                                            }
                                                                            builtChart={
                                                                                builtCharts[
                                                                                player.id + 'blackjakWagerhistory'
                                                                                ]
                                                                            }
                                                                            findInLabelMap={
                                                                                blackjackStandardPerLabelFind
                                                                            }
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            prometheus={prometheusPlayer}
                                                                            query={blackjackStandardQueryBetHistory(
                                                                                player.id
                                                                            )}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'blackjackStandard' &&
                                                    player &&
                                                    player.blackjackStandard.bets > 0 && (
                                                        <Row xs='3'>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Blackjack Wagers
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: blackjackStandardCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Wagers',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betsPerType
                                                                                                        .base
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betsPerType
                                                                                                        .doubleDown
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betsPerType
                                                                                                        .insurance
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betsPerType
                                                                                                        .pair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betsPerType
                                                                                                        .split
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBlackjackStandardBetTypeSimpleCashBreakdown
                                                                                base={
                                                                                    player.blackjackStandard.betsPerType
                                                                                        .base
                                                                                }
                                                                                doubleDown={
                                                                                    player.blackjackStandard.betsPerType
                                                                                        .doubleDown
                                                                                }
                                                                                insurance={
                                                                                    player.blackjackStandard.betsPerType
                                                                                        .insurance
                                                                                }
                                                                                pair={
                                                                                    player.blackjackStandard.betsPerType
                                                                                        .pair
                                                                                }
                                                                                split={
                                                                                    player.blackjackStandard.betsPerType
                                                                                        .split
                                                                                }
                                                                                total={player.blackjackStandard.bets}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Blackjack Win/Loss
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: blackjackStandardCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Win/Loss',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .profitPerType
                                                                                                        .base
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .profitPerType
                                                                                                        .doubleDown
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .profitPerType
                                                                                                        .insurance
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .profitPerType
                                                                                                        .pair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .profitPerType
                                                                                                        .split
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBlackjackStandardBetTypeCashBreakdown
                                                                                base={
                                                                                    player.blackjackStandard
                                                                                        .profitPerType.base
                                                                                }
                                                                                doubleDown={
                                                                                    player.blackjackStandard
                                                                                        .profitPerType.doubleDown
                                                                                }
                                                                                insurance={
                                                                                    player.blackjackStandard
                                                                                        .profitPerType.insurance
                                                                                }
                                                                                pair={
                                                                                    player.blackjackStandard
                                                                                        .profitPerType.pair
                                                                                }
                                                                                split={
                                                                                    player.blackjackStandard
                                                                                        .profitPerType.split
                                                                                }
                                                                                total={player.blackjackStandard.profit}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Blackjack Bets Counts
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: blackjackStandardCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Bets Counts',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betCountPerType
                                                                                                        .base
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betCountPerType
                                                                                                        .doubleDown
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betCountPerType
                                                                                                        .insurance
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betCountPerType
                                                                                                        .pair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackStandard
                                                                                                        .betCountPerType
                                                                                                        .split
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />
                                                                            <DisplayBlackjackStandardBetTypeBreakdown
                                                                                base={formatCountString(
                                                                                    player.blackjackStandard
                                                                                        .betCountPerType.base
                                                                                )}
                                                                                doubleDown={formatCountString(
                                                                                    player.blackjackStandard
                                                                                        .betCountPerType.doubleDown
                                                                                )}
                                                                                insurance={formatCountString(
                                                                                    player.blackjackStandard
                                                                                        .betCountPerType.insurance
                                                                                )}
                                                                                pair={formatCountString(
                                                                                    player.blackjackStandard
                                                                                        .betCountPerType.pair
                                                                                )}
                                                                                split={formatCountString(
                                                                                    player.blackjackStandard
                                                                                        .betCountPerType.split
                                                                                )}
                                                                                total={formatCountString(
                                                                                    player.blackjackStandard.betCount
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'blackjackAnyPairSlot' &&
                                                    isMoreThanOneDayOrNotLive &&
                                                    player &&
                                                    player.blackjackAnyPairSlot.bets > 0 && (
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Blackjack Wager History
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <TableHistoryChart
                                                                            setBuiltChart={(value) =>
                                                                                setBuiltChartProxy(
                                                                                    player.id + 'blackjackWagerhistory',
                                                                                    value
                                                                                )
                                                                            }
                                                                            builtChart={
                                                                                builtCharts[
                                                                                player.id + 'blackjakWagerhistory'
                                                                                ]
                                                                            }
                                                                            findInLabelMap={
                                                                                blackjackAnyPairSlotPerLabelFind
                                                                            }
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            prometheus={prometheusPlayer}
                                                                            query={blackjackAnyPairSlotQueryBetHistory(
                                                                                player.id
                                                                            )}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {gameVariantFilter === 'blackjackAnyPairSlot' &&
                                                    player &&
                                                    player.blackjackAnyPairSlot.bets > 0 && (
                                                        <Row xs='3'>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Blackjack Wagers
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: blackjackAnyPairSlotCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Wagers',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betsPerType
                                                                                                        .base
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betsPerType
                                                                                                        .pair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betsPerType
                                                                                                        .anyPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betsPerType
                                                                                                        .split
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betsPerType
                                                                                                        .doubleDown
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betsPerType
                                                                                                        .insurance
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betsPerType
                                                                                                        .bonus
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBlackjackAnyPairSlotBetTypeSimpleCashBreakdown
                                                                                base={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betsPerType.base
                                                                                }
                                                                                pair={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betsPerType.pair
                                                                                }
                                                                                anyPair={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betsPerType.anyPair
                                                                                }
                                                                                split={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betsPerType.split
                                                                                }
                                                                                doubleDown={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betsPerType.doubleDown
                                                                                }
                                                                                insurance={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betsPerType.insurance
                                                                                }
                                                                                bonus={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betsPerType.bonus
                                                                                }
                                                                                total={player.blackjackAnyPairSlot.bets}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Blackjack Win/Loss
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: blackjackAnyPairSlotCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Win/Loss',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .profitPerType
                                                                                                        .base
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .profitPerType
                                                                                                        .pair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .profitPerType
                                                                                                        .anyPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .profitPerType
                                                                                                        .split
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .profitPerType
                                                                                                        .doubleDown
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .profitPerType
                                                                                                        .insurance
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .profitPerType
                                                                                                        .bonus
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />

                                                                            <DisplayBlackjackAnyPairSlotBetTypeCashBreakdown
                                                                                base={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .profitPerType.base
                                                                                }
                                                                                pair={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .profitPerType.pair
                                                                                }
                                                                                anyPair={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .profitPerType.anyPair
                                                                                }
                                                                                split={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .profitPerType.split
                                                                                }
                                                                                doubleDown={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .profitPerType.doubleDown
                                                                                }
                                                                                insurance={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .profitPerType.insurance
                                                                                }
                                                                                bonus={
                                                                                    player.blackjackAnyPairSlot
                                                                                        .profitPerType.bonus
                                                                                }
                                                                                total={
                                                                                    player.blackjackAnyPairSlot.profit
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col>
                                                                <Card>
                                                                    <CardHeader style={styles.cardHeader}>
                                                                        <span style={styles.cardTitleText}>
                                                                            Blackjack Bets Counts
                                                                        </span>
                                                                    </CardHeader>
                                                                    <CardBody style={styles.cardBody}>
                                                                        <div styles={styles.pieContainer}>
                                                                            <Pie
                                                                                data={{
                                                                                    labels: blackjackAnyPairSlotCharPerTypeLabels,
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'Bets Counts',
                                                                                            data: [
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betCountPerType
                                                                                                        .base
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betCountPerType
                                                                                                        .pair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betCountPerType
                                                                                                        .anyPair
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betCountPerType
                                                                                                        .split
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betCountPerType
                                                                                                        .doubleDown
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betCountPerType
                                                                                                        .insurance
                                                                                                ),
                                                                                                formatNumber(
                                                                                                    player
                                                                                                        .blackjackAnyPairSlot
                                                                                                        .betCountPerType
                                                                                                        .bonus
                                                                                                ),
                                                                                            ],
                                                                                            backgroundColor:
                                                                                                chartBackgroundColors,
                                                                                            borderColor:
                                                                                                chartBackgroundColors,
                                                                                            borderWidth: 1,
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                                options={{
                                                                                    plugins: {
                                                                                        legend: false,
                                                                                    },
                                                                                    responsive: true,
                                                                                    maintainAspectRatio: true,
                                                                                }}
                                                                            />
                                                                            <DisplayBlackjackAnyPairSlotBetTypeBreakdown
                                                                                base={formatCountString(
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betCountPerType.base
                                                                                )}
                                                                                pair={formatCountString(
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betCountPerType.pair
                                                                                )}
                                                                                anyPair={formatCountString(
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betCountPerType.anyPair
                                                                                )}
                                                                                split={formatCountString(
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betCountPerType.split
                                                                                )}
                                                                                doubleDown={formatCountString(
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betCountPerType.doubleDown
                                                                                )}
                                                                                insurance={formatCountString(
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betCountPerType.insurance
                                                                                )}
                                                                                bonus={formatCountString(
                                                                                    player.blackjackAnyPairSlot
                                                                                        .betCountPerType.bonus
                                                                                )}
                                                                                total={formatCountString(
                                                                                    player.blackjackAnyPairSlot.betCount
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                {player && playerSummary && (
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <CardBody style={styles.cardBody}>
                                                                    <Table hover>
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>Period by calendar</th>
                                                                                <th>Last Month</th>
                                                                                <th>Year Today</th>
                                                                                <th>Lifetime value</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Games Played</th>
                                                                                <th>
                                                                                    {player
                                                                                        ? formatCountString(
                                                                                            player.games
                                                                                        )
                                                                                        : '-'}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCountString(
                                                                                        playerSummary.month.games
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCountString(
                                                                                        playerSummary.year.games
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCountString(
                                                                                        playerSummary.life.games
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Time Played</th>
                                                                                <th>
                                                                                    {player
                                                                                        ? formatDuration(player.active)
                                                                                        : '-'}
                                                                                </th>
                                                                                <th>
                                                                                    {formatDuration(
                                                                                        playerSummary.month.active
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatDuration(
                                                                                        playerSummary.year.active
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatDuration(
                                                                                        playerSummary.life.active
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Total Wager</th>
                                                                                <th>
                                                                                    {player
                                                                                        ? formatCashString(player.bets)
                                                                                        : '-'}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.month.bets
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.year.bets
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.life.bets
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Player Net Win/Loss</th>
                                                                                {player && (
                                                                                    <th
                                                                                        style={styles.cashValue(
                                                                                            player.profit
                                                                                        )}
                                                                                    >
                                                                                        {formatCashString(
                                                                                            player.profit
                                                                                        )}
                                                                                    </th>
                                                                                )}
                                                                                {!player && <th>{'-'}</th>}
                                                                                <th
                                                                                    style={styles.cashValue(
                                                                                        playerSummary.month.profit
                                                                                    )}
                                                                                >
                                                                                    {formatCashString(
                                                                                        playerSummary.month.profit
                                                                                    )}
                                                                                </th>
                                                                                <th
                                                                                    style={styles.cashValue(
                                                                                        playerSummary.year.profit
                                                                                    )}
                                                                                >
                                                                                    {formatCashString(
                                                                                        playerSummary.year.profit
                                                                                    )}
                                                                                </th>
                                                                                <th
                                                                                    style={styles.cashValue(
                                                                                        playerSummary.life.profit
                                                                                    )}
                                                                                >
                                                                                    {formatCashString(
                                                                                        playerSummary.life.profit
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Player Net Win/Loss (%)</th>
                                                                                {player && (
                                                                                    <th>
                                                                                        {formatPresicePercentString(
                                                                                            player.bets > 0
                                                                                                ? player.profit /
                                                                                                player.bets
                                                                                                : 0
                                                                                        )}
                                                                                    </th>
                                                                                )}
                                                                                {!player && <th>{'-'}</th>}
                                                                                <th>
                                                                                    {formatPresicePercentString(
                                                                                        playerSummary.month.bets > 0
                                                                                            ? playerSummary.month
                                                                                                .profit /
                                                                                            playerSummary.month
                                                                                                .bets
                                                                                            : 0
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatPresicePercentString(
                                                                                        playerSummary.year.bets > 0
                                                                                            ? playerSummary.year
                                                                                                .profit /
                                                                                            playerSummary.year
                                                                                                .bets
                                                                                            : 0
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatPresicePercentString(
                                                                                        playerSummary.life.bets > 0
                                                                                            ? playerSummary.life
                                                                                                .profit /
                                                                                            playerSummary.life
                                                                                                .bets
                                                                                            : 0
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Avg Bet</th>
                                                                                {player && (
                                                                                    <th>
                                                                                        {player.games > 0
                                                                                            ? formatCashString(
                                                                                                (
                                                                                                    player.bets /
                                                                                                    player.games
                                                                                                ).toFixed(0)
                                                                                            )
                                                                                            : 0}
                                                                                    </th>
                                                                                )}
                                                                                {!player && <th>{'-'}</th>}
                                                                                <th>
                                                                                    {playerSummary.month.games > 0
                                                                                        ? formatCashString(
                                                                                            (
                                                                                                playerSummary.month
                                                                                                    .bets /
                                                                                                playerSummary.month
                                                                                                    .games
                                                                                            ).toFixed(0)
                                                                                        )
                                                                                        : 0}
                                                                                </th>
                                                                                <th>
                                                                                    {playerSummary.year.games > 0
                                                                                        ? formatCashString(
                                                                                            (
                                                                                                playerSummary.year
                                                                                                    .bets /
                                                                                                playerSummary.year
                                                                                                    .games
                                                                                            ).toFixed(0)
                                                                                        )
                                                                                        : 0}
                                                                                </th>
                                                                                <th>
                                                                                    {playerSummary.life.games > 0
                                                                                        ? formatCashString(
                                                                                            (
                                                                                                playerSummary.life
                                                                                                    .bets /
                                                                                                playerSummary.life
                                                                                                    .games
                                                                                            ).toFixed(0)
                                                                                        )
                                                                                        : 0}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Max Bet</th>
                                                                                {player && (
                                                                                    <th>
                                                                                        {formatCashString(
                                                                                            player.maxBet
                                                                                        )}
                                                                                    </th>
                                                                                )}
                                                                                {!player && <th>{'-'}</th>}
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.month.maxBet
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.year.maxBet
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.life.maxBet
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Max Win</th>
                                                                                {player && (
                                                                                    <th>
                                                                                        {formatCashString(
                                                                                            player.maxWin
                                                                                        )}
                                                                                    </th>
                                                                                )}
                                                                                {!player && <th>{'-'}</th>}
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.month.maxWin
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.year.maxWin
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.life.maxWin
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Max Loss</th>
                                                                                {player && (
                                                                                    <th>
                                                                                        {formatCashString(
                                                                                            player.maxLoss
                                                                                        )}
                                                                                    </th>
                                                                                )}
                                                                                {!player && <th>{'-'}</th>}
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.month.maxLoss
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.year.maxLoss
                                                                                    )}
                                                                                </th>
                                                                                <th>
                                                                                    {formatCashString(
                                                                                        playerSummary.life.maxLoss
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                            {gameVariantFilter &&
                                                                                (gameVariantFilter ===
                                                                                    'baccaratStandard' ||
                                                                                    gameVariantFilter ===
                                                                                    'baccaratLucky6' ||
                                                                                    gameVariantFilter ===
                                                                                    'baccaratLucky6Slot' ||
                                                                                    gameVariantFilter ===
                                                                                    'baccaratMega6') && (
                                                                                    <tr>
                                                                                        <th>Baccarat ADT</th>
                                                                                        {player && (
                                                                                            <th>
                                                                                                {formatCashString(
                                                                                                    baccaratCalculatePlayerADT(
                                                                                                        player
                                                                                                    )
                                                                                                )}
                                                                                            </th>
                                                                                        )}
                                                                                        {!player && <th>{'-'}</th>}
                                                                                        <th>
                                                                                            {formatCashString(
                                                                                                baccaratCalculatePlayerADT(
                                                                                                    playerSummary.month
                                                                                                )
                                                                                            )}
                                                                                        </th>
                                                                                        <th>
                                                                                            {formatCashString(
                                                                                                baccaratCalculatePlayerADT(
                                                                                                    playerSummary.year
                                                                                                )
                                                                                            )}
                                                                                        </th>
                                                                                        <th>
                                                                                            {formatCashString(
                                                                                                baccaratCalculatePlayerADT(
                                                                                                    playerSummary.life
                                                                                                )
                                                                                            )}
                                                                                        </th>
                                                                                    </tr>
                                                                                )}
                                                                            {gameVariantFilter &&
                                                                                (gameVariantFilter ===
                                                                                    'blackjackStandard' ||
                                                                                    gameVariantFilter ===
                                                                                    'blackjackAnyPairSlot') && (
                                                                                    <tr>
                                                                                        <th>Blackjack ADT</th>
                                                                                        {player && (
                                                                                            <th>
                                                                                                {formatCashString(
                                                                                                    blackjackCalculatePlayerADT(
                                                                                                        player
                                                                                                    )
                                                                                                )}
                                                                                            </th>
                                                                                        )}
                                                                                        {!player && <th>{'-'}</th>}
                                                                                        <th>
                                                                                            {formatCashString(
                                                                                                blackjackCalculatePlayerADT(
                                                                                                    playerSummary.month
                                                                                                )
                                                                                            )}
                                                                                        </th>
                                                                                        <th>
                                                                                            {formatCashString(
                                                                                                blackjackCalculatePlayerADT(
                                                                                                    playerSummary.year
                                                                                                )
                                                                                            )}
                                                                                        </th>
                                                                                        <th>
                                                                                            {formatCashString(
                                                                                                blackjackCalculatePlayerADT(
                                                                                                    playerSummary.life
                                                                                                )
                                                                                            )}
                                                                                        </th>
                                                                                    </tr>
                                                                                )}
                                                                            <tr>
                                                                                <th>
                                                                                    House Net Win/Loss (Actual House
                                                                                    Hold)
                                                                                </th>
                                                                                {player && (
                                                                                    <th
                                                                                        style={styles.cashValue(
                                                                                            player.profit * -1
                                                                                        )}
                                                                                    >
                                                                                        {formatCashString(
                                                                                            player.profit * -1
                                                                                        )}
                                                                                    </th>
                                                                                )}
                                                                                {!player && <th>{'-'}</th>}
                                                                                <th
                                                                                    style={styles.cashValue(
                                                                                        playerSummary.month.profit * -1
                                                                                    )}
                                                                                >
                                                                                    {formatCashString(
                                                                                        playerSummary.month.profit * -1
                                                                                    )}
                                                                                </th>
                                                                                <th
                                                                                    style={styles.cashValue(
                                                                                        playerSummary.year.profit * -1
                                                                                    )}
                                                                                >
                                                                                    {formatCashString(
                                                                                        playerSummary.year.profit * -1
                                                                                    )}
                                                                                </th>
                                                                                <th
                                                                                    style={styles.cashValue(
                                                                                        playerSummary.life.profit * -1
                                                                                    )}
                                                                                >
                                                                                    {formatCashString(
                                                                                        playerSummary.life.profit * -1
                                                                                    )}
                                                                                </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                )}
                                                {isMoreThanOneDay && player && player.bets > 0 && (
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <CardHeader style={styles.cardHeader}>
                                                                    <span style={styles.cardTitleText}>
                                                                        Wager History
                                                                    </span>
                                                                </CardHeader>
                                                                <CardBody style={styles.cardBody}>
                                                                    <TableHistoryChart
                                                                        setBuiltChart={(value) =>
                                                                            setBuiltChartProxy(
                                                                                player.id + 'wager',
                                                                                value
                                                                            )
                                                                        }
                                                                        builtChart={builtCharts[player.id + 'wager']}
                                                                        findInLabelMap={() => 'wager'}
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        prometheus={prometheusPlayer}
                                                                        query={queryBetsHistory(player.id)}
                                                                    ></TableHistoryChart>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                )}
                                                {isMoreThanOneDay && player && player.bets > 0 && (
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <CardHeader style={styles.cardHeader}>
                                                                    <span style={styles.cardTitleText}>
                                                                        Win/Loss History
                                                                    </span>
                                                                </CardHeader>
                                                                <CardBody style={styles.cardBody}>
                                                                    <TableHistoryChart
                                                                        setBuiltChart={(value) =>
                                                                            setBuiltChartProxy(
                                                                                player.id + 'winloss',
                                                                                value
                                                                            )
                                                                        }
                                                                        builtChart={builtCharts[player.id + 'winloss']}
                                                                        findInLabelMap={() => 'win/loss'}
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        prometheus={prometheusPlayer}
                                                                        query={queryProfitHistory(player.id)}
                                                                    ></TableHistoryChart>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                )}
                                                {numberOfDays === 0 && player && player.games > 0 && (
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <CardHeader>
                                                                    <span style={styles.cardTitleText}>Games</span>
                                                                </CardHeader>
                                                                <CardBody style={styles.cardBody}>
                                                                    <div style={styles.barContainer}>
                                                                        <Line
                                                                            options={{
                                                                                plugins: {
                                                                                    legend: false,
                                                                                },
                                                                                responsive: true,
                                                                                maintainAspectRatio: false,
                                                                                scales: {
                                                                                    yAxes: [
                                                                                        {
                                                                                            id: 'first-y-axis',
                                                                                            type: 'linear',
                                                                                            ticks: {
                                                                                                min: 0,
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            }}
                                                                            data={getHoursGamesData([player])}
                                                                        />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                )}
                                                {numberOfDays === 0 && player && player.bets > 0 && (
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <CardHeader>
                                                                    <span style={styles.cardTitleText}>Wager</span>
                                                                </CardHeader>
                                                                <CardBody style={styles.cardBody}>
                                                                    <div style={styles.barContainer}>
                                                                        <Bar
                                                                            options={{
                                                                                plugins: {
                                                                                    legend: false,
                                                                                },
                                                                                responsive: true,
                                                                                maintainAspectRatio: false,
                                                                                scales: {
                                                                                    yAxes: [
                                                                                        {
                                                                                            id: 'first-y-axis',
                                                                                            type: 'linear',
                                                                                            ticks: {
                                                                                                min: 0,
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            }}
                                                                            data={getHoursWagerData([player])}
                                                                        />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                )}
                                                {numberOfDays === 0 && player && player.bets > 0 && (
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <CardHeader>
                                                                    <span style={styles.cardTitleText}>Win/Loss</span>
                                                                </CardHeader>
                                                                <CardBody style={styles.cardBody}>
                                                                    <div style={styles.barContainer}>
                                                                        <Bar
                                                                            options={{
                                                                                backgroundColor:
                                                                                    chartBackgroundColors[1],
                                                                                plugins: {
                                                                                    legend: false,
                                                                                },
                                                                                responsive: true,
                                                                                maintainAspectRatio: false,
                                                                            }}
                                                                            data={getHoursWinLossData([player])}
                                                                        />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Container>
                                        </TabPane>
                                        <TabPane tabId='2'>
                                            <Container fluid>
                                                <Row>
                                                    <Col>
                                                        {!playerGames && (
                                                            <>
                                                                <h3>Fetching games...</h3>
                                                                <LinearProgress />
                                                            </>
                                                        )}
                                                        {playerGamesMapped && (
                                                            <Card>
                                                                <CardBody>
                                                                    <div
                                                                        className='ag-theme-balham-dark'
                                                                        style={{
                                                                            width: '-webkit-fill-available',
                                                                            height: 'calc(100vh - 520px)',
                                                                            colorScheme: 'dark',
                                                                        }}
                                                                    >
                                                                        <AgGridReact
                                                                            ref={gamesGridRef}
                                                                            rowData={playerGamesMapped}
                                                                            columnDefs={columnDefsGames}
                                                                            defaultColDef={defaultColDef}
                                                                            animateRows={true}
                                                                        />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default Player
