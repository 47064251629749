import React, { Fragment, useState } from 'react'
import { startCase } from 'lodash'
import LetteredAvatar from 'react-lettered-avatar'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { allRoles, allRolesRoot } from '../../util/Roles'

const rootStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        background: 'transparent',
        overflowY: 'hidden',
    },
    viewContainer: {
        margin: '0 auto',
        maxWidth: 350,
        width: 350,
        display: 'flex',
    },
    labelText: {
        fontSize: '0.8em',
        color: '#959595',
        lineHeight: '2em',
        paddingTop: 25,
    },
    valueText: {
        paddingTop: 25,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    nameLabelTitle: {
        justifyContent: 'center',
        display: 'flex',
        fontSize: '1.6em',
    },
    levelLabelTitle: {
        justifyContent: 'center',
        display: 'flex',
        color: '#959595',
    },
    editButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
}))

const FieldView = ({ labelClassName, valueClassName, label, value }) => (
    <Fragment>
        <Grid xs={4}>
            <Typography component='p' className={labelClassName}>
                {label}
            </Typography>
        </Grid>
        <Grid xs={8}>
            <Typography component='p' className={valueClassName}>
                {value}
            </Typography>
        </Grid>
    </Fragment>
)

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ position: 'relative' }}
        >
            {value === index && <Fragment>{children}</Fragment>}
        </div>
    )
}

const UserView = ({ user, goBack }) => {
    const isCurrentUser = window.localStorage.getItem('account') === user.account
    const loggedInRole = window.localStorage.getItem('role')

    const lights = window.localStorage.getItem('lights') === 'on'
    const styles = rootStyles({ lights })

    const [currentTab, setCurrentTab] = useState(0)

    const fieldProps = {
        labelClassName: styles.labelText,
        valueClassName: styles.valueText,
    }

    const fullName = user.firstName + (user.lastName ? ' ' + user.lastName : '')

    const currentSessionRole = getCurrentSessionRoles()
    const selectedRoles = getSelectedRoles()

    function getCurrentSessionRoles() {
        if (loggedInRole === 'masterAdmin' || loggedInRole === 'root') {
            var tempSessionRole = allRolesRoot.filter((el) => loggedInRole.includes(el.role))[0]
            return tempSessionRole
        } else {
            tempSessionRole = allRoles.filter((el) => loggedInRole.includes(el.role))[0]
            return tempSessionRole
        }
    }
    function getSelectedRoles() {
        if (loggedInRole === 'masterAdmin' || loggedInRole === 'root') {
            var tempRoles = allRolesRoot.filter((el) => user.roles.includes(el.role))
            return tempRoles
        } else {
            tempRoles = allRoles.filter((el) => user.roles.includes(el.role))
            return tempRoles
        }
    }

    return (
        <>
            <Paper key='form' className={styles.root} elevation={0}>
                <Tabs
                    value={currentTab}
                    onChange={(e, value) => {
                        console.log(value)
                        setCurrentTab(value)
                    }}
                    aria-label='user details'
                >
                    <Tab label='Info' />
                    <Tab label='Role' />
                </Tabs>

                <Divider />

                <PerfectScrollbar>
                    <Box
                        sx={{
                            padding: '2rem',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2rem',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '0.5rem',
                            }}
                        >
                            <LetteredAvatar size={85} name={fullName} />
                            <Typography component='p' className={styles.nameLabelTitle}>
                                {fullName}
                            </Typography>
                            <Typography component='p' className={styles.levelLabelTitle}>
                                {isCurrentUser ? startCase(loggedInRole) : ''}
                            </Typography>
                        </Box>

                        <Divider />

                        <TabPanel value={currentTab} index={0}>
                            <Grid container spacing={2} className={styles.viewContainer} flexDirection='row'>
                                <FieldView {...fieldProps} label={'First Name'} value={user.firstName} />
                                <FieldView {...fieldProps} label={'Last Name'} value={user.lastName} />
                                <FieldView {...fieldProps} label={'Username'} value={user.account} />
                                <FieldView {...fieldProps} label={'Login PIN'} value={user.email} />
                                <FieldView {...fieldProps} label={'Email'} value={user.email} />
                            </Grid>
                        </TabPanel>

                        <TabPanel value={currentTab} index={1}>
                            <Box className={styles.viewContainer} flexDirection='column'>
                                {isCurrentUser && (
                                    <Box display='flex' flexDirection='column'>
                                        <Typography component='p' className={styles.labelText}>
                                            Current Session Role
                                        </Typography>

                                        <Typography component='p' className={styles.valueText}>
                                            {loggedInRole === 'root'
                                                ? 'Root'
                                                : currentSessionRole
                                                ? startCase(currentSessionRole.level) +
                                                  ': ' +
                                                  startCase(currentSessionRole.role)
                                                : ''}
                                        </Typography>
                                    </Box>
                                )}

                                <Box display='flex' flexDirection='column'>
                                    <Typography component='p' className={styles.labelText}>
                                        Product(s) Access
                                    </Typography>

                                    {user.products.map((product) => {
                                        return (
                                            <Box display='flex' flexDirection='column'>
                                                <Typography component='p' className={styles.valueText}>
                                                    {startCase(product)}
                                                </Typography>
                                            </Box>
                                        )
                                    })}
                                </Box>

                                <Box display='flex' flexDirection='column'>
                                    <Typography component='p' className={styles.labelText}>
                                        Level(s) & Role(s)
                                    </Typography>

                                    {selectedRoles.map((role) => {
                                        return (
                                            <Box display='flex' flexDirection='column'>
                                                <Typography component='p' className={styles.valueText}>
                                                    {startCase(role.level)}: {startCase(role.role)}
                                                </Typography>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </TabPanel>
                    </Box>
                </PerfectScrollbar>
            </Paper>
        </>
    )
}

export default UserView
