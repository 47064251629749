/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useContext, useReducer } from "react";
import { css, jsx } from "@emotion/react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { formatCashString } from "../util/misc";

import { DUIContext } from "./Dealer";

const TableFillCredit = () => {
  const {
    device,
    initialDialogObj,
    dialogObj,
    setDialogObj,
    setShowTableFillCredit,
    callApiPublishChipfill,
    callApiPublishChipcredit,
  } = useContext(DUIContext);

  const [inputState, setInputState] = useReducer((state, obj) => {
    if (obj.key === "D")
      return state.substring(0, state.length - 1).length > 0
        ? state.substring(0, state.length - 1)
        : "0";
    if (state === "0") return obj.key;
    return state + "" + obj.key;
  }, "0");

  const keyPress = (key) => {
    if (key === "C") {
      setDialogObj({
        ...dialogObj,
        mode: "credit",
      });
      return;
    }

    if (key === "F") {
      setDialogObj({
        ...dialogObj,
        mode: "fill",
      });
      return;
    }

    if (key === "E") {
      if (dialogObj.mode === "") {
        setDialogObj({
          ...dialogObj,
          show: true,
          type: "reminder",
          message: "Please select a category before proceed.",
          error: true,
        });
        return;
      }

      if (inputState === "0") {
        setDialogObj({
          ...dialogObj,
          show: true,
          type: "reminder",
          message: "Please input at least $1.",
          error: true,
        });
        return;
      }

      if (dialogObj.mode === "credit") {
        setDialogObj({
          ...dialogObj,
          show: true,
          type: "confirmation",
          message:
            "Table credit amount: $" + formatCashString(Number(inputState)),
          requirePitBoss: true,
          error: false,
          confirm: () =>
            callApiPublishChipcredit({
              data: {
                deviceID: device.index,
                dealerID: window.localStorage.getItem("account"),
                amount: Number(inputState),
              },
              success: () => {
                setDialogObj({
                  ...dialogObj,
                  show: true,
                  type: "success",
                  message: "Table credit success.",
                });
              },
              fail: () => {
                setDialogObj({
                  ...dialogObj,
                  show: true,
                  type: "error",
                  message:
                    "Table credit failed. Please try again or contact support for assistance.",
                  error: true,
                });
              },
            }),
        });
      }

      if (dialogObj.mode === "fill") {
        setDialogObj({
          ...dialogObj,
          show: true,
          type: "confirmation",
          message:
            "Table fill amount: $" + formatCashString(Number(inputState)),
          requirePitBoss: true,
          error: false,
          confirm: () =>
            callApiPublishChipfill({
              data: {
                deviceID: device.index,
                dealerID: window.localStorage.getItem("account"),
                amount: Number(inputState),
              },
              success: () => {
                setDialogObj({
                  ...dialogObj,
                  show: true,
                  type: "success",
                  message: "Table fill success.",
                });
              },
              fail: () => {
                setDialogObj({
                  ...dialogObj,
                  show: true,
                  type: "error",
                  message:
                    "Table fill failed. Please try again or contact support for assistance.",
                });
              },
            }),
        });
      }
      return;
    }
    setInputState({ key: key });
  };

  const handleClose = () => {
    setDialogObj(initialDialogObj);
    setShowTableFillCredit(false);
  };

  const button = css`
    width: 100%;
    height: 100%;
    font-size: 2vw;
    color: #fff;
    border-radius: 0;
    &:hover {
      background-color: #505050;
    }
  `;

  const credit = css`
    color: ${dialogObj.mode === "credit" ? "#fff" : "#0094FF"};
    border: ${dialogObj.mode === "credit" ? "none" : "2px solid #0094FF"};
    background-color: ${dialogObj.mode === "credit" ? "#0094FF" : "none"};
    &:hover {
      background-color: ${dialogObj.mode === "credit" ? "#0094FF" : "none"};
    }
  `;

  const fill = css`
    color: ${dialogObj.mode === "fill" ? "#fff" : "#00b812"};
    border: ${dialogObj.mode === "fill" ? "none" : "2px solid #00b812"};
    background-color: ${dialogObj.mode === "fill" ? "#00b812" : "none"};
    &:hover {
      background-color: ${dialogObj.mode === "fill" ? "#00b812" : "none"};
    }
  `;

  return (
    <React.Fragment>
      <div
        css={css`
          position: relative;
          width: 65vh;
          height: 65vh;
          background-color: #333;
          display: grid;
          grid-template-rows: repeat(6, 1fr);
          grid-template-columns: repeat(6, 1fr);
        `}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          onClick={handleClose}
          className="dialogTrigger"
          css={css`
            position: absolute;
            top: -7vh;
            right: 0;
            height: 80;
            width: 80;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          `}
        >
          <CloseIcon
            css={css`
              font-size: 7vh;
              color: white;
            `}
          />
        </div>

        <div
          css={css`
            grid-column: 1 / span 6;
            grid-row: 1 / span 1;
            background-color: #000;
          `}
        >
          <input
            css={css`
              height: 100%;
              width: 100%;
              padding: 10px;
              text-align: right;
              font-size: 3vw;
              box-sizing: border-box;
              background-color: #000;
              color: #fff;
              border: none;
              outline: none;
            `}
            value={"$" + formatCashString(inputState)}
            readOnly
          />
        </div>

        <div
          css={css`
            grid-row: 2 / span 1;
            grid-column: 1 / span 3;
          `}
        >
          <Button
            css={[button, credit]}
            onClick={() => {
              keyPress("C");
            }}
          >
            Credit
          </Button>
        </div>
        <div
          css={css`
            grid-row: 2 / span 1;
            grid-column: 4 / span 3;
          `}
        >
          <Button
            css={[button, fill]}
            onClick={() => {
              keyPress("F");
            }}
          >
            Fill
          </Button>
        </div>

        <div
          css={css`
            grid-row: 3 / span 1;
            grid-column: 1 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("7");
            }}
          >
            7
          </Button>
        </div>
        <div
          css={css`
            grid-row: 4 / span 1;
            grid-column: 1 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("4");
            }}
          >
            4
          </Button>
        </div>
        <div
          css={css`
            grid-row: 5 / span 1;
            grid-column: 1 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("1");
            }}
          >
            1
          </Button>
        </div>
        <div
          css={css`
            grid-row: 6 / span 1;
            grid-column: 1 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("D");
            }}
          >
            Del
          </Button>
        </div>

        <div
          css={css`
            grid-row: 3 / span 1;
            grid-column: 3 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("8");
            }}
          >
            8
          </Button>
        </div>
        <div
          css={css`
            grid-row: 4 / span 1;
            grid-column: 3 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("5");
            }}
          >
            5
          </Button>
        </div>
        <div
          css={css`
            grid-row: 5 / span 1;
            grid-column: 3 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("2");
            }}
          >
            2
          </Button>
        </div>
        <div
          css={css`
            grid-row: 6 / span 1;
            grid-column: 3 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("0");
            }}
          >
            0
          </Button>
        </div>

        <div
          css={css`
            grid-row: 3 / span 1;
            grid-column: 5 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("9");
            }}
          >
            9
          </Button>
        </div>
        <div
          css={css`
            grid-row: 4 / span 1;
            grid-column: 5 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("6");
            }}
          >
            6
          </Button>
        </div>
        <div
          css={css`
            grid-row: 5 / span 1;
            grid-column: 5 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("3");
            }}
          >
            3
          </Button>
        </div>
        <div
          css={css`
            grid-row: 6 / span 1;
            grid-column: 5 / span 2;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("E");
            }}
          >
            Enter
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableFillCredit;
