import React, { useMemo, useState, useCallback } from 'react'
import { clone, isEmpty } from 'lodash'
import moment from 'moment'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Button, LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'

import { publish, unpublish, useSubscribe } from '../../api'
import { xstadiumIP } from '../../config'

const DnDCalendar = withDragAndDrop(Calendar)

export const nanoToMomentTime = (time) => moment.unix(time / 1000000000)
export const momentTimeToNano = (time) => time.unix() * 1000000000

function dateInRange(rangeStart, rangeEnd, dt) {
    return rangeStart <= dt && rangeEnd >= dt
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            colorScheme: 'dark',
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const Scheduler = () => {
    const [time, timeSocket] = useSubscribe(null)
    const [playlists, playlistsSocket] = useSubscribe(xstadiumIP + '/playlists/*')
    const [schedules, schedulesSocket] = useSubscribe(xstadiumIP + '/schedules/*')

    const active =
        timeSocket &&
        timeSocket.readyState === WebSocket.OPEN &&
        playlistsSocket &&
        playlistsSocket.readyState === WebSocket.OPEN &&
        schedulesSocket &&
        schedulesSocket.readyState === WebSocket.OPEN

    // dialog playlist schedule
    const [playlistsDialog, setPlaylistsDialog] = useState(false)
    const [selectedPlaylist, setSelectedPlaylist] = useState('')
    const [selectedDates, setSelectedDates] = useState([])

    // flags
    const [loading, setLoading] = useState(false)

    // calendar
    // https://stackoverflow.com/a/48056430
    const [viewDate, setViewDate] = useState(new Date())
    const [agenda, setAgenda] = useState([])

    const onNavigate = useCallback(
        (newDate) => {
            const minDate = nanoToMomentTime(time).subtract(12, 'M').startOf('month').valueOf()
            const maxDate = nanoToMomentTime(time).add(12, 'M').endOf('month').valueOf()
            if (newDate < minDate || newDate > maxDate) {
                return
            }
            setViewDate(newDate)
        }, // eslint-disable-next-line
        [setViewDate]
    )
    const localizer = momentLocalizer(moment)
    const { formats } = useMemo(
        () => ({
            formats: {
                dateFormat: 'D',
                weekdayFormat: (date, culture, localizer) => localizer.format(date, 'ddd', culture).toUpperCase(),
                dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd Do', culture),
                timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'hh:mm a', culture),
            },
        }),
        []
    )

    const modifyEvent = async ({ event, start, end }) => {
        const tomorrow = nanoToMomentTime(time).add(1, 'days').startOf('day')
        const maxDate = nanoToMomentTime(time).add(12, 'M').endOf('month').valueOf()
        const rangeStart = moment(start).startOf('day')
        if (rangeStart.isBefore(tomorrow)) {
            console.warn('invalid date range selected, start before tomorrow')
            return
        }
        if (event.start < tomorrow) {
            console.warn('cannot move an event from the past')
            return
        }
        if (start >= maxDate || end >= maxDate) {
            console.warn('cannot move event beyond the bounds')
            return
        }
        setLoading(true)
        let currentSchedules = schedules.map(mapSchedules)
        console.log('mod schedule:', selectedDates, currentSchedules.length)
        let indexesToRemove = []
        // remove all the events on dates that are selected
        for (const newDate of [start, end]) {
            const toRemove = findScheduleInDate(currentSchedules, newDate)
            if (toRemove >= 0) {
                indexesToRemove.push(currentSchedules[toRemove].ID)
                currentSchedules.splice(toRemove, 1)
            }
        }
        console.log('after removal:', currentSchedules.length)

        for (const rm of indexesToRemove) {
            try {
                await unpublish(xstadiumIP + '/schedules/' + rm)
            } catch (e) {
                console.warn('failed to remove schedule overlap', e, rm)
            }
        }
        try {
            await publish(xstadiumIP + '/schedules/' + event.ID, {
                playlistID: event.playlistID,
                title: event.title,
                color: event.color,
                start: momentTimeToNano(moment(start).startOf('day')),
                end: momentTimeToNano(moment(end).endOf('day')),
            })
        } catch (e) {
            console.warn('failed to move schedule', e, event)
        }
        setLoading(false)
    }

    const updateSchedule = async () => {
        setLoading(true)
        try {
            await publish(xstadiumIP + '/schedules/' + findScheduleInDay(selectedDates).ID, {
                playlistID: selectedPlaylist,
                title: findScheduleInDay(selectedDates).title,
                color: getPlaylistColor(selectedPlaylist),
                start: momentTimeToNano(moment(findScheduleInDay(selectedDates).start).startOf('day')),
                end: momentTimeToNano(moment(findScheduleInDay(selectedDates).end).endOf('day')),
            })
        } catch (e) {
            console.warn('failed to update schedule', e, findScheduleInDay(selectedDates).ID)
        }
        setLoading(false)
        clearScheduled()
    }

    const removeSchedule = async () => {
        setLoading(true)

        let currentSchedules = schedules.map(mapSchedules)
        let indexesToRemove = []

        for (const newDate of selectedDates) {
            const toRemove = findScheduleInDate(currentSchedules, newDate)
            if (toRemove >= 0) {
                indexesToRemove.push(currentSchedules[toRemove].ID)
                currentSchedules.splice(toRemove, 1)
            }
        }

        for (const rm of indexesToRemove) {
            try {
                await unpublish(xstadiumIP + '/schedules/' + rm)
            } catch (e) {
                console.warn('failed to remove schedule overlap', e, rm)
            }
        }

        clearPlaylist()
        setLoading(false)
    }

    const onDateSelected = (slotEvent) => {
        if (slotEvent.action === 'click') {
            return
        }
        if (!slotEvent.slots || slotEvent.slots.length === 0) {
            return
        }

        const tomorrow = nanoToMomentTime(time).add(1, 'days').startOf('day')
        const maxDate = nanoToMomentTime(time).add(12, 'M').endOf('month').valueOf()
        for (const slotDate of slotEvent.slots) {
            if (slotDate < tomorrow) {
                console.warn('invalid date range selected')
                return
            }
            if (slotDate >= maxDate || slotDate >= maxDate) {
                console.warn('cannot put event beyond the bounds')
                return
            }
        }

        // Single day
        if (slotEvent.slots.length === 1 && !isEmpty(findScheduleInDay(slotEvent.slots))) {
            setSelectedPlaylist(findScheduleInDay(slotEvent.slots).playlistID)
        }
        setSelectedDates(slotEvent.slots)
        setPlaylistsDialog(true)
    }

    const clearPlaylist = () => {
        setPlaylistsDialog(false)
        setSelectedPlaylist('')
        setSelectedDates([])
    }

    const clearScheduled = () => {
        setSelectedPlaylist('')
        setPlaylistsDialog(false)
    }

    const findScheduleInDate = (schs, newDate) => {
        return schs.findIndex((ev) => dateInRange(ev.start, ev.end, newDate))
    }

    const mapSchedules = (sch) => {
        return {
            ID: sch.index,
            playlistID: sch.data.playlistID,
            title: getPlaylistName(sch.data.playlistID),
            start: nanoToMomentTime(sch.data.start).startOf('day').toDate(),
            end: nanoToMomentTime(sch.data.end).endOf('day').toDate(),
            allDay: true,
            color: sch.data.color,
        }
    }

    const addSchedule = async () => {
        setLoading(true)
        // await unpublish(xstadiumIP + '/schedules/*')
        let currentSchedules = schedules.map(mapSchedules)
        // console.log("add schedule:", selectedDates, currentSchedules.length)
        let indexesToRemove = []
        // remove all the events on dates that are selected
        for (const newDate of selectedDates) {
            const toRemove = findScheduleInDate(currentSchedules, newDate)
            if (toRemove >= 0) {
                indexesToRemove.push(currentSchedules[toRemove].ID)
                currentSchedules.splice(toRemove, 1)
            }
        }
        // console.log("after removal:", currentSchedules.length)

        for (const rm of indexesToRemove) {
            try {
                await unpublish(xstadiumIP + '/schedules/' + rm)
            } catch (e) {
                console.warn('failed to remove schedule overlap', e, rm)
            }
        }

        for (const newDate of selectedDates) {
            try {
                await publish(xstadiumIP + '/schedules/*', {
                    playlistID: selectedPlaylist,
                    title: getPlaylistName(selectedPlaylist),
                    color: getPlaylistColor(selectedPlaylist),
                    start: momentTimeToNano(moment(newDate).startOf('day')),
                    end: momentTimeToNano(moment(newDate).endOf('day')),
                })
            } catch (e) {
                console.warn('failed to push new schedule', e)
            }
        }

        clearPlaylist()
        setLoading(false)
    }

    const getPlaylistName = (playlistID) => {
        let result = 'unknown'
        for (const pl of playlists) {
            if (pl.index === playlistID) {
                return pl.data.name
            }
        }

        return result
    }

    const getPlaylistColor = (playlistID) => {
        let result = ''
        for (const pl of playlists) {
            if (pl.index === playlistID) {
                return pl.data.color
            }
        }

        return result
    }

    const isSelectedDateScheduled = () => {
        let currentSchedules = schedules.map(mapSchedules)
        let indexesToRemove = []

        for (const newDate of selectedDates) {
            const toRemove = findScheduleInDate(currentSchedules, newDate)
            if (toRemove >= 0) {
                indexesToRemove.push(currentSchedules[toRemove].ID)
                currentSchedules.splice(toRemove, 1)
            }
        }

        return indexesToRemove.length > 0 ? true : false
    }

    const findScheduleInDay = (date) => {
        if (!date || (date && date.length !== 1)) {
            return
        }
        let currentSchedules = schedules && schedules.map(mapSchedules)
        return currentSchedules ? currentSchedules.filter((schedules) => sameDate(schedules.start, date[0]))[0] : []
    }

    if (!time || !playlists || !schedules || !active) {
        return <LinearProgress />
    }

    const today = nanoToMomentTime(time).startOf('day')
    const tomorrow = nanoToMomentTime(time).add(1, 'days').startOf('day')

    function sameDate(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
    }

    function getDaysInMonth(month, year) {
        var date = new Date(year, month, 1)
        var days = []
        while (date.getMonth() === month) {
            days.push(new Date(date))
            date.setDate(date.getDate() + 1)
        }
        return days
    }

    const getAgenda = (date) => {
        const defaultAgenda = getDaysInMonth(date.getMonth(), date.getFullYear()).map((item) => {
            return {
                date: item,
                playlist: 'No Playlist',
            }
        })

        const agendaWithData = defaultAgenda.map((item) => {
            const match =
                schedules &&
                schedules.find((schedule) =>
                    sameDate(new Date(moment.unix(schedule.data.start / 1000000000)), item.date)
                )

            if (match) {
                return {
                    ...item,
                    playlist: getPlaylistName(match.data.playlistID),
                }
            } else {
                return { ...item }
            }
        })

        return agendaWithData
    }

    const agendaDate = isEmpty(agenda) ? getAgenda(viewDate) : agenda

    return (
        <>
            <Dialog
                key='dialogPlaylist'
                open={playlistsDialog}
                onClose={clearPlaylist}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                PaperProps={{
                    sx: {
                        width: '20%',
                    },
                }}
            >
                <DialogTitle id='alert-dialog-title'>Playlist scheduling</DialogTitle>
                <DialogContent>
                    <Select
                        id='playlist'
                        inputProps={{
                            name: 'playlist',
                            id: 'playlist',
                        }}
                        required
                        fullWidth
                        onChange={(e) => {
                            setSelectedPlaylist(e.target.value)
                        }}
                        displayEmpty
                        renderValue={(value) => (value !== '' ? getPlaylistName(value) : '...Select playlist')}
                        value={selectedPlaylist}
                        disabled={false}
                        size='small'
                        MenuProps={MenuProps}
                    >
                        {playlists.map((item, index) => {
                            return (
                                <MenuItem key={'select-layout' + index} value={item.index}>
                                    {item.data.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearPlaylist}>Cancel</Button>
                    {isSelectedDateScheduled() && (
                        <Button onClick={removeSchedule} color='error'>
                            {selectedDates.length === 1 && <>Clear</>}
                            {selectedDates.length > 1 && <>Clear Selected Day(s)</>}
                        </Button>
                    )}
                    {selectedDates.length === 1 && !isEmpty(findScheduleInDay(selectedDates)) && (
                        <Button color='primary' onClick={updateSchedule} disabled={selectedPlaylist === ''}>
                            Update
                        </Button>
                    )}
                    {(selectedDates.length > 1 ||
                        (selectedDates.length === 1 && isEmpty(findScheduleInDay(selectedDates)))) && (
                        <Button color='primary' onClick={addSchedule} disabled={selectedPlaylist === ''}>
                            Schedule
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Box width='100%' padding='2rem' display='flex' flexDirection='column' gap='1.25rem'>
                <Box>
                    <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                        Scheduler
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                        Assign playlist for all days on the calendar
                    </Typography>
                </Box>

                <Box width='100%' display='flex' gap='2rem'>
                    <Box width='70%'>
                        <DnDCalendar
                            date={viewDate}
                            onNavigate={onNavigate}
                            selectable={true}
                            views={{
                                month: true,
                                week: false,
                                day: false,
                                agenda: true,
                            }}
                            components={{
                                month: {
                                    dateHeader: (props) => {
                                        const { date } = props
                                        return (
                                            <span
                                                style={{
                                                    color: date < tomorrow ? '#949494' : 'white',
                                                }}
                                            >
                                                {props.label}
                                            </span>
                                        )
                                    },
                                },
                                toolbar: (toolbar) => {
                                    const now = moment(new Date()).format('X')
                                    const minDate = moment.unix(now).subtract(12, 'M').startOf('month').valueOf()
                                    const maxDate = moment.unix(now).add(12, 'M').endOf('month').valueOf()

                                    const viewDate = clone(toolbar.date)

                                    const dateBeforeRange = viewDate.setMonth(viewDate.getMonth() - 1) < minDate
                                    const dateAfterRange = viewDate.setMonth(viewDate.getMonth() + 2) > maxDate

                                    const goToCurrent = () => {
                                        const now = new Date()
                                        toolbar.date.setMonth(now.getMonth())
                                        toolbar.date.setYear(now.getFullYear())
                                        toolbar.onNavigate('current')
                                        setAgenda(getAgenda(toolbar.date))
                                    }

                                    const goToBack = () => {
                                        if (dateBeforeRange) {
                                            return
                                        }
                                        toolbar.date.setMonth(toolbar.date.getMonth() - 1)
                                        toolbar.onNavigate('prev')
                                        setAgenda(getAgenda(toolbar.date))
                                    }

                                    const goToNext = () => {
                                        if (dateAfterRange) {
                                            return
                                        }
                                        toolbar.date.setMonth(toolbar.date.getMonth() + 1)
                                        toolbar.onNavigate('next')
                                        setAgenda(getAgenda(toolbar.date))
                                    }

                                    const label = () => {
                                        const date = moment(toolbar.date)
                                        return (
                                            <span>
                                                <span>{date.format('MMMM')}</span>
                                                <span> {date.format('YYYY')}</span>
                                            </span>
                                        )
                                    }

                                    return (
                                        <Box sx={{ mb: '1rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                            <Button variant='contained' onClick={goToCurrent}>
                                                Today
                                            </Button>

                                            <Box display='flex' gap='.5rem'>
                                                <IconButton onClick={goToBack} disabled={dateBeforeRange}>
                                                    <ArrowBackIosNewIcon />
                                                </IconButton>
                                                <IconButton onClick={goToNext} disabled={dateAfterRange}>
                                                    <ArrowForwardIosIcon />
                                                </IconButton>
                                            </Box>

                                            <Typography variant='h6'>{label()}</Typography>
                                        </Box>
                                    )
                                },
                            }}
                            eventPropGetter={(event) => {
                                return {
                                    className: '',
                                    style: {
                                        backgroundColor: event.color,
                                        color: '#000000',
                                        filter: event.start < today ? 'brightness(55%)' : 'brightness(100%)',
                                        pointerEvents: event.start < tomorrow ? 'none' : 'auto',
                                    },
                                }
                            }}
                            disabled={loading}
                            onSelectSlot={onDateSelected}
                            defaultView={Views.MONTH}
                            formats={formats}
                            localizer={localizer}
                            events={schedules.map(mapSchedules)}
                            onEventDrop={modifyEvent}
                            popup
                            resizable={false}
                        />
                    </Box>

                    <Box
                        sx={{
                            width: '30%',
                            height: '100%',
                            padding: '1rem',
                            border: '1px solid #646464',
                        }}
                    >
                        <PerfectScrollbar
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant='h6'>Date</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h6'>Playlist</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    {agendaDate.map((schedule) => {
                                        return [
                                            <Typography
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '200px',
                                                }}
                                            >
                                                {moment(schedule.date).format('YYYY-MM-DD ddd').toUpperCase()}
                                            </Typography>,
                                        ]
                                    })}
                                </Grid>

                                <Grid item xs={6}>
                                    {getAgenda(viewDate).map((schedule) => {
                                        return [
                                            <Typography
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '200px',
                                                }}
                                            >
                                                {schedule.playlist}
                                            </Typography>,
                                        ]
                                    })}
                                </Grid>
                            </Grid>
                        </PerfectScrollbar>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Scheduler
