import React, { useState } from 'react'
import _ from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { LinearProgress } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import { useSubscribe } from '../../../api'
import Location from '../../../tree/Location'
import useWindowSize from '../../../WindowSize'
import Device from '../Device'
import Overview from './Overview'
import TreeNav from './TreeNav'

const PreWrappedTreeNav = ({
    selected = { type: 'Casino', name: 'Casino', index: 'root', containerIndex: null },
    route,
    settings,
    xstadium,
}) => {
    const params = useParams()
    const selected_withParams = {
        ...selected,
        ...(_.get(params, 'index', null) ? { index: params.index } : {}),
        ...(_.get(params, 'type', null) ? { type: params.type } : {}),
    }

    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')

    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN

    const [minLoadTime, setMinLoadTime] = useState(false)

    const treeLoading = !zones || !pits || !devices || !tables || !status || !active || !minLoadTime

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    return (
        <WrappedTreeNav
            loading={treeLoading}
            settings={settings}
            route={route}
            selected={selected_withParams}
            zones={zones}
            pits={pits}
            tables={tables}
            devices={devices}
            status={status}
            xstadium={xstadium}
        />
    )
}

const WrappedTreeNav = ({ selected, loading, route, zones, pits, tables, devices, status, xstadium }) => {
    const [width, ,] = useWindowSize()
    const history = useNavigate()

    const branchSelect = (type, index) => {
        history(
            type.toLowerCase() === 'casino'
                ? '/xtrend/' + type.toLowerCase()
                : '/xtrend/' + type.toLowerCase() + '/' + index
        )
        return
    }

    const fluid = width < 1900

    const sortFunc = (a, b) => {
        var nameA = a.data.name.toUpperCase()
        var nameB = b.data.name.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        return 0
    }

    if (!loading) {
        zones.sort(sortFunc)
        tables.sort(sortFunc)
        pits.sort(sortFunc)
        devices.sort(sortFunc)
        status.sort(sortFunc)
        // console.log("Z", zones)
        // console.log("P", pits)
        // console.log("T", tables)
        // devices.forEach(dv => {
        //     let found = false
        //     for (const table of tables) {
        //         if (table.index === dv.data.containerIndex) {
        //             found = true
        //         }
        //     }
        //     if (!found) {
        //         console.warn("found device without table container: ", dv.data.name, dv.data.containerIndex, dv)
        //     }
        // })

        // tables.forEach(tb => {
        //     let found = false
        //     for (const pit of pits) {
        //         if (pit.index === tb.data.containerIndex) {
        //             found = true
        //         }
        //     }
        //     if (!found) {
        //         console.warn("found table without pit container: ", tb.data.name, tb.data.containerIndex, tb)
        //     }
        // })

        // pits.forEach(pt => {
        //     let found = false
        //     for (const zone of zones) {
        //         if (zone.index === pt.data.containerIndex) {
        //             found = true
        //         }
        //     }
        //     if (!found) {
        //         console.warn("found pit without zone container: ", pt.data.name, pt.data.containerIndex, pt)
        //     }
        // })
    }

    return (
        <Grid container>
            {!xstadium && (
                <Grid item style={{ width: 315 }}>
                    <Card
                        style={{
                            maxWidth: 300,
                            minWidth: 300,
                            padding: '1em',
                            left: fluid ? 70 : 'calc(50vw - 890px)',
                            position: 'fixed',
                            top: 77,
                        }}
                    >
                        <CardContent style={{ padding: '1em', paddingTop: '0.5em' }}>
                            <h4 style={{ margin: 0 }}>Navigation</h4>
                            <div
                                className='location-tree-card'
                                style={{
                                    height: 'calc(100vh - 170px)',
                                    paddingLeft: 0,
                                }}
                            >
                                <PerfectScrollbar
                                    className='media-list overflow-hidden position-relative'
                                    options={{
                                        wheelPropagation: true,
                                    }}
                                >
                                    {loading && (
                                        <Row xs='1'>
                                            <Col>
                                                <h3>Fetching tables...</h3>
                                                <LinearProgress />
                                            </Col>
                                        </Row>
                                    )}
                                    {!loading && (
                                        <TreeNav
                                            selectedIndex={selected.index}
                                            zones={zones}
                                            pits={pits}
                                            tables={tables}
                                            devices={devices}
                                            branchSelect={branchSelect}
                                        />
                                    )}
                                </PerfectScrollbar>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            )}

            {/* The actual content to the right of the branch view */}
            <Grid item xs>
                <Location
                    loading={loading}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    location={[
                        {
                            branchType: selected.type,
                            branchIndex: selected.index,
                        },
                    ]}
                    xstadium={xstadium}
                    fluid
                />
                {!xstadium && (
                    <>
                        {route === 'Overview' && (
                            <Overview
                                loading={loading}
                                selected={selected}
                                branchSelect={branchSelect}
                                zones={zones}
                                pits={pits}
                                tables={tables}
                                devices={devices}
                                status={status}
                            />
                        )}
                    </>
                )}
                {route === 'Device' && (
                    <Device
                        loading={loading}
                        devices={devices}
                        tables={tables}
                        zones={zones}
                        pits={pits}
                        selected={selected}
                        xstadium={xstadium}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default PreWrappedTreeNav
