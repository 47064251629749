import React, { useState, useReducer, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react' // the AG Grid React Component
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import { keys, omit, uniq, get, isEmpty, startCase, cloneDeep, isNil } from 'lodash'
import moment from 'moment'
import { Pie, Bar, Line } from 'react-chartjs-2'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Card, CardHeader, CardBody, Container, Row, Col, Progress } from 'reactstrap'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import { withStyles } from '@mui/styles'

import { useSubscribe, tableApi, historyApi, cashApi, playerApi } from '../../api'
import { resultsIP, prometheusTrend } from '../../config'
import Location from '../../tree/Location'
import Tree from '../../tree/Tree'
import {
    getDescendantDevices,
    atLeastOneDevice,
    findTablesInLocation,
    findGameDeviceInTable,
    getAllIPs,
    getGameType,
    getGameVariant,
} from '../../tree/TreeUtils'
import { getGameTypeTranslation, getGameVariantTranslation } from '../../util/Translation'
import useWindowSize from '../../WindowSize'
import { Cards } from '../cards/Card'
import {
    formatCashString,
    formatNumber,
    chartBackgroundColors,
    baccaratStandardCharPerTypeLabels,
    baccaratLucky6CharPerTypeLabels,
    baccaratLucky6SlotCharPerTypeLabels,
    baccaratMega6CharPerTypeLabels,
    baccaratTigerCharPerTypeLabels,
    blackjackStandardCharPerTypeLabels,
    blackjackAnyPairSlotCharPerTypeLabels,
    DisplayBaccaratStandardBetTypeSimpleCashBreakdown,
    DisplayBaccaratLucky6BetTypeSimpleCashBreakdown,
    DisplayBaccaratLucky6SlotBetTypeSimpleCashBreakdown,
    DisplayBaccaratMega6BetTypeSimpleCashBreakdown,
    DisplayBaccaratTigerBetTypeSimpleCashBreakdown,
    DisplayBlackjackStandardBetTypeSimpleCashBreakdown,
    DisplayBlackjackAnyPairSlotBetTypeSimpleCashBreakdown,
    hoursLabels,
    DateTimeDisplay,
    DEFAULT_EMPTY_USER,
} from '../Formats'
import TableHistoryChart from '../TableHistoryChart'

import '../../ag-grid/ag-grid-theme.css' // Core grid CSS, always needed
import '../../ag-grid/ag-grid.css' // Core grid CSS, always needed

const styles = {
    pieContainer: {
        width: 365,
        height: 473,
        paddingTop: 35,
        margin: 'auto',
    },
    barContainer: {
        width: '-webkit-fill-available',
        height: 150,
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em',
    },
    cardTitleText: {
        fontSize: '1.1em',
        fontWeight: 'bold',
        justifyContent: 'center',
        display: 'flex',
        width: '-webkit-fill-available',
        overflowWrap: 'anywhere',
        maxWidth: 163,
        margin: 'auto',
        minHeight: 50,
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    cardBodyText: {
        padding: '0px 0.9em 0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
    cardBodyTextString: {
        padding: '0px 0.9em 0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.3em',
        overflowWrap: 'anywhere',
    },
    cardBodyTextStringDate: {
        padding: 0,
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.3em',
        overflowWrap: 'anywhere',
        marginTop: -24,
    },
    cardBodyTextWager: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.3em',
        color: chartBackgroundColors[4],
        paddingTop: 0,
    },
    cardBodyTextWinLoss: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.3em',
        paddingTop: 0,
    },
    cardBodyTextStringResult: (v) => ({
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color:
            v === 'B' || v === 'D'
                ? chartBackgroundColors[0]
                : v === 'P'
                    ? chartBackgroundColors[1]
                    : v === 'NA'
                        ? 'white'
                        : chartBackgroundColors[2],
    }),
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red',
    }),
    seatCard: (index) => ({
        // backgroundColor: seatsBackgroundColors[index]
    }),
    buttonTable: {
        marginBottom: '10px',
    },
    playerPicture: {
        maxWidth: '-webkit-fill-available',
        minWidth: '-webkit-fill-available',
        maxHeight: '145px',
        margin: '0 auto',
        objectFit: 'contain',
        minHeight: 145,
        background: '#666666',
    },
    playerSeatName: {
        display: 'flex',
        width: '-webkit-fill-available',
        maxWidth: '-webkit-fill-available',
        justifyContent: 'space-between',
        paddingBottom: '1em',
        flexWrap: 'inherit',
    },
    button: {
        width: '150px',
    },
    twoLineEllipsis: {
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },
}

const CustomTab = withStyles({
    root: {
        backgroundColor: '#343434',
        borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
    },
    selected: {
        backgroundColor: 'rgb(0, 161, 255)',
        color: '#ffffff !important',
    },
})(Tab)

const Games = ({ xstadium, levels = null }) => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')
    const URLlocationID = urlParams.get('locationID') || window.localStorage.getItem('url:locationID') || 'root'
    const URLlocationType = urlParams.get('locationType') || window.localStorage.getItem('url:locationType') || 'Casino'
    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }
    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN

    // fetched data
    const [allTablesReport, setAllTablesReport] = useState(null)
    const [allTablesCashReport, setAllTablesCashReport] = useState(null)
    const [fetchedTablesReport, setFetchedTablesReport] = useState(false)
    const [deviceGames, setDeviceGames] = useState(null)
    const [fetchedDeviceGames, setFetchedDeviceGames] = useState(false)
    const [fetchedMemberships, setFetchedMemberships] = useState(false)
    const [memberships, setMemberships] = useState(null)
    const [fetchedPlayersReport, setFetchedPlayersReport] = useState(false)
    const [playersReport, setPlayersReport] = useState(null)
    const [fetchedTablesCashReport, setFetchedTablesCashReport] = useState(false)
    const [fetchTablesReportProgress, setFetchTablesReportProgress] = useState(0)
    const [fetchTablesCashReportProgress, setFetchTablesCashReportProgress] = useState(0)
    const [fetchGamesProgress, setFetchGamesProgress] = useState(0)
    const [minLoadTime, setMinLoadTime] = useState(false)

    // tabs
    const [tabIndex, setTabIndex] = useState(0)
    const handleChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex)
    }

    // ag-grid
    const defaultColDef = useMemo(() => {
        return {
            minWidth: 10,
            filter: true,
            sortable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }
    }, [])

    // view list table
    const bacViewListColumnDefs = [
        {
            field: 'startTime',
            headerName: 'Start Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'endTime',
            headerName: 'End Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'deckID',
            headerName: 'Deck ID',
            maxWidth: 200,
        },
        {
            field: 'gameID',
            headerName: 'Game ID',
            maxWidth: 200,
        },
        {
            field: 'gameNumber',
            headerName: 'Game Number',
            maxWidth: 200,
        },
        {
            field: 'resultWinner',
            headerName: 'Result Winner',
            maxWidth: 200,
        },
        {
            field: 'sideBetWin',
            headerName: 'Side Bet Win',
            resizable: true,
            width: 300,
            maxWidth: 400,
        },
        {
            field: 'dealer',
            headerName: 'Dealer',
            maxWidth: 200,
        },
        {
            field: 'wager',
            headerName: 'Wager',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return formatCashString(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'houseWinLoss',
            headerName: 'House Win/Loss',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return formatCashString(params.value)
            },
            cellStyle: (params) => styles.cashValue(params.value),
            maxWidth: 200,
        },
        {
            field: 'b1',
            headerName: 'Banker Card 1',
            maxWidth: 200,
        },
        {
            field: 'b2',
            headerName: 'Banker Card 2',
            maxWidth: 200,
        },
        {
            field: 'b3',
            headerName: 'Banker Card 3',
            maxWidth: 200,
        },
        {
            field: 'p1',
            headerName: 'Player Card 1',
            maxWidth: 200,
        },
        {
            field: 'p2',
            headerName: 'Player Card 2',
            maxWidth: 200,
        },
        {
            field: 'p3',
            headerName: 'Player Card 3',
            maxWidth: 200,
        },
    ]

    const bjViewListColumnDefs = [
        {
            field: 'startTime',
            headerName: 'Start time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'endTime',
            headerName: 'End time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'deckID',
            headerName: 'Deck ID',
            maxWidth: 200,
        },
        {
            field: 'gameID',
            headerName: 'Game ID',
            maxWidth: 200,
        },
        {
            field: 'gameNumber',
            headerName: 'Game Number',
            maxWidth: 200,
        },
        {
            field: 'dealerWinCount',
            headerName: 'Dealer Win Count',
            maxWidth: 200,
        },
        {
            field: 'playerHandWinCount',
            headerName: 'Player Hand Win Count',
            maxWidth: 200,
        },
        {
            field: 'sideBetWin',
            headerName: 'Side Bet Win',
            resizable: true,
            width: 300,
            maxWidth: 400,
        },
        {
            field: 'dealer',
            headerName: 'Dealer',
            maxWidth: 200,
        },
        {
            field: 'wager',
            headerName: 'Wager',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return formatCashString(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'houseWinLoss',
            headerName: 'House Win/Loss',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return formatCashString(params.value)
            },
            cellStyle: (params) => styles.cashValue(params.value),
            maxWidth: 200,
        },
        {
            field: 'd1',
            headerName: 'Dealer Card 1',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
        {
            field: 'd2',
            headerName: 'Dealer Card 2',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
        {
            field: 'd3',
            headerName: 'Dealer Card 3',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
        {
            field: 'd4',
            headerName: 'Dealer Card 4',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
        {
            field: 'd5',
            headerName: 'Dealer Card 5',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
        {
            field: 'd6',
            headerName: 'Dealer Card 6',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
        {
            field: 'd7',
            headerName: 'Dealer Card 7',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
        {
            field: 'd8',
            headerName: 'Dealer Card 8',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
        {
            field: 'd9',
            headerName: 'Dealer Card 9',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return params.value
            },
            maxWidth: 200,
        },
    ]

    const rouViewListColumnDefs = [
        {
            field: 'startTime',
            headerName: 'Start Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'endTime',
            headerName: 'End Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'gameID',
            headerName: 'Game ID',
            maxWidth: 200,
        },
        {
            field: 'gameNumber',
            headerName: 'Game Number',
            maxWidth: 200,
        },
        {
            field: 'dealer',
            headerName: 'Dealer',
            maxWidth: 200,
        },
        {
            field: 'wager',
            headerName: 'Wager',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return formatCashString(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'houseWinLoss',
            headerName: 'House Win/Loss',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return formatCashString(params.value)
            },
            cellStyle: (params) => styles.cashValue(params.value),
            maxWidth: 200,
        },
        {
            field: 'result',
            headerName: 'Result',
            maxWidth: 200,
        },
        {
            field: 'resultWinnerColor',
            headerName: 'Result Winner Color',
            maxWidth: 200,
        },
    ]

    const warViewListColumnDefs = [
        {
            field: 'startTime',
            headerName: 'Start Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'endTime',
            headerName: 'End Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'gameID',
            headerName: 'Game ID',
            maxWidth: 200,
        },
        {
            field: 'gameNumber',
            headerName: 'Game Number',
            maxWidth: 200,
        },
        {
            field: 'dealer',
            headerName: 'Dealer',
            maxWidth: 200,
        },
        {
            field: 'wager',
            headerName: 'Wager',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return formatCashString(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'houseWinLoss',
            headerName: 'House Win/Loss',
            valueFormatter: function (params) {
                if (settings && settings.data.xtable.gameOnlyMode) return 'N/A'
                return formatCashString(params.value)
            },
            cellStyle: (params) => styles.cashValue(params.value),
            maxWidth: 200,
        },
        {
            field: 'dealerCards',
            headerName: 'Dealer Cards',
            maxWidth: 200,
        },
        {
            field: 'playerCards',
            headerName: 'Player Cards',
            maxWidth: 200,
        },
        {
            field: 'dealerWarCards',
            headerName: 'Dealer War Cards',
            maxWidth: 200,
        },
        {
            field: 'playerWarCards',
            headerName: 'Player War Cards',
            maxWidth: 200,
        },
        {
            field: 'result',
            headerName: 'Result',
            valueFormatter: function (params) {
                return getWarResultTranslation(params.value)
            },
            maxWidth: 200,
        },
    ]

    const rowSelectHandler = (event) => {
        if (event.node.isSelected()) {
            setTabIndex(1)
            setSelectedGameIndex(deviceGames.length - event.data.gameNumber.split('/')[0])
        }
    }

    // act logs table
    const [actLogsType, setActLogsType] = useState('cashDrop')
    const handleActLogsTypeChange = (e) => {
        setActLogsType(e.target.value)
    }

    const cashdropColumnDefs = [
        {
            field: 'time',
            headerName: 'Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            valueFormatter: function (params) {
                return formatCashString(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'playerID',
            headerName: 'Player ID or VIP ID',
            resizable: true,
            width: 300,
            maxWidth: 400,
        },
        {
            field: 'seatNumber',
            headerName: 'Player Seat Number',
            maxWidth: 200,
        },
        {
            field: 'dealer',
            headerName: 'Dealer',
            maxWidth: 200,
        },
        {
            field: 'isPitbossApproved',
            headerName: 'Pitboss Approved',
            maxWidth: 200,
        },
    ]

    const chipfillcreditColumnDefs = [
        {
            field: 'time',
            headerName: 'Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'fillOrCredit',
            headerName: 'Fill or Credit',
            valueFormatter: function (params) {
                return startCase(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            valueFormatter: function (params) {
                return formatCashString(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'dealer',
            headerName: 'Dealer',
            maxWidth: 200,
        },
        {
            field: 'isPitbossApproved',
            headerName: 'Pitboss Approved',
            maxWidth: 200,
        },
        {
            field: 'trayStaff',
            headerName: 'Tray Staff',
            maxWidth: 200,
        },
    ]

    // built charts
    const [builtCharts, setBuiltCharts] = useState({})
    const setBuiltChartProxy = (chartID, value) => {
        setBuiltCharts((prevBuilt) => ({
            ...prevBuilt,
            [chartID]: value,
        }))
    }

    // data navigation
    const [selectedGameIndex, setSelectedGameIndex] = useState(0)
    const previousGame = () => {
        setSelectedGameIndex((prev) => (prev < deviceGames.length - 1 ? prev + 1 : prev))
        setAutoUpdateEnabled(false)
    }

    const nextGame = () => {
        setSelectedGameIndex((prev) => (prev > 0 ? prev - 1 : prev))
    }
    const lastGame = () => {
        setSelectedGameIndex(0)
        setAutoUpdateEnabled(true)
    }

    const [autoUpdateEnabled, setAutoUpdateEnabled] = useState(true)
    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter((v) => v !== 'push')
        const oldParams = urlParams.toString()
        propKeys.forEach((k) => {
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange =
            propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        const path = xstadium ? 'xstadium' : 'xtable'
        if (urlChange || props.push) {
            navigate(`/${path}/games?` + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                setFetchedTablesReport(false)
                setFetchedTablesCashReport(false)

                if (!props.push) {
                    setAllTablesReport(null)
                    setAllTablesCashReport(null)
                }
                lastGame()
            }

            setFetchedDeviceGames(false)
            if (!props.push) {
                setDeviceGames(null)
                setFetchGamesProgress(0)
                lastGame()
            }
        }
        setBuiltCharts({})
    }

    const [location, setLocation] = useReducer((_state, obj) => {
        clearFetch({ locationID: obj.branchIndex, locationType: obj.branchType })
        return [obj]
    }, null)

    // View all as list by default
    useEffect(() => {
        if (location) {
            setTabIndex(0)
        }
    }, [location])

    // breadcrumbs
    const key = {
        device: { o: devices, p: 'table' },
        table: { o: tables, p: 'pit' },
        pit: { o: pits, p: 'zone' },
        zone: { o: zones, p: 'casino' },
        casino: { o: [], p: null },
    }

    const getBreadcrumbsArray = (type, index) => {
        const obj = key[type.toLowerCase()]
        const dataObj = obj ? obj.o.filter((v) => v.index === index) : {}
        const name = _.get(dataObj, ['0', 'data', 'name'], null)
        const containerIndex = _.get(dataObj, ['0', 'data', 'containerIndex'], null)
        const containerType = _.get(dataObj, ['0', 'data', 'containerType'], obj.p)
        return containerType
            ? [
                getBreadcrumbsArray(containerType, containerIndex),
                {
                    name: name,
                    type: type.toLowerCase(),
                },
            ].flat()
            : [
                {
                    name: 'Casino',
                    type: 'casino',
                },
            ]
    }

    const breadCrumbs = location
        ? levels
            ? getBreadcrumbsArray(_.lowerCase(location[0].branchType), location[0].branchIndex).slice(0 - levels)
            : getBreadcrumbsArray(_.lowerCase(location[0].branchType), location[0].branchIndex)
        : []

    const isCasinoLevel = !location || (location && location[0].branchIndex === 'root')
    const isTableLevel = location && location[0].branchType === 'Table'

    const dateChange = (start, end, push) => {
        if (start === startDate && end === endDate && !push) return
        if (!push && (start !== startDate || end !== endDate)) {
            setStartDate(start)
            setEndDate(end)
        }
        clearFetch({ startDate: start, endDate: end, push: !!push })
    }

    const IPs = location ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices) : getAllIPs(['root'])
    const locationQuery = 'instance=~"' + IPs.join('|') + '"'
    const queryMetricBase = 'sum({' + locationQuery + ', __name__=~"tray_'
    const queryCashdropHistory = queryMetricBase + 'cashdrop"})'
    const queryChipfillHistory = queryMetricBase + 'chipfill"})'
    const queryChipcreditHistory = queryMetricBase + 'chipcredit"})'

    const getDevicesReport = async () => {
        if (fetchedTablesReport) {
            return
        }

        const api = settings && settings.data.xtable.gameOnlyMode ? historyApi : tableApi

        setFetchedTablesReport(true)
        try {
            setFetchTablesReportProgress(1)
            setTimeout(() => {
                setFetchTablesReportProgress(11)
            }, 300)
            setTimeout(() => {
                setFetchTablesReportProgress(22)
            }, 200)
            setTimeout(() => {
                setFetchTablesReportProgress(33)
            }, 200)
            const response = await api
                .get('report/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            setFetchTablesReportProgress(40)
            setFetchTablesReportProgress(80)
            setTimeout(() => {
                setFetchTablesReportProgress(84)
            }, 300)
            setTimeout(() => {
                setFetchTablesReportProgress(98)
            }, 600)
            setTimeout(() => {
                setAllTablesReport(response)
            }, 700)
        } catch (e) {
            console.warn(e)
            setAllTablesReport([])
        }
    }

    const getDeviceCashReport = async (deviceID) => {
        if (fetchedTablesCashReport) {
            return
        }

        setFetchedTablesCashReport(true)
        if (settings?.data.xtable.gameOnlyMode) {
            setAllTablesCashReport([])
            return
        }
        try {
            setFetchTablesCashReportProgress(1)
            setTimeout(() => {
                setFetchTablesCashReportProgress(11)
            }, 300)
            setTimeout(() => {
                setFetchTablesCashReportProgress(22)
            }, 200)
            setTimeout(() => {
                setFetchTablesCashReportProgress(33)
            }, 200)
            const response = await cashApi
                .get('report/table/' + deviceID + '/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            setFetchTablesCashReportProgress(40)
            setFetchTablesCashReportProgress(80)
            setTimeout(() => {
                setFetchTablesCashReportProgress(84)
            }, 300)
            setTimeout(() => {
                setFetchTablesCashReportProgress(98)
            }, 600)
            setTimeout(() => {
                setAllTablesCashReport([response])
            }, 700)
        } catch (e) {
            console.warn(e)
            setAllTablesCashReport([])
        }
    }

    if (settings && Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        getDevicesReport()
    }

    const getDeviceGames = async (deviceID, gameType, gameVariant) => {
        if (fetchedDeviceGames) {
            return
        }

        const api = settings?.data.xtable.gameOnlyMode || gameType === "war" || gameType === "roulette" || gameType === "sicbo" ? historyApi : tableApi

        setFetchedDeviceGames(true)
        try {
            setFetchGamesProgress(1)
            setTimeout(() => {
                setFetchGamesProgress(11)
            }, 300)
            setTimeout(() => {
                setFetchGamesProgress(22)
            }, 200)
            setTimeout(() => {
                setFetchGamesProgress(33)
            }, 200)
            const response = await api
                .get(
                    'lastgames/' +
                    gameType +
                    '/' +
                    gameVariant +
                    '/' +
                    deviceID +
                    '/' +
                    startDate * 1000000000 +
                    '/' +
                    endDate * 1000000000
                )
                .json()
            // console.log(response)
            setFetchGamesProgress(40)
            setTimeout(() => {
                setFetchGamesProgress(100)
            }, 300)
            setTimeout(() => {
                setDeviceGames(response)
            }, 600)
        } catch (e) {
            console.warn(e)
            setDeviceGames([])
        }
    }

    const getMemberships = async () => {
        if (fetchedMemberships) {
            return
        }

        setFetchedMemberships(true)
        try {
            const response = await playerApi.get('memberships').json()
            setMemberships(response)
        } catch (e) {
            console.warn(e)
            setMemberships([])
        }
    }

    if (settings && !settings.data.xtable.gameOnlyMode) {
        getMemberships()
    }

    const getPlayersReport = async () => {
        if (fetchedPlayersReport) {
            return
        }
        setFetchedPlayersReport(true)
        setPlayersReport(null)
        try {
            const response = await playerApi
                .get('report/overview/players/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            console.log('r', startDate, endDate, response)
            setPlayersReport(response)
        } catch (e) {
            console.warn(e)
            setPlayersReport([])
        }
    }

    if (tabIndex === 1 && memberships && Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        if (!fetchedPlayersReport) {
            clearFetch({})
        }
        getPlayersReport()
    }

    const getHoursCashDropData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'cash drop',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.drop['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[4],
                borderColor: chartBackgroundColors[4],
            },
        ],
    })

    const getHoursChipFillData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'chip fill',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.fill['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[5],
                borderColor: chartBackgroundColors[5],
            },
        ],
    })

    const getHoursChipCreditData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'chip credit',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.credit['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[6],
                borderColor: chartBackgroundColors[6],
            },
        ],
    })

    const getHoursWagerData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'wager',
                data: hoursLabels.map((hr) => tablesReport.reduce((prev, next) => prev + next.hourlyBets['h' + hr], 0)),
                backgroundColor: chartBackgroundColors[4],
                borderColor: chartBackgroundColors[4],
            },
        ],
    })

    const getHoursWinLossData = () => {
        const data = hoursLabels.map((hr) => tablesReport.reduce((prev, next) => prev + next.hourlyProfit['h' + hr], 0))
        return {
            labels: hoursLabels,
            datasets: [
                {
                    label: 'win/loss',
                    data,
                    backgroundColor: data.map((v) => (v > 0 ? 'chartreuse' : 'red')),
                    borderColor: chartBackgroundColors[6],
                    order: 0,
                },
            ],
        }
    }

    const getHoursGamesData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'games',
                data: hoursLabels.map((hr) =>
                    tablesReport.reduce((prev, next) => prev + next.hourlyGames['h' + hr], 0)
                ),
                // backgroundColor: chartBackgroundColors[6],
                borderColor: chartBackgroundColors[6],
            },
        ],
    })

    const treeLoading = !active || !zones || !pits || !devices || !tables || !status || !settings || !minLoadTime

    if (
        !treeLoading &&
        (!location || (URLlocationID !== location[0].branchIndex && URLlocationType !== location[0].branchType))
    ) {
        setLocation({
            branchIndex: URLlocationID,
            branchType: URLlocationType,
            descendantDevices: getDescendantDevices(pits, tables, devices, URLlocationID, URLlocationType),
            gameType: getGameType(tables, devices, URLlocationID, URLlocationType),
            gameVariant: getGameVariant(tables, devices, URLlocationID, URLlocationType),
        })
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    // display data
    const tablesReport =
        location && allTablesReport
            ? allTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : allTablesReport
    const tablesCashReport =
        location && allTablesCashReport
            ? allTablesCashReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : allTablesCashReport
    const totalGamesReport =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalGames = deviceGames && deviceGames.length ? deviceGames.length : totalGamesReport
    console.log('tablesReport', tablesReport)
    const totalWager =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.bets, 0)
    const totalWinLoss =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.profit, 0)
    const allTables = !treeLoading
        ? isCasinoLevel
            ? tables.filter(atLeastOneDevice(devices))
            : findTablesInLocation(pits, tables, location).filter(atLeastOneDevice(devices))
        : []

    const getSideBetTranslation = (value) => {
        let translation
        switch (value) {
            case 'bankerPair':
                translation = 'Banker Pair'
                break
            case 'playerPair':
                translation = 'Player Pair'
                break
            case 'lucky6_2':
                translation = '2 Card Lucky 6'
                break
            case 'lucky6_3':
                translation = '3 Card Lucky 6'
                break
            case 'mega6_2':
                translation = '2 Card Mega 6'
                break
            case 'mega6_3':
                translation = '3 Card Mega 6'
                break
            case 'natural8':
                translation = 'Natural 8'
                break
            case 'natural9':
                translation = 'Natural 9'
                break
            case 'tigerTie':
                translation = 'Tiger Tie'
                break
            case 'tigerPair':
                translation = 'Tiger Pair'
                break
            case 'tigerBig':
                translation = 'Tiger Big'
                break
            case 'tigerSmall':
                translation = 'Tiger Small'
                break
            case 'tiger':
                translation = 'Tiger'
                break
            case 'tigerPairDouble':
                translation = 'Tiger Pair Double'
                break
            case 'tigerPairSingle':
                translation = 'Tiger Pair Single'
                break
            case 'tigerPairTwin':
                translation = 'Tiger Pair Twin'
                break
            case 'base':
                translation = 'Base'
                break
            case 'doubleDown':
                translation = 'Double Down'
                break
            case 'insurance':
                translation = 'Insurance'
                break
            case 'pair':
                translation = 'Pair'
                break
            case 'split':
                translation = 'Split'
                break
            default:
                translation = ''
        }
        return translation
    }

    const getCardSuitPrefix = (suit) => {
        let prefix
        switch (suit) {
            case 1:
                prefix = 'S'
                break
            case 2:
                prefix = 'H'
                break
            case 3:
                prefix = 'C'
                break
            case 4:
                prefix = 'D'
                break
            default:
                return ''
        }
        return prefix
    }

    const getCardDisplayValue = (value) => {
        return value === 1
            ? 'A'
            : value < 11
                ? value
                : value === 11
                    ? 'J'
                    : value === 12
                        ? 'Q'
                        : value === 13
                            ? 'K'
                            : ''
    }

    const getCardDisplayValueWar = (value) => {
        return value === 14
            ? 'A'
            : value < 11
                ? value
                : value === 11
                    ? 'J'
                    : value === 12
                        ? 'Q'
                        : value === 13
                            ? 'K'
                            : ''
    }

    const getWarResultTranslation = (key) => {
        switch (key) {
            case 'B':
                return 'Dealer wins war'
            case 'P':
                return 'Player wins war'
            case 'T':
                return 'Tie War'
            default:
                return '-'
        }
    }

    const viewListData =
        !isNil(deviceGames) && deviceGames.length > 0
            ? location[0]?.gameType === 'baccarat'
                ? deviceGames.map((game, index) => ({
                    startTime: !game.started ? '-' : game.started,
                    endTime: !game.ended ? '-' : game.ended,
                    deckID: !game.deck ? '-' : game.deck,
                    gameID: !game.id ? '-' : game.id,
                    gameNumber: deviceGames.length - index + '/' + deviceGames.length,
                    resultWinner: !game.result.status
                        ? '-'
                        : game.result.status === 'B'
                            ? 'Banker'
                            : game.result.status === 'P'
                                ? 'Player'
                                : game.result.status === 'T'
                                    ? 'Tie'
                                    : '-',
                    sideBetWin: !game.result
                        ? '-'
                        : keys(omit(game.result, 'status')).filter((key) => game.result[key]).length > 0
                            ? keys(omit(game.result, 'status'))
                                .filter((key) => game.result[key])
                                .map((n) => getSideBetTranslation(n))
                            : '-',
                    dealer: !game.dealer ? '-' : game.dealer,
                    ...(settings &&
                        !settings.data.xtable.gameOnlyMode && {
                        wager: !game.bets ? '-' : game.bets,
                        houseWinLoss: !game.profit ? '-' : game.profit,
                    }),
                    ...(settings &&
                        settings.data.xtable.gameOnlyMode && {
                        wager: 'N/A',
                        houseWinLoss: 'N/A',
                    }),
                    b1: !game.cards.filter((v) => v.owner === 'B')[0]
                        ? '-'
                        : getCardSuitPrefix(game.cards.filter((v) => v.owner === 'B')[0].suit) +
                        '-' +
                        getCardDisplayValue(game.cards.filter((v) => v.owner === 'B')[0].value),
                    b2: !game.cards.filter((v) => v.owner === 'B')[1]
                        ? '-'
                        : getCardSuitPrefix(game.cards.filter((v) => v.owner === 'B')[1].suit) +
                        '-' +
                        getCardDisplayValue(game.cards.filter((v) => v.owner === 'B')[1].value),
                    b3: !game.cards.filter((v) => v.owner === 'B')[2]
                        ? '-'
                        : getCardSuitPrefix(game.cards.filter((v) => v.owner === 'B')[2].suit) +
                        '-' +
                        getCardDisplayValue(game.cards.filter((v) => v.owner === 'B')[2].value),
                    p1: !game.cards.filter((v) => v.owner === 'P')[0]
                        ? '-'
                        : getCardSuitPrefix(game.cards.filter((v) => v.owner === 'P')[0].suit) +
                        '-' +
                        getCardDisplayValue(game.cards.filter((v) => v.owner === 'P')[0].value),
                    p2: !game.cards.filter((v) => v.owner === 'P')[1]
                        ? '-'
                        : getCardSuitPrefix(game.cards.filter((v) => v.owner === 'P')[1].suit) +
                        '-' +
                        getCardDisplayValue(game.cards.filter((v) => v.owner === 'P')[1].value),
                    p3: !game.cards.filter((v) => v.owner === 'P')[2]
                        ? '-'
                        : getCardSuitPrefix(game.cards.filter((v) => v.owner === 'P')[2].suit) +
                        '-' +
                        getCardDisplayValue(game.cards.filter((v) => v.owner === 'P')[2].value),
                }))
                : location[0]?.gameType === 'blackjack'
                    ? deviceGames.map((game, index) => ({
                        startTime: !game.started ? '-' : game.started,
                        endTime: !game.ended ? '-' : game.ended,
                        deckID: !game.deck ? '-' : game.deck,
                        gameID: !game.id ? '-' : game.id,
                        gameNumber: deviceGames.length - index + '/' + deviceGames.length,
                        dealerWinCount: !game.result ? '-' : game.result.dealerWins,
                        playerHandWinCount: !game.result ? '-' : game.result.playerWins,
                        sideBetWin: !game.seats
                            ? '-'
                            : uniq(
                                game.seats
                                    .map((seat) =>
                                        keys(seat.profitPerType).filter((key) => seat.profitPerType[key] * -1 > 0)
                                    )
                                    .flat()
                            ).length > 0
                                ? uniq(
                                    game.seats
                                        .map((seat) =>
                                            keys(seat.profitPerType).filter((key) => seat.profitPerType[key] * -1 > 0)
                                        )
                                        .flat()
                                ).map((n) => getSideBetTranslation(n))
                                : '-',
                        dealer: !game.dealer ? '-' : game.dealer,
                        ...(settings &&
                            !settings.data.xtable.gameOnlyMode && {
                            wager: !game.bets ? '-' : game.bets,
                            houseWinLoss: !game.profit ? '-' : game.profit,
                            d1: !game.dealerHand.cards[0]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[0].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[0].value),
                            d2: !game.dealerHand.cards[1]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[1].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[1].value),
                            d3: !game.dealerHand.cards[2]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[2].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[2].value),
                            d4: !game.dealerHand.cards[3]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[3].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[3].value),
                            d5: !game.dealerHand.cards[4]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[4].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[4].value),
                            d6: !game.dealerHand.cards[5]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[5].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[5].value),
                            d7: !game.dealerHand.cards[6]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[6].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[6].value),
                            d8: !game.dealerHand.cards[7]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[7].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[7].value),
                            d9: !game.dealerHand.cards[8]
                                ? '-'
                                : getCardSuitPrefix(game.dealerHand.cards[8].suit) +
                                '-' +
                                getCardDisplayValue(game.dealerHand.cards[8].value),
                        }),
                        ...(settings &&
                            settings.data.xtable.gameOnlyMode && {
                            wager: 'N/A',
                            houseWinLoss: 'N/A',
                            d1: 'N/A',
                            d2: 'N/A',
                            d3: 'N/A',
                            d4: 'N/A',
                            d5: 'N/A',
                            d6: 'N/A',
                            d7: 'N/A',
                            d8: 'N/A',
                            d9: 'N/A',
                        }),
                    }))
                    : location[0]?.gameType === 'roulette'
                        ? deviceGames.map((game, index) => ({
                            startTime: !game.started ? '-' : game.started,
                            endTime: !game.ended ? '-' : game.ended,
                            gameID: !game.id ? '-' : game.id,
                            gameNumber: deviceGames.length - index + '/' + deviceGames.length,
                            dealer: !game.dealer ? '-' : game.dealer,
                            ...(settings &&
                                !settings.data.xtable.gameOnlyMode && {
                                wager: !game.bets ? '-' : game.bets,
                                houseWinLoss: !game.profit ? '-' : game.profit,
                            }),
                            ...(settings &&
                                settings.data.xtable.gameOnlyMode && {
                                wager: 'N/A',
                                houseWinLoss: 'N/A',
                            }),
                            result: isNil(game.result) ? '-' : game.result,
                            resultWinnerColor: !game.color ? '-' : startCase(game.color),
                        }))
                        : location[0]?.gameType === 'war'
                            ? deviceGames.map((game, index) => ({
                                startTime: !game.started ? '-' : game.started,
                                endTime: !game.ended ? '-' : game.ended,
                                gameID: !game.id ? '-' : game.id,
                                gameNumber: deviceGames.length - index + '/' + deviceGames.length,
                                dealer: !game.dealer ? '-' : game.dealer,
                                ...(settings &&
                                    !settings.data.xtable.gameOnlyMode && {
                                    wager: !game.bets ? '-' : game.bets,
                                    houseWinLoss: !game.profit ? '-' : game.profit,
                                }),
                                ...(settings &&
                                    settings.data.xtable.gameOnlyMode && {
                                    wager: 'N/A',
                                    houseWinLoss: 'N/A',
                                }),
                                dealerCards: !game.anteCards
                                    ? '-'
                                    : getCardSuitPrefix(game.anteCards.dealer.suit) +
                                    '-' +
                                    getCardDisplayValueWar(game.anteCards.dealer.value),
                                playerCards: !game.anteCards
                                    ? '-'
                                    : game.anteCards.player
                                        .map((v) => getCardSuitPrefix(v.suit) + '-' + getCardDisplayValueWar(v.value))
                                        .join(', '),
                                dealerWarCards: !game.warCards
                                    ? '-'
                                    : getCardSuitPrefix(game.warCards.dealer.suit) +
                                    '-' +
                                    getCardDisplayValueWar(game.warCards.dealer.value),
                                playerWarCards: !game.warCards
                                    ? '-'
                                    : getCardSuitPrefix(game.warCards.player.suit) +
                                    '-' +
                                    getCardDisplayValueWar(game.warCards.player.value),
                                result: isNil(game.result) ? '-' : game.result.warResult,
                            }))
                            : []
            : []

    console.log('deviceGames', deviceGames)
    console.log('viewListData', viewListData)

    const cashdropData = tablesCashReport
        ? tablesCashReport
            .map((report) =>
                report.drop.map((drop) => {
                    const device = devices.filter((x) => x.index === report.device)[0]

                    return {
                        time: !drop.date ? '-' : drop.date,
                        amount: !drop.amount ? '-' : drop.amount,
                        playerID: !drop.playerID ? '-' : drop.playerID,
                        seatNumber: !drop.seatNumber ? '-' : drop.seatNumber,
                        dealer: !drop.dealerID ? '-' : drop.dealerID,
                        isPitbossApproved:
                            get(device, 'data.buyInConfirmation', false) &&
                                drop.amount >= get(device, 'data.buyInThreshold', 0)
                                ? 'Yes'
                                : 'No',
                    }
                })
            )
            .flat(1)
        : []

    const chipfillData = tablesCashReport
        ? tablesCashReport
            .map((report) =>
                report.fill.map((fill) => {
                    const device = devices.filter((x) => x.index === report.device)[0]

                    return {
                        time: !fill.date ? '-' : fill.date,
                        fillOrCredit: 'fill',
                        amount: !fill.amount ? '-' : fill.amount,
                        dealer: !fill.dealerID ? '-' : fill.dealerID,
                        isPitbossApproved:
                            get(device, 'data.buyInConfirmation', false) &&
                                fill.amount >= get(device, 'data.buyInThreshold', 0)
                                ? 'Yes'
                                : 'No',
                        trayStaff: '-',
                    }
                })
            )
            .flat(1)
        : []

    const chipcreditData = tablesCashReport
        ? tablesCashReport
            .map((report) =>
                report.credit.map((credit) => {
                    const device = devices.filter((x) => x.index === report.device)[0]

                    return {
                        time: !credit.date ? '-' : credit.date,
                        fillOrCredit: 'credit',
                        amount: !credit.amount ? '-' : credit.amount,
                        dealer: !credit.dealerID ? '-' : credit.dealerID,
                        isPitbossApproved:
                            get(device, 'data.buyInConfirmation', false) &&
                                credit.amount >= get(device, 'data.buyInThreshold', 0)
                                ? 'Yes'
                                : 'No',
                        trayStaff: '-',
                    }
                })
            )
            .flat(1)
        : []

    const fillCreditData = chipfillData.concat(chipcreditData)

    const getViewListColumnDefs = (gameType) => {
        switch (gameType) {
            case 'baccarat':
                return bacViewListColumnDefs
            case 'blackjack':
                return bjViewListColumnDefs
            case 'roulette':
                return rouViewListColumnDefs
            case 'war':
                return warViewListColumnDefs
            default:
                return []
        }
    }

    const onExportClick = async () => {
        const workbook = new ExcelJS.Workbook()
        const sheet = workbook.addWorksheet('Game History')

        sheet.getCell('A1').value = 'X Table Game History Report'
        sheet.getCell('A2').value = 'Date of data:'
        sheet.getCell('B2').value = moment(moment.unix(startDate).format('YYYY-MM-DD')).isSame(
            moment.unix(endDate).format('YYYY-MM-DD')
        )
            ? moment.unix(endDate).format('YYYY-MM-DD')
            : moment.unix(startDate).format('YYYY-MM-DD') + ' - ' + moment.unix(endDate).format('YYYY-MM-DD')
        sheet.getCell('A3').value = 'Report run date:'
        sheet.getCell('B3').value = moment(new Date()).format('YYYY-MM-DD hh:mm:ss A')

        sheet.getCell('A4').value = 'Game type'
        sheet.getCell('B4').value = location ? getGameTypeTranslation(location[0]?.gameType) : '-'
        sheet.getCell('A5').value = 'Game variant'
        sheet.getCell('B5').value = location ? getGameVariantTranslation(location[0]?.gameVariant) : '-'
        sheet.getCell('A6').value = 'Number of games'
        sheet.getCell('B6').value = totalGames

        sheet.getCell('D1').value = 'Casino:'
        sheet.getCell('E1').value = breadCrumbs.find((item) => item.type === 'casino')
            ? breadCrumbs.find((item) => item.type === 'casino').name
            : '-'
        sheet.getCell('D2').value = 'Zone:'
        sheet.getCell('E2').value = breadCrumbs.find((item) => item.type === 'zone')
            ? breadCrumbs.find((item) => item.type === 'zone').name
            : '-'
        sheet.getCell('D3').value = 'Pit:'
        sheet.getCell('E3').value = breadCrumbs.find((item) => item.type === 'pit')
            ? breadCrumbs.find((item) => item.type === 'pit').name
            : '-'
        sheet.getCell('D4').value = 'Table:'
        sheet.getCell('E4').value = breadCrumbs.find((item) => item.type === 'table')
            ? breadCrumbs.find((item) => item.type === 'table').name
            : '-'

        sheet.addTable({
            name: 'Game_History',
            ref: 'A8',
            columns: getViewListColumnDefs(location[0]?.gameType).map((column) =>
                Object.assign({
                    name: column.headerName,
                })
            ),
            rows: cloneDeep(viewListData).map((viewList) => {
                getViewListColumnDefs(location[0]?.gameType).forEach((columnDef) => {
                    if (columnDef.field === 'startTime' || columnDef.field === 'endTime') {
                        viewList[columnDef.field] = DateTimeDisplay(viewList[columnDef.field])
                    }
                    if (columnDef.field === 'sideBetWin' && isEmpty(viewList[columnDef.field])) {
                        viewList[columnDef.field] = ''
                    }
                    if (
                        columnDef.field === 'sideBetWin' &&
                        !isEmpty(viewList[columnDef.field]) &&
                        viewList[columnDef.field] !== '-'
                    ) {
                        viewList[columnDef.field] = viewList[columnDef.field].join(', ')
                    }
                    if (location[0]?.gameType === 'war' && columnDef.field === 'result') {
                        viewList[columnDef.field] = getWarResultTranslation(viewList[columnDef.field])
                    }
                    if (typeof viewList[columnDef.field] === 'undefined') {
                        viewList[columnDef.field] = 'N/A'
                    }
                })

                console.log('viewList', viewList)

                return Object.values(viewList)
            }),
        })

        const buffer = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buffer]), `Game History_${moment(new Date()).format('YYYYMMDDHHmmss')}.xlsx`)
    }

    if (allTables.length === 1) {
        const selectedDevice = findGameDeviceInTable(devices, allTables[0].index)
        if (selectedDevice.length > 0) {
            getDeviceGames(selectedDevice[0].index, selectedDevice[0].data.gameType, selectedDevice[0].data.gameVariant)
            if (settings && settings.data && !settings.data.gameOnlyMode) {
                getDeviceCashReport(selectedDevice[0].index)
            }
        }
    }

    if (resizing && builtCharts && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    if (settings && !settings.data.xtable.sections.includes('games')) {
        return <Navigate to='/dashboard' />
    }

    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: 320 }}>
                <Tree
                    loading={treeLoading}
                    autoUpdateEnabled={autoUpdateEnabled}
                    toggleAutoUpdate={(v) => setAutoUpdateEnabled(v)}
                    dateChange={dateChange}
                    startDate={startDate}
                    endDate={endDate}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    setLocation={(newLocation) => {
                        console.log(newLocation, location)
                        setLocation(newLocation)
                        if (newLocation.branchIndex !== location.branchIndex) {
                            setAllTablesCashReport(null)
                            setFetchedTablesCashReport(false)
                        }
                    }}
                    location={location}
                    fluid={width <= 1680}
                    xstadium={xstadium}
                />
            </Grid>
            <Grid item xs>
                <Location
                    loading={treeLoading}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    location={location}
                    fluid={width <= 1680}
                />
                {!allTablesReport && (
                    <Container fluid={width <= 1680}>
                        <Row xs='1'>
                            <Col>
                                <h3>Fetching report...</h3>
                                <Progress value={fetchTablesReportProgress} />
                            </Col>
                        </Row>
                    </Container>
                )}

                {allTablesReport && !resizing && location && (
                    <Container fluid={width <= 1680}>
                        <Row xs='3'>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}># of games</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span style={styles.cardBodyText}>{totalGames}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>Total Wager</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span style={styles.cardBodyText}>{formatCashString(totalWager)}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>House Total win/loss</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span
                                            style={{
                                                ...styles.cardBodyText,
                                                ...styles.cashValue(totalWinLoss),
                                            }}
                                        >
                                            {formatCashString(totalWinLoss)}
                                        </span>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {isTableLevel && allTables.length === 1 && !deviceGames && (
                            <Container fluid>
                                <Row xs='1'>
                                    <h3>Fetching Games...</h3>
                                    <Progress value={fetchGamesProgress} />
                                </Row>
                            </Container>
                        )}

                        {isTableLevel &&
                            allTables.length === 1 &&
                            deviceGames &&
                            deviceGames.length >= 1 &&
                            deviceGames[selectedGameIndex] &&
                            location[0]?.gameType === 'baccarat' && (
                                <div>
                                    <Container fluid>
                                        <Row
                                            xs='1'
                                            style={{
                                                marginBottom: '2.2rem',
                                                borderBottom: '2px solid rgb(0, 161, 255)',
                                            }}
                                        >
                                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                                <Tabs value={tabIndex} onChange={handleChange}>
                                                    <CustomTab label='View list' />
                                                    <CustomTab label='View game' />
                                                    {settings &&
                                                        !settings.data.xtable.gameOnlyMode && [
                                                            <CustomTab label='Activity charts' />,
                                                            <CustomTab label='Activity logs' />,
                                                        ]}
                                                </Tabs>

                                                <Button
                                                    variant='outlined'
                                                    sx={{ height: '2.35rem' }}
                                                    onClick={() => onExportClick()}
                                                >
                                                    Export
                                                </Button>
                                            </Box>
                                        </Row>
                                    </Container>

                                    {tabIndex === 0 && (
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <div
                                                            className='ag-theme-balham-dark'
                                                            style={{
                                                                width: '-webkit-fill-available',
                                                                height: 'calc(100vh - 460px)',
                                                                colorScheme: 'dark',
                                                            }}
                                                        >
                                                            <AgGridReact
                                                                defaultColDef={defaultColDef}
                                                                columnDefs={bacViewListColumnDefs}
                                                                rowData={viewListData}
                                                                animateRows={true}
                                                                rowSelection={'single'}
                                                                onRowSelected={rowSelectHandler}
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}

                                    {tabIndex === 1 && (
                                        <>
                                            <Container fluid>
                                                <Row xs='2'>
                                                    <Col>
                                                        <Box
                                                            display='flex'
                                                            justifyContent='space-between'
                                                            padding='1.25em'
                                                        >
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={previousGame}
                                                                disabled={selectedGameIndex === deviceGames.length - 1}
                                                                disableRipple
                                                            >
                                                                Previous Game
                                                            </Button>
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={nextGame}
                                                                disabled={selectedGameIndex === 0}
                                                                disableRipple
                                                            >
                                                                Next Game
                                                            </Button>
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={lastGame}
                                                                disabled={selectedGameIndex === 0}
                                                                disableRipple
                                                            >
                                                                Last Game
                                                            </Button>
                                                        </Box>
                                                    </Col>

                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game Date</span>
                                                                <span style={styles.cardBodyTextStringDate}>
                                                                    {DateTimeDisplay(
                                                                        deviceGames[selectedGameIndex].ended
                                                                    )}
                                                                </span>
                                                            </CardHeader>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>

                                            <Container fluid>
                                                <Row xs='5'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game ID</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={{
                                                                        ...styles.cardBodyTextString,
                                                                        fontSize: '1em',
                                                                    }}
                                                                >
                                                                    {deviceGames[selectedGameIndex].id}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game Number</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextString}>
                                                                    {deviceGames.length - selectedGameIndex} /{' '}
                                                                    {deviceGames.length}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Dealer</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextString}>
                                                                    {deviceGames[selectedGameIndex].dealer
                                                                        ? deviceGames[selectedGameIndex].dealer
                                                                        : 'Unknown'}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={
                                                                        settings?.data.xtable.gameOnlyMode
                                                                            ? { ...styles.cardBodyText, color: 'white' }
                                                                            : { ...styles.cardBodyText }
                                                                    }
                                                                >
                                                                    {settings?.data.xtable.gameOnlyMode
                                                                        ? 'N/A'
                                                                        : formatCashString(
                                                                            deviceGames[selectedGameIndex].bets
                                                                        )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>House Win/Loss</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={{
                                                                        ...styles.cardBodyText,
                                                                        ...styles.cashValue(
                                                                            deviceGames[selectedGameIndex].profit
                                                                        ),
                                                                    }}
                                                                >
                                                                    {settings?.data.xtable.gameOnlyMode
                                                                        ? 'N/A'
                                                                        : formatCashString(
                                                                            deviceGames[selectedGameIndex].profit
                                                                        )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row xs='3'>
                                                    <Col sm='6'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span
                                                                    style={{ ...styles.cardTitleText, minHeight: 20 }}
                                                                >
                                                                    Cards
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <Cards
                                                                    gameType='baccarat'
                                                                    cards={deviceGames[selectedGameIndex].cards}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col sm='2'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Tally</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextStringResult('P')}>
                                                                    {deviceGames[selectedGameIndex].tally.player}
                                                                </span>
                                                                <span style={styles.cardBodyTextStringResult('B')}>
                                                                    {deviceGames[selectedGameIndex].tally.banker}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col sm='4'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Result</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={styles.cardBodyTextStringResult(
                                                                        deviceGames[selectedGameIndex].result.status
                                                                    )}
                                                                >
                                                                    {deviceGames[selectedGameIndex].result.status}
                                                                </span>
                                                                {deviceGames[selectedGameIndex].result.playerPair && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        Player Pair
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.bankerPair && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        Banker Pair
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.natural8 && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        Natural 8
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.natural9 && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        Natural 9
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.lucky6_2 && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        2 Card Lucky 6
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.lucky6_3 && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        3 Card Lucky 6
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.mega6_2 && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        2 Card Mega 6
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.mega6_3 && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        3 Card Mega 6
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.tigerTie && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        Tiger Tie
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.tigerPair && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        Tiger Pair
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.tigerBig && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        Tiger Big
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.tigerSmall && (
                                                                    <span style={styles.cardBodyTextString}>
                                                                        Tiger Small
                                                                    </span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result.tiger && (
                                                                    <span style={styles.cardBodyTextString}>Tiger</span>
                                                                )}
                                                                {deviceGames[selectedGameIndex].result
                                                                    .tigerPairDouble && (
                                                                        <span style={styles.cardBodyTextString}>
                                                                            Tiger Pair Double
                                                                        </span>
                                                                    )}
                                                                {deviceGames[selectedGameIndex].result
                                                                    .tigerPairSingle && (
                                                                        <span style={styles.cardBodyTextString}>
                                                                            Tiger Pair Single
                                                                        </span>
                                                                    )}
                                                                {deviceGames[selectedGameIndex].result
                                                                    .tigerPairTwin && (
                                                                        <span style={styles.cardBodyTextString}>
                                                                            Tiger Pair Twin
                                                                        </span>
                                                                    )}
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                {settings && !settings.data.xtable.gameOnlyMode && (
                                                    <>
                                                        <Row xs={width > 1300 ? '5' : '1'}>
                                                            {deviceGames[selectedGameIndex].seats.map(
                                                                (seat, seatIndex) => (
                                                                    <Col
                                                                        key={seatIndex}
                                                                        style={
                                                                            width < 1400
                                                                                ? {
                                                                                    paddingLeft: 3,
                                                                                    paddingRight: 3,
                                                                                    display: 'flex',
                                                                                }
                                                                                : {}
                                                                        }
                                                                    >
                                                                        <Card style={styles.seatCard(seatIndex)}>
                                                                            <CardHeader
                                                                                style={{
                                                                                    ...styles.cardHeader,
                                                                                    backgroundColor:
                                                                                        'transparent !important',
                                                                                }}
                                                                            >
                                                                                {(() => {
                                                                                    const selectedDevice =
                                                                                        findGameDeviceInTable(
                                                                                            devices,
                                                                                            allTables[0].index
                                                                                        )
                                                                                    // avoid image cache
                                                                                    const timestamp =
                                                                                        new Date().getTime()

                                                                                    const isMember =
                                                                                        memberships &&
                                                                                        memberships.find(
                                                                                            (member) =>
                                                                                                member.id ===
                                                                                                seat.player
                                                                                        )

                                                                                    const memberInfo =
                                                                                        isMember &&
                                                                                        memberships.find(
                                                                                            (member) =>
                                                                                                member.id ===
                                                                                                seat.player
                                                                                        )

                                                                                    const matchPlayerReport =
                                                                                        playersReport &&
                                                                                        playersReport.find(
                                                                                            (player) =>
                                                                                                player.id ===
                                                                                                seat.player
                                                                                        )

                                                                                    const isPlayerHasSession =
                                                                                        matchPlayerReport &&
                                                                                        matchPlayerReport.membership
                                                                                            .sessions &&
                                                                                        matchPlayerReport.membership.sessions.includes(
                                                                                            seat.player
                                                                                        )

                                                                                    const sessionMemberName =
                                                                                        isPlayerHasSession
                                                                                            ? matchPlayerReport
                                                                                                .membership.name
                                                                                            : '-'

                                                                                    const sessionMemberPicture =
                                                                                        isPlayerHasSession
                                                                                            ? matchPlayerReport
                                                                                                .membership.picture
                                                                                            : '-'

                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                style={
                                                                                                    styles.playerSeatName
                                                                                                }
                                                                                            >
                                                                                                <span
                                                                                                    style={
                                                                                                        styles.cardTitleText
                                                                                                    }
                                                                                                >
                                                                                                    Seat{' '}
                                                                                                    {(selectedDevice[0]
                                                                                                        .data
                                                                                                        .gameType ===
                                                                                                        'baccarat' ||
                                                                                                        selectedDevice[0]
                                                                                                            .data
                                                                                                            .gameType ===
                                                                                                        'blackjack') &&
                                                                                                        selectedDevice[0]
                                                                                                            .data
                                                                                                            ?.skipSeatFour
                                                                                                        ? seatIndex +
                                                                                                            1 >
                                                                                                            3
                                                                                                            ? seatIndex +
                                                                                                            2
                                                                                                            : seatIndex +
                                                                                                            1
                                                                                                        : seatIndex + 1}
                                                                                                </span>
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        seat.player !==
                                                                                                            ''
                                                                                                            ? isMember
                                                                                                                ? memberInfo.name
                                                                                                                : isPlayerHasSession
                                                                                                                    ? sessionMemberName
                                                                                                                    : seat.player
                                                                                                            : '-'
                                                                                                    }
                                                                                                    followCursor
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            ...styles.cardTitleText,
                                                                                                            ...styles.twoLineEllipsis,
                                                                                                        }}
                                                                                                    >
                                                                                                        {seat.player !==
                                                                                                            ''
                                                                                                            ? isMember
                                                                                                                ? memberInfo.name
                                                                                                                : isPlayerHasSession
                                                                                                                    ? sessionMemberName
                                                                                                                    : seat.player
                                                                                                            : '-'}
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                            {seat.player !== '' ? (
                                                                                                isMember ? (
                                                                                                    <img
                                                                                                        alt={'player'}
                                                                                                        style={
                                                                                                            styles.playerPicture
                                                                                                        }
                                                                                                        src={
                                                                                                            memberInfo.picture
                                                                                                        }
                                                                                                    />
                                                                                                ) : isPlayerHasSession ? (
                                                                                                    <img
                                                                                                        alt={'player'}
                                                                                                        style={
                                                                                                            styles.playerPicture
                                                                                                        }
                                                                                                        src={
                                                                                                            sessionMemberPicture
                                                                                                        }
                                                                                                    />
                                                                                                ) : (
                                                                                                    <img
                                                                                                        alt={'player'}
                                                                                                        style={
                                                                                                            styles.playerPicture
                                                                                                        }
                                                                                                        src={`http://${resultsIP}/players/${seat.player}.png?t=${timestamp}`}
                                                                                                        onError={(e) =>
                                                                                                        (e.target.src =
                                                                                                            DEFAULT_EMPTY_USER)
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            ) : (
                                                                                                <img
                                                                                                    alt={'player'}
                                                                                                    style={
                                                                                                        styles.playerPicture
                                                                                                    }
                                                                                                    src={
                                                                                                        DEFAULT_EMPTY_USER
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                    )
                                                                                })()}
                                                                            </CardHeader>
                                                                            {seat.bet === 0 && (
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Card>
                                                                                            <CardHeader
                                                                                                style={
                                                                                                    styles.cardHeader
                                                                                                }
                                                                                            >
                                                                                                <span
                                                                                                    style={
                                                                                                        styles.cardTitleText
                                                                                                    }
                                                                                                >
                                                                                                    No Wager
                                                                                                </span>
                                                                                            </CardHeader>
                                                                                        </Card>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                            {seat.bet > 0 && (
                                                                                <>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Card>
                                                                                                <CardHeader
                                                                                                    style={
                                                                                                        styles.cardHeader
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardTitleText
                                                                                                        }
                                                                                                    >
                                                                                                        Wager
                                                                                                    </span>
                                                                                                </CardHeader>
                                                                                                <CardBody
                                                                                                    style={
                                                                                                        styles.cardBody
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardBodyTextWager
                                                                                                        }
                                                                                                    >
                                                                                                        {formatCashString(
                                                                                                            seat.bet
                                                                                                        )}
                                                                                                    </span>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Card>
                                                                                                <CardHeader
                                                                                                    style={
                                                                                                        styles.cardHeader
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardTitleText
                                                                                                        }
                                                                                                    >
                                                                                                        Player Win/Loss
                                                                                                    </span>
                                                                                                </CardHeader>
                                                                                                <CardBody
                                                                                                    style={
                                                                                                        styles.cardBody
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            ...styles.cardBodyTextWinLoss,
                                                                                                            ...styles.cashValue(
                                                                                                                seat.profit
                                                                                                            ),
                                                                                                        }}
                                                                                                    >
                                                                                                        {formatCashString(
                                                                                                            seat.profit
                                                                                                        )}
                                                                                                    </span>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row xs='1'>
                                                                                        <Col>
                                                                                            <Card
                                                                                                style={
                                                                                                    width > 1300
                                                                                                        ? {
                                                                                                            display:
                                                                                                                'flex',
                                                                                                            flexDirection:
                                                                                                                'column',
                                                                                                            gap: '1em',
                                                                                                        }
                                                                                                        : {}
                                                                                                }
                                                                                            >
                                                                                                <CardHeader
                                                                                                    style={
                                                                                                        styles.cardHeader
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardTitleText
                                                                                                        }
                                                                                                    >
                                                                                                        Wager Breakdown
                                                                                                    </span>
                                                                                                </CardHeader>
                                                                                                <CardBody
                                                                                                    style={
                                                                                                        styles.cardBody
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        style={
                                                                                                            width > 1300
                                                                                                                ? {}
                                                                                                                : styles.pieContainer
                                                                                                        }
                                                                                                    >
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'standard' && (
                                                                                                                <>
                                                                                                                    <Pie
                                                                                                                        data={{
                                                                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                                                                            datasets:
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        label: 'Wagers',
                                                                                                                                        data: [
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .banker
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .player
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tie
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .bankerPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .playerPair
                                                                                                                                            ),
                                                                                                                                        ],
                                                                                                                                        backgroundColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderWidth: 1,
                                                                                                                                    },
                                                                                                                                ],
                                                                                                                        }}
                                                                                                                        options={{
                                                                                                                            plugins:
                                                                                                                            {
                                                                                                                                legend: false,
                                                                                                                            },
                                                                                                                            tooltips:
                                                                                                                            {
                                                                                                                                callbacks:
                                                                                                                                {
                                                                                                                                    label: function (
                                                                                                                                        tooltipItem,
                                                                                                                                        data
                                                                                                                                    ) {
                                                                                                                                        const type =
                                                                                                                                            data
                                                                                                                                                .labels[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        const value =
                                                                                                                                            data
                                                                                                                                                .datasets[0]
                                                                                                                                                .data[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        return (
                                                                                                                                            type +
                                                                                                                                            ': ' +
                                                                                                                                            formatCashString(
                                                                                                                                                value
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            },
                                                                                                                            responsive: true,
                                                                                                                            maintainAspectRatio: true,
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <DisplayBaccaratStandardBetTypeSimpleCashBreakdown
                                                                                                                        banker={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .banker
                                                                                                                        }
                                                                                                                        player={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .player
                                                                                                                        }
                                                                                                                        tie={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tie
                                                                                                                        }
                                                                                                                        bankerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .bankerPair
                                                                                                                        }
                                                                                                                        playerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .playerPair
                                                                                                                        }
                                                                                                                        total={
                                                                                                                            seat.bet
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                        {(location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'lucky6Slot') && (
                                                                                                                <>
                                                                                                                    <Pie
                                                                                                                        data={{
                                                                                                                            labels: baccaratLucky6SlotCharPerTypeLabels,
                                                                                                                            datasets:
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        label: 'Wagers',
                                                                                                                                        data: [
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .banker
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .player
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tie
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .bankerPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .playerPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .lucky6
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .bonus
                                                                                                                                            ),
                                                                                                                                        ],
                                                                                                                                        backgroundColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderWidth: 1,
                                                                                                                                    },
                                                                                                                                ],
                                                                                                                        }}
                                                                                                                        options={{
                                                                                                                            plugins:
                                                                                                                            {
                                                                                                                                legend: false,
                                                                                                                            },
                                                                                                                            tooltips:
                                                                                                                            {
                                                                                                                                callbacks:
                                                                                                                                {
                                                                                                                                    label: function (
                                                                                                                                        tooltipItem,
                                                                                                                                        data
                                                                                                                                    ) {
                                                                                                                                        const type =
                                                                                                                                            data
                                                                                                                                                .labels[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        const value =
                                                                                                                                            data
                                                                                                                                                .datasets[0]
                                                                                                                                                .data[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        return (
                                                                                                                                            type +
                                                                                                                                            ': ' +
                                                                                                                                            formatCashString(
                                                                                                                                                value
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            },
                                                                                                                            responsive: true,
                                                                                                                            maintainAspectRatio: true,
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <DisplayBaccaratLucky6SlotBetTypeSimpleCashBreakdown
                                                                                                                        banker={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .banker
                                                                                                                        }
                                                                                                                        player={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .player
                                                                                                                        }
                                                                                                                        tie={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tie
                                                                                                                        }
                                                                                                                        bankerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .bankerPair
                                                                                                                        }
                                                                                                                        playerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .playerPair
                                                                                                                        }
                                                                                                                        lucky6={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .lucky6
                                                                                                                        }
                                                                                                                        bonus={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .bonus
                                                                                                                        }
                                                                                                                        total={
                                                                                                                            seat.bet
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                        {(location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'lucky6') && (
                                                                                                                <>
                                                                                                                    <Pie
                                                                                                                        data={{
                                                                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                                                                            datasets:
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        label: 'Wagers',
                                                                                                                                        data: [
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .banker
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .player
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tie
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .bankerPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .playerPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .lucky6
                                                                                                                                            ),
                                                                                                                                        ],
                                                                                                                                        backgroundColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderWidth: 1,
                                                                                                                                    },
                                                                                                                                ],
                                                                                                                        }}
                                                                                                                        options={{
                                                                                                                            plugins:
                                                                                                                            {
                                                                                                                                legend: false,
                                                                                                                            },
                                                                                                                            tooltips:
                                                                                                                            {
                                                                                                                                callbacks:
                                                                                                                                {
                                                                                                                                    label: function (
                                                                                                                                        tooltipItem,
                                                                                                                                        data
                                                                                                                                    ) {
                                                                                                                                        const type =
                                                                                                                                            data
                                                                                                                                                .labels[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        const value =
                                                                                                                                            data
                                                                                                                                                .datasets[0]
                                                                                                                                                .data[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        return (
                                                                                                                                            type +
                                                                                                                                            ': ' +
                                                                                                                                            formatCashString(
                                                                                                                                                value
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            },
                                                                                                                            responsive: true,
                                                                                                                            maintainAspectRatio: true,
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <DisplayBaccaratLucky6BetTypeSimpleCashBreakdown
                                                                                                                        banker={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .banker
                                                                                                                        }
                                                                                                                        player={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .player
                                                                                                                        }
                                                                                                                        tie={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tie
                                                                                                                        }
                                                                                                                        bankerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .bankerPair
                                                                                                                        }
                                                                                                                        playerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .playerPair
                                                                                                                        }
                                                                                                                        lucky6={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .lucky6
                                                                                                                        }
                                                                                                                        total={
                                                                                                                            seat.bet
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'mega6' && (
                                                                                                                <>
                                                                                                                    <Pie
                                                                                                                        data={{
                                                                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                                                                            datasets:
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        label: 'Wagers',
                                                                                                                                        data: [
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .banker
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .player
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tie
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .bankerPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .playerPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .mega6
                                                                                                                                            ),
                                                                                                                                        ],
                                                                                                                                        backgroundColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderWidth: 1,
                                                                                                                                    },
                                                                                                                                ],
                                                                                                                        }}
                                                                                                                        options={{
                                                                                                                            plugins:
                                                                                                                            {
                                                                                                                                legend: false,
                                                                                                                            },
                                                                                                                            tooltips:
                                                                                                                            {
                                                                                                                                callbacks:
                                                                                                                                {
                                                                                                                                    label: function (
                                                                                                                                        tooltipItem,
                                                                                                                                        data
                                                                                                                                    ) {
                                                                                                                                        const type =
                                                                                                                                            data
                                                                                                                                                .labels[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        const value =
                                                                                                                                            data
                                                                                                                                                .datasets[0]
                                                                                                                                                .data[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        return (
                                                                                                                                            type +
                                                                                                                                            ': ' +
                                                                                                                                            formatCashString(
                                                                                                                                                value
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            },
                                                                                                                            responsive: true,
                                                                                                                            maintainAspectRatio: true,
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <DisplayBaccaratMega6BetTypeSimpleCashBreakdown
                                                                                                                        banker={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .banker
                                                                                                                        }
                                                                                                                        player={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .player
                                                                                                                        }
                                                                                                                        tie={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tie
                                                                                                                        }
                                                                                                                        bankerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .bankerPair
                                                                                                                        }
                                                                                                                        playerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .playerPair
                                                                                                                        }
                                                                                                                        mega6={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .mega6
                                                                                                                        }
                                                                                                                        total={
                                                                                                                            seat.bet
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'tiger' && (
                                                                                                                <>
                                                                                                                    <Pie
                                                                                                                        data={{
                                                                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                                                                            datasets:
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        label: 'Wagers',
                                                                                                                                        data: [
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .banker
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .player
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tie
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tigerTie
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tigerPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tigerBig
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tigerSmall
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .tiger
                                                                                                                                            ),
                                                                                                                                        ],
                                                                                                                                        backgroundColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderWidth: 1,
                                                                                                                                    },
                                                                                                                                ],
                                                                                                                        }}
                                                                                                                        options={{
                                                                                                                            plugins:
                                                                                                                            {
                                                                                                                                legend: false,
                                                                                                                            },
                                                                                                                            tooltips:
                                                                                                                            {
                                                                                                                                callbacks:
                                                                                                                                {
                                                                                                                                    label: function (
                                                                                                                                        tooltipItem,
                                                                                                                                        data
                                                                                                                                    ) {
                                                                                                                                        const type =
                                                                                                                                            data
                                                                                                                                                .labels[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        const value =
                                                                                                                                            data
                                                                                                                                                .datasets[0]
                                                                                                                                                .data[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        return (
                                                                                                                                            type +
                                                                                                                                            ': ' +
                                                                                                                                            formatCashString(
                                                                                                                                                value
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            },
                                                                                                                            responsive: true,
                                                                                                                            maintainAspectRatio: true,
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <DisplayBaccaratTigerBetTypeSimpleCashBreakdown
                                                                                                                        banker={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .banker
                                                                                                                        }
                                                                                                                        player={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .player
                                                                                                                        }
                                                                                                                        tie={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tie
                                                                                                                        }
                                                                                                                        tigerTie={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tigerTie
                                                                                                                        }
                                                                                                                        tigerPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tigerPair
                                                                                                                        }
                                                                                                                        tigerBig={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tigerBig
                                                                                                                        }
                                                                                                                        tigerSmall={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tigerSmall
                                                                                                                        }
                                                                                                                        tiger={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .tiger
                                                                                                                        }
                                                                                                                        total={
                                                                                                                            seat.bet
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row xs='1'>
                                                                                        <Col>
                                                                                            <Card>
                                                                                                <CardHeader
                                                                                                    style={
                                                                                                        styles.cardHeader
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardTitleText
                                                                                                        }
                                                                                                    >
                                                                                                        Player Win/Loss
                                                                                                        Breakdown
                                                                                                    </span>
                                                                                                </CardHeader>
                                                                                                <CardBody
                                                                                                    style={
                                                                                                        styles.cardBody
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        style={
                                                                                                            width > 1300
                                                                                                                ? {}
                                                                                                                : styles.pieContainer
                                                                                                        }
                                                                                                    >
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'standard' && (
                                                                                                                <DisplayBaccaratStandardBetTypeSimpleCashBreakdown
                                                                                                                    type='playerWinLoss'
                                                                                                                    banker={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .banker
                                                                                                                    }
                                                                                                                    player={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .player
                                                                                                                    }
                                                                                                                    tie={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tie
                                                                                                                    }
                                                                                                                    bankerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .bankerPair
                                                                                                                    }
                                                                                                                    playerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .playerPair
                                                                                                                    }
                                                                                                                    total={
                                                                                                                        seat.profit
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                        {(location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'lucky6Slot') && (
                                                                                                                <DisplayBaccaratLucky6SlotBetTypeSimpleCashBreakdown
                                                                                                                    type='playerWinLoss'
                                                                                                                    banker={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .banker
                                                                                                                    }
                                                                                                                    player={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .player
                                                                                                                    }
                                                                                                                    tie={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tie
                                                                                                                    }
                                                                                                                    bankerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .bankerPair
                                                                                                                    }
                                                                                                                    playerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .playerPair
                                                                                                                    }
                                                                                                                    lucky6={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .lucky6
                                                                                                                    }
                                                                                                                    bonus={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .bonus
                                                                                                                    }
                                                                                                                    total={
                                                                                                                        seat.profit
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                        {(location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'lucky6') && (
                                                                                                                <DisplayBaccaratLucky6BetTypeSimpleCashBreakdown
                                                                                                                    type='playerWinLoss'
                                                                                                                    banker={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .banker
                                                                                                                    }
                                                                                                                    player={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .player
                                                                                                                    }
                                                                                                                    tie={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tie
                                                                                                                    }
                                                                                                                    bankerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .bankerPair
                                                                                                                    }
                                                                                                                    playerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .playerPair
                                                                                                                    }
                                                                                                                    lucky6={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .lucky6
                                                                                                                    }
                                                                                                                    total={
                                                                                                                        seat.profit
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'mega6' && (
                                                                                                                <DisplayBaccaratMega6BetTypeSimpleCashBreakdown
                                                                                                                    type='playerWinLoss'
                                                                                                                    banker={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .banker
                                                                                                                    }
                                                                                                                    player={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .player
                                                                                                                    }
                                                                                                                    tie={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tie
                                                                                                                    }
                                                                                                                    bankerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .bankerPair
                                                                                                                    }
                                                                                                                    playerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .playerPair
                                                                                                                    }
                                                                                                                    mega6={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .mega6
                                                                                                                    }
                                                                                                                    total={
                                                                                                                        seat.profit
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'tiger' && (
                                                                                                                <DisplayBaccaratTigerBetTypeSimpleCashBreakdown
                                                                                                                    type='playerWinLoss'
                                                                                                                    banker={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .banker
                                                                                                                    }
                                                                                                                    player={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .player
                                                                                                                    }
                                                                                                                    tie={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tie
                                                                                                                    }
                                                                                                                    tigerTie={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tigerTie
                                                                                                                    }
                                                                                                                    tigerPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tigerPair
                                                                                                                    }
                                                                                                                    tigerBig={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tigerBig
                                                                                                                    }
                                                                                                                    tigerSmall={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tigerSmall
                                                                                                                    }
                                                                                                                    tiger={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .tiger
                                                                                                                    }
                                                                                                                    total={
                                                                                                                        seat.profit
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                    </div>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </>
                                                                            )}
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                            )}
                                                        </Row>
                                                    </>
                                                )}

                                                {/* <span>table info {devices[0].index}/{selectedGameIndex}</span>
                        {JSON.stringify(deviceGames[selectedGameIndex])} */}
                                            </Container>
                                        </>
                                    )}
                                </div>
                            )}

                        {isTableLevel &&
                            allTables.length === 1 &&
                            deviceGames &&
                            deviceGames.length >= 1 &&
                            deviceGames[selectedGameIndex] &&
                            location[0]?.gameType === 'blackjack' && (
                                <div>
                                    <Container fluid>
                                        <Row
                                            xs='1'
                                            style={{
                                                marginBottom: '2.2rem',
                                                borderBottom: '2px solid rgb(0, 161, 255)',
                                            }}
                                        >
                                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                                <Tabs value={tabIndex} onChange={handleChange}>
                                                    <CustomTab label='View list' />
                                                    <CustomTab label='View game' />
                                                    {settings &&
                                                        !settings.data.xtable.gameOnlyMode && [
                                                            <CustomTab label='Activity charts' />,
                                                            <CustomTab label='Activity logs' />,
                                                        ]}
                                                </Tabs>

                                                <Button
                                                    variant='outlined'
                                                    sx={{ height: '2.35rem' }}
                                                    onClick={() => onExportClick()}
                                                >
                                                    Export
                                                </Button>
                                            </Box>
                                        </Row>
                                    </Container>

                                    {tabIndex === 0 && (
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <div
                                                            className='ag-theme-balham-dark'
                                                            style={{
                                                                width: '-webkit-fill-available',
                                                                height: 'calc(100vh - 460px)',
                                                                colorScheme: 'dark',
                                                            }}
                                                        >
                                                            <AgGridReact
                                                                defaultColDef={defaultColDef}
                                                                columnDefs={bjViewListColumnDefs}
                                                                rowData={viewListData}
                                                                animateRows={true}
                                                                rowSelection={'single'}
                                                                onRowSelected={rowSelectHandler}
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}

                                    {tabIndex === 1 && (
                                        <>
                                            <Container fluid>
                                                <Row xs='2'>
                                                    <Col>
                                                        <Box
                                                            display='flex'
                                                            justifyContent='space-between'
                                                            padding='1.25em'
                                                        >
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={previousGame}
                                                                disabled={selectedGameIndex === deviceGames.length - 1}
                                                                disableRipple
                                                            >
                                                                Previous Game
                                                            </Button>
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={nextGame}
                                                                disabled={selectedGameIndex === 0}
                                                                disableRipple
                                                            >
                                                                Next Game
                                                            </Button>
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={lastGame}
                                                                disabled={selectedGameIndex === 0}
                                                                disableRipple
                                                            >
                                                                Last Game
                                                            </Button>
                                                        </Box>
                                                    </Col>

                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game Date</span>
                                                                <span style={styles.cardBodyTextStringDate}>
                                                                    {DateTimeDisplay(
                                                                        deviceGames[selectedGameIndex].ended
                                                                    )}
                                                                </span>
                                                            </CardHeader>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>

                                            <Container fluid>
                                                <Row xs='5'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game ID</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={{
                                                                        ...styles.cardBodyTextString,
                                                                        fontSize: '1em',
                                                                    }}
                                                                >
                                                                    {deviceGames[selectedGameIndex].id}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game Number</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextString}>
                                                                    {deviceGames.length - selectedGameIndex} /{' '}
                                                                    {deviceGames.length}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Dealer</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextString}>
                                                                    {deviceGames[selectedGameIndex].dealer}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={
                                                                        settings?.data.xtable.gameOnlyMode
                                                                            ? { ...styles.cardBodyText, color: 'white' }
                                                                            : { ...styles.cardBodyText }
                                                                    }
                                                                >
                                                                    {settings?.data.xtable.gameOnlyMode
                                                                        ? 'N/A'
                                                                        : formatCashString(
                                                                            deviceGames[selectedGameIndex].bets
                                                                        )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>House Win/Loss</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={{
                                                                        ...styles.cardBodyText,
                                                                        ...styles.cashValue(
                                                                            deviceGames[selectedGameIndex].profit
                                                                        ),
                                                                    }}
                                                                >
                                                                    {settings?.data.xtable.gameOnlyMode
                                                                        ? 'N/A'
                                                                        : formatCashString(
                                                                            deviceGames[selectedGameIndex].profit
                                                                        )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row xs='3'>
                                                    <Col sm='6'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span
                                                                    style={{ ...styles.cardTitleText, minHeight: 20 }}
                                                                >
                                                                    Dealer Cards
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <Cards
                                                                    gameType='blackjack'
                                                                    cards={
                                                                        settings && settings.data.xtable.gameOnlyMode
                                                                            ? deviceGames[selectedGameIndex].hands
                                                                                ? deviceGames[
                                                                                    selectedGameIndex
                                                                                ].hands.filter(
                                                                                    (hand) => hand.owner === 0
                                                                                )[0].cards
                                                                                : []
                                                                            : deviceGames[selectedGameIndex].dealerHand
                                                                                ? deviceGames[selectedGameIndex].dealerHand
                                                                                    .cards
                                                                                : []
                                                                    }
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col sm='2'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Dealer Tally</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextStringResult('D')}>
                                                                    {deviceGames[selectedGameIndex].result
                                                                        ? deviceGames[selectedGameIndex].result
                                                                            .dealerTally
                                                                        : '-'}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col sm='4'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Result</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextStringResult('D')}>
                                                                    Dealer Wins{' '}
                                                                    {deviceGames[selectedGameIndex].result
                                                                        ? deviceGames[selectedGameIndex].result
                                                                            .dealerWins
                                                                        : '-'}
                                                                </span>
                                                                <span style={styles.cardBodyTextStringResult('P')}>
                                                                    Player Wins{' '}
                                                                    {deviceGames[selectedGameIndex].result
                                                                        ? deviceGames[selectedGameIndex].result
                                                                            .playerWins
                                                                        : '-'}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                {settings && !settings.data.xtable.gameOnlyMode && (
                                                    <>
                                                        <Row xs={width > 1300 ? '5' : '1'}>
                                                            {deviceGames[selectedGameIndex].seats.map(
                                                                (seat, seatIndex) => (
                                                                    <Col
                                                                        key={seatIndex}
                                                                        style={
                                                                            width < 1400
                                                                                ? {
                                                                                    paddingLeft: 3,
                                                                                    paddingRight: 3,
                                                                                    display: 'flex',
                                                                                }
                                                                                : {}
                                                                        }
                                                                    >
                                                                        <Card style={styles.seatCard(seatIndex)}>
                                                                            <CardHeader
                                                                                style={{
                                                                                    ...styles.cardHeader,
                                                                                    backgroundColor:
                                                                                        'transparent !important',
                                                                                }}
                                                                            >
                                                                                {(() => {
                                                                                    const selectedDevice =
                                                                                        findGameDeviceInTable(
                                                                                            devices,
                                                                                            allTables[0].index
                                                                                        )
                                                                                    // avoid image cache
                                                                                    const timestamp =
                                                                                        new Date().getTime()

                                                                                    const isMember =
                                                                                        memberships &&
                                                                                        memberships.find(
                                                                                            (member) =>
                                                                                                member.id ===
                                                                                                seat.player
                                                                                        )

                                                                                    const memberInfo =
                                                                                        isMember &&
                                                                                        memberships.find(
                                                                                            (member) =>
                                                                                                member.id ===
                                                                                                seat.player
                                                                                        )

                                                                                    const matchPlayerReport =
                                                                                        playersReport &&
                                                                                        playersReport.find(
                                                                                            (player) =>
                                                                                                player.id ===
                                                                                                seat.player
                                                                                        )

                                                                                    const isPlayerHasSession =
                                                                                        matchPlayerReport &&
                                                                                        matchPlayerReport.membership
                                                                                            .sessions &&
                                                                                        matchPlayerReport.membership.sessions.includes(
                                                                                            seat.player
                                                                                        )

                                                                                    const sessionMemberName =
                                                                                        isPlayerHasSession
                                                                                            ? matchPlayerReport
                                                                                                .membership.name
                                                                                            : '-'

                                                                                    const sessionMemberPicture =
                                                                                        isPlayerHasSession
                                                                                            ? matchPlayerReport
                                                                                                .membership.picture
                                                                                            : '-'

                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                style={
                                                                                                    styles.playerSeatName
                                                                                                }
                                                                                            >
                                                                                                <span
                                                                                                    style={
                                                                                                        styles.cardTitleText
                                                                                                    }
                                                                                                >
                                                                                                    Seat{' '}
                                                                                                    {selectedDevice[0]
                                                                                                        .data
                                                                                                        .gameType ===
                                                                                                        'blackjack' &&
                                                                                                        selectedDevice[0]
                                                                                                            .data
                                                                                                            ?.skipSeatFour
                                                                                                        ? seatIndex +
                                                                                                            1 >
                                                                                                            3
                                                                                                            ? seatIndex +
                                                                                                            2
                                                                                                            : seatIndex +
                                                                                                            1
                                                                                                        : seatIndex + 1}
                                                                                                </span>
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        seat.player !==
                                                                                                            ''
                                                                                                            ? isMember
                                                                                                                ? memberInfo.name
                                                                                                                : isPlayerHasSession
                                                                                                                    ? sessionMemberName
                                                                                                                    : seat.player
                                                                                                            : '-'
                                                                                                    }
                                                                                                    followCursor
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            ...styles.cardTitleText,
                                                                                                            ...styles.twoLineEllipsis,
                                                                                                        }}
                                                                                                    >
                                                                                                        {seat.player !==
                                                                                                            ''
                                                                                                            ? isMember
                                                                                                                ? memberInfo.name
                                                                                                                : isPlayerHasSession
                                                                                                                    ? sessionMemberName
                                                                                                                    : seat.player
                                                                                                            : '-'}
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                            {seat.player !== '' ? (
                                                                                                isMember ? (
                                                                                                    <img
                                                                                                        alt={'player'}
                                                                                                        style={
                                                                                                            styles.playerPicture
                                                                                                        }
                                                                                                        src={
                                                                                                            memberInfo.picture
                                                                                                        }
                                                                                                    />
                                                                                                ) : isPlayerHasSession ? (
                                                                                                    <img
                                                                                                        alt={'player'}
                                                                                                        style={
                                                                                                            styles.playerPicture
                                                                                                        }
                                                                                                        src={
                                                                                                            sessionMemberPicture
                                                                                                        }
                                                                                                    />
                                                                                                ) : (
                                                                                                    <img
                                                                                                        alt={'player'}
                                                                                                        style={
                                                                                                            styles.playerPicture
                                                                                                        }
                                                                                                        src={`http://${resultsIP}/players/${seat.player}.png?t=${timestamp}`}
                                                                                                        onError={(e) =>
                                                                                                        (e.target.src =
                                                                                                            DEFAULT_EMPTY_USER)
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            ) : (
                                                                                                <img
                                                                                                    alt={'player'}
                                                                                                    style={
                                                                                                        styles.playerPicture
                                                                                                    }
                                                                                                    src={
                                                                                                        DEFAULT_EMPTY_USER
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                    )
                                                                                })()}
                                                                            </CardHeader>
                                                                            {seat.bet === 0 && (
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Card>
                                                                                            <CardHeader
                                                                                                style={
                                                                                                    styles.cardHeader
                                                                                                }
                                                                                            >
                                                                                                <span
                                                                                                    style={
                                                                                                        styles.cardTitleText
                                                                                                    }
                                                                                                >
                                                                                                    No Wager
                                                                                                </span>
                                                                                            </CardHeader>
                                                                                        </Card>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                            {seat.bet > 0 && (
                                                                                <>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Card>
                                                                                                <CardHeader
                                                                                                    style={
                                                                                                        styles.cardHeader
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardTitleText
                                                                                                        }
                                                                                                    >
                                                                                                        Wager
                                                                                                    </span>
                                                                                                </CardHeader>
                                                                                                <CardBody
                                                                                                    style={
                                                                                                        styles.cardBody
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardBodyTextWager
                                                                                                        }
                                                                                                    >
                                                                                                        {formatCashString(
                                                                                                            seat.bet
                                                                                                        )}
                                                                                                    </span>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Card>
                                                                                                <CardHeader
                                                                                                    style={
                                                                                                        styles.cardHeader
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardTitleText
                                                                                                        }
                                                                                                    >
                                                                                                        Player Win/Loss
                                                                                                    </span>
                                                                                                </CardHeader>
                                                                                                <CardBody
                                                                                                    style={
                                                                                                        styles.cardBody
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            ...styles.cardBodyTextWinLoss,
                                                                                                            ...styles.cashValue(
                                                                                                                seat.profit
                                                                                                            ),
                                                                                                        }}
                                                                                                    >
                                                                                                        {formatCashString(
                                                                                                            seat.profit
                                                                                                        )}
                                                                                                    </span>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row xs='1'>
                                                                                        <Col>
                                                                                            <Card
                                                                                                style={
                                                                                                    width > 1300
                                                                                                        ? {
                                                                                                            display:
                                                                                                                'flex',
                                                                                                            flexDirection:
                                                                                                                'column',
                                                                                                            gap: '1em',
                                                                                                        }
                                                                                                        : {}
                                                                                                }
                                                                                            >
                                                                                                <CardHeader
                                                                                                    style={
                                                                                                        styles.cardHeader
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardTitleText
                                                                                                        }
                                                                                                    >
                                                                                                        Wager Breakdown
                                                                                                    </span>
                                                                                                </CardHeader>
                                                                                                <CardBody
                                                                                                    style={
                                                                                                        styles.cardBody
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        style={
                                                                                                            width > 1300
                                                                                                                ? {}
                                                                                                                : styles.pieContainer
                                                                                                        }
                                                                                                    >
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'standard' && (
                                                                                                                <>
                                                                                                                    <Pie
                                                                                                                        data={{
                                                                                                                            labels: blackjackStandardCharPerTypeLabels,
                                                                                                                            datasets:
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        label: 'Wagers',
                                                                                                                                        data: [
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .base
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .doubleDown
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .insurance
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .pair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .split
                                                                                                                                            ),
                                                                                                                                        ],
                                                                                                                                        backgroundColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderWidth: 1,
                                                                                                                                    },
                                                                                                                                ],
                                                                                                                        }}
                                                                                                                        options={{
                                                                                                                            plugins:
                                                                                                                            {
                                                                                                                                legend: false,
                                                                                                                            },
                                                                                                                            tooltips:
                                                                                                                            {
                                                                                                                                callbacks:
                                                                                                                                {
                                                                                                                                    label: function (
                                                                                                                                        tooltipItem,
                                                                                                                                        data
                                                                                                                                    ) {
                                                                                                                                        const type =
                                                                                                                                            data
                                                                                                                                                .labels[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        const value =
                                                                                                                                            data
                                                                                                                                                .datasets[0]
                                                                                                                                                .data[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        return (
                                                                                                                                            type +
                                                                                                                                            ': ' +
                                                                                                                                            formatCashString(
                                                                                                                                                value
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            },
                                                                                                                            responsive: true,
                                                                                                                            maintainAspectRatio: true,
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <DisplayBlackjackStandardBetTypeSimpleCashBreakdown
                                                                                                                        base={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .base
                                                                                                                        }
                                                                                                                        doubleDown={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .doubleDown
                                                                                                                        }
                                                                                                                        insurance={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .insurance
                                                                                                                        }
                                                                                                                        pair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .pair
                                                                                                                        }
                                                                                                                        split={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .split
                                                                                                                        }
                                                                                                                        total={
                                                                                                                            seat.bet
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'anyPairSlot' && (
                                                                                                                <>
                                                                                                                    <Pie
                                                                                                                        data={{
                                                                                                                            labels: blackjackAnyPairSlotCharPerTypeLabels,
                                                                                                                            datasets:
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        label: 'Wagers',
                                                                                                                                        data: [
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .base
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .pair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .anyPair
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .split
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .doubleDown
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .insurance
                                                                                                                                            ),
                                                                                                                                            formatNumber(
                                                                                                                                                seat
                                                                                                                                                    .betsPerType
                                                                                                                                                    .bonus
                                                                                                                                            ),
                                                                                                                                        ],
                                                                                                                                        backgroundColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderColor:
                                                                                                                                            chartBackgroundColors,
                                                                                                                                        borderWidth: 1,
                                                                                                                                    },
                                                                                                                                ],
                                                                                                                        }}
                                                                                                                        options={{
                                                                                                                            plugins:
                                                                                                                            {
                                                                                                                                legend: false,
                                                                                                                            },
                                                                                                                            tooltips:
                                                                                                                            {
                                                                                                                                callbacks:
                                                                                                                                {
                                                                                                                                    label: function (
                                                                                                                                        tooltipItem,
                                                                                                                                        data
                                                                                                                                    ) {
                                                                                                                                        const type =
                                                                                                                                            data
                                                                                                                                                .labels[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        const value =
                                                                                                                                            data
                                                                                                                                                .datasets[0]
                                                                                                                                                .data[
                                                                                                                                            tooltipItem
                                                                                                                                                .index
                                                                                                                                            ]
                                                                                                                                        return (
                                                                                                                                            type +
                                                                                                                                            ': ' +
                                                                                                                                            formatCashString(
                                                                                                                                                value
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            },
                                                                                                                            responsive: true,
                                                                                                                            maintainAspectRatio: true,
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <DisplayBlackjackAnyPairSlotBetTypeSimpleCashBreakdown
                                                                                                                        base={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .base
                                                                                                                        }
                                                                                                                        pair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .pair
                                                                                                                        }
                                                                                                                        anyPair={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .anyPair
                                                                                                                        }
                                                                                                                        split={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .split
                                                                                                                        }
                                                                                                                        doubleDown={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .doubleDown
                                                                                                                        }
                                                                                                                        insurance={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .insurance
                                                                                                                        }
                                                                                                                        bonus={
                                                                                                                            seat
                                                                                                                                .betsPerType
                                                                                                                                .bonus
                                                                                                                        }
                                                                                                                        total={
                                                                                                                            seat.bet
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row xs='1'>
                                                                                        <Col>
                                                                                            <Card>
                                                                                                <CardHeader
                                                                                                    style={
                                                                                                        styles.cardHeader
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={
                                                                                                            styles.cardTitleText
                                                                                                        }
                                                                                                    >
                                                                                                        Player Win/Loss
                                                                                                        Breakdown
                                                                                                    </span>
                                                                                                </CardHeader>
                                                                                                <CardBody
                                                                                                    style={
                                                                                                        styles.cardBody
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        style={
                                                                                                            width > 1300
                                                                                                                ? {}
                                                                                                                : styles.pieContainer
                                                                                                        }
                                                                                                    >
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'standard' && (
                                                                                                                <DisplayBlackjackStandardBetTypeSimpleCashBreakdown
                                                                                                                    type='playerWinLoss'
                                                                                                                    base={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .base
                                                                                                                    }
                                                                                                                    doubleDown={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .doubleDown
                                                                                                                    }
                                                                                                                    insurance={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .insurance
                                                                                                                    }
                                                                                                                    pair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .pair
                                                                                                                    }
                                                                                                                    split={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .split
                                                                                                                    }
                                                                                                                    total={
                                                                                                                        seat.profit
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                        {location[0]
                                                                                                            ?.gameVariant ===
                                                                                                            'anyPairSlot' && (
                                                                                                                <DisplayBlackjackAnyPairSlotBetTypeSimpleCashBreakdown
                                                                                                                    type='playerWinLoss'
                                                                                                                    base={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .base
                                                                                                                    }
                                                                                                                    pair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .pair
                                                                                                                    }
                                                                                                                    anyPair={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .anyPair
                                                                                                                    }
                                                                                                                    split={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .split
                                                                                                                    }
                                                                                                                    doubleDown={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .doubleDown
                                                                                                                    }
                                                                                                                    insurance={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .insurance
                                                                                                                    }
                                                                                                                    bonus={
                                                                                                                        seat
                                                                                                                            .profitPerType
                                                                                                                            .bonus
                                                                                                                    }
                                                                                                                    total={
                                                                                                                        seat.profit
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                    </div>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </>
                                                                            )}
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                            )}
                                                        </Row>
                                                    </>
                                                )}

                                                {/* <span>table info {devices[0].index}/{selectedGameIndex}</span>
                                {JSON.stringify(deviceGames[selectedGameIndex])} */}
                                            </Container>
                                        </>
                                    )}
                                </div>
                            )}

                        {isTableLevel &&
                            allTables.length === 1 &&
                            deviceGames &&
                            deviceGames.length >= 1 &&
                            deviceGames[selectedGameIndex] &&
                            location[0]?.gameType === 'roulette' && (
                                <div>
                                    <Container fluid>
                                        <Row
                                            xs='1'
                                            style={{
                                                marginBottom: '2.2rem',
                                                borderBottom: '2px solid rgb(0, 161, 255)',
                                            }}
                                        >
                                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                                <Tabs value={tabIndex} onChange={handleChange}>
                                                    <CustomTab label='View list' />
                                                    <CustomTab label='View game' />
                                                    {settings &&
                                                        !settings.data.xtable.gameOnlyMode && [
                                                            <CustomTab label='Activity charts' />,
                                                            <CustomTab label='Activity logs' />,
                                                        ]}
                                                </Tabs>

                                                <Button
                                                    variant='outlined'
                                                    sx={{ height: '2.35rem' }}
                                                    onClick={() => onExportClick()}
                                                >
                                                    Export
                                                </Button>
                                            </Box>
                                        </Row>
                                    </Container>

                                    {tabIndex === 0 && (
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <div
                                                            className='ag-theme-balham-dark'
                                                            style={{
                                                                width: '-webkit-fill-available',
                                                                height: 'calc(100vh - 460px)',
                                                                colorScheme: 'dark',
                                                            }}
                                                        >
                                                            <AgGridReact
                                                                defaultColDef={defaultColDef}
                                                                columnDefs={rouViewListColumnDefs}
                                                                rowData={viewListData}
                                                                animateRows={true}
                                                                rowSelection={'single'}
                                                                onRowSelected={rowSelectHandler}
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}

                                    {tabIndex === 1 && (
                                        <>
                                            <Container fluid>
                                                <Row xs='2'>
                                                    <Col>
                                                        <Box
                                                            display='flex'
                                                            justifyContent='space-between'
                                                            padding='1.25em'
                                                        >
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={previousGame}
                                                                disabled={selectedGameIndex === deviceGames.length - 1}
                                                                disableRipple
                                                            >
                                                                Previous Game
                                                            </Button>
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={nextGame}
                                                                disabled={selectedGameIndex === 0}
                                                                disableRipple
                                                            >
                                                                Next Game
                                                            </Button>
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={lastGame}
                                                                disabled={selectedGameIndex === 0}
                                                                disableRipple
                                                            >
                                                                Last Game
                                                            </Button>
                                                        </Box>
                                                    </Col>

                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game Date</span>
                                                                <span style={styles.cardBodyTextStringDate}>
                                                                    {DateTimeDisplay(
                                                                        deviceGames[selectedGameIndex].ended
                                                                    )}
                                                                </span>
                                                            </CardHeader>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>

                                            <Container fluid>
                                                <Row xs='5'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game ID</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={{
                                                                        ...styles.cardBodyTextString,
                                                                        fontSize: '1em',
                                                                    }}
                                                                >
                                                                    {deviceGames[selectedGameIndex].id}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game Number</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextString}>
                                                                    {deviceGames.length - selectedGameIndex} /{' '}
                                                                    {deviceGames.length}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Dealer</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextString}>
                                                                    {deviceGames[selectedGameIndex].dealer
                                                                        ? deviceGames[selectedGameIndex].dealer
                                                                        : 'Unknown'}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={
                                                                        settings?.data.xtable.gameOnlyMode
                                                                            ? { ...styles.cardBodyText, color: 'white' }
                                                                            : { ...styles.cardBodyText }
                                                                    }
                                                                >
                                                                    {settings?.data.xtable.gameOnlyMode
                                                                        ? 'N/A'
                                                                        : formatCashString(
                                                                            deviceGames[selectedGameIndex].bets
                                                                        )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>House Win/Loss</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={{
                                                                        ...styles.cardBodyText,
                                                                        ...styles.cashValue(
                                                                            deviceGames[selectedGameIndex].profit
                                                                        ),
                                                                    }}
                                                                >
                                                                    {settings?.data.xtable.gameOnlyMode
                                                                        ? 'N/A'
                                                                        : formatCashString(
                                                                            deviceGames[selectedGameIndex].profit
                                                                        )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row xs='3'>
                                                    <Col sm='4'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Result</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextStringResult('NA')}>
                                                                    {deviceGames[selectedGameIndex].result}
                                                                </span>
                                                                <span style={styles.cardBodyTextStringResult('NA')}>
                                                                    {startCase(deviceGames[selectedGameIndex].color)}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </>
                                    )}
                                </div>
                            )}

                        {isTableLevel &&
                            allTables.length === 1 &&
                            deviceGames &&
                            deviceGames.length >= 1 &&
                            deviceGames[selectedGameIndex] &&
                            location[0]?.gameType === 'war' && (
                                <div>
                                    <Container fluid>
                                        <Row
                                            xs='1'
                                            style={{
                                                marginBottom: '2.2rem',
                                                borderBottom: '2px solid rgb(0, 161, 255)',
                                            }}
                                        >
                                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                                <Tabs value={tabIndex} onChange={handleChange}>
                                                    <CustomTab label='View list' />
                                                    <CustomTab label='View game' />
                                                    {settings &&
                                                        !settings.data.xtable.gameOnlyMode && [
                                                            <CustomTab label='Activity charts' />,
                                                            <CustomTab label='Activity logs' />,
                                                        ]}
                                                </Tabs>

                                                <Button
                                                    variant='outlined'
                                                    sx={{ height: '2.35rem' }}
                                                    onClick={() => onExportClick()}
                                                >
                                                    Export
                                                </Button>
                                            </Box>
                                        </Row>
                                    </Container>

                                    {tabIndex === 0 && (
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <div
                                                            className='ag-theme-balham-dark'
                                                            style={{
                                                                width: '-webkit-fill-available',
                                                                height: 'calc(100vh - 460px)',
                                                                colorScheme: 'dark',
                                                            }}
                                                        >
                                                            <AgGridReact
                                                                defaultColDef={defaultColDef}
                                                                columnDefs={warViewListColumnDefs}
                                                                rowData={viewListData}
                                                                animateRows={true}
                                                                rowSelection={'single'}
                                                                onRowSelected={rowSelectHandler}
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}

                                    {tabIndex === 1 && (
                                        <>
                                            <Container fluid>
                                                <Row xs='2'>
                                                    <Col>
                                                        <Box
                                                            display='flex'
                                                            justifyContent='space-between'
                                                            padding='1.25em'
                                                        >
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={previousGame}
                                                                disabled={selectedGameIndex === deviceGames.length - 1}
                                                                disableRipple
                                                            >
                                                                Previous Game
                                                            </Button>
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={nextGame}
                                                                disabled={selectedGameIndex === 0}
                                                                disableRipple
                                                            >
                                                                Next Game
                                                            </Button>
                                                            <Button
                                                                sx={styles.button}
                                                                onClick={lastGame}
                                                                disabled={selectedGameIndex === 0}
                                                                disableRipple
                                                            >
                                                                Last Game
                                                            </Button>
                                                        </Box>
                                                    </Col>

                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game Date</span>
                                                                <span style={styles.cardBodyTextStringDate}>
                                                                    {DateTimeDisplay(
                                                                        deviceGames[selectedGameIndex].ended
                                                                    )}
                                                                </span>
                                                            </CardHeader>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>

                                            <Container fluid>
                                                <Row xs='5'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game ID</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={{
                                                                        ...styles.cardBodyTextString,
                                                                        fontSize: '1em',
                                                                    }}
                                                                >
                                                                    {deviceGames[selectedGameIndex].id}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Game Number</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextString}>
                                                                    {deviceGames.length - selectedGameIndex} /{' '}
                                                                    {deviceGames.length}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Dealer</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextString}>
                                                                    {deviceGames[selectedGameIndex].dealer
                                                                        ? deviceGames[selectedGameIndex].dealer
                                                                        : 'Unknown'}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={
                                                                        settings?.data.xtable.gameOnlyMode
                                                                            ? { ...styles.cardBodyText, color: 'white' }
                                                                            : { ...styles.cardBodyText }
                                                                    }
                                                                >
                                                                    {settings?.data.xtable.gameOnlyMode
                                                                        ? 'N/A'
                                                                        : formatCashString(
                                                                            deviceGames[selectedGameIndex].bets
                                                                        )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>House Win/Loss</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span
                                                                    style={{
                                                                        ...styles.cardBodyText,
                                                                        ...styles.cashValue(
                                                                            deviceGames[selectedGameIndex].profit
                                                                        ),
                                                                    }}
                                                                >
                                                                    {settings?.data.xtable.gameOnlyMode
                                                                        ? 'N/A'
                                                                        : formatCashString(
                                                                            deviceGames[selectedGameIndex].profit
                                                                        )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row xs='3'>
                                                    <Col sm='7'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Cards (Ante)</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <Cards
                                                                    gameType='war'
                                                                    cards={deviceGames[selectedGameIndex].anteCards}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col sm='2'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Player Result (Ante)
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                {Array(7)
                                                                    .fill(0)
                                                                    .map((v, i) => {
                                                                        const dealerKey = 'dealer' + (i + 1)
                                                                        const playerKey = 'player' + (i + 1)
                                                                        const tieKey = 'tie' + (i + 1)

                                                                        // console.log('test', playerKey)

                                                                        const result = deviceGames[selectedGameIndex]
                                                                            .result[dealerKey]
                                                                            ? 'Lose'
                                                                            : deviceGames[selectedGameIndex].result[playerKey]
                                                                                ? 'Win'
                                                                                : deviceGames[selectedGameIndex].result[tieKey]
                                                                                    ? 'Tie'
                                                                                    : 'Unknown' + i

                                                                        return (
                                                                            <span
                                                                                style={{
                                                                                    padding: '0px 0.9em 0.9em',
                                                                                    justifyContent: 'center',
                                                                                    flex: '1 1',
                                                                                    display: 'flex',
                                                                                    overflowWrap: 'anywhere',
                                                                                }}
                                                                            >
                                                                                Hand {i + 1}: {result}
                                                                            </span>
                                                                        )
                                                                    })}
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col sm='3'>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Cards (War)</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <Cards
                                                                    gameType='war'
                                                                    cards={deviceGames[selectedGameIndex].warCards}
                                                                    warCards
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Result (War)</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <span style={styles.cardBodyTextStringResult('NA')}>
                                                                    {getWarResultTranslation(
                                                                        deviceGames[selectedGameIndex].result.warResult
                                                                    )}
                                                                </span>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </>
                                    )}
                                </div>
                            )}

                        {tabIndex === 2 && (
                            <>
                                {numberOfDays === 0 && !tablesCashReport && (
                                    <Container style={{ marginTop: 64 }} fluid={width <= 1680}>
                                        <Row xs='1'>
                                            <Col>
                                                <h3>Fetching tray report...</h3>
                                                <Progress value={fetchTablesCashReportProgress} />
                                            </Col>
                                        </Row>
                                    </Container>
                                )}

                                {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly cash drop</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                redraw: true,
                                                                updateMode: 'resize',
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursCashDropData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly chip fill</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                redraw: true,
                                                                updateMode: 'resize',
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursChipFillData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly chip credit</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                redraw: true,
                                                                updateMode: 'resize',
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursChipCreditData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays > 0 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Cash Drop History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) => setBuiltChartProxy('cashdrop', value)}
                                                        builtChart={builtCharts['cashdrop']}
                                                        findInLabelMap={() => 'cash drop'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={queryCashdropHistory}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays > 0 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Chip Fill History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) => setBuiltChartProxy('chipfill', value)}
                                                        builtChart={builtCharts['chipfill']}
                                                        findInLabelMap={() => 'chip fill'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={queryChipfillHistory}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays > 0 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Chip Credit History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('chipcredit', value)
                                                        }
                                                        builtChart={builtCharts['chipcredit']}
                                                        findInLabelMap={() => 'chip credit'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={queryChipcreditHistory}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly wager</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                redraw: true,
                                                                updateMode: 'resize',
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursWagerData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly house win/loss</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                backgroundColor: chartBackgroundColors[1],
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                            }}
                                                            data={getHoursWinLossData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly # of games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Line
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursGamesData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}

                        {tabIndex === 3 && (
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <FormControl sx={{ m: 1, minWidth: 140 }} size='small'>
                                                <Select value={actLogsType} onChange={handleActLogsTypeChange}>
                                                    <MenuItem value='cashDrop'>Cash Drop</MenuItem>
                                                    <MenuItem value='chipFillCredit'>Chip Fill & Credit</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {actLogsType === 'cashDrop' && (
                                                <div
                                                    className='ag-theme-balham-dark'
                                                    style={{
                                                        width: '-webkit-fill-available',
                                                        height: 'calc(100vh - 460px)',
                                                        colorScheme: 'dark',
                                                    }}
                                                >
                                                    <AgGridReact
                                                        defaultColDef={defaultColDef}
                                                        columnDefs={cashdropColumnDefs}
                                                        rowData={cashdropData}
                                                        animateRows={true}
                                                        rowSelection={'single'}
                                                    />
                                                </div>
                                            )}
                                            {actLogsType === 'chipFillCredit' && (
                                                <div
                                                    className='ag-theme-balham-dark'
                                                    style={{
                                                        width: '-webkit-fill-available',
                                                        height: 'calc(100vh - 460px)',
                                                        colorScheme: 'dark',
                                                    }}
                                                >
                                                    <AgGridReact
                                                        defaultColDef={defaultColDef}
                                                        columnDefs={chipfillcreditColumnDefs}
                                                        rowData={fillCreditData}
                                                        animateRows={true}
                                                        rowSelection={'single'}
                                                    />
                                                </div>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}

                        {!xstadium &&
                            !isTableLevel &&
                            allTables.length > 0 &&
                            allTables.map((table) => (
                                <Row key={table.index}>
                                    <Col>
                                        <Button
                                            variant='contained'
                                            onClick={() => {
                                                const descendantDevices = findGameDeviceInTable(
                                                    devices,
                                                    table.index
                                                ).map((dv) => dv.index)
                                                setLocation({
                                                    branchColor: '#FF9F43',
                                                    branchIndex: table.index,
                                                    branchName: table.data.name,
                                                    branchType: 'Table',
                                                    gameType: getGameType(
                                                        tables,
                                                        devices,
                                                        table.index,
                                                        table.data.containerType
                                                    ),
                                                    gameVariant: getGameVariant(
                                                        tables,
                                                        devices,
                                                        table.index,
                                                        table.data.containerType
                                                    ),
                                                    descendantDevices,
                                                })
                                                console.log(table, location)
                                            }}
                                            color='primary'
                                            style={styles.buttonTable}
                                            key={table.index}
                                        >
                                            {table.data.name}
                                        </Button>
                                    </Col>
                                </Row>
                            ))}

                        {allTables.length === 0 && <div>No valid table found at the selected location</div>}
                    </Container>
                )}
            </Grid>
        </Grid>
    )
}

export default Games
