import React, { useState } from 'react'
import { get, isNil } from 'lodash'
import { Link, Navigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import LinearProgress from '@mui/material/LinearProgress'
import SnackbarContent from '@mui/material/SnackbarContent'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { authApi as api, checkAuthorize } from '../api'

const CardApp = ({ status, authorize, dispatch }) => {
	const roles = window.localStorage.getItem('roles')
	const role = window.localStorage.getItem('role')
	const token = window.localStorage.getItem('token')

	const [rootAvailable, setRootAvailable] = useState(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [failType, setFailType] = useState('')

	const checkRoot = async () => {
		if (rootAvailable === null) {
			try {
				await api.get('available?account=root')
				setRootAvailable(true)
			} catch (e) {
				setRootAvailable(false)
			}
		}
	}

	checkRoot()

	const login = async (card) => {
		if (card.slice(-1) === '?' && card.length > 30) {
			setLoading(true)
			setError(false)
			setFailType('')

			let filteredCard = card.slice(card.indexOf(';') + 1, card.indexOf('?'))

			try {
				const res = await api
					.post('authorize/card', {
						json: {
							card: filteredCard,
						},
					})
					.json()
				window.localStorage.setItem('token', res.token)
				window.localStorage.setItem('account', res.account)
				const isRoot = res.level === 'root'
				if (isRoot) {
					window.localStorage.setItem('role', 'root')
				}
				if (res.roles.length === 1) {
					window.localStorage.setItem('role', res.roles[0])
				}
				if (res.roles.length > 1) {
					window.localStorage.setItem('roles', res.roles)
				}
				window.localStorage.setItem('products', res.products)
				await authorize()
			} catch (e) {
				const statusCode = await e.response.status
				switch (statusCode) {
					case 400:
						setFailType('userNotFound')
						break
					case 409:
						setFailType('sessionInUse')
						break
					case 423:
						setFailType('lockedAcc')
						break
					default:
						setFailType('')
				}
				console.warn('nope', e)
				setLoading(false)
				setError(true)
			}
		} else {
			setError(true)
			setFailType('invalidCard')
		}
	}

	const getHelperText = (failType) => {
		let error
		switch (failType) {
			case 'invalidCard':
				error = 'Invalid card ID.'
				break
			case 'userNotFound':
				error = 'User does not exist.'
				break
			case 'sessionInUse':
				error = 'Please logout first from your other active session.'
				break
			case 'lockedAcc':
				error = 'Your account is locked. Please contact support to unlock.'
				break
			default:
				error = ''
		}
		return error
	}

	if (status === 'unauthorized' && !isNil(token) && !isNil(roles)) {
		return <Navigate to='/selectrole' />
	}

	if (status === 'authorized') {
		checkAuthorize({
			success: () => {
				console.log('auth success')
			},
			failed: () => {
				console.log('auth failed')
				dispatch({ type: 'status', data: 'unauthorized' })
			},
		})

		if (role !== 'dealer' && role !== 'pitboss') {
			return <Navigate to='/dashboard' />
		}

		if (role === 'dealer' || role === 'pitboss') {
			return <Navigate to='/posui/dashboard' />
		}
	}

	if (rootAvailable === true) {
		return <Navigate to='/setup' />
	}

	if (rootAvailable === null) {
		return <LinearProgress />
	}

	return (
		<Grid
			sx={{
				flex: 1,
				width: 'inherit',
				margin: 0,
				padding: 0,
				background: (theme) => theme.palette.card.background,
			}}
			container
			spacing={4}
			onClick={() => document.getElementById('cardRef').focus()}
		>
			<Hidden xsDown>
				<Grid item sm={6}></Grid>
			</Hidden>
			<Grid item xs={12} sm={6}>
				<Card
					sx={{
						position: 'fixed',
						maxWidth: '400px',
						margin: '84px auto',
						left: (window.innerWidth - 400) / 2,
					}}
				>
					<AppBar position='static' color='default' elevation={2}>
						<Toolbar variant='dense'>
							<Typography component='h4'>Log in</Typography>
						</Toolbar>
					</AppBar>
					<CardContent
						sx={{
							background: (theme) => theme.palette.card.content,
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem',
						}}
					>
						{error && !loading && (
							<SnackbarContent
								sx={{
									backgroundColor: '#f1932c',
									maxWidth: 'unset',
								}}
								message={
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											gap: '0.25rem',
										}}
									>
										<Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
											<WarningIcon
												sx={{
													verticalAlign: 'bottom',
													color: '#f0cf81',
												}}
											/>
											<Typography
												component='p'
												sx={{
													fontSize: '0.96em',
													fontWeight: 100,
													color: 'white',
												}}
											>
												Login failed
											</Typography>
										</Box>

										{(failType === 'sessionInUse' || failType === 'lockedAcc') && (
											<Typography
												component='p'
												sx={{
													fontSize: '0.96em',
													fontWeight: 100,
													color: 'white',
												}}
											>
												{getHelperText(failType)}
											</Typography>
										)}
									</Box>
								}
							/>
						)}

						<Typography
							sx={{
								fontSize: '1.2em',
							}}
							component='p'
						>
							Select the textbox below and swipe your card.
							<br />
							Please ensure the textbox is selected before swiping.
						</Typography>

						<form
							onSubmit={(e) => {
								e.preventDefault()
								e.stopPropagation()
								setError('')
								login(get(e.currentTarget['cardInput'], 'value', null))
								e.currentTarget['cardInput'].value = ''
							}}
							noValidate
							autoComplete='off'
						>
							<TextField
								id='cardRef'
								name='cardInput'
								required
								InputLabelProps={{ shrink: true }}
								size='small'
								label='Card'
								type='password'
								fullWidth
								variant='outlined'
								disabled={loading}
								autoFocus={true}
								autoComplete='off'
								error={error && (failType === 'invalidCard' || failType === 'userNotFound')}
								helperText={
									error &&
									(failType === 'invalidCard' || failType === 'userNotFound') &&
									getHelperText(failType)
								}
							/>
						</form>

						<Button
							component={Link}
							variant='contained'
							color='secondary'
							fullWidth
							disabled={loading}
							{...{ to: '/login' }}
						>
							Login with password
						</Button>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

export default CardApp
