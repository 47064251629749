import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import CircleIcon from '@mui/icons-material/Circle'
import SquareIcon from '@mui/icons-material/Square'
import WarningIcon from '@mui/icons-material/Warning'
import { makeStyles } from '@mui/styles'

import { formatCashString } from './../../xtable/Formats'
import { PitOverviewContext } from './Manager'

const rootStyles = makeStyles((theme) => ({
    noScrollBar: {
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '1px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
        },
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        position: 'relative',
        backgroundColor: '#1B1B1F',
        color: '#fff',
        textAlign: 'center',
        borderRadius: 25,
        padding: 50,
        outline: 0,
    },
    modalHeader: {
        backgroundColor: '#111314',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        borderRadius: '25px 25px 0 0',
    },
    modalPrompt: {
        marginTop: '3em',
        paddingTop: '1em',
        fontSize: 13,
    },
    modalButtonRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '5%',
        '& button': {
            height: '4.2rem',
            boxShadow: '0px 1px 1px #161D251A',
            border: '1px solid #5F606A',
            fontSize: '19px',
        },
    },
    modalInput: {
        width: '90%',
        height: '4.2rem',
        backgroundColor: '#303238',
        boxShadow: '0px 1px 2px #00000014',
        border: '1px solid #5F606A',
        borderRadius: 4,
        fontSize: '2rem',
        '& .MuiOutlinedInput-root': { height: '4.2rem' },
    },
    tablelink: {
        margin: '1vh',
        textDecoration: 'none',
        width: '35vh',
        display: 'inline-flex',
        backgroundColor: '#1F1B1B',
        padding: '2.5vh',
        textTransform: 'none',
        alignItems: 'baseline',
        minHeight: 300,
        minWidth: 300,
        maxHeight: '100%',
    },
    table: {
        width: '100%',
        height: '100%',
        color: '#ffffff',
        fontWeight: '100',
        textAlign: 'left',
    },
    title: {
        color: '#ccc',
        fontSize: '2vh',
        marginRight: '5%',
    },
    switch: {
        '& .MuiSwitch-root': {
            transform: 'scale(1.5)',
        },
        '& .MuiFormControlLabel-label': {
            fontSize: 20,
        },
    },
    tableDetailsForm: {
        backgroundColor: '',
        fontSize: 20,
        '& label': {
            color: '#777',
            marginLeft: 3,
        },
        '& input': {
            border: '1px solid #ccc',
            backgroundColor: '#333',
            fontSize: 20,
            color: '#ccc',
            padding: 5,
            marginTop: 5,
            borderRadius: 5,
            width: '90%',
        },
        '& .disabled': {
            opacity: 0.5,
        },
    },
    onoff: {
        color: '#fff',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    deviceInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    deviceName: {
        maxWidth: '17.5rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#fff',
        fontSize: '1.5rem',
        fontWeight: '600',
    },
    gameType: {
        color: '#fff',
        fontSize: '1.3rem',
        fontWeight: '600',
        textTransform: 'uppercase',
    },
    gameVariant: {
        color: '#fff',
        fontSize: '1.3rem',
        textTransform: 'uppercase',
    },
    online: {
        color: '#00ff00',
        fontSize: '1.3rem',
        fontWeight: '400',
    },
    offline: {
        color: '#ff1111',
        fontSize: '1.3rem',
        fontWeight: '400',
    },
    betWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    bet: {
        color: '#fff',
        fontSize: '1.5rem',
        fontWeight: '600',
        textTransform: 'uppercase',
    },
    labelRow: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
    },
    label: {
        color: '#fff',
        fontSize: '1.3rem',
        fontWeight: '300',
        textTransform: 'uppercase',
    },
    indicator: {
        position: 'absolute',
        top: '15%',
        right: '5%',
        transform: 'translateY(-50%)',
    },
}))

const PitOverview = () => {
    const { checkForStatusValue, devicesInPit, devicesStatus, setSelectedDevice, selectedDevice, setShowMenu } =
        useContext(PitOverviewContext)

    const s = rootStyles()

    const { t } = useTranslation('common')

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                boxSizing: 'content-box',
                backgroundColor: '#333',
                overflow: 'auto',
            }}
        >
            <PerfectScrollbar
                style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', justifyContent: 'center' }}
            >
                {devicesInPit
                    .filter((v) => v.data.type === 'game')
                    .map((device, index) => (
                        <div
                            key={index}
                            className={s.tablelink}
                            onClick={() => {
                                setSelectedDevice(device)
                                setShowMenu(true)
                            }}
                            style={{
                                position: 'relative',
                                borderTop: '4vh solid ' + device?.data?.minBetColor,
                                ...(selectedDevice?.index === device.index && {
                                    background: '#444',
                                    outline: '1vh solid #ffc526',
                                }),
                            }}
                        >
                            <div
                                className={s.table}
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '2rem',
                                    height: '100%',
                                    fontSize: '3vh',
                                }}
                            >
                                <div className={s.deviceInfoWrapper}>
                                    <div className={s.deviceName}>{device.data.name}</div>

                                    <div className={s.labelRow}>
                                        <span className={s.label}>{t('manager.game')}:&nbsp;</span>
                                        <span className={s.gameType}>{device.data.gameType}</span>
                                    </div>

                                    <div className={s.labelRow}>
                                        <span className={s.label}>Variant:&nbsp;</span>
                                        <span className={s.gameVariant}>{device.data.gameVariant}</span>
                                    </div>

                                    <div className={s.labelRow}>
                                        <span className={s.label}>{t('manager.tableIs')}&nbsp;</span>
                                        {device.data.open ? (
                                            <span className={s.online}>{t('manager.open')}</span>
                                        ) : (
                                            <span className={s.offline}>{t('manager.close')}</span>
                                        )}
                                    </div>

                                    <div className={s.labelRow}>
                                        <span className={s.label}>{t('manager.deviceStatus')}:&nbsp;</span>
                                        {checkForStatusValue(device.index, 'data.game') &&
                                        checkForStatusValue(device.index, 'data.trendboard') ? (
                                            <span className={s.online}>{t('manager.online')}</span>
                                        ) : (
                                            <span className={s.offline}>{t('manager.offline')}</span>
                                        )}
                                    </div>
                                </div>

                                <div className={s.betWrapper}>
                                    <div className={s.labelRow}>
                                        <span className={s.bet}>{t('manager.minBet')}:&nbsp;</span>
                                        <span className={s.bet}>
                                            {formatCashString(
                                                device.data.bets
                                                    ? device.data.bets[device.data.gameType][device.data.gameVariant]
                                                          .minBet
                                                    : device.data.minbet
                                            )}
                                        </span>
                                    </div>

                                    <div className={s.labelRow}>
                                        <span className={s.bet}>{t('manager.maxBet')}:&nbsp;</span>
                                        <span className={s.bet}>
                                            {formatCashString(
                                                device.data.bets
                                                    ? device.data.bets[device.data.gameType][device.data.gameVariant]
                                                          .maxBet
                                                    : device.data.maxbet
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={s.indicator}>
                                {device.data.open &&
                                    checkForStatusValue(device.index, 'data.game') &&
                                    checkForStatusValue(device.index, 'data.trendboard') && (
                                        <CircleIcon style={{ fontSize: '4rem', color: 'lime' }} />
                                    )}
                                {!device.data.open &&
                                    checkForStatusValue(device.index, 'data.game') &&
                                    checkForStatusValue(device.index, 'data.trendboard') && (
                                        <SquareIcon sx={{ fontSize: '4rem', color: '#FF8A00' }} />
                                    )}
                                {(device.data.open || !device.data.open) &&
                                    (!checkForStatusValue(device.index, 'data.game') ||
                                        !checkForStatusValue(device.index, 'data.trendboard')) && (
                                        <WarningIcon style={{ fontSize: '4rem', color: 'red' }} />
                                    )}
                            </div>
                        </div>
                    ))}

                {devicesStatus
                    .filter((v) => v.data.type === 'pos')
                    .map((device, index) => (
                        <div
                            key={index}
                            className={s.tablelink}
                            onClick={() => {
                                setSelectedDevice(device)
                                setShowMenu(true)
                            }}
                            style={{
                                position: 'relative',
                                borderTop: '4vh solid #1F1B1B',
                                ...(selectedDevice?.index === device.index && {
                                    background: '#444',
                                    borderTop: '4vh solid #444',
                                    outline: '1vh solid #ffc526',
                                }),
                            }}
                        >
                            <div
                                className={s.table}
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '2rem',
                                    height: '100%',
                                    fontSize: '3vh',
                                }}
                            >
                                <div className={s.deviceInfoWrapper}>
                                    <div className={s.deviceName}>{device.data.name}</div>

                                    <div className={s.labelRow}>
                                        <span className={s.label}>Device type:&nbsp;</span>
                                        <span className={s.gameType}>{device.data.type}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={s.indicator}>
                                {device.data.on && <CircleIcon style={{ fontSize: '4rem', color: 'lime' }} />}
                                {!device.data.on && <WarningIcon sx={{ fontSize: '4rem', color: 'red' }} />}
                            </div>
                        </div>
                    ))}

                {devicesStatus
                    .filter((v) => !['game', 'pos'].includes(v.data.type))
                    .map((device, index) => (
                        <div
                            key={index}
                            className={s.tablelink}
                            onClick={() => {
                                setSelectedDevice(device)
                                setShowMenu(true)
                            }}
                            style={{
                                position: 'relative',
                                borderTop: '4vh solid #1F1B1B',
                                ...(selectedDevice?.index === device.index && {
                                    background: '#444',
                                    borderTop: '4vh solid #444',
                                    outline: '1vh solid #ffc526',
                                }),
                            }}
                        >
                            <div
                                className={s.table}
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '2rem',
                                    height: '100%',
                                    fontSize: '3vh',
                                }}
                            >
                                <div className={s.deviceInfoWrapper}>
                                    <div className={s.deviceName}>{device.data.name}</div>

                                    <div className={s.labelRow}>
                                        <span className={s.label}>Device type:&nbsp;</span>
                                        <span className={s.gameType}>{device.data.type}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={s.indicator}>
                                {device.data.on && <CircleIcon style={{ fontSize: '4rem', color: 'lime' }} />}
                                {!device.data.on && <WarningIcon sx={{ fontSize: '4rem', color: 'red' }} />}
                            </div>
                        </div>
                    ))}
            </PerfectScrollbar>
        </div>
    )
}

export default PitOverview
