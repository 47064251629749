import { useState, useMemo, useCallback } from 'react'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'

import { useSubscribe, unpublish } from '../../api'
import AlertDialog, { handleClose } from '../../xtrend/components/AlertDialog'
import Playlist from './Playlist'

const Row = (props) => {
    const { row, authorize, edit, alertDialogState, setAlertDialogState } = props

    const dateDisplay = (time) => moment.unix(time / 1000000000).format('YYYY-MM-DD')

    const handleRemove = async (index) => {
        setAlertDialogState((prev) => ({
            ...prev,
            loading: true,
        }))
        try {
            await unpublish('playlists/' + index, authorize)
            handleClose(alertDialogState, setAlertDialogState)
        } catch (e) {
            console.warn('failed to delete playlist', e)
            setAlertDialogState({
                show: true,
                type: 'error',
                message: 'Unable to remove playlist. Please try again.',
                loading: false,
                error: true,
                confirm: () => {},
            })
        }
    }

    return (
        <TableRow
            onDoubleClick={() => {
                edit(row.playlist)
            }}
        >
            <TableCell>{row.playlistName}</TableCell>
            <TableCell>{dateDisplay(row.updatedOn)}</TableCell>
            <TableCell>{row.updatedBy}</TableCell>
            <TableCell>
                <IconButton size='small' onClick={() => edit(row.playlist)}>
                    <EditIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                <IconButton
                    size='small'
                    onClick={() => {
                        setAlertDialogState({
                            show: true,
                            type: 'confirmation',
                            message: 'Are you sure you want to delete this playlist?',
                            loading: false,
                            error: false,
                            confirm: () => {
                                handleRemove(row.id)
                            },
                        })
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

const Playlists = ({ authorize }) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [videos, videosSocket] = useSubscribe('content/files/videos/*', authorize)
    const [playlists, playlistsSocket] = useSubscribe('playlists/*', authorize)

    const active =
        videosSocket &&
        videosSocket.readyState === WebSocket.OPEN &&
        playlistsSocket &&
        playlistsSocket.readyState === WebSocket.OPEN

    const [open, setOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const [selectedPlaylist, setSelectedPlaylist] = useState(null)
    const [selectedVideo, setSelectedVideo] = useState([])

    const [order, setOrder] = useState('')
    const [orderBy, setOrderBy] = useState('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        loading: false,
        error: false,
        confirm: () => {},
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }

    const stableSort = useCallback((array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) {
                return order
            }
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }, [])

    const matchedPlaylists = useMemo(
        () =>
            playlists
                ? playlists
                      .filter((playlist) => JSON.stringify(playlist).indexOf(searchTerm) >= 0)
                      .map((playlist) => {
                          return {
                              id: playlist.index ? playlist.index : null,
                              playlistName: playlist.data.name ? playlist.data.name : '-',
                              updatedOn: playlist.updated ? playlist.updated : playlist.created,
                              updatedBy: playlist.data.updatedBy ? playlist.data.updatedBy : '-',
                              playlist: playlist,
                          }
                      })
                : [],
        [playlists, searchTerm]
    )

    const filteredPlaylists = useMemo(() => {
        function getComparator(order, orderBy) {
            return order === 'desc'
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy)
        }

        return stableSort(matchedPlaylists, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )
    }, [order, orderBy, page, rowsPerPage, stableSort, matchedPlaylists])

    if (!videos || !playlists || !active) {
        return <LinearProgress />
    }

    const handleClickOpen = () => {
        setOpen(true)
        setSelectedPlaylist(null)
    }

    const handleSelect = (playlist) => {
        setOpen(true)
        setSelectedPlaylist(playlist)
        const selectedVideosIDs = playlist.data.videos.map((v) => v.id)
        const selectedVideoObjs = selectedVideosIDs.map((v) => videos.filter((video) => video.data.id === v)).flat(1)
        setSelectedVideo(selectedVideoObjs)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const columns = [
        { id: 'playlistName', label: 'Playlist Name', width: 250 },
        { id: 'updatedOn', label: 'Updated On', width: 25 },
        { id: 'updatedBy', label: 'Updated By', width: 25 },
        { id: 'edit', label: '', width: '2.5%' },
        { id: 'remove', label: '', width: '2.5%' },
    ]

    const isTableEmpty = playlists.length === 0 || (playlists.length > 0 && filteredPlaylists.length === 0)

    return (
        <>
            <Box padding='2rem' display='flex' flexDirection='column' gap='1rem'>
                <Box
                    display='flex'
                    flexDirection={mobile ? 'column' : 'row'}
                    alignItems='center'
                    justifyContent='space-between'
                    gap='1rem'
                >
                    <Tabs
                        sx={{ width: mobile ? '100%' : 'auto' }}
                        value={1}
                        indicatorColor='primary'
                        textColor='primary'
                        variant={mobile ? 'fullWidth' : 'standard'}
                    >
                        <Tab disableTouchRipple label='Videos' component={Link} to='/xsignage/videos' />
                        <Tab disableTouchRipple label='Playlists' component={Link} to='/xsignage/playlists' />
                    </Tabs>

                    <Box
                        width={mobile ? '100%' : 'auto'}
                        display='flex'
                        flexDirection={mobile ? 'column' : 'row'}
                        alignItems='center'
                        gap='1rem'
                    >
                        <Box width={mobile ? '100%' : 'auto'}>
                            <Button
                                sx={{ width: mobile ? '100%' : 'auto' }}
                                variant='contained'
                                component='label'
                                onClick={handleClickOpen}
                            >
                                <AddIcon />
                                &nbsp; Create Playlist
                            </Button>
                        </Box>

                        <TextField
                            sx={{ width: mobile ? '100%' : 'auto' }}
                            variant='outlined'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>

                <Paper>
                    <TableContainer sx={{ height: '72.5vh', overflow: 'auto' }}>
                        <PerfectScrollbar>
                            <Table stickyHeader sx={{ height: isTableEmpty ? '100%' : 'auto' }}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => {
                                            return (
                                                <TableCell key={column.id} width={column.width}>
                                                    {column.label ? (
                                                        <TableSortLabel
                                                            active={orderBy === column.id}
                                                            direction={orderBy === column.id ? order : 'asc'}
                                                            onClick={createSortHandler(column.id)}
                                                        >
                                                            {column.label}
                                                        </TableSortLabel>
                                                    ) : (
                                                        <>{column.label}</>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {isTableEmpty && (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Typography>
                                                        {playlists.length === 0 && 'No playlist(s) found.'}
                                                        {playlists.length > 0 &&
                                                            filteredPlaylists.length === 0 &&
                                                            'No match playlist(s) found.'}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {filteredPlaylists.map((row) => (
                                        <Row
                                            key={row.id}
                                            row={row}
                                            authorize={authorize}
                                            edit={handleSelect}
                                            alertDialogState={alertDialogState}
                                            setAlertDialogState={setAlertDialogState}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </PerfectScrollbar>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={matchedPlaylists.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>

            <Playlist
                open={open}
                handleClose={handleClose}
                videos={videos}
                editing={selectedPlaylist}
                selectedVideo={selectedVideo}
                setSelectedVideo={setSelectedVideo}
            />

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default Playlists
