import React, { useState, useReducer, useEffect } from 'react'
import { cloneDeep, isEqual } from 'lodash'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import SnackbarContent from '@mui/material/SnackbarContent'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles, withStyles } from '@mui/styles'
import { updateWithTypeAndVariant, validateWithTypeAndVariant } from '../../../forms'
import AlertDialog from '../../components/AlertDialog'
import { defaultValues } from '../defaultValues'

const XSwitch = withStyles((theme) => ({
    root: {
        width: 48,
        height: 26,
        padding: 5,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        paddingLeft: 4,
    },
    thumb: {
        width: 24,
        height: 24,
    },
}))(Switch)

const rootStyles = makeStyles((theme) => ({
    form: {
        width: ({ template }) => template ? 'inherit' : 900,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
    stickyTableHeaderCells: {
        position: 'sticky',
        top: 45,
        backgroundColor: '#333',
        zIndex: 1,
    },
    spinArrows: {
        opacity: '0.2 !important',
        '&:hover': {
            opacity: '1 !important',
        },
    },
    highlight: {
        color: 'lime',
        fontSize: '1.2em',
    },
    tableHeaderWrapper: {
        padding: '1.5rem 1rem 0.75rem 1rem',
    },
    tableHeader: {
        fontSize: 17,
        fontWeight: 'bold',
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.2em',
    },
    switchCols: {
        width: '7.5rem',
    },
    fieldRowHeader: {
        fontWeight: 'normal',
    },
    contentWrapper: {
        padding: '1rem 1.5rem 2rem 1.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
    },
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
}))

function ChaseTheTrend({ publish, tables, table, settings, xstadium, template, changeCallback }) {
    const styles = rootStyles({ xstadium, template })
    const inputBoxStyle = {
        background: 'transparent',
        width: 50,
        padding: '10.5px 0',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderColor: 'rgba(255, 255, 255, 0.3)',
        borderRadius: '4px',
        color: 'lime',
        fontWeight: 'bold',
    }

    const navigate = useNavigate()
    const [infoStates, setInfoStates] = useReducer(
        (state, obj) => {
            return { ...state, ...obj }
        },
        { main: false, a: false, b: false, c: false, d: false, e: false, f: false, g: false }
    )

    // form changed detect hook
    const [changed, setChanged] = useState(0)

    // field flags
    const [editing, setEditing] = useState([])
    const [submitted, setSubmitted] = useState(false)

    // form flags
    const [updated, setUpdated] = useState(-1)

    const [loadedInitialStates, setLoadedInitialStates] = useReducer(
        (state, obj) => {
            return { ...state, ...obj }
        },
        {
            hotTrends: table.data.hotTrends ? cloneDeep(table.data.hotTrends) : cloneDeep(defaultValues.hotTrends),
            meters: table.data.meters ? cloneDeep(table.data.meters) : cloneDeep(defaultValues.meters),
            methodValue: table.data.accuracyMode ? table.data.accuracyMode : defaultValues.accuracyMode,
            offsetValue: table.data.accuracyAddOnPercentage
                ? table.data.accuracyAddOnPercentage
                : defaultValues.accuracyAddOnPercentage,
        }
    )

    const [hotTrends, setHotTrends] = useState(
        table.data.hotTrends ? cloneDeep(table.data.hotTrends) : cloneDeep(defaultValues.hotTrends)
    )
    const [methodValue, setMethodValue] = useState(
        table.data.accuracyMode ? table.data.accuracyMode : defaultValues.accuracyMode
    )
    const [offsetValue, setOffsetValue] = useReducer(
        (state, obj) => {
            //console.log('setOffsetValue', obj, state)
            return obj < 0 ? 0 : obj > 100 ? 100 : obj
        },
        table.data.accuracyAddOnPercentage ? table.data.accuracyAddOnPercentage : defaultValues.accuracyAddOnPercentage
    )

    const [, rerender] = useReducer((x) => (x ? false : true), false)

    // meters
    const [lucky6MeterMax, setLucky6MeterMax] = useState(16)
    const [mega6MeterMax, setMega6MeterMax] = useState(16)
    const [tigerMeterMax, setTigerMeterMax] = useState(16)
    const [tigerTieMeterMax, setTigerTieMeterMax] = useState(16)
    const [tigerPairMeterMax, setTigerPairMeterMax] = useState(11)
    const [tigerBigMeterMax, setTigerBigMeterMax] = useState(16)
    const [tigerSmallMeterMax, setTigerSmallMeterMax] = useState(16)
    const [tieMeterMax, setTieMeterMax] = useState(10)
    const [bankerPairMeterMax, setBankerPairMeterMax] = useState(11)
    const [playerPairMeterMax, setPlayerPairMeterMax] = useState(11)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
        loading: false,
    })

    const metersFields = {
        baccarat: {
            standard: {
                tieMeterMax: {
                    error: () => Number(tieMeterMax) < 1,
                    value: tieMeterMax,
                    set: setTieMeterMax,
                    message: 'Tie meter max must be at least 1 or higher',
                },
                bankerPairMeterMax: {
                    error: () => Number(bankerPairMeterMax) < 1,
                    value: bankerPairMeterMax,
                    set: setBankerPairMeterMax,
                    message: 'Banker pair meter max must be at least 1 or higher',
                },
                playerPairMeterMax: {
                    error: () => Number(playerPairMeterMax) < 1,
                    value: playerPairMeterMax,
                    set: setPlayerPairMeterMax,
                    message: 'Player pair meter max must be at least 1 or higher',
                },
            },
            lucky6: {
                lucky6MeterMax: {
                    error: () => Number(lucky6MeterMax) < 1,
                    value: lucky6MeterMax,
                    set: setLucky6MeterMax,
                    message: 'Lucky 6 meter max must be at least 1 or higher',
                },
                tieMeterMax: {
                    error: () => Number(tieMeterMax) < 1,
                    value: tieMeterMax,
                    set: setTieMeterMax,
                    message: 'Tie meter max must be at least 1 or higher',
                },
                bankerPairMeterMax: {
                    error: () => Number(bankerPairMeterMax) < 1,
                    value: bankerPairMeterMax,
                    set: setBankerPairMeterMax,
                    message: 'Banker pair meter max must be at least 1 or higher',
                },
                playerPairMeterMax: {
                    error: () => Number(playerPairMeterMax) < 1,
                    value: playerPairMeterMax,
                    set: setPlayerPairMeterMax,
                    message: 'Player pair meter max must be at least 1 or higher',
                },
            },
            lucky6Slot: {
                lucky6MeterMax: {
                    error: () => Number(lucky6MeterMax) < 1,
                    value: lucky6MeterMax,
                    set: setLucky6MeterMax,
                    message: 'Lucky 6 meter max must be at least 1 or higher',
                },
                tieMeterMax: {
                    error: () => Number(tieMeterMax) < 1,
                    value: tieMeterMax,
                    set: setTieMeterMax,
                    message: 'Tie meter max must be at least 1 or higher',
                },
                bankerPairMeterMax: {
                    error: () => Number(bankerPairMeterMax) < 1,
                    value: bankerPairMeterMax,
                    set: setBankerPairMeterMax,
                    message: 'Banker pair meter max must be at least 1 or higher',
                },
                playerPairMeterMax: {
                    error: () => Number(playerPairMeterMax) < 1,
                    value: playerPairMeterMax,
                    set: setPlayerPairMeterMax,
                    message: 'Player pair meter max must be at least 1 or higher',
                },
            },
            mega6: {
                mega6MeterMax: {
                    error: () => Number(mega6MeterMax) < 1,
                    value: mega6MeterMax,
                    set: setMega6MeterMax,
                    message: 'Mega 6 meter max must be at least 1 or higher',
                },
                tieMeterMax: {
                    error: () => Number(tieMeterMax) < 1,
                    value: tieMeterMax,
                    set: setTieMeterMax,
                    message: 'Tie meter max must be at least 1 or higher',
                },
                bankerPairMeterMax: {
                    error: () => Number(bankerPairMeterMax) < 1,
                    value: bankerPairMeterMax,
                    set: setBankerPairMeterMax,
                    message: 'Banker pair meter max must be at least 1 or higher',
                },
                playerPairMeterMax: {
                    error: () => Number(playerPairMeterMax) < 1,
                    value: playerPairMeterMax,
                    set: setPlayerPairMeterMax,
                    message: 'Player pair meter max must be at least 1 or higher',
                },
            },
            tiger: {
                tieMeterMax: {
                    error: () => Number(tieMeterMax) < 1,
                    value: tieMeterMax,
                    set: setTieMeterMax,
                    message: 'Tie meter max must be at least 1 or higher',
                },
                tigerMeterMax: {
                    error: () => Number(tigerMeterMax) < 1,
                    value: tigerMeterMax,
                    set: setTigerMeterMax,
                    message: 'Tiger meter max must be at least 1 or higher',
                },
                tigerTieMeterMax: {
                    error: () => Number(tigerTieMeterMax) < 1,
                    value: tigerTieMeterMax,
                    set: setTigerTieMeterMax,
                    message: 'Tiger tie meter max must be at least 1 or higher',
                },
                tigerPairMeterMax: {
                    error: () => Number(tigerPairMeterMax) < 1,
                    value: tigerPairMeterMax,
                    set: setTigerPairMeterMax,
                    message: 'Tiger pair meter max must be at least 1 or higher',
                },
                tigerBigMeterMax: {
                    error: () => Number(tigerBigMeterMax) < 1,
                    value: tigerBigMeterMax,
                    set: setTigerBigMeterMax,
                    message: 'Tiger big meter max must be at least 1 or higher',
                },
                tigerSmallMeterMax: {
                    error: () => Number(tigerSmallMeterMax) < 1,
                    value: tigerSmallMeterMax,
                    set: setTigerSmallMeterMax,
                    message: 'Tiger small meter max must be at least 1 or higher',
                },
            },
        },
    }

    //----------
    // Render Control
    //-----------

    const checkForChanges = (c, key) => {
        const hasChanges = !isEqual(c, loadedInitialStates[key])
        if (hasChanges) {
            setChanged(changed + 1)
        }
    }

    //----------
    // Live data Control
    //-----------

    const metersValidation = validateWithTypeAndVariant(metersFields, table.data.gameType, table.data.gameVariant)

    if (table.updated !== updated) {
        if (table.data.hotTrends) {
            setLoadedInitialStates({ hotTrends: cloneDeep(table.data.hotTrends) })
            setHotTrends(cloneDeep(table.data.hotTrends))
        }
        if (table.data.accuracyMode) {
            setLoadedInitialStates({ methodValue: cloneDeep(table.data.accuracyMode) })
            setMethodValue(cloneDeep(table.data.accuracyMode))
        }
        if (table.data.accuracyAddOnPercentage) {
            setLoadedInitialStates({ offsetValue: cloneDeep(table.data.accuracyAddOnPercentage) })
            setOffsetValue(cloneDeep(table.data.accuracyAddOnPercentage))
        }
        updateWithTypeAndVariant(metersFields, editing, table, table.data.gameType, table.data.gameVariant, 'meters')

        rerender()
        setUpdated(table.updated)
    }

    const onFocus = (key) => setEditing([...editing, key])

    const onBlur = (e, key) =>
        e.target.value === table.data[key] ? setEditing(editing.filter((field) => field !== key)) : null

    const getNewMetersObj = (gameType, gameVariant) => {
        const metersObj = table.data.meters ? cloneDeep(table).data.meters : defaultValues.meters
        const metersObjByVariant = metersObj[gameType][gameVariant]

        metersObjByVariant.tieMeterMax = Number(tieMeterMax)

        if (gameType === 'baccarat' && gameVariant !== 'tiger') {
            metersObjByVariant.bankerPairMeterMax = Number(bankerPairMeterMax)
            metersObjByVariant.playerPairMeterMax = Number(playerPairMeterMax)
        }

        if (gameType === 'baccarat' && (gameVariant === 'lucky6' || gameVariant === 'lucky6Slot')) {
            metersObjByVariant.lucky6MeterMax = Number(lucky6MeterMax)
        }

        if (gameType === 'baccarat' && gameVariant === 'mega6') {
            metersObjByVariant.mega6MeterMax = Number(mega6MeterMax)
        }

        if (gameType === 'baccarat' && gameVariant === 'tiger') {
            metersObjByVariant.tigerMeterMax = Number(tigerMeterMax)
            metersObjByVariant.tigerTieMeterMax = Number(tigerTieMeterMax)
            metersObjByVariant.tigerPairMeterMax = Number(tigerPairMeterMax)
            metersObjByVariant.tigerBigMeterMax = Number(tigerBigMeterMax)
            metersObjByVariant.tigerSmallMeterMax = Number(tigerSmallMeterMax)
        }

        return metersObj
    }

    //----------
    // UI EVENTS
    //-----------

    const blur = (e, key) => {
        hotTrends[table.data.gameType][table.data.gameVariant][key] =
            String(e.target.value).length > 0 ? parseInt(e.target.value, 10) : 0
        checkForChanges(hotTrends, 'hotTrends')
    }

    const switchEvent = (name, key) => {
        hotTrends[table.data.gameType][table.data.gameVariant].trends[key][name] = hotTrends[table.data.gameType][
            table.data.gameVariant
        ].trends[key][name]
            ? false
            : true
        setChanged(changed + 1)
    }

    const getDefaultHotTrends = (gameType, gameVariant) => {
        const clonedDefaultHotTrends = cloneDeep(defaultValues.hotTrends)
        const clonedHotTrends = cloneDeep(hotTrends)
        Object.keys(clonedDefaultHotTrends[gameType][gameVariant].trends).forEach((key) => {
            clonedDefaultHotTrends[gameType][gameVariant].trends[key].priority =
                clonedHotTrends[gameType][gameVariant].trends[key].priority
        })
        return clonedDefaultHotTrends
    }

    const setMetersToDefaultValues = (fields) => {
        const clonedDefaultMeters = cloneDeep(defaultValues.meters)

        for (let key in fields[table.data.gameType][table.data.gameVariant]) {
            if (key in clonedDefaultMeters[table.data.gameType][table.data.gameVariant]) {
                fields[table.data.gameType][table.data.gameVariant][key].set(
                    clonedDefaultMeters[table.data.gameType][table.data.gameVariant][key]
                )
            }
        }
    }

    const setDefaults = () => {
        setHotTrends(getDefaultHotTrends(table.data.gameType, table.data.gameVariant))
        setMetersToDefaultValues(metersFields)
        setMethodValue(cloneDeep(defaultValues.accuracyMode))
        setOffsetValue(cloneDeep(defaultValues.accuracyAddOnPercentage))
        setChanged(changed + 1)
    }

    //----------
    // API EVENTS
    //-----------

    const validateData = (data, gameType, gameVariant) => {
        const number = (v) => {
            return parseInt(String(v).replace(/\D/g, ''), 10)
        }

        Object.keys(data[gameType][gameVariant]).forEach((key) => {
            if (key !== 'trends') {
                data[gameType][gameVariant][key] = number(data[gameType][gameVariant][key])
            }
        })

        console.log(data)
        return data
    }

    const [postStatus, setPostStatus] = useState(false)

    const getFields = () => ({
        accuracyMode: methodValue,
        accuracyAddOnPercentage: offsetValue,
        hotTrends: validateData(cloneDeep(hotTrends), table.data.gameType, table.data.gameVariant),
        meters: getNewMetersObj(table.data.gameType, table.data.gameVariant),
    })

    const send = async () => {
        setSubmitted(true)
        if (!metersValidation.error) {
            try {
                setPostStatus(true)
                await publish({
                    ...table.data,
                    ...getFields(),
                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
                setChanged(0)
            } catch (e) {
                console.log(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
            } finally {
                setPostStatus(false)
            }
        }
    }

    //console.log('table.data', table.data)
    const sortFunc = (a, b) => {
        if (a.priority < b.priority) {
            return -1
        }
        if (a.priority > b.priority) {
            return 1
        }
        return 0
    }

    const basicTrendsList = [
        'dragonTriple',
        'dragonDouble',
        'dragonBanker',
        'dragonPlayer',
        'pingPongStreak',
        'pingPongDouble',
        'streakBanker',
        'streakPlayer',
        'pingPong',
        'bankerWin',
        'playerWin',
    ]

    const predictionsSortedByPriority = (table.data.trends &&
        table.data.trends[table.data.gameType] &&
        table.data.trends[table.data.gameType][table.data.gameVariant]) ? Object.keys(table.data.trends[table.data.gameType][table.data.gameVariant])
            .filter((item) => basicTrendsList.includes(item))
            .map((v) => ({ key: v, priority: table.data.trends[table.data.gameType][table.data.gameVariant][v].priority }))
            .sort(sortFunc) : Object.keys(defaultValues.trends[table.data.gameType][table.data.gameVariant])
                .filter((item) => basicTrendsList.includes(item))
                .map((v) => ({ key: v, priority: defaultValues.trends[table.data.gameType][table.data.gameVariant][v].priority }))
                .sort(sortFunc)

    const sidebetSortedByPriority = Object.keys(hotTrends[table.data.gameType][table.data.gameVariant].trends)
        .map((v) => ({ key: v, priority: hotTrends[table.data.gameType][table.data.gameVariant].trends[v].priority }))
        .sort(sortFunc)

    const minMaxValues = {
        luckyDealerPercentage: { min: 50, max: 75 },
        hotDealerPercentage: { min: 55, max: 80 },
        geniusDealerPercentage: { min: 60, max: 85 },
        godDealerPercentage: { min: 65, max: 90 },
    }
    const changeHotTrendValue = (e, key) => {
        hotTrends[table.data.gameType][table.data.gameVariant][key] = Number(e.target.value.replace(/\D/g, ''))
        checkForChanges(hotTrends, 'hotTrends')
    }

    const changeHotTrendValue_step = (value, key) => {
        if (value < minMaxValues[key].min || value > minMaxValues[key].max) return

        const forceLessThan5 = (a, b) => {
            hotTrends[table.data.gameType][table.data.gameVariant][b] =
                hotTrends[table.data.gameType][table.data.gameVariant][b] -
                    hotTrends[table.data.gameType][table.data.gameVariant][a] >=
                    5
                    ? hotTrends[table.data.gameType][table.data.gameVariant][b]
                    : hotTrends[table.data.gameType][table.data.gameVariant][a] + 5
        }
        const forceMoreThan5 = (a, b) => {
            hotTrends[table.data.gameType][table.data.gameVariant][b] =
                hotTrends[table.data.gameType][table.data.gameVariant][a] -
                    hotTrends[table.data.gameType][table.data.gameVariant][b] >=
                    5
                    ? hotTrends[table.data.gameType][table.data.gameVariant][b]
                    : hotTrends[table.data.gameType][table.data.gameVariant][a] - 5
        }
        const valueDown = value < hotTrends[table.data.gameType][table.data.gameVariant][key] ? true : false
        hotTrends[table.data.gameType][table.data.gameVariant][key] = value

        if (!valueDown) {
            forceLessThan5('luckyDealerPercentage', 'hotDealerPercentage')
            forceLessThan5('hotDealerPercentage', 'geniusDealerPercentage')
            forceLessThan5('geniusDealerPercentage', 'godDealerPercentage')
        }
        if (valueDown) {
            forceMoreThan5('godDealerPercentage', 'geniusDealerPercentage')
            forceMoreThan5('geniusDealerPercentage', 'hotDealerPercentage')
            forceMoreThan5('hotDealerPercentage', 'luckyDealerPercentage')
        }

        checkForChanges(hotTrends, 'hotTrends')
    }

    useEffect(() => {
        if (changed > 0 && template) {
            changeCallback({
                trends: {
                    ...table.data.trends,
                    ...getFields()
                },
            })
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {submitted && metersValidation.error && postStatus === false && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(metersValidation.errors).map((error, key) =>
                                metersValidation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {metersValidation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    </Box>
                )}

                <Box>
                    {/* More Information area - CHASE THE TREND*/}
                    <Paper
                        sx={{
                            textAlign: 'center',
                            padding: '10px',
                            background: (theme) => theme.palette.card.background,
                        }}
                    >
                        {!infoStates['main'] && (
                            <Button variant='contained' color='info' onClick={() => setInfoStates({ main: true })}>
                                More Information
                            </Button>
                        )}
                        {infoStates['main'] && (
                            <>
                                <Button
                                    variant='contained'
                                    color='error'
                                    onClick={() => setInfoStates({ main: false })}
                                >
                                    Close
                                </Button>
                                <div style={{ textAlign: 'left' }}>
                                    <Paper
                                        style={{
                                            position: 'relative',
                                            backgroundColor: '#333',
                                            margin: '15px',
                                            padding: '5px 0 12px 12px',
                                        }}
                                    >
                                        {infoStates['a'] && (
                                            <Button
                                                endIcon={<KeyboardArrowUpIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ a: false })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                COLLAPSE
                                            </Button>
                                        )}
                                        {!infoStates['a'] && (
                                            <Button
                                                endIcon={<KeyboardArrowDownIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ a: true })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                EXPAND
                                            </Button>
                                        )}
                                        <h3
                                            style={{
                                                marginBottom: 0,
                                                marginTop: 5,
                                                fontWeight: 'bold',
                                                letterSpacing: '1.5px',
                                            }}
                                        >
                                            {' '}
                                            Chase The Trend
                                        </h3>
                                        {infoStates['a'] && (
                                            <>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    There are three display components to Chase The Trend:
                                                </div>
                                                <div style={{ margin: 10, marginLeft: 20 }}>
                                                    <div>1. Basic Trends and Side Bet Trends</div>
                                                    <div>2. Bet Prediction (based on current trends)</div>
                                                    <div>
                                                        3. Dealer Accuracy Ratings (how many predictions were correct)
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Paper>

                                    <Paper
                                        style={{
                                            position: 'relative',
                                            backgroundColor: '#333',
                                            margin: '15px',
                                            padding: '5px 0 12px 12px',
                                        }}
                                    >
                                        {infoStates['b'] && (
                                            <Button
                                                endIcon={<KeyboardArrowUpIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ b: false })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                COLLAPSE
                                            </Button>
                                        )}
                                        {!infoStates['b'] && (
                                            <Button
                                                endIcon={<KeyboardArrowDownIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ b: true })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                EXPAND
                                            </Button>
                                        )}
                                        <h3
                                            style={{
                                                marginBottom: 0,
                                                marginTop: 5,
                                                fontWeight: 'bold',
                                                letterSpacing: '1.5px',
                                            }}
                                        >
                                            'Chase The Trend' Basic Trends
                                        </h3>
                                        {infoStates['b'] && (
                                            <>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Basic trends will display according to the priority order set under
                                                    the "Trend Priority" drag and drop list.
                                                </div>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Super Hot Banker / Super Hot Player:
                                                </div>
                                                <div style={{ margin: 10, marginLeft: 20 }}>
                                                    These trends will be triggered when the banker or player winning
                                                    ratio exceeds{' '}
                                                    <span style={{ color: 'lime' }}>
                                                        {
                                                            hotTrends[table.data.gameType][table.data.gameVariant][
                                                            'superHotPercentage'
                                                            ]
                                                        }
                                                        %
                                                    </span>{' '}
                                                    otherwise normal Hot Banker or Hot Player trends (depending on
                                                    whether there are more Banker wins or Player wins) will be triggered
                                                    instead.
                                                </div>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    There is a special condition regarding Super Hot Banker/Player: If
                                                    the Banker Win or Player Win exceeds the value input for the Super
                                                    Hot Banker or Player Trend respectively, it will supersede all basic
                                                    trend animations.
                                                </div>
                                            </>
                                        )}
                                    </Paper>

                                    <Paper
                                        style={{
                                            position: 'relative',
                                            backgroundColor: '#333',
                                            margin: '15px',
                                            padding: '5px 0 12px 12px',
                                        }}
                                    >
                                        {infoStates['c'] && (
                                            <Button
                                                endIcon={<KeyboardArrowUpIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ c: false })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                COLLAPSE
                                            </Button>
                                        )}
                                        {!infoStates['c'] && (
                                            <Button
                                                endIcon={<KeyboardArrowDownIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ c: true })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                EXPAND
                                            </Button>
                                        )}
                                        <h3
                                            style={{
                                                marginBottom: 0,
                                                marginTop: 5,
                                                fontWeight: 'bold',
                                                letterSpacing: '1.5px',
                                            }}
                                        >
                                            'Chase The Trend' Side Bet Trends
                                        </h3>
                                        {infoStates['c'] && (
                                            <>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    <p>Side Bet Trend will be displayed alongside the Basic Trend.</p>
                                                    <p>
                                                        Side Bet Trend are displayed when any side bet result has not
                                                        occurred after a certain period of time. These trends are driven
                                                        by the Side Bet Meters settings, located under the Trendboard
                                                        tab.{' '}
                                                    </p>
                                                    <p>
                                                        When any of the side bet meters is 75% full, the corresponding
                                                        "Hot" Side Bet Trend will be displayed.{' '}
                                                    </p>
                                                    <p>
                                                        When any of the side bet meters is 100% full, the corresponding
                                                        "Super Hot" Side Bet Trend will be displayed.
                                                    </p>
                                                    <p>
                                                        When multiple Side Bet Trends conditions are met, they are
                                                        displayed according to their priority. This priority can be set
                                                        under the "Trend Priority" settings below.
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </Paper>

                                    <Paper
                                        style={{
                                            position: 'relative',
                                            backgroundColor: '#333',
                                            margin: '15px',
                                            padding: '5px 0 12px 12px',
                                        }}
                                    >
                                        {infoStates['d'] && (
                                            <Button
                                                endIcon={<KeyboardArrowUpIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ d: false })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                COLLAPSE
                                            </Button>
                                        )}
                                        {!infoStates['d'] && (
                                            <Button
                                                endIcon={<KeyboardArrowDownIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ d: true })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                EXPAND
                                            </Button>
                                        )}
                                        <h3
                                            style={{
                                                marginBottom: 0,
                                                marginTop: 5,
                                                fontWeight: 'bold',
                                                letterSpacing: '1.5px',
                                            }}
                                        >
                                            Bet Predictions Based on Current Trend
                                        </h3>
                                        {infoStates['d'] && (
                                            <>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Bet Predictions will show predictions based on the current Basic
                                                    Trends or Side Bet Trends information:
                                                </div>
                                                <div style={{ margin: 10, marginLeft: 20 }}>
                                                    <div style={{}}>Basic Trend Predictions:</div>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <div>Banker Win</div>
                                                        <div>Player Win</div>
                                                        <div>Tie</div>
                                                    </div>
                                                    <div style={{ marginTop: 10 }}>Side Bet Trend Predictions:</div>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <div>All side bet types depending on its variant</div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Paper>

                                    <Paper
                                        style={{
                                            position: 'relative',
                                            backgroundColor: '#333',
                                            margin: '15px',
                                            padding: '5px 0 12px 12px',
                                        }}
                                    >
                                        {infoStates['e'] && (
                                            <Button
                                                endIcon={<KeyboardArrowUpIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ e: false })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                COLLAPSE
                                            </Button>
                                        )}
                                        {!infoStates['e'] && (
                                            <Button
                                                endIcon={<KeyboardArrowDownIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ e: true })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                EXPAND
                                            </Button>
                                        )}
                                        <h3
                                            style={{
                                                marginBottom: 0,
                                                marginTop: 5,
                                                fontWeight: 'bold',
                                                letterSpacing: '1.5px',
                                            }}
                                        >
                                            Dealer Accuracy Ratings
                                        </h3>
                                        {infoStates['e'] && (
                                            <>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Dealer Accuracy Ratings are reset between every deck change, and are
                                                    calculated as so:
                                                </div>
                                                <div style={{ marginLeft: 20 }}>
                                                    Total Number of Games that Match its Prediction / Total Number of
                                                    Predictions Provided = Dealer Accuracy Percentage
                                                </div>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Additional graphics will be displayed when the accuracy is above the
                                                    following:
                                                </div>
                                                <div style={{ margin: 10, marginLeft: 20 }}>
                                                    <div>
                                                        <span style={{ color: 'lime' }}>50% to 75%</span> Lucky Dealer
                                                    </div>
                                                    <div>
                                                        <span style={{ color: 'lime' }}>55% to 80%</span> Hot Dealer
                                                    </div>
                                                    <div>
                                                        <span style={{ color: 'lime' }}>60% to 85%</span> Genius Dealer
                                                    </div>
                                                    <div>
                                                        <span style={{ color: 'lime' }}>65% to 90%</span> God Dealer
                                                    </div>{' '}
                                                    <br />
                                                </div>
                                            </>
                                        )}
                                    </Paper>

                                    <Paper
                                        style={{
                                            position: 'relative',
                                            backgroundColor: '#333',
                                            margin: '15px',
                                            padding: '5px 0 12px 12px',
                                        }}
                                    >
                                        {infoStates['g'] && (
                                            <Button
                                                endIcon={<KeyboardArrowUpIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ g: false })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                COLLAPSE
                                            </Button>
                                        )}
                                        {!infoStates['g'] && (
                                            <Button
                                                endIcon={<KeyboardArrowDownIcon />}
                                                variant='outlined'
                                                onClick={() => {
                                                    setInfoStates({ g: true })
                                                }}
                                                style={{ position: 'absolute', right: '5px' }}
                                            >
                                                EXPAND
                                            </Button>
                                        )}
                                        <h3
                                            style={{
                                                marginBottom: 0,
                                                marginTop: 5,
                                                fontWeight: 'bold',
                                                letterSpacing: '1.5px',
                                            }}
                                        >
                                            {' '}
                                            Dealer Accuracy Offset
                                        </h3>
                                        {infoStates['g'] && (
                                            <>
                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Method 1 – Side Bet Bias (Current Method / Preferred Method):
                                                </div>
                                                {/* <div style={{ margin: 10, marginLeft: 20, textAlign: 'center', backgroundColor: '#444', borderRadius: 10, padding: 10 }}> */}
                                                <Box
                                                    sx={{
                                                        m: '10px',
                                                        ml: '20px',
                                                        textAlign: 'center',
                                                        background: (theme) => theme.palette.card.background,
                                                        borderRadius: '10px',
                                                        p: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        Total number of games dealt by the dealer that matches the
                                                        previous game recommended bet prediction (if both basic and side
                                                        bet are matched, this will be counted as two correct predictions
                                                        [side bet bias]).
                                                    </div>
                                                    <span>&#247;</span>
                                                    <div>
                                                        Total number of Basic Recommended Bet Prediction provided.
                                                        (Ignoring the Side Bet Prediction).
                                                    </div>
                                                </Box>

                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Method 2: True “Basic” Accuracy:
                                                </div>
                                                <Box
                                                    sx={{
                                                        m: '10px',
                                                        ml: '20px',
                                                        textAlign: 'center',
                                                        background: (theme) => theme.palette.card.background,
                                                        borderRadius: '10px',
                                                        p: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        Total number of games dealt by the dealer that matches the
                                                        previous basic game recommended bet prediction (All Side Bet
                                                        predictions and results are ignored).
                                                    </div>
                                                    <span>&#247;</span>
                                                    <div>
                                                        Total number of Basic Recommended Bet Prediction provided.{' '}
                                                    </div>
                                                </Box>

                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Method 3 – True “Basic + Side Bet” Accuracy:
                                                </div>
                                                <Box
                                                    sx={{
                                                        m: '10px',
                                                        ml: '20px',
                                                        textAlign: 'center',
                                                        background: (theme) => theme.palette.card.background,
                                                        borderRadius: '10px',
                                                        p: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        Total number of games dealt by the dealer that matches the
                                                        previous game recommended bet prediction (if both basic and side
                                                        bet are matches, this will be counted as two correct
                                                        predictions).
                                                    </div>
                                                    <span>&#247;</span>
                                                    <div>
                                                        (Total number of Basic Recommended Bet Prediction provided,
                                                        “plus” the number of Side Bet Recommended Bet Prediction
                                                        provided that result in the Correct Prediction).
                                                    </div>
                                                </Box>

                                                <div style={{ margin: 10, marginTop: 20 }}>
                                                    Method 4 – Factored Accuracy (Availability Depend on Rules at
                                                    different Jurisdiction):
                                                </div>
                                                <Box
                                                    sx={{
                                                        m: '10px',
                                                        ml: '20px',
                                                        textAlign: 'center',
                                                        background: (theme) => theme.palette.card.background,
                                                        borderRadius: '10px',
                                                        p: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        Total number of games dealt by the dealer that matches the
                                                        previous basic game recommended bet prediction (All Side Bet
                                                        predictions and results are ignored).
                                                    </div>
                                                    <span>&#247;</span>
                                                    <div>
                                                        Total number of Basic Recommended Bet Prediction provided,
                                                        "plus"{' '}
                                                    </div>
                                                </Box>
                                                <div style={{ textAlign: 'center' }}>
                                                    <span>+</span>
                                                </div>
                                                <Box
                                                    sx={{
                                                        m: '10px',
                                                        ml: '20px',
                                                        textAlign: 'center',
                                                        background: (theme) => theme.palette.card.background,
                                                        borderRadius: '10px',
                                                        p: '10px',
                                                    }}
                                                >
                                                    Percentage offset set below by the operator.
                                                </Box>
                                            </>
                                        )}
                                    </Paper>
                                </div>
                            </>
                        )}
                    </Paper>
                    {/* Rest of the content beneath - START */}
                    {/* <Paper style={{ backgroundColor: '#333', marginTop: 20 }}> */}
                    <Paper sx={{ background: (theme) => theme.palette.card.background, mt: '20px' }}>
                        <Table className='config-table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {' '}
                                        <h3>Conditions</h3>{' '}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right', paddingRight: 20 }}>
                                        {' '}
                                        <h3>Value</h3>{' '}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(hotTrends[table.data.gameType][table.data.gameVariant])
                                    .filter(
                                        (v) =>
                                            ![
                                                'trends',
                                                'luckyDealerPercentage',
                                                'hotDealerPercentage',
                                                'geniusDealerPercentage',
                                                'godDealerPercentage',
                                            ].includes(v)
                                    )
                                    .map((key, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {key === 'hotTrendStart' && (
                                                    <div>
                                                        <span className={styles.highlight}>
                                                            {
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            }
                                                            x
                                                        </span>{' '}
                                                        games required after deck change before showing 'Chase The
                                                        Trend' Trend Info and Bet Predictions
                                                    </div>
                                                )}
                                                {key === 'superHotStart' && (
                                                    <div>
                                                        <span className={styles.highlight}>
                                                            {
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            }
                                                            x
                                                        </span>{' '}
                                                        wins in a row since last deck change needed to show 'Super Hot
                                                        Streak'
                                                    </div>
                                                )}
                                                {key === 'superHotPercentage' && (
                                                    <div>
                                                        <span className={styles.highlight}>
                                                            {
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            }
                                                            %
                                                        </span>{' '}
                                                        of wins since last deck change needed to show 'Super Hot' banker
                                                        or player
                                                    </div>
                                                )}
                                                {key === 'dealerModeMinCount' && (
                                                    <div>
                                                        <span className={styles.highlight}>
                                                            {
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            }
                                                            x
                                                        </span>{' '}
                                                        games required before showing Lucky/Hot/Genius/God Dealer
                                                        ratings
                                                    </div>
                                                )}
                                            </TableCell>

                                            <TableCell style={{ textAlign: 'right', paddingRight: 20 }}>
                                                <input
                                                    onFocus={(e) => {
                                                        e.target.select()
                                                    }}
                                                    onBlur={(e) => {
                                                        blur(e, key)
                                                    }}
                                                    onChange={(e) => {
                                                        changeHotTrendValue(e, key)
                                                    }}
                                                    style={inputBoxStyle}
                                                    value={
                                                        hotTrends[table.data.gameType][table.data.gameVariant][key] +
                                                        (['superHotPercentage'].includes(key) ? '%' : 'x')
                                                    }
                                                ></input>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                {Object.keys(hotTrends[table.data.gameType][table.data.gameVariant])
                                    .filter((v) =>
                                        [
                                            'luckyDealerPercentage',
                                            'hotDealerPercentage',
                                            'geniusDealerPercentage',
                                            'godDealerPercentage',
                                        ].includes(v)
                                    )
                                    .map((key, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                {key === 'luckyDealerPercentage' && (
                                                    <div>
                                                        <span className={styles.highlight}>
                                                            {
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            }
                                                            %
                                                        </span>{' '}
                                                        of results correctly predicted needed to show 'Lucky Dealer'
                                                    </div>
                                                )}
                                                {key === 'hotDealerPercentage' && (
                                                    <div>
                                                        <span className={styles.highlight}>
                                                            {
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            }
                                                            %
                                                        </span>{' '}
                                                        of results correctly predicted needed to show 'Hot Dealer'
                                                    </div>
                                                )}
                                                {key === 'geniusDealerPercentage' && (
                                                    <div>
                                                        <span className={styles.highlight}>
                                                            {
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            }
                                                            %
                                                        </span>{' '}
                                                        of results correctly predicted needed to show 'Genius Dealer'
                                                    </div>
                                                )}
                                                {key === 'godDealerPercentage' && (
                                                    <div>
                                                        <span className={styles.highlight}>
                                                            {
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            }
                                                            %
                                                        </span>{' '}
                                                        of results correctly predicted needed to show 'God Dealer'
                                                    </div>
                                                )}
                                            </TableCell>

                                            <TableCell
                                                style={{ textAlign: 'right', paddingRight: 20, position: 'relative' }}
                                            >
                                                <input
                                                    onKeyPress={(e) => e.preventDefault()}
                                                    style={inputBoxStyle}
                                                    readOnly={true}
                                                    value={
                                                        hotTrends[table.data.gameType][table.data.gameVariant][key] +
                                                        '%'
                                                    }
                                                ></input>

                                                <KeyboardArrowUpIcon
                                                    onClick={() =>
                                                        changeHotTrendValue_step(
                                                            Number(
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            ) + 5,
                                                            key
                                                        )
                                                    }
                                                    className={styles.spinArrows}
                                                    style={{
                                                        fontSize: '15px',
                                                        border: '1px solid #999',
                                                        position: 'absolute',
                                                        top: 16,
                                                        right: 80,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                                <KeyboardArrowDownIcon
                                                    onClick={() =>
                                                        changeHotTrendValue_step(
                                                            Number(
                                                                hotTrends[table.data.gameType][table.data.gameVariant][
                                                                key
                                                                ]
                                                            ) - 5,
                                                            key
                                                        )
                                                    }
                                                    className={styles.spinArrows}
                                                    style={{
                                                        fontSize: '15px',
                                                        border: '1px solid #999',
                                                        position: 'absolute',
                                                        bottom: 16,
                                                        right: 80,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    <Paper sx={{ background: (theme) => theme.palette.card.background, mt: '20px' }}>
                        <Box className={styles.tableHeaderWrapper}>
                            <Box className={styles.tooltipWrapper}>
                                <Typography className={styles.tableHeader}>Side Bet Trends</Typography>
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '15px' }}>
                                            Super is 100% and Hot is 75% of side bet meter fills. Toggle on/off to
                                            disable any trends from display. To adjust the order of priority, go to
                                            Trend Priority tab
                                        </span>
                                    }
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>
                        </Box>
                        <Table className='config-table'>
                            <TableBody>
                                {sidebetSortedByPriority.map((v, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell style={{ textAlign: 'center', width: 50, color: '#bbb' }}>
                                            {v.priority}
                                        </TableCell>
                                        <TableCell>
                                            {v.key === 'superHotLuckySix' && <div>Super Hot Lucky 6</div>}
                                            {v.key === 'superHotMegaSix' && <div>Super Hot Mega 6</div>}
                                            {v.key === 'superHotTiger' && <div>Super Hot Tiger</div>}
                                            {v.key === 'superHotTigerBig' && <div>Super Hot Tiger Big</div>}
                                            {v.key === 'superHotTigerSmall' && <div>Super Hot Tiger Small</div>}
                                            {v.key === 'hotLuckySix' && <div>Hot Lucky 6</div>}
                                            {v.key === 'hotMegaSix' && <div>Hot Mega 6</div>}
                                            {v.key === 'hotTiger' && <div>Hot Tiger</div>}
                                            {v.key === 'hotTigerBig' && <div>Hot Tiger Big</div>}
                                            {v.key === 'hotTigerSmall' && <div>Hot Tiger Small</div>}
                                            {v.key === 'superHotTie' && <div>Super Hot Tie</div>}
                                            {v.key === 'superHotTigerTie' && <div>Super Hot Tiger Tie</div>}
                                            {v.key === 'superHotPlayerPair' && <div>Super Hot Player Pair </div>}
                                            {v.key === 'superHotBankerPair' && <div>Super Hot Banker Pair</div>}
                                            {v.key === 'superHotTigerPair' && <div>Super Hot Tiger Pair</div>}
                                            {v.key === 'hotTie' && <div>Hot Tie</div>}
                                            {v.key === 'hotTigerTie' && <div>Hot Tiger Tie</div>}
                                            {v.key === 'hotPlayerPair' && <div>Hot Player Pair</div>}
                                            {v.key === 'hotBankerPair' && <div>Hot Banker Pair</div>}
                                            {v.key === 'hotTigerPair' && <div>Hot Tiger Pair</div>}
                                            {v.key === ''}
                                        </TableCell>
                                        <TableCell className={styles.switchCols}>
                                            <div className='activeToggle'>
                                                <FormControlLabel
                                                    sx={{ margin: 0 }}
                                                    control={
                                                        <XSwitch
                                                            checked={
                                                                hotTrends[table.data.gameType][table.data.gameVariant]
                                                                    .trends[v.key].active
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(e) => {
                                                                switchEvent('active', v.key)
                                                            }}
                                                            inputProps={{ 'aria-label': 'active on/off' }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    <Paper sx={{ background: (theme) => theme.palette.card.background, mt: '20px' }}>
                        <Box className={styles.tableHeaderWrapper}>
                            <Box className={styles.tooltipWrapper}>
                                <Typography className={styles.tableHeader}>Side Bet Meters</Typography>
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '15px' }}>
                                            Changes on these meter values will overwrite the gold pot side bet meter
                                            values on “Trend Priority” tab
                                        </span>
                                    }
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box className={styles.contentWrapper}>
                            {(table.data.gameVariant === 'lucky6' || table.data.gameVariant === 'lucky6Slot') && (
                                <Box>
                                    <Typography className={styles.fieldRowHeader}>Lucky 6 meter</Typography>
                                    <TextField
                                        size='small'
                                        id='lucky6MeterMax'
                                        name='lucky6MeterMax'
                                        type='text'
                                        fullWidth
                                        variant='outlined'
                                        onFocus={() => onFocus('lucky6MeterMax')}
                                        onBlur={(e) => onBlur(e, 'lucky6MeterMax')}
                                        onChange={(e) => {
                                            setLucky6MeterMax(e.target.value.replace(/\D/g, ''))
                                            setChanged(changed + 1)
                                        }}
                                        value={lucky6MeterMax}
                                        disabled={postStatus === true}
                                        error={
                                            submitted &&
                                            metersFields[table.data.gameType][
                                                table.data.gameVariant
                                            ].lucky6MeterMax.error()
                                        }
                                    />
                                </Box>
                            )}

                            {table.data.gameVariant === 'mega6' && (
                                <Box>
                                    <Typography className={styles.fieldRowHeader}>Mega 6 meter</Typography>
                                    <TextField
                                        size='small'
                                        id='mega6MeterMax'
                                        name='mega6MeterMax'
                                        type='text'
                                        fullWidth
                                        variant='outlined'
                                        onFocus={() => onFocus('mega6MeterMax')}
                                        onBlur={(e) => onBlur(e, 'mega6MeterMax')}
                                        onChange={(e) => {
                                            setMega6MeterMax(e.target.value.replace(/\D/g, ''))
                                            setChanged(changed + 1)
                                        }}
                                        value={mega6MeterMax}
                                        disabled={postStatus === true}
                                        error={
                                            submitted &&
                                            metersFields[table.data.gameType][
                                                table.data.gameVariant
                                            ].mega6MeterMax.error()
                                        }
                                    />
                                </Box>
                            )}

                            <Box>
                                <Typography className={styles.fieldRowHeader}>Tie meter</Typography>
                                <TextField
                                    size='small'
                                    id='tieMeterMax'
                                    name='tieMeterMax'
                                    type='text'
                                    fullWidth
                                    variant='outlined'
                                    onFocus={() => onFocus('tieMeterMax')}
                                    onBlur={(e) => onBlur(e, 'tieMeterMax')}
                                    onChange={(e) => {
                                        setTieMeterMax(e.target.value.replace(/\D/g, ''))
                                        setChanged(changed + 1)
                                    }}
                                    value={tieMeterMax}
                                    disabled={postStatus === true}
                                    error={
                                        submitted &&
                                        metersFields[table.data.gameType][table.data.gameVariant].tieMeterMax.error()
                                    }
                                />
                            </Box>

                            {table.data.gameVariant === 'tiger' && (
                                <>
                                    <Box>
                                        <Typography className={styles.fieldRowHeader}>Tiger meter</Typography>
                                        <TextField
                                            size='small'
                                            id='tigerMeterMax'
                                            name='tigerMeterMax'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('tigerMeterMax')}
                                            onBlur={(e) => onBlur(e, 'tigerMeterMax')}
                                            onChange={(e) => {
                                                setTigerMeterMax(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)
                                            }}
                                            value={tigerMeterMax}
                                            disabled={postStatus === true}
                                            error={
                                                submitted &&
                                                metersFields[table.data.gameType][
                                                    table.data.gameVariant
                                                ].tigerMeterMax.error()
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Typography className={styles.fieldRowHeader}>Tiger Tie meter</Typography>
                                        <TextField
                                            size='small'
                                            id='tigerTieMeterMax'
                                            name='tigerTieMeterMax'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('tigerTieMeterMax')}
                                            onBlur={(e) => onBlur(e, 'tigerTieMeterMax')}
                                            onChange={(e) => {
                                                setTigerTieMeterMax(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)
                                            }}
                                            value={tigerTieMeterMax}
                                            disabled={postStatus === true}
                                            error={
                                                submitted &&
                                                metersFields[table.data.gameType][
                                                    table.data.gameVariant
                                                ].tigerTieMeterMax.error()
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Typography className={styles.fieldRowHeader}>Tiger Pair meter</Typography>
                                        <TextField
                                            size='small'
                                            id='tigerPairMeterMax'
                                            name='tigerPairMeterMax'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('tigerPairMeterMax')}
                                            onBlur={(e) => onBlur(e, 'tigerPairMeterMax')}
                                            onChange={(e) => {
                                                setTigerPairMeterMax(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)
                                            }}
                                            value={tigerPairMeterMax}
                                            disabled={postStatus === true}
                                            error={
                                                submitted &&
                                                metersFields[table.data.gameType][
                                                    table.data.gameVariant
                                                ].tigerPairMeterMax.error()
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Typography className={styles.fieldRowHeader}>Tiger Big meter</Typography>
                                        <TextField
                                            size='small'
                                            id='tigerBigMeterMax'
                                            name='tigerBigMeterMax'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('tigerBigMeterMax')}
                                            onBlur={(e) => onBlur(e, 'tigerBigMeterMax')}
                                            onChange={(e) => {
                                                setTigerBigMeterMax(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)
                                            }}
                                            value={tigerBigMeterMax}
                                            disabled={postStatus === true}
                                            error={
                                                submitted &&
                                                metersFields[table.data.gameType][
                                                    table.data.gameVariant
                                                ].tigerBigMeterMax.error()
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Typography className={styles.fieldRowHeader}>Tiger Small meter</Typography>
                                        <TextField
                                            size='small'
                                            id='tigerSmallMeterMax'
                                            name='tigerSmallMeterMax'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('tigerSmallMeterMax')}
                                            onBlur={(e) => onBlur(e, 'tigerSmallMeterMax')}
                                            onChange={(e) => {
                                                setTigerSmallMeterMax(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)
                                            }}
                                            value={tigerSmallMeterMax}
                                            disabled={postStatus === true}
                                            error={
                                                submitted &&
                                                metersFields[table.data.gameType][
                                                    table.data.gameVariant
                                                ].tigerSmallMeterMax.error()
                                            }
                                        />
                                    </Box>
                                </>
                            )}

                            {table.data.gameVariant !== 'tiger' && (
                                <>
                                    <Box>
                                        <Typography className={styles.fieldRowHeader}>Banker Pair meter</Typography>
                                        <TextField
                                            size='small'
                                            id='bankerPairMeterMax'
                                            name='bankerPairMeterMax'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('bankerPairMeterMax')}
                                            onBlur={(e) => onBlur(e, 'bankerPairMeterMax')}
                                            onChange={(e) => {
                                                setBankerPairMeterMax(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)
                                            }}
                                            value={bankerPairMeterMax}
                                            disabled={postStatus === true}
                                            error={
                                                submitted &&
                                                metersFields[table.data.gameType][
                                                    table.data.gameVariant
                                                ].bankerPairMeterMax.error()
                                            }
                                        />
                                    </Box>

                                    <Box>
                                        <Typography className={styles.fieldRowHeader}>Player Pair meter</Typography>
                                        <TextField
                                            size='small'
                                            id='playerPairMeterMax'
                                            name='playerPairMeterMax'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('playerPairMeterMax')}
                                            onBlur={(e) => onBlur(e, 'playerPairMeterMax')}
                                            onChange={(e) => {
                                                setPlayerPairMeterMax(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)
                                            }}
                                            value={playerPairMeterMax}
                                            disabled={postStatus === true}
                                            error={
                                                submitted &&
                                                metersFields[table.data.gameType][
                                                    table.data.gameVariant
                                                ].playerPairMeterMax.error()
                                            }
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Paper>
                    <Paper sx={{ background: (theme) => theme.palette.card.background, mt: '20px' }}>
                        <Box className={styles.tableHeaderWrapper}>
                            <Box className={styles.tooltipWrapper}>
                                <Typography className={styles.tableHeader}>Basic Trends</Typography>
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '15px' }}>
                                            To adjust the order of priority, go to Trend Priority tab
                                        </span>
                                    }
                                    placement='right'
                                >
                                    <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                </Tooltip>
                            </Box>
                        </Box>
                        <Table className='config-table'>
                            <TableBody>
                                {predictionsSortedByPriority.map((v, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ height: '4.5rem', '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell style={{ textAlign: 'center', width: 50, color: '#bbb' }}>
                                            {v.priority}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                defaultValues.trends[table.data.gameType][table.data.gameVariant][v.key]
                                                    .name
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    <Paper sx={{ background: (theme) => theme.palette.card.background, p: '10px', mt: '20px' }}>
                        <h3 style={{ marginLeft: 10 }}>Dealer Accuracy Offset</h3>
                        <FormControl component='fieldset' style={{ marginLeft: 50, padding: 5 }}>
                            <RadioGroup
                                value={methodValue}
                                onChange={(e) => {
                                    setMethodValue(Number(e.target.value))
                                    checkForChanges(Number(e.target.value), 'methodValue')
                                }}
                                style={{ marginLeft: 20 }}
                            >
                                <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label='Method 1 – Side Bet Bias (Current Method / Preferred Method)'
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label='Method 2 - True “Basic” Accuracy'
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio />}
                                    label='Method 3 - True “Basic + Side Bet” Accuracy'
                                />
                                <FormControlLabel value={3} control={<Radio />} label='Method 4 - Factored Accuracy' />
                                <div style={{ margin: '10px 0 0 16px' }}>
                                    {methodValue === 3 && (
                                        <>
                                            <div>Percentage Offset</div>
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                sx={{ width: '150px' }}
                                                type='number'
                                                value={offsetValue}
                                                onChange={(e) => {
                                                    setOffsetValue(Number(e.target.value))
                                                    checkForChanges(Number(e.target.value), 'offsetValue')
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </>
                                    )}
                                    {methodValue !== 3 && (
                                        <>
                                            <div>Percentage Offset</div>
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                sx={{ width: '150px' }}
                                                type='number'
                                                defaultValue={offsetValue}
                                                disabled
                                                InputProps={{ readOnly: true }}
                                            />
                                        </>
                                    )}
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </Box>

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={postStatus === true}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        onClick={() => {
                            setDefaults()
                        }}
                    >
                        Default
                    </Button>

                    {changed > 0 && (
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={send}
                            disabled={postStatus}
                        >
                            Update
                        </Button>
                    )}
                </div>}
            </form>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default ChaseTheTrend
