import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'
import Button from '@mui/material/Button'
import { fetch } from '../../api'

const rootStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px 20px',
        boxShadow: 'none',
        borderRadius: 0,
        background: '#333333'
    },
    paper: {
        padding: '10px 20em',
        maxWidth: 676,
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px auto',
    },
    removeButton: {
        background: theme.palette.error.main,
        '&:hover': {
            background: '#bf3328',
        }
    }
}))

const Settings = () => {
    const role = window.localStorage.getItem('role')
    const [confirmRestart, setConfirmRestart] = useState(false)
    const [loading, setLoading] = useState(false)
    const styles = rootStyles()

    const reboot = async () => {
        console.log("boom")
        setLoading(true)
        try {
            await fetch("windows/reboot")
        } finally {
            setTimeout(() => {
                setConfirmRestart(false)
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            }, 2000)
        }
    }

    const onRestartClick = (e) => {
        e.stopPropagation()
        setConfirmRestart(true)
    }

    return <Paper className={styles.root}>
        <Dialog
            key="dialogRestart"
            open={confirmRestart}
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return
                }
                setConfirmRestart(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!loading && <DialogTitle id="alert-dialog-title">Do you want to restart the system?</DialogTitle>}
            {loading && <DialogTitle id="alert-dialog-title">restarting</DialogTitle>}
            {loading && <LinearProgress />}
            {!loading && <DialogActions>
                <Button onClick={() => setConfirmRestart(false)} color="primary" key="delete-no">
                    No
                </Button>
                <Button onClick={reboot} key="delete-yes">
                    Yes
                </Button>
            </DialogActions>}
        </Dialog>
        <Paper className={styles.paper} elevation={1}>
            <>
                {(role === 'root' || role === 'masterAdmin') && <div>
                    <Button className={styles.removeButton}
                        onClick={onRestartClick}>
                        Restart system
                    </Button>
                </div>}

                {role === 'jackpotUser' && <div>
                    user settings
                </div>}
            </>
        </Paper>
    </Paper>
}

export default Settings