const GamesPie = ({
    gameVariantFilter,
    baccaratStandardGames,
    baccaratLucky6Games,
    baccaratLucky6SlotGames,
    baccaratMega6Games,
    baccaratTigerGames,
    blackjackStandardGames,
    blackjackAnyPairSlotGames,
}) => {
    const getTotalGames = (gameVariantFilter) => {
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                return baccaratStandardGames
            case 'baccaratLucky6':
                return baccaratLucky6Games
            case 'baccaratLucky6Slot':
                return baccaratLucky6SlotGames
            case 'baccaratMega6':
                return baccaratMega6Games
            case 'baccaratTiger':
                return baccaratTigerGames
            case 'blackjackStandard':
                return blackjackStandardGames
            case 'blackjackAnyPairSlot':
                return blackjackAnyPairSlotGames
            default:
                return 0
        }
    }

    return (
        <svg className='donut' width='300' height='100%' viewBox='0 0 42 42'>
            <circle
                className='donut-hole'
                cx='21'
                cy='21'
                r='15.91549430918954'
                fill='#222222'
                style={{
                    width: 'inherit',
                    pointerEvents: 'none',
                }}
            />
            <g className='gamespie-chart-text'>
                <text x='50%' y='50%' className='gamespie-chart-number' id='totalValue'>
                    {getTotalGames(gameVariantFilter)}
                </text>
                <text x='50%' y='50%' className='gamespie-chart-label'>
                    Total Games
                </text>
            </g>
        </svg>
    )
}

export default GamesPie
