import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Row, Col } from 'reactstrap'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import LinearProgress from '@mui/material/LinearProgress'

import DateSelectorInline from './DateSelectorInline'
import LocationSelectorPanel from './LocationSelectorPanel'
import {
    findGameDeviceInTable,
    getGameType,
    getGameVariant,
    getDescendantDevicesByGameType,
    getDescendantDevicesByGameVariant,
} from './TreeUtils'

const Tree = ({
    loading,
    noAutoUpdate,
    autoUpdateEnabled,
    toggleAutoUpdate,
    dateChange,
    startDate,
    endDate,
    zones,
    pits,
    tables,
    devices,
    setLocation,
    location,
    fluid,
    hideLocation,
    xstadium,
}) => {
    const sortFunc = (a, b) => {
        var nameA = a.data.name.toUpperCase()
        var nameB = b.data.name.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        return 0
    }

    if (!loading && zones && pits && tables && devices) {
        zones.sort(sortFunc)
        tables.sort(sortFunc)
        pits.sort(sortFunc)
        devices.sort(sortFunc)
    }

    // const etgZone = !loading && zones && zones.find((zone) => zone.data.name === 'etgzone')
    // const etgPit = !loading && pits && pits.find((pit) => pit.data.name === 'etgpit')
    // const etgTables = etgZone && etgPit ? tables.filter((table) => table.data.containerIndex === etgPit.index) : []

    return (
        <div
            style={{
                position: 'fixed',
                top: 68,
                padding: '1em',
                left: fluid ? 54 : 'calc(50vw - 770px)',
                alignSelf: 'start',
                background: 'transparent',
                height: '-webkit-fill-available',
                zIndex: 1,
            }}
        >
            <DateSelectorInline
                externalAutoUpdateEnabled={autoUpdateEnabled}
                toggleAutoUpdate={toggleAutoUpdate}
                noAutoUpdate={noAutoUpdate}
                dateChange={dateChange}
                rangeStart={startDate}
                rangeEnd={endDate}
            />
            {!hideLocation && (
                <Card>
                    <CardContent style={{ padding: '2em' }}>
                        <h4 style={{ margin: 0 }}>Navigation</h4>
                        <div
                            className='location-tree-card'
                            style={{
                                height: 'calc(87vh - 470px)',
                                paddingLeft: 0,
                            }}
                        >
                            <PerfectScrollbar
                                className='media-list overflow-hidden position-relative'
                                options={{
                                    wheelPropagation: true,
                                }}
                            >
                                {loading && (
                                    <Row xs='1'>
                                        <Col>
                                            <h3>Fetching tables...</h3>
                                            <LinearProgress />
                                        </Col>
                                    </Row>
                                )}
                                {!loading && (
                                    <>
                                        {xstadium ? (
                                            <>
                                                {tables.length > 0 ? (
                                                    <Box
                                                        padding='1rem 0'
                                                        display='flex'
                                                        flexDirection='column'
                                                        gap='.75rem'
                                                    >
                                                        {tables.map((table, index) => (
                                                            <Box key={index}>
                                                                <Button
                                                                    variant='contained'
                                                                    sx={{
                                                                        backgroundColor:
                                                                            location &&
                                                                            location[0].branchIndex === table.index
                                                                                ? '#FF9F43'
                                                                                : 'primary',
                                                                        ':hover': {
                                                                            backgroundColor:
                                                                                location &&
                                                                                location[0].branchIndex === table.index
                                                                                    ? '#FF9F43'
                                                                                    : 'primary',
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        const descendantDevices = findGameDeviceInTable(
                                                                            devices,
                                                                            table.index
                                                                        ).map((dv) => dv.index)
                                                                        setLocation({
                                                                            branchColor: '#FF9F43',
                                                                            branchIndex: table.index,
                                                                            branchName: table.data.name,
                                                                            branchType: 'Table',
                                                                            gameType: getGameType(
                                                                                tables,
                                                                                devices,
                                                                                table.index,
                                                                                table.data.containerType
                                                                            ),
                                                                            gameVariant: getGameVariant(
                                                                                tables,
                                                                                devices,
                                                                                table.index,
                                                                                table.data.containerType
                                                                            ),
                                                                            descendantDevices,
                                                                            descendantDevicesByGameType:
                                                                                getDescendantDevicesByGameType(
                                                                                    pits,
                                                                                    tables,
                                                                                    devices,
                                                                                    table.index,
                                                                                    table.data.containerType
                                                                                ),
                                                                            descendantDevicesByGameVariant:
                                                                                getDescendantDevicesByGameVariant(
                                                                                    pits,
                                                                                    tables,
                                                                                    devices,
                                                                                    table.index,
                                                                                    table.data.containerType
                                                                                ),
                                                                        })
                                                                    }}
                                                                >
                                                                    {table.data.name}
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                ) : (
                                                    <Box padding='1rem 0'>No valid table found</Box>
                                                )}
                                            </>
                                        ) : (
                                            <LocationSelectorPanel
                                                zones={zones}
                                                pits={pits}
                                                tables={tables}
                                                devices={devices}
                                                setSelectedBranch={setLocation}
                                                selectedBranch={location}
                                            />
                                        )}
                                    </>
                                )}
                            </PerfectScrollbar>
                        </div>
                    </CardContent>
                </Card>
            )}
        </div>
    )
}

export default Tree
