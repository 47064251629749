import React from "react";
import { get, flatten } from "lodash";
import LinearProgress from "@mui/material/LinearProgress";
import Icons from "../icons/Icons";
import { pivotIP } from "../../config";
import { useSubscribe } from "../../api";

const BreadCrumbs = ({ selected, levels = null, output = null, size = 20 }) => {

    //selected = {type containerType, index:index}

    const [zones, zonesSocket] = useSubscribe(pivotIP + '/zones/*')
    const [pits, pitsSocket] = useSubscribe(pivotIP + '/pits/*')
    const [tables, tablesSocket] = useSubscribe(pivotIP + '/tables/*')
    const [devices, devicesSocket] = useSubscribe(pivotIP + '/devices/*')


    const active = zonesSocket && zonesSocket.readyState === WebSocket.OPEN
        && tablesSocket && tablesSocket.readyState === WebSocket.OPEN
        && pitsSocket && pitsSocket.readyState === WebSocket.OPEN
        && devicesSocket && devicesSocket.readyState === WebSocket.OPEN

    if (!zones
        || !pits
        || !devices
        || !tables
        || !active
    ) { return <LinearProgress /> }

    const key = {
        device: { o: devices, p: 'table', c: '#E6A655' },
        table: { o: tables, p: 'pit', c: '#3498DB' },
        pit: { o: pits, p: 'zone', c: '#A1C2CC' },
        zone: { o: zones, p: 'casino', c: '#E74C3C' },
        casino: { o: [], p: null, c: '#2980B9' }
    }

    const getBreadcrumbsArray = (type, index) => {
        const obj = key[type.toLowerCase()]
        const dataObj = obj ? obj.o.filter(v => v.index === index) : []
        const name = get(dataObj, ['0', 'data', 'name'], null)
        const containerIndex = get(dataObj, ['0', 'data', 'containerIndex'], null)
        const containerType = get(dataObj, ['0', 'data', 'containerType'], obj.p)
        return containerType ? flatten([getBreadcrumbsArray(containerType, containerIndex), { name: name, type: type.toLowerCase(), c: obj.c }]) : [{ name: 'Casino', type: 'casino', c: obj.c }]
    }

    const breadCrumbs = levels ? getBreadcrumbsArray(selected.type, selected.index).slice(0 - levels) : getBreadcrumbsArray(selected.type, selected.index)

    return <>{breadCrumbs && breadCrumbs.map((v, i, a) => <span key={i} >
        <h2 style={{ display: 'inline-block', fontSize: size, color: v.c }}>
            <Icons icon={v.type} size={size} style={{ display: 'inline-flex', verticalAlign: 'middle' }} />
            {v.name}
        </h2>
        {i !== a.length - 1 && <div style={{ display: 'inline-block', fontSize: size, margin: 5 }}>{'>'}</div>}
    </span>)}
    </>


}

export default BreadCrumbs
