import React, { useState } from "react"
import { fetch as api_fetch } from "../../api"

const ManualEndGameButton = ({ deviceIp }) => {
    const [endGameButtonState, setEndGameButtonState] = useState("ok")

    const endGame = async () => {
        try {
            const response = await api_fetch(deviceIp + "/baccarat/manual/end")
            console.log("manual end game:", response.status, response)
            setEndGameButtonState("ok")
        } catch (e) {
            console.log("failed manual end game", e)
            setEndGameButtonState("failed")
            setTimeout(() => setEndGameButtonState("ok"), 3000)
        }
    };

    const buttonColor =
        endGameButtonState === "ok"
            ? "green"
            : endGameButtonState === "trying"
                ? "orange"
                : endGameButtonState === "failed"
                    ? "firebrick"
                    : "green";

    return (
        <div
            onClick={() => {
                setEndGameButtonState("trying")
                endGame()
            }}
            style={{
                width: "min(100%,250px)",
                maxWidth: "min(100%,250px)",
                height: "10vh",
                zIndex: 100,
                background: buttonColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                whiteSpace: "nowrap",
                color: "white",
                fontSize: "3vh",
                borderRadius: 10,
                borderBottomRightRadius: 0,
                cursor: "pointer",
            }}
        >
            {endGameButtonState === "ok" && "END GAME"}
            {endGameButtonState === "trying" && "ENDING GAME"}
            {endGameButtonState === "failed" && "FAILED"}
        </div>
    );
};

export default ManualEndGameButton;
