import React, { Fragment, useState, useEffect } from "react"
import { isEmpty, get } from "lodash"
import { useNavigate } from "react-router-dom"
import { LinearProgress } from "@mui/material"

import { useSubscribe } from "../../api"
import { pivotIP } from "../../config"
import { useMenuContext } from "../contexts/MenuContext"

import "./initialise.css"

const Initalize = () => {
    const [pits, pitsSocket] = useSubscribe(pivotIP + '/pits/*')
    const [devices, devicesSocket] = useSubscribe(pivotIP + '/devices/*')
    const [tables, tablesSocket] = useSubscribe(pivotIP + '/tables/*')
    const [status, statusSocket] = useSubscribe(pivotIP + '/status/*')

    const active = pitsSocket && pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket && devicesSocket.readyState === WebSocket.OPEN &&
        tablesSocket && tablesSocket.readyState === WebSocket.OPEN &&
        statusSocket && statusSocket.readyState === WebSocket.OPEN

    if (!active || !pits || !devices || !tables || !status) {
        return <LinearProgress />
    }

    return <Initialise devices={devices}
        tables={tables}
        pits={pits}
        status={status}
    />
}

const Initialise = ({ devices, tables, pits, status }) => {
    const role = window.localStorage.getItem("role")
    const { setSelectedPit, setSelectedTable } = useMenuContext()
    const [devicesInPit, setDevicesInPit] = useState(null)
    const [deviceStatusesInPit, setDeviceStatusesInPit] = useState(null)

    useEffect(() => {
        if (devicesInPit !== null) {
            const deviceIndexesInPit = devicesInPit.map(v => v.index)
            setDeviceStatusesInPit(status.filter(d => deviceIndexesInPit.includes(d.index)).sort((a, b) => (a.data.name > b.data.name) ? 1 : -1))
        }
    }, [devicesInPit, status])

    const checkForStatusValue = (data, index, key) => {
        const isInStatus = data.filter(v => v.index === index)
        if (isInStatus.length > 0) {
            return get(isInStatus[0], key)
        } else {
            return false
        }
    }

    const navigate = useNavigate()

    const handleInitialisation = (deviceIndex, deviceIp, devicePort, deviceContainerIndex, deviceName) => {
        const deviceLocalize = deviceIp === '127.0.0.1' ? window.location.hostname : deviceIp
        const deviceAddress = isEmpty(devicePort) ? deviceLocalize + ':3099' : deviceLocalize + ':' + devicePort

        if (role === 'dealer') {
            const devicesInTables = tables.filter(v => v.index === deviceContainerIndex)[0];
            const matchedPit = pits.filter(v => v.index === devicesInTables.data.containerIndex)[0];
            setSelectedPit(matchedPit.data.name);

            window.localStorage.setItem('dealer-initialisation', deviceIndex);
            window.localStorage.setItem('dealer-ip', deviceAddress);
            window.localStorage.setItem('dealer-pit', matchedPit.data.name);
            window.localStorage.setItem('dealer-table', deviceName);
        }

        if (role === 'pitboss') {
            window.localStorage.setItem('manager-index', deviceIndex);
            window.localStorage.setItem('manager-ip', deviceAddress);
            window.localStorage.setItem('manager-table', deviceName);
        }

        setSelectedTable(deviceName);
        //setInitialised(true);
        navigate('/posui/dashboard')
    }

    const handlePitInitialisation = (pitIndex, pitName) => {
        window.localStorage.setItem('manager-initialisation', pitIndex)
        setSelectedPit(pitName)
        window.localStorage.setItem('manager-pit', pitName)
        const containerIndexesInPit = tables.reduce((c, v) => { if (v.data.containerIndex === pitIndex) { c.push(v.index) } return c }, [pitIndex])
        setDevicesInPit(devices.filter(v => containerIndexesInPit.includes(v.data.containerIndex)))
    }

    if (role === 'dealer') {
        return (
            <div className='initialisation'>
                {devices.length > 0 && status !== null && <Fragment>

                    <h1 className='header'>Please link your touchscreen with a device.</h1>
                    <div className='init-flexbox'>
                        {devices.filter(v => v.data.type === 'game').map((device, index) => (
                            <div className="table" key={index} id={'table' + index}>
                                <div className="header">
                                    <div className="header-title"><h2>{device.data.name}</h2></div>
                                    <div className="header-ip">
                                        <h3 className="ipadd">IP ADDRESS</h3>
                                        <h3 className="ip">{device.data.ip}</h3>
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="info">
                                        <div className="info-game info-child">
                                            <h3 className="title">GAME TYPE</h3>
                                            <h3 className="data">{device.data.gameType}</h3>
                                        </div>
                                    </div>
                                    <button className="link" onClick={() => handleInitialisation(device.index, device.data.ip, device.data.port, device.data.containerIndex, device.data.name)} disabled={!checkForStatusValue(status, device.index, 'data.game')}>{checkForStatusValue(status, device.index, 'data.game') && <>LINK</>}
                                        {!checkForStatusValue(status, device.index, 'data.game') && <>OFFLINE</>}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </Fragment>}
                {devices.length === 0 && <h1 className='header'>Sorry, there are currently no devices available to link your touchscreen</h1>}

            </div>
        )
    }

    if (role === 'pitboss') {
        return (
            <Fragment>
                {devicesInPit === null && (
                    <div className='initialisation'>
                        {pits.length > 0 && <Fragment>
                            <h1 className='header'>Please link your touchscreen with a pit.</h1>
                            <div className='init-flexbox'>
                                {pits.map((pit, index) => (
                                    <div className="pit" key={index} id={'pit' + index}>
                                        <div className="header">
                                            <div className="header-title"><h2>{pit.data.name}</h2></div>
                                        </div>
                                        <div className="body">
                                            <div className="info">
                                                <div className="info-pit info-child">
                                                    <h3 className="title">TYPE</h3>
                                                    <h3 className="data">{pit.data.type}</h3>
                                                </div>
                                            </div>
                                            <button className="link" onClick={() => handlePitInitialisation(pit.index, pit.data.name)}>
                                                LINK
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Fragment>}
                        {pits.length === 0 && <h1 className='header'>Sorry, there are currently no pits available to link your touchscreen</h1>}
                    </div>)}

                {devicesInPit !== null && deviceStatusesInPit !== null && (
                    <div className='initialisation'>
                        {devicesInPit.length > 0 && <Fragment>
                            <h1 className='header'>Please link your touchscreen with a device.</h1>
                            <div className='init-flexbox'>
                                {devicesInPit.filter(v => v.data.type === 'game').map((device, index) => (
                                    <div className="table" key={index} id={'table' + index}>
                                        <div className="header">
                                            <div className="header-title"><h2>{device.data.name}</h2></div>
                                            <div className="header-ip">
                                                <h3 className="ipadd">IP ADDRESS</h3>
                                                <h3 className="ip">{device.data.ip}</h3>
                                            </div>
                                        </div>
                                        <div className="body">
                                            <div className="info">
                                                <div className="info-game info-child">
                                                    <h3 className="title">GAME TYPE</h3>
                                                    <h3 className="data">{device.data.gameType}</h3>
                                                </div>
                                            </div>
                                            <button className="link" onClick={() => handleInitialisation(device.index, device.data.ip, device.data.port, device.data.containerIndexm, device.data.name)}
                                                disabled={!checkForStatusValue(deviceStatusesInPit, device.index, 'data.game')}>
                                                {checkForStatusValue(deviceStatusesInPit, device.index, 'data.game') && <>LINK</>}
                                                {!checkForStatusValue(deviceStatusesInPit, device.index, 'data.game') && <>OFFLINE</>}
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Fragment>}

                        {devices.length === 0 && <h1 className='header'>Sorry, there are currently no devices available to link your touchscreen</h1>}
                    </div>)}
            </Fragment>
        )
    }
}
export default Initalize
