import React, { useContext, useState } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button, Modal, Fade } from '@mui/material'

import { SideMenuContext } from './Manager'

const SideMenu = () => {
    const { t } = useTranslation('common')

    const { s, pageNav, setPageNav, setShowMenu, showMenu, setSelectedDevice, selectedDevice, deviceOn } =
        useContext(SideMenuContext)

    const [bashBlocker, setBashBlocker] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalType, setModalType] = useState(null)

    const device = selectedDevice

    const modalAction = (type) => {
        if (bashBlocker) return
        setModalType(type)
        setModalOpen(true)
    }

    const rebootDevice = async () => {
        try {
            setBashBlocker(true)
            const address = !isEmpty(device.data.port)
                ? device.data.ip + ':' + device.data.port
                : device.data.ip + ':3099'
            await window.fetch('http://' + address + '/remote/reboot', {
                method: 'GET',
            })
            setModalOpen(false)
            setTimeout(() => setBashBlocker(false), 5000)
        } catch (e) {
            console.log(e)
        }
    }

    const shutdownDevice = async () => {
        try {
            setBashBlocker(true)
            const address = !isEmpty(device.data.port)
                ? device.data.ip + ':' + device.data.port
                : device.data.ip + ':3099'
            await window.fetch('http://' + address + '/remote/shutdown', {
                method: 'GET',
            })
            setModalOpen(false)
            setTimeout(() => setBashBlocker(false), 5000)
        } catch (e) {
            console.log(e)
        }
    }

    const restartTrendboard = async () => {
        try {
            setBashBlocker(true)
            const address = !isEmpty(device.data.port)
                ? device.data.ip + ':' + device.data.port
                : device.data.ip + ':3099'
            await window.fetch('http://' + address + '/remote/restart/trendboard', {
                method: 'GET',
            })
            setModalOpen(false)
            setTimeout(() => setBashBlocker(false), 5000)
        } catch (e) {
            console.log(e)
        }
    }

    const turnOffDevice = async () => {
        try {
            setBashBlocker(true)
            const address = !isEmpty(device.data.port)
                ? device.data.ip + ':' + device.data.port
                : device.data.ip + ':3099'
            await fetch('http://' + address + '/device/close')
            setModalOpen(false)
            setTimeout(() => setBashBlocker(false), 5000)
        } catch (e) {
            console.log(e)
        }
    }

    const turnOnDevice = async () => {
        try {
            setBashBlocker(true)
            const address = !isEmpty(device.data.port)
                ? device.data.ip + ':' + device.data.port
                : device.data.ip + ':3099'
            await fetch('http://' + address + '/device/open')
            setModalOpen(false)
            setTimeout(() => setBashBlocker(false), 5000)
        } catch (e) {
            console.log(e)
        }
    }

    const backButtonPress = () => {
        if (pageNav !== 'overview') {
            setPageNav('overview')
        } else {
            setSelectedDevice(null)
        }
    }

    return (
        <div
            style={{
                width: 0,
                background: '#000',
                zIndex: 100,
                position: 'relative',
                ...(showMenu && { width: 250 }),
                ...(!selectedDevice && { width: 0 }),
            }}
            className={s.noScrollBar}
        >
            {selectedDevice && showMenu && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        boxSizing: 'border-box',
                        padding: 5,
                        overflow: 'auto',
                    }}
                >
                    <PerfectScrollbar style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        <Button
                            onClick={() => backButtonPress()}
                            style={{
                                height: '100%',
                                maxHeight: 100,
                                width: '100%',
                                background: '#222',
                                color: '#ccc',
                                fontSize: 25,
                            }}
                        >
                            {pageNav === 'overview' ? 'Hide Menu' : 'Back'}
                        </Button>
                        {selectedDevice.data.type === 'game' && (
                            <>
                                {(selectedDevice.data.gameType === 'baccarat' ||
                                    selectedDevice.data.gameType === 'blackjack') && (
                                    <Button
                                        onClick={() => {
                                            setShowMenu(false)
                                            setPageNav('dealerUI')
                                            window.localStorage.setItem('pageNav', 'dealerUI')
                                        }}
                                        style={{
                                            height: '100%',
                                            maxHeight: 100,
                                            width: '100%',
                                            background: '#222',
                                            color: '#ccc',
                                            fontSize: 25,
                                        }}
                                    >
                                        Dealer UI
                                    </Button>
                                )}
                                <Button
                                    onClick={() => {
                                        setPageNav('configurations')
                                        window.localStorage.setItem('pageNav', 'configurations')
                                    }}
                                    style={{
                                        height: '100%',
                                        maxHeight: 100,
                                        width: '100%',
                                        background: '#222',
                                        color: '#ccc',
                                        fontSize: 25,
                                    }}
                                >
                                    Edit Min Max
                                </Button>
                                {selectedDevice.data.gameType === 'baccarat' && (
                                    <Button
                                        onClick={() => {
                                            setPageNav('gameHistory')
                                            window.localStorage.setItem('pageNav', 'gameHistory')
                                        }}
                                        style={{
                                            height: '100%',
                                            maxHeight: 100,
                                            width: '100%',
                                            background: '#222',
                                            color: '#ccc',
                                            fontSize: 25,
                                        }}
                                    >
                                        Edit Results
                                    </Button>
                                )}
                                {deviceOn && (
                                    <Button
                                        onClick={() => modalAction('turnOff')}
                                        style={{
                                            height: '100%',
                                            maxHeight: 100,
                                            width: '100%',
                                            background: '#222',
                                            color: '#ccc',
                                            fontSize: 25,
                                        }}
                                    >
                                        Close Table
                                    </Button>
                                )}
                                {!deviceOn && (
                                    <Button
                                        onClick={() => modalAction('turnOn')}
                                        style={{
                                            height: '100%',
                                            maxHeight: 100,
                                            width: '100%',
                                            background: '#222',
                                            color: '#ccc',
                                            fontSize: 25,
                                        }}
                                    >
                                        Open Table
                                    </Button>
                                )}
                                <Button
                                    onClick={() => modalAction('trendboard')}
                                    style={{
                                        height: '100%',
                                        maxHeight: 100,
                                        width: '100%',
                                        background: '#222',
                                        color: '#ccc',
                                        fontSize: 25,
                                    }}
                                >
                                    Restart Trendboard
                                </Button>
                                <Button
                                    onClick={() => modalAction('reboot')}
                                    style={{
                                        height: '100%',
                                        maxHeight: 100,
                                        width: '100%',
                                        background: '#222',
                                        color: '#ccc',
                                        fontSize: 25,
                                    }}
                                >
                                    Reboot Device
                                </Button>
                                <Button
                                    onClick={() => modalAction('shutdown')}
                                    style={{
                                        height: '100%',
                                        maxHeight: 100,
                                        width: '100%',
                                        background: '#222',
                                        color: '#ccc',
                                        fontSize: 25,
                                    }}
                                >
                                    Shutdown Device
                                </Button>
                            </>
                        )}
                    </PerfectScrollbar>
                </div>
            )}

            <Modal
                className={s.modalContainer}
                open={modalOpen}
                onClose={(e) => {
                    setModalOpen(false)
                }}
            >
                <Fade in={modalOpen}>
                    <div className={s.modal}>
                        <div className={s.modalHeader}>
                            {modalType === 'reboot' && <h1>{t('manager.rebootDevice')}</h1>}
                            {modalType === 'shutdown' && <h1>{t('manager.shutdownDevice')}</h1>}
                            {modalType === 'trendboard' && <h1>{t('manager.restartTrendboard')}</h1>}
                            {modalType === 'turnOff' && <h1>{'CLOSE TABLE CONFIRMATION'}</h1>}
                            {modalType === 'turnOn' && <h1>{'OPEN TABLE CONFIRMATION'}</h1>}
                        </div>
                        <div className={s.modalPrompt}>
                            <div style={{ fontSize: 14, color: '#ccc', margin: '30px' }}>
                                {modalType === 'reboot' && <h2>{t('manager.rebootDevicePrompt')}</h2>}
                                {modalType === 'shutdown' && <h2>{t('manager.shutdownDevicePrompt')}</h2>}
                                {modalType === 'trendboard' && <h2>{t('manager.restartTrendboardPrompt')}</h2>}
                                {modalType === 'turnOff' && <h1>{'ARE YOU SURE YOU WISH TO CLOSE THIS TABLE?'}</h1>}
                                {modalType === 'turnOn' && <h1>{'ARE YOU SURE YOU WISH TO OPEN THIS TABLE?'}</h1>}
                            </div>
                            <div className={s.modalButtonRow}>
                                <Button variant='contained' onClick={() => setModalOpen(false)}>
                                    {t('global.cancel')}
                                </Button>
                                {modalType === 'reboot' && (
                                    <Button variant='contained' color='primary' onClick={() => rebootDevice()}>
                                        {t('global.confirm')}
                                    </Button>
                                )}
                                {modalType === 'shutdown' && (
                                    <Button variant='contained' color='primary' onClick={() => shutdownDevice()}>
                                        {t('global.confirm')}
                                    </Button>
                                )}
                                {modalType === 'trendboard' && (
                                    <Button variant='contained' color='primary' onClick={() => restartTrendboard()}>
                                        {t('global.confirm')}
                                    </Button>
                                )}
                                {modalType === 'turnOff' && (
                                    <Button variant='contained' color='primary' onClick={() => turnOffDevice()}>
                                        {t('global.confirm')}
                                    </Button>
                                )}
                                {modalType === 'turnOn' && (
                                    <Button variant='contained' color='primary' onClick={() => turnOnDevice()}>
                                        {t('global.confirm')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default SideMenu
