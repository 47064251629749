import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { validate, update, FieldText } from '../../../forms'

const rootStyles = makeStyles((theme) => ({
    form: {
        width: 700,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
}))

const RotatorTimers = ({ device, publish, xstadium }) => {
    const styles = rootStyles({ xstadium })

    const navigate = useNavigate()

    // field flags
    const [submitted, setSubmitted] = useState(false)
    // form changed detect hook
    const [changed, setChanged] = useState(false)
    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)

    const [timerRotation, setTimerRotation] = useState('')

    const fields = {
        timerRotation: {
            error: () => timerRotation === '' || Number(timerRotation) <= 0,
            value: timerRotation,
            set: setTimerRotation,
            message: `Time between rotations can't be empty or zero`,
        }
    }

    const defaultValues = {
        timerRotation: 20
    }

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            try {
                setLoading(true)
                await publish({
                    ...device.data,
                    timerRotation: Number(timerRotation),
                })
                setFail('')
                setLoading(false)
            } catch (e) {
                setFail(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setLoading(false)
            }
        }
        setChanged(false)
    }

    const handleChange = (e, setState) => {
        setState(Number(e.target.value.replace(/\D/g, '')))
        setChanged(true)
    }

    const validation = validate(fields)

    if (device.updated !== updated) {
        update(fields, [], device.data, defaultValues)
        setUpdated(device.updated)
    }

    console.log("AA", fields.timerRotation.error())

    return <>
        <form
            className={styles.form}
            noValidate
            onSubmit={(e) => {
                e.preventDefault()
            }}
            autoComplete='off'
        >
            {fail && !loading && (
                <Box className={styles.warningContainer}>
                    <SnackbarContent
                        className={styles.fail}
                        message={
                            <Typography component='p' className={styles.warningText}>
                                <WarningIcon className={styles.warningIcon} /> {fail}
                            </Typography>
                        }
                    />
                </Box>
            )}
            <FieldText
                label='Time between rotations (seconds)'
                name='timerRotation'
                value={timerRotation}
                handleChange={(e) => handleChange(e, setTimerRotation)}
                loading={loading}
                error={submitted && fields.timerRotation.error()}
                helperText={fields.timerRotation.message}
            />
            <div className={styles.formButtonWrapper}>
                <Button
                    color='inherit'
                    disabled={loading}
                    disableRipple
                    onClick={() => {
                        navigate(
                            !xstadium
                                ? device.data.containerType === 'casino'
                                    ? `/xtrend/${device.data.containerType}`
                                    : `/xtrend/${device.data.containerType}/${device.data.containerIndex}`
                                : device.data.type === 'game'
                                    ? '/xstadium/tables'
                                    : '/xstadium/servers'
                        )
                    }}
                >
                    Back
                </Button>

                {changed && (
                    <Button variant='contained' type='button' color='primary' disabled={loading} onClick={send}>
                        Update
                    </Button>
                )}
            </div>

            {loading && (
                <CircularProgress
                    size={80}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                    }}
                />
            )}
        </form>
    </>
}

export default RotatorTimers