import React, { memo, useState } from "react"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next"
import { Routes, Route } from "react-router-dom"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import R404 from "../404"
import { MenuContext } from "./contexts/MenuContext"
import Dashboard from "./dashboard/Dashboard"
import Dealer from "./dealer/Dealer"
import i18nConfiguration from "./i18n"
import Initialise from "./initialise/Initialise"
import Manager from "./manager/Manager"

export default memo(() => {
    const role = window.localStorage.getItem("role")
    const [i18nStatus, seti18nStatus] = useState(null)

    if (!i18nStatus)
        (() =>
            i18n
                .use(Backend)
                .use(LanguageDetector)
                .use(initReactI18next)
                .init(i18nConfiguration, (r) =>
                    i18n.changeLanguage("en", () => seti18nStatus(true))
                ))()

    return (
        <MenuContext>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Routes>
                    {role === "dealer" && (<>
                        <Route exact path="/dashboard" element={<Dashboard Component={memo(() => (<Dealer />))} />} />
                        <Route exact path="/dashboard/initialise" element={<Initialise />} />
                        <Route path="*" element={<R404 />} />
                    </>)}

                    {role === "pitboss" && (<>
                        <Route exact path="/dashboard/initialise" element={<Dashboard Component={memo(() => (<Initialise />))} />} />
                        <Route exact path="/dashboard" element={<Dashboard Component={memo(() => (<Manager />))} />} />
                        <Route path="*" element={<R404 />} />
                    </>)}
                </Routes>
            </LocalizationProvider>
        </MenuContext>
    )
})
