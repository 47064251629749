import { useState } from 'react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { makeStyles } from '@mui/styles'

import AlertDialog, { handleClose } from '../../components/AlertDialog'
import GamePayouts from './PayoutsGame'
import InsurancePayouts from './PayoutsInsurance'

const rootStyles = makeStyles(() => ({
    form: {
        width: ({ template }) => template ? 'inherit' : 980,
        margin: ({ template }) => template ? '2em auto 0' : '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '1em',
    },
}))

const Payouts = ({ publish, tables, table, settings, xstadium, template, changeCallback }) => {
    const styles = rootStyles({ xstadium, template })

    // form changed detect hook
    const [changed, setChanged] = useState(0)

    // tabs
    const [value, setValue] = useState(0)

    const handleTabChange = (event, newValue) => {
        if (changed > 0 && !template) {
            setAlertDialogState({
                show: true,
                type: 'confirmation',
                message: 'Are you sure you want to leave this page? Changes you made will not be saved.',
                loading: false,
                error: false,
                confirm: () => {
                    handleClose(alertDialogState, setAlertDialogState)
                    setChanged(0)
                    setValue(newValue)
                },
            })
        } else {
            setValue(newValue)
        }
    }

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
        loading: false,
    })

    return (
        <>
            <div className={styles.form}>
                <Tabs value={value} onChange={handleTabChange}>
                    <Tab label='Game Variant' />
                    {table.data.gameType === 'baccarat' && <Tab label='Insurance' />}
                </Tabs>

                {value === 0 && (
                    <GamePayouts
                        publish={publish}
                        tables={tables}
                        table={table}
                        settings={settings}
                        xstadium={xstadium}
                        changed={changed}
                        setChanged={setChanged}
                        template={template}
                        changeCallback={changeCallback}
                    />
                )}

                {value === 1 && (
                    <InsurancePayouts
                        publish={publish}
                        tables={tables}
                        table={table}
                        settings={settings}
                        xstadium={xstadium}
                        changed={changed}
                        setChanged={setChanged}
                        template={template}
                        changeCallback={changeCallback}
                    />
                )}
            </div>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default Payouts
