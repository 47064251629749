import React, { useState, useRef, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react' // the AG Grid React Component
import _ from 'lodash'
import moment from 'moment'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Container,
    Row,
    Col,
    Progress
} from 'reactstrap'
import Grid from '@mui/material/Grid'

import { useSubscribe, dealerApi, authApi } from '../../api'
import Tree from '../../tree/Tree'
import useWindowSize from '../../WindowSize'
import {
    NanoToHours,
} from '../Formats'

import '../../ag-grid/ag-grid-theme.css' // Core grid CSS, always needed
import '../../ag-grid/ag-grid.css' // Core grid CSS, always needed

const styles = {
    backToListButton: {
        marginTop: 20,
        marginBottom: 20,
        float: 'right'
    },
    pieContainer: {
        width: 200,
        height: 100
    },
    barContainer: {
        width: '-webkit-fill-available',
        height: 150
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em'
    },
    cardTitleText: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        // color: 'white'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5vw 0.7vw',
    },
    cardBodyText: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
    cardSubtitle: {
        margin: '0 15px',
        color: 'darkgrey'
    },
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red'
    }),
    profileDetailsText: {
        padding: '0.9em',
    },
    profilePicture: {
        marginLeft: 25
    },
    profileText: {
        padding: '0.5em !important'
    },
    profileRow: {
        marginBottom: '1em !important'
    },
    profileContainer: {
        maxWidth: 3000
    },
    tabPane: {
        backgroundColor: 'transparent'
    },
}

const Dealers = () => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    // const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active = zonesSocket && zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket && tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket && pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket && devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket && statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket && statusSocket.readyState === WebSocket.OPEN

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')

    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }

    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // fetched data
    const [dealersReport, setDealersReport] = useState(null)
    const [fetchedDealersReport, setFetchedDealersReport] = useState(false)
    const [users, setUsers] = useState(null)
    const [fetchedUsers, setFetchedUsers] = useState(false)
    const [minLoadTime, setMinLoadTime] = useState(false)
    const [progressLoad, setProgressLoad] = useState(0)

    // built charts
    const [builtCharts, setBuiltCharts] = useState({})
    // const setBuiltChartProxy = (chartID, value) => {
    //     setBuiltCharts(prevBuilt => ({
    //         ...prevBuilt,
    //         [chartID]: value
    //     }))
    // }

    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter(v => v !== 'push')
        const oldParams = urlParams.toString()
        // console.log(oldParams)
        propKeys.forEach(k => {
            if (props[k] === null) {
                urlParams.delete(k)
                window.localStorage.removeItem('url:' + k)
                return
            }
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange = propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        if (urlChange || props.push) {
            navigate('/xtable/dealers?' + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                setFetchedDealersReport(false)
            }
            setBuiltCharts({})
        }
    }

    // const isLive = startDate === startOfToday && endDate === endOfToday ? true : false
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))
    // GRID
    const gridRef = useRef() // Optional - for accessing Grid's API

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => {
        return {
            // flex: 1,
            minWidth: 10,
            filter: true,
            sortable: true,
            // floatingFilter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }
    }, [])

    // Example of consuming Grid Event
    const rowSelectHandler = event => {
        if (event.node.isSelected()) {
            console.log("navigate", event.data.id)
            navigate('/xtable/dealer/' + event.data.id)
        }
    }
    // GRID END

    // const isMoreThanOneDay = numberOfDays > 0
    // const isLive = startDate === startOfToday && endDate === endOfToday ? true : false
    // const isMoreThanOneDayOrNotLive = isMoreThanOneDay || (numberOfDays === 0 && !isLive)

    const dateChange = (start, end) => {
        if (start === startDate && end === endDate) return
        setStartDate(start)
        setEndDate(end)
        clearFetch({ startDate: start, endDate: end })
    }

    const getUsers = async () => {
        if (fetchedUsers) {
            return
        }

        setFetchedUsers(true)
        try {
            const response = await authApi.get('dealers').json()
            console.log("users", response)
            setUsers(response)
        } catch (e) {
            console.warn(e)
            setUsers([])
        }
    }

    const getDealersReport = async () => {
        if (fetchedDealersReport) {
            return
        }

        setFetchedDealersReport(true)
        try {
            setTimeout(() => {
                setProgressLoad(10)
            }, 100)
            setTimeout(() => {
                setProgressLoad(20)
            }, 200)
            setTimeout(() => {
                setProgressLoad(25)
            }, 300)
            setTimeout(() => {
                setProgressLoad(35)
            }, 400)
            const response = await dealerApi.get('report/overview/dealers/' + (startDate) * 1000000000 + '/' + (endDate) * 1000000000).json()
            console.log("overview", response)
            // const availableUsers = users.map(user => user.account)
            // const filteredDealersReportByUsers = response.filter((entry) => availableUsers.indexOf(entry.account) >= 0)
            setTimeout(() => {
                setProgressLoad(40)
            }, 100)
            setTimeout(() => {
                setProgressLoad(60)
            }, 200)
            setTimeout(() => {
                setProgressLoad(84)
            }, 300)
            setTimeout(() => {
                setProgressLoad(95)
            }, 400)
            setTimeout(() => {
                setDealersReport(response)
            }, 500)
        } catch (e) {
            console.warn(e)
            setDealersReport([])
        }
    }

    const getDealerHPH = (dr) => {
        let result = 0
        let count = 0
        for (const hour in dr.hourlyGames) {
            if (dr.hourlyGames[hour] > 0) {
                count++
                result = result + dr.hourlyGames[hour]
            }
        }

        if (count === 0) {
            return 0
        }

        result = result / count

        return (result).toFixed(1)
    }

    const dealerTheoOffset = 45 * 1000000000
    const getDealerTheoHPH = (dr) => {
        const dur = NanoToHours(dr.active + (dr.games * dealerTheoOffset))
        if (Number.isNaN(dur) || dur === 0) {
            return '-'
        }

        return (dr.games / dur).toFixed(1)
    }

    getUsers()

    if (users && Number(URLstartDate) === startDate && (Number(URLendDate) === endDate)) {
        if (!fetchedDealersReport) {
            clearFetch({})
        }
        getDealersReport()
    }

    if (resizing && (builtCharts && Object.keys(builtCharts).length > 0)) {
        setBuiltCharts({})
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    const treeLoading = !active ||
        !zones ||
        !pits ||
        !devices ||
        !tables ||
        !status ||
        !minLoadTime

    const overviewDealers = dealersReport ? dealersReport.map(dealer => {
        const dealerUser = _.get(users.filter(user => user.account === dealer.account), '0', {
            firstName: 'unknown'
        })
        return {
            id: dealer.account,
            Name: dealerUser.firstName,
            Account: dealer.account,
            HPH: numberOfDays === 0 ? getDealerHPH(dealer) : '-',
            TheoHPH: getDealerTheoHPH(dealer),
            Hands: dealer.games,
            Turnover: dealer.bets,
            WinLoss: dealer.profit,
        }
    }) : []

    const columnDefs = [
        {
            field: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'Account',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
        {
            field: 'HPH',
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'TheoHPH',
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'Hands',
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'Turnover',
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'WinLoss',
            filter: 'agNumberColumnFilter'
        },
    ]

    // display data
    const avgHPH = !overviewDealers || !overviewDealers.reduce ? 0 : overviewDealers.reduce(
        (prev, next) => {
            if (next.HPH === '-' || Number.isNaN(next.HPH)) {
                return prev
            }
            return prev + Number(next.HPH)
        }, 0)

    const fluid = width < 1900

    if (settings && !settings.data.xtable.sections.includes('dealers')) {
        return <Navigate to='/dashboard' />
    }

    return <Grid container spacing={2}>
        <Grid item style={{ width: 320 }}>
            <Tree loading={treeLoading}
                noAutoUpdate={true}
                dateChange={dateChange}
                startDate={startDate}
                endDate={endDate}
                fluid={fluid}
                hideLocation></Tree>
        </Grid>
        <Grid item xs>
            {!dealersReport && <Container fluid={fluid}>
                <Row xs="1">
                    <Col>
                        <h3>Fetching dealers report...</h3>
                        <Progress
                            value={progressLoad}
                        />
                    </Col>
                </Row>
            </Container>}
            {dealersReport && <Container fluid={fluid}>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader style={styles.cardHeader}>
                                <span style={styles.cardTitleText}>
                                    Avg. HPH
                                </span>
                            </CardHeader>
                            <CardBody style={styles.cardBody}>
                                <span style={styles.cardBodyText}>
                                    {numberOfDays === 0 && (dealersReport.length > 0 ? (avgHPH / dealersReport.length).toFixed(1) : 0)}
                                    {numberOfDays > 0 && '-'}
                                </span>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <CardTitle>Dealers</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="ag-theme-balham-dark" style={{ width: '-webkit-fill-available', height: 'calc(100vh - 460px)' }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={overviewDealers}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        animateRows={true}
                                        rowSelection={'single'}
                                        onRowSelected={rowSelectHandler}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>}
        </Grid>
    </Grid>
}

export default Dealers