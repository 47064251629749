import React, { useReducer } from 'react'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { CssBaseline, LinearProgress } from '@mui/material'
import { lightBlue } from '@mui/material/colors'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import R404 from './404'
import { useAuthorize } from './api'
import Card from './auth/Card'
import Login from './auth/Login'
import Logout from './auth/Logout'
import SelectRole from './auth/SelectRole'
import Setup from './auth/Setup'
import { AppContext } from './contexts/AppContext'
import i18nConfiguration from './i18n'
import Nav from './nav/Nav'
import Pivot from './pivot/Pivot'
import Simulators from './simulator/Simulators'

const reducer = (state, action) => {
    switch (action.type) {
        case 'status':
            return {
                ...state,
                status: action.data,
            }
        case 'i18nInit':
            return {
                ...state,
                i18nInit: action.data,
            }
        default:
            throw new Error()
    }
}

const App = () => {
    const account = window.localStorage.getItem('account')
    const role = window.localStorage.getItem('role')
    const [state, dispatch] = useReducer(reducer, {
        status: null,
        i18nInit: null,
    })

    const authorize = useAuthorize(dispatch)

    const { status, i18nInit } = state

    window.onstorage = async () => {
        const newAccount = window.localStorage.getItem('account')
        const newRole = window.localStorage.getItem('role')
        const newConfig = window.localStorage.getItem('config')
        if (newConfig === '' || newConfig === null) {
            console.log('a', newConfig)
            window.location.reload()
        }
        if (newAccount !== account || newRole !== role) {
            try {
                await authorize()
            } catch (e) {
                console.warn(e)
            }
        }
    }

    const theme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: lightBlue[500],
            },
            secondary: {
                main: '#ffca28',
            },
            card: {
                content: '#424242',
                background: '#303030',
                primary: '#303030',
                secondary: '#424242',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                s: 375,
                sm: 675,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        typography: {
            useNextVariants: true,
            allVariants: {
                fontFamily: "'Montserrat', Helvetica, Arial, serif",
            },
        },
    })

    if (!status) {
        ; (async () => {
            try {
                await authorize()
            } catch (e) {
                console.warn('failed to authorize', e)
            }
        })()
    }

    if (!i18nInit) {
        ; (() => {
            i18n
                // load translation using xhr -> see /public/locales
                // learn more: https://github.com/i18next/i18next-xhr-backend
                .use(Backend)
                // detect user language
                // learn more: https://github.com/i18next/i18next-browser-languageDetector
                .use(LanguageDetector)
                // pass the i18n instance to react-i18next.
                .use(initReactI18next)
                // init i18next
                // for all options read: https://www.i18next.com/overview/configuration-options
                .init(i18nConfiguration, (r) => {
                    // TODO: read this from localStorage?
                    i18n.changeLanguage('en', () => {
                        dispatch({
                            type: 'i18nInit',
                            data: true,
                        })
                    })
                })
        })()
    }

    if ((!status && account !== '') || !i18nInit) {
        return (
            <ThemeProvider theme={theme}>
                <LinearProgress />
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Router>
                    <AppContext>
                        {status !== 'authorized' && <Nav />}
                        <Routes>
                            <Route exact path='/index.html' element={<Navigate to='/' />} />
                            <Route
                                exact
                                path='/'
                                element={<Card status={status} authorize={authorize} dispatch={dispatch} />}
                            />
                            <Route exact path='/logout' element={<Logout />} />
                            <Route exact path='/login' element={<Login status={status} authorize={authorize} />} />
                            <Route
                                exact
                                path='/selectrole'
                                element={<SelectRole status={status} authorize={authorize} />}
                            />
                            <Route exact path='/setup' element={<Setup status={status} authorize={authorize} />} />
                            <Route exact path='/simulator' element={<Simulators />} />
                            <Route path='/dashboard/*' element={<Pivot status={status} />} />
                            <Route path='/xview/*' element={<Pivot to={'xview'} status={status} />} />
                            <Route path='/xplayer/*' element={<Pivot to={'xplayer'} status={status} />} />
                            <Route path='/updates/*' element={<Pivot to={'updates'} status={status} />} />
                            <Route path='/licenses/*' element={<Pivot to={'licenses'} status={status} />} />
                            <Route path='/xtrend/*' element={<Pivot to={'xtrend'} status={status} />} />
                            <Route path='/xtable/*' element={<Pivot to={'xtable'} status={status} />} />
                            <Route path='/xuser/*' element={<Pivot to={'xuser'} status={status} />} />
                            <Route path='/xsignage/*' element={<Pivot to={'xsignage'} status={status} />} />
                            <Route path='/xmeter/*' element={<Pivot to={'xmeter'} status={status} />} />
                            <Route path='/xstadium/*' element={<Pivot to={'xstadium'} status={status} />} />
                            <Route path='/settings/*' element={<Pivot to={'settings'} status={status} />} />
                            <Route path='/posui/*' element={<Pivot to={'pos'} status={status} />} />
                            <Route path='*' element={<R404 />} />
                        </Routes>
                    </AppContext>
                </Router>
            </LocalizationProvider>
        </ThemeProvider>
    )
}

export default App
