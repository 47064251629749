import React from 'react'

import TreeView from './TreeView'

const TreeMenu = ({ zones, pits, tables, devices, setSelectedBranch }) => {

    const buildTreeNavData = () => {
        //build main data structure using flat object to hold references to branches on main
        const baseData = { index: 'root', type: 'Casino', containerIndex: 0 }
        const indexes = { 'root': baseData }

        const insertData = (v, type) => {

            const index = (v.index && v.index.length > 0) ? v.index : null
            const name = (v.data && v.data.name && v.data.name.length > 0) ? v.data.name : null
            const gameType = (type === "Device" && v.data && v.data.gameType) ? v.data.gameType : null
            const gameVariant = (type === "Device" && v.data && v.data.gameVariant) ? v.data.gameVariant : null
            const containerIndex = (v.data && v.data.containerIndex && v.data.containerIndex.length > 0) ? v.data.containerIndex : null

            if (name && index && containerIndex && typeof indexes[containerIndex] !== 'undefined') {
                indexes[containerIndex][name] = {}
                indexes[index] = indexes[containerIndex][name]
                indexes[index]['index'] = index
                indexes[index]['type'] = type
                indexes[index]['displayName'] = name
                indexes[index]['containerIndex'] = containerIndex
                indexes[index]['gameType'] = gameType
                indexes[index]['gameVariant'] = gameVariant
            }

        }

        zones.forEach(v => { insertData(v, 'Zone') })
        pits.forEach(v => { insertData(v, 'Pit') })
        tables.forEach(v => { insertData(v, 'Table') })
        devices.forEach(v => { insertData(v, 'Device') })
        return baseData
    }

    const treeNavData = buildTreeNavData()

    return <TreeView Data={treeNavData} Label={"Casino"} setSelectedBranch={setSelectedBranch} />
}

export default TreeMenu