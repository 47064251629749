import React, { useState } from 'react'

import { useSubscribe, usePublish } from '../api'
import Loader from '../Loader'
import BacGame from './BacGame'

const BacSimulator = ({ devices }) => {
    return (() =>
        devices.map((device) => (
            <SimulateBaccaratGame
                key={device.index}
                device={device}
                deviceID={device.index}
                gameVariant={device.data.gameVariant}
                name={device.data.name}
                ip={device.data.ip + ':' + (device.data.port ? device.data.port : '3099')}
            />
        )))()
}

const SimulateBaccaratGame = ({ device, deviceID, gameVariant, name, ip }) => {
    // socket
    const [game, socket] = useSubscribe(ip + '/game')
    const [games, gamesSocket] = useSubscribe(ip + '/games/*')
    const [time, timeSocket] = useSubscribe(ip)
    const [trend, trendSocket] = useSubscribe(ip + '/trend')
    const [statistics, statisticsSocket] = useSubscribe(ip + '/statistics')
    const [standardSummary, standardSummarySocket] = useSubscribe(ip + '/summary/baccarat/standard')
    const [lucky6Summary, lucky6SummarySocket] = useSubscribe(ip + '/summary/baccarat/lucky6')
    const [mega6Summary, mega6SummarySocket] = useSubscribe(ip + '/summary/baccarat/mega6')
    const [tigerSummary, tigerSummarySocket] = useSubscribe(ip + '/summary/baccarat/tiger')
    const [session, sessionSocket] = useSubscribe(ip + '/sessions/' + deviceID)
    const sessionStart = usePublish(ip + '/sessions/' + deviceID)
    const [loadingSession, setLoadingSession] = useState(false)
    const [doneTrigger, setDoneTrigger] = useState(false)
    const [done, setDone] = useState(false)
    const activeSessionSocket = sessionSocket && sessionSocket.readyState === WebSocket.OPEN
    const active =
        socket &&
        socket.readyState === WebSocket.OPEN &&
        gamesSocket &&
        gamesSocket.readyState === WebSocket.OPEN &&
        timeSocket &&
        timeSocket.readyState === WebSocket.OPEN &&
        trendSocket &&
        trendSocket.readyState === WebSocket.OPEN &&
        statisticsSocket &&
        statisticsSocket.readyState === WebSocket.OPEN &&
        standardSummarySocket &&
        standardSummarySocket.readyState === WebSocket.OPEN &&
        lucky6SummarySocket &&
        lucky6SummarySocket.readyState === WebSocket.OPEN &&
        mega6SummarySocket &&
        mega6SummarySocket.readyState === WebSocket.OPEN &&
        tigerSummarySocket &&
        tigerSummarySocket.readyState === WebSocket.OPEN &&
        activeSessionSocket

    if (session && !session.data && !loadingSession && activeSessionSocket) {
        ; (async () => {
            setLoadingSession(true)
            await sessionStart({
                account: 'root',
            })
            console.log('done')
            setLoadingSession(false)
        })()
    }

    if (!doneTrigger) {
        setDoneTrigger(true)
        setTimeout(() => setDone(true), 1000)
    }

    if (
        !done ||
        loadingSession ||
        !session ||
        !time ||
        !game ||
        !games ||
        !trend ||
        !statistics ||
        (gameVariant === 'standard' && !standardSummary) ||
        (gameVariant === 'lucky6' && !lucky6Summary) ||
        (gameVariant === 'mega6' && !mega6Summary) ||
        (gameVariant === 'tiger' && !tigerSummary) ||
        !active
    ) {
        return <Loader />
    }

    return (
        <BacGame
            time={time}
            device={device}
            deviceID={deviceID}
            gameVariant={gameVariant}
            name={name}
            ip={ip}
            session={session.data.account}
            sessionStart={sessionStart}
            games={games}
            game={game}
            trend={trend}
            statistics={statistics}
            standardSummary={standardSummary}
            lucky6Summary={lucky6Summary}
            mega6Summary={mega6Summary}
            tigerSummary={tigerSummary}
        />
    )
}

export default BacSimulator
