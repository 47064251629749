import { isNil, values } from "lodash";

const useBaccarat = () => {
  const getNumberOfCards = (cards) => {
    let result = 0;

    values(cards).forEach((card) => {
      if (card.suit && card.value) {
        result++;
      }
    });

    return result;
  };

  const getCardValue = (cards, cardKey) => {
    if (cards[cardKey].value > 9) return 0;
    return cards[cardKey].value;
  };

  const getCardStatus = (cards, cardKey) => {
    return cards[cardKey].show;
  };

  const isAnyCardRevealed = (cards, cardReveal) => {
    if (cardReveal === "immediate") return true;
    if (cardReveal === "timed" || cardReveal === "trigger") {
      const isExist = values(cards).find((card) => card.show === true);
      if (isExist) return true;
    }
    return false;
  };

  const showUpdatedPlayerPoint = (cards, cardReveal) => {
    const playerThirdCard = getCardValue(cards, "p3");
    const playerThirdCardDrawn = !isNil(playerThirdCard);
    const playerThirdCardRevealed = getCardStatus(cards, "p3");

    if (cardReveal === "immediate") return true;
    if (cardReveal === "timed" || cardReveal === "trigger") {
      if (playerThirdCardDrawn && playerThirdCardRevealed) return true;
    }
    return false;
  };

  const showUpdatedBankerPoint = (cards, cardReveal) => {
    const bankerThirdCard = getCardValue(cards, "b3");
    const bankerThirdCardDrawn = !isNil(bankerThirdCard);
    const bankerThirdCardRevealed = getCardStatus(cards, "b3");

    if (cardReveal === "immediate") return true;
    if (cardReveal === "timed" || cardReveal === "trigger") {
      if (bankerThirdCardDrawn && bankerThirdCardRevealed) return true;
    }
    return false;
  };

  const getValueHand = (cards, owner) => {
    const numberOfCards = getNumberOfCards(cards);
    if (owner === "player" && numberOfCards < 3) return;
    if (owner === "banker" && numberOfCards < 4) return;

    let cardKey;
    let result = 0;

    if (owner === "player") {
      cardKey = ["p1", "p2"];
    } else if (owner === "banker") {
      cardKey = ["b1", "b2"];
    }

    cardKey.forEach((cardKey) => {
      result += getCardValue(cards, cardKey);
    });

    if (result > 9) {
      result -= 10;
    }
    return result;
  };

  const getValuePlayersHand = (cards) => getValueHand(cards, "player");

  const getValueBankersHand = (cards) => getValueHand(cards, "banker");

  const showPlayerDrawIndicator = (cards, cardReveal) => {
    const playerThirdCard = getCardValue(cards, "p3");
    const playerThirdCardDrawn = !isNil(playerThirdCard);

    if (!playerThirdCardDrawn) {
      if (cardReveal === "immediate") return true;
      if (cardReveal === "timed" || cardReveal === "trigger") {
        if (isAnyCardRevealed(cards, cardReveal)) return true;
      }
    }
    return false;
  };

  const showBankerDrawIndicator = (cards, cardReveal) => {
    const bankerThirdCard = getCardValue(cards, "b3");
    const bankerThirdCardDrawn = !isNil(bankerThirdCard);
    const playerThirdCard = getCardValue(cards, "p3");
    const playerThirdCardDrawn = !isNil(playerThirdCard);
    const playerThirdCardRevealed = getCardStatus(cards, "p3");

    if (!bankerThirdCardDrawn) {
      if (cardReveal === "immediate") return true;
      if (cardReveal === "timed" || cardReveal === "trigger") {
        if (
          (isPlayerThirdCardNeeded(cards, cardReveal) === true &&
            playerThirdCardDrawn &&
            playerThirdCardRevealed) ||
          isPlayerThirdCardNeeded(cards, cardReveal) === false
        )
          return true;
      }
    }
    return false;
  };

  const isPlayerThirdCardNeeded = (cards, cardReveal) => {
    const valuePlayersHand = getValuePlayersHand(cards);
    const valueBankersHand = getValueBankersHand(cards);

    if (isNil(valuePlayersHand) || !isAnyCardRevealed(cards, cardReveal))
      return;

    if (
      [0, 1, 2, 3, 4, 5].includes(valuePlayersHand) &&
      ![8, 9].includes(valueBankersHand)
    )
      return true;
    return false;
  };

  const isBankerThirdCardNeeded = (cards, cardReveal) => {
    const valuePlayersHand = getValuePlayersHand(cards);
    const valueBankersHand = getValueBankersHand(cards);
    const playerThirdCard = getCardValue(cards, "p3");
    const playerThirdCardDrawn = !isNil(playerThirdCard);

    if ([8, 9].includes(valuePlayersHand)) {
      return false;
    }
    // Player stand
    else if (isPlayerThirdCardNeeded(cards, cardReveal) === false) {
      if ([0, 1, 2, 3, 4, 5].includes(valueBankersHand)) {
        return true;
      }
      if ([6, 7, 8, 9].includes(valueBankersHand)) {
        return false;
      }
      // Player draw
    } else if (
      isPlayerThirdCardNeeded(cards, cardReveal) === true &&
      playerThirdCardDrawn
    ) {
      if ([0, 1, 2].includes(valueBankersHand)) {
        return true;
      }

      if (valueBankersHand === 3) {
        if (playerThirdCard !== 8) return true;
        return false;
      }

      if (valueBankersHand === 4) {
        if ([2, 3, 4, 5, 6, 7].includes(playerThirdCard)) return true;
        return false;
      }

      if (valueBankersHand === 5) {
        if ([4, 5, 6, 7].includes(playerThirdCard)) return true;
        return false;
      }

      if (valueBankersHand === 6) {
        if ([6, 7].includes(playerThirdCard)) return true;
        return false;
      }

      if (valueBankersHand === 7) {
        return false;
      }
    }
  };

  const getTally = (cards, owner) => {
    let cardKey;
    let result = 0;

    if (owner === "player") {
      cardKey = ["p1", "p2", "p3"];
    } else if (owner === "banker") {
      cardKey = ["b1", "b2", "b3"];
    }

    cardKey.forEach((cardKey) => {
      result += getCardValue(cards, cardKey);
    });

    if (result > 9) {
      result -= 10;
    }

    return result;
  };

  const getPlayerTally = (cards) => getTally(cards, "player");

  const getBankerTally = (cards) => getTally(cards, "banker");

  const gameOver = (cards) => {
    const numberOfCards = getNumberOfCards(cards);
    const playerThirdCard = getCardValue(cards, "p3");
    const bankerThirdCard = getCardValue(cards, "b3");
    const playerTally = getPlayerTally(cards);
    const bankerTally = getBankerTally(cards);

    if (numberOfCards === 6) {
      return true;
    }

    if (
      numberOfCards === 4 &&
      (playerTally > 7 ||
        bankerTally > 7 ||
        ([6, 7].includes(playerTally) && [6, 7].includes(bankerTally)))
    ) {
      return true;
    }

    if (numberOfCards === 5 && !isNil(bankerThirdCard)) {
      return true;
    }

    if (
      numberOfCards === 5 &&
      bankerTally > 2 &&
      (bankerTally > 6 ||
        (bankerTally === 3 && playerThirdCard === 8) ||
        (bankerTally === 4 && ![2, 3, 4, 5, 6, 7].includes(playerThirdCard)) ||
        (bankerTally === 5 && ![4, 5, 6, 7].includes(playerThirdCard)) ||
        (bankerTally === 6 && ![6, 7].includes(playerThirdCard)))
    ) {
      return true;
    }

    return false;
  };

  return {
    getNumberOfCards,
    getCardValue,
    getCardStatus,
    isAnyCardRevealed,
    showUpdatedPlayerPoint,
    showUpdatedBankerPoint,
    getValuePlayersHand,
    getValueBankersHand,
    showPlayerDrawIndicator,
    showBankerDrawIndicator,
    isPlayerThirdCardNeeded,
    isBankerThirdCardNeeded,
    getPlayerTally,
    getBankerTally,
    gameOver,
  };
};

export default useBaccarat;
