import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import { InputLabel, LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

const rootStyles = makeStyles((theme) => ({
    form: {
        width: 700,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
}))

const NDI = ({ publish, device, streams, xstadium }) => {
    const styles = rootStyles({ xstadium })

    const navigate = useNavigate()

    // fields
    const [trendboardA, setTrendboardA] = useState('')
    const [trendboardB, setTrendboardB] = useState('')
    const [trendboardC, setTrendboardC] = useState('')
    const [trendboardD, setTrendboardD] = useState('')
    const [trendboardE, setTrendboardE] = useState('')
    const [trendboardF, setTrendboardF] = useState('')
    const [trendboardG, setTrendboardG] = useState('')
    const [trendboardH, setTrendboardH] = useState('')
    const [liveA, setLiveA] = useState('')
    const [liveB, setLiveB] = useState('')
    const [liveC, setLiveC] = useState('')
    const [liveD, setLiveD] = useState('')
    const [liveE, setLiveE] = useState('')
    const [liveF, setLiveF] = useState('')
    const [liveG, setLiveG] = useState('')
    const [liveH, setLiveH] = useState('')

    // flags
    const [loading, setLoading] = useState(false)
    const [fail, setFail] = useState(false)
    const [fetched, setFetched] = useState(false)

    const send = async () => {
        setLoading(true)
        try {
            await publish({
                ...device.data,
                trendboardA,
                trendboardB,
                trendboardC,
                trendboardD,
                trendboardE,
                trendboardF,
                trendboardG,
                trendboardH,
                liveA,
                liveB,
                liveC,
                liveD,
                liveE,
                liveF,
                liveG,
                liveH,
            })
            setFail('')
            setLoading(false)
        } catch (e) {
            setFail(
                `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            setLoading(false)
        }
    }

    const fillFields = () => {
        if (fetched) {
            return
        }
        setFetched(true)
        setTrendboardA(device.data.trendboardA)
        setTrendboardB(device.data.trendboardB)
        setTrendboardC(device.data.trendboardC)
        setTrendboardD(device.data.trendboardD)
        setTrendboardE(device.data.trendboardE)
        setTrendboardF(device.data.trendboardF)
        setTrendboardG(device.data.trendboardG)
        setTrendboardH(device.data.trendboardH)
        setLiveA(device.data.liveA)
        setLiveB(device.data.liveB)
        setLiveC(device.data.liveC)
        setLiveD(device.data.liveD)
        setLiveE(device.data.liveE)
        setLiveF(device.data.liveF)
        setLiveG(device.data.liveG)
        setLiveH(device.data.liveH)
    }

    if (!fetched) {
        fillFields()
    }

    if (!streams) {
        return <form
            className={styles.form}
            noValidate
            onSubmit={(e) => {
                e.preventDefault()
            }}
            autoComplete='off'
        >
            <Typography component='p' className={styles.warningText}>Loading NDI streams</Typography>
            <LinearProgress />
        </form>
    }

    return (
        <form
            className={styles.form}
            noValidate
            onSubmit={(e) => {
                e.preventDefault()
            }}
            autoComplete='off'
        >
            {fail && !loading && (
                <Box className={styles.warningContainer}>
                    <SnackbarContent
                        className={styles.fail}
                        message={
                            <Typography component='p' className={styles.warningText}>
                                <WarningIcon className={styles.warningIcon} /> {fail}
                            </Typography>
                        }
                    />
                </Box>
            )}

            <>
                <InputLabel htmlFor='trendboardA'>Trendboard A</InputLabel>
                <Select
                    label='Trendboard A'
                    id='trendboardA'
                    inputProps={{
                        name: 'trendboardA',
                        id: 'trendboardA',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setTrendboardA(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select trendboard A NDI')}
                    value={trendboardA}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>

            <>
                <InputLabel htmlFor='trendboardB'>Trendboard B</InputLabel>
                <Select
                    label='Trendboard B'
                    id='trendboardB'
                    inputProps={{
                        name: 'trendboardB',
                        id: 'trendboardB',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setTrendboardB(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select trendboard B NDI')}
                    value={trendboardB}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='trendboardC'>Trendboard C</InputLabel>
                <Select
                    id='trendboardC'
                    inputProps={{
                        name: 'trendboardC',
                        id: 'trendboardC',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setTrendboardC(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select trendboard C NDI')}
                    value={trendboardC}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='trendboardD'>Trendboard D</InputLabel>
                <Select
                    id='trendboardD'
                    inputProps={{
                        name: 'trendboardD',
                        id: 'trendboardD',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setTrendboardD(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select trendboard D NDI')}
                    value={trendboardD}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='trendboardE'>Trendboard E</InputLabel>
                <Select
                    id='trendboardE'
                    inputProps={{
                        name: 'trendboardE',
                        id: 'trendboardE',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setTrendboardE(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select trendboard E NDI')}
                    value={trendboardE}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='trendboardF'>Trendboard F</InputLabel>
                <Select
                    id='trendboardF'
                    inputProps={{
                        name: 'trendboardF',
                        id: 'trendboardF',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setTrendboardF(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select trendboard F NDI')}
                    value={trendboardF}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>

            <>
                <InputLabel htmlFor='trendboardG'>Trendboard G</InputLabel>
                <Select
                    id='trendboardG'
                    inputProps={{
                        name: 'trendboardG',
                        id: 'trendboardG',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setTrendboardF(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select trendboard F NDI')}
                    value={trendboardG}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>

            <>
                <InputLabel htmlFor='trendboardH'>Trendboard H</InputLabel>
                <Select
                    id='trendboardH'
                    inputProps={{
                        name: 'trendboardH',
                        id: 'trendboardH',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setTrendboardH(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select trendboard F NDI')}
                    value={trendboardH}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>

            <>
                <InputLabel htmlFor='liveA'>Live A</InputLabel>
                <Select
                    id='liveA'
                    inputProps={{
                        name: 'liveA',
                        id: 'liveA',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setLiveA(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select live A NDI')}
                    value={liveA}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='liveB'>Live B</InputLabel>
                <Select
                    id='liveB'
                    inputProps={{
                        name: 'liveB',
                        id: 'liveB',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setLiveB(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select live B NDI')}
                    value={liveB}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='liveC'>Live C</InputLabel>
                <Select
                    id='liveC'
                    inputProps={{
                        name: 'liveC',
                        id: 'liveC',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setLiveC(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select live C NDI')}
                    value={liveC}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='liveD'>Live D</InputLabel>
                <Select
                    id='liveD'
                    inputProps={{
                        name: 'liveD',
                        id: 'liveD',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setLiveD(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select live D NDI')}
                    value={liveD}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='liveE'>Live E</InputLabel>
                <Select
                    id='liveE'
                    inputProps={{
                        name: 'liveE',
                        id: 'liveE',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setLiveE(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select live E NDI')}
                    value={liveE}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>


            <>
                <InputLabel htmlFor='liveF'>Live F</InputLabel>
                <Select
                    id='liveF'
                    inputProps={{
                        name: 'liveF',
                        id: 'liveF',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setLiveF(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select live F NDI')}
                    value={liveF}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>

            <>
                <InputLabel htmlFor='liveG'>Live G</InputLabel>
                <Select
                    id='liveG'
                    inputProps={{
                        name: 'liveG',
                        id: 'liveG',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setLiveF(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select live F NDI')}
                    value={liveF}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>

            <>
                <InputLabel htmlFor='liveH'>Live H</InputLabel>
                <Select
                    id='liveH'
                    inputProps={{
                        name: 'liveH',
                        id: 'liveH',
                    }}
                    required
                    fullWidth
                    onChange={(e) => {
                        setLiveF(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(value) => (value !== '' ? value : '...Select live F NDI')}
                    value={liveH}
                    disabled={false}
                    size='small'
                >
                    {streams.sources.map((item, index) => {
                        return (
                            <MenuItem key={'select-ndi' + index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>
            </>

            <div className={styles.formButtonWrapper}>
                <Button
                    color='inherit'
                    disabled={loading}
                    disableRipple
                    onClick={() => {
                        navigate(
                            !xstadium
                                ? device.data.containerType === 'casino'
                                    ? `/xtrend/${device.data.containerType}`
                                    : `/xtrend/${device.data.containerType}/${device.data.containerIndex}`
                                : device.data.type === 'game'
                                    ? '/xstadium/tables'
                                    : '/xstadium/servers'
                        )
                    }}
                >
                    Back
                </Button>

                {streams && (
                    <Button variant='contained' type='button' color='primary' disabled={loading} onClick={send}>
                        Update
                    </Button>
                )}
            </div>
        </form>
    )
}

export default NDI
