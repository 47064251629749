import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

// dialog
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

// grid
import { DataGrid } from '@mui/x-data-grid'
import { formatCashString } from '../../xtable/Formats'
import CircleIcon from '@mui/icons-material/Circle'
import { startCase } from 'lodash'
import moment from 'moment'


import { Typography } from '@mui/material'
import { publish } from '../../api'

const Apply = ({ open, handleClose, devices, template }) => {
    const [applying, setApplying] = useState(false)
    const [selectedDevices, setSelectedDevices] = useState([])
    const [confirmingApply, setConfirmingApply] = useState(false)

    const closeDialog = () => {
        handleClose()
    }
    const applyDisabled = () => {
        return applying || selectedDevices.length === 0
    }
    const _merge = (device, template) => {
        return {
            ...template,
            containerIndex: device.containerIndex,
            containerType: device.containerType,
            ip: device.ip,
            port: device.port,
            open: device.open,
            name: device.name,
            streamOutputName: device.streamOutputName,
            marketingStream: device.marketingStream,
            stream: device.stream,
            tableNum: device.tableNum,
            updatedBy: window.localStorage.getItem('account') ?? '-',
        }
    }
    const _apply = async (id, template) => {
        const found = devices.filter(d => d.index === id)
        if (found.length === 0) {
            return new Promise((_, reject) => setTimeout(() => reject(id), 1200))
        }
        const device = found[0]
        // console.log('_apply', id, _merge(device.data, template.data))
        return publish('devices/' + id, _merge(device.data, template.data))
    }
    const _applyAll = async (ids, template) => {
        return Promise.all(ids.map(id => _apply(id, template)))
    }
    const apply = async () => {
        console.log('applying', template, selectedDevices)
        setApplying(true)
        setConfirmingApply(false)
        try {
            await _applyAll(selectedDevices, template)
            closeDialog()
        } catch (e) {
            console.warn('failed to apply', e)
        } finally {
            setTimeout(() => {
                setApplying(false)
            }, 400)
        }
    }
    const confirmApply = () => {
        setConfirmingApply(true)
    }
    const backConfirm = () => {
        setConfirmingApply(false)
    }

    const dateDisplay = (time) => moment.unix(time / 1000000000).format('YYYY-MM-DD')

    const haveOverrideSelected = (selected) => {
        for (const deviceID of selected) {
            const found = devices.filter(d => d.index === deviceID)
            if (found.length > 0 &&
                (found[0].data.gameType !== template.data.gameType || found[0].data.gameVariant !== template.data.gameVariant)) {
                return true
            }
        }

        return false
    }

    const rows = devices
        ? devices
            .filter((device) => device.data.type === 'game')
            // .filter((template) => JSON.stringify(template).indexOf(searchTerm) >= 0)
            .map((device) => {
                return {
                    id: device.index ? device.index : '-',
                    name: device.data.tableNum ? device.data.tableNum : '-',
                    gameType: device.data.gameType ? device.data.gameType : '-',
                    gameVariant: device.data.gameVariant ? device.data.gameVariant : '-',
                    minbet: device.data.bets
                        ? device.data.bets[device.data.gameType][device.data.gameVariant].minBet
                        : '-',
                    minBetColor: device.data.minBetColor ? device.data.minBetColor : '-',
                    maxbet: device.data.bets
                        ? device.data.bets[device.data.gameType][device.data.gameVariant].maxBet
                        : '-',
                    trendboardLayout: device.data.layout ? device.data.layout : '-',
                    template: device.data.templateName ? device.data.templateName : '-',
                    lastUpdatedOn: device.updated ? device.updated : device.created,
                    lastUpdatedBy: device.data.updatedBy ? device.data.updatedBy : '-',
                }
            })
        : []

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{params.value}</>}</>
            },
            width: 115,
        },
        {
            field: 'gameType',
            headerName: 'Game Type',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{startCase(params.value)}</>}</>
            },
            width: 115,
        },
        {
            field: 'gameVariant',
            headerName: 'Game Variant',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{startCase(params.value)}</>}</>
            },
            width: 115,
        },
        {
            field: 'minbet',
            headerName: 'Min Bet',
            renderCell: (params) => {
                return (
                    <Box display='flex' alignItems='center' gap='.5rem'>
                        {params.row.minBetColor && <CircleIcon sx={{ color: params.row.minBetColor }} />}
                        <>{formatCashString(params.value)}</>
                    </Box>
                )
            },
            width: 180,
        },
        {
            field: 'maxbet',
            headerName: 'Max Bet',
            renderCell: (params) => {
                return <>{formatCashString(params.value)}</>
            },
            width: 180,
        },
        {
            field: 'trendboardLayout',
            headerName: 'Trendboard Layout',
            width: 150,
        },
        {
            field: 'template',
            headerName: 'Template',
            width: 150,
        },
        {
            field: 'lastUpdatedOn',
            headerName: 'Last Updated On',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{dateDisplay(params.value)}</>}</>
            },
            width: 145,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 145,
        },
        // {
        //     field: 'apply',
        //     headerName: 'Apply',
        //     sortable: false,
        //     filterable: false,
        //     renderCell: (params) => {
        //         return (
        //             <IconButton onClick={() => selectApplyTemplate(params)}>
        //                 <ShareIcon />
        //             </IconButton>
        //         )
        //     },
        // },
    ]

    return <Dialog
        open={open}
        maxWidth={"900"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle
            id='form-dialog-title'
            sx={{
                pb: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
                marginBottom: '1em',
                marginTop: '1em'
            }}
        >
            <Box display='flex' alignItems='center' justifyContent='space-between'>
                {!applying && <Typography>Apply <b>{template?.data?.templateName}</b></Typography>}
                {(!applying && !confirmingApply) &&
                    <Typography>Devices highlighted <CircleIcon sx={{ color: '#6b2606' }} /> are of a different type than the template</Typography>}
            </Box>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
            {(!applying && !confirmingApply) && <Box>
                <DataGrid
                    sx={{
                        colorScheme: 'dark',
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                            outline: 'none',
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-row.popo.Mui-selected': {
                            background: '#6b2606'
                        }
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    gridRowId={(row) => row.index}
                    checkboxSelection
                    onRowSelectionModelChange={(id) => {
                        setSelectedDevices(id)
                    }}
                    getRowClassName={(params) => {
                        if (params.row.gameType !== template.data.gameType || params.row.gameVariant !== template.data.gameVariant) {
                            return "popo"
                        }
                    }}
                />
            </Box>}
            {(confirmingApply && !applying) && <Box>
                {haveOverrideSelected(selectedDevices) && <Typography>You have selected devices with a different type/variant than the template, do you want to proceeed applying the teplate?</Typography>}
                {!haveOverrideSelected(selectedDevices) && <Typography>Do you want to proceeed applying the teplate?</Typography>}
            </Box>}
            {applying && <Box sx={{ display: 'flex', width: '600px', flexDirection: 'column' }}>
                <Typography sx={{ marginBottom: '10px' }}>Applying template on <b>{selectedDevices.length} devices</b></Typography>
                <LinearProgress />
            </Box>}
        </DialogContent>
        {!applying && <DialogActions>
            {!confirmingApply && <Button color='inherit' onClick={closeDialog}>
                Cancel
            </Button>}
            {confirmingApply && <Button color='inherit' onClick={backConfirm}>
                Back
            </Button>}
            {!confirmingApply && <Button
                color='primary'
                disabled={applyDisabled()}
                onClick={confirmApply}
            >
                Apply
            </Button>}
            {confirmingApply && <Button
                color='primary'
                onClick={apply}
            >
                Apply
            </Button>}
        </DialogActions>}
    </Dialog>
}

export default Apply 