import React, { memo, forwardRef, useState } from 'react'
import { Users, Video } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { DeveloperBoard, StackedBarChart, Segment, Stadium, Settings } from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/styles'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const CustomTooltipWrapper = ({ condition, wrapper, children }) => (condition ? children : wrapper(children))

export default memo(({ open, setOpen }) => {
    const activeStyle = {
        background: 'rgb(88, 88, 88)',
    }

    const NavLinkRef = forwardRef((props, ref) => <NavLink {...props} ref={ref} />)

    // handle propagation
    // from the click event on the hamburger triggering the click on the chevron
    // not good but idk wtf
    const [openDoneStarted, setOpenDoneStarted] = useState(false)
    const [openDone, setOpenDone] = useState(false)

    if (open && !openDone && !openDoneStarted) {
        setOpenDoneStarted(true)
        setTimeout(() => {
            if (open) {
                setOpenDone(true)
            }
        }, 400)
    }

    if (!open && openDone) {
        setOpenDoneStarted(false)
        setOpenDone(false)
    }

    const role = window.localStorage.getItem('role')
    const products = window.localStorage.getItem('products')

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (openDone) {
                    setOpen(false)
                }
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box>
                    <DrawerHeader style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton
                            onClick={() => {
                                if (openDone) {
                                    setOpen(false)
                                }
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>

                    <Divider />

                    <List style={{ padding: 0 }}>
                        {(role === 'root' || products.includes('xTrend')) && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='XTREND' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtrend/home',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>{<DeveloperBoard />}</ListItemIcon>
                                        <ListItemText primary={'XTREND'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {(role === 'root' || products.includes('xTable')) && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='XTABLE' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xtable/overview',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>{<StackedBarChart />}</ListItemIcon>
                                        <ListItemText primary={'XTABLE'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='XUSER' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xuser/home',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>{<Users />}</ListItemIcon>
                                <ListItemText primary={'XUSER'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        {(role === 'root' || products.includes('xSignage')) && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='XSIGNAGE' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xsignage/home',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>{<Video />}</ListItemIcon>
                                        <ListItemText primary={'XSIGNAGE'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {(role === 'root' || products.includes('xMeter')) && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='XMETER' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xmeter/home',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>{<Segment />}</ListItemIcon>
                                        <ListItemText primary={'XMETER'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {(role === 'root' || products.includes('xStadium')) && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='XSTADIUM' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xstadium/liveplay/animation',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>{<Stadium />}</ListItemIcon>
                                        <ListItemText primary={'XSTADIUM'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        {(role === 'root' || role === 'masterAdmin') && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='SETTINGS' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/settings',
                                            style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                        }}
                                    >
                                        <ListItemIcon>{<Settings />}</ListItemIcon>
                                        <ListItemText primary={'SETTINGS'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}
                    </List>
                </Box>
            </Box>
        </ClickAwayListener>
    )
})
