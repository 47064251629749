import React, { Fragment, useContext } from "react";
import { TextField, Modal, Fade } from "@mui/material";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { DUIContext } from "./Dealer";

const DealerModal = () => {
  const {
    deviceData,
    game,
    hasGameState,
    logoutDealer,
    modalConfirm,
    modalConfirmPress,
    modalDescript,
    modalHeader,
    modalOpen,
    pitbossAccount,
    pitbossPassword,
    s,
    setModalOpen,
    setPitbossAccount,
    setPitbossPassword,
    setSwipeCard,
    setUseSwipeAuth,
    swipeCard,
    t,
    useSwipeAuth,
  } = useContext(DUIContext);

  return (
    <Modal
      className={s.modalContainer}
      open={modalOpen}
      onClose={(e) => {
        setModalOpen(false);
      }}
      style={{
        backgroundColor: !deviceData.open ? "#ccc" : "",
        ...(deviceData.doubleConfirmation &&
          (modalConfirm === "void" || modalConfirm === "edit") && {
            top: "-25vh",
          }),
      }}
    >
      <Fade in={modalOpen}>
        <div className={s.modal}>
          <div className={s.modalHeader}>
            <h1> {modalHeader} </h1>
          </div>
          {(!deviceData.doubleConfirmation ||
            (modalConfirm !== "void" && modalConfirm !== "edit")) && (
            <div className={s.modalPrompt}>
              {deviceData.open && modalConfirm !== "burnMode" && (
                <Fragment>
                  <h2 style={{ fontWeight: 200 }}> {modalDescript} </h2>
                  <div className={s.modalButtonRow}>
                    <button
                      onClick={(e) => {
                        setModalOpen(false);
                      }}
                    >
                      {" "}
                      {t("global.cancel")}{" "}
                    </button>
                    <button
                      onClick={(e) => {
                        modalConfirmPress(modalConfirm);
                      }}
                    >
                      {" "}
                      {t("global.confirm")}{" "}
                    </button>
                  </div>
                </Fragment>
              )}

              {deviceData.open && modalConfirm === "burnMode" && (
                <div className={s.modalButtonRow}>
                  <div>
                    <h2
                      style={{
                        fontWeight: 200,
                        padding: "2vh",
                        paddingBottom: 0,
                      }}
                    >
                      {modalDescript}
                    </h2>
                    <h2>
                      {hasGameState && game.data.burning > 0 && (
                        <span>{game.data.burning}</span>
                      )}
                    </h2>
                  </div>
                </div>
              )}

              {!deviceData.open && modalConfirm === "deviceOn" && (
                <Fragment>
                  <h2 style={{ fontWeight: 200 }}> {modalDescript} </h2>
                  <div className={s.modalButtonRow}>
                    <button
                      onClick={(e) => {
                        logoutDealer();
                      }}
                    >
                      {" "}
                      {t("dealer.logOut")}{" "}
                    </button>
                    <button
                      onClick={(e) => {
                        modalConfirmPress(modalConfirm);
                      }}
                    >
                      {" "}
                      {t("dealer.open")}{" "}
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          )}

          {deviceData.doubleConfirmation &&
            (modalConfirm === "void" ||
              modalConfirm === "voidLast" ||
              modalConfirm === "edit") && (
              <div className={s.modalPrompt}>
                <h2 style={{ fontWeight: 200 }}>
                  {modalConfirm === "edit" &&
                    t("dealer.editCardsDoubleConfirmation")}
                  {modalConfirm === "void" &&
                    t("dealer.voidGameDoubleConfirmation")}
                  {modalConfirm === "voidLast" &&
                    t("dealer.voidLastGameDoubleConfirmation")}
                  <br /> {t("dealer.pleaseSwipe")}{" "}
                </h2>
                <div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      modalConfirmPress(modalConfirm);
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {useSwipeAuth && (
                        <>
                          <TextField
                            className={s.modalInput}
                            required
                            autoFocus
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            id="card"
                            label="Card"
                            type="password"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setSwipeCard(e.target.value)}
                            value={swipeCard}
                            error={swipeCard === ""}
                          />
                          <KeyboardIcon
                            onClick={() => setUseSwipeAuth(false)}
                            style={{ fontSize: "5vh", marginLeft: 10 }}
                          />
                        </>
                      )}

                      {!useSwipeAuth && (
                        <>
                          <div style={{ margin: 0, padding: 0 }}>
                            <TextField
                              className={s.modalInput}
                              required
                              autoFocus
                              InputLabelProps={{ shrink: true }}
                              margin="dense"
                              id="user"
                              label="Account"
                              type="text"
                              fullWidth
                              onChange={(e) =>
                                setPitbossAccount(e.target.value)
                              }
                              value={pitbossAccount}
                              error={pitbossAccount === ""}
                              variant="outlined"
                            />
                            <TextField
                              className={s.modalInput}
                              required
                              InputLabelProps={{ shrink: true }}
                              margin="dense"
                              id="pass"
                              label="Password"
                              type="password"
                              onChange={(e) =>
                                setPitbossPassword(e.target.value)
                              }
                              value={pitbossPassword}
                              error={pitbossPassword === ""}
                              fullWidth
                              variant="outlined"
                            />
                          </div>
                          <CreditCardIcon
                            onClick={() => setUseSwipeAuth(true)}
                            style={{ fontSize: "5vh", marginLeft: 10 }}
                          />
                        </>
                      )}
                    </div>
                  </form>
                  <div className={s.modalButtonRow}>
                    <button
                      onClick={(e) => {
                        setModalOpen(false);
                      }}
                    >
                      {" "}
                      {t("global.cancel")}{" "}
                    </button>
                    <button
                      onClick={(e) => {
                        modalConfirmPress(modalConfirm);
                      }}
                    >
                      {" "}
                      {t("global.confirm")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
      </Fade>
    </Modal>
  );
};

export default DealerModal;
