import React, { useState, useContext } from "react"
import { get } from "lodash"
import { Base64 } from "js-base64"
import converter from "hex2dec"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { terminalServerIP, ticketsIP } from "../../config"
import { formatCashString } from "../util/misc"
import { publish, fetch } from "../../api"
import { DUIContext } from "./Dealer"
import PitBossConfirmation from "./PitBossConfirmation"
import DealerConfirmation from "./DealerConfirmation"

const VoucherTopUp = () => {
    const {
        capturedString,
        device,
        getBalance,
        authorize,
        setShowVoucherTopUp,
        selectedSeatID,
        setCapturedString,
    } = useContext(DUIContext);

    const [modalObj, setModalObj] = useState({
        show: false,
        modalMsg: "",
        requirePitBoss: false,
        confirm: () => { },
    });
    const [ticketIsUsed, setTicketIsUsed] = useState(false);
    const [ticketIsInvalid, setTicketIsInvalid] = useState(false);
    const [lastCapturedString, setLastCapturedString] = useState(null);

    const seatID = selectedSeatID;

    const getTicket = async (voucherOutput) => {
        console.log("getTicket:1 voucherOutput", voucherOutput);
        const hex = converter.decToHex(voucherOutput, { prefix: false });
        console.log("getTicket:3a hex", hex);
        const voucherID = hex ? hex : voucherOutput;
        console.log("getTicket:3b voucherID", voucherID);
        try {
            const resCredits = await fetch(
                ticketsIP + "/tickets/" + voucherID,
                authorize
            );
            const creditObj = JSON.parse(Base64.decode(resCredits.data));
            console.log("getTicket:4a creditObj", creditObj);
            const voucherValue = Number(get(creditObj, "value", 0));
            console.log("getTicket:4b voucherValue", voucherValue);
            setModalObj({
                show: true,
                modalMsg:
                    "Seat: " +
                    (Number(seatID) + 1) +
                    ". Add Credit: $" +
                    formatCashString(voucherValue) +
                    ". Type: Voucher",
                requirePitBoss:
                    get(device, "data.buyInConfirmation", false) &&
                        voucherValue >= get(device, "data.buyInThreshold", 0)
                        ? true
                        : false,
                confirm: async () => {
                    setLastCapturedString(null);
                    setCapturedString(null);
                    try {
                        const redeemedCredits = await fetch(
                            ticketsIP + "/ticket/redeem/" + voucherID,
                            authorize
                        );
                        const redeemedValue = get(redeemedCredits, "value", 0);
                        console.log("getTicket:5a redeemedCredits", redeemedCredits);
                        console.log("getTicket:5b redeemedValue", redeemedValue);
                        publish(
                            terminalServerIP + "/seat/credits/" + device.index + "/" + seatID,
                            {
                                balance: getBalance(seatID) + redeemedValue,
                            },
                            authorize
                        );
                        console.log("getTicket:5c balace credited");
                        setShowVoucherTopUp(false);
                        console.log("getTicket:6 modal confirm ", "done");
                    } catch (e) {
                        if (e && e.response && e.response.status === 400) {
                            console.log(
                                "getTicket:7 modal confirm ",
                                "fail - ticket already used"
                            );
                            setTicketIsUsed(true);
                            setTimeout(() => {
                                setTicketIsUsed(false);
                            }, 10000);
                        } else {
                            console.warn(e);
                            console.log("getTicket:8 modal confirm ", "fail", e);
                        }
                    }
                },
                cancel: () => {
                    setLastCapturedString(null);
                    setCapturedString(null);
                },
            });
        } catch (e) {
            if (e && e.response && e.response.status === 404) {
                console.log("getTicket: ", "fail - invalid ticket");
                setTicketIsInvalid(true);
                setTimeout(() => {
                    setTicketIsInvalid(false);
                }, 6000);
            } else {
                console.warn(e);
                console.log("getTicket: ", "fail", e);
            }
        }
    };

    const voucherCapture = get(capturedString, "voucherCapture", null);
    if (
        capturedString &&
        voucherCapture &&
        voucherCapture !== lastCapturedString &&
        !modalObj.show
    ) {
        setLastCapturedString(voucherCapture);
        getTicket(voucherCapture);
    }

    return (
        <div
            style={{
                position: "relative",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: "0.9",
                backgroundColor: "#000",
            }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div
                style={{
                    height: "30vh",
                    width: "60vh",
                    background: "#333",
                    color: "#ccc",
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                    position: "relative",
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <div
                    onClick={() => setShowVoucherTopUp(false)}
                    className="modalOffTigger"
                    style={{
                        position: "absolute",
                        top: -50,
                        right: -50,
                        height: 80,
                        width: 80,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                >
                    <HighlightOffIcon style={{ fontSize: 50, color: "white" }} />
                </div>

                <div
                    style={{
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "2.8vh",
                        fontWeight: 700,
                        marginTop: "2vh",
                    }}
                >
                    Scan Ticket Or Voucher
                </div>
                <div
                    style={{
                        height: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "2.4vh",
                    }}
                >
                    {!ticketIsUsed && !ticketIsInvalid && (
                        <div>Scan Ticket Or Voucher to add credit</div>
                    )}
                    {ticketIsUsed && (
                        <div>
                            <span style={{ color: "red" }}>
                                Ticket has already been used.
                            </span>{" "}
                            Please scan another
                        </div>
                    )}
                    {ticketIsInvalid && (
                        <div>
                            <span style={{ color: "red" }}>Ticket is not valid.</span> Please
                            scan another
                        </div>
                    )}
                    {/* <form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); getTicket(get(e.currentTarget['voucherInput'], 'value', null)); e.currentTarget['voucherInput'].value = '' }}>
                    <input name="voucherInput" type="text" autoFocus={true} autoComplete="off" style={{ position: 'absolute', inset: 0, height: '100%', width: '100%', opacity: '0' }} />
                </form> */}
                </div>
            </div>

            {modalObj.show && modalObj.requirePitBoss && (
                <PitBossConfirmation modalObj={modalObj} setModalObj={setModalObj} />
            )}
            {modalObj.show && !modalObj.requirePitBoss && (
                <DealerConfirmation modalObj={modalObj} setModalObj={setModalObj} />
            )}
        </div>
    );
};

export default VoucherTopUp;
