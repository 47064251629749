import React, { useState } from 'react'
import { Card } from 'reactstrap'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { formatCashString, formatCountString } from '../Formats'

const DataRow = ({ text, value, bold }) => {
    return (
        <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography color='text.secondary' fontWeight={bold && 'bold'}>
                {text}:
            </Typography>
            <Typography color='text.secondary' fontWeight={bold && 'bold'}>
                {formatCashString(value)}
            </Typography>
        </Box>
    )
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#5E1789',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#9E00FF',
    },
}))

const Targets = ({ title, data }) => {
    const [timePeriod, setTimeoutPeriod] = useState(1)

    const handleChange = (event) => {
        setTimeoutPeriod(event.target.value)
    }

    // const getTargetPerDay = (target) => {
    //     let targetPerDay
    //     if (timePeriod === 1) {
    //         targetPerDay = target / 7
    //     } else if (timePeriod === 2) {
    //         targetPerDay = target / 30
    //     } else if (timePeriod === 3) {
    //         targetPerDay = target / 90
    //     } else if (timePeriod === 4) {
    //         targetPerDay = target / 365
    //     }
    //     return targetPerDay
    // }

    const actual =
        timePeriod === 1
            ? data.weekly.actual
            : timePeriod === 2
            ? data.monthly.actual
            : timePeriod === 3
            ? data.quarterly.actual
            : timePeriod === 4
            ? data.yearly.actual
            : 0

    const target =
        timePeriod === 1
            ? data.weekly.target
            : timePeriod === 2
            ? data.monthly.target
            : timePeriod === 3
            ? data.quarterly.target
            : timePeriod === 4
            ? data.yearly.target
            : 0

    const targetUntilTdy =
        timePeriod === 1
            ? data.weekly.targetUntilTdy
            : timePeriod === 2
            ? data.monthly.targetUntilTdy
            : timePeriod === 3
            ? data.quarterly.targetUntilTdy
            : timePeriod === 4
            ? data.yearly.targetUntilTdy
            : 0

    const projectedValue =
        timePeriod === 1
            ? data.weekly.projected
            : timePeriod === 2
            ? data.monthly.projected
            : timePeriod === 3
            ? data.quarterly.projected
            : timePeriod === 4
            ? data.yearly.projected
            : 0

    const currentReachRate = actual / targetUntilTdy - 1
    const currentOverallReachRate = actual / target

    return (
        <Card sx={{ minWidth: 275, backgroundColor: '#303030' }}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={timePeriod}
                        label='Age'
                        onChange={handleChange}
                        variant='standard'
                        disableUnderline
                        sx={{
                            '& .MuiSvgIcon-root': {
                                right: 'unset',
                                left: '0px',
                            },
                            '& .MuiInput-input': {
                                paddingLeft: '24px',
                            },
                        }}
                    >
                        <MenuItem value={1}>Weekly</MenuItem>
                        <MenuItem value={2}>Monthly</MenuItem>
                        <MenuItem value={3}>Quarterly</MenuItem>
                        <MenuItem value={4}>Yearly</MenuItem>
                    </Select>
                    <Typography
                        variant='h6'
                        color='text.secondary'
                        fontWeight='bold'
                        textAlign='right'
                        textTransform='uppercase'
                    >
                        {title}
                    </Typography>
                </Box>

                <Box>
                    <Box display='flex' gap='.5rem'>
                        <Box width='65%'>
                            <DataRow text='Target' value={target} />
                            <DataRow
                                text={
                                    timePeriod === 1
                                        ? 'Projected (EOW)'
                                        : timePeriod === 2
                                        ? 'Projected (EOM)'
                                        : timePeriod === 3
                                        ? 'Projected (EOQ)'
                                        : timePeriod === 4
                                        ? 'Projected (EOY)'
                                        : ''
                                }
                                value={projectedValue}
                                bold
                            />
                        </Box>

                        <Box width='35%' display='flex' alignItems='center' justifyContent='end'>
                            <Typography variant='h4' color='text.secondary' fontWeight='bold'>
                                {currentReachRate > 0 ? (
                                    <Box display='flex' alignItems='center' justifyContent='center'>
                                        <KeyboardArrowUpIcon sx={{ color: '#00FF0A', fontSize: '2rem' }} />
                                        <Typography variant='h4' fontWeight='bold' color='#00FF0A'>
                                            {formatCountString(Math.round(Math.abs(currentReachRate * 100)))}%
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box display='flex' alignItems='center' justifyContent='center'>
                                        <KeyboardArrowDownIcon sx={{ color: '#FF0000', fontSize: '2rem' }} />
                                        <Typography variant='h4' fontWeight='bold' color='#FF0000'>
                                            {formatCountString(Math.round(Math.abs(currentReachRate * 100)))}%
                                        </Typography>
                                    </Box>
                                )}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ margin: '.5rem 0' }} />

                    <Box display='flex' flexDirection='column' gap='.5rem'>
                        <Box display='flex' gap='.5rem'>
                            <Box width='65%'>
                                <DataRow text='Actual' value={actual} />
                            </Box>
                            <Box width='35%' display='flex' alignItems='center' justifyContent='end'>
                                <Typography fontSize='.8rem' textAlign='right'>
                                    Currently at {formatCountString(Math.round(currentOverallReachRate * 100))}%
                                </Typography>
                            </Box>
                        </Box>
                        <BorderLinearProgress
                            variant='determinate'
                            value={
                                currentOverallReachRate * 100 > 100 ? 100 : Math.round(currentOverallReachRate * 100)
                            }
                        />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

export default Targets
