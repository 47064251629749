import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import PerfectScrollbar from 'react-perfect-scrollbar'
import LinearProgress from '@mui/material/LinearProgress'
// dialog
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
// step
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
// tabs
import Main from '../devices/Main'
import Bet from '../devices/form/Bet'
import Trendboard from '../devices/form/Trendboard'
import TrendPriority from '../devices/form/TrendPriority'
import TrendAnimations from '../devices/form/TrendAnimations'
import ChaseTheTrend from '../devices/form/ChaseTheTrend'
import Payouts from '../devices/form/Payouts'
import DealerUI from '../devices/form/DealerUI'
import NDI from '../devices/form/NDISettings'

import { getMinBetColor } from '../devices/minBetColor/color'
import { defaultValues } from '../devices/defaultValues'
import { pivotIP } from '../../config'
import { Typography } from '@mui/material'
import { publish } from '../../api'
import { FieldText } from '../../forms'

const Template = ({ open, handleClose, editing, devices, status, settings }) => {
    // layout fetch
    const [layouts, setLayouts] = useState(null)
    const [loadingLayouts, setLoadingLayouts] = useState(false)
    const [layoutDevice, setLayoutDevice] = useState({})

    // steps flags
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)

    // editing
    const [editingLoaded, setEditingLoaded] = useState(false)

    // fields
    const [name, setName] = useState('')
    const [gameType, setGameType] = useState('')
    const [gameVariant, setGameVariant] = useState('')
    const [betSettings, setBetSettings] = useState({})
    const [trendboardSettings, setTrendboardSettings] = useState({})
    const [trendPrioritySettings, setTrendPrioritySettings] = useState({})
    const [trendAnimationsSettings, setTrendAnimationsSettings] = useState({})
    const [chaseTheTrendSettings, setChaseTheTrendSettings] = useState({})
    const [payoutsSettings, setPayoutsSettings] = useState({})
    const [dealerUISettings, setDealerUISettings] = useState({})
    const [ndiSettings, setNDISettings] = useState({})

    const closeDialog = () => {
        setName('')
        setGameType('')
        setGameVariant('')
        setBetSettings({})
        setTrendboardSettings({})
        setTrendPrioritySettings({})
        setTrendAnimationsSettings({})
        setChaseTheTrendSettings({})
        setPayoutsSettings({})
        setNDISettings({})
        setDealerUISettings({})
        setActiveStep(0)
        setEditingLoaded(false)
        handleClose()
    }

    const loadEditing = () => {
        setName(editing.data.templateName)
        setGameType(editing.data.gameType)
        setGameVariant(editing.data.gameVariant)
        setBetSettings({
            bets: editing.data.bets
        })
        setPayoutsSettings({
            betOdds: editing.data.betOdds
        })
        setTrendboardSettings({
            layout: editing.data.layout
        })
    }

    const nextDisabled = () => {
        if (activeStep === 0 &&
            (gameType === '' ||
                gameVariant === '' || !layouts || layouts.length === 0)) {
            return true
        }

        return false
    }

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const changeCallbackMain = ({ gameType, gameVariant }) => {
        setGameType(gameType)
        setGameVariant(gameVariant)
        if (layoutDevice.gameType !== gameType || layoutDevice.gameVariant !== gameVariant) {
            setLayouts(null)
            setLayoutDevice({})
        }

    }

    const changeCallbackBet = (fields) => {
        console.log("RTR", fields)
        setBetSettings(fields)
    }

    const changeCallbackTrendboard = (fields) => {
        console.log("RTR", fields)
        setTrendboardSettings(fields)
    }

    const changeCallbackTrendPriority = (fields) => {
        console.log("RTR", fields)
        setTrendPrioritySettings(fields)
    }

    const changeCallbackTrendAnimations = (fields) => {
        console.log("RTR", fields)
        setTrendAnimationsSettings(fields)
    }

    const changeCallbackChaseTheTrend = (fields) => {
        console.log("RTR", fields)
        setChaseTheTrendSettings(fields)
    }

    const changeCallbackPayouts = (fields) => {
        console.log("RTR", fields)
        setPayoutsSettings(fields)
    }

    const changeCallbackDealerUI = (fields) => {
        console.log("RTR", fields)
        setDealerUISettings(fields)
    }

    const changeCallbackNDI = (fields) => {
        console.log("RTR", fields)
        setNDISettings(fields)
    }


    const deviceLocal = {
        data: {
            ...defaultValues,
            type: 'game',
            gameType,
            gameVariant,
            templateName: name,
            ...betSettings,
            ...trendboardSettings,
            ...trendPrioritySettings,
            ...trendAnimationsSettings,
            ...chaseTheTrendSettings,
            ...payoutsSettings,
            ...dealerUISettings,
            ...ndiSettings
        },
    }

    const steps = [
        'Template type',
        'Bets',
        'Trendboard',
        gameType === 'baccarat' && 'Trend Priority',
        (gameType === 'baccarat' || gameType === 'roulette') &&
        'Trend Animations',
        gameType === 'baccarat' &&
        'Chase The Trend',
        'Payouts',
        gameType === 'baccarat' && 'Dealer UI',
        'NDI Settings',
        ((gameType === 'baccarat' &&
            gameVariant === 'lucky6Slot') ||
            (gameType === 'blackjack' &&
                gameVariant === 'anyPairSlot')) && 'Slots',
    ].filter((v) => !!v)

    const getStepContent = (stepLabel) => {
        switch (stepLabel) {
            case 'Template type':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <FieldText
                            label='Name'
                            name='templateName'
                            value={name}
                            handleChange={(e) => handleNameChange(e)}
                            loading={loading || loadingLayouts}
                            error={false}
                            helperText={'name to identify the template for future use'}
                        />
                        {!loadingLayouts && <Main device={deviceLocal}
                            devices={devices}
                            settings={settings}
                            template
                            changeCallback={changeCallbackMain}
                            zones={[]} />}
                        {loadingLayouts && <>
                            <LinearProgress />
                        </>}
                        {(!loadingLayouts && layouts && layouts.length === 0) &&
                            <Typography>No device available of the selected type and variant to create a template</Typography>}
                    </Box>
                )
            case 'Bets':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <Bet
                            table={deviceLocal}
                            settings={settings}
                            template
                            changeCallback={changeCallbackBet}
                        />
                    </Box>
                )
            case 'Trendboard':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <Trendboard
                            table={deviceLocal}
                            layouts={layouts}
                            streams={[]}
                            template
                            changeCallback={changeCallbackTrendboard}
                        />
                    </Box>
                )
            case 'Trend Priority':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <TrendPriority
                            table={deviceLocal}
                            template
                            changeCallback={changeCallbackTrendPriority}
                        />
                    </Box>
                )
            case 'Trend Animations':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <TrendAnimations
                            table={deviceLocal}
                            template
                            changeCallback={changeCallbackTrendAnimations}
                        />
                    </Box>
                )
            case 'Chase The Trend':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <ChaseTheTrend
                            table={deviceLocal}
                            template
                            changeCallback={changeCallbackChaseTheTrend}
                        />
                    </Box>
                )
            case 'Payouts':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <Payouts
                            table={deviceLocal}
                            template
                            changeCallback={changeCallbackPayouts}
                        />
                    </Box>
                )
            case 'Dealer UI':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <DealerUI
                            table={deviceLocal}
                            settings={settings}
                            template
                            changeCallback={changeCallbackDealerUI}
                        />
                    </Box>
                )
            case 'NDI Settings':
                return (
                    <Box display='flex' flexDirection='column' gap='.35rem'>
                        <NDI
                            table={deviceLocal}
                            template
                            changeCallback={changeCallbackNDI}
                        />
                    </Box>
                )
            default:
                return 'Unknown step label'
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            setLoading(true)
            console.log(deviceLocal)
            setTimeout(() => {
                setLoading(false)
            }, 1400)
            try {
                await publish('templates/*', {
                    ...deviceLocal.data,
                    minBetColor: getMinBetColor(
                        deviceLocal.data.bets[gameType][gameVariant].minBet,
                        deviceLocal.data.colorThresholds ? deviceLocal.data.colorThresholds : defaultValues.colorThresholds
                    ),
                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
                closeDialog()
            } catch (e) {
                console.warn('failed to store template', e)
            } finally {
                setLoading(false)
            }
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    useEffect(() => {
        const fetchLayouts = async (deviceID) => {
            try {
                const content = await (
                    await fetch('http://' + pivotIP + '/remote/trendboard/layouts/' + deviceID, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        }
                    })
                ).json()
                setLayouts(content.layouts)
            } catch (e) {
                console.warn("failed to fetch layouts", e)
            } finally {
                setLoadingLayouts(false)
            }
        }

        if (gameType !== '' && gameVariant !== '' && layouts === null && !loadingLayouts) {
            setLoadingLayouts(true)
            const matchingDevices = status.filter(s => s.data.gameType === gameType && s.data.gameVariant === gameVariant)
            const devicesWithTrendboardActive = matchingDevices.filter(s => s.data.trendboard)
            if (devicesWithTrendboardActive.length > 0) {
                setLayoutDevice(devicesWithTrendboardActive[0].data)
                fetchLayouts(devicesWithTrendboardActive[0].index)
            } else {
                setLayouts([])
                setLoadingLayouts(false)
            }
        }
    }, [gameType, gameVariant, devices, status, layouts, loadingLayouts])

    if (editing && !editingLoaded) {
        setEditingLoaded(true)
        loadEditing()
    }

    return <Dialog
        open={open}
        maxWidth={"900"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle
            id='form-dialog-title'
            sx={{
                pb: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
                marginBottom: '1em',
                marginTop: '1em'
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <PerfectScrollbar>
                <Box>
                    {!loading && getStepContent(steps[activeStep])}
                    {loading && <LinearProgress />}
                </Box>
            </PerfectScrollbar>
        </DialogContent>
        <DialogActions>
            <Button color='inherit' onClick={closeDialog}>
                Cancel
            </Button>
            {activeStep !== 0 && <Button color='inherit' onClick={handleBack}>
                Back
            </Button>}
            <Button
                color='primary'
                disabled={nextDisabled()}
                onClick={handleNext}
            >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
        </DialogActions>
    </Dialog>

}

export default Template