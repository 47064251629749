import React, { memo, forwardRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import BarChartIcon from '@mui/icons-material/BarChart'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CasinoIcon from '@mui/icons-material/Casino'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DnsIcon from '@mui/icons-material/Dns'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import SettingsIcon from '@mui/icons-material/Settings'
import TableBarIcon from '@mui/icons-material/TableBar'
import TuneIcon from '@mui/icons-material/Tune'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/styles'

import { useSubscribe } from '../api'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const CustomTooltipWrapper = ({ condition, wrapper, children }) => (condition ? children : wrapper(children))

export default memo(({ open, setOpen }) => {
    const { pathname } = useLocation()

    const isLivePlayActive = pathname.split('/').includes('liveplay')

    const isSettingsActive = pathname.split('/')[2]?.includes('settings')

    const isHTHTActive = pathname.split('/')[2]?.includes('hottrends')

    const [settings] = useSubscribe('settings')

    const activeStyle = {
        background: 'rgb(88, 88, 88)',
    }

    const NavLinkRef = forwardRef((props, ref) => <NavLink {...props} ref={ref} />)

    // handle propagation
    // from the click event on the hamburger triggering the click on the chevron
    // not good but idk wtf
    const [openDoneStarted, setOpenDoneStarted] = useState(false)
    const [openDone, setOpenDone] = useState(false)

    if (open && !openDone && !openDoneStarted) {
        setOpenDoneStarted(true)
        setTimeout(() => {
            if (open) {
                setOpenDone(true)
            }
        }, 400)
    }

    if (!open && openDone) {
        setOpenDoneStarted(false)
        setOpenDone(false)
    }

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (openDone) {
                    setOpen(false)
                }
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box>
                    <DrawerHeader>
                        <Typography sx={{ ml: 2, fontWeight: 'bold', textTransform: 'uppercase' }}>
                            X Stadium
                        </Typography>
                        <IconButton
                            onClick={() => {
                                if (openDone) {
                                    setOpen(false)
                                }
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>

                    <Divider />

                    <List sx={{ padding: 0 }}>
                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Live Play' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xstadium/liveplay/animation',
                                    style: isLivePlayActive ? activeStyle : undefined,
                                }}
                            >
                                <ListItemIcon>
                                    <TuneIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Live Play'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Tables' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xstadium/tables',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>
                                    <TableBarIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Tables'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Games' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xstadium/games',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>
                                    <CasinoIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Games'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Trends' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xstadium/trends',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>
                                    <BarChartIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Trends'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Playlists' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xstadium/playlists',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>
                                    <PlayCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Playlists'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Scheduler' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xstadium/scheduler',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>
                                    <CalendarMonthIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Scheduler'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Servers' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xstadium/servers',
                                    style: ({ isActive }) => (isActive ? activeStyle : undefined),
                                }}
                            >
                                <ListItemIcon>
                                    <DnsIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Servers'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />

                        {settings && settings.data.xstadium?.hotTrendsHotTablesMode && (
                            <>
                                <CustomTooltipWrapper
                                    condition={open}
                                    wrapper={(children) => (
                                        <Tooltip title='Hot Trends' placement='right'>
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <ListItemButton
                                        component={NavLinkRef}
                                        onClick={() => setOpen(false)}
                                        {...{
                                            disableTouchRipple: true,
                                            to: '/xstadium/hottrends/trends',
                                            style: isHTHTActive ? activeStyle : undefined,
                                        }}
                                    >
                                        <ListItemIcon>
                                            <WhatshotIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Hot Trends'} />
                                    </ListItemButton>
                                </CustomTooltipWrapper>

                                <Divider />
                            </>
                        )}

                        <CustomTooltipWrapper
                            condition={open}
                            wrapper={(children) => (
                                <Tooltip title='Settings' placement='right'>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            <ListItemButton
                                component={NavLinkRef}
                                onClick={() => setOpen(false)}
                                {...{
                                    disableTouchRipple: true,
                                    to: '/xstadium/settings/defaultplaylist',
                                    style: isSettingsActive ? activeStyle : undefined,
                                }}
                            >
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Settings'} />
                            </ListItemButton>
                        </CustomTooltipWrapper>

                        <Divider />
                    </List>
                </Box>

                <Box>
                    <CustomTooltipWrapper
                        condition={open}
                        wrapper={(children) => (
                            <Tooltip title='Exit App' placement='right'>
                                {children}
                            </Tooltip>
                        )}
                    >
                        <ListItemButton
                            component={NavLinkRef}
                            onClick={() => setOpen(false)}
                            {...{
                                disableTouchRipple: true,
                                to: '/dashboard',
                            }}
                        >
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Exit App'} />
                        </ListItemButton>
                    </CustomTooltipWrapper>
                </Box>
            </Box>
        </ClickAwayListener>
    )
})
