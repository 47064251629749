import React, { useState, useReducer } from 'react'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import moment from 'moment'
import Chart from 'react-apexcharts'
import { Pie } from 'react-chartjs-2'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap'
import { LinearProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { useSubscribe, tableApi, historyApi, promTrendApi } from '../../api'
import { prometheusTrend } from '../../config'
import { useAppContext } from '../../contexts/AppContext'
import Location from '../../tree/Location'
import Tree from '../../tree/Tree'
import {
    getGameType,
    getGameVariant,
    getDescendantDevices,
    getAllIPs,
    // getBacStandardIPs,
    // getBacLucky6IPs,
    // getBacLucky6SlotIPs,
    // getBacMega6IPs,
    // getBacTigerIPs,
    // getBjStandardIPs,
    // getBjAnyPairSlotIPs,
    // getRouStandardIPs,
    // getWarEtgIPs,
} from '../../tree/TreeUtils'
import useWindowSize from '../../WindowSize'
import {
    formatNumber,
    chartBackgroundColors,
    baccaratStandardCharPerTypeLabels,
    baccaratLucky6CharPerTypeLabels,
    baccaratMega6CharPerTypeLabels,
    baccaratTigerCharPerTypeLabels,
    gradient5Colors,
    formatPresicePercentString,
    DisplayBetTypeBPTBreakdown,
    DisplayTop5Breakdown,
    TrendsVersusBreakdown,
} from '../Formats'
import TableHistoryChart from '../TableHistoryChart'

const ExcelJS = require('exceljs')

const styles = {
    pieContainer: {
        width: 200,
        height: 100,
    },
    barContainer: {
        width: 'inherit',
        height: 150,
    },
    radarContainer: {
        width: '-webkit-fill-available',
        height: 600,
        display: 'flex',
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em',
    },
    cardTitleText: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        // color: 'white'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5vw 0.7vw',
    },
    cardBodyText: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
}

const Trends = ({ xstadium, levels = null }) => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))
    const isLive = startDate === startOfToday && endDate === endOfToday ? true : false

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')
    const URLlocationID = urlParams.get('locationID') || window.localStorage.getItem('url:locationID') || 'root'
    const URLlocationType = urlParams.get('locationType') || window.localStorage.getItem('url:locationType') || 'Casino'
    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }
    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN

    // filter
    const { gameVariantFilter } = useAppContext()

    // fetched data
    const [allTablesReport, setAllTablesReport] = useState(null)
    const [baccaratStandardAllTablesReport, setBaccaratStandardAllTablesReport] = useState(null)
    const [baccaratLucky6AllTablesReport, setBaccaratLucky6AllTablesReport] = useState(null)
    const [baccaratLucky6SlotAllTablesReport, setBaccaratLucky6SlotAllTablesReport] = useState(null)
    const [baccaratMega6AllTablesReport, setBaccaratMega6AllTablesReport] = useState(null)
    const [baccaratTigerAllTablesReport, setBaccaratTigerAllTablesReport] = useState(null)
    const [blackjackStandardAllTablesReport, setBlackjackStandardAllTablesReport] = useState(null)
    const [blackjackAnyPairSlotAllTablesReport, setBlackjackAnyPairSlotAllTablesReport] = useState(null)
    const [rouletteStandardAllTablesReport, setRouletteStandardAllTablesReport] = useState(null)
    const [warEtgAllTablesReport, setWarEtgAllTablesReport] = useState(null)
    const [trendCounts, setTrendCounts] = useState(null)
    const [gamesSideCounts, setGamesSideCounts] = useState(null)
    const [fetchedTablesReport, setFetchedTablesReport] = useState(false)
    const [fetchedTrendCounts, setFetchedTrendCounts] = useState(false)
    const [fetchedGamesSideCounts, setFetchedGamesSideCounts] = useState(false)
    const [minLoadTime, setMinLoadTime] = useState(false)

    // built charts
    const [builtCharts, setBuiltCharts] = useState({})
    const setBuiltChartProxy = (chartID, value) => {
        setBuiltCharts((prevBuilt) => ({
            ...prevBuilt,
            [chartID]: value,
        }))
    }

    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter((v) => v !== 'push')
        const oldParams = urlParams.toString()
        propKeys.forEach((k) => {
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange =
            propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        const path = xstadium ? 'xstadium' : 'xtable'
        if (urlChange || props.push) {
            navigate(`/${path}/trends?` + urlParams.toString())
            setFetchedTablesReport(false)
            setFetchedTrendCounts(false)
            setFetchedGamesSideCounts(false)
        }
        setBuiltCharts({})
    }

    const [location, setLocation] = useReducer((_state, obj) => {
        clearFetch({ locationID: obj.branchIndex, locationType: obj.branchType })
        return [obj]
    }, null)

    // breadcrumbs
    const key = {
        device: { o: devices, p: 'table' },
        table: { o: tables, p: 'pit' },
        pit: { o: pits, p: 'zone' },
        zone: { o: zones, p: 'casino' },
        casino: { o: [], p: null },
    }

    const getBreadcrumbsArray = (type, index) => {
        const obj = key[type.toLowerCase()]
        const dataObj = obj ? obj.o.filter((v) => v.index === index) : {}
        const name = _.get(dataObj, ['0', 'data', 'name'], null)
        const containerIndex = _.get(dataObj, ['0', 'data', 'containerIndex'], null)
        const containerType = _.get(dataObj, ['0', 'data', 'containerType'], obj.p)
        return containerType
            ? [
                getBreadcrumbsArray(containerType, containerIndex),
                {
                    name: name,
                    type: type.toLowerCase(),
                },
            ].flat()
            : [
                {
                    name: 'Casino',
                    type: 'casino',
                },
            ]
    }

    const breadCrumbs = location
        ? levels
            ? getBreadcrumbsArray(_.lowerCase(location[0].branchType), location[0].branchIndex).slice(0 - levels)
            : getBreadcrumbsArray(_.lowerCase(location[0].branchType), location[0].branchIndex)
        : []

    const isTable = _.get(location, ['0', 'branchType'], '') === 'Table' ? true : false
    const gameType = _.get(location, ['0', 'gameType'], '')
    const gameVariant = _.get(location, ['0', 'gameVariant'], '')

    const dateChange = (start, end, push) => {
        if (start === startDate && end === endDate && !push) return
        if (!push && (start !== startDate || end !== endDate)) {
            setStartDate(start)
            setEndDate(end)
        }
        clearFetch({ startDate: start, endDate: end, push: !!push })
    }

    const getDevicesReport = async () => {
        if (fetchedTablesReport) {
            return
        }

        setFetchedTablesReport(true)

        const api = settings && settings.data.xtable.gameOnlyMode ? historyApi : tableApi

        try {
            const responseOverview = await api
                .get('report/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratStandard = await api
                .get('report/baccarat/standard/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratLucky6 = await api
                .get('report/baccarat/lucky6/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratLucky6Slot = await api
                .get('report/baccarat/lucky6Slot/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratMega6 = await api
                .get('report/baccarat/mega6/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratTiger = await api
                .get('report/baccarat/tiger/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBlackjackStandard = await api
                .get('report/blackjack/standard/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBlackjackAnyPairSlot = await api
                .get('report/blackjack/anyPairSlot/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseRouletteStandard = await historyApi
                .get('report/roulette/standard/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseWarEtg = await historyApi
                .get('report/war/etg/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            console.log('overview', responseOverview)
            console.log('baccarat/standard', responseBaccaratStandard)
            console.log('baccarat/lucky6', responseBaccaratLucky6)
            console.log('baccarat/lucky6Slot', responseBaccaratLucky6Slot)
            console.log('baccarat/mega6', responseBaccaratMega6)
            console.log('baccarat/tiger', responseBaccaratTiger)
            console.log('blackjack/standard', responseBlackjackStandard)
            console.log('blackjack/anyPairSlot', responseBlackjackAnyPairSlot)
            console.log('roulette/standard', responseRouletteStandard)
            console.log('war/etg', responseWarEtg)
            setAllTablesReport(responseOverview)
            setBaccaratStandardAllTablesReport(responseBaccaratStandard)
            setBaccaratLucky6AllTablesReport(responseBaccaratLucky6)
            setBaccaratLucky6SlotAllTablesReport(responseBaccaratLucky6Slot)
            setBaccaratMega6AllTablesReport(responseBaccaratMega6)
            setBaccaratTigerAllTablesReport(responseBaccaratTiger)
            setBlackjackStandardAllTablesReport(responseBlackjackStandard)
            setBlackjackAnyPairSlotAllTablesReport(responseBlackjackAnyPairSlot)
            setRouletteStandardAllTablesReport(responseRouletteStandard)
            setWarEtgAllTablesReport(responseWarEtg)
        } catch (e) {
            console.warn(e)
            setAllTablesReport([])
            setBaccaratStandardAllTablesReport([])
            setBaccaratLucky6AllTablesReport([])
            setBaccaratLucky6SlotAllTablesReport([])
            setBaccaratMega6AllTablesReport([])
            setBaccaratTigerAllTablesReport([])
            setBlackjackStandardAllTablesReport([])
            setBlackjackAnyPairSlotAllTablesReport([])
            setRouletteStandardAllTablesReport([])
            setWarEtgAllTablesReport([])
        }
    }

    if (settings && Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        getDevicesReport()
    }

    const IPs = location
        ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices)
        : getAllIPs(['root'], zones, pits, tables, devices)
    const bacStandardIPs = location
        ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices)
        : []
    const bacLucky6IPs = location ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices) : []
    const bacLucky6SlotIPs = location
        ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices)
        : []
    const bacMega6IPs = location ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices) : []
    const bacTigerIPs = location ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices) : []
    const bjStandardIPs = location ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices) : []
    const bjAnyPairSlotIPs = location
        ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices)
        : []
    const rouStandardIPs = location
        ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices)
        : []
    const warEtgIPs = location ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices) : []

    const locationQuery = 'instance=~"' + IPs.join('|') + '"'
    const bacStandardLocationQuery = 'instance=~"' + bacStandardIPs.join('|') + '"'
    const bacLucky6LocationQuery = 'instance=~"' + bacLucky6IPs.join('|') + '"'
    const bacLucky6SlotLocationQuery = 'instance=~"' + bacLucky6SlotIPs.join('|') + '"'
    const bacMega6LocationQuery = 'instance=~"' + bacMega6IPs.join('|') + '"'
    const bacTigerLocationQuery = 'instance=~"' + bacTigerIPs.join('|') + '"'
    const bjStandardLocationQuery = 'instance=~"' + bjStandardIPs.join('|') + '"'
    const bjAnyPairSlotLocationQuery = 'instance=~"' + bjAnyPairSlotIPs.join('|') + '"'
    const rouStandardLocationQuery = 'instance=~"' + rouStandardIPs.join('|') + '"'
    const warEtgLocationQuery = 'instance=~"' + warEtgIPs.join('|') + '"'

    const queryTrendsBase = 'sum({' + locationQuery + ', __name__=~"'
    const bacStandardQueryTrendsBase = 'sum({' + bacStandardLocationQuery + ', __name__=~"'
    const bacLucky6QueryTrendsBase = 'sum({' + bacLucky6LocationQuery + ', __name__=~"'
    const bacLucky6SlotQueryTrendsBase = 'sum({' + bacLucky6SlotLocationQuery + ', __name__=~"'
    const bacMega6QueryTrendsBase = 'sum({' + bacMega6LocationQuery + ', __name__=~"'
    const bacTigerQueryTrendsBase = 'sum({' + bacTigerLocationQuery + ', __name__=~"'
    const bjStandardQueryTrendsBase = 'sum({' + bjStandardLocationQuery + ', __name__=~"'
    const bjAnyPairSlotQueryTrendsBase = 'sum({' + bjAnyPairSlotLocationQuery + ', __name__=~"'
    const rouStandardQueryTrendsBase = 'sum({' + rouStandardLocationQuery + ', __name__=~"'
    const warEtgQueryTrendsBase = 'sum({' + warEtgLocationQuery + ', __name__=~"'

    const baccaratGamesHistoryQuery = [
        ...(bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard'
            ? [bacStandardQueryTrendsBase + 'baccarat_standard_games"})']
            : []),
        ...(bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6'
            ? [bacLucky6QueryTrendsBase + 'baccarat_lucky6_games"})']
            : []),
        ...(bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot'
            ? [bacLucky6SlotQueryTrendsBase + 'baccarat_lucky6Slot_games"})']
            : []),
        ...(bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6'
            ? [bacMega6QueryTrendsBase + 'baccarat_mega6_games"})']
            : []),
        ...(bacTigerIPs.length > 0 && gameVariantFilter === 'baccaratTiger'
            ? [bacTigerQueryTrendsBase + 'baccarat_tiger_games"})']
            : []),
    ].join(' + ')

    const baccaratStandardGamesHistoryQuery = [queryTrendsBase + 'baccarat_standard_games"})']

    const baccaratStandardTrends = [
        {
            label: 'streak banker',
            qry: 'baccarat_standard_streak_banker',
            tier: 1,
        },
        {
            label: 'streak player',
            qry: 'baccarat_standard_streak_player',
            tier: 1,
        },
        {
            label: 'tie double',
            qry: 'baccarat_standard_tie_double',
            tier: 0,
        },
        {
            label: 'tie triple',
            qry: 'baccarat_standard_tie_triple',
            tier: 0,
        },
        {
            label: 'banker pair',
            qry: 'baccarat_standard_banker_pair',
            tier: 2,
        },
        {
            label: 'player pair',
            qry: 'baccarat_standard_player_pair',
            tier: 2,
        },
        {
            label: 'pingpong',
            qry: 'baccarat_standard_pingpong',
            tier: 3,
        },
        {
            label: 'pingpong double',
            qry: 'baccarat_standard_pingpong_double',
            tier: 0,
        },
        {
            label: 'pingpong streak',
            qry: 'baccarat_standard_pingpong_streak',
            tier: 0,
        },
        {
            label: 'one lounge two bedrooms',
            qry: 'baccarat_standard_oneloungetwobedrooms',
            tier: 3,
        },
        {
            label: 'clap clap connect',
            qry: 'baccarat_standard_clapclapconnect',
            tier: 0,
        },
        {
            label: 'banker dragon',
            qry: 'baccarat_standard_dragon_banker',
            tier: 4,
        },
        {
            label: 'player dragon',
            qry: 'baccarat_standard_dragon_player',
            tier: 4,
        },
        {
            label: 'pair streak banker',
            qry: 'baccarat_standard_pair_streak_banker',
            tier: 6,
        },
        {
            label: 'pair streak player',
            qry: 'baccarat_standard_pair_streak_player',
            tier: 6,
        },
    ]

    const baccaratStandardSides = [
        {
            label: 'Player',
            qry: 'baccarat_standard_player_wins',
        },
        {
            label: 'Banker',
            qry: 'baccarat_standard_banker_wins',
        },
        {
            label: 'Tie',
            qry: 'baccarat_standard_tie',
        },
    ]

    const baccaratLucky6GamesHistoryQuery = [queryTrendsBase + 'baccarat_lucky6_games"})']

    const baccaratLucky6Trends = [
        {
            label: 'streak banker',
            qry: 'baccarat_lucky6_streak_banker',
            tier: 1,
        },
        {
            label: 'streak player',
            qry: 'baccarat_lucky6_streak_player',
            tier: 1,
        },
        {
            label: 'tie double',
            qry: 'baccarat_lucky6_tie_double',
            tier: 0,
        },
        {
            label: 'tie triple',
            qry: 'baccarat_lucky6_tie_triple',
            tier: 0,
        },
        {
            label: 'lucky6',
            qry: 'baccarat_lucky6_lucky6',
            tier: 3,
        },
        {
            label: 'banker pair',
            qry: 'baccarat_lucky6_banker_pair',
            tier: 2,
        },
        {
            label: 'player pair',
            qry: 'baccarat_lucky6_player_pair',
            tier: 2,
        },
        {
            label: 'pingpong',
            qry: 'baccarat_lucky6_pingpong',
            tier: 3,
        },
        {
            label: 'pingpong double',
            qry: 'baccarat_lucky6_pingpong_double',
            tier: 0,
        },
        {
            label: 'pingpong streak',
            qry: 'baccarat_lucky6_pingpong_streak',
            tier: 0,
        },
        {
            label: 'one lounge two bedrooms',
            qry: 'baccarat_lucky6_oneloungetwobedrooms',
            tier: 3,
        },
        {
            label: 'clap clap connect',
            qry: 'baccarat_lucky6_clapclapconnect',
            tier: 0,
        },
        {
            label: 'banker dragon',
            qry: 'baccarat_lucky6_dragon_banker',
            tier: 4,
        },
        {
            label: 'player dragon',
            qry: 'baccarat_lucky6_dragon_player',
            tier: 4,
        },
        {
            label: 'pair streak banker',
            qry: 'baccarat_lucky6_pair_streak_banker',
            tier: 6,
        },
        {
            label: 'pair streak player',
            qry: 'baccarat_lucky6_pair_streak_player',
            tier: 6,
        },
    ]

    const baccaratLucky6Sides = [
        {
            label: 'Player',
            qry: 'baccarat_lucky6_player_wins',
        },
        {
            label: 'Banker',
            qry: 'baccarat_lucky6_banker_wins',
        },
        {
            label: 'Tie',
            qry: 'baccarat_lucky6_tie',
        },
    ]

    const baccaratLucky6SlotGamesHistoryQuery = [queryTrendsBase + 'baccarat_lucky6Slot_games"})']

    const baccaratLucky6SlotTrends = [
        {
            label: 'streak banker',
            qry: 'baccarat_lucky6Slot_streak_banker',
            tier: 1,
        },
        {
            label: 'streak player',
            qry: 'baccarat_lucky6Slot_streak_player',
            tier: 1,
        },
        {
            label: 'tie double',
            qry: 'baccarat_lucky6Slot_tie_double',
            tier: 0,
        },
        {
            label: 'tie triple',
            qry: 'baccarat_lucky6Slot_tie_triple',
            tier: 0,
        },
        {
            label: 'lucky6',
            qry: 'baccarat_lucky6Slot_lucky6',
            tier: 3,
        },
        {
            label: 'banker pair',
            qry: 'baccarat_lucky6Slot_banker_pair',
            tier: 2,
        },
        {
            label: 'player pair',
            qry: 'baccarat_lucky6Slot_player_pair',
            tier: 2,
        },
        {
            label: 'pingpong',
            qry: 'baccarat_lucky6Slot_pingpong',
            tier: 3,
        },
        {
            label: 'pingpong double',
            qry: 'baccarat_lucky6Slot_pingpong_double',
            tier: 0,
        },
        {
            label: 'pingpong streak',
            qry: 'baccarat_lucky6Slot_pingpong_streak',
            tier: 0,
        },
        {
            label: 'one lounge two bedrooms',
            qry: 'baccarat_lucky6Slot_oneloungetwobedrooms',
            tier: 3,
        },
        {
            label: 'clap clap connect',
            qry: 'baccarat_lucky6Slot_clapclapconnect',
            tier: 0,
        },
        {
            label: 'banker dragon',
            qry: 'baccarat_lucky6Slot_dragon_banker',
            tier: 4,
        },
        {
            label: 'player dragon',
            qry: 'baccarat_lucky6Slot_dragon_player',
            tier: 4,
        },
        {
            label: 'pair streak banker',
            qry: 'baccarat_lucky6Slot_pair_streak_banker',
            tier: 6,
        },
        {
            label: 'pair streak player',
            qry: 'baccarat_lucky6Slot_pair_streak_player',
            tier: 6,
        },
    ]

    const baccaratLucky6SlotSides = [
        {
            label: 'Player',
            qry: 'baccarat_lucky6Slot_player_wins',
        },
        {
            label: 'Banker',
            qry: 'baccarat_lucky6Slot_banker_wins',
        },
        {
            label: 'Tie',
            qry: 'baccarat_lucky6Slot_tie',
        },
    ]

    const baccaratMega6GamesHistoryQuery = [queryTrendsBase + 'baccarat_mega6_games"})']

    const baccaratMega6Trends = [
        {
            label: 'streak banker',
            qry: 'baccarat_mega6_streak_banker',
            tier: 1,
        },
        {
            label: 'streak player',
            qry: 'baccarat_mega6_streak_player',
            tier: 1,
        },
        {
            label: 'tie double',
            qry: 'baccarat_mega6_tie_double',
            tier: 0,
        },
        {
            label: 'tie triple',
            qry: 'baccarat_mega6_tie_triple',
            tier: 0,
        },
        {
            label: 'mega6',
            qry: 'baccarat_mega6_mega6',
            tier: 3,
        },
        {
            label: 'banker pair',
            qry: 'baccarat_mega6_banker_pair',
            tier: 2,
        },
        {
            label: 'player pair',
            qry: 'baccarat_mega6_player_pair',
            tier: 2,
        },
        {
            label: 'pingpong',
            qry: 'baccarat_mega6_pingpong',
            tier: 3,
        },
        {
            label: 'pingpong double',
            qry: 'baccarat_mega6_pingpong_double',
            tier: 0,
        },
        {
            label: 'pingpong streak',
            qry: 'baccarat_mega6_pingpong_streak',
            tier: 0,
        },
        {
            label: 'one lounge two bedrooms',
            qry: 'baccarat_mega6_oneloungetwobedrooms',
            tier: 3,
        },
        {
            label: 'clap clap connect',
            qry: 'baccarat_mega6_clapclapconnect',
            tier: 0,
        },
        {
            label: 'banker dragon',
            qry: 'baccarat_mega6_dragon_banker',
            tier: 4,
        },
        {
            label: 'player dragon',
            qry: 'baccarat_mega6_dragon_player',
            tier: 4,
        },
        {
            label: 'pair streak banker',
            qry: 'baccarat_mega6_pair_streak_banker',
            tier: 6,
        },
        {
            label: 'pair streak player',
            qry: 'baccarat_mega6_pair_streak_player',
            tier: 6,
        },
    ]

    const baccaratMega6Sides = [
        {
            label: 'Player',
            qry: 'baccarat_mega6_player_wins',
        },
        {
            label: 'Banker',
            qry: 'baccarat_mega6_banker_wins',
        },
        {
            label: 'Tie',
            qry: 'baccarat_mega6_tie',
        },
    ]

    const baccaratTigerGamesHistoryQuery = [queryTrendsBase + 'baccarat_tiger_games"})']

    const baccaratTigerTrends = [
        {
            label: 'dragon triple',
            qry: 'baccarat_tiger_dragon_triple',
            tier: 0,
        },
        {
            label: 'dragon double',
            qry: 'baccarat_tiger_dragon_double',
            tier: 0,
        },
        {
            label: 'player dragon',
            qry: 'baccarat_tiger_dragon_player',
            tier: 4,
        },
        {
            label: 'banker dragon',
            qry: 'baccarat_tiger_dragon_banker',
            tier: 4,
        },
        {
            label: 'tiger pair twin',
            qry: 'baccarat_tiger_tiger_pair_twin',
            tier: 0,
        },
        {
            label: 'tiger big',
            qry: 'baccarat_tiger_tiger_big',
            tier: 0,
        },
        {
            label: 'tiger small',
            qry: 'baccarat_tiger_tiger_small',
            tier: 0,
        },
        {
            label: 'clap clap connect',
            qry: 'baccarat_tiger_clapclapconnect',
            tier: 0,
        },
        {
            label: 'tiger tie triple',
            qry: 'baccarat_tiger_tiger_tie_triple',
            tier: 0,
        },
        {
            label: 'tie triple',
            qry: 'baccarat_tiger_tie_triple',
            tier: 0,
        },
        {
            label: 'pingpong streak',
            qry: 'baccarat_tiger_pingpong_streak',
            tier: 0,
        },
        {
            label: 'pingpong double',
            qry: 'baccarat_tiger_pingpong_double',
            tier: 0,
        },
        {
            label: 'streak tiger pair',
            qry: 'baccarat_tiger_tiger_pair_streak',
            tier: 0,
        },
        {
            label: 'tiger tie double',
            qry: 'baccarat_tiger_tiger_tie_double',
            tier: 0,
        },
        {
            label: 'tie double',
            qry: 'baccarat_tiger_tie_double',
            tier: 0,
        },
        {
            label: 'one lounge two bedrooms',
            qry: 'baccarat_tiger_oneloungetwobedrooms',
            tier: 3,
        },
        {
            label: 'streak player',
            qry: 'baccarat_tiger_streak_player',
            tier: 1,
        },
        {
            label: 'streak banker',
            qry: 'baccarat_tiger_streak_banker',
            tier: 1,
        },
        {
            label: 'tiger pair double',
            qry: 'baccarat_tiger_tiger_pair_double',
            tier: 0,
        },
        {
            label: 'pingpong',
            qry: 'baccarat_tiger_pingpong',
            tier: 3,
        },
        {
            label: 'tiger tie',
            qry: 'baccarat_tiger_tiger_tie',
            tier: 0,
        },
        {
            label: 'tie',
            qry: 'baccarat_tiger_tie',
            tier: 0,
        },
        {
            label: 'tiger pair',
            qry: 'baccarat_tiger_tiger_pair',
            tier: 0,
        },
        {
            label: 'player win',
            qry: 'baccarat_tiger_player_wins',
            tier: 0,
        },
        {
            label: 'banker win',
            qry: 'baccarat_tiger_banker_wins',
            tier: 0,
        },
    ]

    const baccaratTigerSides = [
        {
            label: 'Player',
            qry: 'baccarat_tiger_player_wins',
        },
        {
            label: 'Banker',
            qry: 'baccarat_tiger_banker_wins',
        },
        {
            label: 'Tie',
            qry: 'baccarat_tiger_tie',
        },
    ]

    const blackjackStandardGamesHistoryQuery = [queryTrendsBase + 'blackjack_standard_games"})']

    const blackjackStandardTrends = [
        {
            label: 'hands',
            qry: 'blackjack_standard_hands',
            tier: 1,
        },
        {
            label: 'dealer blackjack',
            qry: 'blackjack_standard_dealer_blackjack',
            tier: 1,
        },
        {
            label: 'dealer bust',
            qry: 'blackjack_standard_dealer_bust',
            tier: 1,
        },
        {
            label: 'dealer 21',
            qry: 'blackjack_standard_dealer_21',
            tier: 1,
        },
        {
            label: 'dealer 20',
            qry: 'blackjack_standard_dealer_20',
            tier: 1,
        },
        {
            label: 'dealer 19',
            qry: 'blackjack_standard_dealer_19',
            tier: 1,
        },
        {
            label: 'dealer 18',
            qry: 'blackjack_standard_dealer_18',
            tier: 1,
        },
        {
            label: 'dealer 17',
            qry: 'blackjack_standard_dealer_17',
            tier: 1,
        },
        {
            label: 'player blackjack',
            qry: 'blackjack_standard_player_blackjack',
            tier: 1,
        },
        {
            label: 'player 21',
            qry: 'blackjack_standard_player_21',
            tier: 1,
        },
        {
            label: 'player 20',
            qry: 'blackjack_standard_player_20',
            tier: 1,
        },
        {
            label: 'player 19',
            qry: 'blackjack_standard_player_19',
            tier: 1,
        },
        {
            label: 'player 18',
            qry: 'blackjack_standard_player_18',
            tier: 1,
        },
        {
            label: 'player 17',
            qry: 'blackjack_standard_player_17',
            tier: 1,
        },
        {
            label: 'player 16',
            qry: 'blackjack_standard_player_16',
            tier: 1,
        },
        {
            label: 'player 15',
            qry: 'blackjack_standard_player_15',
            tier: 1,
        },
        {
            label: 'player 14',
            qry: 'blackjack_standard_player_14',
            tier: 1,
        },
        {
            label: 'player 13',
            qry: 'blackjack_standard_player_13',
            tier: 1,
        },
        {
            label: 'player 12',
            qry: 'blackjack_standard_player_12',
            tier: 1,
        },
        {
            label: 'player 11',
            qry: 'blackjack_standard_player_11',
            tier: 1,
        },
        {
            label: 'player 10',
            qry: 'blackjack_standard_player_10',
            tier: 1,
        },
        {
            label: 'player 9',
            qry: 'blackjack_standard_player_9',
            tier: 1,
        },
        {
            label: 'player 8',
            qry: 'blackjack_standard_player_8',
            tier: 1,
        },
        {
            label: 'player 7',
            qry: 'blackjack_standard_player_7',
            tier: 1,
        },
        {
            label: 'player 6',
            qry: 'blackjack_standard_player_6',
            tier: 1,
        },
        {
            label: 'player 5',
            qry: 'blackjack_standard_player_5',
            tier: 1,
        },
        {
            label: 'player 4',
            qry: 'blackjack_standard_player_4',
            tier: 1,
        },
        {
            label: 'player 3',
            qry: 'blackjack_standard_player_3',
            tier: 1,
        },
        {
            label: 'player 2',
            qry: 'blackjack_standard_player_2',
            tier: 1,
        },
        {
            label: 'perfect pairs',
            qry: 'blackjack_standard_perfect_pairs',
            tier: 1,
        },
        {
            label: 'pairs',
            qry: 'blackjack_standard_pairs',
            tier: 1,
        },
        {
            label: 'dealer hands won',
            qry: 'blackjack_standard_dealer_hands_won',
            tier: 1,
        },
        {
            label: 'player hands won',
            qry: 'blackjack_standard_player_hands_won',
            tier: 1,
        },
        {
            label: 'tie hands',
            qry: 'blackjack_standard_tie_hands',
            tier: 1,
        },
    ]

    const blackjackAnyPairSlotGamesHistoryQuery = [queryTrendsBase + 'blackjack_anyPairSlot_games"})']

    const blackjackAnyPairSlotTrends = [
        {
            label: 'hands',
            qry: 'blackjack_anyPairSlot_hands',
            tier: 1,
        },
        {
            label: 'dealer blackjack',
            qry: 'blackjack_anyPairSlot_dealer_blackjack',
            tier: 1,
        },
        {
            label: 'dealer bust',
            qry: 'blackjack_anyPairSlot_dealer_bust',
            tier: 1,
        },
        {
            label: 'dealer 21',
            qry: 'blackjack_anyPairSlot_dealer_21',
            tier: 1,
        },
        {
            label: 'dealer 20',
            qry: 'blackjack_anyPairSlot_dealer_20',
            tier: 1,
        },
        {
            label: 'dealer 19',
            qry: 'blackjack_anyPairSlot_dealer_19',
            tier: 1,
        },
        {
            label: 'dealer 18',
            qry: 'blackjack_anyPairSlot_dealer_18',
            tier: 1,
        },
        {
            label: 'dealer 17',
            qry: 'blackjack_anyPairSlot_dealer_17',
            tier: 1,
        },
        {
            label: 'player blackjack',
            qry: 'blackjack_anyPairSlot_player_blackjack',
            tier: 1,
        },
        {
            label: 'player 21',
            qry: 'blackjack_anyPairSlot_player_21',
            tier: 1,
        },
        {
            label: 'player 20',
            qry: 'blackjack_anyPairSlot_player_20',
            tier: 1,
        },
        {
            label: 'player 19',
            qry: 'blackjack_anyPairSlot_player_19',
            tier: 1,
        },
        {
            label: 'player 18',
            qry: 'blackjack_anyPairSlot_player_18',
            tier: 1,
        },
        {
            label: 'player 17',
            qry: 'blackjack_anyPairSlot_player_17',
            tier: 1,
        },
        {
            label: 'player 16',
            qry: 'blackjack_anyPairSlot_player_16',
            tier: 1,
        },
        {
            label: 'player 15',
            qry: 'blackjack_anyPairSlot_player_15',
            tier: 1,
        },
        {
            label: 'player 14',
            qry: 'blackjack_anyPairSlot_player_14',
            tier: 1,
        },
        {
            label: 'player 13',
            qry: 'blackjack_anyPairSlot_player_13',
            tier: 1,
        },
        {
            label: 'player 12',
            qry: 'blackjack_anyPairSlot_player_12',
            tier: 1,
        },
        {
            label: 'player 11',
            qry: 'blackjack_anyPairSlot_player_11',
            tier: 1,
        },
        {
            label: 'player 10',
            qry: 'blackjack_anyPairSlot_player_10',
            tier: 1,
        },
        {
            label: 'player 9',
            qry: 'blackjack_anyPairSlot_player_9',
            tier: 1,
        },
        {
            label: 'player 8',
            qry: 'blackjack_anyPairSlot_player_8',
            tier: 1,
        },
        {
            label: 'player 7',
            qry: 'blackjack_anyPairSlot_player_7',
            tier: 1,
        },
        {
            label: 'player 6',
            qry: 'blackjack_anyPairSlot_player_6',
            tier: 1,
        },
        {
            label: 'player 5',
            qry: 'blackjack_anyPairSlot_player_5',
            tier: 1,
        },
        {
            label: 'player 4',
            qry: 'blackjack_anyPairSlot_player_4',
            tier: 1,
        },
        {
            label: 'player 3',
            qry: 'blackjack_anyPairSlot_player_3',
            tier: 1,
        },
        {
            label: 'player 2',
            qry: 'blackjack_anyPairSlot_player_2',
            tier: 1,
        },
        {
            label: 'perfect pairs',
            qry: 'blackjack_anyPairSlot_perfect_pairs',
            tier: 1,
        },
        {
            label: 'pairs',
            qry: 'blackjack_anyPairSlot_pairs',
            tier: 1,
        },
        {
            label: 'dealer hands won',
            qry: 'blackjack_anyPairSlot_dealer_hands_won',
            tier: 1,
        },
        {
            label: 'player hands won',
            qry: 'blackjack_anyPairSlot_player_hands_won',
            tier: 1,
        },
        {
            label: 'tie hands',
            qry: 'blackjack_anyPairSlot_tie_hands',
            tier: 1,
        },
    ]

    const rouletteStandardTrends = [
        new Array(37).fill(0).map((v, i) => {
            return {
                label: `${i}`,
                qry: `roulette_standard_r${i}`,
                tier: 1,
            }
        }),
        {
            label: 'odd',
            qry: 'roulette_standard_odd',
            tier: 1,
        },
        {
            label: 'even',
            qry: 'roulette_standard_even',
            tier: 1,
        },
        {
            label: 'red',
            qry: 'roulette_standard_red',
            tier: 1,
        },
        {
            label: 'black',
            qry: 'roulette_standard_black',
            tier: 1,
        },
        {
            label: 'green',
            qry: 'roulette_standard_green',
            tier: 1,
        },
        {
            label: 'range18',
            qry: 'roulette_standard_range18',
            tier: 1,
        },
        {
            label: 'range36',
            qry: 'roulette_standard_range36',
            tier: 1,
        },
    ].flat(1)

    const warEtgTrends = [
        {
            label: 'dealer hands win',
            qry: 'war_etg_hands_dealer',
            tier: 1,
        },
        {
            label: 'player hands win',
            qry: 'war_etg_hands_player',
            tier: 1,
        },
        {
            label: 'tie hands won',
            qry: 'war_etg_hands_tie',
            tier: 1,
        },
        new Array(7).fill(0).map((v, i) => {
            return {
                label: `player position ${i + 1} won`,
                qry: `war_etg_player_${i + 1}`,
                tier: 1,
            }
        }),
        {
            label: 'player wins war',
            qry: 'war_etg_war_player',
            tier: 1,
        },
        {
            label: 'dealer wins war',
            qry: 'war_etg_war_dealer',
            tier: 1,
        },
        {
            label: 'tie war',
            qry: 'war_etg_war_tie',
            tier: 1,
        },
    ].flat(1)

    const baccaratStandardTier1trendsHistoryQuery = baccaratStandardTrends
        .filter((t) => t.tier === 1)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratStandardTier2trendsHistoryQuery = baccaratStandardTrends
        .filter((t) => t.tier === 2)
        .map((t) => queryTrendsBase + t.qry + '"})')
    // const baccaratStandardTier3trendsHistoryQuery = baccaratStandardTrends.filter(t => t.tier === 3).map(t => queryTrendsBase + t.qry + '"})')
    // const baccaratStandardTier4trendsHistoryQuery = baccaratStandardTrends.filter(t => t.tier === 4).map(t => queryTrendsBase + t.qry + '"})')
    // const baccaratStandardTier5trendsHistoryQuery = baccaratStandardTrends.filter(t => t.tier === 0).map(t => queryTrendsBase + t.qry + '"})')

    const baccaratLucky6Tier1trendsHistoryQuery = baccaratLucky6Trends
        .filter((t) => t.tier === 1)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratLucky6Tier2trendsHistoryQuery = baccaratLucky6Trends
        .filter((t) => t.tier === 2)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratLucky6Tier3trendsHistoryQuery = baccaratLucky6Trends
        .filter((t) => t.tier === 3)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratLucky6Tier4trendsHistoryQuery = baccaratLucky6Trends
        .filter((t) => t.tier === 4)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratLucky6Tier5trendsHistoryQuery = baccaratLucky6Trends
        .filter((t) => t.tier === 0)
        .map((t) => queryTrendsBase + t.qry + '"})')

    const baccaratLucky6SlotTier1trendsHistoryQuery = baccaratLucky6SlotTrends
        .filter((t) => t.tier === 1)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratLucky6SlotTier2trendsHistoryQuery = baccaratLucky6SlotTrends
        .filter((t) => t.tier === 2)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratLucky6SlotTier3trendsHistoryQuery = baccaratLucky6SlotTrends
        .filter((t) => t.tier === 3)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratLucky6SlotTier4trendsHistoryQuery = baccaratLucky6SlotTrends
        .filter((t) => t.tier === 4)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratLucky6SlotTier5trendsHistoryQuery = baccaratLucky6SlotTrends
        .filter((t) => t.tier === 0)
        .map((t) => queryTrendsBase + t.qry + '"})')

    const baccaratMega6Tier1trendsHistoryQuery = baccaratMega6Trends
        .filter((t) => t.tier === 1)
        .map((t) => queryTrendsBase + t.qry + '"})')
    const baccaratMega6Tier2trendsHistoryQuery = baccaratMega6Trends
        .filter((t) => t.tier === 2)
        .map((t) => queryTrendsBase + t.qry + '"})')
    // const baccaratMega6Tier3trendsHistoryQuery = baccaratMega6Trends.filter(t => t.tier === 3).map(t => queryTrendsBase + t.qry + '"})')
    // const baccaratMega6Tier4trendsHistoryQuery = baccaratMega6Trends.filter(t => t.tier === 4).map(t => queryTrendsBase + t.qry + '"})')
    // const baccaratMega6Tier5trendsHistoryQuery = baccaratMega6Trends.filter(t => t.tier === 0).map(t => queryTrendsBase + t.qry + '"})')

    const baccaratTigerTier1trendsHistoryQuery = baccaratTigerTrends
        .filter((t) => t.tier === 1)
        .map((t) => queryTrendsBase + t.qry + '"})')
    // const baccaratTigerTier2trendsHistoryQuery = baccaratTigerTrends.filter(t => t.tier === 2).map(t => queryTrendsBase + t.qry + '"})')
    // const baccaratTigerTier3trendsHistoryQuery = baccaratTigerTrends.filter(t => t.tier === 3).map(t => queryTrendsBase + t.qry + '"})')
    // const baccaratTigerTier4trendsHistoryQuery = baccaratTigerTrends.filter(t => t.tier === 4).map(t => queryTrendsBase + t.qry + '"})')
    // const baccaratTigerTier5trendsHistoryQuery = baccaratTigerTrends.filter(t => t.tier === 0).map(t => queryTrendsBase + t.qry + '"})')

    const baccaratTier1StreakBankerTrendsHistoryQuery = [
        ...(bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard'
            ? [
                baccaratStandardTrends
                    .filter((t) => t.label === 'streak banker')
                    .map((t) => bacStandardQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6'
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'streak banker')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot'
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'streak banker')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6'
            ? [
                baccaratMega6Trends
                    .filter((t) => t.label === 'streak banker')
                    .map((t) => bacMega6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacTigerIPs.length > 0 && gameVariantFilter === 'baccaratTiger'
            ? [
                baccaratTigerTrends
                    .filter((t) => t.label === 'streak banker')
                    .map((t) => bacTigerQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ]
        .flat(1)
        .join(' + ')

    const baccaratTier1StreakPlayerTrendsHistoryQuery = [
        ...(bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard'
            ? [
                baccaratStandardTrends
                    .filter((t) => t.label === 'streak player')
                    .map((t) => bacStandardQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6'
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'streak player')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot'
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'streak player')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6'
            ? [
                baccaratMega6Trends
                    .filter((t) => t.label === 'streak player')
                    .map((t) => bacMega6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacTigerIPs.length > 0 && gameVariantFilter === 'baccaratTiger'
            ? [
                baccaratTigerTrends
                    .filter((t) => t.label === 'streak player')
                    .map((t) => bacTigerQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ]
        .flat(1)
        .join(' + ')

    const baccaratTier1TrendsHistoryQuery = [
        baccaratTier1StreakBankerTrendsHistoryQuery,
        baccaratTier1StreakPlayerTrendsHistoryQuery,
    ]

    const baccaratTier2BankerPairTrendsHistoryQuery = [
        ...(bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard'
            ? [
                baccaratStandardTrends
                    .filter((t) => t.label === 'banker pair')
                    .map((t) => bacStandardQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6'
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'banker pair')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot'
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'banker pair')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6'
            ? [
                baccaratMega6Trends
                    .filter((t) => t.label === 'banker pair')
                    .map((t) => bacMega6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacTigerIPs.length > 0 && gameVariantFilter === 'baccaratTiger'
            ? [
                baccaratTigerTrends
                    .filter((t) => t.label === 'banker pair')
                    .map((t) => bacTigerQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ]
        .flat(1)
        .join(' + ')

    const baccaratTier2PlayerPairTrendsHistoryQuery = [
        ...(bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard'
            ? [
                baccaratStandardTrends
                    .filter((t) => t.label === 'player pair')
                    .map((t) => bacStandardQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6'
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'player pair')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot'
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'player pair')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6'
            ? [
                baccaratMega6Trends
                    .filter((t) => t.label === 'player pair')
                    .map((t) => bacMega6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacTigerIPs.length > 0 && gameVariantFilter === 'baccaratTiger'
            ? [
                baccaratTigerTrends
                    .filter((t) => t.label === 'player pair')
                    .map((t) => bacTigerQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ]
        .flat(1)
        .join(' + ')

    const baccaratTier2TrendsHistoryQuery = [
        baccaratTier2BankerPairTrendsHistoryQuery,
        baccaratTier2PlayerPairTrendsHistoryQuery,
    ]

    const baccaratTier3Lucky6TrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'lucky6')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'lucky6')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier3PingPongTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'pingpong')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'pingpong')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier3OneLoungeTwoBedroomsTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'one lounge two bedrooms')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'one lounge two bedrooms')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier3TrendsHistoryQuery = _.union(
        baccaratTier3Lucky6TrendsHistoryQuery,
        baccaratTier3PingPongTrendsHistoryQuery,
        baccaratTier3OneLoungeTwoBedroomsTrendsHistoryQuery
    )

    const baccaratTier4DragonBankerTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6'
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'banker dragon')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot'
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'banker dragon')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier4DragonPlayerTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6'
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'player dragon')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot'
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'player dragon')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier4TrendsHistoryQuery = _.union(
        baccaratTier4DragonBankerTrendsHistoryQuery,
        baccaratTier4DragonPlayerTrendsHistoryQuery
    )

    const baccaratTier5TieDoubleTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'tie double')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'tie double')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier5TieTripleTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'tie triple')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'tie triple')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier5PingPongDoubleTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'pingpong double')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'pingpong double')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier5PingPongStreakTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'pingpong streak')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'pingpong streak')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier5ClapClapConnectTrendsHistoryQuery = [
        ...(bacLucky6IPs.length > 0
            ? [
                baccaratLucky6Trends
                    .filter((t) => t.label === 'clap clap connect')
                    .map((t) => bacLucky6QueryTrendsBase + t.qry + '"})'),
            ]
            : []),
        ...(bacLucky6SlotIPs.length > 0
            ? [
                baccaratLucky6SlotTrends
                    .filter((t) => t.label === 'clap clap connect')
                    .map((t) => bacLucky6SlotQueryTrendsBase + t.qry + '"})'),
            ]
            : []),
    ].flat(1)

    const baccaratTier5TrendsHistoryQuery = _.union(
        baccaratTier5TieDoubleTrendsHistoryQuery,
        baccaratTier5TieTripleTrendsHistoryQuery,
        baccaratTier5PingPongDoubleTrendsHistoryQuery,
        baccaratTier5PingPongStreakTrendsHistoryQuery,
        baccaratTier5ClapClapConnectTrendsHistoryQuery
    )

    const getStepResolution = () => {
        if (isLive && numberOfDays === 0) {
            return '14'
        }
        if (numberOfDays === 0) {
            return '3600'
        }

        return '86400'
    }

    const getTrendMetric = async (queryBase, trend, start, end, step) => {
        try {
            const response = await promTrendApi.rangeQuery(queryBase + trend.qry + '"})', start, end, step)
            const series = response.result[0].values
            // console.log("P", trend.label, series[series.length - 1].value)
            if (numberOfDays === 0) {
                return {
                    label: trend.label,
                    qry: trend.qry,
                    value: series[series.length - 1].value,
                }
            }

            return {
                label: trend.label,
                qry: trend.qry,
                value: series.reduce((prev, next) => prev + next.value, 0),
            }
        } catch (e) {
            // console.warn(e, queryBase + trend.qry + '"})')
            return {
                label: trend.label,
                qry: trend.qry,
                value: 0,
            }
        }
    }

    const findTrendCount = (trend) => trendCounts.filter((tr) => tr.label === trend).reduce((a, cV) => a + cV.value, 0)

    const getTrendsCounts = async () => {
        if (fetchedTrendCounts) {
            return
        }

        setFetchedTrendCounts(true)
        let qrys = []
        const _step = getStepResolution()
        const _start = new Date((Number(startDate) + (numberOfDays === 0 ? 10 : 86300)) * 1000)
        const _end = new Date((Number(endDate) - (numberOfDays > 0 ? 10 : 0)) * 1000)
        console.log("GTC", bacLucky6IPs)
        if (!isTable) {
            for (let trend of baccaratStandardTrends) {
                qrys.push(getTrendMetric(bacStandardQueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of baccaratLucky6Trends) {
                qrys.push(getTrendMetric(bacLucky6QueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of baccaratLucky6SlotTrends) {
                qrys.push(getTrendMetric(bacLucky6SlotQueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of baccaratMega6Trends) {
                qrys.push(getTrendMetric(bacMega6QueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of baccaratTigerTrends) {
                qrys.push(getTrendMetric(bacTigerQueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of blackjackStandardTrends) {
                qrys.push(getTrendMetric(bjStandardQueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of blackjackAnyPairSlotTrends) {
                qrys.push(getTrendMetric(bjAnyPairSlotQueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of rouletteStandardTrends) {
                qrys.push(getTrendMetric(rouStandardQueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of warEtgTrends) {
                qrys.push(getTrendMetric(warEtgQueryTrendsBase, trend, _start, _end, _step))
            }
        }
        if (isTable) {
            if (gameType === 'baccarat' && gameVariant === 'standard') {
                for (let trend of baccaratStandardTrends) {
                    qrys.push(getTrendMetric(bacStandardQueryTrendsBase, trend, _start, _end, _step))
                }
            }
            if (gameType === 'baccarat' && gameVariant === 'lucky6') {
                for (let trend of baccaratLucky6Trends) {
                    qrys.push(getTrendMetric(bacLucky6QueryTrendsBase, trend, _start, _end, _step))
                }
            }
            if (gameType === 'baccarat' && gameVariant === 'lucky6Slot') {
                for (let trend of baccaratLucky6SlotTrends) {
                    qrys.push(getTrendMetric(bacLucky6SlotQueryTrendsBase, trend, _start, _end, _step))
                }
            }
            if (gameType === 'baccarat' && gameVariant === 'mega6') {
                for (let trend of baccaratMega6Trends) {
                    qrys.push(getTrendMetric(bacMega6QueryTrendsBase, trend, _start, _end, _step))
                }
            }
            if (gameType === 'baccarat' && gameVariant === 'tiger') {
                for (let trend of baccaratTigerTrends) {
                    qrys.push(getTrendMetric(bacTigerQueryTrendsBase, trend, _start, _end, _step))
                }
            }
            if (gameType === 'blackjack' && gameVariant === 'standard') {
                for (let trend of blackjackStandardTrends) {
                    qrys.push(getTrendMetric(bjStandardQueryTrendsBase, trend, _start, _end, _step))
                }
            }
            if (gameType === 'blackjack' && gameVariant === 'anyPairSlot') {
                for (let trend of blackjackAnyPairSlotTrends) {
                    qrys.push(getTrendMetric(bjAnyPairSlotQueryTrendsBase, trend, _start, _end, _step))
                }
            }
            if (gameType === 'roulette' && gameVariant === 'standard') {
                for (let trend of rouletteStandardTrends) {
                    qrys.push(getTrendMetric(rouStandardQueryTrendsBase, trend, _start, _end, _step))
                }
            }
            if (gameType === 'war' && gameVariant === 'etg') {
                for (let trend of warEtgTrends) {
                    qrys.push(getTrendMetric(warEtgQueryTrendsBase, trend, _start, _end, _step))
                }
            }
        }
        const results = await Promise.all(qrys)
        console.log('trendCountsResults', results)
        setTrendCounts(results)
    }

    const getTrendCountsByVariant = (gameVariantFilter) => {
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                return trendCounts.filter((trend) => trend.qry.includes('baccarat') && trend.qry.includes('standard'))
            case 'baccaratLucky6':
                return trendCounts.filter(
                    (trend) =>
                        trend.qry.includes('baccarat') &&
                        trend.qry.includes('lucky6') &&
                        !trend.qry.includes('lucky6Slot')
                )
            case 'baccaratLucky6Slot':
                return trendCounts.filter((trend) => trend.qry.includes('baccarat') && trend.qry.includes('lucky6Slot'))
            case 'baccaratMega6':
                return trendCounts.filter((trend) => trend.qry.includes('baccarat') && trend.qry.includes('mega6'))
            case 'baccaratTiger':
                return trendCounts.filter((trend) => trend.qry.includes('baccarat') && trend.qry.includes('tiger'))
            case 'blackjackStandard':
                return trendCounts.filter((trend) => trend.qry.includes('blackjack') && trend.qry.includes('standard'))
            case 'blackjackAnyPairSlot':
                return trendCounts.filter(
                    (trend) => trend.qry.includes('blackjack') && trend.qry.includes('anyPairSlot')
                )
            case 'rouletteStandard':
                return trendCounts.filter((trend) => trend.qry.includes('roulette') && trend.qry.includes('standard'))
            case 'warEtg':
                return trendCounts.filter((trend) => trend.qry.includes('war') && trend.qry.includes('etg'))
            default:
        }
    }

    const sortedTrends = trendCounts
        ? !isTable
            ? gameVariantFilter
                ? getTrendCountsByVariant(gameVariantFilter).sort((a, b) => b.value - a.value)
                : []
            : trendCounts.sort((a, b) => b.value - a.value)
        : []

    const getGamesSideCounts = async () => {
        if (fetchedGamesSideCounts) {
            return
        }

        setFetchedGamesSideCounts(true)
        let qrys = []
        const _step = getStepResolution()
        const _start = new Date((Number(startDate) + (numberOfDays === 0 ? 10 : 86300)) * 1000)
        const _end = new Date((Number(endDate) - (numberOfDays > 0 ? 10 : 0)) * 1000)
        if (!isTable) {
            for (let trend of baccaratStandardSides) {
                qrys.push(getTrendMetric(bacStandardQueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of baccaratLucky6Sides) {
                qrys.push(getTrendMetric(bacLucky6QueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of baccaratLucky6SlotSides) {
                qrys.push(getTrendMetric(bacLucky6SlotQueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of baccaratMega6Sides) {
                qrys.push(getTrendMetric(bacMega6QueryTrendsBase, trend, _start, _end, _step))
            }
            for (let trend of baccaratTigerSides) {
                qrys.push(getTrendMetric(bacTigerQueryTrendsBase, trend, _start, _end, _step))
            }
        }
        if (gameType === 'baccarat' && gameVariant === 'standard') {
            for (let trend of baccaratStandardSides) {
                qrys.push(getTrendMetric(bacStandardQueryTrendsBase, trend, _start, _end, _step))
            }
        }
        if (gameType === 'baccarat' && gameVariant === 'lucky6') {
            for (let trend of baccaratLucky6Sides) {
                qrys.push(getTrendMetric(bacLucky6QueryTrendsBase, trend, _start, _end, _step))
            }
        }
        if (gameType === 'baccarat' && gameVariant === 'lucky6Slot') {
            for (let trend of baccaratLucky6SlotSides) {
                qrys.push(getTrendMetric(bacLucky6SlotQueryTrendsBase, trend, _start, _end, _step))
            }
        }
        if (gameType === 'baccarat' && gameVariant === 'mega6') {
            for (let trend of baccaratMega6Sides) {
                qrys.push(getTrendMetric(bacMega6QueryTrendsBase, trend, _start, _end, _step))
            }
        }
        if (gameType === 'baccarat' && gameVariant === 'tiger') {
            for (let trend of baccaratTigerSides) {
                qrys.push(getTrendMetric(bacTigerQueryTrendsBase, trend, _start, _end, _step))
            }
        }
        const results = await Promise.all(qrys)
        console.log('gameSideCountsResults', results)
        setGamesSideCounts(results)
    }

    const getGamesSideCountsByVariant = (gameVariantFilter) => {
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                return gamesSideCounts.filter((side) => side.qry.includes('baccarat') && side.qry.includes('standard'))
            case 'baccaratLucky6':
                return gamesSideCounts.filter(
                    (side) =>
                        side.qry.includes('baccarat') && side.qry.includes('lucky6') && !side.qry.includes('lucky6Slot')
                )
            case 'baccaratLucky6Slot':
                return gamesSideCounts.filter(
                    (side) => side.qry.includes('baccarat') && side.qry.includes('lucky6Slot')
                )
            case 'baccaratMega6':
                return gamesSideCounts.filter((side) => side.qry.includes('baccarat') && side.qry.includes('mega6'))
            case 'baccaratTiger':
                return gamesSideCounts.filter((side) => side.qry.includes('baccarat') && side.qry.includes('tiger'))
            case 'blackjackStandard':
                return gamesSideCounts.filter((side) => side.qry.includes('blackjack') && side.qry.includes('standard'))
            case 'blackjackAnyPairSlot':
                return gamesSideCounts.filter(
                    (side) => side.qry.includes('blackjack') && side.qry.includes('anyPairSlot')
                )
            default:
        }
    }

    const findTrendSide = (side) =>
        !isTable && gameVariantFilter !== 'rouletteStandard'
            ? getGamesSideCountsByVariant(gameVariantFilter)
                .filter((tr) => tr.label === side)
                .reduce((a, cV) => a + cV.value, 0)
            : gamesSideCounts.filter((tr) => tr.label === side).reduce((a, cV) => a + cV.value, 0)

    const oddVsEven = [
        {
            label: 'Odd',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_odd')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_odd').value
                    : 0,
        },
        {
            label: 'Even',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_even')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_even').value
                    : 0,
        },
        {
            label: 'Zero(s)',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_green')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_green').value
                    : 0,
        },
    ]

    const redVsBlackVsGreen = [
        {
            label: 'Red',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_red')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_red').value
                    : 0,
        },
        {
            label: 'Black',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_black')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_black').value
                    : 0,
        },
        {
            label: 'Green',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_green')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_green').value
                    : 0,
        },
    ]

    const range18Vsrange36 = [
        {
            label: '1-18',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_range18')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_range18').value
                    : 0,
        },
        {
            label: '19-36',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_range36')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_range36').value
                    : 0,
        },
        {
            label: 'Zero(s)',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_green')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'roulette_standard_green').value
                    : 0,
        },
    ]

    const dealerVsPlayerVsTieAnte = [
        {
            label: 'Dealer',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'war_etg_hands_dealer')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'war_etg_hands_dealer').value
                    : 0,
        },
        {
            label: 'Player',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'war_etg_hands_player')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'war_etg_hands_player').value
                    : 0,
        },
        {
            label: 'Tie',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'war_etg_hands_tie')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'war_etg_hands_tie').value
                    : 0,
        },
    ]

    const dealerVsPlayerVsTieWar = [
        {
            label: 'Dealer',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'war_etg_war_dealer')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'war_etg_war_dealer').value
                    : 0,
        },
        {
            label: 'Player',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'war_etg_war_player')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'war_etg_war_player').value
                    : 0,
        },
        {
            label: 'Tie',
            value:
                trendCounts && trendCounts.find((trendCount) => trendCount.qry === 'war_etg_war_tie')
                    ? trendCounts.find((trendCount) => trendCount.qry === 'war_etg_war_tie').value
                    : 0,
        },
    ]

    const getVesusObj = (data) =>
        data.map((v) => {
            return {
                label: v.label,
                value: v.value,
                percentage: formatPresicePercentString(v.value / data.reduce((acc, v) => acc + v.value, 0)),
            }
        })

    const baccaratPerTier1TrendTypeLabelFind = (metricIndex) => {
        switch (metricIndex) {
            case 1:
                return 'streak banker'
            case 3:
                return 'streak player'
            default:
                return ''
        }
    }

    const baccaratPerTier2TrendTypeLabelFind = (metricIndex) => {
        switch (metricIndex) {
            case 1:
                return 'banker pair'
            case 3:
                return 'player pair'
            default:
                return ''
        }
    }

    const baccaratPerTier3TrendTypeLabelFind = (metricIndex) => {
        switch (metricIndex) {
            case 1:
                return 'lucky6'
            case 3:
                return 'pingpong'
            case 5:
                return 'oneloungetwobedrooms'
            default:
                return ''
        }
    }

    const perTier4TrendTypeLabelFind = (metricIndex) => {
        switch (metricIndex) {
            case 1:
                return 'banker dragon'
            case 3:
                return 'player dragon'
            default:
                return ''
        }
    }

    const baccaratPerTier5TrendTypeLabelFind = (metricIndex) => {
        switch (metricIndex) {
            case 1:
                return 'tie double'
            case 3:
                return 'tie triple'
            case 5:
                return 'pingpong double'
            case 7:
                return 'pingpong streak'
            case 9:
                return 'clap clap connect'
            default:
                return ''
        }
    }

    // display data
    const tablesReport =
        allTablesReport && location
            ? allTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : null
    const baccaratStandardTablesReport =
        location && baccaratStandardAllTablesReport
            ? baccaratStandardAllTablesReport.filter(
                (entry) => location[0].descendantDevices.indexOf(entry.device) >= 0
            )
            : baccaratStandardAllTablesReport
    const baccaratLucky6TablesReport =
        location && baccaratLucky6AllTablesReport
            ? baccaratLucky6AllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : baccaratLucky6AllTablesReport
    const baccaratLucky6SlotTablesReport =
        location && baccaratLucky6SlotAllTablesReport
            ? baccaratLucky6SlotAllTablesReport.filter(
                (entry) => location[0].descendantDevices.indexOf(entry.device) >= 0
            )
            : baccaratLucky6SlotAllTablesReport
    const baccaratMega6TablesReport =
        location && baccaratMega6AllTablesReport
            ? baccaratMega6AllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : baccaratMega6AllTablesReport
    const baccaratTigerTablesReport =
        location && baccaratTigerAllTablesReport
            ? baccaratTigerAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : baccaratTigerAllTablesReport
    const blackjackStandardTablesReport =
        location && blackjackStandardAllTablesReport
            ? blackjackStandardAllTablesReport.filter(
                (entry) => location[0].descendantDevices.indexOf(entry.device) >= 0
            )
            : blackjackStandardAllTablesReport
    const blackjackAnyPairSlotTablesReport =
        location && blackjackAnyPairSlotAllTablesReport
            ? blackjackAnyPairSlotAllTablesReport.filter(
                (entry) => location[0].descendantDevices.indexOf(entry.device) >= 0
            )
            : blackjackAnyPairSlotAllTablesReport
    const rouletteStandardTablesReport =
        location && rouletteStandardAllTablesReport
            ? rouletteStandardAllTablesReport.filter(
                (entry) => location[0].descendantDevices.indexOf(entry.device) >= 0
            )
            : rouletteStandardAllTablesReport
    const warEtgTablesReport =
        location && warEtgAllTablesReport
            ? warEtgAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : warEtgAllTablesReport
    const totalGames =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.games, 0)
    console.log('totalGames', totalGames)
    console.log('tablesReport', tablesReport)
    console.log('bacStandardReport', baccaratStandardTablesReport)
    const totalBaccaratStandardGames =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalBaccaratLucky6Games =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalBaccaratLucky6SlotGames =
        !baccaratLucky6SlotTablesReport || !baccaratLucky6SlotTablesReport.reduce
            ? 0
            : baccaratLucky6SlotTablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalBaccaratMega6Games =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalBaccaratTigerGames =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalBaccaratGames =
        totalBaccaratStandardGames +
        totalBaccaratLucky6Games +
        totalBaccaratLucky6SlotGames +
        totalBaccaratMega6Games +
        totalBaccaratTigerGames
    const totalBlackjackStandardGames =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalBlackjackAnyPairSlotGames =
        !blackjackAnyPairSlotTablesReport || !blackjackAnyPairSlotTablesReport.reduce
            ? 0
            : blackjackAnyPairSlotTablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalRouletteStandardGames =
        !rouletteStandardTablesReport || !rouletteStandardTablesReport.reduce
            ? 0
            : rouletteStandardTablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalWarEtgGames =
        !warEtgTablesReport || !warEtgTablesReport.reduce
            ? 0
            : warEtgTablesReport.reduce((prev, next) => prev + next.games, 0)

    const getNumberOfGamesByVariant = (gameVariantFilter) => {
        switch (gameVariantFilter) {
            case 'baccaratStandard':
                return totalBaccaratStandardGames
            case 'baccaratLucky6':
                return totalBaccaratLucky6Games
            case 'baccaratLucky6Slot':
                return totalBaccaratLucky6SlotGames
            case 'baccaratMega6':
                return totalBaccaratMega6Games
            case 'baccaratTiger':
                return totalBaccaratTigerGames
            case 'blackjackStandard':
                return totalBlackjackStandardGames
            case 'blackjackAnyPairSlot':
                return totalBlackjackAnyPairSlotGames
            case 'rouletteStandard':
                return totalRouletteStandardGames
            case 'warEtg':
                return totalWarEtgGames
            default:
        }
    }

    if (tablesReport) {
        getGamesSideCounts()
    }

    if (resizing && builtCharts && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    const top5Trends = sortedTrends.slice(0, 5)

    const treeLoading = !active || !zones || !pits || !devices || !tables || !status || !settings || !minLoadTime

    if (tablesReport && location && !treeLoading) {
        getTrendsCounts()
    }

    if (
        !treeLoading &&
        (!location || (URLlocationID !== location[0].branchIndex && URLlocationType !== location[0].branchType))
    ) {
        setLocation({
            branchIndex: URLlocationID,
            branchType: URLlocationType,
            gameType: getGameType(tables, devices, URLlocationID, URLlocationType),
            gameVariant: getGameVariant(tables, devices, URLlocationID, URLlocationType),
            descendantDevices: getDescendantDevices(pits, tables, devices, URLlocationID, URLlocationType),
        })
    }

    const onExportClick = async () => {
        const workbook = new ExcelJS.Workbook()
        const sheet = workbook.addWorksheet('Game Trends')

        sheet.getCell('A1').value = 'X Table Trends Report'
        sheet.getCell('A2').value = 'Date of data:'
        sheet.getCell('B2').value = moment(moment.unix(startDate).format('YYYY-MM-DD')).isSame(
            moment.unix(endDate).format('YYYY-MM-DD')
        )
            ? moment.unix(endDate).format('YYYY-MM-DD')
            : moment.unix(startDate).format('YYYY-MM-DD') + ' - ' + moment.unix(endDate).format('YYYY-MM-DD')
        sheet.getCell('A3').value = 'Report run date:'
        sheet.getCell('B3').value = moment(new Date()).format('YYYY-MM-DD hh:mm:ss A')

        sheet.getCell('A4').value = 'Game type'
        sheet.getCell('B4').value = !isTable
            ? gameVariantFilter.toLowerCase().includes('baccarat')
                ? 'Baccarat'
                : gameVariantFilter.toLowerCase().includes('blackjack')
                    ? 'Blackjack'
                    : gameVariantFilter.toLowerCase().includes('roulette')
                        ? 'Roulette'
                        : gameVariantFilter.toLowerCase().includes('sicbo')
                            ? 'Sicbo'
                            : gameVariantFilter.toLowerCase().includes('war')
                                ? 'Casino War'
                                : ''
            : _.startCase(gameType)
        sheet.getCell('A5').value = 'Game variant'
        sheet.getCell('B5').value = !isTable
            ? gameVariantFilter.toLowerCase().includes('standard')
                ? 'Standard'
                : gameVariantFilter.toLowerCase().includes('lucky6') &&
                    !gameVariantFilter.toLowerCase().includes('lucky6slot')
                    ? 'Lucky 6'
                    : gameVariantFilter.toLowerCase().includes('lucky6slot')
                        ? 'Lucky 6 with Slot'
                        : gameVariantFilter.toLowerCase().includes('mega6')
                            ? 'Mega 6'
                            : gameVariantFilter.toLowerCase().includes('tiger')
                                ? 'Tiger'
                                : gameVariantFilter.toLowerCase().includes('anypairslot')
                                    ? 'Any Pair with Slot'
                                    : gameVariantFilter.toLowerCase().includes('etg')
                                        ? 'ETG'
                                        : ''
            : _.startCase(gameVariant)
        sheet.getCell('A6').value = 'Number of games'
        sheet.getCell('B6').value = !isTable ? getNumberOfGamesByVariant(gameVariantFilter) : totalGames

        sheet.getCell('D1').value = 'Casino:'
        sheet.getCell('E1').value = breadCrumbs.find((item) => item.type === 'casino')
            ? breadCrumbs.find((item) => item.type === 'casino').name
            : '-'
        sheet.getCell('D2').value = 'Zone:'
        sheet.getCell('E2').value = breadCrumbs.find((item) => item.type === 'zone')
            ? breadCrumbs.find((item) => item.type === 'zone').name
            : '-'
        sheet.getCell('D3').value = 'Pit:'
        sheet.getCell('E3').value = breadCrumbs.find((item) => item.type === 'pit')
            ? breadCrumbs.find((item) => item.type === 'pit').name
            : '-'
        sheet.getCell('D4').value = 'Table:'
        sheet.getCell('E4').value = breadCrumbs.find((item) => item.type === 'table')
            ? breadCrumbs.find((item) => item.type === 'table').name
            : '-'

        // Trend Distribution
        if (trendCounts.length) {
            sheet.getCell('A8').value = 'Trend Distribution'
            sheet.mergeCells('A8:B8')

            sheet.addTable({
                name: 'Trends_Distribution',
                ref: 'A9',
                columns: [{ name: 'Trend' }, { name: 'Count' }],
                rows: sortedTrends.map((trend) => {
                    let arr = []

                    const result = _.omit(trend, 'qry')
                    arr.push(result.label, result.value)

                    return arr
                }),
            })
        }

        // Banker vs Player
        if (
            ((isTable && gameType === 'baccarat') ||
                (!isTable && gameVariantFilter.toLowerCase().includes('baccarat'))) &&
            gamesSideCounts.length
        ) {
            sheet.getCell('G8').value = 'Banker vs Player'
            sheet.mergeCells('G8:H8')

            sheet.addTable({
                name: 'Banker_Vs_Player',
                ref: 'G9',
                columns: [{ name: 'Side' }, { name: 'Percentage' }],
                rows: !isTable
                    ? getGamesSideCountsByVariant(gameVariantFilter).map((trend) => {
                        let arr = []

                        const result = _.omit(trend, 'qry')
                        arr.push(
                            result.label,
                            formatPresicePercentString(result.value / getNumberOfGamesByVariant(gameVariantFilter))
                        )

                        return arr
                    })
                    : gamesSideCounts.map((trend) => {
                        let arr = []

                        const result = _.omit(trend, 'qry')
                        arr.push(result.label, formatPresicePercentString(result.value / totalBaccaratGames))

                        return arr
                    }),
            })
        }

        // Top 5 Trends
        if (
            ((isTable && gameType === 'baccarat') ||
                (!isTable && gameVariantFilter.toLowerCase().includes('baccarat'))) &&
            trendCounts.length
        ) {
            sheet.getCell('D8').value = 'Top 5 Trends'
            sheet.mergeCells('D8:E8')

            sheet.addTable({
                name: 'Top_5_Trends',
                ref: 'D9',
                columns: [{ name: 'Trend' }, { name: 'Count' }],
                rows: top5Trends.map((trend) => {
                    let arr = []

                    const result = _.omit(trend, 'qry')
                    arr.push(result.label, result.value)

                    return arr
                }),
            })
        }

        // Odd vs Even
        if (
            ((isTable && gameType === 'roulette') ||
                (!isTable && gameVariantFilter.toLowerCase().includes('roulette'))) &&
            trendCounts.length
        ) {
            sheet.getCell('D8').value = 'Odd vs Even'
            sheet.mergeCells('D8:E8')

            sheet.addTable({
                name: 'Odd_Vs_Even',
                ref: 'D9',
                columns: [{ name: 'Side' }, { name: 'Percentage' }],
                rows: getVesusObj(oddVsEven).map((trend) => {
                    let arr = []

                    arr.push(trend.label, trend.percentage)

                    return arr
                }),
            })
        }

        // Red vs Black vs Green
        if (
            ((isTable && gameType === 'roulette') ||
                (!isTable && gameVariantFilter.toLowerCase().includes('roulette'))) &&
            trendCounts.length
        ) {
            sheet.getCell('G8').value = 'Red vs Black vs Green'
            sheet.mergeCells('G8:H8')

            sheet.addTable({
                name: 'Red_Vs_Black_Vs_Green',
                ref: 'G9',
                columns: [{ name: 'Side' }, { name: 'Percentage' }],
                rows: getVesusObj(redVsBlackVsGreen).map((trend) => {
                    let arr = []

                    arr.push(trend.label, trend.percentage)

                    return arr
                }),
            })
        }

        // 1-18 vs 19-36
        if (
            ((isTable && gameType === 'roulette') ||
                (!isTable && gameVariantFilter.toLowerCase().includes('roulette'))) &&
            trendCounts.length
        ) {
            sheet.getCell('J8').value = '1-18 vs 19-36'
            sheet.mergeCells('J8:K8')

            sheet.addTable({
                name: 'range18_Vs_range36',
                ref: 'J9',
                columns: [{ name: 'Side' }, { name: 'Percentage' }],
                rows: getVesusObj(range18Vsrange36).map((trend) => {
                    let arr = []

                    arr.push(trend.label, trend.percentage)

                    return arr
                }),
            })
        }

        // Dealer vs. Player (Ante Win)
        if (
            ((isTable && gameType === 'war') || (!isTable && gameVariantFilter.toLowerCase().includes('war'))) &&
            trendCounts.length
        ) {
            sheet.getCell('D8').value = 'Dealer vs. Player (Ante Win)'
            sheet.mergeCells('D8:E8')

            sheet.addTable({
                name: 'Dealer_Vs_Player_Vs_Tie_Ante',
                ref: 'D9',
                columns: [{ name: 'Side' }, { name: 'Percentage' }],
                rows: getVesusObj(dealerVsPlayerVsTieAnte).map((trend) => {
                    let arr = []

                    arr.push(trend.label, trend.percentage)

                    return arr
                }),
            })
        }

        // Dealer vs. Player (War Win)
        if (
            ((isTable && gameType === 'war') || (!isTable && gameVariantFilter.toLowerCase().includes('war'))) &&
            trendCounts.length
        ) {
            sheet.getCell('G8').value = 'Dealer vs. Player (War Win)'
            sheet.mergeCells('G8:H8')

            sheet.addTable({
                name: 'Dealer_Vs_Player_Vs_Tie_War',
                ref: 'G9',
                columns: [{ name: 'Side' }, { name: 'Percentage' }],
                rows: getVesusObj(dealerVsPlayerVsTieWar).map((trend) => {
                    let arr = []

                    arr.push(trend.label, trend.percentage)

                    return arr
                }),
            })
        }

        const buffer = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buffer]), `Game Trends_${moment(new Date()).format('YYYYMMDDHHmmss')}.xlsx`)
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    // this detects the hotreload from react
    if (treeLoading && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    const fluid = width < 1900

    if (settings && !settings.data.xtable.sections.includes('trends')) {
        return <Navigate to='/dashboard' />
    }

    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: 320 }}>
                <Tree
                    loading={treeLoading}
                    autoUpdateEnabled={true}
                    dateChange={dateChange}
                    startDate={startDate}
                    endDate={endDate}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    setLocation={(newLocation) => {
                        if (newLocation.gameType !== gameType || newLocation.gameVariant !== gameVariant) {
                            setTrendCounts(null)
                            setGamesSideCounts(null)
                            setFetchedTrendCounts(false)
                            setFetchedGamesSideCounts(false)
                        }
                        setLocation(newLocation)
                    }}
                    location={location}
                    fluid={fluid}
                    xstadium={xstadium}
                />
            </Grid>
            <Grid item xs>
                <Location
                    loading={treeLoading}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    location={location}
                    xstadium={xstadium}
                    fluid={fluid}
                    filter
                />
                {((!trendCounts && isTable) || !tablesReport) && !treeLoading && (
                    <Container fluid={fluid}>
                        <Row xs='1'>
                            <Col>
                                <h3>Fetching report...</h3>
                                <LinearProgress />
                            </Col>
                        </Row>
                    </Container>
                )}
                {!isTable &&
                    !gameVariantFilter &&
                    location &&
                    !treeLoading &&
                    !resizing &&
                    tablesReport &&
                    (trendCounts || !isTable) &&
                    sortedTrends && (
                        <Container fluid={fluid}>
                            <Row xs='1'>
                                <Col>
                                    <h3>Please choose a game type variant to view Trends data</h3>
                                </Col>
                            </Row>
                        </Container>
                    )}
                {((!isTable && gameVariantFilter) || isTable) &&
                    location &&
                    !treeLoading &&
                    !resizing &&
                    tablesReport &&
                    (trendCounts || !isTable) &&
                    sortedTrends && (
                        <Container fluid={fluid}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader style={styles.cardHeader}>
                                            <span style={styles.cardTitleText}># of games</span>
                                        </CardHeader>
                                        <CardBody style={styles.cardBody}>
                                            <span style={styles.cardBodyText}>
                                                {!isTable ? getNumberOfGamesByVariant(gameVariantFilter) : totalGames}
                                            </span>
                                        </CardBody>
                                    </Card>
                                </Col>
                                {((!isTable && bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6') ||
                                    (isTable && gameType === 'baccarat' && gameVariant === 'lucky6')) && (
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Lucky6 wins</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    {trendCounts && (
                                                        <span style={styles.cardBodyText}>{findTrendCount('lucky6')}</span>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                {((!isTable &&
                                    bacLucky6SlotIPs.length > 0 &&
                                    gameVariantFilter === 'baccaratLucky6Slot') ||
                                    (isTable && gameType === 'baccarat' && gameVariant === 'lucky6Slot')) && (
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Lucky6 wins</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    {trendCounts && (
                                                        <span style={styles.cardBodyText}>{findTrendCount('lucky6')}</span>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                {((!isTable && bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6') ||
                                    (isTable && gameType === 'baccarat' && gameVariant === 'mega6')) && (
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Mega6 wins</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    {trendCounts && (
                                                        <span style={styles.cardBodyText}>{findTrendCount('mega6')}</span>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                            </Row>
                            {!isTable &&
                                ((bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6') ||
                                    (bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot')) &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Frequent Trends</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('frequentTrends', value)
                                                        }
                                                        builtChart={builtCharts['frequentTrends']}
                                                        findInLabelMap={baccaratPerTier3TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratTier3TrendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6' &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Frequent Trends</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('frequentTrends', value)
                                                        }
                                                        builtChart={builtCharts['frequentTrends']}
                                                        findInLabelMap={baccaratPerTier3TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6Tier3trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6Slot' &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Frequent Trends</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('frequentTrends', value)
                                                        }
                                                        builtChart={builtCharts['frequentTrends']}
                                                        findInLabelMap={baccaratPerTier3TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6SlotTier3trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {!isTable &&
                                ((bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6') ||
                                    (bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot')) &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Rare Trends</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('lucky6RareTrends', value)
                                                        }
                                                        builtChart={builtCharts['lucky6RareTrends']}
                                                        findInLabelMap={baccaratPerTier5TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratTier5TrendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6' &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Rare Trends</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('lucky6RareTrends', value)
                                                        }
                                                        builtChart={builtCharts['lucky6RareTrends']}
                                                        findInLabelMap={baccaratPerTier5TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6Tier5trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6Slot' &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Rare Trends</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('lucky6RareTrends', value)
                                                        }
                                                        builtChart={builtCharts['lucky6RareTrends']}
                                                        findInLabelMap={baccaratPerTier5TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6SlotTier5trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {!_.isEmpty(trendCounts) && (
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Trends Distribution</span>
                                                <Button
                                                    variant='outlined'
                                                    sx={{ height: '2.35rem' }}
                                                    onClick={() => onExportClick()}
                                                >
                                                    Export
                                                </Button>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <Chart
                                                    options={{
                                                        chart: {
                                                            height: sortedTrends.length * 25,
                                                            type: 'bar',
                                                            toolbar: {
                                                                show: false,
                                                            },
                                                        },
                                                        tooltip: {
                                                            theme: 'dark',
                                                        },
                                                        title: {
                                                            text: '',
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                borderRadius: 4,
                                                                horizontal: true,
                                                                distributed: true,
                                                            },
                                                        },
                                                        dataLabels: {
                                                            enabled: false,
                                                        },
                                                        xaxis: {
                                                            categories: sortedTrends.map((t) => t.label),
                                                        },
                                                    }}
                                                    series={[
                                                        {
                                                            name: '',
                                                            data: sortedTrends.map((t) => t.value),
                                                        },
                                                    ]}
                                                    type='bar'
                                                    height={sortedTrends.length * 25}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                            <Row xs='3'>
                                {!isTable &&
                                    ((bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard') ||
                                        (bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6') ||
                                        (bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot') ||
                                        (bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6') ||
                                        (bacTigerIPs.length > 0 && gameVariantFilter === 'baccaratTiger')) && (
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Banker vs Player</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    {gamesSideCounts && (
                                                        <div styles={styles.pieContainer}>
                                                            <Pie
                                                                data={{
                                                                    labels: baccaratStandardCharPerTypeLabels,
                                                                    datasets: [
                                                                        {
                                                                            label: 'Banker vs Player',
                                                                            data: [
                                                                                formatNumber(findTrendSide('Banker')),
                                                                                formatNumber(findTrendSide('Player')),
                                                                                formatNumber(findTrendSide('Tie')),
                                                                            ],
                                                                            backgroundColor: chartBackgroundColors,
                                                                            borderColor: chartBackgroundColors,
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                }}
                                                                options={{
                                                                    plugins: {
                                                                        legend: false,
                                                                    },
                                                                    responsive: true,
                                                                    maintainAspectRatio: true,
                                                                }}
                                                            />

                                                            <DisplayBetTypeBPTBreakdown
                                                                banker={formatPresicePercentString(
                                                                    formatNumber(findTrendSide('Banker')) /
                                                                    getNumberOfGamesByVariant(gameVariantFilter)
                                                                )}
                                                                player={formatPresicePercentString(
                                                                    formatNumber(findTrendSide('Player')) /
                                                                    getNumberOfGamesByVariant(gameVariantFilter)
                                                                )}
                                                                tie={formatPresicePercentString(
                                                                    formatNumber(findTrendSide('Tie')) /
                                                                    getNumberOfGamesByVariant(gameVariantFilter)
                                                                )}
                                                            ></DisplayBetTypeBPTBreakdown>
                                                        </div>
                                                    )}
                                                    {!gamesSideCounts && (
                                                        <div>
                                                            <h4>No data</h4>
                                                        </div>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                {gameType === 'baccarat' && gameVariant === 'standard' && (
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Banker vs Player</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                {gamesSideCounts && (
                                                    <div styles={styles.pieContainer}>
                                                        <Pie
                                                            data={{
                                                                labels: baccaratStandardCharPerTypeLabels,
                                                                datasets: [
                                                                    {
                                                                        label: 'Banker vs Player',
                                                                        data: [
                                                                            formatNumber(findTrendSide('Banker')),
                                                                            formatNumber(findTrendSide('Player')),
                                                                            formatNumber(findTrendSide('Tie')),
                                                                        ],
                                                                        backgroundColor: chartBackgroundColors,
                                                                        borderColor: chartBackgroundColors,
                                                                        borderWidth: 1,
                                                                    },
                                                                ],
                                                            }}
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: true,
                                                            }}
                                                        />

                                                        <DisplayBetTypeBPTBreakdown
                                                            banker={formatPresicePercentString(
                                                                findTrendSide('Banker') / totalBaccaratStandardGames
                                                            )}
                                                            player={formatPresicePercentString(
                                                                findTrendSide('Player') / totalBaccaratStandardGames
                                                            )}
                                                            tie={formatPresicePercentString(
                                                                findTrendSide('Tie') / totalBaccaratStandardGames
                                                            )}
                                                        ></DisplayBetTypeBPTBreakdown>
                                                    </div>
                                                )}
                                                {!gamesSideCounts && (
                                                    <div>
                                                        <h4>No data</h4>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                                {gameType === 'baccarat' && gameVariant === 'lucky6' && (
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Banker vs Player</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                {gamesSideCounts && (
                                                    <div styles={styles.pieContainer}>
                                                        <Pie
                                                            data={{
                                                                labels: baccaratLucky6CharPerTypeLabels,
                                                                datasets: [
                                                                    {
                                                                        label: 'Banker vs Player',
                                                                        data: [
                                                                            formatNumber(findTrendSide('Banker')),
                                                                            formatNumber(findTrendSide('Player')),
                                                                            formatNumber(findTrendSide('Tie')),
                                                                        ],
                                                                        backgroundColor: chartBackgroundColors,
                                                                        borderColor: chartBackgroundColors,
                                                                        borderWidth: 1,
                                                                    },
                                                                ],
                                                            }}
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: true,
                                                            }}
                                                        />

                                                        <DisplayBetTypeBPTBreakdown
                                                            banker={formatPresicePercentString(
                                                                findTrendSide('Banker') / totalBaccaratLucky6Games
                                                            )}
                                                            player={formatPresicePercentString(
                                                                findTrendSide('Player') / totalBaccaratLucky6Games
                                                            )}
                                                            tie={formatPresicePercentString(
                                                                findTrendSide('Tie') / totalBaccaratLucky6Games
                                                            )}
                                                        ></DisplayBetTypeBPTBreakdown>
                                                    </div>
                                                )}
                                                {!gamesSideCounts && (
                                                    <div>
                                                        <h4>No data</h4>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                                {gameType === 'baccarat' && gameVariant === 'lucky6Slot' && (
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Banker vs Player</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                {gamesSideCounts && (
                                                    <div styles={styles.pieContainer}>
                                                        <Pie
                                                            data={{
                                                                labels: baccaratLucky6CharPerTypeLabels,
                                                                datasets: [
                                                                    {
                                                                        label: 'Banker vs Player',
                                                                        data: [
                                                                            formatNumber(findTrendSide('Banker')),
                                                                            formatNumber(findTrendSide('Player')),
                                                                            formatNumber(findTrendSide('Tie')),
                                                                        ],
                                                                        backgroundColor: chartBackgroundColors,
                                                                        borderColor: chartBackgroundColors,
                                                                        borderWidth: 1,
                                                                    },
                                                                ],
                                                            }}
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: true,
                                                            }}
                                                        />

                                                        <DisplayBetTypeBPTBreakdown
                                                            banker={formatPresicePercentString(
                                                                findTrendSide('Banker') / totalBaccaratLucky6SlotGames
                                                            )}
                                                            player={formatPresicePercentString(
                                                                findTrendSide('Player') / totalBaccaratLucky6SlotGames
                                                            )}
                                                            tie={formatPresicePercentString(
                                                                findTrendSide('Tie') / totalBaccaratLucky6SlotGames
                                                            )}
                                                        ></DisplayBetTypeBPTBreakdown>
                                                    </div>
                                                )}
                                                {!gamesSideCounts && (
                                                    <div>
                                                        <h4>No data</h4>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                                {gameType === 'baccarat' && gameVariant === 'mega6' && (
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Banker vs Player</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                {gamesSideCounts && (
                                                    <div styles={styles.pieContainer}>
                                                        <Pie
                                                            data={{
                                                                labels: baccaratMega6CharPerTypeLabels,
                                                                datasets: [
                                                                    {
                                                                        label: 'Banker vs Player',
                                                                        data: [
                                                                            formatNumber(findTrendSide('Banker')),
                                                                            formatNumber(findTrendSide('Player')),
                                                                            formatNumber(findTrendSide('Tie')),
                                                                        ],
                                                                        backgroundColor: chartBackgroundColors,
                                                                        borderColor: chartBackgroundColors,
                                                                        borderWidth: 1,
                                                                    },
                                                                ],
                                                            }}
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: true,
                                                            }}
                                                        />

                                                        <DisplayBetTypeBPTBreakdown
                                                            banker={formatPresicePercentString(
                                                                findTrendSide('Banker') / totalBaccaratMega6Games
                                                            )}
                                                            player={formatPresicePercentString(
                                                                findTrendSide('Player') / totalBaccaratMega6Games
                                                            )}
                                                            tie={formatPresicePercentString(
                                                                findTrendSide('Tie') / totalBaccaratMega6Games
                                                            )}
                                                        ></DisplayBetTypeBPTBreakdown>
                                                    </div>
                                                )}
                                                {!gamesSideCounts && (
                                                    <div>
                                                        <h4>No data</h4>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                                {gameType === 'baccarat' && gameVariant === 'tiger' && (
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Banker vs Player</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                {gamesSideCounts && (
                                                    <div styles={styles.pieContainer}>
                                                        <Pie
                                                            data={{
                                                                labels: baccaratTigerCharPerTypeLabels,
                                                                datasets: [
                                                                    {
                                                                        label: 'Banker vs Player',
                                                                        data: [
                                                                            formatNumber(findTrendSide('Banker')),
                                                                            formatNumber(findTrendSide('Player')),
                                                                            formatNumber(findTrendSide('Tie')),
                                                                        ],
                                                                        backgroundColor: chartBackgroundColors,
                                                                        borderColor: chartBackgroundColors,
                                                                        borderWidth: 1,
                                                                    },
                                                                ],
                                                            }}
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: true,
                                                            }}
                                                        />

                                                        <DisplayBetTypeBPTBreakdown
                                                            banker={formatPresicePercentString(
                                                                findTrendSide('Banker') / totalBaccaratTigerGames
                                                            )}
                                                            player={formatPresicePercentString(
                                                                findTrendSide('Player') / totalBaccaratTigerGames
                                                            )}
                                                            tie={formatPresicePercentString(
                                                                findTrendSide('Tie') / totalBaccaratTigerGames
                                                            )}
                                                        ></DisplayBetTypeBPTBreakdown>
                                                    </div>
                                                )}
                                                {!gamesSideCounts && (
                                                    <div>
                                                        <h4>No data</h4>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                                {((!isTable && gameVariantFilter === 'rouletteStandard') ||
                                    (isTable && gameType === 'roulette' && gameVariant === 'standard')) && (
                                        <>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>Odd vs Even vs Zero(s)</span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        {!_.isEmpty(trendCounts) && (
                                                            <div styles={styles.pieContainer}>
                                                                <Pie
                                                                    data={{
                                                                        labels: ['Odd', 'Even', 'Zero(s)'],
                                                                        datasets: [
                                                                            {
                                                                                label: 'Odd vs Even',
                                                                                data: oddVsEven.map((v) => v.value),
                                                                                backgroundColor: chartBackgroundColors,
                                                                                borderColor: chartBackgroundColors,
                                                                                borderWidth: 1,
                                                                            },
                                                                        ],
                                                                    }}
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: true,
                                                                    }}
                                                                />

                                                                <TrendsVersusBreakdown data={getVesusObj(oddVsEven)} />
                                                            </div>
                                                        )}
                                                        {!gamesSideCounts && (
                                                            <div>
                                                                <h4>No data</h4>
                                                            </div>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>Red vs Black vs Green</span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        {!_.isEmpty(trendCounts) && (
                                                            <div styles={styles.pieContainer}>
                                                                <Pie
                                                                    data={{
                                                                        labels: ['Red', 'Black', 'Green'],
                                                                        datasets: [
                                                                            {
                                                                                label: 'Red vs Black vs Green',
                                                                                data: redVsBlackVsGreen.map((v) => v.value),
                                                                                backgroundColor: chartBackgroundColors,
                                                                                borderColor: chartBackgroundColors,
                                                                                borderWidth: 1,
                                                                            },
                                                                        ],
                                                                    }}
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: true,
                                                                    }}
                                                                />

                                                                <TrendsVersusBreakdown
                                                                    data={getVesusObj(redVsBlackVsGreen)}
                                                                />
                                                            </div>
                                                        )}
                                                        {!gamesSideCounts && (
                                                            <div>
                                                                <h4>No data</h4>
                                                            </div>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>1-18 vs 19-36 vs Zero(s)</span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        {!_.isEmpty(trendCounts) && (
                                                            <div styles={styles.pieContainer}>
                                                                <Pie
                                                                    data={{
                                                                        labels: ['1-18', '19-36', 'Zero(s)'],
                                                                        datasets: [
                                                                            {
                                                                                label: '1-18 vs 19-36',
                                                                                data: range18Vsrange36.map((v) => v.value),
                                                                                backgroundColor: chartBackgroundColors,
                                                                                borderColor: chartBackgroundColors,
                                                                                borderWidth: 1,
                                                                            },
                                                                        ],
                                                                    }}
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: true,
                                                                    }}
                                                                />

                                                                <TrendsVersusBreakdown
                                                                    data={getVesusObj(range18Vsrange36)}
                                                                />
                                                            </div>
                                                        )}
                                                        {!gamesSideCounts && (
                                                            <div>
                                                                <h4>No data</h4>
                                                            </div>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </>
                                    )}
                                {((!isTable && gameVariantFilter === 'warEtg') ||
                                    (isTable && gameType === 'war' && gameVariant === 'etg')) && (
                                        <>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>
                                                            Dealer vs. Player (Ante Win)
                                                        </span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        {!_.isEmpty(trendCounts) && (
                                                            <div styles={styles.pieContainer}>
                                                                <Pie
                                                                    data={{
                                                                        labels: ['Dealer', 'Player', 'Tie'],
                                                                        datasets: [
                                                                            {
                                                                                label: 'Dealer vs Player vs Tie',
                                                                                data: dealerVsPlayerVsTieAnte.map(
                                                                                    (v) => v.value
                                                                                ),
                                                                                backgroundColor: chartBackgroundColors,
                                                                                borderColor: chartBackgroundColors,
                                                                                borderWidth: 1,
                                                                            },
                                                                        ],
                                                                    }}
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: true,
                                                                    }}
                                                                />

                                                                <TrendsVersusBreakdown
                                                                    data={getVesusObj(dealerVsPlayerVsTieAnte)}
                                                                />
                                                            </div>
                                                        )}
                                                        {!gamesSideCounts && (
                                                            <div>
                                                                <h4>No data</h4>
                                                            </div>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>
                                                            Dealer vs. Player (War Win)
                                                        </span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        {!_.isEmpty(trendCounts) && (
                                                            <div styles={styles.pieContainer}>
                                                                <Pie
                                                                    data={{
                                                                        labels: ['Dealer', 'Player', 'Tie'],
                                                                        datasets: [
                                                                            {
                                                                                label: 'Dealer vs Player vs Tie',
                                                                                data: dealerVsPlayerVsTieWar.map(
                                                                                    (v) => v.value
                                                                                ),
                                                                                backgroundColor: chartBackgroundColors,
                                                                                borderColor: chartBackgroundColors,
                                                                                borderWidth: 1,
                                                                            },
                                                                        ],
                                                                    }}
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: true,
                                                                    }}
                                                                />

                                                                <TrendsVersusBreakdown
                                                                    data={getVesusObj(dealerVsPlayerVsTieWar)}
                                                                />
                                                            </div>
                                                        )}
                                                        {!gamesSideCounts && (
                                                            <div>
                                                                <h4>No data</h4>
                                                            </div>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </>
                                    )}
                                {((!isTable &&
                                    gameVariantFilter !== 'blackjackStandard' &&
                                    gameVariantFilter !== 'blackjackAnyPairSlot' &&
                                    gameVariantFilter !== 'rouletteStandard' &&
                                    gameVariantFilter !== 'warEtg') ||
                                    isTable) &&
                                    !_.isEmpty(gamesSideCounts) && (
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Top 5 Trends</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    {gamesSideCounts && (
                                                        <div styles={styles.pieContainer}>
                                                            <Pie
                                                                data={{
                                                                    labels: top5Trends.map((tv) => tv.label),
                                                                    datasets: [
                                                                        {
                                                                            label: 'Top 5 Trends',
                                                                            data: top5Trends.map((tv) => tv.value),
                                                                            backgroundColor: gradient5Colors,
                                                                            borderColor: gradient5Colors,
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                }}
                                                                options={{
                                                                    plugins: {
                                                                        legend: false,
                                                                    },
                                                                    responsive: true,
                                                                    maintainAspectRatio: true,
                                                                }}
                                                            />

                                                            <DisplayTop5Breakdown
                                                                top5={top5Trends}
                                                            ></DisplayTop5Breakdown>
                                                        </div>
                                                    )}
                                                    {!gamesSideCounts && (
                                                        <div>
                                                            <h4>No data</h4>
                                                        </div>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                            </Row>
                            {!isTable &&
                                ((bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard') ||
                                    (bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6') ||
                                    (bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot') ||
                                    (bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6') ||
                                    (bacTigerIPs.length > 0 && gameVariantFilter === 'baccaratTiger')) &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Baccarat Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratGames', value)
                                                        }
                                                        builtChart={builtCharts['baccaratGames']}
                                                        findInLabelMap={() => 'games history'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratGamesHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'standard' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Baccarat Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratStandardGames', value)
                                                        }
                                                        builtChart={builtCharts['baccaratStandardGames']}
                                                        findInLabelMap={() => 'games history'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratStandardGamesHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Baccarat Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6Games', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6Games']}
                                                        findInLabelMap={() => 'games history'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6GamesHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6Slot' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Baccarat Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6SlotGames', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6SlotGames']}
                                                        findInLabelMap={() => 'games history'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6SlotGamesHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'mega6' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Baccarat Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratMega6Games', value)
                                                        }
                                                        builtChart={builtCharts['baccaratMega6Games']}
                                                        findInLabelMap={() => 'games history'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratMega6GamesHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'tiger' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Baccarat Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratTigerGames', value)
                                                        }
                                                        builtChart={builtCharts['baccaratTigerGames']}
                                                        findInLabelMap={() => 'games history'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratTigerGamesHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {tablesReport &&
                                numberOfDays > 1 &&
                                ((!isTable && bjStandardIPs.length > 0 && gameVariantFilter === 'blackjackStandard') ||
                                    (isTable && gameType === 'blackjack' && gameVariant === 'standard')) && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Blackjack Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('blackjackStandardGames', value)
                                                        }
                                                        builtChart={builtCharts['blackjackStandardGames']}
                                                        findInLabelMap={() => 'games history'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={blackjackStandardGamesHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {tablesReport &&
                                numberOfDays > 1 &&
                                ((!isTable &&
                                    bjAnyPairSlotIPs.length > 0 &&
                                    gameVariantFilter === 'blackjackAnyPairSlot') ||
                                    (isTable && gameType === 'blackjack' && gameVariant === 'anyPairSlot')) && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Blackjack Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('blackjackAnyPairSlotGames', value)
                                                        }
                                                        builtChart={builtCharts['blackjackAnyPairSlotGames']}
                                                        findInLabelMap={() => 'games history'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={blackjackAnyPairSlotGamesHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {!isTable &&
                                ((bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard') ||
                                    (bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6') ||
                                    (bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot') ||
                                    (bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6') ||
                                    (bacTigerIPs.length > 0 && gameVariantFilter === 'baccaratTiger')) &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player streaks
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratStreakTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratStreakTrends']}
                                                        findInLabelMap={baccaratPerTier1TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratTier1TrendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'standard' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player streaks
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratStandardStreakTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratStandardStreakTrends']}
                                                        findInLabelMap={baccaratPerTier1TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratStandardTier1trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player streaks
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6StreakTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6StreakTrends']}
                                                        findInLabelMap={baccaratPerTier1TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6Tier1trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6Slot' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player streaks
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6SlotStreakTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6SlotStreakTrends']}
                                                        findInLabelMap={baccaratPerTier1TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6SlotTier1trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'mega6' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player streaks
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratMega6StreakTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratMega6StreakTrends']}
                                                        findInLabelMap={baccaratPerTier1TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratMega6Tier1trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'tiger' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player streaks
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratTigerStreakTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratTigerStreakTrends']}
                                                        findInLabelMap={baccaratPerTier1TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratTigerTier1trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {!isTable &&
                                ((bacStandardIPs.length > 0 && gameVariantFilter === 'baccaratStandard') ||
                                    (bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6') ||
                                    (bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot') ||
                                    (bacMega6IPs.length > 0 && gameVariantFilter === 'baccaratMega6')) &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player pairs
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratPairTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratPairTrends']}
                                                        findInLabelMap={baccaratPerTier2TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratTier2TrendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'standard' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player pairs
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratStandardPairTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratStandardPairTrends']}
                                                        findInLabelMap={baccaratPerTier2TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratStandardTier2trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player pairs
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6PairTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6PairTrends']}
                                                        findInLabelMap={baccaratPerTier2TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6Tier2trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6Slot' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player pairs
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6SlotPairTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6SlotPairTrends']}
                                                        findInLabelMap={baccaratPerTier2TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6SlotTier2trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'mega6' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player pairs
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratMega6PairTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratMega6PairTrends']}
                                                        findInLabelMap={baccaratPerTier2TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratMega6Tier2trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {!isTable &&
                                ((bacLucky6IPs.length > 0 && gameVariantFilter === 'baccaratLucky6') ||
                                    (bacLucky6SlotIPs.length > 0 && gameVariantFilter === 'baccaratLucky6Slot')) &&
                                tablesReport &&
                                numberOfDays > 1 && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player dragon
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6DragonTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6DragonTrends']}
                                                        findInLabelMap={perTier4TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratTier4TrendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player dragon
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6DragonTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6DragonTrends']}
                                                        findInLabelMap={perTier4TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6Tier4trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            {isTable &&
                                tablesReport &&
                                numberOfDays > 1 &&
                                gameType === 'baccarat' &&
                                gameVariant === 'lucky6Slot' && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>
                                                        Baccarat Banker vs Player dragon
                                                    </span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('baccaratLucky6SlotDragonTrends', value)
                                                        }
                                                        builtChart={builtCharts['baccaratLucky6SlotDragonTrends']}
                                                        findInLabelMap={perTier4TrendTypeLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={baccaratLucky6SlotTier4trendsHistoryQuery}
                                                    ></TableHistoryChart>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                        </Container>
                    )}
            </Grid>
        </Grid>
    )
}

export default Trends
