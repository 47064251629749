/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext, useReducer } from "react";
import { get } from "lodash";
import { css, jsx } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

import { formatCashString } from "../util/misc";
import { DUIContext } from "./Dealer";

const CashDrop = () => {
  const {
    device,
    initialDialogObj,
    dialogObj,
    setDialogObj,
    setShowCashDrop,
    callApiPublishCashdrop,
  } = useContext(DUIContext);

  const [inputState, setInputState] = useReducer((state, obj) => {
    if (obj.key === "D")
      return state.substring(0, state.length - 1).length > 0
        ? state.substring(0, state.length - 1)
        : "0";
    if (state === "0") return obj.key;
    return state + "" + obj.key;
  }, "0");

  const keyPress = (key) => {
    if (key === "E") {
      if (inputState === "0") {
        setDialogObj({
          ...dialogObj,
          show: true,
          type: "reminder",
          message: "Please input at least $1.",
          error: true,
        });
        return;
      }

      setDialogObj({
        ...dialogObj,
        show: true,
        type: "confirmation",
        message: "Cash drop amount: $" + formatCashString(Number(inputState)),
        requirePitBoss:
          get(device, "data.buyInConfirmation", false) &&
          Number(inputState) >= get(device, "data.buyInThreshold", 0)
            ? true
            : false,
        error: false,
        confirm: () =>
          callApiPublishCashdrop({
            data: {
              deviceID: device.index,
              dealerID: window.localStorage.getItem("account"),
              playerID: dialogObj.playerID,
              seatNumber: dialogObj.seatNumber,
              amount: Number(inputState),
            },
            success: () => {
              setDialogObj({
                ...dialogObj,
                show: true,
                type: "success",
                message: "Cash Drop success.",
              });
            },
            fail: () => {
              setDialogObj({
                ...dialogObj,
                show: true,
                type: "error",
                message:
                  "Cash Drop failed. Please try again or contact support for assistance.",
                error: true,
              });
            },
          }),
      });
      return;
    }
    setInputState({ key: key });
  };

  const handleClose = () => {
    setDialogObj(initialDialogObj);
    setShowCashDrop(false);
  };

  const button = css`
    width: 100%;
    height: 100%;
    font-size: 2vw;
    color: #fff;
    border-radius: 0;
    &:hover {
      background-color: #505050;
    }
  `;

  return (
    <React.Fragment>
      <div
        css={css`
          position: relative;
          width: 65vh;
          height: 65vh;
          background-color: #333;
          display: grid;
          grid-template-rows: repeat(5, 1fr);
          grid-template-columns: repeat(3, 1fr);
        `}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          onClick={handleClose}
          className="dialogOffTrigger"
          css={css`
            position: absolute;
            top: -7vh;
            right: 0;
            height: 80;
            width: 80;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          `}
        >
          <CloseIcon
            css={css`
              font-size: 7vh;
              color: white;
            `}
          />
        </div>

        <div
          css={css`
            grid-column: 1 / span 4;
            grid-row: 1 / span 1;
            background-color: #000;
          `}
        >
          <input
            css={css`
              height: 100%;
              width: 100%;
              padding: 10px;
              text-align: right;
              font-size: 3vw;
              box-sizing: border-box;
              background-color: #000;
              color: #fff;
              border: none;
              outline: none;
            `}
            value={"$" + formatCashString(inputState)}
            readOnly
          />
        </div>

        <div
          css={css`
            grid-row: 2 / span 1;
            grid-column: 1 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("7");
            }}
          >
            7
          </Button>
        </div>
        <div
          css={css`
            grid-row: 3 / span 1;
            grid-column: 1 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("4");
            }}
          >
            4
          </Button>
        </div>
        <div
          css={css`
            grid-row: 4 / span 1;
            grid-column: 1 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("1");
            }}
          >
            1
          </Button>
        </div>
        <div
          css={css`
            grid-row: 5 / span 1;
            grid-column: 1 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("D");
            }}
          >
            Del
          </Button>
        </div>

        <div
          css={css`
            grid-row: 2 / span 1;
            grid-column: 2 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("8");
            }}
          >
            8
          </Button>
        </div>
        <div
          css={css`
            grid-row: 3 / span 1;
            grid-column: 2 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("5");
            }}
          >
            5
          </Button>
        </div>
        <div
          css={css`
            grid-row: 4 / span 1;
            grid-column: 2 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("2");
            }}
          >
            2
          </Button>
        </div>
        <div
          css={css`
            grid-row: 5 / span 1;
            grid-column: 2 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("0");
            }}
          >
            0
          </Button>
        </div>

        <div
          css={css`
            grid-row: 2 / span 1;
            grid-column: 3 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("9");
            }}
          >
            9
          </Button>
        </div>
        <div
          css={css`
            grid-row: 3 / span 1;
            grid-column: 3 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("6");
            }}
          >
            6
          </Button>
        </div>
        <div
          css={css`
            grid-row: 4 / span 1;
            grid-column: 3 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("3");
            }}
          >
            3
          </Button>
        </div>
        <div
          css={css`
            grid-row: 5 / span 1;
            grid-column: 3 / span 1;
          `}
        >
          <Button
            css={button}
            onClick={() => {
              keyPress("E");
            }}
          >
            Enter
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CashDrop;
