/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useContext, useRef, useState } from "react"
import { css, jsx } from "@emotion/react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import KeyboardIcon from "@mui/icons-material/Keyboard"
import CreditCardIcon from "@mui/icons-material/CreditCard"

import { isUndefined } from "lodash"

import { DUIContext } from "./Dealer"

import { authApi } from "../../api"

const darkTheme = createTheme({ palette: { mode: "dark" } });

const PitBossAlertDialog = () => {
    const { t, dialogObj, setDialogObj } = useContext(DUIContext);
    const cardField = useRef(null);
    const [swipeCard, setSwipeCard] = useState("");
    const [useSwipeAuth, setUseSwipeAuth] = useState(true);
    const [pitbossAccount, setPitbossAccount] = useState("");
    const [pitbossPassword, setPitbossPassword] = useState("");

    const reFocusCardField = () => {
        if (useSwipeAuth && cardField.current) cardField.current.focus();
    };

    const handleCancel = () => {
        setDialogObj({
            ...dialogObj,
            show: false,
            type: "",
            message: "",
            requirePitBoss: false,
            error: false,
            confirm: () => { },
        });
    };

    const handleConfirm = async () => {
        try {
            const response = useSwipeAuth
                ? await authApi
                    .post("authorize/card", {
                        json: {
                            card: swipeCard.slice(1, swipeCard.length - 1),
                        },
                    })
                    .json()
                : await authApi
                    .post("authorize", {
                        json: {
                            account: pitbossAccount,
                            password: pitbossPassword,
                        },
                    })
                    .json();

            if (response.roles.indexOf('pitboss') >= 0) {
                if (
                    !isUndefined(dialogObj.confirm) &&
                    typeof dialogObj.confirm === "function"
                )
                    dialogObj.confirm();
            } else {
                setDialogObj({
                    ...dialogObj,
                    fieldErrMsg:
                        "You do not have the right permissions to proceed, please try again.",
                });
                setSwipeCard("");
                setPitbossAccount("");
                setPitbossPassword("");
                reFocusCardField();
                return;
            }
            setSwipeCard("");
            setPitbossAccount("");
            setPitbossPassword("");
            setDialogObj({ ...dialogObj, show: false });
        } catch (e) {
            setDialogObj({
                ...dialogObj,
                fieldErrMsg: "Failed to authorize, please try again.",
            });
            setSwipeCard("");
            setPitbossAccount("");
            setPitbossPassword("");
        }
        reFocusCardField();
    };

    // Rare case whereby on some certain card-readers, a half swipe will return a single 'F' char.
    // We should ignore this by reseting the value
    if (swipeCard === "F") {
        setDialogObj({
            ...dialogObj,
            fieldErrMsg: "Card swipe failed, please try again.",
        });
        setSwipeCard("");
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Dialog
                open={dialogObj.show}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm"
                PaperProps={{
                    sx: {
                        position: "absolute",
                        top: "2.5vh",
                    },
                }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                    css={css`
            font-size: 1.4rem;
          `}
                >
                    {dialogObj.type === "confirmation" && <div>Pitboss Confirmation</div>}
                    {dialogObj.type === "success" && <div>Note</div>}
                    {dialogObj.type === "reminder" && <div>Reminder</div>}
                    {dialogObj.type === "error" && <div>Error</div>}
                </DialogTitle>

                <DialogContent
                    css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
          `}
                >
                    <DialogContentText
                        id="alert-dialog-description"
                        css={css`
              font-size: 1.2rem;
            `}
                    >
                        {dialogObj.message}
                    </DialogContentText>

                    {dialogObj.type === "confirmation" && (
                        <DialogContentText
                            id="alert-dialog-description"
                            css={css`
                font-size: 1.2rem;
              `}
                        >
                            {t("dealer.pleaseSwipe")}
                        </DialogContentText>
                    )}

                    {dialogObj.type === "confirmation" && (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleConfirm();
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {useSwipeAuth && (
                                <div
                                    css={css`
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                  `}
                                >
                                    <div
                                        css={css`
                      width: 100%;
                      display: flex;
                      align-items: start;
                      justify-content: center;
                      flex-direction: column;
                    `}
                                    >
                                        <TextField
                                            required
                                            autoFocus
                                            InputLabelProps={{ shrink: true }}
                                            margin="dense"
                                            id="card"
                                            label="Card"
                                            type="password"
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e) => setSwipeCard(e.target.value)}
                                            value={swipeCard}
                                            error={swipeCard === ""}
                                            InputProps={{
                                                inputProps: { ref: cardField },
                                            }}
                                        />

                                        {dialogObj.fieldErrMsg && (
                                            <div
                                                css={css`
                          color: red;
                        `}
                                            >
                                                {dialogObj.fieldErrMsg}
                                            </div>
                                        )}
                                    </div>

                                    <KeyboardIcon
                                        onClick={() => setUseSwipeAuth(false)}
                                        css={css`
                      font-size: 3rem;
                    `}
                                    />
                                </div>
                            )}

                            {!useSwipeAuth && (
                                <div
                                    css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                  `}
                                >
                                    <div
                                        css={css`
                      width: 100%;
                      display: flex;
                      align-items: start;
                      justify-content: center;
                      flex-direction: column;
                    `}
                                    >
                                        <TextField
                                            required
                                            autoFocus
                                            InputLabelProps={{ shrink: true }}
                                            margin="dense"
                                            id="user"
                                            label="Account"
                                            type="text"
                                            fullWidth
                                            onChange={(e) => setPitbossAccount(e.target.value)}
                                            value={pitbossAccount}
                                            error={pitbossAccount === ""}
                                            variant="outlined"
                                        />
                                        <TextField
                                            required
                                            InputLabelProps={{ shrink: true }}
                                            margin="dense"
                                            id="pass"
                                            label="Password"
                                            type="password"
                                            onChange={(e) => setPitbossPassword(e.target.value)}
                                            value={pitbossPassword}
                                            error={pitbossPassword === ""}
                                            fullWidth
                                            variant="outlined"
                                        />

                                        {dialogObj.fieldErrMsg && (
                                            <div
                                                css={css`
                          color: red;
                        `}
                                            >
                                                {dialogObj.fieldErrMsg}
                                            </div>
                                        )}
                                    </div>

                                    <CreditCardIcon
                                        onClick={() => setUseSwipeAuth(true)}
                                        css={css`
                      font-size: 3rem;
                    `}
                                    />
                                </div>
                            )}
                        </form>
                    )}
                </DialogContent>

                <DialogActions>
                    {dialogObj.type === "confirmation" && (
                        <React.Fragment>
                            <Button
                                onClick={handleCancel}
                                css={css`
                  font-size: 1.1rem;
                `}
                                size="large"
                            >
                                {t("global.cancel")}
                            </Button>
                            <Button
                                onClick={handleConfirm}
                                css={css`
                  font-size: 1.1rem;
                `}
                                size="large"
                            >
                                {t("global.confirm")}
                            </Button>
                        </React.Fragment>
                    )}

                    {(dialogObj.type === "success" ||
                        dialogObj.type === "reminder" ||
                        dialogObj.type === "error") && (
                            <Button
                                onClick={handleCancel}
                                css={css`
                font-size: 1.1rem;
              `}
                                size="large"
                            >
                                OK
                            </Button>
                        )}
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default PitBossAlertDialog;
