import React, { useState, useReducer } from 'react'
import _, { startCase } from 'lodash'
import moment from 'moment'
import { Bar, Line, Pie } from 'react-chartjs-2'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Card, CardHeader, CardBody, Container, Row, Col, Progress } from 'reactstrap'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'

import { useSubscribe, tableApi, cashApi } from '../../api'
import { prometheusTable, prometheusTrend } from '../../config'
import Location from '../../tree/Location'
import Tree from '../../tree/Tree'
import { getGameType, getGameVariant, getDescendantDevices, getAllIPs } from '../../tree/TreeUtils'
import useWindowSize from '../../WindowSize'
import {
    formatCashString,
    formatCountString,
    formatPresicePercentNumber,
    formatNumber,
    chartBackgroundColors,
    hoursLabels,
    formatPresicePercentString,
    baccaratStandardPerLabelFind,
    baccaratStandardCharPerTypeLabels,
    DisplayBaccaratStandardBetTypeSimpleCashBreakdown,
    DisplayBaccaratStandardBetTypeBreakdown,
    DisplayBaccaratStandardBetTypeCashBreakdown,
    baccaratLucky6PerLabelFind,
    baccaratLucky6CharPerTypeLabels,
    DisplayBaccaratLucky6BetTypeSimpleCashBreakdown,
    DisplayBaccaratLucky6BetTypeBreakdown,
    DisplayBaccaratLucky6BetTypeCashBreakdown,
    baccaratMega6PerLabelFind,
    baccaratMega6CharPerTypeLabels,
    DisplayBaccaratMega6BetTypeSimpleCashBreakdown,
    DisplayBaccaratMega6BetTypeCashBreakdown,
    DisplayBaccaratMega6BetTypeBreakdown,
    baccaratTigerPerLabelFind,
    baccaratTigerCharPerTypeLabels,
    DisplayBaccaratTigerBetTypeSimpleCashBreakdown,
    DisplayBaccaratTigerBetTypeBreakdown,
    DisplayBaccaratTigerBetTypeCashBreakdown,
    blackjackStandardPerLabelFind,
    blackjackStandardCharPerTypeLabels,
    DisplayBlackjackStandardBetTypeSimpleCashBreakdown,
    DisplayBlackjackStandardBetTypeBreakdown,
    DisplayBlackjackStandardBetTypeCashBreakdown,
    DisplayBetTypeBPBreakdown,
} from '../Formats'
import TableHistoryChart from '../TableHistoryChart'

const styles = {
    pieContainer: {
        width: 325,
        height: 485,
        paddingTop: 35,
        margin: 'auto',
    },
    smallPieContainer: {
        width: 325,
        height: 285,
        paddingTop: 35,
        margin: 'auto',
    },
    barContainer: {
        width: 'inherit',
        height: 150,
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em',
    },
    cardTitleText: {
        fontSize: '1.2em',
        fontWeight: 'bold',
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardBodyTitle: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
    },
    cardBodyText: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red',
    }),
}

const GameTypeMetrics = ({ gameType, gameVariant, wager, winLoss, handleClick }) => {
    return (
        <Row>
            <Col>
                <Card style={{ backgroundColor: '#9A9A9A' }}>
                    <CardHeader>
                        <span
                            style={{
                                ...styles.cardTitleText,
                                fontSize: '1.4em',
                                color: '#2A2A2A',
                                textTransform: 'uppercase',
                            }}
                        >
                            {startCase(gameType)}-{startCase(gameVariant)}
                        </span>
                        <Button variant='contained' onClick={handleClick}>
                            View Details
                        </Button>
                    </CardHeader>
                    <CardBody style={{ padding: '1.4em' }}>
                        <Row lg='3'>
                            <Col>
                                <Card style={{ margin: '0' }}>
                                    <CardHeader>
                                        <span style={styles.cardTitleText}>Total Wager</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span style={styles.cardBodyText}>{formatCashString(wager)}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card style={{ margin: '0' }}>
                                    <CardHeader>
                                        <span style={styles.cardTitleText}>Total House Win/Loss</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span
                                            style={{
                                                ...styles.cardBodyText,
                                                ...styles.cashValue(winLoss),
                                            }}
                                        >
                                            {formatCashString(winLoss)}
                                        </span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card style={{ margin: '0' }}>
                                    <CardHeader>
                                        <span style={styles.cardTitleText}>Theoretical Hold</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span style={styles.cardBodyText}>
                                            {formatPresicePercentString(winLoss / wager)}
                                        </span>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

const Statistics = ({ xstadium }) => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')
    const URLlocationID = urlParams.get('locationID') || window.localStorage.getItem('url:locationID') || 'root'
    const URLlocationType = urlParams.get('locationType') || window.localStorage.getItem('url:locationType') || 'Casino'
    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }
    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        statusSocket.readyState === WebSocket.OPEN

    // fetched data
    const [allTablesReport, setAllTablesReport] = useState(null)
    const [allTablesCashReport, setAllTablesCashReport] = useState(null)
    const [baccaratStandardAllTablesReport, setBaccaratStandardAllTablesReport] = useState(null)
    const [baccaratLucky6AllTablesReport, setBaccaratLucky6AllTablesReport] = useState(null)
    const [baccaratMega6AllTablesReport, setBaccaratMega6AllTablesReport] = useState(null)
    const [baccaratTigerAllTablesReport, setBaccaratTigerAllTablesReport] = useState(null)
    const [blackjackStandardAllTablesReport, setBlackjackStandardAllTablesReport] = useState(null)
    const [fetchedTablesReport, setFetchedTablesReport] = useState(false)
    const [fetchedTablesCashReport, setFetchedTablesCashReport] = useState(false)
    const [fetchTablesCashReportProgress, setFetchTablesCashReportProgress] = useState(0)
    const [progressLoad, setProgressLoad] = useState(0)
    const [minLoadTime, setMinLoadTime] = useState(false)

    // built charts
    const [builtCharts, setBuiltCharts] = useState({})
    const setBuiltChartProxy = (chartID, value) => {
        setBuiltCharts((prevBuilt) => ({
            ...prevBuilt,
            [chartID]: value,
        }))
    }

    const path = xstadium ? 'xstadium' : 'xtable'

    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter((v) => v !== 'push')
        const oldParams = urlParams.toString()
        propKeys.forEach((k) => {
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange =
            propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        if (urlChange || props.push) {
            navigate(`/${path}/statistics?` + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                if (!props.push) {
                    setProgressLoad(0)
                    setAllTablesReport(null)
                    setBaccaratStandardAllTablesReport(null)
                    setBaccaratLucky6AllTablesReport(null)
                    setBaccaratMega6AllTablesReport(null)
                    setBaccaratTigerAllTablesReport(null)
                    setBlackjackStandardAllTablesReport(null)
                }
                setBuiltCharts({})
                setFetchedTablesReport(false)
            }
        }
    }

    const [location, setLocation] = useReducer((_state, obj) => {
        clearFetch({ locationID: obj.branchIndex, locationType: obj.branchType })
        return [obj]
    }, null)
    const isCasinoLevel = !location || (location && location[0].branchIndex === 'root')
    const isTable = _.get(location, ['0', 'branchType'], '') === 'Table' ? true : false
    const gameType = _.get(location, ['0', 'gameType'], '')
    const gameVariant = _.get(location, ['0', 'gameVariant'], '')

    const dateChange = (start, end, push) => {
        if (start === startDate && end === endDate && !push) return
        if (!push && (start !== startDate || end !== endDate)) {
            setStartDate(start)
            setEndDate(end)
        }
        clearFetch({ startDate: start, endDate: end, push: !!push })
    }

    const viewDetails = (gameType, gameVariant) => {
        navigate(`/${path}/statistics/${gameType}/${gameVariant}`)
    }

    const getDevicesReport = async () => {
        if (fetchedTablesReport) {
            return
        }

        setFetchedTablesReport(true)
        try {
            setProgressLoad(1)
            setTimeout(() => {
                setProgressLoad(11)
            }, 300)
            setTimeout(() => {
                setProgressLoad(22)
            }, 200)
            setTimeout(() => {
                setProgressLoad(33)
            }, 200)
            const responseOverview = await tableApi
                .get('report/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratStandard = await tableApi
                .get('report/baccarat/standard/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratLucky6 = await tableApi
                .get('report/baccarat/lucky6/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratMega6 = await tableApi
                .get('report/baccarat/mega6/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccaratTiger = await tableApi
                .get('report/baccarat/tiger/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBlackjackStandard = await tableApi
                .get('report/blackjack/standard/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            console.log('overview', responseOverview)
            console.log('baccarat/standard', responseBaccaratStandard)
            console.log('baccarat/lucky6', responseBaccaratLucky6)
            console.log('baccarat/mega6', responseBaccaratMega6)
            console.log('baccarat/tiger', responseBaccaratTiger)
            console.log('blackjack/standard', responseBlackjackStandard)
            setProgressLoad(40)
            setTimeout(() => {
                setProgressLoad(55)
            }, 300)
            setTimeout(() => {
                setProgressLoad(74)
            }, 600)
            setTimeout(() => {
                setProgressLoad(100)
            }, 900)
            setTimeout(() => {
                setAllTablesReport(responseOverview)
                setBaccaratStandardAllTablesReport(responseBaccaratStandard)
                setBaccaratLucky6AllTablesReport(responseBaccaratLucky6)
                setBaccaratMega6AllTablesReport(responseBaccaratMega6)
                setBaccaratTigerAllTablesReport(responseBaccaratTiger)
                setBlackjackStandardAllTablesReport(responseBlackjackStandard)
            }, 1000)
        } catch (e) {
            console.warn(e)
            setAllTablesReport([])
            setBaccaratStandardAllTablesReport([])
            setBaccaratLucky6AllTablesReport([])
            setBaccaratMega6AllTablesReport([])
            setBaccaratTigerAllTablesReport([])
            setBlackjackStandardAllTablesReport([])
        }
    }

    const getDevicesCashReport = async () => {
        if (fetchedTablesCashReport) {
            return
        }

        setFetchedTablesCashReport(true)
        try {
            setFetchTablesCashReportProgress(1)
            setTimeout(() => {
                setFetchTablesCashReportProgress(11)
            }, 300)
            setTimeout(() => {
                setFetchTablesCashReportProgress(22)
            }, 200)
            setTimeout(() => {
                setFetchTablesCashReportProgress(33)
            }, 200)
            const response = await cashApi
                .get('report/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            // console.log(response)
            setFetchTablesCashReportProgress(40)
            setFetchTablesCashReportProgress(80)
            setTimeout(() => {
                setFetchTablesCashReportProgress(84)
            }, 300)
            setTimeout(() => {
                setFetchTablesCashReportProgress(98)
            }, 600)
            setTimeout(() => {
                setAllTablesCashReport(response)
            }, 700)
        } catch (e) {
            console.warn(e)
            setAllTablesCashReport([])
        }
    }

    if (allTablesReport && location) {
        getDevicesCashReport()
    }

    const getHoursCashDropData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'cash drop',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.drop['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[4],
                borderColor: chartBackgroundColors[4],
            },
        ],
    })

    const getHoursChipFillData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'chip fill',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.fill['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[5],
                borderColor: chartBackgroundColors[5],
            },
        ],
    })

    const getHoursChipCreditData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'chip credit',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.credit['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[6],
                borderColor: chartBackgroundColors[6],
            },
        ],
    })

    if (Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        getDevicesReport()
    }

    // history
    const IPs = location ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices) : getAllIPs(['root'])
    const historylocationQuery = 'instance=~"' + IPs.join('|') + '"'
    const queryMetricBase = 'sum({' + historylocationQuery + ', __name__=~"tray_'

    const queryCashdropHistory = queryMetricBase + 'cashdrop"})'
    const queryChipfillHistory = queryMetricBase + 'chipfill"})'
    const queryChipcreditHistory = queryMetricBase + 'chipcredit"})'

    const locationQuery = isCasinoLevel ? '.+' : '(' + location[0].descendantDevices.join('|') + ')'

    const minBetHistory = [
        'sum({__name__=~"table_' + gameType + '_' + gameVariant + '_' + locationQuery + '_min_bet"})',
    ]

    // baccarat standard
    const baccaratStandardQueryBetsBase = 'sum({__name__=~"table_baccarat_standard_' + locationQuery + '_bets_'
    const baccaratStandardGameCountHistory = ['sum({__name__=~"table_baccarat_standard_' + locationQuery + '_games"})']
    const baccaratStandardProfitHistory = ['sum({__name__=~"table_baccarat_standard_' + locationQuery + '_profit"})']
    const baccaratStandardBetsQuery =
        baccaratStandardQueryBetsBase +
        'banker"}) + ' +
        baccaratStandardQueryBetsBase +
        'player"}) + ' +
        baccaratStandardQueryBetsBase +
        'tie"}) + ' +
        baccaratStandardQueryBetsBase +
        'banker_pair"}) + ' +
        baccaratStandardQueryBetsBase +
        'player_pair"})'
    const baccaratStandardBetsHistory = [
        baccaratStandardQueryBetsBase + 'banker"})',
        baccaratStandardQueryBetsBase + 'player"})',
        baccaratStandardQueryBetsBase + 'tie"})',
        baccaratStandardQueryBetsBase + 'banker_pair"})',
        baccaratStandardQueryBetsBase + 'player_pair"})',
        baccaratStandardBetsQuery,
    ]

    // baccarat lucky6
    const baccaratLucky6QueryBetsBase = 'sum({__name__=~"table_baccarat_lucky6_' + locationQuery + '_bets_'
    const baccaratLucky6GameCountHistory = ['sum({__name__=~"table_baccarat_lucky6_' + locationQuery + '_games"})']
    const baccaratLucky6ProfitHistory = ['sum({__name__=~"table_baccarat_lucky6_' + locationQuery + '_profit"})']
    const baccaratLucky6BetsQuery =
        baccaratLucky6QueryBetsBase +
        'banker"}) + ' +
        baccaratLucky6QueryBetsBase +
        'player"}) + ' +
        baccaratLucky6QueryBetsBase +
        'tie"}) + ' +
        baccaratLucky6QueryBetsBase +
        'banker_pair"}) + ' +
        baccaratLucky6QueryBetsBase +
        'player_pair"}) + ' +
        baccaratLucky6QueryBetsBase +
        'lucky6"})'
    const baccaratLucky6BetsHistory = [
        baccaratLucky6QueryBetsBase + 'banker"})',
        baccaratLucky6QueryBetsBase + 'player"})',
        baccaratLucky6QueryBetsBase + 'tie"})',
        baccaratLucky6QueryBetsBase + 'banker_pair"})',
        baccaratLucky6QueryBetsBase + 'player_pair"})',
        baccaratLucky6QueryBetsBase + 'lucky6"})',
        baccaratLucky6BetsQuery,
    ]

    // baccarat mega6
    const baccaratMega6QueryBetsBase = 'sum({__name__=~"table_baccarat_mega6_' + locationQuery + '_bets_'
    const baccaratMega6GameCountHistory = ['sum({__name__=~"table_baccarat_mega6_' + locationQuery + '_games"})']
    const baccaratMega6ProfitHistory = ['sum({__name__=~"table_baccarat_mega6_' + locationQuery + '_profit"})']
    const baccaratMega6BetsQuery =
        baccaratMega6QueryBetsBase +
        'banker"}) + ' +
        baccaratMega6QueryBetsBase +
        'player"}) + ' +
        baccaratMega6QueryBetsBase +
        'tie"}) + ' +
        baccaratMega6QueryBetsBase +
        'banker_pair"}) + ' +
        baccaratMega6QueryBetsBase +
        'player_pair"}) + ' +
        baccaratMega6QueryBetsBase +
        'mega6"})'
    const baccaratMega6BetsHistory = [
        baccaratMega6QueryBetsBase + 'banker"})',
        baccaratMega6QueryBetsBase + 'player"})',
        baccaratMega6QueryBetsBase + 'tie"})',
        baccaratMega6QueryBetsBase + 'banker_pair"})',
        baccaratMega6QueryBetsBase + 'player_pair"})',
        baccaratMega6QueryBetsBase + 'mega6"})',
        baccaratMega6BetsQuery,
    ]

    // baccarat tiger
    const baccaratTigerQueryBetsBase = 'sum({__name__=~"table_baccarat_tiger_' + locationQuery + '_bets_'
    const baccaratTigerGameCountHistory = ['sum({__name__=~"table_baccarat_tiger_' + locationQuery + '_games"})']
    const baccaratTigerProfitHistory = ['sum({__name__=~"table_baccarat_tiger_' + locationQuery + '_profit"})']
    const baccaratTigerBetsQuery =
        baccaratTigerQueryBetsBase +
        'banker"}) + ' +
        baccaratTigerQueryBetsBase +
        'player"}) + ' +
        baccaratTigerQueryBetsBase +
        'tie"}) + ' +
        baccaratTigerQueryBetsBase +
        'tiger_tie"}) + ' +
        baccaratTigerQueryBetsBase +
        'tiger_pair"}) + ' +
        baccaratTigerQueryBetsBase +
        'tiger_big"}) + ' +
        baccaratTigerQueryBetsBase +
        'tiger_small"}) + ' +
        baccaratTigerQueryBetsBase +
        'tiger"})'
    const baccaratTigerBetsHistory = [
        baccaratTigerQueryBetsBase + 'banker"})',
        baccaratTigerQueryBetsBase + 'player"})',
        baccaratTigerQueryBetsBase + 'tie"})',
        baccaratTigerQueryBetsBase + 'tiger_tie"})',
        baccaratTigerQueryBetsBase + 'tiger_pair"})',
        baccaratTigerQueryBetsBase + 'tiger_big"})',
        baccaratTigerQueryBetsBase + 'tiger_small"})',
        baccaratTigerQueryBetsBase + 'tiger"})',
        baccaratTigerBetsQuery,
    ]

    // blackjack standard
    const blackjackStandardQueryBetsBase = 'sum({__name__=~"table_blackjack_standard_' + locationQuery + '_bets_'
    const blackjackStandardGameCountHistory = [
        'sum({__name__=~"table_blackjack_standard_' + locationQuery + '_games"})',
    ]
    const blackjackStandardProfitHistory = ['sum({__name__=~"table_blackjack_standard_' + locationQuery + '_profit"})']
    const blackjackStandardBetsQuery =
        blackjackStandardQueryBetsBase +
        'base"}) + ' +
        blackjackStandardQueryBetsBase +
        'doubledown"}) + ' +
        blackjackStandardQueryBetsBase +
        'insurance"}) + ' +
        blackjackStandardQueryBetsBase +
        'pair"}) + ' +
        blackjackStandardQueryBetsBase +
        'split"})'
    const blackjackStandardBetsHistory = [
        blackjackStandardQueryBetsBase + 'base"})',
        blackjackStandardQueryBetsBase + 'doubledown"})',
        blackjackStandardQueryBetsBase + 'insurance"})',
        blackjackStandardQueryBetsBase + 'pair"})',
        blackjackStandardQueryBetsBase + 'split"})',
        blackjackStandardBetsQuery,
    ]

    // const getRando = (min, max) => Math.random() * (max - min) + min

    const getHoursWagerData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'wager',
                data: hoursLabels.map((hr) => tablesReport.reduce((prev, next) => prev + next.hourlyBets['h' + hr], 0)),
                backgroundColor: chartBackgroundColors[4],
                borderColor: chartBackgroundColors[4],
            },
        ],
    })

    const getHoursWinLossData = () => {
        const data = hoursLabels.map((hr) => tablesReport.reduce((prev, next) => prev + next.hourlyProfit['h' + hr], 0))
        return {
            labels: hoursLabels,
            datasets: [
                {
                    label: 'win/loss',
                    data,
                    backgroundColor: data.map((v) => (v > 0 ? 'chartreuse' : 'red')),
                    borderColor: chartBackgroundColors[6],
                    order: 0,
                },
            ],
        }
    }

    const getHoursGamesData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'games',
                data: hoursLabels.map((hr) =>
                    tablesReport.reduce((prev, next) => prev + next.hourlyGames['h' + hr], 0)
                ),
                // backgroundColor: chartBackgroundColors[6],
                borderColor: chartBackgroundColors[6],
            },
        ],
    })

    if (resizing && builtCharts && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    const treeLoading = !active || !zones || !pits || !devices || !tables || !status || !minLoadTime

    if (
        !treeLoading &&
        (!location || (URLlocationID !== location[0].branchIndex && URLlocationType !== location[0].branchType))
    ) {
        setLocation({
            branchIndex: URLlocationID,
            branchType: URLlocationType,
            gameType: getGameType(tables, devices, URLlocationID, URLlocationType),
            gameVariant: getGameVariant(tables, devices, URLlocationID, URLlocationType),
            descendantDevices: getDescendantDevices(pits, tables, devices, URLlocationID, URLlocationType),
        })
    }

    // filter reports by location
    const tablesReport =
        location && allTablesReport
            ? allTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : allTablesReport
    const baccaratStandardTablesReport =
        location && baccaratStandardAllTablesReport
            ? baccaratStandardAllTablesReport.filter(
                  (entry) => location[0].descendantDevices.indexOf(entry.device) >= 0
              )
            : baccaratStandardAllTablesReport
    const baccaratLucky6TablesReport =
        location && baccaratLucky6AllTablesReport
            ? baccaratLucky6AllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : baccaratLucky6AllTablesReport
    const baccaratMega6TablesReport =
        location && baccaratMega6AllTablesReport
            ? baccaratMega6AllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : baccaratMega6AllTablesReport
    const baccaratTigerTablesReport =
        location && baccaratTigerAllTablesReport
            ? baccaratTigerAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : baccaratTigerAllTablesReport
    const blackjackStandardTablesReport =
        location && blackjackStandardAllTablesReport
            ? blackjackStandardAllTablesReport.filter(
                  (entry) => location[0].descendantDevices.indexOf(entry.device) >= 0
              )
            : blackjackStandardAllTablesReport

    const tablesCashReport =
        location && allTablesCashReport
            ? allTablesCashReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : allTablesCashReport

    const loading =
        !allTablesReport ||
        !baccaratStandardAllTablesReport ||
        !baccaratLucky6AllTablesReport ||
        !baccaratMega6AllTablesReport ||
        !baccaratTigerAllTablesReport ||
        !blackjackStandardAllTablesReport

    // Baccarat Standard
    const baccaratStandardGamesWithBets =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.gamesWithBets, 0)
    // wager/bet/turnover
    const baccaratStandardWager =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.bets, 0)
    const baccaratStandardWagerPlayer =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.player, 0)
    const baccaratStandardWagerBanker =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.banker, 0)
    const baccaratStandardWagerTie =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.tie, 0)
    const baccaratStandardWagerPlayerPair =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.playerPair, 0)
    const baccaratStandardWagerBankerPair =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.bankerPair, 0)
    const baccaratStandardWagerLucky6 =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.lucky6, 0)
    const baccaratStandardMainWager =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce(
                  (prev, next) => prev + next.betsPerType.player + next.betsPerType.banker,
                  0
              )
    const baccaratStandardSideWager =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betsPerType.tie +
                      next.betsPerType.bankerPair +
                      next.betsPerType.playerPair +
                      next.betsPerType.lucky6,
                  0
              )
    // standoff (refund from tie)
    const baccaratStandardStandoffBanker =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.standoffBanker, 0)
    const baccaratStandardStandoffPlayer =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.standoffPlayer, 0)
    const baccaratStandardStandoff = baccaratStandardStandoffPlayer + baccaratStandardStandoffBanker
    // counts
    const baccaratStandardBetCountPlayer =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.player, 0)
    const baccaratStandardBetCountBanker =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.banker, 0)
    const baccaratStandardBetCountTie =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.tie, 0)
    const baccaratStandardBetCountPlayerPair =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.playerPair, 0)
    const baccaratStandardBetCountBankerPair =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.bankerPair, 0)
    const baccaratStandardBetCountLucky6 =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.lucky6, 0)
    const baccaratStandardBetCount =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.betCount, 0)
    const baccaratStandardMainBetCounts =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce(
                  (prev, next) => prev + next.betCountPerType.player + next.betCountPerType.banker,
                  0
              )
    const baccaratStandardSideBetCounts =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betCountPerType.tie +
                      next.betCountPerType.bankerPair +
                      next.betCountPerType.playerPair +
                      next.betCountPerType.lucky6,
                  0
              )
    // payouts/return (profit+refund)
    const baccaratStandardPayoutPlayer =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.player, 0)
    const baccaratStandardPayoutBanker =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.banker, 0)
    const baccaratStandardPayoutTie =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.tie, 0)
    const baccaratStandardPayoutPlayerPair =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.playerPair, 0)
    const baccaratStandardPayoutBankerPair =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.bankerPair, 0)
    const baccaratStandardPayoutLucky6 =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.lucky6, 0)
    const baccaratStandardPayout =
        baccaratStandardPayoutPlayer +
        baccaratStandardPayoutBanker +
        baccaratStandardPayoutTie +
        baccaratStandardPayoutPlayerPair +
        baccaratStandardPayoutBankerPair +
        baccaratStandardPayoutLucky6
    // profit/outcome/winloss
    const baccaratStandardProfitPlayer =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.player, 0)
    const baccaratStandardProfitBanker =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.banker, 0)
    const baccaratStandardProfitTie =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.tie, 0)
    const baccaratStandardProfitPlayerPair =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.playerPair, 0)
    const baccaratStandardProfitBankerPair =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.bankerPair, 0)
    const baccaratStandardProfitLucky6 =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.lucky6, 0)
    const baccaratStandardProfit =
        baccaratStandardProfitPlayer +
        baccaratStandardProfitBanker +
        baccaratStandardProfitTie +
        baccaratStandardProfitPlayerPair +
        baccaratStandardProfitBankerPair +
        baccaratStandardProfitLucky6

    const getBaccaratStandardHouseWin = () => {
        const player = baccaratStandardProfitPlayer < 0 ? 0 : baccaratStandardProfitPlayer
        const banker = baccaratStandardProfitBanker < 0 ? 0 : baccaratStandardProfitBanker
        const tie = baccaratStandardProfitTie < 0 ? 0 : baccaratStandardProfitTie
        const playerPair = baccaratStandardProfitPlayerPair < 0 ? 0 : baccaratStandardProfitPlayerPair
        const bankerPair = baccaratStandardProfitBankerPair < 0 ? 0 : baccaratStandardProfitBankerPair
        const lucky6 = baccaratStandardProfitLucky6 < 0 ? 0 : baccaratStandardProfitLucky6

        return player + banker + tie + playerPair + bankerPair + lucky6
    }

    const getBaccaratStandardHouseLoss = () => {
        const player = baccaratStandardProfitPlayer > 0 ? 0 : baccaratStandardProfitPlayer
        const banker = baccaratStandardProfitBanker > 0 ? 0 : baccaratStandardProfitBanker
        const tie = baccaratStandardProfitTie > 0 ? 0 : baccaratStandardProfitTie
        const playerPair = baccaratStandardProfitPlayerPair > 0 ? 0 : baccaratStandardProfitPlayerPair
        const bankerPair = baccaratStandardProfitBankerPair > 0 ? 0 : baccaratStandardProfitBankerPair
        const lucky6 = baccaratStandardProfitLucky6 > 0 ? 0 : baccaratStandardProfitLucky6

        return player + banker + tie + playerPair + bankerPair + lucky6
    }

    // Baccarat lucky6
    const baccaratLucky6GamesWithBets =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.gamesWithBets, 0)
    // wager/bet/turnover
    const baccaratLucky6Wager =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.bets, 0)
    const baccaratLucky6WagerPlayer =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betsPerType.player, 0)
    const baccaratLucky6WagerBanker =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betsPerType.banker, 0)
    const baccaratLucky6WagerTie =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betsPerType.tie, 0)
    const baccaratLucky6WagerPlayerPair =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betsPerType.playerPair, 0)
    const baccaratLucky6WagerBankerPair =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betsPerType.bankerPair, 0)
    const baccaratLucky6WagerLucky6 =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betsPerType.lucky6, 0)
    const baccaratLucky6MainWager =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce(
                  (prev, next) => prev + next.betsPerType.player + next.betsPerType.banker,
                  0
              )
    const baccaratLucky6SideWager =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betsPerType.tie +
                      next.betsPerType.bankerPair +
                      next.betsPerType.playerPair +
                      next.betsPerType.lucky6,
                  0
              )
    // standoff (refund from tie)
    const baccaratLucky6StandoffBanker =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.standoffBanker, 0)
    const baccaratLucky6StandoffPlayer =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.standoffPlayer, 0)
    const baccaratLucky6Standoff = baccaratLucky6StandoffPlayer + baccaratLucky6StandoffBanker
    // counts
    const baccaratLucky6BetCountPlayer =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betCountPerType.player, 0)
    const baccaratLucky6BetCountBanker =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betCountPerType.banker, 0)
    const baccaratLucky6BetCountTie =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betCountPerType.tie, 0)
    const baccaratLucky6BetCountPlayerPair =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betCountPerType.playerPair, 0)
    const baccaratLucky6BetCountBankerPair =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betCountPerType.bankerPair, 0)
    const baccaratLucky6BetCountLucky6 =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betCountPerType.lucky6, 0)
    const baccaratLucky6BetCount =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.betCount, 0)
    const baccaratLucky6MainBetCounts =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce(
                  (prev, next) => prev + next.betCountPerType.player + next.betCountPerType.banker,
                  0
              )
    const baccaratLucky6SideBetCounts =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betCountPerType.tie +
                      next.betCountPerType.bankerPair +
                      next.betCountPerType.playerPair +
                      next.betCountPerType.lucky6,
                  0
              )
    // payouts/return (profit+refund)
    const baccaratLucky6PayoutPlayer =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.returnPerType.player, 0)
    const baccaratLucky6PayoutBanker =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.returnPerType.banker, 0)
    const baccaratLucky6PayoutTie =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.returnPerType.tie, 0)
    const baccaratLucky6PayoutPlayerPair =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.returnPerType.playerPair, 0)
    const baccaratLucky6PayoutBankerPair =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.returnPerType.bankerPair, 0)
    const baccaratLucky6PayoutLucky6 =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.returnPerType.lucky6, 0)
    const baccaratLucky6Payout =
        baccaratLucky6PayoutPlayer +
        baccaratLucky6PayoutBanker +
        baccaratLucky6PayoutTie +
        baccaratLucky6PayoutPlayerPair +
        baccaratLucky6PayoutBankerPair +
        baccaratLucky6PayoutLucky6
    // profit/outcome/winloss
    const baccaratLucky6ProfitPlayer =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.profitPerType.player, 0)
    const baccaratLucky6ProfitBanker =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.profitPerType.banker, 0)
    const baccaratLucky6ProfitTie =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.profitPerType.tie, 0)
    const baccaratLucky6ProfitPlayerPair =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.profitPerType.playerPair, 0)
    const baccaratLucky6ProfitBankerPair =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.profitPerType.bankerPair, 0)
    const baccaratLucky6ProfitLucky6 =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.profitPerType.lucky6, 0)
    const baccaratLucky6Profit =
        baccaratLucky6ProfitPlayer +
        baccaratLucky6ProfitBanker +
        baccaratLucky6ProfitTie +
        baccaratLucky6ProfitPlayerPair +
        baccaratLucky6ProfitBankerPair +
        baccaratLucky6ProfitLucky6

    const getBaccaratLucky6HouseWin = () => {
        const player = baccaratLucky6ProfitPlayer < 0 ? 0 : baccaratLucky6ProfitPlayer
        const banker = baccaratLucky6ProfitBanker < 0 ? 0 : baccaratLucky6ProfitBanker
        const tie = baccaratLucky6ProfitTie < 0 ? 0 : baccaratLucky6ProfitTie
        const playerPair = baccaratLucky6ProfitPlayerPair < 0 ? 0 : baccaratLucky6ProfitPlayerPair
        const bankerPair = baccaratLucky6ProfitBankerPair < 0 ? 0 : baccaratLucky6ProfitBankerPair
        const lucky6 = baccaratLucky6ProfitLucky6 < 0 ? 0 : baccaratLucky6ProfitLucky6

        return player + banker + tie + playerPair + bankerPair + lucky6
    }

    const getBaccaratLucky6HouseLoss = () => {
        const player = baccaratLucky6ProfitPlayer > 0 ? 0 : baccaratLucky6ProfitPlayer
        const banker = baccaratLucky6ProfitBanker > 0 ? 0 : baccaratLucky6ProfitBanker
        const tie = baccaratLucky6ProfitTie > 0 ? 0 : baccaratLucky6ProfitTie
        const playerPair = baccaratLucky6ProfitPlayerPair > 0 ? 0 : baccaratLucky6ProfitPlayerPair
        const bankerPair = baccaratLucky6ProfitBankerPair > 0 ? 0 : baccaratLucky6ProfitBankerPair
        const lucky6 = baccaratLucky6ProfitLucky6 > 0 ? 0 : baccaratLucky6ProfitLucky6

        return player + banker + tie + playerPair + bankerPair + lucky6
    }

    // Baccarat mega6
    const baccaratMega6GamesWithBets =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.gamesWithBets, 0)
    // wager/bet/turnover
    const baccaratMega6Wager =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.bets, 0)
    const baccaratMega6WagerPlayer =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betsPerType.player, 0)
    const baccaratMega6WagerBanker =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betsPerType.banker, 0)
    const baccaratMega6WagerTie =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betsPerType.tie, 0)
    const baccaratMega6WagerPlayerPair =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betsPerType.playerPair, 0)
    const baccaratMega6WagerBankerPair =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betsPerType.bankerPair, 0)
    const baccaratMega6WagerMega6 =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betsPerType.mega6, 0)
    const baccaratMega6MainWager =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce(
                  (prev, next) => prev + next.betsPerType.player + next.betsPerType.banker,
                  0
              )
    const baccaratMega6SideWager =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betsPerType.tie +
                      next.betsPerType.bankerPair +
                      next.betsPerType.playerPair +
                      next.betsPerType.mega6,
                  0
              )
    // standoff (refund from tie)
    const baccaratMega6StandoffBanker =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.standoffBanker, 0)
    const baccaratMega6StandoffPlayer =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.standoffPlayer, 0)
    const baccaratMega6Standoff = baccaratMega6StandoffPlayer + baccaratMega6StandoffBanker
    // counts
    const baccaratMega6BetCountPlayer =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betCountPerType.player, 0)
    const baccaratMega6BetCountBanker =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betCountPerType.banker, 0)
    const baccaratMega6BetCountTie =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betCountPerType.tie, 0)
    const baccaratMega6BetCountPlayerPair =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betCountPerType.playerPair, 0)
    const baccaratMega6BetCountBankerPair =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betCountPerType.bankerPair, 0)
    const baccaratMega6BetCountMega6 =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betCountPerType.mega6, 0)
    const baccaratMega6BetCount =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.betCount, 0)
    const baccaratMega6MainBetCounts =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce(
                  (prev, next) => prev + next.betCountPerType.player + next.betCountPerType.banker,
                  0
              )
    const baccaratMega6SideBetCounts =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betCountPerType.tie +
                      next.betCountPerType.bankerPair +
                      next.betCountPerType.playerPair +
                      next.betCountPerType.mega6,
                  0
              )
    // payouts/return (profit+refund)
    const baccaratMega6PayoutPlayer =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.returnPerType.player, 0)
    const baccaratMega6PayoutBanker =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.returnPerType.banker, 0)
    const baccaratMega6PayoutTie =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.returnPerType.tie, 0)
    const baccaratMega6PayoutPlayerPair =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.returnPerType.playerPair, 0)
    const baccaratMega6PayoutBankerPair =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.returnPerType.bankerPair, 0)
    const baccaratMega6PayoutMega6 =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.returnPerType.mega6, 0)
    const baccaratMega6Payout =
        baccaratMega6PayoutPlayer +
        baccaratMega6PayoutBanker +
        baccaratMega6PayoutTie +
        baccaratMega6PayoutPlayerPair +
        baccaratMega6PayoutBankerPair +
        baccaratMega6PayoutMega6
    // profit/outcome/winloss
    const baccaratMega6ProfitPlayer =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.profitPerType.player, 0)
    const baccaratMega6ProfitBanker =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.profitPerType.banker, 0)
    const baccaratMega6ProfitTie =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.profitPerType.tie, 0)
    const baccaratMega6ProfitPlayerPair =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.profitPerType.playerPair, 0)
    const baccaratMega6ProfitBankerPair =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.profitPerType.bankerPair, 0)
    const baccaratMega6ProfitMega6 =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.profitPerType.mega6, 0)
    const baccaratMega6Profit =
        baccaratMega6ProfitPlayer +
        baccaratMega6ProfitBanker +
        baccaratMega6ProfitTie +
        baccaratMega6ProfitPlayerPair +
        baccaratMega6ProfitBankerPair +
        baccaratMega6ProfitMega6

    const getBaccaratMega6HouseWin = () => {
        const player = baccaratMega6ProfitPlayer < 0 ? 0 : baccaratMega6ProfitPlayer
        const banker = baccaratMega6ProfitBanker < 0 ? 0 : baccaratMega6ProfitBanker
        const tie = baccaratMega6ProfitTie < 0 ? 0 : baccaratMega6ProfitTie
        const playerPair = baccaratMega6ProfitPlayerPair < 0 ? 0 : baccaratMega6ProfitPlayerPair
        const bankerPair = baccaratMega6ProfitBankerPair < 0 ? 0 : baccaratMega6ProfitBankerPair
        const mega6 = baccaratMega6ProfitMega6 < 0 ? 0 : baccaratMega6ProfitMega6

        return player + banker + tie + playerPair + bankerPair + mega6
    }

    const getBaccaratMega6HouseLoss = () => {
        const player = baccaratMega6ProfitPlayer > 0 ? 0 : baccaratMega6ProfitPlayer
        const banker = baccaratMega6ProfitBanker > 0 ? 0 : baccaratMega6ProfitBanker
        const tie = baccaratMega6ProfitTie > 0 ? 0 : baccaratMega6ProfitTie
        const playerPair = baccaratMega6ProfitPlayerPair > 0 ? 0 : baccaratMega6ProfitPlayerPair
        const bankerPair = baccaratMega6ProfitBankerPair > 0 ? 0 : baccaratMega6ProfitBankerPair
        const mega6 = baccaratMega6ProfitMega6 > 0 ? 0 : baccaratMega6ProfitMega6

        return player + banker + tie + playerPair + bankerPair + mega6
    }

    // Baccarat tiger
    const baccaratTigerGamesWithBets =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.gamesWithBets, 0)
    // wager/bet/turnover
    const baccaratTigerWager =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.bets, 0)
    const baccaratTigerWagerPlayer =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betsPerType.player, 0)
    const baccaratTigerWagerBanker =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betsPerType.banker, 0)
    const baccaratTigerWagerTie =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betsPerType.tie, 0)
    const baccaratTigerWagerTigerTie =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betsPerType.tigerTie, 0)
    const baccaratTigerWagerTigerPair =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betsPerType.tigerPair, 0)
    const baccaratTigerWagerTigerBig =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betsPerType.tigerBig, 0)
    const baccaratTigerWagerTigerSmall =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betsPerType.tigerSmall, 0)
    const baccaratTigerWagerTiger =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betsPerType.tiger, 0)
    const baccaratTigerMainWager =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce(
                  (prev, next) => prev + next.betsPerType.player + next.betsPerType.banker,
                  0
              )
    const baccaratTigerSideWager =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betsPerType.tie +
                      next.betsPerType.tigerTie +
                      next.betsPerType.tigerPair +
                      next.betsPerType.tigerBig +
                      next.betsPerType.tigerSmall +
                      next.betsPerType.tiger,
                  0
              )
    // standoff (refund from tie)
    const baccaratTigerStandoffBanker =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.standoffBanker, 0)
    const baccaratTigerStandoffPlayer =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.standoffPlayer, 0)
    const baccaratTigerStandoff = baccaratTigerStandoffPlayer + baccaratTigerStandoffBanker
    // counts
    const baccaratTigerBetCountPlayer =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCountPerType.player, 0)
    const baccaratTigerBetCountBanker =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCountPerType.banker, 0)
    const baccaratTigerBetCountTie =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCountPerType.tie, 0)
    const baccaratTigerBetCountTigerTie =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCountPerType.tigerTie, 0)
    const baccaratTigerBetCountTigerPair =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCountPerType.tigerPair, 0)
    const baccaratTigerBetCountTigerBig =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCountPerType.tigerBig, 0)
    const baccaratTigerBetCountTigerSmall =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCountPerType.tigerSmall, 0)
    const baccaratTigerBetCountTiger =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCountPerType.tiger, 0)
    const baccaratTigerBetCount =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.betCount, 0)
    const baccaratTigerMainBetCounts =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce(
                  (prev, next) => prev + next.betCountPerType.player + next.betCountPerType.banker,
                  0
              )
    const baccaratTigerSideBetCounts =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betCountPerType.tie +
                      next.betCountPerType.tigerTie +
                      next.betCountPerType.tigerPair +
                      next.betCountPerType.tigerBig +
                      next.betCountPerType.tigerSmall +
                      next.betCountPerType.lucky6,
                  0
              )
    // payouts/return (profit+refund)
    const baccaratTigerPayoutPlayer =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.returnPerType.player, 0)
    const baccaratTigerPayoutBanker =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.returnPerType.banker, 0)
    const baccaratTigerPayoutTie =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.returnPerType.tie, 0)
    const baccaratTigerPayoutTigerTie =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.returnPerType.tigerTie, 0)
    const baccaratTigerPayoutTigerPair =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.returnPerType.tigerPair, 0)
    const baccaratTigerPayoutTigerBig =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.returnPerType.tigerBig, 0)
    const baccaratTigerPayoutTigerSmall =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.returnPerType.tigerSmall, 0)
    const baccaratTigerPayoutTiger =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.returnPerType.tiger, 0)
    const baccaratTigerPayout =
        baccaratTigerPayoutPlayer +
        baccaratTigerPayoutBanker +
        baccaratTigerPayoutTie +
        baccaratTigerPayoutTigerTie +
        baccaratTigerPayoutTigerPair +
        baccaratTigerPayoutTigerBig +
        baccaratTigerPayoutTigerSmall +
        baccaratTigerPayoutTiger
    // profit/outcome/winloss
    const baccaratTigerProfitPlayer =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profitPerType.player, 0)
    const baccaratTigerProfitBanker =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profitPerType.banker, 0)
    const baccaratTigerProfitTie =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profitPerType.tie, 0)
    const baccaratTigerProfitTigerTie =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profitPerType.tigerTie, 0)
    const baccaratTigerProfitTigerPair =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profitPerType.tigerPair, 0)
    const baccaratTigerProfitTigerBig =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profitPerType.tigerBig, 0)
    const baccaratTigerProfitTigerSmall =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profitPerType.tigerSmall, 0)
    const baccaratTigerProfitTiger =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profitPerType.tiger, 0)
    const baccaratTigerProfit =
        baccaratTigerProfitPlayer +
        baccaratTigerProfitBanker +
        baccaratTigerProfitTie +
        baccaratTigerProfitTigerTie +
        baccaratTigerProfitTigerPair +
        baccaratTigerProfitTigerBig +
        baccaratTigerProfitTigerSmall +
        baccaratTigerProfitTiger

    const getBaccaratTigerHouseWin = () => {
        const player = baccaratTigerProfitPlayer < 0 ? 0 : baccaratTigerProfitPlayer
        const banker = baccaratTigerProfitBanker < 0 ? 0 : baccaratTigerProfitBanker
        const tie = baccaratTigerProfitTie < 0 ? 0 : baccaratTigerProfitTie
        const tigerTie = baccaratTigerProfitTigerTie < 0 ? 0 : baccaratTigerProfitTigerTie
        const tigerPair = baccaratTigerProfitTigerPair < 0 ? 0 : baccaratTigerProfitTigerPair
        const tigerBig = baccaratTigerProfitTigerBig < 0 ? 0 : baccaratTigerProfitTigerBig
        const tigerSmall = baccaratTigerProfitTigerSmall < 0 ? 0 : baccaratTigerProfitTigerSmall
        const tiger = baccaratTigerProfitTiger < 0 ? 0 : baccaratTigerProfitTiger

        return player + banker + tie + tigerTie + tigerPair + tigerBig + tigerSmall + tiger
    }

    const getBaccaratTigerHouseLoss = () => {
        const player = baccaratTigerProfitPlayer > 0 ? 0 : baccaratTigerProfitPlayer
        const banker = baccaratTigerProfitBanker > 0 ? 0 : baccaratTigerProfitBanker
        const tie = baccaratTigerProfitTie > 0 ? 0 : baccaratTigerProfitTie
        const tigerTie = baccaratTigerProfitTigerTie > 0 ? 0 : baccaratTigerProfitTigerTie
        const tigerPair = baccaratTigerProfitTigerPair > 0 ? 0 : baccaratTigerProfitTigerPair
        const tigerBig = baccaratTigerProfitTigerBig > 0 ? 0 : baccaratTigerProfitTigerBig
        const tigerSmall = baccaratTigerProfitTigerSmall > 0 ? 0 : baccaratTigerProfitTigerSmall
        const tiger = baccaratTigerProfitTiger > 0 ? 0 : baccaratTigerProfitTiger

        return player + banker + tie + tigerTie + tigerPair + tigerBig + tigerSmall + tiger
    }

    // Blackjack Standard
    const blackjackStandardGamesWithBets =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.gamesWithBets, 0)
    // wager/bet/turnover
    const blackjackStandardWager =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.bets, 0)
    const blackjackStandardWagerBase =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.base, 0)
    const blackjackStandardWagerDoubleDown =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.doubleDown, 0)
    const blackjackStandardWagerInsurance =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.insurance, 0)
    const blackjackStandardWagerPair =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.pair, 0)
    const blackjackStandardWagerSplit =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betsPerType.split, 0)
    const blackjackStandardSideWager =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce(
                  (prev, next) =>
                      prev +
                      next.betsPerType.doubleDown +
                      next.betsPerType.insurance +
                      next.betsPerType.pair +
                      next.betsPerType.split,
                  0
              )
    // counts
    const blackjackStandardBetCountBase =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.base, 0)
    const blackjackStandardBetCountDoubleDown =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.doubleDown, 0)
    const blackjackStandardBetCountInsurance =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.insurance, 0)
    const blackjackStandardBetCountPair =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.pair, 0)
    const blackjackStandardBetCountSplit =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betCountPerType.split, 0)
    const blackjackStandardBetCount =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.betCount, 0)
    const blackjackStandardSideBetCounts = blackjackStandardBetCount - blackjackStandardBetCountBase
    // payouts/return (profit+refund)
    const blackjackStandardPayoutBase =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.base, 0)
    const blackjackStandardPayoutDoubleDown =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.doubleDown, 0)
    const blackjackStandardPayoutInsurance =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.insurance, 0)
    const blackjackStandardPayoutPair =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.pair, 0)
    const blackjackStandardPayoutSplit =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.returnPerType.split, 0)
    const blackjackStandardPayout =
        blackjackStandardPayoutBase +
        blackjackStandardPayoutDoubleDown +
        blackjackStandardPayoutInsurance +
        blackjackStandardPayoutPair +
        blackjackStandardPayoutSplit
    // profit/outcome/winloss
    const blackjackStandardProfitBase =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.base, 0)
    const blackjackStandardProfitDoubleDown =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.doubleDown, 0)
    const blackjackStandardProfitInsurance =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.insurance, 0)
    const blackjackStandardProfitPair =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.pair, 0)
    const blackjackStandardProfitSplit =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.profitPerType.split, 0)
    const blackjackStandardProfit =
        blackjackStandardProfitBase +
        blackjackStandardProfitDoubleDown +
        blackjackStandardProfitInsurance +
        blackjackStandardProfitPair +
        blackjackStandardProfitSplit

    const getBlackjackStandardHouseWin = () => {
        const base = blackjackStandardProfitBase < 0 ? 0 : blackjackStandardProfitBase
        const doubleDown = blackjackStandardProfitDoubleDown < 0 ? 0 : blackjackStandardProfitDoubleDown
        const insurance = blackjackStandardProfitInsurance < 0 ? 0 : blackjackStandardProfitInsurance
        const pair = blackjackStandardProfitPair < 0 ? 0 : blackjackStandardProfitPair
        const split = blackjackStandardProfitSplit < 0 ? 0 : blackjackStandardProfitSplit

        return base + doubleDown + insurance + pair + split
    }

    const getBlackjackStandardHouseLoss = () => {
        const base = blackjackStandardProfitBase > 0 ? 0 : blackjackStandardProfitBase
        const doubleDown = blackjackStandardProfitDoubleDown > 0 ? 0 : blackjackStandardProfitDoubleDown
        const insurance = blackjackStandardProfitInsurance > 0 ? 0 : blackjackStandardProfitInsurance
        const pair = blackjackStandardProfitPair > 0 ? 0 : blackjackStandardProfitPair
        const split = blackjackStandardProfitSplit > 0 ? 0 : blackjackStandardProfitSplit

        return base + doubleDown + insurance + pair + split
    }

    // Overview
    const numberOfGames =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.games, 0)
    const wager = !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.bets, 0)
    const winLoss =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.profit, 0)
    const baccaratStandardWinLoss =
        !baccaratStandardTablesReport || !baccaratStandardTablesReport.reduce
            ? 0
            : baccaratStandardTablesReport.reduce((prev, next) => prev + next.profit, 0)
    const baccaratLucky6WinLoss =
        !baccaratLucky6TablesReport || !baccaratLucky6TablesReport.reduce
            ? 0
            : baccaratLucky6TablesReport.reduce((prev, next) => prev + next.profit, 0)
    const baccaratMega6WinLoss =
        !baccaratMega6TablesReport || !baccaratMega6TablesReport.reduce
            ? 0
            : baccaratMega6TablesReport.reduce((prev, next) => prev + next.profit, 0)
    const baccaratTigerWinLoss =
        !baccaratTigerTablesReport || !baccaratTigerTablesReport.reduce
            ? 0
            : baccaratTigerTablesReport.reduce((prev, next) => prev + next.profit, 0)
    const blackjackStandardWinLoss =
        !blackjackStandardTablesReport || !blackjackStandardTablesReport.reduce
            ? 0
            : blackjackStandardTablesReport.reduce((prev, next) => prev + next.profit, 0)
    const payout =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.return, 0)
    const sideWager =
        baccaratStandardSideWager +
        baccaratLucky6SideWager +
        baccaratMega6SideWager +
        baccaratTigerSideWager +
        blackjackStandardSideWager
    const sideBetCounts =
        baccaratStandardSideBetCounts +
        baccaratLucky6SideBetCounts +
        baccaratMega6SideBetCounts +
        baccaratTigerSideBetCounts +
        blackjackStandardSideBetCounts
    const numberOfGamesWithBets =
        baccaratStandardGamesWithBets +
        baccaratLucky6GamesWithBets +
        baccaratMega6GamesWithBets +
        baccaratTigerGamesWithBets +
        blackjackStandardGamesWithBets
    const betCount =
        baccaratStandardBetCount +
        baccaratLucky6BetCount +
        baccaratMega6BetCount +
        baccaratTigerBetCount +
        blackjackStandardBetCount

    // this detects the hotreload from react
    if (treeLoading && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    const fluid = width < 1900

    if (settings && !settings.data.xtable.sections.includes('statistics')) {
        return <Navigate to='/dashboard' />
    }

    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: 320 }}>
                <Tree
                    loading={treeLoading}
                    autoUpdateEnabled={true}
                    dateChange={dateChange}
                    startDate={startDate}
                    endDate={endDate}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    setLocation={(newLocation) => {
                        if (newLocation.gameType !== gameType) {
                            setBuiltCharts({})
                        }
                        setLocation(newLocation)
                    }}
                    location={location}
                    fluid={fluid}
                    xstadium={xstadium}
                />
            </Grid>
            <Grid item xs>
                {resizing && <LinearProgress />}
                {!resizing && !xstadium && (
                    <Location
                        loading={treeLoading}
                        zones={zones}
                        pits={pits}
                        tables={tables}
                        devices={devices}
                        location={location}
                        fluid={fluid}
                    />
                )}
                {!treeLoading && !resizing && (
                    <>
                        {loading && (
                            <Container fluid={fluid}>
                                <Row xs='1'>
                                    <Col>
                                        <h3>Fetching report...</h3>
                                        <Progress value={progressLoad} />
                                    </Col>
                                </Row>
                            </Container>
                        )}
                        {!loading && (
                            <Container fluid={fluid}>
                                {/* overview aggregated values */}
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Total Wager</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <span style={styles.cardBodyText}>{formatCashString(wager)}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Total House Win/Loss</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <span
                                                    style={{
                                                        ...styles.cardBodyText,
                                                        ...styles.cashValue(winLoss),
                                                    }}
                                                >
                                                    {formatCashString(winLoss)}
                                                </span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Theoretical Hold</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <span style={styles.cardBodyText}>
                                                    {formatPresicePercentString(winLoss / wager)}
                                                </span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                {!isTable && (
                                    <GameTypeMetrics
                                        gameType='baccarat'
                                        gameVariant='standard'
                                        wager={baccaratStandardWager}
                                        winLoss={baccaratStandardWinLoss}
                                        handleClick={() => viewDetails('baccarat', 'standard')}
                                    />
                                )}

                                {!isTable && (
                                    <GameTypeMetrics
                                        gameType='baccarat'
                                        gameVariant='lucky6'
                                        wager={baccaratLucky6Wager}
                                        winLoss={baccaratLucky6WinLoss}
                                        handleClick={() => viewDetails('baccarat', 'lucky6')}
                                    />
                                )}

                                {!isTable && (
                                    <GameTypeMetrics
                                        gameType='baccarat'
                                        gameVariant='mega6'
                                        wager={baccaratMega6Wager}
                                        winLoss={baccaratMega6WinLoss}
                                        handleClick={() => viewDetails('baccarat', 'mega6')}
                                    />
                                )}

                                {!isTable && (
                                    <GameTypeMetrics
                                        gameType='baccarat'
                                        gameVariant='tiger'
                                        wager={baccaratTigerWager}
                                        winLoss={baccaratTigerWinLoss}
                                        handleClick={() => viewDetails('baccarat', 'tiger')}
                                    />
                                )}

                                {!isTable && (
                                    <GameTypeMetrics
                                        gameType='blackjack'
                                        gameVariant='standard'
                                        wager={blackjackStandardWager}
                                        winLoss={blackjackStandardWinLoss}
                                        handleClick={() => viewDetails('blackjack', 'standard')}
                                    />
                                )}

                                {isTable && (
                                    <>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>Total Game Count</span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        <span style={styles.cardBodyText}>
                                                            {formatCountString(numberOfGames)}
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>Theoretical RTP</span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        <span style={styles.cardBodyText}>
                                                            {formatPresicePercentString(1 - winLoss / wager)}
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>
                                                            Total Game Count with Bet(s)
                                                        </span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        <span style={styles.cardBodyText}>
                                                            {formatCountString(numberOfGamesWithBets)}
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>Total Bet Count</span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        <span style={styles.cardBodyText}>
                                                            {formatCountString(betCount)}
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>Bet Efficiency</span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        <span style={styles.cardBodyText}>
                                                            {formatPresicePercentString(
                                                                betCount / (numberOfGames * 5 * 4)
                                                            )}
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>Total Payout</span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        <span style={styles.cardBodyText}>
                                                            {formatCashString(payout)}
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>
                                                            Side Bet Participation Wager
                                                        </span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        <span style={styles.cardBodyText}>
                                                            {formatPresicePercentString(sideWager / wager)}
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <CardHeader style={styles.cardHeader}>
                                                        <span style={styles.cardTitleText}>
                                                            Side Bet Participation Count
                                                        </span>
                                                    </CardHeader>
                                                    <CardBody style={styles.cardBody}>
                                                        <span style={styles.cardBodyText}>
                                                            {formatPresicePercentString(sideBetCounts / betCount)}
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                        {numberOfDays > 0 && tablesReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>
                                                                Minimum Bet History
                                                            </span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <TableHistoryChart
                                                                setBuiltChart={(value) =>
                                                                    setBuiltChartProxy('minbet', value)
                                                                }
                                                                builtChart={builtCharts['minbet']}
                                                                findInLabelMap={() => 'min bet'}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                prometheus={prometheusTable}
                                                                query={minBetHistory}
                                                                hideLegend
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'standard' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager History</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratStandardWagerHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratStandardWagerHistory']
                                                                    }
                                                                    findInLabelMap={baccaratStandardPerLabelFind}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratStandardBetsHistory}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'lucky6' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager History</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratLucky6WagerHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratLucky6WagerHistory']
                                                                    }
                                                                    findInLabelMap={baccaratLucky6PerLabelFind}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratLucky6BetsHistory}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'mega6' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager History</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratMega6WagerHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratMega6WagerHistory']
                                                                    }
                                                                    findInLabelMap={baccaratMega6PerLabelFind}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratMega6BetsHistory}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'tiger' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager History</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratTigerWagerHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratTigerWagerHistory']
                                                                    }
                                                                    findInLabelMap={baccaratTigerPerLabelFind}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratTigerBetsHistory}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'blackjack' &&
                                            gameVariant === 'standard' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Wager History</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'blackjackStandardWagerHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['blackjackStandardWagerHistory']
                                                                    }
                                                                    findInLabelMap={blackjackStandardPerLabelFind}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={blackjackStandardBetsHistory}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {numberOfDays === 0 && tablesReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>Hourly Wager</span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <div style={styles.barContainer}>
                                                                <Bar
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        redraw: true,
                                                                        updateMode: 'resize',
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        scales: {
                                                                            yAxes: [
                                                                                {
                                                                                    id: 'first-y-axis',
                                                                                    type: 'linear',
                                                                                    ticks: {
                                                                                        min: 0,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    }}
                                                                    data={getHoursWagerData()}
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'standard' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratStandardProfitHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratStandardProfitHistory']
                                                                    }
                                                                    findInLabelMap={() => 'house win/loss'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratStandardProfitHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'lucky6' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratLucky6ProfitHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratLucky6ProfitHistory']
                                                                    }
                                                                    findInLabelMap={() => 'house win/loss'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratLucky6ProfitHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'mega6' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratMega6ProfitHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratMega6ProfitHistory']
                                                                    }
                                                                    findInLabelMap={() => 'house win/loss'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratMega6ProfitHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'tiger' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratTigerProfitHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratTigerProfitHistory']
                                                                    }
                                                                    findInLabelMap={() => 'house win/loss'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratTigerProfitHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'blackjack' &&
                                            gameVariant === 'standard' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'blackjackStandardProfitHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['blackjackStandardProfitHistory']
                                                                    }
                                                                    findInLabelMap={() => 'house win/loss'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={blackjackStandardProfitHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {numberOfDays === 0 && tablesReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>
                                                                Hourly House Win/Loss
                                                            </span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <div style={styles.barContainer}>
                                                                <Bar
                                                                    options={{
                                                                        backgroundColor: chartBackgroundColors[1],
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                    }}
                                                                    data={getHoursWinLossData()}
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'standard' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Game Count History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratStandardGameCountHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratStandardGameCountHistory']
                                                                    }
                                                                    findInLabelMap={() => 'game count'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratStandardGameCountHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'lucky6' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Game Count History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratLucky6GameCountHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratLucky6GameCountHistory']
                                                                    }
                                                                    findInLabelMap={() => 'game count'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratLucky6GameCountHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'mega6' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Game Count History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratMega6GameCountHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratMega6GameCountHistory']
                                                                    }
                                                                    findInLabelMap={() => 'game count'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratMega6GameCountHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'baccarat' &&
                                            gameVariant === 'tiger' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Game Count History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'baccaratTigerGameCountHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['baccaratTigerGameCountHistory']
                                                                    }
                                                                    findInLabelMap={() => 'game count'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={baccaratTigerGameCountHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {gameType === 'blackjack' &&
                                            gameVariant === 'standard' &&
                                            numberOfDays > 0 &&
                                            tablesReport && (
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Game Count History
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <TableHistoryChart
                                                                    setBuiltChart={(value) =>
                                                                        setBuiltChartProxy(
                                                                            'blackjackStandardGameCountHistory',
                                                                            value
                                                                        )
                                                                    }
                                                                    builtChart={
                                                                        builtCharts['blackjackStandardGameCountHistory']
                                                                    }
                                                                    findInLabelMap={() => 'game count'}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    prometheus={prometheusTable}
                                                                    query={blackjackStandardGameCountHistory}
                                                                    hideLegend
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}

                                        {numberOfDays === 0 && tablesReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>
                                                                Hourly Number of Games
                                                            </span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <div style={styles.barContainer}>
                                                                <Line
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        scales: {
                                                                            yAxes: [
                                                                                {
                                                                                    id: 'first-y-axis',
                                                                                    type: 'linear',
                                                                                    ticks: {
                                                                                        min: 0,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    }}
                                                                    data={getHoursGamesData()}
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {!tablesCashReport && (
                                            <Container fluid={width <= 1680}>
                                                <Row xs='1'>
                                                    <Col>
                                                        <h3>Fetching tray report...</h3>
                                                        <Progress value={fetchTablesCashReportProgress} />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        )}

                                        {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>Hourly Cash Drop</span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <div style={styles.barContainer}>
                                                                <Bar
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        redraw: true,
                                                                        updateMode: 'resize',
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        scales: {
                                                                            yAxes: [
                                                                                {
                                                                                    id: 'first-y-axis',
                                                                                    type: 'linear',
                                                                                    ticks: {
                                                                                        min: 0,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    }}
                                                                    data={getHoursCashDropData()}
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {numberOfDays > 0 && tablesCashReport && fetchedTablesCashReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>Cash Drop History</span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <TableHistoryChart
                                                                setBuiltChart={(value) =>
                                                                    setBuiltChartProxy('cashdrop', value)
                                                                }
                                                                builtChart={builtCharts['cashdrop']}
                                                                findInLabelMap={() => 'cash drop'}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                prometheus={prometheusTrend}
                                                                query={queryCashdropHistory}
                                                                hideLegend
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>Hourly Chip Fill</span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <div style={styles.barContainer}>
                                                                <Bar
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        redraw: true,
                                                                        updateMode: 'resize',
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        scales: {
                                                                            yAxes: [
                                                                                {
                                                                                    id: 'first-y-axis',
                                                                                    type: 'linear',
                                                                                    ticks: {
                                                                                        min: 0,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    }}
                                                                    data={getHoursChipFillData()}
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {numberOfDays > 0 && tablesCashReport && fetchedTablesCashReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>Chip Fill History</span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <TableHistoryChart
                                                                setBuiltChart={(value) =>
                                                                    setBuiltChartProxy('chipfill', value)
                                                                }
                                                                builtChart={builtCharts['chipfill']}
                                                                findInLabelMap={() => 'chip fill'}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                prometheus={prometheusTrend}
                                                                query={queryChipfillHistory}
                                                                hideLegend
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>Hourly Chip Credit</span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <div style={styles.barContainer}>
                                                                <Bar
                                                                    options={{
                                                                        plugins: {
                                                                            legend: false,
                                                                        },
                                                                        redraw: true,
                                                                        updateMode: 'resize',
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        scales: {
                                                                            yAxes: [
                                                                                {
                                                                                    id: 'first-y-axis',
                                                                                    type: 'linear',
                                                                                    ticks: {
                                                                                        min: 0,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    }}
                                                                    data={getHoursChipCreditData()}
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}

                                        {numberOfDays > 0 && tablesCashReport && fetchedTablesCashReport && (
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader style={styles.cardHeader}>
                                                            <span style={styles.cardTitleText}>
                                                                Chip Credit History
                                                            </span>
                                                        </CardHeader>
                                                        <CardBody style={styles.cardBody}>
                                                            <TableHistoryChart
                                                                setBuiltChart={(value) =>
                                                                    setBuiltChartProxy('chipcredit', value)
                                                                }
                                                                builtChart={builtCharts['chipcredit']}
                                                                findInLabelMap={() => 'chip credit'}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                prometheus={prometheusTrend}
                                                                query={queryChipcreditHistory}
                                                                hideLegend
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}
                                        {gameType === 'baccarat' && gameVariant === 'standard' && (
                                            <>
                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Wagers',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratStandardWagerBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardWagerPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardWagerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardWagerBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardWagerPlayerPair
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratStandardBetTypeSimpleCashBreakdown
                                                                        banker={baccaratStandardWagerBanker}
                                                                        player={baccaratStandardWagerPlayer}
                                                                        tie={baccaratStandardWagerTie}
                                                                        bankerPair={baccaratStandardWagerBankerPair}
                                                                        playerPair={baccaratStandardWagerPlayerPair}
                                                                        lucky6={baccaratStandardWagerLucky6}
                                                                        total={baccaratStandardWager}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitBanker <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitPlayer <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitTie <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitBankerPair <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitPlayerPair <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitLucky6 <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratStandardBetTypeCashBreakdown
                                                                        banker={
                                                                            baccaratStandardProfitBanker < 0
                                                                                ? 0
                                                                                : baccaratStandardProfitBanker
                                                                        }
                                                                        player={
                                                                            baccaratStandardProfitPlayer < 0
                                                                                ? 0
                                                                                : baccaratStandardProfitPlayer
                                                                        }
                                                                        tie={
                                                                            baccaratStandardProfitTie < 0
                                                                                ? 0
                                                                                : baccaratStandardProfitTie
                                                                        }
                                                                        bankerPair={
                                                                            baccaratStandardProfitBankerPair < 0
                                                                                ? 0
                                                                                : baccaratStandardProfitBankerPair
                                                                        }
                                                                        playerPair={
                                                                            baccaratStandardProfitPlayerPair < 0
                                                                                ? 0
                                                                                : baccaratStandardProfitPlayerPair
                                                                        }
                                                                        lucky6={
                                                                            baccaratStandardProfitLucky6 < 0
                                                                                ? 0
                                                                                : baccaratStandardProfitLucky6
                                                                        }
                                                                        total={getBaccaratStandardHouseWin()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Loss Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Loss',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitBanker >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitPlayer >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitTie >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitBankerPair >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitPlayerPair >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardProfitLucky6 >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratStandardProfitLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratStandardBetTypeCashBreakdown
                                                                        banker={
                                                                            baccaratStandardProfitBanker > 0
                                                                                ? 0
                                                                                : baccaratStandardProfitBanker
                                                                        }
                                                                        player={
                                                                            baccaratStandardProfitPlayer > 0
                                                                                ? 0
                                                                                : baccaratStandardProfitPlayer
                                                                        }
                                                                        tie={
                                                                            baccaratStandardProfitTie > 0
                                                                                ? 0
                                                                                : baccaratStandardProfitTie
                                                                        }
                                                                        bankerPair={
                                                                            baccaratStandardProfitBankerPair > 0
                                                                                ? 0
                                                                                : baccaratStandardProfitBankerPair
                                                                        }
                                                                        playerPair={
                                                                            baccaratStandardProfitPlayerPair > 0
                                                                                ? 0
                                                                                : baccaratStandardProfitPlayerPair
                                                                        }
                                                                        lucky6={
                                                                            baccaratStandardProfitLucky6 > 0
                                                                                ? 0
                                                                                : baccaratStandardProfitLucky6
                                                                        }
                                                                        total={getBaccaratStandardHouseLoss()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout per bet type',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratStandardPayoutBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardPayoutPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardPayoutTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardPayoutBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardPayoutPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardPayoutLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratStandardBetTypeSimpleCashBreakdown
                                                                        banker={baccaratStandardPayoutBanker}
                                                                        player={baccaratStandardPayoutPlayer}
                                                                        tie={baccaratStandardPayoutTie}
                                                                        bankerPair={baccaratStandardPayoutBankerPair}
                                                                        playerPair={baccaratStandardPayoutPlayerPair}
                                                                        total={baccaratStandardPayout}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Bet Count Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Bets Counts',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratStandardBetCountBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardBetCountPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardBetCountTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardBetCountBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardBetCountPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardBetCountLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratStandardBetTypeBreakdown
                                                                        banker={formatCountString(
                                                                            baccaratStandardBetCountBanker
                                                                        )}
                                                                        player={formatCountString(
                                                                            baccaratStandardBetCountPlayer
                                                                        )}
                                                                        tie={formatCountString(
                                                                            baccaratStandardBetCountTie
                                                                        )}
                                                                        bankerPair={formatCountString(
                                                                            baccaratStandardBetCountBankerPair
                                                                        )}
                                                                        playerPair={formatCountString(
                                                                            baccaratStandardBetCountPlayerPair
                                                                        )}
                                                                        lucky6={formatCountString(
                                                                            baccaratStandardBetCountLucky6
                                                                        )}
                                                                        total={formatCountString(
                                                                            baccaratStandardBetCount
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout/Bet',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardPayoutBanker /
                                                                                                baccaratStandardWagerBanker
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardPayoutPlayer /
                                                                                                baccaratStandardWagerPlayer
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardPayoutTie /
                                                                                                baccaratStandardWagerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardPayoutBankerPair /
                                                                                                baccaratStandardWagerBankerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardPayoutPlayerPair /
                                                                                                baccaratStandardWagerPlayerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardPayoutLucky6 /
                                                                                                baccaratStandardWagerLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratStandardBetTypeBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratStandardPayoutBanker /
                                                                                baccaratStandardWagerBanker
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratStandardPayoutPlayer /
                                                                                baccaratStandardWagerPlayer
                                                                        )}
                                                                        tie={formatPresicePercentString(
                                                                            baccaratStandardPayoutTie /
                                                                                baccaratStandardWagerTie
                                                                        )}
                                                                        bankerPair={formatPresicePercentString(
                                                                            baccaratStandardPayoutBankerPair /
                                                                                baccaratStandardWagerBankerPair
                                                                        )}
                                                                        playerPair={formatPresicePercentString(
                                                                            baccaratStandardPayoutPlayerPair /
                                                                                baccaratStandardWagerPlayerPair
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            baccaratStandardPayout /
                                                                                baccaratStandardWager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win/Loss (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardProfitBanker /
                                                                                                baccaratStandardWagerBanker
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardProfitPlayer /
                                                                                                baccaratStandardWagerPlayer
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardProfitTie /
                                                                                                baccaratStandardWagerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardProfitBankerPair /
                                                                                                baccaratStandardWagerBankerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardProfitPlayerPair /
                                                                                                baccaratStandardWagerPlayerPair
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratStandardBetTypeBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratStandardProfitBanker /
                                                                                baccaratStandardWagerBanker
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratStandardProfitPlayer /
                                                                                baccaratStandardWagerPlayer
                                                                        )}
                                                                        tie={formatPresicePercentString(
                                                                            baccaratStandardProfitTie /
                                                                                baccaratStandardWagerTie
                                                                        )}
                                                                        bankerPair={formatPresicePercentString(
                                                                            baccaratStandardProfitBankerPair /
                                                                                baccaratStandardWagerBankerPair
                                                                        )}
                                                                        playerPair={formatPresicePercentString(
                                                                            baccaratStandardProfitPlayerPair /
                                                                                baccaratStandardWagerPlayerPair
                                                                        )}
                                                                        lucky6={formatPresicePercentString(
                                                                            baccaratStandardProfitLucky6 /
                                                                                baccaratStandardWagerLucky6
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            baccaratStandardProfit /
                                                                                baccaratStandardWager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Standoff (Tie)</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Standoff',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratStandardStandoffBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratStandardStandoffPlayer
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={baccaratStandardStandoffBanker}
                                                                        player={baccaratStandardStandoffPlayer}
                                                                        total={baccaratStandardStandoff}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Banker vs. Player Split Wager
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Banker vs. Player split wgr (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardWagerBanker /
                                                                                                baccaratStandardMainWager
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardWagerPlayer /
                                                                                                baccaratStandardMainWager
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratStandardWagerBanker /
                                                                                baccaratStandardMainWager
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratStandardWagerPlayer /
                                                                                baccaratStandardMainWager
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Banker vs. Player Split Count
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Banker vs. Player split count (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardBetCountBanker /
                                                                                                baccaratStandardMainBetCounts
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratStandardBetCountPlayer /
                                                                                                baccaratStandardMainBetCounts
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratStandardBetCountBanker /
                                                                                baccaratStandardMainBetCounts
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratStandardBetCountPlayer /
                                                                                baccaratStandardMainBetCounts
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {gameType === 'baccarat' && gameVariant === 'lucky6' && (
                                            <>
                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Wagers',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratLucky6WagerBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6WagerPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6WagerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6WagerBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6WagerPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6WagerLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratLucky6BetTypeSimpleCashBreakdown
                                                                        banker={baccaratLucky6WagerBanker}
                                                                        player={baccaratLucky6WagerPlayer}
                                                                        tie={baccaratLucky6WagerTie}
                                                                        bankerPair={baccaratLucky6WagerBankerPair}
                                                                        playerPair={baccaratLucky6WagerPlayerPair}
                                                                        lucky6={baccaratLucky6WagerLucky6}
                                                                        total={baccaratLucky6Wager}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitBanker <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitPlayer <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitTie < 0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitBankerPair <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitPlayerPair <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitLucky6 <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratLucky6BetTypeCashBreakdown
                                                                        banker={
                                                                            baccaratLucky6ProfitBanker < 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitBanker
                                                                        }
                                                                        player={
                                                                            baccaratLucky6ProfitPlayer < 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitPlayer
                                                                        }
                                                                        tie={
                                                                            baccaratLucky6ProfitTie < 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitTie
                                                                        }
                                                                        bankerPair={
                                                                            baccaratLucky6ProfitBankerPair < 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitBankerPair
                                                                        }
                                                                        playerPair={
                                                                            baccaratLucky6ProfitPlayerPair < 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitPlayerPair
                                                                        }
                                                                        lucky6={
                                                                            baccaratLucky6ProfitLucky6 < 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitLucky6
                                                                        }
                                                                        total={getBaccaratLucky6HouseWin()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Loss Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Loss',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitBanker >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitPlayer >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitTie > 0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitBankerPair >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitPlayerPair >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6ProfitLucky6 >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratLucky6ProfitLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratLucky6BetTypeCashBreakdown
                                                                        banker={
                                                                            baccaratLucky6ProfitBanker > 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitBanker
                                                                        }
                                                                        player={
                                                                            baccaratLucky6ProfitPlayer > 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitPlayer
                                                                        }
                                                                        tie={
                                                                            baccaratLucky6ProfitTie > 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitTie
                                                                        }
                                                                        bankerPair={
                                                                            baccaratLucky6ProfitBankerPair > 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitBankerPair
                                                                        }
                                                                        playerPair={
                                                                            baccaratLucky6ProfitPlayerPair > 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitPlayerPair
                                                                        }
                                                                        lucky6={
                                                                            baccaratLucky6ProfitLucky6 > 0
                                                                                ? 0
                                                                                : baccaratLucky6ProfitLucky6
                                                                        }
                                                                        total={getBaccaratLucky6HouseLoss()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout per bet type',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratLucky6PayoutBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6PayoutPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6PayoutTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6PayoutBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6PayoutPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6PayoutLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratLucky6BetTypeSimpleCashBreakdown
                                                                        banker={baccaratLucky6PayoutBanker}
                                                                        player={baccaratLucky6PayoutPlayer}
                                                                        tie={baccaratLucky6PayoutTie}
                                                                        bankerPair={baccaratLucky6PayoutBankerPair}
                                                                        playerPair={baccaratLucky6PayoutPlayerPair}
                                                                        lucky6={baccaratLucky6PayoutLucky6}
                                                                        total={baccaratLucky6Payout}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Bet Count Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Bets Counts',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratLucky6BetCountBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6BetCountPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6BetCountTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6BetCountBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6BetCountPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6BetCountLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratLucky6BetTypeBreakdown
                                                                        banker={formatCountString(
                                                                            baccaratLucky6BetCountBanker
                                                                        )}
                                                                        player={formatCountString(
                                                                            baccaratLucky6BetCountPlayer
                                                                        )}
                                                                        tie={formatCountString(
                                                                            baccaratLucky6BetCountTie
                                                                        )}
                                                                        bankerPair={formatCountString(
                                                                            baccaratLucky6BetCountBankerPair
                                                                        )}
                                                                        playerPair={formatCountString(
                                                                            baccaratLucky6BetCountPlayerPair
                                                                        )}
                                                                        lucky6={formatCountString(
                                                                            baccaratLucky6BetCountLucky6
                                                                        )}
                                                                        total={formatCountString(
                                                                            baccaratLucky6BetCount
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout/Bet',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6PayoutBanker /
                                                                                                baccaratLucky6WagerBanker
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6PayoutPlayer /
                                                                                                baccaratLucky6WagerPlayer
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6PayoutTie /
                                                                                                baccaratLucky6WagerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6PayoutBankerPair /
                                                                                                baccaratLucky6WagerBankerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6PayoutPlayerPair /
                                                                                                baccaratLucky6WagerPlayerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6PayoutLucky6 /
                                                                                                baccaratLucky6WagerLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratLucky6BetTypeBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratLucky6PayoutBanker /
                                                                                baccaratLucky6WagerBanker
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratLucky6PayoutPlayer /
                                                                                baccaratLucky6WagerPlayer
                                                                        )}
                                                                        tie={formatPresicePercentString(
                                                                            baccaratLucky6PayoutTie /
                                                                                baccaratLucky6WagerTie
                                                                        )}
                                                                        bankerPair={formatPresicePercentString(
                                                                            baccaratLucky6PayoutBankerPair /
                                                                                baccaratLucky6WagerBankerPair
                                                                        )}
                                                                        playerPair={formatPresicePercentString(
                                                                            baccaratLucky6PayoutPlayerPair /
                                                                                baccaratLucky6WagerPlayerPair
                                                                        )}
                                                                        lucky6={formatPresicePercentString(
                                                                            baccaratLucky6PayoutLucky6 /
                                                                                baccaratLucky6WagerLucky6
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            baccaratLucky6Payout / baccaratLucky6Wager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win/Loss (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6ProfitBanker /
                                                                                                baccaratLucky6WagerBanker
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6ProfitPlayer /
                                                                                                baccaratLucky6WagerPlayer
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6ProfitTie /
                                                                                                baccaratLucky6WagerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6ProfitBankerPair /
                                                                                                baccaratLucky6WagerBankerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6ProfitPlayerPair /
                                                                                                baccaratLucky6WagerPlayerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6ProfitLucky6 /
                                                                                                baccaratLucky6WagerLucky6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratLucky6BetTypeBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratLucky6ProfitBanker /
                                                                                baccaratLucky6WagerBanker
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratLucky6ProfitPlayer /
                                                                                baccaratLucky6WagerPlayer
                                                                        )}
                                                                        tie={formatPresicePercentString(
                                                                            baccaratLucky6ProfitTie /
                                                                                baccaratLucky6WagerTie
                                                                        )}
                                                                        bankerPair={formatPresicePercentString(
                                                                            baccaratLucky6ProfitBankerPair /
                                                                                baccaratLucky6WagerBankerPair
                                                                        )}
                                                                        playerPair={formatPresicePercentString(
                                                                            baccaratLucky6ProfitPlayerPair /
                                                                                baccaratLucky6WagerPlayerPair
                                                                        )}
                                                                        lucky6={formatPresicePercentString(
                                                                            baccaratLucky6ProfitLucky6 /
                                                                                baccaratLucky6WagerLucky6
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            baccaratLucky6Profit / baccaratLucky6Wager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Standoff (Tie)</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Standoff',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratLucky6StandoffBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratLucky6StandoffPlayer
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={baccaratLucky6StandoffBanker}
                                                                        player={baccaratLucky6StandoffPlayer}
                                                                        total={baccaratLucky6Standoff}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Banker vs. Player Split Wager
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Banker vs. Player split wgr (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6WagerBanker /
                                                                                                baccaratLucky6MainWager
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6WagerPlayer /
                                                                                                baccaratLucky6MainWager
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratLucky6WagerBanker /
                                                                                baccaratLucky6MainWager
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratLucky6WagerPlayer /
                                                                                baccaratLucky6MainWager
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Banker vs. Player Split Count
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratLucky6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Banker vs. Player split count (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6BetCountBanker /
                                                                                                baccaratLucky6MainBetCounts
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratLucky6BetCountPlayer /
                                                                                                baccaratLucky6MainBetCounts
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratLucky6BetCountBanker /
                                                                                baccaratLucky6MainBetCounts
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratLucky6BetCountPlayer /
                                                                                baccaratLucky6MainBetCounts
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {gameType === 'baccarat' && gameVariant === 'mega6' && (
                                            <>
                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Wagers',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratMega6WagerBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6WagerPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6WagerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6WagerBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6WagerPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6WagerMega6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratMega6BetTypeSimpleCashBreakdown
                                                                        banker={baccaratMega6WagerBanker}
                                                                        player={baccaratMega6WagerPlayer}
                                                                        tie={baccaratMega6WagerTie}
                                                                        bankerPair={baccaratMega6WagerBankerPair}
                                                                        playerPair={baccaratMega6WagerPlayerPair}
                                                                        mega6={baccaratMega6WagerMega6}
                                                                        total={baccaratMega6Wager}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitBanker <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitPlayer <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitTie < 0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitBankerPair <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitPlayerPair <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitMega6 < 0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitMega6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratMega6BetTypeCashBreakdown
                                                                        banker={
                                                                            baccaratMega6ProfitBanker < 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitBanker
                                                                        }
                                                                        player={
                                                                            baccaratMega6ProfitPlayer < 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitPlayer
                                                                        }
                                                                        tie={
                                                                            baccaratMega6ProfitTie < 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitTie
                                                                        }
                                                                        bankerPair={
                                                                            baccaratMega6ProfitBankerPair < 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitBankerPair
                                                                        }
                                                                        playerPair={
                                                                            baccaratMega6ProfitPlayerPair < 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitPlayerPair
                                                                        }
                                                                        mega6={
                                                                            baccaratMega6ProfitMega6 < 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitMega6
                                                                        }
                                                                        total={getBaccaratMega6HouseWin()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Loss Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Loss',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitBanker >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitPlayer >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitTie > 0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitBankerPair >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitPlayerPair >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6ProfitMega6 > 0
                                                                                                ? 0
                                                                                                : baccaratMega6ProfitMega6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratMega6BetTypeCashBreakdown
                                                                        banker={
                                                                            baccaratMega6ProfitBanker > 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitBanker
                                                                        }
                                                                        player={
                                                                            baccaratMega6ProfitPlayer > 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitPlayer
                                                                        }
                                                                        tie={
                                                                            baccaratMega6ProfitTie > 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitTie
                                                                        }
                                                                        bankerPair={
                                                                            baccaratMega6ProfitBankerPair > 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitBankerPair
                                                                        }
                                                                        playerPair={
                                                                            baccaratMega6ProfitPlayerPair > 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitPlayerPair
                                                                        }
                                                                        mega6={
                                                                            baccaratMega6ProfitMega6 > 0
                                                                                ? 0
                                                                                : baccaratMega6ProfitMega6
                                                                        }
                                                                        total={getBaccaratMega6HouseLoss()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout per bet type',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratMega6PayoutBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6PayoutPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6PayoutTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6PayoutBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6PayoutPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6PayoutMega6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratMega6BetTypeSimpleCashBreakdown
                                                                        banker={baccaratMega6PayoutBanker}
                                                                        player={baccaratMega6PayoutPlayer}
                                                                        tie={baccaratMega6PayoutTie}
                                                                        bankerPair={baccaratMega6PayoutBankerPair}
                                                                        playerPair={baccaratMega6PayoutPlayerPair}
                                                                        mega6={baccaratMega6PayoutMega6}
                                                                        total={baccaratMega6Payout}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Bet Count Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Bets Counts',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratMega6BetCountBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6BetCountPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6BetCountTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6BetCountBankerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6BetCountPlayerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6BetCountMega6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratMega6BetTypeBreakdown
                                                                        banker={formatCountString(
                                                                            baccaratMega6BetCountBanker
                                                                        )}
                                                                        player={formatCountString(
                                                                            baccaratMega6BetCountPlayer
                                                                        )}
                                                                        tie={formatCountString(
                                                                            baccaratMega6BetCountTie
                                                                        )}
                                                                        bankerPair={formatCountString(
                                                                            baccaratMega6BetCountBankerPair
                                                                        )}
                                                                        playerPair={formatCountString(
                                                                            baccaratMega6BetCountPlayerPair
                                                                        )}
                                                                        mega6={formatCountString(
                                                                            baccaratMega6BetCountMega6
                                                                        )}
                                                                        total={formatCountString(baccaratMega6BetCount)}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout/Bet',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6PayoutBanker /
                                                                                                baccaratMega6WagerBanker
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6PayoutPlayer /
                                                                                                baccaratMega6WagerPlayer
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6PayoutTie /
                                                                                                baccaratMega6WagerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6PayoutBankerPair /
                                                                                                baccaratMega6WagerBankerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6PayoutPlayerPair /
                                                                                                baccaratMega6WagerPlayerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6PayoutMega6 /
                                                                                                baccaratMega6WagerMega6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratMega6BetTypeBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratMega6PayoutBanker /
                                                                                baccaratMega6WagerBanker
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratMega6PayoutPlayer /
                                                                                baccaratMega6WagerPlayer
                                                                        )}
                                                                        tie={formatPresicePercentString(
                                                                            baccaratMega6PayoutTie /
                                                                                baccaratMega6WagerTie
                                                                        )}
                                                                        bankerPair={formatPresicePercentString(
                                                                            baccaratMega6PayoutBankerPair /
                                                                                baccaratMega6WagerBankerPair
                                                                        )}
                                                                        playerPair={formatPresicePercentString(
                                                                            baccaratMega6PayoutPlayerPair /
                                                                                baccaratMega6WagerPlayerPair
                                                                        )}
                                                                        mega6={formatPresicePercentString(
                                                                            baccaratMega6PayoutMega6 /
                                                                                baccaratMega6WagerMega6
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            baccaratMega6Payout / baccaratMega6Wager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win/Loss (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6ProfitBanker /
                                                                                                baccaratMega6WagerBanker
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6ProfitPlayer /
                                                                                                baccaratMega6WagerPlayer
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6ProfitTie /
                                                                                                baccaratMega6WagerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6ProfitBankerPair /
                                                                                                baccaratMega6WagerBankerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6ProfitPlayerPair /
                                                                                                baccaratMega6WagerPlayerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6ProfitMega6 /
                                                                                                baccaratMega6WagerMega6
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratMega6BetTypeBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratMega6ProfitBanker /
                                                                                baccaratMega6WagerBanker
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratMega6ProfitPlayer /
                                                                                baccaratMega6WagerPlayer
                                                                        )}
                                                                        tie={formatPresicePercentString(
                                                                            baccaratMega6ProfitTie /
                                                                                baccaratMega6WagerTie
                                                                        )}
                                                                        bankerPair={formatPresicePercentString(
                                                                            baccaratMega6ProfitBankerPair /
                                                                                baccaratMega6WagerBankerPair
                                                                        )}
                                                                        playerPair={formatPresicePercentString(
                                                                            baccaratMega6ProfitPlayerPair /
                                                                                baccaratMega6WagerPlayerPair
                                                                        )}
                                                                        mega6={formatPresicePercentString(
                                                                            baccaratMega6ProfitMega6 /
                                                                                baccaratMega6WagerMega6
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            baccaratMega6Profit / baccaratMega6Wager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Standoff (Tie)</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Standoff',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratMega6StandoffBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratMega6StandoffPlayer
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={baccaratMega6StandoffBanker}
                                                                        player={baccaratMega6StandoffPlayer}
                                                                        total={baccaratMega6Standoff}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Banker vs. Player Split Wager
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Banker vs. Player split wgr (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6WagerBanker /
                                                                                                baccaratMega6MainWager
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6WagerPlayer /
                                                                                                baccaratMega6MainWager
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratMega6WagerBanker /
                                                                                baccaratMega6MainWager
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratMega6WagerPlayer /
                                                                                baccaratMega6MainWager
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Banker vs. Player Split Count
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratMega6CharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Banker vs. Player split count (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6BetCountBanker /
                                                                                                baccaratMega6MainBetCounts
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratMega6BetCountPlayer /
                                                                                                baccaratMega6MainBetCounts
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratMega6BetCountBanker /
                                                                                baccaratMega6MainBetCounts
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratMega6BetCountPlayer /
                                                                                baccaratMega6MainBetCounts
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {gameType === 'baccarat' && gameVariant === 'tiger' && (
                                            <>
                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Wagers',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratTigerWagerBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerWagerPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerWagerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerWagerTigerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerWagerTigerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerWagerTigerBig
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerWagerTigerSmall
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerWagerTiger
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratTigerBetTypeSimpleCashBreakdown
                                                                        banker={baccaratTigerWagerBanker}
                                                                        player={baccaratTigerWagerPlayer}
                                                                        tie={baccaratTigerWagerTie}
                                                                        tigerTie={baccaratTigerWagerTigerTie}
                                                                        tigerPair={baccaratTigerWagerTigerPair}
                                                                        tigerBig={baccaratTigerWagerTigerBig}
                                                                        tigerSmall={baccaratTigerWagerTigerSmall}
                                                                        tiger={baccaratTigerWagerTiger}
                                                                        total={baccaratTigerWager}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitBanker <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitPlayer <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTie < 0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTigerTie <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTigerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTigerPair <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTigerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTigerBig <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTigerBig
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTigerSmall <
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTigerSmall
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTiger < 0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTiger
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratTigerBetTypeCashBreakdown
                                                                        banker={
                                                                            baccaratTigerProfitBanker < 0
                                                                                ? 0
                                                                                : baccaratTigerProfitBanker
                                                                        }
                                                                        player={
                                                                            baccaratTigerProfitPlayer < 0
                                                                                ? 0
                                                                                : baccaratTigerProfitPlayer
                                                                        }
                                                                        tie={
                                                                            baccaratTigerProfitTie < 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTie
                                                                        }
                                                                        tigerTie={
                                                                            baccaratTigerProfitTigerTie < 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTigerTie
                                                                        }
                                                                        tigerPair={
                                                                            baccaratTigerProfitTigerPair < 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTigerPair
                                                                        }
                                                                        tigerBig={
                                                                            baccaratTigerProfitTigerBig < 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTigerBig
                                                                        }
                                                                        tigerSmall={
                                                                            baccaratTigerProfitTigerSmall < 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTigerSmall
                                                                        }
                                                                        tiger={
                                                                            baccaratTigerProfitTiger < 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTiger
                                                                        }
                                                                        total={getBaccaratTigerHouseWin()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Loss Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Loss',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitBanker >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitPlayer >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTie > 0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTigerTie >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTigerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTigerPair >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTigerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTigerBig >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTigerBig
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTigerSmall >
                                                                                                0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTigerSmall
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerProfitTiger > 0
                                                                                                ? 0
                                                                                                : baccaratTigerProfitTiger
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratTigerBetTypeCashBreakdown
                                                                        banker={
                                                                            baccaratTigerProfitBanker > 0
                                                                                ? 0
                                                                                : baccaratTigerProfitBanker
                                                                        }
                                                                        player={
                                                                            baccaratTigerProfitPlayer > 0
                                                                                ? 0
                                                                                : baccaratTigerProfitPlayer
                                                                        }
                                                                        tie={
                                                                            baccaratTigerProfitTie > 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTie
                                                                        }
                                                                        tigerTie={
                                                                            baccaratTigerProfitTigerTie > 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTigerTie
                                                                        }
                                                                        tigerPair={
                                                                            baccaratTigerProfitTigerPair > 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTigerPair
                                                                        }
                                                                        tigerBig={
                                                                            baccaratTigerProfitTigerBig > 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTigerBig
                                                                        }
                                                                        tigerSmall={
                                                                            baccaratTigerProfitTigerSmall > 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTigerSmall
                                                                        }
                                                                        tiger={
                                                                            baccaratTigerProfitTiger > 0
                                                                                ? 0
                                                                                : baccaratTigerProfitTiger
                                                                        }
                                                                        total={getBaccaratTigerHouseLoss()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout per bet type',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratTigerPayoutBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerPayoutPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerPayoutTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerPayoutTigerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerPayoutTigerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerPayoutTigerBig
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerPayoutTigerSmall
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerPayoutTiger
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratTigerBetTypeSimpleCashBreakdown
                                                                        banker={baccaratTigerPayoutBanker}
                                                                        player={baccaratTigerPayoutPlayer}
                                                                        tie={baccaratTigerPayoutTie}
                                                                        tigerTie={baccaratTigerPayoutTigerTie}
                                                                        tigerPair={baccaratTigerPayoutTigerPair}
                                                                        tigerBig={baccaratTigerPayoutTigerBig}
                                                                        tigerSmall={baccaratTigerPayoutTigerSmall}
                                                                        tiger={baccaratTigerPayoutTiger}
                                                                        total={baccaratTigerPayout}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Bet Count Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Bets Counts',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratTigerBetCountBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerBetCountPlayer
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerBetCountTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerBetCountTigerTie
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerBetCountTigerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerBetCountTigerBig
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerBetCountTiger
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratTigerBetTypeBreakdown
                                                                        banker={formatCountString(
                                                                            baccaratTigerBetCountBanker
                                                                        )}
                                                                        player={formatCountString(
                                                                            baccaratTigerBetCountPlayer
                                                                        )}
                                                                        tie={formatCountString(
                                                                            baccaratTigerBetCountTie
                                                                        )}
                                                                        tigerTie={formatCountString(
                                                                            baccaratTigerBetCountTigerTie
                                                                        )}
                                                                        tigerPair={formatCountString(
                                                                            baccaratTigerBetCountTigerPair
                                                                        )}
                                                                        tigerBig={formatCountString(
                                                                            baccaratTigerBetCountTigerBig
                                                                        )}
                                                                        tigerSmall={formatCountString(
                                                                            baccaratTigerBetCountTigerSmall
                                                                        )}
                                                                        tiger={formatCountString(
                                                                            baccaratTigerBetCountTiger
                                                                        )}
                                                                        total={formatCountString(baccaratTigerBetCount)}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout/Bet',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerPayoutBanker /
                                                                                                baccaratTigerWagerBanker
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerPayoutPlayer /
                                                                                                baccaratTigerWagerPlayer
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerPayoutTie /
                                                                                                baccaratTigerWagerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerPayoutTigerTie /
                                                                                                baccaratTigerWagerTigerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerPayoutTigerPair /
                                                                                                baccaratTigerWagerTigerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerPayoutTigerBig /
                                                                                                baccaratTigerWagerTigerBig
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerPayoutTigerSmall /
                                                                                                baccaratTigerWagerTigerSmall
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerPayoutTiger /
                                                                                                baccaratTigerWagerTiger
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratTigerBetTypeBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratTigerPayoutBanker /
                                                                                baccaratTigerWagerBanker
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratTigerPayoutPlayer /
                                                                                baccaratTigerWagerPlayer
                                                                        )}
                                                                        tie={formatPresicePercentString(
                                                                            baccaratTigerPayoutTie /
                                                                                baccaratTigerWagerTie
                                                                        )}
                                                                        tigerTie={formatPresicePercentString(
                                                                            baccaratTigerPayoutTigerTie /
                                                                                baccaratTigerWagerTigerTie
                                                                        )}
                                                                        tigerPair={formatPresicePercentString(
                                                                            baccaratTigerPayoutTigerPair /
                                                                                baccaratTigerWagerTigerPair
                                                                        )}
                                                                        tigerBig={formatPresicePercentString(
                                                                            baccaratTigerPayoutTigerBig /
                                                                                baccaratTigerWagerTigerBig
                                                                        )}
                                                                        tigerSmall={formatPresicePercentString(
                                                                            baccaratTigerPayoutTigerSmall /
                                                                                baccaratTigerWagerTigerSmall
                                                                        )}
                                                                        tiger={formatPresicePercentString(
                                                                            baccaratTigerPayoutTiger /
                                                                                baccaratTigerWagerTiger
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            baccaratTigerPayout / baccaratTigerWager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win/Loss (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerProfitBanker /
                                                                                                baccaratTigerWagerBanker
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerProfitPlayer /
                                                                                                baccaratTigerWagerPlayer
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerProfitTie /
                                                                                                baccaratTigerWagerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerProfitTigerTie /
                                                                                                baccaratTigerWagerTigerTie
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerProfitTigerPair /
                                                                                                baccaratTigerWagerTigerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerProfitTigerBig /
                                                                                                baccaratTigerWagerTigerBig
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerProfitTigerSmall /
                                                                                                baccaratTigerWagerTigerSmall
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerProfitTiger /
                                                                                                baccaratTigerWagerTiger
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBaccaratTigerBetTypeBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratTigerProfitBanker /
                                                                                baccaratTigerWagerBanker
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratTigerProfitPlayer /
                                                                                baccaratTigerWagerPlayer
                                                                        )}
                                                                        tie={formatPresicePercentString(
                                                                            baccaratTigerProfitTie /
                                                                                baccaratTigerWagerTie
                                                                        )}
                                                                        tigerTie={formatPresicePercentString(
                                                                            baccaratTigerProfitTigerTie /
                                                                                baccaratTigerWagerTigerTie
                                                                        )}
                                                                        tigerPair={formatPresicePercentString(
                                                                            baccaratTigerProfitTigerPair /
                                                                                baccaratTigerWagerTigerPair
                                                                        )}
                                                                        tigerBig={formatPresicePercentString(
                                                                            baccaratTigerProfitTigerBig /
                                                                                baccaratTigerWagerTigerBig
                                                                        )}
                                                                        tigerSmall={formatPresicePercentString(
                                                                            baccaratTigerProfitTigerSmall /
                                                                                baccaratTigerWagerTigerSmall
                                                                        )}
                                                                        tiger={formatPresicePercentString(
                                                                            baccaratTigerProfitTiger /
                                                                                baccaratTigerWagerTiger
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            baccaratTigerProfit / baccaratTigerWager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>Standoff (Tie)</span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Standoff',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            baccaratTigerStandoffBanker
                                                                                        ),
                                                                                        formatNumber(
                                                                                            baccaratTigerStandoffPlayer
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={baccaratTigerStandoffBanker}
                                                                        player={baccaratTigerStandoffPlayer}
                                                                        total={baccaratTigerStandoff}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Banker vs. Player Split Wager
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Banker vs. Player split wgr (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerWagerBanker /
                                                                                                baccaratTigerMainWager
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerWagerPlayer /
                                                                                                baccaratTigerMainWager
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratTigerWagerBanker /
                                                                                baccaratTigerMainWager
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratTigerWagerPlayer /
                                                                                baccaratTigerMainWager
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Banker vs. Player Split Count
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div
                                                                    style={width < 1600 ? {} : styles.smallPieContainer}
                                                                >
                                                                    <Pie
                                                                        data={{
                                                                            labels: baccaratTigerCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Banker vs. Player split count (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerBetCountBanker /
                                                                                                baccaratTigerMainBetCounts
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            baccaratTigerBetCountPlayer /
                                                                                                baccaratTigerMainBetCounts
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBetTypeBPBreakdown
                                                                        banker={formatPresicePercentString(
                                                                            baccaratTigerBetCountBanker /
                                                                                baccaratTigerMainBetCounts
                                                                        )}
                                                                        player={formatPresicePercentString(
                                                                            baccaratTigerBetCountPlayer /
                                                                                baccaratTigerMainBetCounts
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {gameType === 'blackjack' && gameVariant === 'standard' && (
                                            <>
                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: blackjackStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Wagers',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            blackjackStandardWagerBase
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardWagerDoubleDown
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardWagerInsurance
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardWagerPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardWagerSplit
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBlackjackStandardBetTypeSimpleCashBreakdown
                                                                        base={blackjackStandardWagerBase}
                                                                        doubleDown={blackjackStandardWagerDoubleDown}
                                                                        insurance={blackjackStandardWagerInsurance}
                                                                        pair={blackjackStandardWagerPair}
                                                                        split={blackjackStandardWagerSplit}
                                                                        total={blackjackStandardWager}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: blackjackStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitBase <
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitBase
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitDoubleDown <
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitDoubleDown
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitInsurance <
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitInsurance
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitPair <
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitSplit <
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitSplit
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBlackjackStandardBetTypeCashBreakdown
                                                                        base={
                                                                            blackjackStandardProfitBase < 0
                                                                                ? 0
                                                                                : blackjackStandardProfitBase
                                                                        }
                                                                        doubleDown={
                                                                            blackjackStandardProfitDoubleDown < 0
                                                                                ? 0
                                                                                : blackjackStandardProfitDoubleDown
                                                                        }
                                                                        insurance={
                                                                            blackjackStandardProfitInsurance < 0
                                                                                ? 0
                                                                                : blackjackStandardProfitInsurance
                                                                        }
                                                                        pair={
                                                                            blackjackStandardProfitPair < 0
                                                                                ? 0
                                                                                : blackjackStandardProfitPair
                                                                        }
                                                                        split={
                                                                            blackjackStandardProfitSplit < 0
                                                                                ? 0
                                                                                : blackjackStandardProfitSplit
                                                                        }
                                                                        total={getBlackjackStandardHouseWin()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Loss Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: blackjackStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Loss',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitBase >
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitBase
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitDoubleDown >
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitDoubleDown
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitInsurance >
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitInsurance
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitPair >
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardProfitSplit >
                                                                                                0
                                                                                                ? 0
                                                                                                : blackjackStandardProfitSplit
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBlackjackStandardBetTypeCashBreakdown
                                                                        base={
                                                                            blackjackStandardProfitBase > 0
                                                                                ? 0
                                                                                : blackjackStandardProfitBase
                                                                        }
                                                                        doubleDown={
                                                                            blackjackStandardProfitDoubleDown > 0
                                                                                ? 0
                                                                                : blackjackStandardProfitDoubleDown
                                                                        }
                                                                        insurance={
                                                                            blackjackStandardProfitInsurance > 0
                                                                                ? 0
                                                                                : blackjackStandardProfitInsurance
                                                                        }
                                                                        pair={
                                                                            blackjackStandardProfitPair > 0
                                                                                ? 0
                                                                                : blackjackStandardProfitPair
                                                                        }
                                                                        split={
                                                                            blackjackStandardProfitSplit > 0
                                                                                ? 0
                                                                                : blackjackStandardProfitSplit
                                                                        }
                                                                        total={getBlackjackStandardHouseLoss()}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: blackjackStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout per bet type',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            blackjackStandardPayoutBase
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardPayoutDoubleDown
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardPayoutInsurance
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardPayoutPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardPayoutSplit
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBlackjackStandardBetTypeSimpleCashBreakdown
                                                                        base={blackjackStandardPayoutBase}
                                                                        doubleDown={blackjackStandardPayoutDoubleDown}
                                                                        insurance={blackjackStandardPayoutInsurance}
                                                                        pair={blackjackStandardPayoutPair}
                                                                        split={blackjackStandardPayoutSplit}
                                                                        total={blackjackStandardPayout}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Bet Count Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: blackjackStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Bets Counts',
                                                                                    data: [
                                                                                        formatNumber(
                                                                                            blackjackStandardBetCountBase
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardBetCountDoubleDown
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardBetCountInsurance
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardBetCountPair
                                                                                        ),
                                                                                        formatNumber(
                                                                                            blackjackStandardBetCountSplit
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBlackjackStandardBetTypeBreakdown
                                                                        base={formatCountString(
                                                                            blackjackStandardBetCountBase
                                                                        )}
                                                                        doubleDown={formatCountString(
                                                                            blackjackStandardBetCountDoubleDown
                                                                        )}
                                                                        insurance={formatCountString(
                                                                            blackjackStandardBetCountInsurance
                                                                        )}
                                                                        pair={formatCountString(
                                                                            blackjackStandardBetCountPair
                                                                        )}
                                                                        split={formatCountString(
                                                                            blackjackStandardBetCountSplit
                                                                        )}
                                                                        total={formatCountString(
                                                                            blackjackStandardBetCount
                                                                        )}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    Payout Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: blackjackStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'Payout/Bet',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardPayoutBase /
                                                                                                blackjackStandardWagerBase
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardPayoutDoubleDown /
                                                                                                blackjackStandardWagerDoubleDown
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardPayoutInsurance /
                                                                                                blackjackStandardWagerInsurance
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardPayoutPair /
                                                                                                blackjackStandardWagerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardPayoutSplit /
                                                                                                blackjackStandardWagerSplit
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBlackjackStandardBetTypeBreakdown
                                                                        base={formatPresicePercentString(
                                                                            blackjackStandardPayoutBase /
                                                                                blackjackStandardWagerBase
                                                                        )}
                                                                        doubleDown={formatPresicePercentString(
                                                                            blackjackStandardPayoutDoubleDown /
                                                                                blackjackStandardWagerDoubleDown
                                                                        )}
                                                                        insurance={formatPresicePercentString(
                                                                            blackjackStandardPayoutInsurance /
                                                                                blackjackStandardWagerInsurance
                                                                        )}
                                                                        pair={formatPresicePercentString(
                                                                            blackjackStandardPayoutPair /
                                                                                blackjackStandardWagerPair
                                                                        )}
                                                                        split={formatPresicePercentString(
                                                                            blackjackStandardPayoutSplit /
                                                                                blackjackStandardWagerSplit
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            blackjackStandardPayout /
                                                                                blackjackStandardWager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row lg='3'>
                                                    <Col>
                                                        <Card>
                                                            <CardHeader style={styles.cardHeader}>
                                                                <span style={styles.cardTitleText}>
                                                                    House Win/Loss Per Wager Breakdown
                                                                </span>
                                                            </CardHeader>
                                                            <CardBody style={styles.cardBody}>
                                                                <div style={width < 1600 ? {} : styles.pieContainer}>
                                                                    <Pie
                                                                        data={{
                                                                            labels: blackjackStandardCharPerTypeLabels,
                                                                            datasets: [
                                                                                {
                                                                                    label: 'House Win/Loss (%)',
                                                                                    data: [
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardProfitBase /
                                                                                                blackjackStandardWagerBase
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardProfitDoubleDown /
                                                                                                blackjackStandardWagerDoubleDown
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardProfitInsurance /
                                                                                                blackjackStandardWagerInsurance
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardProfitPair /
                                                                                                blackjackStandardWagerPair
                                                                                        ),
                                                                                        formatPresicePercentNumber(
                                                                                            blackjackStandardProfitSplit /
                                                                                                blackjackStandardWagerSplit
                                                                                        ),
                                                                                    ],
                                                                                    backgroundColor:
                                                                                        chartBackgroundColors,
                                                                                    borderColor: chartBackgroundColors,
                                                                                    borderWidth: 1,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: false,
                                                                            },
                                                                            responsive: true,
                                                                            maintainAspectRatio: true,
                                                                        }}
                                                                    />
                                                                    <DisplayBlackjackStandardBetTypeBreakdown
                                                                        base={formatPresicePercentString(
                                                                            blackjackStandardProfitBase /
                                                                                blackjackStandardWagerBase
                                                                        )}
                                                                        doubleDown={formatPresicePercentString(
                                                                            blackjackStandardProfitDoubleDown /
                                                                                blackjackStandardWagerDoubleDown
                                                                        )}
                                                                        insurance={formatPresicePercentString(
                                                                            blackjackStandardProfitInsurance /
                                                                                blackjackStandardWagerInsurance
                                                                        )}
                                                                        pair={formatPresicePercentString(
                                                                            blackjackStandardProfitPair /
                                                                                blackjackStandardWagerPair
                                                                        )}
                                                                        split={formatPresicePercentString(
                                                                            blackjackStandardProfitSplit /
                                                                                blackjackStandardWagerSplit
                                                                        )}
                                                                        total={formatPresicePercentString(
                                                                            blackjackStandardProfit /
                                                                                blackjackStandardWager
                                                                        )}
                                                                        perWager
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </>
                                )}
                            </Container>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default Statistics
