import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { formatCountString } from '../../xtable/Formats'

const getLabelTranslation = (value) => {
    switch (value) {
        case 'tieMeterMax':
            return 'Tie Meter'
        case 'bankerPairMeterMax':
            return 'Banker Pair Meter'
        case 'playerPairMeterMax':
            return 'Player Pair Meter'
        case 'lucky6MeterMax':
            return 'Lucky6 Meter'
        case 'mega6MeterMax':
            return 'Mega6 Meter'
        case 'tigerMeterMax':
            return 'Tiger Meter'
        case 'tigerTieMeterMax':
            return 'Tiger Tie Meter'
        case 'tigerPairMeterMax':
            return 'Tiger Pair Meter'
        case 'tigerBigMeterMax':
            return 'Tiger Big Meter'
        case 'tigerSmallMeterMax':
            return 'Tiger Small Meter'
        default:
    }
}

const FieldRow = ({ label, name, setState, value, variant, submitted }) => {
    return (
        <Box>
            <Typography>{getLabelTranslation(label)}</Typography>
            <TextField
                id={name}
                name={name}
                type='text'
                variant='outlined'
                size='small'
                value={formatCountString(value)}
                onChange={(e) => {
                    setState((prevState) => {
                        prevState[variant].meters[label] = Number(e.target.value.replace(/\D/g, ''))
                        return { ...prevState }
                    })
                }}
                error={submitted && Number(value) < 1}
                helperText={submitted && Number(value) < 1 && 'Value cannot be zero'}
            />
        </Box>
    )
}

const SideBetMeter = ({ variant, patterns, setPatterns, submitted }) => {
    return (
        <Box display='flex' flexDirection='column' gap='1rem' padding='1rem'>
            {patterns.meters &&
                Object.keys(patterns.meters).map((key) => {
                    return (
                        key.includes('MeterMax') && (
                            <FieldRow
                                label={key}
                                value={patterns.meters[key]}
                                setState={setPatterns}
                                variant={variant}
                                key={key}
                                submitted={submitted}
                            />
                        )
                    )
                })}
        </Box>
    )
}

export default SideBetMeter
