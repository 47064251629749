import React, { useContext, useState, useEffect } from "react";
import { isNil } from "lodash";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ClearIcon from "@mui/icons-material/Clear";
import { DUIContext } from "./Dealer";
import useBaccarat from "../rules/baccarat";
import Card from "./Card";

const DealerCards = () => {
  const {
    apiRevealPerCard,
    bankerPoints,
    cardPress,
    cardState,
    deviceData,
    editState,
    game,
    getClassName,
    hasGameState,
    isCardSelected,
    isMiniCardVisible,
    playerPoints,
    revealStatus,
    s,
    t,
    device,
    isGameVoided,
    setIsGameVoided,
  } = useContext(DUIContext);
  
  const {
    getNumberOfCards,
    getCardValue,
    isAnyCardRevealed,
    showUpdatedPlayerPoint,
    showUpdatedBankerPoint,
    showPlayerDrawIndicator,
    showBankerDrawIndicator,
    isPlayerThirdCardNeeded,
    isBankerThirdCardNeeded,
  } = useBaccarat();
  
  const [valuePlayersHand, setValuePlayersHand] = useState(null);
  const [valueBankersHand, setValueBankersHand] = useState(null);
  
  const [playerThirdCardNeeded, setPlayerThirdCardNeeded] = useState(null);
  const [bankerThirdCardNeeded, setBankerThirdCardNeeded] = useState(null);
  
  const cardReveal = device.data.cardReveal;
  const numberOfCards = getNumberOfCards(cardState);
  const playerFirstCardDrawn =
    numberOfCards === 1 && !isNil(getCardValue(cardState, "p1"));
  const playerGotTwoCards =
    !isNil(getCardValue(cardState, "p1")) &&
    !isNil(getCardValue(cardState, "p2")) &&
    isNil(getCardValue(cardState, "p3"));
  const bankerGotTwoCards =
    !isNil(getCardValue(cardState, "b1")) &&
    !isNil(getCardValue(cardState, "b2")) &&
    isNil(getCardValue(cardState, "b3"));
  
  // Save point if each side got two cards
  useEffect(() => {
    if (playerFirstCardDrawn) {
      setIsGameVoided(false);
      setValuePlayersHand(null);
      setValueBankersHand(null);
    }
  
    if (playerGotTwoCards) {
      setValuePlayersHand(playerPoints);
    }
  
    if (bankerGotTwoCards) {
      setValueBankersHand(bankerPoints);
    }
  }, [
    playerFirstCardDrawn,
    playerGotTwoCards,
    bankerGotTwoCards,
    setIsGameVoided,
    playerPoints,
    bankerPoints,
  ]);
  
  // Check if player or banker need to draw 3rd card
  useEffect(() => {
    if (playerFirstCardDrawn) {
      setPlayerThirdCardNeeded(null);
      setBankerThirdCardNeeded(null);
    }
  
    if (isPlayerThirdCardNeeded(cardState, cardReveal) === true) {
      setPlayerThirdCardNeeded(true);
    } else if (isPlayerThirdCardNeeded(cardState, cardReveal) === false) {
      setPlayerThirdCardNeeded(false);
    } else if (isNil(isBankerThirdCardNeeded(cardState, cardReveal))) {
      setPlayerThirdCardNeeded(null);
    }
  
    if (isBankerThirdCardNeeded(cardState, cardReveal) === true) {
      setBankerThirdCardNeeded(true);
    } else if (isBankerThirdCardNeeded(cardState, cardReveal) === false) {
      setBankerThirdCardNeeded(false);
    } else if (isNil(isBankerThirdCardNeeded(cardState, cardReveal))) {
      setBankerThirdCardNeeded(null);
    }
  }, [
    cardState,
    cardReveal,
    playerFirstCardDrawn,
    isPlayerThirdCardNeeded,
    isBankerThirdCardNeeded,
  ]);
  
  // console.log("player", {
  //   showUpdatedPlayerPoint: showUpdatedPlayerPoint(cardState, cardReveal),
  //   playerPoint: playerPoints,
  //   valuePlayersHand: valuePlayersHand,
  //   showPlayerDrawIndicator: showPlayerDrawIndicator(cardState, cardReveal),
  //   isPlayerThirdCardNeeded: isPlayerThirdCardNeeded(cardState, cardReveal),
  //   playerThirdCardNeeded: playerThirdCardNeeded
  // });
  
  // console.log("banker", {
  //   showUpdatedBankerPoint: showUpdatedBankerPoint(cardState, cardReveal),
  //   bankerPoint: bankerPoints,
  //   valueBankersHand: valueBankersHand,
  //   showBankerDrawIndicator: showBankerDrawIndicator(cardState, cardReveal),
  //   isBankerThirdCardNeeded: isBankerThirdCardNeeded(cardState, cardReveal),
  //   bankerThirdCardNeeded: bankerThirdCardNeeded
  // });  

  return <>

    {/* Red / Blue background gradients */}

    {/* BANKER SIDE */}
    <div className={getClassName(s.border)} style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', color: '#cc3c3c', overflow: 'hidden', border: '3px solid #FF4F4F', borderBottom: '0px', background: 'transparent linear-gradient(180deg, #340404 0%, #FF4F4F 100%)' }}>
      <div className={getClassName(s.border)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10%', fontSize: '2.5vh', textTransform: 'uppercase', color: '#FF4F4F', fontWeight: '600', letterSpacing: 4 }}>
        {t('dealer.banker')}
      </div>

      <div className="TopCardsArea" style={{ display: 'flex', height: '30%', gap: '5%' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '1vh' }}>
          <div className={getClassName(isCardSelected('b1'), isMiniCardVisible('b1'))} style={{ width: '6vh', height: '9vh', backgroundColor: '#fff', borderRadius: '1vh', padding: '0.6vh', pointerEvents: 'none', boxSizing: 'border-box' }}>
            <Card id={'b1_mini'} suit={editState.b1.suit} value={editState.b1.value} reveal={revealStatus.b1} revealTimer={deviceData.revealTimer} />
          </div>
          <div onClick={(e) => { cardPress('b1') }} className={isCardSelected('b1')} style={{ width: '18vh', height: '100%', backgroundColor: '#fff', borderRadius: '1vh', padding: 8, boxSizing: 'border-box' }}>
            <Card id={'b1'} suit={cardState.b1.suit} value={cardState.b1.value} reveal={revealStatus.b1} revealTimer={deviceData.revealTimer} apiRevealPerCard={apiRevealPerCard} />
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', gap: '1vh' }}>
          <div onClick={(e) => { cardPress('b2') }} className={isCardSelected('b2')} style={{ width: '18vh', height: '100%', backgroundColor: '#fff', borderRadius: '1vh', padding: 8, boxSizing: 'border-box' }}>
            <Card id={'b2'} suit={cardState.b2.suit} value={cardState.b2.value} reveal={revealStatus.b2} revealTimer={deviceData.revealTimer} apiRevealPerCard={apiRevealPerCard} />
          </div>
          <div className={getClassName(isCardSelected('b2'), isMiniCardVisible('b2'))} style={{ width: '6vh', height: '9vh', backgroundColor: '#fff', borderRadius: '1vh', padding: '0.6vh', pointerEvents: 'none', boxSizing: 'border-box' }}>
            <Card id={'b2_mini'} suit={editState.b2.suit} value={editState.b2.value} reveal={revealStatus.b2} revealTimer={deviceData.revealTimer} />
          </div>
        </div>
      </div>

      <div className="BottomCardArea" style={{ display: 'flex', height: '30%', justifyContent: 'center', alignItems: 'flex-start' }}>
        <div style={{ width: '100%', height: '100%' }}></div>
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
          <div className={isCardSelected('b3')} onClick={(e) => { cardPress('b3') }} style={{ position: 'relative', width: '18vh', height: '100%', backgroundColor: '#fff', borderRadius: '1vh', padding: 8, transform: 'rotate(90deg) translateX(-2vh)', boxSizing: 'border-box' }}>
            <div className={getClassName(isCardSelected('b3'), isMiniCardVisible('b3'))} style={{ position: 'absolute', top: '-10vh', left: '2vh', transform: 'rotate(-90deg)', width: '6vh', height: '9vh', backgroundColor: 'white', borderRadius: '1vh', padding: '0.6vh', boxSizing: 'border-box' }}>
              <Card id={'b3_mini'} suit={editState.b3.suit} value={editState.b3.value} reveal={revealStatus.b3} revealTimer={deviceData.revealTimer} />
            </div>
            <Card id={'b3'} suit={cardState.b3.suit} value={cardState.b3.value} reveal={revealStatus.b3} revealTimer={deviceData.revealTimer} apiRevealPerCard={apiRevealPerCard} />
            {showBankerDrawIndicator(cardState, cardReveal) && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {bankerThirdCardNeeded === true && (
                  <RadioButtonUncheckedIcon
                    style={{
                      fontSize: "10vh",
                      color: "green",
                    }}
                  />
                )}
                {bankerThirdCardNeeded === false && (
                  <ClearIcon
                    style={{
                      fontSize: "10vh",
                      color: "red",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div style={{ width: '100%', height: '100%' }}></div>
      </div>

      <div className="PointsZone" style={{ height: '10%', fontSize: '5vh', fontWeight: 'bold', letterSpacing: 'normal', color: 'white', textTransform: 'uppercase', whiteSpace: 'nowrap', textAlign: 'center' }}>
        {isAnyCardRevealed(cardState, cardReveal) && (
          <>
            {showUpdatedBankerPoint(cardState, cardReveal) && (
              <>{bankerPoints} {t("dealer.points")}</>
            )}
            {!showUpdatedBankerPoint(cardState, cardReveal) && (
              <>{valueBankersHand} {t("dealer.points")}</>
            )}
          </>
        )}
      </div>
    </div>


    {/* Display Results */}
    <div style={{ width: 0 }}>
      <div style={{ height: '10%' }}></div>
      <div style={{ height: '30%' }}></div>
      <div style={{ height: '30%' }}>
        {isGameVoided && (
          <div
            className={getClassName(s.border)}
            style={{
              width: "26vh",
              fontSize: "5vh",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
            }}
          >
            <div
              className={getClassName(s.border)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2vh",
                textTransform: "uppercase",
                letterSpacing: "1px",
                background: "#505050",
                border: "1px solid #fff",
                color: "#fff",
                fontWeight: "700",
              }}
            >
              VOIDED
            </div>
          </div>
        )}
      </div>
      <div style={{ height: '10%' }}>
        {hasGameState && game.data.status === 'done' && <div className={getClassName(s.border)} style={{ width: '30vh', fontSize: '3.5vh', transform: 'translateX(-50%)', whiteSpace: 'nowrap' }}>
          {hasGameState && game.data.result.status === "P" &&
            <div className={getClassName(s.border)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2vh', borderRadius: '1vh', textTransform: 'uppercase', letterSpacing: '1px', background: '#005da5', border: '2px solid #fff', color: '#fff' }}>
              {t('dealer.playerWin')}
            </div>
          }
          {hasGameState && game.data.result.status === "B" &&
            <div className={getClassName(s.border)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2vh', borderRadius: '1vh', textTransform: 'uppercase', letterSpacing: '1px', background: '#a82f2e', border: '2px solid #fff', color: '#fff' }}>
              {t('dealer.bankerWin')}
            </div>
          }
          {hasGameState && game.data.result.status === "T" &&
            <div className={getClassName(s.border)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2vh', borderRadius: '1vh', textTransform: 'uppercase', letterSpacing: '1px', background: '#505050', border: '2px solid #fff', color: '#fff' }}>
              {t('dealer.tie')}
            </div>
          }
        </div>}
      </div>
    </div>


    {/* PLAYER SIDE */}
    <div className={getClassName(s.border)} style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', color: '#00589a', overflow: 'hidden', border: '3px solid #008FFA', borderBottom: '0px', background: 'transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)' }}>
      <div className={getClassName(s.border)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10%', fontSize: '2.5vh', textTransform: 'uppercase', color: '#008FFA', fontWeight: '600', letterSpacing: 4 }}>
        {t('dealer.player')}
      </div>
      <div className="TopCardsArea" style={{ display: 'flex', height: '30%', gap: '5%' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '1vh' }}>
          <div className={getClassName(isCardSelected('p1'), isMiniCardVisible('p1'))} style={{ width: '6vh', height: '9vh', backgroundColor: '#fff', borderRadius: '1vh', padding: '0.6vh', pointerEvents: 'none', boxSizing: 'border-box' }}>
            <Card id={'p1_mini'} suit={editState.p1.suit} value={editState.p1.value} reveal={revealStatus.p1} revealTimer={deviceData.revealTimer} />
          </div>
          <div onClick={(e) => { cardPress('p1') }} className={isCardSelected('p1')} style={{ width: '18vh', height: '100%', backgroundColor: '#fff', borderRadius: '1vh', padding: 8, boxSizing: 'border-box' }}>
            <Card id={'p1'} suit={cardState.p1.suit} value={cardState.p1.value} reveal={revealStatus.p1} revealTimer={deviceData.revealTimer} apiRevealPerCard={apiRevealPerCard} />
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', gap: '1vh' }}>
          <div onClick={(e) => { cardPress('p2') }} className={isCardSelected('p2')} style={{ width: '18vh', height: '100%', backgroundColor: '#fff', borderRadius: '1vh', padding: 8, boxSizing: 'border-box' }}>
            <Card id={'p2'} suit={cardState.p2.suit} value={cardState.p2.value} reveal={revealStatus.p2} revealTimer={deviceData.revealTimer} apiRevealPerCard={apiRevealPerCard} />
          </div>
          <div className={getClassName(isCardSelected('p2'), isMiniCardVisible('p2'))} style={{ width: '6vh', height: '9vh', backgroundColor: '#fff', borderRadius: '1vh', padding: '0.6vh', pointerEvents: 'none', boxSizing: 'border-box' }}>
            <Card id={'p2_mini'} suit={editState.p2.suit} value={editState.p2.value} reveal={revealStatus.p2} revealTimer={deviceData.revealTimer} />
          </div>
        </div>
      </div>
      <div className="BottomCardArea" style={{ display: 'flex', height: '30%', justifyContent: 'center', alignItems: 'flex-start' }}>
        <div style={{ width: '100%', height: '100%' }}></div>
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
          <div className={isCardSelected('p3')} onClick={(e) => { cardPress('p3') }} style={{ position: 'relative', width: '18vh', height: '100%', backgroundColor: '#fff', borderRadius: '1vh', padding: 8, transform: 'rotate(90deg) translateX(-2vh)', boxSizing: 'border-box' }}>
            <div className={getClassName(isCardSelected('p3'), isMiniCardVisible('p3'))} style={{ position: 'absolute', top: '-10vh', left: '2vh', transform: 'rotate(-90deg)', width: '6vh', height: '9vh', backgroundColor: 'white', borderRadius: '1vh', padding: '0.6vh', boxSizing: 'border-box' }}>
              <Card id={'p3_mini'} suit={editState.p3.suit} value={editState.p3.value} reveal={revealStatus.p3} revealTimer={deviceData.revealTimer} />
            </div>
            <Card id={'p3'} suit={cardState.p3.suit} value={cardState.p3.value} reveal={revealStatus.p3} revealTimer={deviceData.revealTimer} apiRevealPerCard={apiRevealPerCard} />
            {showPlayerDrawIndicator(cardState, cardReveal) && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {playerThirdCardNeeded === true && (
                  <RadioButtonUncheckedIcon
                    style={{
                      fontSize: "10vh",
                      color: "green",
                    }}
                  />
                )}
                {playerThirdCardNeeded === false && (
                  <ClearIcon
                    style={{
                      fontSize: "10vh",
                      color: "red",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div style={{ width: '100%', height: '100%' }}></div>
      </div>
      <div className="PointsZone" style={{ height: '10%', fontSize: '5vh', fontWeight: 'bold', letterSpacing: 'normal', color: 'white', textTransform: 'uppercase', whiteSpace: 'nowrap', textAlign: 'center' }}>
        {isAnyCardRevealed(cardState, cardReveal) && (
          <>
            {showUpdatedPlayerPoint(cardState, cardReveal) && (
              <>{playerPoints} {t("dealer.points")}</>
            )}
            {!showUpdatedPlayerPoint(cardState, cardReveal) && (
              <>{valuePlayersHand} {t("dealer.points")}</>
            )}
          </>
        )}
      </div>
    </div>
  </>
}

export default DealerCards;