import { useState } from 'react'
import { XCircle, Check } from 'react-feather'
import styled from 'styled-components'

import { pivotIP } from '../config'

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`
const Container = styled(FlexCenter)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`
const Inner = styled(FlexCenter)`
    position: relative;
    width: 100%;
    height: 100%;
`
const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.8;
`
const Content = styled(FlexCenter)`
    color: white;
    z-index: 11;
    flex-direction: column;
    background: #4a4a4a;
    padding: 30px;
    border-radius: 10px;
    gap: 40px;
    width: 400px;
    position: relative;
`
const Heading = styled(FlexCenter)`
    font-size: 25px;
    font-weight: bold;
`
const Button = styled(FlexCenter)`
    border-radius: 20px;
    padding: 10px 20px;
    color: white;
    background-color: ${(props) => props.color};
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    ${(props) =>
        !props.inProgress &&
        `
        &:hover {
        background:#333;
        }
    `}

    user-select:none;
`
const CloseButton = styled(XCircle)`
    color: #ccc;
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    transform: translate(100%, -100%);
    cursor: pointer;
    &:hover {
        color: white;
    }
`
const SuccessIcon = styled(Check)`
    height: 30px;
    width: 30px;
    color: limegreen;
`

const Generate = ({ setShowPanel }) => {
    const [inProgress, setInProgress] = useState(false)
    const [success, setSuccess] = useState(false)

    function saveOrOpenBlob(url, blobName) {
        var blob
        var xmlHTTP = new XMLHttpRequest()
        xmlHTTP.open('GET', url, true)
        xmlHTTP.responseType = 'arraybuffer'
        xmlHTTP.onload = function (e) {
            blob = new Blob([this.response])
        }
        xmlHTTP.onprogress = function (pr) {
            //pr.loaded - current state
            //pr.total  - max
        }
        xmlHTTP.onloadend = function (e) {
            var fileName = blobName
            var tempEl = document.createElement('a')
            document.body.appendChild(tempEl)
            tempEl.style = 'display: none'
            url = window.URL.createObjectURL(blob)
            tempEl.href = url
            tempEl.download = fileName
            tempEl.click()
            window.URL.revokeObjectURL(url)
            setInProgress(false)
            setSuccess(true)
            setTimeout(() => setShowPanel(false), 2000)
        }
        xmlHTTP.send()
    }

    const downloadLicense = () => {
        const url = 'http://' + pivotIP + '/wibu/fetch'
        setInProgress(true)
        saveOrOpenBlob(url, 'renewal-request.zip')
    }
    return (
        <Container>
            <Inner>
                <Content>
                    <CloseButton onClick={() => setShowPanel(null)} />
                    <Heading>Generate License File</Heading>
                    <Button
                        inProgress={inProgress}
                        color={inProgress ? '#F28C28' : '#666'}
                        onClick={() => (inProgress || success ? {} : downloadLicense())}
                    >
                        {success && <SuccessIcon />}
                        {inProgress && 'In Progress'}
                        {success && 'Success'}
                        {!success && !inProgress && 'Download'}
                    </Button>
                </Content>
                <Backdrop onClick={() => setShowPanel(null)} />
            </Inner>
        </Container>
    )
}

export default Generate
