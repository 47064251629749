import React, { createContext, useState, useEffect } from 'react'
import { get, isEmpty } from 'lodash'
import { Navigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { LinearProgress } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'

import { useSubscribe, usePublish } from '../../api'
import { pivotIP } from '../../config'
import { useMenuContext } from '../contexts/MenuContext'
import DealerUI from '../dealer/Dealer'
import ManualGame from '../dealer/ManualGame'
import GameHistory from '../gameHistory/GameHistory'
import Configurations from './Configurations'
import DeviceDetails from './DeviceDetails'
import PitOverview from './PitOverview'
import SideMenu from './SideMenu'

const rootStyles = makeStyles((theme) => ({
    noScrollBar: {
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '1px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent'
        }
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    modal: {
        position: 'relative',
        backgroundColor: '#1B1B1F',
        color: '#fff',
        textAlign: 'center',
        borderRadius: 25,
        padding: 50,
        outline: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 50,
    },
    modalHeader: {
        backgroundColor: '#111314',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        borderRadius: '25px 25px 0 0'
    },
    modalPrompt: {
        marginTop: '3em',
        paddingTop: '1em',
        fontSize: 13
    },
    modalButtonRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '5%',
        '& button': {
            //width: '33%',
            height: '4.2rem',
            //backgroundColor: '#303238',
            //color: '#fff',
            boxShadow: '0px 1px 1px #161D251A',
            border: '1px solid #5F606A',
            fontSize: '19px',

        }
    },
    modalInput: {
        width: '90%',
        height: '4.2rem',
        backgroundColor: '#303238',
        boxShadow: '0px 1px 2px #00000014',
        border: '1px solid #5F606A',
        borderRadius: 4,
        fontSize: '2rem',
        '& .MuiOutlinedInput-root': { height: '4.2rem' }

    },
    wrapper: {

    },
    container: {
    },
    tablelink: {
        height: '35vh', margin: '1vh', textDecoration: 'none', width: '35vh', display: 'inline-flex', backgroundColor: '#1F1B1B', padding: '2vh', textTransform: 'none'
    },
    table: {
        width: '100%', height: '100%', color: '#ffffff', fontWeight: '100', textAlign: 'left',
    },
    icon: {
    },
    title: {
        color: '#5F606A', fontSize: '1rem', marginRight: '5%',

    },
    offline: {
        color: '#ff1111',

    },
    online: {
        color: '#00ff00',

    },
    switch: {
        '& .MuiSwitch-root': {
            transform: 'scale(1.5)',
        },
        '& .MuiFormControlLabel-label': {
            fontSize: 20,
        }
    },
    tableDetailsForm: {
        backgroundColor: '',
        fontSize: 20,
        '& label': {
            color: '#777',
            marginLeft: 3
        },
        '& input': {
            border: '1px solid #ccc',
            backgroundColor: '#333',
            fontSize: 20,
            color: '#ccc',
            padding: 5,
            marginTop: 5,
            borderRadius: 5,
            width: '90%'
        },
        '& .disabled': {
            opacity: 0.5
        }
    },
    onoff: {
        color: '#fff'
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81'
    },
    modalScroll: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        maxHeight: '100%',
        '&::-webkit-scrollbar': {
            width: 3,
            backgroundColor: '#333',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#888'
        },

        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: 10,
            backgroundColor: '#333',
        }
    },
}))

export const SideMenuContext = createContext()
export const PitOverviewContext = createContext()


const Manager = () => {
    const { setSelectedPit } = useMenuContext()
    const pitIndex = window.localStorage.getItem('manager-initialisation')
    const [zones, zonesSocket] = useSubscribe(pivotIP + '/zones/*')
    const [pits, pitsSocket] = useSubscribe(pivotIP + '/pits/*')
    const [tables, tablesSocket] = useSubscribe(pivotIP + '/tables/*')
    const [devices, devicesSocket] = useSubscribe(pivotIP + '/devices/*')
    const [status, statusSocket] = useSubscribe(pivotIP + '/status/*')
    const [settings, settingsSocket] = useSubscribe('settings')

    const active = pitsSocket && pitsSocket.readyState === WebSocket.OPEN &&
        tablesSocket && tablesSocket.readyState === WebSocket.OPEN &&
        devicesSocket && devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket && statusSocket.readyState === WebSocket.OPEN &&
        zonesSocket && zonesSocket.readyState === WebSocket.OPEN &&
        settingsSocket && settingsSocket.readyState === WebSocket.OPEN

    if (!pits || !zones || !tables || !devices || !status || !settings || !active) {
        return <LinearProgress />
    }

    const managerPitExists = pits.map(v => v.index === pitIndex).includes(true)
    //if (!pitIndex || !pit.created) {
    if (!pitIndex || !managerPitExists) {
        if (!managerPitExists) { // the pit that was in the local settings was deleted
            console.log('// the pit that was in the local settings was deleted')
            console.log('pitIndex', pitIndex)
            console.log('pits', pits)
            window.localStorage.removeItem('manager-initialisation')
        }
        return <Navigate to="/posui/dashboard/initialise" />
    }

    // Redirect to initialisation if return with unselected table
    const deviceIndex = window.localStorage.getItem('manager-index')

    if (!deviceIndex) {
        setSelectedPit("")
        window.localStorage.removeItem('manager-pit')
        return <Navigate to="/posui/dashboard/initialise" />
    }

    const selectedPit = pits.filter(v => v.index === pitIndex)[0]

    console.log('pits', pits)
    console.log('status', status)
    console.log('devices', devices)
    console.log('selectedPit', selectedPit)

    const containerIndexesInPit = tables.reduce((c, v) => { if (v.data.containerIndex === selectedPit.index) { c.push(v.index) } return c }, [selectedPit.index])
    const devicesInPit = devices.filter(v => containerIndexesInPit.includes(v.data.containerIndex))
    const deviceIndexesInPit = devicesInPit.map(v => v.index)
    const deviceStatusesInPit = status.filter(d => deviceIndexesInPit.includes(d.index)).sort((a, b) => (a.data.name > b.data.name) ? 1 : -1)

    console.log('devicesInPit', devicesInPit)
    if (devicesInPit.length === 0 ) {
        return <Navigate to="/posui/dashboard/initialise" />
    }
    console.log('deviceStatusesInPit', deviceStatusesInPit)
    const data = { devices: devicesInPit, status: deviceStatusesInPit, pit: selectedPit }

    return <LayoutAndMenu data={data} devices={devices} pits={pits} zones={zones} tables={tables} settings={settings} />
}


const LayoutAndMenu = ({ data, devices, pits, zones, tables, settings }) => {
    const s = rootStyles()
    const { pageNav, setPageNav } = useMenuContext()

    //const pitbossHomePage = JSON.parse(window.localStorage.getItem('pitbossHomePage'))
    // const deviceInHomePageExists = pitbossHomePage && get(pitbossHomePage, 'deviceID', null) ? get(data.devices.filter(v => v.index === pitbossHomePage.deviceID), '0', null) : null

    // const [pageNav, setPageNav] = useReducer((state, obj) => {
    //     return { ...state, ...obj }
    // }, pitbossHomePage && deviceInHomePageExists ? pitbossHomePage : { page: 'overview', deviceID: null })

    const [selectedDevice, setSelectedDevice] = useState(null)
    const [showMenu, setShowMenu] = useState(false)
    const [expandedURL, setExpandedURL] = useState(null)
    const [editGameID, setEditGameID] = useState(null)
    const [fromPitbossUI, setfromPitbossUI] = useState(false)

    useEffect(() => {
        if (pageNav === "overview") {
            setfromPitbossUI(true);
            window.localStorage.setItem('pageNav', "overview");
        }
    }, [pageNav])

    useEffect(() => {
        setPageNav("dealerUI");

        // Direct to Pitboss UI on refreshing
        const path = window.localStorage.getItem('pageNav')
        if (path === "overview" || path === "configurations" || path === "gameHistory") {
            setPageNav("overview")
        }
    }, [setPageNav]);

    const device = selectedDevice ? get(data.devices.filter(v => v.index === selectedDevice.index), '0', null) : null
    const deviceStatus = selectedDevice ? get(data.status.filter(v => v.index === selectedDevice.index), '0', null) : null

    const address = (editGameID && device && device.data) ? device.data.ip + ':' + get(device, 'data.port', '3099') + '/baccarat/update/' + editGameID : null
    console.log('address', address)
    const publishResChange = usePublish(address ? address : '')
    const publishBacRes = async (...args) => {
        try {
            await publishResChange(...args);
            setTimeout(() => {
                setPageNav('gameHistory')
            }, 1500)
        } catch (e) {
            console.log('failed to update result: ', e)
            throw e
        }
    }



    console.log('data', data)
    const checkForStatusValue = (index, key) => {
        const isInStatus = data.status.filter(v => v.index === index)
        if (isInStatus.length > 0) {
            return get(isInStatus[0], key)
        } else {
            return false
        }
    }


    const selected = selectedDevice ? get(devices.filter(v => v.index === selectedDevice.index).map(v => ({ type: 'device', index: v.index })), '0', null) : null
    const deviceOn = device?.data?.open || null

    const deviceIndex = window.localStorage.getItem('manager-index')
    const deviceIp = window.localStorage.getItem('manager-ip')

    const defaultDeviceIndex = isEmpty(selected) ? deviceIndex : device.index
    const defaultDeviceIp = isEmpty(selected) ? deviceIp : isEmpty(device.data.port) ? device.data.ip + ':3099' : device.data.ip + ':' + device.data.port

    return <div style={{ height: 'calc(100%)', width: '100%', display: 'flex', backgroundColor: '#111', color: '#fff', position: 'relative' }}>
        <SideMenuContext.Provider value={{
            data,
            pageNav,
            s,
            setPageNav,
            setSelectedDevice,
            showMenu,
            setShowMenu,
            selectedDevice,
            deviceOn
        }}>
            <SideMenu />
        </SideMenuContext.Provider>



        <div style={{ width: '100%', marginLeft: '0px', height: '100%', }}>
            {pageNav === 'overview' && <PitOverviewContext.Provider value={{
                checkForStatusValue,
                devicesInPit: data.devices,
                devicesStatus: data.status,
                setPageNav,
                pit: data.pit,
                setSelectedDevice,
                selectedDevice,
                setShowMenu
            }}><PitOverview /></PitOverviewContext.Provider>}

            {pageNav === 'gameHistory' && <GameHistory {...{ canEditResult: true, setPageNav, selected, devices, tables, pits, zones, expandedURL, setExpandedURL, setEditGameID, device, settings }} />}
            {pageNav === 'configurations' && <Configurations device={device} />}
            {/* {pageNav === 'editResults' && <ManualGame publishBacRes={publishBacRes} showMenu={showMenu} />} */}
            {pageNav === 'device' && <DeviceDetails checkForStatusValue={checkForStatusValue} device={device} deviceStatus={deviceStatus} setPageNav={setPageNav} tables={tables} />}
            {pageNav === 'dealerUI' && <DealerUI isPitBoss={true} defaultDeviceIndex={defaultDeviceIndex} defaultDeviceIp={defaultDeviceIp} fromPitbossUI={fromPitbossUI} selectedDevice={selectedDevice} />}
        </div>



        <Modal
            className={s.modal}
            open={pageNav === 'editResults'}
            onClose={(e) => { setPageNav('gameHistory') }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                style: {
                    backgroundColor: '#000',
                    opacity: 0.8
                }
            }} >
            <Fade in={pageNav === 'editResults'} >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', outline: 0, position: 'relative', height: '70%', width: '75%', }} >
                    <CloseIcon style={{ position: 'absolute', top: '-60px', right: '-60px', cursor: 'pointer' }} onClick={(e) => { setPageNav('gameHistory') }} />
                    <ManualGame publishBacRes={publishBacRes} showMenu={showMenu} extraStyles={{ height: '100%', width: '100%', backgroundColor: '#000' }} device={device} />
                </div>
            </Fade>
        </Modal>
    </div>
}

export default Manager