import React, { useState } from 'react'
import { isEmpty, isNil } from 'lodash'
import { Link, Navigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import LoadingButton from '@mui/lab/LoadingButton'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import LinearProgress from '@mui/material/LinearProgress'
import SnackbarContent from '@mui/material/SnackbarContent'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { authApi as api } from '../api'

const Login = ({ status, authorize }) => {
	const roles = window.localStorage.getItem('roles')
	const role = window.localStorage.getItem('role')
	const token = window.localStorage.getItem('token')

	const [rootAvailable, setRootAvailable] = useState(null)
	const [account, setAccount] = useState('')
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [failType, setFailType] = useState('')

	const checkRoot = async () => {
		if (rootAvailable === null) {
			try {
				await api.get('available?account=root')
				setRootAvailable(true)
			} catch (e) {
				setRootAvailable(false)
			}
		}
	}

	checkRoot()

	const login = async () => {
		if (!isEmpty(account) && !isEmpty(password)) {
			setLoading(true)
			setError(false)
			setFailType('')

			try {
				const res = await api
					.post('authorize', {
						json: {
							account,
							password,
						},
					})
					.json()
				window.localStorage.setItem('token', res.token)
				window.localStorage.setItem('account', account)
				const isRoot = res.level === 'root'
				if (isRoot) {
					window.localStorage.setItem('role', 'root')
				}
				if (res.roles.length === 1) {
					window.localStorage.setItem('role', res.roles[0])
				}
				if (res.roles.length > 1) {
					window.localStorage.setItem('roles', res.roles)
				}
				window.localStorage.setItem('products', res.products)
				await authorize()
			} catch (e) {
				const statusCode = await e.response.status
				switch (statusCode) {
					case 400:
						setFailType('userNotFound')
						break
					case 403:
						setFailType('wrongPwd')
						break
					case 409:
						setFailType('sessionInUse')
						break
					case 423:
						setFailType('lockedAcc')
						break
					default:
						setFailType('')
				}
				console.warn('nope', e)
				setLoading(false)
				setError(true)
				setPassword('')
			}
		} else if (isEmpty(account)) {
			setError(true)
			setFailType('emptyAcc')
		} else if (isEmpty(password)) {
			setError(true)
			setFailType('emptyPwd')
		}
	}

	const getHelperText = (failType) => {
		let error
		switch (failType) {
			case 'emptyAcc':
				error = 'Please enter username.'
				break
			case 'emptyPwd':
				error = 'Please enter password.'
				break
			case 'userNotFound':
				error = 'User does not exist.'
				break
			case 'wrongPwd':
				error = 'Wrong password.'
				break
			case 'sessionInUse':
				error = 'Please logout first from your other active session.'
				break
			case 'lockedAcc':
				error = 'Your account is locked. Please contact support to unlock.'
				break
			default:
				error = ''
		}
		return error
	}

	if (status === 'unauthorized' && !isNil(token) && !isNil(roles)) {
		return <Navigate to='/selectrole' />
	}

	if (status === 'authorized') {
		if (role !== 'dealer' && role !== 'pitboss') {
			return <Navigate to='/dashboard' />
		}

		if (role === 'dealer' || role === 'pitboss') {
			return <Navigate to='/posui/dashboard' />
		}
	}

	if (rootAvailable === true) {
		return <Navigate to='/setup' />
	}

	if (rootAvailable === null) {
		return <LinearProgress />
	}

	return (
		<Box
			sx={{
				flex: 1,
				background: (theme) => theme.palette.card.background,
			}}
		>
			<Hidden xsDown>
				<Grid item sm={6}></Grid>
			</Hidden>
			<Card
				sx={{
					maxWidth: '400px',
					margin: '100px auto 0',
				}}
			>
				<AppBar position='static' color='default' elevation={2}>
					<Toolbar variant='dense'>
						<Typography component='h4'>Log in</Typography>
					</Toolbar>
				</AppBar>
				<CardContent
					sx={{
						background: (theme) => theme.palette.card.content,
						display: 'flex',
						flexDirection: 'column',
						gap: '1rem',
					}}
				>
					{error && !loading && (
						<SnackbarContent
							sx={{
								backgroundColor: '#f1932c',
								maxWidth: 'unset',
								marginBottom: '10px',
							}}
							message={
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: '0.25rem',
									}}
								>
									<Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
										<WarningIcon
											sx={{
												verticalAlign: 'bottom',
												color: '#f0cf81',
											}}
										/>
										<Typography
											component='p'
											sx={{
												fontSize: '0.96em',
												fontWeight: 100,
												color: 'white',
											}}
										>
											Login failed
										</Typography>
									</Box>

									{(failType === 'sessionInUse' || failType === 'lockedAcc') && (
										<Typography
											component='p'
											sx={{
												fontSize: '0.96em',
												fontWeight: 100,
												color: 'white',
											}}
										>
											{getHelperText(failType)}
										</Typography>
									)}
								</Box>
							}
						/>
					)}

					<form
						onSubmit={(e) => {
							e.preventDefault()
						}}
						noValidate
						autoComplete='off'
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: '2.5rem',
							}}
							noValidate
							autoComplete='off'
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: '1rem',
								}}
							>
								<TextField
									required
									autoFocus
									InputLabelProps={{ shrink: true }}
									size='small'
									id='account'
									label='Username'
									type='text'
									fullWidth
									variant='outlined'
									onChange={(e) => setAccount(e.target.value)}
									value={account}
									disabled={loading}
									error={error && (failType === 'emptyAcc' || failType === 'userNotFound')}
									helperText={
										error &&
										(failType === 'emptyAcc' || failType === 'userNotFound') &&
										getHelperText(failType)
									}
								/>
								<TextField
									required
									InputLabelProps={{ shrink: true }}
									size='small'
									id='password'
									label='Password'
									type='password'
									fullWidth
									variant='outlined'
									onChange={(e) => setPassword(e.target.value)}
									value={password}
									disabled={loading}
									error={error && (failType === 'emptyPwd' || failType === 'wrongPwd')}
									helperText={
										error &&
										(failType === 'emptyPwd' || failType === 'wrongPwd') &&
										getHelperText(failType)
									}
								/>
							</Box>

							<LoadingButton
								type='submit'
								variant='contained'
								color='primary'
								fullWidth
								loading={loading}
								onClick={login}
							>
								Log in
							</LoadingButton>
						</Box>
					</form>

					<Button
						component={Link}
						variant='contained'
						color='secondary'
						fullWidth
						disabled={loading}
						{...{ to: '/' }}
					>
						Login with card
					</Button>
				</CardContent>
			</Card>
		</Box>
	)
}

export default Login
