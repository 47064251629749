import React, { useState } from 'react'
import { isEmpty, startCase } from 'lodash'
import { Navigate } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Radio from '@mui/material/Radio'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { allRoles, allRolesRoot } from '../util/Roles'

const SelectRole = ({ status, authorize }) => {
    // radio control
    const [selectedRole, setSelectedRole] = useState('')
    const handleChange = (e) => {
        setSelectedRole(e.target.value)
    }

    const roles = window.localStorage.getItem('roles') && window.localStorage.getItem('roles').split(',')
    const role = window.localStorage.getItem('role')

    function getMatched() {
        if (roles.includes('masterAdmin')) {
            var tempmatchedRoles = roles && allRolesRoot.filter((el) => roles.includes(el.role))
            return tempmatchedRoles
        } else {
            tempmatchedRoles = roles && allRoles.filter((el) => roles.includes(el.role))
            return tempmatchedRoles
        }
    }
    const matchedRoles = getMatched()
   
    const proceed = async () => {
        if (isEmpty(selectedRole)) return
        window.localStorage.setItem('role', selectedRole)
        await authorize()
    }

    if (status === 'unauthorized' && !roles) {
        return <Navigate to='/' />
    }

    if (status === 'authorized') {
        if (role !== 'dealer' && role !== 'pitboss') {
            return <Navigate to='/dashboard' />
        }

        if (role === 'dealer' || role === 'pitboss') {
            return <Navigate to='/posui/dashboard' />
        }
    }

    return (
        <Box
            sx={{
                flex: 1,
                background: (theme) => theme.palette.card.background,
                colorScheme: 'dark',
            }}
        >
            <Hidden xsDown>
                <Grid item sm={6}></Grid>
            </Hidden>
            <Card
                sx={{
                    maxWidth: '400px',
                    margin: '100px auto 0',
                }}
            >
                <AppBar position='static' color='default' elevation={2}>
                    <Toolbar variant='dense'>
                        <Typography component='h4'>Log in</Typography>
                    </Toolbar>
                </AppBar>
                <CardContent
                    sx={{
                        background: (theme) => theme.palette.card.content,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                    }}
                >
                    <Typography>Choose which role to log in as</Typography>
                    <TableContainer sx={{ maxHeight: 200 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{ backgroundColor: (theme) => theme.palette.card.content, border: 0 }}
                                    ></TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.card.content,
                                            border: 0,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Level
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.card.content,
                                            border: 0,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Role
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {matchedRoles.map((role, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ border: 0 }}>
                                            <Radio
                                                value={role.role}
                                                checked={selectedRole === role.role}
                                                onChange={handleChange}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ border: 0 }}>
                                            <Typography>{startCase(role.level)}</Typography>
                                        </TableCell>
                                        <TableCell sx={{ border: 0 }}>
                                            <Typography>{startCase(role.role)}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button variant='contained' disabled={isEmpty(selectedRole)} onClick={proceed}>
                        Proceed
                    </Button>
                </CardContent>
            </Card>
        </Box>
    )
}

export default SelectRole
