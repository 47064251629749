import { Navigate } from 'react-router-dom'

import { authApi, unpublish } from '../api'

const Logout = () => {
    const sessionDevice = window.localStorage.getItem('sessionDevice')
    if (sessionDevice) {
        window.localStorage.removeItem('sessionDevice')
        unpublish(sessionDevice)
    }

    const token = window.localStorage.getItem('token')
    try {
        authApi.delete('unauthorize', {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        })
    } catch (e) {
        console.warn('failed to unauthorize', e)
    }
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('account')
    window.localStorage.removeItem('roles')
    window.localStorage.removeItem('role')
    window.localStorage.removeItem('productsList')
    window.localStorage.removeItem('products')

    return <Navigate to='/' />
}

export default Logout
