import React, { useState, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { makeStyles} from "@mui/styles";
import { Modal, Fade, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import Spinner from "../util/spinner";

const rootStyles = makeStyles((theme) => ({
    noScrollBar: {
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '1px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent'
        }
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    modal: {
        position: 'relative',
        backgroundColor: '#1B1B1F',
        color: '#fff',
        textAlign: 'center',
        borderRadius: 25,
        padding: 50,
        outline: 0,

    },
    modalHeader: {
        backgroundColor: '#111314',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        borderRadius: '25px 25px 0 0'
    },
    modalPrompt: {
        marginTop: '3em',
        paddingTop: '1em',
        fontSize: 13
    },
    modalButtonRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '5%',
        '& button': {
            //width: '33%',
            height: '4.2rem',
            //backgroundColor: '#303238',
            //color: '#fff',
            boxShadow: '0px 1px 1px #161D251A',
            border: '1px solid #5F606A',
            fontSize: '19px',

        }
    },
    modalInput: {
        width: '90%',
        height: '4.2rem',
        backgroundColor: '#303238',
        boxShadow: '0px 1px 2px #00000014',
        border: '1px solid #5F606A',
        borderRadius: 4,
        fontSize: '2rem',
        '& .MuiOutlinedInput-root': { height: '4.2rem' }

    },

    wrapper: {

    },
    container: {
    },
    tablelink: {
        height: '350px', margin: 10, textDecoration: 'none', width: '350px', display: 'inline-flex', backgroundColor: '#1F1B1B', padding: '20px', textTransform: 'none'
    },
    table: {
        width: '100%', height: '100%', color: '#ffffff', fontWeight: '100', textAlign: 'left',
    },
    icon: {
    },
    title: {
        color: '#5F606A', fontSize: '1rem', marginRight: '5%',

    },
    offline: {
        color: '#ff1111',

    },
    online: {
        color: '#00ff00',

    },
    switch: {
        '& .MuiSwitch-root': {
            transform: 'scale(1.5)',
        },
        '& .MuiFormControlLabel-label': {
            fontSize: 20,
        }
    },
    tableDetailsForm: {
        backgroundColor: '',
        fontSize: 20,
        '& label': {
            color: '#777',
            marginLeft: 3
        },
        '& input': {
            border: '1px solid #ccc',
            backgroundColor: '#333',
            fontSize: 20,
            color: '#ccc',
            padding: 5,
            marginTop: 5,
            borderRadius: 5,
            width: '90%'
        },
        '& .disabled': {
            opacity: 0.5
        }
    },
    onoff: {
        color: '#fff'
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81'
    },
}))

const DeviceDetails = ({ checkForStatusValue, device, deviceStatus, setPageNav, tables }) => {

    const s = rootStyles()
    const [modalOpen, setModalOpen] = useState(false)
    const [modalType, setModalType] = useState(null)


    //translation hook
    const { t, } = useTranslation('common')


    const restartClick = (type) => {
        if (bashBlocker[type]) return
        setModalType(type)
        setModalOpen(true)
    }

    const [bashBlocker, setBashBlocker] = useReducer((state, obj) => {
        return { ...state, ...obj }
    }, { device: false, trendboard: false, server: false })

    const restartDevice = async () => {
        try {
            setBashBlocker({ device: true })
            const address = !isEmpty(device.data.port) ? device.data.ip + ':' + device.data.port : device.data.ip + ':3099'
            await window.fetch('http://' + address + '/remote/reboot', { method: 'GET' });
            setModalOpen(false)
            setTimeout(() => {
                setBashBlocker({ device: false })
            }, 5000)
        } catch (e) {
            console.log(e)
        }

    }

    const restartServer = async () => {

        try {
            setBashBlocker({ server: true })
            const address = !isEmpty(device.data.port) ? device.data.ip + ':' + device.data.port : device.data.ip + ':3099'
            await window.fetch('http://' + address + '/remote/restart/game', { method: 'GET' });
            setModalOpen(false)
            setTimeout(() => {
                setBashBlocker({ server: false })
            }, 5000)

        } catch (e) {
            console.log(e)
        }
    }

    const restartTrendboard = async () => {

        try {
            setBashBlocker({ trendboard: true })
            const address = !isEmpty(device.data.port) ? device.data.ip + ':' + device.data.port : device.data.ip + ':3099'
            await window.fetch('http://' + address + '/remote/restart/trendboard', { method: 'GET' });
            setModalOpen(false)
            setTimeout(() => {
                setBashBlocker({ trendboard: false })
            }, 5000)

            //setBashBlocker({trendboard:false})

        } catch (e) {
            console.log(e)
        }
    }

    const [buttonBlock, setButtonBlock] = useState({})
    const [checkPOSStatus, setCheckPOSStatus] = useState(true)

    const deviceType = device.data.type
    const blockButtonBashing = (e, index, fn) => {
        const el = e.nativeEvent.target
        if (typeof el.block === 'undefined' || !el.block) {
            el.block = true
            el.prevHTML = el.innerHTML
            setButtonBlock(buttonBlock => ({ ...buttonBlock, [index]: true }))
            let callback = () => {
                el.interval = setTimeout((el) => {
                    clearTimeout(el.interval)
                    el.block = false
                    setButtonBlock(buttonBlock => ({ ...buttonBlock, [index]: false }))
                }, 5000, el)
            }
            fn(e, callback)
        }
        e.stopPropagation()
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', fontSize: 20, letterSpacing: '1px', alignItems: 'center', margin: 10, height: 100, position: 'relative' }}>
                <div>
                    <h3 style={{ fontFamily: 'Roboto', color: '#ccc' }}><span style={{ fontWeight: 500 }}>{t('manager.tableId')}</span>
                        {device.data.containerType.toLowerCase() === 'table' && tables.filter(v => v.index === device.data.containerIndex).map(v => ' ' + v.data.name)}
                    </h3>
                </div>
                <div>
                    <h3 style={{ fontFamily: 'Roboto', color: '#ccc' }}>
                        <span style={{ fontWeight: 500 }}>{t('manager.game')}:</span>
                        {deviceType === 'game' && <span> {device.data.gameType}</span>}
                    </h3>
                </div>
                {deviceType === 'game' && <div>

                    <SettingsApplicationsIcon onClick={() => { setPageNav({ page: 'configurations' }) }} style={{ position: 'absolute', top: 30, right: 50, fontSize: 50, color: '#ccc', cursor: 'pointer' }} />
                    <EditIcon onClick={() => { setPageNav({ page: 'gameHistory', expandedURL: null }) }} style={{ position: 'absolute', top: 30, right: 110, fontSize: 50, color: '#ccc', cursor: 'pointer' }} />
                </div>}
            </div>

            <Table style={{ width: '90%', margin: 'auto', backgroundColor: '#444', fontFamily: 'Roboto' }}>
                <TableHead>
                    <TableRow style={{ backgroundColor: '#333' }}>
                        <TableCell><h2 style={{ fontWeight: 500 }}>{t('manager.deviceId')}</h2></TableCell>
                        <TableCell><h2 style={{ fontWeight: 500 }}>{t('manager.ipAddress')}</h2></TableCell>
                        <TableCell><h2 style={{ fontWeight: 500 }}>{t('manager.status')}</h2></TableCell>
                        <TableCell style={{ textAlign: 'center' }}><h2 style={{ fontWeight: 500 }}>{t('manager.manage')}</h2></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell><h2>{device.data.name}</h2></TableCell>
                        <TableCell><h2>{device.data.ip}</h2></TableCell>
                        <TableCell>
                            {deviceType === 'game' && checkForStatusValue(device.index, 'data.trendboard') && checkForStatusValue(device.index, 'data.game') && <h2 style={{ fontWeight: 100, color: 'lime' }}>{t('manager.healthy')}</h2>}
                            {deviceType === 'game' && (!checkForStatusValue(device.index, 'data.trendboard') || checkForStatusValue(device.index, !'data.game')) && <h2 style={{ fontWeight: 100, color: 'red' }}>{t('manager.unhealthy')}</h2>}
                            {deviceType === 'pos' && <POSStatusIndicator ip={device.data.ip} setCheckPOSStatus={setCheckPOSStatus} checkPOSStatus={checkPOSStatus} type={'text'} t={t} s={s} />}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            {deviceType === 'game' && <Button onClick={() => restartClick('device')} style={{ color: '#fff', fontSize: '16px', height: 70, width: 188 }} variant="contained" color="primary" >
                                {bashBlocker.device ? <Spinner /> : <>{t('manager.restart')}<br />{t('manager.device')}</>}
                            </Button>}

                            {deviceType === 'pos' && <Button style={{ color: '#fff', fontSize: '16px', height: 70, width: 188 }} variant="contained" color="primary" onClick={(e) => {
                                blockButtonBashing(e, device.index + 'rt', async (e, callback) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    try {
                                        let address = device.data.ip + ':3090'
                                        await fetch('http://' + address + '/restart/dashboard')
                                    } catch (e) {
                                        console.log(e)
                                    } finally {
                                        setCheckPOSStatus(true)
                                        callback()
                                    }

                                })
                            }}
                            >{buttonBlock[device.index + 'rt'] ? <Spinner /> : <>restart POS </>}</Button>}

                            {deviceType === 'pos' && <Button style={{ color: '#fff', fontSize: '16px', height: 70, width: 188, marginLeft: 10 }} variant="contained" color="primary" onClick={(e) => {
                                blockButtonBashing(e, device.index + 'rgs', async (e, callback) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    try {
                                        let address = device.data.ip + ':3090'
                                        await fetch('http://' + address + '/reboot')
                                    } catch (e) {
                                        console.log(e)
                                    } finally {
                                        setCheckPOSStatus(true)
                                        callback()
                                    }
                                })
                            }}
                            >{buttonBlock[device.index + 'rgs'] ? <Spinner /> : <>reboot POS </>}</Button>}




                        </TableCell>
                    </TableRow>
                    {deviceType === 'game' && <TableRow>
                        <TableCell><h2 style={{ fontWeight: 100 }}>{t('manager.trendboard')}</h2></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                            <h2>
                                {(checkForStatusValue(device.index, 'data.trendboard')) && (<span style={{ fontWeight: 100, color: 'lime' }}>{t('manager.online')}</span>)}
                                {(!checkForStatusValue(device.index, 'data.trendboard')) && (<span style={{ fontWeight: 100, color: 'red' }}>{t('manager.offline')}</span>)}
                            </h2>
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            <Button onClick={() => restartClick('trendboard')} style={{ color: '#fff', fontSize: '16px', height: 70, width: 188 }} variant="contained" color="primary" >{bashBlocker.trendboard ? <Spinner /> : <>{t('manager.restart')}<br />{t('manager.trendboard')}</>}</Button>
                        </TableCell>
                    </TableRow>}
                    {deviceType === 'game' && <TableRow>
                        <TableCell><h2 style={{ fontWeight: 100 }}>{t('manager.gameServer')}</h2></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                            <h2>
                                {(checkForStatusValue(device.index, 'data.game')) && (<span style={{ fontWeight: 100, color: 'lime' }}>{t('manager.online')}</span>)}
                                {(checkForStatusValue(device.index, !'data.game')) && (<span style={{ fontWeight: 100, color: 'red' }}>{t('manager.offline')}</span>)}
                            </h2>
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            <Button onClick={() => restartClick('server')} style={{ color: '#fff', fontSize: '16px', height: 70, width: 188 }} variant="contained" color="primary" >{bashBlocker.server ? <Spinner /> : <>{t('manager.restart')}<br />{t('manager.server')}</>}</Button>
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>

            <Modal
                className={s.modalContainer}
                open={modalOpen}
                onClose={(e) => { setModalOpen(false) }}  >
                <Fade in={modalOpen}>
                    <div className={s.modal}>
                        <div className={s.modalHeader}>
                            {modalType === 'device' && <h1>{t('manager.restartDevice')}</h1>}
                            {modalType === 'server' && <h1>{t('manager.restartServer')}</h1>}
                            {modalType === 'trendboard' && <h1>{t('manager.restartTrendboard')}</h1>}
                        </div>
                        <div className={s.modalPrompt}>
                            <div style={{ fontSize: 14, color: '#ccc', margin: '30px' }} >
                                {modalType === 'device' && <h2>{t('manager.restartDevicePrompt')}</h2>}
                                {modalType === 'server' && <h2>{t('manager.restartServerPrompt')}</h2>}
                                {modalType === 'trendboard' && <h2>{t('manager.restartTrendboardPrompt')}</h2>}
                            </div>
                            <div className={s.modalButtonRow}>
                                <Button variant="contained" onClick={() => setModalOpen(false)}>{t('global.cancel')}</Button>
                                {modalType === 'device' && <Button variant="contained" color="primary" onClick={restartDevice}>{t('global.confirm')}</Button>}
                                {modalType === 'server' && <Button variant="contained" color="primary" onClick={restartServer}>{t('global.confirm')}</Button>}
                                {modalType === 'trendboard' && <Button variant="contained" color="primary" onClick={restartTrendboard}>{t('global.confirm')}</Button>}
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

        </div>
    )
}


const POSStatusIndicator = ({ ip, setCheckPOSStatus, checkPOSStatus, type, t, s }) => {
    const [status, setStatus] = useState(null)

    const getStatus = async () => {
        try {
            let address = ip + ':3090'
            const response = await fetch('http://' + address + '/status/dashboard')
            console.log('response', response.status, response)
            setStatus(true)
        } catch (e) {
            setStatus(false)
            console.log(e)
        } finally {
            setCheckPOSStatus(false)
            console.log('finally')
        }
    }
    if (!status || checkPOSStatus) {
        getStatus()
    }

    return type === 'text' ? status ? <h2 style={{ fontWeight: 100, color: 'lime' }}>{t('manager.healthy')}</h2> : <h2 style={{ fontWeight: 100, color: 'red' }}>{t('manager.unhealthy')}</h2> : <></>


}
export default DeviceDetails
