import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import {
    validate,
    update,
    getFieldsValues,
    setDefaults,
    FieldsForm
} from '../../../forms'
import { defaultValues } from '../defaultValues'
import { getMinBetColor } from '../minBetColor/color'
import _ from 'lodash'

const rootStyles = makeStyles((theme) => ({
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        width: ({ template }) => template ? 'inherit' : 700,
        margin: ({ template }) => template ? '2em auto 0' : '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    }
}))

const Bet = ({ publish, table, xstadium, template, changeCallback }) => {
    const styles = rootStyles({ xstadium, template })

    const navigate = useNavigate()

    // form changed detect hook
    const [changed, setChanged] = useState(0)

    // field flags
    const [submitted, setSubmitted] = useState(false)

    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)

    // more fields
    const [minBet, setMinBet] = useState('')
    const [maxBet, setMaxBet] = useState('')
    const [tieMinBet, setTieMinBet] = useState('')
    const [tieMaxBet, setTieMaxBet] = useState('')
    const [pairMinBet, setPairMinBet] = useState('')
    const [pairMaxBet, setPairMaxBet] = useState('')
    const [naturalMinBet, setNaturalMinBet] = useState('')
    const [naturalMaxBet, setNaturalMaxBet] = useState('')
    const [lucky6MinBet, setLucky6MinBet] = useState('')
    const [lucky6MaxBet, setLucky6MaxBet] = useState('')
    const [mega6MinBet, setMega6MinBet] = useState('')
    const [mega6MaxBet, setMega6MaxBet] = useState('')
    const [mega6AllSidesMinBet, setMega6AllSidesMinBet] = useState('')
    const [mega6AllSidesMaxBet, setMega6AllSidesMaxBet] = useState('')
    const [maxTableDiffBet, setMaxTableDiffBet] = useState('')
    const [tigerTieMinBet, setTigerTieMinBet] = useState('')
    const [tigerTieMaxBet, setTigerTieMaxBet] = useState('')
    const [tigerPairMinBet, setTigerPairMinBet] = useState('')
    const [tigerPairMaxBet, setTigerPairMaxBet] = useState('')
    const [tigerMinBet, setTigerMinBet] = useState('')
    const [tigerMaxBet, setTigerMaxBet] = useState('')
    const [tigerBigMinBet, setTigerBigMinBet] = useState('')
    const [tigerBigMaxBet, setTigerBigMaxBet] = useState('')
    const [tigerSmallMinBet, setTigerSmallMinBet] = useState('')
    const [tigerSmallMaxBet, setTigerSmallMaxBet] = useState('')
    const [anyPairMinBet, setAnyPairMinBet] = useState('')
    const [anyPairMaxBet, setAnyPairMaxBet] = useState('')
    const [anyPairAnyPlayerMinBet, setAnyPairAnyPlayerMinBet] = useState('')
    const [anyPairAnyPlayerMaxBet, setAnyPairAnyPlayerMaxBet] = useState('')
    const [tripleDiceLimit, setTripleDiceLimit] = useState(100)
    const [doubleDiceLimit, setDoubleDiceLimit] = useState(100)
    const [useMaxDiffBet, setUseMaxDiffBet] = useState(false)

    // formerly global fields - pit boss
    const [canTieBet, setCanTieBet] = useState(false)
    const [canNatBet, setCanNatBet] = useState(false)
    const [canPairBet, setCanPairBet] = useState(false)
    const [canLucky6, setCanLucky6] = useState(false)
    const [canMega6, setCanMega6] = useState(false)
    const [canTigerTieBet, setCanTigerTieBet] = useState(false)
    const [canTigerPairBet, setCanTigerPairBet] = useState(false)
    const [canTigerBet, setCanTigerBet] = useState(false)
    const [canTigerBigBet, setCanTigerBigBet] = useState(false)
    const [canTigerSmallBet, setCanTigerSmallBet] = useState(false)
    const [canAnyPairBet, setCanAnyPairBet] = useState(false)
    const [canAnyPairAnyPlayerBet, setCanAnyPairAnyPlayerBet] = useState(false)

    const fields = {
        bets: {
            baccarat: {
                standard: {
                    minBet: {
                        group: 'bankerPlayer',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Min bet cannot be more than max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'bankerPlayer',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Max bet cannot be less than min bet',
                        disabled: () => useMaxDiffBet,
                        type: 'number'
                    },
                    useMaxDiffBet: {
                        group: 'displayOptions',
                        label: 'Bet Display Options',
                        labelTrue: 'Use Maximum Differential',
                        labelFalse: 'Use Maximum Bet',
                        error: () => false,
                        value: useMaxDiffBet,
                        set: setUseMaxDiffBet,
                        message: '',
                        type: 'radioBool'
                    },
                    maxTableDiffBet: {
                        group: 'maxDifferential',
                        label: 'Max Table Differential',
                        error: () => Number(maxTableDiffBet) < 0,
                        value: maxTableDiffBet,
                        set: setMaxTableDiffBet,
                        message: 'Max table Differential can not be less than 0',
                        disabled: () => !useMaxDiffBet,
                        type: 'number'
                    },
                    tieMinBet: {
                        group: 'tie',
                        label: 'Min',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMinBet,
                        set: setTieMinBet,
                        message: 'Tie min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    tieMaxBet: {
                        group: 'tie',
                        label: 'Max',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMaxBet,
                        set: setTieMaxBet,
                        message: 'Tie max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    pairMinBet: {
                        group: 'pair',
                        label: 'Min',
                        error: () => Number(pairMinBet) > Number(pairMaxBet),
                        value: pairMinBet,
                        set: setPairMinBet,
                        message: 'Pair min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    pairMaxBet: {
                        group: 'pair',
                        label: 'Max',
                        error: () => Number(pairMinBet) > Number(pairMaxBet),
                        value: pairMaxBet,
                        set: setPairMaxBet,
                        message: 'Pair max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    naturalMinBet: {
                        group: 'natural',
                        label: 'Min',
                        error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                        value: naturalMinBet,
                        set: setNaturalMinBet,
                        message: 'Natural min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    naturalMaxBet: {
                        group: 'natural',
                        label: 'Max',
                        error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                        value: naturalMaxBet,
                        set: setNaturalMaxBet,
                        message: 'Natural max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    canTieBet: {
                        group: 'allowPitBoss',
                        label: 'Tie',
                        error: () => false,
                        value: canTieBet,
                        set: setCanTieBet,
                        message: '',
                        type: 'bool'
                    },
                    canPairBet: {
                        group: 'allowPitBoss',
                        label: 'Pair',
                        error: () => false,
                        value: canPairBet,
                        set: setCanPairBet,
                        message: '',
                        type: 'bool'
                    },
                    canNatBet: {
                        group: 'allowPitBoss',
                        label: 'Natural 8/9',
                        error: () => false,
                        value: canNatBet,
                        set: setCanNatBet,
                        message: '',
                        type: 'bool'
                    },
                },
                lucky6: {
                    minBet: {
                        group: 'bankerPlayer',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Min bet cannot be more than max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'bankerPlayer',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Max bet cannot be less than min bet',
                        disabled: () => useMaxDiffBet,
                        type: 'number'
                    },
                    useMaxDiffBet: {
                        group: 'displayOptions',
                        label: 'Bet Display Options',
                        labelTrue: 'Use Maximum Differential',
                        labelFalse: 'Use Maximum Bet',
                        error: () => false,
                        value: useMaxDiffBet,
                        set: setUseMaxDiffBet,
                        message: '',
                        type: 'radioBool'
                    },
                    maxTableDiffBet: {
                        group: 'maxDifferential',
                        label: 'Max Table Differential',
                        error: () => Number(maxTableDiffBet) < 0,
                        value: maxTableDiffBet,
                        set: setMaxTableDiffBet,
                        message: 'Max table Differential can not be less than 0',
                        disabled: () => !useMaxDiffBet,
                        type: 'number'
                    },
                    tieMinBet: {
                        group: 'tie',
                        label: 'Min',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMinBet,
                        set: setTieMinBet,
                        message: 'Tie min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    tieMaxBet: {
                        group: 'tie',
                        label: 'Max',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMaxBet,
                        set: setTieMaxBet,
                        message: 'Tie max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    pairMinBet: {
                        group: 'pair',
                        label: 'Min',
                        error: () => Number(pairMinBet) > Number(pairMaxBet),
                        value: pairMinBet,
                        set: setPairMinBet,
                        message: 'Pair min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    pairMaxBet: {
                        group: 'pair',
                        label: 'Max',
                        error: () => Number(pairMinBet) > Number(pairMaxBet),
                        value: pairMaxBet,
                        set: setPairMaxBet,
                        message: 'Pair max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    naturalMinBet: {
                        group: 'natural',
                        label: 'Min',
                        error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                        value: naturalMinBet,
                        set: setNaturalMinBet,
                        message: 'Natural min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    naturalMaxBet: {
                        group: 'natural',
                        label: 'Max',
                        error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                        value: naturalMaxBet,
                        set: setNaturalMaxBet,
                        message: 'Natural max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    lucky6MinBet: {
                        group: 'lucky6',
                        label: 'Min',
                        error: () => Number(lucky6MinBet) > Number(lucky6MaxBet),
                        value: lucky6MinBet,
                        set: setLucky6MinBet,
                        message: 'Lucky 6 min bet cannot be more than Lucky 6 max bet',
                        type: 'number'
                    },
                    lucky6MaxBet: {
                        group: 'lucky6',
                        label: 'Max',
                        error: () => Number(lucky6MinBet) > Number(lucky6MaxBet),
                        value: lucky6MaxBet,
                        set: setLucky6MaxBet,
                        message: 'Lucky 6 max bet cannot be less than Lucky 6 min bet',
                        type: 'number'
                    },
                    canTieBet: {
                        group: 'allowPitBoss',
                        label: 'Tie',
                        error: () => false,
                        value: canTieBet,
                        set: setCanTieBet,
                        message: '',
                        type: 'bool'
                    },
                    canPairBet: {
                        group: 'allowPitBoss',
                        label: 'Pair',
                        error: () => false,
                        value: canPairBet,
                        set: setCanPairBet,
                        message: '',
                        type: 'bool'
                    },
                    canNatBet: {
                        group: 'allowPitBoss',
                        label: 'Natural 8/9',
                        error: () => false,
                        value: canNatBet,
                        set: setCanNatBet,
                        message: '',
                        type: 'bool'
                    },
                    canLucky6: {
                        group: 'allowPitBoss',
                        label: 'Lucky6',
                        error: () => false,
                        value: canLucky6,
                        set: setCanLucky6,
                        message: '',
                        type: 'bool'
                    },
                },
                lucky6Slot: {
                    minBet: {
                        group: 'bankerPlayer',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Min bet cannot be more than max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'bankerPlayer',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Max bet cannot be less than min bet',
                        disabled: () => useMaxDiffBet,
                        type: 'number'
                    },
                    useMaxDiffBet: {
                        group: 'displayOptions',
                        label: 'Bet Display Options',
                        labelTrue: 'Use Maximum Differential',
                        labelFalse: 'Use Maximum Bet',
                        error: () => false,
                        value: useMaxDiffBet,
                        set: setUseMaxDiffBet,
                        message: '',
                        type: 'radioBool'
                    },
                    maxTableDiffBet: {
                        group: 'maxDifferential',
                        label: 'Max Table Differential',
                        error: () => Number(maxTableDiffBet) < 0,
                        value: maxTableDiffBet,
                        set: setMaxTableDiffBet,
                        message: 'Max table Differential can not be less than 0',
                        disabled: () => !useMaxDiffBet,
                        type: 'number'
                    },
                    tieMinBet: {
                        group: 'tie',
                        label: 'Min',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMinBet,
                        set: setTieMinBet,
                        message: 'Tie min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    tieMaxBet: {
                        group: 'tie',
                        label: 'Max',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMaxBet,
                        set: setTieMaxBet,
                        message: 'Tie max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    pairMinBet: {
                        group: 'pair',
                        label: 'Min',
                        error: () => Number(pairMinBet) > Number(pairMaxBet),
                        value: pairMinBet,
                        set: setPairMinBet,
                        message: 'Pair min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    pairMaxBet: {
                        group: 'pair',
                        label: 'Max',
                        error: () => Number(pairMinBet) > Number(pairMaxBet),
                        value: pairMaxBet,
                        set: setPairMaxBet,
                        message: 'Pair max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    naturalMinBet: {
                        group: 'natural',
                        label: 'Min',
                        error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                        value: naturalMinBet,
                        set: setNaturalMinBet,
                        message: 'Natural min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    naturalMaxBet: {
                        group: 'natural',
                        label: 'Max',
                        error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                        value: naturalMaxBet,
                        set: setNaturalMaxBet,
                        message: 'Natural max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    lucky6MinBet: {
                        group: 'lucky6',
                        label: 'Min',
                        error: () => Number(lucky6MinBet) > Number(lucky6MaxBet),
                        value: lucky6MinBet,
                        set: setLucky6MinBet,
                        message: 'Lucky 6 min bet cannot be more than Lucky 6 max bet',
                        type: 'number'
                    },
                    lucky6MaxBet: {
                        group: 'lucky6',
                        label: 'Max',
                        error: () => Number(lucky6MinBet) > Number(lucky6MaxBet),
                        value: lucky6MaxBet,
                        set: setLucky6MaxBet,
                        message: 'Lucky 6 max bet cannot be less than Lucky 6 min bet',
                        type: 'number'
                    },
                    canTieBet: {
                        group: 'allowPitBoss',
                        label: 'Tie',
                        error: () => false,
                        value: canTieBet,
                        set: setCanTieBet,
                        message: '',
                        type: 'bool'
                    },
                    canPairBet: {
                        group: 'allowPitBoss',
                        label: 'Pair',
                        error: () => false,
                        value: canPairBet,
                        set: setCanPairBet,
                        message: '',
                        type: 'bool'
                    },
                    canNatBet: {
                        group: 'allowPitBoss',
                        label: 'Natural',
                        error: () => false,
                        value: canNatBet,
                        set: setCanNatBet,
                        message: '',
                        type: 'bool'
                    },
                    canLucky6: {
                        group: 'allowPitBoss',
                        label: 'Lucky 6',
                        error: () => false,
                        value: canLucky6,
                        set: setCanLucky6,
                        message: '',
                        type: 'bool'
                    },
                },
                mega6: {
                    minBet: {
                        group: 'bankerPlayer',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Min bet cannot be more than max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'bankerPlayer',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Max bet cannot be less than min bet',
                        disabled: () => useMaxDiffBet,
                        type: 'number'
                    },
                    useMaxDiffBet: {
                        group: 'displayOptions',
                        label: 'Bet Display Options',
                        labelTrue: 'Use Maximum Differential',
                        labelFalse: 'Use Maximum Bet',
                        error: () => false,
                        value: useMaxDiffBet,
                        set: setUseMaxDiffBet,
                        message: '',
                        type: 'radioBool'
                    },
                    maxTableDiffBet: {
                        group: 'maxDifferential',
                        label: 'Table Max Differential',
                        error: () => Number(maxTableDiffBet) < 0,
                        value: maxTableDiffBet,
                        set: setMaxTableDiffBet,
                        message: 'Max table Differential can not be less than 0',
                        disabled: () => !useMaxDiffBet,
                        type: 'number'
                    },
                    tieMinBet: {
                        group: 'tie',
                        label: 'Min',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMinBet,
                        set: setTieMinBet,
                        message: 'Tie min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    tieMaxBet: {
                        group: 'tie',
                        label: 'Max',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMaxBet,
                        set: setTieMaxBet,
                        message: 'Tie max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    pairMinBet: {
                        group: 'pair',
                        label: 'Min',
                        error: () => Number(pairMinBet) > Number(pairMaxBet),
                        value: pairMinBet,
                        set: setPairMinBet,
                        message: 'Pair min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    pairMaxBet: {
                        group: 'pair',
                        label: 'Max',
                        error: () => Number(pairMinBet) > Number(pairMaxBet),
                        value: pairMaxBet,
                        set: setPairMaxBet,
                        message: 'Pair max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    naturalMinBet: {
                        group: 'natural',
                        label: 'Min',
                        error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                        value: naturalMinBet,
                        set: setNaturalMinBet,
                        message: 'Natural min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    naturalMaxBet: {
                        group: 'natural',
                        label: 'Max',
                        error: () => Number(naturalMinBet) > Number(naturalMaxBet),
                        value: naturalMaxBet,
                        set: setNaturalMaxBet,
                        message: 'Natural max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    mega6MinBet: {
                        group: 'mega6',
                        label: 'Min',
                        error: () => Number(mega6MinBet) > Number(mega6MaxBet),
                        value: mega6MinBet,
                        set: setMega6MinBet,
                        message: 'Mega 6 min bet cannot be more than Mega 6 max bet',
                        type: 'number'
                    },
                    mega6MaxBet: {
                        group: 'mega6',
                        label: 'Max',
                        error: () => Number(mega6MinBet) > Number(mega6MaxBet),
                        value: mega6MaxBet,
                        set: setMega6MaxBet,
                        message: 'Mega 6 max bet cannot be less than Mega 6 min bet',
                        type: 'number'
                    },
                    mega6AllSidesMinBet: {
                        group: 'allSideBets',
                        label: 'Min',
                        error: () => Number(mega6AllSidesMinBet) > Number(mega6AllSidesMaxBet),
                        value: mega6AllSidesMinBet,
                        set: setMega6AllSidesMinBet,
                        message: 'Mega 6 all sides max bet cannot be less than Mega 6 min bet',
                        type: 'number'
                    },
                    mega6AllSidesMaxBet: {
                        group: 'allSideBets',
                        label: 'Max',
                        error: () => Number(mega6AllSidesMinBet) > Number(mega6AllSidesMaxBet),
                        value: mega6AllSidesMaxBet,
                        set: setMega6AllSidesMaxBet,
                        message: 'Mega 6 all sides max bet cannot be less than Mega 6 min bet',
                        type: 'number'
                    },
                    canTieBet: {
                        group: 'allowPitBoss',
                        label: 'Tie',
                        error: () => false,
                        value: canTieBet,
                        set: setCanTieBet,
                        message: '',
                        type: 'bool'
                    },
                    canPairBet: {
                        group: 'allowPitBoss',
                        label: 'Pair',
                        error: () => false,
                        value: canPairBet,
                        set: setCanPairBet,
                        message: '',
                        type: 'bool'
                    },
                    canNatBet: {
                        group: 'allowPitBoss',
                        label: 'Natural 8/9',
                        error: () => false,
                        value: canNatBet,
                        set: setCanNatBet,
                        message: '',
                        type: 'bool'
                    },
                    canMega6: {
                        group: 'allowPitBoss',
                        label: 'Mega 6',
                        error: () => false,
                        value: canMega6,
                        set: setCanMega6,
                        message: '',
                        type: 'bool'
                    },
                },
                tiger: {
                    minBet: {
                        group: 'bankerPlayer',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Min bet cannot be more than max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'bankerPlayer',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Max bet cannot be less than min bet',
                        disabled: () => useMaxDiffBet,
                        type: 'number'
                    },
                    useMaxDiffBet: {
                        group: 'displayOptions',
                        label: 'Bet Display Options',
                        labelTrue: 'Use Maximum Differential',
                        labelFalse: 'Use Maximum Bet',
                        error: () => false,
                        value: useMaxDiffBet,
                        set: setUseMaxDiffBet,
                        message: '',
                        type: 'radioBool'
                    },
                    maxTableDiffBet: {
                        group: 'maxDifferential',
                        label: 'Max Table Differential',
                        error: () => Number(maxTableDiffBet) < 0,
                        value: maxTableDiffBet,
                        set: setMaxTableDiffBet,
                        message: 'Max table Differential can not be less than 0',
                        disabled: () => !useMaxDiffBet,
                        type: 'number'
                    },
                    tieMinBet: {
                        group: 'tie',
                        label: 'Min',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMinBet,
                        set: setTieMinBet,
                        message: 'Tie min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    tieMaxBet: {
                        group: 'tie',
                        label: 'Max',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMaxBet,
                        set: setTieMaxBet,
                        message: 'Tie max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    tigerMinBet: {
                        group: 'tiger',
                        label: 'Min',
                        error: () => Number(tigerMinBet) > Number(tigerMaxBet),
                        value: tigerMinBet,
                        set: setTigerMinBet,
                        message: 'Tiger min bet cannot be more than Tiger max bet',
                        type: 'number'
                    },
                    tigerMaxBet: {
                        group: 'tiger',
                        label: 'Max',
                        error: () => Number(tigerMinBet) > Number(tigerMaxBet),
                        value: tigerMaxBet,
                        set: setTigerMaxBet,
                        message: 'Tiger max bet cannot be less than Tiger min bet',
                        type: 'number'
                    },
                    tigerTieMinBet: {
                        group: 'tigerTie',
                        label: 'Min',
                        error: () => Number(tigerTieMinBet) > Number(tigerTieMaxBet),
                        value: tigerTieMinBet,
                        set: setTigerTieMinBet,
                        message: 'Tiger tie min bet cannot be more than Tiger tie max bet',
                        type: 'number'
                    },
                    tigerTieMaxBet: {
                        group: 'tigerTie',
                        label: 'Max',
                        error: () => Number(tigerTieMinBet) > Number(tigerTieMaxBet),
                        value: tigerTieMaxBet,
                        set: setTigerTieMaxBet,
                        message: 'Tiger tie max bet cannot be less than Tiger tie min bet',
                        type: 'number'
                    },
                    tigerPairMinBet: {
                        group: 'tigerPair',
                        label: 'Min',
                        error: () => Number(tigerPairMinBet) > Number(tigerPairMaxBet),
                        value: tigerPairMinBet,
                        set: setTigerPairMinBet,
                        message: 'Tiger pair min bet cannot be more than Tiger pair max bet',
                        type: 'number'
                    },
                    tigerPairMaxBet: {
                        group: 'tigerPair',
                        label: 'Max',
                        error: () => Number(tigerPairMinBet) > Number(tigerPairMaxBet),
                        value: tigerPairMaxBet,
                        set: setTigerPairMaxBet,
                        message: 'Tiger pair max bet cannot be less than Tiger pair min bet',
                        type: 'number'
                    },
                    tigerBigMinBet: {
                        group: 'tigerBig',
                        label: 'Min',
                        error: () => Number(tigerBigMinBet) > Number(tigerBigMaxBet),
                        value: tigerBigMinBet,
                        set: setTigerBigMinBet,
                        message: 'Tiger big min bet cannot be more than Tiger big max bet',
                        type: 'number'
                    },
                    tigerBigMaxBet: {
                        group: 'tigerBig',
                        label: 'Max',
                        error: () => Number(tigerBigMinBet) > Number(tigerBigMaxBet),
                        value: tigerBigMaxBet,
                        set: setTigerBigMaxBet,
                        message: 'Tiger big max bet cannot be less than Tiger big min bet',
                        type: 'number'
                    },
                    tigerSmallMinBet: {
                        group: 'tigerSmall',
                        label: 'Min',
                        error: () => Number(tigerSmallMinBet) > Number(tigerSmallMaxBet),
                        value: tigerSmallMinBet,
                        set: setTigerSmallMinBet,
                        message: 'Tiger big min bet cannot be more than Tiger big max bet',
                        type: 'number'
                    },
                    tigerSmallMaxBet: {
                        group: 'tigerSmall',
                        label: 'Max',
                        error: () => Number(tigerSmallMinBet) > Number(tigerSmallMaxBet),
                        value: tigerSmallMaxBet,
                        set: setTigerSmallMaxBet,
                        message: 'Tiger big max bet cannot be less than Tiger big min bet',
                        type: 'number'
                    },
                    canTieBet: {
                        group: 'allowPitBoss',
                        label: 'Tie',
                        error: () => false,
                        value: canTieBet,
                        set: setCanTieBet,
                        message: '',
                        type: 'bool'
                    },
                    canTigerBet: {
                        group: 'allowPitBoss',
                        label: 'Tiger',
                        error: () => false,
                        value: canTigerBet,
                        set: setCanTigerBet,
                        message: '',
                        type: 'bool'
                    },
                    canTigerTieBet: {
                        group: 'allowPitBoss',
                        label: 'Tiger Tie',
                        error: () => false,
                        value: canTigerTieBet,
                        set: setCanTigerTieBet,
                        message: '',
                        type: 'bool'
                    },
                    canTigerPairBet: {
                        group: 'allowPitBoss',
                        label: 'Tiger Pair',
                        error: () => false,
                        value: canTigerPairBet,
                        set: setCanTigerPairBet,
                        message: '',
                        type: 'bool'
                    },
                    canTigerBigBet: {
                        group: 'allowPitBoss',
                        label: 'Tiger Big',
                        error: () => false,
                        value: canTigerBigBet,
                        set: setCanTigerBigBet,
                        message: '',
                        type: 'bool'
                    },
                    canTigerSmallBet: {
                        group: 'allowPitBoss',
                        label: 'Tiger Small',
                        error: () => false,
                        value: canTigerSmallBet,
                        set: setCanTigerSmallBet,
                        message: '',
                        type: 'bool'
                    },
                },
            },
            blackjack: {
                standard: {
                    minBet: {
                        group: 'base',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Base min bet cannot be more than base max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'base',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Base max bet cannot be less than base min bet',
                        type: 'number'
                    },
                },
                anyPairSlot: {
                    minBet: {
                        group: 'base',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Base min bet cannot be more than base max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'base',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Base max bet cannot be less than base min bet',
                        type: 'number'
                    },
                    anyPairMinBet: {
                        group: 'anyPair',
                        label: 'Min',
                        error: () => Number(anyPairMinBet) > Number(anyPairMaxBet),
                        value: anyPairMinBet,
                        set: setAnyPairMinBet,
                        message: 'Any pair min bet cannot be more than any pair max bet',
                        type: 'number'
                    },
                    anyPairMaxBet: {
                        group: 'anyPair',
                        label: 'Max',
                        error: () => Number(anyPairMinBet) > Number(anyPairMaxBet),
                        value: anyPairMaxBet,
                        set: setAnyPairMaxBet,
                        message: 'Any pair max bet cannot be less than any pair min bet',
                        type: 'number'
                    },
                    anyPairAnyPlayerMinBet: {
                        group: 'anyPlayer',
                        label: 'Min',
                        error: () => Number(anyPairAnyPlayerMinBet) > Number(anyPairAnyPlayerMaxBet),
                        value: anyPairAnyPlayerMinBet,
                        set: setAnyPairAnyPlayerMinBet,
                        message: 'Any pair any player min bet cannot be more than any pair any player max bet',
                        type: 'number'
                    },
                    anyPairAnyPlayerMaxBet: {
                        group: 'anyPlayer',
                        label: 'Max',
                        error: () => Number(anyPairAnyPlayerMinBet) > Number(anyPairAnyPlayerMaxBet),
                        value: anyPairAnyPlayerMaxBet,
                        set: setAnyPairAnyPlayerMaxBet,
                        message: 'Any pair any player max bet cannot be less than any pair any player min bet',
                        type: 'number'
                    },
                    canAnyPairBet: {
                        group: 'allowPitBoss',
                        label: 'Any Pair',
                        error: () => false,
                        value: canAnyPairBet,
                        set: setCanAnyPairBet,
                        message: '',
                        type: 'bool'
                    },
                    canAnyPairAnyPlayerBet: {
                        group: 'allowPitBoss',
                        label: 'Any Player',
                        error: () => false,
                        value: canAnyPairAnyPlayerBet,
                        set: setCanAnyPairAnyPlayerBet,
                        message: '',
                        type: 'bool'
                    },
                },
            },
            roulette: {
                standard: {
                    minBet: {
                        group: 'main',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Min bet cannot be more than max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'main',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Max bet cannot be less than min bet',
                        type: 'number'
                    },
                },
            },
            sicbo: {
                standard: {
                    minBet: {
                        group: 'main',
                        label: 'Min Bet',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Min bet cannot be more than max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'main',
                        label: 'Max Bet',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Max bet cannot be less than min bet',
                        type: 'number'
                    },
                    tripleDiceLimit: {
                        label: 'Triple Dice Limit',
                        error: () => Number(tripleDiceLimit) < 1,
                        value: tripleDiceLimit,
                        set: setTripleDiceLimit,
                        message: 'Triple dice limit cannot be less than 1',
                        type: 'number'
                    },
                    doubleDiceLimit: {
                        label: 'Double Dice Limit',
                        error: () => Number(doubleDiceLimit) < 1,
                        value: doubleDiceLimit,
                        set: setDoubleDiceLimit,
                        message: 'Double dice limit cannot be less than 1',
                        type: 'number'
                    },
                },
            },
            war: {
                etg: {
                    minBet: {
                        group: 'ante',
                        label: 'Min',
                        error: () => Number(minBet) > Number(maxBet),
                        value: minBet,
                        set: setMinBet,
                        message: 'Ante min bet cannot be more than ante max bet',
                        type: 'number'
                    },
                    maxBet: {
                        group: 'ante',
                        label: 'Max',
                        error: () => Number(minBet) > Number(maxBet),
                        value: maxBet,
                        set: setMaxBet,
                        message: 'Ante max bet cannot be less than ante min bet',
                        type: 'number'
                    },
                    tieMinBet: {
                        group: 'tie',
                        label: 'Min',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMinBet,
                        set: setTieMinBet,
                        message: 'Tie min bet cannot be more than tie max bet',
                        type: 'number'
                    },
                    tieMaxBet: {
                        group: 'tie',
                        label: 'Max',
                        error: () => Number(tieMinBet) > Number(tieMaxBet),
                        value: tieMaxBet,
                        set: setTieMaxBet,
                        message: 'Tie max bet cannot be less than tie min bet',
                        type: 'number'
                    },
                    canTieBet: {
                        group: 'allowPitBoss',
                        label: 'Tie',
                        error: () => false,
                        value: canTieBet,
                        set: setCanTieBet,
                        message: '',
                        type: 'bool'
                    },
                },
            },
        }
    }

    const groups = {
        bankerPlayer: {
            label: 'Banker/Player',
            type: 'horizontal'
        },
        base: {
            label: 'Base',
            type: 'horizontal'
        },
        main: {
            label: 'Main',
            type: 'horizontal'
        },
        ante: {
            label: 'Ante',
            type: 'horizontal'
        },
        displayOptions: {},
        maxDifferential: {},
        tie: {
            label: 'Tie',
            type: 'horizontal'
        },
        pair: {
            label: 'Pair',
            type: 'horizontal'
        },
        natural: {
            label: 'Natural 8/9',
            type: 'horizontal'
        },
        lucky6: {
            label: 'Lucky 6',
            type: 'horizontal'
        },
        mega6: {
            label: 'Mega6',
            type: 'horizontal'
        },
        tiger: {
            label: 'Tiger',
            type: 'horizontal'
        },
        tigerTie: {
            label: 'Tiger Tie',
            type: 'horizontal'
        },
        tigerPair: {
            label: 'Tiger Pair',
            type: 'horizontal'
        },
        tigerSmall: {
            label: 'Tiger Small',
            type: 'horizontal'
        },
        tigerBig: {
            label: 'Tiger Big',
            type: 'horizontal'
        },
        anyPair: {
            label: 'Any Pair',
            type: 'horizontal'
        },
        anyPlayer: {
            label: 'Any Player',
            type: 'horizontal'
        },
        allowPitBoss: {
            label: 'Allow Pit Boss to set min/max bets for',
            dividerTop: true,
            type: 'vertical'
        }
    }

    const gameType = table.data.gameType
    const gameVariant = table.data.gameVariant
    // const variantFieldPath = gameType + '.' + gameVariant + '.'
    const validation = validate(fields)
    const fieldsOfVariant = fields.bets[gameType][gameVariant]
    const isolatedFields = {
        bets: table.data.bets
    }
    const _fields = {
        bets: {
            [gameType]: {
                [gameVariant]: fieldsOfVariant
            }
        }
    }

    if (table.updated !== updated) {
        update(_fields, [], table.data, defaultValues)
        setUpdated(table.updated)
    }

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            try {
                setLoading(true)
                const newFields = _.merge(isolatedFields, getFieldsValues(_fields))
                await publish({
                    ...table.data,
                    ...newFields,
                    minBetColor: getMinBetColor(
                        minBet,
                        table.data.colorThresholds ? table.data.colorThresholds : defaultValues.colorThresholds
                    ),
                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
                setFail('')
                setLoading(false)
                // fired after a successful update or create
                // history.push('/dashboard/devices')
            } catch (e) {
                setFail(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setLoading(false)
            }
        }
        setChanged(0)
    }

    useEffect(() => {
        if (changed > 0 && template) {
            const newFields = _.merge(isolatedFields, getFieldsValues(_fields))
            changeCallback(newFields)
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                key='sideBetsForm'
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </Box>
                )}

                {submitted && validation.error && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    </Box>
                )}

                <FieldsForm fields={fieldsOfVariant}
                    groups={groups}
                    changed={changed}
                    setChanged={setChanged}
                    submitted={submitted}
                    loading={loading} />

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={loading}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                            setDefaults(fields, defaultValues)
                            setChanged(changed + 1)
                        }}
                    >
                        Default
                    </Button>

                    {changed > 0 && (
                        <Button variant='contained' type='button' color='primary' disabled={loading} onClick={send}>
                            Update
                        </Button>
                    )}

                </div>}

                {loading && (
                    <CircularProgress
                        size={80}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                )}
            </form>
        </>
    )
}

export default Bet
