import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useReducer,
} from "react"
import { get, isEqual } from "lodash"
import {
    chaseTheTrendGameIPChipless,
    chaseTheTrendGameIPChipped,
    terminalServerIP,
    dMods,
} from "../../config"
import { fetch as apiFetch } from "../../api"
import { formatCashString } from "../util/misc"
import { DUIContext } from "./Dealer"
import DealerConfirmation from "./DealerConfirmation"

const ChaseTheTrendGame = () => {
    const unmounted = useRef(false);
    useEffect(() => { return () => { unmounted.current = true } }, []);
    const [modalObj, setModalObj] = useState({ show: false, modalMsg: '', requirePitBoss: false, confirm: () => { } })

    const {
        authorize,
        device,
        game,
        hasGameState,
        //getBalance,
        chasetrend,
        //showCTTGButton,
        setShowCTTGButton,
        CTTGButtonTriggered,
        setCTTGButtonTriggered
    } = useContext(DUIContext)


    const chaseTheTrendGameIP = dMods.has_chase_trend_game_chipless ? chaseTheTrendGameIPChipless : chaseTheTrendGameIPChipped
    const [sessionIDs, setSessionIDs] = useReducer((currentState, obj) => {
        return get(obj, 'reset', false) ? { ...currentState, loading: false, value: null, fetchAttempts: 0, maxAttempts: 5 } : { ...currentState, ...obj }
    }, {
        loading: false, value: null, fetchAttempts: 0, maxAttempts: 5, fetch: async () => {
            setSessionIDs({ loading: true, fetchAttempts: sessionIDs.fetchAttempts + 1 })
            try {
                const response = await apiFetch(terminalServerIP + '/sessions/' + device.index + '/*', authorize)
                setSessionIDs({ value: response.filter(v => !isNaN(Number(v.index))), loading: false })
            } catch (e) {
                setTimeout(() => {
                    setSessionIDs({ loading: false, value: sessionIDs.fetchAttempts < sessionIDs.maxAttempts ? sessionIDs.value : [] })
                }, 1500)
            }
        }
    })
    const [uncashedBets, setUncashedBets] = useState(null)
    const [s, setS] = useReducer((s, o) => ({ ...s, ...o }), {
        game: null,
        betAmount: 0,
        showBetUI: false,
        showPayoutUI: false,
        stage: 0,
        payout: 0,
    })
    const [bets, setBets] = useReducer((s, o) => {
        if (o.action === 'remove') return s.filter(v => v !== o.seat)
        if (o.action === 'set') return s.includes(o.seat) ? s.filter(v => v !== o.seat) : [...s, o.seat]
        if (o.action === 'all') return o.bets
        return []
    }, [])
    const [lastGameWinner, setLastGameWinner] = useState(null)
    const [gameIDOfLastTieGameAfterStage1, setGameIDOfLastTieGameAfterStage1] = useState(null)

    //when chipless: hide the CTG button, hide the UI
    // if (dMods.has_chase_trend_game_chipless) {
    //     if (//showCTTGButton) setShowCTTGButton(false)
    //     return <></>
    // }

    if (!sessionIDs.value && !sessionIDs.loading && sessionIDs.fetchAttempts < sessionIDs.maxAttempts) {
        sessionIDs.fetch()
        return <></>
    }
    const isTieGameAfterStage1 = hasGameState && get(game, 'data.result.status', '') === 'T' && get(chasetrend, 'data.games', []).length === 1

    if (isTieGameAfterStage1 && gameIDOfLastTieGameAfterStage1 !== get(game, 'data.id', null)) {
        setGameIDOfLastTieGameAfterStage1(get(game, 'data.id', null))
        return <></>
    }


    const chaseTheTrendGameConfigs = get(device, 'data.chaseTheTrendGameConfigs', {})
    const setHideUIPanel = (gameStreaks) => {
        if (CTTGButtonTriggered) return 0
        if (isTieGameAfterStage1 && newS.hideUIPanel !== gameIDOfLastTieGameAfterStage1) return 0
        if (isTieGameAfterStage1 && newS.hideUIPanel === gameIDOfLastTieGameAfterStage1) return newS.hideUIPanel
        if (newS.hideUIPanel === gameStreaks.slice(-1).pop()) return newS.hideUIPanel
        return 0
    }

    const newS = {
        ...s, game: null, betAmount: 0, showBetUI: false, showPayoutUI: false, stage: 0, payout: 0, cashOutGame: 0
    }





    if (hasGameState && get(chaseTheTrendGameConfigs, 'enabled', false) && get(chaseTheTrendGameConfigs, 'chaseTheDragon.enabled', false) && game.data.status === 'done') {
        const dragon = get(chasetrend, 'data.games', [])
        newS.game = 'Chase The Dragon'
        newS.betAmount = get(chaseTheTrendGameConfigs, 'chaseTheDragon.betAmount', 0)
        newS.stage = dragon.length
        newS.hideUIPanel = setHideUIPanel(dragon)
        console.log('setHideUIPanel(dragon)', setHideUIPanel(dragon))
        if (dragon.length === 1) {
            newS.showBetUI = true
            if (lastGameWinner !== get(chasetrend, 'data.lastResult', '')) {
                newS.hideUIPanel = 0
                setLastGameWinner(get(chasetrend, 'data.lastResult', ''))
            }
        }
        if (dragon.length === 4 && get(chaseTheTrendGameConfigs, 'chaseTheDragon.stage1Enabled', false)) {
            newS.showPayoutUI = true
            newS.payout = get(chaseTheTrendGameConfigs, 'chaseTheDragon.stage1Pay', 0)
            newS.cashOutGame = 4
        }
        if (dragon.length === 7 && get(chaseTheTrendGameConfigs, 'chaseTheDragon.stage2Enabled', false)) {
            newS.showPayoutUI = true
            newS.payout = get(chaseTheTrendGameConfigs, 'chaseTheDragon.stage2Pay', 0)
            newS.cashOutGame = 7
        }
        if (dragon.length === 8 && get(chaseTheTrendGameConfigs, 'chaseTheDragon.stage3Enabled', false)) {
            newS.showPayoutUI = true
            newS.payout = get(chaseTheTrendGameConfigs, 'chaseTheDragon.stage3Pay', 0)
            newS.cashOutGame = 8
        }
        if ([2, 3, 5, 6].includes(dragon.length)) {
            newS.showBetUI = true
        }
    }

    if (hasGameState && get(chaseTheTrendGameConfigs, 'chaseTheTrendGame', false) && get(chaseTheTrendGameConfigs, 'chaseThePingPong.enabled', false) && game.data.status === 'done') {
        const pingPong = get(chasetrend, 'data.games', [])
        newS.game = 'Chase The Ping Pong'
        newS.betAmount = get(chaseTheTrendGameConfigs, 'chaseThePingPong.betAmount', 0)
        newS.stage = pingPong.length
        newS.hideUIPanel = setHideUIPanel(pingPong)
        if (pingPong.length === 1) {
            newS.showBetUI = true
        }
        if (pingPong.length === 4 && get(chaseTheTrendGameConfigs, 'chaseThePingPong.stage1Enabled', false)) {
            newS.showPayoutUI = true
            newS.payout = get(chaseTheTrendGameConfigs, 'chaseThePingPong.stage1Pay', 0)
            newS.cashOutGame = 4
        }
        if (pingPong.length === 7 && get(chaseTheTrendGameConfigs, 'chaseThePingPong.stage2Enabled', false)) {
            newS.showPayoutUI = true
            newS.payout = get(chaseTheTrendGameConfigs, 'chaseThePingPong.stage2Pay', 0)
            newS.cashOutGame = 7
        }
        if (pingPong.length === 8 && get(chaseTheTrendGameConfigs, 'chaseThePingPong.stage3Enabled', false)) {
            newS.showPayoutUI = true
            newS.payout = get(chaseTheTrendGameConfigs, 'chaseThePingPong.stage3Pay', 0)
            newS.cashOutGame = 8
        }
    }


    const calcPayout = (seatID) => '$' + formatCashString(Number(s.betAmount) * Number(s.payout))

    const placeBet = async (seatID) => {
        if (dMods.has_chase_trend_game_chipless && !dMods.allow_dealer_chipless_chase_trend_game_controls) return
        //const seatAlreadyHasBet = (seatChasetrend.filter(v => v.index === seatID + '').length > 0) ? true : false
        //if (seatChasetrend.filter(v => v.index === seatID + '').length > 0) return // stop placebet when already have bet

        try {
            const token = window.localStorage.getItem('token')
            const res = await fetch('http://' + chaseTheTrendGameIP + '/bet/chase/' + device.index + '/' + seatID, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            console.log('res', res)
        } catch (e) {
            if (e && e.response && (e.response.status === 401 || e.response.status === 403)) {
                try {
                    await authorize(e)
                    placeBet(seatID)
                } catch (e) {
                    console.log('failed to refresh token', e)
                }
            } else {
                console.log('failed to set Bet', e)
            }

        }
    }

    const recallBet = async (seatID) => {
        if (dMods.has_chase_trend_game_chipless && !dMods.allow_dealer_chipless_chase_trend_game_controls) return


        try {
            const token = window.localStorage.getItem('token')
            const res = await fetch('http://' + chaseTheTrendGameIP + '/recall/chase/' + device.index + '/' + seatID, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            console.log('res', res)
        } catch (e) {
            if (e && e.response && (e.response.status === 401 || e.response.status === 403)) {
                try {
                    await authorize(e)
                    recallBet(seatID)
                } catch (e) {
                    console.log('failed to refresh token', e)
                }
            } else {
                console.log('failed to set Bet', e)
            }

        }
    }


    const claimPayout = async (seatID) => {
        if (dMods.has_chase_trend_game_chipless && !dMods.allow_dealer_chipless_chase_trend_game_controls) return

        setModalObj({
            show: true,
            modalMsg: 'Seat: ' + (Number(seatID) + 1) + '. Claim Win: ' + calcPayout(seatID) + '.',
            requirePitBoss: false,
            confirm: async () => {
                try {
                    const token = window.localStorage.getItem('token')
                    const res = await fetch('http://' + chaseTheTrendGameIP + '/cashout/chase/' + device.index + '/' + seatID, {
                        method: 'GET', // *GET, POST, PUT, DELETE, etc.
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    });
                    console.log('res', res)// parses JSON response into native JavaScript objects
                } catch (e) {
                    if (e && e.response && (e.response.status === 401 || e.response.status === 403)) {
                        try {
                            await authorize(e)
                            claimPayout(seatID)
                        } catch (e) {
                            console.log('failed to refresh token', e)
                        }
                    } else {
                        console.log('failed to cashout', e)
                    }
                }

            }
        })



    }






    const getSeatsWithActiveBets = () => {
        const playerIDsWithBets = get(chasetrend, 'data.bets', []).filter(v => v.cashOutGame === 0).length > 0 ? get(chasetrend, 'data.bets', []).filter(v => v.cashOutGame === 0).map(v => v.player) : []
        const sess = sessionIDs.value ? get(sessionIDs, 'value', []) : []
        return sess.filter(v => playerIDsWithBets.includes(v.index)).map(v => Number(v.index))
    }


    //if all bets are cashed out
    if (sessionIDs.value && !sessionIDs.loading && get(chasetrend, 'data.bets') && uncashedBets !== get(chasetrend, 'data.bets', []).filter(v => v.cashOutGame === 0).length) {
        //console.log("{ action: 'all', bets: getSeatsWithActiveBets() }", { action: 'all', bets: getSeatsWithActiveBets() })
        setBets({ action: 'all', bets: getSeatsWithActiveBets() })
        setUncashedBets(get(chasetrend, 'data.bets', []).filter(v => v.cashOutGame === 0).length)
        return <></>
    }


    //if bets null set to empty []
    if (!get(chasetrend, 'data.bets', []) && bets.length !== 0) {
        setBets({ action: 'all', bets: [] })
        return <></>
    }


    if (!sessionIDs.value) {
        console.log('CTD: no sessionsID value?')
        return <></>
    }


    //if current gameID is not in the chaseTrend array, then dont show UI
    //unless its a tie game after stage 1
    if (hasGameState && !get(chasetrend, 'data.games', []).includes(get(game, 'data.id', null)) && !isTieGameAfterStage1) {
        setShowCTTGButton(false)
        console.log('CTD: game ID not in series')
        return <></>
    } else {
        setShowCTTGButton(true)
    }

    if (!isEqual(s, newS)) {
        setS(newS)
        // if (newS.game && (newS.showBetUI || (newS.showPayoutUI && bets.length > 0))) {
        //     setShowCTTGButton(true)
        // } else {
        //     setShowCTTGButton(false)
        // }
        // if (CTTGButtonTriggered) {
        //     setCTTGButtonTriggered(false)
        // }
    }

    if (CTTGButtonTriggered) {
        setCTTGButtonTriggered(false)
    }

    const displayBetUI = () => {
        if (s.hideUIPanel) return false
        if (s.game && s.showBetUI) return true
        return false
    }
    const displayPayoutUI = () => {
        if (s.hideUIPanel) return false
        //in chipless mode, show the payout ui even when all bets have been cashed out...
        if (s.game && s.showPayoutUI && dMods.has_chase_trend_game_chipless) return true
        if (s.game && s.showPayoutUI && bets.length > 0) return true
        return false
    }

    // console.log('-------------------------')
    // console.log('chasetrend', chasetrend)
    // console.log('device', device)
    // console.log('game', game)
    // console.log('newS', newS)
    // console.log('s', s)
    // console.log('newS.hideUIPanel', newS.hideUIPanel)
    // console.log('s.hideUIPanel', s.hideUIPanel)
    // console.log('CTG bets', bets)
    const placeBetBoxStyle = { border: '3px solid #000', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vh', color: '#fff', background: 'transparent linear-gradient(0deg, #999 0%, #000 90%)', padding: '10px', position: 'relative', cursor: 'pointer' }
    return <>
        {/* place bet ui */}
        {displayBetUI() && <div onClick={() => setS({ hideUIPanel: game.data.id })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999 }}>
            <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} style={{ backgroundColor: '#666', height: '30%', width: '85%', borderRadius: 10, objectFit: 'contain', overflow: 'hidden', display: 'flex', flexDirection: 'column', padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '2.651vw', fontStyle: 'italic', letterSpacing: '0.244em', textTransform: 'uppercase', fontWeight: '900', position: 'relative' }}>
                        <div style={{ position: 'absolute', top: 0, left: 0, whiteSpace: 'nowrap', zIndex: 2, marginLeft: 20, color: 'black', }}>{s.game}</div>
                        <div style={{ position: 'absolute', top: 0, left: 0, whiteSpace: 'nowrap', zIndex: 1, marginLeft: 20, transformOrigin: 'right bottom', transform: 'skew(48deg, 0deg) translate(12px, -4px) scale(1, 0.6)', background: 'linear-gradient(0deg, rgb(0, 0, 0) 0%, transparent 60%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', }}>{s.game}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 0 }}>
                        <div style={{ fontSize: '2.2vw', fontWeight: '600', letterSpacing: '0.003em' }}>Fixed Bet: <span style={{ color: 'limegreen' }}>${formatCashString(s.betAmount)}</span></div>
                        <div style={{ fontSize: '1.5vw', fontWeight: '600', letterSpacing: '0.003em' }}>Stage {s.stage}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', height: '100%', justifyContent: 'space-around', alignItems: 'center', position: 'relative', border: '6px solid #000', borderLeft: 0, borderRight: 0, margin: '55px 10px 20px 10px' }}>
                    <div style={{ color: '#fff', backgroundColor: '#000', position: 'absolute', top: '-43px', left: 0, padding: '5px 10px', letterSpacing: '0.229em' }}>SEATS</div>
                    <div onClick={() => { bets.includes(0) ? recallBet(0) : placeBet(0) }} style={{ ...placeBetBoxStyle }}>
                        <span style={{ zIndex: 100 }}>1</span>
                        {bets.includes(0) && <svg preserveAspectRatio="none" viewBox="0 0 95 93" style={{ position: 'absolute', left: '50%', top: '53%', transform: 'translate(-50%, -50%)', padding: '10%', fill: 'limegreen', zIndex: 99 }}>
                            <path d="M 47.5 0 C 73.7335205078125 0 95 20.81876182556152 95 46.5 C 95 72.18123626708984 73.7335205078125 93 47.5 93 C 21.26647758483887 93 0 72.18123626708984 0 46.5 C 0 20.81876182556152 21.26647758483887 0 47.5 0 Z"></path>
                        </svg>}
                    </div>
                    <div onClick={() => { bets.includes(1) ? recallBet(1) : placeBet(1) }} style={{ ...placeBetBoxStyle }}>
                        <span style={{ zIndex: 100 }}>2</span>
                        {bets.includes(1) && <svg preserveAspectRatio="none" viewBox="0 0 95 93" style={{ position: 'absolute', left: '50%', top: '53%', transform: 'translate(-50%, -50%)', padding: '10%', fill: 'limegreen', zIndex: 99 }}>
                            <path d="M 47.5 0 C 73.7335205078125 0 95 20.81876182556152 95 46.5 C 95 72.18123626708984 73.7335205078125 93 47.5 93 C 21.26647758483887 93 0 72.18123626708984 0 46.5 C 0 20.81876182556152 21.26647758483887 0 47.5 0 Z"></path>
                        </svg>}
                    </div>
                    <div onClick={() => { bets.includes(2) ? recallBet(2) : placeBet(2) }} style={{ ...placeBetBoxStyle }}>
                        <span style={{ zIndex: 100 }}>3</span>
                        {bets.includes(2) && <svg preserveAspectRatio="none" viewBox="0 0 95 93" style={{ position: 'absolute', left: '50%', top: '53%', transform: 'translate(-50%, -50%)', padding: '10%', fill: 'limegreen', zIndex: 99 }}>
                            <path d="M 47.5 0 C 73.7335205078125 0 95 20.81876182556152 95 46.5 C 95 72.18123626708984 73.7335205078125 93 47.5 93 C 21.26647758483887 93 0 72.18123626708984 0 46.5 C 0 20.81876182556152 21.26647758483887 0 47.5 0 Z"></path>
                        </svg>}
                    </div>
                    <div onClick={() => { bets.includes(3) ? recallBet(3) : placeBet(3) }} style={{ ...placeBetBoxStyle }}>
                        <span style={{ zIndex: 100 }}>4</span>
                        {bets.includes(3) && <svg preserveAspectRatio="none" viewBox="0 0 95 93" style={{ position: 'absolute', left: '50%', top: '53%', transform: 'translate(-50%, -50%)', padding: '10%', fill: 'limegreen', zIndex: 99 }}>
                            <path d="M 47.5 0 C 73.7335205078125 0 95 20.81876182556152 95 46.5 C 95 72.18123626708984 73.7335205078125 93 47.5 93 C 21.26647758483887 93 0 72.18123626708984 0 46.5 C 0 20.81876182556152 21.26647758483887 0 47.5 0 Z"></path>
                        </svg>}
                    </div>
                    <div onClick={() => { bets.includes(4) ? recallBet(4) : placeBet(4) }} style={{ ...placeBetBoxStyle }}>
                        <span style={{ zIndex: 100 }}>5</span>
                        {bets.includes(4) && <svg preserveAspectRatio="none" viewBox="0 0 95 93" style={{ position: 'absolute', left: '50%', top: '53%', transform: 'translate(-50%, -50%)', padding: '10%', fill: 'limegreen', zIndex: 99 }}>
                            <path d="M 47.5 0 C 73.7335205078125 0 95 20.81876182556152 95 46.5 C 95 72.18123626708984 73.7335205078125 93 47.5 93 C 21.26647758483887 93 0 72.18123626708984 0 46.5 C 0 20.81876182556152 21.26647758483887 0 47.5 0 Z"></path>
                        </svg>}
                    </div>
                </div>
            </div>
        </div>}

        {/* claim payout ui */}
        {displayPayoutUI() && <div onClick={() => setS({ hideUIPanel: game.data.id })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999 }}>
            <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} style={{ backgroundColor: '#666', height: '30%', width: '85%', borderRadius: 10, objectFit: 'contain', overflow: 'hidden', display: 'flex', flexDirection: 'column', padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '2.651vw', fontStyle: 'italic', letterSpacing: '0.244em', textTransform: 'uppercase', fontWeight: '900', position: 'relative' }}>
                        <div style={{ position: 'absolute', top: 0, left: 0, whiteSpace: 'nowrap', zIndex: 2, marginLeft: 20, color: 'black', }}>{s.game}</div>
                        <div style={{ position: 'absolute', top: 0, left: 0, whiteSpace: 'nowrap', zIndex: 1, marginLeft: 20, transformOrigin: 'right bottom', transform: 'skew(48deg, 0deg) translate(12px, -4px) scale(1, 0.6)', background: 'linear-gradient(0deg, rgb(0, 0, 0) 0%, transparent 60%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>{s.game}</div>
                    </div>
                    <div style={{ fontSize: '2.2vw', fontWeight: '600', letterSpacing: '0.003em' }}>Stage {s.stage} Payout Odds: <span style={{ color: 'limegreen' }}>1 to {s.payout}</span></div>
                </div>
                <div style={{ display: 'flex', height: '100%', justifyContent: 'space-around', alignItems: 'center', position: 'relative', border: '6px solid #000', borderLeft: 0, borderRight: 0, margin: '55px 10px 20px 10px' }}>
                    <div style={{ color: '#fff', backgroundColor: '#000', position: 'absolute', top: '-43px', left: 0, padding: '5px 10px', letterSpacing: '0.229em' }}>SEATS</div>
                    <div onClick={() => bets.includes(0) ? claimPayout(0) : () => { }} style={{ border: '3px solid #000', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vh', color: '#fff', background: 'transparent linear-gradient(0deg, #999 0%, #000 90%)', padding: '10px', position: 'relative', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', borderRadius: '50px', height: 100, width: 100, background: '#222', color: '#fff', fontSize: '23px', top: '-70px' }}>1</div>
                        {bets.includes(0) && <div style={{ color: 'limegreen' }}>{calcPayout(0)}</div>}
                    </div>
                    <div onClick={() => bets.includes(1) ? claimPayout(1) : () => { }} style={{ border: '3px solid #000', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vh', color: '#fff', background: 'transparent linear-gradient(0deg, #999 0%, #000 90%)', padding: '10px', position: 'relative', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', borderRadius: '50px', height: 100, width: 100, background: '#222', color: '#fff', fontSize: '23px', top: '-70px' }}>2</div>
                        {bets.includes(1) && <div style={{ color: 'limegreen' }}>{calcPayout(1)}</div>}
                    </div>
                    <div onClick={() => bets.includes(2) ? claimPayout(2) : () => { }} style={{ border: '3px solid #000', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vh', color: '#fff', background: 'transparent linear-gradient(0deg, #999 0%, #000 90%)', padding: '10px', position: 'relative', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', borderRadius: '50px', height: 100, width: 100, background: '#222', color: '#fff', fontSize: '23px', top: '-70px' }}>3</div>
                        {bets.includes(2) && <div style={{ color: 'limegreen' }}>{calcPayout(2)}</div>}

                    </div>
                    <div onClick={() => bets.includes(3) ? claimPayout(3) : () => { }} style={{ border: '3px solid #000', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vh', color: '#fff', background: 'transparent linear-gradient(0deg, #999 0%, #000 90%)', padding: '10px', position: 'relative', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', borderRadius: '50px', height: 100, width: 100, background: '#222', color: '#fff', fontSize: '23px', top: '-70px' }}>4</div>
                        {bets.includes(3) && <div style={{ color: 'limegreen' }}>{calcPayout(3)}</div>}

                    </div>
                    <div onClick={() => bets.includes(4) ? claimPayout(4) : () => { }} style={{ border: '3px solid #000', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vh', color: '#fff', background: 'transparent linear-gradient(0deg, #999 0%, #000 90%)', padding: '10px', position: 'relative', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', borderRadius: '50px', height: 100, width: 100, background: '#222', color: '#fff', fontSize: '23px', top: '-70px' }}>5</div>
                        {bets.includes(4) && <div style={{ color: 'limegreen' }}>{calcPayout(4)}</div>
                        }
                    </div>
                </div>
            </div>
        </div>}

        {modalObj.show && !modalObj.requirePitBoss && <DealerConfirmation modalObj={modalObj} setModalObj={setModalObj} />}


    </>
}

export default ChaseTheTrendGame
