import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiDrawer from '@mui/material/Drawer'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { usePublish, patternsApi } from '../../api'
import AlertDialog from '../../xtrend/components/AlertDialog'
import Lookup from './Lookup'
import SideBetMeter from './SideBetMeter'
import Trends from './Trends'

const rootStyles = makeStyles((theme) => ({
    root: {
        marginTop: '4em',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        background: 'transparent',
        overflowY: 'hidden',
    },
}))

const MoreInfoDrawer = ({ displayMoreInfo, setDisplayMoreInfo }) => {
    const lights = window.localStorage.getItem('lights') === 'on'
    const styles = rootStyles({ lights })

    return (
        <MuiDrawer
            anchor={'right'}
            variant={'temporary'}
            open={displayMoreInfo}
            onClose={() => setDisplayMoreInfo(false)}
        >
            <Paper key='form' className={styles.root} elevation={0}>
                <Box
                    sx={{
                        width: '500px',
                        height: '100%',
                        padding: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography>Please contact IDX Support for any questions or comments.</Typography>

                    <Box display='flex' justifyContent='center'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                setDisplayMoreInfo(false)
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </MuiDrawer>
    )
}

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}))

const baccaratVariants = ['standard', 'lucky6', 'mega6', 'tiger']

export const getGameVariantTranslation = (variant) => {
    let result = ''
    switch (variant) {
        case 'standard':
            result = 'Standard'
            break
        case 'lucky6':
            result = 'Lucky 6'
            break
        case 'lucky6Slot':
            result = 'Lucky 6 with Slot'
            break
        case 'mega6':
            result = 'Mega 6'
            break
        case 'tiger':
            result = 'Tiger'
            break
        default:
    }
    return result
}

function Row(props) {
    const { variant, patterns, setPatterns, submitted } = props

    // tabs
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Accordion sx={{ background: '#333333' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Box>
                    <Typography>Game Type: Baccarat</Typography>
                    <Typography>Game Variant: {getGameVariantTranslation(variant)}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Tabs
                    sx={{ backgroundColor: '#424242' }}
                    value={value}
                    onChange={handleChange}
                    indicatorColor='secondary'
                    textColor='secondary'
                    aria-label='full width tabs example'
                >
                    <Tab label='Basic' />
                    <Tab label='Side Bet' />
                    <Tab label='Side Bet Meter' />
                    <Tab label='Lookup' />
                </Tabs>

                {value === 0 && <Trends type='trends' variant={variant} patterns={patterns} />}
                {value === 1 && <Trends type='hotTrends' variant={variant} patterns={patterns} />}
                {value === 2 && (
                    <SideBetMeter
                        variant={variant}
                        patterns={patterns}
                        setPatterns={setPatterns}
                        submitted={submitted}
                    />
                )}
                {value === 3 && (
                    <Lookup variant={variant} patterns={patterns} setPatterns={setPatterns} submitted={submitted} />
                )}
            </AccordionDetails>
        </Accordion>
    )
}

const HotTrendOptions = ({
    devices,
    standardPatterns,
    lucky6Patterns,
    mega6Patterns,
    tigerPatterns,
    standardPatternsPublish,
    lucky6PatternsPublish,
    mega6PatternsPublish,
    tigerPatternsPublish,
}) => {
    const [displayMoreInfo, setDisplayMoreInfo] = useState(false)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => {},
        loading: false,
    })

    // tabs
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [patterns, setPatterns] = useState({
        standard: standardPatterns.data,
        lucky6: lucky6Patterns.data,
        mega6: mega6Patterns.data,
        tiger: tigerPatterns.data,
    })

    const setToDefault = async () => {
        try {
            await patternsApi.get(`settings/defaults/baccarat/standard`).json()
            await patternsApi.get(`settings/defaults/baccarat/lucky6`).json()
            await patternsApi.get(`settings/defaults/baccarat/mega6`).json()
            await patternsApi.get(`settings/defaults/baccarat/tiger`).json()
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to set default settings. Please try again.',
                error: true,
            })
        }
    }

    const [submitted, setSubmitted] = useState(false)

    const validateData = () => {
        const gamesLimitValidation = Object.keys(patterns)
            .map((key) => {
                return patterns[key].gamesLimit < 100 ? true : patterns[key].gamesLimit > 500 ? true : false
            })
            .every((v) => v === false)

        const metersValidation = Object.keys(patterns)
            .map((key) =>
                Object.keys(patterns[key].meters).map((v) => v.includes('MeterMax') && patterns[key].meters[v] < 1)
            )
            .map((v) => v.every((v) => v === false))
            .every((v) => v === true)

        return gamesLimitValidation && metersValidation ? true : false
    }

    console.log('patterns', patterns)

    const batchApplyPrompt = () => {
        setSubmitted(true)
        if (validateData()) {
            const baccaratStandardDevicesToApply = devices.filter(
                (v) => v.data.gameType === 'baccarat' && v.data.gameVariant === 'standard'
            )
            const baccaratLucky6DevicesToApply = devices.filter(
                (v) => v.data.gameType === 'baccarat' && v.data.gameVariant === 'lucky6'
            )
            const baccaratMega6DevicesToApply = devices.filter(
                (v) => v.data.gameType === 'baccarat' && v.data.gameVariant === 'mega6'
            )
            const baccaratTigerDevicesToApply = devices.filter(
                (v) => v.data.gameType === 'baccarat' && v.data.gameVariant === 'tiger'
            )

            setAlertDialogState({
                show: true,
                type: 'confirmation',
                message: <>These settings will apply to all ETG tables of the same variant. Are you sure?</>,
                loading: false,
                error: false,
                confirm: async () => {
                    await standardPatternsPublish({
                        ...patterns.standard,
                    })
                    await lucky6PatternsPublish({
                        ...patterns.lucky6,
                    })
                    await mega6PatternsPublish({
                        ...patterns.mega6,
                    })
                    await tigerPatternsPublish({
                        ...patterns.tiger,
                    })

                    const baccaratStandardDeviceIndexesToApply = baccaratStandardDevicesToApply.map((v) => v.index)
                    const baccaratLucky6DeviceIndexesToApply = baccaratLucky6DevicesToApply.map((v) => v.index)
                    const baccaratMega6DeviceIndexesToApply = baccaratMega6DevicesToApply.map((v) => v.index)
                    const baccaratTigerDeviceIndexesToApply = baccaratTigerDevicesToApply.map((v) => v.index)

                    setAlertDialogState((prev) => ({
                        ...prev,
                        loading: true,
                    }))

                    const baccaratStandardPromises = baccaratStandardDeviceIndexesToApply.map((deviceIndex) =>
                        Apply(deviceIndex, 'standard')
                    )
                    const baccaratLucky6Promises = baccaratLucky6DeviceIndexesToApply.map((deviceIndex) =>
                        Apply(deviceIndex, 'lucky6')
                    )
                    const baccaratMega6Promises = baccaratMega6DeviceIndexesToApply.map((deviceIndex) =>
                        Apply(deviceIndex, 'mega6')
                    )
                    const baccaratTigerPromises = baccaratTigerDeviceIndexesToApply.map((deviceIndex) =>
                        Apply(deviceIndex, 'tiger')
                    )

                    Promise.allSettled([
                        baccaratStandardPromises,
                        baccaratLucky6Promises,
                        baccaratMega6Promises,
                        baccaratTigerPromises,
                    ]).then(() => {
                        setAlertDialogState({
                            show: 'true',
                            type: 'success',
                            message: 'Update successfully.',
                            loading: false,
                            error: 'false',
                        })
                        setSubmitted(false)
                    })
                },
            })
        }
    }

    const Apply = async (deviceIndex, gameVariant) => {
        const publish = usePublish('devices/' + deviceIndex)
        const device = devices.filter((device) => device.index === deviceIndex)[0]

        try {
            return publish({
                ...device.data,
                gamesLimit:
                    gameVariant === 'standard'
                        ? patterns.standard.gamesLimit
                        : gameVariant === 'lucky6'
                        ? patterns.lucky6.gamesLimit
                        : gameVariant === 'mega6'
                        ? patterns.mega6.gamesLimit
                        : gameVariant === 'tiger'
                        ? patterns.tiger.gamesLimit
                        : 100,
                trends: {
                    ...device.data.trends,
                    baccarat: {
                        ...device.data.trends.baccarat,
                        standard: {
                            dragonTriple: {
                                ...device.data.trends.baccarat.standard.dragonTriple,
                                showOnTrendboard: patterns.standard.trends.dragonTriple.active,
                            },
                            dragonDouble: {
                                ...device.data.trends.baccarat.standard.dragonDouble,
                                showOnTrendboard: patterns.standard.trends.dragonDouble.active,
                            },
                            dragonPlayer: {
                                ...device.data.trends.baccarat.standard.dragonPlayer,
                                showOnTrendboard: patterns.standard.trends.dragonPlayer.active,
                            },
                            dragonBanker: {
                                ...device.data.trends.baccarat.standard.dragonBanker,
                                showOnTrendboard: patterns.standard.trends.dragonBanker.active,
                            },
                            clapClapConnect: {
                                ...device.data.trends.baccarat.standard.clapClapConnect,
                                showOnTrendboard: patterns.standard.trends.clapClapConnect.active,
                            },
                            tieTriple: {
                                ...device.data.trends.baccarat.standard.tieTriple,
                                showOnTrendboard: patterns.standard.trends.tieTriple.active,
                            },
                            pingPongStreak: {
                                ...device.data.trends.baccarat.standard.pingPongStreak,
                                showOnTrendboard: patterns.standard.trends.pingPongStreak.active,
                            },
                            pingPongDouble: {
                                ...device.data.trends.baccarat.standard.pingPongDouble,
                                showOnTrendboard: patterns.standard.trends.pingPongDouble.active,
                            },
                            pairStreakPlayer: {
                                ...device.data.trends.baccarat.standard.pairStreakPlayer,
                                showOnTrendboard: patterns.standard.trends.pairStreakPlayer.active,
                            },
                            pairStreakBanker: {
                                ...device.data.trends.baccarat.standard.pairStreakBanker,
                                showOnTrendboard: patterns.standard.trends.pairStreakBanker.active,
                            },
                            tieDouble: {
                                ...device.data.trends.baccarat.standard.tieDouble,
                                showOnTrendboard: patterns.standard.trends.tieDouble.active,
                            },
                            oneLoungeTwoBedrooms: {
                                ...device.data.trends.baccarat.standard.oneLoungeTwoBedrooms,
                                showOnTrendboard: patterns.standard.trends.oneLoungeTwoBedrooms.active,
                            },
                            streakPlayer: {
                                ...device.data.trends.baccarat.standard.streakPlayer,
                                showOnTrendboard: patterns.standard.trends.streakPlayer.active,
                            },
                            streakBanker: {
                                ...device.data.trends.baccarat.standard.streakBanker,
                                showOnTrendboard: patterns.standard.trends.streakBanker.active,
                            },
                            doublePair: {
                                ...device.data.trends.baccarat.standard.doublePair,
                                showOnTrendboard: patterns.standard.trends.doublePair.active,
                            },
                            pingPong: {
                                ...device.data.trends.baccarat.standard.pingPong,
                                showOnTrendboard: patterns.standard.trends.pingPong.active,
                            },
                            tie: {
                                ...device.data.trends.baccarat.standard.tie,
                                showOnTrendboard: patterns.standard.trends.tie.active,
                            },
                            playerPair: {
                                ...device.data.trends.baccarat.standard.playerPair,
                                showOnTrendboard: patterns.standard.trends.playerPair.active,
                            },
                            bankerPair: {
                                ...device.data.trends.baccarat.standard.bankerPair,
                                showOnTrendboard: patterns.standard.trends.bankerPair.active,
                            },
                            playerWin: {
                                ...device.data.trends.baccarat.standard.playerWin,
                                showOnTrendboard: patterns.standard.trends.playerWin.active,
                            },
                            bankerWin: {
                                ...device.data.trends.baccarat.standard.bankerWin,
                                showOnTrendboard: patterns.standard.trends.bankerWin.active,
                            },
                        },
                        lucky6: {
                            dragonTriple: {
                                ...device.data.trends.baccarat.lucky6.dragonTriple,
                                showOnTrendboard: patterns.lucky6.trends.dragonTriple.active,
                            },
                            dragonDouble: {
                                ...device.data.trends.baccarat.lucky6.dragonDouble,
                                showOnTrendboard: patterns.lucky6.trends.dragonDouble.active,
                            },
                            dragonPlayer: {
                                ...device.data.trends.baccarat.lucky6.dragonPlayer,
                                showOnTrendboard: patterns.lucky6.trends.dragonPlayer.active,
                            },
                            dragonBanker: {
                                ...device.data.trends.baccarat.lucky6.dragonBanker,
                                showOnTrendboard: patterns.lucky6.trends.dragonBanker.active,
                            },
                            lucky6: {
                                ...device.data.trends.baccarat.lucky6.lucky6,
                                showOnTrendboard: patterns.lucky6.trends.lucky6.active,
                            },
                            clapClapConnect: {
                                ...device.data.trends.baccarat.lucky6.clapClapConnect,
                                showOnTrendboard: patterns.lucky6.trends.clapClapConnect.active,
                            },
                            tieTriple: {
                                ...device.data.trends.baccarat.lucky6.tieTriple,
                                showOnTrendboard: patterns.lucky6.trends.tieTriple.active,
                            },
                            pingPongStreak: {
                                ...device.data.trends.baccarat.lucky6.pingPongStreak,
                                showOnTrendboard: patterns.lucky6.trends.pingPongStreak.active,
                            },
                            pingPongDouble: {
                                ...device.data.trends.baccarat.lucky6.pingPongDouble,
                                showOnTrendboard: patterns.lucky6.trends.pingPongDouble.active,
                            },
                            pairStreakPlayer: {
                                ...device.data.trends.baccarat.lucky6.pairStreakPlayer,
                                showOnTrendboard: patterns.lucky6.trends.pairStreakPlayer.active,
                            },
                            pairStreakBanker: {
                                ...device.data.trends.baccarat.lucky6.pairStreakBanker,
                                showOnTrendboard: patterns.lucky6.trends.pairStreakBanker.active,
                            },
                            tieDouble: {
                                ...device.data.trends.baccarat.lucky6.tieDouble,
                                showOnTrendboard: patterns.lucky6.trends.tieDouble.active,
                            },
                            oneLoungeTwoBedrooms: {
                                ...device.data.trends.baccarat.lucky6.oneLoungeTwoBedrooms,
                                showOnTrendboard: patterns.lucky6.trends.oneLoungeTwoBedrooms.active,
                            },
                            streakPlayer: {
                                ...device.data.trends.baccarat.lucky6.streakPlayer,
                                showOnTrendboard: patterns.lucky6.trends.streakPlayer.active,
                            },
                            streakBanker: {
                                ...device.data.trends.baccarat.lucky6.streakBanker,
                                showOnTrendboard: patterns.lucky6.trends.streakBanker.active,
                            },
                            doublePair: {
                                ...device.data.trends.baccarat.lucky6.doublePair,
                                showOnTrendboard: patterns.lucky6.trends.doublePair.active,
                            },
                            pingPong: {
                                ...device.data.trends.baccarat.lucky6.pingPong,
                                showOnTrendboard: patterns.lucky6.trends.pingPong.active,
                            },
                            tie: {
                                ...device.data.trends.baccarat.lucky6.tie,
                                showOnTrendboard: patterns.lucky6.trends.tie.active,
                            },
                            playerPair: {
                                ...device.data.trends.baccarat.lucky6.playerPair,
                                showOnTrendboard: patterns.lucky6.trends.playerPair.active,
                            },
                            bankerPair: {
                                ...device.data.trends.baccarat.lucky6.bankerPair,
                                showOnTrendboard: patterns.lucky6.trends.bankerPair.active,
                            },
                            playerWin: {
                                ...device.data.trends.baccarat.lucky6.playerWin,
                                showOnTrendboard: patterns.lucky6.trends.playerWin.active,
                            },
                            bankerWin: {
                                ...device.data.trends.baccarat.lucky6.bankerWin,
                                showOnTrendboard: patterns.lucky6.trends.bankerWin.active,
                            },
                        },
                        mega6: {
                            dragonTriple: {
                                ...device.data.trends.baccarat.mega6.dragonTriple,
                                showOnTrendboard: patterns.mega6.trends.dragonTriple.active,
                            },
                            dragonDouble: {
                                ...device.data.trends.baccarat.mega6.dragonDouble,
                                showOnTrendboard: patterns.mega6.trends.dragonDouble.active,
                            },
                            dragonPlayer: {
                                ...device.data.trends.baccarat.mega6.dragonPlayer,
                                showOnTrendboard: patterns.mega6.trends.dragonPlayer.active,
                            },
                            dragonBanker: {
                                ...device.data.trends.baccarat.mega6.dragonBanker,
                                showOnTrendboard: patterns.mega6.trends.dragonBanker.active,
                            },
                            mega6: {
                                ...device.data.trends.baccarat.mega6.mega6,
                                showOnTrendboard: patterns.mega6.trends.mega6.active,
                            },
                            clapClapConnect: {
                                ...device.data.trends.baccarat.mega6.clapClapConnect,
                                showOnTrendboard: patterns.mega6.trends.clapClapConnect.active,
                            },
                            tieTriple: {
                                ...device.data.trends.baccarat.mega6.tieTriple,
                                showOnTrendboard: patterns.mega6.trends.tieTriple.active,
                            },
                            pingPongStreak: {
                                ...device.data.trends.baccarat.mega6.pingPongStreak,
                                showOnTrendboard: patterns.mega6.trends.pingPongStreak.active,
                            },
                            pingPongDouble: {
                                ...device.data.trends.baccarat.mega6.pingPongDouble,
                                showOnTrendboard: patterns.mega6.trends.pingPongDouble.active,
                            },
                            pairStreakPlayer: {
                                ...device.data.trends.baccarat.mega6.pairStreakPlayer,
                                showOnTrendboard: patterns.mega6.trends.pairStreakPlayer.active,
                            },
                            pairStreakBanker: {
                                ...device.data.trends.baccarat.mega6.pairStreakBanker,
                                showOnTrendboard: patterns.mega6.trends.pairStreakBanker.active,
                            },
                            tieDouble: {
                                ...device.data.trends.baccarat.mega6.tieDouble,
                                showOnTrendboard: patterns.mega6.trends.tieDouble.active,
                            },
                            oneLoungeTwoBedrooms: {
                                ...device.data.trends.baccarat.mega6.oneLoungeTwoBedrooms,
                                showOnTrendboard: patterns.mega6.trends.oneLoungeTwoBedrooms.active,
                            },
                            streakPlayer: {
                                ...device.data.trends.baccarat.mega6.streakPlayer,
                                showOnTrendboard: patterns.mega6.trends.streakPlayer.active,
                            },
                            streakBanker: {
                                ...device.data.trends.baccarat.mega6.streakBanker,
                                showOnTrendboard: patterns.mega6.trends.streakBanker.active,
                            },
                            doublePair: {
                                ...device.data.trends.baccarat.mega6.doublePair,
                                showOnTrendboard: patterns.mega6.trends.doublePair.active,
                            },
                            pingPong: {
                                ...device.data.trends.baccarat.mega6.pingPong,
                                showOnTrendboard: patterns.mega6.trends.pingPong.active,
                            },
                            tie: {
                                ...device.data.trends.baccarat.mega6.tie,
                                showOnTrendboard: patterns.mega6.trends.tie.active,
                            },
                            playerPair: {
                                ...device.data.trends.baccarat.mega6.playerPair,
                                showOnTrendboard: patterns.mega6.trends.playerPair.active,
                            },
                            bankerPair: {
                                ...device.data.trends.baccarat.mega6.bankerPair,
                                showOnTrendboard: patterns.mega6.trends.bankerPair.active,
                            },
                            playerWin: {
                                ...device.data.trends.baccarat.mega6.playerWin,
                                showOnTrendboard: patterns.mega6.trends.playerWin.active,
                            },
                            bankerWin: {
                                ...device.data.trends.baccarat.mega6.bankerWin,
                                showOnTrendboard: patterns.mega6.trends.bankerWin.active,
                            },
                        },
                        tiger: {
                            dragonTriple: {
                                ...device.data.trends.baccarat.tiger.dragonTriple,
                                showOnTrendboard: patterns.tiger.trends.dragonTriple.active,
                            },
                            dragonDouble: {
                                ...device.data.trends.baccarat.tiger.dragonDouble,
                                showOnTrendboard: patterns.tiger.trends.dragonDouble.active,
                            },
                            dragonPlayer: {
                                ...device.data.trends.baccarat.tiger.dragonPlayer,
                                showOnTrendboard: patterns.tiger.trends.dragonPlayer.active,
                            },
                            dragonBanker: {
                                ...device.data.trends.baccarat.tiger.dragonBanker,
                                showOnTrendboard: patterns.tiger.trends.dragonBanker.active,
                            },
                            tigerPairTwin: {
                                ...device.data.trends.baccarat.tiger.tigerPairTwin,
                                showOnTrendboard: patterns.tiger.trends.tigerPairTwin.active,
                            },
                            tigerBig: {
                                ...device.data.trends.baccarat.tiger.tigerBig,
                                showOnTrendboard: patterns.tiger.trends.tigerBig.active,
                            },
                            tigerSmall: {
                                ...device.data.trends.baccarat.tiger.tigerSmall,
                                showOnTrendboard: patterns.tiger.trends.tigerSmall.active,
                            },
                            clapClapConnect: {
                                ...device.data.trends.baccarat.tiger.clapClapConnect,
                                showOnTrendboard: patterns.tiger.trends.clapClapConnect.active,
                            },
                            tigerTieTriple: {
                                ...device.data.trends.baccarat.tiger.tigerTieTriple,
                                showOnTrendboard: patterns.tiger.trends.tigerTieTriple.active,
                            },
                            tieTriple: {
                                ...device.data.trends.baccarat.tiger.tieTriple,
                                showOnTrendboard: patterns.tiger.trends.tieTriple.active,
                            },
                            pingPongStreak: {
                                ...device.data.trends.baccarat.tiger.pingPongStreak,
                                showOnTrendboard: patterns.tiger.trends.pingPongStreak.active,
                            },
                            pingPongDouble: {
                                ...device.data.trends.baccarat.tiger.pingPongDouble,
                                showOnTrendboard: patterns.tiger.trends.pingPongDouble.active,
                            },
                            tigerPairStreak: {
                                ...device.data.trends.baccarat.tiger.tigerPairStreak,
                                showOnTrendboard: patterns.tiger.trends.tigerPairStreak.active,
                            },
                            tigerTieDouble: {
                                ...device.data.trends.baccarat.tiger.tigerTieDouble,
                                showOnTrendboard: patterns.tiger.trends.tigerTieDouble.active,
                            },
                            tieDouble: {
                                ...device.data.trends.baccarat.tiger.tieDouble,
                                showOnTrendboard: patterns.tiger.trends.tieDouble.active,
                            },
                            oneLoungeTwoBedrooms: {
                                ...device.data.trends.baccarat.tiger.oneLoungeTwoBedrooms,
                                showOnTrendboard: patterns.tiger.trends.oneLoungeTwoBedrooms.active,
                            },
                            streakBanker: {
                                ...device.data.trends.baccarat.tiger.streakBanker,
                                showOnTrendboard: patterns.tiger.trends.streakBanker.active,
                            },
                            streakPlayer: {
                                ...device.data.trends.baccarat.tiger.streakPlayer,
                                showOnTrendboard: patterns.tiger.trends.streakPlayer.active,
                            },
                            tigerPairDouble: {
                                ...device.data.trends.baccarat.tiger.tigerPairDouble,
                                showOnTrendboard: patterns.tiger.trends.tigerPairDouble.active,
                            },
                            pingPong: {
                                ...device.data.trends.baccarat.tiger.pingPong,
                                showOnTrendboard: patterns.tiger.trends.pingPong.active,
                            },
                            tigerTie: {
                                ...device.data.trends.baccarat.tiger.tigerTie,
                                showOnTrendboard: patterns.tiger.trends.tigerTie.active,
                            },
                            tie: {
                                ...device.data.trends.baccarat.tiger.tie,
                                showOnTrendboard: patterns.tiger.trends.tie.active,
                            },
                            tigerPair: {
                                ...device.data.trends.baccarat.tiger.tigerPair,
                                showOnTrendboard: patterns.tiger.trends.tigerPair.active,
                            },
                            playerWin: {
                                ...device.data.trends.baccarat.tiger.playerWin,
                                showOnTrendboard: patterns.tiger.trends.playerWin.active,
                            },
                            bankerWin: {
                                ...device.data.trends.baccarat.tiger.bankerWin,
                                showOnTrendboard: patterns.tiger.trends.bankerWin.active,
                            },
                        },
                    },
                },
                hotTrends: {
                    ...device.data.hotTrends,
                    baccarat: {
                        ...device.data.hotTrends.baccarat,
                        standard: {
                            ...device.data.hotTrends.baccarat.standard,
                            trends: patterns.standard.hotTrends,
                        },
                        lucky6: {
                            ...device.data.hotTrends.baccarat.lucky6,
                            trends: patterns.lucky6.hotTrends,
                        },
                        mega6: {
                            ...device.data.hotTrends.baccarat.mega6,
                            trends: patterns.mega6.hotTrends,
                        },
                        tiger: {
                            ...device.data.hotTrends.baccarat.tiger,
                            trends: patterns.tiger.hotTrends,
                        },
                    },
                },
                meters: {
                    ...device.data.meters,
                    baccarat: {
                        ...device.data.meters.baccarat,
                        standard: {
                            ...device.data.meters.baccarat.standard,
                            tieMeterMax: patterns.standard.meters.tieMeterMax,
                            bankerPairMeterMax: patterns.standard.meters.bankerPairMeterMax,
                            playerPairMeterMax: patterns.standard.meters.playerPairMeterMax,
                        },
                        lucky6: {
                            ...device.data.meters.baccarat.lucky6,
                            lucky6MeterMax: patterns.lucky6.meters.lucky6MeterMax,
                            tieMeterMax: patterns.lucky6.meters.tieMeterMax,
                            bankerPairMeterMax: patterns.lucky6.meters.bankerPairMeterMax,
                            playerPairMeterMax: patterns.lucky6.meters.playerPairMeterMax,
                        },
                        mega6: {
                            ...device.data.meters.baccarat.mega6,
                            mega6MeterMax: patterns.mega6.meters.mega6MeterMax,
                            tieMeterMax: patterns.mega6.meters.tieMeterMax,
                            bankerPairMeterMax: patterns.mega6.meters.bankerPairMeterMax,
                            playerPairMeterMax: patterns.mega6.meters.playerPairMeterMax,
                        },
                        tiger: {
                            ...device.data.meters.baccarat.tiger,
                            tieMeterMax: patterns.tiger.meters.tieMeterMax,
                            tigerMeterMax: patterns.tiger.meters.tigerMeterMax,
                            tigerTieMeterMax: patterns.tiger.meters.tigerTieMeterMax,
                            tigerPairMeterMax: patterns.tiger.meters.tigerPairMeterMax,
                            tigerBigMeterMax: patterns.tiger.meters.tigerBigMeterMax,
                            tigerSmallMeterMax: patterns.tiger.meters.tigerSmallMeterMax,
                        },
                    },
                },
                updatedBy: window.localStorage.getItem('account') ?? '-',
            })
        } catch (error) {
            console.log('error:', error)
        }
    }

    return (
        <Box
            sx={{
                height: '100%',
                padding: '2rem',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <Box display='flex' justifyContent='space-between' gap='2rem'>
                <Box>
                    <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                        Chase The Trend ETG
                    </Typography>
                </Box>

                <Button
                    variant='outlined'
                    onClick={() => {
                        setDisplayMoreInfo(true)
                    }}
                >
                    More Information
                </Button>
            </Box>

            <Tabs
                sx={{ backgroundColor: '#424242', mt: 2 }}
                value={value}
                onChange={handleChange}
                indicatorColor='secondary'
                textColor='secondary'
                aria-label='full width tabs example'
            >
                <Tab label='Trends' component={Link} to={'/xstadium/hottrends/trends'} />
                <Tab label='Settings' component={Link} to={'/xstadium/hottrends/settings'} />
            </Tabs>

            <Box sx={{ height: '100%', colorScheme: 'dark', overflow: 'auto' }}>
                <PerfectScrollbar>
                    {baccaratVariants.map((variant, index) => (
                        <Row
                            key={index}
                            variant={variant}
                            patterns={patterns[variant]}
                            setPatterns={setPatterns}
                            submitted={submitted}
                        />
                    ))}
                </PerfectScrollbar>
            </Box>

            <Box sx={{ mt: 2 }} display='flex' justifyContent='flex-end' gap='.5rem'>
                <Button variant='text' onClick={() => setToDefault()}>
                    Default
                </Button>

                <Button variant='contained' color='primary' onClick={() => batchApplyPrompt()}>
                    Save
                </Button>
            </Box>

            <MoreInfoDrawer displayMoreInfo={displayMoreInfo} setDisplayMoreInfo={setDisplayMoreInfo} />

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </Box>
    )
}

export default HotTrendOptions
