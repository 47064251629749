import React, { useState } from 'react'
import { isEmpty, startCase } from 'lodash'
import { Navigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import LinearProgress from '@mui/material/LinearProgress'
import SnackbarContent from '@mui/material/SnackbarContent'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { useSubscribe, usePublish } from '../api'
import { validate } from '../forms'
import { allProducts, xTableSections } from '../util/Products'
import View from '../View'

const rootStyles = makeStyles((theme) => ({
    formContainer: {
        margin: '0 auto',
        maxWidth: 728,
        display: 'flex',
        flexDirection: 'column',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em 0',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        padding: '4em 0',
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    selectControl: {
        marginBottom: 15,
    },
    formSubmit: {
        marginTop: 20,
        marginLeft: 10,
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    titleText: {
        marginTop: '1em',
    },
    sectionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.75em',
    },
    sectionHeader: {
        fontSize: '1.15em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    fieldRowWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5em',
    },
    toggleWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))

const Settings = ({ menuOpen }) => {
    const theme = useTheme()
    const lights = window.localStorage.getItem('lights') === 'on'
    const styles = rootStyles({ lights })

    const [settings, settingsSocket] = useSubscribe('settings')
    const publish = usePublish('settings')

    const active = settingsSocket && settingsSocket.readyState === WebSocket.OPEN

    const role = window.localStorage.getItem('role')

    // form fields
    const [products, setProducts] = useState([])
    const [sections, setSections] = useState([])
    const [xtableGameOnlyMode, setXtableGameOnlyMode] = useState(false)
    const [xviewGameOnlyMode, setXviewGameOnlyMode] = useState(false)
    const [xstadiumETGMode, setXstadiumETGMode] = useState(false)
    const [xstadiumHTHTMode, setXstadiumHTHTMode] = useState(false)

    // form flags
    const [updated, setUpdated] = useState(-1)
    const [submitted, setSubmitted] = useState(false)

    const [postStatus, setPostStatus] = useState(false)

    if (!settings || !active) {
        return <LinearProgress />
    }

    const fields = {
        products: {
            error: () => isEmpty(products),
            message: 'Please select at lease one product.',
        },
    }

    const validation = validate(fields)

    if (settings.updated !== updated) {
        setUpdated(settings.updated)
        setProducts(settings?.data?.products ? settings?.data?.products : [])
        setSections(settings?.data?.xtable?.sections ? settings?.data?.xtable?.sections : [])
        setXtableGameOnlyMode(settings?.data?.xtable?.gameOnlyMode ? settings?.data?.xtable?.gameOnlyMode : false)
        setXviewGameOnlyMode(settings?.data?.xview?.gameOnlyMode ? settings?.data?.xview?.gameOnlyMode : false)
        setXstadiumETGMode(settings?.data?.xstadium?.etgMode ? settings?.data?.xstadium?.etgMode : false)
        setXstadiumHTHTMode(
            settings?.data?.xstadium?.hotTrendsHotTablesMode ? settings?.data?.xstadium?.hotTrendsHotTablesMode : false
        )
    }

    const sectionsOrder = {
        overview: 1,
        statistics: 2,
        games: 3,
        trends: 4,
        view: 5,
        players: 6,
        slots: 7,
        dealers: 8,
    }

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            setPostStatus(true)
            try {
                await publish({
                    ...settings.data,
                    products: products,
                    xtable: {
                        sections: sections,
                        gameOnlyMode: xtableGameOnlyMode,
                    },
                    xview: {
                        gameOnlyMode: xviewGameOnlyMode,
                    },
                    xstadium: {
                        etgMode: xstadiumETGMode,
                        hotTrendsHotTablesMode: xstadiumHTHTMode,
                    },
                })
                setPostStatus('success')
                setTimeout(() => {
                    setPostStatus(false)
                }, 1000)
            } catch (e) {
                setPostStatus('failed')
                console.log(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setTimeout(() => {
                    setPostStatus(false)
                }, 1000)
            }
        }
    }

    if (role !== 'root' && role !== 'masterAdmin') {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            <Box key='main' component='main' sx={View(menuOpen, theme)}>
                <div className={styles.formContainer}>
                    <Typography variant='h4' className={styles.titleText}>
                        Configuration
                    </Typography>

                    {validation.error && submitted && (
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    )}

                    <form
                        key='product-list-configuration-form'
                        className={styles.form}
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}
                        autoComplete='off'
                    >
                        <Box>
                            <Box className={styles.fieldRowWrapper}>
                                <Box>
                                    <Typography>Product List</Typography>

                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        id='products-filter'
                                        key='products-filter'
                                        value={products}
                                        onChange={(e, value) => {
                                            setProducts(value)
                                        }}
                                        disableCloseOnSelect={false}
                                        options={allProducts}
                                        getOptionLabel={(option) => startCase(option)}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        filterSelectedOptions
                                        disabled={postStatus === true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant='outlined'
                                                error={submitted && fields.products.error()}
                                            />
                                        )}
                                    />

                                    <Divider sx={{ margin: '2em 0' }} />
                                </Box>
                            </Box>

                            <Box className={styles.sectionWrapper}>
                                <Typography className={styles.sectionHeader}>
                                    XTable and XTrend Configuration
                                </Typography>

                                <Box className={styles.fieldRowWrapper}>
                                    <Box>
                                        <Typography>XTable Sections</Typography>

                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            id='xtable-sections-filter'
                                            key='xtable-sections-filter'
                                            value={sections.sort((a, b) => sectionsOrder[a] - sectionsOrder[b])}
                                            onChange={(e, value) => {
                                                setSections(value)
                                            }}
                                            disableCloseOnSelect={false}
                                            options={xTableSections}
                                            getOptionLabel={(option) => startCase(option)}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            filterSelectedOptions
                                            disabled={postStatus === true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant='outlined'
                                                    disabled={postStatus === true}
                                                />
                                            )}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={startCase(option)}
                                                        {...getTagProps({ index })}
                                                        disabled={'trends'.indexOf(option) !== -1}
                                                    />
                                                ))
                                            }
                                            disableClearable
                                        />
                                    </Box>

                                    <FormGroup>
                                        <Box className={styles.toggleWrapper} component='label'>
                                            <Box>
                                                <Typography>
                                                    XTable and XTrend “Game-only mode”&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span style={{ fontSize: '15px' }}>
                                                                All bets and payout related data on XTrend and XTable
                                                                will be hidden on Game-only mode. This includes hiding
                                                                XTrend Device Settings “Payouts” section, XTable “Game”
                                                                section’s player data, Activity Charts and Activity
                                                                Logs.
                                                            </span>
                                                        }
                                                        placement='right'
                                                    >
                                                        <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                                    </Tooltip>
                                                </Typography>
                                            </Box>

                                            <FormControlLabel
                                                value='gameOnlyMode'
                                                control={
                                                    <Switch
                                                        checked={xtableGameOnlyMode}
                                                        onChange={(e) => {
                                                            setXtableGameOnlyMode(e.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'Game-only mode checkbox' }}
                                                        disabled={postStatus === true}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '2em 0' }} />
                                    </FormGroup>
                                </Box>
                            </Box>

                            <Box className={styles.sectionWrapper}>
                                <Typography className={styles.sectionHeader}>Dealer UI Configuration</Typography>

                                <Box className={styles.fieldRowWrapper}>
                                    <FormGroup>
                                        <Box className={styles.toggleWrapper} component='label'>
                                            <Box>
                                                <Typography>
                                                    Dealer UI “Game-only mode”&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span style={{ fontSize: '15px' }}>
                                                                Game-only mode will hide all player related features in
                                                                Dealer UI. These features include Seated Players section
                                                                and Table chip/fill.
                                                            </span>
                                                        }
                                                        placement='right'
                                                    >
                                                        <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                                    </Tooltip>
                                                </Typography>
                                            </Box>

                                            <FormControlLabel
                                                value='gameOnlyMode'
                                                control={
                                                    <Switch
                                                        checked={xviewGameOnlyMode}
                                                        onChange={(e) => {
                                                            setXviewGameOnlyMode(e.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'Game-only mode checkbox' }}
                                                        disabled={postStatus === true}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '2em 0' }} />
                                    </FormGroup>
                                </Box>
                            </Box>

                            <Box className={styles.sectionWrapper}>
                                <Typography className={styles.sectionHeader}>Xstadium Configuration</Typography>

                                <Box className={styles.fieldRowWrapper}>
                                    <FormGroup>
                                        <Box className={styles.toggleWrapper} component='label'>
                                            <Box>
                                                <Typography>
                                                    “Hot Trends Hot Tables” mode&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span style={{ fontSize: '15px' }}>
                                                                Toggle on if using third party Trendboards. Toggle off
                                                                if using IDX Trendboards.
                                                            </span>
                                                        }
                                                        placement='right'
                                                    >
                                                        <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                                    </Tooltip>
                                                </Typography>
                                            </Box>

                                            <FormControlLabel
                                                value='hotTrendsHotTablesMode'
                                                control={
                                                    <Switch
                                                        checked={xstadiumHTHTMode}
                                                        onChange={(e) => {
                                                            setXstadiumHTHTMode(e.target.checked)
                                                        }}
                                                        inputProps={{
                                                            'aria-label': 'Hot Trends Hot Tables mode checkbox',
                                                        }}
                                                        disabled={postStatus === true}
                                                    />
                                                }
                                            />
                                        </Box>
                                    </FormGroup>

                                    <FormGroup>
                                        <Box className={styles.toggleWrapper} component='label'>
                                            <Typography>XStadium ETG mode for game device settings&nbsp;</Typography>

                                            <FormControlLabel
                                                value='etgMode'
                                                control={
                                                    <Switch
                                                        checked={xstadiumETGMode}
                                                        onChange={(e) => {
                                                            setXstadiumETGMode(e.target.checked)
                                                        }}
                                                        inputProps={{ 'aria-label': 'ETG mode checkbox' }}
                                                        disabled={postStatus === true}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Divider sx={{ margin: '2em 0' }} />
                                    </FormGroup>
                                </Box>
                            </Box>
                        </Box>

                        <div key='buttonwrapper' className={styles.formButtonWrapper}>
                            <Button
                                className={styles.formSubmit}
                                variant='contained'
                                type='submit'
                                color='primary'
                                onClick={send}
                                disabled={postStatus}
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </div>
            </Box>
        </>
    )
}

export default Settings
