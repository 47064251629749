import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { modules } from '../config'

const styles = {
    twoLineEllipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },
}

const Home = () => {
    const navigate = useNavigate()
    const role = window.localStorage.getItem('role')
    const products = window.localStorage.getItem('products')

    return (
        <>
            <Grid container spacing={2} padding={2}>
                {(role === 'root' || products.includes('xTrend')) && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/xtrend.png' title='XTREND' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        XTREND
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Manage the casino floor devices and settings
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/xtrend')}>
                                        Go to XTREND
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || products.includes('xTable')) && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/xtable.png' title='XTABLE' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        XTABLE
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Monitor bets, games, players and dealers
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/xtable')}>
                                        Go to XTABLE
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || products.includes('xStadium')) && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/xstadium.png' title='XSTADIUM' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        XSTADIUM
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Display and show control system
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/xstadium')}>
                                        Go to XSTADIUM
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                <Grid item>
                    <Card sx={{ width: '21rem', height: '19rem' }}>
                        <CardMedia sx={{ height: '45%' }} image='/images/xuser.png' title='XUSER' />
                        <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                            <CardContent>
                                <Typography gutterBottom variant='h5' component='div'>
                                    XUSER
                                </Typography>
                                <Typography variant='body2' color='text.secondary'>
                                    Manage users and authentication policies
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size='small' onClick={() => navigate('/xuser')}>
                                    Go to XUSER
                                </Button>
                            </CardActions>
                        </Box>
                    </Card>
                </Grid>

                {(role === 'root' || products.includes('xSignage')) && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/xsignage.png' title='XSIGNAGE' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        XSIGNAGE
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Marketing video playlists stream management
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/xsignage')}>
                                        Go to XSIGNAGE
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || products.includes('xMeter')) && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/xmeter.png' title='XMETER' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        XMETER
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Trigger jackpots badges management
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/xmeter')}>
                                        Go to XMETER
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || role === 'masterAdmin') && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/dozzle.png' title='containers logs' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        LOGS
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Logs per container
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        size='small'
                                        onClick={() =>
                                            window.open('http://' + window.location.hostname + ':9999', '_blank')
                                        }
                                    >
                                        Go to LOGS
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || role === 'masterAdmin') && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/provectus.png' title='kafka monitoring' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        KAFKA MONITOR
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Kafka topics and subscribers monitoring
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        size='small'
                                        onClick={() =>
                                            window.open('http://' + window.location.hostname + ':8080', '_blank')
                                        }
                                    >
                                        Go to KAFKA MONITOR
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || role === 'masterAdmin') && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia
                                sx={{ height: '45%' }}
                                image='/images/glances.png'
                                title='host resources monitor'
                            />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        HOST MONITOR
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Host resources monitoring
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        size='small'
                                        onClick={() =>
                                            window.open('http://' + window.location.hostname + ':61208', '_blank')
                                        }
                                    >
                                        Go to HOST MONITOR
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || role === 'masterAdmin') && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/simulator.png' title='games simulator' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        GAMES SIMULATOR
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Games manual simulation and monitoring of game state
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        size='small'
                                        onClick={() =>
                                            window.open('http://' + window.location.host + '/simulator', '_blank')
                                        }
                                    >
                                        Go to GAMES SIMULATOR
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || role === 'masterAdmin') && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/x.png' title='xview chip result' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        XVIEW CHIP RESULTS
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Raw output of bets: chip stack image, chips value, bet type and seat number
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/xview')}>
                                        Go to XVIEW CHIP
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || role === 'masterAdmin') && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/x.png' title='xplayer' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        XPLAYER
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Create new and view existing players information. Manage players profile
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/xplayer')}>
                                        Go to XPLAYER
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || role === 'masterAdmin' || role === 'itSupport') && modules.updates && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/x.png' title='updates' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        UPDATES
                                    </Typography>
                                    <Tooltip
                                        title='Perform software and trendboard layout updates for various applications across
                                        selected game tables'
                                        followCursor
                                    >
                                        <Typography variant='body2' color='text.secondary' sx={styles.twoLineEllipsis}>
                                            Perform software and trendboard layout updates for various applications
                                            across selected game tables
                                        </Typography>
                                    </Tooltip>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/updates')}>
                                        Go to UPDATES
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {(role === 'root' || role === 'masterAdmin' || role === 'itSupport') && modules.license && (
                    <Grid item>
                        <Card sx={{ width: '21rem', height: '19rem' }}>
                            <CardMedia sx={{ height: '45%' }} image='/images/x.png' title='licenses' />
                            <Box height='55%' display='flex' flexDirection='column' justifyContent='space-between'>
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        LICENSES
                                    </Typography>
                                    <Tooltip
                                        title='Generate extension request files or upload extension files to renew application
                                        software license period'
                                        followCursor
                                    >
                                        <Typography variant='body2' color='text.secondary' sx={styles.twoLineEllipsis}>
                                            Generate extension request files or upload extension files to renew
                                            application software license period
                                        </Typography>
                                    </Tooltip>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => navigate('/licenses')}>
                                        Go to LICENSES
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default Home
