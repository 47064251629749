import React, { useState, useReducer } from 'react'
import { isEqual, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import DoneIcon from '@mui/icons-material/Done'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'

import AlertDialog from '../../xtrend/components/AlertDialog'
import Spinner from '../util/spinner'

const rootStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 8,
    border: "3px solid #333",
    fontSize: 30,
    fontWeight: "bold",
    color: "#CCC",
    opacity: 0.5,
    display: "grid",
    margin: 2,
    overflow: "hidden",
  },

  blue: {
    background: "transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)",
  },
  pink: {
    background: "transparent linear-gradient(180deg, #340404 0%, #FF4F4F 100%)",
  },
  green: {
    background: "transparent linear-gradient(180deg, #333 0%, #4fd736  100%)",
  },
  gray: {
    background: "transparent linear-gradient(180deg, #333 0%, #ccc 100%)",
  },
  tie: {},

  confirmBtn: {
    margin: 10,
    borderColor: "#333",
    backgroundColor: "#666",
    color: "#fff",
    opacity: 1,
    "&:hover": {
      backgroundColor: "#666",
    },
  },
  selected: {
    borderColor: "white",
    opacity: 1,
    color: "white",
  },
}));

const ManualGame = ({ publishBacRes, showMenu, extraStyles = {}, device }) => {
  const { t } = useTranslation("common");
  const s = rootStyles();

  // alert dialog state
  const [alertDialogState, setAlertDialogState] = useState({
      show: false,
      type: '',
      message: '',
      error: false,
      confirm: () => {},
  })

  const gameVariant = device ? device.data.gameVariant : ''

  const initialState = {
    bankerPair: { selected: false },
    playerPair: { selected: false },
    bankerWin: { selected: false },
    tie: { selected: false },
    playerWin: { selected: false },
    natural8: { selected: false },
    natural9: { selected: false },
    ...(gameVariant === 'lucky6' || gameVariant === 'lucky6Slot') && { lucky6: { selected: false } },
    ...gameVariant === 'mega6' && { mega6: { selected: false } },
    confirm: { selected: false },
  };
  console.log('initialState', initialState)
  const [btnState, setBtnState] = useState(initialState);
  const [, rerender] = useReducer((x) => (x ? false : true), false);

  const formattedData = () => {
    return Object.keys(btnState).reduce(
      (c, v, i) => {
        switch (v) {
          case "bankerPair":
            c.bankerPair = btnState[v].selected ? true : false;
            break;
          case "playerPair":
            c.playerPair = btnState[v].selected ? true : false;
            break;
          case "bankerWin":
            c.status = btnState[v].selected ? "B" : c.status;
            break;
          case "tie":
            c.status = btnState[v].selected ? "T" : c.status;
            break;
          case "playerWin":
            c.status = btnState[v].selected ? "P" : c.status;
            break;
          case "natural8":
            c.natural8 = btnState[v].selected ? true : c.natural8;
            break;
          case "natural9":
            c.natural9 = btnState[v].selected ? true : c.natural9;
            break;
          case "lucky6":
            c.lucky6_2 = btnState[v].selected ? true : false;
            break;
          case "mega6":
            c.mega6_2 = btnState[v].selected ? true : false;
            break;
          case "confirm":
            break;
          default:
            console.log("no object match");
        }
        return c;
        //}, { result: '', banker_pair: 0, player_pair: 0, natural8: 0, natural9: 0, lucky6: 0 })
      },
      {
        status: "",
        playerPair: false,
        bankerPair: false,
        natural8: false,
        natural9: false,
        ...(gameVariant === 'lucky6' || gameVariant === 'lucky6Slot') && { lucky6_2: false, lucky6_3: false },
        ...gameVariant === 'mega6' && { mega6_2: false, mega6_3: false },
      }
    );
  };

  const [postStatus, setPostStatus] = useState(false);
  const sendData = async () => {
    //noting selected, do nothing
    if (
      isEqual(
        omit(btnState, ["confirm", "playerPair", "bankerPair"]),
        omit(initialState, ["confirm", "playerPair", "bankerPair"])
      )
    ) {
      return;
    }

    try {
      let data = formattedData();
      console.log('editResultsPayload', data)
      await publishBacRes(data);
      setPostStatus(true);
      setPostStatus("success");
      setTimeout(() => {
        setBtnState(initialState);
        setPostStatus(false);
      }, 1000);
    } catch (e) {
      setPostStatus("failed");
      setAlertDialogState({
        show: true,
        type: 'reminder',
        message: `Games from previous deck cannot be edited.`,
        error: false,
      })
      setTimeout(() => {
        setBtnState(initialState);
        setPostStatus(false);
      }, 1000);
    }
  };

  const btnClick = (key) => {
    const buttonsForReset = [
        'bankerWin',
        'tie',
        'playerWin',
        'natural8',
        'natural9',
        ...((gameVariant === 'lucky6' || gameVariant === 'lucky6Slot') ? ['lucky6'] : []),
        ...(gameVariant === 'mega6' ? ['mega6'] : []),
    ]
    const buttonsForReset2 = [
        'tie',
        'natural8',
        'natural9',
        ...((gameVariant === 'lucky6' || gameVariant === 'lucky6Slot') ? ['lucky6'] : []),
        ...(gameVariant === 'mega6' ? ['mega6'] : []),
    ]
    const buttonsForReset3 = [
        'bankerWin',
        'tie',
        'playerWin',
        ...((gameVariant === 'lucky6' || gameVariant === 'lucky6Slot') ? ['lucky6'] : []),
        ...(gameVariant === 'mega6' ? ['mega6'] : []),
    ]
  
    console.log('buttonsForReset', buttonsForReset)
    console.log('buttonsForReset2', buttonsForReset2)
    console.log('buttonsForReset3', buttonsForReset3)

    switch (key) {
      case "bankerPair": {
        toggleSelect("natural9", false);
        toggleSelect("bankerPair", true);
        break;
      }
      case "playerPair": {
        toggleSelect("natural9", false);
        toggleSelect("playerPair", true);
        break;
      }
      case "bankerWin": {
        toggleSelect(buttonsForReset3, false);
        toggleSelect("bankerWin", true);
        break;
      }
      case "tie": {
        toggleSelect(buttonsForReset, false);
        toggleSelect("tie", true);
        break;
      }
      case "playerWin": {
        toggleSelect(buttonsForReset3, false);
        toggleSelect("playerWin", true);
        break;
      }
      case "natural8": {
        toggleSelect(buttonsForReset2, false);
        toggleSelect("natural8", true);
        break;
      }
      case "natural9": {
        toggleSelect(buttonsForReset2, false);
        toggleSelect(["bankerPair", "playerPair"], false);
        toggleSelect("natural9", true);
        break;
      }
      case "lucky6": {
        toggleSelect(buttonsForReset, false);
        toggleSelect(["lucky6", "bankerWin"], true);
        break;
      }
      case "mega6": {
        toggleSelect(buttonsForReset, false);
        toggleSelect(["mega6", "bankerWin"], true);
        break;
      }
      case "confirm": {
        toggleSelect("confirm", true);
        sendData();
        break;
      }
      default:
        console.log("unknown button click");
    }
    rerender();
  };

  const toggleSelect = (key, value) => {
    if (typeof key === "object" && key.length > 0) {
      key.forEach((k) => {
        toggleSelect(k, value);
      });
      return;
    }
    btnState[key].selected =
      typeof value !== "undefined"
        ? value
        : btnState[key].selected
        ? false
        : true;
  };

  const getNaturalColor = () => {
    return btnState["playerWin"].selected
      ? s.blue
      : btnState["bankerWin"].selected
      ? s.pink
      : s.gray;
  };

  const isSelected = (key) => {
    return btnState[key].selected ? s.selected : "";
  };

  const getClassName = (...k) => {
    return k.join(" ");
  };

  return (
    <>
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(24, 1fr)',
                gridTemplateRows: 'repeat(14, 1fr) 12vh',
                gridColumnGap: 5,
                gridRowGap: 5,
                ...extraStyles,
            }}
        >
            <Button
                variant='contained'
                onClick={(e) => {
                    btnClick('bankerPair')
                }}
                className={getClassName(s.button, s.pink, isSelected('bankerPair'))}
                style={{ gridRow: '1 / span 11', gridColumn: '1 / span 2' }}
            >
                {t('dealer.pair')}{' '}
            </Button>

            <Button
                variant='contained'
                onClick={(e) => {
                    btnClick('playerPair')
                }}
                className={getClassName(s.button, s.blue, isSelected('playerPair'))}
                style={{ gridRow: '1 / span 11', gridColumn: '23 / span 2' }}
            >
                {t('dealer.pair')}
            </Button>

            <Button
                variant='contained'
                onClick={(e) => {
                    btnClick('bankerWin')
                }}
                className={getClassName(s.button, s.pink, isSelected('bankerWin'))}
                style={{ gridRow: '1  / span 11', gridColumn: '3 / span 7' }}
            >
                {t('dealer.bankerWin')}
            </Button>

            <Button
                variant='contained'
                onClick={(e) => {
                    btnClick('tie')
                }}
                className={getClassName(s.button, s.green, s.tie, isSelected('tie'))}
                style={{ gridRow: '1  / span 11', gridColumn: '10 / span 6' }}
            >
                {t('dealer.tie')}
            </Button>

            <Button
                variant='contained'
                onClick={(e) => {
                    btnClick('playerWin')
                }}
                className={getClassName(s.button, s.blue, isSelected('playerWin'))}
                style={{ gridRow: '1  / span 11', gridColumn: '16 / span 7' }}
            >
                {t('dealer.playerWin')}
            </Button>

            {(gameVariant === 'lucky6' || gameVariant === 'lucky6Slot') && (
                <Button
                    variant='contained'
                    onClick={(e) => {
                        btnClick('lucky6')
                    }}
                    className={getClassName(s.button, s.pink, isSelected('lucky6'))}
                    style={{
                        gridRow: '12 / span 3',
                        gridColumn: '1 / span 6',
                        fontSize: 18,
                    }}
                >
                    {t('dealer.lucky6')}
                </Button>
            )}

            {gameVariant === 'mega6' && (
                <Button
                    variant='contained'
                    onClick={(e) => {
                        btnClick('mega6')
                    }}
                    className={getClassName(s.button, s.pink, isSelected('mega6'))}
                    style={{
                        gridRow: '12 / span 3',
                        gridColumn: '1 / span 6',
                        fontSize: 18,
                    }}
                >
                    MEGA 6
                </Button>
            )}

            <Button
                variant='contained'
                onClick={(e) => {
                    btnClick('natural8')
                }}
                className={getClassName(s.button, getNaturalColor(), isSelected('natural8'))}
                style={{ gridRow: '12 / span 3', gridColumn: '7 / span 9' }}
            >
                {t('dealer.natural8')}
            </Button>

            <Button
                variant='contained'
                onClick={(e) => {
                    btnClick('natural9')
                }}
                className={getClassName(s.button, getNaturalColor(), isSelected('natural9'))}
                style={{ gridRow: '12 / span 3', gridColumn: '16 / span 9' }}
            >
                {t('dealer.natural9')}
            </Button>

            <Button
                variant='contained'
                onClick={(e) => {
                    btnClick('confirm')
                }}
                className={getClassName(s.button, s.confirmBtn, isSelected('confirm'))}
                style={{
                    backgroundColor: postStatus === 'success' ? '#349922' : postStatus === 'failed' ? '#992222' : '#666',
                    borderColor: postStatus === 'success' ? '#349922' : postStatus === 'failed' ? '#992222' : '',
                    gridRow: '15  / span 1',
                    gridColumn: '1 / span 24',
                    height: '100%',
                    margin: '0 2px -2px 0',
                    width: '100%',
                    color: '#fff',
                    borderRadius: 4,
                    border: '3px solid #ccc',
                    fontSize: 20,
                    fontWeight: 'normal',
                }}
            >
                {postStatus === false && t('global.confirm')}
                {postStatus === true && <Spinner />}

                {postStatus === 'success' && <DoneIcon />}
                {postStatus === 'failed' && <ErrorOutlineIcon />}
            </Button>
        </div>
        
        <AlertDialog state={alertDialogState} setState={setAlertDialogState} />,
    </>
  );
};

export default ManualGame;
