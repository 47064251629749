import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

const VideoList = ({ videos, searchTerm, selectedVideo, setSelectedVideo }) => {
    const [videoPreview, setVideoPreview] = useState({})

    const filteredVideos = videos.filter((video) => video.data.name.indexOf(searchTerm) !== -1)

    const handleCheck = (video) => {
        setSelectedVideo((prev) => {
            const isExist = prev.find((prevItem) => prevItem.data.id === video.data.id)

            if (!isExist) {
                return [...prev, video]
            } else {
                const newState = prev.filter((prevItem) => prevItem.data.id !== isExist.data.id)

                return newState
            }
        })
    }

    const togglePreview = (index, e) => {
        setVideoPreview({
            [index]: !videoPreview[index],
        })
    }

    const isRowEmpty = videos.length === 0 || (videos.length > 1 && filteredVideos.length === 0)

    return (
        <>
            <Box>
                <List
                    sx={{
                        padding: 0,
                    }}
                >
                    <PerfectScrollbar>
                        {isRowEmpty && (
                            <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
                                <Typography>
                                    {videos.length === 0 && 'No video(s) found.'}
                                    {videos.length > 1 && filteredVideos.length === 0 && 'No match video(s) found.'}
                                </Typography>
                            </Box>
                        )}

                        {filteredVideos.map((video, index) => {
                            const isVideoChosen = selectedVideo
                                ? selectedVideo.find((v) => v.data.id === video.data.id)
                                : null

                            return (
                                <Box key={index}>
                                    <ListItem
                                        key={video.data.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: '1rem',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleCheck(video)}
                                    >
                                        <Box display='flex' alignItems='center' gap='.5rem'>
                                            <Checkbox checked={isVideoChosen ? true : false} />
                                            <Typography>{video.data.name}</Typography>
                                        </Box>

                                        <Box>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    togglePreview(video.index, e)
                                                }}
                                            >
                                                {videoPreview[video.index] ? (
                                                    <VisibilityIcon />
                                                ) : (
                                                    <VisibilityOutlinedIcon />
                                                )}
                                            </IconButton>
                                        </Box>
                                    </ListItem>

                                    {!!videoPreview[video.index] && (
                                        <Box
                                            sx={{
                                                margin: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <video
                                                type='video/webm'
                                                src={video.data.url}
                                                style={{ padding: 10, maxWidth: '50vh' }}
                                                controls
                                                preload='auto'
                                            />
                                        </Box>
                                    )}

                                    <Divider />
                                </Box>
                            )
                        })}
                    </PerfectScrollbar>
                </List>
            </Box>
        </>
    )
}

export default VideoList
