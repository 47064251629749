import React, { useState } from 'react'
import ky from 'ky'
import _ from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import { useSubscribe } from '../api'
import LoadingMessage from '../posui/LoadingMessage'
import useInterval from './../useInterval'

const PreChips = () => {
    const navigate = useNavigate()
    const deviceIp = window.localStorage.getItem('deviceIP')
    const visionIP = window.localStorage.getItem('visionIP')
    const selectedSeats = window.localStorage.getItem('selectedSeats')
    const [game, gameSocket] = useSubscribe(deviceIp + '/game')

    const active = gameSocket && gameSocket.readyState === WebSocket.OPEN

    if (!visionIP) {
        navigate('/xview/selectdevice')
    }

    if (!selectedSeats) {
        navigate('/xview/selectdevice')
    }

    if (!game || !active) {
        return <LoadingMessage message={'Connecting to Table Game Server'} />
    }

    return <Chips game={game} visionIP={visionIP} selectedSeats={selectedSeats} />
}

const query = async ({ qUrl }) => {
    try {
        const token = window.localStorage.getItem('token')
        const res = await ky
            .get(qUrl, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .json()
        return res
    } catch (e) {
        console.log('failed to q:', e, qUrl)
    }
}

const rootStyles = makeStyles((theme) => ({
    noScrollBar: {
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '1px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
        },
    },
}))

const Chips = ({ game, visionIP, selectedSeats }) => {
    const s = rootStyles()
    const [stackInfo, setStackInfo] = useState(null)
    const [gameUpdatedTime, setGameUpdatedTime] = useState(null)

    const getStackInfo = async () => {
        try {
            const res = await query({
                qUrl: `http://${visionIP}:1111/chip/stackinfo`,
            })
            const res2 = _.get(res, 'stacks', [])
            if (!_.isEqual(res2, stackInfo)) {
                console.log('stack data has changed, setstackinfo')
                console.log('stackInfo', stackInfo)
                console.log('res2', res2)
                setStackInfo(_.get(res, 'stacks', []))
            } else {
                console.log('stack data is same')
            }
        } catch (e) {
            console.log('failed to get infos ', e)
        }
    }
    console.log('selectedSeats', selectedSeats);

    if (!stackInfo) getStackInfo()

    ///change state every 5 seconds to refetch new data
    useInterval(() => {
        getStackInfo()
    }, 5000)

    if (game.updated !== gameUpdatedTime) {
        setGameUpdatedTime(game.updated)
        getStackInfo()
    }

    const formatCashString = (v) => {
        const nf = Intl.NumberFormat()
        return nf.format(Number(v))
    }

    // const stackinfo = [
    //     {
    //         image: '',
    //         count: [2, 3, 1, 4, 5, 3, 1, 2],
    //         bettype: 'tie',
    //         seat: 0,
    //     },
    //     {
    //         image: '',
    //         count: [2, 3, 1, 4, 5, 3, 1, 2],
    //         bettype: 'banker_pair',
    //         seat: 1,
    //     },
    //     {
    //         image: '',
    //         count: [2, 3, 1, 4, 5, 3, 1, 2],
    //         bettype: 'player_pair',
    //         seat: 1,
    //     },
    //     {
    //         image: '',
    //         count: [2, 3, 1, 4, 5, 3, 1, 2],
    //         bettype: 'lucky6',
    //         seat: 2,
    //     },
    //     {
    //         image: '',
    //         count: [2, 3, 1, 4, 5, 3, 1, 2],
    //         bettype: 'banker',
    //         seat: 3,
    //     },
    //     {
    //         image: '',
    //         count: [2, 3, 1, 4, 5, 3, 1, 2],
    //         bettype: 'player',
    //         seat: 3,
    //     },
    //     {
    //         image: '',
    //         count: [2, 3, 1, 4, 5, 3, 1, 2],
    //         bettype: 'tie',
    //         seat: 1,
    //     },
    //     {
    //         image: '',
    //         count: [2, 3, 1, 4, 5, 3, 1, 2],
    //         bettype: 'lucky6',
    //         seat: 1,
    //     },
    // ]

    const stackValues = [
        5, //stack.Counts.Red,
        10, //stack.Counts.Blue,
        25, //stack.Counts.Green,
        100, //stack.Counts.Black
        500, //stack.Counts.Purple,
        1000, //stack.Counts.Cyan,
        5000, //stack.Counts.Orange,
        10000, //stack.Counts.White,
    ]

    const stackValuesShortForm = [
        '5', //stack.Counts.Red,
        '10', //stack.Counts.Blue,
        '25', //stack.Counts.Green,
        '100', //stack.Counts.Black
        '500', //stack.Counts.Purple,
        '1k', //stack.Counts.Cyan,
        '5k', //stack.Counts.Orange,
        '10k', //stack.Counts.White,
    ]

    const chipColors = ['red', 'blue', 'green', 'black', 'purple', 'turquoise', 'orange', 'white']

    const betTypes = {
        banker_pair: 'Banker Pair',
        player_pair: 'Player Pair',
        lucky6: 'Lucky 6',
        tie: 'Tie',
        banker: 'Banker',
        player: 'Player',
        bonus: 'Bonus',
        base: 'Base',
        doubledown: 'Double Down',
        insurance: 'Insurance',
        pair: 'Pair',
        split: 'Split',
        anypair: 'Any Pair',
    }

    const betTypesOrder = {
        player: 1,
        banker: 2,
        tie: 3,
        lucky6: 4,
        banker_pair: 5,
        player_pair: 6,
        bonus: 7,
        base: 8,
        doubledown: 9,
        insurance: 10,
        pair: 11,
        split: 12,
        anypair: 13,
    }

    const showSeatChips = (id) =>
        stackInfo
            .filter((v) => v.seat === id)
            .sort((a, b) => betTypesOrder[a.bettype] - betTypesOrder[b.bettype])
            .map((v, i) => (
                <div key={i} style={{ margin: 5, marginBottom: 30 }}>
                    <div style={{ margin: '10px 0' }}>
                        <span style={{ fontSize: '20px', backgroundColor: 'black', color: 'white', padding: 5 }}>
                            {betTypes[v.bettype]}
                        </span>
                    </div>
                    <div style={{ margin: '10px 0', display: 'flex' }}>
                        <div
                            style={{
                                background: 'black',
                                boxSizing: 'border-box',
                                color: 'white',
                                fontSize: 28,
                                padding: 20,
                            }}
                        >
                            {v.bettype === 'insurance' && stackInfo.find((v) => v.bettype === 'base') && (
                                <>
                                    $
                                    {formatCashString(
                                        stackInfo
                                            .find((v) => v.bettype === 'base')
                                            .count.reduce((c, v, i) => c + v * stackValues[i], 0) / 2
                                    )}
                                </>
                            )}
                            {v.bettype !== 'insurance' && (
                                <>${formatCashString(v.count.reduce((c, v, i) => c + v * stackValues[i], 0))}</>
                            )}
                        </div>
                        {v.bettype !== 'insurance' && (
                            <img alt='' key={i} src={v.image} style={{ height: '75px', width: '75px', margin: 5 }} />
                        )}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'left', gap: 10, flexWrap: 'wrap' }}>
                        {v.bettype !== 'insurance' &&
                            v.count.map((c, i) =>
                                c < 1 ? (
                                    <div key={i} style={{ display: 'none' }}></div>
                                ) : (
                                    <div
                                        key={i}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            marginTop: 10,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: 30,
                                                width: 30,
                                                borderRadius: '50%',
                                                backgroundColor: '#444',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '10px solid ' + chipColors[i],
                                                color: 'gold',
                                                padding: '1rem',
                                            }}
                                        >
                                            {stackValuesShortForm[i]}
                                        </div>
                                        <div style={{ fontSize: '25px', marginLeft: '5px' }}>x{c}</div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            ))

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    gap: 10,
                    height: '100%',
                    position: 'relative',
                    padding: 10,
                    boxSizing: 'border-box',
                }}
            >
                {new Array(Number(selectedSeats)).fill(0).map((v, i) => (
                    <div
                        key={i}
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#666',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        className={s.noScrollBar}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
                            <div
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    height: '50px',
                                    width: '50px',
                                    borderRadius: '25px',
                                    fontSize: '35px',
                                    textAlign: 'center',
                                }}
                            >
                                {i + 1 < 4 ? i + 1 : i + 2}
                            </div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#666',
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'auto',
                            }}
                        >
                            <PerfectScrollbar>{stackInfo && stackInfo.length > 0 && showSeatChips(i)}</PerfectScrollbar>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PreChips
