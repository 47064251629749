import SearchIcon from '@mui/icons-material/Search'
import React, { useState, Fragment } from 'react'
import { unpublish } from '../../api'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'

import Toolbar from '@mui/material/Toolbar'
import InputBase from '@mui/material/InputBase'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'

const madeStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
    },
    searchToolbar: {
        display: 'flex'
    },
    list: {
        padding: 0
    },
    listItem: {
        background: '#3e3e3e',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            background: '#353535',
        }
    },
    listHeaderText: {
        overflowWrap: 'break-word'
    },
    videoPreview: {
        maxWidth: 400,
    },
    removeButton: {
        background: theme.palette.error.main,
        '&:hover': {
            background: '#bf3328',
        }
    },
    formDelete: {
        marginTop: 20,
        marginLeft: 10,
        background: theme.palette.error.main,
        '&:hover': {
            background: '#bf3328',
        }
    },
}))


const JackpotList = ({ jackpots, editDialog, authorize }) => {
    const styles = madeStyles()
    const [searchTerm, setSearchTerm] = useState('')
    const [removing, setRemoving] = useState(null)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleClick = (selected) => {
        editDialog(selected)
    }

    const onRemoveClick = (e, selected) => {
        e.stopPropagation()
        setRemoving(selected)
        setConfirmDelete(true)
    }

    const remove = async () => {
        setLoading(true)
        try {
            await unpublish('jackpots/' + removing.index, authorize)
        } catch (e) {
            console.warn("failed to delete jackpot", e)
        }
        setLoading(false)
        setConfirmDelete(false)
        setRemoving(null)
    }

    const filteredJackpots = jackpots.filter(jackpot => jackpot.data.name.indexOf(searchTerm) !== -1)

    return <Paper className={styles.root} elevation={1}>
        <Dialog
            key="dialogDelete"
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {(removing && !loading) && <DialogTitle id="alert-dialog-title">Do you want to delete the jackpot <b>{removing.data.name}</b>?</DialogTitle>}
            {loading && <LinearProgress />}
            {!loading && <DialogActions>
                <Button onClick={() => setConfirmDelete(false)} color="primary" key="delete-no">
                    No
                </Button>
                <Button onClick={remove} key="delete-yes">
                    Yes
                </Button>
            </DialogActions>}
        </Dialog>
        <Toolbar className={styles.searchToolbar}>
            <SearchIcon />
            <InputBase
                placeholder="Search (name - level)"
                inputProps={{ 'aria-label': 'search' }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </Toolbar>
        <List className={styles.list} component="nav">
            {filteredJackpots.map(jackpot =>
                <Fragment key={jackpot.index}>
                    <ListItem className={styles.listItem} key={jackpot.index} onClick={() => handleClick(jackpot)}>
                        <span></span>
                        <div>{jackpot.data.name} - {jackpot.data.level}</div>
                        <Button className={styles.removeButton}
                            onClick={(e) => onRemoveClick(e, jackpot)}>
                            Remove
                        </Button>
                    </ListItem>
                    <Divider />
                </Fragment>
            )}
        </List>
    </Paper>
}
export default JackpotList