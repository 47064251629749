import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'

const HotTables = ({ hotTablesDisplay, setHotTablesDisplay }) => {
    return (
        <Box sx={{ background: '#333333', padding: '1rem' }}>
            <FormControl>
                <Typography>No. of tables to show for Hot Tables display</Typography>

                <Select
                    labelId='hot-tables-display'
                    id='hot-tables-display'
                    value={hotTablesDisplay}
                    onChange={(e) => setHotTablesDisplay(e.target.value)}
                    size='small'
                >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export default HotTables
