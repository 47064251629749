import React, { useState } from 'react'

import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Fab, Tooltip, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

import { useSubscribe } from '../api'
import BacSimulator from './BacSimulator'
import BJSimulator from './BJSimulator'
import WarSimulator from './WarSimulator'

const Simulators = () => {
    // socket
    const [devices, socket] = useSubscribe('devices/*')
    const active = socket && socket.readyState === WebSocket.OPEN

    const [gameType, setGameType] = useState('');

    if (!devices || !active) {
        return (<LinearProgress />)
    }

    console.log(devices);
    let bacs = []
    let bjs = []

    devices.map(device =>
        (device.data.open && device.data.type === 'game') &&
            device.data.gameType === 'blackjack' ? bjs.push(device) :
            device.data.gameType === 'baccarat' ? bacs.push(device) : null)

    const warEtgDevices = devices.filter(dev =>
        dev.data.open && dev.data.type === 'game' &&
        dev.data.gameType === 'war' &&
        dev.data.gameVariant === 'etg')

    console.log(gameType);

    return (
        <>

            {(gameType !== '') ?
                <Box width={'100%'}>
                    <Typography size="large">Current Simulator : {gameType.toUpperCase()}</Typography>
                    {gameType === "baccarat" && <BacSimulator devices={bacs} />}
                    {gameType === "blackjack" && <BJSimulator devices={bjs} />}
                    {gameType === "war" && <WarSimulator devices={warEtgDevices} />}/
                    <Tooltip open={true} title={`current : ${gameType.toUpperCase()}`}>
                        <Fab sx={{ margin: 0, right: '5%', bottom: '10%', position: 'fixed' }}
                            variant="extended" color="success" size="large" href='/simulator' disableRipple disableTouchRipple disableFocusRipple>
                            Back to Simulators
                        </Fab>
                    </Tooltip>
                </Box> : null}

            {(gameType === '') &&
                <Container sx={{
                    p: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                    alignItems: 'flex-start'
                }}>

                    <Card sx={{ height: '420px', padding: 2, margin: 2 }}>
                        <CardMedia
                            sx={{ height: 240 }}
                            image="images/baccarat.png"
                            title="baccarat"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Baccarat
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => setGameType("baccarat")} variant='outlined' size="large">Simulate Baccarat Game</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ height: '420px', padding: 2, margin: 2 }}>
                        <CardMedia
                            sx={{ height: 240 }}
                            image="images/blackjack.png"
                            title="Blackjack"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Blackjack
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => setGameType("blackjack")} variant='outlined' size="large">Simulate blackjack Game</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ height: '420px', padding: 2, margin: 2 }}>
                        <CardMedia
                            sx={{ height: 240 }}
                            image="images/war.png"
                            title="baccarat"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                War
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => setGameType("war")} variant='outlined' size="large">Simulate War Game</Button>
                        </CardActions>
                    </Card>
                </Container>
            }

        </>
    )
}
export default Simulators