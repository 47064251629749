import React, { useContext, useState } from "react"
import { DUIContext } from "./Dealer"

const BetTimer = ({ time }) => {
    const {
        betTimer,
        setBetTimer,
        device,
        deviceData,
        game,
        noMoreBetsPress,
        restartBetTimerPress,
        stopBetTimerPress,
        hasGameState
    } = useContext(DUIContext)
    const NanoToSeconds = (time) => Math.floor(time / 1000000000)
    const sec = Math.floor(time / 1000000000)
    const [deviceUpdatedTime, setDeviceUpdatedTime] = useState(0)
    const [timeLastReduced, setTimeLastReduced] = useState(sec)

    //['started', 'restarted','new','stopped','counting']

    const getBetCountDown = () => {
        if (betTimer.stop || !hasGameState) return ''
        if (betTimer.start > 0 && betTimer.countdown > 0) {
            const countdown = betTimer.bet_timer - (betTimer.countdown - betTimer.start)
            if (countdown === 0 && !game.data.betsoff) {
                noMoreBetsPress()
                stopBetTimerPress()
                setBetTimer({ action: 'stop' })
            }
            return countdown > 0 ? ' (' + countdown + ')' : ''
        }
        return ''
    }

    if (hasGameState && NanoToSeconds(game.data.timerRestarted) !== betTimer.start && !game.data.timerStop) {
        setBetTimer({ action: 'setStart' })
        return <></>
    }

    if (hasGameState && game.data.timerStop && betTimer.status !== 'stopped') {
        setBetTimer({ action: 'stop' })
        return <></>
    }


    if (device.updated !== deviceUpdatedTime && betTimer.bet_timer !== Number(device.data.bet_timer)) {
        setBetTimer({ action: 'timerChange' })
        setDeviceUpdatedTime(device.updated)
        return <></>
    }

    // auto start countdown on a new hand, except when chipless mode
    if (betTimer.status === 'new' && !deviceData.timerModeChipless && hasGameState && game.data.status !== "ongoing" && !game.data.betsoff && game.data.timerRestarted === 0) {
        restartBetTimerPress()
        setBetTimer({ action: 'start' })
        return <></>
    }

    if (['started', 'counting'].includes(betTimer.status) && hasGameState && game.data.status !== "ongoing" && !game.data.betsoff) {
        if (timeLastReduced !== sec) {
            setTimeLastReduced(sec)
            setBetTimer({ action: 'reduce1' })
        }
    }



    return <>{getBetCountDown()}</>

}

export default BetTimer
