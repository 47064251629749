import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import { LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { makeStyles } from '@mui/styles'
import { DeviceSelect } from '../../../forms'
import { useSubscribe } from '../../../api'

const rootStyles = makeStyles((theme) => ({
    form: {
        width: 700,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
}))

const Devices = ({ publish, device, xstadium }) => {
    const [devices, devicesSocket] = useSubscribe('devices/*')

    const active = devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN


    const styles = rootStyles({ xstadium })
    const navigate = useNavigate()

    // fields
    const [deviceA, setDeviceA] = useState('')
    const [deviceB, setDeviceB] = useState('')
    const [deviceC, setDeviceC] = useState('')
    const [deviceD, setDeviceD] = useState('')
    const [deviceE, setDeviceE] = useState('')
    const [deviceF, setDeviceF] = useState('')
    const [deviceG, setDeviceG] = useState('')
    const [deviceH, setDeviceH] = useState('')

    // flags
    const [loading, setLoading] = useState(false)
    const [fail, setFail] = useState(false)
    const [fetched, setFetched] = useState(false)

    const devicesFiltered = devices ? devices.filter((e) => e.data.type === 'game') : []

    const send = async () => {
        setLoading(true)
        try {
            await publish({
                ...device.data,
                deviceA,
                deviceB,
                deviceC,
                deviceD,
                deviceE,
                deviceF,
                deviceG,
                deviceH,
            })
            setFail('')
            setLoading(false)
        } catch (e) {
            setFail(
                `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            setLoading(false)
        }
    }

    const fillFields = () => {
        if (fetched) {
            return
        }
        setFetched(true)
        setDeviceA(device.data.deviceA)
        setDeviceB(device.data.deviceB)
        setDeviceC(device.data.deviceC)
        setDeviceD(device.data.deviceD)
        setDeviceE(device.data.deviceE)
        setDeviceF(device.data.deviceF)
        setDeviceG(device.data.deviceG)
        setDeviceH(device.data.deviceH)
    }

    if (!active ||
        !devices) {
        return <LinearProgress />
    }

    if (!fetched) {
        fillFields()
    }

    return (
        <form
            className={styles.form}
            noValidate
            onSubmit={(e) => {
                e.preventDefault()
            }}
            autoComplete='off'
        >
            {fail && !loading && (
                <Box className={styles.warningContainer}>
                    <SnackbarContent
                        className={styles.fail}
                        message={
                            <Typography component='p' className={styles.warningText}>
                                <WarningIcon className={styles.warningIcon} /> {fail}
                            </Typography>
                        }
                    />
                </Box>
            )}

            <Box>
                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                    Device A
                </Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <DeviceSelect devices={devicesFiltered}
                        name={'deviceA'}
                        value={deviceA}
                        setValue={setDeviceA}
                        publishing={loading} />
                </Stack>
            </Box>

            <Box>
                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                    Device B
                </Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <DeviceSelect devices={devicesFiltered}
                        name={'deviceB'}
                        value={deviceB}
                        setValue={setDeviceB}
                        publishing={loading} />
                </Stack>
            </Box>

            <Box>
                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                    Device C
                </Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <DeviceSelect devices={devicesFiltered}
                        name={'deviceC'}
                        value={deviceC}
                        setValue={setDeviceC}
                        publishing={loading} />
                </Stack>
            </Box>

            <Box>
                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                    Device D
                </Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <DeviceSelect devices={devicesFiltered}
                        name={'deviceD'}
                        value={deviceD}
                        setValue={setDeviceD}
                        publishing={loading} />
                </Stack>
            </Box>

            <Box>
                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                    Device E
                </Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <DeviceSelect devices={devicesFiltered}
                        name={'deviceE'}
                        value={deviceE}
                        setValue={setDeviceE}
                        publishing={loading} />
                </Stack>
            </Box>

            <Box>
                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                    Device F
                </Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <DeviceSelect devices={devicesFiltered}
                        name={'deviceF'}
                        value={deviceF}
                        setValue={setDeviceF}
                        publishing={loading} />
                </Stack>
            </Box>

            <Box>
                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                    Device G
                </Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <DeviceSelect devices={devicesFiltered}
                        name={'deviceG'}
                        value={deviceG}
                        setValue={setDeviceG}
                        publishing={loading} />
                </Stack>
            </Box>

            <Box>
                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                    Device H
                </Typography>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <DeviceSelect devices={devicesFiltered}
                        name={'deviceH'}
                        value={deviceH}
                        setValue={setDeviceH}
                        publishing={loading} />
                </Stack>
            </Box>

            <div className={styles.formButtonWrapper}>
                <Button
                    color='inherit'
                    disabled={loading}
                    disableRipple
                    onClick={() => {
                        navigate(
                            !xstadium
                                ? device.data.containerType === 'casino'
                                    ? `/xtrend/${device.data.containerType}`
                                    : `/xtrend/${device.data.containerType}/${device.data.containerIndex}`
                                : device.data.type === 'game'
                                    ? '/xstadium/tables'
                                    : '/xstadium/servers'
                        )
                    }}
                >
                    Back
                </Button>


                <Button variant='contained' type='button' color='primary' disabled={loading} onClick={send}>
                    Update
                </Button>
            </div>
        </form>
    )
}

export default Devices