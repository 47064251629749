import React, { memo } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import R404 from '../404'
import Players from './Players'

export default memo(() => {
    const role = window.localStorage.getItem('role')

    if (role !== 'root' && role !== 'masterAdmin') {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            <Routes>
                <Route exact path='/' element={<Navigate to='/xplayer/players' replace />} />
                <Route exact path='/players' element={<Players />} />
                <Route path='*' element={<R404 basePath='xplayer/' />} />
            </Routes>
        </>
    )
})
