import React, { useCallback } from 'react'
import update from 'immutability-helper'
import List from '@mui/material/List'

import PlaylistVideo from './PlaylistVideo'

const PlaylistVideos = ({ videos, setVideos }) => {
    const moveVideo = useCallback(
        (dragIndex, hoverIndex) => {
            const dragVideo = videos[dragIndex]
            setVideos(
                update(videos, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragVideo],
                    ],
                })
            )
        },
        [videos, setVideos]
    )

    return (
        <List sx={{ display: 'flex', flexDirection: 'column', gap: '.75rem' }}>
            {videos.map((video, index) => (
                <PlaylistVideo key={index} index={index} id={video.index} text={video.data.name} move={moveVideo} />
            ))}
        </List>
    )
}

export default PlaylistVideos
