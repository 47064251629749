import React, { useReducer } from "react";
import { keys } from "lodash";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const Configurations = ({ setShowConfigurations }) => {
  const [config, setConfig] = useReducer((s, o) => {
    return { ...s, ...o };
  }, JSON.parse(window.localStorage.getItem("config")));

  const saveConfig = () => {
    window.localStorage.setItem("config", JSON.stringify(config));
    window.localStorage.setItem("immutedConfig", true);
    window.location.reload();
  };

  const hardRefresh = () => {
    window.location.reload(true);
  };

  const clear = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  console.log(config);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{
        height: "100vh",
        width: "100vw",
        overflowY: "scroll",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 999999,
        background: "#333",
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 20,
          gap: 20,
        }}
      >
        <Button
          style={{ width: "100px" }}
          variant="contained"
          color="primary"
          onClick={() => saveConfig()}
        >
          Save
        </Button>

        <Button
          style={{ width: "100px" }}
          variant="contained"
          color="primary"
          onClick={() => clear()}
        >
          Clear
        </Button>

        <Button
          style={{ width: "100px" }}
          variant="contained"
          color="primary"
          onClick={() => hardRefresh()}
        >
          Refresh
        </Button>

        <Button
          style={{ width: "100px" }}
          variant="contained"
          color="primary"
          onClick={() => setShowConfigurations(false)}
        >
          Close
        </Button>
      </div>

      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            gap: 20,
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 100,
          }}
        >
          {keys(config)
            .filter(
              (v) =>
                typeof config[v] === "boolean" ||
                (typeof config[v] !== "boolean" &&
                  typeof config[v] !== "object")
            )
            .map((v, i) => (
              <div key={i} style={{ width: 400 }}>
                {typeof config[v] === "boolean" && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={config[v]}
                        onChange={(e) => setConfig({ [v]: e.target.checked })}
                      />
                    }
                    label={v + ": " + (config[v] ? "true" : "false")}
                  />
                )}

                {typeof config[v] !== "boolean" &&
                  typeof config[v] !== "object" && (
                    <TextField
                      style={{ width: "100%" }}
                      margin="dense"
                      label={v}
                      type="text"
                      variant="outlined"
                      onChange={(e) => setConfig({ [v]: e.target.value })}
                      value={config[v]}
                    />
                  )}
              </div>
            ))}
        </div>

        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            gap: 20,
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 100,
          }}
        >
          {keys(config)
            .filter((v) => typeof config[v] === "object")
            .map((v, i) => (
              <div key={i} style={{ width: 400 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 20,
                  }}
                >
                  <div>{v}:</div>
                  {keys(config[v]).map((v1, i) => (
                    <div key={i} style={{ marginLeft: 50 }}>
                      {typeof config[v][v1] === "boolean" && (
                        <FormControlLabel
                          value=""
                          control={
                            <Switch
                              checked={config[v][v1]}
                              onChange={(e) =>
                                setConfig({
                                  [v]: { ...config[v], [v1]: e.target.checked },
                                })
                              }
                            />
                          }
                          label={
                            <>
                              {v1 + ": "}
                              {config[v][v1] && (
                                <span style={{ color: "limegreen" }}>True</span>
                              )}
                              {!config[v][v1] && (
                                <span style={{ color: "red" }}>False</span>
                              )}
                            </>
                          }
                        />
                      )}

                      {typeof config[v][v1] !== "boolean" && (
                        <TextField
                          style={{ width: "100%" }}
                          margin="dense"
                          label={v + "." + v1}
                          type="text"
                          variant="outlined"
                          onChange={(e) =>
                            setConfig({
                              [v]: { ...config[v], [v1]: e.target.value },
                            })
                          }
                          value={config[v][v1]}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Configurations;
