import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'

import { usePublish, useSubscribe } from '../../api'
import { xstadiumIP } from '../../config'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            colorScheme: 'dark',
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const CustomTab = withStyles({
    root: {
        backgroundColor: '#343434',
        borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
    },
    selected: {
        backgroundColor: 'rgb(0, 161, 255)',
    },
})(Tab)

const styles = {
    form: {
        width: '100%',
        padding: '2rem',
    },
}

const DefaultPlaylist = () => {
    const [status, statusSocket] = useSubscribe(xstadiumIP + '/watchout/status')

    const [defaultPlaylist, defaultSocket] = useSubscribe(xstadiumIP + '/watchout/default')
    const publish = usePublish(xstadiumIP + '/watchout/default')

    const active =
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        defaultSocket &&
        defaultSocket.readyState === WebSocket.OPEN

    // tabs
    const [layout, setLayout] = useState('')
    const [background, setBackground] = useState('')
    const [music, setMusic] = useState('')

    const [firstLoad, setFirstLoad] = useState(true)
    const [publishing, setPublishing] = useState(false)

    if (!active || !status || !defaultPlaylist) {
        return <LinearProgress />
    }

    const setFields = () => {
        if (!firstLoad) {
            return
        }

        setFirstLoad(false)
        setLayout(defaultPlaylist.data.layout || '')
        setBackground(defaultPlaylist.data.background || '')
        setMusic(defaultPlaylist.data.music || '')
    }

    setFields()

    const submitForm = () => {
        setPublishing(true)

        try {
            publish({
                layout: layout,
                background: background,
                music: music,
            })
        } catch (e) {
            console.warn('failed to submit form: ', e)
        } finally {
            setPublishing(false)
        }
    }

    return (
        <Grid item xs>
            <form
                style={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {publishing && <LinearProgress />}

                <Box display='flex' flexDirection='column' gap='1.25rem'>
                    <Box>
                        <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                            Settings
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            Select tab to configure the corresponding features of the application
                        </Typography>
                    </Box>

                    <Tabs
                        sx={{
                            background: '#2a2a2a',
                            borderBottom: '2px solid rgb(0, 161, 255)',
                        }}
                        value={0}
                        textColor='inherit'
                        indicatorColor='rgb(0, 161, 255)'
                    >
                        <CustomTab
                            label='Default Playlist'
                            component={Link}
                            to={'/xstadium/settings/defaultplaylist'}
                        />
                        <CustomTab label='Support' component={Link} to={'/xstadium/settings/support'} />
                        <CustomTab label='Volumes' component={Link} to={'/xstadium/settings/volumes'} />
                        <CustomTab label='Device Animations' component={Link} to={'/xstadium/settings/screener'} />
                    </Tabs>

                    <Typography variant='body2' color='text.secondary'>
                        To prevent blank screen, this default playlist automatically loads when no playlist or content
                        is assigned to play
                    </Typography>

                    {status.data.layouts && <FormControl
                        sx={{
                            display: 'flex',
                            width: '50%',
                        }}
                        variant='outlined'
                        autoComplete='off'
                    >
                        <Typography>Layout</Typography>
                        <Select
                            id='layout'
                            inputProps={{
                                name: 'layout',
                                id: 'layout',
                            }}
                            required
                            fullWidth
                            onChange={(e) => {
                                setLayout(e.target.value)
                            }}
                            value={layout}
                            disabled={publishing}
                            size='small'
                            MenuProps={MenuProps}
                        >
                            {status.data.layouts.map((item, index) => {
                                return (
                                    <MenuItem key={'select-layout' + index} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>}

                    {status.data.backgrounds && <FormControl
                        sx={{
                            display: 'flex',
                            width: '50%',
                        }}
                        variant='outlined'
                        autoComplete='off'
                    >
                        <Typography>Background</Typography>
                        <Select
                            id='background'
                            inputProps={{
                                name: 'background',
                                id: 'background',
                            }}
                            required
                            fullWidth
                            onChange={(e) => {
                                setBackground(e.target.value)
                            }}
                            value={background}
                            disabled={publishing}
                            size='small'
                            MenuProps={MenuProps}
                        >
                            {status.data.backgrounds.map((item, index) => {
                                return (
                                    <MenuItem key={'select-background' + index} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>}

                    {status.data.music && <FormControl
                        sx={{
                            display: 'flex',
                            width: '50%',
                        }}
                        variant='outlined'
                        autoComplete='off'
                    >
                        <Typography>Music</Typography>
                        <Select
                            id='music'
                            inputProps={{
                                name: 'music',
                                id: 'music',
                            }}
                            required
                            fullWidth
                            onChange={(e) => {
                                setMusic(e.target.value)
                            }}
                            value={music}
                            disabled={publishing}
                            size='small'
                            MenuProps={MenuProps}
                        >
                            {status.data.music.map((item, index) => {
                                return (
                                    <MenuItem key={'select-music' + index} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>}

                    {(status.data.music || status.data.backgrounds || status.data.layouts) && <Box>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            onClick={submitForm}
                            disabled={publishing}
                        >
                            Save changes
                        </Button>
                    </Box>}
                </Box>
            </form>
        </Grid>
    )
}

export default DefaultPlaylist
