import React, { useContext } from "react";
import { DUIContext } from "./Dealer";

const DealerCards = () => {
  const {
    getClassName,
    inEditMode,
    isPanelBtnSelected,
    isVisible,
    panelPress,
    s,
  } = useContext(DUIContext);

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "30vh",
        zIndex: 1,
      }}
    >
      {/* Edit Cards Panel */}
      <div
        className={getClassName(s.border, isVisible(inEditMode))}
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          className={getClassName(s.border)}
          style={{ display: "flex", height: "100%" }}
        >
          {[...Array(9)].map((x, i) => (
            <div
              key={i}
              onClick={(e) => {
                console.log("panel press");
                panelPress(i + 1);
              }}
              className={getClassName(
                s.border,
                s.cardsInput,
                isPanelBtnSelected(i + 1)
              )}
              style={{ width: "100%" }}
            >
              {i === 0 ? "A" : i + 1}
            </div>
          ))}
        </div>
        <div
          className={getClassName(s.border)}
          style={{ display: "flex", height: "100%" }}
        >
          {["10", "J", "Q", "K"].map((x, i) => (
            <div
              key={i}
              onClick={(e) => {
                console.log("panel press");
                panelPress(i + 10);
              }}
              className={getClassName(
                s.border,
                s.cardsInput,
                isPanelBtnSelected(i + 10)
              )}
              style={{ width: "100%" }}
            >
              {x}
            </div>
          ))}
        </div>
        <div
          className={getClassName(s.border)}
          style={{ display: "flex", height: "100%" }}
        >
          {["S", "H", "C", "D"].map((x, i) => (
            <div
              key={i}
              onClick={(e) => {
                console.log("panel press");
                panelPress(i + 1, true);
              }}
              className={getClassName(
                s.border,
                s.cardsInput,
                s["suit" + (i + 1)],
                isPanelBtnSelected(i + 1, true)
              )}
              style={{ width: "100%" }}
            >
              &nbsp;
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DealerCards;