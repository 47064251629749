import React, { useContext } from "react";
import { DUIContext } from "./Dealer";
import { usePublish } from "../../api";

function Simulator() {
    const { deviceIp } = useContext(DUIContext);
    const ip = deviceIp;
    const card = usePublish(ip + "/baccarat/card");

    const sendBetsOff = async () => {
        try {
            await window.fetch("http://" + ip + "/baccarat/betsoff");
        } catch (e) {
            console.warn(e);
        }
    };

    const sendEnd = async () => {
        try {
            await window.fetch("http://" + ip + "/baccarat/end");
        } catch (e) {
            console.warn(e);
        }
    };

    const sendVoid = async () => {
        try {
            await fetch(ip + "/baccarat/void");
        } catch (e) {
            console.warn(e);
        }
    };

    const sendDeck = async () => {
        try {
            await window.fetch("http://" + ip + "/baccarat/deck");
        } catch (e) {
            console.warn(e);
        }
    };

    const sendStop = async () => {
        try {
            await window.fetch("http://" + ip + "/baccarat/timer/stop");
        } catch (e) {
            console.warn(e);
        }
    };

    const sendRestart = async () => {
        try {
            await window.fetch("http://" + ip + "/baccarat/timer/restart");
        } catch (e) {
            console.warn(e);
        }
    };

    const cardStyle = {
        width: "100%",
        background: "#ccc",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10%",
        cursor: "pointer",
        fontSize: "1.5vw",
    };

    const buttonStyle = {
        width: "100%",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "1.0vw",
        color: "#333",
    };

    const sendCard = async ({ owner, value }) => {
        try {
            await card({
                suit: 1,
                value: value,
                owner,
            });
        } catch (e) {
            console.warn(e);
        }
    };

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 0,
                padding: 5,
            }}
        >
            <div
                style={{
                    display: "flex",
                    gap: 10,
                    height: "100%",
                    padding: 5,
                    border: "3px solid #FF4F4F",
                    background:
                        "transparent linear-gradient(180deg, #340404 0%, #FF4F4F 100%)",
                }}
            >
                <div
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    B:
                </div>
                {[...Array(13)].map((x, i) => (
                    <div
                        key={i}
                        onClick={(e) => sendCard({ owner: "B", value: i + 1 })}
                        style={{ ...cardStyle }}
                    >
                        {i === 0
                            ? "A"
                            : i === 10
                                ? "J"
                                : i === 11
                                    ? "Q"
                                    : i === 12
                                        ? "K"
                                        : i + 1}
                    </div>
                ))}
            </div>
            <div
                style={{
                    display: "flex",
                    gap: 10,
                    height: "100%",
                    padding: 5,
                    border: "3px solid #008FFA",
                    background:
                        "transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)",
                }}
            >
                <div
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    P:
                </div>
                {[...Array(13)].map((x, i) => (
                    <div
                        key={i}
                        onClick={(e) => sendCard({ owner: "P", value: i + 1 })}
                        style={{ ...cardStyle }}
                    >
                        {i === 0
                            ? "A"
                            : i === 10
                                ? "J"
                                : i === 11
                                    ? "Q"
                                    : i === 12
                                        ? "K"
                                        : i + 1}
                    </div>
                ))}
            </div>

            <div style={{ display: "flex", gap: 10, height: "80%", marginTop: 5 }}>
                <button onClick={() => sendBetsOff()} style={{ ...buttonStyle }}>
                    Bets Off
                </button>
                <button onClick={() => sendStop()} style={{ ...buttonStyle }}>
                    Stop Timer
                </button>
                <button onClick={() => sendRestart()} style={{ ...buttonStyle }}>
                    Restart Timer
                </button>
                <button onClick={() => sendDeck()} style={{ ...buttonStyle }}>
                    Deck Change
                </button>
                <button onClick={() => sendVoid()} style={{ ...buttonStyle }}>
                    Void Game
                </button>
                <button onClick={() => sendEnd()} style={{ ...buttonStyle }}>
                    End Game
                </button>
            </div>
        </div>
    );
}

export default Simulator;
