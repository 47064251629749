import React, { memo } from 'react'
import { toLower } from 'lodash'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

import R404 from '../404'
import Playlists from './playlists/Playlists'
import Videos from './playlists/Videos'

export default memo(() => {
    const { pathname } = useLocation()
    const role = window.localStorage.getItem('role')
    const products = window.localStorage.getItem('products')
        ? window.localStorage
              .getItem('products')
              .split(',')
              .map((x) => toLower(x))
        : []

    if (role !== 'root' && !products.includes(pathname.split('/')[1])) {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            <Routes>
                <Route exact path='/' element={<Navigate to='/xsignage/home' replace />} />
                <Route exact path='/home' element={<Videos />} />
                <Route exact path='/playlists' element={<Playlists />} />
                <Route exact path='/videos' element={<Videos />} />
                <Route path='*' element={<R404 basePath='xsignage/' />} />
            </Routes>
        </>
    )
})
