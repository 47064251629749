import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import Settings from './Settings'

export default memo(() => {
	return (
		<>
			<Routes>
				<Route exact path='/' element={<Settings />} />
			</Routes>
		</>
	)
})
