import React, { memo, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CloseIcon from '@mui/icons-material/Close'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { makeStyles } from '@mui/styles'

import { useMenuContext } from '../contexts/MenuContext'
import UserView from './UserView'

const rootStyles = makeStyles((theme) => ({
    menu: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontStyle: 'italic',
        fontWeight: '100',
        fontVariant: 'small-caps',
        flex: 1,
        padding: '0.37em 0',
        color: theme.palette.text.primary,
    },
    logo: {
        height: 20,
        verticalAlign: 'middle',
        marginRight: 34,
        marginLeft: 1,
        filter: 'none',
    },
    logOutWrapper: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column-reverse',
    },
    versionLabel: {
        position: 'absolute',
        bottom: 3,
        right: 3,
        fontSize: 12,
        color: '#CCC',
        display: 'flex',
        flexDirection: 'column',
    },
    infoWrapper: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    infoRowWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    infoDataWrapper: {
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    closeDrawerButton: {
        width: 40,
        position: 'absolute',
        top: 4,
        right: 4,
        zIndex: 100,
    },
}))

export default memo(({ profile, setDrawer }) => {
    const { selectedPit, selectedTable, prevSelectedPit, prevSelectedTable } = useMenuContext()
    const root = rootStyles()
    const role = window.localStorage.getItem('role')
    const account = window.localStorage.getItem('account')

    console.log('role', role)

    const [open, setOpen] = useState(false)
    const [userDrawerOpen, setUserDrawerOpen] = useState(false)

    const MenuInfo = () => {
        const loggedAccount = window.localStorage.getItem('account')

        return (
            <div className={root.infoWrapper}>
                <div className={root.infoRowWrapper}>
                    <div>Pit:</div>
                    <div className={root.infoDataWrapper}>
                        {selectedPit.length !== 0 ? selectedPit : prevSelectedPit !== null ? prevSelectedPit : '-'}
                    </div>
                </div>
                <div className={root.infoRowWrapper}>
                    <div>Table:</div>
                    <div className={root.infoDataWrapper}>
                        {selectedTable.length !== 0
                            ? selectedTable
                            : prevSelectedTable !== null
                            ? prevSelectedTable
                            : '-'}
                    </div>
                </div>
                <div className={root.infoRowWrapper}>
                    <div>User:</div>
                    <div className={root.infoDataWrapper}>{loggedAccount}</div>
                </div>
                {/* TODO: fetch this server pivotIP/version/current */}
                {/* <div className={root.infoRowWrapper}>
                    <div>Version:</div>
                    <div className={root.infoDataWrapper}>{packageInfo.version}</div>
                </div> */}
            </div>
        )
    }

    const MenuBottom = () => {
        return (
            <Box className={root.logOutWrapper}>
                <Box>
                    <Divider />

                    <ListItemButton
                        onClick={() => {
                            setUserDrawerOpen(true)
                        }}
                    >
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary='User Profile' />
                    </ListItemButton>

                    <Divider />

                    <ListItemButton
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        <ListItemIcon>
                            <ExitToAppIcon color='error' />
                        </ListItemIcon>
                        <ListItemText primary='Logout' />
                    </ListItemButton>
                </Box>

                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    PaperProps={{ elevation: 5 }}
                >
                    <DialogTitle id='alert-dialog-title'>
                        Log out of <b>{account}</b> account?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color='primary'>
                            No
                        </Button>
                        <Button variant='contained' component={Link} {...{ to: '/logout' }}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    return (
        <>
            <MenuInfo />
            <MenuBottom />
            <MuiDrawer
                anchor={'right'}
                variant={'temporary'}
                open={userDrawerOpen}
                onClose={() => {
                    setUserDrawerOpen(false)
                }}
            >
                <IconButton
                    color='primary'
                    className={root.closeDrawerButton}
                    onClick={() => setUserDrawerOpen(false)}
                    aria-label='close drawer'
                    component='label'
                >
                    <CloseIcon />
                </IconButton>
                {profile && <UserView user={profile} goBack={() => setUserDrawerOpen(false)} />}
            </MuiDrawer>
        </>
    )
})
