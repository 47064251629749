import React, {
    useState,
    useReducer,
} from "react"
import { Link, useLocation } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"
import Drawer from "@mui/material/Drawer"
import Fade from "@mui/material/Fade"
import Hidden from "@mui/material/Hidden"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import useMediaQuery from "@mui/material/useMediaQuery"
import { makeStyles, useTheme } from "@mui/styles"

import {
    authApi
} from '../../api'
import { configurable } from "../../config"
import useAsyncState from '../../useAsyncState'
import { useMenuContext } from "../contexts/MenuContext"
import Configurations from "../navBar/Configurations"
import Logo from "./idx_hor_wht.png"
import Menu from "./Menu"

const drawerWidth = 240;
// const docTitle = document.title
const immutedConfig = window.localStorage.getItem("immutedConfig");

const toolbarStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        background: (props) => (props.lights ? "#f1f1f1" : "#383838"),
        height: "6.5vh",
        minHeight: "6.5vh",
        paddingTop: 0,
        position: "fixed",
        top: 0,
        zIndex: 1000,
        width: "100%",
        padding: "0",
    },
    title: {
        fontStyle: "italic",
        fontWeight: "100",
        fontVariant: "small-caps",
        flex: 1,
        padding: "0.37em 0",
        color: theme.palette.text.primary,
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        // overflowY: 'visible'
        overflowX: "hidden",
    },
    menuContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    menuButton: {
        marginRight: 10,
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
        background: "transparent !important",
        color: (props) => (props.lights ? "black" : "white"),
    },
    date: {
        fontSize: "1.4vh",
        textAlign: "right",
        color: (props) => (props.lights ? "black" : "white"),
        padding: "12px",
        left: -120,
        position: "absolute",
    },
    dateStatic: {
        fontSize: "1.4vh",
        textAlign: "right",
        color: (props) => (props.lights ? "black" : "white"),
        padding: 0,
    },
    pageTitle: {
        flexWrap: "wrap",
        //flex:1,
        //color:'red',
        color: (props) => (props.lights ? "black" : "white"),
        textAlign: "center",
        textTransform: "uppercase",
        fontWeight: "bold",
        letterSpacing: 3,
        //marginLeft:'-15%',
        //marginRight:-48
        zIndex: 3,
        textShadow: "0 0 5px #000",
        fontSize: "2.5vh",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
        //minWidth:390
    },
    logoWrapper: {
        width: "13vw",
        height: "4.5vh",
        background: `url(${Logo}) no-repeat center left / contain`,
        cursor: "pointer",
        filter: immutedConfig ? "invert(1) drop-shadow(2px 4px 6px white)" : "",
    },
    icon: {
        color: "#03a9f4",
    },
    iconButton: {
        padding: 22,
        flex: "0 0 auto",
    },
    iconButtonWrapper: {
        display: "flex",
        flexDirection: "row-reverse",
        height: 65,
        justifyContent: "space-between",
        position: "relative",
    },
    logOutIcon: {
        color: "red",
    },
    logOutWrapper: {
        position: "absolute",
        bottom: 0,
        width: drawerWidth,
    },
    breadcrumb: {
        height: "1rem",
        lineHeight: "1rem",
        color: "white",
        borderRadius: "10px",
        boxSizing: "content-box",
        padding: "1.4vh 1.75vh",
        minWidth: "130px",
        maxWidth: "130px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: "2vh",
        [theme.breakpoints.down("md")]: {
            fontSize: "2.5vh",
        },
        textAlign: "center",
    },
    backButton: {
        height: "1rem",
        lineHeight: "1rem",
        backgroundColor: "#555",
        color: "white",
        borderRadius: "10px",
        boxSizing: "content-box",
        padding: "1.4vh 1.75vh",
        fontSize: "2vh",
        [theme.breakpoints.down("md")]: {
            fontSize: "2.5vh",
        },
    },
}));
const appStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1",
        paddingTop: "6.5vh",
    },
}));

let timeOut = null;

// TODO: nick please nuke this file
export function Dashboard({
    Component,
}) {
    const location = useLocation()
    const app = appStyles()
    const lights = window.localStorage.getItem("lights") === "on"
    const token = window.localStorage.getItem('token')
    const role = window.localStorage.getItem("role")
    const toolbar = toolbarStyles({
        mobile: useMediaQuery(useTheme().breakpoints.down("xs")),
        location,
        lights,
    })

    const [drawer, setDrawer] = useState(false);
    const [dashboardTitle, setDashboardTitle] = useReducer(
        (state, obj) => {
            const pageTitle = getPageTitle(obj.role, obj.path)
            const newTitle =
                obj.role !== "ceo"
                    ? "IDX Games: X Trend > " + pageTitle.toUpperCase()
                    : document.title;
            return { docTitle: newTitle, path: obj.path, pageTitle: pageTitle }
        },
        { docTitle: document.title, path: "", pageTitle: "Dashboard" }
    )
    const [showConfigurations, setShowConfigurations] = useState(false)
    const [clickCount, setClickCount] = useReducer(
        (s, o) => (o.action === "click" ? s + 1 : 0),
        0
    )

    const {
        selectedTable,
        setSelectedTable,
        prevSelectedTable,
        pageNav,
        setPageNav,
    } = useMenuContext()

    // user profile
    const [fetchedProfile, setFetchedProfile] = useAsyncState(false)
    const [profile, setProfile] = useAsyncState(null)

    const fetchUserProfile = async () => {
        if (fetchedProfile) {
            return
        }

        setFetchedProfile(true)
        try {
            const data = await authApi
                .get('profile', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                .json()
            setTimeout(() => {
                setProfile(data)
            }, 400)
        } catch (e) {
            console.warn(e)
        }
    }

    fetchUserProfile()

    const click = () => {
        if (showConfigurations) return;
        clearTimeout(timeOut);
        setClickCount({ action: "click" });
        timeOut = setTimeout(() => {
            setClickCount({});
            clearTimeout(timeOut);
        }, 3000);
    };

    if (clickCount >= 5) {
        clearTimeout(timeOut);
        setClickCount({});
        setShowConfigurations(true);
    }

    const path = location.pathname.match(/\/dashboard[/]?[a-z-]*[/]?/)[0];
    if (dashboardTitle.path !== path) {
        setDashboardTitle({ role: role, path: path });
    }
    document.title = dashboardTitle.docTitle;
    const pageTitle = dashboardTitle.pageTitle;

    const currentTable =
        selectedTable.length !== 0
            ? selectedTable
            : prevSelectedTable !== null
                ? prevSelectedTable
                : null;

    return (
        <div className={app.root}>
            <Toolbar className={toolbar.root}>
                {/* <Idle timeout={roleTimeout} onChange={({ idle }) => handleIdle(idle)} /> */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                        padding: "0 10px",
                    }}
                >
                    <Link
                        className={toolbar.logoWrapper}
                        onClick={() => click()}
                        to={"/posui/dashboard"}
                        style={{ zIndex: 1 }}
                    />
                    {currentTable !== null && (
                        <div className={toolbar.breadcrumb}>{currentTable}</div>
                    )}
                </div>

                <div className={toolbar.pageTitle} anchor={"right"}>
                    {role !== "ceo" ? pageTitle : ""}
                </div>

                <div
                    className={toolbar.dateStatic}
                    style={{ display: "flex", gap: 10, alignItems: "center" }}
                >
                    {role === "pitboss" && pageNav === "dealerUI" && (
                        <div
                            onClick={() => {
                                setPageNav("overview");
                                setSelectedTable(window.localStorage.getItem("manager-table"));
                            }}
                            className={toolbar.backButton}
                        >
                            BACK TO PITBOSS UI
                        </div>
                    )}
                    {(role === "pitboss" || role === "dealer") && (
                        <div
                            style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                                fontSize: "2.5vh",
                            }}
                        >
                            <div style={{ display: "flex", gap: 10 }}>
                                <div>
                                    {/* <DateDisplay time={time} /> */}
                                </div>
                                <div>
                                    {/* <TimeDisplay time={time} /> */}
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            marginRight: 10,
                        }}
                    >
                        <IconButton
                            style={{ margin: 0 }}
                            disableRipple
                            color="primary"
                            aria-label="open drawer"
                            edge="end"
                            onClick={() => setDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>

            {showConfigurations && configurable && (
                <Configurations setShowConfigurations={setShowConfigurations} />
            )}

            <nav className={toolbar.drawer} aria-label="Main menu">
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: toolbar.drawerPaper,
                        }}
                        variant="temporary"
                        anchor={"right"}
                        open={drawer}
                        onClose={() => setDrawer(false)}
                    >
                        <div className={toolbar.iconButtonWrapper}>
                            <IconButton
                                className={toolbar.iconButton}
                                onClick={() => setDrawer(false)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Fade in={drawer}>
                                <div className={toolbar.date}>
                                    {/* <TimeDisplay time={time} /> */}
                                    <br />
                                    {/* <DateDisplay time={time} /> */}
                                </div>
                            </Fade>
                        </div>
                        <div className={toolbar.menuContainer}>
                            <Menu
                                profile={profile}
                                location={location}
                                setDrawer={setDrawer}
                            />
                        </div>
                    </Drawer>
                </Hidden>
            </nav>
            <Component />
        </div>
    );
}

const getPageTitle = (role, path) => {
    let pageTitle = "";
    if (role === "pitboss") {
        switch (path) {
            case "/dashboard":
                pageTitle = "PITBOSS UI";
                break;
            case "/dashboard/device-config":
                pageTitle = "Device Config";
                break;
            case "/dashboard/device-config/":
                pageTitle = "Device Config";
                break;
            case "/dashboard/devices":
                pageTitle = "Devices";
                break;
            case "/dashboard/devices/":
                pageTitle = "Devices";
                break;
            case "/dashboard/gameresults":
                pageTitle = "Game Results";
                break;
            case "/dashboard/gameresults/":
                pageTitle = "Game Results";
                break;
            default:
                pageTitle = "";
        }
    }
    if (role === "root") {
        switch (path) {
            case "/dashboard":
                pageTitle = "dashboard";
                break;
            case "/dashboard/tables":
                pageTitle = "tables";
                break;
            case "/dashboard/user/":
                pageTitle = "edit user";
                break;
            case "/dashboard/users":
                pageTitle = "users";
                break;
            case "/dashboard/device/baccarat/":
                pageTitle = "edit device";
                break;
            case "/dashboard/device/roulette/":
                pageTitle = "edit device";
                break;
            case "/dashboard/device/sicbo/":
                pageTitle = "edit device";
                break;
            case "/dashboard/casino":
                pageTitle = "dashboard";
                break;
            case "/dashboard/zone/":
                pageTitle = "dashboard - zone";
                break;
            case "/dashboard/pit/":
                pageTitle = "dashboard - pit";
                break;
            case "/dashboard/table/":
                pageTitle = "dashboard - table";
                break;
            case "/dashboard/device/":
                pageTitle = "dashboard - device";
                break;
            case "/dashboard/storage":
                pageTitle = "storage";
                break;
            case "/dashboard/storage/":
                pageTitle = "edit storage";
                break;
            case "/dashboard/settings":
                pageTitle = "settings";
                break;
            case "/dashboard/gamehistory":
                pageTitle = "Game History";
                break;
            case "/dashboard/gamehistory/":
                pageTitle = "Game History";
                break;
            case "/dashboard/simulator":
                pageTitle = "Simulator";
                break;
            default:
                pageTitle = "";
        }
    }
    if (role === "dealer") {
        switch (path) {
            case "/dashboard":
                pageTitle = "DEALER UI";
                break;
            default:
                pageTitle = "";
        }
    }
    return pageTitle;
};

export default Dashboard
