import React, { useState, memo, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import SnackbarContent from '@mui/material/SnackbarContent'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { update, validate, setDefaults, getFieldsValues } from '../../../forms'
import { formatCountString } from '../../../xtable/Formats'
import { defaultValues } from '../defaultValues'
import { getMinBetColor } from '../minBetColor/color'
import BetInfoUploader from './BetInfoUploader'
import ClosedTableUploader from './ClosedTableUploader'
import PIPUploader from './PIPUploader'

const rootStyles = makeStyles((theme) => ({
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        width: ({ template }) => template ? 'inherit' : 700,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    selectControl: {
        display: 'flex',
        '& > label[data-shrink="false"]': {
            transform: 'translate(14px, 15px) scale(1)',
        },
    },
    selectInputPreview: {
        height: '100%',
        padding: 10,
    },
    MenuListProps: {
        maxHeight: '150px',
    },
    MenuListPropsPreview: {
        maxHeight: '500px',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
    cardReveal: {
        minWidth: '101px',
    },
    sectionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.75em',
    },
    sectionHeader: {
        fontSize: '1.15em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    fieldRowWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5em',
    },
    fieldRowHeader: {
        fontWeight: 'normal',
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.2em',
    },
    togglesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.2em',
    },
    toggleWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))

const FieldRow = memo(({ name, label, value, handleChange, loading, error, type = 'text' }) => {
    return (
        <Box display='flex' flexDirection='column'>
            <Box>{label}</Box>
            <TextField
                size='small'
                id={name}
                name={name}
                type={type}
                fullWidth
                variant='outlined'
                onChange={handleChange}
                value={value}
                disabled={loading}
                error={error}
            />
        </Box>
    )
})

const Trendboard = ({ publish, table, layouts, streams, xstadium, template, changeCallback }) => {
    const styles = rootStyles({ xstadium, template })
    /* const borderGreenClass = borderGreenStyle() */

    const navigate = useNavigate()

    // form changed detect hook
    const [changed, setChanged] = useState(0)
    //const [mouseOverApplyTo, setMouseOverApplyTo] = useState(false) 

    // field flags
    const [editing, setEditing] = useState([])
    const [submitted, setSubmitted] = useState(false)


    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)
    const [tableNum, setTableNum] = useState('')
    const [layout, setLayout] = useState('')
    const [themes, setTheme] = useState('default')
    const [showNeighbor, setShowNeighbor] = useState(true)
    const [showAnimations, setShowAnimations] = useState(false)
    const [showInsuranceBadge, setShowInsuranceBadge] = useState(false)
    const [insuranceReminderTimeout, setInsuranceReminderTimeout] = useState(10)
    const [insuranceReminderDelayFourCards, setInsuranceReminderDelayFourCards] = useState(0)
    const [insuranceReminderDelayPlayerThirdCard, setInsuranceReminderDelayPlayerThirdCard] = useState(0)
    const [showBetTimerAnimation, setShowBetTimerAnimation] = useState(false)
    const [showBigRoadNumbers, setShowBigRoadNumbers] = useState(false)
    const [frameRule, setFrameRule] = useState('')
    const [trendHighlight, setTrendHighlight] = useState(false)
    const [revealAnim, setRevealAnim] = useState(false)
    const [cardReveal, setCardReveal] = useState('immediate')
    const [deckChangeTimer, setDeckChangeTimer] = useState('')
    const [hotColdNumDisplay, setHotColdNumDisplay] = useState('')
    const [timerPlayerShowHand, setTimerPlayerShowHand] = useState('')
    const [timerBankerShowHand, setTimerBankerShowHand] = useState('')
    const [timerShowPlayerThirdCard, setTimerShowPlayerThirdCard] = useState('')
    const [timerShowBankerThirdCard, setTimerShowBankerThirdCard] = useState('')
    const [chosenStream, setChosenStream] = useState('')
    const [chosenMarketingStream, setChosenMarketingStream] = useState('')
    const [timeOnly, setTimeOnly] = useState(false)
    const [disclaimerText, setDisclaimerText] = useState('')
    const [disclaimerSpeed, setDisclaimerSpeed] = useState('medium')
    const [streamOutputName, setStreamOutputName] = useState('')
    const [colorScheme, setColorScheme] = useState('bankerRed')

    // meters
    const [showSideBetMeterValue, setShowSideBetMeterValue] = useState(true)
    const [sideBetMeterValueMode, setSideBetMeterValueMode] = useState('')
    const [lucky6MeterMax, setLucky6MeterMax] = useState(16)
    const [mega6MeterMax, setMega6MeterMax] = useState(16)
    const [tigerMeterMax, setTigerMeterMax] = useState(16)
    const [tigerTieMeterMax, setTigerTieMeterMax] = useState(16)
    const [tigerPairMeterMax, setTigerPairMeterMax] = useState(11)
    const [tigerBigMeterMax, setTigerBigMeterMax] = useState(16)
    const [tigerSmallMeterMax, setTigerSmallMeterMax] = useState(16)
    const [tieMeterMax, setTieMeterMax] = useState(10)
    const [bankerPairMeterMax, setBankerPairMeterMax] = useState(11)
    const [playerPairMeterMax, setPlayerPairMeterMax] = useState(11)

    const fields = {
        tableNum: {
            error: () => tableNum.trim().length < 1,
            value: tableNum,
            set: setTableNum,
            message: 'Table Number cannot be empty',
        },
        layout: {
            error: () => false,
            value: layout,
            set: setLayout,
            message: '',
        },
        themes: {
            error: () => false,
            value: themes,
            set: setTheme,
            message: '',
        },
        stream: {
            error: () => false,
            value: chosenStream,
            set: setChosenStream,
            message: '',
        },
        marketingStream: {
            error: () => false,
            value: chosenMarketingStream,
            set: setChosenMarketingStream,
            message: '',
        },
        timeOnly: {
            error: () => false,
            value: timeOnly,
            set: setTimeOnly,
            message: '',
        },
        disclaimerText: {
            error: () => false,
            value: disclaimerText,
            set: setDisclaimerText,
            message: '',
        },
        disclaimerSpeed: {
            error: () => false,
            value: disclaimerSpeed,
            set: setDisclaimerSpeed,
            message: '',
        },
        showNeighbor: {
            error: () => false,
            value: showNeighbor,
            set: setShowNeighbor,
            message: '',
        },
        showAnimations: {
            error: () => false,
            value: showAnimations,
            set: setShowAnimations,
            message: '',
        },
        showBigRoadNumbers: {
            error: () => false,
            value: showBigRoadNumbers,
            set: setShowBigRoadNumbers,
            message: '',
        },
        trendHighlight: {
            error: () => false,
            value: trendHighlight,
            set: setTrendHighlight,
            message: '',
        },
        showInsuranceBadge: {
            error: () => false,
            value: showInsuranceBadge,
            set: setShowInsuranceBadge,
            message: '',
        },
        insuranceReminderTimeout: {
            error: () => Number(insuranceReminderTimeout) === 0,
            value: insuranceReminderTimeout,
            set: setInsuranceReminderTimeout,
            message: 'Insurance reminder display duration cannot be zero',
            type: 'number'
        },
        insuranceReminderDelayFourCards: {
            error: () => false,
            value: insuranceReminderDelayFourCards,
            set: setInsuranceReminderDelayFourCards,
            message: '',
        },
        insuranceReminderDelayPlayerThirdCard: {
            error: () => false,
            value: insuranceReminderDelayPlayerThirdCard,
            set: setInsuranceReminderDelayPlayerThirdCard,
            message: '',
        },
        showBetTimerAnimation: {
            error: () => false,
            value: showBetTimerAnimation,
            set: setShowBetTimerAnimation,
            message: '',
        },
        deckchangeTimer: {
            error: () => false,
            value: deckChangeTimer,
            set: setDeckChangeTimer,
            message: '',
        },
        hotColdNumDisplay: {
            error: () => false,
            value: hotColdNumDisplay,
            set: setHotColdNumDisplay,
            message: '',
        },
        revealAnim: {
            error: () => false,
            value: revealAnim,
            set: setRevealAnim,
            message: '',
        },
        cardReveal: {
            error: () => false,
            value: cardReveal,
            set: setCardReveal,
            message: '',
        },
        timerBankerShowHand: {
            error: () => Number(timerBankerShowHand) === 0 && String(cardReveal) === 'timed',
            value: timerBankerShowHand,
            set: setTimerBankerShowHand,
            message: 'Banker hand timer cannot be zero',
            type: 'number'
        },
        timerPlayerShowHand: {
            error: () => Number(timerPlayerShowHand) === 0 && String(cardReveal) === 'timed',
            value: timerPlayerShowHand,
            set: setTimerPlayerShowHand,
            message: 'Player hand timer cannot be zero',
            type: 'number'
        },
        timerShowBankerThirdCard: {
            error: () => Number(timerShowBankerThirdCard) === 0 && String(cardReveal) === 'timed',
            value: timerShowBankerThirdCard,
            set: setTimerShowBankerThirdCard,
            message: 'Banker hand timer cannot be zero',
            type: 'number'
        },
        timerShowPlayerThirdCard: {
            error: () => Number(timerShowPlayerThirdCard) === 0 && String(cardReveal) === 'timed',
            value: timerShowPlayerThirdCard,
            set: setTimerShowPlayerThirdCard,
            message: 'Player hand timer cannot be zero',
            type: 'number'
        },
        frameRule: {
            error: () => false,
            value: frameRule,
            set: setFrameRule,
            message: '',
        },
        streamOutputName: {
            error: () => false,
            value: streamOutputName,
            set: setStreamOutputName,
            message: '',
        },
        colorScheme: {
            error: () => false,
            value: colorScheme,
            set: setColorScheme,
            message: '',
        },
        meters: {
            baccarat: {
                standard: {
                    showSideBetMeterValue: {
                        error: () => false,
                        value: showSideBetMeterValue,
                        set: setShowSideBetMeterValue,
                        message: '',
                    },
                    sideBetMeterValueMode: {
                        error: () => sideBetMeterValueMode.trim().length === 0,
                        value: sideBetMeterValueMode,
                        set: setSideBetMeterValueMode,
                        message: 'Side bet meter value mode cannot be empty',
                    },
                    tieMeterMax: {
                        error: () => Number(tieMeterMax) < 1,
                        value: tieMeterMax,
                        set: setTieMeterMax,
                        message: 'Tie meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    bankerPairMeterMax: {
                        error: () => Number(bankerPairMeterMax) < 1,
                        value: bankerPairMeterMax,
                        set: setBankerPairMeterMax,
                        message: 'Banker pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    playerPairMeterMax: {
                        error: () => Number(playerPairMeterMax) < 1,
                        value: playerPairMeterMax,
                        set: setPlayerPairMeterMax,
                        message: 'Player pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                },
                lucky6: {
                    showSideBetMeterValue: {
                        error: () => false,
                        value: showSideBetMeterValue,
                        set: setShowSideBetMeterValue,
                        message: '',
                    },
                    sideBetMeterValueMode: {
                        error: () => sideBetMeterValueMode.trim().length === 0,
                        value: sideBetMeterValueMode,
                        set: setSideBetMeterValueMode,
                        message: 'Side bet meter value mode cannot be empty',
                    },
                    lucky6MeterMax: {
                        error: () => Number(lucky6MeterMax) < 1,
                        value: lucky6MeterMax,
                        set: setLucky6MeterMax,
                        message: 'Lucky 6 meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    tieMeterMax: {
                        error: () => Number(tieMeterMax) < 1,
                        value: tieMeterMax,
                        set: setTieMeterMax,
                        message: 'Tie meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    bankerPairMeterMax: {
                        error: () => Number(bankerPairMeterMax) < 1,
                        value: bankerPairMeterMax,
                        set: setBankerPairMeterMax,
                        message: 'Banker pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    playerPairMeterMax: {
                        error: () => Number(playerPairMeterMax) < 1,
                        value: playerPairMeterMax,
                        set: setPlayerPairMeterMax,
                        message: 'Player pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                },
                lucky6Slot: {
                    showSideBetMeterValue: {
                        error: () => false,
                        value: showSideBetMeterValue,
                        set: setShowSideBetMeterValue,
                        message: '',
                    },
                    sideBetMeterValueMode: {
                        error: () => sideBetMeterValueMode.trim().length === 0,
                        value: sideBetMeterValueMode,
                        set: setSideBetMeterValueMode,
                        message: 'Side bet meter value mode cannot be empty',
                    },
                    lucky6MeterMax: {
                        error: () => Number(lucky6MeterMax) < 1,
                        value: lucky6MeterMax,
                        set: setLucky6MeterMax,
                        message: 'Lucky 6 meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    tieMeterMax: {
                        error: () => Number(tieMeterMax) < 1,
                        value: tieMeterMax,
                        set: setTieMeterMax,
                        message: 'Tie meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    bankerPairMeterMax: {
                        error: () => Number(bankerPairMeterMax) < 1,
                        value: bankerPairMeterMax,
                        set: setBankerPairMeterMax,
                        message: 'Banker pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    playerPairMeterMax: {
                        error: () => Number(playerPairMeterMax) < 1,
                        value: playerPairMeterMax,
                        set: setPlayerPairMeterMax,
                        message: 'Player pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                },
                mega6: {
                    showSideBetMeterValue: {
                        error: () => false,
                        value: showSideBetMeterValue,
                        set: setShowSideBetMeterValue,
                        message: '',
                    },
                    sideBetMeterValueMode: {
                        error: () => sideBetMeterValueMode.trim().length === 0,
                        value: sideBetMeterValueMode,
                        set: setSideBetMeterValueMode,
                        message: 'Side bet meter value mode cannot be empty',
                    },
                    mega6MeterMax: {
                        error: () => Number(mega6MeterMax) < 1,
                        value: mega6MeterMax,
                        set: setMega6MeterMax,
                        message: 'Mega 6 meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    tieMeterMax: {
                        error: () => Number(tieMeterMax) < 1,
                        value: tieMeterMax,
                        set: setTieMeterMax,
                        message: 'Tie meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    bankerPairMeterMax: {
                        error: () => Number(bankerPairMeterMax) < 1,
                        value: bankerPairMeterMax,
                        set: setBankerPairMeterMax,
                        message: 'Banker pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    playerPairMeterMax: {
                        error: () => Number(playerPairMeterMax) < 1,
                        value: playerPairMeterMax,
                        set: setPlayerPairMeterMax,
                        message: 'Player pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                },
                tiger: {
                    showSideBetMeterValue: {
                        error: () => false,
                        value: showSideBetMeterValue,
                        set: setShowSideBetMeterValue,
                        message: '',
                    },
                    sideBetMeterValueMode: {
                        error: () => sideBetMeterValueMode.trim().length === 0,
                        value: sideBetMeterValueMode,
                        set: setSideBetMeterValueMode,
                        message: 'Side bet meter value mode cannot be empty',
                    },
                    tieMeterMax: {
                        error: () => Number(tieMeterMax) < 1,
                        value: tieMeterMax,
                        set: setTieMeterMax,
                        message: 'Tie meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    tigerMeterMax: {
                        error: () => Number(tigerMeterMax) < 1,
                        value: tigerMeterMax,
                        set: setTigerMeterMax,
                        message: 'Tiger meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    tigerTieMeterMax: {
                        error: () => Number(tigerTieMeterMax) < 1,
                        value: tigerTieMeterMax,
                        set: setTigerTieMeterMax,
                        message: 'Tiger tie meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    tigerPairMeterMax: {
                        error: () => Number(tigerPairMeterMax) < 1,
                        value: tigerPairMeterMax,
                        set: setTigerPairMeterMax,
                        message: 'Tiger pair meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    tigerBigMeterMax: {
                        error: () => Number(tigerBigMeterMax) < 1,
                        value: tigerBigMeterMax,
                        set: setTigerBigMeterMax,
                        message: 'Tiger big meter max must be at least 1 or higher',
                        type: 'number'
                    },
                    tigerSmallMeterMax: {
                        error: () => Number(tigerSmallMeterMax) < 1,
                        value: tigerSmallMeterMax,
                        set: setTigerSmallMeterMax,
                        message: 'Tiger small meter max must be at least 1 or higher',
                        type: 'number'
                    },
                },
            },
            war: {
                etg: {
                    showSideBetMeterValue: {
                        error: () => false,
                        value: showSideBetMeterValue,
                        set: setShowSideBetMeterValue,
                        message: '',
                    },
                    sideBetMeterValueMode: {
                        error: () => sideBetMeterValueMode.trim().length === 0,
                        value: sideBetMeterValueMode,
                        set: setSideBetMeterValueMode,
                        message: 'Side bet meter value mode cannot be empty',
                    },
                    tieMeterMax: {
                        error: () => Number(tieMeterMax) < 1,
                        value: tieMeterMax,
                        set: setTieMeterMax,
                        message: 'Tie meter max must be at least 1 or higher',
                        type: 'number'
                    },
                },
            },
        }
    }

    const validation = validate(fields)

    if (table.updated !== updated) {
        update(fields, editing, table.data, defaultValues)
        setUpdated(table.updated)
    }

    const onFocus = (key) => setEditing([...editing, key])

    const onBlur = (e, key) =>
        e.target.value === table.data[key] ? setEditing(editing.filter((field) => field !== key)) : null

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            try {
                setLoading(true)
                await publish({
                    ...table.data,
                    ...getFieldsValues(fields),
                    colorThresholds: rainbow,
                    minBetColor: getMinBetColor(
                        table.data.bets[table.data.gameType][table.data.gameVariant].minBet,
                        rainbow
                    ),

                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
                setEditing([])
                setFail('')
                setLoading(false)
                // fired after a successful update or create
                // history.push('/dashboard/devices')
            } catch (e) {
                setFail(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setLoading(false)
            }
        }
        setChanged(0)
    }

    let colorThresholds =
        table && table.data.colorThresholds ? table.data.colorThresholds : defaultValues.colorThresholds
    const [rainbow, setRainbow] = useState(colorThresholds)
    const changeRainbowValue = (i, value) =>
        setRainbow((rainbow) => ({ ...rainbow, ['level' + i]: { color: rainbow['level' + i].color, value: value } }))
    const changeRainbowColor = (i, color) =>
        setRainbow((rainbow) => ({ ...rainbow, ['level' + i]: { color: color, value: rainbow['level' + i].value } }))

    const colorFields = () => {
        let c = {}
        let prevLowest = { value: null, index: null }
        for (let i = 0; i < Object.keys(rainbow).length; i++) {
            let key = 'level' + i
            if (rainbow[key].value && !prevLowest.value) {
                prevLowest.value = rainbow[key].value
                prevLowest.index = i
                continue
            }
            if (rainbow[key].value && prevLowest.value) {
                if (Number(rainbow[key].value) <= Number(prevLowest.value)) {
                    c['level' + prevLowest.index] = {
                        error: () => {
                            return true
                        },
                        message:
                            'Level ' +
                            (prevLowest.index + 1) +
                            ' min bet cannot be greater than or equal to Level ' +
                            (i + 1) +
                            ' min bet ',
                    }
                    c['level' + i] = {
                        error: () => {
                            return true
                        },
                        message:
                            'Level ' +
                            (i + 1) +
                            ' min bet cannot be less than or equal to Level ' +
                            (prevLowest.index + 1) +
                            ' min bet ',
                    }
                } else {
                    prevLowest.value = rainbow[key].value
                    prevLowest.index = i
                }
            }
        }
        return c
    }

    const [showColorPicker, setShowColorPicker] = useState(null)

    const colorValidation = validate(colorFields())
    validation.error = colorValidation.error ? true : validation.error
    validation.errors = { ...validation.errors, ...colorValidation.errors }

    const rainbowHtml = () => {
        return (
            <Box className={styles.sectionWrapper}>
                <Typography className={styles.sectionHeader}>Min Bet Color Thresholds</Typography>
                <Box className={styles.fieldRowWrapper}>
                    {Object.keys(rainbow).map((k, i) => (
                        <div key={k + 'div'} style={{ display: 'flex' }}>
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    onClick={() => setShowColorPicker(i)}
                                    variant='rounded'
                                    style={{
                                        backgroundColor: rainbow['level' + i].color,
                                        width: 50,
                                        height: 50,
                                        fontSize: 15,
                                        verticalAlign: 'middle',
                                        marginRight: 10,
                                    }}
                                >
                                    {i + 1}
                                </Avatar>
                                {showColorPicker === i && (
                                    <>
                                        <div style={{ position: 'absolute', top: 0, left: 40, zIndex: 100 }}>
                                            <SketchPicker
                                                color={rainbow['level' + i].color}
                                                onChange={(color) => {
                                                    changeRainbowColor(i, color.hex)
                                                    setChanged(changed + 1)

                                                }}
                                            />
                                        </div>
                                        <div
                                            onClick={() => setShowColorPicker(null)}
                                            style={{
                                                position: 'fixed',
                                                top: '0px',
                                                bottom: '0px',
                                                left: '0px',
                                                right: '0px',
                                                zIndex: 99,
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                            <Box width='100%'>
                                <Typography className={styles.fieldRowHeader}>
                                    Level {i + 1} min bet {i === 0 && <>(Lowest value)</>}
                                    {i === 4 && <>(Highest value)</>}
                                </Typography>
                                <TextField
                                    fullWidth
                                    size='small'
                                    type='text'
                                    variant='outlined'
                                    onFocus={() => onFocus('')}
                                    onBlur={(e) => onBlur(e, '')}
                                    onChange={(e) => {
                                        changeRainbowValue(i, e.target.value.replace(/\D/g, ''))
                                        setChanged(changed + 1)

                                    }}
                                    value={formatCountString(rainbow['level' + i].value)}
                                    disabled={loading}
                                    error={submitted && 'level' + i in validation.errors}
                                />
                            </Box>
                        </div>
                    ))}
                </Box>
            </Box>
        )
    }

    useEffect(() => {
        if (changed > 0 && template) {
            changeCallback(getFieldsValues(fields))
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </Box>
                )}

                {submitted && validation.error && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    </Box>
                )}

                <Box>
                    <Box className={styles.sectionWrapper}>
                        <Typography className={styles.sectionHeader}>Content Display</Typography>

                        <Box className={styles.fieldRowWrapper}>
                            {!template && <Box>
                                <Typography className={styles.fieldRowHeader}>Display Table Number</Typography>
                                <TextField
                                    size='small'
                                    id='tableNum'
                                    name='tableNum'
                                    type='text'
                                    fullWidth
                                    variant='outlined'
                                    onFocus={() => onFocus('tableNum')}
                                    onBlur={(e) => onBlur(e, 'tableNum')}
                                    onChange={(e) => {
                                        setTableNum(e.target.value)
                                        setChanged(changed + 1)
                                    }}
                                    value={tableNum}
                                    disabled={loading}
                                    error={submitted && fields.tableNum.error()}
                                />
                            </Box>}

                            <Box>
                                <Typography className={styles.fieldRowHeader}>Layout Selection</Typography>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Select
                                        id='layout-select'
                                        fullWidth
                                        value={layout}
                                        onChange={(e) => {
                                            setLayout(e.target.value)
                                            setChanged(changed + 1)

                                        }}
                                        MenuProps={{ MenuListProps: { className: styles.MenuListPropsPreview } }}
                                        inputProps={{
                                            name: 'layout',
                                            id: 'layout-select',
                                            className: styles.selectInputPreview,
                                        }}
                                        disabled={loading}
                                        error={submitted && fields.layout.error()}
                                        size='small'
                                    >
                                        {layouts && layouts.map((item) => {
                                            return (
                                                <MenuItem key={item.name} value={item.name}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img
                                                            alt=''
                                                            src={item.thumbnail}
                                                            style={{ height: 200, width: 300 }}
                                                        />
                                                        &nbsp;{item.name}
                                                    </div>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box>
                                <Typography className={styles.fieldRowHeader}>Theme Selection</Typography>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Select
                                        id='themes-select'
                                        fullWidth
                                        value={themes}
                                        onChange={(e) => {
                                            setTheme(e.target.value)
                                            setChanged(changed + 1)

                                        }}
                                        MenuProps={{ MenuListProps: { className: styles.MenuListPropsPreview } }}
                                        inputProps={{
                                            name: 'themes',
                                            id: 'themes-select',
                                            className: styles.selectInputPreview,
                                        }}
                                        disabled={loading}
                                        error={submitted && fields.themes.error()}
                                        size='small'
                                    >
                                        <MenuItem value='default'>Default (No Theme)</MenuItem>
                                        <MenuItem value='xmas'>Christmas</MenuItem>
                                        <MenuItem value='lunar'>Lunar New Year</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            {table.data.gameType === 'roulette' && (
                                <Box className={styles.toggleWrapper}>
                                    <Typography className={styles.fieldRowHeader}>
                                        Show Hot/Cold Neighbour Number
                                    </Typography>
                                    <FormControlLabel
                                        value='neighborSwitch'
                                        control={
                                            <Switch
                                                checked={showNeighbor}
                                                onChange={(e) => {
                                                    setShowNeighbor(e.target.checked)
                                                    setChanged(changed + 1)

                                                }}
                                            />
                                        }
                                    />
                                </Box>
                            )}

                            {!template && <ClosedTableUploader device={table} streams={streams} />}

                            {!template && <PIPUploader device={table} streams={streams} />}

                            {!template && <BetInfoUploader device={table} streams={streams} />}

                            <FormControl>
                                <Typography className={styles.fieldRowHeader} sx={{ mb: '0.5rem' }}>
                                    Time and Date Display
                                </Typography>
                                <RadioGroup
                                    value={timeOnly}
                                    onChange={(e) => {
                                        setTimeOnly(e.target.value === 'true' ? true : false)
                                        setChanged(changed + 1)

                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Time only'
                                        disabled={loading}
                                        style={{ marginLeft: 1 }}
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Time and Date'
                                        disabled={loading}
                                        style={{ marginLeft: 1 }}
                                    />
                                </RadioGroup>
                            </FormControl>

                            <Box>
                                <Typography className={styles.fieldRowHeader}>
                                    Bottom Screen Scrolling Text Display
                                </Typography>
                                <TextField
                                    size='small'
                                    id='disclaimerText'
                                    name='disclaimerText'
                                    type='text'
                                    multiline={true}
                                    fullWidth
                                    variant='outlined'
                                    onFocus={() => onFocus('disclaimerText')}
                                    onBlur={(e) => onBlur(e, 'disclaimerText')}
                                    onChange={(e) => {
                                        setDisclaimerText(e.target.value)
                                        setChanged(changed + 1)

                                    }}
                                    value={disclaimerText}
                                    disabled={loading}
                                    error={submitted && fields.disclaimerText.error()}
                                />

                                {table.data.gameType === 'war' && <Divider sx={{ margin: '2em 0' }} />}
                            </Box>

                            <Box>
                                <Typography className={styles.fieldRowHeader}>Bottom Screen Scrolling Text Speed</Typography>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Select
                                        id='disclaimerSpeed'
                                        fullWidth
                                        value={disclaimerSpeed}
                                        onChange={(e) => {
                                            setDisclaimerSpeed(e.target.value)
                                            setChanged(changed + 1)
                                        }}
                                        MenuProps={{ MenuListProps: { className: styles.MenuListPropsPreview } }}
                                        inputProps={{
                                            name: 'speed',
                                            id: 'speed-select',
                                            className: styles.selectInputPreview,
                                        }}
                                        disabled={loading}
                                        error={submitted && fields.themes.error()}
                                        size='small'
                                    >
                                        <MenuItem value='slow'>Slow</MenuItem>
                                        <MenuItem value='medium'>Medium</MenuItem>
                                        <MenuItem value='fast'>Fast</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>

                    {table.data.gameType !== 'war' && <Divider sx={{ margin: '2em 0' }} />}

                    {!template && <Box className={styles.sectionWrapper}>
                        <Typography className={styles.sectionHeader}>Trendboard Output Settings</Typography>

                        <Box>
                            <FieldRow
                                label='NDI Stream Name'
                                name='streamName'
                                value={streamOutputName}
                                handleChange={(e) => {
                                    setStreamOutputName(e.target.value)
                                    setChanged(changed + 1)

                                }}
                                loading={loading}
                                error={false}
                            />

                            {table.data.gameType === 'war' && <Divider sx={{ margin: '2em 0' }} />}
                        </Box>
                    </Box>}

                    {(table.data.gameType === 'baccarat' ||
                        table.data.gameType === 'blackjack' ||
                        table.data.gameType === 'roulette') && (
                            <>
                                {!template && <Divider sx={{ margin: '2em 0' }} />}

                                <Box className={styles.sectionWrapper}>
                                    <Typography className={styles.sectionHeader}>Features</Typography>

                                    <Box className={styles.fieldRowWrapper}>
                                        {table.data.gameType === 'baccarat' && (
                                            <Box className={styles.togglesWrapper}>
                                                <Box className={styles.toggleWrapper}>
                                                    <Typography className={styles.fieldRowHeader}>
                                                        Master Trend Animations
                                                    </Typography>
                                                    <FormControlLabel
                                                        className={styles.switchLabel}
                                                        value='animationsSwitch'
                                                        control={
                                                            <Switch
                                                                checked={showAnimations}
                                                                onChange={(e) => {
                                                                    setShowAnimations(e.target.checked)
                                                                    setChanged(changed + 1)

                                                                }}
                                                                inputProps={{ 'aria-label': 'display animations on/off' }}
                                                            />
                                                        }
                                                        disabled={loading}
                                                    />
                                                </Box>

                                                <Box className={styles.toggleWrapper}>
                                                    <Typography className={styles.fieldRowHeader}>
                                                        Trend Highlight
                                                    </Typography>
                                                    <FormControlLabel
                                                        className={styles.switchLabel}
                                                        value='trendHighlight'
                                                        control={
                                                            <Switch
                                                                checked={trendHighlight}
                                                                onChange={(e) => {
                                                                    setTrendHighlight(e.target.checked)
                                                                    setChanged(changed + 1)

                                                                }}
                                                                inputProps={{
                                                                    'aria-label': 'turn trend highlighting on/off',
                                                                }}
                                                            />
                                                        }
                                                        disabled={loading}
                                                    />
                                                </Box>

                                                <Box className={styles.toggleWrapper}>
                                                    <Typography className={styles.fieldRowHeader}>
                                                        Show Bet Timer Animation
                                                    </Typography>
                                                    <FormControlLabel
                                                        className={styles.switchLabel}
                                                        value='BetTimerAnimation'
                                                        control={
                                                            <Switch
                                                                checked={showBetTimerAnimation}
                                                                onChange={(e) => {
                                                                    setShowBetTimerAnimation(e.target.checked)
                                                                    setChanged(changed + 1)

                                                                }}
                                                            />
                                                        }
                                                        disabled={loading}
                                                    />
                                                </Box>

                                                <Box className={styles.toggleWrapper}>
                                                    <Typography className={styles.fieldRowHeader}>
                                                        Display Winning Hand Points on Big Road
                                                    </Typography>
                                                    <FormControlLabel
                                                        className={styles.switchLabel}
                                                        value='BigRoadNumbers'
                                                        control={
                                                            <Switch
                                                                checked={showBigRoadNumbers}
                                                                onChange={(e) => {
                                                                    setShowBigRoadNumbers(e.target.checked)
                                                                    setChanged(changed + 1)

                                                                }}
                                                            />
                                                        }
                                                        disabled={loading}
                                                    />
                                                </Box>
                                            </Box>
                                        )}

                                        {(table.data.gameType === 'baccarat' || table.data.gameType === 'blackjack') && (
                                            <Box>
                                                <Box className={styles.tooltipWrapper}>
                                                    <Typography className={styles.fieldRowHeader}>
                                                        Deck change notice display duration (seconds)
                                                    </Typography>
                                                    <Tooltip
                                                        title={
                                                            <span style={{ fontSize: '15px' }}>
                                                                The notice is disabled if the display length is set to 0
                                                            </span>
                                                        }
                                                        placement='right'
                                                    >
                                                        <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                                    </Tooltip>
                                                </Box>
                                                <TextField
                                                    size='small'
                                                    id='deckChangeTimer'
                                                    name='deckChangeTimer'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('deckChangeTimer')}
                                                    onBlur={(e) => onBlur(e, 'deckChangeTimer')}
                                                    onChange={(e) => {
                                                        setDeckChangeTimer(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={deckChangeTimer}
                                                    disabled={loading}
                                                    error={submitted && fields.deckchangeTimer.error()}
                                                />
                                            </Box>
                                        )}

                                        {table.data.gameType === 'roulette' && (
                                            <FormControl>
                                                <Typography className={styles.fieldRowHeader} sx={{ mb: '0.5rem' }}>
                                                    Hot and Cold Number Display
                                                </Typography>
                                                <RadioGroup
                                                    value={hotColdNumDisplay}
                                                    onChange={(e) => {
                                                        setHotColdNumDisplay(e.target.value)
                                                        setChanged(changed + 1)

                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value='both'
                                                        control={<Radio />}
                                                        label='Show Both Hot and Cold Numbers'
                                                        disabled={loading}
                                                        style={{ marginLeft: 1 }}
                                                    />
                                                    <FormControlLabel
                                                        value='hot'
                                                        control={<Radio />}
                                                        label='Show Hot Numbers only'
                                                        disabled={loading}
                                                        style={{ marginLeft: 1 }}
                                                    />
                                                    <FormControlLabel
                                                        value='cold'
                                                        control={<Radio />}
                                                        label='Show Cold Numbers only'
                                                        disabled={loading}
                                                        style={{ marginLeft: 1 }}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    </Box>
                                </Box>
                            </>
                        )}

                    {table.data.gameType === 'baccarat' && (
                        <>
                            <Divider sx={{ margin: '2em 0' }} />

                            <Box className={styles.sectionWrapper}>
                                <Typography className={styles.sectionHeader}>Insurance</Typography>

                                <Box className={styles.fieldRowWrapper}>
                                    <Box className={styles.toggleWrapper}>
                                        <Typography className={styles.fieldRowHeader}>
                                            Show Insurance Reminder
                                        </Typography>
                                        <FormControlLabel
                                            className={styles.switchLabel}
                                            value='showInsuranceBadge'
                                            control={
                                                <Switch
                                                    checked={showInsuranceBadge}
                                                    onChange={(e) => {
                                                        setShowInsuranceBadge(e.target.checked)
                                                        setChanged(changed + 1)

                                                    }}
                                                />
                                            }
                                            disabled={loading}
                                        />
                                    </Box>

                                    <Box>
                                        <Box className={styles.tooltipWrapper}>
                                            <Typography className={styles.fieldRowHeader}>
                                                Insurance reminder display duration (seconds)
                                            </Typography>
                                            <Tooltip
                                                title={<span style={{ fontSize: '15px' }}>Cannot be zero</span>}
                                                placement='right'
                                            >
                                                <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                            </Tooltip>
                                        </Box>
                                        <TextField
                                            size='small'
                                            id='insuranceReminderTimeout'
                                            name='insuranceReminderTimeout'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('insuranceReminderTimeout')}
                                            onBlur={(e) => onBlur(e, 'insuranceReminderTimeout')}
                                            onChange={(e) => {
                                                setInsuranceReminderTimeout(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)

                                            }}
                                            value={insuranceReminderTimeout}
                                            disabled={loading || !showInsuranceBadge}
                                            error={submitted && fields.insuranceReminderTimeout.error()}
                                        />
                                    </Box>

                                    <Box display='flex' flexDirection='column' gap='0.6em'>
                                        <Box className={styles.tooltipWrapper}>
                                            <Typography className={styles.fieldRowHeader}>
                                                Insurance reminder display delay time
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <span style={{ fontSize: '15px' }}>
                                                        Set to zero for cards face-up dealing. For squeeze cards
                                                        (face-down), using the same amount of time from card reveal
                                                        behavior is suggested.{' '}
                                                    </span>
                                                }
                                                placement='right'
                                            >
                                                <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                            </Tooltip>
                                        </Box>
                                        <Box display='flex' flexDirection='column' gap='1em'>
                                            <Box width='100%'>
                                                <Typography className={styles.fieldRowHeader}>
                                                    After four cards are dealt (seconds)
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='insuranceReminderDelayFourCards'
                                                    name='insuranceReminderDelayFourCards'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('insuranceReminderDelayFourCards')}
                                                    onBlur={(e) => onBlur(e, 'insuranceReminderDelayFourCards')}
                                                    onChange={(e) => {
                                                        setInsuranceReminderDelayFourCards(
                                                            e.target.value.replace(/\D/g, '')
                                                        )
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={insuranceReminderDelayFourCards}
                                                    disabled={loading || !showInsuranceBadge}
                                                    error={submitted && fields.insuranceReminderDelayFourCards.error()}
                                                />
                                            </Box>

                                            <Box width='100%'>
                                                <Typography className={styles.fieldRowHeader}>
                                                    After Player's third card is dealt (seconds)
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='insuranceReminderDelayPlayerThirdCard'
                                                    name='insuranceReminderDelayPlayerThirdCard'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('insuranceReminderDelayPlayerThirdCard')}
                                                    onBlur={(e) => onBlur(e, 'insuranceReminderDelayPlayerThirdCard')}
                                                    onChange={(e) => {
                                                        setInsuranceReminderDelayPlayerThirdCard(
                                                            e.target.value.replace(/\D/g, '')
                                                        )
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={insuranceReminderDelayPlayerThirdCard}
                                                    disabled={loading || !showInsuranceBadge}
                                                    error={
                                                        submitted &&
                                                        fields.insuranceReminderDelayPlayerThirdCard.error()
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider sx={{ margin: '2em 0' }} />

                            <Box className={styles.sectionWrapper}>
                                <Box className={styles.tooltipWrapper}>
                                    <Typography className={styles.sectionHeader}>Card Reveal Behaviour</Typography>
                                    <Tooltip
                                        title={
                                            <span style={{ fontSize: '15px' }}>
                                                Only applicable to Trendboard layouts that contain cards display
                                                component
                                            </span>
                                        }
                                        placement='right'
                                    >
                                        <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                    </Tooltip>
                                </Box>

                                <FormControl variant='outlined'>
                                    <Box className={styles.tooltipWrapper}>
                                        <Typography>Card Reveal</Typography>
                                        <Tooltip
                                            title={
                                                <span style={{ fontSize: '15px' }}>
                                                    Immediate (Face up game) <br />

                                                    Timed (Squeeze game. Reveal by timer)<br />

                                                    Trigger (Squeeze game. Reveal by dealer)
                                                </span>
                                            }
                                            placement='right'
                                        >
                                            <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                        </Tooltip>
                                    </Box>

                                    <Select
                                        id='card-reveal'
                                        fullWidth
                                        onChange={(e) => {
                                            setCardReveal(e.target.value)
                                            setChanged(changed + 1)

                                        }}
                                        inputProps={{
                                            name: 'cardReveal',
                                            id: 'card-reveal',
                                        }}
                                        value={cardReveal}
                                        disabled={loading}
                                        size='small'
                                    >
                                        <MenuItem value='immediate'>
                                            Immediate
                                        </MenuItem>
                                        <MenuItem value='timed' >
                                            Timed
                                        </MenuItem>
                                        <MenuItem value='trigger'>
                                            Trigger
                                        </MenuItem>
                                    </Select>
                                </FormControl>

                                <Box className={styles.fieldRowWrapper}>
                                    <Box display='flex' flexDirection='column' gap='0.6em'>
                                        <Box className={styles.tooltipWrapper}>
                                            <Typography className={styles.fieldRowHeader}>
                                                Reveal 1st and 2nd card timer
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <span style={{ fontSize: '15px' }}>
                                                        Cannot be zero
                                                    </span>
                                                }
                                                placement='right'
                                            >
                                                <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                            </Tooltip>
                                        </Box>
                                        <Box display='flex' gap='1em'>
                                            <Box width='100%'>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Banker hand (seconds)
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='timerBankerShowHand'
                                                    name='timerBankerShowHand'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('timerBankerShowHand')}
                                                    onBlur={(e) => onBlur(e, 'timerBankerShowHand')}
                                                    onChange={(e) => {
                                                        setTimerBankerShowHand(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={timerBankerShowHand}
                                                    disabled={loading || cardReveal === 'immediate' || cardReveal === 'trigger'}
                                                    error={submitted && fields.timerBankerShowHand.error()}
                                                />
                                            </Box>

                                            <Box width='100%'>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Player hand (seconds)
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='timerPlayerShowHand'
                                                    name='timerPlayerShowHand'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('timerPlayerShowHand')}
                                                    onBlur={(e) => onBlur(e, 'timerPlayerShowHand')}
                                                    onChange={(e) => {
                                                        setTimerPlayerShowHand(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={timerPlayerShowHand}
                                                    disabled={loading || cardReveal === 'immediate' || cardReveal === 'trigger'}
                                                    error={submitted && fields.timerPlayerShowHand.error()}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box display='flex' flexDirection='column' gap='0.6em'>
                                        <Box className={styles.tooltipWrapper}>
                                            <Typography className={styles.fieldRowHeader}>
                                                Reveal 3rd card timer
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <span style={{ fontSize: '15px' }}>
                                                        Cannot be zero
                                                    </span>
                                                }
                                                placement='right'
                                            >
                                                <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                            </Tooltip>
                                        </Box>
                                        <Box display='flex' gap='1em'>
                                            <Box width='100%'>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Banker hand (seconds)
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='timerShowBankerThirdCard'
                                                    name='timerShowBankerThirdCard'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('timerShowBankerThirdCard')}
                                                    onBlur={(e) => onBlur(e, 'timerShowBankerThirdCard')}
                                                    onChange={(e) => {
                                                        setTimerShowBankerThirdCard(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={timerShowBankerThirdCard}
                                                    disabled={loading || cardReveal === 'immediate' || cardReveal === 'trigger'}
                                                    error={submitted && fields.timerShowBankerThirdCard.error()}
                                                />
                                            </Box>

                                            <Box width='100%'>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Player hand (seconds)
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='timerShowPlayerThirdCard'
                                                    name='timerShowPlayerThirdCard'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('timerShowPlayerThirdCard')}
                                                    onBlur={(e) => onBlur(e, 'timerShowPlayerThirdCard')}
                                                    onChange={(e) => {
                                                        setTimerShowPlayerThirdCard(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={timerShowPlayerThirdCard}
                                                    disabled={loading || cardReveal === 'immediate' || cardReveal === 'trigger'}
                                                    error={submitted && fields.timerShowPlayerThirdCard.error()}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={styles.togglesWrapper}>
                                        <Box className={styles.toggleWrapper}>
                                            <Typography className={styles.fieldRowHeader}>
                                                Fullscreen Card Reveal Animation
                                            </Typography>
                                            <FormControlLabel
                                                className={styles.switchLabel}
                                                value='revealAnim'
                                                control={
                                                    <Switch
                                                        checked={revealAnim}
                                                        onChange={(e) => {
                                                            setRevealAnim(e.target.checked)
                                                            setChanged(changed + 1)

                                                        }}
                                                        inputProps={{ 'aria-label': 'turn reveal animation on/off' }}
                                                    />
                                                }
                                                disabled={loading}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Divider sx={{ margin: '2em 0' }} />
                        </>
                    )}

                    {(table.data.gameType === 'baccarat' || table.data.gameType === 'war') && (
                        <>
                            <Box className={styles.sectionWrapper}>
                                <Box className={styles.tooltipWrapper}>
                                    <Typography className={styles.sectionHeader}>
                                        Side Bet Meters (Gold Pots)
                                    </Typography>
                                    <Tooltip
                                        title={
                                            <span style={{ fontSize: '15px' }}>
                                                The value equals the number of games it takes to fill up the meter.
                                                <br />
                                                By default, these are games that are NOT the side bet.
                                                <br />
                                                e.g. If Tie = 10, it takes 10 non-Tie game results to fill up the Tie
                                                meter
                                                <br />
                                                Changes on these meter values will overwrite the side bet meter values
                                                on “Chase The Trend” tab
                                            </span>
                                        }
                                        placement='right'
                                    >
                                        <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                    </Tooltip>
                                </Box>

                                <Box className={styles.toggleWrapper}>
                                    <Typography className={styles.fieldRowHeader}>Show side bet meter value</Typography>

                                    <FormControlLabel
                                        className={styles.switchLabel}
                                        value='showSideBetMeterValue'
                                        control={
                                            <Switch
                                                checked={showSideBetMeterValue}
                                                onChange={(e) => {
                                                    setShowSideBetMeterValue(e.target.checked)
                                                    setChanged(changed + 1)

                                                }}
                                                inputProps={{ 'aria-label': 'side bet meter value toggle' }}
                                            />
                                        }
                                        disabled={loading}
                                    />
                                </Box>

                                <FormControl>
                                    <Typography className={styles.fieldRowHeader} sx={{ mb: '0.5rem' }}>
                                        Side bet meter value mode
                                    </Typography>
                                    <RadioGroup
                                        value={sideBetMeterValueMode}
                                        onChange={(e) => {
                                            setSideBetMeterValueMode(e.target.value)
                                            setChanged(changed + 1)

                                        }}
                                    >
                                        <FormControlLabel
                                            value='number'
                                            control={<Radio />}
                                            label='Number of games until the last side bet'
                                            style={{ marginLeft: 1 }}
                                            disabled={loading}
                                        />
                                        <FormControlLabel
                                            value='percentage'
                                            control={<Radio />}
                                            label='Percentage of filled pot'
                                            style={{ marginLeft: 1 }}
                                            disabled={loading}
                                        />
                                    </RadioGroup>
                                </FormControl>

                                <Box className={styles.fieldRowWrapper}>
                                    {(table.data.gameVariant === 'lucky6' ||
                                        table.data.gameVariant === 'lucky6Slot') && (
                                            <Box>
                                                <Typography className={styles.fieldRowHeader}>Lucky 6 meter</Typography>
                                                <TextField
                                                    size='small'
                                                    id='lucky6MeterMax'
                                                    name='lucky6MeterMax'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('lucky6MeterMax')}
                                                    onBlur={(e) => onBlur(e, 'lucky6MeterMax')}
                                                    onChange={(e) => {
                                                        setLucky6MeterMax(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={lucky6MeterMax}
                                                    disabled={loading}
                                                    error={
                                                        submitted &&
                                                        fields.meters[table.data.gameType][
                                                            table.data.gameVariant
                                                        ].lucky6MeterMax.error()
                                                    }
                                                />
                                            </Box>
                                        )}

                                    {table.data.gameVariant === 'mega6' && (
                                        <Box>
                                            <Typography className={styles.fieldRowHeader}>Mega 6 meter</Typography>
                                            <TextField
                                                size='small'
                                                id='mega6MeterMax'
                                                name='mega6MeterMax'
                                                type='text'
                                                fullWidth
                                                variant='outlined'
                                                onFocus={() => onFocus('mega6MeterMax')}
                                                onBlur={(e) => onBlur(e, 'mega6MeterMax')}
                                                onChange={(e) => {
                                                    setMega6MeterMax(e.target.value.replace(/\D/g, ''))
                                                    setChanged(changed + 1)

                                                }}
                                                value={mega6MeterMax}
                                                disabled={loading}
                                                error={
                                                    submitted &&
                                                    fields.meters[table.data.gameType][
                                                        table.data.gameVariant
                                                    ].mega6MeterMax.error()
                                                }
                                            />
                                        </Box>
                                    )}

                                    <Box>
                                        <Typography className={styles.fieldRowHeader}>Tie meter</Typography>
                                        <TextField
                                            size='small'
                                            id='tieMeterMax'
                                            name='tieMeterMax'
                                            type='text'
                                            fullWidth
                                            variant='outlined'
                                            onFocus={() => onFocus('tieMeterMax')}
                                            onBlur={(e) => onBlur(e, 'tieMeterMax')}
                                            onChange={(e) => {
                                                setTieMeterMax(e.target.value.replace(/\D/g, ''))
                                                setChanged(changed + 1)

                                            }}
                                            value={tieMeterMax}
                                            disabled={loading}
                                            error={
                                                submitted &&
                                                fields.meters[table.data.gameType][
                                                    table.data.gameVariant
                                                ].tieMeterMax.error()
                                            }
                                        />
                                    </Box>

                                    {table.data.gameVariant === 'tiger' && (
                                        <>
                                            <Box>
                                                <Typography className={styles.fieldRowHeader}>Tiger meter</Typography>
                                                <TextField
                                                    size='small'
                                                    id='tigerMeterMax'
                                                    name='tigerMeterMax'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('tigerMeterMax')}
                                                    onBlur={(e) => onBlur(e, 'tigerMeterMax')}
                                                    onChange={(e) => {
                                                        setTigerMeterMax(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={tigerMeterMax}
                                                    disabled={loading}
                                                    error={
                                                        submitted &&
                                                        fields.meters[table.data.gameType][
                                                            table.data.gameVariant
                                                        ].tigerMeterMax.error()
                                                    }
                                                />
                                            </Box>

                                            <Box>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Tiger Tie meter
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='tigerTieMeterMax'
                                                    name='tigerTieMeterMax'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('tigerTieMeterMax')}
                                                    onBlur={(e) => onBlur(e, 'tigerTieMeterMax')}
                                                    onChange={(e) => {
                                                        setTigerTieMeterMax(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={tigerTieMeterMax}
                                                    disabled={loading}
                                                    error={
                                                        submitted &&
                                                        fields.meters[table.data.gameType][
                                                            table.data.gameVariant
                                                        ].tigerTieMeterMax.error()
                                                    }
                                                />
                                            </Box>

                                            <Box>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Tiger Pair meter
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='tigerPairMeterMax'
                                                    name='tigerPairMeterMax'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('tigerPairMeterMax')}
                                                    onBlur={(e) => onBlur(e, 'tigerPairMeterMax')}
                                                    onChange={(e) => {
                                                        setTigerPairMeterMax(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={tigerPairMeterMax}
                                                    disabled={loading}
                                                    error={
                                                        submitted &&
                                                        fields.meters[table.data.gameType][
                                                            table.data.gameVariant
                                                        ].tigerPairMeterMax.error()
                                                    }
                                                />
                                            </Box>

                                            <Box>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Tiger Big meter
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='tigerBigMeterMax'
                                                    name='tigerBigMeterMax'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('tigerBigMeterMax')}
                                                    onBlur={(e) => onBlur(e, 'tigerBigMeterMax')}
                                                    onChange={(e) => {
                                                        setTigerBigMeterMax(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={tigerBigMeterMax}
                                                    disabled={loading}
                                                    error={
                                                        submitted &&
                                                        fields.meters[table.data.gameType][
                                                            table.data.gameVariant
                                                        ].tigerBigMeterMax.error()
                                                    }
                                                />
                                            </Box>

                                            <Box>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Tiger Small meter
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='tigerSmallMeterMax'
                                                    name='tigerSmallMeterMax'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('tigerSmallMeterMax')}
                                                    onBlur={(e) => onBlur(e, 'tigerSmallMeterMax')}
                                                    onChange={(e) => {
                                                        setTigerSmallMeterMax(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={tigerSmallMeterMax}
                                                    disabled={loading}
                                                    error={
                                                        submitted &&
                                                        fields.meters[table.data.gameType][
                                                            table.data.gameVariant
                                                        ].tigerSmallMeterMax.error()
                                                    }
                                                />
                                            </Box>
                                        </>
                                    )}

                                    {table.data.gameVariant !== 'tiger' && table.data.gameVariant !== 'etg' && (
                                        <>
                                            <Box>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Banker Pair meter
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='bankerPairMeterMax'
                                                    name='bankerPairMeterMax'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('bankerPairMeterMax')}
                                                    onBlur={(e) => onBlur(e, 'bankerPairMeterMax')}
                                                    onChange={(e) => {
                                                        setBankerPairMeterMax(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={bankerPairMeterMax}
                                                    disabled={loading}
                                                    error={
                                                        submitted &&
                                                        fields.meters[table.data.gameType][
                                                            table.data.gameVariant
                                                        ].bankerPairMeterMax.error()
                                                    }
                                                />
                                            </Box>

                                            <Box>
                                                <Typography className={styles.fieldRowHeader}>
                                                    Player Pair meter
                                                </Typography>
                                                <TextField
                                                    size='small'
                                                    id='playerPairMeterMax'
                                                    name='playerPairMeterMax'
                                                    type='text'
                                                    fullWidth
                                                    variant='outlined'
                                                    onFocus={() => onFocus('playerPairMeterMax')}
                                                    onBlur={(e) => onBlur(e, 'playerPairMeterMax')}
                                                    onChange={(e) => {
                                                        setPlayerPairMeterMax(e.target.value.replace(/\D/g, ''))
                                                        setChanged(changed + 1)

                                                    }}
                                                    value={playerPairMeterMax}
                                                    disabled={loading}
                                                    error={
                                                        submitted &&
                                                        fields.meters[table.data.gameType][
                                                            table.data.gameVariant
                                                        ].playerPairMeterMax.error()
                                                    }
                                                />
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Box>

                            {table.data.gameType !== 'war' && <Divider sx={{ margin: '2em 0' }} />}
                        </>
                    )}

                    {table.data.gameType === 'baccarat' && (
                        <>
                            {rainbowHtml()}

                            <Divider sx={{ margin: '2em 0' }} />
                        </>
                    )}

                    {table.data.gameType === 'baccarat' && (
                        <>
                            <Box className={styles.sectionWrapper}>
                                <Box className={styles.tooltipWrapper}>
                                    <Typography className={styles.sectionHeader}>Color Behaviour</Typography>
                                    <Tooltip
                                        title={
                                            <span style={{ fontSize: '15px' }}>
                                                Please refer to the XTrend User Guide for a detailed explanation of this
                                                feature
                                            </span>
                                        }
                                        placement='right'
                                    >
                                        <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                    </Tooltip>
                                </Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Typography className={styles.fieldRowHeader}>Edge Color Rules</Typography>
                                    <Select
                                        id='frameRule'
                                        fullWidth
                                        value={frameRule}
                                        onChange={(e) => {
                                            setFrameRule(e.target.value)
                                            setChanged(changed + 1)

                                        }}
                                        MenuProps={{ MenuListProps: { className: styles.MenuListProps } }}
                                        inputProps={{
                                            name: 'frameRule',
                                            id: 'frameRule',
                                        }}
                                        disabled={loading}
                                        error={submitted && fields.frameRule.error()}
                                        size='small'
                                    >
                                        <MenuItem value='-1'>Frame Off</MenuItem>
                                        <MenuItem value='0'>Default Ruleset</MenuItem>
                                        <MenuItem value='1'>Short Term Ruleset</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Typography className={styles.fieldRowHeader}>Color Scheme</Typography>
                                    <Select
                                        id='colorScheme'
                                        fullWidth
                                        value={colorScheme}
                                        onChange={(e) => {
                                            setColorScheme(e.target.value)
                                            setChanged(changed + 1)

                                        }}
                                        MenuProps={{ MenuListProps: { className: styles.MenuListProps } }}
                                        inputProps={{
                                            name: 'colorScheme',
                                            id: 'colorScheme',
                                        }}
                                        disabled={loading}
                                        error={submitted && fields.colorScheme.error()}
                                        size='small'
                                    >
                                        <MenuItem value='bankerRed'>Banker Red + Player Blue</MenuItem>
                                        <MenuItem value='bankerBlue'>Banker Blue + Player Red</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </>
                    )}
                </Box>

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={loading}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                            setDefaults(fields, defaultValues)
                            setRainbow(defaultValues.colorThresholds)
                            setChanged(changed + 1)
                        }}
                    >
                        Default
                    </Button>

                    {changed > 0 && (
                        <Button variant='contained' type='button' color='primary' disabled={loading} onClick={send}>
                            Update
                        </Button>
                    )}
                </div>}

                {loading && (
                    <CircularProgress
                        size={80}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                )}
            </form>
        </>
    )
}

export default Trendboard
