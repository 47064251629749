import React, { memo, } from 'react'
import { Route, Routes } from 'react-router-dom'
import R404 from '../404'
import Home from './Home'


export default memo(() => {
    return <>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route component={R404} />
        </Routes>
    </>
})