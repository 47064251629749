import _ from "lodash"

export const mapToID = dv => dv.index
export const findDevicesInTable = (devices, tableID) => devices.filter(dv => tableID === dv.data.containerIndex)
export const findGameDeviceInTable = (devices, tableID) => devices.filter(dv => tableID === dv.data.containerIndex && dv.data.type === 'game')
export const findTable = (tables, tableID) => tables.filter(dv => dv.index === tableID)
export const atLeastOneDevice = (devices) => (table) => findGameDeviceInTable(devices, table.index).length === 1
export const findTablesInZone = (pits, tables, zoneID) => findTablesInPits(tables, findPitsInZone(pits, zoneID).map(mapToID))
export const findPitsInZone = (pits, zoneID) => pits.filter(dv => dv.data.containerIndex === zoneID)
export const findTablesInPit = (tables, pitID) => tables.filter(dv => dv.data.containerIndex === pitID)
export const findDevicesInTables = (devices, subsetTables) => devices.filter(dv => subsetTables.indexOf(dv.data.containerIndex) >= 0).map(mapToID)
export const findTablesInPits = (tables, subsetPits) => tables.filter(dv => subsetPits.indexOf(dv.data.containerIndex) >= 0)
export const findTablesInLocation = (pits, tables, loc) => {
    if (loc.length !== 1) {
        return []
    }

    switch (loc[0].branchType) {
        case 'Zone':
            return findTablesInZone(pits, tables, loc[0].branchIndex)
        case 'Pit':
            return findTablesInPit(tables, loc[0].branchIndex)
        case 'Table':
            return findTable(tables, loc[0].branchIndex)
        default:
            return []
    }
}

export const getDescendantDevices = (pits, tables, devices, locationID, locationType) => {
    switch (locationType) {
        case 'Casino':
            return devices.map(mapToID)
        case 'Zone':
            const tbz = findTablesInZone(pits, tables, locationID).map(mapToID)
            return findDevicesInTables(devices, tbz)
        case 'Pit':
            const tbp = findTablesInPit(tables, locationID).map(mapToID)
            return findDevicesInTables(devices, tbp)
        case 'Table':
            return findDevicesInTables(devices, [locationID])
        default:
            return []
    }
}

export const getDescendantDevicesByGameType = (pits, tables, devices, locationID, locationType) => {
    switch (locationType) {
        case 'Casino':
            return {
                baccarat: devices.filter(dv => dv.data.gameType === 'baccarat').map(mapToID),
                blackjack: devices.filter(dv => dv.data.gameType === 'blackjack').map(mapToID)
            }
        case 'Zone':
            const tbz = findTablesInZone(pits, tables, locationID).map(mapToID)
            return {
                baccarat: findDevicesInTables(devices.filter(dv => dv.data.gameType === 'baccarat'), tbz),
                blackjack: findDevicesInTables(devices.filter(dv => dv.data.gameType === 'blackjack'), tbz)
            }
        case 'Pit':
            const tbp = findTablesInPit(tables, locationID).map(mapToID)
            return {
                baccarat: findDevicesInTables(devices.filter(dv => dv.data.gameType === 'baccarat'), tbp),
                blackjack: findDevicesInTables(devices.filter(dv => dv.data.gameType === 'blackjack'), tbp)
            }
        case 'Table':
            return findDevicesInTables(devices, [locationID])
        default:
            return []
    }
}

export const getDescendantDevicesByGameVariant = (pits, tables, devices, locationID, locationType) => {
    switch (locationType) {
        case 'Casino':
            return {
                baccarat: {
                    standard: devices
                        .filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'standard')
                        .map(mapToID),
                    lucky6: devices
                        .filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'lucky6')
                        .map(mapToID),
                    lucky6Slot: devices
                        .filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'lucky6Slot')
                        .map(mapToID),
                    mega6: devices
                        .filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'mega6')
                        .map(mapToID),
                    tiger: devices
                        .filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'tiger')
                        .map(mapToID),
                },
                blackjack: {
                    standard: devices
                        .filter((dv) => dv.data.gameType === 'blackjack' && dv.data.gameVariant === 'standard')
                        .map(mapToID),
                    anyPairSlot: devices
                        .filter((dv) => dv.data.gameType === 'blackjack' && dv.data.gameVariant === 'anyPairSlot')
                        .map(mapToID),
                },
                roulette: {
                    standard: devices
                        .filter((dv) => dv.data.gameType === 'roulette' && dv.data.gameVariant === 'standard')
                        .map(mapToID),
                },
                sicbo: {
                    standard: devices
                        .filter((dv) => dv.data.gameType === 'sicbo' && dv.data.gameVariant === 'standard')
                        .map(mapToID),
                },
                war: {
                    etg: devices
                        .filter((dv) => dv.data.gameType === 'war' && dv.data.gameVariant === 'etg')
                        .map(mapToID),
                }
            }
        case 'Zone':
            const tbz = findTablesInZone(pits, tables, locationID).map(mapToID)
            return {
                baccarat: {
                    standard: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'standard'),
                        tbz
                    ),
                    lucky6: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'lucky6'),
                        tbz
                    ),
                    lucky6Slot: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'lucky6Slot'),
                        tbz
                    ),
                    mega6: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'mega6'),
                        tbz
                    ),
                    tiger: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'tiger'),
                        tbz
                    ),
                },
                blackjack: {
                    standard: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'blackjack' && dv.data.gameVariant === 'standard'),
                        tbz
                    ),
                    anyPairSlot: findDevicesInTables(
                        devices.filter(
                            (dv) => dv.data.gameType === 'blackjack' && dv.data.gameVariant === 'anyPairSlot'
                        ),
                        tbz
                    ),
                },
                roulette: {
                    standard: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'roulette' && dv.data.gameVariant === 'standard'),
                        tbz
                    ),
                },
                sicbo: {
                    standard: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'sicbo' && dv.data.gameVariant === 'standard'),
                        tbz
                    ),
                },
                war: {
                    etg: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'war' && dv.data.gameVariant === 'etg'),
                        tbz
                    ),
                }
            }
        case 'Pit':
            const tbp = findTablesInPit(tables, locationID).map(mapToID)
            return {
                baccarat: {
                    standard: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'standard'),
                        tbp
                    ),
                    lucky6: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'lucky6'),
                        tbp
                    ),
                    lucky6Slot: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'lucky6Slot'),
                        tbp
                    ),
                    mega6: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'mega6'),
                        tbp
                    ),
                    tiger: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'baccarat' && dv.data.gameVariant === 'tiger'),
                        tbp
                    ),
                },
                blackjack: {
                    standard: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'blackjack' && dv.data.gameVariant === 'standard'),
                        tbp
                    ),
                    anyPairSlot: findDevicesInTables(
                        devices.filter(
                            (dv) => dv.data.gameType === 'blackjack' && dv.data.gameVariant === 'anyPairSlot'
                        ),
                        tbp
                    ),
                },
                roulette: {
                    standard: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'roulette' && dv.data.gameVariant === 'standard'),
                        tbp
                    ),
                },
                sicbo: {
                    standard: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'sicbo' && dv.data.gameVariant === 'standard'),
                        tbp
                    ),
                },
                war: {
                    etg: findDevicesInTables(
                        devices.filter((dv) => dv.data.gameType === 'war' && dv.data.gameVariant === 'etg'),
                        tbp
                    ),
                },
            }
        case 'Table':
            return findDevicesInTables(devices, [locationID])
        default:
            return []
    }
}

export const getGameType = (tables, devices, locationIndex, locationType) => {
    if (locationType && locationType.toLowerCase() !== "table") {
        return ""
    }
    const table = findTable(tables, locationIndex).map(mapToID)
    if (table.length === 0) {
        return ""
    }
    const devicesInTable = findDevicesInTable(devices, table[0])
    const gameDevices = devicesInTable.filter(dv => dv.data.type === "game")
    if (gameDevices.length === 0) {
        return ""
    }

    return gameDevices[0].data.gameType
}

export const getGameVariant = (tables, devices, locationIndex, locationType) => {
    if (locationType && locationType.toLowerCase() !== "table") {
        return ""
    }
    const table = findTable(tables, locationIndex).map(mapToID)
    if (table.length === 0) {
        return ""
    }
    const devicesInTable = findDevicesInTable(devices, table[0])
    const gameDevices = devicesInTable.filter(dv => dv.data.type === "game")
    if (gameDevices.length === 0) {
        return ""
    }

    return gameDevices[0].data.gameVariant
}

export const getAllIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter(v => indexes.includes(v.data.containerIndex)).map(v => v.index)
    const pitIndexes = !pits ? [] : pits.filter(v => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex)).map(v => v.index)
    const tableIndexes = !tables ? [] : tables.filter(v => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex)).map(v => v.index)
    const deviceIPs = !devices ? [] : devices.filter(v => v.data.type === 'game' && (
        indexes.includes(v.index) ||
        indexes.includes(v.data.containerIndex)
        || tableIndexes.includes(v.data.containerIndex)
        || pitIndexes.includes(v.data.containerIndex)
        || zoneIndexes.includes(v.data.containerIndex)
        || ['root'].includes(v.data.containerIndex)
    )).map(v => _.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099')
    return deviceIPs
}

export const getBacIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
            .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
            .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
            .filter(
                (v) =>
                    v.data.type === 'game' &&
                    v.data.gameType === 'baccarat' &&
                    (indexes.includes(v.index) ||
                        indexes.includes(v.data.containerIndex) ||
                        tableIndexes.includes(v.data.containerIndex) ||
                        pitIndexes.includes(v.data.containerIndex) ||
                        zoneIndexes.includes(v.data.containerIndex) ||
                        ['root'].includes(v.data.containerIndex))
            )
            .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getBjIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
            .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
            .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
            .filter(
                (v) =>
                    v.data.type === 'game' &&
                    v.data.gameType === 'blackjack' &&
                    (indexes.includes(v.index) ||
                        indexes.includes(v.data.containerIndex) ||
                        tableIndexes.includes(v.data.containerIndex) ||
                        pitIndexes.includes(v.data.containerIndex) ||
                        zoneIndexes.includes(v.data.containerIndex) ||
                        ['root'].includes(v.data.containerIndex))
            )
            .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getRouIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
            .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
            .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
            .filter(
                (v) =>
                    v.data.type === 'game' &&
                    v.data.gameType === 'roulette' &&
                    (indexes.includes(v.index) ||
                        indexes.includes(v.data.containerIndex) ||
                        tableIndexes.includes(v.data.containerIndex) ||
                        pitIndexes.includes(v.data.containerIndex) ||
                        zoneIndexes.includes(v.data.containerIndex) ||
                        ['root'].includes(v.data.containerIndex))
            )
            .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getSicboIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
            .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
            .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
            .filter(
                (v) =>
                    v.data.type === 'game' &&
                    v.data.gameType === 'sicbo' &&
                    (indexes.includes(v.index) ||
                        indexes.includes(v.data.containerIndex) ||
                        tableIndexes.includes(v.data.containerIndex) ||
                        pitIndexes.includes(v.data.containerIndex) ||
                        zoneIndexes.includes(v.data.containerIndex) ||
                        ['root'].includes(v.data.containerIndex))
            )
            .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getWarIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
            .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
            .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
            .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
            .filter(
                (v) =>
                    v.data.type === 'game' &&
                    v.data.gameType === 'war' &&
                    (indexes.includes(v.index) ||
                        indexes.includes(v.data.containerIndex) ||
                        tableIndexes.includes(v.data.containerIndex) ||
                        pitIndexes.includes(v.data.containerIndex) ||
                        zoneIndexes.includes(v.data.containerIndex) ||
                        ['root'].includes(v.data.containerIndex))
            )
            .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getBacStandardIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'baccarat' &&
                      v.data.gameVariant === 'standard' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getBacLucky6IPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'baccarat' &&
                      v.data.gameVariant === 'lucky6' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getBacLucky6SlotIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'baccarat' &&
                      v.data.gameVariant === 'lucky6Slot' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getBacMega6IPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'baccarat' &&
                      v.data.gameVariant === 'mega6' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getBacTigerIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'baccarat' &&
                      v.data.gameVariant === 'tiger' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getBjStandardIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'blackjack' &&
                      v.data.gameVariant === 'standard' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getBjAnyPairSlotIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'blackjack' &&
                      v.data.gameVariant === 'anyPairSlot' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getRouStandardIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'roulette' &&
                      v.data.gameVariant === 'standard' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getSicboStandardIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'sicbo' &&
                      v.data.gameVariant === 'standard' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}

export const getWarEtgIPs = (indexes, zones, pits, tables, devices) => {
    const zoneIndexes = !zones ? [] : zones.filter((v) => indexes.includes(v.data.containerIndex)).map((v) => v.index)
    const pitIndexes = !pits
        ? []
        : pits
              .filter((v) => zoneIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const tableIndexes = !tables
        ? []
        : tables
              .filter((v) => pitIndexes.includes(v.data.containerIndex) || indexes.includes(v.data.containerIndex))
              .map((v) => v.index)
    const deviceIPs = !devices
        ? []
        : devices
              .filter(
                  (v) =>
                      v.data.type === 'game' &&
                      v.data.gameType === 'war' &&
                      v.data.gameVariant === 'etg' &&
                      (indexes.includes(v.index) ||
                          indexes.includes(v.data.containerIndex) ||
                          tableIndexes.includes(v.data.containerIndex) ||
                          pitIndexes.includes(v.data.containerIndex) ||
                          zoneIndexes.includes(v.data.containerIndex) ||
                          ['root'].includes(v.data.containerIndex))
              )
              .map((v) => (_.get(v, 'data.port', null) ? v.data.ip + ':' + v.data.port : v.data.ip + ':3099'))
    return deviceIPs
}