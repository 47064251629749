import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PlayArrow } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import WarningIcon from '@mui/icons-material/Warning'
import { Button, LinearProgress } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import SnackbarContent from '@mui/material/SnackbarContent'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'

import { useSubscribe, usePublish, publish, xstadiumApi } from '../../api'
import { xstadiumIP } from '../../config'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const CustomTab = withStyles({
    root: {
        backgroundColor: '#343434',
        borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
    },
    selected: {
        backgroundColor: 'rgb(0, 161, 255)',
    },
})(Tab)

const styles = {
    form: {
        width: '100%',
        padding: '2rem',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
    },
    warningIcon: {
        color: '#f0cf81',
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: '100',
    },
    thumbnail: {
        minHeight: 180,
        maxHeight: 180,
    },
}

const LivePlay = ({ type }) => {
    // const { pathname } = useLocation()

    const [status, statusSocket] = useSubscribe(xstadiumIP + '/watchout/status')

    const [thumbnails, thumbnailsSocket] = useSubscribe(xstadiumIP + '/thumbnails/*')
    const create = usePublish(xstadiumIP + '/thumbnails/*')

    const active =
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        thumbnailsSocket &&
        thumbnailsSocket.readyState === WebSocket.OPEN

    const [loading, setLoading] = useState(false)
    const [playInProgress, setPlayInProgress] = useState(false)

    // tabs
    const [tabIndex, setTabIndex] = useState(0)
    const handleChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex)
    }

    // dialog
    const [thumnailDialog, setThumbnailDialog] = useState(false)
    const [selectedThumbnailItem, setSelectedThumbnailItem] = useState({})
    const [thumbnailFail, setThumbnailFail] = useState(null)
    const [img, setImg] = useState(null)

    // const role = window.localStorage.getItem('role')

    if (!active || !status || !thumbnails) {
        return <LinearProgress />
    }

    // if (pathname === '/xstadium/liveplay/demo' && role !== 'root') {
    //     return <Navigate to='/dashboard' />
    // }

    const inProgress =
        playInProgress || !status.data || !status.data.online || status.data.restarting || status.data.locked

    const img404 = '/images/x.png'

    const openDialog = (item) => {
        setSelectedThumbnailItem(item)
        if (item.thumbnail !== img404) {
            setImg(item.thumbnail)
        }
        setThumbnailDialog(true)
    }

    const onChangeImg = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader()
            reader.addEventListener('load', (theFile) => {
                var image = new Image()
                image.src = reader.result
                image.onload = function () {
                    var height = this.height
                    var width = this.width
                    if (height > 400 || width > 400) {
                        alert('Height and Width must not exceed 2000px.')
                        return false
                    }
                    setImg(reader.result)
                }
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const _pushThumbnail = async (selected) => {
        setLoading(true)
        try {
            if (!selected) {
                await create({
                    name: selectedThumbnailItem.name,
                    img,
                })
            } else {
                await publish(xstadiumIP + '/thumbnails/' + selectedThumbnailItem.thumbnailID, {
                    name: selectedThumbnailItem.name,
                    img,
                })
            }
        } catch (e) {
            console.warn('failed to store thumbnail', e)
            setThumbnailFail('failed to store thumbnail')
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 1200)
        }
    }

    const clearThumbnail = () => {
        if (loading) {
            return
        }
        setThumbnailDialog(false)
        setImg(null)
        setThumbnailFail(null)
        setSelectedThumbnailItem({})
    }

    const mappedAnimations = status.data.animations ? status.data.animations.map((item) => {
        const thumb = thumbnails.find((t) => t.data.name === item.Name)
        // TODO: we should have a fixed prefix to hide stuff
        if (item.Name.indexOf('animation_smalltiger') === 0 || item.Name.indexOf('animation_bigtiger') === 0) {
            return null
        }
        return {
            type: 'animation',
            name: item.Name,
            thumbnail: thumb ? thumb.data.img : img404,
            thumbnailID: thumb ? thumb.index : '',
        }
    }).filter((e) => e) : []

    const mappedMusics = status.data.music ? status.data.music.map((item) => {
        const thumb = thumbnails.find((t) => t.data.name === item)
        return {
            type: 'music',
            name: item,
            thumbnail: thumb ? thumb.data.img : img404,
            thumbnailID: thumb ? thumb.index : '',
        }
    }) : []

    const mappedBackgrounds = status.data.backgrounds ? status.data.backgrounds.map((item) => {
        const thumb = thumbnails.find((t) => t.data.name === item)
        return {
            type: 'background',
            name: item,
            thumbnail: thumb ? thumb.data.img : img404,
            thumbnailID: thumb ? thumb.index : '',
        }
    }) : []

    const mappedLayouts = status.data.layouts ? status.data.layouts.map((item) => {
        const thumb = thumbnails.find((t) => t.data.name === item)
        return {
            type: 'layout',
            name: item,
            thumbnail: thumb ? thumb.data.img : img404,
            thumbnailID: thumb ? thumb.index : '',
        }
    }) : []

    const mappedAnnouncements = status.data.announcements ? status.data.announcements.map((item) => {
        const thumb = thumbnails.find((t) => t.data.name === item)
        return {
            type: 'announcement',
            name: item.Name,
            thumbnail: thumb ? thumb.data.img : img404,
            thumbnailID: thumb ? thumb.index : '',
        }
    }) : []

    const mappedFeeds = status.data.feeds ? status.data.feeds.map((item) => {
        const thumb = thumbnails.find((t) => t.data.name === item)
        return {
            type: 'feed',
            name: item,
            thumbnail: thumb ? thumb.data.img : img404,
            thumbnailID: thumb ? thumb.index : '',
        }
    }) : []

    const MIN_WAIT_DELAY = 400
    const MIN_WAIT_DELAY_ANIMATION = 1500

    const playAnimation = async (animation) => {
        setPlayInProgress(true)
        try {
            const response = await xstadiumApi.get('watchout/play/animation/' + animation)
            if (response.status !== 200) {
                throw Error('play failed')
            }
            setTimeout(() => {
                setPlayInProgress(false)
            }, MIN_WAIT_DELAY_ANIMATION)
        } catch (e) {
            console.warn(e)
            setPlayInProgress(false)
        }
    }

    const playMusic = async (music) => {
        setPlayInProgress(true)
        try {
            const response = await xstadiumApi.get('watchout/play/music/' + music)
            if (response.status !== 200) {
                throw Error('play failed')
            }
            setTimeout(() => {
                setPlayInProgress(false)
            }, MIN_WAIT_DELAY)
        } catch (e) {
            console.warn(e)
            setPlayInProgress(false)
        }
    }

    const playBackground = async (background) => {
        setPlayInProgress(true)
        try {
            const response = await xstadiumApi.get('watchout/play/background/' + background)
            if (response.status !== 200) {
                throw Error('play failed')
            }
            // wait for the status to reflect the change
            setTimeout(() => {
                setPlayInProgress(false)
            }, MIN_WAIT_DELAY)
        } catch (e) {
            console.warn(e)
            setPlayInProgress(false)
        }
    }

    const playLayout = async (layout) => {
        setPlayInProgress(true)
        try {
            const response = await xstadiumApi.get('watchout/play/layout/' + layout)
            if (response.status !== 200) {
                throw Error('play failed')
            }
            setTimeout(() => {
                setPlayInProgress(false)
            }, MIN_WAIT_DELAY)
        } catch (e) {
            console.warn(e)
            setPlayInProgress(false)
        }
    }

    const playAnnouncement = async (announcement) => {
        setPlayInProgress(true)
        try {
            const response = await xstadiumApi.get('watchout/play/announcement/' + announcement)
            if (response.status !== 200) {
                throw Error('play failed')
            }
            setTimeout(() => {
                setPlayInProgress(false)
            }, MIN_WAIT_DELAY_ANIMATION)
        } catch (e) {
            console.warn(e)
            setPlayInProgress(false)
        }
    }

    const playFeed = async (feed) => {
        setPlayInProgress(true)
        try {
            let url = ''
            if (feed === "left_live") {
                url = 'watchout/left/live'
            }
            if (feed === "left_marketing") {
                url = 'watchout/left/marketing'
            }
            if (feed === "center_live") {
                url = 'watchout/center/live'
            }
            if (feed === "center_marketing") {
                url = 'watchout/center/marketing'
            }
            if (feed === "right_live") {
                url = 'watchout/right/live'
            }
            if (feed === "right_marketing") {
                url = 'watchout/right/marketing'
            }
            const response = await xstadiumApi.get(url)
            if (response.status !== 200) {
                throw Error('play failed')
            }
            setTimeout(() => {
                setPlayInProgress(false)
            }, MIN_WAIT_DELAY_ANIMATION)
        } catch (e) {
            console.warn(e)
            setPlayInProgress(false)
        }
    }

    const isPlayingFeed = (playback, name) => {
        if (name === "left_live" && playback.leftFeed === "live") {
            return true
        }

        if (name === "left_marketing" && playback.leftFeed === "marketing") {
            return true
        }

        if (name === "center_live" && playback.centerFeed === "live") {
            return true
        }

        if (name === "center_marketing" && playback.centerFeed === "marketing") {
            return true
        }

        if (name === "right_live" && playback.rightFeed === "live") {
            return true
        }

        if (name === "right_marketing" && playback.rightFeed === "marketing") {
            return true
        }

        return false
    }

    const mappedData =
        type === 'animation'
            ? mappedAnimations
            : type === 'music'
                ? mappedMusics
                : type === 'background'
                    ? mappedBackgrounds
                    : type === 'layout'
                        ? mappedLayouts
                        : type === 'announcement'
                            ? mappedAnnouncements
                            : type === 'feed'
                                ? mappedFeeds
                                : []

    const play = (name) => {
        switch (type) {
            case 'animation':
                playAnimation(name)
                break
            case 'music':
                playMusic(name)
                break
            case 'background':
                playBackground(name)
                break
            case 'layout':
                playLayout(name)
                break
            case 'announcement':
                playAnnouncement(name)
                break
            case 'feed':
                playFeed(name)
                break
            default:
        }
    }

    const playback = status.data.playback ?
        type === 'animation'
            ? status.data.playback.animation
            : type === 'music'
                ? status.data.playback.music
                : type === 'background'
                    ? status.data.playback.background
                    : type === 'layout'
                        ? status.data.playback.layout
                        : type === 'announcement'
                            ? status.data.playback.announcement
                            : type === "feed"
                                ? status.data.playback
                                : ''
        : ''

    console.log("requi", status)

    return (
        <Grid container spacing={2}>
            <Dialog
                key='dialogThumbnail'
                open={thumnailDialog}
                onClose={clearThumbnail}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Select {selectedThumbnailItem.type} thumbnail</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {thumbnailFail && (
                            <SnackbarContent
                                className={styles.fail}
                                message={
                                    <Typography component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {thumbnailFail}
                                    </Typography>
                                }
                            />
                        )}
                    </DialogContentText>
                    <DialogContentText>
                        Only pictures in (.png) format and maximum size w=400px h=400px are valid
                    </DialogContentText>
                    <Button disabled={playInProgress} variant='contained' component='label'>
                        Select {selectedThumbnailItem.name} Image
                        <input onChange={onChangeImg} type='file' accept='image/png' hidden />
                    </Button>
                    <div style={styles.previewContainerImg}>
                        {img && (
                            <img
                                alt='jackpot'
                                style={styles.imgPreview}
                                src={(img.indexOf('data:') < 0 ? 'http://' + xstadiumIP + '/' : '') + img}
                            ></img>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={playInProgress} onClick={clearThumbnail}>
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            playInProgress ||
                            img === selectedThumbnailItem.thumbnail ||
                            (selectedThumbnailItem.thumbnailID === '' && !img)
                        }
                        onClick={() => _pushThumbnail(selectedThumbnailItem.thumbnailID !== '')}
                        color='primary'
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs>
                <form
                    style={styles.form}
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault()
                    }}
                    autoComplete='off'
                >
                    <Box display='flex' flexDirection='column' gap='1.25rem'>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    {status.data.restarting && (
                                        <Alert severity='warning'>
                                            The server is currently restarting, please wait
                                        </Alert>
                                    )}
                                    {!status.data.restarting && !status.data.online && (
                                        <Alert severity='warning'>The server is currently offline</Alert>
                                    )}
                                    {!status.data.restarting && status.data.online && status.data.locked && (
                                        <Alert severity='warning'>Changes in process, please wait</Alert>
                                    )}
                                </Grid>
                            </Grid>
                            {(playInProgress || !status.data || status.data.restarting) && <LinearProgress />}
                        </Box>

                        <Box>
                            <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                                Live Play
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                Select content to play it in real-time. It will override the existing playlist.
                            </Typography>
                        </Box>

                        <Box display='flex' flexDirection='column' gap='1.5rem'>
                            <Tabs
                                sx={{
                                    background: '#2a2a2a',
                                    borderBottom: '2px solid rgb(0, 161, 255)',
                                }}
                                value={tabIndex}
                                onChange={handleChange}
                                textColor='inherit'
                                indicatorColor='rgb(0, 161, 255)'
                            >
                                <CustomTab label='Animation' component={Link} to={'/xstadium/liveplay/animation'} />
                                <CustomTab label='Music' component={Link} to={'/xstadium/liveplay/music'} />
                                <CustomTab label='Background' component={Link} to={'/xstadium/liveplay/background'} />
                                <CustomTab label='Layout' component={Link} to={'/xstadium/liveplay/layout'} />
                                <CustomTab
                                    label='Announcement'
                                    component={Link}
                                    to={'/xstadium/liveplay/announcement'}
                                />
                                <CustomTab label='Feeds' component={Link} to={'/xstadium/liveplay/feeds'} />
                                {/* {role === 'root' && (
                                    <CustomTab label='Demo' component={Link} to={'/xstadium/liveplay/demo'} />
                                )} */}
                            </Tabs>

                            <Grid container display='flex' gap='1rem'>
                                {mappedData.length === 0 && <Typography>No {type} found.</Typography>}
                                {mappedData.length > 0 &&
                                    mappedData.map((item) => {
                                        return (
                                            <Card key={`play-${type}-${item.name}`} sx={{ width: 345 }}>
                                                <div style={{ position: 'relative' }}>
                                                    <CardMedia
                                                        component='img'
                                                        alt={`play-${type}-${item.name}`}
                                                        src={
                                                            (item.thumbnail !== img404
                                                                ? 'http://' + xstadiumIP + '/'
                                                                : '') + item.thumbnail
                                                        }
                                                        style={styles.thumbnail}
                                                    />
                                                    <IconButton
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '0',
                                                            right: '0',
                                                            margin: '.25rem',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => openDialog(item)}
                                                        disabled={loading}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </div>

                                                <CardContent
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() => play(item.name)}
                                                        disabled={inProgress || (type !== "feed" && playback === item.name) || (type === "feed" && isPlayingFeed(playback, item.name))}
                                                    >
                                                        {item.name}
                                                    </Button>
                                                    {type === 'animation' && playback === item.name && (
                                                        <PlayArrow
                                                            style={{
                                                                marginTop: 5,
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                </CardContent>
                                            </Card>
                                        )
                                    })}
                            </Grid>
                        </Box>
                    </Box>
                </form>
            </Grid>
        </Grid>
    )
}

export default LivePlay
