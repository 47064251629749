import React, { useState } from 'react'
import moment from 'moment'
import PasswordChecklist from 'react-password-checklist'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import MuiAlert from '@mui/material/Alert'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import { styled, makeStyles, useTheme } from '@mui/styles'

import { useSubscribe, authApi } from '../api'
import IDXLogo from '../dashboard/idx_hor_wht.png'
import DashboardMenu from '../dashboard/Menu'
import DashboardRouter from '../dashboard/Router'
import LicensesMenu from '../licenses/Menu'
import LicensesRouter from '../licenses/Router'
import PosRouter from '../posui/Router'
import SettingsMenu from '../settings/Menu'
import SettingsRouter from '../settings/Router'
import UpdatesMenu from '../updates/Menu'
import UpdatesRouter from '../updates/Router'
import useAsyncState from '../useAsyncState'
import useIdle from '../useIdle'
import useInterval from '../useInterval'
import View from '../View'
import XmeterMenu from '../xmeter/Menu'
import XmeterRouter from '../xmeter/Router'
import XplayerMenu from '../xplayer/Menu'
import XplayerRouter from '../xplayer/Router'
import XsignageMenu from '../xsignage/Menu'
import XsignageRouter from '../xsignage/Router'
import XstadiumMenu from '../xstadium/Menu'
import XstadiumRouter from '../xstadium/Router'
import XtableMenu from '../xtable/Menu'
import XtableRouter from '../xtable/Router'
import XtrendMenu from '../xtrend/Menu'
import XtrendRouter from '../xtrend/Router'
import XuserMenu from '../xuser/Menu'
import XuserRouter from '../xuser/Router'
import UserView from '../xuser/users/UserView'
import XviewMenu from '../xview/Menu'
import XviewRouter from '../xview/Router'

const drawerWidth = 240

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const nanoToMomentTime = (time) => moment.unix(time / 1000000000)

const rootStyles = makeStyles((theme) => ({
    topBar: {
        paddingLeft: 10,
    },
    closeDrawerButton: {
        width: 40,
        position: 'absolute',
        right: 2,
        zIndex: 9999999,
        top: '2.8em',
    },
    userButtonContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
}))

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
})

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: !open ? theme.zIndex.drawer + 1 : 'inherit',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

// const DateDisplay = ({ time }) => (moment.unix(time / 1000000000).format('dddd, MMMM Do Y LTS'))

const Pivot = ({ to, policies, passwordChecklist }) => {
    const location = useLocation()
    const theme = useTheme()
    const navigate = useNavigate()
    const role = window.localStorage.getItem('role')
    const account = window.localStorage.getItem('account')
    const token = window.localStorage.getItem('token')
    const [time, socket] = useSubscribe(null)
    const [settings, settingsSocket] = useSubscribe('settings')
    const active =
        socket && socket.readyState === WebSocket.OPEN && settingsSocket && settingsSocket.readyState === WebSocket.OPEN
    const [menuOpen, setMenuOpen] = useState(false)
    const [menuOpenDebouncing, setMenuOpenDeboucing] = useState(false)
    const [menuOpenDebounced, setMenuOpenDebouced] = useState(false)
    const [userDrawerOpen, setUserDrawerOpen] = useState(false)
    const lights = window.localStorage.getItem('lights') === 'on'
    const styles = rootStyles({
        location,
        lights,
    })

    const xtableSectionsOrder = {
        overview: 1,
        statistics: 2,
        games: 3,
        trends: 4,
        view: 5,
        players: 6,
        slots: 7,
        dealers: 8,
    }

    const route =
        settings && settings.data.xtable.sections.sort((a, b) => xtableSectionsOrder[a] - xtableSectionsOrder[b])[0]

    // expired dialog
    const [expiredPasswordOpen, setExpiredPasswordOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    // expiry warning
    const [openExpiryWarning, setExpiryWarningOpen] = useState(false)
    const [calculatedExpiry, setCalculatedExpiry] = useAsyncState(false)

    // user profile
    const [fetchedProfile, setFetchedProfile] = useAsyncState(false)
    const [profile, setProfile] = useAsyncState(null)

    // idle listener
    const idle = useIdle({ deadline: policies.idleLogoutMinutes * 60000 })
    // almost idle listener
    const almostIdle = useIdle({ deadline: policies.idleLogoutMinutes * 60000 - 60000 })
    // const almostIdle = useIdle({ deadline: 2000 })

    // this will allow autologout by another login (single session)
    // and prevent autologout by innactive time
    useInterval(async () => {
        if (account === 'root') {
            return
        }
        try {
            const response = await authApi.get('beat', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            if (response.status === 401) {
                throw Error('beat failed, autologout')
            }
        } catch (e) {
            console.warn(e)
            // this should differentiate between network failure and autologout
            if (
                e.message === 'beat failed, autologout' ||
                e.message === 'Request failed with status code 401 Unauthorized'
            ) {
                navigate('/logout')
            }
        }
    }, 3000)

    const changePassword = async () => {
        try {
            setLoading(true)
            await authApi.put('password/' + account, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                json: { password: newPassword },
            })
            await setProfile(null)
            await setFetchedProfile(false)
            await setCalculatedExpiry(false)
            // setLoading(false)
        } catch (e) {
            // setFail(`Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'})`)
            setLoading(false)
        }
    }

    const fetchUserProfile = async () => {
        if (fetchedProfile) {
            return
        }

        setFetchedProfile(true)
        try {
            const data = await authApi
                .get('profile', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                .json()
            setTimeout(() => {
                setProfile(data)
            }, 400)
        } catch (e) {
            console.warn(e)
        }
    }

    fetchUserProfile()

    const handleExpiryWarningClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return
        }

        setExpiryWarningOpen(false)
    }

    const calculateExpiry = () => {
        if (!active || !time || !profile || !policies) {
            return
        }

        if (calculatedExpiry) {
            return
        }

        if (account === 'root') {
            setCalculatedExpiry(true)
            return
        }

        const now = nanoToMomentTime(time)
        const lastPasswordChange = nanoToMomentTime(profile.lastPasswordChange)
        const expiryTime = lastPasswordChange.add(policies.password.expiryAgeDays, 'days')
        const isPasswordExpired = policies.password.canExpire && expiryTime.isBefore(now)
        const warningBeforeExpiryTime = expiryTime.subtract(policies.password.warningDaysBeforeExpiry, 'days')
        const showWarningBeforeExpiry =
            policies.password.willThrowWarningsBeforeExpiry && warningBeforeExpiryTime.isBefore(now)

        if (showWarningBeforeExpiry && !isPasswordExpired) {
            setExpiryWarningOpen(true)
        }

        if (isPasswordExpired) {
            console.log('password expired')
            setExpiredPasswordOpen(true)
        }

        if (!isPasswordExpired) {
            setExpiredPasswordOpen(false)
        }

        if (!showWarningBeforeExpiry) {
            setExpiryWarningOpen(false)
        }

        setCalculatedExpiry(true)
    }

    calculateExpiry()

    if (menuOpen && !menuOpenDebouncing && !menuOpenDebounced) {
        setMenuOpenDeboucing(true)
        setTimeout(() => {
            setMenuOpenDebouced(true)
            setMenuOpenDeboucing(false)
        }, 300)
    }

    if (!menuOpen && !menuOpenDebouncing && menuOpenDebounced) {
        setMenuOpenDeboucing(true)
        setTimeout(() => {
            setMenuOpenDebouced(false)
            setMenuOpenDeboucing(false)
        }, 300)
    }

    if (idle && account !== 'root') {
        return <Navigate to='/logout' />
    }

    if (!active || !time || !profile || !policies || !calculatedExpiry) {
        return <LinearProgress />
    }

    if ((role === 'dealer' || role === 'pitboss') && to !== 'pos') {
        return <Navigate to='/posui/dashboard' />
    }

    return (
        <>
            <Dialog
                key='dialogChange'
                open={expiredPasswordOpen}
                onClose={() => { }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <br />
                        Please type and confirm your new password in order to proceed.
                    </DialogContentText>
                    <TextField
                        required
                        margin='dense'
                        id='newPassword'
                        label='New Password'
                        type='password'
                        fullWidth
                        variant='outlined'
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                        disabled={loading}
                        error={newPassword === ''}
                    />
                    <TextField
                        required
                        margin='dense'
                        id='confirmPassword'
                        label='Confirm New Password'
                        type='password'
                        fullWidth
                        variant='outlined'
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        disabled={loading}
                        error={newPassword !== confirmPassword || confirmPassword === ''}
                    />
                    {policies && (
                        <PasswordChecklist
                            style={{ marginTop: 30 }}
                            rules={passwordChecklist}
                            minLength={policies.password.minimumLength}
                            value={newPassword}
                            valueAgain={confirmPassword}
                            onChange={(isValid) => { }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        color='warning'
                        onClick={() => {
                            navigate('/logout')
                        }}
                    >
                        Exit
                    </Button>
                    <Button
                        disabled={newPassword === '' || newPassword !== confirmPassword}
                        onClick={changePassword}
                        color='primary'
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                key='almostIdle'
                open={almostIdle && account !== 'root'}
                onClose={() => {
                    return
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>No recent activity</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <br />
                        For security reasons if you continue to be idle you will be logged out of your session. Please
                        tap your touchscreen or move your cursor to stay logged in.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={openExpiryWarning}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={handleExpiryWarningClose}
            >
                <Alert onClose={handleExpiryWarningClose} severity='warning' sx={{ width: '100%' }}>
                    Your password will expire soon. Please reset your password now
                </Alert>
            </Snackbar>
            {to === 'pos' && <PosRouter />}
            {to !== 'pos' && (
                <>
                    <MuiDrawer
                        anchor={'right'}
                        variant={'temporary'}
                        open={userDrawerOpen}
                        onClose={() => {
                            setUserDrawerOpen(false)
                        }}
                    >
                        <IconButton
                            color='primary'
                            className={styles.closeDrawerButton}
                            onClick={() => setUserDrawerOpen(false)}
                            aria-label='close drawer'
                            component='label'
                        >
                            <CloseIcon />
                        </IconButton>
                        {profile && <UserView user={profile} goBack={() => setUserDrawerOpen(false)} />}
                    </MuiDrawer>
                    <AppBar open={menuOpen}>
                        <Toolbar className={styles.topBar}>
                            {!menuOpen && (
                                <IconButton
                                    sx={{
                                        color: 'white',
                                    }}
                                    onTouchStart={() => {
                                        // not sure why the touch event doesn't trigger click here
                                        setUserDrawerOpen(false)
                                        setMenuOpen(true)
                                    }}
                                    onClick={() => {
                                        setUserDrawerOpen(false)
                                        setMenuOpen(true)
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    sx={{
                                        marginLeft: '15px',
                                        backgroundSize: 'contain',
                                        height: '4.5vh',
                                        width: '15%',
                                        marginRight: '3vw',
                                        minWidth: '140px',
                                        background: `url(${IDXLogo}) no-repeat center left / contain`,
                                    }}
                                />
                                <Box className={styles.userButtonContainer}>
                                    <IconButton
                                        sx={{
                                            color: 'white',
                                        }}
                                        onClick={() => {
                                            setMenuOpen(false)
                                            setUserDrawerOpen(true)
                                        }}
                                    >
                                        <PersonIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant={'permanent'} open={menuOpen} style={{ position: 'absolute' }}>
                        {!to && <DashboardMenu location={location} setOpen={setMenuOpen} open={menuOpen} />}
                        {to === 'xview' && <XviewMenu location={location} setOpen={setMenuOpen} open={menuOpen} />}
                        {to === 'xplayer' && <XplayerMenu location={location} setOpen={setMenuOpen} open={menuOpen} />}
                        {to === 'updates' && <UpdatesMenu location={location} setOpen={setMenuOpen} open={menuOpen} />}
                        {to === 'licenses' && (
                            <LicensesMenu location={location} setOpen={setMenuOpen} open={menuOpen} />
                        )}
                        {to === 'xtrend' && <XtrendMenu location={location} setOpen={setMenuOpen} open={menuOpen} />}
                        {to === 'xtable' && <XtableMenu location={location} setOpen={setMenuOpen} open={menuOpen} />}
                        {to === 'xuser' && <XuserMenu location={location} setOpen={setMenuOpen} open={menuOpen} />}
                        {to === 'xsignage' && (
                            <XsignageMenu location={location} setOpen={setMenuOpen} open={menuOpen} />
                        )}
                        {to === 'xmeter' && <XmeterMenu location={location} setOpen={setMenuOpen} open={menuOpen} />}
                        {to === 'xstadium' && (
                            <XstadiumMenu location={location} setOpen={setMenuOpen} open={menuOpen} />
                        )}
                        {to === 'settings' && (
                            <SettingsMenu location={location} setOpen={setMenuOpen} open={menuOpen} />
                        )}
                    </Drawer>
                    <Container
                        maxWidth={'xl'}
                        style={{ overflow: 'hidden', paddingLeft: 56, marginTop: 66 }}
                        disableGutters={true}
                    >
                        <PerfectScrollbar
                            className='media-list overflow-hidden position-relative'
                            style={{}}
                            options={{
                                wheelPropagation: true,
                            }}
                        >
                            <Box component='main' sx={View(menuOpen, theme)} style={{ height: '100%' }}>
                                {!to && <DashboardRouter />}
                                {to === 'xview' && <XviewRouter />}
                                {to === 'xplayer' && <XplayerRouter />}
                                {to === 'updates' && <UpdatesRouter />}
                                {to === 'licenses' && <LicensesRouter />}
                                {to === 'xtrend' && <XtrendRouter />}
                                {to === 'xtable' && <XtableRouter route={route} />}
                                {to === 'xuser' && <XuserRouter menuOpen={menuOpen} setMenuOpen={setMenuOpen} />}
                                {to === 'xsignage' && <XsignageRouter />}
                                {to === 'xmeter' && <XmeterRouter />}
                                {to === 'xstadium' && <XstadiumRouter />}
                                {to === 'settings' && <SettingsRouter />}
                            </Box>
                        </PerfectScrollbar>
                    </Container>
                </>
            )}
        </>
    )
}

// need to load policies to know the idle logout setting before starting the event listeners
const PreloadPolicies = ({ status, to }) => {
    const [passwordChecklist, setPasswordChecklist] = useAsyncState([])

    // authentication policies
    const [policies, setPolicies] = useAsyncState(null)
    const [fetchedPolicies, setFetchedPolicies] = useAsyncState(false)

    const fetchPolicies = async () => {
        if (fetchedPolicies) {
            return
        }

        setFetchedPolicies(true)
        try {
            const token = window.localStorage.getItem('token')
            const response = await authApi
                .get('policies', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                .json()
            let newPasswordChecklist = ['match', 'minLength']
            if (response.password.upperAndLowerCase) {
                newPasswordChecklist.push('capital')
                newPasswordChecklist.push('lowercase')
            }
            if (response.password.numericCharacters) {
                newPasswordChecklist.push('number')
            }
            if (response.password.nonAlphanumericCharacters) {
                newPasswordChecklist.push('specialChar')
            }
            await setPasswordChecklist(newPasswordChecklist)
            await setPolicies(response)
        } catch (e) {
            console.warn(e)
        }
    }

    // reload the policies every 3 seconds to handle it changing while the user is logged in
    useInterval(async () => {
        const account = window.localStorage.getItem('account')
        if (account === 'root') {
            return
        }
        setFetchedPolicies(false)
    }, 3000)

    if (status === 'unauthorized') {
        return <Navigate to='/' />
    }

    fetchPolicies()

    if (!policies) {
        return <LinearProgress />
    }

    return <Pivot to={to} policies={policies} passwordChecklist={passwordChecklist} />
}

export default PreloadPolicies
