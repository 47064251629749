import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CircleIcon from '@mui/icons-material/Circle'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PowerIcon from '@mui/icons-material/Power'
import SquareIcon from '@mui/icons-material/Square'
import WarningIcon from '@mui/icons-material/Warning'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Fab from '@mui/material/Fab'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { publish, unpublish, usePublish } from '../../../api'
import { modules, pivotIP } from '../../../config'
import { containerTypeColor } from '../../../util/ContainerTypeColor'
import { formatCashString } from '../../../xtable/Formats'
import AlertDialog from '../../components/AlertDialog'
import { reservedFields } from './TreeNav'

const rootStyles = makeStyles((theme) => ({
    selfName: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'none',
                borderWidth: 0,
            },
            '&:hover fieldset': {
                borderColor: '#888',
                opacity: 0.5,
                borderWidth: 1,
            },
            '&.Mui-focused fieldset': {
                borderColor: '#888',
                borderWidth: 1,
            },
        },
    },
    restartGameButton: {
        position: 'relative',
        width: '40%',
        height: '75px',
        fontSize: '12px',
        color: 'white',
    },
    cardContainerData: {
        display: 'flex',
        margin: '10px',
        height: '100px',
        width: '100px',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        background: theme.palette.card.content,
        cursor: 'pointer',
    },
    indicator: {
        position: 'absolute',
        top: '0',
        right: '0',
        margin: '15px',
    },
    deviceName: {
        maxWidth: '15rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#fff',
        fontSize: '1.4rem',
        fontWeight: '600',
    },
    label: {
        color: '#fff',
        textTransform: 'uppercase',
    },
    online: {
        color: '#00ff00',
        fontWeight: '400',
        textTransform: 'uppercase',
    },
    offline: {
        color: '#ff1111',
        fontWeight: '400',
        textTransform: 'uppercase',
    },
    bet: {
        color: '#fff',
        fontSize: '1.1rem',
        fontWeight: '600',
        textTransform: 'uppercase',
    },
    actionButton: {
        width: '100px',
        height: '50px',
        float: 'right',
    },
    options: {
        width: '180px',
        minWidth: '150px',
        height: '100px',
        backgroundColor: '#7300B9',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#7300B9',
        },
    },
    selectedOptions: {
        backgroundColor: '#EBFF00',
        color: '#000',
        '&:hover': {
            backgroundColor: '#EBFF00',
        },
    },
}))

const Overview = ({ loading, branchSelect, selected, zones, pits, tables, devices, status }) => {
    const styles = rootStyles()

    const zonePublish = usePublish('zones/*')
    const tablePublish = usePublish('tables/*')
    const pitPublish = usePublish('pits/*')
    const devicePublish = usePublish('devices/*')

    const [validationErrors, setValidationErrors] = useState({})
    const [newDeviceName, setNewDeviceName] = useState('')
    const [newContainerName, setNewContainerName] = useState('')
    const [newSelfNameHasChanges, setNewSelfNameHasChanges] = useState(false)
    const [deleteWarning, setDeleteWarning] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogState, setDialogState] = useState({ message: '', yesFn: () => {}, noFn: () => {} })

    const [selectedIndex, setSelectedIndex] = useState('root')
    const [selectedContainerIndex, setSelectedContainerIndex] = useState(null)
    const [selectedType, setSelectedType] = useState('Casino')
    const [selectedName, setSelectedName] = useState('Casino')
    const [newSelfName, setNewSelfName] = useState('')
    const [inProgress, setInProgress] = useState(false)

    const [actionDialogOpen, setActionDialogOpen] = useState(false)
    const [actionDialogState, setActionDialogState] = useState({})
    const [actionDialogLoading, setActionDialogLoading] = useState(false)

    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => {},
    })

    const selectBranch = (selected) => {
        if (!selected) return
        setSelectedIndex(selected.index)
        setSelectedContainerIndex(selected.containerIndex)
        setSelectedType(selected.type)
        setSelectedName(selected.name)
        setNewDeviceName('')
        setNewContainerName('')
        setNewSelfName(selected.name)
        setNewSelfNameHasChanges(false)
        setDeleteWarning(false)
        setValidationErrors({})
    }

    //if selected from URL, we need to fetch a few additional fields
    if (!loading && selected.index !== selectedIndex) {
        const getObj = (selected) => {
            let obj = []
            switch (selected.type) {
                case 'Zone':
                    obj = zones.filter((v) => v.index === selected.index)
                    break
                case 'Pit':
                    obj = pits.filter((v) => v.index === selected.index)
                    break
                case 'Table':
                    obj = tables.filter((v) => v.index === selected.index)
                    break
                case 'Device':
                    obj = devices.filter((v) => v.index === selected.index)
                    break
                default:
                    console.log('selected casino root', selected.index, selectedIndex)
            }

            if (obj.length) {
                selected.containerIndex = obj[0].data.containerIndex
                selected.name = obj[0].data.name
            } else {
                selected.type = 'Casino'
                selected.name = 'Casino'
                selected.index = 'root'
                selected.containerIndex = null
            }
            return selected
        }
        selected = getObj(selected)
        selectBranch(selected)
    }

    const callPublish = async (data) => {
        const dataload = {
            Zone: {
                name: data.name,
                containerIndex: data.index,
                ...(data.type === 'Self' && { containerIndex: data.containerIndex }),
            },
            Pit: {
                name: data.name,
                containerIndex: data.index,
                ...(data.type === 'Self' && { containerIndex: data.containerIndex }),
            },
            Table: {
                name: data.name,
                containerIndex: data.index,
                ...(data.type === 'Self' && { containerIndex: data.containerIndex }),
            },
            Device: {
                name: data.name,
                containerIndex: data.index,
                containerType: data.containerType.toLowerCase(),
                type: 'other',
                ip: '',
                port: '3099',
            },
        }
        try {
            switch (data.itemType) {
                case 'Zone':
                    data.type === 'Self'
                        ? await publish('zones/' + data.index, dataload['Zone'])
                        : await zonePublish(dataload['Zone'])
                    break
                case 'Pit':
                    data.type === 'Self'
                        ? await publish('pits/' + data.index, dataload['Pit'])
                        : await pitPublish(dataload['Pit'])
                    break
                case 'Table':
                    data.type === 'Self'
                        ? await publish('tables/' + data.index, dataload['Table'])
                        : await tablePublish(dataload['Table'])
                    break
                case 'Device':
                    await devicePublish(dataload['Device'])
                    break
                default:
                    console.log('Not a valid type:', data.containerType)
            }
            console.log(dataload)
            data.success()
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            data.error()
        }
    }

    if (!loading) {
        status.sort((a, b) => {
            let g1 = a.data.game
            let g2 = b.data.game
            let t1 = a.data.trendboard
            let t2 = b.data.trendboard
            if (g1 === g2 && t1 === t2) {
                return 0
            }
            if (g1 > g2 || t1 > t2) {
                return 1
            }
            return -1
        })
    }

    const newNameValidation = (type, currentInput, childContainerType) => {
        const newItemName = currentInput ? currentInput.value.trim() : ''
        const containerType = type === 'Device' ? type : childContainerType

        if (newItemName.length < 1) {
            //textfield has no input
            setValidationErrors((v) => ({ ...v, [type]: null }))
            return
        }

        if (newItemName.length < 5) {
            setValidationErrors((v) => ({ ...v, [type]: 'short' }))
            return
        }

        if (reservedFields.includes(newItemName.toLowerCase())) {
            console.log('error, cannot have name', newItemName)
            setValidationErrors((v) => ({ ...v, [type]: true }))
            return
        }

        const dataObject =
            containerType === 'Zone'
                ? zones
                : containerType === 'Pit'
                ? pits
                : containerType === 'Table'
                ? tables
                : containerType === 'Device'
                ? devices
                : []
        const matches = dataObject.filter(
            (v) => v.data.name.toLowerCase() === newItemName.toLowerCase() && v.data.containerIndex === selectedIndex
        )

        if (matches.length > 0) {
            console.log('error, already have item of same name and type')
            setValidationErrors((v) => ({ ...v, [type]: true }))
            return
        }

        setValidationErrors((v) => ({ ...v, [type]: false }))
    }

    const getChildContainerType = () => {
        return selectedType === 'Casino'
            ? 'Zone'
            : selectedType === 'Zone'
            ? 'Pit'
            : selectedType === 'Pit'
            ? 'Table'
            : selectedType === 'Table'
            ? 'Device'
            : 'Device'
    }

    const getBranchColor = (containerType) => {
        return containerType === 'Casino'
            ? containerTypeColor.casino
            : containerType === 'Zone'
            ? containerTypeColor.zone
            : containerType === 'Pit'
            ? containerTypeColor.pit
            : containerType === 'Table'
            ? containerTypeColor.table
            : containerType === 'Device'
            ? containerTypeColor.device
            : '#fff'
    }

    const containerReduce = (container, selectedIndex, type) => {
        return container.reduce((c, v) => {
            const index = v.index && v.index.length > 0 ? v.index : null
            const name = v.data && v.data.name && v.data.name.length > 0 ? v.data.name : null
            const containerIndex =
                v.data && v.data.containerIndex && v.data.containerIndex.length > 0 ? v.data.containerIndex : null
            if (name && index && containerIndex && (containerIndex === selectedIndex || index === selectedIndex)) {
                c.push({ name: v.data.name, type: type, index: index, containerIndex: selectedIndex })
            }
            return c
        }, [])
    }

    const selectedContainerData =
        !selectedType || !selectedIndex || !selectedName || loading
            ? []
            : selectedType === 'Casino'
            ? containerReduce(zones, selectedIndex, 'Zone')
            : selectedType === 'Zone'
            ? containerReduce(pits, selectedIndex, 'Pit')
            : selectedType === 'Pit'
            ? containerReduce(tables, selectedIndex, 'Table')
            : []

    const selectedDevices =
        !selectedType || !selectedIndex || !selectedName || loading
            ? []
            : devices.reduce((c, v) => {
                  const index = v.index && v.index.length > 0 ? v.index : null
                  const name = v.data && v.data.name && v.data.name.length > 0 ? v.data.name : null
                  const containerIndex =
                      v.data && v.data.containerIndex && v.data.containerIndex.length > 0 ? v.data.containerIndex : null
                  if (
                      name &&
                      index &&
                      containerIndex &&
                      (containerIndex === selectedIndex || index === selectedIndex)
                  ) {
                      c.push(index)
                  }
                  return c
              }, [])

    const addNew = (type) => {
        const newItemName =
            type === 'Device'
                ? newDeviceName.trim()
                : type === 'Container'
                ? newContainerName.trim()
                : newSelfName.trim()
        const itemType = type === 'Device' ? 'Device' : type === 'Container' ? getChildContainerType() : selectedType
        const success =
            type === 'Device'
                ? () => {
                      setNewDeviceName('')
                  }
                : type === 'Container'
                ? () => {
                      setNewContainerName('')
                  }
                : () => {
                      setNewSelfNameHasChanges(false)
                  }

        if (newItemName.length < 1) {
            //textfield has no input
            return
        }
        if (validationErrors[type]) {
            //has errors
            return
        }
        //setLoading(true)
        callPublish({
            name: newItemName,
            itemType: itemType,
            containerType: selectedType,
            index: selectedIndex,
            containerIndex: selectedContainerIndex,
            type: type,
            success: () => {
                if (type === 'Self') setSelectedName(newItemName)
                success()
                document.activeElement.blur()
            },
            error: () => {
                console.log('failed')
            },
        })
        //const name = currentInput.value
        //const level = level
        //const index = index
        //console.log(name, level, type, index)
    }

    const deleteSelected = async (confirmed = false) => {
        if (containerDevicesMap.length > 0 || selectedContainerData.length > 0) {
            setDeleteWarning(true)
            return
        }

        if (!confirmed) {
            setDialogState({
                message: (
                    <>
                        Are you sure you want to delete <b>{selectedName}</b>?
                    </>
                ),
                yesFn: () => {
                    deleteSelected(true)
                    setInProgress(true)
                },
                noFn: () => {
                    setDialogOpen(false)
                },
            })
            setDialogOpen(true)
            return
        }

        try {
            switch (selectedType) {
                case 'Zone':
                    await unpublish('zones/' + selectedIndex)
                    break
                case 'Pit':
                    await unpublish('pits/' + selectedIndex)
                    break
                case 'Table':
                    await unpublish('tables/' + selectedIndex)
                    break
                case 'Device':
                    await unpublish('devices/' + selectedIndex)
                    break
                default:
                    console.log('Not a valid type:', selectedType)
            }
            setInProgress(false)
            setDialogOpen(false)
            branchSelect('Casino', '')
        } catch (e) {
            console.log(
                `Something went wrong (${
                    e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            setInProgress(false)
        }
    }

    const childContainerType = getChildContainerType()
    const childContainerColor = getBranchColor(childContainerType)
    const deviceColor = getBranchColor('Device')
    const containerColor = getBranchColor(selectedType)
    const containerDevicesMap = selectedDevices.map((v) => {
        const f = status.find((s) => s.index === v)
        return f
            ? f
            : {
                  index: v,
                  data: {
                      name: devices.filter((d) => d.index === v)[0].data.name,
                      type: 'pending',
                  },
              }
    })

    const closeTable = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            await fetch(`http://${pivotIP}/remote/device/close/${selectedDevice.index}`)
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...actionDialogState,
                show: true,
                type: 'error',
                message: 'Unable to close table. Please try again.',
                error: true,
            })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const openTable = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            await fetch(`http://${pivotIP}/remote/device/open/${selectedDevice.index}`)
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...actionDialogState,
                show: true,
                type: 'error',
                message: 'Unable to open table. Please try again.',
                error: true,
            })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const restartTrendboard = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            await fetch(`http://${pivotIP}/remote/restart/trendboard/${selectedDevice.index}`)
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...actionDialogState,
                show: true,
                type: 'error',
                message: 'Unable to restart trendboard. Please try again.',
                error: true,
            })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const rebootDevice = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            await fetch(`http://${pivotIP}/remote/reboot/${selectedDevice.index}`)
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            // setAlertDialogState({
            //     ...actionDialogState,
            //     show: true,
            //     type: 'error',
            //     message: 'Unable to reboot device. Please try again.',
            //     error: true
            // })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const shutdownDevice = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            await fetch(`http://${pivotIP}/remote/shutdown/${selectedDevice.index}`)
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...actionDialogState,
                show: true,
                type: 'error',
                message: 'Unable to shut down device. Please try again.',
                error: true,
            })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const shutdownPos = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            let address = selectedDevice.data.ip + ':' + selectedDevice.data.port
            await fetch('http://' + address + '/remote/shutdown')
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...actionDialogState,
                show: true,
                type: 'error',
                message: 'Unable to shutdown POS. Please try again.',
                error: true,
            })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const rebootPos = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            let address = selectedDevice.data.ip + ':' + selectedDevice.data.port
            await fetch('http://' + address + '/reboot')
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            // setAlertDialogState({
            //     ...actionDialogState,
            //     show: true,
            //     type: 'error',
            //     message: 'Unable to reboot POS. Please try again.',
            //     error: true
            // })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const rebootVision = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            let address = selectedDevice.data.ip + ':' + selectedDevice.data.port
            await fetch('http://' + address + '/reboot')
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            // setAlertDialogState({
            //     ...actionDialogState,
            //     show: true,
            //     type: 'error',
            //     message: `Unable to reboot${
            //         selectedDevice.data.type === 'visionServer'
            //             ? ' server.'
            //             : selectedDevice.data.type === 'visionCamera'
            //             ? ' camera.'
            //             : ''
            //     }. Please try again.`,
            //     error: true
            // })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const shutdownVision = async (selectedDevice) => {
        setActionDialogLoading(true)
        try {
            let address = selectedDevice.data.ip + ':' + selectedDevice.data.port
            await fetch('http://' + address + '/shutdown')
            setActionDialogOpen(false)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...actionDialogState,
                show: true,
                type: 'error',
                message: `Unable to shut down${
                    selectedDevice.data.type === 'visionServer'
                        ? ' server.'
                        : selectedDevice.data.type === 'visionCamera'
                        ? ' camera.'
                        : ''
                }. Please try again.`,
                error: true,
            })
        }
        setActionDialogLoading(false)
        setActionDialogState({})
    }

    const confirmAction = (selectedDeviceStatus, selectedDevice) => {
        if (actionDialogState.action === 'closeTable') {
            closeTable(selectedDevice)
        }

        if (actionDialogState.action === 'openTable') {
            openTable(selectedDevice)
        }

        if (actionDialogState.action === 'restartTrendboard') {
            restartTrendboard(selectedDeviceStatus)
        }

        if (actionDialogState.action === 'rebootDevice') {
            rebootDevice(selectedDeviceStatus)
        }

        if (actionDialogState.action === 'shutdownDevice') {
            shutdownDevice(selectedDeviceStatus)
        }

        if (actionDialogState.action === 'shutdownPos') {
            shutdownPos(selectedDeviceStatus)
        }

        if (actionDialogState.action === 'rebootPos') {
            rebootPos(selectedDeviceStatus)
        }

        if (actionDialogState.action === 'rebootVision') {
            rebootVision(selectedDeviceStatus)
        }

        if (actionDialogState.action === 'shutdownVision') {
            shutdownVision(selectedDeviceStatus)
        }
    }

    return (
        <>
            {selectedType !== 'Casino' && (
                <div
                    style={{
                        position: 'absolute',
                        top: `calc(40px)`,
                        right: 20,
                    }}
                >
                    <Tooltip title={<span style={{ fontSize: '15px' }}>Delete {selectedName}</span>}>
                        <Fab
                            onClick={() => {
                                deleteSelected()
                            }}
                            style={{ backgroundColor: '#ff3f3f', height: 40, width: 40 }}
                        >
                            <DeleteIcon style={{ color: '#fff' }} />
                        </Fab>
                    </Tooltip>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        key='topright'
                        open={deleteWarning}
                        autoHideDuration={6000}
                        onClose={() => {
                            setDeleteWarning(false)
                        }}
                    >
                        <Alert elevation={6} variant='filled' severity='warning'>
                            {'Cannot delete ' + selectedType + '. Please delete all contents within first.'}
                        </Alert>
                    </Snackbar>
                </div>
            )}

            {/* SELF NAME AND RENAMING */}
            <div style={{ margin: 20 }}>
                <span style={{ fontSize: 15, color: '#999', display: 'block', marginLeft: 10 }}>{selectedType}</span>
                {selectedType === 'Casino' && (
                    <span style={{ fontSize: 30, color: containerColor, display: 'block', marginLeft: 10 }}>
                        {selectedName}
                    </span>
                )}
                {selectedType !== 'Casino' && (
                    <form
                        onSubmit={
                            validationErrors['Self'] === false
                                ? (e) => {
                                      addNew('Self')
                                      e.preventDefault()
                                  }
                                : (e) => {
                                      e.preventDefault()
                                  }
                        }
                        spellCheck='false'
                    >
                        <Tooltip title={<span style={{ fontSize: '15px' }}>Rename {selectedName}</span>}>
                            <TextField
                                className={styles.selfName}
                                style={{ margin: 0, color: containerColor }}
                                size='small'
                                variant='outlined'
                                value={newSelfName}
                                onChange={(e) => {
                                    newNameValidation('Self', e.target, selectedType)
                                    setNewSelfName(e.target.value.replace(/[\WA-Z ]/g, ''))
                                    setNewSelfNameHasChanges(true)
                                }}
                                onFocus={(e) => {
                                    newNameValidation('Self', e.target, selectedType)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {newSelfNameHasChanges &&
                                                validationErrors &&
                                                typeof validationErrors['Self'] !== 'undefined' &&
                                                newSelfName.length > 0 && (
                                                    <>
                                                        {validationErrors['Self'] === false && (
                                                            <DoneIcon
                                                                onClick={() => {
                                                                    addNew('Self')
                                                                }}
                                                                style={{ marginLeft: 5, color: 'lime' }}
                                                            />
                                                        )}
                                                        {validationErrors['Self'] === 'short' && (
                                                            <MoreHorizIcon style={{ marginLeft: 5, color: '#666' }} />
                                                        )}
                                                        {validationErrors['Self'] === true && (
                                                            <CancelIcon style={{ marginLeft: 5, color: 'red' }} />
                                                        )}
                                                    </>
                                                )}
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    size: newSelfName.length ? newSelfName.length : 1,
                                    style: { width: 'auto', color: containerColor, fontSize: 30, padding: 10 },
                                }}
                                error={validationErrors && validationErrors['Self'] === true ? true : false}
                            />
                        </Tooltip>
                    </form>
                )}
            </div>

            {/* Display Containers in this container */}
            {selectedType !== 'Device' && selectedType !== 'Table' && modules.zoning && (
                <div style={{ margin: '40px 20px', border: '1px solid #444', borderRadius: 5 }}>
                    <header
                        style={{
                            backgroundColor: '#444',
                            background: 'linear-gradient(0deg, #444 75%, ' + childContainerColor + ' 200%)',
                            color: childContainerColor,
                            padding: 10,
                            paddingLeft: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>{childContainerType + 's'}</span>
                        <form
                            onSubmit={
                                validationErrors['Container'] === false
                                    ? (e) => {
                                          addNew('Container')
                                          e.preventDefault()
                                      }
                                    : (e) => {
                                          e.preventDefault()
                                      }
                            }
                            spellCheck='false'
                        >
                            <TextField
                                sx={{ margin: 0 }}
                                size='small'
                                label={'Add New ' + childContainerType}
                                variant='outlined'
                                value={newContainerName}
                                onChange={(e) => {
                                    newNameValidation('Container', e.target, childContainerType)
                                    setNewContainerName(e.target.value.replace(/[\WA-Z ]/g, ''))
                                }}
                                onFocus={(e) => {
                                    newNameValidation('Container', e.target, childContainerType)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {validationErrors &&
                                                typeof validationErrors['Container'] !== 'undefined' &&
                                                newContainerName.length > 0 && (
                                                    <>
                                                        {validationErrors['Container'] === false && (
                                                            <DoneIcon
                                                                onClick={() => {
                                                                    addNew('Container')
                                                                }}
                                                                style={{ marginLeft: 5, color: 'lime' }}
                                                            />
                                                        )}
                                                        {validationErrors['Container'] === 'short' && (
                                                            <MoreHorizIcon style={{ marginLeft: 5, color: '#666' }} />
                                                        )}
                                                        {validationErrors['Container'] === true && (
                                                            <CancelIcon style={{ marginLeft: 5, color: 'red' }} />
                                                        )}
                                                    </>
                                                )}
                                            {newContainerName.length === 0 && (
                                                <AddCircleIcon style={{ color: childContainerColor }} />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                                error={validationErrors && validationErrors['Container'] === true ? true : false}
                            />
                        </form>
                    </header>
                    <div>
                        {selectedContainerData.length > 0 && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: 10 }}>
                                {selectedContainerData.map((v, index) => {
                                    const gameDeviceInTable = devices?.filter(
                                        (device) =>
                                            device.data.type === 'game' && device.data.containerIndex === v.index
                                    )

                                    return (
                                        <Tooltip key={index} arrow title={v.name} placement='top'>
                                            <Card
                                                className={styles.cardContainerData}
                                                sx={{
                                                    borderTop:
                                                        selected.type === 'Pit'
                                                            ? gameDeviceInTable[0]
                                                                ? '3px solid ' + gameDeviceInTable[0].data.minBetColor
                                                                : 'none'
                                                            : '3px solid ' + childContainerColor,
                                                }}
                                                key={v.index}
                                                onClick={() => {
                                                    branchSelect(childContainerType, v.index)
                                                }}
                                            >
                                                <CardContent>{v.name}</CardContent>
                                            </Card>
                                        </Tooltip>
                                    )
                                })}
                            </div>
                        )}
                        {selectedContainerData.length === 0 && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: 10, justifyContent: 'center' }}>
                                No {childContainerType}s found in this {selectedType}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Display Devicees in this container */}
            <div
                style={{
                    margin: '40px 20px',
                    border: '1px solid #444',
                    borderRadius: 5,
                }}
            >
                <header
                    style={{
                        backgroundColor: '#444',
                        background: 'linear-gradient(0deg, #444 75%, ' + deviceColor + ' 200%)',
                        color: deviceColor,
                        padding: 10,
                        paddingLeft: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span>
                        {/* <PowerIcon style={{ verticalAlign: 'middle', marginLeft: '0', paddingRight: 5 }} /> */}
                        <PowerIcon sx={{ verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}>Devices</span>
                    </span>
                    <form
                        onSubmit={
                            validationErrors['Device'] === false
                                ? (e) => {
                                      addNew('Device')
                                      e.preventDefault()
                                  }
                                : (e) => {
                                      e.preventDefault()
                                  }
                        }
                        spellCheck='false'
                    >
                        <TextField
                            style={{ margin: 0 }}
                            size='small'
                            label='Add New Device'
                            variant='outlined'
                            value={newDeviceName}
                            onChange={(e) => {
                                newNameValidation('Device', e.target, childContainerType)
                                setNewDeviceName(e.target.value.replace(/[\WA-Z ]/g, ''))
                            }}
                            onFocus={(e) => {
                                newNameValidation('Device', e.target, childContainerType)
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {validationErrors &&
                                            typeof validationErrors['Device'] !== 'undefined' &&
                                            newDeviceName.length > 0 && (
                                                <>
                                                    {validationErrors['Device'] === false && (
                                                        <DoneIcon
                                                            onClick={() => {
                                                                addNew('Device')
                                                            }}
                                                            style={{ marginLeft: 5, color: 'lime' }}
                                                        />
                                                    )}
                                                    {validationErrors['Device'] === 'short' && (
                                                        <MoreHorizIcon style={{ marginLeft: 5, color: '#666' }} />
                                                    )}
                                                    {validationErrors['Device'] === true && (
                                                        <CancelIcon style={{ marginLeft: 5, color: 'red' }} />
                                                    )}
                                                </>
                                            )}
                                        {newDeviceName.length === 0 && <AddCircleIcon sx={{ color: deviceColor }} />}
                                    </InputAdornment>
                                ),
                            }}
                            error={validationErrors && validationErrors['Device'] === true ? true : false}
                        />
                    </form>
                </header>
                <div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: 10 }}>
                        {containerDevicesMap.map((v) => {
                            const matchedDeviceInfo = devices?.find((device) => device.index === v.index)

                            return (
                                <Card
                                    key={v.index}
                                    onClick={() => {
                                        branchSelect('Device', v.index)
                                    }}
                                    sx={{
                                        display: 'block',
                                        margin: '10px',
                                        padding: '1rem',
                                        minHeight: '310px',
                                        width: '370px',
                                        position: 'relative',
                                        borderTop: '3px solid ' + deviceColor,
                                        background: (theme) => theme.palette.card.content,
                                    }}
                                >
                                    {v.data.type === 'game' && modules.status && (
                                        <Box className={styles.indicator}>
                                            {matchedDeviceInfo.data.open && v.data.game && v.data.trendboard && (
                                                <CircleIcon style={{ fontSize: '4rem', color: 'lime' }} />
                                            )}
                                            {!matchedDeviceInfo.data.open && v.data.game && v.data.trendboard && (
                                                <SquareIcon sx={{ fontSize: '4rem', color: '#FF8A00' }} />
                                            )}
                                            {(matchedDeviceInfo.data.open || !matchedDeviceInfo.data.open) &&
                                                (!v.data.game || !v.data.trendboard) && (
                                                    <WarningIcon sx={{ fontSize: '4rem', color: 'red' }} />
                                                )}
                                        </Box>
                                    )}

                                    {(v.data.type === 'pos' ||
                                        v.data.type === 'visionServer' ||
                                        v.data.type === 'visionCamera' ||
                                        v.data.type === 'xstadiumContent' ||
                                        v.data.type === 'other') && (
                                        <Box className={styles.indicator}>
                                            {v.data.on && <CircleIcon style={{ fontSize: '4rem', color: 'lime' }} />}
                                            {!v.data.on && <WarningIcon sx={{ fontSize: '4rem', color: 'red' }} />}
                                        </Box>
                                    )}

                                    <Box
                                        height='100%'
                                        display='flex'
                                        flexDirection='column'
                                        justifyContent='space-between'
                                    >
                                        <Box display='flex' flexDirection='column' gap='1rem'>
                                            <Typography className={styles.deviceName}>{v.data.name}</Typography>

                                            <List disablePadding>
                                                <ListItem disablePadding>
                                                    <Typography variant='body2' className={styles.label}>
                                                        IP:&nbsp;&nbsp;
                                                    </Typography>
                                                    <Typography variant='body2' sx={{ fontWeight: '600' }}>
                                                        {!isEmpty(v.data.ip) ? v.data.ip : '-'}
                                                    </Typography>
                                                </ListItem>

                                                <ListItem disablePadding>
                                                    <Typography variant='body2' className={styles.label}>
                                                        Device Type:&nbsp;&nbsp;
                                                    </Typography>
                                                    <Typography
                                                        variant='body2'
                                                        sx={{ fontWeight: '600', textTransform: 'uppercase' }}
                                                    >
                                                        {!isEmpty(v.data.type) ? v.data.type : '-'}
                                                    </Typography>
                                                </ListItem>

                                                {v.data.type === 'game' && (
                                                    <ListItem disablePadding>
                                                        <Typography variant='body2' className={styles.label}>
                                                            Game Type:&nbsp;&nbsp;
                                                        </Typography>
                                                        <Typography
                                                            variant='body2'
                                                            sx={{ fontWeight: '600', textTransform: 'uppercase' }}
                                                        >
                                                            {!isEmpty(v.data.gameType) ? v.data.gameType : '-'}
                                                        </Typography>
                                                    </ListItem>
                                                )}

                                                {v.data.type === 'game' && (
                                                    <ListItem disablePadding>
                                                        <Typography variant='body2' className={styles.label}>
                                                            Game Variant:&nbsp;&nbsp;
                                                        </Typography>
                                                        <Typography
                                                            variant='body2'
                                                            sx={{ fontWeight: '600', textTransform: 'uppercase' }}
                                                        >
                                                            {!isEmpty(v.data.gameVariant) ? v.data.gameVariant : '-'}
                                                        </Typography>
                                                    </ListItem>
                                                )}

                                                {v.data.type === 'game' && modules.status && (
                                                    <>
                                                        <ListItem disablePadding>
                                                            <Typography variant='body2' className={styles.label}>
                                                                Version:&nbsp;&nbsp;
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {!isEmpty(v.data.gameVersion)
                                                                    ? v.data.gameVersion
                                                                    : '-'}
                                                            </Typography>
                                                        </ListItem>

                                                        <ListItem disablePadding>
                                                            <Typography variant='body2' className={styles.label}>
                                                                Table is&nbsp;
                                                            </Typography>
                                                            {matchedDeviceInfo.data.open ? (
                                                                <Typography variant='body2' className={styles.online}>
                                                                    Open
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant='body2' className={styles.offline}>
                                                                    Closed
                                                                </Typography>
                                                            )}
                                                        </ListItem>
                                                    </>
                                                )}
                                            </List>

                                            {v.data.type === 'game' && modules.status && (
                                                <List disablePadding>
                                                    <ListItem disablePadding>
                                                        <Typography variant='body2' className={styles.label}>
                                                            Device Status:&nbsp;&nbsp;
                                                        </Typography>
                                                        {v.data.game && v.data.trendboard ? (
                                                            <Typography variant='body2' className={styles.online}>
                                                                Online
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant='body2' className={styles.offline}>
                                                                Offline
                                                            </Typography>
                                                        )}
                                                    </ListItem>
                                                </List>
                                            )}
                                        </Box>

                                        <Box display='flex' alignItems='end' justifyContent='space-between'>
                                            {v.data.type === 'game' && (
                                                <Box width='100%'>
                                                    <ListItem disablePadding>
                                                        <Typography variant='body2' className={styles.bet}>
                                                            Min Bet:&nbsp;&nbsp;
                                                        </Typography>
                                                        <Typography variant='body2' className={styles.bet}>
                                                            {formatCashString(
                                                                matchedDeviceInfo.data.bets
                                                                    ? matchedDeviceInfo.data.bets[
                                                                          matchedDeviceInfo.data.gameType
                                                                      ][matchedDeviceInfo.data.gameVariant].minBet
                                                                    : matchedDeviceInfo.data.minbet
                                                            )}
                                                        </Typography>
                                                    </ListItem>

                                                    <ListItem disablePadding>
                                                        <Typography variant='body2' className={styles.bet}>
                                                            Max Bet:&nbsp;&nbsp;
                                                        </Typography>
                                                        <Typography variant='body2' className={styles.bet}>
                                                            {formatCashString(
                                                                matchedDeviceInfo.data.bets
                                                                    ? matchedDeviceInfo.data.bets[
                                                                          matchedDeviceInfo.data.gameType
                                                                      ][matchedDeviceInfo.data.gameVariant].maxBet
                                                                    : matchedDeviceInfo.data.maxbet
                                                            )}
                                                        </Typography>
                                                    </ListItem>
                                                </Box>
                                            )}

                                            {(v.data.type === 'game' ||
                                                v.data.type === 'pos' ||
                                                v.data.type === 'visionServer' ||
                                                v.data.type === 'visionCamera' ||
                                                v.data.type === 'other') && (
                                                <Box
                                                    width={
                                                        (v.data.type === 'game' && v.data.gameType === 'war') ||
                                                        v.data.type !== 'game'
                                                            ? '100%'
                                                            : '0'
                                                    }
                                                >
                                                    <Button
                                                        variant='contained'
                                                        className={styles.actionButton}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setActionDialogOpen(true)
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                status: v,
                                                                device: matchedDeviceInfo,
                                                            })
                                                        }}
                                                    >
                                                        Action
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>

                                    <Dialog
                                        open={actionDialogOpen}
                                        onClose={() => {
                                            setActionDialogOpen(false)
                                            setActionDialogState({})
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        aria-labelledby='alert-dialog-title'
                                        aria-describedby='alert-dialog-description'
                                        maxWidth={false}
                                    >
                                        <DialogTitle id='alert-dialog-title'>Please choose option</DialogTitle>
                                        <DialogContent>
                                            {actionDialogState.status?.data.type === 'game' && (
                                                <Box display='flex' flexDirection='column' gap='1em'>
                                                    <Box display='flex' justifyContent='space-between' gap='1em'>
                                                        {actionDialogState.device.data.open ? (
                                                            <Button
                                                                className={`${styles.options} ${
                                                                    actionDialogState.action === 'closeTable' &&
                                                                    styles.selectedOptions
                                                                }`}
                                                                onClick={() =>
                                                                    setActionDialogState({
                                                                        ...actionDialogState,
                                                                        action: 'closeTable',
                                                                    })
                                                                }
                                                            >
                                                                Close Table
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className={`${styles.options} ${
                                                                    actionDialogState.action === 'openTable' &&
                                                                    styles.selectedOptions
                                                                }`}
                                                                onClick={() =>
                                                                    setActionDialogState({
                                                                        ...actionDialogState,
                                                                        action: 'openTable',
                                                                    })
                                                                }
                                                            >
                                                                Open Table
                                                            </Button>
                                                        )}

                                                        <Button
                                                            className={`${styles.options} ${
                                                                actionDialogState.action === 'restartTrendboard' &&
                                                                styles.selectedOptions
                                                            }`}
                                                            onClick={() =>
                                                                setActionDialogState({
                                                                    ...actionDialogState,
                                                                    action: 'restartTrendboard',
                                                                })
                                                            }
                                                        >
                                                            Restart Trendboard
                                                        </Button>

                                                        <Button
                                                            className={`${styles.options} ${
                                                                actionDialogState.action === 'rebootDevice' &&
                                                                styles.selectedOptions
                                                            }`}
                                                            onClick={() =>
                                                                setActionDialogState({
                                                                    ...actionDialogState,
                                                                    action: 'rebootDevice',
                                                                })
                                                            }
                                                        >
                                                            Reboot Device
                                                        </Button>

                                                        <Button
                                                            className={`${styles.options} ${
                                                                actionDialogState.action === 'shutdownDevice' &&
                                                                styles.selectedOptions
                                                            }`}
                                                            onClick={() =>
                                                                setActionDialogState({
                                                                    ...actionDialogState,
                                                                    action: 'shutdownDevice',
                                                                })
                                                            }
                                                        >
                                                            Shutdown Device
                                                        </Button>
                                                    </Box>

                                                    <Box>
                                                        <Box display='flex'>
                                                            <Typography textTransform='uppercase'>
                                                                Game Server Status:&nbsp;
                                                            </Typography>
                                                            {actionDialogState.status?.data.game ? (
                                                                <Typography className={styles.online}>
                                                                    Online
                                                                </Typography>
                                                            ) : (
                                                                <Typography className={styles.offline}>
                                                                    Offline
                                                                </Typography>
                                                            )}
                                                        </Box>

                                                        <Box display='flex'>
                                                            <Typography textTransform='uppercase'>
                                                                Trendboard Status:&nbsp;
                                                            </Typography>
                                                            {actionDialogState.status?.data.trendboard ? (
                                                                <Typography className={styles.online}>
                                                                    Online
                                                                </Typography>
                                                            ) : (
                                                                <Typography className={styles.offline}>
                                                                    Offline
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )}

                                            {actionDialogState.status?.data.type === 'pos' && (
                                                <Box display='flex' justifyContent='space-between' gap='2em'>
                                                    <Button
                                                        className={`${styles.options} ${
                                                            actionDialogState.action === 'rebootPos' &&
                                                            styles.selectedOptions
                                                        }`}
                                                        onClick={() =>
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                action: 'rebootPos',
                                                            })
                                                        }
                                                    >
                                                        Reboot POS
                                                    </Button>
                                                    <Button
                                                        className={`${styles.options} ${
                                                            actionDialogState.action === 'shutdownPos' &&
                                                            styles.selectedOptions
                                                        }`}
                                                        onClick={() =>
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                action: 'shutdownPos',
                                                            })
                                                        }
                                                    >
                                                        Shutdown POS
                                                    </Button>
                                                </Box>
                                            )}

                                            {actionDialogState.status?.data.type === 'visionServer' && (
                                                <Box display='flex' justifyContent='space-between' gap='2em'>
                                                    <Button
                                                        className={`${styles.options} ${
                                                            actionDialogState.action === 'rebootVision' &&
                                                            styles.selectedOptions
                                                        }`}
                                                        onClick={() =>
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                action: 'rebootVision',
                                                            })
                                                        }
                                                    >
                                                        Reboot Server
                                                    </Button>

                                                    <Button
                                                        className={`${styles.options} ${
                                                            actionDialogState.action === 'shutdownVision' &&
                                                            styles.selectedOptions
                                                        }`}
                                                        onClick={() =>
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                action: 'shutdownVision',
                                                            })
                                                        }
                                                    >
                                                        Shutdown Server
                                                    </Button>
                                                </Box>
                                            )}

                                            {actionDialogState.status?.data.type === 'visionCamera' && (
                                                <Box display='flex' justifyContent='space-between' gap='2em'>
                                                    <Button
                                                        className={`${styles.options} ${
                                                            actionDialogState.action === 'rebootVision' &&
                                                            styles.selectedOptions
                                                        }`}
                                                        onClick={() =>
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                action: 'rebootVision',
                                                            })
                                                        }
                                                    >
                                                        Reboot Camera
                                                    </Button>

                                                    <Button
                                                        className={`${styles.options} ${
                                                            actionDialogState.action === 'shutdownVision' &&
                                                            styles.selectedOptions
                                                        }`}
                                                        onClick={() =>
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                action: 'shutdownVision',
                                                            })
                                                        }
                                                    >
                                                        Shutdown Camera
                                                    </Button>
                                                </Box>
                                            )}

                                            {actionDialogState.status?.data.type === 'other' && (
                                                <Box display='flex' justifyContent='space-between' gap='2em'>
                                                    <Button
                                                        className={`${styles.options} ${
                                                            actionDialogState.action === 'rebootDevice' &&
                                                            styles.selectedOptions
                                                        }`}
                                                        onClick={() =>
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                action: 'rebootDevice',
                                                            })
                                                        }
                                                    >
                                                        Reboot Device
                                                    </Button>

                                                    <Button
                                                        className={`${styles.options} ${
                                                            actionDialogState.action === 'shutdownDevice' &&
                                                            styles.selectedOptions
                                                        }`}
                                                        onClick={() =>
                                                            setActionDialogState({
                                                                ...actionDialogState,
                                                                action: 'shutdownDevice',
                                                            })
                                                        }
                                                    >
                                                        Shutdown Device
                                                    </Button>
                                                </Box>
                                            )}
                                        </DialogContent>

                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    setActionDialogOpen(false)
                                                    setActionDialogState({})
                                                }}
                                            >
                                                Cancel
                                            </Button>

                                            <LoadingButton
                                                loading={actionDialogLoading}
                                                disabled={isEmpty(actionDialogState.action)}
                                                onClick={() =>
                                                    confirmAction(actionDialogState.status, actionDialogState.device)
                                                }
                                            >
                                                Confirm
                                            </LoadingButton>
                                        </DialogActions>
                                    </Dialog>

                                    <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
                                </Card>
                            )
                        })}
                    </div>

                    {selectedDevices.length === 0 && (
                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: 10, justifyContent: 'center' }}>
                            No Devices found in this {selectedType}
                        </div>
                    )}
                </div>
            </div>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                {inProgress && <LinearProgress />}
                <DialogTitle>{dialogState.message}</DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => {
                            dialogState.noFn()
                        }}
                        color='primary'
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => {
                            dialogState.yesFn()
                        }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Overview
