import React, { useReducer, useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { useNavigate } from 'react-router-dom'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import SnackbarContent from '@mui/material/SnackbarContent'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { validate } from '../../../forms'
import AlertDialog from '../../components/AlertDialog'
import { defaultValues } from '../defaultValues'

const rootStyles = makeStyles((theme) => ({
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        width: ({ template }) => template ? 'inherit' : 900,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
    modal: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        margin: 50,
    },
    stickyTableHeaderCells: {
        position: 'sticky',
        top: 112,
        backgroundColor: '#333',
        zIndex: 1,
    },
    switchCols: { textAlign: 'center' },
    modalScroll: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        maxHeight: '100%',
        '&::-webkit-scrollbar': {
            width: 3,
            backgroundColor: '#333',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#888',
        },

        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: 10,
            backgroundColor: '#333',
        },
    },
    tableHeaderWrapper: {
        padding: '1.5rem 1rem 0.75rem 1rem',
    },
    tableHeader: {
        fontSize: 17,
        fontWeight: 'bold',
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.2em',
    },
}))

const TrendPriority = ({ publish, tables, table, settings, xstadium, template, changeCallback }) => {
    const navigate = useNavigate()

    const [displayMoreInfo, setDisplayMoreInfo] = useState(false)

    //----------
    // Styles
    //-----------

    const styles = rootStyles({ xstadium, template })

    //----------
    // Define States
    //-----------

    const [changed, setChanged] = useState(0)
    const [updated, setUpdated] = useState(-1)

    const [gamesLimit, setGamesLimit] = useState(
        table.data.gamesLimit ? cloneDeep(table.data.gamesLimit) : cloneDeep(defaultValues.gamesLimit)
    )
    const [trends, setTrends] = useState(
        table.data.trends ? cloneDeep(table.data.trends) : cloneDeep(defaultValues.trends)
    )
    const [hotTrends, setHotTrends] = useState(
        table.data.hotTrends ? cloneDeep(table.data.hotTrends) : cloneDeep(defaultValues.hotTrends)
    )

    console.log('TT', trends, table)

    const [, rerender] = useReducer((x) => (x ? false : true), false)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
        loading: false,
    })

    //----------
    // Render Control
    //-----------}

    //----------
    // Live data Control
    //-----------

    if (table.updated !== updated) {
        if (table.data.gamesLimit) {
            setGamesLimit(cloneDeep(table.data.gamesLimit))
        }

        if (table.data.trends) {
            setTrends(cloneDeep(table.data.trends))
        }

        if (table.data.hotTrends) {
            setHotTrends(cloneDeep(table.data.hotTrends))
        }

        rerender()
        setUpdated(table.updated)
    }

    //----------
    // UI EVENTS
    //-----------

    const getDefaultTrends = (gameType, gameVariant) => {
        const clonedDefaultTrends = cloneDeep(defaultValues.trends)
        const clonedTrends = cloneDeep(trends)
        Object.keys(clonedDefaultTrends[gameType][gameVariant]).forEach((key) => {
            clonedDefaultTrends[gameType][gameVariant][key].showOnTrendboard =
                clonedTrends[gameType][gameVariant][key].showOnTrendboard
            clonedDefaultTrends[gameType][gameVariant][key].showOnPit =
                clonedTrends[gameType][gameVariant][key].showOnPit
            clonedDefaultTrends[gameType][gameVariant][key].showOnZone =
                clonedTrends[gameType][gameVariant][key].showOnZone
            clonedDefaultTrends[gameType][gameVariant][key].showOnEvent =
                clonedTrends[gameType][gameVariant][key].showOnEvent
            clonedDefaultTrends[gameType][gameVariant][key].showOnXstadium =
                clonedTrends[gameType][gameVariant][key].showOnXstadium
        })
        return clonedDefaultTrends
    }

    const getDefaultHotTrends = (gameType, gameVariant) => {
        const clonedDefaultHotTrends = cloneDeep(defaultValues.hotTrends)
        const clonedHotTrends = cloneDeep(hotTrends)
        Object.keys(clonedDefaultHotTrends[gameType][gameVariant].trends).forEach((key) => {
            clonedDefaultHotTrends[gameType][gameVariant].trends[key].active =
                clonedHotTrends[gameType][gameVariant].trends[key].active
        })
        return clonedDefaultHotTrends
    }

    const setDefault = () => {
        setGamesLimit(defaultValues.gamesLimit)
        setTrends(getDefaultTrends(table.data.gameType, table.data.gameVariant))
        setHotTrends(getDefaultHotTrends(table.data.gameType, table.data.gameVariant))
        setChanged(changed + 1)
    }

    const getTrends = (gameType, gameVariant) => {
        const clonedTrends = cloneDeep(trends)
        const result = Object.keys(clonedTrends[gameType][gameVariant]).reduce((obj, key) => {
            obj[key] = clonedTrends[gameType][gameVariant][key]
            return obj
        }, {})
        return result
    }

    const filteredTrends = getTrends(table.data.gameType, table.data.gameVariant)

    //----------
    // API EVENTS
    //-----------

    const fields = {
        gamesLimit: {
            error: () => Number(gamesLimit) < 100 || Number(gamesLimit) > 500,
            value: gamesLimit,
            set: setGamesLimit,
            message:
                (Number(gamesLimit) < 100 && 'Value cannot be less than 100') ||
                (Number(gamesLimit) > 500 && 'Value cannnot be greater than 500'),
        },
    }

    const validation = validate(fields)

    const validateData = (data, gameType, gameVariant, props) => {
        const number = (v) => {
            return parseInt(String(v).replace(/\D/g, ''), 10)
        }

        const dataSet = props ? data[gameType][gameVariant][props] : data[gameType][gameVariant]

        Object.keys(dataSet).forEach((key) => {
            dataSet[key].priority = number(dataSet[key].priority)
        })

        console.log(data)
        return data
    }

    const [submitted, setSubmitted] = useState(false)
    const [postStatus, setPostStatus] = useState(false)

    const getFields = () => ({
        gamesLimit: Number(gamesLimit),
        trends: validateData(cloneDeep(trends), table.data.gameType, table.data.gameVariant),
        hotTrends: validateData(
            cloneDeep(hotTrends),
            table.data.gameType,
            table.data.gameVariant,
            'trends'
        ),
        updatedBy: window.localStorage.getItem('account') ?? '-',
    })
    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            try {
                setPostStatus(true)
                await publish({
                    ...table.data,
                    ...getFields(),
                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
                setChanged(0)
            } catch (e) {
                console.log(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
            } finally {
                setPostStatus(false)
            }
        }
    }

    const sortFunc = (a, b) => {
        if (a.priority < b.priority) {
            return -1
        }
        if (a.priority > b.priority) {
            return 1
        }
        return 0
    }

    const keysSortedByPriority = Object.keys(filteredTrends)
        .map((v) => ({ key: v, priority: filteredTrends[v].priority }))
        .sort(sortFunc)

    const getSideBetMeterKey = (sideBetTrendKey) => {
        const getBetType = (key) => {
            if (key.includes('superHot')) {
                return key.replace('superHot', '').toLowerCase()
            }
            if (key.includes('hot')) {
                return key.replace('hot', '').toLowerCase()
            }
        }

        if (getBetType(sideBetTrendKey) === 'tie') {
            return 'tieMeterMax'
        }
        if (table.data.gameVariant !== 'tiger') {
            if (getBetType(sideBetTrendKey) === 'bankerpair') {
                return 'bankerPairMeterMax'
            }
            if (getBetType(sideBetTrendKey) === 'playerpair') {
                return 'playerPairMeterMax'
            }
        }
        if (table.data.gameVariant === 'lucky6' || table.data.gameVariant === 'lucky6Slot') {
            if (getBetType(sideBetTrendKey) === 'luckysix') {
                return 'lucky6MeterMax'
            }
        }
        if (table.data.gameVariant === 'mega6') {
            if (getBetType(sideBetTrendKey) === 'megasix') {
                return 'mega6MeterMax'
            }
        }
        if (table.data.gameVariant === 'tiger') {
            if (getBetType(sideBetTrendKey) === 'tiger') {
                return 'tigerMeterMax'
            }
            if (getBetType(sideBetTrendKey) === 'tigertie') {
                return 'tigerTieMeterMax'
            }
            if (getBetType(sideBetTrendKey) === 'tigerpair') {
                return 'tigerPairMeterMax'
            }
            if (getBetType(sideBetTrendKey) === 'tigerbig') {
                return 'tigerBigMeterMax'
            }
            if (getBetType(sideBetTrendKey) === 'tigersmall') {
                return 'tigerSmallMeterMax'
            }
        }
    }

    const getReferenceValueFactor = (sideBetTrendKey) => {
        if (sideBetTrendKey.toLowerCase().includes('super')) {
            return 1
        } else return 0.75
    }

    const sidebetSortedByPriority = Object.keys(hotTrends[table.data.gameType][table.data.gameVariant].trends)
        .map((v) => ({
            key: v,
            priority: hotTrends[table.data.gameType][table.data.gameVariant].trends[v].priority,
            referenceValue:
                table.data.meters[table.data.gameType][table.data.gameVariant][getSideBetMeterKey(v)] *
                getReferenceValueFactor(v),
        }))
        .sort(sortFunc)

    const [draggedItem, setDraggedItem] = useReducer((state, obj) => {
        return obj ? obj : null
    }, null)
    const moveItemPriorityFromTo = (setName, set, setfn, from, to, key1, key2) => {
        if (from === to) return

        const dataSet =
            setName === 'hotTrends'
                ? set[table.data.gameType][table.data.gameVariant].trends
                : set[table.data.gameType][table.data.gameVariant]

        const newDataSet = Object.keys(dataSet).reduce((c, v, i) => {
            if (c[v]['priority'] === from) {
                return { ...c, [v]: { ...c[v], priority: to } }
            }
            if (to > from && c[v]['priority'] > from && c[v]['priority'] <= to) {
                return { ...c, [v]: { ...c[v], priority: c[v].priority - 1 } }
            }

            if (to < from && c[v]['priority'] < from && c[v]['priority'] >= to) {
                return { ...c, [v]: { ...c[v], priority: c[v].priority + 1 } }
            }
            return c
        }, dataSet)

        setfn((prev) => {
            const clonedPrevState = cloneDeep(prev)
            if (setName === 'hotTrends') {
                clonedPrevState[table.data.gameType][table.data.gameVariant].trends = cloneDeep(newDataSet)
            } else {
                clonedPrevState[table.data.gameType][table.data.gameVariant] = cloneDeep(newDataSet)
            }
            return clonedPrevState
        })
    }
    const dragProps = (rowProps) => ({
        //rowProps = { set: "predictionPriority", key: v.key, priority: v.priority }
        onDrag: (e) => {
            //console.log('OnDrag', rowProps)
            e.stopPropagation()
        },
        onDragStart: (e) => {
            //console.log('onDragStart', rowProps)
            //e.preventDefault();
            e.stopPropagation()
            setDraggedItem(rowProps)
        },
        onDrop: (e) => {
            if (!draggedItem || !rowProps || draggedItem.setName !== rowProps.setName) {
                return
            }
            //console.log('onDrop', rowProps)
            e.stopPropagation()
            e.preventDefault()
            moveItemPriorityFromTo(
                rowProps.setName,
                rowProps.set,
                rowProps.setfn,
                draggedItem.priority,
                rowProps.priority,
                draggedItem.key,
                rowProps.key
            )
            e.currentTarget.style.backgroundColor = ''
            //setDraggedItem(null)
        },
        onDragEnd: (e) => {
            if (!draggedItem || !rowProps || draggedItem.setName !== rowProps.setName) {
                return
            }
            setChanged(changed + 1)
            e.preventDefault()
            e.stopPropagation()
            setDraggedItem(null)
        },
        onDragOver: (e) => {
            if (!draggedItem || !rowProps || draggedItem.setName !== rowProps.setName) {
                return
            }
            //console.log('onDragOver', rowProps)
            e.preventDefault()
            e.stopPropagation()
            e.currentTarget.style.backgroundColor = '#555'
        },
        onDragLeave: (e) => {
            if (!draggedItem || !rowProps || draggedItem.setName !== rowProps.setName) {
                return
            }
            //console.log('onDragLeave', rowProps)
            e.stopPropagation()
            e.preventDefault()
            e.currentTarget.style.backgroundColor = ''
        },
        draggable: true,
        style: {
            cursor: 'grab',
        },
    })

    useEffect(() => {
        if (changed > 0 && template) {
            changeCallback(getFields())
        }
    }, [changed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {submitted && validation.error && postStatus === false && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} /> {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    </Box>
                )}

                <Box>
                    <Paper
                        sx={{
                            textAlign: 'center',
                            padding: '10px',
                            background: (theme) => theme.palette.card.background,
                        }}
                    >
                        <TextField
                            style={{ width: 300, margin: 10 }}
                            inputProps={{ style: { height: 30 }, 'data-testid': 'gamesLimit' }}
                            margin='dense'
                            id='gamesLimit'
                            name='gamesLimit'
                            label={'Number of Game History to Look Up'}
                            type='text'
                            fullWidth
                            variant='outlined'
                            onChange={(e) => {
                                setGamesLimit(Number(e.target.value.replace(/\D/g, '')))
                                setChanged(changed + 1)
                            }}
                            value={gamesLimit}
                            error={submitted && fields.gamesLimit.error()}
                        />
                    </Paper>

                    <br />

                    <Paper
                        sx={{
                            textAlign: 'center',
                            padding: '10px',
                            background: (theme) => theme.palette.card.background,
                        }}
                    >
                        {!displayMoreInfo && (
                            <Button
                                variant='contained'
                                color='info'
                                onClick={() => {
                                    setDisplayMoreInfo(true)
                                }}
                            >
                                More Information
                            </Button>
                        )}

                        {displayMoreInfo && (
                            <>
                                <Button
                                    variant='contained'
                                    color='error'
                                    onClick={() => {
                                        setDisplayMoreInfo(false)
                                    }}
                                >
                                    Close
                                </Button>
                                <p style={{ textAlign: 'left' }}>
                                    <br />
                                    Each trend is associated with a specific game result combination or trend. The
                                    priority of the occurrence of these trends can be adjusted. They are listed in
                                    descending order, with 1 being the highest priority.
                                    <br />
                                </p>
                            </>
                        )}
                    </Paper>

                    <br />

                    {/* The table displaying the priority order */}
                    <Paper
                        className='triggers'
                        sx={{
                            backgroundColor: 'inherit',
                        }}
                    >
                        <Box className={styles.tableHeaderWrapper}>
                            <Typography className={styles.tableHeader}>Trends Priority Setting</Typography>
                        </Box>
                        <Table>
                            <TableBody className='triggers-table'>
                                {keysSortedByPriority.map((v, index) => {
                                    const key = v.key
                                    const item = trends[table.data.gameType][table.data.gameVariant][key]

                                    return (
                                        <TableRow
                                            key={index}
                                            {...dragProps({
                                                setName: 'trends',
                                                set: trends,
                                                setfn: setTrends,
                                                key: key,
                                                priority: item.priority,
                                            })}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{ width: 25 }}>
                                                <DragHandleIcon />
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', width: 50, color: '#bbb' }}>
                                                {item.priority}
                                            </TableCell>
                                            <TableCell>{item.name}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>

                    <br />

                    <Paper
                        className='triggers'
                        sx={{
                            backgroundColor: 'inherit',
                        }}
                    >
                        <Box className={styles.tableHeaderWrapper}>
                            <Typography className={styles.tableHeader}>
                                "Chase The Trend" Side Bet Trends Priority Setting
                            </Typography>
                        </Box>
                        <Table>
                            <TableBody className='triggers-table'>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: 200 }} align='center'>
                                        <Box className={styles.tooltipWrapper}>
                                            <Typography>Reference Value</Typography>
                                            <Tooltip
                                                title={
                                                    <span style={{ fontSize: '15px' }}>
                                                        This value is calculated by multiplying the Hot level percentage
                                                        by the Side Bet Meter value of the trend. Levels “Super Hot”
                                                        equals 100% and “Hot” equals 75%. For example, if Tie’s Side Bet
                                                        Meter value is 8, the “Hot Tie” Reference Value will be 75% x 8
                                                        = 6.
                                                    </span>
                                                }
                                                placement='top'
                                            >
                                                <InfoIcon style={{ fontSize: 20, color: '#77aeff' }} />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>

                                {sidebetSortedByPriority.map((v, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            {...dragProps({
                                                setName: 'hotTrends',
                                                set: hotTrends,
                                                setfn: setHotTrends,
                                                key: v,
                                                priority: v.priority,
                                            })}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{ width: 25 }}>
                                                <DragHandleIcon />
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', width: 50, color: '#bbb' }}>
                                                {
                                                    hotTrends[table.data.gameType][table.data.gameVariant].trends[v.key]
                                                        .priority
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {v.key === 'superHotLuckySix' && <div>Super Hot Lucky 6</div>}
                                                {v.key === 'superHotMegaSix' && <div>Super Hot Mega 6</div>}
                                                {v.key === 'superHotTiger' && <div>Super Hot Tiger</div>}
                                                {v.key === 'superHotTigerBig' && <div>Super Hot Tiger Big</div>}
                                                {v.key === 'superHotTigerSmall' && <div>Super Hot Tiger Small</div>}
                                                {v.key === 'hotLuckySix' && <div>Hot Lucky 6</div>}
                                                {v.key === 'hotMegaSix' && <div>Hot Mega 6</div>}
                                                {v.key === 'hotTiger' && <div>Hot Tiger</div>}
                                                {v.key === 'hotTigerBig' && <div>Hot Tiger Big</div>}
                                                {v.key === 'hotTigerSmall' && <div>Hot Tiger Small</div>}
                                                {v.key === 'superHotTie' && <div>Super Hot Tie</div>}
                                                {v.key === 'superHotTigerTie' && <div>Super Hot Tiger Tie</div>}
                                                {v.key === 'superHotPlayerPair' && <div>Super Hot Player Pair </div>}
                                                {v.key === 'superHotBankerPair' && <div>Super Hot Banker Pair</div>}
                                                {v.key === 'superHotTigerPair' && <div>Super Hot Tiger Pair</div>}
                                                {v.key === 'hotTie' && <div>Hot Tie</div>}
                                                {v.key === 'hotTigerTie' && <div>Hot Tiger Tie</div>}
                                                {v.key === 'hotPlayerPair' && <div>Hot Player Pair</div>}
                                                {v.key === 'hotBankerPair' && <div>Hot Banker Pair</div>}
                                                {v.key === 'hotTigerPair' && <div>Hot Tiger Pair</div>}
                                                {v.key === ''}
                                            </TableCell>
                                            <TableCell sx={{ width: 200 }} align='center'>
                                                {Number.isInteger(v.referenceValue)
                                                    ? v.referenceValue
                                                    : v.referenceValue.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>

                {!template && <div className={styles.formButtonWrapper}>
                    <Button
                        color='inherit'
                        disabled={postStatus === true}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? table.data.containerType === 'casino'
                                        ? `/xtrend/${table.data.containerType}`
                                        : `/xtrend/${table.data.containerType}/${table.data.containerIndex}`
                                    : table.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='button'
                        color='primary'
                        onClick={() => {
                            setDefault()
                        }}
                    >
                        Default
                    </Button>

                    {changed > 0 && (
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={send}
                            disabled={postStatus}
                        >
                            update
                        </Button>
                    )}
                </div>}
            </form>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </>
    )
}

export default TrendPriority
