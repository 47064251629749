import React, { useState, memo } from 'react'
import WarningIcon from '@mui/icons-material/Warning'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import SnackbarContent from '@mui/material/SnackbarContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { useSubscribe, usePublish } from '../../api'
import { validate, update } from '../../forms'

const rootStyles = makeStyles((theme) => ({
    form: {
        width: 700,
        margin: '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    formButtonWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.6em',
    },
}))

const FieldRow = memo(({ name, label, value, handleChange, loading, error }) => {
    return (
        <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box width='100%'>
                <Typography>{label}:</Typography>
            </Box>
            <TextField
                sx={{
                    colorScheme: 'dark',
                }}
                margin='dense'
                size='small'
                id={name}
                name={name}
                type='text'
                inputProps={{
                    step: '0.5',
                }}
                variant='outlined'
                onChange={handleChange}
                value={value}
                disabled={loading}
                error={error}
            />
        </Box>
    )
})

const Settings = () => {
    const styles = rootStyles()

    const [targets, targetsSocket] = useSubscribe('targets')
    const publish = usePublish('targets')

    const active = targetsSocket && targetsSocket.readyState === WebSocket.OPEN

    // field flags
    const [submitted, setSubmitted] = useState(false)
    // form changed detect hook
    const [changed, setChanged] = useState(false)
    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(-1)

    const [january, setJanuary] = useState('')
    const [february, setFebruary] = useState('')
    const [march, setMarch] = useState('')
    const [april, setApril] = useState('')
    const [may, setMay] = useState('')
    const [june, setJune] = useState('')
    const [july, setJuly] = useState('')
    const [august, setAugust] = useState('')
    const [september, setSeptember] = useState('')
    const [october, setOctober] = useState('')
    const [november, setNovember] = useState('')
    const [december, setDecember] = useState('')

    if (!targets || !active) {
        return <LinearProgress />
    }

    const fields = {
        january: {
            error: () => january === '',
            value: january,
            set: setJanuary,
            message: 'It cannot be empty.',
        },
        february: {
            error: () => february === '',
            value: february,
            set: setFebruary,
            message: 'It cannot be empty.',
        },
        march: {
            error: () => march === '',
            value: march,
            set: setMarch,
            message: 'It cannot be empty.',
        },
        april: {
            error: () => april === '',
            value: april,
            set: setApril,
            message: 'It cannot be empty.',
        },
        may: {
            error: () => may === '',
            value: may,
            set: setMay,
            message: 'It cannot be empty.',
        },
        june: {
            error: () => june === '',
            value: june,
            set: setJune,
            message: 'It cannot be empty.',
        },
        july: {
            error: () => july === '',
            value: july,
            set: setJuly,
            message: 'It cannot be empty.',
        },
        august: {
            error: () => august === '',
            value: august,
            set: setAugust,
            message: 'It cannot be empty.',
        },
        september: {
            error: () => september === '',
            value: september,
            set: setSeptember,
            message: 'It cannot be empty.',
        },
        october: {
            error: () => october === '',
            value: october,
            set: setOctober,
            message: 'It cannot be empty.',
        },
        november: {
            error: () => november === '',
            value: november,
            set: setNovember,
            message: 'It cannot be empty.',
        },
        december: {
            error: () => december === '',
            value: december,
            set: setDecember,
            message: 'It cannot be empty.',
        },
    }

    const send = async () => {
        setSubmitted(true)
        if (!validation.error) {
            try {
                setLoading(true)
                await publish({
                    ...targets.data,
                    january: january,
                    february: february,
                    march: march,
                    april: april,
                    may: may,
                    june: june,
                    july: july,
                    august: august,
                    september: september,
                    october: october,
                    november: november,
                    december: december,
                })
                setFail('')
                setLoading(false)
            } catch (e) {
                setFail(
                    `Something went wrong (${
                        e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setLoading(false)
            }
        }
        setChanged(false)
    }

    const handleChange = (e, setState) => {
        setState(Number(e.target.value.replace(/\D/g, '')))
        setChanged(true)
    }

    const validation = validate(fields)

    if (targets.updated !== updated) {
        update(fields, [], targets)
        setUpdated(targets.updated)
    }

    return (
        <Box width='100%' padding='2rem' display='flex' flexDirection='column' gap='2rem'>
            <Typography variant='h5'>Targets</Typography>

            {fail && !loading && (
                <Box className={styles.warningContainer}>
                    <SnackbarContent
                        className={styles.fail}
                        message={
                            <Typography component='p' className={styles.warningText}>
                                <WarningIcon className={styles.warningIcon} /> {fail}
                            </Typography>
                        }
                    />
                </Box>
            )}

            <Box display='flex' flexDirection='column' gap='1rem'>
                <FieldRow
                    label='January'
                    name='january'
                    value={january}
                    handleChange={(e) => handleChange(e, setJanuary)}
                    loading={loading}
                    error={submitted && fields.january.error()}
                />
                <FieldRow
                    label='February'
                    name='february'
                    value={february}
                    handleChange={(e) => handleChange(e, setFebruary)}
                    loading={loading}
                    error={submitted && fields.february.error()}
                />
                <FieldRow
                    label='March'
                    name='march'
                    value={march}
                    handleChange={(e) => handleChange(e, setMarch)}
                    loading={loading}
                    error={submitted && fields.march.error()}
                />
                <FieldRow
                    label='April'
                    name='april'
                    value={april}
                    handleChange={(e) => handleChange(e, setApril)}
                    loading={loading}
                    error={submitted && fields.april.error()}
                />
                <FieldRow
                    label='May'
                    name='may'
                    value={may}
                    handleChange={(e) => handleChange(e, setMay)}
                    loading={loading}
                    error={submitted && fields.may.error()}
                />
                <FieldRow
                    label='June'
                    name='june'
                    value={june}
                    handleChange={(e) => handleChange(e, setJune)}
                    loading={loading}
                    error={submitted && fields.june.error()}
                />
                <FieldRow
                    label='July'
                    name='july'
                    value={july}
                    handleChange={(e) => handleChange(e, setJuly)}
                    loading={loading}
                    error={submitted && fields.july.error()}
                />
                <FieldRow
                    label='August'
                    name='august'
                    value={august}
                    handleChange={(e) => handleChange(e, setAugust)}
                    loading={loading}
                    error={submitted && fields.august.error()}
                />
                <FieldRow
                    label='September'
                    name='september'
                    value={september}
                    handleChange={(e) => handleChange(e, setSeptember)}
                    loading={loading}
                    error={submitted && fields.september.error()}
                />
                <FieldRow
                    label='October'
                    name='october'
                    value={october}
                    handleChange={(e) => handleChange(e, setOctober)}
                    loading={loading}
                    error={submitted && fields.october.error()}
                />
                <FieldRow
                    label='November'
                    name='november'
                    value={november}
                    handleChange={(e) => handleChange(e, setNovember)}
                    loading={loading}
                    error={submitted && fields.november.error()}
                />
                <FieldRow
                    label='December'
                    name='december'
                    value={december}
                    handleChange={(e) => handleChange(e, setDecember)}
                    loading={loading}
                    error={submitted && fields.december.error()}
                />
            </Box>

            <div className={styles.formButtonWrapper}>
                {changed && (
                    <Button variant='contained' type='button' color='primary' disabled={loading} onClick={send}>
                        Update
                    </Button>
                )}
            </div>

            {loading && (
                <CircularProgress
                    size={80}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                    }}
                />
            )}
        </Box>
    )
}

export default Settings
