import React, { useEffect, useState } from 'react'
import App from './App'
import * as hash from 'object-hash'
import { merge } from 'lodash'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LinearProgress } from '@mui/material'


const fetchLocal = (url) => {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
            resolve(new Response(xhr.responseText, { status: xhr.status }))
        }
        xhr.onerror = function () {
            reject(new TypeError('Local request failed'))
        }
        xhr.open('GET', url + '?' + (new Date().getTime()))
        xhr.send(null)
    })
}

const PreApp = () => {
    const [conf, setConf] = useState(null)

    const mergeNewConfigs = (nconf) => {
        //updates the immuted config with any newly added configs without changing values set in immuted config
        window.localStorage.setItem('config', JSON.stringify(merge(JSON.parse(JSON.stringify(nconf)), JSON.parse(window.localStorage.getItem('config')))))
    }
    useEffect(() => {
        const fetchConf = async () => {
            try {
                const response = await fetchLocal(process.env.PUBLIC_URL + '/conf.json')
                const nconf = await response.json()
                if (nconf) {
                    if (window.localStorage.getItem('immutedConfig')) mergeNewConfigs(nconf)
                    if (hash(nconf) !== hash(JSON.parse(window.localStorage.getItem('config'))) && (!window.localStorage.getItem('immutedConfig') || !window.localStorage.getItem('config'))) {
                        window.localStorage.setItem('config', JSON.stringify(nconf))
                        window.location.reload()
                    }
                    if (!conf) {
                        setConf(nconf)
                    }
                }
            } catch (e) {
                console.warn(e)
            }
        }

        if (!conf || !conf.domain) {
            fetchConf()
        }
    }, [setConf, conf])

    if (!conf) {
        return (<LinearProgress />)
    }

    return <>
        <App />
        <ToastContainer
            hideProgressBar
            position='top-right'
            autoClose={10000}
            closeOnClick
            pauseOnHover
            style={{ zIndex: 1000000 }}
        />
    </>
}
export default PreApp