import React, { useState } from 'react'
import { omit, keys, pick, lowerCase } from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import SnackbarContent from '@mui/material/SnackbarContent'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { unpublish } from '../../api'
import { validate, update } from '../../forms'
import { getDescendantDevices } from '../../tree/TreeUtils'
import AlertDialog from '../components/AlertDialog'
import { defaultValues } from './defaultValues'
import { calculateMinBetColor } from './minBetColor/color'
import { reservedFields } from './tree/TreeNav'

const rootStyles = makeStyles((theme) => ({
    warningContainer: {
        margin: '0 auto',
        maxWidth: 728,
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warning: {
        backgroundColor: '#f1932c',
        maxWidth: 'unset',
        marginBottom: 10,
        margin: '1em',
    },
    warningText: {
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: 100,
    },
    warningIcon: {
        verticalAlign: 'bottom',
        color: '#f0cf81',
    },
    form: {
        width: ({ template }) => template ? 'inherit' : 700,
        margin: ({ template }) => template ? '2em auto 0' : '5em auto 0',
        padding: ({ xstadium }) => (xstadium ? '0 1em 2.5em 1em' : '0 1em'),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '2em',
    },
    fieldRowWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5em',
    }
}))

const DateDisplay = ({ time }) => moment.unix(time / 1000000000).format('YYYY-MM-DD | h:mm A')

const Main = ({
    publish,
    device,
    zones,
    pits,
    tables,
    devices,
    settings,
    xstadium,
    template,
    changeCallback }) => {
    const styles = rootStyles({ xstadium, template })
    const navigate = useNavigate()

    // fields
    const [type, setType] = useState('')
    const [gameType, setGameType] = useState('')
    const [gameVariant, setGameVariant] = useState('')
    const [name, setName] = useState('')
    const [ip, setIP] = useState('')
    const [port, setPort] = useState('')


    // field flags
    const [editing, setEditing] = useState([])
    const [submitted, setSubmitted] = useState(false)

    // form flags
    const [fail, setFail] = useState('')
    const [loading, setLoading] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [updated, setUpdated] = useState(-1)
    const [hasChanges, setHasChanges] = useState(false)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
    })

    // component state
    const [loadedDevice, setLoadedDevice] = useState('')

    const [isRemoving, setIsRemoving] = useState(false)

    const fields = {
        type: {
            error: () => type.trim().length < 1,
            value: type,
            set: setType,
            message: 'Type cannot be empty',
        },
        name: {
            error: () => !name || name.trim().length < 5 || reservedFields.includes(name.trim().toLowerCase()),
            value: name,
            set: setName,
            message:
                !name || name.trim().length < 5
                    ? 'Device name cannot be less than 5 characters long'
                    : 'Invalid device name, please choose another',
        },
        ip: {
            error: () =>
                ip.trim().length < 5 ||
                devices.filter((v) => v.data.ip + ':' + v.data.port === ip + ':' + port && v.index !== device.index)
                    .length > 0,
            value: ip,
            set: setIP,
            message:
                ip.trim().length < 5 ? 'IP cannot be less than 5 characters' : 'IP + Port is used on another device',
        },
        port: {
            error: () =>
                port.trim().length < 2 ||
                devices.filter((v) => v.data.ip + ':' + v.data.port === ip + ':' + port && v.index !== device.index)
                    .length > 0,
            value: port,
            set: setPort,
            message:
                port.trim().length < 2
                    ? 'Port cannot be less than 2 characters'
                    : 'IP + Port is used on another device',
        },
        ...(((type === 'game' && submitted) || (device.data.type === 'game' && !submitted)) && {
            gameType: {
                error: () => gameType.trim().length < 1,
                value: gameType,
                set: setGameType,
                message: 'Game Type cannot be empty',
            },
            gameVariant: {
                error: () => gameVariant.trim().length < 1,
                value: gameVariant,
                set: setGameVariant,
                message: 'Game Variant cannot be empty',
            },
            ip: {
                error: () =>
                    ip.trim().length < 5 ||
                    devices.filter((v) => v.data.ip + ':' + v.data.port === ip + ':' + port && v.index !== device.index)
                        .length > 0,
                value: ip,
                set: setIP,
                message:
                    ip.trim().length < 5
                        ? 'IP cannot be less than 5 characters'
                        : 'IP + Port is used on another device',
            },
            port: {
                error: () =>
                    port.trim().length < 2 ||
                    devices.filter((v) => v.data.ip + ':' + v.data.port === ip + ':' + port && v.index !== device.index)
                        .length > 0,
                value: port,
                set: setPort,
                message:
                    port.trim().length < 2
                        ? 'Port cannot be less than 2 characters'
                        : 'IP + Port is used on another device',
            }
        }),
    }

    const validation = validate(fields)

    if (device.updated !== updated) {
        update(fields, editing, device.data, defaultValues)
        if (editing.length === 0) {
            setUpdated(device.updated)
        }
    }

    const onFocus = (key) => setEditing([...editing, key])

    const onBlur = (e, key) =>
        e.target.value === device.data[key] ? setEditing(editing.filter((field) => field !== key)) : null

    const isGameDeviceExist = () => {
        const devicesInTable = devices.filter((v) => v.data.containerIndex === device.data.containerIndex)

        return devicesInTable.filter((v) => v.data.type === 'game').length > 0 ? true : false
    }

    const etgZone = zones.find((zone) => zone.data.name === 'etgzone')
    const etgDeviceIndexes = etgZone ? getDescendantDevices(pits, tables, devices, etgZone.index, 'Zone') : []
    const isEtgDevice = etgDeviceIndexes.includes(device.index)
    const isEtgServer = device.data.type === 'xstadiumContent' || device.data.type === 'xstadiumOthers'
    const isXstadiumDevice = isEtgDevice || isEtgServer

    const remove = async () => {
        try {
            setIsRemoving(true)
            await unpublish('devices/' + device.index)
            if (isEtgDevice) {
                await unpublish('tables/' + tables.find((table) => table.index === device.data.containerIndex).index)
            }
            setIsRemoving(false)
            if (!isXstadiumDevice) {
                navigate(
                    !xstadium ?
                        device.data.containerType === 'casino'
                            ? `/xtrend/${device.data.containerType}`
                            : `/xtrend/${device.data.containerType}/${device.data.containerIndex}`
                        : device.data.type === 'game'
                            ? '/xstadium/tables'
                            : '/xstadium/servers'
                )
            }
        } catch (e) {
            setFail(
                `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                })`
            )
            setConfirm(false)
            setIsRemoving(false)
        }
    }

    const send = async () => {
        setSubmitted(true)

        if (type === 'game' && device.data.type !== 'game' && isGameDeviceExist() && !validation.error) {
            setAlertDialogState({
                show: 'true',
                type: 'error',
                message: 'Only 1 game device per table is allowed. Please change device type.',
                error: 'true',
            })
            return
        }

        if (type === 'game' && lowerCase(device.data.containerType) !== 'table' && !validation.error) {
            setAlertDialogState({
                show: 'true',
                type: 'error',
                message: 'Game device can be added to Table only. Please change device type.',
                error: 'true',
            })
            return
        }

        if (!validation.error) {
            try {
                setLoading(true)
                const payload =
                    type === 'game'
                        ? calculateMinBetColor({
                            ...{ ...defaultValues, ...pick(device.data, defaultValues) },
                            ...device.data,
                            containerIndex: device.data.containerIndex,
                            containerType: device.data.containerType,
                            type: type,
                            gameType: gameType,
                            gameVariant: gameVariant,
                            name: name.trim(),
                            ip: ip.trim(),
                            port: port.trim(),
                            updatedBy: window.localStorage.getItem('account') ?? '-',
                        })
                        : {
                            ...omit(
                                device.data,
                                keys({ ...defaultValues }).concat(['gameType', 'subTypes'])
                            ),
                            containerIndex: device.data.containerIndex,
                            containerType: device.data.containerType,
                            type: type,
                            name: name.trim(),
                            ip: ip.trim(),
                            port: port.trim(),
                            updatedBy: window.localStorage.getItem('account') ?? '-',
                        }
                console.log('device payload: ', payload)
                await publish(payload)
                setHasChanges(false)
                setEditing([])
                setFail('')
                setLoading(false)
            } catch (e) {
                console.warn(e)
                setFail(
                    `Something went wrong (${e && e.response ? await e.response.text() : 'unable to connect to the server'
                    })`
                )
                setLoading(false)
            }
        }
    }

    if (loadedDevice !== device.index) {
        // reset to defaults when selected a new device
        setType('')
        setGameType('')
        setGameVariant('')
        setName('')
        setIP('')
        setPort('')

        setEditing([])
        setSubmitted(false)

        setFail('')
        setLoading(false)
        setConfirm(false)

        setLoadedDevice(device.index)
        setUpdated(-1) // force fields update
        return <LinearProgress />
    }

    console.log('device', device)

    return (
        <Box
            sx={{
                borderRadius: 0,
                display: 'flex',
                flexDirection: 'column',
                pb: '23px',
                position: 'relative',
                width: '100%',
                height: '100%',
            }}
        >
            <Dialog
                open={confirm}
                onClose={() => setConfirm(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                {isRemoving && <LinearProgress />}
                <DialogTitle id='alert-dialog-title'>
                    Do you want to delete <b>{name}</b>?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setConfirm(false)} color='primary'>
                        No
                    </Button>
                    <Button onClick={isRemoving ? () => { } : () => remove()}>Yes</Button>
                </DialogActions>
            </Dialog>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />

            <Box
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {fail && !loading && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.fail}
                            message={
                                <Typography component='p' className={styles.warningText}>
                                    <WarningIcon className={styles.warningIcon} /> {fail}
                                </Typography>
                            }
                        />
                    </Box>
                )}

                {submitted && validation.error && (
                    <Box className={styles.warningContainer}>
                        <SnackbarContent
                            className={styles.warning}
                            message={Object.keys(validation.errors).map((error, key) =>
                                validation.errors[error] !== '' ? (
                                    <Typography key={key} component='p' className={styles.warningText}>
                                        <WarningIcon className={styles.warningIcon} />
                                        {validation.errors[error]}
                                    </Typography>
                                ) : null
                            )}
                        />
                    </Box>
                )}

                <Box className={styles.fieldRowWrapper}>
                    {!template && <>
                        <Box>
                            <Stack
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    fontSize: '0.8em',
                                    color: '#999',
                                }}
                            >
                                {device.created && (
                                    <Box sx={{ width: 'auto' }}>
                                        Created on: <DateDisplay time={device.created} />
                                    </Box>
                                )}

                                {device.created && device.updated !== 0 && (
                                    <Box sx={{ width: 'auto' }}>
                                        Updated on: <DateDisplay time={device.updated} />
                                    </Box>
                                )}
                            </Stack>
                        </Box>

                        {type === 'game' && (
                            <>
                                <Box display='flex'>
                                    <Typography>Table is&nbsp;</Typography>
                                    {device.data.open ? (
                                        <Typography color='#00ff00' textTransform='uppercase'>
                                            Open
                                        </Typography>
                                    ) : (
                                        <Typography color='#ff1111' textTransform='uppercase'>
                                            Closed
                                        </Typography>
                                    )}
                                </Box>
                            </>
                        )}
                    </>}

                    {!template && <FormControl
                        sx={{
                            display: 'flex',
                        }}
                        variant='outlined'
                        autoComplete='off'
                    >
                        <Typography>Device Type</Typography>
                        <Select
                            id='device-type'
                            inputProps={{
                                name: 'type',
                                id: 'type',
                            }}
                            required
                            fullWidth
                            onChange={(e) => {
                                setType(e.target.value)
                                setHasChanges(true)
                            }}
                            error={submitted && fields.type.error()}
                            value={type}
                            disabled={loading || device.data.type !== 'other'}
                            size='small'
                        >
                            <MenuItem value='game'>Game</MenuItem>
                            <MenuItem value='pos'>POS</MenuItem>
                            <MenuItem value='visionServer'>Vision Server</MenuItem>
                            <MenuItem value='visionCamera'>Vision Camera</MenuItem>
                            <MenuItem value='xstadiumContent'>XStadium Content</MenuItem>
                            <MenuItem value='xstadiumRotator'>XStadium Rotator</MenuItem>
                            <MenuItem value='xstadiumOthers'>XStadium Others</MenuItem>
                            <MenuItem value='other'>Other</MenuItem>
                        </Select>
                    </FormControl>}

                    {type === 'game' && (
                        <>
                            <FormControl
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Typography>Game Type*</Typography>
                                <Select
                                    id='game-type'
                                    required
                                    fullWidth
                                    inputProps={{
                                        name: 'gameType',
                                        id: 'gameType',
                                    }}
                                    onChange={(e) => {
                                        setGameType(e.target.value)
                                        if (e.target.value !== 'war') {
                                            setGameVariant('standard')
                                            if (template) {
                                                changeCallback({
                                                    gameType: e.target.value,
                                                    gameVariant: 'standard'
                                                })
                                            }
                                        }
                                        if (e.target.value === 'war') {
                                            setGameVariant('etg')
                                            if (template) {
                                                changeCallback({
                                                    gameType: e.target.value,
                                                    gameVariant: 'etg'
                                                })
                                            }
                                        }
                                        setHasChanges(true)
                                    }}
                                    error={submitted && fields.gameType.error()}
                                    value={gameType}
                                    disabled={loading}
                                    size='small'
                                >
                                    <MenuItem value='baccarat'>Baccarat</MenuItem>
                                    <MenuItem value='blackjack'>Blackjack</MenuItem>
                                    <MenuItem value='roulette'>Roulette</MenuItem>
                                    <MenuItem value='sicbo'>Sicbo</MenuItem>
                                    <MenuItem value='war'>Casino War</MenuItem>
                                </Select>
                            </FormControl>

                            {gameType === 'baccarat' && (
                                <>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Typography>Game Variant*</Typography>
                                        <Select
                                            id='game-variant'
                                            required
                                            fullWidth
                                            inputProps={{
                                                name: 'gameVariant',
                                                id: 'gameVariant',
                                            }}
                                            onChange={(e) => {
                                                setGameVariant(e.target.value)
                                                setHasChanges(true)
                                                if (template) {
                                                    changeCallback({
                                                        gameType,
                                                        gameVariant: e.target.value
                                                    })
                                                }
                                            }}
                                            error={submitted && fields.gameVariant.error()}
                                            value={gameVariant}
                                            disabled={loading}
                                            size='small'
                                        >
                                            <MenuItem value='standard'>Standard</MenuItem>
                                            <MenuItem value='lucky6'>Lucky6</MenuItem>
                                            <MenuItem value='mega6'>Mega6</MenuItem>
                                            <MenuItem value='tiger'>Tiger</MenuItem>
                                            <MenuItem value='lucky6Slot'>Lucky6 with Slot</MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}

                            {gameType === 'blackjack' && (
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Typography>Game Variant*</Typography>
                                    <Select
                                        id='game-variant'
                                        required
                                        fullWidth
                                        inputProps={{
                                            name: 'gameVariant',
                                            id: 'gameVariant',
                                        }}
                                        onChange={(e) => {
                                            setGameVariant(e.target.value)
                                            setHasChanges(true)
                                            if (template) {
                                                changeCallback({
                                                    gameType,
                                                    gameVariant: e.target.value
                                                })
                                            }
                                        }}
                                        error={submitted && fields.gameVariant.error()}
                                        value={gameVariant}
                                        disabled={loading}
                                        size='small'
                                    >
                                        <MenuItem value='standard'>Standard</MenuItem>
                                        <MenuItem value='anyPairSlot'>Any Pair with Slot</MenuItem>
                                    </Select>
                                </FormControl>
                            )}

                            {gameType !== 'baccarat' && gameType !== 'blackjack' && gameType !== 'war' && (
                                <>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Typography>Game Variant*</Typography>
                                        <Select
                                            id='game-variant'
                                            required
                                            fullWidth
                                            inputProps={{
                                                name: 'gameVariant',
                                                id: 'gameVariant',
                                            }}
                                            onChange={(e) => {
                                                setGameVariant(e.target.value)
                                                setHasChanges(true)
                                                if (template) {
                                                    changeCallback({
                                                        gameType,
                                                        gameVariant: e.target.value
                                                    })
                                                }
                                            }}
                                            error={submitted && fields.gameVariant.error()}
                                            value={gameVariant}
                                            disabled={loading}
                                            size='small'
                                        >
                                            <MenuItem value='standard'>Standard</MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}

                            {gameType === 'war' && (
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Typography>Game Variant*</Typography>
                                    <Select
                                        id='game-variant'
                                        required
                                        fullWidth
                                        inputProps={{
                                            name: 'gameVariant',
                                            id: 'gameVariant',
                                        }}
                                        onChange={(e) => {
                                            setGameVariant(e.target.value)
                                            setHasChanges(true)
                                            if (template) {
                                                changeCallback({
                                                    gameType,
                                                    gameVariant: e.target.value
                                                })
                                            }
                                        }}
                                        error={submitted && fields.gameVariant.error()}
                                        value={gameVariant}
                                        disabled={loading}
                                        size='small'
                                    >
                                        <MenuItem value='etg'>ETG</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </>
                    )}

                    {!template && <Box>
                        <Typography>Device Name*</Typography>
                        <TextField
                            required
                            size='small'
                            id='name'
                            name='name'
                            type='text'
                            fullWidth
                            variant='outlined'
                            onFocus={() => onFocus('name')}
                            onBlur={(e) => onBlur(e, 'name')}
                            onChange={(e) => {
                                setName(e.target.value.replace(/[\WA-Z ]/g, ''))
                                setHasChanges(true)
                            }}
                            value={name}
                            disabled={loading}
                            error={submitted && fields.name.error()}
                            autoComplete='off'
                        />
                    </Box>}

                    {!template && <Box display='flex' flexDirection='column' gap='1em'>
                        <Box display='flex' gap='1em'>
                            <Box width='100%'>
                                <Typography>Device IP*</Typography>
                                <TextField
                                    required
                                    size='small'
                                    id='ip'
                                    name='ip'
                                    type='text'
                                    fullWidth
                                    variant='outlined'
                                    onFocus={() => onFocus('ip')}
                                    onBlur={(e) => onBlur(e, 'ip')}
                                    onChange={(e) => {
                                        setIP(e.target.value)
                                        setHasChanges(true)
                                    }}
                                    value={ip}
                                    disabled={loading}
                                    error={submitted && fields.ip.error()}
                                    autoComplete='off'
                                />
                            </Box>

                            <Box width='100%'>
                                <Typography>Device Port*</Typography>
                                <TextField
                                    required
                                    size='small'
                                    id='port'
                                    name='port'
                                    type='text'
                                    fullWidth
                                    variant='outlined'
                                    onFocus={() => onFocus('port')}
                                    onBlur={(e) => onBlur(e, 'port')}
                                    onChange={(e) => {
                                        setPort(e.target.value.replace(/\D/g, ''))
                                        setHasChanges(true)
                                    }}
                                    value={port}
                                    disabled={loading}
                                    error={submitted && fields.port.error()}
                                    autoComplete='off'
                                />
                            </Box>
                        </Box>

                        <SnackbarContent
                            style={{
                                backgroundColor: 'rgb(96 96 96)',
                                maxWidth: 'unset',
                            }}
                            action={[]}
                            message={
                                <Typography component='p' style={{ color: '#c3c3c3' }}>
                                    <InfoIcon sx={{ verticalAlign: 'bottom', color: 'rgb(197 206 207)' }} />
                                    Do not change the port value from the default (3099). Please contact support if
                                    there are any issues
                                </Typography>
                            }
                        />
                    </Box>}
                </Box>

                {!template && <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '0.6em',
                    }}
                >
                    <Button
                        color='inherit'
                        disabled={loading}
                        disableRipple
                        onClick={() => {
                            navigate(
                                !xstadium
                                    ? device.data.containerType === 'casino'
                                        ? `/xtrend/${device.data.containerType}`
                                        : `/xtrend/${device.data.containerType}/${device.data.containerIndex}`
                                    : device.data.type === 'game'
                                        ? '/xstadium/tables'
                                        : '/xstadium/servers'
                            )
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        variant='contained'
                        type='submit'
                        color='error'
                        disabled={loading}
                        disableRipple
                        onClick={() => setConfirm(true)}
                    >
                        Delete
                    </Button>

                    {hasChanges && (
                        <Button variant='contained' type='submit' color='info' disabled={loading} onClick={send}>
                            Update
                        </Button>
                    )}
                </Box>}

                {loading && (
                    <CircularProgress
                        size={80}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default Main
