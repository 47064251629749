import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
import VolumeDown from '@mui/icons-material/VolumeDown'
import VolumeUp from '@mui/icons-material/VolumeUp'
import { withStyles, makeStyles } from '@mui/styles'
import { usePublish, useSubscribe } from '../../api'
import { xstadiumIP } from '../../config'
import _ from 'lodash'

const CustomTab = withStyles({
    root: {
        backgroundColor: '#343434',
        borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
    },
    selected: {
        backgroundColor: 'rgb(0, 161, 255)',
    },
})(Tab)

const rootStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        padding: '2rem',
    },
    fail: {
        backgroundColor: 'brown',
        maxWidth: 'unset',
    },
    warningIcon: {
        color: '#f0cf81',
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        color: 'white',
        fontSize: '0.96em',
        lineHeight: 2,
        fontWeight: '100',
    },
    tooltipWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.2em',
    },
    toggleWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))

const Volumes = () => {
    const styles = rootStyles({})
    const [volumes, volumesSocket] = useSubscribe(xstadiumIP + '/volumes')
    const _setVolumes = usePublish(xstadiumIP + '/volumes')
    const MIN_VOLUME = -100
    const MAX_VOLUME = 15

    const setVolumes = _.debounce(_setVolumes, 10, {
        leading: false,
        trailing: true
    })

    const active = volumesSocket &&
        volumesSocket.readyState === WebSocket.OPEN

    const [masterVolume, setMasterVolume] = useState(0)
    const [trendboardsVolume, setTrendboardsVolume] = useState(0)
    const [contentVolume, setContentVolume] = useState(0)
    const [marketingVolume, setMarketingVolume] = useState(0)
    const [displayVolume, setDisplayVolume] = useState(0)

    const handleMasterVolumeChange = (event, newValue) => {
        const base = volumes?.data ? volumes.data : {}
        setVolumes({
            ...base,
            master: newValue
        })
    }

    const handleTrendboardsVolumeChange = (event, newValue) => {
        const base = volumes?.data ? volumes.data : {}
        setVolumes({
            ...base,
            trendboards: newValue
        })
    }

    const handleContentVolumeChange = (event, newValue) => {
        const base = volumes?.data ? volumes.data : {}
        setVolumes({
            ...base,
            content: newValue
        })
    }

    const handleMarketingVolumeChange = (event, newValue) => {
        const base = volumes?.data ? volumes.data : {}
        setVolumes({
            ...base,
            marketing: newValue
        })
    }

    const handleDisplayVolumeChange = (event, newValue) => {
        const base = volumes?.data ? volumes.data : {}
        setVolumes({
            ...base,
            display: newValue
        })
    }

    useEffect(() => {
        if (volumes?.data?.master !== undefined) {
            setMasterVolume(volumes.data.master)
        }
        if (volumes?.data?.master !== undefined) {
            setTrendboardsVolume(volumes.data.trendboards)
        }
        if (volumes?.data?.content !== undefined) {
            setContentVolume(volumes.data.content)
        }
        if (volumes?.data?.marketing !== undefined) {
            setMarketingVolume(volumes.data.marketing)
        }
        if (volumes?.data?.display !== undefined) {
            setDisplayVolume(volumes.data.display)
        }
    }, [volumes])

    if (!active ||
        !volumes) {
        return <LinearProgress />
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <form
                    className={styles.form}
                    noValidate
                    onSubmit={(e) => {
                        e.preventDefault()
                    }}
                    autoComplete='off'
                >
                    <Box display='flex' flexDirection='column' gap='1.25rem'>
                        <Box>
                            <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                                Settings
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                Select tab to configure the corresponding features of the application
                            </Typography>
                        </Box>
                        <Tabs
                            sx={{
                                background: '#2a2a2a',
                                borderBottom: '2px solid rgb(0, 161, 255)',
                            }}
                            value={2}
                            textColor='inherit'
                            indicatorColor='rgb(0, 161, 255)'
                        >
                            <CustomTab
                                label='Default Playlist'
                                component={Link}
                                to={'/xstadium/settings/defaultplaylist'}
                            />
                            <CustomTab label='Support' component={Link} to={'/xstadium/settings/support'} />
                            <CustomTab label='Volumes' component={Link} to={'/xstadium/settings/volumes'} />
                            <CustomTab label='Device Animations' component={Link} to={'/xstadium/settings/screener'} />
                        </Tabs>
                        <Box width='50%' display='flex' flexDirection='column' gap='1.5rem'>
                            <Box>
                                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                    Master Volume
                                </Typography>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <VolumeDown />
                                    <Slider max={MAX_VOLUME} min={MIN_VOLUME} aria-label="Master Volume" value={masterVolume} onChange={handleMasterVolumeChange} />
                                    <VolumeUp />
                                </Stack>
                                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                    Trendboards Volume
                                </Typography>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <VolumeDown />
                                    <Slider max={MAX_VOLUME} min={MIN_VOLUME} aria-label="Trendboards Volume" value={trendboardsVolume} onChange={handleTrendboardsVolumeChange} />
                                    <VolumeUp />
                                </Stack>
                                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                    Content Volume
                                </Typography>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <VolumeDown />
                                    <Slider max={MAX_VOLUME} min={MIN_VOLUME} aria-label="Content Volume" value={contentVolume} onChange={handleContentVolumeChange} />
                                    <VolumeUp />
                                </Stack>
                                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                    Marketing Volume
                                </Typography>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <VolumeDown />
                                    <Slider max={MAX_VOLUME} min={MIN_VOLUME} aria-label="Marketing Volume" value={marketingVolume} onChange={handleMarketingVolumeChange} />
                                    <VolumeUp />
                                </Stack>
                                <Typography variant='body1' fontWeight='bold' textTransform='uppercase'>
                                    Display Volume
                                </Typography>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <VolumeDown />
                                    <Slider max={MAX_VOLUME} min={MIN_VOLUME} aria-label="Display Volume" value={displayVolume} onChange={handleDisplayVolumeChange} />
                                    <VolumeUp />
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </form>
            </Grid>
        </Grid>
    )
}


export default Volumes
