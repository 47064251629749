import React, { memo } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import R404 from '../404'
import Updates from './Updates'

export default memo(() => {
    const role = window.localStorage.getItem('role')

    if (role !== 'root' && role !== 'itSupport' && role !== 'masterAdmin') {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            <Routes>
                <Route exact path='/' element={<Navigate to='/updates/home' replace />} />
                <Route exact path='/home' element={<Updates />} />
                <Route path='*' element={<R404 basePath='updates/' />} />
            </Routes>
        </>
    )
})
