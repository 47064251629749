import { useState } from 'react'
import { XCircle, AlertCircle } from 'react-feather'
import styled from 'styled-components'

import { pivotIP } from '../config'

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`
const Container = styled(FlexCenter)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`
const Inner = styled(FlexCenter)`
    position: relative;
    width: 100%;
    height: 100%;
`
const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.8;
`
const Content = styled(FlexCenter)`
    color: white;
    z-index: 11;
    flex-direction: column;
    background: #4a4a4a;
    padding: 30px;
    border-radius: 10px;
    gap: 40px;
    width: 400px;
    position: relative;
`
const Heading = styled(FlexCenter)`
    font-size: 25px;
    font-weight: bold;
`
const Button = styled.label`
    border-radius: 20px;
    padding: 10px 20px;
    color: white;
    background-color: ${(props) => props.color};
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover {
        background: #333;
    }
`
const CloseButton = styled(XCircle)`
    color: #ccc;
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    transform: translate(100%, -100%);
    cursor: pointer;
    &:hover {
        color: white;
    }
`
const ErrorIcon = styled(AlertCircle)`
    height: 30px;
    width: 30px;
    color: orange;
`
const UploadSoftwarePanel = ({ setShowPanel }) => {
    const [error, setError] = useState(false)

    const onFileChange = async (e) => {
        const url = 'http://' + pivotIP + '/version/create'
        // console.log('onFileChange', e.target.files[0])
        setError(false)

        const req = new XMLHttpRequest()
        req.open('POST', url, true)
        req.onload = function (e) {
            console.log('// Uploaded.', e)
            if (e.target.status === 200) {
                setShowPanel(false)
            } else {
                setError(true)
                console.log('// error.', e.target.statusText)
            }
        }
        req.onerror = function (e) {
            setError(true)
            console.log('// error.', e)
        }
        req.send(e.target.files[0])
    }

    return (
        <Container>
            <Inner>
                <Content>
                    <CloseButton onClick={() => setShowPanel(null)} />
                    <Heading>Upload Software</Heading>

                    <Button color={'#666'} htmlFor='uploadInput'>
                        {error && <ErrorIcon />}
                        {'Upload'}
                    </Button>
                    <input onChange={(e) => onFileChange(e)} type='file' id='uploadInput' hidden />
                </Content>
                <Backdrop onClick={() => setShowPanel(null)} />
            </Inner>
        </Container>
    )
}

export default UploadSoftwarePanel
