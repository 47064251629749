import { useState } from 'react'
import ky from 'ky'
import { XCircle, AlertCircle } from 'react-feather'
import styled from 'styled-components'

import { pivotIP } from '../config'

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`
const Container = styled(FlexCenter)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`
const Inner = styled(FlexCenter)`
    position: relative;
    width: 100%;
    height: 100%;
`
const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.8;
`
const Content = styled(FlexCenter)`
    color: white;
    z-index: 11;
    flex-direction: column;
    background: #4a4a4a;
    padding: 30px;
    border-radius: 10px;
    gap: 40px;
    width: 400px;
    position: relative;
`
const Heading = styled(FlexCenter)`
    font-size: 25px;
    font-weight: bold;
`
const Button = styled(FlexCenter)`
    border-radius: 20px;
    padding: 10px 20px;
    color: white;
    background-color: ${(props) => props.color};
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover {
        background: #333;
    }
`
const Options = styled(FlexCenter)`
    flex-direction: column;
    background: #6c6c6c;
    border-radius: 20px;
    width: 100%;
    max-height: 300px;
    justify-content: flex-start;
    overflow-y: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 20px;
        border: transparent;
    }
`
const OptionsHeading = styled.div`
    margin-top: 20px;
    width: 100%;
    position: relative;
    &:before {
        content: 'Selected Devices';
        position: absolute;
        top: -6px;
        left: 5px;
        transform: translateY(-100%);
    }
`
const Option = styled(FlexCenter)`
    justify-content: flex-start;
    min-height: 40px;
    font-size: 20px;
    width: 100%;
    padding: 0px 20px;
    cursor: pointer;
    &:hover {
        background: #333;
    }
`
const SelectedVersion = styled(FlexCenter)`
    margin-top: 20px;
    background: #6c6c6c;
    padding: 10px 20px;
    border-radius: 10px;
    position: relative;
    width: 100%;
    font-size: 23px;
    font-weight: bold;
    text-shadow: 0px 0px 6px #000;
    justify-content: flex-start;
    color: red;
    position: relative;
    &:before {
        text-shadow: none;
        content: 'Selected Version';
        position: absolute;
        top: -6px;
        left: 5px;
        transform: translateY(-100%);
        color: white;
        font-size: 16px;
    }
`
const CloseButton = styled(XCircle)`
    color: #ccc;
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    transform: translate(100%, -100%);
    cursor: pointer;
    &:hover {
        color: white;
    }
`
const ErrorIcon = styled(AlertCircle)`
    height: 30px;
    width: 30px;
    color: orange;
`
const DeployPanel = ({ selectedDevices, devicesData, setShowPanel, selectedVersion }) => {
    const [error, setError] = useState(false)

    const deploySelectedVersion = async (id) => {
        try {
            //since only 1 device allowed use [0]
            const url = 'http://' + pivotIP + '/version/update/' + id
            const res = await ky.get(url)

            if (res.status === 200) {
                setError(false)
                setShowPanel(false)
            }
        } catch (e) {
            console.log('error:', e)
            setError(true)
        }
    }

    const deploySelectedVersionToAll = () => {
        devicesData.forEach((v) => {
            deploySelectedVersion(v.index)
        })
    }

    //ignore selected devices and use all devices as new default
    // const OptionsArr = devicesData.filter(v => selectedDevices.includes(v.index)).map(v => v.data.name)
    const OptionsArr = devicesData.map((v) => v.data.name)

    return (
        <Container>
            <Inner>
                <Content>
                    <CloseButton onClick={() => setShowPanel(null)} />
                    <Heading>Deploy Software Update</Heading>
                    <OptionsHeading>
                        <Options>
                            {OptionsArr.map((v) => (
                                <Option>{v}</Option>
                            ))}
                        </Options>
                    </OptionsHeading>
                    <SelectedVersion>{selectedVersion.tag}</SelectedVersion>
                    <Button
                        color={'#666'}
                        // onClick={() => deploySelectedVersion(selectedDevices[0])}
                        onClick={() => deploySelectedVersionToAll()}
                    >
                        {error && <ErrorIcon />}
                        Deploy
                    </Button>
                </Content>
                <Backdrop onClick={() => setShowPanel(null)} />
            </Inner>
        </Container>
    )
}

export default DeployPanel
