import React, { memo, } from 'react'
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import Users from './users/Users'
import User from './users/User'
import Policies from './Policies'
import R404 from '../404'

export default memo(({ menuOpen, setMenuOpen }) => {
    const navigate = useNavigate()
    const loggedInRole = window.localStorage.getItem('role')
    const isAdmin = loggedInRole === 'root' || loggedInRole === 'itSupport' || loggedInRole === 'masterAdmin'
    
    return <>
        <Routes>
            <Route exact path="/" element={<Navigate to="/xuser/home" replace />} />
            <Route path="/home" element={<Users menuOpen={menuOpen} setMenuOpen={setMenuOpen} />} />
            {isAdmin && <Route path="/policies" element={<Policies menuOpen={menuOpen} setMenuOpen={setMenuOpen} />} />}
            <Route path="/users" element={<Users menuOpen={menuOpen} setMenuOpen={setMenuOpen} />} />
            {isAdmin && <Route path="/users/new" element={<User menuOpen={menuOpen}
                afterCreate={() => navigate('/xuser/users')} />} />}
            <Route exact path="/user/:id" element={<User menuOpen={menuOpen} />} />
            <Route path="*" element={<R404 basePath='xuser/' />} />
        </Routes>
    </>
})