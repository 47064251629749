import React, { useState, useReducer, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react' // the AG Grid React Component
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import { isEmpty, cloneDeep, isNil } from 'lodash'
import moment from 'moment'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Card, CardHeader, CardBody, Container, Row, Col, Progress } from 'reactstrap'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { withStyles } from '@mui/styles'

import { useSubscribe, historyApi } from '../../api'
import Location from '../../tree/Location'
import Tree from '../../tree/Tree'
import {
    getDescendantDevices,
    atLeastOneDevice,
    findTablesInLocation,
    findGameDeviceInTable,
    getGameType,
    getGameVariant,
} from '../../tree/TreeUtils'
import { getGameTypeTranslation, getGameVariantTranslation } from '../../util/Translation'
import useWindowSize from '../../WindowSize'
import { formatCashString, chartBackgroundColors, DateTimeDisplay } from '../Formats'

import '../../ag-grid/ag-grid-theme.css' // Core grid CSS, always needed
import '../../ag-grid/ag-grid.css' // Core grid CSS, always needed

const styles = {
    pieContainer: {
        width: 365,
        height: 473,
        paddingTop: 35,
        margin: 'auto',
    },
    barContainer: {
        width: '-webkit-fill-available',
        height: 150,
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em',
    },
    cardTitleText: {
        fontSize: '1.1em',
        fontWeight: 'bold',
        justifyContent: 'center',
        display: 'flex',
        width: '-webkit-fill-available',
        overflowWrap: 'anywhere',
        maxWidth: 163,
        margin: 'auto',
        minHeight: 50,
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    cardBodyText: {
        padding: '0px 0.9em 0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
    cardBodyTextString: {
        padding: '0px 0.9em 0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.3em',
        overflowWrap: 'anywhere',
    },
    cardBodyTextStringDate: {
        padding: 0,
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.3em',
        overflowWrap: 'anywhere',
        marginTop: -24,
    },
    cardBodyTextWager: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.3em',
        color: chartBackgroundColors[4],
        paddingTop: 0,
    },
    cardBodyTextWinLoss: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.3em',
        paddingTop: 0,
    },
    cardBodyTextStringResult: (v) => ({
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color:
            v === 'B' || v === 'D'
                ? chartBackgroundColors[0]
                : v === 'P'
                ? chartBackgroundColors[1]
                : v === 'NA'
                ? 'white'
                : chartBackgroundColors[2],
    }),
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red',
    }),
    seatCard: (index) => ({
        // backgroundColor: seatsBackgroundColors[index]
    }),
    buttonTable: {
        marginBottom: '10px',
    },
    playerPicture: {
        maxWidth: '-webkit-fill-available',
        minWidth: '-webkit-fill-available',
        maxHeight: '145px',
        margin: '0 auto',
        objectFit: 'contain',
        minHeight: 145,
        background: '#666666',
    },
    playerSeatName: {
        display: 'flex',
        width: '-webkit-fill-available',
        maxWidth: '-webkit-fill-available',
        justifyContent: 'space-between',
        paddingBottom: '1em',
        flexWrap: 'inherit',
    },
    button: {
        width: '150px',
    },
    twoLineEllipsis: {
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },
}

const CustomTab = withStyles({
    root: {
        backgroundColor: '#343434',
        borderRadius: 'calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0',
    },
    selected: {
        backgroundColor: 'rgb(0, 161, 255)',
        color: '#ffffff !important',
    },
})(Tab)

const Slots = ({ xstadium, levels = null }) => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')
    const URLlocationID = urlParams.get('locationID') || window.localStorage.getItem('url:locationID') || 'root'
    const URLlocationType = urlParams.get('locationType') || window.localStorage.getItem('url:locationType') || 'Casino'
    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }
    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN

    // fetched data
    const [allTablesReport, setAllTablesReport] = useState(null)
    const [fetchedTablesReport, setFetchedTablesReport] = useState(false)
    const [deviceGames, setDeviceGames] = useState(null)
    const [fetchedDeviceGames, setFetchedDeviceGames] = useState(false)
    const [fetchTablesReportProgress, setFetchTablesReportProgress] = useState(0)
    const [fetchGamesProgress, setFetchGamesProgress] = useState(0)
    const [minLoadTime, setMinLoadTime] = useState(false)

    // tabs
    const [tabIndex, setTabIndex] = useState(0)
    const handleChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex)
    }

    // ag-grid
    const defaultColDef = useMemo(() => {
        return {
            minWidth: 10,
            filter: true,
            sortable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }
    }, [])

    const slotsGameViewListColumnDefs = [
        {
            field: 'slotGameID',
            headerName: 'Slot Game ID',
            maxWidth: 200,
        },
        {
            field: 'slotSpinTime',
            headerName: 'Slot Spin Time',
            valueFormatter: function (params) {
                return DateTimeDisplay(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'baseGameNumber',
            headerName: 'Base Game Number',
            maxWidth: 200,
        },
        {
            field: 'resultWinner',
            headerName: 'Result Winner',
            valueFormatter: function (params) {
                return getSlotResultWinnerTranslation(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'slotResult1',
            headerName: 'Slot Result 1',
            valueFormatter: function (params) {
                return getSlotResultTranslation(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'slotResult2',
            headerName: 'Slot Result 2',
            valueFormatter: function (params) {
                return getSlotResultTranslation(params.value)
            },
            maxWidth: 200,
        },
        {
            field: 'slotResult3',
            headerName: 'Slot Result 3',
            valueFormatter: function (params) {
                return getSlotResultTranslation(params.value)
            },
            maxWidth: 200,
        },
    ]

    const getSlotResultWinnerTranslation = (key) => {
        switch (key) {
            case 'fullA':
                return 'Three Bells'
            case 'fullB':
                return 'Three Nines'
            case 'fullC':
                return 'Three Spades'
            case 'fullD':
                return 'Three Hearts'
            case 'mixE2F1':
                return '2 Clubs & Diamond'
            case 'mixE2notF':
                return '2 Clubs & Ex-diamond'
            default:
                return '-'
        }
    }

    const getSlotResultTranslation = (key) => {
        switch (key) {
            case 'logo':
                return 'Logo'
            case 'heart':
                return 'Heart'
            case 'diamond':
                return 'Diamond'
            case 'spades':
                return 'Spades'
            case 'clubs':
                return 'Clubs'
            case '9':
                return '9'
            case 'notDiamond':
                return 'Ex-diamond'
            default:
                return '-'
        }
    }

    // data navigation
    const [autoUpdateEnabled, setAutoUpdateEnabled] = useState(true)
    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter((v) => v !== 'push')
        const oldParams = urlParams.toString()
        propKeys.forEach((k) => {
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange =
            propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        const path = xstadium ? 'xstadium' : 'xtable'
        if (urlChange || props.push) {
            navigate(`/${path}/slots?` + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                setFetchedTablesReport(false)

                if (!props.push) {
                    setAllTablesReport(null)
                }
            }

            setFetchedDeviceGames(false)
            if (!props.push) {
                setDeviceGames(null)
                setFetchGamesProgress(0)
            }
        }
    }

    const [location, setLocation] = useReducer((_state, obj) => {
        clearFetch({ locationID: obj.branchIndex, locationType: obj.branchType })
        return [obj]
    }, null)

    // View all as list by default
    useEffect(() => {
        if (location) {
            setTabIndex(0)
        }
    }, [location])

    // breadcrumbs
    const key = {
        device: { o: devices, p: 'table' },
        table: { o: tables, p: 'pit' },
        pit: { o: pits, p: 'zone' },
        zone: { o: zones, p: 'casino' },
        casino: { o: [], p: null },
    }

    const getBreadcrumbsArray = (type, index) => {
        const obj = key[type.toLowerCase()]
        const dataObj = obj ? obj.o.filter((v) => v.index === index) : {}
        const name = _.get(dataObj, ['0', 'data', 'name'], null)
        const containerIndex = _.get(dataObj, ['0', 'data', 'containerIndex'], null)
        const containerType = _.get(dataObj, ['0', 'data', 'containerType'], obj.p)
        return containerType
            ? [
                  getBreadcrumbsArray(containerType, containerIndex),
                  {
                      name: name,
                      type: type.toLowerCase(),
                  },
              ].flat()
            : [
                  {
                      name: 'Casino',
                      type: 'casino',
                  },
              ]
    }

    const breadCrumbs = location
        ? levels
            ? getBreadcrumbsArray(_.lowerCase(location[0].branchType), location[0].branchIndex).slice(0 - levels)
            : getBreadcrumbsArray(_.lowerCase(location[0].branchType), location[0].branchIndex)
        : []

    const isCasinoLevel = !location || (location && location[0].branchIndex === 'root')
    const isTableLevel = location && location[0].branchType === 'Table'

    const dateChange = (start, end, push) => {
        if (start === startDate && end === endDate && !push) return
        if (!push && (start !== startDate || end !== endDate)) {
            setStartDate(start)
            setEndDate(end)
        }
        clearFetch({ startDate: start, endDate: end, push: !!push })
    }

    const getDevicesReport = async () => {
        if (fetchedTablesReport) {
            return
        }

        setFetchedTablesReport(true)
        try {
            setFetchTablesReportProgress(1)
            setTimeout(() => {
                setFetchTablesReportProgress(11)
            }, 300)
            setTimeout(() => {
                setFetchTablesReportProgress(22)
            }, 200)
            setTimeout(() => {
                setFetchTablesReportProgress(33)
            }, 200)
            const response = await historyApi
                .get('report/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            setFetchTablesReportProgress(40)
            setFetchTablesReportProgress(80)
            setTimeout(() => {
                setFetchTablesReportProgress(84)
            }, 300)
            setTimeout(() => {
                setFetchTablesReportProgress(98)
            }, 600)
            setTimeout(() => {
                setAllTablesReport(response)
            }, 700)
        } catch (e) {
            console.warn(e)
            setAllTablesReport([])
        }
    }

    if (settings && Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        getDevicesReport()
    }

    const getDeviceGames = async (deviceID, gameType, gameVariant) => {
        if (fetchedDeviceGames) {
            return
        }

        setFetchedDeviceGames(true)
        try {
            setFetchGamesProgress(1)
            setTimeout(() => {
                setFetchGamesProgress(11)
            }, 300)
            setTimeout(() => {
                setFetchGamesProgress(22)
            }, 200)
            setTimeout(() => {
                setFetchGamesProgress(33)
            }, 200)
            const response = await historyApi
                .get(
                    'lastgames/' +
                        gameType +
                        '/' +
                        gameVariant +
                        '/slot/' +
                        deviceID +
                        '/' +
                        startDate * 1000000000 +
                        '/' +
                        endDate * 1000000000
                )
                .json()
            // console.log(response)
            setFetchGamesProgress(40)
            setTimeout(() => {
                setFetchGamesProgress(100)
            }, 300)
            setTimeout(() => {
                setDeviceGames(response)
            }, 600)
        } catch (e) {
            console.warn(e)
            setDeviceGames([])
        }
    }

    const treeLoading = !active || !zones || !pits || !devices || !tables || !status || !settings || !minLoadTime

    if (
        !treeLoading &&
        (!location || (URLlocationID !== location[0].branchIndex && URLlocationType !== location[0].branchType))
    ) {
        setLocation({
            branchIndex: URLlocationID,
            branchType: URLlocationType,
            descendantDevices: getDescendantDevices(pits, tables, devices, URLlocationID, URLlocationType),
            gameType: getGameType(tables, devices, URLlocationID, URLlocationType),
            gameVariant: getGameVariant(tables, devices, URLlocationID, URLlocationType),
        })
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    // display data
    const tablesReport =
        location && allTablesReport
            ? allTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : allTablesReport
    const totalGamesReport =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.games, 0)
    const totalGames = deviceGames && deviceGames.length ? deviceGames.length : totalGamesReport
    const totalWager =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.bets, 0)
    const totalWinLoss =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.profit, 0)
    const allTables = !treeLoading
        ? isCasinoLevel
            ? tables.filter(atLeastOneDevice(devices))
            : findTablesInLocation(pits, tables, location).filter(atLeastOneDevice(devices))
        : []

    const viewListData =
        !isNil(deviceGames) && deviceGames.length > 0
            ? (location[0]?.gameType === 'baccarat' && location[0]?.gameVariant === 'lucky6Slot') ||
              (location[0]?.gameType === 'blackjack' && location[0]?.gameVariant === 'anyPairSlot')
                ? deviceGames.map((game, index) => ({
                      slotGameID: !game.gameID ? '-' : game.gameID,
                      slotSpinTime: !game.spun ? '-' : game.spun,
                      baseGameNumber: deviceGames.length - index + '/' + deviceGames.length,
                      resultWinner: !game.result.name ? '-' : game.result.name,
                      slotResult1: !game.result.symbols ? '-' : game.result.symbols[0].name,
                      slotResult2: !game.result.symbols ? '-' : game.result.symbols[1].name,
                      slotResult3: !game.result.symbols ? '-' : game.result.symbols[2].name,
                  }))
                : []
            : []

    console.log('deviceGames', deviceGames)
    console.log('viewListData', viewListData)

    const onExportClick = async () => {
        const workbook = new ExcelJS.Workbook()
        const sheet = workbook.addWorksheet('Slot Game History')

        sheet.getCell('A1').value = 'X Table Slot Game History Report'
        sheet.getCell('A2').value = 'Date of data:'
        sheet.getCell('B2').value = moment(moment.unix(startDate).format('YYYY-MM-DD')).isSame(
            moment.unix(endDate).format('YYYY-MM-DD')
        )
            ? moment.unix(endDate).format('YYYY-MM-DD')
            : moment.unix(startDate).format('YYYY-MM-DD') + ' - ' + moment.unix(endDate).format('YYYY-MM-DD')
        sheet.getCell('A3').value = 'Report run date:'
        sheet.getCell('B3').value = moment(new Date()).format('YYYY-MM-DD hh:mm:ss A')

        sheet.getCell('A4').value = 'Game type'
        sheet.getCell('B4').value = location ? getGameTypeTranslation(location[0]?.gameType) : '-'
        sheet.getCell('A5').value = 'Game variant'
        sheet.getCell('B5').value = location ? getGameVariantTranslation(location[0]?.gameVariant) : '-'
        sheet.getCell('A6').value = 'Number of games'
        sheet.getCell('B6').value = totalGames

        sheet.getCell('D1').value = 'Casino:'
        sheet.getCell('E1').value = breadCrumbs.find((item) => item.type === 'casino')
            ? breadCrumbs.find((item) => item.type === 'casino').name
            : '-'
        sheet.getCell('D2').value = 'Zone:'
        sheet.getCell('E2').value = breadCrumbs.find((item) => item.type === 'zone')
            ? breadCrumbs.find((item) => item.type === 'zone').name
            : '-'
        sheet.getCell('D3').value = 'Pit:'
        sheet.getCell('E3').value = breadCrumbs.find((item) => item.type === 'pit')
            ? breadCrumbs.find((item) => item.type === 'pit').name
            : '-'
        sheet.getCell('D4').value = 'Table:'
        sheet.getCell('E4').value = breadCrumbs.find((item) => item.type === 'table')
            ? breadCrumbs.find((item) => item.type === 'table').name
            : '-'

        sheet.addTable({
            name: 'Slot_Game_History',
            ref: 'A8',
            columns: slotsGameViewListColumnDefs.map((column) =>
                Object.assign({
                    name: column.headerName,
                })
            ),
            rows: cloneDeep(viewListData).map((viewList) => {
                slotsGameViewListColumnDefs.forEach((columnDef) => {
                    if (columnDef.field === 'slotSpinTime') {
                        viewList[columnDef.field] = DateTimeDisplay(viewList[columnDef.field])
                    }
                    if (
                        columnDef.field === 'resultWinner' &&
                        !isEmpty(viewList[columnDef.field]) &&
                        viewList[columnDef.field] !== '-'
                    ) {
                        viewList[columnDef.field] = getSlotResultWinnerTranslation(viewList[columnDef.field])
                    }
                    if (
                        (columnDef.field === 'slotResult1' ||
                            columnDef.field === 'slotResult2' ||
                            columnDef.field === 'slotResult3') & !isEmpty(viewList[columnDef.field]) &&
                        viewList[columnDef.field] !== '-'
                    ) {
                        viewList[columnDef.field] = getSlotResultTranslation(viewList[columnDef.field])
                    }
                    if (typeof viewList[columnDef.field] === 'undefined') {
                        viewList[columnDef.field] = 'N/A'
                    }
                })

                console.log('viewList', viewList)

                return Object.values(viewList)
            }),
        })

        const buffer = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buffer]), `Slot Game History_${moment(new Date()).format('YYYYMMDDHHmmss')}.xlsx`)
    }

    if (allTables.length === 1) {
        const selectedDevice = findGameDeviceInTable(devices, allTables[0].index)
        if (selectedDevice.length > 0) {
            getDeviceGames(selectedDevice[0].index, selectedDevice[0].data.gameType, selectedDevice[0].data.gameVariant)
        }
    }

    if (settings && !settings.data.xtable.sections.includes('games')) {
        return <Navigate to='/dashboard' />
    }

    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: 320 }}>
                <Tree
                    loading={treeLoading}
                    autoUpdateEnabled={autoUpdateEnabled}
                    toggleAutoUpdate={(v) => setAutoUpdateEnabled(v)}
                    dateChange={dateChange}
                    startDate={startDate}
                    endDate={endDate}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    setLocation={(newLocation) => {
                        console.log(newLocation, location)
                        setLocation(newLocation)
                    }}
                    location={location}
                    fluid={width <= 1680}
                    xstadium={xstadium}
                />
            </Grid>
            <Grid item xs>
                <Location
                    loading={treeLoading}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    location={location}
                    fluid={width <= 1680}
                />
                {!allTablesReport && (
                    <Container fluid={width <= 1680}>
                        <Row xs='1'>
                            <Col>
                                <h3>Fetching report...</h3>
                                <Progress value={fetchTablesReportProgress} />
                            </Col>
                        </Row>
                    </Container>
                )}

                {allTablesReport && !resizing && location && (
                    <Container fluid={width <= 1680}>
                        <Row xs='3'>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}># of games</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span style={styles.cardBodyText}>{totalGames}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>Total Wager</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span style={styles.cardBodyText}>{formatCashString(totalWager)}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader style={styles.cardHeader}>
                                        <span style={styles.cardTitleText}>House Total win/loss</span>
                                    </CardHeader>
                                    <CardBody style={styles.cardBody}>
                                        <span
                                            style={{
                                                ...styles.cardBodyText,
                                                ...styles.cashValue(totalWinLoss),
                                            }}
                                        >
                                            {formatCashString(totalWinLoss)}
                                        </span>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {isTableLevel && allTables.length === 1 && !deviceGames && (
                            <Container fluid>
                                <Row xs='1'>
                                    <h3>Fetching Games...</h3>
                                    <Progress value={fetchGamesProgress} />
                                </Row>
                            </Container>
                        )}

                        {isTableLevel &&
                            allTables.length === 1 &&
                            deviceGames &&
                            deviceGames.length >= 1 &&
                            ((location[0]?.gameType === 'baccarat' && location[0]?.gameVariant === 'lucky6Slot') ||
                                (location[0]?.gameType === 'blackjack' &&
                                    location[0]?.gameVariant === 'anyPairSlot')) && (
                                <div>
                                    <Container fluid>
                                        <Row
                                            xs='1'
                                            style={{
                                                marginBottom: '2.2rem',
                                                borderBottom: '2px solid rgb(0, 161, 255)',
                                            }}
                                        >
                                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                                <Tabs value={tabIndex} onChange={handleChange}>
                                                    <CustomTab label='View list' />
                                                </Tabs>

                                                <Button
                                                    variant='outlined'
                                                    sx={{ height: '2.35rem' }}
                                                    onClick={() => onExportClick()}
                                                >
                                                    Export
                                                </Button>
                                            </Box>
                                        </Row>
                                    </Container>

                                    {tabIndex === 0 && (
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <div
                                                            className='ag-theme-balham-dark'
                                                            style={{
                                                                width: '-webkit-fill-available',
                                                                height: 'calc(100vh - 460px)',
                                                                colorScheme: 'dark',
                                                            }}
                                                        >
                                                            <AgGridReact
                                                                defaultColDef={defaultColDef}
                                                                columnDefs={slotsGameViewListColumnDefs}
                                                                rowData={viewListData}
                                                                animateRows={true}
                                                                rowSelection={'single'}
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            )}

                        {!xstadium &&
                            !isTableLevel &&
                            allTables.length > 0 &&
                            allTables.map((table) => (
                                <Row key={table.index}>
                                    <Col>
                                        <Button
                                            variant='contained'
                                            onClick={() => {
                                                const descendantDevices = findGameDeviceInTable(
                                                    devices,
                                                    table.index
                                                ).map((dv) => dv.index)
                                                setLocation({
                                                    branchColor: '#FF9F43',
                                                    branchIndex: table.index,
                                                    branchName: table.data.name,
                                                    branchType: 'Table',
                                                    gameType: getGameType(
                                                        tables,
                                                        devices,
                                                        table.index,
                                                        table.data.containerType
                                                    ),
                                                    gameVariant: getGameVariant(
                                                        tables,
                                                        devices,
                                                        table.index,
                                                        table.data.containerType
                                                    ),
                                                    descendantDevices,
                                                })
                                                console.log(table, location)
                                            }}
                                            color='primary'
                                            style={styles.buttonTable}
                                            key={table.index}
                                        >
                                            {table.data.name}
                                        </Button>
                                    </Col>
                                </Row>
                            ))}

                        {allTables.length === 0 && <div>No valid table found at the selected location</div>}
                    </Container>
                )}
            </Grid>
        </Grid>
    )
}

export default Slots
