import React, { useState } from 'react'
import { cloneDeep, keys, isEmpty, startCase } from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import CircleIcon from '@mui/icons-material/Circle'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SearchIcon from '@mui/icons-material/Search'
import StyleIcon from '@mui/icons-material/Style'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled, alpha } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'

import { useSubscribe, usePublish } from '../../api'
import { pivotIP } from '../../config'
import { validate } from '../../forms'
import { formatCashString, formatCountString } from '../../xtable/Formats'
import AlertDialog from '../../xtrend/components/AlertDialog'
import { defaultValues } from '../../xtrend/devices/defaultValues'
import { getMinBetColor } from '../../xtrend/devices/minBetColor/color'
import { calculateMinBetColor } from '../../xtrend/devices/minBetColor/color'
import { reservedFields } from '../../xtrend/devices/tree/TreeNav'

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}))

const FieldRow = ({ id, title, state, onChange, disabled, error, helperText }) => {
    return (
        <Box width='13rem'>
            <Typography>{title}</Typography>
            <TextField
                id={id}
                name={id}
                type='text'
                size='small'
                fullWidth
                variant='outlined'
                value={state !== '' ? formatCountString(state) : state}
                onChange={onChange}
                disabled={disabled}
                error={error}
                helperText={helperText}
                autoComplete='off'
            />
        </Box>
    )
}

const initialBatch = {
    minBet: {
        value: '',
        changed: false,
    },
    maxBet: {
        value: '',
        changed: false,
    },
    tieMinBet: {
        value: '',
        changed: false,
    },
    tieMaxBet: {
        value: '',
        changed: false,
    },
    pairMinBet: {
        value: '',
        changed: false,
    },
    pairMaxBet: {
        value: '',
        changed: false,
    },
    naturalMinBet: {
        value: '',
        changed: false,
    },
    naturalMaxBet: {
        value: '',
        changed: false,
    },
    lucky6MinBet: {
        value: '',
        changed: false,
    },
    lucky6MaxBet: {
        value: '',
        changed: false,
    },
    mega6MinBet: {
        value: '',
        changed: false,
    },
    mega6MaxBet: {
        value: '',
        changed: false,
    },
    tigerMinBet: {
        value: '',
        changed: false,
    },
    tigerMaxBet: {
        value: '',
        changed: false,
    },
    tigerTieMinBet: {
        value: '',
        changed: false,
    },
    tigerTieMaxBet: {
        value: '',
        changed: false,
    },
    tigerPairMinBet: {
        value: '',
        changed: false,
    },
    tigerPairMaxBet: {
        value: '',
        changed: false,
    },
    tigerBigMinBet: {
        value: '',
        changed: false,
    },
    tigerBigMaxBet: {
        value: '',
        changed: false,
    },
    tigerSmallMinBet: {
        value: '',
        changed: false,
    },
    tigerSmallMaxBet: {
        value: '',
        changed: false,
    },
    anyPairMinBet: {
        value: '',
        changed: false,
    },
    anyPairMaxBet: {
        value: '',
        changed: false,
    },
    anyPairAnyPlayerMinBet: {
        value: '',
        changed: false,
    },
    anyPairAnyPlayerMaxBet: {
        value: '',
        changed: false,
    },
    tripleDiceLimit: {
        value: '',
        changed: false,
    },
    doubleDiceLimit: {
        value: '',
        changed: false,
    },
}

const Tables = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const navigate = useNavigate()

    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [statuses, statusesSocket] = useSubscribe('status/*')

    const zonePublish = usePublish('zones/*')
    const pitPublish = usePublish('pits/*')
    const tablePublish = usePublish('tables/*')
    const devicePublish = usePublish('devices/*')

    const [gameType, setGameType] = useState('')
    const [gameVariant, setGameVariant] = useState('')
    const [name, setName] = useState('')
    const [ip, setIP] = useState('')
    const [port, setPort] = useState('')
    const [dialogLoading, setDialogLoading] = useState(false)
    const [dialogSubmitted, setDialogSubmitted] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [currentRow, setCurrentRow] = useState({})
    const [deviceIndexes, setDeviceIndexes] = useState([])
    const [batch, setBatch] = useState(initialBatch)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [buttonSubmitted, setButtonSubmitted] = useState(false)

    // dialog
    const [dialogOpen, setDialogOpen] = useState(false)

    const handleClickOpen = () => {
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false)
        setDialogSubmitted(false)
        setGameType('')
        setGameVariant('')
        setName('')
        setIP('')
        setPort('')
    }

    // menu
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    // alert dialog state
    const [alertDialogState, setAlertDialogState] = useState({
        show: false,
        type: '',
        message: '',
        error: false,
        confirm: () => { },
    })

    const active = zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusesSocket &&
        statusesSocket.readyState === WebSocket.OPEN

    if (!active || !zones || !pits || !tables || !devices || !statuses) {
        return <LinearProgress />
    }

    const addTableFields = {
        gameType: {
            error: () => gameType.trim().length < 1,
            value: gameType,
            set: setGameType,
            message: 'Game Type cannot be empty',
        },
        gameVariant: {
            error: () => gameVariant.trim().length < 1,
            value: gameVariant,
            set: setGameVariant,
            message: 'Game Variant cannot be empty',
        },
        name: {
            error: () =>
                name.trim().length < 5 ||
                reservedFields.includes(name.trim().toLowerCase()) ||
                devices.filter((v) => v.data.name === name).length > 0,
            value: name,
            set: setName,
            message:
                (name.trim().length < 5 && 'Table name cannot be less than 5 characters long') ||
                (reservedFields.includes(name.trim().toLowerCase()) && 'Invalid table name, please choose another') ||
                (devices.filter((v) => v.data.name === name).length > 0 && 'Table name is already used'),
        },
        ip: {
            error: () =>
                ip.trim().length < 5 ||
                devices.filter((v) => v.data.ip + ':' + v.data.port === ip + ':' + port).length > 0,
            value: ip,
            set: setIP,
            message:
                ip.trim().length < 5 ? 'IP cannot be less than 5 characters' : 'IP + Port is used on another device',
        },
        port: {
            error: () =>
                port.trim().length < 2 ||
                devices.filter((v) => v.data.ip + ':' + v.data.port === ip + ':' + port).length > 0,
            value: port,
            set: setPort,
            message:
                port.trim().length < 2
                    ? 'Port cannot be less than 2 characters'
                    : 'IP + Port is used on another device',
        },
    }

    const addTableValidation = validate(addTableFields)

    const addTable = async () => {
        try {
            const existZone = zones && zones.find((zone) => zone.data.name === 'etgzone')
            const existPit = pits && pits.find((zone) => zone.data.name === 'etgpit')

            const zone =
                !existZone &&
                (await zonePublish({
                    name: 'etgzone',
                    containerIndex: 'root',
                }))
            const pit =
                !existPit &&
                (await pitPublish({
                    name: 'etgpit',
                    containerIndex: zone.index,
                }))
            const table = await tablePublish({
                name: name,
                containerIndex: existZone ? existPit.index : pit.index,
            })
            await devicePublish(
                calculateMinBetColor({
                    ...{ ...defaultValues },
                    containerIndex: table.index,
                    containerType: 'table',
                    type: 'game',
                    gameType: gameType,
                    gameVariant: gameVariant,
                    name: name.trim(),
                    ip: ip.trim(),
                    port: port.trim(),
                    updatedBy: window.localStorage.getItem('account') ?? '-',
                })
            )
        } catch (e) {
            console.warn(e)
        }
    }

    const proceed = async () => {
        setDialogSubmitted(true)
        if (!addTableValidation.error) {
            setDialogSubmitted(false)
            setDialogLoading(true)
            try {
                await addTable()
                handleClose()
            } catch (e) {
                console.warn(e)
                setAlertDialogState({
                    ...alertDialogState,
                    show: true,
                    type: 'error',
                    message: 'Unable to add table. Please try again.',
                    error: true,
                })
            }
            setDialogLoading(false)
        }
    }

    const username = window.localStorage.getItem('account') ?? '-'

    const dateDisplay = (time) => moment.unix(time / 1000000000).format('YYYY-MM-DD')

    const columns = [
        {
            field: 'online',
            headerName: 'Online',
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            color: params.value === 2 ? '#00ff00' : params.value === 1 ? '#ff8a11' : '#ff1111',
                        }}
                    >
                        {params.value === 2 ? 'online' : params.value === 1 ? 'online' : 'offline'}
                    </Box>
                )
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            color: params.value === true ? '#00ff00' : '#ff1111',
                        }}
                    >
                        {params.value === true ? 'Open' : 'Closed'}
                    </Box>
                )
            },
        },
        { field: 'tableName', headerName: 'Table Name', width: 180 },
        {
            field: 'gameType',
            headerName: 'Game Type',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{startCase(params.value)}</>}</>
            },
            width: 135,
        },
        {
            field: 'gameVariant',
            headerName: 'Game Variant',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{startCase(params.value)}</>}</>
            },
            width: 135,
        },
        {
            field: 'minbet',
            headerName: 'Min Bet',
            renderCell: (params) => {
                return (
                    <Box display='flex' alignItems='center' gap='.5rem'>
                        {params.row.minBetColor && <CircleIcon sx={{ color: params.row.minBetColor }} />}
                        <>{formatCashString(params.value)}</>
                    </Box>
                )
            },
            width: 230,
        },
        {
            field: 'maxbet',
            headerName: 'Max Bet',
            renderCell: (params) => {
                return <>{formatCashString(params.value)}</>
            },
            width: 230,
        },
        {
            field: 'trendboardLayout',
            headerName: 'Trendboard Layout',
            width: 210,
        },
        {
            field: 'lastUpdatedOn',
            headerName: 'Last Updated On',
            renderCell: (params) => {
                return <>{params.value === '-' ? <>-</> : <>{dateDisplay(params.value)}</>}</>
            },
            width: 210,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 210,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={(event) => {
                            setCurrentRow(params.row)
                            setAnchorEl(event.currentTarget)
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                )
            },
        },
    ]

    const rows = devices
        ? devices
            .filter((device) => device.data.type === 'game')
            .filter((playlist) => JSON.stringify(playlist).indexOf(searchTerm) >= 0)
            .map((device) => {
                const statusFilter = statuses.filter((st) => st.index === device.index)
                const status = statusFilter.length > 0 ? statusFilter[0] : null

                return {
                    id: device.index ? device.index : '-',
                    online: status && status.data ? (status.data.game && status.data.trendboard) ? 2 : (status.data.game ? 1 : 0) : 0,
                    service: status && status.data ? status.data.service : '',
                    containerIndex: device.data.containerIndex ? device.data.containerIndex : '-',
                    status: device.data.open,
                    tableName: device.data.name ? device.data.name : '-',
                    gameType: device.data.gameType ? device.data.gameType : '-',
                    deviceIP: device.data.ip ? device.data.ip : '-',
                    devicePort: device.data.port ? device.data.port : '-',
                    gameVariant: device.data.gameVariant ? device.data.gameVariant : '-',
                    minbet: device.data.bets
                        ? device.data.bets[device.data.gameType][device.data.gameVariant].minBet
                        : '-',
                    minBetColor: device.data.minBetColor ? device.data.minBetColor : '-',
                    maxbet: device.data.bets
                        ? device.data.bets[device.data.gameType][device.data.gameVariant].maxBet
                        : '-',
                    trendboardLayout: device.data.layout ? device.data.layout : '-',
                    lastUpdatedOn: device.updated ? device.updated : device.created,
                    lastUpdatedBy: device.data.updatedBy ? device.data.updatedBy : '-',
                }
            })
        : []

    const restartTrendboard = async (deviceID) => {
        try {
            await fetch(`http://${pivotIP}//remote/restart/trendboard/${deviceID}`)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to restart trendboard. Please try again.',
                error: true,
            })
        }
    }

    const deckChange = async (ip, port, gameType) => {
        try {
            await fetch(`http://${ip}:${port}/${gameType}/deck`)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to deck change. Please try again.',
                error: true,
            })
        }
    }

    const openTable = async (deviceID) => {
        try {
            await fetch(`http://${pivotIP}/remote/device/open/${deviceID}`)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to open table. Please try again.',
                error: true,
            })
        }
    }

    const closeTable = async (deviceID) => {
        try {
            await fetch(`http://${pivotIP}/remote/device/close/${deviceID}`)
        } catch (e) {
            console.log(e)
            setAlertDialogState({
                ...alertDialogState,
                show: true,
                type: 'error',
                message: 'Unable to close table. Please try again.',
                error: true,
            })
        }
    }

    const minMaxBetFields = {
        minBet: {
            error: () =>
                batch.minBet.changed && batch.maxBet.changed && Number(batch.minBet.value) > Number(batch.maxBet.value),
            message: 'Min bet cannot be more than max bet',
        },
        maxBet: {
            error: () =>
                batch.minBet.changed && batch.maxBet.changed && Number(batch.minBet.value) > Number(batch.maxBet.value),
            message: 'Max bet cannot be less than min bet',
        },
        tieMinBet: {
            error: () =>
                batch.tieMinBet.changed &&
                batch.tieMaxBet.changed &&
                Number(batch.tieMinBet.value) > Number(batch.tieMaxBet.value),
            message: 'Tie min bet cannot be more than tie max bet',
        },
        tieMaxBet: {
            error: () =>
                batch.tieMinBet.changed &&
                batch.tieMaxBet.changed &&
                Number(batch.tieMinBet.value) > Number(batch.tieMaxBet.value),
            message: 'Tie max bet cannot be less than tie min bet',
        },
        pairMinBet: {
            error: () =>
                batch.pairMinBet.changed &&
                batch.pairMaxBet.changed &&
                Number(batch.pairMinBet.value) > Number(batch.pairMaxBet.value),
            message: 'Pair min bet cannot be more than tie max bet',
        },
        pairMaxBet: {
            error: () =>
                batch.pairMinBet.changed &&
                batch.pairMaxBet.changed &&
                Number(batch.pairMinBet.value) > Number(batch.pairMaxBet.value),
            message: 'Pair max bet cannot be less than tie min bet',
        },
        naturalMinBet: {
            error: () =>
                batch.naturalMinBet.changed &&
                batch.naturalMaxBet.changed &&
                Number(batch.naturalMinBet.value) > Number(batch.naturalMaxBet.value),
            message: 'Natural min bet cannot be more than tie max bet',
        },
        naturalMaxBet: {
            error: () =>
                batch.naturalMinBet.changed &&
                batch.naturalMaxBet.changed &&
                Number(batch.naturalMinBet.value) > Number(batch.naturalMaxBet.value),
            message: 'Natural max bet cannot be less than tie min bet',
        },
        lucky6MinBet: {
            error: () =>
                batch.lucky6MinBet.changed &&
                batch.lucky6MaxBet.changed &&
                Number(batch.lucky6MinBet.value) > Number(batch.lucky6MaxBet.value),
            message: 'Lucky 6 min bet cannot be more than Lucky 6 max bet',
        },
        lucky6MaxBet: {
            error: () =>
                batch.lucky6MinBet.changed &&
                batch.lucky6MaxBet.changed &&
                Number(batch.lucky6MinBet.value) > Number(batch.lucky6MaxBet.value),
            message: 'Lucky 6 max bet cannot be less than Lucky 6 min bet',
        },
        mega6MinBet: {
            error: () =>
                batch.mega6MinBet.changed &&
                batch.mega6MaxBet.changed &&
                Number(batch.mega6MinBet.value) > Number(batch.mega6MaxBet.value),
            message: 'Mega 6 min bet cannot be more than Mega 6 max bet',
        },
        mega6MaxBet: {
            error: () =>
                batch.mega6MinBet.changed &&
                batch.mega6MaxBet.changed &&
                Number(batch.mega6MinBet.value) > Number(batch.mega6MaxBet.value),
            message: 'Mega 6 max bet cannot be less than Mega 6 min bet',
        },
        tigerMinBet: {
            error: () =>
                batch.tigerMinBet.changed &&
                batch.tigerMaxBet.changed &&
                Number(batch.tigerMinBet.value) > Number(batch.tigerMaxBet.value),
            message: 'Tiger min bet cannot be more than Tiger max bet',
        },
        tigerMaxBet: {
            error: () =>
                batch.tigerMinBet.changed &&
                batch.tigerMaxBet.changed &&
                Number(batch.tigerMinBet.value) > Number(batch.tigerMaxBet.value),
            message: 'Tiger max bet cannot be less than Tiger min bet',
        },
        tigerTieMinBet: {
            error: () =>
                batch.tigerTieMinBet.changed &&
                batch.tigerTieMaxBet.changed &&
                Number(batch.tigerTieMinBet.value) > Number(batch.tigerTieMaxBet.value),
            message: 'Tiger tie min bet cannot be more than Tiger tie max bet',
        },
        tigerTieMaxBet: {
            error: () =>
                batch.tigerTieMinBet.changed &&
                batch.tigerTieMaxBet.changed &&
                Number(batch.tigerTieMinBet.value) > Number(batch.tigerTieMaxBet.value),
            message: 'Tiger tie max bet cannot be less than Tiger tie min bet',
        },
        tigerPairMinBet: {
            error: () =>
                batch.tigerPairMinBet.changed &&
                batch.tigerPairMaxBet.changed &&
                Number(batch.tigerPairMinBet.value) > Number(batch.tigerPairMaxBet.value),
            message: 'Tiger pair min bet cannot be more than Tiger pair max bet',
        },
        tigerPairMaxBet: {
            error: () =>
                batch.tigerPairMinBet.changed &&
                batch.tigerPairMaxBet.changed &&
                Number(batch.tigerPairMinBet.value) > Number(batch.tigerPairMaxBet.value),
            message: 'Tiger pair max bet cannot be less than Tiger pair min bet',
        },
        tigerBigMinBet: {
            error: () =>
                batch.tigerBigMinBet.changed &&
                batch.tigerBigMaxBet.changed &&
                Number(batch.tigerBigMinBet.value) > Number(batch.tigerBigMaxBet.value),
            message: 'Tiger big min bet cannot be more than Tiger big max bet',
        },
        tigerBigMaxBet: {
            error: () =>
                batch.tigerBigMinBet.changed &&
                batch.tigerBigMaxBet.changed &&
                Number(batch.tigerBigMinBet.value) > Number(batch.tigerBigMaxBet.value),
            message: 'Tiger big max bet cannot be less than Tiger big min bet',
        },
        tigerSmallMinBet: {
            error: () =>
                batch.tigerSmallMinBet.changed &&
                batch.tigerSmallMaxBet.changed &&
                Number(batch.tigerSmallMinBet.value) > Number(batch.tigerSmallMaxBet.value),
            message: 'Tiger small min bet cannot be more than Tiger small max bet',
        },
        tigerSmallMaxBet: {
            error: () =>
                batch.tigerSmallMinBet.changed &&
                batch.tigerSmallMaxBet.changed &&
                Number(batch.tigerSmallMinBet.value) > Number(batch.tigerSmallMaxBet.value),
            message: 'Tiger small max bet cannot be less than Tiger small min bet',
        },
        anyPairMinBet: {
            error: () =>
                batch.anyPairMinBet.changed &&
                batch.anyPairMaxBet.changed &&
                Number(batch.anyPairMinBet.value) > Number(batch.anyPairMaxBet.value),
            message: 'Any pair min bet cannot be more than Any pair max bet',
        },
        anyPairMaxBet: {
            error: () =>
                batch.anyPairMinBet.changed &&
                batch.anyPairMaxBet.changed &&
                Number(batch.anyPairMinBet.value) > Number(batch.anyPairMaxBet.value),
            message: 'Any pair max bet cannot be less than Any pair min bet',
        },
        anyPairAnyPlayerMinBet: {
            error: () =>
                batch.anyPairAnyPlayerMinBet.changed &&
                batch.anyPairAnyPlayerMaxBet.changed &&
                Number(batch.anyPairAnyPlayerMinBet.value) > Number(batch.anyPairAnyPlayerMaxBet.value),
            message: 'Any pair any player min bet cannot be more than Any pair any player max bet',
        },
        anyPairAnyPlayerMaxBet: {
            error: () =>
                batch.anyPairAnyPlayerMinBet.changed &&
                batch.anyPairAnyPlayerMaxBet.changed &&
                Number(batch.anyPairAnyPlayerMinBet.value) > Number(batch.anyPairAnyPlayerMaxBet.value),
            message: 'Any pair any player max bet cannot be less than Any pair any player min bet',
        },
        tripleDiceLimit: {
            error: () => batch.tripleDiceLimit.changed && Number(batch.tripleDiceLimit.value) < 1,
            message: 'Triple dice limit cannot be less than 1',
        },
        doubleDiceLimit: {
            error: () => batch.doubleDiceLimit.changed && Number(batch.doubleDiceLimit.value) < 1,
            message: 'Double dice limit cannot be less than 1',
        },
    }

    const minMaxBetvalidation = validate(minMaxBetFields)

    const isSelectionIncludeDeviceWithGameType = (gameType) => {
        const match = deviceIndexes.map((di) => devices.filter((device) => device.index === di)).flat()
        return match.some((match) => match.data.gameType === gameType) ? true : false
    }

    const isSelectionIncludeDeviceWithGameVariant = (gameVariant) => {
        const match = deviceIndexes.map((di) => devices.filter((device) => device.index === di)).flat()
        return match.some((match) => match.data.gameVariant === gameVariant) ? true : false
    }

    const isSelectionNoSideBet = () => {
        const match = deviceIndexes.map((di) => devices.filter((device) => device.index === di)).flat()
        return match.every((match) => match.data.gameType === 'roulette' && match.data.gameVariant === 'standard') ||
            match.every((match) => match.data.gameType === 'blackjack' && match.data.gameVariant === 'standard') ||
            match.every(
                (match) =>
                    (match.data.gameType === 'blackjack' && match.data.gameVariant === 'standard') ||
                    (match.data.gameType === 'roulette' && match.data.gameVariant === 'standard')
            )
            ? true
            : false
    }

    const isMinMaxRuleMet = () => {
        let errorMessage = []

        deviceIndexes.map((deviceIndex) => {
            const match = devices.find((device) => device.index === deviceIndex)
            const changedFieldsKeys = Object.keys(batch).filter((key) => batch[key].changed)

            if (
                match &&
                changedFieldsKeys.includes('minBet') &&
                !changedFieldsKeys.includes('maxBet') &&
                batch.minBet.value > match.data.bets[match.data.gameType][match.data.gameVariant].maxBet
            ) {
                errorMessage.push(`${match.data.name}: Min bet cannot be greater than current max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('maxBet') &&
                !changedFieldsKeys.includes('minBet') &&
                batch.maxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].minBet
            ) {
                errorMessage.push(`${match.data.name}: Max bet cannot be less than current min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tieMinBet') &&
                !changedFieldsKeys.includes('tieMaxBet') &&
                batch.tieMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tieMaxBet
            ) {
                errorMessage.push(`${match.data.name}: Tie min bet cannot be greater than current tie max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tieMaxBet') &&
                !changedFieldsKeys.includes('tieMinBet') &&
                batch.tieMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tieMinBet
            ) {
                errorMessage.push(`${match.data.name}: Tie max bet cannot be less than current tie min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('pairMinBet') &&
                !changedFieldsKeys.includes('pairMaxBet') &&
                batch.pairMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].pairMaxBet
            ) {
                errorMessage.push(`${match.data.name}: Pair min bet cannot be greater than current pair max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('pairMaxBet') &&
                !changedFieldsKeys.includes('pairMinBet') &&
                batch.pairMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].pairMinBet
            ) {
                errorMessage.push(`${match.data.name}: Pair max bet cannot be less than current pair min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('naturalMinBet') &&
                !changedFieldsKeys.includes('naturalMaxBet') &&
                batch.naturalMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].naturalMaxBet
            ) {
                errorMessage.push(`${match.data.name}: Natural min bet cannot be greater than current natural max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('naturalMaxBet') &&
                !changedFieldsKeys.includes('naturalMinBet') &&
                batch.naturalMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].naturalMinBet
            ) {
                errorMessage.push(`${match.data.name}: Natural max bet cannot be less than current natural min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('lucky6MinBet') &&
                !changedFieldsKeys.includes('lucky6MaxBet') &&
                batch.lucky6MinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].lucky6MaxBet
            ) {
                errorMessage.push(`${match.data.name}: Lucky6 min bet cannot be greater than current lucky6 max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('lucky6MaxBet') &&
                !changedFieldsKeys.includes('lucky6MinBet') &&
                batch.lucky6MaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].lucky6MinBet
            ) {
                errorMessage.push(`${match.data.name}: Lucky6 max bet cannot be less than current lucky6 min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('mega6MinBet') &&
                !changedFieldsKeys.includes('mega6MaxBet') &&
                batch.mega6MinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].mega6MaxBet
            ) {
                errorMessage.push(`${match.data.name}: Mega6 min bet cannot be greater than current mega6 max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('mega6MaxBet') &&
                !changedFieldsKeys.includes('mega6MinBet') &&
                batch.mega6MaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].mega6MinBet
            ) {
                errorMessage.push(`${match.data.name}: Mega6 max bet cannot be less than current mega6 min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerMinBet') &&
                !changedFieldsKeys.includes('tigerMaxBet') &&
                batch.tigerMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerMaxBet
            ) {
                errorMessage.push(`${match.data.name}: Tiger min bet cannot be greater than current Tiger max bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerMaxBet') &&
                !changedFieldsKeys.includes('tigerMinBet') &&
                batch.tigerMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerMinBet
            ) {
                errorMessage.push(`${match.data.name}: Tiger max bet cannot be less than current Tiger min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerTieMinBet') &&
                !changedFieldsKeys.includes('tigerTieMaxBet') &&
                batch.tigerTieMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerTieMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger tie min bet cannot be greater than current Tiger tie max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerTieMaxBet') &&
                !changedFieldsKeys.includes('tigerTieMinBet') &&
                batch.tigerTieMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerTieMinBet
            ) {
                errorMessage.push(`${match.data.name}: Tiger tie max bet cannot be less than current Tiger tie min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerPairMinBet') &&
                !changedFieldsKeys.includes('tigerPairMaxBet') &&
                batch.tigerPairMinBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].tigerPairMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger pair min bet cannot be greater than current Tiger pair max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerPairMaxBet') &&
                !changedFieldsKeys.includes('tigerPairMinBet') &&
                batch.tigerPairMaxBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].tigerPairMinBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger pair max bet cannot be less than current Tiger pair min bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerBigMinBet') &&
                !changedFieldsKeys.includes('tigerBigMaxBet') &&
                batch.tigerBigMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerBigMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger big min bet cannot be greater than current Tiger big max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerBigMaxBet') &&
                !changedFieldsKeys.includes('tigerBigMinBet') &&
                batch.tigerBigMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].tigerBigMinBet
            ) {
                errorMessage.push(`${match.data.name}: Tiger big max bet cannot be less than current Tiger big min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerSmallMinBet') &&
                !changedFieldsKeys.includes('tigerSmallMaxBet') &&
                batch.tigerSmallMinBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].tigerSmallMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger small min bet cannot be greater than current Tiger small max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('tigerSmallMaxBet') &&
                !changedFieldsKeys.includes('tigerSmallMinBet') &&
                batch.tigerSmallMaxBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].tigerSmallMinBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Tiger small max bet cannot be less than current Tiger small min bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('anyPairMinBet') &&
                !changedFieldsKeys.includes('anyPairMaxBet') &&
                batch.anyPairMinBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].anyPairMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Any pair min bet cannot be greater than current Any pair max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('anyPairMaxBet') &&
                !changedFieldsKeys.includes('anyPairMinBet') &&
                batch.anyPairMaxBet.value < match.data.bets[match.data.gameType][match.data.gameVariant].anyPairMinBet
            ) {
                errorMessage.push(`${match.data.name}: Any pair max bet cannot be less than current Any pair min bet`)
            }
            if (
                match &&
                changedFieldsKeys.includes('anyPairAnyPlayerMinBet') &&
                !changedFieldsKeys.includes('anyPairAnyPlayerMaxBet') &&
                batch.anyPairAnyPlayerMinBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].anyPairAnyPlayerMaxBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Any pair any player min bet cannot be greater than current Any pair any player max bet`
                )
            }
            if (
                match &&
                changedFieldsKeys.includes('anyPairAnyPlayerMaxBet') &&
                !changedFieldsKeys.includes('anyPairAnyPlayerMinBet') &&
                batch.anyPairAnyPlayerMaxBet.value <
                match.data.bets[match.data.gameType][match.data.gameVariant].anyPairAnyPlayerMinBet
            ) {
                errorMessage.push(
                    `${match.data.name}: Any pair any player max bet cannot be less than current Any pair any player min bet`
                )
            }

            return errorMessage
        })

        return errorMessage
    }

    const Apply = async (deviceIndex) => {
        const publish = usePublish('devices/' + deviceIndex)
        const device = devices.filter((device) => device.index === deviceIndex)[0]

        const getNewBetsObj = (gameType, gameVariant) => {
            const betsObj = device.data.bets ? cloneDeep(device).data.bets : defaultValues.bets
            const betsObjByVariant = betsObj[gameType][gameVariant]

            keys(betsObjByVariant).forEach((item) => {
                keys(batch).forEach((field) => {
                    if (batch[item] && batch[field].changed && item === field) {
                        console.log(`${field} has changed`)
                        betsObjByVariant[item] = Number(batch[field].value)
                    }
                })
            })
            return betsObj
        }

        try {
            return publish({
                ...device.data,
                ...(batch.minBet.changed && {
                    minBetColor: device.data.colorThresholds
                        ? getMinBetColor(Number(batch.minBet.value), device.data.colorThresholds)
                        : getMinBetColor(Number(batch.minBet.value), defaultValues.colorThresholds),
                }),
                bets: getNewBetsObj(device.data.gameType, device.data.gameVariant),
                updatedBy: username,
            })
        } catch (error) {
            console.log('error:', error)
        }
    }

    const getDeviceName = (deviceIndex) => {
        const match = devices.filter((device) => device.index === deviceIndex)
        return match ? match[0].data.name : ''
    }

    const applyBtnClick = () => {
        setButtonSubmitted(true)

        if (!isEmpty(isMinMaxRuleMet())) {
            setAlertDialogState({
                show: 'true',
                type: 'reminder',
                message: isMinMaxRuleMet(),
                error: 'true',
            })
            setButtonSubmitted(false)
            return
        }

        if (!minMaxBetvalidation.error) {
            setButtonLoading(true)

            const promises = deviceIndexes.map((deviceIndex) => Apply(deviceIndex))

            Promise.allSettled(promises).then((result) => {
                setButtonLoading(false)

                const successDevices = result.map((v) => (v.value.index ? v.value.index : '')).filter((v) => v !== '')
                const successDevicesNames = successDevices.map((v) => getDeviceName(v))
                const failedDevices = deviceIndexes.filter((v) => !successDevices.includes(v))
                const failedDevicesNames = failedDevices.map((v) => getDeviceName(v))

                setAlertDialogState({
                    show: 'true',
                    type: 'success',
                    message: `${!isEmpty(successDevices)
                        ? `Updated success for ${successDevices.length > 1 ? successDevicesNames.join(', ') : successDevicesNames[0]
                        }.`
                        : ''
                        } ${!isEmpty(failedDevices)
                            ? `Updated failed for ${failedDevices.length > 1 ? failedDevicesNames.join(', ') : failedDevicesNames[0]
                            }.`
                            : ''
                        }`,
                    error: 'false',
                })

                setBatch(initialBatch)
            })

            setButtonSubmitted(false)
        }
    }

    return (
        <Box padding='2rem' display='flex' flexDirection='column' gap='3rem'>
            <Box display='flex' flexDirection='column' gap='1.25rem'>
                <Box
                    display='flex'
                    flexDirection={mobile ? 'column' : 'row'}
                    alignItems='center'
                    justifyContent='space-between'
                    gap='1rem'
                >
                    <Box width={mobile ? '100%' : 'auto'}>
                        <Typography gutterBottom variant='h5' component='div' fontWeight='bold'>
                            Tables
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            Operator and Pitboss controls for table setup and configuration
                        </Typography>
                    </Box>

                    <Box
                        width={mobile ? '100%' : 'auto'}
                        display='flex'
                        flexDirection={mobile ? 'column' : 'row'}
                        alignItems='center'
                        gap='1rem'
                    >
                        <Box width={mobile ? '100%' : 'auto'}>
                            <Button
                                sx={{ width: mobile ? '100%' : 'auto' }}
                                variant='contained'
                                color='primary'
                                onClick={handleClickOpen}
                            >
                                <AddIcon />
                                &nbsp; Add Table
                            </Button>
                        </Box>

                        <TextField
                            sx={{ width: mobile ? '100%' : 'auto' }}
                            variant='outlined'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>

                <Box height='29.5rem'>
                    <DataGrid
                        sx={{
                            colorScheme: 'dark',
                            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                                outline: 'none',
                            },
                        }}
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pinnedColumns: { left: ['name'], right: ['actions'] },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15, 20]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        gridRowId={(row) => row.index}
                        onRowSelectionModelChange={(id) => {
                            setDeviceIndexes(id)
                        }}
                        onRowDoubleClick={(params) => navigate(`/xstadium/device/${params.row.id}`)}
                    />

                    <StyledMenu
                        id='actions-menu'
                        MenuListProps={{
                            'aria-labelledby': 'actions-menu-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem
                            onClick={() => {
                                navigate(`/xstadium/device/${currentRow.id}`)
                                setAnchorEl(null)
                            }}
                            disableRipple
                        >
                            <EditIcon />
                            Edit
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        {(currentRow.gameType === 'baccarat' || currentRow.gameType === 'blackjack') && (
                            <MenuItem
                                onClick={() => {
                                    deckChange(currentRow.deviceIP, currentRow.devicePort, currentRow.gameType)
                                    setAnchorEl(null)
                                }}
                                disableRipple
                            >
                                <StyleIcon />
                                Deck Change
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                restartTrendboard(currentRow.id)
                                setAnchorEl(null)
                            }}
                            disableRipple
                        >
                            <RestartAltIcon />
                            Restart Trendboard
                        </MenuItem>
                        {currentRow.status ? (
                            <MenuItem
                                onClick={() => {
                                    closeTable(currentRow.id)
                                    setAnchorEl(null)
                                }}
                                disableRipple
                            >
                                <ToggleOffIcon />
                                Close Table
                            </MenuItem>
                        ) : (
                            <MenuItem
                                onClick={() => {
                                    openTable(currentRow.id)
                                    setAnchorEl(null)
                                }}
                                disableRipple
                            >
                                <ToggleOnIcon />
                                Open Table
                            </MenuItem>
                        )}
                    </StyledMenu>
                </Box>
            </Box>

            <Box>
                <Box display='flex' justifyContent='space-between' gap='2rem'>
                    <Box display='flex' gap='2rem'>
                        <Box display='flex' flexDirection='column' gap='1rem'>
                            <FieldRow
                                id='minBet'
                                title='Min Bet'
                                state={batch.minBet.value}
                                onChange={(e) => {
                                    setBatch((prev) => ({
                                        ...prev,
                                        minBet: { value: e.target.value.replace(/\D/g, ''), changed: true },
                                    }))
                                }}
                                disabled={buttonLoading}
                                error={buttonSubmitted && minMaxBetFields.minBet.error()}
                                helperText={
                                    buttonSubmitted && minMaxBetFields.minBet.error()
                                        ? minMaxBetFields.minBet.message
                                        : null
                                }
                            />

                            <FieldRow
                                id='maxBet'
                                title='Max Bet'
                                state={batch.maxBet.value}
                                onChange={(e) => {
                                    setBatch((prev) => ({
                                        ...prev,
                                        maxBet: { value: e.target.value.replace(/\D/g, ''), changed: true },
                                    }))
                                }}
                                disabled={buttonLoading}
                                error={buttonSubmitted && minMaxBetFields.maxBet.error()}
                                helperText={
                                    buttonSubmitted && minMaxBetFields.maxBet.error()
                                        ? minMaxBetFields.maxBet.message
                                        : null
                                }
                            />
                        </Box>

                        {!isEmpty(deviceIndexes) && !isSelectionNoSideBet() && (
                            <Box
                                padding='1rem'
                                display='flex'
                                flexDirection='column'
                                border='2px solid grey'
                                gap='1rem'
                            >
                                <Typography>Side Bets</Typography>

                                <Grid container display='flex' gap='1rem'>
                                    {(isSelectionIncludeDeviceWithGameType('baccarat') ||
                                        isSelectionIncludeDeviceWithGameType('war')) && (
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='tieMinBet'
                                                    title='TIE Min Bet'
                                                    state={batch.tieMinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tieMinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={buttonLoading}
                                                    error={buttonSubmitted && minMaxBetFields.tieMinBet.error()}
                                                    helperText={
                                                        buttonSubmitted && minMaxBetFields.tieMinBet.error()
                                                            ? minMaxBetFields.tieMinBet.message
                                                            : null
                                                    }
                                                />

                                                <FieldRow
                                                    id='tieMaxBet'
                                                    title='TIE Max Bet'
                                                    state={batch.tieMaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            tieMaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={buttonLoading}
                                                    error={buttonSubmitted && minMaxBetFields.tieMaxBet.error()}
                                                    helperText={
                                                        buttonSubmitted && minMaxBetFields.tieMaxBet.error()
                                                            ? minMaxBetFields.tieMaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('baccarat') &&
                                        (isSelectionIncludeDeviceWithGameVariant('standard') ||
                                            isSelectionIncludeDeviceWithGameVariant('lucky6') ||
                                            isSelectionIncludeDeviceWithGameVariant('lucky6Slot') ||
                                            isSelectionIncludeDeviceWithGameVariant('mega6')) && (
                                            <>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='pairMinBet'
                                                        title='PAIR Min Bet'
                                                        state={batch.pairMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                pairMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={buttonSubmitted && minMaxBetFields.pairMinBet.error()}
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.pairMinBet.error()
                                                                ? minMaxBetFields.pairMinBet.message
                                                                : null
                                                        }
                                                    />

                                                    <FieldRow
                                                        id='pairMaxBet'
                                                        title='PAIR Max Bet'
                                                        state={batch.pairMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                pairMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={buttonSubmitted && minMaxBetFields.pairMaxBet.error()}
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.pairMaxBet.error()
                                                                ? minMaxBetFields.pairMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='naturalMinBet'
                                                        title='NATURAL Min Bet'
                                                        state={batch.naturalMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                naturalMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={buttonSubmitted && minMaxBetFields.naturalMinBet.error()}
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.naturalMinBet.error()
                                                                ? minMaxBetFields.naturalMinBet.message
                                                                : null
                                                        }
                                                    />

                                                    <FieldRow
                                                        id='naturalMaxBet'
                                                        title='NATURAL Max Bet'
                                                        state={batch.naturalMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                naturalMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={buttonSubmitted && minMaxBetFields.naturalMaxBet.error()}
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.naturalMaxBet.error()
                                                                ? minMaxBetFields.naturalMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('baccarat') &&
                                        (isSelectionIncludeDeviceWithGameVariant('lucky6') ||
                                            isSelectionIncludeDeviceWithGameVariant('lucky6Slot')) && (
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='lucky6MinBet'
                                                    title='LUCKY6 Min Bet'
                                                    state={batch.lucky6MinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            lucky6MinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={buttonLoading}
                                                    error={buttonSubmitted && minMaxBetFields.lucky6MinBet.error()}
                                                    helperText={
                                                        buttonSubmitted && minMaxBetFields.lucky6MinBet.error()
                                                            ? minMaxBetFields.lucky6MinBet.message
                                                            : null
                                                    }
                                                />
                                                <FieldRow
                                                    id='lucky6MaxBet'
                                                    title='LUCKY6 Max Bet'
                                                    state={batch.lucky6MaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            lucky6MaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={buttonLoading}
                                                    error={buttonSubmitted && minMaxBetFields.lucky6MaxBet.error()}
                                                    helperText={
                                                        buttonSubmitted && minMaxBetFields.lucky6MaxBet.error()
                                                            ? minMaxBetFields.lucky6MaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('baccarat') &&
                                        isSelectionIncludeDeviceWithGameVariant('mega6') && (
                                            <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                <FieldRow
                                                    id='mega6MinBet'
                                                    title='MEGA6 Min Bet'
                                                    state={batch.mega6MinBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            mega6MinBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={buttonLoading}
                                                    error={buttonSubmitted && minMaxBetFields.mega6MinBet.error()}
                                                    helperText={
                                                        buttonSubmitted && minMaxBetFields.mega6MinBet.error()
                                                            ? minMaxBetFields.mega6MinBet.message
                                                            : null
                                                    }
                                                />

                                                <FieldRow
                                                    id='mega6MaxBet'
                                                    title='MEGA6 Max Bet'
                                                    state={batch.mega6MaxBet.value}
                                                    onChange={(e) => {
                                                        setBatch((prev) => ({
                                                            ...prev,
                                                            mega6MaxBet: {
                                                                value: e.target.value.replace(/\D/g, ''),
                                                                changed: true,
                                                            },
                                                        }))
                                                    }}
                                                    disabled={buttonLoading}
                                                    error={buttonSubmitted && minMaxBetFields.mega6MaxBet.error()}
                                                    helperText={
                                                        buttonSubmitted && minMaxBetFields.mega6MaxBet.error()
                                                            ? minMaxBetFields.mega6MaxBet.message
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('baccarat') &&
                                        isSelectionIncludeDeviceWithGameVariant('tiger') && (
                                            <Grid container display='flex' gap='1rem'>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='tigerMinBet'
                                                        title='TIGER Min Bet'
                                                        state={batch.tigerMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={buttonSubmitted && minMaxBetFields.tigerMinBet.error()}
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerMinBet.error()
                                                                ? minMaxBetFields.tigerMinBet.message
                                                                : null
                                                        }
                                                    />
                                                    <FieldRow
                                                        id='tigerMaxBet'
                                                        title='TIGER Max Bet'
                                                        state={batch.tigerMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={buttonSubmitted && minMaxBetFields.tigerMaxBet.error()}
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerMaxBet.error()
                                                                ? minMaxBetFields.tigerMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='tigerTieMinBet'
                                                        title='TIGER TIE Min Bet'
                                                        state={batch.tigerTieMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerTieMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tigerTieMinBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerTieMinBet.error()
                                                                ? minMaxBetFields.tigerTieMinBet.message
                                                                : null
                                                        }
                                                    />
                                                    <FieldRow
                                                        id='tigerTieMaxBet'
                                                        title='TIGER TIE Max Bet'
                                                        state={batch.tigerTieMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerTieMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tigerTieMaxBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerTieMaxBet.error()
                                                                ? minMaxBetFields.tigerTieMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='tigerPairMinBet'
                                                        title='TIGER PAIR Min Bet'
                                                        state={batch.tigerPairMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerPairMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tigerPairMinBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerPairMinBet.error()
                                                                ? minMaxBetFields.tigerPairMinBet.message
                                                                : null
                                                        }
                                                    />
                                                    <FieldRow
                                                        id='tigerPairMaxBet'
                                                        title='TIGER PAIR Max Bet'
                                                        state={batch.tigerPairMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerPairMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tigerPairMaxBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerPairMaxBet.error()
                                                                ? minMaxBetFields.tigerPairMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='tigerBigMinBet'
                                                        title='TIGER BIG Min Bet'
                                                        state={batch.tigerBigMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerBigMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tigerBigMinBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerBigMinBet.error()
                                                                ? minMaxBetFields.tigerBigMinBet.message
                                                                : null
                                                        }
                                                    />
                                                    <FieldRow
                                                        id='tigerBigMaxBet'
                                                        title='TIGER BIG Max Bet'
                                                        state={batch.tigerBigMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerBigMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tigerBigMaxBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerBigMaxBet.error()
                                                                ? minMaxBetFields.tigerBigMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='tigerSmallMinBet'
                                                        title='TIGER SMALL Min Bet'
                                                        state={batch.tigerSmallMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerSmallMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tigerSmallMinBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerSmallMinBet.error()
                                                                ? minMaxBetFields.tigerSmallMinBet.message
                                                                : null
                                                        }
                                                    />
                                                    <FieldRow
                                                        id='tigerSmallMaxBet'
                                                        title='TIGER SMALL Max Bet'
                                                        state={batch.tigerSmallMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tigerSmallMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tigerSmallMaxBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tigerSmallMaxBet.error()
                                                                ? minMaxBetFields.tigerSmallMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('blackjack') &&
                                        isSelectionIncludeDeviceWithGameVariant('anyPairSlot') && (
                                            <Grid container display='flex' gap='1rem'>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='anyPairMinBet'
                                                        title='ANY PAIR Min Bet'
                                                        state={batch.anyPairMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                anyPairMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={buttonSubmitted && minMaxBetFields.anyPairMinBet.error()}
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.anyPairMinBet.error()
                                                                ? minMaxBetFields.anyPairMinBet.message
                                                                : null
                                                        }
                                                    />
                                                    <FieldRow
                                                        id='anyPairMaxBet'
                                                        title='ANY PAIR Max Bet'
                                                        state={batch.anyPairMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                anyPairMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={buttonSubmitted && minMaxBetFields.anyPairMaxBet.error()}
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.anyPairMaxBet.error()
                                                                ? minMaxBetFields.anyPairMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='anyPairAnyPlayerMinBet'
                                                        title='ANY PAIR ANY PLAYER Min Bet'
                                                        state={batch.anyPairAnyPlayerMinBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                anyPairAnyPlayerMinBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted &&
                                                            minMaxBetFields.anyPairAnyPlayerMinBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted &&
                                                                minMaxBetFields.anyPairAnyPlayerMinBet.error()
                                                                ? minMaxBetFields.anyPairAnyPlayerMinBet.message
                                                                : null
                                                        }
                                                    />
                                                    <FieldRow
                                                        id='anyPairAnyPlayerMaxBet'
                                                        title='ANY PAIR ANY PLAYER Max Bet'
                                                        state={batch.anyPairAnyPlayerMaxBet.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                anyPairAnyPlayerMaxBet: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted &&
                                                            minMaxBetFields.anyPairAnyPlayerMaxBet.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted &&
                                                                minMaxBetFields.anyPairAnyPlayerMaxBet.error()
                                                                ? minMaxBetFields.anyPairAnyPlayerMaxBet.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                    {isSelectionIncludeDeviceWithGameType('sicbo') &&
                                        isSelectionIncludeDeviceWithGameVariant('standard') && (
                                            <Grid container display='flex' gap='1rem'>
                                                <Grid item display='flex' flexDirection='column' gap='1rem'>
                                                    <FieldRow
                                                        id='tripleDiceLimit'
                                                        title='Triple Dice Limit'
                                                        state={batch.tripleDiceLimit.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                tripleDiceLimit: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.tripleDiceLimit.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.tripleDiceLimit.error()
                                                                ? minMaxBetFields.tripleDiceLimit.message
                                                                : null
                                                        }
                                                    />
                                                    <FieldRow
                                                        id='doubleDiceLimit'
                                                        title='Double Dice Limit'
                                                        state={batch.doubleDiceLimit.value}
                                                        onChange={(e) => {
                                                            setBatch((prev) => ({
                                                                ...prev,
                                                                doubleDiceLimit: {
                                                                    value: e.target.value.replace(/\D/g, ''),
                                                                    changed: true,
                                                                },
                                                            }))
                                                        }}
                                                        disabled={buttonLoading}
                                                        error={
                                                            buttonSubmitted && minMaxBetFields.doubleDiceLimit.error()
                                                        }
                                                        helperText={
                                                            buttonSubmitted && minMaxBetFields.doubleDiceLimit.error()
                                                                ? minMaxBetFields.doubleDiceLimit.message
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                </Grid>
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <LoadingButton
                            variant='contained'
                            onClick={() => applyBtnClick()}
                            loading={buttonLoading}
                            disabled={isEmpty(deviceIndexes)}
                        >
                            Apply
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>

            <Dialog
                key='addTableDialog'
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby='add-table-dialog-title'
                aria-describedby='add-table-dialog-description'
                PaperProps={{
                    sx: {
                        width: '25rem',
                    },
                }}
            >
                <DialogTitle id='add-table-dialog-title'>Add Table</DialogTitle>
                <DialogContent sx={{ overflowY: 'visible' }}>
                    <Box display='flex' flexDirection='column' gap='1rem'>
                        <FormControl
                            sx={{
                                display: 'flex',
                            }}
                            variant='outlined'
                            autoComplete='off'
                        >
                            <Typography>Table Type*</Typography>
                            <Select
                                id='table-type'
                                inputProps={{
                                    name: 'type',
                                    id: 'type',
                                }}
                                required
                                fullWidth
                                value={'game'}
                                disabled={true}
                                size='small'
                            >
                                <MenuItem value='game'>Game</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl
                            sx={{
                                display: 'flex',
                            }}
                            error={dialogSubmitted && addTableFields.gameType.error()}
                        >
                            <Typography>Game Type*</Typography>
                            <Select
                                id='game-type'
                                required
                                fullWidth
                                inputProps={{
                                    name: 'gameType',
                                    id: 'gameType',
                                }}
                                onChange={(e) => {
                                    setGameType(e.target.value)
                                    setGameVariant('standard')
                                }}
                                value={gameType}
                                disabled={dialogLoading}
                                size='small'
                            >
                                <MenuItem value='baccarat'>Baccarat</MenuItem>
                                <MenuItem value='blackjack'>Blackjack</MenuItem>
                                <MenuItem value='roulette'>Roulette</MenuItem>
                                <MenuItem value='sicbo'>Sicbo</MenuItem>
                                <MenuItem value='war'>Casino War</MenuItem>
                            </Select>
                            {dialogSubmitted && addTableFields.gameType.error() && (
                                <FormHelperText>{addTableFields.gameType.message}</FormHelperText>
                            )}
                        </FormControl>

                        {gameType === 'baccarat' && (
                            <FormControl
                                sx={{
                                    display: 'flex',
                                }}
                                error={dialogSubmitted && addTableFields.gameVariant.error()}
                            >
                                <Typography>Game Variant*</Typography>
                                <Select
                                    id='game-variant'
                                    required
                                    fullWidth
                                    inputProps={{
                                        name: 'gameVariant',
                                        id: 'gameVariant',
                                    }}
                                    onChange={(e) => {
                                        setGameVariant(e.target.value)
                                    }}
                                    value={gameVariant}
                                    disabled={dialogLoading}
                                    size='small'
                                >
                                    <MenuItem value='standard'>Standard</MenuItem>
                                    <MenuItem value='lucky6'>Lucky6</MenuItem>
                                    <MenuItem value='mega6'>Mega6</MenuItem>
                                    <MenuItem value='tiger'>Tiger</MenuItem>
                                    <MenuItem value='lucky6Slot'>Lucky6 with Slot</MenuItem>
                                </Select>
                                {dialogSubmitted && addTableFields.gameVariant.error() && (
                                    <FormHelperText>{addTableFields.gameVariant.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}

                        {gameType === 'blackjack' && (
                            <FormControl
                                sx={{
                                    display: 'flex',
                                }}
                                error={dialogSubmitted && addTableFields.gameVariant.error()}
                            >
                                <Typography>Game Variant*</Typography>
                                <Select
                                    id='game-variant'
                                    required
                                    fullWidth
                                    inputProps={{
                                        name: 'gameVariant',
                                        id: 'gameVariant',
                                    }}
                                    onChange={(e) => {
                                        setGameVariant(e.target.value)
                                    }}
                                    value={gameVariant}
                                    disabled={dialogLoading}
                                    size='small'
                                >
                                    <MenuItem value='standard'>Standard</MenuItem>
                                    <MenuItem value='anyPairSlot'>Any Pair with Slot</MenuItem>
                                </Select>
                                {dialogSubmitted && addTableFields.gameVariant.error() && (
                                    <FormHelperText>{addTableFields.gameVariant.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}

                        {(gameType === 'roulette' || gameType === 'sicbo') && (
                            <FormControl
                                sx={{
                                    display: 'flex',
                                }}
                                error={dialogSubmitted && addTableFields.gameVariant.error()}
                            >
                                <Typography>Game Variant*</Typography>
                                <Select
                                    id='game-variant'
                                    required
                                    fullWidth
                                    inputProps={{
                                        name: 'gameVariant',
                                        id: 'gameVariant',
                                    }}
                                    onChange={(e) => {
                                        setGameVariant(e.target.value)
                                    }}
                                    value={gameVariant}
                                    disabled={dialogLoading}
                                    size='small'
                                >
                                    <MenuItem value='standard'>Standard</MenuItem>
                                </Select>
                                {dialogSubmitted && addTableFields.gameVariant.error() && (
                                    <FormHelperText>{addTableFields.gameVariant.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}

                        {gameType === 'war' && (
                            <FormControl
                                sx={{
                                    display: 'flex',
                                }}
                                error={dialogSubmitted && addTableFields.gameVariant.error()}
                            >
                                <Typography>Game Variant*</Typography>
                                <Select
                                    id='game-variant'
                                    required
                                    fullWidth
                                    inputProps={{
                                        name: 'gameVariant',
                                        id: 'gameVariant',
                                    }}
                                    onChange={(e) => {
                                        setGameVariant(e.target.value)
                                    }}
                                    value={gameVariant}
                                    disabled={dialogLoading}
                                    size='small'
                                >
                                    <MenuItem value='etg'>ETG</MenuItem>
                                </Select>
                                {dialogSubmitted && addTableFields.gameVariant.error() && (
                                    <FormHelperText>{addTableFields.gameVariant.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}

                        <Box>
                            <Typography>Table Name*</Typography>
                            <TextField
                                required
                                size='small'
                                id='name'
                                name='name'
                                type='text'
                                fullWidth
                                variant='outlined'
                                onChange={(e) => {
                                    setName(e.target.value.replace(/[\WA-Z ]/g, ''))
                                }}
                                value={name}
                                disabled={dialogLoading}
                                error={dialogSubmitted && addTableFields.name.error()}
                                helperText={
                                    dialogSubmitted && addTableFields.name.error() ? addTableFields.name.message : null
                                }
                                autoComplete='off'
                            />
                        </Box>

                        <Box>
                            <Typography>Table IP*</Typography>
                            <TextField
                                required
                                size='small'
                                id='ip'
                                name='ip'
                                type='text'
                                fullWidth
                                variant='outlined'
                                onChange={(e) => {
                                    setIP(e.target.value)
                                }}
                                value={ip}
                                disabled={dialogLoading}
                                error={dialogSubmitted && addTableFields.ip.error()}
                                helperText={
                                    dialogSubmitted && addTableFields.ip.error() ? addTableFields.ip.message : null
                                }
                                autoComplete='off'
                            />
                        </Box>

                        <Box>
                            <Typography>Table Port*</Typography>
                            <TextField
                                required
                                size='small'
                                id='port'
                                name='port'
                                type='text'
                                fullWidth
                                variant='outlined'
                                onChange={(e) => {
                                    setPort(e.target.value.replace(/\D/g, ''))
                                }}
                                value={port}
                                disabled={dialogLoading}
                                error={dialogSubmitted && addTableFields.port.error()}
                                helperText={
                                    dialogSubmitted && addTableFields.port.error() ? addTableFields.port.message : null
                                }
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton color='primary' loading={dialogLoading} onClick={proceed}>
                        Proceed
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <AlertDialog state={alertDialogState} setState={setAlertDialogState} />
        </Box>
    )
}

export default Tables
