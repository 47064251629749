import React, { useState } from 'react'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles, withStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { usePublish, publish } from '../../api'

const XSwitch = withStyles(theme => ({
    root: {
        width: 48,
        height: 26,
        padding: 5,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        paddingLeft: 4,
    },
    thumb: {
        width: 24,
        height: 24,
    }
}))(Switch);

const madeStyles = makeStyles((theme) => ({
    root: {
        padding: 5
    },
    upload: {
        display: 'flex'
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '70vw',
        maxWidth: '70vw',
    },
    imgPreview: {
        maxWidth: '-webkit-fill-available'
    },
    levelImgPreview: {
        maxWidth: '-webkit-fill-available'
    },
    previewContainerImg: {
        paddingBottom: 10
    }
}))

const Jackpot = ({ authorize, open, handleClose, editing }) => {
    const styles = madeStyles()
    // data
    const create = usePublish('jackpots/*', authorize)

    // form control flags
    const [isLoading, setIsLoading] = useState(true)
    const [clean, setClean] = useState(true)
    const [selected, setSelected] = useState(null)

    // fields
    const [name, setName] = useState('')
    const [active, setActive] = useState(false)
    const [level, setLevel] = useState('')
    const [cnName, setCnName] = useState('')
    const [levelCnName, setLevelCnName] = useState('')
    const [img, setImg] = useState('')
    const [levelImg, setLevelImg] = useState('')

    const onChangeImg = e => {
        if (e.target.files[0]) {
            const reader = new FileReader()
            reader.addEventListener("load", (theFile) => {
                var image = new Image()
                image.src = reader.result
                image.onload = function () {
                    var height = this.height
                    var width = this.width
                    if (height > 2000 || width > 2000) {
                        alert("Height and Width must not exceed 2000px.")
                        return false
                    }
                    setImg(reader.result)
                }
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const onChangeLevelImg = e => {
        if (e.target.files[0]) {
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                var image = new Image()
                image.src = reader.result
                image.onload = function () {
                    var height = this.height
                    var width = this.width
                    if (height > 2000 || width > 2000) {
                        alert("Height and Width must not exceed 2000px.")
                        return false
                    }
                    setLevelImg(reader.result)
                }
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }

    // form control functions
    const resetForm = () => {
        setName('')
        setLevel('')
        setCnName('')
        setLevelCnName('')
        setImg('')
        setLevelImg('')
        setActive(true)
    }

    const closeDialog = () => {
        resetForm()
        setSelected(null)
        setIsLoading(true)
        handleClose()
        setIsLoading(true)
        setClean(true)
    }

    const handleNext = async () => {
        setIsLoading(true)
        try {
            if (!selected) {
                await create({
                    active,
                    name,
                    level,
                    cnName,
                    levelCnName,
                    img,
                    levelImg,
                })
            } else {
                await publish('jackpots/' + selected, {
                    active,
                    name,
                    level,
                    cnName,
                    levelCnName,
                    img,
                    levelImg,
                }, authorize)
            }
        } catch (e) {
            console.warn("failed to store jackpot", e)
        } finally {
            setTimeout(() => {
                closeDialog()
            }, 1200)

        }
    }

    if (editing && clean && open) {
        setName(editing.data.name)
        setCnName(editing.data.cnName)
        setLevel(editing.data.level)
        setLevelCnName(editing.data.levelCnName)
        setActive(editing.data.active)
        setSelected(editing.index)
        setImg(editing.data.img)
        setLevelImg(editing.data.levelImg)
        setClean(false)
        setIsLoading(false)
    }

    if (!editing && clean && open) {
        resetForm()
        setClean(false)
        setIsLoading(false)
    }

    return <Paper className={styles.root}>
        <Dialog open={open}
            aria-labelledby="form-dialog-title"
            classes={{ paper: styles.dialogPaper }}
            onClose={(e, reason) => {
                if (reason === "backdropClick") {
                    return
                }
                closeDialog()
            }}>
            <DialogTitle id="form-dialog-title">Jackpot information</DialogTitle>
            <DialogContent>
                <DialogContentText>Create a jackpot level, please notice that by canceling the proccess your changes will be lost</DialogContentText>
                {!isLoading && <>
                    <FormControlLabel
                        control={<XSwitch
                            onMouseDown={(e) => { e.preventDefault() }}
                            checked={active}
                            onChange={(e) => { setActive(e.target.checked) }}
                            inputProps={{ 'aria-label': 'toggle jackpot' }}
                        />}
                        label={<span>{'use image level'}</span>}
                        labelPlacement="end"
                    />
                    <TextField
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        tabIndex="0"
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="name"
                        fullWidth
                        focused
                    />
                    <TextField
                        variant="outlined"
                        value={cnName}
                        onChange={(e) => setCnName(e.target.value)}
                        tabIndex="2"
                        autoFocus
                        margin="dense"
                        id="cnName"
                        label="Chinesse Name"
                        type="text"
                        fullWidth
                        focused
                    />
                    <TextField
                        variant="outlined"
                        value={level}
                        onChange={(e) => setLevel(e.target.value)}
                        tabIndex="1"
                        autoFocus
                        margin="dense"
                        id="level"
                        label="Level"
                        type="text"
                        fullWidth
                        focused
                    />
                    <TextField
                        variant="outlined"
                        value={levelCnName}
                        onChange={(e) => setLevelCnName(e.target.value)}
                        tabIndex="2"
                        autoFocus
                        margin="dense"
                        id="levelCnName"
                        label="Level Chinesse Name"
                        type="text"
                        fullWidth
                        focused
                    />
                    <DialogContentText>Only pictures in (.png) format and maximum size w=2000px h=2000px are valid</DialogContentText>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Select Jackpot Image
                        <input
                            onChange={onChangeImg}
                            type="file"
                            accept="image/png"
                            hidden
                        />
                    </Button>
                    <div className={styles.previewContainerImg}>
                        {img && <img alt="jackpot" className={styles.imgPreview} src={(img.indexOf("data:") < 0 ? "https://" : "") + img}></img>}
                    </div>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Select Level Image
                        <input
                            onChange={onChangeLevelImg}
                            type="file"
                            accept="image/png"
                            hidden
                        />
                    </Button>
                    {levelImg && <div>
                        <img alt="level" className={styles.levelImgPreview} src={(levelImg.indexOf("data:") < 0 ? "https://" : "") + levelImg}></img>
                    </div>}
                </>}
                {isLoading && <LinearProgress />}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button disabled={(name === "")}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}>Save</Button>
            </DialogActions>
        </Dialog>
    </Paper>
}


export default Jackpot