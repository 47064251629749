import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const Lookup = ({ type, variant, gamesLimit, setGamesLimit, patterns, setPatterns, submitted }) => {
    const getHelperText = (value) => {
        return value < 100 ? 'Value cannot be less than 100' : value > 500 ? 'Value cannnot be greater than 500' : null
    }

    return (
        <Box sx={{ background: '#333333', padding: '1rem' }}>
            <FormControl>
                <Typography>Number of Game History to Look Up</Typography>

                <TextField
                    id='gamesLimit'
                    variant='outlined'
                    type='text'
                    value={type === 'setting' ? gamesLimit : patterns.gamesLimit}
                    onChange={(e) => {
                        if (type === 'setting') {
                            setGamesLimit(Number(e.target.value.replace(/\D/g, '')))
                        } else {
                            setPatterns((prevState) => {
                                prevState[variant].gamesLimit = Number(e.target.value.replace(/\D/g, ''))
                                return { ...prevState }
                            })
                        }
                    }}
                    error={
                        (type === 'setting' && submitted && (gamesLimit < 100 || gamesLimit > 500)) ||
                        (type !== 'setting' && submitted && (patterns.gamesLimit < 100 || patterns.gamesLimit > 500))
                    }
                    helperText={
                        (type === 'setting' && submitted && getHelperText(gamesLimit)) ||
                        (type !== 'setting' && submitted && getHelperText(patterns.gamesLimit))
                    }
                    size='small'
                />
            </FormControl>
        </Box>
    )
}

export default Lookup
