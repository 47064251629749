import React, { memo } from 'react'
import { toLower } from 'lodash'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

import R404 from '../404'
import Dealer from './dealers/Dealer'
import Dealers from './dealers/Dealers'
import Games from './games/Games'
import Overview from './overview/Overview'
import Player from './players/Player'
import Players from './players/Players'
import Settings from './settings/Settings'
import Slots from './slots/Slots'
import Statistic from './statistics/Statistic'
import Statistics from './statistics/Statistics'
import Trends from './trends/Trends'
import View from './View'

export default memo(({ route }) => {
    const defaultRoute = route || 'trend'
    const { pathname } = useLocation()
    const role = window.localStorage.getItem('role')
    const products = window.localStorage.getItem('products')
        ? window.localStorage
              .getItem('products')
              .split(',')
              .map((x) => toLower(x))
        : []

    if (role !== 'root' && !products.includes(pathname.split('/')[1])) {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            <Routes>
                <Route exact path='/' element={<Navigate to={`/xtable/${defaultRoute}`} replace />} />
                <Route exact path='/overview' element={<Overview />} />
                <Route exact path='/statistics' element={<Statistics />} />
                <Route exact path='/statistics/:gameType/:gameVariant' element={<Statistic />} />
                <Route exact path='/games' element={<Games />} />
                <Route exact path='/trends' element={<Trends />} />
                <Route exact path='/view' element={<View />} />
                <Route exact path='/players' element={<Players />} />
                <Route exact path='/player/:playerID/:gameType' element={<Player />} />
                <Route exact path='/slots' element={<Slots />} />
                <Route exact path='/dealers' element={<Dealers />} />
                <Route exact path='/dealer/:dealerID' element={<Dealer />} />
                <Route exact path='/settings' element={<Settings />} />
                <Route path='*' element={<R404 basePath='xtable/' />} />
            </Routes>
        </>
    )
})
