import React, { useState } from 'react'
import ky from 'ky'
import moment from 'moment'
import semver from 'semver'
import CancelIcon from '@mui/icons-material/Cancel'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import LoopIcon from '@mui/icons-material/Loop'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import WarningIcon from '@mui/icons-material/Warning'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import SnackbarContent from '@mui/material/SnackbarContent'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

import { api, useSubscribe } from '../api'
import { pivotIP } from '../config'

const DateDisplay = ({ time }) => moment.unix(time / 1000000000).format('Do MMM YYYY')
const TimeDisplay = ({ time }) => moment.unix(time / 1000000000).format('LTS')

const madeStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        // background: '#1f1f1fd6',
        paddingBottom: 23,
        position: 'inherit',
        width: '100%',
    },
    form: {
        padding: '1em',
        width: 700,
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 auto',
        },
    },
    createButton: {
        display: 'flex',
        margin: 5,
    },
    cancelButton: {
        display: 'flex',
        margin: 5,
        backgroundColor: '#a9a9a9',
        '&:hover': {
            backgroundColor: '#7a7a7a',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: '#7a7a7a',
            },
        },
    },
    uploadVersionButton: {
        display: 'flex',
        margin: 5,
        marginBottom: '20px',
    },
    clearOldVersionsButton: {
        display: 'flex',
        margin: 5,
        marginBottom: '20px',
    },
    switchLabel: {
        color: 'white',
    },
    deployButton: {
        display: 'flex',
        margin: 5,
        backgroundColor: '#4c8941',
        '&:hover': {
            backgroundColor: '#56a548',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: '#56a548',
            },
        },
    },
    reDeployButton: {
        display: 'flex',
        margin: 5,
        backgroundColor: '#f1932c',
        '&:hover': {
            backgroundColor: '#955a19',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: '#955a19',
            },
        },
    },
    selectControl: {
        display: 'flex',
        marginBottom: 15,
        '& > label[data-shrink="false"]': {
            transform: 'translate(14px, 15px) scale(1)',
        },
    },
    selectInput: {
        height: 7,
        padding: 14,
        paddingBottom: 22,
    },
    FilterSelect: {
        margin: 15,
    },
    FilteringContainer: {
        padding: '1em',
        background: '#2e2e2e',
        marginBottom: '1em',
    },
    ClearFiltersButton: {
        display: 'flex',
        marginTop: 15,
        marginRight: 16,
        fontSize: '0.7em',
        textTransform: 'lowercase',
        backgroundColor: '#a9a9a9',
        '&:hover': {
            backgroundColor: '#7a7a7a',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: '#7a7a7a',
            },
        },
    },
}))

const spinnner = {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(360deg)',
        },
        '100%': {
            transform: 'rotate(0deg)',
        },
    },
}

const Updates = () => {
    const styles = madeStyles()
    const [deployingStateRaw, deployingStateSocket] = useSubscribe('deploying')
    const [versions, versionsSocket] = useSubscribe('versions/*')
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [allDevices, devicesSocket] = useSubscribe('devices/*')
    const [devicesStatus, devicesStatusSocket] = useSubscribe('status/*')
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploadFailed, setUploadFailed] = useState(false)
    const [deployFailed, setDeployFailed] = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [selecting, setSelecting] = useState(false)
    const [selectedVersion, setSelectedVersion] = useState(null)
    const [selectedZones, setSelectedZones] = useState([])
    const [selectedPits, setSelectedPits] = useState([])
    const [selectedDevices, setSelectedDevices] = useState([])
    const [filtering, setFiltering] = useState(true)
    const [confirmPurge, setConfirmPurge] = useState(false)
    const [purgeFailed, setPurgeFailed] = useState(false)
    const [purgeSuccess, setPurgeSuccess] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [confirmRetry, setConfirmRetry] = useState(false)

    const active =
        deployingStateSocket &&
        deployingStateSocket.readyState === WebSocket.OPEN &&
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        devicesStatusSocket &&
        devicesStatusSocket.readyState === WebSocket.OPEN &&
        versionsSocket &&
        versionsSocket.readyState === WebSocket.OPEN

    if (!active || !deployingStateRaw || !versions || !zones || !pits || !tables || !allDevices || !devicesStatus) {
        return <LinearProgress />
    }


    const devices = allDevices
        .map((device) => {
            const filtered = devicesStatus.filter((ds) => ds.index === device.index)
            const status = filtered.length === 0 ? {} : filtered[0].data
            return {
                ...device,
                data: {
                    ...device.data,
                    status,
                },
            }
        })
        .filter((device) => device.data.type === 'game')

    const deployingState = {
        ...deployingStateRaw,
        data: {
            ...deployingStateRaw.data,
            DevicesStatus: deployingStateRaw.data.DevicesStatus
                ? deployingStateRaw.data.DevicesStatus.map((ds) => {
                    const filtered = devices.filter((dv) => dv.index === ds.ID)
                    const name = filtered.length === 0 ? "(device deleted)" : filtered[0].data.name
                    return {
                        ...ds,
                        name,
                    }
                })
                : [],
        },
    }

    const deviceDeleted = deployingStateRaw.data && Array.isArray(deployingStateRaw.data.DevicesStatus) ? deployingStateRaw.data.DevicesStatus.filter(ds => {
        const filtered = devices.filter((dv) => dv.index === ds.ID)
        if (filtered.length === 0) {
            return true
        }
        return false
    }).length > 0 : false

    const sortedVersions = versions.sort((a, b) => {
        let vera = a.data.tag.trim().split('_')[0]
        let verb = b.data.tag.trim().split('_')[0]
        const av = semver.clean(vera)
        const bv = semver.clean(verb)
        if (semver.gt(av, bv)) {
            return -1
        }
        if (semver.lt(av, bv)) {
            return 1
        }

        return 0
    })
    const auxCurrentSelectedVersion = versions.filter((a) => a.data.selected)
    const currentSelectedVersion = auxCurrentSelectedVersion.length > 0 ? auxCurrentSelectedVersion[0] : {}
    if (selectedVersion === null && !!currentSelectedVersion.index) {
        setSelectedVersion(currentSelectedVersion.index)
    }

    const selectedZonesIndexes = selectedZones.map((zone) => zone.index)
    const pitsAvailable = pits.filter((pit) => selectedZonesIndexes.indexOf(pit.data.containerIndex) >= 0)
    const selectedPitsIndexes = selectedPits.map((pit) => pit.index)
    const availablePitsIndexes = pitsAvailable.map((pit) => pit.index)
    const tablesAvailable = tables.filter(
        (table) =>
            selectedZonesIndexes.indexOf(table.data.containerIndex) >= 0 ||
            selectedPitsIndexes.indexOf(table.data.containerIndex) >= 0 ||
            (selectedPitsIndexes.length === 0 && availablePitsIndexes.indexOf(table.data.containerIndex) >= 0)
    )
    const availableTablesIndexes = tablesAvailable.map((table) => table.index)
    const availableDevices = devices.filter(
        (device) =>
            !filtering ||
            selectedZonesIndexes.indexOf(device.data.containerIndex) >= 0 ||
            selectedPitsIndexes.indexOf(device.data.containerIndex) >= 0 ||
            availableTablesIndexes.indexOf(device.data.containerIndex) >= 0 ||
            (selectedZonesIndexes.length === 0 && selectedPitsIndexes.length === 0)
    )
    const availableDevicesIndexes = availableDevices.map((device) => device.index)

    if (selectedZones.length === 0 && selectedPits.length > 0) {
        setSelectedPits([])
    }

    if (availableDevices.length === 0 && selectedDevices.length > 0) {
        setSelectedDevices([])
    }

    const selectedDevicesFromAvailable = selectedDevices.filter(
        (device) => availableDevicesIndexes.indexOf(device.index) >= 0
    )
    if (selectedDevicesFromAvailable.length < selectedDevices.length) {
        setSelectedDevices(selectedDevicesFromAvailable)
    }

    const countDevicesToDeploy =
        selectedDevices.length === 0 || !filtering ? availableDevicesIndexes.length : selectedDevices.length

    const activeFilters = selectedZones.length > 0 || selectedPits.length > 0 || selectedDevices.length > 0

    const handleClickUpdate = async () => {
        setConfirmUpdate(false)
        setLoading(true)
        clearNotifications()
        try {
            const devicesToDeploy = selectedDevices.length === 0 || !filtering ? availableDevices : selectedDevices
            const DevicesIDs = devicesToDeploy.map((device) => device.index).filter((did) => !!did)
            await api
                .post('version/job/doupdate', {
                    json: {
                        DevicesIDs,
                    },
                })
                .json()
            setLoading(false)
            clearAllFilters()
        } catch (e) {
            console.warn('nope', e)
            setLoading(false)
        }
    }

    const handleClickRetry = async () => {
        setConfirmRetry(false)
        setLoading(true)
        clearNotifications()
        try {
            const DevicesIDs = deployingState.data.DevicesStatus.map((device) => {
                if (device.Status === 'failed') {
                    return device.ID
                }

                return null
            }).filter((did) => !!did)
            await api
                .post('version/job/doupdate', {
                    json: {
                        DevicesIDs,
                    },
                })
                .json()
            setLoading(false)
        } catch (e) {
            console.warn('nope', e)
            setLoading(false)
        }
    }

    const applySelectedVersion = async () => {
        const kyt = ky.extend({
            timeout: 5 * 60000,
        })
        setLoading(true)
        clearNotifications()
        setSelecting(true)
        try {
            const url = 'http://' + pivotIP + '/version/select/' + selectedVersion
            await kyt.get(url)
            setLoading(false)
            setSelecting(false)
        } catch (e) {
            setLoading(false)
            setSelecting(false)
            setDeployFailed(true)
            console.log('error:', e)
        }
    }

    const onVersionFileChange = async (e) => {
        const url = 'http://' + pivotIP + '/version/create'
        const req = new XMLHttpRequest()
        setLoading(true)
        setUploading(true)
        clearNotifications()
        req.timeout = 50 * 60000 // time in milliseconds, 50 min
        req.open('POST', url, true)
        req.onload = (e) => {
            console.log('// Uploaded.', e)
            if (e.target.status === 200) {
                console.log('done')
                setTimeout(() => {
                    setLoading(false)
                    setUploading(false)
                    setSelectedVersion(null)
                }, 500)
            } else {
                setLoading(false)
                setUploading(false)
                setUploadFailed(true)
                console.log('error.', e.target.statusText)
            }
        }
        req.onerror = (e) => {
            setLoading(false)
            setUploading(false)
            setUploadFailed(true)
            console.log('error.', e)
        }
        req.send(e.target.files[0])
    }

    const purgeVersions = async () => {
        const kyt = ky.extend({
            timeout: 5 * 60000,
        })
        setConfirmPurge(false)
        setLoading(true)
        clearNotifications()
        try {
            const url = 'http://' + pivotIP + '/version/purge'
            await kyt.get(url)
            setLoading(false)
            setPurgeSuccess(true)
        } catch (e) {
            setLoading(false)
            setPurgeFailed(true)
            console.log('error:', e)
        }
    }

    const clearAllFilters = () => {
        setSelectedZones([])
        setSelectedPits([])
        setSelectedDevices([])
    }

    const clearNotifications = () => {
        setUploadSuccess(false)
        setUploadFailed(false)
        setDeployFailed(false)
        setPurgeFailed(false)
        setPurgeSuccess(false)
    }

    return (
        <div className={styles.root}>
            <Dialog
                open={confirmPurge}
                onClose={() => setConfirmPurge(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    Do you want to clear old versions? This action cannot be undone
                </DialogTitle>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setConfirmPurge(false)} color='primary'>
                        No
                    </Button>
                    <Button variant='contained' onClick={purgeVersions}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {currentSelectedVersion.data && (
                <Dialog
                    open={confirmUpdate}
                    onClose={() => setConfirmUpdate(false)}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>
                        Do you want to start the update?
                        {countDevicesToDeploy > 1 && ` (` + countDevicesToDeploy + `) devices`}
                        {countDevicesToDeploy === 1 && ` (` + countDevicesToDeploy + `) device`} -{' '}
                        {currentSelectedVersion.data.tag}
                    </DialogTitle>
                    <DialogActions>
                        <Button variant='outlined' onClick={() => setConfirmUpdate(false)} color='primary'>
                            No
                        </Button>
                        <Button variant='contained' onClick={handleClickUpdate}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Dialog
                open={confirmRetry}
                onClose={() => setConfirmRetry(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Do you want to start the retry update?</DialogTitle>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setConfirmRetry(false)} color='primary'>
                        No
                    </Button>
                    <Button variant='contained' onClick={handleClickRetry}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {!selecting && !uploading && (
                <SnackbarContent
                    style={{
                        backgroundColor: deployingState.data.Status === 'failed' ? '#f1932c' : 'rgb(90 143 43)',
                        maxWidth: 'unset',
                        borderRadius: 0,
                    }}
                    action={[]}
                    message={
                        <Typography component='p' style={{ color: 'white' }}>
                            {deployingState.data.Status === 'failed' && (
                                <WarningIcon style={{ verticalAlign: 'bottom', color: '#f0cf81' }} />
                            )}
                            {deployingState.data.Status === 'done' && (
                                <ThumbUpIcon style={{ verticalAlign: 'bottom', color: '#ffffff' }} />
                            )}
                            {deployingState.data.Status === 'failed' && (
                                <span style={{ marginLeft: '5px' }}>last update failed on some devices</span>
                            )}
                            {deployingState.data.Status === 'done' && (
                                <span style={{ marginLeft: '5px' }}>
                                    last update of {deployingState.data.Version} was successful on{' '}
                                    <DateDisplay time={deployingState.data.EndedAt} /> -{' '}
                                    <TimeDisplay time={deployingState.data.EndedAt} />
                                </span>
                            )}
                            {deployingState.data.Status === 'working' && (
                                <span style={{ marginLeft: '5px' }}>
                                    Update in progress ({deployingState.data.Version}):
                                </span>
                            )}
                            {deployingState.data.Status !== 'done' &&
                                deployingState.data.DevicesStatus &&
                                deployingState.data.DevicesStatus.map((ds) => {
                                    if (deployingState.data.Status !== 'working' && ds.Status === 'done') {
                                        return null
                                    }
                                    const iconStyle = { verticalAlign: 'bottom' }
                                    return (
                                        <span
                                            key={ds.ID}
                                            style={{
                                                marginLeft: '35px',
                                                marginTop: '10px',
                                                display: 'block',
                                            }}
                                        >
                                            {ds.Status === 'failed' && <ErrorIcon style={iconStyle} />}
                                            {ds.Status === 'done' && <ThumbUpIcon style={iconStyle} />}
                                            {ds.Status === 'working' && <LoopIcon style={iconStyle} sx={spinnner} />}
                                            <span
                                                style={{
                                                    marginTop: '10px',
                                                    fontSize: '0.7em',
                                                    fontStyle: 'italic',
                                                    marginLeft: '4px',
                                                }}
                                            >
                                                {ds.name}
                                            </span>
                                        </span>
                                    )
                                })}
                        </Typography>
                    }
                />
            )}
            {purgeSuccess && (
                <SnackbarContent
                    style={{
                        backgroundColor: 'rgb(50 68 90)',
                        maxWidth: 'unset',
                        borderRadius: 0,
                    }}
                    action={[
                        <IconButton
                            style={{ color: 'white' }}
                            size='large'
                            key='close'
                            aria-label='Close'
                            color='inherit'
                            onClick={() => setPurgeSuccess(false)}
                        >
                            <CancelIcon />
                        </IconButton>,
                    ]}
                    message={
                        <Typography component='p' style={{ color: 'white' }}>
                            <InfoIcon style={{ verticalAlign: 'bottom', color: '#f0cf81', marginRight: 5 }} />
                            successfully cleared older versions.
                        </Typography>
                    }
                />
            )}
            {purgeFailed && (
                <SnackbarContent
                    style={{
                        backgroundColor: '#f1932c',
                        maxWidth: 'unset',
                        borderRadius: 0,
                    }}
                    action={[
                        <IconButton
                            style={{ color: 'white' }}
                            key='close'
                            aria-label='Close'
                            color='inherit'
                            onClick={() => setPurgeFailed(false)}
                        >
                            <CancelIcon />
                        </IconButton>,
                    ]}
                    message={
                        <Typography component='p' style={{ color: 'white' }}>
                            <WarningIcon style={{ verticalAlign: 'bottom', color: '#f0cf81', marginRight: 5 }} />
                            we were not able to clear older versions, please contact support.
                        </Typography>
                    }
                />
            )}
            {uploadSuccess && currentSelectedVersion.data && (
                <SnackbarContent
                    style={{
                        backgroundColor: 'rgb(50 68 90)',
                        maxWidth: 'unset',
                        borderRadius: 0,
                    }}
                    action={[
                        <IconButton
                            style={{ color: 'white' }}
                            key='close'
                            aria-label='Close'
                            color='inherit'
                            onClick={() => setUploadSuccess(false)}
                        >
                            <CancelIcon />
                        </IconButton>,
                    ]}
                    message={
                        <Typography component='p' style={{ color: 'white' }}>
                            <InfoIcon style={{ verticalAlign: 'bottom', color: '#f0cf81', marginRight: 5 }} />
                            current active version changed to {currentSelectedVersion.data.tag}.
                        </Typography>
                    }
                />
            )}
            {uploadFailed && (
                <SnackbarContent
                    style={{
                        backgroundColor: '#f1932c',
                        maxWidth: 'unset',
                        borderRadius: 0,
                    }}
                    action={[
                        <IconButton
                            style={{ color: 'white' }}
                            key='close'
                            aria-label='Close'
                            color='inherit'
                            onClick={() => setUploadFailed(false)}
                        >
                            <CancelIcon />
                        </IconButton>,
                    ]}
                    message={
                        <Typography component='p' style={{ color: 'white' }}>
                            <WarningIcon style={{ verticalAlign: 'bottom', color: '#f0cf81', marginRight: 5 }} />
                            we were not able to upload the new version, please contact support.
                        </Typography>
                    }
                />
            )}
            {deployFailed && (
                <SnackbarContent
                    style={{
                        backgroundColor: '#f1932c',
                        maxWidth: 'unset',
                        borderRadius: 0,
                    }}
                    action={[
                        <IconButton
                            style={{ color: 'white' }}
                            key='close'
                            aria-label='Close'
                            color='inherit'
                            onClick={() => setDeployFailed(false)}
                        >
                            <CancelIcon />
                        </IconButton>,
                    ]}
                    message={
                        <Typography component='p' style={{ color: 'white' }}>
                            <WarningIcon style={{ verticalAlign: 'bottom', color: '#f0cf81' }} />
                            we were not able to update, please contact support.
                        </Typography>
                    }
                />
            )}
            {selecting && (
                <SnackbarContent
                    style={{
                        backgroundColor: '#f1932c',
                        maxWidth: 'unset',
                        borderRadius: 0,
                    }}
                    action={[]}
                    message={
                        <Typography component='p' style={{ color: 'white' }}>
                            <WarningIcon style={{ verticalAlign: 'bottom', color: '#f0cf81' }} />
                            setting up the new selected version, please don't close the browser while it's in progress.
                        </Typography>
                    }
                />
            )}
            {uploading && (
                <SnackbarContent
                    style={{
                        backgroundColor: '#f1932c',
                        maxWidth: 'unset',
                        borderRadius: 0,
                    }}
                    action={[]}
                    message={
                        <Typography component='p' style={{ color: 'white' }}>
                            <WarningIcon style={{ verticalAlign: 'bottom', color: '#f0cf81' }} />
                            uploading the new version, please don't close the browser while it's in progress.
                        </Typography>
                    }
                />
            )}
            <form
                className={styles.form}
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                autoComplete='off'
            >
                {sortedVersions.length > 0 && (
                    <FormControl
                        className={styles.selectControl}
                        variant='outlined'
                        style={{ margin: '20px 0' }}
                        autoComplete='off'
                    >
                        <InputLabel htmlFor='version-label'>Version</InputLabel>
                        <Select
                            required
                            fullWidth
                            inputProps={{
                                name: 'type',
                                id: 'type',
                                className: styles.selectInput,
                            }}
                            onChange={(e) => {
                                setSelectedVersion(e.target.value)
                            }}
                            value={selectedVersion}
                            error={selectedVersion !== currentSelectedVersion.index}
                            disabled={deployingState.data.Status === 'working' || loading}
                            label='version-label'
                        // labelWidth={95}
                        >
                            {sortedVersions.map((vl) => {
                                return (
                                    <MenuItem key={vl.index} value={vl.index}>
                                        {vl.data.tag.trim()}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        {selectedVersion !== currentSelectedVersion.index && (
                            <Button
                                className={styles.createButton}
                                color='secondary'
                                variant='contained'
                                component='label'
                                disabled={deployingState.data.Status === 'working' || loading}
                                onClick={applySelectedVersion}
                            >
                                Apply selected version
                            </Button>
                        )}
                        {selectedVersion !== currentSelectedVersion.index && (
                            <Button
                                className={styles.cancelButton}
                                color='primary'
                                variant='contained'
                                component='label'
                                disabled={deployingState.data.Status === 'working' || loading}
                                onClick={() => {
                                    setSelectedVersion(currentSelectedVersion.index)
                                }}
                            >
                                cancel
                            </Button>
                        )}
                    </FormControl>
                )}
                {(selectedVersion === currentSelectedVersion.index || !currentSelectedVersion.index) && (
                    <Button
                        className={styles.uploadVersionButton}
                        color='primary'
                        variant='contained'
                        component='label'
                        htmlFor='uploadInput'
                        disabled={deployingState.data.Status === 'working' || loading}
                    >
                        Upload new version
                    </Button>
                )}
                {currentSelectedVersion.data &&
                    selectedVersion === currentSelectedVersion.index &&
                    sortedVersions.length > 2 && (
                        <Button
                            className={styles.clearOldVersionsButton}
                            color='secondary'
                            component='label'
                            variant='contained'
                            disabled={deployingState.data.Status === 'working' || loading}
                            onClick={() => setConfirmPurge(true)}
                        >
                            Clear older versions
                        </Button>
                    )}
                {currentSelectedVersion.data && selectedVersion === currentSelectedVersion.index && (
                    <FormControlLabel
                        key='filtering'
                        className={styles.switchLabel}
                        value=''
                        control={
                            <Switch
                                color='secondary'
                                checked={filtering}
                                disabled={deployingState.data.Status === 'working' || loading}
                                onChange={(e) => setFiltering(e.target.checked)}
                            />
                        }
                        label={'Filter update by zones, pits, tables'}
                        labelPlacement='end'
                    />
                )}
                {currentSelectedVersion.data && filtering && selectedVersion === currentSelectedVersion.index && (
                    <Paper className={styles.FilteringContainer}>
                        <SnackbarContent
                            style={{
                                backgroundColor: 'rgb(96 96 96)',
                                maxWidth: 'unset',
                            }}
                            action={[]}
                            message={
                                <Typography component='p' style={{ color: '#c3c3c3' }}>
                                    <InfoIcon style={{ verticalAlign: 'bottom', color: 'rgb(197 206 207)' }} />
                                    The filters are exclusive, while the selection is empty means that the filter is
                                    open
                                </Typography>
                            }
                        />
                        {zones.length >= 2 && (
                            <Autocomplete
                                className={styles.FilterSelect}
                                multiple={true}
                                id='zones-filter'
                                key='zones-filter'
                                value={selectedZones}
                                onChange={(e, value) => setSelectedZones(value)}
                                options={zones}
                                disabled={deployingState.data.Status === 'working' || loading}
                                getOptionLabel={(option) => option.data.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label={selectedZones.length === 0 ? 'All Zones' : 'Zones'}
                                        placeholder='Zones'
                                    />
                                )}
                            />
                        )}
                        {pitsAvailable.length >= 2 && (
                            <Autocomplete
                                className={styles.FilterSelect}
                                multiple={true}
                                id='pits-filter'
                                key='pits-filter'
                                value={selectedPits}
                                onChange={(e, value) => setSelectedPits(value)}
                                options={pitsAvailable}
                                disabled={deployingState.data.Status === 'working' || loading}
                                getOptionLabel={(option) => option.data.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label={selectedPits.length === 0 ? 'All Pits' : 'Pits'}
                                        placeholder='Pits'
                                    />
                                )}
                            />
                        )}

                        {availableDevices.length >= 2 && (
                            // {availableDevices.length == 11 &&
                            <Autocomplete
                                className={styles.FilterSelect}
                                multiple={true}
                                id='devices-filter'
                                key='devices-filter'
                                value={selectedDevices}
                                onChange={(e, value) => setSelectedDevices(value)}
                                disableCloseOnSelect
                                disabled={deployingState.data.Status === 'working' || loading}
                                options={availableDevices}
                                getOp
                                isOptionEqualToValue={(option, value) => option.index === value.index}
                                getOptionLabel={(option) => option.data.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        {/* Hello there */}
                                        {option.data.name}
                                        <span
                                            style={{
                                                marginLeft: '5px',
                                                color:
                                                    option.data.status.gameVersion.trim() ===
                                                        currentSelectedVersion.data.tag.trim()
                                                        ? 'green'
                                                        : 'red',
                                            }}
                                        >
                                            {option.data.status.gameVersion.trim() ===
                                                currentSelectedVersion.data.tag.trim()
                                                ? 'current(' + currentSelectedVersion.data.tag.trim() + ')'
                                                : option.data.status.gameVersion.trim()}
                                        </span>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label={
                                            selectedDevices.length === 0
                                                ? 'All Tables (' + countDevicesToDeploy + ')'
                                                : 'Tables'
                                        }
                                        placeholder='Tables'
                                    />
                                )}
                            />
                        )}

                        {countDevicesToDeploy === 0 && (
                            <SnackbarContent
                                style={{
                                    backgroundColor: '#f1932c',
                                    maxWidth: 'unset',
                                    borderRadius: 0,
                                }}
                                action={[]}
                                message={
                                    <Typography component='p' style={{ color: 'white' }}>
                                        <WarningIcon style={{ verticalAlign: 'bottom', color: '#f0cf81' }} />
                                        Your current filter selection have no devices to deploy (selected an empty pit
                                        or empty zone?).
                                    </Typography>
                                }
                            />
                        )}
                        {activeFilters && (
                            <Grid container spacing={2}>
                                <Grid item xs={8}></Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                    }}
                                >
                                    <Button
                                        color='primary'
                                        size='small'
                                        variant='contained'
                                        className={styles.ClearFiltersButton}
                                        onClick={clearAllFilters}
                                    >
                                        clear all filters
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                )}
                {countDevicesToDeploy > 0 &&
                    currentSelectedVersion.data &&
                    selectedVersion === currentSelectedVersion.index && (
                        <Button
                            className={styles.deployButton}
                            color='primary'
                            variant='contained'
                            component='label'
                            disabled={
                                deployingState.data.Status === 'working' || loading || !currentSelectedVersion.index
                            }
                            onClick={() => setConfirmUpdate(true)}
                        >
                            {countDevicesToDeploy > 1 && `Start Update for (` + countDevicesToDeploy + `) tables`}
                            {countDevicesToDeploy === 1 && `Start Update for (` + countDevicesToDeploy + `) table`}
                        </Button>
                    )}
                {deployingState.data.Status === 'failed' && selectedVersion === currentSelectedVersion.index && !deviceDeleted && (
                    <Button
                        className={styles.reDeployButton}
                        color='primary'
                        variant='contained'
                        component='label'
                        disabled={deployingState.data.Status === 'working' || loading}
                        onClick={() => setConfirmRetry(true)}
                    >
                        Retry failed update
                    </Button>
                )}
                <input onChange={(e) => onVersionFileChange(e)} type='file' id='uploadInput' hidden />
            </form>
        </div>
    )
}

export default Updates
