import { createContext, useContext, useEffect, useState } from "react";

const Context = createContext();

export const MenuContext = ({ children }) => {
  const [selectedPit, setSelectedPit] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  const [pageNav, setPageNav] = useState("");

  const role = window.localStorage.getItem("role");

  let prevSelectedPit;
  let prevSelectedTable;

  if (role === "pitboss") {
    prevSelectedPit = window.localStorage.getItem("manager-pit");
    prevSelectedTable = window.localStorage.getItem("manager-table");
  } else if (role === "dealer") {
    prevSelectedPit = window.localStorage.getItem("dealer-pit");
    prevSelectedTable = window.localStorage.getItem("dealer-table");
  }

  // Set the state if there is cached data
  useEffect(() => {
    if (prevSelectedPit) {
      setSelectedPit(prevSelectedPit);
    }
    if (prevSelectedTable) {
      setSelectedTable(prevSelectedTable);
    }
  }, [prevSelectedPit, prevSelectedTable]);

  return (
    <Context.Provider
      value={{
        selectedPit,
        setSelectedPit,
        selectedTable,
        setSelectedTable,
        prevSelectedPit,
        prevSelectedTable,
        pageNav,
        setPageNav,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useMenuContext = () => useContext(Context);
