import React, { memo } from 'react'
import { toLower } from 'lodash'
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom'

import R404 from '../404'
import Games from '../xtable/games/Games'
import Trends from '../xtable/trends/Trends'
import DragDropTree from '../xtrend/devices/tree/DragDropTree'
import HTHT from './htht/HTHT'
import LivePlay from './liveplay/LivePlay'
import Playlist from './playlists/Playlist'
import Playlists from './playlists/Playlists'
import Scheduler from './scheduler/Scheduler'
import Servers from './servers/Servers'
import DefaultPlaylist from './settings/DefaultPlaylist'
import Support from './settings/Support'
import Volumes from './settings/Volumes'
import Screener from './settings/Screener'
import Tables from './tables/Tables'

export default memo(() => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const role = window.localStorage.getItem('role')
    const products = window.localStorage.getItem('products')
        ? window.localStorage
            .getItem('products')
            .split(',')
            .map((x) => toLower(x))
        : []

    if (role !== 'root' && !products.includes(pathname.split('/')[1])) {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            <Routes>
                <Route exact path='/' element={<Navigate to='/xstadium/liveplay/animation' replace />} />
                <Route exact path='/tables' element={<Tables />} />
                <Route exact path='/games' element={<Games xstadium />} />
                <Route exact path='/trends' element={<Trends xstadium />} />
                <Route
                    exact
                    path='/device/:index'
                    element={<DragDropTree route='Device' selected={{ type: 'Device' }} xstadium />}
                />
                <Route exact path='/liveplay/animation' element={<LivePlay type='animation' />} />
                <Route exact path='/liveplay/music' element={<LivePlay type='music' />} />
                <Route exact path='/liveplay/background' element={<LivePlay type='background' />} />
                <Route exact path='/liveplay/layout' element={<LivePlay type='layout' />} />
                <Route exact path='/liveplay/announcement' element={<LivePlay type='announcement' />} />
                <Route exact path='/liveplay/feeds' element={<LivePlay type='feed' />} />
                {/* <Route exact path='/liveplay/demo' element={<LivePlay type='demo' />} /> */}
                <Route exact path='/playlists' element={<Playlists />} />
                <Route
                    exact
                    path='/playlists/new'
                    element={<Playlist afterCreate={() => navigate('/xstadium/playlists')} />}
                />
                <Route exact path='/playlist/:id' element={<Playlist />} />
                <Route exact path='/scheduler' element={<Scheduler />} />
                <Route exact path='/servers' element={<Servers />} />
                <Route exact path='/hottrends/trends' element={<HTHT section='trends' />} />
                <Route exact path='/hottrends/settings' element={<HTHT section='settings' />} />
                <Route exact path='/settings/defaultplaylist' element={<DefaultPlaylist />} />
                <Route exact path='/settings/support' element={<Support />} />
                <Route exact path='/settings/volumes' element={<Volumes />} />
                <Route exact path='/settings/screener' element={<Screener />} />
                <Route path='*' element={<R404 basePath='xstadium/' />} />
            </Routes>
        </>
    )
})
