import React, { useState, useReducer } from 'react'
import _, { union } from 'lodash'
import moment from 'moment'
import { Bar, Line } from 'react-chartjs-2'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Card, CardHeader, CardBody, Container, Row, Col, Progress } from 'reactstrap'
import { LinearProgress } from '@mui/material'
import Grid from '@mui/material/Grid'

import { useSubscribe, tableApi, cashApi, historyApi } from '../../api'
import { prometheusTable, prometheusTrend } from '../../config'
import Location from '../../tree/Location'
import Tree from '../../tree/Tree'
import { getGameType, getDescendantDevices, getAllIPs, getBacIPs, getBjIPs } from '../../tree/TreeUtils'
import useWindowSize from '../../WindowSize'
import {
    formatCashString,
    formatCountString,
    chartBackgroundColors,
    hoursLabels,
    formatPresicePercentString,
    allPerLabelFind,
} from '../Formats'
import TableHistoryChart from '../TableHistoryChart'
import Targets from './Targets'

const styles = {
    pieContainer: {
        width: 325,
        height: 485,
        paddingTop: 35,
        margin: 'auto',
    },
    smallPieContainer: {
        width: 325,
        height: 285,
        paddingTop: 35,
        margin: 'auto',
    },
    barContainer: {
        width: 'inherit',
        height: 150,
    },
    cardTitle: {
        padding: '0.9em',
        paddingBottom: '0.2em',
    },
    cardTitleText: {
        fontSize: '1.2em',
        fontWeight: 'bold',
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardBodyTitle: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
    },
    cardBodyText: {
        padding: '0.9em',
        justifyContent: 'center',
        flex: '1 1',
        display: 'flex',
        fontSize: '1.6em',
        color: 'chartreuse',
    },
    cashValue: (v) => ({
        color: v > 0 ? 'chartreuse' : v === 0 || isNaN(v) ? 'white' : 'red',
    }),
}

const Overview = () => {
    const navigate = useNavigate()
    const [width, , resizing] = useWindowSize()
    const startOfToday = moment().startOf('day').unix()
    const endOfToday = moment().endOf('day').unix()
    const [startDate, setStartDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    const numberOfDays = Math.abs(moment.unix(endDate).diff(moment.unix(startDate), 'days'))

    // url navigation
    const loc = useLocation()
    const urlParams = new URLSearchParams(loc.search)
    const URLstartDate = urlParams.get('startDate') || window.localStorage.getItem('url:startDate')
    const URLendDate = urlParams.get('endDate') || window.localStorage.getItem('url:endDate')
    const URLlocationID = urlParams.get('locationID') || window.localStorage.getItem('url:locationID') || 'root'
    const URLlocationType = urlParams.get('locationType') || window.localStorage.getItem('url:locationType') || 'Casino'
    if (URLstartDate && Number(URLstartDate) !== startDate) {
        setStartDate(Number(URLstartDate))
    }
    if (URLendDate && Number(URLendDate) !== endDate) {
        setEndDate(Number(URLendDate))
    }

    // subscriptions
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [status, statusSocket] = useSubscribe('status/*')
    const [settings, settingsSocket] = useSubscribe('settings')
    const [targets, targetsSocket] = useSubscribe('targets')
    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        statusSocket &&
        statusSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN &&
        targetsSocket &&
        targetsSocket.readyState === WebSocket.OPEN

    // fetched data
    const [allTablesReport, setAllTablesReport] = useState(null)
    const [allTablesCashReport, setAllTablesCashReport] = useState(null)
    const [baccaratAllTablesReport, setBaccaratAllTablesReport] = useState(null)
    const [blackjackAllTablesReport, setBlackjackAllTablesReport] = useState(null)
    const [fetchedTablesReport, setFetchedTablesReport] = useState(false)
    const [fetchedTablesCashReport, setFetchedTablesCashReport] = useState(false)
    const [fetchTablesCashReportProgress, setFetchTablesCashReportProgress] = useState(0)
    const [progressLoad, setProgressLoad] = useState(0)
    const [minLoadTime, setMinLoadTime] = useState(false)

    // built charts
    const [builtCharts, setBuiltCharts] = useState({})
    const setBuiltChartProxy = (chartID, value) => {
        setBuiltCharts((prevBuilt) => ({
            ...prevBuilt,
            [chartID]: value,
        }))
    }

    const clearFetch = ({ ...props }) => {
        const propKeys = Object.keys(props).filter((v) => v !== 'push')
        const oldParams = urlParams.toString()
        propKeys.forEach((k) => {
            window.localStorage.setItem('url:' + k, props[k])
            urlParams.set(k, props[k])
        })
        const localChange =
            propKeys.indexOf('startDate') < 0 &&
            (URLstartDate !== urlParams.get('startDate') || URLendDate !== urlParams.get('endDate'))
        if (localChange) {
            urlParams.set('startDate', URLstartDate)
            urlParams.set('endDate', URLendDate)
        }
        const urlChange = oldParams !== urlParams.toString()
        if (urlChange || props.push) {
            navigate('/xtable/overview?' + urlParams.toString())
            if (propKeys.indexOf('startDate') >= 0) {
                if (!props.push) {
                    setProgressLoad(0)
                    setAllTablesReport(null)
                    setBaccaratAllTablesReport(null)
                    setBlackjackAllTablesReport(null)
                }
                setBuiltCharts({})
                setFetchedTablesReport(false)
                setFetchedTablesCashReport(false)
            }
        }
    }

    const [location, setLocation] = useReducer((_state, obj) => {
        clearFetch({ locationID: obj.branchIndex, locationType: obj.branchType })
        return [obj]
    }, null)
    const isCasinoLevel = !location || (location && location[0].branchIndex === 'root')
    const gameType = _.get(location, ['0', 'gameType'], '')

    const dateChange = (start, end, push) => {
        if (start === startDate && end === endDate && !push) return
        if (!push && (start !== startDate || end !== endDate)) {
            setStartDate(start)
            setEndDate(end)
        }
        clearFetch({ startDate: start, endDate: end, push: !!push })
    }

    const getDevicesReport = async () => {
        if (fetchedTablesReport) {
            return
        }

        const api = settings?.data.xtable.gameOnlyMode ? historyApi : tableApi

        setFetchedTablesReport(true)
        try {
            setProgressLoad(1)
            setTimeout(() => {
                setProgressLoad(11)
            }, 300)
            setTimeout(() => {
                setProgressLoad(22)
            }, 200)
            setTimeout(() => {
                setProgressLoad(33)
            }, 200)
            const responseOverview = await api
                .get('report/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBaccarat = await api
                .get('report/baccarat/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            const responseBlackjack = await api
                .get('report/blackjack/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            console.log('overview', responseOverview)
            console.log('baccarat', responseBaccarat)
            console.log('blackjack', responseBlackjack)
            setProgressLoad(40)
            setTimeout(() => {
                setProgressLoad(55)
            }, 300)
            setTimeout(() => {
                setProgressLoad(74)
            }, 600)
            setTimeout(() => {
                setProgressLoad(100)
            }, 900)
            setTimeout(() => {
                setAllTablesReport(responseOverview)
                setBaccaratAllTablesReport(responseBaccarat)
                setBlackjackAllTablesReport(responseBlackjack)
            }, 1000)
        } catch (e) {
            console.warn(e)
            setAllTablesReport([])
            setBaccaratAllTablesReport([])
            setBlackjackAllTablesReport([])
        }
    }

    const getDevicesCashReport = async () => {
        if (fetchedTablesCashReport) {
            return
        }

        setFetchedTablesCashReport(true)
        try {
            setFetchTablesCashReportProgress(1)
            setTimeout(() => {
                setFetchTablesCashReportProgress(11)
            }, 300)
            setTimeout(() => {
                setFetchTablesCashReportProgress(22)
            }, 200)
            setTimeout(() => {
                setFetchTablesCashReportProgress(33)
            }, 200)
            const response = await cashApi
                .get('report/tables/' + startDate * 1000000000 + '/' + endDate * 1000000000)
                .json()
            // console.log(response)
            setFetchTablesCashReportProgress(40)
            setFetchTablesCashReportProgress(80)
            setTimeout(() => {
                setFetchTablesCashReportProgress(84)
            }, 300)
            setTimeout(() => {
                setFetchTablesCashReportProgress(98)
            }, 600)
            setTimeout(() => {
                setAllTablesCashReport(response)
            }, 700)
        } catch (e) {
            console.warn(e)
            setAllTablesCashReport([])
        }
    }

    if (allTablesReport && location && settings && settings.data && !settings.data.xtable.gameOnlyMode) {
        getDevicesCashReport()
    }

    const getHoursCashDropData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'cash drop',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.drop['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[4],
                borderColor: chartBackgroundColors[4],
            },
        ],
    })

    const getHoursChipFillData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'chip fill',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.fill['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[5],
                borderColor: chartBackgroundColors[5],
            },
        ],
    })

    const getHoursChipCreditData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'chip credit',
                data: hoursLabels.map((hr) =>
                    tablesCashReport.reduce((prev, next) => prev + next.hourly.credit['h' + hr], 0)
                ),
                backgroundColor: chartBackgroundColors[6],
                borderColor: chartBackgroundColors[6],
            },
        ],
    })

    if (settings && Number(URLstartDate) === startDate && Number(URLendDate) === endDate) {
        getDevicesReport()
    }

    const withWager = settings && settings.data && !settings.data.xtable.gameOnlyMode

    // history
    const IPs = location ? getAllIPs(location[0].descendantDevices, zones, pits, tables, devices) : getAllIPs(['root'])
    const bacIPs = location ? getBacIPs(location[0].descendantDevices, zones, pits, tables, devices) : []
    const bjIPs = location ? getBjIPs(location[0].descendantDevices, zones, pits, tables, devices) : []

    const allHistoryLocationQuery = 'instance=~"' + IPs.join('|') + '"'
    const bacHistoryLocationQuery = 'instance=~"' + bacIPs.join('|') + '"'
    const bjHistoryLocationQuery = 'instance=~"' + bjIPs.join('|') + '"'

    const totalQueryMetricBase = 'sum({' + allHistoryLocationQuery + ', __name__=~"tray_'
    const bacQueryMetricBase = 'sum({' + bacHistoryLocationQuery + ', __name__=~"tray_'
    const bjQueryMetricBase = 'sum({' + bjHistoryLocationQuery + ', __name__=~"tray_'

    // cash drop
    const baccaratCashdropHistory = bacQueryMetricBase + 'cashdrop"})'
    const blackjackCashdropHistory = bjQueryMetricBase + 'cashdrop"})'
    const totalCashdropHistory = totalQueryMetricBase + 'cashdrop"})'

    const allCashdropHistory = [baccaratCashdropHistory, blackjackCashdropHistory, totalCashdropHistory]

    // chip fill
    const baccaratChipfillHistory = bacQueryMetricBase + 'chipfill"})'
    const blackjackChipfillHistory = bjQueryMetricBase + 'chipfill"})'
    const totalChipfillHistory = totalQueryMetricBase + 'chipfill"})'

    const allChipfillHistory = [baccaratChipfillHistory, blackjackChipfillHistory, totalChipfillHistory]

    // chip credit
    const baccaratChipcreditHistory = bacQueryMetricBase + 'chipcredit"})'
    const blackjackChipcreditHistory = bjQueryMetricBase + 'chipcredit"})'
    const totalChipcreditHistory = totalQueryMetricBase + 'chipcredit"})'

    const allChipcreditHistory = [baccaratChipcreditHistory, blackjackChipcreditHistory, totalChipcreditHistory]

    const locationQuery = isCasinoLevel ? '.+' : '(' + location[0].descendantDevices.join('|') + ')'

    const baccaratQueryBetsBase = 'sum({__name__=~"table_baccarat_' + locationQuery + '_bets_'
    const baccaratGameCountHistory = ['sum({__name__=~"table_baccarat_' + locationQuery + '_games"})']
    const baccaratProfitHistory = ['sum({__name__=~"table_baccarat_' + locationQuery + '_profit"})']
    const baccaratBetsQuery =
        baccaratQueryBetsBase +
        'banker"}) + ' +
        baccaratQueryBetsBase +
        'player"}) + ' +
        baccaratQueryBetsBase +
        'tie"}) + ' +
        baccaratQueryBetsBase +
        'banker_pair"}) + ' +
        baccaratQueryBetsBase +
        'player_pair"}) + ' +
        baccaratQueryBetsBase +
        'lucky6"})'

    const blackjackQueryBetsBase = 'sum({__name__=~"table_blackjack_' + locationQuery + '_bets_'
    const blackjackGameCountHistory = ['sum({__name__=~"table_blackjack_' + locationQuery + '_games"})']
    const blackjackProfitHistory = ['sum({__name__=~"table_blackjack_' + locationQuery + '_profit"})']
    const blackjackBetsQuery =
        blackjackQueryBetsBase +
        'base"}) + ' +
        blackjackQueryBetsBase +
        'doubledown"}) + ' +
        blackjackQueryBetsBase +
        'insurance"}) + ' +
        blackjackQueryBetsBase +
        'pair"}) + ' +
        blackjackQueryBetsBase +
        'split"})'

    const totalGameCountHistory = [
        'sum({__name__=~"table_baccarat_' +
        locationQuery +
        '_games"}) + sum({__name__=~"table_blackjack_' +
        locationQuery +
        '_games"})',
    ]
    const totalProfitHistory = [
        'sum({__name__=~"table_baccarat_' +
        locationQuery +
        '_profit"}) + sum({__name__=~"table_blackjack_' +
        locationQuery +
        '_profit"})',
    ]
    const totalBetsHistory = baccaratBetsQuery + ' + ' + blackjackBetsQuery

    const allGameCountHistory = union(baccaratGameCountHistory, blackjackGameCountHistory, totalGameCountHistory)
    const allProfitHistory = union(baccaratProfitHistory, blackjackProfitHistory, totalProfitHistory)
    const allBetsHistory = [baccaratBetsQuery, blackjackBetsQuery, totalBetsHistory]

    // const getRando = (min, max) => Math.random() * (max - min) + min

    const getHoursWagerData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'wager',
                data: hoursLabels.map((hr) => tablesReport.reduce((prev, next) => prev + next.hourlyBets['h' + hr], 0)),
                backgroundColor: chartBackgroundColors[4],
                borderColor: chartBackgroundColors[4],
            },
        ],
    })

    const getHoursWinLossData = () => {
        const data = hoursLabels.map((hr) => tablesReport.reduce((prev, next) => prev + next.hourlyProfit['h' + hr], 0))
        return {
            labels: hoursLabels,
            datasets: [
                {
                    label: 'win/loss',
                    data,
                    backgroundColor: data.map((v) => (v > 0 ? 'chartreuse' : 'red')),
                    borderColor: chartBackgroundColors[6],
                    order: 0,
                },
            ],
        }
    }

    const getHoursGamesData = () => ({
        labels: hoursLabels,
        datasets: [
            {
                label: 'games',
                data: hoursLabels.map((hr) =>
                    tablesReport.reduce((prev, next) => prev + next.hourlyGames['h' + hr], 0)
                ),
                // backgroundColor: chartBackgroundColors[6],
                borderColor: chartBackgroundColors[6],
            },
        ],
    })

    if (resizing && builtCharts && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    setTimeout(() => {
        setMinLoadTime(true)
    }, 800)

    const treeLoading = !active || !zones || !pits || !devices || !tables || !status || !minLoadTime

    if (
        !treeLoading &&
        (!location || (URLlocationID !== location[0].branchIndex && URLlocationType !== location[0].branchType))
    ) {
        setLocation({
            branchIndex: 'root',
            branchType: 'Casino',
            gameType: getGameType(tables, devices, URLlocationID, URLlocationType),
            descendantDevices: getDescendantDevices(pits, tables, devices, URLlocationID, URLlocationType),
        })
    }

    // filter reports by location
    const tablesReport =
        location && allTablesReport
            ? allTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : allTablesReport
    const baccaratTablesReport =
        location && baccaratAllTablesReport
            ? baccaratAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : baccaratAllTablesReport
    const blackjackTablesReport =
        location && blackjackAllTablesReport
            ? blackjackAllTablesReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : blackjackAllTablesReport

    const tablesCashReport =
        location && allTablesCashReport
            ? allTablesCashReport.filter((entry) => location[0].descendantDevices.indexOf(entry.device) >= 0)
            : allTablesCashReport

    const loading = !allTablesReport || !baccaratAllTablesReport || !blackjackAllTablesReport

    // Baccarat
    const baccaratWager =
        !baccaratTablesReport || !baccaratTablesReport.reduce
            ? 0
            : baccaratTablesReport.reduce((prev, next) => prev + next.bets, 0)
    const baccaratBetCount =
        !baccaratTablesReport || !baccaratTablesReport.reduce
            ? 0
            : baccaratTablesReport.reduce((prev, next) => prev + next.betCount, 0)
    const baccaratGamesWithBets =
        !baccaratTablesReport || !baccaratTablesReport.reduce
            ? 0
            : baccaratTablesReport.reduce((prev, next) => prev + next.gamesWithBets, 0)

    // payouts/return (profit+refund)
    // const baccaratPayout = !baccaratTablesReport || !baccaratTablesReport.reduce ? 0 : baccaratTablesReport.reduce((prev, next) => prev + next.return, 0)

    // Blackjack
    const blackjackWager =
        !blackjackTablesReport || !blackjackTablesReport.reduce
            ? 0
            : blackjackTablesReport.reduce((prev, next) => prev + next.bets, 0)
    const blackjackBetCount =
        !blackjackTablesReport || !blackjackTablesReport.reduce
            ? 0
            : blackjackTablesReport.reduce((prev, next) => prev + next.betCount, 0)
    const blackjackGamesWithBets =
        !blackjackTablesReport || !blackjackTablesReport.reduce
            ? 0
            : blackjackTablesReport.reduce((prev, next) => prev + next.gamesWithBets, 0)

    // payouts/return (profit+refund)
    // const blackjackPayout = !blackjackTablesReport || !blackjackTablesReport.reduce ? 0 : blackjackTablesReport.reduce((prev, next) => prev + next.return, 0)

    // Overview
    const numberOfGames =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.games, 0)
    const baccaratNumberOfGames =
        !baccaratTablesReport || !baccaratTablesReport.reduce
            ? 0
            : baccaratTablesReport.reduce((prev, next) => prev + next.games, 0)
    const blackjackNumberOfGames =
        !blackjackTablesReport || !blackjackTablesReport.reduce
            ? 0
            : blackjackTablesReport.reduce((prev, next) => prev + next.games, 0)
    const wager = !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.bets, 0)
    const winLoss =
        !tablesReport || !tablesReport.reduce ? 0 : tablesReport.reduce((prev, next) => prev + next.profit, 0)
    const baccaratWinLoss =
        !baccaratTablesReport || !baccaratTablesReport.reduce
            ? 0
            : baccaratTablesReport.reduce((prev, next) => prev + next.profit, 0)
    const blackjackWinLoss =
        !blackjackTablesReport || !blackjackTablesReport.reduce
            ? 0
            : blackjackTablesReport.reduce((prev, next) => prev + next.profit, 0)
    const numberOfGamesWithBets = baccaratGamesWithBets + blackjackGamesWithBets

    // this detects the hotreload from react
    if (treeLoading && Object.keys(builtCharts).length > 0) {
        setBuiltCharts({})
    }

    const fluid = width < 1900

    const currentMonth = moment.unix(startDate).format('MMMM')

    const getMonthTarget = (month) => {
        const currentMonth = month.toLowerCase()

        return targets ? targets.data[currentMonth] : 0
    }

    const cashdrop = tablesCashReport
        ? tablesCashReport
            .map((report) => report.drop && report.drop.reduce((prev, next) => prev + next.amount, 0))
            .reduce((prev, next) => prev + next)
        : 0

    const dummyData = {
        wager: {
            weekly: {
                target: 27012,
                targetUntilTdy: 3332,
                actual: 3000,
                projected: 3225,
            },
            monthly: {
                target: getMonthTarget(currentMonth),
                targetUntilTdy: 666666666,
                actual: wager,
                projected: 93345,
            },
            quarterly: {
                target: 300000,
                targetUntilTdy: 163044,
                actual: 170000,
                projected: 240294,
            },
            yearly: {
                target: 1200000,
                targetUntilTdy: 1061918,
                actual: 950000,
                projected: 1029243,
            },
        },
        housewin: {
            weekly: {
                target: 15000000,
                targetUntilTdy: 7857142,
                actual: 12212405,
                projected: 12420423,
            },
            monthly: {
                target: getMonthTarget(currentMonth),
                targetUntilTdy: 96453420,
                actual: winLoss,
                projected: 189343252,
            },
            quarterly: {
                target: 500000000,
                targetUntilTdy: 262445434,
                actual: 310000000,
                projected: 342304252,
            },
            yearly: {
                target: 600000000,
                targetUntilTdy: 282412434,
                actual: 310000000,
                projected: 329843582,
            },
        },
        cashdrop: {
            weekly: {
                target: 35000000,
                targetUntilTdy: 19446533,
                actual: 29295000,
                projected: 33249850,
            },
            monthly: {
                target: getMonthTarget(currentMonth),
                targetUntilTdy: 45023872,
                actual: cashdrop,
                projected: 43902342,
            },
            quarterly: {
                target: 340000000,
                targetUntilTdy: 223232324,
                actual: 304495000,
                projected: 354309342,
            },
            yearly: {
                target: 2356457233,
                targetUntilTdy: 104560470,
                actual: 202449500,
                projected: 253953063,
            },
        },
    }

    if (settings && !settings.data.xtable.sections.includes('overview')) {
        return <Navigate to='/dashboard' />
    }

    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: 320 }}>
                <Tree
                    loading={treeLoading}
                    autoUpdateEnabled={true}
                    dateChange={dateChange}
                    startDate={startDate}
                    endDate={endDate}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    setLocation={(newLocation) => {
                        if (newLocation.gameType !== gameType) {
                            setBuiltCharts({})
                        }
                        setLocation(newLocation)
                    }}
                    location={location}
                    fluid={fluid}
                />
            </Grid>
            <Grid item xs>
                {resizing && <LinearProgress />}
                {!resizing && (
                    <Location
                        loading={treeLoading}
                        zones={zones}
                        pits={pits}
                        tables={tables}
                        devices={devices}
                        location={location}
                        fluid={fluid}
                    />
                )}
                {!treeLoading && !resizing && (
                    <>
                        {loading && (
                            <Container fluid={fluid}>
                                <Row xs='1'>
                                    <Col>
                                        <h3>Fetching report...</h3>
                                        <Progress value={progressLoad} />
                                    </Col>
                                </Row>
                            </Container>
                        )}
                        {!loading && (
                            <Container fluid={fluid}>
                                {/* overview aggregated values */}
                                <Row>
                                    <Col>
                                        <Targets title='Target Wager' data={dummyData.wager} />
                                    </Col>
                                    <Col>
                                        <Targets title='Target House Win' data={dummyData.housewin} />
                                    </Col>
                                    <Col>
                                        <Targets title='Target Cash Drop' data={dummyData.cashdrop} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Total Wager</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <span style={styles.cardBodyText}>{formatCashString(wager)}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Total House Win/Loss</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <span
                                                    style={{
                                                        ...styles.cardBodyText,
                                                        ...styles.cashValue(winLoss),
                                                    }}
                                                >
                                                    {formatCashString(winLoss)}
                                                </span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Theoretical Hold</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={styles.cardBodyTitle}>Baccarat:</div>
                                                        <div style={styles.cardBodyText}>
                                                            {formatPresicePercentString(
                                                                baccaratWinLoss / baccaratWager
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={styles.cardBodyTitle}>Blackjack:</div>
                                                        <div style={styles.cardBodyText}>
                                                            {formatPresicePercentString(
                                                                blackjackWinLoss / blackjackWager
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Total Game Count</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <span style={styles.cardBodyText}>
                                                    {formatCountString(numberOfGames)}
                                                </span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Theoretical RTP</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={styles.cardBodyTitle}>Baccarat:</div>
                                                        <div style={styles.cardBodyText}>
                                                            {formatPresicePercentString(
                                                                1 - baccaratWinLoss / baccaratWager
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={styles.cardBodyTitle}>Blackjack:</div>
                                                        <div style={styles.cardBodyText}>
                                                            {formatPresicePercentString(
                                                                1 - blackjackWinLoss / blackjackWager
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Total Game Count with Bet(s)</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <span style={styles.cardBodyText}>
                                                    {formatCountString(numberOfGamesWithBets)}
                                                </span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Total Bet Count</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={styles.cardBodyTitle}>Baccarat:</div>
                                                        <div style={styles.cardBodyText}>
                                                            {formatCountString(baccaratBetCount)}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={styles.cardBodyTitle}>Blackjack:</div>
                                                        <div style={styles.cardBodyText}>
                                                            {formatCountString(blackjackBetCount)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader style={styles.cardHeader}>
                                                <span style={styles.cardTitleText}>Bet Efficiency</span>
                                            </CardHeader>
                                            <CardBody style={styles.cardBody}>
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={styles.cardBodyTitle}>Baccarat:</div>
                                                        <div style={styles.cardBodyText}>
                                                            {formatPresicePercentString(
                                                                baccaratBetCount / (baccaratNumberOfGames * 5 * 4)
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={styles.cardBodyTitle}>Blackjack:</div>
                                                        <div style={styles.cardBodyText}>
                                                            {formatPresicePercentString(
                                                                blackjackBetCount / (blackjackNumberOfGames * 5 * 4)
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                {numberOfDays > 0 && tablesReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Wager History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('allWagerHistory', value)
                                                        }
                                                        builtChart={builtCharts['allWagerHistory']}
                                                        findInLabelMap={allPerLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTable}
                                                        query={allBetsHistory}
                                                        nonTable
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesReport && withWager && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly Wager</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                redraw: true,
                                                                updateMode: 'resize',
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursWagerData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays > 0 && tablesReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>House Win/Loss History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('allProfitHistory', value)
                                                        }
                                                        builtChart={builtCharts['allProfitHistory']}
                                                        findInLabelMap={allPerLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTable}
                                                        query={allProfitHistory}
                                                        nonTable
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesReport && withWager && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly House Win/Loss</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                backgroundColor: chartBackgroundColors[1],
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                            }}
                                                            data={getHoursWinLossData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays > 0 && tablesReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Game Count History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('allGameCountHistory', value)
                                                        }
                                                        builtChart={builtCharts['allGameCountHistory']}
                                                        findInLabelMap={allPerLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTable}
                                                        query={allGameCountHistory}
                                                        nonTable
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly Number of Games</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Line
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursGamesData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {(!tablesCashReport && withWager) && (
                                    <Container fluid={width <= 1680}>
                                        <Row xs='1'>
                                            <Col>
                                                <h3>Fetching tray report...</h3>
                                                <Progress value={fetchTablesCashReportProgress} />
                                            </Col>
                                        </Row>
                                    </Container>
                                )}

                                {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly Cash Drop</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                redraw: true,
                                                                updateMode: 'resize',
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursCashDropData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays > 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Cash Drop History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('allCashdropHistory', value)
                                                        }
                                                        builtChart={builtCharts['allCashdropHistory']}
                                                        findInLabelMap={allPerLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={allCashdropHistory}
                                                        nonTable
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly Chip Fill</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                redraw: true,
                                                                updateMode: 'resize',
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursChipFillData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays > 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Chip Fill History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('allChipfillHistory', value)
                                                        }
                                                        builtChart={builtCharts['allChipfillHistory']}
                                                        findInLabelMap={allPerLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={allChipfillHistory}
                                                        nonTable
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays === 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Hourly Chip Credit</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <div style={styles.barContainer}>
                                                        <Bar
                                                            options={{
                                                                plugins: {
                                                                    legend: false,
                                                                },
                                                                redraw: true,
                                                                updateMode: 'resize',
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [
                                                                        {
                                                                            id: 'first-y-axis',
                                                                            type: 'linear',
                                                                            ticks: {
                                                                                min: 0,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                            data={getHoursChipCreditData()}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {numberOfDays > 0 && tablesCashReport && fetchedTablesCashReport && (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader style={styles.cardHeader}>
                                                    <span style={styles.cardTitleText}>Chip Credit History</span>
                                                </CardHeader>
                                                <CardBody style={styles.cardBody}>
                                                    <TableHistoryChart
                                                        setBuiltChart={(value) =>
                                                            setBuiltChartProxy('allChipcreditHistory', value)
                                                        }
                                                        builtChart={builtCharts['allChipcreditHistory']}
                                                        findInLabelMap={allPerLabelFind}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        prometheus={prometheusTrend}
                                                        query={allChipcreditHistory}
                                                        nonTable
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </Container>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default Overview
