import React, { useState } from 'react'

import C11 from './assets/c_j.png'
import C13 from './assets/c_k.png'
import C12 from './assets/c_q.png'
import cardBackBanker from './assets/card_back_banker.png'
import cardBackPlayer from './assets/card_back_player.png'
import SuitC from './assets/club.png'
import D11 from './assets/d_j.png'
import D13 from './assets/d_k.png'
import D12 from './assets/d_q.png'
import SuitD from './assets/diamond.png'
import H11 from './assets/h_j.png'
import H13 from './assets/h_k.png'
import H12 from './assets/h_q.png'
import SuitH from './assets/heart.png'
import S11 from './assets/s_j.png'
import S13 from './assets/s_k.png'
import S12 from './assets/s_q.png'
import SuitS from './assets/spade.png'

const rootStyles = {
    outline: { border: '0px solid #000' },
    face: {
        color: 'blue'
    },

    suit1: {
        background: `url(${SuitS}) no-repeat center center / contain`
    },
    suit2: {
        background: `url(${SuitH}) no-repeat center center / contain`
    },
    suit3: {
        background: `url(${SuitC}) no-repeat center center / contain`
    },
    suit4: {
        background: `url(${SuitD}) no-repeat center center / contain`
    },


    s1_11: {
        background: `url(${S11}) no-repeat center center / contain`
    },
    s1_12: {
        background: `url(${S12}) no-repeat center center / contain`
    },
    s1_13: {
        background: `url(${S13}) no-repeat center center / contain`
    },

    s2_11: {
        background: `url(${H11}) no-repeat center center / contain`
    },
    s2_12: {
        background: `url(${H12}) no-repeat center center / contain`
    },
    s2_13: {
        background: `url(${H13}) no-repeat center center / contain`
    },

    s3_11: {
        background: `url(${C11}) no-repeat center center / contain`
    },
    s3_12: {
        background: `url(${C12}) no-repeat center center / contain`
    },
    s3_13: {
        background: `url(${C13}) no-repeat center center / contain`
    },

    s4_11: {
        background: `url(${D11}) no-repeat center center / contain`
    },
    s4_12: {
        background: `url(${D12}) no-repeat center center / contain`
    },
    s4_13: {
        background: `url(${D13}) no-repeat center center / contain`
    },

    suitRow: {
        gridColumn: '1 /span 1', display: 'flex', alignItems: 'center', justifyContent: 'center'
    },

    red: {
        color: '#cd0600'
    },
    black: {
        color: 'black'
    },
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    },
    hiddenCard: {
        fontSize: 30
    },
    readyCard: {
        fontSize: 16,
        letterSpacing: 1
    },
    countDownBankerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: `url(${cardBackBanker}) no-repeat center center / cover`,
        fontSize: '19px',
        color: '#370505',
        '& div': {
            backgroundColor: '#000',
            width: '9vh',
            height: '9vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.6',
            borderRadius: '50%',
            color: 'white',
        }

    },
    countDownBankerHorizontal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: `url(${cardBackBanker}) no-repeat center center / cover`,
        fontSize: '19px',
        color: '#370505',
        '& div': {
            transform: 'rotate(-90deg)',
            textAlign: 'center',
            backgroundColor: '#000',
            width: '9vh',
            height: '9vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.6',
            borderRadius: '50%',
            color: 'white',
        }

    },

    countDownPlayerVertical: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: `url(${cardBackPlayer}) no-repeat center center / cover`,
        fontSize: '19px',
        color: '#00243f',
        '& div': {
            backgroundColor: '#000',
            width: '9vh',
            height: '9vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.6',
            borderRadius: '50%',
            color: 'white',
        }
    },
    countDownPlayerHorizontal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: `url(${cardBackBanker}) no-repeat center center / cover`,
        fontSize: '19px',
        color: '#00243f',
        '& div': {
            transform: 'rotate(-90deg)',
            textAlign: 'center',
            backgroundColor: '#000',
            width: '9vh',
            height: '9vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '0.6',
            borderRadius: '50%',
            color: 'white',
        }
    },

    counterDiv: {
        backgroundColor: '#000',
        width: '9vh',
        height: '9vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: '0.6',
        borderRadius: '50%',
        color: 'white',
        fontSize: '29px'
    },

    border: {
        border: '0px solid #ccc'
    },
    card: {
        margin: 5,
        '& ::selection': {
            color: 'none',
            background: 'none'
        }
    },
}

const getClassName = (...k) => Object.assign({}, ...k)

export const Card = ({ id, owner, suit, value, reveal, revealTimer, ApiRevealPerCard = null }) => {
    //reveal = 'hidden', 'ready', 'show', 'stealth'

    // 1s
    // 2h
    // 3c
    // 4d
    const s = rootStyles

    const getStealthCardCoverStyle = () => {
        if (owner === "P") {
            return s.countDownPlayerVertical
        }

        return s.countDownBankerVertical
    }

    const getCardCoverStyle = () => {
        if (id === 'b1' || id === 'b2') {
            return s.countDownBankerVertical
        }


        if (id === 'p1' || id === 'p2') {
            return s.countDownPlayerVertical
        }
        if (id === 'p3') {
            return s.countDownPlayerHorizontal
        }
        if (id === 'b3') {
            return s.countDownBankerHorizontal
        }
    }



    const getFace = (suit, value) => {
        return value > 10 ? s['s' + suit + '_' + value] : ''
    }

    const suitColumn = (col, suit, value) => {
        const LRCol = [[0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [1, 0, 0, 0, 0, 0, 1], [1, 0, 0, 0, 0, 0, 1], [1, 0, 0, 1, 0, 0, 1], [1, 0, 0, 1, 0, 0, 1], [1, 0, 0, 1, 0, 0, 1], [1, 0, 1, 0, 1, 0, 1], [1, 0, 1, 0, 1, 0, 1]]
        const CCol = [[0, 0, 0, 1, 0, 0, 0], [1, 0, 0, 0, 0, 0, 1], [1, 0, 0, 1, 0, 0, 1], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 1, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 1, 0, 0, 0, 0, 0], [0, 1, 0, 0, 0, 1, 0], [0, 0, 0, 1, 0, 0, 0], [0, 1, 0, 0, 0, 1, 0]]
        const isVisible = (col, value, row) => {
            const arr = col === 'C' ? CCol : LRCol
            return !value ? s.hidden : value > 10 ? s.hidden : arr[value - 1][row - 1] ? s.visible : s.hidden
        }
        return (
            <div style={getClassName(s.outline, { display: 'grid', gridTemplateRows: '8% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 8%', gridTemplateColumns: 'auto' })}>
                <div style={getClassName(s.outline, s.suitRow, { gridRow: '1 / span 1' })}></div>
                <div style={getClassName(isVisible(col, value, 1), s.outline, s.suitRow, s['suit' + suit], { gridRow: '2 / span 2' })}></div>
                <div style={getClassName(isVisible(col, value, 2), s.outline, s.suitRow, s['suit' + suit], { gridRow: '3 / span 2' })}></div>
                <div style={getClassName(isVisible(col, value, 3), s.outline, s.suitRow, s['suit' + suit], { gridRow: '4 / span 2' })}></div>
                <div style={getClassName(isVisible(col, value, 4), s.outline, s.suitRow, s['suit' + suit], { gridRow: '5 / span 2' })}></div>
                <div style={getClassName(isVisible(col, value, 5), s.outline, s.suitRow, s['suit' + suit], { gridRow: '6 / span 2' })}></div>
                <div style={getClassName(isVisible(col, value, 6), s.outline, s.suitRow, s['suit' + suit], { gridRow: '7 / span 2' })}></div>
                <div style={getClassName(isVisible(col, value, 7), s.outline, s.suitRow, s['suit' + suit], { gridRow: '8 / span 2' })}></div>
                <div style={getClassName(s.outline, s.suitRow, { gridRow: '10 / span 1' })}></div>
            </div>
        )
    }

    const displayValue = (value) => {
        return value === 1 ? 'A' : value < 11 ? value : value === 11 ? 'J' : value === 12 ? 'Q' : value === 13 ? 'K' : ''
    }
    const getSuitColor = () => {
        return suit === 2 || suit === 4 ? s.red : s.black
    }

    const miniCard = <div style={getClassName(getSuitColor(), { textAlign: "center", fontSize: '1.8em', fontWeight: 500 })}>
        {displayValue(value)} <div style={getClassName(s['suit' + suit], { height: '1.6em', marginTop: '-8px' })}>&nbsp;</div></div>

    const miniStealthCard = <div style={getClassName(s.hiddenCard, getStealthCardCoverStyle(), { textAlign: "center", fontSize: '2vh', fontWeight: 500 })}>&nbsp;</div>

    const fullCard = (<div style={getClassName(getSuitColor(), { display: 'grid', gridTemplateColumns: '15% auto 15%', height: '100%' })} >
        <div style={getClassName(s.outline, { textAlign: "center", padding: '10% 0', fontSize: '3em', fontWeight: 500 })}>
            {displayValue(value)}
            <div style={getClassName(s['suit' + suit])}>&nbsp;</div>
        </div>
        <div style={getClassName(getFace(suit, value), { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' })}>
            {suitColumn('L', suit, value)}
            {suitColumn('C', suit, value)}
            {suitColumn('R', suit, value)}
        </div>
        <div style={getClassName(s.outline, { textAlign: "center", padding: '10% 0', fontSize: '3em', fontWeight: 500, transform: 'rotate(180deg)' })}>
            {displayValue(value)}
            <div style={getClassName(s['suit' + suit])}>&nbsp;</div>
        </div>
    </div>)

    const hiddenCard = <div style={getClassName(s.hiddenCard, getCardCoverStyle())}>&nbsp;</div>
    const readyCard = <div style={getClassName(s.readyCard, getCardCoverStyle())}><div>REVEAL</div></div>
    const [counter, setCount] = useState(revealTimer)
    const countDownCard = <div style={getCardCoverStyle()}><div style={s.counterDiv}>{counter}</div></div>



    const countDown = () => {
        if (counter === 0) {
            return false
        } else {
            setTimeout(() => {
                setCount(counter - 1)
            }, 1000)
            return true
        }

    }


    if (reveal === "hidden" && counter !== revealTimer) {
        setCount(revealTimer)
    }

    if (reveal === 'countdown_flop' && (id === 'b1' || id === 'b2' || id === 'p1' || id === 'p2')) {
        if (countDown()) return countDownCard
        if (ApiRevealPerCard) ApiRevealPerCard(['b1', 'b2', 'p1', 'p2'])
    }

    if (reveal === 'countdown_plusB' && id === 'b3') {
        if (countDown()) return countDownCard
        if (ApiRevealPerCard) ApiRevealPerCard(id)
    }
    if (reveal === 'countdown_plusP' && id === 'p3') {
        if (countDown()) return countDownCard
        if (ApiRevealPerCard) ApiRevealPerCard(id)
    }

    // console.log(reveal, id, suit, value)
    //console.log(revealTimer, counter)
    return id === 'mini' && reveal !== 'stealth' ? miniCard :
        id === 'mini' && reveal === 'stealth' ? miniStealthCard :
            (!suit || !value) ? '' :
                reveal === 'hidden' ? hiddenCard :
                    reveal === 'ready' ? readyCard : fullCard
}

export const Cards = ({ gameType, cards, fake, notDone, warCards }) => {
    const bankerCards = gameType === 'baccarat' && cards.filter((v) => v.owner === 'B')
    const playerCards = gameType === 'baccarat' && cards.filter((v) => v.owner === 'P')

    const firstFourDealerCards = gameType === 'blackjack' && cards.slice(0, 4)
    const lastFiveDealerCards = gameType === 'blackjack' && cards.slice(4, 9)

    const dealerAnteCards = gameType === 'war' && !warCards && cards.dealer
    const playerAnteCards = gameType === 'war' && !warCards && cards.player

    const dealerWarCards = gameType === 'war' && warCards && cards.dealer
    const playerWarCards = gameType === 'war' && warCards && cards.player

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '1.5em' }}>
            {gameType === 'baccarat' && (
                <div
                    style={{
                        background: 'transparent linear-gradient(180deg, #340404 0%, #FF4F4F 100%)',
                        border: '3px solid #FF4F4F',
                        borderRadius: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 7,
                        margin: 3,
                    }}
                >
                    <div style={{ margin: 5, marginTop: 0, color: '#999' }}>Banker Cards</div>
                    <div style={{ display: 'flex' }}>
                        {bankerCards.map((v, i) => {
                            return (
                                <div key={i} style={getClassName(rootStyles.border, rootStyles.card)}>
                                    <div
                                        style={{
                                            width: '49px',
                                            height: '90px',
                                            backgroundColor: '#fff',
                                            borderRadius: '10px',
                                            border: '8px solid transparent',
                                            margin: 'auto',
                                        }}
                                    >
                                        <Card
                                            id={'mini'}
                                            suit={v.suit}
                                            value={v.value}
                                            owner={'B'}
                                            reveal={fake ? 'stealth' : 'show'}
                                            revealTimer={0}
                                            ApiRevealPerCard={false}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}

            {gameType === 'baccarat' && (
                <div
                    style={{
                        background: 'transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)',
                        border: '3px solid #008FFA',
                        borderRadius: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 7,
                        margin: 3,
                    }}
                >
                    <div style={{ margin: 5, marginTop: 0, color: '#999' }}>Player Cards</div>
                    <div style={{ display: 'flex' }}>
                        {playerCards.map((v, i) => {
                            return (
                                <div key={i} style={getClassName(rootStyles.border, rootStyles.card)}>
                                    <div
                                        style={{
                                            width: '49px',
                                            height: '90px',
                                            backgroundColor: '#fff',
                                            borderRadius: '10px',
                                            border: '8px solid transparent',
                                            margin: 'auto',
                                        }}
                                    >
                                        <Card
                                            id={'mini'}
                                            suit={v.suit}
                                            value={v.value}
                                            owner={'P'}
                                            reveal={fake ? 'stealth' : 'show'}
                                            revealTimer={0}
                                            ApiRevealPerCard={false}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}

            {gameType === 'blackjack' && (
                <div
                    style={{
                        background: !notDone ? 'transparent linear-gradient(180deg, #340404 0%, #FF4F4F 100%)' :
                            'transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)',
                        border: !notDone ? '3px solid #FF4F4F' : '3px solid #008FFA',
                        borderRadius: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 7,
                        margin: 3,
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        {firstFourDealerCards.map((v, i) => {
                            return (
                                <div key={i} style={getClassName(rootStyles.border, rootStyles.card)}>
                                    <div
                                        style={{
                                            width: '49px',
                                            height: '90px',
                                            backgroundColor: '#fff',
                                            borderRadius: '10px',
                                            border: '8px solid transparent',
                                            margin: 'auto',
                                        }}
                                    >
                                        <Card
                                            id={'mini'}
                                            suit={v.suit}
                                            value={v.value}
                                            reveal={fake ? 'stealth' : 'show'}
                                            revealTimer={0}
                                            ApiRevealPerCard={false}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{ display: 'flex' }}>
                        {lastFiveDealerCards.map((v, i) => {
                            return (
                                <div key={i} style={getClassName(rootStyles.border, rootStyles.card)}>
                                    <div
                                        style={{
                                            width: '49px',
                                            height: '90px',
                                            backgroundColor: '#fff',
                                            borderRadius: '10px',
                                            border: '8px solid transparent',
                                            margin: 'auto',
                                        }}
                                    >
                                        <Card
                                            id={'mini'}
                                            suit={v.suit}
                                            value={v.value}
                                            reveal={fake ? 'stealth' : 'show'}
                                            revealTimer={0}
                                            ApiRevealPerCard={false}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}

            {gameType === 'war' && !warCards && (
                <>
                    <div
                        style={{
                            background: !notDone
                                ? 'transparent linear-gradient(180deg, #340404 0%, #FF4F4F 100%)'
                                : 'transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)',
                            border: !notDone ? '3px solid #FF4F4F' : '3px solid #008FFA',
                            borderRadius: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 7,
                            margin: 3,
                        }}
                    >
                        <div style={{ margin: 5, marginTop: 0, color: '#999' }}>Dealer Card</div>
                        <div style={{ display: 'flex' }}>
                            {Array(1)
                                .fill(dealerAnteCards)
                                .map((v, i) => {
                                    return (
                                        <div key={i} style={getClassName(rootStyles.border, rootStyles.card)}>
                                            <div
                                                style={{
                                                    width: '49px',
                                                    height: '90px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '10px',
                                                    border: '8px solid transparent',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Card
                                                    id={'mini'}
                                                    suit={v.suit}
                                                    value={v.value === 14 ? 1 : v.value}
                                                    reveal={fake ? 'stealth' : 'show'}
                                                    revealTimer={0}
                                                    ApiRevealPerCard={false}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <div
                        style={{
                            background: 'transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)',
                            border: '3px solid #008FFA',
                            borderRadius: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 7,
                            margin: 3,
                        }}
                    >
                        <div style={{ margin: 5, marginTop: 0, color: '#999' }}>Player Cards</div>
                        <div style={{ display: 'flex' }}>
                            {playerAnteCards.map((v, i) => {
                                return (
                                    <div key={i} style={getClassName(rootStyles.border, rootStyles.card)}>
                                        <div style={{ margin: 5, marginTop: 0, color: '#999' }}>Hand {i + 1}</div>
                                        <div
                                            style={{
                                                width: '49px',
                                                height: '90px',
                                                backgroundColor: '#fff',
                                                borderRadius: '10px',
                                                border: '8px solid transparent',
                                                margin: 'auto',
                                            }}
                                        >
                                            <Card
                                                id={'mini'}
                                                suit={v.suit}
                                                value={v.value === 14 ? 1 : v.value}
                                                owner={'P'}
                                                reveal={fake ? 'stealth' : 'show'}
                                                revealTimer={0}
                                                ApiRevealPerCard={false}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            )}

            {gameType === 'war' && warCards && (
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            background: !notDone
                                ? 'transparent linear-gradient(180deg, #340404 0%, #FF4F4F 100%)'
                                : 'transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)',
                            border: !notDone ? '3px solid #FF4F4F' : '3px solid #008FFA',
                            borderRadius: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 7,
                            margin: 3,
                        }}
                    >
                        <div style={{ margin: 5, marginTop: 0, color: '#999', textAlign: 'center' }}>Dealer Card</div>
                        <div style={{ display: 'flex' }}>
                            {Array(1)
                                .fill(dealerWarCards)
                                .map((v, i) => {
                                    return (
                                        <div key={i} style={getClassName(rootStyles.border, rootStyles.card)}>
                                            <div
                                                style={{
                                                    width: '49px',
                                                    height: '90px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '10px',
                                                    border: '8px solid transparent',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Card
                                                    id={'mini'}
                                                    suit={v.suit}
                                                    value={v.value === 14 ? 1 : v.value}
                                                    reveal={fake ? 'stealth' : 'show'}
                                                    revealTimer={0}
                                                    ApiRevealPerCard={false}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <div
                        style={{
                            background: 'transparent linear-gradient(180deg, #001F36 0%, #008FFA 100%)',
                            border: '3px solid #008FFA',
                            borderRadius: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 7,
                            margin: 3,
                        }}
                    >
                        <div style={{ margin: 5, marginTop: 0, color: '#999', textAlign: 'center' }}>Player Card</div>
                        <div style={{ display: 'flex' }}>
                            {Array(1)
                                .fill(playerWarCards)
                                .map((v, i) => {
                                    return (
                                        <div key={i} style={getClassName(rootStyles.border, rootStyles.card)}>
                                            <div
                                                style={{
                                                    width: '49px',
                                                    height: '90px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '10px',
                                                    border: '8px solid transparent',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Card
                                                    id={'mini'}
                                                    suit={v.suit}
                                                    value={v.value === 14 ? 1 : v.value}
                                                    owner={'P'}
                                                    reveal={fake ? 'stealth' : 'show'}
                                                    revealTimer={0}
                                                    ApiRevealPerCard={false}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
