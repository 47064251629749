import React, { memo } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { toLower } from 'lodash'

import R404 from '../404'
import Jackpots from './jackpots/Jackpots'
import Settings from './jackpots/Settings'

export default memo(() => {
	const { pathname } = useLocation()
	const role = window.localStorage.getItem('role')
	const products = window.localStorage.getItem('products')
		? window.localStorage
				.getItem('products')
				.split(',')
				.map((x) => toLower(x))
		: []

	if (role !== 'root' && !products.includes(pathname.split('/')[1])) {
		return <Navigate to='/dashboard' />
	}

	return (
		<>
			<Routes>
				<Route exact path='/' element={<Navigate to='/xmeter/home' replace />} />
				<Route exact path='/home' element={<Jackpots />} />
				<Route exact path='/settings' element={<Settings />} />
				<Route path='*' element={<R404 basePath='xmeter/' />} />
			</Routes>
		</>
	)
})
