import React from 'react'
import { Navigate } from 'react-router-dom'
import { LinearProgress } from '@mui/material'

import { useSubscribe, usePublish } from '../../api.js'
import { patternsServerIP } from '../../config.js'
import HotTrendOptions from './HotTrendOptions.js'
import Settings from './Settings.js'

const HTHT = ({ section }) => {
    const [settings, settingsSocket] = useSubscribe('settings')
    const [zones, zonesSocket] = useSubscribe('zones/*')
    const [pits, pitsSocket] = useSubscribe('pits/*')
    const [tables, tablesSocket] = useSubscribe('tables/*')
    const [devices, devicesSocket] = useSubscribe('devices/*')
    const [standardPatterns, standardPatternsSocket] = useSubscribe(patternsServerIP + '/settings/baccarat/standard')
    const [lucky6Patterns, lucky6PatternsSocket] = useSubscribe(patternsServerIP + '/settings/baccarat/lucky6')
    const [mega6Patterns, mega6PatternsSocket] = useSubscribe(patternsServerIP + '/settings/baccarat/mega6')
    const [tigerPatterns, tigerPatternsSocket] = useSubscribe(patternsServerIP + '/settings/baccarat/tiger')
    const standardPatternsPublish = usePublish(patternsServerIP + '/settings/baccarat/standard')
    const lucky6PatternsPublish = usePublish(patternsServerIP + '/settings/baccarat/lucky6')
    const mega6PatternsPublish = usePublish(patternsServerIP + '/settings/baccarat/mega6')
    const tigerPatternsPublish = usePublish(patternsServerIP + '/settings/baccarat/tiger')

    const active =
        zonesSocket &&
        zonesSocket.readyState === WebSocket.OPEN &&
        tablesSocket &&
        tablesSocket.readyState === WebSocket.OPEN &&
        pitsSocket &&
        pitsSocket.readyState === WebSocket.OPEN &&
        devicesSocket &&
        devicesSocket.readyState === WebSocket.OPEN &&
        settingsSocket &&
        settingsSocket.readyState === WebSocket.OPEN &&
        standardPatternsSocket &&
        standardPatternsSocket.readyState === WebSocket.OPEN &&
        lucky6PatternsSocket &&
        lucky6PatternsSocket.readyState === WebSocket.OPEN &&
        mega6PatternsSocket &&
        mega6PatternsSocket.readyState === WebSocket.OPEN &&
        tigerPatternsSocket &&
        tigerPatternsSocket.readyState === WebSocket.OPEN

    if (
        !zones ||
        !pits ||
        !devices ||
        !tables ||
        !settings ||
        !standardPatterns ||
        !lucky6Patterns ||
        !mega6Patterns ||
        !tigerPatterns ||
        !active
    ) {
        return <LinearProgress />
    }

    if (settings && !settings.data.xstadium?.hotTrendsHotTablesMode) {
        return <Navigate to='/dashboard' />
    }

    return (
        <>
            {section === 'trends' && (
                <HotTrendOptions
                    settings={settings}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    standardPatterns={standardPatterns}
                    lucky6Patterns={lucky6Patterns}
                    mega6Patterns={mega6Patterns}
                    tigerPatterns={tigerPatterns}
                    standardPatternsPublish={standardPatternsPublish}
                    lucky6PatternsPublish={lucky6PatternsPublish}
                    mega6PatternsPublish={mega6PatternsPublish}
                    tigerPatternsPublish={tigerPatternsPublish}
                />
            )}
            {section === 'settings' && (
                <Settings
                    settings={settings}
                    zones={zones}
                    pits={pits}
                    tables={tables}
                    devices={devices}
                    standardPatterns={standardPatterns}
                    lucky6Patterns={lucky6Patterns}
                    mega6Patterns={mega6Patterns}
                    tigerPatterns={tigerPatterns}
                    standardPatternsPublish={standardPatternsPublish}
                    lucky6PatternsPublish={lucky6PatternsPublish}
                    mega6PatternsPublish={mega6PatternsPublish}
                    tigerPatternsPublish={tigerPatternsPublish}
                />
            )}
        </>
    )
}

export default HTHT
